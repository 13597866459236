import {
  createTheme,
  createThemeProvider,
  createIntrinsicViews,
} from '@sail/engine/deprecated';
import * as Headless from '@sail/theme-headless';

import {tokensColor} from './tokens';
import {font} from './plugins';

// This is a purposefully simple thing, we don't actually want to support this generally,
// so this supports only the exact things we need, and takes input that should lead to easier
// refactoring later.
export const withOpacity = (
  color: '$color.neutral700' | '$color.gray200' | '$color.blue400',
  opacity = '100%',
): string =>
  ({
    '$color.gray200': `rgb(192 200 210 / ${opacity})`,
    '$color.neutral700': `rgb(64 68 82 / ${opacity})`,
    '$color.blue400': `rgb(1 150 237 / ${opacity})`,
  }[color] ?? 'rgb(0,0,0)');

export const theme = createTheme(
  'classic',
  Headless.css.configure((css) => ({
    plugins: {
      ...font(css),
    },
  })),
);

export const Provider = createThemeProvider([theme]);

export const css = theme.css;

export const view = createIntrinsicViews<typeof css.ComputedProperties>(css);

const classicTokenOverrides = css.token.assign({
  ...tokensColor,
  radius: {
    small: '4px',
  },
  shadow: {
    top: `rgb(0 0 0 / 12%) 0px 1px 1px 0px`,
    base: `${withOpacity(
      '$color.neutral700',
      '8%',
    )} 0px 2px 5px 0px, 0 0 0 0 transparent`,
    hover: `${withOpacity(
      '$color.neutral700',
      '8%',
    )} 0px 2px 5px 0px, ${withOpacity(
      '$color.neutral700',
      '8%',
    )} 0px 3px 9px 0px`,
    focus: `0 0 0 4px ${withOpacity('$color.blue400', '36%')}`,
  },
});

theme.global([
  classicTokenOverrides,
  css({
    fontFamily: 'ui',
    color: 'primary',
    fill: 'primary',
    WebkitFontSmoothing: css.value('antialiased'),
  }),
]);
