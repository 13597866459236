import {createAlias} from '../core';
import type {StylePlugin} from '../core';

export const axisProperties: StylePlugin = {
  properties: {
    'margin-x': createAlias(['margin-left', 'margin-right']),
    'margin-y': createAlias(['margin-top', 'margin-bottom']),
    'padding-x': createAlias(['padding-left', 'padding-right']),
    'padding-y': createAlias(['padding-top', 'padding-bottom']),
  },
};
