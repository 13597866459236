import * as React from 'react';
import {createViewConfig, view} from '@sail/engine';
import {arrowUpDown} from '@sail/icons/react/Icon';
import type {IconAsset} from '@sail/icons/types';
import type {View} from '../view';
import {Input} from './Input';
import {Icon} from './Icon';
import {useFieldContexts} from '../hooks/useFieldContexts';
import type {FormFieldProps} from './FormField';
import {useFormFieldProps, FormField} from './FormField';
import {css} from '../css';
import {tokens} from '../tokens';

export type NativeSelectProps = View.IntrinsicElement<
  'select',
  {
    /**
     * Sets the Select's value, for controlled use cases. Should be used in conjunction with `onChange`.
     */
    value?: string;
    /**
     * Function to call when the element's value changes.
     * @external
     */
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    /**
     * Sets whether or not the element should be disabled. Prevents selection.
     * @external
     */
    disabled?: boolean;
    /**
     * Displays the component that indicates that the value is invalid
     * @external
     */
    invalid?: boolean;
    /**
     * The size of the select control
     * @external
     */
    size?: 'small' | 'medium' | 'large';
    /**
     * The icon to display in the select control
     * @external
     */
    icon?: IconAsset;
  }
>;

type NativeSelectPropsWithComposite = NativeSelectProps & {
  id?: string;
};

export const NativeSelectControlConfig = createViewConfig({
  props: {} as NativeSelectPropsWithComposite,
  name: 'NativeSelectControl',
  flattens: true,
  defaults: {
    multiple: false,
    size: 'medium',
  },
});

const NativeSelectControl = NativeSelectControlConfig.createView(
  (props) => <Input as="select" {...props} />,
  {
    css: {
      // Reset
      appearance: 'none',
      boxSizing: 'border-box',

      // Layout
      stack: 'y',
      // When placed in a grid, and very large we want to avoid the grid taking
      // up more horizontal space than exists.
      // Fixes: SAIL-3272
      // Solution is from: css-tricks.com/preventing-a-grid-blowout
      minWidth: 'space.0',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      // Appearance
      topShadow: 'form',
      keyline: 'form',
      backgroundColor: 'form',
      textColor: 'form',
      border: 'none',
      borderRadius: 'form',
      transition: 'box-shadow 240ms',

      // Cursor
      ':not(:disabled)': {
        cursor: 'pointer',
      },

      // Focus
      ':focus': {
        focusRing: 'focus',
        topShadow: 'form.focused',
        keyline: 'form.focused',
        backgroundColor: 'form.focused',
        textColor: 'form.focused',
      },

      // Hovered
      ':hover:not(:disabled)': {
        topShadow: 'form.hovered',
        keyline: 'form.hovered',
        backgroundColor: 'form.hovered',
        textColor: 'form.hovered',
      },

      ':active:not(:disabled)': {
        topShadow: 'form.pressed',
        keyline: 'form.pressed',
        backgroundColor: 'form.pressed',
        textColor: 'form.pressed',
      },

      // Error styles
      '[aria-invalid="true"]': {
        keyline: 'form.error',
      },

      // Disabled styles
      ':disabled': {
        textColor: 'form.disabled',
        borderColor: 'form.disabled',
        backgroundColor: 'form.disabled',
        pointerEvents: 'none',
        topShadow: '0 0 0 0 transparent',
        opacity: 1,
      },
    },
    forward: {
      multiple: true,
    },
    variants: {
      size: {
        small: css({
          paddingY: 'xsmall',
          paddingLeft: 'small',
          paddingRight: 'large',
          font: 'label.small.emphasized',
        }),
        medium: css({
          paddingY: 'xsmall',
          paddingLeft: 'small',
          paddingRight: '26px',
          font: 'label.medium.emphasized',
        }),
        large: css({
          paddingY: 'small',
          paddingX: 'space.150',
          paddingRight: 'space.350',
          font: 'label.large.emphasized',
        }),
      },
    },
  },
);

const iconStyles = css({
  // Consider how this might be themed
  pointerEvents: 'none',
  width: 'space.150',
  height: 'space.150',
  marginRight: 'small',
  alignSelfX: 'end',
  alignSelfY: 'center',
});

// The select element does its own overrides of these, but
// setting these means that the icon inherits the colors
const disabledColorStyles = css({
  color: 'form.disabled',
  fill: 'form.disabled',
});

const enabledColorStyles = css({
  color: 'form',
  fill: 'form',
  ':hover': {
    // This is for the icon to inherit
    fill: tokens.color.neutral[900],
  },
});

export const NativeSelectConfig = createViewConfig({
  props: {} as NativeSelectProps & {
    subviews?: View.Subviews<{
      wrapper: 'div';
    }>;
  },
  name: 'NativeSelect',
  defaults: {
    multiple: false,
    size: 'medium',
  },
});

export const NativeSelect = NativeSelectConfig.createView(
  ({
    size = 'medium',
    multiple = false,
    icon = arrowUpDown,
    subviews,
    ...props
  }) => {
    const {disabled} = useFieldContexts(props);

    const iconStyleIntents = [iconStyles];

    return (
      <view.div
        inherits={subviews.wrapper}
        uses={[
          css({stack: 'z'}),
          disabled ? disabledColorStyles : enabledColorStyles,
        ]}
      >
        <NativeSelectControl
          {...props}
          multiple={multiple}
          size={size}
          disabled={disabled}
        />
        {!multiple ? (
          <Icon icon={icon} uses={iconStyleIntents} aria-hidden />
        ) : null}
      </view.div>
    );
  },
);

export const SelectConfig = createViewConfig({
  props: {} as FormFieldProps<NativeSelectProps, typeof NativeSelect>,
  name: 'Select',
  flattens: true,
});

/**
 * @external
 * @param autoComplete
 * @param autoFocus
 * @param defaultValue
 * @param form
 * @param multiple
 * @param name
 * @param required
 * @param children
 */
export const Select = SelectConfig.createView((props) => {
  const [fieldProps, controlProps] = useFormFieldProps(props);
  return (
    <FormField {...fieldProps}>
      <NativeSelect {...controlProps} inherits={props.subviews.input} />
    </FormField>
  );
});
