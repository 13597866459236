import {StyleCache} from './cache';
import {DEFAULT_PREFIX} from './constants';
import {hash} from '../../../util';
import type {SerializedStyles, SerializedRulesets} from './types';

function addIsolatedClasses(
  target: SerializedStyles,
  source: SerializedStyles = target,
) {
  for (let i = 0; i < source.isolated.length; i++) {
    const isolated = StyleCache.get(source.isolated[i]);
    if (isolated) {
      isolated.classes.forEach((c) => target.classes.add(c));
    }
  }
}

export function flattenStyles(styles: SerializedStyles): void {
  addIsolatedClasses(styles);

  const stylesList = [] as SerializedStyles[];
  styles.dependencies.forEach((key) => {
    const dep = StyleCache.get(key);
    if (dep) {
      addIsolatedClasses(styles, dep);
      stylesList.push(dep);
    }
  });
  stylesList.push(styles);

  // Gather all of the rulesets for each layer
  const rulesetsListByLayer = {} as Record<string, SerializedRulesets[]>;
  stylesList.forEach((styles) => {
    Object.entries(styles.layers).forEach(([layer, rulesets]) => {
      const rulesetsList = rulesetsListByLayer[layer];
      if (rulesetsList) {
        rulesetsList.push(rulesets);
      } else {
        rulesetsListByLayer[layer] = [rulesets];
      }
    });
  });

  // For each layer, merge rulesets if necessary
  Object.entries(rulesetsListByLayer).forEach(([layer, rulesetsList]) => {
    if (rulesetsList.length === 1) {
      // There's only one list of rulesets in this layer; reuse it
      const rulesets = rulesetsList[0];
      styles.layers[layer] = rulesets;
      styles.classes.add(rulesets.className);
    } else {
      // There are multiple lists of rulesets in this layer; merge them
      const className = `${DEFAULT_PREFIX}${hash(
        rulesetsList.map(({className}) => className).join(''),
      )}`;
      const mergedRulesets = [] as string[] as SerializedRulesets;
      mergedRulesets.className = className;
      styles.classes.add(className);

      rulesetsList.forEach((rulesets) => {
        const classNameRegExp = new RegExp(rulesets.className, 'g');
        rulesets.forEach((ruleset) => {
          mergedRulesets.push(ruleset.replace(classNameRegExp, className));
        });
      });

      styles.layers[layer] = mergedRulesets;
    }
  });
}
