export function ensure<T>(val?: T | null): T {
  // this is one of my favorite utilites of all time
  if (val === undefined || val === null) {
    throw new Error(`'${String(val)}' value passed to ensure`);
  } else {
    return val;
  }
}

// skip func application if val is undefined
export function maybe<S, T>(
  val: S | null | undefined,
  func: (val: S) => T,
): T | null | undefined {
  if (val === undefined || val === null) {
    // @ts-expect-error - TS2322 - Type 'S | null | undefined' is not assignable to type 'T | null | undefined'.
    return val;
  } else {
    return func(val);
  }
}

// chain func application until undefined or called with no func
export function maybes(val: any) {
  return (func?: (x?: any) => any) => {
    if (func === undefined) {
      return val;
    } else {
      return maybes(maybe(val, func));
    }
  };
}

export function fetchBlobFromFile(filePath: string): Promise<Blob> {
  return fetch(filePath).then((res) => {
    return res.blob();
  });
}

// Returns the input dictionary as JSON with all strings converted to UTF8
export function toJsonUTF8(data: any): string {
  const jsonData = JSON.stringify(data) || '{}';

  // This little magic does UTF8 encoding.  encodeURIComponent converts all chars into UTF8 + escapes some URI values.
  // Then unescape undoes the URI escaping.  This results in just UTF8 encoding being done.
  return unescape(encodeURIComponent(jsonData));
}
