import {createTokens} from '@sail/engine';

const spacingScale = createTokens('space', {
  0: '0px',
  1: '1px',
  25: '2px',
  50: '4px',
  75: '6px',
  100: '8px',
  125: '10px',
  150: '12px',
  175: '14px',
  200: '16px',
  225: '18px',
  250: '20px',
  300: '24px',
  350: '28px',
  400: '32px',
  450: '36px',
  500: '40px',
  600: '48px',
  700: '56px',
  800: '64px',
  900: '72px',
  1000: '80px',
});

export const space = createTokens('space', {
  $default: spacingScale,
  xxsmall: spacingScale[25],
  xsmall: spacingScale[50],
  small: spacingScale[100],
  medium: spacingScale[200],
  large: spacingScale[300],
  xlarge: spacingScale[400],
  xxlarge: spacingScale[600],
});
