import {moreSvg as Icon__small__moreSvg} from '../Icon__small/Icon__small--more.js'
import {moreSvg as Icon__xsmall__moreSvg} from '../Icon__xsmall/Icon__xsmall--more.js'

export var more = {
  name: 'more',
  category: 'icon',
  colorable: true,
  svg: Icon__small__moreSvg,
  alt: {
    Icon__small: Icon__small__moreSvg,
    Icon__xsmall: Icon__xsmall__moreSvg
  },
  combined: ['Icon__small', 'Icon__xsmall']
};
