import * as React from 'react';

import isFieldNeeded from 'gelato/frontend/src/controllers/utils/isFieldNeeded';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const {useEffect} = React;

/**
 * This hook handles the automatic redirection to the next page in the flow if
 * the Welcome page should be skipped.
 * @returns undefined if the session is not available yet, true if the welcome
 *   page is skipped, false otherwise.
 */
export default function useAppRedirectIfWelcomePageSkipped():
  | boolean
  | undefined {
  const {appState, appController} = useAppController();
  const {session} = appState;
  // In handoff mode, we don't need to show the welcome page again unless the
  // consent is still needed.
  const skipWelcomePage =
    session &&
    session.operatingMode === 'secondary' &&
    !isFieldNeeded(appState, 'consent');

  const redirecting = React.useRef(false);

  useEffect(() => {
    if (redirecting.current) {
      return;
    }
    if (skipWelcomePage) {
      appController.routeToNextPage({
        reason: 'leave_welcome',
        redirect: true,
        caller: 'useAppRedirectIfWelcomePageSkipped',
      });
      redirecting.current = true;
    }
  }, [appController, skipWelcomePage]);

  return session ? !!skipWelcomePage : undefined;
}
