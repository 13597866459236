import clsx from 'clsx';
import Color from 'color';
import * as React from 'react';

import {
  DEFAULT_BRANDING_PLATFORM_COLOR,
  DEFAULT_BRANDING_BUTTON_COLOR,
} from 'gelato/frontend/src/lib/constants';
import {
  useConnectIframe,
  useBranding,
  usePageLayoutPortrait,
} from 'gelato/frontend/src/lib/hooks';
import {stripeColors} from 'gelato/frontend/src/lib/stripes';

import styles from './Background.module.css';

export function usePageBackgroundColor(buttonColor: string): string {
  return React.useMemo<string>(() => {
    const color = Color(buttonColor);
    const [h, s] = color.hsl().array();
    // Always sets the lightness value to 97.
    return Color.hsl(h, s, 97).toString();
  }, [buttonColor]);
}

export type Props = {
  // the platformIcon and platformName props are used to provide branding
  // settings directly to this component in contexts where branding is not
  // available via graphql in the useBranding hook. e.g. in the no-code flows
  // this component is rendered on some pages before authentication has occurred
  // and so before there is a VerificationSession to provide branding.
  platformColor?: string;
  buttonColor?: string;
};

export default function Background(props: Props) {
  const {
    platformColor: injectedPlatformColor,
    buttonColor: injectedButtonColor,
  } = props;
  const branding = useBranding();

  const brandingPlatformColor =
    branding?.platformColor || DEFAULT_BRANDING_PLATFORM_COLOR;

  const brandingButtonColor =
    branding?.buttonColor || DEFAULT_BRANDING_BUTTON_COLOR;

  const platformColor = injectedPlatformColor || brandingPlatformColor;
  const buttonColor = injectedButtonColor || brandingButtonColor;

  const pageLayoutPortraitEnabled = usePageLayoutPortrait();

  const {lightened, darkened} = React.useMemo(
    () => stripeColors(platformColor),
    [platformColor],
  );
  const pageBackgroundColor = usePageBackgroundColor(buttonColor);

  const isConnectIframe = useConnectIframe();

  if (isConnectIframe) {
    return <></>;
  }

  if (pageLayoutPortraitEnabled) {
    const style = {
      '--page-background-color': pageBackgroundColor,
    } as React.CSSProperties;
    return <div className={styles.Background} style={style} />;
  }

  return (
    <div className={styles.gridWrapper}>
      <div className={styles.gridContainer}>
        <div className={styles.grid} style={{backgroundColor: platformColor}}>
          <div
            className={styles.gridBackground}
            style={{
              backgroundColor: platformColor,
            }}
          />
          <div
            className={clsx(styles.stripe, styles.stripe1)}
            style={{
              backgroundColor: lightened.rgb().toString(),
            }}
          />
          <div
            className={clsx(styles.stripe, styles.stripe2)}
            style={{
              backgroundColor: darkened.rgb().toString(),
            }}
          />
          <div
            className={clsx(styles.stripe, styles.stripe3)}
            style={{
              backgroundColor: lightened.rgb().toString(),
            }}
          />
          <div className={clsx(styles.ghostStripe, styles.ghostStripe1)} />
          <div className={clsx(styles.ghostStripe, styles.ghostStripe2)} />
        </div>
        <div className={styles.backgroundAlt} />
      </div>
    </div>
  );
}
