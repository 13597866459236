import {createCustomProperty} from '@sail/engine';
import type {Style} from '@sail/engine';
import {tokens} from '../../tokens';
import {universalSelectorDisabled} from '../utils';

type Axis = 'x' | 'y' | 'z';

const alignXProp = createCustomProperty('--align-x', {
  syntax: 'flex-start | flex-end | center | stretch',
  initialValue: 'stretch',
});
const alignYProp = createCustomProperty('--align-y', {
  syntax: 'flex-start | flex-end | center | stretch | baseline',
  initialValue: 'baseline',
});
const alignSelfDisplay = createCustomProperty('--align-self-display', {
  syntax: 'auto | flex-start | flex-end | center | stretch | baseline',
  initialValue: 'auto',
});
const alignSelfXProp = createCustomProperty('--align-self-x', {
  syntax: 'auto | flex-start | flex-end | center | stretch',
  initialValue: 'auto',
});
const alignSelfYProp = createCustomProperty('--align-self-y', {
  syntax: 'auto | flex-start | flex-end | center | stretch | baseline',
  initialValue: 'auto',
});
const flexX = createCustomProperty('--flex-x', {
  initialValue: '1 0 0',
});
const flexY = createCustomProperty('--flex-y', {
  initialValue: '1 0 auto',
});
const flexBasisY = createCustomProperty('--flex-basis-y', {
  initialValue: 'auto',
});
export const columnGap = createCustomProperty('--column-gap', {
  initialValue: '0px',
});
export const rowGap = createCustomProperty('--row-gap', {
  initialValue: '0px',
});

// TODO(koop): Update this to not rely on 'initial'
const distributeProp = createCustomProperty('--distribute');

const displayBlockProp = createCustomProperty('--display-block', {
  initialValue: 'block',
});
const displayInlineProp = createCustomProperty('--display-inline', {
  initialValue: 'inline',
});
// Font plugins require extra properties:
export const baselineAlignmentContent = createCustomProperty(
  '--baseline-alignment-content',
  {initialValue: 'none'},
);
export const objectHeight = createCustomProperty('--object-height', {
  initialValue: 'initial',
});

export function setBaselinePseudoelement(
  set: Style.PluginAPI,
  active: boolean,
): void {
  // Use a pseudo element with an unselectable zero-width-space character at
  // the beginning of the node to ensure that the browser sets the desired
  // baseline. This normalizes cases that don’t begin with a text node (e.g.
  // no text child nodes or a child node with different text styles).
  //
  // Establishing the baseline is important for both aligning the node’s
  // children and also aligning the node within its parent layout.
  const zeroWidthSpace = '"\\200B"';

  set.selector('&::before', () => {
    set.property(baselineAlignmentContent, active ? zeroWidthSpace : 'none');
  });
}

export const hasBaselineLayout = createCustomProperty('--baseline-multiplier', {
  initialValue: '1',
});
export function setHasBaselineLayout(
  set: Style.PluginAPI,
  active: boolean,
): void {
  set.property(hasBaselineLayout, active ? '1' : '0');
}

const flexAlignXMap = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  stretch: 'stretch',
} as const;

const flexAlignYMap = {
  top: 'flex-start',
  center: 'center',
  baseline: 'baseline',
  bottom: 'flex-end',
  stretch: 'stretch',
} as const;

function distribute(value: string, set: Style.PluginAPI): void {
  set.property('--distribute', () => {
    if (value === 'packed') {
      set.property(distributeProp, 'initial');
    } else if (value === 'space-between') {
      set.property(distributeProp, 'space-between');

      // Disable text aligner
      setBaselinePseudoelement(set, false);

      if (!universalSelectorDisabled) {
        set.selector('& > *', () => {
          set.property(flexX, '0 1 auto');
          set.property(flexY, '0 1 auto');
        });
      }
    }
  });

  // otherwise, use the default browser styling (typically stretch)
}

function wrap(value: string, set: Style.PluginAPI): void {
  set.property('flexWrap', value);
}

type SizeKey =
  | 'width'
  | 'height'
  | 'minWidth'
  | 'minHeight'
  | 'maxWidth'
  | 'maxHeight';

/**
 * A map of size values (variables) to their corresponding token keys.
 * e.g. `{ 'var(--size-2/5-1j1r695)': '1/2' }`.
 *
 * This map is used to determine if a fractional size needs to be adjusted
 * to account for the current flex gap.
 */
const sizeMap = Object.fromEntries(
  Object.keys(tokens.size).map((key) => [
    tokens.size[key as keyof typeof tokens.size].toString(),
    key,
  ]),
);

/**
 * Calculates if a fractional size needs to be adjusted by the current flex gap
 * and returns an adjusted size value.
 */
function applySizeOffset(set: Style.PluginAPI, value: string, axis: Axis) {
  let size = value;

  // match the size value to it's corresponding token key
  // if the token represents a fractional size.
  if (value in sizeMap && sizeMap[value].includes('/')) {
    size = sizeMap[value];
  }

  const [, numerator, denominator] = (
    `${size}`.match(/(\d+)\\?\/(\d+)/) || []
  ).map(Number);

  // if size is a percentage, return a calculated value that reduces
  // the size proportionally to account for gaps applied on that axis.
  if (numerator && denominator) {
    const percentage = Number((numerator / denominator) * 100).toFixed(6);
    const proportionalGap = (denominator - numerator) / denominator;

    const gapVar = axis === 'x' ? set.var(columnGap) : set.var(rowGap);
    size = `calc(${percentage}% - (${gapVar} * ${proportionalGap}))`;
  }

  return size;
}

const fill = tokens.size.fill.toString();
const fit = tokens.size.fit.toString();

function getSizeFlex(
  set: Style.PluginAPI,
  value: string,
  axis: Exclude<Axis, 'z'>,
) {
  switch (value) {
    case fill:
      return '1 1 auto';
    case fit:
      return '0 1 auto';
    case 'auto':
    default:
      return axis === 'y' ? `1 0 ${set.var(flexBasisY)}` : '1 0 0';
  }
}

function createSizePlugin(
  property: SizeKey,
  axis: Exclude<Axis, 'z'>,
): Style.Plugin {
  return function sizePlugin(value, set) {
    // Unlike other size values, fill and auto should continue to flex in size with the stack.
    // NOTE: 'auto' is NOT a token - it's a css value for length properties.
    const hasFlexBehavior = ['auto', fill, fit].includes(value);
    const flexAxis = axis === 'x' ? flexX : flexY;
    set.property(property, () => {
      let size = '';

      if (hasFlexBehavior) {
        set.property(property, value);

        // restore flex stack "stretch" behavior for height/width sizes of auto and fill
        // (we need to set this, even though it's the default, to ensure styles are merged correctly)
        if (property === 'height' || property === 'width') {
          set.property(flexAxis, getSizeFlex(set, value, axis));
        }
      } else {
        // for fractional values (e.g. `1/3`), we adjust the calculated size based on any applied gap.
        size = applySizeOffset(set, value, axis);
        set.property(property, size);

        // setting height or width disables the "stretch" behavior on the current item in the stack
        // (but setting other properties (e.g `maxWidth`) shouldn't change the flex behavior)
        if (property === 'height' || property === 'width') {
          set.property(flexAxis, '0 0 auto');
        }
      }

      if (property === 'height') {
        set.property(objectHeight, size ?? 'initial');

        if (!universalSelectorDisabled) {
          // If a stack has "auto" height, then its children should be allowed to
          // exceed its bounds. Set flex-basis to auto to ensure that the node
          // doesn't default to a height of 0.
          set.selector('& > *', () => {
            set.property(flexBasisY, hasFlexBehavior ? 'auto' : '0');
          });
        }
      }
    });
  };
}

function alignX(value: string, set: Style.PluginAPI): void {
  if (value === 'undefined') {
    return;
  }

  if (!(value in flexAlignXMap)) {
    throw new Error(`Invalid value for alignX "${value}"`);
  }
  const flexValue = flexAlignXMap[value as keyof typeof flexAlignXMap];
  set.property('--align-x', () => {
    set.property(alignXProp, flexValue);

    if (!universalSelectorDisabled) {
      set.selector('& > *', () => {
        set.property(alignSelfXProp, flexValue);
        // set.property(alignXRef, flexValue);

        // any value other than stretch disables default flex grow behavior
        if (flexValue !== 'stretch') {
          set.property(flexX, '0 1 auto');
        }
      });
    }
  });
}

function alignY(value: string, set: Style.PluginAPI): void {
  if (value === 'undefined') {
    return;
  }

  if (!(value in flexAlignYMap)) {
    throw new Error(`Invalid value for alignY "${value}"`);
  }
  const flexValue = flexAlignYMap[value as keyof typeof flexAlignYMap];
  // If this plugin changes, change the corresponding lines in the stack: 'x' code
  set.property('--align-y', () => {
    set.property(alignYProp, flexValue);

    if (!universalSelectorDisabled) {
      set.selector('& > *', () => {
        const selfValue =
          flexValue === 'stretch' ? set.var(alignSelfDisplay) : flexValue;
        set.property(alignSelfYProp, selfValue);

        setHasBaselineLayout(set, flexValue === 'baseline');
        // set.property(
        //   '--baseline-multiplier',
        //   flexValue === 'baseline' ? '1' : '0',
        // );

        // any value other than stretch disables default flex grow behavior
        if (flexValue !== 'stretch') {
          set.property(flexY, '0 1 auto');
        }
      });
    }
  });
}

function alignSelfX(value: string, set: Style.PluginAPI): void {
  if (value === 'undefined') {
    return;
  }

  if (!(value in flexAlignXMap)) {
    throw new Error(`Invalid value for alignSelfX "${value}"`);
  }
  const flexValue = flexAlignXMap[value as keyof typeof flexAlignXMap];
  set.property(alignSelfXProp, flexValue);
}

function alignSelfY(value: string, set: Style.PluginAPI): void {
  if (value === 'undefined') {
    return;
  }

  if (!(value in flexAlignYMap)) {
    throw new Error(`Invalid value for alignSelfY "${value}"`);
  }
  set.property(alignSelfYProp, () => {
    const flexValue = flexAlignYMap[value as keyof typeof flexAlignYMap];
    set.property(alignSelfYProp, flexValue);
    setHasBaselineLayout(set, flexValue === 'baseline');
  });

  // TODO(koop): If we add 'auto' as a valid value, skip setting baseline
  // alignment for that case.

  // set.property('--baseline-multiplier', flexValue === 'baseline' ? '1' : '0');
}

const displayInsideMap = {
  flex: ['flex', 'inline-flex'],
  flow: ['block', 'inline'],
  'flow-root': ['flow-root', 'inline-block'],
  grid: ['grid', 'inline-grid'],
  table: ['table', 'inline-table'],
};

function displayInside(value: string, set: Style.PluginAPI): void {
  const values = displayInsideMap[value as keyof typeof displayInsideMap];
  if (!values) {
    return;
  }
  const [block, inline] = values;
  set.property('--display-inside', () => {
    // This needs to be on the reset layer to avoid overriding any global layer
    // styles that use the 'display' property.
    set.reset({display: set.var(displayBlockProp)});
    set.property(displayBlockProp, block);
    set.property(displayInlineProp, inline);

    if (!universalSelectorDisabled) {
      const isFlow = value === 'flow' || value === 'flow-root';
      if (isFlow) {
        set.selector('& > *', () => {
          setHasBaselineLayout(set, true);
        });
      }
    }
  });
}

function isFlowDisplay(value: string): boolean {
  switch (value) {
    case 'inline-block':
    case 'flow-root':
      return true;
    default:
      return false;
  }
}

function display(value: string, set: Style.PluginAPI): void {
  set.property('--display', () => {
    if (value === 'block' || value === 'inline') {
      const isBlock = value === 'block';
      set.property(
        'display',
        isBlock ? set.var(displayBlockProp) : set.var(displayInlineProp),
      );

      // Ensure that inline items won't stretch to fill a stack's cross-axis
      // by default.
      set.property(alignSelfDisplay, isBlock ? 'auto' : 'flex-start');

      if (!isBlock) {
        set.property(flexX, '0 1 auto');
        set.property(flexY, '0 1 auto');
      }
    } else {
      set.property('--display-inside', undefined);
      set.property('display', value);

      if (!universalSelectorDisabled) {
        set.selector('& > *', () => {
          setHasBaselineLayout(set, isFlowDisplay(value));
        });
      }
    }
  });
}

function stackXY(set: Style.PluginAPI): void {
  // we want items in a stack to distribute their space evenly,
  // but we don't want to collapse SVG icons below their intrinsic size
  // so disable flex growing/shrinking on SVGs specifically.
  set.selector('& > svg', () => {
    set.property(flexX, '0 0 auto');
    set.property(flexY, '0 0 auto');
  });
}

function stack(value: string, set: Style.PluginAPI): void {
  set.property('--stack', () => {
    switch (value) {
      case 'x':
        set.property('displayInside', 'flex');
        set.property('flexDirection', 'row');
        set.property('alignItems', set.var(alignYProp));
        set.property(
          'justifyContent',
          set.var(distributeProp, set.var(alignXProp)),
        );
        setBaselinePseudoelement(set, true);

        if (!universalSelectorDisabled) {
          set.selector('& > *', () => {
            set.property('flex', set.var(flexX));
            set.property(alignSelfYProp, 'auto');
            set.property('alignSelf', set.var(alignSelfYProp));
            setHasBaselineLayout(set, true);
            // These values needs to be kept in sync with the result of set.property('alignY', 'baseline')
            // set.reset({'--baseline-multiplier': '1'});
            // set.reset({'--flex-y': 'initial'});
          });
        }
        stackXY(set);

        break;
      case 'y':
        set.property('displayInside', 'flex');
        set.property('flexDirection', 'column');
        set.property('alignItems', set.var(alignXProp));
        set.property(
          'justifyContent',
          set.var(distributeProp, set.var(alignYProp)),
        );

        if (!universalSelectorDisabled) {
          set.selector('& > *', () => {
            set.property('flex', set.var(flexY));
            set.property(alignSelfXProp, set.var(alignSelfDisplay));
            set.property('alignSelf', set.var(alignSelfXProp));
            setHasBaselineLayout(set, false);
          });
        }
        stackXY(set);

        break;
      case 'z':
        set.property('displayInside', 'grid');
        set.property('alignItems', set.var(alignYProp));
        set.property('justifyItems', set.var(alignXProp));

        if (!universalSelectorDisabled) {
          set.selector('& > *, &::before, &::after', () => {
            set.property('alignSelf', set.var(alignSelfYProp));
            set.property('justifySelf', set.var(alignSelfXProp));
            set.property('gridColumn', '1 / auto');
            set.property('gridRow', '1 / auto');
          });
        }
        break;
      case null:
      case undefined:
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn('Unexpected value for stack');
        break;
    }
  });
}

function minTileWidth(value: string, set: Style.PluginAPI): void {
  const trackSize = applySizeOffset(set, value, 'x');
  set.reset({
    '--col-repeat': 'initial',
    gridTemplateColumns:
      'repeat(var(--col-repeat, auto-fill), var(--col-width))',
  });

  if (!universalSelectorDisabled) {
    set.selector('& > *', () => {
      set.reset({
        alignSelf: set.var(alignSelfYProp),
        justifySelf: set.var(alignSelfXProp),
      });
    });
  }

  set.property('display', 'grid');
  set.property('--col-width', `minmax(min(${trackSize}, 100%), 1fr)`);
}

function gridColumns(value: string, set: Style.PluginAPI): void {
  set.property('--col-repeat', value);
}

function gap(value: string, set: Style.PluginAPI): void {
  // TODO(koop): Need to somehow add a modifier to transform the tree when gap
  // is unsupported.

  set.property('rowGap', undefined);
  set.property('columnGap', undefined);

  set.property('gap', () => {
    set.property('gap', value);
    if (!universalSelectorDisabled) {
      set.selector('& > *, &::before, &::after', () => {
        set.property(rowGap, value);
        set.property(columnGap, value);
      });
    }
  });
}

function gapX(value: string, set: Style.PluginAPI): void {
  set.property('columnGap', () => {
    set.property('columnGap', value);
    if (!universalSelectorDisabled) {
      set.selector('& > *, &::before, &::after', () => {
        set.property(columnGap, value);
      });
    }
  });
}

function gapY(value: string, set: Style.PluginAPI): void {
  set.property('rowGap', () => {
    set.property('rowGap', value);
    if (!universalSelectorDisabled) {
      set.selector('& > *, &::before, &::after', () => {
        set.property(rowGap, value);
      });
    }
  });
}

export const pluginsLayout = {
  alignSelfX,
  alignSelfY,
  alignX,
  alignY,
  display,
  displayInside,
  gap,
  gapX,
  gapY,
  stack,
  distribute,
  wrap,
  width: createSizePlugin('width', 'x'),
  minWidth: createSizePlugin('minWidth', 'x'),
  maxWidth: createSizePlugin('maxWidth', 'x'),
  height: createSizePlugin('height', 'y'),
  minHeight: createSizePlugin('minHeight', 'y'),
  maxHeight: createSizePlugin('maxHeight', 'y'),
  minTileWidth,
  gridColumns,
};
