import {view, css} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import BottomSheet from 'gelato/frontend/src/components/BottomSheetV2';
import Button from 'gelato/frontend/src/components/ButtonV2';
import Reasons from 'gelato/frontend/src/components/Link/DocumentSelection/Document/InvalidReasonsSheet/Reasons';
import Message from 'gelato/frontend/src/components/Message';
import {bgOffset} from 'gelato/frontend/src/components/stylesV2';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const messages = defineMessages({
  savedIdAbout: {
    defaultMessage: 'ID is not accepted',
    description: 'Header for more information on saved ID',
    id: 'verification.documentSelectSheet.savedIdAbout',
  },
  savedIdDescription: {
    defaultMessage:
      'Although this ID was previously verified and saved to Link, {merchant} has specific ID requirements. The selected ID is not accepted because:',
    description: 'Explanation of ID refusal',
    id: 'verification.documentSelectSheet.savedIdDescription',
  },
  selectValidId: {
    defaultMessage: 'Select a valid ID to continue.',
    description: 'Call to action for rectifying invalid ID',
    id: 'verification.documentSelectSheet.selectValidId',
  },
  close: {
    defaultMessage: 'Back',
    description: 'Button text for closing sheet',
    id: 'verification.documentSelectSheet.close',
  },
});

const Styles = {
  contentContainer: css({
    stack: 'y',
    gap: 'small',
  }),
  content: css({
    font: 'body.small',
  }),
  section: css({
    font: 'heading.large',
  }),
  reason: css({
    padding: 'medium',
  }),
};

function InvalidReasonsSheet(): JSX.Element {
  const {
    appController,
    appState: {
      networkedIdentity: {consumerDocuments, selectedInvalidDocumentId},
      session,
    },
  } = useAppController();

  const handleClose = () => {
    appController.handleClearInvalidIdentityDocument();
  };

  const selectedInvalidDocument = React.useMemo(() => {
    return consumerDocuments.find(
      (doc) => doc.id === selectedInvalidDocumentId,
    );
  }, [consumerDocuments, selectedInvalidDocumentId]);

  return (
    <BottomSheet data-testid="invalid-reasons-sheet">
      <view.div uses={[Styles.contentContainer]}>
        <view.div uses={[Styles.section]}>
          <Message {...messages.savedIdAbout} />
        </view.div>
        <view.div uses={[Styles.content]}>
          <Message
            {...messages.savedIdDescription}
            values={{
              merchant: session?.branding.platformName,
            }}
          />
        </view.div>
        <view.div
          uses={[bgOffset, Styles.reason, Styles.content]}
          css={{marginBottom: 'medium'}}
        >
          {selectedInvalidDocument && (
            <Reasons selectedInvalidDocument={selectedInvalidDocument} />
          )}
        </view.div>
        <view.div>
          <Button type="primary" onPress={handleClose}>
            <Message {...messages.close} />
          </Button>
        </view.div>
      </view.div>
    </BottomSheet>
  );
}

export default InvalidReasonsSheet;
