import {arrowUpDownSvg as Icon__small__arrowUpDownSvg} from '../Icon__small/Icon__small--arrowUpDown.js'
import {arrowUpDownSvg as Icon__xsmall__arrowUpDownSvg} from '../Icon__xsmall/Icon__xsmall--arrowUpDown.js'

export var arrowUpDown = {
  name: 'arrowUpDown',
  category: 'icon',
  colorable: true,
  svg: Icon__small__arrowUpDownSvg,
  alt: {
    Icon__small: Icon__small__arrowUpDownSvg,
    Icon__xsmall: Icon__xsmall__arrowUpDownSvg
  },
  combined: ['Icon__small', 'Icon__xsmall']
};
