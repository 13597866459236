import {css, view} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import ScrollableArea from 'gelato/frontend/src/components/ScrollableArea';

type Props = {
  // The required testing ID for the bottom sheet. It can be used to locate the
  // bottom sheet for testing or analytics purposes.
  // To make the ID more distinguishable, it should end with '-sheet'.
  'data-testid': `${string}-sheet`;

  // The scrollable content of the bottom sheet.
  children?: React.ReactNode | null | undefined;
  // The footer of the bottom sheet. This would typically contain a button to
  // close the bottom sheet.
  footer?: React.ReactNode | null;
  bottomSheetStyles?: ReturnType<typeof css>;
  footerStyles?: ReturnType<typeof css>;
};

/**
 * Shareable messages for the bottom sheet.
 */
export const Messages = defineMessages({
  close: {
    defaultMessage: 'Back',
    description: 'Button label for closing the bottom sheet',
    id: 'verification.buttonSheet.close',
  },
});

// The CSS variable `--page-card-offset-height` is provided by the containing
// page (i.e. <PageCard />) component.
export const Styles = {
  bottomSheet: css({
    alignY: 'top',
    backgroundColor: 'surface',
    borderRadius: '10px 10px 0 0',
    maxHeight: 'calc(var(--page-card-offset-height) - 20px)',
    position: 'relative',
    stack: 'y',
    paddingTop: 'space.500',
  }),
  body: css({
    boxSizing: 'border-box',
    font: 'body.small',
  }),
  bodyInset: css({
    paddingX: 'large',
  }),
  footer: css({
    paddingBottom: 'large',
    paddingTop: 'large',
    paddingX: 'large',
  }),
};

// TODOs:
// - Auto focus on the first focusable element in the bottom sheet.

/**
 * The UI element that appears as a sheet from the bottom of a app. The bottom
 * sheet typically slides up from the bottom of the screen, partially covering
 * the main content, and provides a focused context for user interaction.
 * It often contains a set of actionable buttons, controls, or a list of
 * choices.
 */
export default function BottomSheet(props: Props): JSX.Element {
  const {
    'data-testid': testId,
    children,
    footer,
    footerStyles,
    bottomSheetStyles,
  } = props;

  const content = children ? (
    <ScrollableArea uses={[Styles.body]}>
      <view.div uses={[Styles.bodyInset]}>{children}</view.div>
    </ScrollableArea>
  ) : null;

  return (
    <view.div
      data-testid={testId}
      uses={[Styles.bottomSheet, bottomSheetStyles]}
    >
      {content}
      {footer && (
        <view.div uses={[Styles.footer, footerStyles]}>{footer}</view.div>
      )}
    </view.div>
  );
}
