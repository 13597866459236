import * as React from 'react';

const getMatches = (query: string): boolean => {
  return window.matchMedia(query).matches;
};

const supportsMatchMedia =
  typeof window !== 'undefined' && typeof window?.matchMedia === 'function';

const useMatchMedia = (query: string): boolean => {
  const [matches, setMatches] = React.useState<boolean>(
    supportsMatchMedia ? getMatches(query) : false,
  );

  React.useEffect(() => {
    if (!supportsMatchMedia) {
      return;
    }

    const handleChange = () => {
      setMatches(getMatches(query));
    };

    // Triggered at the first client-side load and if query changes
    handleChange();

    const mediaQueryList = window.matchMedia(query);

    // addListener/removeListener is deprecated, but older browsers may use it
    if (mediaQueryList.addListener) {
      mediaQueryList.addListener(handleChange);
      return () => mediaQueryList.removeListener(handleChange);
    }

    mediaQueryList.addEventListener('change', handleChange);
    return () => mediaQueryList.removeEventListener('change', handleChange);
  }, [query]);

  return matches;
};

export const useMediaMaxWidth = (viewport: string) => {
  return useMatchMedia(`(max-width: ${viewport})`);
};

export const anyCoarsePointerCheck = () => {
  let isMobile = false;
  if (
    typeof window !== 'undefined' &&
    typeof window.matchMedia === 'function'
  ) {
    const mediaList = window.matchMedia('(any-pointer: coarse)');
    isMobile = mediaList.matches;
  }
  return isMobile;
};

export const smallScreenCheck = (viewport: string) => {
  let isMobile = false;
  const query = `(max-width: ${viewport})`;
  if (
    typeof window !== 'undefined' &&
    typeof window.matchMedia === 'function'
  ) {
    const mediaList = window.matchMedia(query);
    isMobile = mediaList.matches;
  }
  return isMobile;
};
