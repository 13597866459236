import {view, css} from '@sail/ui';
import {DocumentTypes} from '@stripe-internal/data-gelato/schema/types';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import BottomSheet from 'gelato/frontend/src/components/BottomSheetV2';
import LinkButton from 'gelato/frontend/src/components/Link/LinkButton';
import Message from 'gelato/frontend/src/components/Message';
import {bgOffset} from 'gelato/frontend/src/components/stylesV2';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import genericMessages from 'gelato/frontend/src/messages';

const messages = defineMessages({
  idNotAccepted: {
    defaultMessage: 'ID not accepted',
    description: 'Header for more information on saved ID',
    id: 'verification.documentSelectSheet.notAccepted',
  },
  idExpired: {
    defaultMessage: 'ID expired',
    description: 'Header for ID expired',
    id: 'verification.documentSelectSheet.idExpired',
  },
  selectAnotherId: {
    defaultMessage: 'Select another ID to continue.',
    description: 'Content for CTA',
    id: 'verification.documentSelectSheet.selectAnotherId',
  },
  idExpiring: {
    defaultMessage: 'ID expiring',
    description: 'Header for ID expiring',
    id: 'verification.documentSelectSheet.idExpiring',
  },
  acceptedIDTypes: {
    defaultMessage:
      'Although this ID was previously verified and saved to Link, {merchant} has specific ID requirements. Here are the accepted IDs:',
    description: 'Explanation of ID refusal',
    id: 'verification.documentSelectSheet.acceptedIDTypes',
  },
  liveCaptureRequired: {
    defaultMessage: '{merchant} requires a live camera capture of the ID.',
    description: 'Live capture required description',
    id: 'verification.documentSelectSheet.liveCaptureRequired',
  },
  selectValidId: {
    defaultMessage: 'Select a valid ID to continue.',
    description: 'Call to action for rectifying invalid ID',
    id: 'verification.documentSelectSheet.selectValidId',
  },
  close: {
    defaultMessage: 'Back',
    description: 'Button text for closing sheet',
    id: 'verification.documentSelectSheet.close',
  },
});

const Styles = {
  bottomSheetStyles: css({
    paddingY: 'large',
    gap: 'none',
  }),
  contentContainer: css({
    stack: 'y',
    gap: 'small',
  }),
  content: css({
    font: 'body.small',
  }),
  section: css({
    font: 'heading.large',
  }),
  reason: css({
    padding: 'medium',
  }),
};

function InvalidReasonsSheet(): JSX.Element {
  const {
    appController,
    appState: {
      networkedIdentity: {consumerDocuments, selectedInvalidDocumentId},
      session,
    },
  } = useAppController();

  const handleClose = () => {
    appController.handleClearInvalidIdentityDocument();
  };

  const selectedInvalidDocument = React.useMemo(() => {
    return consumerDocuments.find(
      (doc) => doc.id === selectedInvalidDocumentId,
    );
  }, [consumerDocuments, selectedInvalidDocumentId]);

  if (!selectedInvalidDocument) {
    return <></>;
  }

  const {isNotAllowedDocType, isExpired, isNotLiveCaptured} =
    selectedInvalidDocument?.invalidUseReasons;

  return (
    <BottomSheet
      data-testid="invalid-reasons-sheet"
      bottomSheetStyles={Styles.bottomSheetStyles}
    >
      <view.div uses={[Styles.contentContainer]}>
        {(isNotAllowedDocType && (
          <IDNotAccepted
            platformName={session?.branding.platformName}
            acceptedIDTypes={session?.documentTypeAllowlist}
          />
        )) ||
          (isExpired && <IDExpired />) ||
          (isNotLiveCaptured && (
            <NotLiveCaptured platformName={session?.branding.platformName} />
          ))}
        <view.div>
          <LinkButton onPress={handleClose}>
            <Message {...messages.close} />
          </LinkButton>
        </view.div>
      </view.div>
    </BottomSheet>
  );
}

const idTypeToMessageMap = {
  passport: genericMessages.passport,
  driving_license: genericMessages.driversLicense,
  id_card: genericMessages.idCard,
};

type IDNotAcceptedProps = {
  platformName: string | undefined;
  acceptedIDTypes: readonly DocumentTypes[] | undefined | null;
};

function IDNotAccepted({platformName, acceptedIDTypes}: IDNotAcceptedProps) {
  return (
    <>
      <view.div uses={[Styles.section]}>
        <Message {...messages.idNotAccepted} />
      </view.div>
      <view.div uses={[Styles.content]}>
        <Message
          {...messages.acceptedIDTypes}
          values={{
            merchant: platformName,
          }}
        />
      </view.div>
      <view.div
        uses={[bgOffset, Styles.reason, Styles.content]}
        css={{marginBottom: 'medium'}}
      >
        {acceptedIDTypes?.map((idType) => {
          return (
            <view.div key={idType}>
              <view.span>
                {acceptedIDTypes?.length > 1 && '• '}
                <Message {...idTypeToMessageMap[idType]} />
              </view.span>
            </view.div>
          );
        })}
      </view.div>
    </>
  );
}

function IDExpired() {
  return (
    <>
      <view.div uses={[Styles.section]}>
        <Message {...messages.idExpired} />
      </view.div>
      <view.div uses={[Styles.content]}>
        <Message {...messages.selectAnotherId} />
      </view.div>
    </>
  );
}

function IDExpiring() {
  return (
    <>
      <view.div uses={[Styles.section]}>
        <Message {...messages.idExpiring} />
      </view.div>
      <view.div uses={[Styles.content]}>
        <Message {...messages.selectAnotherId} />
      </view.div>
    </>
  );
}

type NotLivedCapturedProps = {
  platformName: string | undefined;
};

function NotLiveCaptured({platformName}: NotLivedCapturedProps) {
  return (
    <>
      <view.div uses={[Styles.section]}>
        <Message {...messages.idNotAccepted} />
      </view.div>
      <view.div uses={[Styles.content]}>
        <Message
          {...messages.liveCaptureRequired}
          values={{
            merchant: platformName,
          }}
        />
      </view.div>
    </>
  );
}

export default InvalidReasonsSheet;
