import {hsluvToHex} from 'hsluv-ts';

function toLinear(c: number) {
  return c > 0.04045 ? ((c + 0.055) / 1.055) ** 2.4 : c / 12.92;
}

function sRGBToXYZ([r, g, b]: number[]): number[] {
  // Convert gamma-corrected sRGB to linear RGB
  const R = toLinear(r);
  const G = toLinear(g);
  const B = toLinear(b);

  // Apply transformation matrix
  const X =
    R * 41.23865632529916 + G * 35.75914909206253 + B * 18.045049120356364;
  const Y =
    R * 21.26368216773238 + G * 71.51829818412506 + B * 7.218019648142546;
  const Z =
    R * 1.9330620152483982 + G * 11.919716364020843 + B * 95.03725870054352;

  // Create and return XYZ object
  return [X, Y, Z];
}

function fromHex(hexCode: string): number[] {
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$$/.test(hexCode)) {
    throw new Error('Bad Input: Must be of form "#FFF" or "#FFFFFF"');
  }
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  return [
    parseInt(hex.substring(0, 2), 16) / 0xff, // R
    parseInt(hex.substring(2, 4), 16) / 0xff, // G
    parseInt(hex.substring(4, 6), 16) / 0xff, // B
  ];
}

function convertXYZ_YtoHSLuv_L(Y: number) {
  return (Y > 0.008856 ? Y ** (1 / 3) : 7.787 * Y + 16 / 116) * 116 - 16;
}

function calculateXYZ_YfromContrastRatio(Y: number, R: number) {
  return Y > 0.1841 ? (Y + 0.05) / R - 0.05 : (R * Y + 0.05 * R - 0.05) / 1;
}

/**
 * Arithmetic version of the WCAG luminosity-based contrast ratio
 */
const wcagLuminosity = (i: number) => Math.exp(3.04 * i);

/**
 * Quartic ease-out interpolation
 */
const easeOutQuart = (i: number) => 1 - (1 - i) ** 4;

/**
 * Linear interpolation (identity function)
 */
const linearInterpolation = (i: number) => i;

/**
 * Generates a color at the given step in a color scale. Returns a color in hex format.
 */
export function generateColorStep(
  /**
   * The step to compute. The step should be a number between 0 and 1000.
   * When comparing two steps, a difference of 500 or more means that those
   * colors will target WCAG AA guidance for all text. A difference of 400 or more
   * means that those colors will target WCAG AA guidance for large text.
   */
  step: number,
  /**
   * Options to use for the color step generation. Each step in a color scale
   * should use the same options.
   */
  options: {
    startHue: number;
    endHue: number;
    /**
     * The minimum chroma value to use. Defaults to 0.
     */
    minChroma?: number;
    /**
     * The maximum chroma value to use. Defaults to 100.
     */
    maxChroma?: number;
    /**
     * The background color to compute contrast ratios against. Defaults to
     * white (#ffffff).
     */
    backgroundColor?: string;
    /**
     * Interpolation functions for hue. Defaults to linear interpolation.
     */
    hueFunction?: (step: number) => number;
    /**
     * Interpolation function for chroma. Defaults to quartic interpolation
     * with min chroma at step 0 and quickly ramping up to max chroma.
     */
    chromaFunction?: (step: number) => number;
    /**
     * Interpolation function for contrast. Defaults to arithmetic version of the
     * WCAG luminosity-based contrast ratio.
     */
    contrastFunction?: (step: number) => number;
  },
): string {
  const {
    startHue,
    endHue,
    minChroma = 0,
    maxChroma = 100,
    backgroundColor = '#ffffff',
    hueFunction = linearInterpolation,
    chromaFunction = easeOutQuart,
    contrastFunction = wcagLuminosity,
  } = options;
  const backgroundY = sRGBToXYZ(fromHex(backgroundColor))[1] / 100;
  const i = step / 1000;
  const thisStartHue = startHue;
  const thisEndHue = endHue;

  let hueDiff = thisEndHue - thisStartHue;
  if (Math.abs(hueDiff) > 180) {
    hueDiff = hueDiff < 0 ? hueDiff + 360 : hueDiff - 360;
  }

  const hue = thisStartHue + hueDiff * hueFunction(i);
  const chroma = minChroma + (maxChroma - minChroma) * chromaFunction(i);
  const lightness = convertXYZ_YtoHSLuv_L(
    calculateXYZ_YfromContrastRatio(backgroundY, contrastFunction(i)),
  );

  return hsluvToHex([hue, chroma, lightness]);
}
