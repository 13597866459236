import * as React from 'react';

import {
  addCanvasMemoryLeakPatch,
  clearCanvasMemoryLeak,
} from 'gelato/frontend/src/lib/CanvasMemoryLeakPatch';
import {isExperimentActive} from 'gelato/frontend/src/lib/experiments';

import type {GraphQlField} from '@sail/data';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

/**
 * This hook patches the API of `canvas.getContext('2d')` to prevent
 * memory leaks.
 */
export default function useCanvasMemoryLeakPatch(config: {
  experiments: ReadonlyArray<GraphQlField<GetSessionQueryData, 'experiments'>>;
}) {
  const {experiments} = config;
  React.useEffect(() => {
    if (!isExperimentActive('fix_canvas_memory_leak', experiments)) {
      return;
    }
    // Patch the API of `canvas.getContext('2d')` to prevent memory leaks.
    const removePatch = addCanvasMemoryLeakPatch();
    const onNavigation = () => {
      // When navigating away from the page, clear the canvases.
      // This assumes that we do not share static canvas across
      // page urls.
      clearCanvasMemoryLeak();
    };
    window.addEventListener('popstate', onNavigation, false);
    return () => {
      removePatch();
      window.removeEventListener('popstate', onNavigation, false);
    };
  }, [experiments]);
}
