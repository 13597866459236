import * as React from 'react';
import type {ReactNode} from 'react';
import {useId} from '@sail/react-aria';
import {
  createView,
  view,
  dynamic,
  createViewConfig,
  unprocessedCss,
} from '@sail/engine';
import type {View} from '../view';
import {Description, ErrorText} from './FormField';
import type {FieldMetadata, FormFieldSubviews, SubsetOfKeys} from './FormField';
import {NativeTextFieldConfig} from './TextField';
import {NativeSelectConfig} from './Select';
import {NativeTextAreaConfig} from './TextArea';
import {CompositeFieldConfig} from './CompositeField';
import {Label} from './Label';

export type FormFieldGroupProps = View.IntrinsicElement<
  'fieldset',
  {
    /**
     * @external
     * The layout of the fields in the group.
     */
    layout: 'horizontal' | 'vertical';
    /**
     * @external
     * The text of the group's legend. This will be associated as a label with all fields inside the group.
     */
    legend?: ReactNode;
    /**
     * @external
     * Disables all fields in the group. Can be overriden on a per-field basis.
     */
    disabled?: boolean;
    /**
     * Descriptive text that will be rendered adjacent to the group's legend.
     * @external
     */
    description?: ReactNode;
    /**
     * Error to display above FormFieldGroup
     * @external
     */
    error?: React.ReactNode;
    /**
     * Sets the size prop on inner components where applicable
     * @external
     */
    size: 'small' | 'medium' | 'large';
    /**
     * Visually hides the specified elements. The hidden elements will still be present and visible to screen readers.
     */
    hiddenElements?: ('error' | 'description' | 'legend')[];

    subviews?: View.Subviews<
      Omit<FormFieldSubviews, 'inputs'> & {
        inputs: typeof InputWrapper;
      }
    >;
  }
>;

export type FormFieldGroupElements = SubsetOfKeys<
  FormFieldGroupProps,
  'legend' | 'description'
>;

export type FormFieldGroupMetadata = FieldMetadata<FormFieldGroupElements>;

export const FormFieldGroupContext = React.createContext<{
  elements: FormFieldGroupMetadata;
  disabled?: boolean;
}>({
  elements: {
    description: {},
    legend: {},
  },
});

export type InputWrapperProps = View.IntrinsicElement<
  'div',
  {
    disabled?: boolean;
    children?: React.ReactNode;
    invalid?: boolean;
  }
>;

export const InputWrapperConfig = createViewConfig({
  props: {} as InputWrapperProps,
  name: 'InputWrapper',
  flattens: true,
});

const InputWrapper = InputWrapperConfig.createView(
  // "invalid" is presumably exposed to enable subview customization based on the validity of the field
  // but we shouldn't spread it onto the DOM element.
  ({invalid: _omit, ...props}) => <div {...props} />,
);

type ErrorBlockProps = View.IntrinsicElement<
  'div',
  {
    error: React.ReactNode;
    errorId: string;
    isHidden?: boolean;
  }
>;

// The error block goes above the group if it's a vertical layout, like address field
// and below the group if it's a horizontal layout, like currency field.
const ErrorBlock = createView(
  ({error, errorId, isHidden, ...props}: ErrorBlockProps) => (
    <view.div role="alert" {...props}>
      {error ? (
        <ErrorText role="none" id={errorId} hidden={isHidden}>
          {error}
        </ErrorText>
      ) : null}
    </view.div>
  ),
);

export const FormFieldGroupConfig = createViewConfig({
  props: {} as FormFieldGroupProps,
  name: 'FormFieldGroup',
  flattens: true,
  defaults: {
    size: 'medium',
    layout: 'horizontal',
    hiddenElements: [],
  },
});

/**
 * @external
 * @param name
 * @param form
 */
export const FormFieldGroup = FormFieldGroupConfig.createView(
  ({
    layout,
    legend,
    disabled,
    error,
    description,
    children,
    size,
    hiddenElements,
    subviews,
    ...props
  }) => {
    const ref = React.useRef<HTMLFieldSetElement>(null);
    const legendId = useId();
    const descriptionId = useId();
    const errorId = useId();
    const errorProps: {'aria-describedby'?: string; 'aria-invalid'?: boolean} =
      {};

    if (error) {
      errorProps['aria-describedby'] = errorId;
      errorProps['aria-invalid'] = true;
    }

    const handleClick = () => {
      ref.current
        ?.querySelector<HTMLElement>('select,button,input:not([disabled])')
        ?.focus();
    };

    return (
      <view.fieldset {...props} {...errorProps} ref={ref}>
        <FormFieldGroupContext.Provider
          value={{
            elements: {
              description: {
                id: descriptionId,
                present: Boolean(description),
              },
              legend: {
                id: legendId,
                present: Boolean(legend),
              },
            },
            disabled,
          }}
        >
          <Label
            hidden={hiddenElements?.includes('legend')}
            as="legend"
            onClick={handleClick}
            id={legendId}
            inherits={subviews.label}
          >
            {legend}
          </Label>
          {description ? (
            <Description
              hidden={hiddenElements?.includes('description')}
              id={descriptionId}
              inherits={subviews.description}
            >
              {description}
            </Description>
          ) : null}
          {layout !== 'horizontal' ? (
            <ErrorBlock
              inherits={subviews.error}
              error={error}
              errorId={errorId}
              isHidden={hiddenElements?.includes('error')}
            />
          ) : null}
          <InputWrapper
            inherits={subviews.inputs}
            disabled={disabled}
            invalid={!!error}
            css={{stack: layout === 'vertical' ? 'y' : 'x'}}
          >
            {children}
          </InputWrapper>
          {layout === 'horizontal' ? (
            <ErrorBlock
              inherits={subviews.error}
              error={error}
              errorId={errorId}
              isHidden={hiddenElements?.includes('error')}
            />
          ) : null}
        </FormFieldGroupContext.Provider>
      </view.fieldset>
    );
  },
  {
    css: {
      stack: 'y',
      gap: 'xsmall',
    },
    subviews: {
      // NOTE: gap doesn't apply to `<legend>` elements (without `display: contents`)
      // see: https://drafts.csswg.org/css-display/#unbox-html
      label: {
        uses: [
          dynamic(({hidden}: {hidden: boolean}) => {
            if (!hidden) {
              return [unprocessedCss({display: 'contents'})];
            }
          }),
        ],
      },
      description: {
        css: {
          // unlike FormField, label appears to be optional in FormFieldGroup
          ':not(:empty) + &': {
            bleedTop: 'xsmall',
          },
        },
      },
      inputs: {css: {gap: 'small'}},
      // empty error container needs to be present for the error message to be read
      // by screen readers on insertion, but we don't want it to participate in the layout.
      error: {uses: [unprocessedCss({display: 'contents'})]},
    },
    uses: [
      dynamic(({size, disabled}) =>
        [
          NativeSelectConfig,
          NativeTextAreaConfig,
          NativeTextFieldConfig,
          CompositeFieldConfig,
        ].map((context) =>
          context.customize({
            defaults: {
              size,
              disabled,
            },
          }),
        ),
      ),
    ],
  },
);
