/**
 * Merge a partial object into a target object, returning the target object.
 * Consider using this function instead of `Object.assign()` when you want to
 * merge a partial object into an existing object and enforce the type safety.
 *
 * @param target The target object.
 * @param source The partial object to be merged into the target object.
 * @returns The merged target object.
 */
export default function shallowMergeInto<T>(
  target: T & Object,
  source: Partial<T>,
): T {
  return Object.assign(target, source);
}
