import * as React from 'react';

import AppController from 'gelato/frontend/src/controllers/AppController';
import useAppControllerContextProviderValue from 'gelato/frontend/src/lib/hooks/useAppControllerContextProviderValue';

import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

export type AppControllerContextValue = {
  appState: ApplicationState;
  appController: AppController;
};

const {createContext} = React;

export const AppControllerContext =
  createContext<AppControllerContextValue | null>(null);

/**
 * The component that provides the context value for the hook
 * `useAppController()`.
 */
export default function AppControllerContextProvider(props: {
  children: React.ReactNode;
}) {
  const contextValue = useAppControllerContextProviderValue();
  const {children} = props;
  return (
    <AppControllerContext.Provider value={contextValue}>
      {children}
    </AppControllerContext.Provider>
  );
}
