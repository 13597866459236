import * as React from 'react';
import {getIntents} from '../../intent';
import {addIntentsToProps} from './props';
import {onRender} from '../intentTypes';
import {DYNAMIC_TOKEN_PREFIX} from '../css/core/constants';
import type {Intent, IntentMap, IntentType} from '../../intent';
import type {ExternalProps} from './types';
import {useIsomorphicLayoutEffect} from '../../util';

type RenderIntentCallback<S> = (
  intents: S,
  props: ExternalProps & IntentMap,
) => void | (Intent | Intent<ExternalProps> | Partial<ExternalProps>)[];

type RenderIntentHandler<I = unknown> = [
  IntentType<I, unknown>,
  RenderIntentCallback<I>,
];

const renderIntents: RenderIntentHandler[] = [];

export function addRenderIntent<I, S>(
  intentType: IntentType<I, S>,
  callback: RenderIntentCallback<S>,
): void {
  renderIntents.push([intentType, callback] as RenderIntentHandler);
}

addRenderIntent(onRender, (onRenders, props) => {
  if (!onRenders) {
    return;
  }
  onRenders.forEach((callback) => {
    addIntentsToProps(props, callback(props));
  });
});

// Store NODE_ENV check in a constant because some tests override NODE_ENV.
// The check for a custom property will not work in a test environment because
// jsdom does not support inserting CSS custom properties.
const isDevMode = process.env.NODE_ENV === 'development';
let hasThrownDevError = false;

export function runRenderIntents(props: IntentMap): void {
  for (let i = 0; i < renderIntents.length; i++) {
    const [intentType, callback] = renderIntents[i];
    const intents = getIntents(intentType, props);
    addIntentsToProps(props, callback(intents, props));
  }

  if (isDevMode) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = React.useRef<HTMLElement>(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useIsomorphicLayoutEffect(() => {
      if (
        !hasThrownDevError &&
        ref?.current &&
        ref.current.isConnected &&
        !getComputedStyle(ref.current).getPropertyValue(
          `--${DYNAMIC_TOKEN_PREFIX}hue-gray0`,
        )
      ) {
        hasThrownDevError = true;
        throw new Error(
          `Element ${ref.current.outerHTML} was rendered without Sail Next style values being present. It requires a Sail Next theme provider above it, or adoptedStyleSheets to be set before it renders.`,
        );
      }
    }, []);

    addIntentsToProps(props, [{ref} as unknown as typeof props]);
  }
}
