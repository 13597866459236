import type {GraphQlField} from '@sail/data';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

/**
 * Compute the progress value (0 ~ 100).
 * @param session The session to compute the progress value.
 * @returns The progress value.
 */
export default function computeProgressValue(
  session: GraphQlField<GetSessionQueryData, 'session'> | null | undefined,
): number {
  const missingFields = session?.missingFields || [];
  const requiredFields = session?.requiredFields || [];
  const requiredCount = requiredFields.length;
  const missingCount = missingFields.length;

  let progressValue = 0;
  if (!session) {
    progressValue = 0;
  } else if (!requiredCount) {
    // No required fields.
    progressValue = 100;
  } else if (!missingCount) {
    // All required fields are fulfilled.
    progressValue = 100;
  } else if (requiredCount > missingCount && missingCount) {
    // Some fileds missing.
    let progress = requiredCount - missingCount;

    // Special case for ID document images which contains multiple
    // steps.
    if (
      requiredFields.includes('id_document_images') &&
      missingFields.includes('id_document_images') &&
      session?.collectedData.individual.idDocument.front
    ) {
      // If the user has uploaded the front image, we count it as half done.
      progress += 0.5;
    }

    progressValue = (progress / requiredFields.length) * 100;
  }

  // Show fake progress if no progress is made

  let progressToShow = progressValue;
  if (progressValue === 0) {
    progressToShow = 5;
  } else if (progressValue === 100 && !session?.submitted) {
    progressToShow = 95;
  }

  return progressToShow;
}
