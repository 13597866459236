import type {ReactNode} from 'react';
import React, {useContext} from 'react';
import {isRTL} from './utils';
import type {Locale} from './useDefaultLocale';
import {useDefaultLocale} from './useDefaultLocale';

export interface I18nProviderProps {
  /** Contents that should have the locale applied. */
  children: ReactNode;
  /** The locale to apply to the children. */
  locale?: string;
}

const I18nContext = React.createContext<Locale | null>(null);

/**
 * Provides the locale for the application to all child components.
 */
export function I18nProvider(props: I18nProviderProps) {
  const {locale, children} = props;
  const defaultLocale = useDefaultLocale();

  const value: Locale = locale
    ? {
        locale,
        direction: isRTL(locale) ? 'rtl' : 'ltr',
      }
    : defaultLocale;

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
}

/**
 * Returns the current locale and layout direction.
 */
export function useLocale(): Locale {
  const defaultLocale = useDefaultLocale();
  const context = useContext(I18nContext);
  return context || defaultLocale;
}
