import * as React from 'react';
import {useState, useCallback, useMemo, useContext, createContext} from 'react';
import {useId} from '@sail/react-aria';
import {useIsomorphicLayoutEffect} from '@sail/engine';
import {
  useIndexedChildren,
  useIndex,
  compareIndexPaths,
  treeFromItems,
} from './use-indexed-children';

export {
  useIndex,
  useIndexedChildren,
  useIndexedGroup,
  IndexedChildren,
  IndexedGroup,
} from './use-indexed-children';

export type Item<T> = {
  // A unique id for the item
  key: string;
  // Type of item. Allows components to easily differentiate between
  // items if needed. Defaults to "item"
  type: string;
  // A place to store data relevant to the specific item type
  data: T;
  // A path representing this item's order (internal)
  indexPathString: string;
  // A ref to the DOM node
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any;
  // An accessible label
  'aria-label'?: string;
  // A string value used to match in typeahead searches. If
  // `null`, it will use `aria-label` if given
  typeaheadValue: string | null;
  // Children
  childItems: Array<Item<T>> | null;
};

export type Collection<T> = {
  children: React.ReactNode;
  register: (id: string, item: Item<T>) => () => void;
  getItems: () => Array<Item<T>>;
};

const CollectionRegisterContext = createContext<
  Collection<unknown>['register'] | null
>(null);
const CollectionItemsContext = createContext<
  Collection<unknown>['getItems'] | null
>(null);

/**
 * This takes a collection returned from `useCollection` and makes it available
 * to all components. You must use this in the top-level component for the
 * system to work; otherwise `useItem` won't be able to register items.
 */
export function CollectionProvider({
  collection,
  children,
}: {
  collection: Collection<unknown>;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <CollectionRegisterContext.Provider value={collection.register}>
      <CollectionItemsContext.Provider value={collection.getItems}>
        {children}
      </CollectionItemsContext.Provider>
    </CollectionRegisterContext.Provider>
  );
}

/**
 * This is the starting point for creating a component that uses collections.
 * Use this in the top-level component to create the data structure that holds
 * items. `useItem` is complementary to this and will be used inside item
 * components to register items.
 */
export function useCollection<T>(children: React.ReactNode): Collection<T> {
  const [version, setVersion] = useState(Math.random());
  const itemMap = useMemo(() => new Map(), []);

  const register = useCallback(
    (key, item) => {
      itemMap.set(key, item);
      setVersion(Math.random());
      return () => {
        itemMap.delete(key);
        setVersion(Math.random());
      };
    },
    [itemMap],
  );

  const getItems = useMemo(() => {
    let cache: Item<T>[] | null = null;

    return () => {
      if (cache) {
        return cache;
      }

      // We trick React into thinking we depend on `version` so we
      // can control when `getItems` is recreated. We don't recreate
      // `getItems` so we can't use it as a signal to recreate
      // `getItems`. The goal is to avoid constantly recreating
      // the `Map` for `itemMap`.
      //
      // eslint-disable-next-line no-unused-expressions
      version;

      const items = Array.from(itemMap.entries())
        .sort((a, b) =>
          compareIndexPaths(a[1].indexPathString, b[1].indexPathString),
        )
        .map((entry) => entry[1]);

      cache = treeFromItems(items) as Item<T>[];
      return cache;
    };
  }, [itemMap, version]);

  const indexedChildren = useIndexedChildren(children);

  return {children: indexedChildren, getItems, register};
}

type ItemArgsWithTypeahead = {
  'aria-label'?: string;
  typeaheadValue: string | false;
};

type ItemArgsWithAriaLabel = {
  'aria-label': string;
  typeaheadValue?: string | false;
};

type ItemArgs<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
  type?: string;
  data?: T;
  id?: string;
} & (ItemArgsWithTypeahead | ItemArgsWithAriaLabel);

/**
 * Use this inside all components that represent items. You can specify `type`
 * to create different types of items, and provide an arbitrary `data`
 * object that is later used to customize behavior.
 */
export function useItem<T>({
  ref,
  type = 'item',
  data,
  typeaheadValue,
  id,
  'aria-label': ariaLabel,
}: ItemArgs<T>): {key: string} {
  const key = useId(id);
  const index = useIndex();
  const register = useContext(CollectionRegisterContext);

  if (register == null) {
    throw new Error(
      `Item rendered without a collection context: ${JSON.stringify(data)}`,
    );
  }
  if (index == null) {
    // `useCollection` sets up both `register` and `index`, but
    // `useIndex` may still return `null` according to the types.
    // This is mainly to narrow the type to be non-null
    throw new Error('Improper collection context: item has no index');
  }

  const item = useMemo(() => {
    return {
      data,
      type,
      key,
      indexPathString: index.indexPathString,
      ref,
      typeaheadValue: ariaLabel || typeaheadValue,
      'aria-label': ariaLabel,
    };
  }, [data, type, key, index, ref, typeaheadValue, ariaLabel]);

  useIsomorphicLayoutEffect(
    () => register(key, item as Item<unknown>),
    [register, key, item],
  );

  return item;
}

/**
 * This hook will return all the items.
 */
export function useCollectionItems<T>(): Item<T>[] {
  const items = useContext(CollectionItemsContext)?.() || [];
  return items as Array<Item<T>>;
}
