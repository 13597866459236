import {IconAsset} from '@sail/icons/types';
import {view, css} from '@sail/ui';
import * as React from 'react';
import {injectIntl} from 'react-intl';

import ConsentDeclined, {
  generateConsentDeclinedPageContent,
} from 'gelato/frontend/src/components/Invalid/ConsentDeclinedV2';
import DeviceUnsupported from 'gelato/frontend/src/components/Invalid/DeviceUnsupportedV2';
import ExpiredSession, {
  generateExpiredSessionPageContent,
} from 'gelato/frontend/src/components/Invalid/ExpiredSessionV2';
import Generic from 'gelato/frontend/src/components/Invalid/GenericV2';
import OTPDeclined, {
  generateOTPDeclinedContent,
} from 'gelato/frontend/src/components/Invalid/OTPDeclinedV2';
import SanctionedDocumentCountry from 'gelato/frontend/src/components/Invalid/SanctionedDocumentCountryV2';
import UnderConsentAge from 'gelato/frontend/src/components/Invalid/UnderConsentAgeV2';
import UnsupportedCountry from 'gelato/frontend/src/components/Invalid/UnsupportedCountryV2';
import UnsupportedHandoffDevice from 'gelato/frontend/src/components/Invalid/UnsupportedHandoffDeviceV2';
import UnsupportedIpCountry from 'gelato/frontend/src/components/Invalid/UnsupportedIpCountryV2';
import {OTPMode} from 'gelato/frontend/src/components/OTPVerification';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import ThemedIcon from 'gelato/frontend/src/components/ThemedIcon';
import TopNavigationBar from 'gelato/frontend/src/components/TopNavigationBar';
import {getInvalidStates} from 'gelato/frontend/src/controllers/utils/getInvalidStates';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

import type {InvalidState} from 'gelato/frontend/src/controllers/utils/getInvalidStates';
import type {IntlShape} from 'react-intl';

type Props = {
  intl: IntlShape;
};

const {useEffect} = React;
const Styles = {
  invalidPage: css({
    stack: 'y',
    textAlign: 'center',
  }),
  body: css({
    stack: 'y',
    padding: 'large',
  }),
};

export function InvalidPage(props: Props) {
  const {appController, appState} = useAppController();
  const {session} = appState;
  const branding = appController.runtime?.branding;
  const platformName = getBrandingPlatformName(branding);

  const [contentState, setContentState] = React.useState<Record<
    InvalidState,
    boolean
  > | null>(null);

  useEffect(() => {
    setContentState(getInvalidStates(appState));
  }, [appState]);

  useEffect(() => {
    if (contentState) {
      appController.recordInvalidState();
    }
  }, [contentState, appController]);

  // If session is expired, session will be null but contentState.isExpired should still return true
  if (!session || !contentState) {
    return <PageCard loading />;
  }

  const component = () => {
    if (contentState.unsupportedIpCountry) {
      return <UnsupportedIpCountry />;
    } else if (contentState.unsupportedHandoffDevice) {
      return <UnsupportedHandoffDevice />;
    } else if (contentState.consentDeclined) {
      return <ConsentDeclined />;
    } else if (contentState.deviceUnsupported) {
      return (
        <DeviceUnsupported
          operatingMode={session.operatingMode}
          unsupportedReason={session.lastErrorDetails}
        />
      );
    } else if (contentState.isExpired) {
      return <ExpiredSession operatingMode={session.operatingMode} />;
    } else if (contentState.unsupportedCountry) {
      return <UnsupportedCountry session={session} />;
    } else if (contentState.sanctionedDocumentCountry) {
      return <SanctionedDocumentCountry session={session} />;
    } else if (contentState.emailOTPDeclined) {
      return <OTPDeclined session={session} mode={OTPMode.email} />;
    } else if (contentState.phoneOTPDeclined) {
      return <OTPDeclined session={session} mode={OTPMode.phone} />;
    } else if (contentState.underConsentAge) {
      return <UnderConsentAge session={session} />;
    } else {
      return <Generic platformName={platformName} />;
    }
  };

  if (layoutV2Enabled(contentState)) {
    let icon;
    let title;
    let description;
    let footer;

    if (contentState.consentDeclined) {
      ({icon, title, description, footer} =
        generateConsentDeclinedPageContent(platformName));
    } else if (contentState.isExpired) {
      ({icon, title, description, footer} = generateExpiredSessionPageContent(
        platformName,
        session.operatingMode,
      ));
    } else if (contentState.emailOTPDeclined) {
      ({icon, title, description, footer} = generateOTPDeclinedContent(
        OTPMode.email,
      ));
    } else if (contentState.phoneOTPDeclined) {
      ({icon, title, description, footer} = generateOTPDeclinedContent(
        OTPMode.phone,
      ));
    }

    return (
      <>
        <InvalidPageLayoutV2
          icon={icon}
          title={title}
          description={description}
          footer={footer}
        />
      </>
    );
  }

  const header = <TopNavigationBar />;

  const body = <view.div uses={[Styles.invalidPage]}>{component()}</view.div>;

  return (
    <>
      <PageCard header={header} body={body} bodySectionStyles={[Styles.body]} />
    </>
  );
}

const layoutV2Enabled = (contentState: Record<InvalidState, boolean>) => {
  // enable shifting layout to new component pattern
  if (contentState.consentDeclined) {
    return true;
  } else if (contentState.isExpired) {
    return true;
  } else if (contentState.emailOTPDeclined) {
    return true;
  } else if (contentState.phoneOTPDeclined) {
    return true;
  }
  return false;
};

const LayoutStyles = {
  iconContainer: css({
    stack: 'y',
    alignX: 'center',
  }),
  footerContainer: css({
    paddingX: 'large',
    paddingBottom: 'large',
    paddingTop: 'small',
  }),
  bodyContainer: css({textAlign: 'center', stack: 'y', gap: 'medium'}),
  titleContainer: css({font: 'heading.large'}),
  descriptionContainer: css({font: 'body.medium'}),
  contentContainer: css({
    stack: 'y',
    gap: 'large',
  }),
};

const InvalidPageLayoutV2 = (props: {
  icon: IconAsset | undefined;
  title: JSX.Element | undefined;
  description: JSX.Element | undefined;
  footer: JSX.Element | undefined;
}) => {
  const {icon, title, description, footer} = props;

  const showBody = title || description;

  return (
    <PageCard
      header={<TopNavigationBar />}
      body={
        <view.div uses={[LayoutStyles.contentContainer]}>
          {icon && (
            <view.div uses={[LayoutStyles.iconContainer]}>
              <ThemedIcon icon={icon} size="large" />
            </view.div>
          )}
          {showBody && (
            <view.div uses={[LayoutStyles.bodyContainer]}>
              <view.div uses={[LayoutStyles.titleContainer]}>{title}</view.div>
              <view.div uses={[LayoutStyles.descriptionContainer]}>
                {description}
              </view.div>
            </view.div>
          )}
        </view.div>
      }
      footer={
        <view.div uses={[LayoutStyles.footerContainer]}>{footer}</view.div>
      }
      alignBodyItems="center"
    />
  );
};

setComponentConfig(InvalidPage, {
  componentName: 'invalid_page',
  isTerminal: true,
  ignoreExpiredSession: true,
});

export default injectIntl(InvalidPage);
