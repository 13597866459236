// In this file, we use the concept of "branded types" to help differentiate
// between certain types of strings that represent secret values, such as
// AuthSessionClientSecret and ConsumerSessionClientSecret. Branded types allow
// us to treat these strings as distinct types, even though they are just
// strings at runtime. This can help prevent accidental misuse or leakage of
// these secrets within the codebase.

// The SecretString type function is used to introduce a "brand" - a type-only
// property that distinguishes the branded types from other strings. The
// resulting branded types can only be created or manipulated through specific
// "transformer" functions, such as stringToAuthSessionClientSecret and
// stringToConsumerSessionClientSecret. These functions are private to the code
// areas that handle the boundaries between secret values and the rest of the
// codebase, such as API calls or cookie lookups.

// For testing purposes, we also allow the use of strings starting with
// fake_secret as valid branded types. This makes it easier to use test values
// without requiring tests to either cast or use the transformer functions.

// By using branded types and transformers, we can better track where secrets
// are being used in the codebase, making it harder to use them incorrectly and
// adding a layer of safety when handling confidential information.

// Use an interface rather than an object type to ensure that SecretString does
// not pass type-level serialization checks.
interface Branded<T extends string> {
  readonly __brand: T;
}

type SecretString<T extends string> =
  | Branded<T>
  // Allow "fake_secret" to make testing easier. In live code secrets are never
  // a const that would fulfill this type.
  | `fake_secret${string}`
  // Allow "" (empty string) to make testing flows where a null secret is passed
  // as an empty string easier. In live code secrets are never a const that
  // would fulfill this type.
  | '';

export type DeepSecretsToString<T extends any> = T extends SecretString<any>
  ? string
  : T extends Array<any>
  ? DeepSecretsToString<T[number]>[]
  : T extends Record<string, any>
  ? {[K in keyof T]: DeepSecretsToString<T[K]>}
  : T;

/**
 * `ConsumerSessionClientSecret` is just a string at runtime, but we add a
 * type-only `__brand` so that it's easier to distinguish from other strings
 * and so we can more easily track where it is being used across the codebase.
 *
 * `'fake_secret'` (optionally suffixed with whatever you want) will also
 * satisfy this type, which can make testing easier.
 */
export type ConsumerSessionClientSecret =
  SecretString<'ConsumerSessionClientSecret'>;

/**
 * `AuthSessionClientSecret` is just a string at runtime, but we add a
 * type-only `__brand` so that it's easier to distinguish from other strings
 * and so we can more easily track where it is being used across the codebase.
 *
 * `'fake_secret'` (optionally suffixed with whatever you want) will also
 * satisfy this type, which can make testing easier.
 */
export type AuthSessionClientSecret = SecretString<'AuthSessionClientSecret'>;

export type Nullable<T> = T | null | undefined;

/**
 * Util which allows our converter functions to be a bit more generic. If called
 * with a non-nullable type, it will return a non-nullable type. If called with
 * a type that can be null or undefined, it will return a matching nullable
 * type.
 */
type MaybeNullable<A, B> = A extends null
  ? null | B
  : A extends undefined
  ? undefined | B
  : A extends null | undefined
  ? null | undefined | B
  : B;

/**
 * Converts a SecretString to a raw string. Broad use of this method is not
 * safe and should warrant more careful review that we are not logging,
 * postMessaging, or otherwise exposing the client secrets externally.
 */
export const secretToString = <
  S extends Nullable<AuthSessionClientSecret | ConsumerSessionClientSecret>,
>(
  secret: S,
): MaybeNullable<S, string> => {
  return secret as any;
};

/**
 * Converts raw string to `AuthSessionClientSecret`. Eventually this
 * will also prefix the string to make it easier to distinguish from other
 * strings at the edges of the codebase where we want to make sure we're
 * not accidentally passing secrets into the wrong context.
 */
export const stringToAuthSessionClientSecret = <S extends Nullable<string>>(
  secret: S,
): MaybeNullable<S, AuthSessionClientSecret> => {
  return secret as any;
};

/**
 * Converts raw string to `ConsumerSessionClientSecret`. Eventually this
 * will also prefix the string to make it easier to distinguish from other
 * strings at the edges of the codebase where we want to make sure we're
 * not accidentally passing secrets into the wrong context.
 */
export const stringToConsumerSessionClientSecret = <S extends Nullable<string>>(
  secret: S,
): MaybeNullable<S, ConsumerSessionClientSecret> => {
  return secret as any;
};
