import {Flow} from '@stripe-internal/typescriptify';
import * as React from 'react';
import {injectIntl as _injectIntl, FormattedMessage} from 'react-intl';

import {markdownify} from 'gelato/frontend/src/lib/intlFormat';

import type {RemarkableOptions} from 'gelato/frontend/src/components/MarkdownTextWithConfig';
import type {MessageDescriptor, IntlShape} from 'react-intl';

export type MessageDescriptorMap = {
  [key: string]: MessageDescriptor;
};

export type IntlProps = {
  intl: IntlShape;
};

export type Props = {
  description?: string;
  defaultMessage?: string;
  id: string;
  values?: any;
};

export function noTranslateMessages<T extends MessageDescriptorMap>(
  messages: T,
): T {
  return messages;
}

export const defineMessages = noTranslateMessages;

export function injectIntl<
  Props extends Record<any, any>,
  K extends React.ComponentType<Props>,
>(
  Component: K,
  options?: any,
): Flow.Class<
  React.Component<
    Flow.Diff<
      // @ts-expect-error - TS2344 - Type 'LibraryManagedAttributes<K, ComponentProps<K>>' does not satisfy the constraint '{ intl: IntlShape | undefined; }'.
      JSX.LibraryManagedAttributes<K, React.ComponentProps<K>>,
      {
        intl: IntlShape | undefined;
      }
    >,
    any
  >
> {
  // @ts-expect-error - TS2322 - Type 'FC<WithIntlProps<WrappedComponentProps<"intl">>> & { WrappedComponent: ComponentType<WrappedComponentProps<"intl">>; }' is not assignable to type 'Class<Component<Diff<LibraryManagedAttributes<K, ComponentProps<K>>, { intl: IntlShape | undefined; }>, any, any>>'. | TS2769 - No overload matches this call.
  return _injectIntl(Component, options);
}

class Message extends React.PureComponent<Props & IntlProps> {
  render() {
    const {
      intl,
      description,
      defaultMessage = '',
      id,
      values,
      ...otherProps
    } = this.props;
    if (typeof id !== 'string') {
      // eslint-disable-next-line
      console.error(
        'Attempted to use message without passing id! Returning null instead.',
      );
      return null;
    }
    return intl.formatMessage(
      {
        id,
        description: typeof description === 'string' ? description : id,
        defaultMessage,
      },
      values || otherProps,
    );
  }
}

export const FormattedMarkdownMessage = (
  props: MessageDescriptor & {
    values?: any;
    config?: RemarkableOptions;
  },
) => {
  const {values, config = {}, ...otherProps} = props;

  return (
    <FormattedMessage {...otherProps} values={values}>
      {markdownify(config, values)}
    </FormattedMessage>
  );
};

// @ts-expect-error - TS2345 - Argument of type 'typeof Message' is not assignable to parameter of type 'ComponentType<Record<any, any>>'.
export default injectIntl(Message);
