import * as React from 'react';
import {defineMessages} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import {isMobileDevice} from 'gelato/frontend/src/lib/device';
import {useConnectIframe} from 'gelato/frontend/src/lib/hooks';

import styles from './LandscapeMessage.module.css';

const messages = defineMessages({
  landscapeMessage: {
    id: 'gelato.landscapeMessage',
    description:
      'Tell the user to rotate a device from landscape orientation to portrait orientation',
    defaultMessage: 'Please rotate your device to portrait mode',
  },
});

/**
 * The LandscapeMessage component is used to tell the user to rotate a device
 * from landscape orientation to portrait orientation.
 * This only applies to mobile devices.
 */
const LandscapeMessage = () => {
  const isConnectIframe = useConnectIframe();
  if (isConnectIframe || !isMobileDevice()) {
    return null;
  }

  return (
    <div className={styles.landscapeMessageContainer}>
      <div className={styles.landscapeMessage}>
        <Message {...messages.landscapeMessage} />
      </div>
    </div>
  );
};

export default LandscapeMessage;
