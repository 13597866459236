export type ErrorName =
  | 'DETECTOR_BUILD_SOFT_ERROR'
  | 'DETECTOR_WARMUP_SOFT_ERROR'
  | 'DETECTOR_DETECT_SOFT_ERROR';

type Props = {
  name: ErrorName;
  message: string;
  cause?: any;
};

export class DetectorSoftError extends Error {
  name: ErrorName;

  message: string;

  cause: any;

  constructor(props: Props) {
    super();
    const {name, message, cause} = props;
    this.name = name;
    this.message = message;
    this.cause = cause;
  }
}
