import asError from 'gelato/frontend/src/lib/asError';

type Task = {
  promise: () => Promise<any>;
  reject: (error: Error) => void;
  resolve: (value: any) => void;
};

const tasks: Task[] = [];
let busy = false;

/**
 * Clears the queue of promises.
 * This is useful for testing purposes.
 */
export function resetForJSTests(): void {
  tasks.length = 0;
  busy = false;
}

/**
 * Enqueues a promise to be executed in order globally. If a promise is pending,
 * the new promise will be enqueued to be executed after the pending promise
 * is resolved or rejected. This is useful for executing promises in order
 * without worrying about concurrent execution of promises.
 * @param promise The promise to be enqueued.
 * @returns A Promise that resolves with the result of the enqueued promise.
 */
export default async function enqueuePromise<T>(
  promise: () => Promise<T>,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const task: Task = {resolve, reject, promise};
    tasks.push(task);

    if (!busy) {
      // Execute the task immediately if the queue is not busy.
      executeNextTask();
    }
  });
}

/**
 * Executes a task and executes the next task in the queue.
 * @param task The task to be executed.
 */
async function executeNextTask(): Promise<void> {
  busy = true;
  const task = tasks.shift();
  if (!task) {
    busy = false;
    return;
  }

  const {resolve, reject, promise} = task;
  try {
    const result = await promise();
    resolve(result);
  } catch (ex) {
    reject(asError(ex));
  } finally {
    // Continue to execute the next task in the queue.
    await executeNextTask();
  }
}
