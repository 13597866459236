import {add, check} from '@sail/icons/react/Icon';
import {view, Action, Icon, css} from '@sail/ui';
import * as React from 'react';

import {LINK_GREEN} from 'gelato/frontend/src/components/Link/constants';
import {Styles as SharedStyles} from 'gelato/frontend/src/components/Link/DocumentSelection/styles';
import {messages} from 'gelato/frontend/src/components/Link/messages';
import Message from 'gelato/frontend/src/components/Message';
import {DEFAULT_BRANDING_BUTTON_COLOR} from 'gelato/frontend/src/lib/constants';
import flags from 'gelato/frontend/src/lib/flags';
import useBranding from 'gelato/frontend/src/lib/hooks/useBranding';

type AddNewDocumentProps = {
  disabled?: boolean;
  addNewDocument: () => void;
  selected: boolean;
};

const Styles = {
  typeIconCss: css({
    padding: 'space.75',
  }),
};

const AddNewDocument = ({
  disabled,
  addNewDocument,
  selected,
}: AddNewDocumentProps) => {
  const {buttonColor} = useBranding();
  const safeButtonColor = buttonColor || DEFAULT_BRANDING_BUTTON_COLOR;

  const actionInnerDynamic = SharedStyles.calculateActionInnerDynamic(
    selected,
    flags.isActive('idprod_ni_implementation_review')
      ? LINK_GREEN
      : safeButtonColor,
  );

  const checkColor = flags.isActive('idprod_ni_implementation_review')
    ? LINK_GREEN
    : undefined;

  return (
    <view.div
      role="option"
      aria-selected={selected}
      uses={[SharedStyles.action]}
    >
      <Action
        onPress={() => addNewDocument()}
        disabled={disabled}
        data-testid="link-upload-new-document"
      >
        <view.div uses={[SharedStyles.actionInnerStatic, actionInnerDynamic]}>
          <view.div uses={[SharedStyles.typeIconWrapperStatic]}>
            <Icon icon={add} size="large" uses={[Styles.typeIconCss]} />
          </view.div>
          <view.div uses={[SharedStyles.text]}>
            <view.div uses={[SharedStyles.largeLabel]}>
              <Message {...messages.toBeCollected} />
            </view.div>
          </view.div>
          {selected && (
            <Icon icon={check} size="small" css={{fill: checkColor}} />
          )}
        </view.div>
      </Action>
    </view.div>
  );
};

export default AddNewDocument;
