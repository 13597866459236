import {warn} from './warn';

/**
 * This method generates unique ids. It was more or less stolen from the checkout team.
 *
 * @method generateID
 * @private
 *
 * @return {String}
 * It returns a string in the form `"xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"`
 */
export const generateID = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // Ignore linting so I can keep it the exact same as it was, without fear
    // of subtle changes.
    /* eslint-disable */
    let r = (Math.random() * 16) | 0;
    let v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
    /* eslint-enable */
  });
};

/**
 * This method takes a string, usually an event name, and forces snake_case
 * on it.
 *
 * @param str {String}
 *
 * @return {String}
 * Returns a snake cased version of the input string.
 */
export const snakeCase = (str: string): string => {
  const hasSeparator = /[\s\W_]/;
  const leadingSeparators = /^[\s\W_]*/;
  const trailingSeparators = /[\s\W_]*$/;
  const camelOne = /(.)([A-Z][a-z]+)/g;
  const camelTwo = /([a-z0-9])([A-Z])/g;
  const separatorSplitter = /[\s\W_]+(.|$)/g;

  if (!str) {
    warn('empty_snake_case');
    return str;
  }
  let result = str;
  result = result.replace(leadingSeparators, '');
  result = result.replace(trailingSeparators, '');
  // Test if the string already has a separator, if so only replace separators
  if (hasSeparator.test(result)) {
    return result
      .replace(separatorSplitter, function (s, next) {
        return next ? `${'_'}${next}` : '';
      })
      .toLowerCase();
  } else {
    result = result.replace(camelOne, function (s, previous, next) {
      return `${previous}${'_'}${next}`;
    });
    // Format a camel case string by splitting on capitalized letters
    return result
      .replace(camelTwo, function (s, previous, next) {
        return `${previous}${'_'}${next}`;
      })
      .toLowerCase();
  }
};

/**
 * This is a *highly* simplified object-to-query-param serializer. It is
 * generally best used with flat structures.
 *
 * @param params {Object}
 * A serializable object that you'd like to turn into a query string.
 * Top level objects become query parameters. All sub-objects are simply
 * JSON.stringified and used as a flat value. Url encoding is automatically
 * applied to all keys and values, so that does not need to be done first.
 *
 * @return {String}
 * This is *just* the query string, not including the initial `?` character.
 * The output is url encoded.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toQueryString = (params: {[key: string]: any}): string => {
  let qs = '';

  // Safely loop through keys
  for (const k in params) {
    if (params.hasOwnProperty(k)) {
      // Grab the value
      let v = params[k];

      // If it's an object, serialize it
      if (typeof v === 'object') {
        try {
          v = JSON.stringify(v);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          warn('json_stringify_error', e);
          throw e;
        }
      }
      qs += `&${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
    }
  }

  return qs.replace('&', '');
};
