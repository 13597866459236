export default function routeToLinkForConsumerDocumentSave(
  networkedIdentityEnabled: boolean,
  hasDocumentRequirement: boolean,
  hasSharedDocument: boolean,
  skippedNetworking: boolean,
  consentsToDocumentNetworking: boolean,
) {
  return (
    networkedIdentityEnabled &&
    hasDocumentRequirement &&
    !hasSharedDocument &&
    !skippedNetworking &&
    // If the user has already consented to document networking, we should not route them to the /link page
    // because that means that they have already agreed to share their document.
    !consentsToDocumentNetworking
  );
}
