/**
 * @fileoverview This file contains the document actions for the application
 *   controller. Each action is a function that takes the application controller
 *   and update the application state accordingly.
 */

import {produce} from 'immer';

import AnalyticsClientProvider from 'gelato/frontend/src/components/AnalyticsProvider/AnalyticsClientProvider';
import checkWebcamVideo from 'gelato/frontend/src/components/webcam/checkWebcamVideo';
import {getActiveDeviceId} from 'gelato/frontend/src/controllers/actions/cameraActions';
import {refreshSessionAction} from 'gelato/frontend/src/controllers/actions/mutationActions';
import {
  AutoCaptureStatus,
  CameraInfo,
  Feedback,
  FeedbackValue,
  HARD_BLOCK_FEEDBACKS,
  InputMethod,
  InputMethodValue,
  InspectionState,
  LIVE_MOVEMENT_FEEDBACKS,
  UploadState,
  UserStep,
  createInspectionState,
  createInspectionStateId,
} from 'gelato/frontend/src/controllers/states/DocumentState';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import clearDataFieldsMutation from 'gelato/frontend/src/controllers/utils/clearDataFieldsMutation';
import confirmDocumentImagesMutation from 'gelato/frontend/src/controllers/utils/confirmDocumentImagesMutation';
import inspectDocument from 'gelato/frontend/src/controllers/utils/inspectDocument';
import isFieldNeeded from 'gelato/frontend/src/controllers/utils/isFieldNeeded';
import routeToNextPage from 'gelato/frontend/src/controllers/utils/routeToNextPage';
import sendDocumentImageMutation from 'gelato/frontend/src/controllers/utils/sendDocumentImageMutation';
import updateDocumentMetadataMutation from 'gelato/frontend/src/controllers/utils/updateDocumentMetadataMutation';
import testmodeBack from 'gelato/frontend/src/images/testmodeBack.jpg';
import testmodeFront from 'gelato/frontend/src/images/testmodeFront.jpg';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import enqueuePromise from 'gelato/frontend/src/lib/enqueuePromise';
import experiments from 'gelato/frontend/src/lib/experiments';
import flags from 'gelato/frontend/src/lib/flags';
import ImageFrame, {InvalidImageInfo} from 'gelato/frontend/src/lib/ImageFrame';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';
import Storage from 'gelato/frontend/src/lib/Storage';
import timedFunction from 'gelato/frontend/src/lib/timedFunction';
import {uploadFile} from 'gelato/frontend/src/lib/uploadFile';
import {getUploadHost} from 'gelato/frontend/src/lib/urlConfig';
import {fetchBlobFromFile, toJsonUTF8} from 'gelato/frontend/src/lib/utils';
import {
  BACK_TIMEOUT_DURATION,
  DOC_FRAUD_MODEL_IMAGE_MIN_HEIGHT,
  DOC_FRAUD_MODEL_IMAGE_MIN_WIDTH,
  FRONT_TIMEOUT_DURATION,
} from 'gelato/frontend/src/ML/lib/constants';

import type {DocumentModelDataInput} from '@stripe-internal/data-gelato/schema/types';
import type {
  ApplicationAction,
  ApplicationActionWithPayload,
  ApplicationController,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';
import type {RouteChangeReason} from 'gelato/frontend/src/controllers/utils/routeToNextPage';

// The maximum dimensions of the full frame image.
const FULL_FRAME_MAX_DIMENSIONS = [1200, 1200];

// The quality of the full frame image.
const FULL_FRAME_IMAGE_QUALITY = 0.82;

// The quality of the document image.
const DOCUMENT_IMAGE_QUALITY = 0.9;

// The maximum number of previous feedbacks to keep.
const MAX_PREVIOUS_FEEDBACKS_LENGTH = 10;

/**
 * The timer ID for the document auto capture.
 */
let documentAutoCaptureTimerId: number | null = null;

/**
 * Helper function to convert the image to a preview image.
 * @param image The image to convert.
 */
async function toPreviewImage(image: ImageFrame): Promise<ImageFrame> {
  // This preview will be shown in a smaller box, approximately 400x300 in size.
  // Using 600x450 to make sure the image has enough resolution.
  return image.fitToContain(600, 450);
}

/**
 * Clear the error during the inspection phase of the working document.
 */
export const clearInspectionErrorAction: ApplicationAction = async (
  controller,
) => {
  controller.update((draft) => {
    const {workingSide} = draft.document;
    const {inspectionState} = draft.document[workingSide];
    if (inspectionState) {
      inspectionState.error = null;
    }
  });
};

export const setDocumentInputMethodAction: ApplicationActionWithPayload<{
  method: InputMethodValue;
}> = async (controller, payload) => {
  const {method} = payload;
  if (method !== controller.state.document.workingInputMethod) {
    await stopDocumentAutoCaptureAction(controller, {error: null});
    controller.update((draft) => {
      const side = draft.document.workingSide;
      draft.document[side].uploadState = null;
      draft.document[side].inspectionState = null;
      draft.document[side].reviewState = null;
      draft.document.workingInputMethod = payload.method;
    });

    switch (method) {
      case InputMethod.cameraAutoCapture:
      // fallthrough
      case InputMethod.cameraManualCapture:
        Storage.setVerifyOption('webcam');
        break;
      case InputMethod.fileUpload:
        Storage.setVerifyOption('upload');
        break;
    }
  }
  return true;
};

/**
 * Start the document auto capture.
 * @returns Whether the action is successful.
 */
export const startDocumentAutoCaptureAction: ApplicationAction = async (
  controller,
) => {
  return enqueuePromise(() =>
    startDocumentAutoCaptureActionEnqueued(controller),
  );
};

/**
 * Start the document auto capture.
 * @returns Whether the action is successful.
 */
export const stopDocumentAutoCaptureAction: ApplicationActionWithPayload<{
  error?: Error | null;
}> = async (controller, payload) => {
  return enqueuePromise(() =>
    stopDocumentAutoCaptureActionEnqueued(controller, payload),
  );
};

/**
 * The action to capture the document image with the one-time inspection. This
 * is used for the manual capture or file upload.
 * @param controller
 * @param payload
 */
export const captureDocumentImageAction: ApplicationActionWithPayload<{
  image: ImageFrame;
}> = async (controller, payload) => {
  const {workingInputMethod} = controller.state.document;

  const inputImage = await payload.image.clone();

  const side = controller.state.document.workingSide;
  if (
    workingInputMethod !== InputMethod.cameraManualCapture &&
    workingInputMethod !== InputMethod.fileUpload
  ) {
    // This action is only for manual capture or file upload.
    throw new Error(ErrorCode.invalidInputMethod);
  }

  // There won't be any more iterations like auto capture does. We're going to
  // inspect the image just for once.
  const iterationCount = 0;

  // Clean up the old inspection state images that are no longer needed.
  const prevInspectionState = controller.state.document[side].inspectionState;
  prevInspectionState?.inputImage.dispose();
  prevInspectionState?.documentImage?.dispose();

  const cameraInfo =
    workingInputMethod === InputMethod.cameraManualCapture
      ? getCameraInfo(controller.state)
      : null;

  const timeoutDuration =
    side === 'front' ? FRONT_TIMEOUT_DURATION : BACK_TIMEOUT_DURATION;

  // Generate the new inspection state.
  let inspectionState = produce<InspectionState>(
    createInspectionState({
      cameraInfo,
      inputImage,
      iterationCount,
      side,
      startedAt: Date.now(),
      timeoutAt: Date.now() + timeoutDuration,
    }),
    () => {},
  );

  controller.update((draft) => {
    draft.document[side].inspectionState = inspectionState;
    // Clear the old upload state since we have a new inspection state.
    draft.document[side].uploadState?.previewImage?.dispose();

    draft.document[side].uploadState = null;

    // Do not clear the review state since we want to keep the previous
    // validation errors to see if we coulld allow user to try again.
  });

  try {
    inspectionState = await inspectDocument(controller.state, inspectionState);

    // Inform the controller that the inspection state is updated.
    controller.update((draft) => {
      draft.document[side].inspectionState = inspectionState;
    });
  } catch (ex) {
    // Unable to inspect the image on the frontend, possibly because ML or
    // the camera is unavailable. We can bypass local inspection and proceed
    // to the next step, treating the image as locally approved. Backend
    // inspection will then take over.
    handleException(ErrorCode.failedToInspectDocument, String(ex));
  }

  const {approved, feedback} = inspectionState;
  inspectionState = await ensuresDocumentImage(controller, inspectionState);

  if (approved) {
    await proceedToUploadStep(controller, inspectionState);
  } else if (feedback && HARD_BLOCK_FEEDBACKS.has(feedback)) {
    // We have a hard block feedback. Proceed to the review step where user
    // will be asked to take another image.
    await proceedToReviewStep(controller, inspectionState);
  } else if (
    feedback &&
    !LIVE_MOVEMENT_FEEDBACKS.has(feedback) &&
    workingInputMethod === InputMethod.cameraManualCapture
  ) {
    // We have the feedback that does not require user to move the camera in
    // real time. Such feedbacks are usually related to the document quality
    // such as blur, glare, etc. We can proceed to the review step where user
    // will be asked to take another image.
    // Note that we only do this for manual capture. For file upload, we can't
    // tell if user has another image to upload.
    await proceedToReviewStep(controller, inspectionState);
  } else {
    // We are not sure if we could ask user to take another image with better
    // quality. Just proceed to the upload step and let the backend decide.
    if (!approved || feedback) {
      inspectionState = produce(inspectionState, (draft) => {
        // Force teh approval so that the image could be uploaded.
        draft.approved = true;
        draft.feedback = null;
      });
    }
    await proceedToUploadStep(controller, inspectionState);
  }
};

/**
 * Start the document auto capture.
 * @returns Whether the action is successful.
 */
export const startDocumentAutoCaptureActionEnqueued: ApplicationAction = async (
  controller,
) => {
  if (
    controller.state.document.autoCaptureStatus === AutoCaptureStatus.started
  ) {
    // The auto capture is already started, skip this action.
    return true;
  }

  controller.update((draft) => {
    draft.document.autoCaptureStatus = AutoCaptureStatus.started;

    // Always reset the inspection state when this action is called.
    shallowMergeInto(draft.document[draft.document.workingSide], {
      inspectionState: null,
    });
  });

  const iterate = timedFunction('autocaptureIteration', async () => {
    try {
      const appState = controller.state;
      validateStateForAutoCapture(appState);

      const nextState = await getNextInspectionState(appState);

      // Inform the controller that the inspection state is updated.
      controller.update((draft) => {
        const {side} = nextState;
        // Dispose the images from previous inspection state.
        const prevState = draft.document[side].inspectionState;
        if (prevState && prevState !== nextState) {
          disposeInspectionState(prevState);
        }

        // Assign the new inspection state.
        draft.document[side].inspectionState = nextState;
      });

      if (
        controller.state.document.autoCaptureStatus !==
        AutoCaptureStatus.started
      ) {
        // The auto capture is stopped, skip this iteration.
        throw new Error(ErrorCode.inspectionStopped);
      }

      if (nextState.error) {
        // The inspection failed. We can stop the auto capture and move to the
        // next step.
        // TODO: Move to the next step.
        throw nextState.error;
      }

      if (nextState.approved) {
        await proceedToUploadStep(controller, nextState);
        return;
      }

      // The inspection is not done yet. We can continue the auto capture.
      if (documentAutoCaptureTimerId) {
        clearTimeout(documentAutoCaptureTimerId);
      }
      const interval = 500;
      documentAutoCaptureTimerId = window.setTimeout(iterate, interval);
    } catch (ex) {
      // The inspection failed. We can stop the auto capture and move to the
      // next step.
      const error = asError(ex);
      if (error.message === ErrorCode.inspectionStopped) {
        // This error is safe to ignore.
        return;
      }
      handleException(error, error.message);

      controller.update((draft) => {
        const side = draft.document.workingSide;
        const inspectionState = draft.document[side].inspectionState;
        // TODO(hedger): perhaps upload snapshot of timeout image for debugging
        if (inspectionState) {
          // Mark as inspection error.
          // Dispose the images to release the memory.
          disposeInspectionState(inspectionState);
          inspectionState.error = error;
        } else {
          // The inspection had not started yet. We need to create a new
          // inspection state to store the error.
          draft.document[side].inspectionState = createInspectionState({
            error,
            side,
          });
        }

        if (error.message === ErrorCode.documentAutoCaptureNotSupported) {
          draft.document.autoCaptureIsSupported = false;
        }
      });

      await stopDocumentAutoCaptureActionEnqueued(controller, {error});

      if (error.message === ErrorCode.documentAutoCaptureNotSupported) {
        if (controller.state.camera.ready) {
          // Auto capture is not supported. Fall back to manual capture.
          await setDocumentInputMethodAction(controller, {
            method: InputMethod.cameraManualCapture,
          });
        } else {
          // Auto capture is not supported. Fall back to file upload.
          await setDocumentInputMethodAction(controller, {
            method: InputMethod.fileUpload,
          });
        }
      }
    }
  });

  if (documentAutoCaptureTimerId) {
    clearTimeout(documentAutoCaptureTimerId);
  }
  documentAutoCaptureTimerId = window.setTimeout(iterate, 500);
  return true;
};

const maybeTrackInvalidImageInfo = (
  invalidImageInfo: InvalidImageInfo,
  fileInfo: {
    frameType: string;
    id: string;
    size: number;
    type: string;
    scale?: number;
  },
) => {
  const {isValidImage, pixelCount} = invalidImageInfo;
  const {scale = 1} = fileInfo;
  const imageMetadata = {
    ...invalidImageInfo,
    ...fileInfo,
  };

  if (!isValidImage) {
    analytics.track('reportInvalidImage', {
      imageDebugInfo: imageMetadata,
    });
  }

  if (pixelCount > 0) {
    analytics.track('imageContainsBlackPixel', {
      // Keeping these 2 as separate fields to maintain previous data format
      pixelCount,
      scale,
      imageDebugInfo: imageMetadata,
    });
  }
};

/**
 * Stop the document auto capture.
 * You may specify an error to indicate that the auto capture is stopped due to
 * an error.
 * @returns Whether the action is successful.
 */
export const stopDocumentAutoCaptureActionEnqueued: ApplicationActionWithPayload<{
  error?: Error | null;
}> = async (controller, payload) => {
  try {
    if (documentAutoCaptureTimerId) {
      clearTimeout(documentAutoCaptureTimerId);
      documentAutoCaptureTimerId = null;
    }

    controller.update((draft) => {
      draft.document.autoCaptureStatus = payload.error
        ? AutoCaptureStatus.failed
        : AutoCaptureStatus.stopped;
    });

    return true;
  } catch (ex) {
    const error = asError(ex);
    // Report the error to Sentry.
    handleException(error, error.message);
    controller.update((draft) => {
      draft.document.autoCaptureStatus = AutoCaptureStatus.failed;
    });
    return false;
  }
};

/**
 * Upload the working document image.
 * @param controller The application controller.
 * @returns Whether the action is successful.
 */
export const uploadWorkingDocumentImageAction: ApplicationAction = async (
  controller,
) => {
  const {workingSide, workingInputMethod} = controller.state.document;
  try {
    const {session} = controller.state;
    const token = Storage.getSessionAPIKey();

    if (!session) {
      throw new Error(ErrorCode.sessionIsEmpty);
    }

    if (!token) {
      throw new Error(ErrorCode.sessionIsClosed);
    }

    const {uploadState, inspectionState} =
      controller.state.document[workingSide];

    if (!uploadState || !inspectionState) {
      throw new Error(ErrorCode.illegalDocumentUploadState);
    }

    const {
      cameraInfo,
      documentBlurScore,
      documentImage,
      documentLocation,
      documentType,
      feedback,
      idInspectorResult,
      inputImage,
      iterationCount,
      microBlinkCaptureInspectorResult,
      side,
    } = inspectionState;

    if (!documentImage) {
      // No image to upload.
      throw new Error(ErrorCode.illegalDocumentUploadState);
    }

    if (
      (uploadState.pending || uploadState.done) &&
      uploadState.image === documentImage
    ) {
      // The image is either already uploaded or is being uploaded.
      // Skip this action.
      return false;
    }

    if (uploadState) {
      controller.update((draft) => {
        shallowMergeInto(draft.document[workingSide].uploadState!, {
          done: false,
          image: documentImage,
          pending: true,
          startedAt: Date.now(),
        });
      });
    }

    const {id, livemode, branding} = session;
    const isStripe = branding?.isStripe;

    // Will use the live image if the user is in livemode or is a Stripe user.
    // Otherwise, do not upload PII on the external client side in testmode.
    const willUseLiveImage = livemode || isStripe;
    let invalidImageInfoPromise;
    let scale: number | undefined;

    let documentBlob: Blob;
    if (willUseLiveImage) {
      scale = documentImage.computeMinimumFitToScale(
        DOC_FRAUD_MODEL_IMAGE_MIN_WIDTH,
        DOC_FRAUD_MODEL_IMAGE_MIN_HEIGHT,
      );
      if (experiments.isActive('rescale_images') && scale > 1) {
        // Sometimes the cropped image could be too small, which may have an
        // impact on model training.
        // Currently, there is no impact in production because the production
        // doc fraud still has a minimum image size of (200, 200) in serving.
        // However, this prevents promoting new shadow models since the shadow
        // models have a minimum image size of (448, 576) in serving and are
        // trained on images with size (h:448, w:576).
        // To address this, we need to scale up the image to help the backend
        // train their models with the image.
        const scaledImage = await documentImage.scaleTo(scale);
        invalidImageInfoPromise = scaledImage.maybeTrackImageIssues({
          enabled: flags.isActive('idprod_log_black_pixel_count'),
        });
        documentBlob = await scaledImage.toBlob(DOCUMENT_IMAGE_QUALITY);
        analytics.track('imageScaled', {
          before: `width: ${documentImage.width} height: ${documentImage.height}`,
          after: `width: ${scaledImage.width} height: ${scaledImage.height}`,
          scale,
          reason: 'uploadWorkingDocumentImageAction',
        });
        scaledImage.dispose();
      } else {
        invalidImageInfoPromise = documentImage.maybeTrackImageIssues({
          enabled: flags.isActive('idprod_log_black_pixel_count'),
        });
        documentBlob = await documentImage.toBlob(DOCUMENT_IMAGE_QUALITY);
        analytics.track('imageNotScaled', {
          width: documentImage.width,
          height: documentImage.height,
          reason: 'uploadWorkingDocumentImageAction',
        });
      }
    } else {
      const filePath = side === 'front' ? testmodeFront : testmodeBack;
      try {
        documentBlob = await fetchBlobFromFile(filePath);
      } catch (ex) {
        // In case the test image is not available, we'll create an empty image
        // as fallback.
        const cause = asError(ex);
        const error = new Error(ErrorCode.failedToFetchTestImage, {cause});
        handleException(error, cause.message);

        const emptyImage = await ImageFrame.createEmpty(300, 200, '#cccccc');
        documentBlob = await emptyImage.toBlob();
        emptyImage.dispose();
      }
    }

    // TODO: Compress the image if it is too large.

    const documentFrameType = 'user_upload';

    const {apolloClient} = controller.runtime!;

    if (
      side === 'front' &&
      session?.collectedData.individual.idDocument.front
    ) {
      // When the user is uploading the front image, we need to clear the old
      // front and back images first if they exist.
      // This ensures that the collected data matches the latest uploaded
      // images.
      await clearDataFieldsMutation(apolloClient, {
        clearIdDocumentFront: true,
        clearIdDocumentBack: true,
      });
    }

    if (side === 'back' && session?.collectedData.individual.idDocument.back) {
      // Reset the back image before uploading the new one.
      await clearDataFieldsMutation(apolloClient, {
        clearIdDocumentFront: false,
        clearIdDocumentBack: true,
      });
    }

    const uploadStartTime = Date.now();

    const documentFile = await uploadFile({
      blob: documentBlob,
      createLink: false,
      fileName: `${id}_${workingSide}_${workingInputMethod}_${documentFrameType}.jpg`,
      frameType: documentFrameType,
      host: getUploadHost(),
      isSelfie: false,
      livemode: !!livemode,
      ownedBy: id,
      token,
      uploadMethod: workingInputMethod,
    });
    // We want to move this down to after the file API has been called to attach the
    // analytics event to the file token. Don't block on this validation.
    if (invalidImageInfoPromise) {
      invalidImageInfoPromise.then((invalidImageInfo) => {
        if (invalidImageInfo) {
          maybeTrackInvalidImageInfo(invalidImageInfo, {
            id: documentFile.id,
            type: documentFile.type,
            size: documentFile.size,
            frameType: documentFrameType,
            scale,
          });
        }
      });
    }

    const boundingBox = documentLocation
      ? [
          documentLocation.topLeft[0],
          documentLocation.topLeft[1],
          documentLocation.topLeft[0] + documentLocation.dimensions[0],
          documentLocation.topLeft[1] + documentLocation.dimensions[1],
        ]
      : null;

    const mbWasmDecoded = !!microBlinkCaptureInspectorResult?.isValid;
    const documentProbability = idInspectorResult?.probability;

    // Whether Microblink is effectively supported. Value could be 0 or 1.
    let mbSupportScore;

    if (microBlinkCaptureInspectorResult) {
      // If we could get any result from Microblink, we'll consider that the
      // Microblink is supported.
      mbSupportScore = 1;
    } else {
      // Microblink did not work. Likely to be one of these issues:
      // 1. Old browser that does not support WebAssembly.
      // 2. Slow network that prevents the ML assets from being downloaded.
      // 3. Runtime error in the ML code.
      mbSupportScore = 0;
    }

    const modelData: DocumentModelDataInput = {
      back: documentProbability?.back ?? 0,
      bbox: boundingBox,
      blurScore: documentBlurScore,
      blurScoreVariance: 0, // TODO(hedger): Compute this value.
      darknessScore: 0, // TODO(hedger): Compute this value.
      detectionState: feedback,
      frames: iterationCount,
      frontCard: documentProbability?.frontCard ?? 0,
      inputSize: [inputImage.width, inputImage.height],
      invalid: documentProbability?.invalid && !mbWasmDecoded ? 1 : 0,
      mbScore: 0, // The unused Microblink Plausibility score.
      mbScoreVariance: 0,
      mbSupportScore,
      mbWasmDecoded,
      noDocument: documentProbability?.noDocument ?? 0,
    };

    // If the frontend is unable to determine the document type, we'll use the
    // default document type. This is unlikely to happen though.
    const detectedType = documentType || 'id_card';

    const response = await sendDocumentImageMutation(
      controller.runtime!.apolloClient,
      {
        frameType: documentFrameType,
        fileData: {
          cameraLabel: cameraInfo?.cameraLabel,
          ci: cameraInfo ? window.btoa(toJsonUTF8(cameraInfo)) : undefined,
          detectedType,
          file: documentFile.id,
          isFront: workingSide === 'front',
          modelData,
          uploadMethod: workingInputMethod,
        },
      },
    );

    if (workingSide === 'front') {
      // After uploading the front image, the backend will erase the metadata
      // of the both sides. We need to save the document type before proceeding
      // otherwise the required field "document_metata" will be missing.
      await updateDocumentMetadataMutation(apolloClient, {
        documentMetadata: {
          type: detectedType,
        },
      });
    }

    if (response?.errors) {
      // Mutation failed.
      throw new Error(ErrorCode.failedToSendDocumentImage);
    }

    const validationErrors =
      response?.data?.captureDocumentImageFrame.validationErrors || [];

    const previousValidationErrors =
      controller.state.document[workingSide].reviewState?.validationErrors ||
      [];

    // Track the file upload event.
    analytics.track('fileUploaded', {
      file: documentFile.id,
      fileSize: documentBlob.size,
      frameType: documentFrameType,
      modelData: JSON.stringify(modelData),
      uploadMethod: workingInputMethod,
      uploadTime: Date.now() - uploadStartTime,
      inputWidth: modelData.inputSize?.[0],
      inputHeight: modelData.inputSize?.[1],
      side,
      validationErrors,
    });

    // Upload the full image for the backend to use.
    // We don't need to upload the full image if any of the following are true:
    // - this is a test mode verification
    // - the original image is the same as the cropped/processed image
    // - the BE returned a hard_block or restart_collection validation error
    const uploadFullImage =
      willUseLiveImage &&
      inputImage &&
      inputImage !== documentImage &&
      !validationErrors?.some((error) => error.hardBlock);
    if (uploadFullImage) {
      const [width, height] = FULL_FRAME_MAX_DIMENSIONS;
      const fullImage = await inputImage.fitToContain(width, height);
      const fullBlob = await fullImage.toBlob(FULL_FRAME_IMAGE_QUALITY);
      const frameType = 'full_frame';
      const uploadMethod = workingInputMethod;
      const fileName = `${id}_${workingSide}_${uploadMethod}_${frameType}.jpg`;
      const invalidFullImageInfoPromise = fullImage.maybeTrackImageIssues({
        enabled: flags.isActive('idprod_log_black_pixel_count'),
      });

      await uploadFile({
        blob: fullBlob,
        createLink: false,
        fileName,
        frameType,
        host: getUploadHost(),
        isSelfie: false,
        livemode: !!livemode,
        ownedBy: id,
        token,
        uploadMethod,
      })
        .catch((ex) => {
          const cause = asError(ex);
          handleException(
            new Error(ErrorCode.failedToUploadFile, {cause}),
            cause.message,
          );
        })
        .then((uploadedFile) => {
          if (!uploadedFile) {
            return;
          }
          analytics.track('fileUploaded', {
            file: uploadedFile.id,
            fileSize: documentBlob.size,
            frameType,
            uploadMethod: workingInputMethod,
            uploadTime: Date.now() - uploadStartTime,
          });

          invalidFullImageInfoPromise.then((invalidFullImageInfo) => {
            if (invalidFullImageInfo) {
              maybeTrackInvalidImageInfo(invalidFullImageInfo, {
                id: uploadedFile.id,
                type: uploadedFile.type,
                size: uploadedFile.size,
                frameType,
              });
            }
          });

          if (!controller.state.session?.closed) {
            return sendDocumentImageMutation(controller.runtime!.apolloClient, {
              frameType: 'full_frame',
              fileData: {
                cameraLabel: cameraInfo?.cameraLabel,
                ci: cameraInfo
                  ? window.btoa(toJsonUTF8(cameraInfo))
                  : undefined,
                detectedType,
                file: uploadedFile.id,
                isFront: workingSide === 'front',
                modelData,
                uploadMethod: workingInputMethod,
              },
            });
          }
        })
        .catch((ex) => {
          const cause = asError(ex);
          handleException(
            new Error(ErrorCode.failedToSendDocumentImage, {cause}),
            cause.message,
          );
        })
        .finally(() => {
          fullImage.dispose();
        });
    }

    // Refresh the session to update the validation result.
    // When confirming the image we use the file token from the session.
    const refreshed = await refreshSessionAction(controller);
    if (!refreshed) {
      throw new Error(ErrorCode.failedToRefreshSession);
    }

    controller.update((draft) => {
      draft.document.workingStep = UserStep.reviewImage;

      draft.document[workingSide].uploadedCount += 1;

      shallowMergeInto(draft.document[workingSide].uploadState!, {
        done: true,
        pending: false,
      });
      draft.document[workingSide].reviewState = {
        createdAt: Date.now(),
        error: null,
        pending: false,
        validationErrors,
        previousValidationErrors,
      };
    });

    logUploadedDocumentImage(controller, 'uploadedDocumentImage');

    return true;
  } catch (ex) {
    const error = asError(ex);
    controller.update((draft) => {
      const uploadState = draft.document[workingSide].uploadState;
      if (uploadState) {
        uploadState.pending = false;
        uploadState.error = error;
      } else {
        draft.error = error;
      }
    });
    return false;
  }
};

/**
 * Clear the working document and capture another one again.
 * @param controller The application controller.
 * @returns Whether the action is successful.
 */
export const retakeWorkingDocumentImageAction: ApplicationAction = async (
  controller,
) => {
  try {
    if (controller.state.document.workingStep !== UserStep.reviewImage) {
      throw new Error(ErrorCode.invalidStep);
    }
    controller.update((draft) => {
      const {workingSide} = draft.document;
      draft.document.workingStep = UserStep.collectImage;

      // dispose the old document image captured.
      const documentImage =
        draft.document[workingSide].inspectionState?.documentImage;
      const inputImage =
        draft.document[workingSide].inspectionState?.inputImage;

      documentImage?.dispose();
      inputImage?.dispose();

      // Do not clear the "uploadState" which will be used to display the
      // previous image for the transition animation later.
      shallowMergeInto(draft.document[workingSide], {
        inspectionState: null,
      });
    });

    logUploadedDocumentImage(controller, 'retakeDocumentImage');
    return true;
  } catch (ex) {
    controller.update((draft) => {
      draft.error = asError(ex);
    });
    return false;
  }
};

/**
 * Perform the action to confirm the working document image.
 * @param controller The application controller.
 */
export const confirmWorkingDocumentImageAction: ApplicationAction = async (
  controller,
) => {
  try {
    const {workingStep, workingSide} = controller.state.document;
    if (workingStep !== UserStep.reviewImage) {
      throw new Error(ErrorCode.invalidStep);
    }
    let fileId: string | null | undefined;

    if (workingSide === 'front') {
      fileId =
        controller.state.session?.collectedData.individual.idDocument.front;
    } else if (workingSide === 'back') {
      fileId =
        controller.state.session?.collectedData.individual.idDocument.back;
    }

    if (!fileId) {
      // We need the file uploaded first so user can confirm it.
      throw new Error(ErrorCode.fileDoesNotExist);
    }

    await confirmDocumentImagesMutation(controller.runtime!.apolloClient, {
      confirmFile: fileId,
      confirmSide: workingSide,
    });

    // Refresh the session to update the "missingFields" and "status".
    const refreshed = await refreshSessionAction(controller);

    if (!refreshed) {
      throw new Error(ErrorCode.failedToRefreshSession);
    }

    return proceedToNextDocumentUploadStepAction(controller);
  } catch (ex) {
    controller.update((draft) => {
      draft.error = asError(ex);
    });
    return false;
  }
};

/**
 * Perform the action to restart the document upload step.
 * @param controller The application controller.
 */
export const restartDocumentUploadStepAction: ApplicationAction = async (
  controller,
) => {
  controller.update((draft) => {
    draft.document.workingStep = UserStep.warmup;
    draft.document.workingSide = 'front';
    draft.document.front.inspectionState?.documentImage?.dispose();
    draft.document.front.inspectionState?.inputImage.dispose();
    draft.document.front.uploadState?.previewImage?.dispose();
    draft.document.front = {
      inspectionState: null,
      reviewState: null,
      uploadState: null,
      // Remember how many files that had been uploaded previously.
      uploadedCount: draft.document.front.uploadedCount,
    };
    draft.document.back.inspectionState?.documentImage?.dispose();
    draft.document.back.inspectionState?.inputImage.dispose();
    draft.document.back.uploadState?.previewImage?.dispose();
    draft.document.back = {
      inspectionState: null,
      reviewState: null,
      uploadState: null,
      // Remember how many files that had been uploaded previously.
      uploadedCount: draft.document.back.uploadedCount,
    };
  });
};

/**
 * Perform the action to proceed to the next step of the document upload.
 * @param controller The application controller.
 */
export const proceedToNextDocumentUploadStepAction: ApplicationAction = async (
  controller,
) => {
  try {
    const {workingSide, workingStep} = controller.state.document;
    let reasonToLeave: RouteChangeReason | null = null;

    analytics.action('proceededToDocumentUploadStep', {
      reason: 'proceedToNextDocumentUploadStepAction',
      side: workingSide,
      step: workingStep,
      state: {
        sessionDocument:
          controller.state.session?.collectedData.individual.idDocument,
        missingFields: controller.state.session?.missingFields,
        requiredFields: controller.state.session?.requiredFields,
        stateDocument: controller.state.document,
      },
    });

    if (workingStep === UserStep.reviewImage) {
      controller.update((draft) => {
        const {workingStep} = draft.document;
        const {front, back, docTypeHasMbDecodableBack, forceSkipBackImage} =
          draft.session?.collectedData.individual.idDocument || {};

        if (!isFieldNeeded(draft, 'id_document_images')) {
          // We have all the images we need, we can move to the next page.
          reasonToLeave = 'leave_document_upload_with_all_images';
        } else if (!front) {
          // We need the front image.
          draft.document.workingStep = UserStep.collectImage;
          draft.document.workingSide = 'front';
        } else if (!back) {
          if (forceSkipBackImage || docTypeHasMbDecodableBack === false) {
            // We can skip the back image and route to the next page if either:
            // 1. We don't need the back image.
            // 2. We know that the document type does not have a back image that
            //    can be decoded by Microblink.
            reasonToLeave = 'leave_document_upload_without_all_images';
          } else if (workingStep === UserStep.warmup) {
            // We need the back image.
            // We can collect the back image directly after the warmup.
            draft.document.workingStep = UserStep.collectImage;
            draft.document.workingSide = 'back';
          } else {
            // Warm up the user before collecting the back image.
            draft.document.workingStep = UserStep.warmup;
            draft.document.workingSide = 'back';
          }
        } else {
          // Should never reach here.
          throw new Error(ErrorCode.illegalDocumentUploadState);
        }
      });
    } else if (workingStep === UserStep.warmup) {
      // We have warmed up the user, we can collect the image now.
      controller.update((draft) => {
        draft.document.workingStep = UserStep.collectImage;
      });
    } else {
      // Step that is currently not supported.
      throw new Error(ErrorCode.invalidStep);
    }

    if (reasonToLeave) {
      await routeToNextPage(
        controller.state,
        controller.runtime!,
        reasonToLeave,
        undefined,
        'documentActions.proceedToNextDocumentUploadStepAction',
      );
    }

    return true;
  } catch (ex) {
    controller.update((draft) => {
      draft.error = asError(ex);
    });
    return false;
  }
};

/**
 * Check the integrity of the application state before starting the auto
 * capture.
 */
function validateStateForAutoCapture(state: ApplicationState): void {
  if (state.document.workingStep !== UserStep.collectImage) {
    throw new Error(ErrorCode.invalidStep);
  }

  if (state.document.workingInputMethod !== InputMethod.cameraAutoCapture) {
    throw new Error(ErrorCode.invalidInputMethod);
  }

  if (state.camera.ready !== true) {
    throw new Error(ErrorCode.cameraIsNotReady);
  }
}

/**
 * Helper function to get the next inspection state during the auto capture.
 * iteration.
 * @param appState The current application state.
 * @returns The next inspection state.
 */
async function getNextInspectionState(
  appState: ApplicationState,
): Promise<InspectionState> {
  validateStateForAutoCapture(appState);

  const {camera, document} = appState;
  const {video} = camera;
  const {workingSide} = document;
  const videoEl = video!;
  const cameraInfo = getCameraInfo(appState);

  let inputImage: ImageFrame;
  let feedback: FeedbackValue | null = null;

  if (isVideoElementReady(video)) {
    inputImage = await ImageFrame.fromVideoElement(videoEl);
  } else {
    // The video element is not ready. Use a black image as the input.
    inputImage = await ImageFrame.createEmpty(1, 1, '#000');
    // Do not throw an error here, since the video element may be ready later.
    // This feedback is transient and it should recover automatically later.
    // Otherwise, it will end up with the timeout error.
    feedback = Feedback.cameraVideoIsNotReady;
  }

  const createdAt = Date.now();
  const previousInspectionState =
    appState.document[workingSide].inspectionState;

  const previousFeedback = previousInspectionState?.feedback
    ? {
        createdAt: previousInspectionState?.createdAt!,
        feedback: previousInspectionState?.feedback!,
      }
    : null;

  const previousFeedbacks =
    previousInspectionState?.previousFeedbacks.slice(0) ?? [];

  if (previousFeedback) {
    // Newer feedback should be at the front of the array.
    previousFeedbacks.unshift(previousFeedback);
  }
  while (previousFeedbacks.length > MAX_PREVIOUS_FEEDBACKS_LENGTH) {
    previousFeedbacks.pop();
  }

  const startedAt = previousInspectionState
    ? previousInspectionState.startedAt
    : createdAt;

  const timeoutDuration =
    workingSide === 'front' ? FRONT_TIMEOUT_DURATION : BACK_TIMEOUT_DURATION;
  const timeoutAt = previousInspectionState
    ? previousInspectionState.timeoutAt
    : createdAt + timeoutDuration;

  const iterationCount = previousInspectionState
    ? previousInspectionState.iterationCount + 1
    : 1;

  const id = previousInspectionState
    ? previousInspectionState.id
    : createInspectionStateId();

  const inspectionState = produce<InspectionState>(
    createInspectionState({
      cameraInfo,
      createdAt,
      feedback,
      id,
      inputImage,
      iterationCount,
      previousFeedbacks,
      side: workingSide,
      startedAt,
      timeoutAt,
    }),
    () => {},
  );

  return inspectDocument(appState, inspectionState);
}

/**
 * Helper function to dispose the inspection state.
 * @param inspectionState The inspection state to dispose.
 */
function disposeInspectionState(inspectionState: InspectionState): void {
  const {inputImage, documentImage, disposed} = inspectionState;
  if (!disposed) {
    inputImage.dispose();
    documentImage?.dispose();
    inspectionState.disposed = true;
  }
}

/**
 * Proceed to the upload step.
 * @param controller The application controller.
 * @param inspectionState The inspection state.
 */
async function proceedToUploadStep(
  controller: ApplicationController,
  inspectionState: Readonly<InspectionState>,
): Promise<void> {
  const {side, documentImage} = inspectionState;
  // The inspection is done. We can move to
  // the next step which also stops the auto capture.

  // Initialize the preview image before advancing to the next step.
  const previewImage = await toPreviewImage(documentImage!);

  controller.update((draft) => {
    // Prepare the upload state.
    const oldUploadState = draft.document[side].uploadState;
    // Dispose the old preview image.
    oldUploadState?.previewImage?.dispose();

    const uploadState: UploadState = {
      done: false,
      error: null,
      image: null,
      pending: false,
      previewImage,
      startedAt: null,
    };

    // Now the image is ready for upload and will be reviewed after
    // uploading.
    draft.document[side].inspectionState = inspectionState;
    draft.document[side].uploadState = uploadState;
    draft.document.workingStep = UserStep.uploadImage;
  });
}

/**
 * Proceed to the review step.
 * @param controller The application controller.
 * @param inspectionState The inspection state.
 */
async function proceedToReviewStep(
  controller: ApplicationController,
  inspectionState: Readonly<InspectionState>,
): Promise<void> {
  const {side, documentImage} = inspectionState;
  // The inspection is done. We can move to
  // the next step which also stops the auto capture.

  // Prepare the preview image before moving to the next step.
  const previewImage = await toPreviewImage(documentImage!);

  controller.update((draft) => {
    // Prepare the upload state.
    const oldUploadState = draft.document[side].uploadState;
    oldUploadState?.previewImage?.dispose();

    const uploadState: UploadState = {
      done: false,
      error: null,
      image: null,
      pending: false,
      previewImage,
      startedAt: null,
    };

    // Now the image is ready for upload and will be reviewed after
    // uploading.
    draft.document[side].uploadState = uploadState;
    draft.document.workingStep = UserStep.reviewImage;
  });
}

/**
 * Helper function to ensure that the document image is set.
 * @param inspectionState The inspection state to ensure.
 * @returns The inspection state with the document image set.
 */
async function ensuresDocumentImage(
  controller: ApplicationController,
  inspectionState: InspectionState,
): Promise<InspectionState> {
  let nextState = inspectionState;
  // When auto capture failed to run, it's likely that the document is
  // not capture. Therefore, we should use the full image as the document.
  // This applies to both manual capture and file upload flows.
  const {documentImage, inputImage} = nextState;
  if (!documentImage) {
    // If we did not detect the document, we can use the full image as the
    // document image and let the backend to handle the cropping.
    const documentImage = await inputImage.clone();
    nextState = produce(nextState, (draft) => {
      draft.documentImage = documentImage;
    });
    const {side} = nextState;

    controller.update((draft) => {
      draft.document[side].inspectionState = nextState;
    });
  }

  return nextState;
}

/**
 * Helper function to check if the video element is ready to be used as a source
 * for a canvas.
 * @param video The video element to check.
 * @returns Whether the video element is ready.
 */
export function isVideoElementReady(video: HTMLVideoElement | null): boolean {
  try {
    checkWebcamVideo(video);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Helper function to extract the active device info from the stream.
 * @param state The application state.
 * @returns The active device info.
 */
function getCameraInfo(state: ApplicationState): CameraInfo | null {
  const {stream} = state.camera;
  const deviceId = stream ? getActiveDeviceId(stream) : null;
  const device = state.cameraDeviceList.find((d) => d.deviceId === deviceId);
  const cameraLabel = device?.label ?? undefined;
  let capabilities;
  let error;
  try {
    const track = stream?.getVideoTracks()[0]!;
    // Firefox which does not support the "track.getCapabilities()" API.
    capabilities = track.getCapabilities ? track?.getCapabilities() : undefined;
  } catch (ex) {
    error = asError(ex);
  }
  return {cameraLabel, capabilities, error};
}

/**
 * Log the information that helps us understand about the image uploaded or
 * retaken. This should only be called after the image is uploaded or retaken.
 * @param controller The application controller.
 */
function logUploadedDocumentImage(
  controller: ApplicationController,
  eventName: 'retakeDocumentImage' | 'uploadedDocumentImage',
) {
  const {document} = controller.state;
  const {workingSide, workingInputMethod} = document;
  const validationErrors = document[workingSide].reviewState?.validationErrors;
  const feedback = document[workingSide].inspectionState?.feedback;

  const probability =
    document[workingSide].inspectionState?.idInspectorResult?.probability;

  const fileId =
    controller.state.session?.collectedData.individual.idDocument[workingSide];

  const payload = {
    feedback,
    file: fileId,
    probability: probability ? JSON.stringify(probability) : null,
    side: workingSide,
    uploadMethod: workingInputMethod,
    validationErrors: (validationErrors || [])
      .slice(0)
      .map((err) => err.type)
      .sort(),
  };

  analytics.action(eventName, payload);
}
