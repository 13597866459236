type GoogleAnalytics = (...args: Array<unknown>) => void | null | undefined;

const defaultAllowList = {
  // required parameters
  client_id: true,
  created: true,
  event_id: true,
  event_name: true,
  // parameters included by Analytics.js
  action_name: true,
  analytics_ua: true,
  arb_id: true,
  cid: true,
  domain: true,
  event_count: true,
  flags: true,
  link_name: true,
  lsid: true,
  merchant_id: true,
  modal_name: true,
  // `page` is redacted because it includes the query portion of the URL, which often contains PII (e.g. /search?query=kyeb@example.com)
  page: false,
  page_view_id: true,
  privacy_consent_loaded: true,
  privacy_consent_functional: true,
  privacy_consent_advertising: true,
  referrer: true,
  sid: true,
  stripe_locale: true,
  // user environment params
  ua_browser_name: true,
  ua_browser_version: true,
  ua_device_model: true,
  ua_device_type: true,
  ua_device_vendor: true,
  ua_engine_name: true,
  ua_engine_version: true,
  ua_mobile: true,
  ua_model: true,
  ua_os_name: true,
  ua_os_version: true,
  ua_platform: true,
  ua_platform_version: true,
  user_id: true,
  version: true,
  viewed_name: true,
  viewport_height: true,
  viewport_width: true,
} as const;

// CONFIG: Text to use when replacing email addresses and parameters to be removed
const EMAIL_PATTERN =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
const EMAIL_REPLACEMENT_PATTERN =
  /([a-zA-Z0-9\-.+_-`~!#$%^&*()]+(@|%40|%2540)[a-zA-Z0-9\-.+_-`~!#$%^&*()]+\.[a-zA-Z0-9.+_-`~!#$%^*()]+)/gi;

export const PARAM_REPLACE_TEXT: string = '[REDACTED]';

export const PII_PARAMS: Array<string> = [
  'address',
  'client_id',
  'description',
  'e',
  'firstname',
  'fname',
  'gender',
  'lastname',
  'lname',
  'location',
  'name',
  'nickname',
  'p',
  'prefill_email',
  'prefilled_email',
  'profileurl',
  'pwd',
  'query',
  'shipping.address[address-line2]',
  'shipping.address[address]',
  'shipping.address[country]',
  'shipping.address[locality]',
  'shipping.address[subregion]',
  'shipping.address[zip]',
  'shipping.phone',
  'stripe_user[city]',
  'stripe_user[country]',
  'stripe_user[dob_day]',
  'stripe_user[dob_month]',
  'stripe_user[dob_year]',
  'stripe_user[email]',
  'stripe_user[first_name]',
  'stripe_user[last_name]',
  'stripe_user[phone_number]',
  'stripe_user[state]',
  'stripe_user[street_address]',
  'stripe_user[zip]',
  'user',

  // Register page invite codes (see SAF-242)
  'account_invite',
  'invite',
  'invite_code',
];

export const pagePathScrubber = (
  pagePath: string,
  piiQueryParams: Array<string>,
  emailRemovedText: string,
): string => {
  // Looks for anything containing email and removes it
  const removeEmailPagePath = (pagePath: string, emailRemovedText: string) => {
    return pagePath.replace(EMAIL_REPLACEMENT_PATTERN, emailRemovedText);
  };

  const removePiiQueryParams = (
    query: string,
    pii: Array<string>,
    paramReplaceText: string,
  ) => {
    const isEmail = (email: string) => EMAIL_PATTERN.test(email);

    const queryArray = query.split(/[&;]/);
    const resultArray: Array<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | any
      | {
          index: number;
          name: string;
          value: string;
        }
    > = [];
    let result = '';

    // If no query return
    if (query === '') {
      return '';
    }

    // Check for PII names
    for (let i = 0; i < queryArray.length; i++) {
      const tempSplit = queryArray[i].split('=');
      const name = tempSplit[0];
      const value = tempSplit[1];

      // Check all PII names each time in case there are repeat values
      let include = true;
      for (let k = 0; k < pii.length; k++) {
        if (name.toLowerCase() === pii[k] || isEmail(name)) {
          include = false;
        }
      }
      if (include) {
        resultArray.push({
          name,
          value,
          index: i,
        });
      } else {
        resultArray.push({
          name,
          value: paramReplaceText,
          index: i,
        });
      }
    }

    // exit if no qp left
    if (resultArray.length < 1) {
      return result;
    }

    // Recompose query
    for (let i = 0; i < resultArray.length; i++) {
      result += resultArray[i].name;
      if (queryArray[resultArray[i].index].indexOf('=') > -1) {
        result += '=';
        result += resultArray[i].value;
      }

      // Don't add ampersand if at end query
      if (i !== resultArray.length - 1) {
        result += '&';
      }
    }

    return `?${result}`;
  };

  let cleanedPagePath = pagePath;
  const urlSplit = cleanedPagePath.split('?');
  const baseUrl = urlSplit[0];
  const qp =
    urlSplit.length > 1
      ? cleanedPagePath.replace(baseUrl, '').substring(1)
      : '';

  const cleanedQp = removePiiQueryParams(
    qp,
    piiQueryParams,
    PARAM_REPLACE_TEXT,
  );

  cleanedPagePath = baseUrl + cleanedQp;
  cleanedPagePath = removeEmailPagePath(cleanedPagePath, emailRemovedText);

  return cleanedPagePath;
};

export const cleanPagePath = <T extends string | null | undefined>(
  pagePath: T,
): T => {
  return pagePath == null
    ? pagePath
    : (pagePathScrubber(pagePath, PII_PARAMS, PARAM_REPLACE_TEXT) as T);
};

// Accepts full URL or URI stem and returns same with cleaned query string and/or hash identifier
export const setupScrubber = (ga: GoogleAnalytics): void => {
  // There is no reason this should
  // ever be false, but we want to be
  // *extra* safe during the init process
  if (ga) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ga((tracker: any) => {
      // Again, this should never be false
      // but better to be safe
      if (tracker) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tracker.set('customTask', function (model: any) {
          // Gets fieldname values
          const location = model.get('location');
          const pagePath = model.get('page') || undefined;
          const referrer = model.get('referrer') || undefined;

          // Sets fieldnames to PII scrubbed field
          model.set('location', cleanPagePath(location));
          model.set('page', cleanPagePath(pagePath));
          model.set('referrer', cleanPagePath(referrer));
        });
      }
    });
  }
};

// This redacts the value for any key that isn't explicitly allow-listed.
export const scrubValues = (
  params: {
    [key: string]: unknown;
  },
  allowList?: {
    [key: string]: boolean;
  },
): void => {
  for (const k in params) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly client_id: true; readonly created: true; readonly event_id: true; readonly event_name: true; readonly action_name: true; readonly analytics_ua: true; readonly arb_id: true; ... 16 more ...; readonly viewport_width: true; }'.
    if (params.hasOwnProperty(k) && !defaultAllowList[k]) {
      if (!allowList || !allowList[k]) {
        params[k] = PARAM_REPLACE_TEXT;
      }
    }
  }
};
