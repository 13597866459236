/**
 * @fileoverview Actions that manage the mutation state for the controller.
 * All mutations actions should be defined here.
 */

import {OTPMode} from 'gelato/frontend/src/controllers/states/IndividualState';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';

import type {
  ConsentDataInput,
  DocumentTypes,
} from '@stripe-internal/data-gelato/schema/types';

export type MutationValue<T> = {
  createdAt: number | null;
  error: Error | null;
  pending: boolean;
  value: T;
};

export type EmailHandoffValue = MutationValue<string | null>;
export type HandoffUrlValue = MutationValue<string | null>;
export type SMSHandoffValue = MutationValue<string | null>;
export type ConsentValue = MutationValue<ConsentDataInput | null>;
export type StartValue = MutationValue<boolean | null>;
export type DocumentTypeValue = MutationValue<DocumentTypes | null>;
export type SubmitValue = MutationValue<boolean | null>;
export type UpdateIndividualValue = MutationValue<boolean | null>;
export type OTPValue = MutationValue<Partial<Record<OTPMode, boolean>> | null>;

export type MutationState = {
  mutation: {
    consent: ConsentValue;
    documentType: DocumentTypeValue;
    emailHandoff: EmailHandoffValue;
    handoffUrl: HandoffUrlValue;
    smsHandoff: SMSHandoffValue;
    start: StartValue;
    submit: SubmitValue;
    updateIndividual: UpdateIndividualValue;
    generateOTP: OTPValue;
    validateOTP: OTPValue;
  };
};

type State = MutationState;

/**
 * Creates mutation state like pending, value, error.
 * @returns The initial mutation state.
 */
export function createMutationState(): State {
  return {
    mutation: {
      consent: {
        createdAt: null,
        error: null,
        pending: false,
        value: null,
      },
      documentType: {
        createdAt: null,
        error: null,
        pending: false,
        value: null,
      },
      emailHandoff: {
        createdAt: null,
        error: null,
        pending: false,
        value: null,
      },
      handoffUrl: {
        createdAt: null,
        error: null,
        pending: false,
        value: null,
      },
      smsHandoff: {
        createdAt: null,
        error: null,
        pending: false,
        value: null,
      },
      start: {
        createdAt: null,
        error: null,
        pending: false,
        value: null,
      },
      submit: {
        createdAt: null,
        error: null,
        pending: false,
        value: null,
      },
      updateIndividual: {
        createdAt: null,
        error: null,
        pending: false,
        value: null,
      },
      generateOTP: {
        createdAt: null,
        error: null,
        pending: false,
        value: null,
      },
      validateOTP: {
        createdAt: null,
        error: null,
        pending: false,
        value: null,
      },
    },
  };
}

/**
 * Updates the consent state.
 * @param state The current state.
 * @param consent The new consent state.
 */
export function setConsent(state: State, consent: ConsentValue) {
  shallowMergeInto(state.mutation.consent, consent);
}

/**
 * Updates the handoff URL state.
 * @param state The current state.
 * @param handoffUrl The new handoff URL state.
 */
export function setHandoffUrl(state: State, handoffUrl: HandoffUrlValue) {
  shallowMergeInto(state.mutation.handoffUrl, handoffUrl);
}

/**
 * Updates the email handoff state.
 * @param state The current state.
 * @param emailHandoff The new email handoff state.
 */
export function setEmailHandoff(state: State, emailHandoff: EmailHandoffValue) {
  shallowMergeInto(state.mutation.emailHandoff, emailHandoff);
}

/**
 * Updates the SMS handoff state.
 * @param state The current state.
 * @param smsHandoff The new SMS handoff state.
 */
export function setSMSHandoff(state: State, smsHandoff: SMSHandoffValue) {
  shallowMergeInto(state.mutation.smsHandoff, smsHandoff);
}

/**
 * Updates the SMS handoff state.
 * @param state The current state.
 * @param start The new start state.
 */
export function setStart(state: State, start: StartValue) {
  shallowMergeInto(state.mutation.start, start);
}

/**
 * Updates the verification submit state.
 * @param state The current state.
 * @param start The new start state.
 */
export function setSubmit(state: State, submitValue: SubmitValue) {
  shallowMergeInto(state.mutation.submit, submitValue);
}

/**
 * Updates the verification update individual mutation state.
 * @param state The current state.
 * @param updateValue Whether or not there has been a successful update
 */
export function setUpdateIndividual(
  state: State,
  updateValue: UpdateIndividualValue,
) {
  shallowMergeInto(state.mutation.updateIndividual, updateValue);
}

/**
 * Updates the generate OTP mutation state.
 * @param state The current state.
 * @param updateValue Whether or not there has been a successful update
 */
export function setGenerateOTP(state: State, value: OTPValue) {
  shallowMergeInto(state.mutation.generateOTP, value);
}

/**
 * Updates the validate OTP mutation state.
 * @param state The current state.
 * @param value Whether or not there has been a successful update
 */
export function setValidateOTP(state: State, value: OTPValue) {
  shallowMergeInto(state.mutation.validateOTP, value);
}
