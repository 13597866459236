import {useEffect} from 'react';

import {importModules} from 'gelato/frontend/src/lib/routes';

/**
 * Preload the page(s) for future transition. This is used to improve the UX by
 * being able to load the page in advance so the page transition animation
 * could be smoother.
 */
export default function usePreloadPagesForNextPageTransition() {
  // TODO: This loads all pages in advance. We should only load the page that
  // we are going to transition to.
  useEffect(() => {
    importModules();
  }, []);
}
