// Shim for requestIdleCallback/cancelIdleCallback
// Extracted from https://github.com/behnammodi/polyfill/blob/e27dd5a590ff8c2b9f4b34cf307e76efdc239e14/window.polyfill.js

export function requestIdleCallback(
  callback: Parameters<typeof globalThis.requestIdleCallback>[0],
  options: Parameters<typeof globalThis.requestIdleCallback>[1] = {},
): ReturnType<(typeof globalThis)['requestIdleCallback']> {
  if (globalThis.requestIdleCallback) {
    return globalThis.requestIdleCallback(callback, options);
  }

  const relaxation = 1;
  const timeout = options.timeout || relaxation;
  const start = performance.now();

  return setTimeout(function () {
    callback({
      get didTimeout() {
        return options.timeout
          ? false
          : performance.now() - start - relaxation > timeout;
      },
      timeRemaining() {
        return Math.max(0, relaxation + (performance.now() - start));
      },
    });
  }, relaxation) as unknown as number;
}

export function cancelIdleCallback(
  id: Parameters<typeof globalThis.cancelIdleCallback>[0],
): void {
  if (globalThis.cancelIdleCallback) {
    return globalThis.cancelIdleCallback(id);
  }

  return clearTimeout(id);
}
