const shadow = 'shadow' as const;

export const aliasShadowBuiltIns = {
  boxShadow: shadow,
  focusRing: shadow,
  topShadow: shadow,
} as const;

export const aliasShadow = {
  ...aliasShadowBuiltIns,
} as const;
