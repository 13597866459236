import type {FocusStrategy} from '@react-types/shared';
import type {MenuTriggerProps} from '@react-types/menu';
import {useState} from 'react';
import type {OverlayTriggerState} from '../overlays';
import {useOverlayTriggerState} from '../overlays';

export interface MenuTriggerState extends OverlayTriggerState {
  /** Controls which item will be auto focused when the menu opens. */
  readonly focusStrategy: FocusStrategy | null;

  /** Opens the menu. */
  open(focusStrategy?: FocusStrategy | null): void;

  /** Toggles the menu. */
  toggle(focusStrategy?: FocusStrategy | null): void;
}

/**
 * Manages state for a menu trigger. Tracks whether the menu is currently open,
 * and controls which item will receive focus when it opens.
 */
export function useMenuTriggerState(props: MenuTriggerProps): MenuTriggerState {
  const overlayTriggerState = useOverlayTriggerState(props);
  const [focusStrategy, setFocusStrategy] = useState<FocusStrategy | null>(
    null,
  );

  return {
    focusStrategy,
    ...overlayTriggerState,
    open(focusStrategy: FocusStrategy | null = null) {
      setFocusStrategy(focusStrategy);
      overlayTriggerState.open();
    },
    toggle(focusStrategy: FocusStrategy | null = null) {
      setFocusStrategy(focusStrategy);
      overlayTriggerState.toggle();
    },
  };
}
