import {createCustomProperty} from '@sail/engine';
import type {Style} from '@sail/engine';

// Add an additional variable for vertical alignment based on font metrics
export const transformAlignY = createCustomProperty('--transform-align-y', {
  initialValue: '0',
});
const transformX = createCustomProperty('--transform-x', {
  initialValue: '0px',
});
const transformY = createCustomProperty('--transform-y', {
  initialValue: '0px',
});
const transformScale = createCustomProperty('--transform-scale', {
  initialValue: '1',
});
const transformRotate = createCustomProperty('--transform-rotate', {
  initialValue: '0deg',
});
const transform = createCustomProperty('--transform', {
  initialValue: 'scale(1)',
});

function createTransformPlugin(
  customProperty: Style.CustomProperty,
): Style.Plugin {
  return function transformPlugin(value, set) {
    set.property(customProperty, () => {
      set.property(
        'transform',
        // https://www.w3.org/TR/css-transforms-2/#ctm
        `translateY(${set.var(transformAlignY)})
        translate(${set.var(transformX)}, ${set.var(transformY)})
        rotate(${set.var(transformRotate)})
        scale(${set.var(transformScale)})
        ${set.var(transform)}`,
        {skipPlugin: true},
      );
      set.property(customProperty, value);
    });
  };
}

export const pluginsTransform = {
  [transformAlignY.intent.toString()]: createTransformPlugin(transformAlignY),
  x: createTransformPlugin(transformX),
  y: createTransformPlugin(transformY),
  scale: createTransformPlugin(transformScale),
  rotate: createTransformPlugin(transformRotate),
  transform: createTransformPlugin(transform),
};
