/**
 * Check if the client has the enable the permission of cameras in browser.
 */
export default async function isBrowserCameraPermissionEnabled(): Promise<boolean> {
  const mediaDevices = window.navigator.mediaDevices;

  if (!mediaDevices) {
    // "mediaDevices" is available only in secure contexts (HTTPS).
    return false;
  }

  if (!mediaDevices.enumerateDevices) {
    // "enumerateDevices" is available only in secure contexts (HTTPS).
    return false;
  }

  // Do not use `window.navigator.mediaDevices.getUserMedia()` to detect
  // whether the camera is enabled because it might prompt user to turn on the
  // camera (if user allows the site to prompt users) and that's the side-effect
  // we should avoid (we'd like the check to unobtrusive).

  // Do not use `window.navigator.permissions.query({ name: "camera" })` to
  // detect whether the camera is enabled because it has limited support in
  // Safari (see https://caniuse.com/?search=permissions.query)

  let devices: MediaDeviceInfo[] | undefined;

  try {
    devices = await mediaDevices.enumerateDevices();
  } catch (ex) {
    // Possible errors (most likely found from Android Webview):
    // - NotSupportedError: The browser does not support the media devices API.
    // - SecurityError: The browser does not allow the page to access the media devices.
    // - AbortError: The browser does not allow the page to access the media devices.
    // - NotFoundError: The browser cannot find the media devices.
    // - NotReadableError: The browser cannot read the media devices.
    // - NotAllowedError: The user has explicitly denied permission to use the media devices.
  }

  if (!devices) {
    return false;
  }

  return !!devices.find((info) => {
    // Note: For security reasons, the label or deviceId is always blank unless
    // an active media stream exists or the user has granted persistent
    // permission for media device access.
    // The set of device labels could otherwise be used as part of a
    // fingerprinting mechanism to identify a user.
    // We could use it to tell if the camera is enabled.
    // See https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo

    // Do not check `info.label` because it might be empty even if the camera
    // is enabled.
    // On Firefox, the device labels obtained from
    // navigator.mediaDevices.enumerateDevices() will also be set to the blank
    // string in the case where there is no more active MediaStream, even
    // though the application has been previously temporarily authorized to
    // access the devices by calling navigator.mediaDevices.getUserMedia().
    // See https://stackoverflow.com/questions/46648645 to learn more.
    return !!(info.kind === 'videoinput' && info.deviceId);
  });
}
