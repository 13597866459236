import * as React from 'react';
import {createViewConfig} from '@sail/engine';
import type {ReactNode, ElementType} from 'react';
import type {View} from '../view';

// Technically, this could be other h tags like h2/h3/etc but they
// should all have the same props
export type HeadingProps = View.IntrinsicElement<
  'h1',
  {
    /**
     * The [heading level](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements), h1 through h6.
     */
    level: 1 | 2 | 3 | 4 | 5 | 6;
    /**
     * Heading content.
     */
    children: ReactNode;
    /**
     * Allow data-* attributes
     */
    [key: `data-${string}`]: unknown;
  }
>;

/** @internal */
export const HeadingConfig = createViewConfig({
  name: 'Heading',
  props: {} as HeadingProps,
  flattens: true,
  defaults: {
    level: 3,
  },
});

/**
 * Headings are used to create levels of typographic hierarchies within a page.
 *
 * `Heading` provides no specific styling by itself, but receives customizations from the parent container.
 *
 * @see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements
 */
export const Heading = HeadingConfig.createView(({level, ...props}) => {
  const HeadingTag = `h${level}` as ElementType;
  return <HeadingTag {...props} />;
});
