import {defineMessages} from 'react-intl';

import type {IntlShape, MessageDescriptor} from 'react-intl';
import type {
  AddressInputLabels,
  AddressErrorMessages,
} from 'sail/AddressInput/types';

type MessageDescriptorMap<K extends string> = Partial<
  Record<K, MessageDescriptor>
>;

type AddressMessageKeys =
  | 'address_only_line_label'
  | 'address_line1_label'
  | 'address_line2_label'
  | 'country_or_region_label'
  | 'admin_area'
  | 'admin_county'
  | 'admin_department'
  | 'admin_district'
  | 'admin_do_si'
  | 'admin_emirate'
  | 'admin_island'
  | 'admin_oblast'
  | 'admin_parish'
  | 'admin_prefecture'
  | 'admin_province'
  | 'admin_state'
  | 'locality_city'
  | 'locality_district'
  | 'locality_post_town'
  | 'locality_suburb'
  | 'sublocality_suburb'
  | 'sublocality_district'
  | 'sublocality_neighborhood'
  | 'sublocality_village_township'
  | 'sublocality_townland'
  | 'zip_postal'
  | 'zip_eircode'
  | 'zip_pin'
  | 'zip_zip'
  | 'sortingCode_cedex';

const addressMessages: MessageDescriptorMap<AddressMessageKeys> =
  defineMessages({
    address_only_line_label: {
      id: 'internationalAddressInput.field.address_only_line_label',
      description: 'Address input label: Address',
      defaultMessage: 'Address',
    },
    address_line1_label: {
      id: 'internationalAddressInput.field.address_line1_label',
      description: 'Address input label: Address Line 1',
      defaultMessage: 'Address line 1',
    },
    address_line2_label: {
      id: 'internationalAddressInput.field.address_line2_label',
      description: 'Address input label: Address Line 2',
      defaultMessage: 'Address line 2',
    },
    country_or_region_label: {
      id: 'internationalAddressInput.field.country_or_region_label',
      description: 'Address input label: Country',
      defaultMessage: 'Country',
    },
    admin_area: {
      id: 'internationalAddressInput.field.admin_area',
      description: 'Address input label: Area',
      defaultMessage: 'Area',
    },
    admin_county: {
      id: 'internationalAddressInput.field.admin_county',
      description: 'Address input label: County',
      defaultMessage: 'County',
    },
    admin_department: {
      id: 'internationalAddressInput.field.admin_department',
      description: 'Address input label: Department',
      defaultMessage: 'Department',
    },
    admin_district: {
      id: 'internationalAddressInput.field.admin_district',
      description: 'Address input label: District',
      defaultMessage: 'District',
    },
    admin_do_si: {
      id: 'internationalAddressInput.field.admin_do_si',
      description: 'Address input label: Do Si',
      defaultMessage: 'Do Si',
    },
    admin_emirate: {
      id: 'internationalAddressInput.field.admin_emirate',
      description: 'Address input label: Emirate',
      defaultMessage: 'Emirate',
    },
    admin_island: {
      id: 'internationalAddressInput.field.admin_island',
      description: 'Address input label: Island',
      defaultMessage: 'Island',
    },
    admin_oblast: {
      id: 'internationalAddressInput.field.admin_oblast',
      description: 'Address input label: Oblast',
      defaultMessage: 'Oblast',
    },
    admin_parish: {
      id: 'internationalAddressInput.field.admin_parish',
      description: 'Address input label: Parish',
      defaultMessage: 'Parish',
    },
    admin_prefecture: {
      id: 'internationalAddressInput.field.admin_prefecture',
      description: 'Address input label: Prefecture',
      defaultMessage: 'Prefecture',
    },
    admin_province: {
      id: 'internationalAddressInput.field.admin_province',
      description: 'Address input label: Province',
      defaultMessage: 'Province',
    },
    admin_state: {
      id: 'internationalAddressInput.field.admin_state',
      description: 'Address input label: State',
      defaultMessage: 'State',
    },
    locality_city: {
      id: 'internationalAddressInput.field.locality_city',
      description: 'Address input label: City',
      defaultMessage: 'City',
    },
    locality_district: {
      id: 'internationalAddressInput.field.locality_district',
      description: 'Address input label: District',
      defaultMessage: 'District',
    },
    locality_post_town: {
      id: 'internationalAddressInput.field.locality_post_town',
      description: 'Address input label: Town or City',
      defaultMessage: 'Town or City',
    },
    locality_suburb: {
      id: 'internationalAddressInput.field.locality_suburb',
      description: 'Address input label: Suburb',
      defaultMessage: 'Suburb',
    },
    sublocality_suburb: {
      id: 'internationalAddressInput.field.sublocality_suburb',
      description: 'Address input label: Suburb',
      defaultMessage: 'Suburb',
    },
    sublocality_district: {
      id: 'internationalAddressInput.field.sublocality_district',
      description: 'Address input label: District',
      defaultMessage: 'District',
    },
    sublocality_neighborhood: {
      id: 'internationalAddressInput.field.sublocality_neighborhood',
      description: 'Address input label: Neighborhood',
      defaultMessage: 'Neighborhood',
    },
    sublocality_village_township: {
      id: 'internationalAddressInput.field.sublocality_village_township',
      description: 'Address input label: Village or Township',
      defaultMessage: 'Village or Township',
    },
    sublocality_townland: {
      id: 'internationalAddressInput.field.sublocality_townland',
      description: 'Address input label: Townland',
      defaultMessage: 'Townland',
    },
    zip_postal: {
      id: 'internationalAddressInput.field.zip_postal',
      description: 'Address input label: Postal code',
      defaultMessage: 'Postal code',
    },
    zip_eircode: {
      id: 'internationalAddressInput.field.zip_eircode',
      description: 'Address input label: Eircode',
      defaultMessage: 'Eircode',
    },
    zip_pin: {
      id: 'internationalAddressInput.field.zip_pin',
      description: 'Address input label: PIN',
      defaultMessage: 'PIN',
    },
    zip_zip: {
      id: 'internationalAddressInput.field.zip_zip',
      description: 'Address input label: ZIP',
      defaultMessage: 'ZIP',
    },
    sortingCode_cedex: {
      id: 'internationalAddressInput.field.sortingCode_cedex',
      description: 'Address input label: CEDEX',
      defaultMessage: 'CEDEX',
    },
  });

export const addressLabelMessages = (intl: IntlShape): AddressInputLabels => {
  return {
    address_only_line_label: intl.formatMessage(
      // @ts-expect-error - TS2769 - No overload matches this call.
      addressMessages.address_only_line_label,
    ),
    address_line1_label: intl.formatMessage(
      // @ts-expect-error - TS2769 - No overload matches this call.
      addressMessages.address_line1_label,
    ),
    address_line2_label: intl.formatMessage(
      // @ts-expect-error - TS2769 - No overload matches this call.
      addressMessages.address_line2_label,
    ),
    country_or_region_label: intl.formatMessage(
      // @ts-expect-error - TS2769 - No overload matches this call.
      addressMessages.country_or_region_label,
    ),
    admin: {
      // @ts-expect-error - TS2769 - No overload matches this call.
      area: intl.formatMessage(addressMessages.admin_area),
      // @ts-expect-error - TS2769 - No overload matches this call.
      county: intl.formatMessage(addressMessages.admin_county),
      // @ts-expect-error - TS2769 - No overload matches this call.
      department: intl.formatMessage(addressMessages.admin_department),
      // @ts-expect-error - TS2769 - No overload matches this call.
      district: intl.formatMessage(addressMessages.admin_district),
      // @ts-expect-error - TS2769 - No overload matches this call.
      do_si: intl.formatMessage(addressMessages.admin_do_si),
      // @ts-expect-error - TS2769 - No overload matches this call.
      emirate: intl.formatMessage(addressMessages.admin_emirate),
      // @ts-expect-error - TS2769 - No overload matches this call.
      island: intl.formatMessage(addressMessages.admin_island),
      // @ts-expect-error - TS2769 - No overload matches this call.
      oblast: intl.formatMessage(addressMessages.admin_oblast),
      // @ts-expect-error - TS2769 - No overload matches this call.
      parish: intl.formatMessage(addressMessages.admin_parish),
      // @ts-expect-error - TS2769 - No overload matches this call.
      prefecture: intl.formatMessage(addressMessages.admin_prefecture),
      // @ts-expect-error - TS2769 - No overload matches this call.
      province: intl.formatMessage(addressMessages.admin_province),
      // @ts-expect-error - TS2769 - No overload matches this call.
      state: intl.formatMessage(addressMessages.admin_state),
    },
    locality: {
      // @ts-expect-error - TS2769 - No overload matches this call.
      city: intl.formatMessage(addressMessages.locality_city),
      // @ts-expect-error - TS2769 - No overload matches this call.
      district: intl.formatMessage(addressMessages.locality_district),
      // @ts-expect-error - TS2769 - No overload matches this call.
      post_town: intl.formatMessage(addressMessages.locality_post_town),
      // @ts-expect-error - TS2769 - No overload matches this call.
      suburb: intl.formatMessage(addressMessages.locality_suburb),
    },
    sublocality: {
      // @ts-expect-error - TS2769 - No overload matches this call.
      suburb: intl.formatMessage(addressMessages.sublocality_suburb),
      // @ts-expect-error - TS2769 - No overload matches this call.
      district: intl.formatMessage(addressMessages.sublocality_district),
      neighborhood: intl.formatMessage(
        // @ts-expect-error - TS2769 - No overload matches this call.
        addressMessages.sublocality_neighborhood,
      ),
      village_township: intl.formatMessage(
        // @ts-expect-error - TS2769 - No overload matches this call.
        addressMessages.sublocality_village_township,
      ),
      // @ts-expect-error - TS2769 - No overload matches this call.
      townland: intl.formatMessage(addressMessages.sublocality_townland),
    },
    zip: {
      // @ts-expect-error - TS2769 - No overload matches this call.
      postal: intl.formatMessage(addressMessages.zip_postal),
      // @ts-expect-error - TS2769 - No overload matches this call.
      eircode: intl.formatMessage(addressMessages.zip_eircode),
      // @ts-expect-error - TS2769 - No overload matches this call.
      pin: intl.formatMessage(addressMessages.zip_pin),
      // @ts-expect-error - TS2769 - No overload matches this call.
      zip: intl.formatMessage(addressMessages.zip_zip),
    },
    sortingCode: {
      // @ts-expect-error - TS2769 - No overload matches this call.
      cedex: intl.formatMessage(addressMessages.sortingCode_cedex),
    },
  };
};

type AddressErrorMessageKeys =
  | 'address_required_address'
  | 'address_required_neighborhood'
  | 'address_required_townland'
  | 'address_required_city'
  | 'address_required_district'
  | 'address_required_post_town'
  | 'address_required_area'
  | 'address_required_county'
  | 'address_required_department'
  | 'address_required_island'
  | 'address_required_oblast'
  | 'address_required_parish'
  | 'address_required_prefecture'
  | 'address_required_province'
  | 'address_required_state'
  | 'address_required_eircode'
  | 'address_required_postal'
  | 'address_required_zip'
  | 'address_invalid_eircode'
  | 'address_invalid_postal'
  | 'address_invalid_zip'
  | 'address_required_required';

const addressErrorMessages: MessageDescriptorMap<AddressErrorMessageKeys> =
  defineMessages({
    address_required_address: {
      id: 'addressInputError.address_required_address',
      description: 'Address input error: Address required',
      defaultMessage: 'Address required',
    },
    address_required_neighborhood: {
      id: 'addressInputError.address_required_neighborhood',
      description: 'Address input error: Neighborhood required',
      defaultMessage: 'Neighborhood required',
    },
    address_required_townland: {
      id: 'addressInputError.address_required_townland',
      description: 'Address input error: Townland required',
      defaultMessage: 'Townland required',
    },
    address_required_city: {
      id: 'addressInputError.address_required_city',
      description: 'Address input error: City required',
      defaultMessage: 'City required',
    },
    address_required_district: {
      id: 'addressInputError.address_required_district',
      description: 'Address input error: District required',
      defaultMessage: 'District required',
    },
    address_required_post_town: {
      id: 'addressInputError.address_required_post_town',
      description: 'Address input error: Town or city required',
      defaultMessage: 'Town or city required',
    },
    address_required_area: {
      id: 'addressInputError.address_required_area',
      description: 'Address input error: Area required',
      defaultMessage: 'Area required',
    },
    address_required_county: {
      id: 'addressInputError.address_required_county',
      description: 'Address input error: County required',
      defaultMessage: 'County required',
    },
    address_required_department: {
      id: 'addressInputError.address_required_department',
      description: 'Address input error: Department required',
      defaultMessage: 'Department required',
    },
    address_required_island: {
      id: 'addressInputError.address_required_island',
      description: 'Address input error: Island required',
      defaultMessage: 'Island required',
    },
    address_required_oblast: {
      id: 'addressInputError.address_required_oblast',
      description: 'Address input error: Oblast required',
      defaultMessage: 'Oblast required',
    },
    address_required_parish: {
      id: 'addressInputError.address_required_parish',
      description: 'Address input error: Parish required',
      defaultMessage: 'Parish required',
    },
    address_required_prefecture: {
      id: 'addressInputError.address_required_prefecture',
      description: 'Address input error: Prefecture required',
      defaultMessage: 'Prefecture required',
    },
    address_required_province: {
      id: 'addressInputError.address_required_province',
      description: 'Address input error: Province required',
      defaultMessage: 'Province required',
    },
    address_required_state: {
      id: 'addressInputError.address_required_state',
      description: 'Address input error: State required',
      defaultMessage: 'State required',
    },
    address_required_eircode: {
      id: 'addressInputError.address_required_eircode',
      description: 'Address input error: Eircode required',
      defaultMessage: 'Eircode required',
    },
    address_required_postal: {
      id: 'addressInputError.address_required_postal',
      description: 'Address input error: Postal code required',
      defaultMessage: 'Postal code required',
    },
    address_required_zip: {
      id: 'addressInputError.address_required_zip',
      description: 'Address input error: ZIP required',
      defaultMessage: 'ZIP required',
    },
    address_invalid_eircode: {
      id: 'addressInputError.address_invalid_eircode',
      description: 'Address input error: Invalid Eircode',
      defaultMessage: 'Invalid Eircode',
    },
    address_invalid_postal: {
      id: 'addressInputError.address_invalid_postal',
      description: 'Address input error: Invalid postal code',
      defaultMessage: 'Invalid postal code',
    },
    address_invalid_zip: {
      id: 'addressInputError.address_invalid_zip',
      description: 'Address input error: Invalid ZIP',
      defaultMessage: 'Invalid ZIP',
    },
    address_required_required: {
      id: 'addressInputError.address_required_required',
      description: 'Address input error: CEDEX required',
      defaultMessage: 'CEDEX required',
    },
  });

export const addressErrorLabelMessages = (
  intl: IntlShape,
): AddressErrorMessages => {
  return {
    address: {
      required: intl.formatMessage(
        // @ts-expect-error - TS2769 - No overload matches this call.
        addressErrorMessages.address_required_address,
      ),
    },
    'dependent-locality': {
      required: {
        neighborhood: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_neighborhood,
        ),
        townland: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_townland,
        ),
      },
    },
    locality: {
      required: {
        // @ts-expect-error - TS2769 - No overload matches this call.
        city: intl.formatMessage(addressErrorMessages.address_required_city),
        district: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_district,
        ),
        post_town: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_post_town,
        ),
      },
    },
    subregion: {
      required: {
        // @ts-expect-error - TS2769 - No overload matches this call.
        area: intl.formatMessage(addressErrorMessages.address_required_area),
        county: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_county,
        ),
        department: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_department,
        ),
        district: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_district,
        ),
        island: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_island,
        ),
        oblast: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_oblast,
        ),
        parish: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_parish,
        ),
        prefecture: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_prefecture,
        ),
        province: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_province,
        ),
        // @ts-expect-error - TS2769 - No overload matches this call.
        state: intl.formatMessage(addressErrorMessages.address_required_state),
      },
    },
    zip: {
      required: {
        eircode: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_eircode,
        ),
        postal: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_required_postal,
        ),
        // @ts-expect-error - TS2769 - No overload matches this call.
        zip: intl.formatMessage(addressErrorMessages.address_required_zip),
      },
      pattern: {
        eircode: intl.formatMessage(
          // @ts-expect-error - TS2769 - No overload matches this call.
          addressErrorMessages.address_invalid_eircode,
        ),
        // @ts-expect-error - TS2769 - No overload matches this call.
        postal: intl.formatMessage(addressErrorMessages.address_invalid_postal),
        // @ts-expect-error - TS2769 - No overload matches this call.
        zip: intl.formatMessage(addressErrorMessages.address_invalid_zip),
      },
    },
    'sorting-code': {
      required: intl.formatMessage(
        // @ts-expect-error - TS2769 - No overload matches this call.
        addressErrorMessages.address_required_required,
      ),
    },
  };
};
