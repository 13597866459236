import {StyleCache} from './cache';
import {TRANSITIVE_KEY} from './constants';
import {createIntentType} from '../../../intent';
import {StyleSheets} from '../../../stylesheets';
import {useIsomorphicLayoutEffect} from '../../../util';
import {addRenderIntent, mergeProperty} from '../../view';
import type {SerializedStylesShape, SerializedStyles} from './types';

type Decorate = (styles: SerializedStylesShape) => SerializedStyles;

const MAX_POOL_LENGTH = 32;

// The css intent creates a temporary internal object that is used within a
// single render. Since the object is internal, we can be sure that it is not
// retained and use a pool to avoid creating unnecessary garbage.
const pool = [] as SerializedStylesShape[];

function maybeReclaim(styles: SerializedStylesShape) {
  if (pool.length > MAX_POOL_LENGTH) {
    return;
  }

  styles.classes.clear();
  styles.dependencies.length = 0;
  styles.isolated.length = 0;
  styles.key = TRANSITIVE_KEY;
  styles.layers = {};

  pool.push(styles);
}

function pushUnique<T>(array: T[], item: T) {
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  array.push(item);
}

function assign(
  source: SerializedStylesShape,
  target: SerializedStylesShape = pool.pop() || {
    classes: new Set(),
    dependencies: [],
    key: TRANSITIVE_KEY,
    isolated: [],
    layers: {},
  },
): SerializedStylesShape {
  if (source.key === TRANSITIVE_KEY) {
    for (let i = 0; i < source.isolated.length; i++) {
      pushUnique(target.isolated, source.isolated[i]);
    }
    for (let i = 0; i < source.dependencies.length; i++) {
      pushUnique(target.dependencies, source.dependencies[i]);
    }
  } else {
    pushUnique(target.dependencies, source.key);
  }
  return target;
}

export const toCssIntent = createIntentType<
  SerializedStyles,
  SerializedStylesShape,
  Decorate
>('css', {
  add(intent, state) {
    return assign(intent, state);
  },
  merge(target, source) {
    return assign(source, target);
  },
});

export function createCssRenderer(
  setClassName: (props: {className?: string}, className: string) => void,
): (styles: SerializedStylesShape, props: {className?: string}) => void {
  return function RenderCssIntents(styles, props) {
    if (styles) {
      setClassName(props, StyleCache.insert(styles as SerializedStyles));
      maybeReclaim(styles);
    }

    useIsomorphicLayoutEffect(() => {
      StyleSheets.commit();
    });
  };
}

addRenderIntent(
  toCssIntent,
  createCssRenderer((props, className) =>
    mergeProperty(props, 'className', className),
  ),
);
