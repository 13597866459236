import {gql} from '@apollo/client';

import type {DocumentNode} from '@apollo/client';

export default function tag(
  document: TemplateStringsArray,
  ...args: any[]
): DocumentNode & {
  permissions: any;
} {
  let compiled: DocumentNode | null = null;
  let _permissions: any;
  let permissionsSet = false; // Flag to check if permissions were set
  let permissionsFrozen = false;

  return {
    get kind() {
      if (!compiled) {
        compiled = gql(document, ...args);
      }

      return compiled.kind;
    },

    get definitions() {
      if (!compiled) {
        compiled = gql(document, ...args);
      }

      return compiled.definitions;
    },

    get loc() {
      if (!compiled) {
        compiled = gql(document, ...args);
      }

      return compiled.loc;
    },

    get permissions() {
      if (!permissionsFrozen && permissionsSet) {
        _permissions = Object.freeze(_permissions);
        permissionsFrozen = true;
      }
      return _permissions;
    },

    set permissions(value) {
      if (!permissionsSet) {
        _permissions = value;
        permissionsSet = true;
      } else {
        throw new Error('Permissions can only be set once');
      }
    },
  };
}
