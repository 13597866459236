import {view} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';

import type {
  ConsumerIdentityDocumentApi,
  ConsumerIdentityDocument,
} from 'gelato/frontend/src/api/Consumer/types';

const messages = defineMessages({
  idTypeNotAllowed: {
    defaultMessage: 'This ID type is not accepted.',
    description: 'Reason the ID is not valid',
    id: 'verification.documentSelectSheet.idTypeNotAllowed',
  },
  idExpired: {
    defaultMessage: 'This ID is expired and no longer valid.',
    description: 'Reason the ID is not valid',
    id: 'verification.documentSelectSheet.idExpired',
  },
  idIncorrectCaptureMethod: {
    defaultMessage: 'A live camera capture of the ID is required.',
    description: 'Reason the ID is not valid',
    id: 'verification.documentSelectSheet.idIncorrectCaptureMethod',
  },
});

type DocumentSelectSheetReasonsProps = {
  selectedInvalidDocument: ConsumerIdentityDocument<ConsumerIdentityDocumentApi>;
};

export default function Reasons({
  selectedInvalidDocument,
}: DocumentSelectSheetReasonsProps) {
  const {
    invalidUseReasons: {isNotAllowedDocType, isExpired, isNotLiveCaptured},
  } = selectedInvalidDocument;

  const invalidUseReasonsMessages = [
    isNotAllowedDocType && messages.idTypeNotAllowed,
    isExpired && messages.idExpired,
    isNotLiveCaptured && messages.idIncorrectCaptureMethod,
  ].filter((reason) => reason !== false);

  return (
    <>
      {invalidUseReasonsMessages.map(
        (reason) =>
          reason && (
            <view.div key={reason.id}>
              <view.span>
                {invalidUseReasonsMessages.length > 1 && '• '}
                <Message {...reason} />
              </view.span>
            </view.div>
          ),
      )}
    </>
  );
}
