import {ButtonGroup, view} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import BottomSheet from 'gelato/frontend/src/components/BottomSheetV2';
import Button from 'gelato/frontend/src/components/ButtonV2';
import Heading from 'gelato/frontend/src/components/HeadingV2';
import Message from 'gelato/frontend/src/components/Message';
import {mB100, tCenter} from 'gelato/frontend/src/components/stylesV2';
import analytics from 'gelato/frontend/src/lib/analytics';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const {useCallback} = React;

const Messages = defineMessages({
  body: {
    defaultMessage: `You haven't finished verifying your information for {platformName}.`,
    description: 'Body for the cancel verification',
    id: 'verification.confirmCancelVerificationSheet.body',
  },
  confirm: {
    defaultMessage: 'Yes, cancel',
    description: 'Button label for cancelling the verification process',
    id: 'verification.confirmCancelVerificationSheet.confirm',
  },
  continue: {
    defaultMessage: 'Continue verification',
    description: 'Button label for continuing verification button',
    id: 'verification.confirmCancelVerificationSheet.continue',
  },
  title: {
    defaultMessage: 'Are you sure you want to cancel?',
    description: 'Title for the cancel verification',
    id: 'verification.confirmCancelVerificationSheet.title',
  },
});

/**
 * The flyout sheet that asks the user to confirm that they want to cancel the
 * verification process.
 */
export default function ConfirmCancelVerificationSheet(): JSX.Element {
  const {appController, appState} = useAppController();
  const platformName = getBrandingPlatformName(appState.session?.branding);

  const handleContinue = useCallback(() => {
    appController.closeAllLayers();
  }, [appController]);

  const handleConfirm = useCallback(() => {
    appController.cancelVerification();
    analytics.action('cancelledVerification');
  }, [appController]);

  const footer = (
    <ButtonGroup direction="column">
      <Button onPress={handleContinue} type="secondary">
        <Message {...Messages.continue} />
      </Button>
      <Button onPress={handleConfirm} type="destructive">
        <Message {...Messages.confirm} />
      </Button>
    </ButtonGroup>
  );

  return (
    <BottomSheet
      data-testid="confirm-cancel-verification-sheet"
      footer={footer}
    >
      <Heading>
        <Message {...Messages.title} />
      </Heading>
      <view.div uses={[tCenter, mB100]}>
        <Message {...Messages.body} values={{platformName}} />
      </view.div>
    </BottomSheet>
  );
}
