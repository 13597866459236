import React from 'react';

import PlaceholderText from 'gelato/frontend/src/components/PlaceholderText';
import {useBranding, useSession} from 'gelato/frontend/src/lib/hooks';
import {maybe} from 'gelato/frontend/src/lib/utils';
import {ButtonLink} from 'sail/Button';
import {Title, TitleTextProps} from 'sail/Text';

import styles from './MobileHeader.module.css';

type shouldShowBackLinkParams = {
  showBackLink?: boolean;
  skipWelcomePage: boolean;
};

const shouldShowBackLink = ({
  showBackLink,
  skipWelcomePage,
}: shouldShowBackLinkParams) => {
  if (typeof window !== 'undefined') {
    const path = window.location.pathname;
    return (
      showBackLink ||
      !(
        path.includes('/start') ||
        path.includes('/submit') ||
        path.includes('/success') ||
        path.includes('/testing') ||
        path.includes('/welcome') ||
        path.includes('/verify_welcome') ||
        path.includes('/verify_inactive') ||
        (path.includes('/verify_options') && skipWelcomePage) ||
        path.includes('/invalid')
      )
    );
  }
};

const handleGoBack = () => window.history.go(-1);

export const BackLink = () => {
  return (
    <>
      <div className={styles.backLinkContainer}>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <ButtonLink
          label=""
          icon="arrowLeft"
          color="gray"
          onClick={handleGoBack}
        />
      </div>
      <div className={styles.verticalDivider} />
    </>
  );
};

type Props = {
  showBackLink?: boolean;
  // the platformIcon and platformName props are used to provide branding
  // settings directly to this component in contexts where branding is not
  // available via graphql in the useBranding hook. e.g. in the no-code flows
  // this component is rendered on some pages before authentication has occurred
  // and so before there is a VerificationSession to provide branding.
  platformName?: string;
  platformIcon?: string;
};

const MobileHeader = ({
  showBackLink,
  platformName: propsPlatformName,
  platformIcon: propsPlatformIcon,
}: Props) => {
  const branding = useBranding();
  const session = useSession();

  const platformIcon =
    propsPlatformIcon || maybe(branding, (branding) => branding.platformIcon);
  const platformName =
    propsPlatformName || maybe(branding, (branding) => branding.platformName);
  const skipWelcomePage: boolean = !!maybe(
    session,
    (session) => session.skipWelcomePage,
  );

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        {shouldShowBackLink({showBackLink, skipWelcomePage}) && <BackLink />}
        {platformIcon && (
          <img
            alt="business icon"
            src={platformIcon}
            className={styles.platformIcon}
          />
        )}
        {platformName ? (
          <Title>{platformName}</Title>
        ) : (
          <PlaceholderText
            width={162}
            color="gray"
            reference={TitleTextProps}
          />
        )}
      </div>
    </div>
  );
};

export default MobileHeader;
