import {useRef} from 'react';

/**
 * This custom React Hook maintains an updated ref of the provided input value.
 * It creates a ref with the initial input value and then immediately updates
 * the current value of the ref to the incoming value.
 *
 * @see https://sail.stripe.me/apis/react/useUpdatedRef
 */
export default function useUpdatedRef<T>(value: T) {
  const ref = useRef<T>(value);

  ref.current = value;

  return ref;
}
