import {useEffect} from 'react';

// These methods are used to optionally replace real timeouts with much shorter ones for
// puppeteer testing. Please use these methods instead of native setTimeout.

export const setMockableTimeout = (
  callback: () => any,
  timeInMillis?: number | null,
): number => {
  const trueTime = process.env.FAKE_TIMEOUT ? 10 : timeInMillis;
  // @ts-expect-error - TS2769 - No overload matches this call.
  return window.setTimeout(callback, trueTime);
};

// Version of setMockableTimeout that uses React effects to get trigger after
// render and automatically clears the timeout on unmount.
export function useSetMockableTimeout(
  callback: () => any,
  timeInMillis?: number | null,
) {
  useEffect(() => {
    const t = setMockableTimeout(callback, timeInMillis);

    return () => {
      clearTimeout(t);
    };
  }, [callback, timeInMillis]);
}
