import {Intent} from '@sail/engine';
import {css, view} from '@sail/ui';
import * as React from 'react';

import {withScrollShadows} from 'gelato/frontend/src/components/stylesV2';
import useScrollableArea from 'gelato/frontend/src/lib/hooks/useScrollableArea';

type Props = {
  children?: React.ReactNode;
  uses?: Intent[] | null | undefined;
};

const {useRef} = React;

const Styles = {
  scrollableArea: css({
    overflow: 'auto',
    position: 'relative',
  }),
};

/**
 * The ScrollableArea component is a wrapper around a div that allows it to be
 * scrolled. It is used to create a scrollable area within a page.
 */
export default function ScrollableArea(props: Props): JSX.Element {
  const domRef = useRef<HTMLDivElement>(null);
  useScrollableArea(domRef);

  const {children, uses} = props;

  const styles: Intent[] = [Styles.scrollableArea, withScrollShadows];
  if (uses) {
    styles.push(...uses);
  }

  return (
    <view.div ref={domRef} uses={styles}>
      {children}
    </view.div>
  );
}
