import {debounce} from 'lodash';
import * as React from 'react';
import useResizeObserver from 'use-resize-observer/polyfilled';

import {setMockableTimeout} from 'gelato/frontend/src/lib/clock';
import {
  BreakpointContext,
  CameraStreamStateContext,
  ConnectIframeContext,
  ExperimentsContext,
  PageCardAnimatingContext,
} from 'gelato/frontend/src/lib/contexts';
import useBranding from 'gelato/frontend/src/lib/hooks/useBranding';
import useFeatureFlags from 'gelato/frontend/src/lib/hooks/useFeatureFlags';
import useIsMounted from 'gelato/frontend/src/lib/hooks/useIsMounted';
import usePageLayoutPortrait from 'gelato/frontend/src/lib/hooks/usePageLayoutPortrait';
import useSession from 'gelato/frontend/src/lib/hooks/useSession';
import {isInIframe} from 'gelato/frontend/src/lib/iframe';
import getRouter from 'gelato/frontend/src/lib/localRouter';
import {maybe} from 'gelato/frontend/src/lib/utils';

import type {LocalRouter} from 'gelato/frontend/src/lib/localRouter';

// Re-export the hooks to support the legacy import paths.
// New code should import from the hooks directly.
export {
  useBranding,
  useSession,
  useIsMounted,
  usePageLayoutPortrait,
  useFeatureFlags,
};

export function useDebouncedResizeObserver(wait: number) {
  const [size, setSize] = React.useState({});
  const onResize = React.useMemo(
    () => debounce(setSize, wait, {leading: true}),
    [wait],
  );
  const {ref} = useResizeObserver({onResize});
  // @ts-expect-error - TS2339 - Property 'height' does not exist on type '{}'.
  const height = size.height;
  return {ref, height};
}

export function useExperiments() {
  return React.useContext(ExperimentsContext);
}

export function useConnectIframe() {
  return React.useContext(ConnectIframeContext);
}

export function useSessionUnsafe() {
  const session = useSession();
  if (!session) {
    throw new Error('Missing session');
  }
  return session;
}

export function useSupport() {
  const session = useSession();
  const sessionSupport = session?.support;

  return sessionSupport || {email: '', url: ''};
}

export function useWelcomeHandoff() {
  const session = useSession();
  const sessionVal = session?.welcomeHandoff;
  return sessionVal;
}

export function useConsentConfig() {
  return useSession()?.consentConfig;
}

export function useBrandingUnsafe() {
  const branding = useBranding();
  if (!branding) {
    throw new Error('Missing branding');
  }
  return branding;
}

export function useReturnUrl() {
  const session = useSession();
  if (isInIframe()) {
    return '';
  } else {
    return encodeURI((session && session.returnUrl) || '');
  }
}

export function useBreakpoint() {
  return React.useContext(BreakpointContext);
}

export function useCameraStreamState() {
  return React.useContext(CameraStreamStateContext);
}

export function useRouter() {
  const routerRef = React.useRef<LocalRouter>(getRouter());
  return routerRef.current;
}

export function useOperatingMode() {
  const session = useSession();
  return maybe(session, (s) => s.operatingMode);
}

export function useWindowInnerDims() {
  const [height, setHeight] = React.useState(window.innerHeight);
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const updateDims = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateDims);
    return () => window.removeEventListener('resize', updateDims);
  }, []);
  return {innerHeight: height, innerWidth: width};
}

export function useDynamicPollingRate(
  initialRate: number,
  pollRates: ReadonlyArray<readonly [number, number]>,
) {
  const [pollInterval, setPollInterval] = React.useState(initialRate);
  React.useEffect(() => {
    const timeoutFuncs = pollRates.map((rateTuple) => {
      const [rate, atTime] = rateTuple;
      return setMockableTimeout(() => setPollInterval(rate), atTime);
    });
    return () => {
      timeoutFuncs.map((timeoutFunc) => clearTimeout(timeoutFunc));
    };
  }, [pollRates]);

  return pollInterval;
}

export const useIsPageCardAnimating = () => {
  return React.useContext(PageCardAnimatingContext);
};
