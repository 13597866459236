import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {IndividualPageFields} from 'gelato/frontend/src/controllers/states/IndividualState';
import {isVerificationFlowSession} from 'gelato/frontend/src/controllers/states/SessionState';
import {
  isConsentDeclinedAutoComplete,
  isForceDelayed,
} from 'gelato/frontend/src/controllers/states/TestModeState';
import hasMissingFields from 'gelato/frontend/src/controllers/utils/hasMissingFields';
import isFieldNeeded from 'gelato/frontend/src/controllers/utils/isFieldNeeded';
import routeToLinkForConsumerDocumentSave from 'gelato/frontend/src/controllers/utils/routeToLinkForConsumerDocumentSave';
import updateDeviceStatusMutation from 'gelato/frontend/src/controllers/utils/updateDeviceStatusMutation';
import {getWelcomePageV2} from 'gelato/frontend/src/lib/consent_utils';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {isMobileDevice} from 'gelato/frontend/src/lib/device';
import experiments from 'gelato/frontend/src/lib/experiments';
import flags from 'gelato/frontend/src/lib/flags';
import isBrowserCameraPermissionEnabled from 'gelato/frontend/src/lib/isBrowserCameraPermissionEnabled';
import {reportMetricWithExceptionHandling} from 'gelato/frontend/src/lib/metricsBatcher';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {
  ApplicationRouterPath,
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';

export type RouteChangeReason =
  | 'start_session'
  | 'end_session'
  | 'leave_document_types'
  | 'leave_document_upload_with_all_images'
  | 'leave_document_upload_without_all_images'
  | 'leave_handoff'
  | 'leave_link'
  | 'leave_permissions'
  | 'leave_reload'
  | 'leave_welcome'
  | 'leave_individual'
  | 'unsupported'
  | 'email_otp_submitted'
  | 'phone_otp_submitted'
  | 'submit_redirect'
  | 'submitted'
  // networking document for reuse
  | 'reuse_networked_document'
  | 'skip_networking'
  | 'link_otp_page'
  | 'link_reuse_page'
  // test mode
  | 'manual_test_mode'
  | 'autocomplete_test_mode'
  // webcam
  | 'webcam_error';

/**
 * Route to the next page based on the current session state.
 * This function should be the central place to determine the routing logic for
 * Butter 2.0.
 * @param state The current application state.
 * @param runtime The current application runtime.
 * @param reason The reason for routing to the next page.
 */
export default async function routeToNextPage(
  state: Readonly<ApplicationState>,
  runtime: Readonly<ApplicationRuntime>,
  reason: RouteChangeReason,
  redirect?: boolean,
  caller?: string,
): Promise<void> {
  const {session, sessionCanceledByUserAt, networkedIdentity} = state;
  const {router} = runtime!;
  const {operatingMode, fMobile, networkingData} = session || {};

  const initialPath = router.currentPath;

  const skipsDocumentNetworking =
    flags.isActive('idprod_update_consumer_networking_data') &&
    Boolean(session?.networkingData.skipsDocumentNetworking);

  const goto = (
    path: ApplicationRouterPath,
    params?: {
      // Additional information for debugging purposes.
      debugInfo?: string;
    },
  ) => {
    if (path === router.currentPath) {
      // Already on the path.

      const tags = [
        {key: 'initial_path', value: initialPath},
        {key: 'path', value: path},
        {key: 'reason', value: reason},
      ];

      reportMetricWithExceptionHandling({
        metric: 'gelato_frontend_route_to_same_page_error',
        operation: 'count',
        value: 1,
        tags,
        storytime: tags,
      });

      const debugInfo = params?.debugInfo || 'N/A';

      const callerInfo = caller || 'N/A';

      // Set the details as the cause of the soft error so that we could
      // debug the issue from Splunk logs.
      const cause = new Error(
        `Route to same path: ${path}. reason: ${reason}, initial path: ${initialPath}, info: ${debugInfo}, caller: ${callerInfo}`,
      );

      // Report the soft error to Sentry.
      const error = new Error(ErrorCode.routeToSamePath, {cause});
      handleException(error, cause.message);
      return;
    }

    if (redirect) {
      router.replace(path);
      return;
    }

    router.push(path);
  };

  if (reason === 'start_session') {
    if (session) {
      if (
        session?.operatingMode !== 'secondary' &&
        session?.livemode === false
      ) {
        goto('/testing');
        return;
      } else {
        const welcomePageUrl = getWelcomePageV2(
          session,
          experiments.getValues(),
        );
        goto(welcomePageUrl);
        return;
      }
    } else {
      // We should have session already when starting a session
      // otherwise show the error page
      goto('/invalid');
      return;
    }
  }

  if (sessionCanceledByUserAt) {
    goto('/end');
    return;
  }

  // We should immediately route to invalid if any of the unsupported cases are detected
  if (
    reason === 'unsupported' &&
    (session?.underConsentAge ||
      session?.unsupportedCountry ||
      session?.sanctionedDocumentCountry)
  ) {
    goto('/invalid');
    return;
  }

  if (
    (reason === 'email_otp_submitted' && isFieldNeeded(state, 'email_otp')) ||
    (reason === 'phone_otp_submitted' && isFieldNeeded(state, 'phone_otp'))
  ) {
    goto('/invalid');
    return;
  }

  if (session?.closed) {
    goto('/success');
    return;
  }

  if (reason === 'webcam_error') {
    await updateDeviceStatusMutation(runtime!.apolloClient, {
      deviceData: {
        supported: false,
        reason: 'no_webcam',
      },
    });
    goto('/invalid');
    return;
  }

  if (reason === 'submitted' && hasMissingFields(state)) {
    // TODO(cjmisenas, 5-30-2024):
    // Clean this up when we are done with Butter migration since we won't need to maintain backwards
    // compatible behavior for routing/some of the nextDataPageForSession pages will be removed
    const nextPage = await nextDataPageForSession({
      missingFields: session?.missingFields || [],
      requiredFields: session?.requiredFields || [],
      rLCapture: session?.rLCapture || false,
    });
    goto(nextPage);
    return;
  }

  // Test Mode for VF routing
  if (session?.livemode === false) {
    if (reason === 'manual_test_mode') {
      if (isVerificationFlowSession(state)) {
        goto('/verify_welcome');
        return;
      }
      const welcomePageUrl = getWelcomePageV2(session, experiments.getValues());
      goto(welcomePageUrl);
      return;
    }

    if (reason === 'autocomplete_test_mode') {
      const forceAsyncParam = isForceDelayed(state) ? '?forceAsync' : '';
      const nextPage: ApplicationRouterPath = isConsentDeclinedAutoComplete(
        state,
      )
        ? '/invalid'
        : `/submit${forceAsyncParam}`;

      goto(nextPage);
      return;
    }
  }

  // Consent page.
  if (isFieldNeeded(state, 'consent')) {
    if (reason === 'leave_welcome') {
      goto('/invalid');
    } else {
      goto('/welcome');
    }
    return;
  }

  // Link page for authenticating the consumer account and
  // reusing networked identity documents.
  if (
    !flags.isActive('idprod_ni_implementation_review') &&
    reason !== 'skip_networking' &&
    reason !== 'reuse_networked_document' &&
    reason !== 'leave_link' &&
    (networkedIdentity.reuseEnabled ||
      (networkedIdentity.enabled && experiments.isActive('ni_email_login'))) &&
    networkedIdentity.networkingOptIn &&
    !networkedIdentity.uploadNewData &&
    !networkedIdentity.shareSuccess &&
    !skipsDocumentNetworking &&
    isFieldNeeded(state, 'id_document_images')
  ) {
    goto('/link', {debugInfo: 'for authenticating'});
    return;
  }

  if (
    flags.isActive('idprod_ni_implementation_review') &&
    reason === 'link_otp_page'
  ) {
    goto('/link_otp');
    return;
  }

  if (
    flags.isActive('idprod_ni_implementation_review') &&
    reason === 'link_reuse_page'
  ) {
    goto('/link_reuse');
    return;
  }

  if (reason === 'submitted') {
    goto('/success');
    return;
  }

  if (isFieldNeeded(state, 'email_otp')) {
    goto('/email_verification');
    return;
  }

  if (isFieldNeeded(state, 'phone_otp')) {
    goto('/phone_verification');
    return;
  }

  if (
    isFieldNeeded(state, 'id_document_metadata') &&
    !session?.collectedData?.individual?.idDocument?.type
  ) {
    // If the document type is not known, we need to go to the document type
    // selection page first.
    goto('/document_select');
    return;
  }

  // Selfie Verification Method select page
  if (
    reason === 'leave_welcome' &&
    isFieldNeeded(state, 'selfie_verification_method')
  ) {
    goto('/selfie_verification_method');
    return;
  }

  // Document upload page.
  if (isFieldNeeded(state, 'id_document_images')) {
    if (isMobileDevice()) {
      // Mobile device.
      await routeToDocumentUpload(state, runtime, goto);
    } else {
      // Desktop device.
      switch (true) {
        case operatingMode === 'primary':
          if (
            reason === 'leave_handoff' ||
            reason === 'leave_document_types' ||
            // page was reloaded to refresh the camera permission state.
            reason === 'leave_reload'
          ) {
            await routeToDocumentUpload(state, runtime, goto);
          } else if (experiments.isActive('no_handoff')) {
            if (fMobile) {
              // Mobile is required, but we can't do another handoff from
              // desktop.

              // Raise this as a soft-error to Sentry to track the impact of
              // this issue.
              const error = new Error(ErrorCode.unableToHandoffToMobileDevice);
              handleException(error, 'routeToNextPage() error');

              // Make the device as "unsupported device" that does not have
              // the mobile camera needed.
              await updateDeviceStatusMutation(runtime!.apolloClient, {
                deviceData: {
                  supported: false,
                  reason: 'no_webcam',
                },
              });
              goto('/invalid');
            } else {
              await routeToDocumentUpload(state, runtime, goto);
            }
          } else {
            // Force Desktop to try handoff first to acquire better camera.
            // Note that this expects that the handoff
            // page to allow user to continue on the same device if applicable.
            goto('/handoff');
          }
          break;
        case operatingMode === 'waiting':
          if (experiments.isActive('no_handoff')) {
            // This should not happen, but if it does, we should raise this as
            // a soft-error to Sentry and investigate why this happened.
            const error = new Error(ErrorCode.unexpectedHandoffSessionStarted);
            handleException(error, 'routeToNextPage() error');
          }

          // Navigate to the handoff page which will show the "waiting"
          // UI.
          goto('/handoff');
          break;
        case operatingMode === 'secondary':
          if (fMobile) {
            // Mobile is required, but we can't do another handoff from desktop.
            goto('/invalid');
          } else {
            await routeToDocumentUpload(state, runtime, goto);
          }
          break;
        default:
          throw new Error(
            `${ErrorCode.unexpectedOperatingMode}:${operatingMode}`,
          );
      }
    }
    return;
  }

  // Selfie upload page.

  if (isFieldNeeded(state, 'face')) {
    const enabled = await isBrowserCameraPermissionEnabled();
    if (enabled || Storage.getWebcamPermission() === 'true') {
      goto('/face_upload');
    } else {
      // Go to the page that prompts user to enable camera permission.
      // TODO: In M2 (new document upload flow), we don't this
      // "/permissions" page anymore. We should remove this.
      goto('/permissions');
    }
    return;
  }

  if (IndividualPageFields.some((field) => isFieldNeeded(state, field))) {
    // If we have any required fields on the individual page, we should go to
    // that page.
    goto('/individual');
    return;
  }

  /*
    If all of the required fields are captured, i.e. not needed, and the required fields also
    contain a document requirement, we can move to Link sign-up / log-in.

  */
  if (session!.requiredFields.every((field) => !isFieldNeeded(state, field))) {
    // todo(aywang, 2023-12-11): Remove the use of hasDocumentRequirement and move the check to the backend
    const hasDocumentRequirement =
      session!.requiredFields.includes('id_document_images');

    /*
      When adding logic to this if statement please double check that the logic should not also be added to
      `routeToLinkForConsumerDocumentSave`. This is not the only place that we route to /link. We also route
      to /link in `dataRouting.ts`, which uses `routeToLinkForConsumerDocumentSave`.

      Specifically, if the current page the user is on is not Butter enabled we use `dataRouting.ts`.
    */
    if (
      !flags.isActive('idprod_ni_implementation_review') &&
      reason !== 'skip_networking' &&
      reason !== 'leave_link' &&
      reason !== 'reuse_networked_document' &&
      routeToLinkForConsumerDocumentSave(
        networkedIdentity.enabled,
        hasDocumentRequirement,
        networkedIdentity.shareSuccess,
        networkedIdentity.skipped || skipsDocumentNetworking,
        Boolean(networkingData?.consentsToDocumentNetworking),
      ) &&
      // If there is not a consumer account ID then the user has not yet signed up for an account
      // or logged in and we should not route the user to /link
      !networkedIdentity.consumerAccountId
    ) {
      goto('/link', {debugInfo: 'for reuse'});
      return;
    }
    // We have all the required fields, so we can go to the submit page.
    goto('/submit');
    return;
  }

  const tags = [
    {key: 'path', value: router.currentPath},
    {key: 'reason', value: reason},
    {key: 'operatingMode', value: operatingMode || 'null'},
  ];
  reportMetricWithExceptionHandling({
    metric: 'gelato_frontend_unexpected_routing_reason_error',
    operation: 'count',
    value: 1,
    tags,
    storytime: tags,
  });

  throw new Error(`${ErrorCode.unexpectedRoutingReason}:${reason}"`);
}

/**
 * Route to the document upload page if user is ready. Otherwise, route to the
 * page that informs user to get their document ready or camera permission
 * first.
 * @param runtime The current application runtime.
 */
async function routeToDocumentUpload(
  state: Readonly<ApplicationState>,
  runtime: Readonly<ApplicationRuntime>,
  goto: (path: ApplicationRouterPath) => void,
): Promise<void> {
  if (
    !Storage.hasVisitedPath('/document_types') &&
    !experiments.isActive('document_upload_page_v2')
  ) {
    // Inform the user that they need to get their document ready first.
    goto('/document_types');
    return;
  }

  const enabled = await isBrowserCameraPermissionEnabled();
  if (
    !enabled &&
    !Storage.hasVisitedPath('/permissions') &&
    // We don't need to show the permission page if we are in the new document
    // upload flow.
    !experiments.isActive('document_upload_page_v2')
  ) {
    // Go to the page that prompts user to enable camera permission.
    // TODO: In M2 (new document upload flow), we don't this
    // "/permissions" page anymore. We should remove this.
    goto('/permissions');
    return;
  }

  goto('/document_upload');
}
