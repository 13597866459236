import * as React from 'react';

import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {OTPMode} from 'gelato/frontend/src/components/OTPVerification';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {
  useBranding,
  useConnectIframe,
  useReturnUrl,
  useRouter,
} from 'gelato/frontend/src/lib/hooks';
import {isInIframe, postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {
  shouldShowCloseButton,
  hasiOSSheetCloseWindowHandler,
} from 'gelato/frontend/src/lib/windowHelpers';
import Icon from 'sail/Icon';
import {Body, Title} from 'sail/Text';

import styles from './ConsentDeclined.module.css';

import type {Session} from 'gelato/frontend/src/lib/contexts';

const OTPDeclined = (props: {mode: OTPMode; session: Session}) => {
  const {mode, session} = props;
  const router = useRouter();
  const proceedCallback = React.useCallback(async () => {
    if (session) {
      const nextPage = await nextDataPageForSession(session);
      router.push(nextPage);
    }
  }, [router, session]);
  const branding = useBranding();
  const isConnectIframe = useConnectIframe();
  const returnUrl = useReturnUrl();
  const {platformName} = branding || {};

  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'user_action',
      code:
        mode === OTPMode.email
          ? 'email_verification_declined'
          : 'phone_otp_declined',
    });
  });

  const headingMessage = () => {
    if (mode === OTPMode.email) {
      return <Message {...messages.otpDeclinedHeadingEmail} />;
    } else {
      return <Message {...messages.otpDeclinedHeadingPhone} />;
    }
  };

  const bodyMessage = () => {
    if (mode === OTPMode.email) {
      if (!returnUrl && !shouldShowCloseButton(isConnectIframe)) {
        return (
          <Message
            {...messages.otpDeclinedBodyEmailNoReturnUrl}
            values={{platformName}}
          />
        );
      } else {
        return (
          <Message {...messages.otpDeclinedBodyEmail} values={{platformName}} />
        );
      }
    } else if (!returnUrl && !shouldShowCloseButton(isConnectIframe)) {
      return (
        <Message
          {...messages.otpDeclinedBodyPhoneNoReturnUrl}
          values={{platformName}}
        />
      );
    } else {
      return (
        <Message {...messages.otpDeclinedBodyPhone} values={{platformName}} />
      );
    }
  };

  const handleClose = () => {
    // tell the Stripe.js iframe to close
    postIframeEvent('STRIPE_IDENTITY_CLOSE');

    // tell the iOS sheet to close
    if (hasiOSSheetCloseWindowHandler()) {
      // @ts-expect-error - TS2339 - Property 'webkit' does not exist on type 'Window & typeof globalThis'.
      window.webkit.messageHandlers.closeWindow.postMessage(null);
    }
  };

  const renderCtaButtons = (
    proceedCallback: () => Promise<void>,
    returnUrl: string,
    platformName: string,
  ) => {
    let exitButton = null;
    let bodyLink = null;
    if (returnUrl && !isInIframe()) {
      exitButton = (
        <ThemableButton
          href={returnUrl}
          label={
            <div
              style={{
                whiteSpace: 'normal',
              }}
            >
              <Message {...messages.failureLink} values={{platformName}} />
            </div>
          }
          size="large"
          width="maximized"
        />
      );
    } else if (shouldShowCloseButton(isConnectIframe)) {
      exitButton = (
        <ThemableButton
          onClick={handleClose}
          label={<Message {...messages.failureLinkNoReturnUrl} />}
          size="large"
          width="maximized"
        />
      );
    }

    if (mode === OTPMode.email) {
      bodyLink = (
        <ThemableButton
          onClick={proceedCallback}
          color="blue"
          label={<Message {...messages.otpDeclinedBodyEmailLink} />}
          size="large"
          width="maximized"
        />
      );
    } else {
      bodyLink = (
        <ThemableButton
          onClick={proceedCallback}
          color="blue"
          label={<Message {...messages.otpDeclinedBodyPhoneLink} />}
          size="large"
          width="maximized"
        />
      );
    }

    return (
      <>
        {bodyLink}
        {exitButton}
      </>
    );
  };

  return (
    <>
      <div className={styles.pageContent}>
        <div className={styles.pageHeader}>
          <Icon icon="dispute" color="gray" size={20} />
          <Title className={styles.title}>{headingMessage()}</Title>
        </div>
        <Body>{bodyMessage()}</Body>
      </div>
      <div className={styles.pageButtons}>
        {renderCtaButtons(proceedCallback, returnUrl, platformName || '')}
      </div>
    </>
  );
};

export default OTPDeclined;
