import * as Sentry from '@sentry/browser';
import qs from 'qs';

import {getConfigValue} from 'gelato/frontend/src/lib/config';
import {VERIFY_URL_PATTERN} from 'gelato/frontend/src/lib/localRouter';
import Storage from 'gelato/frontend/src/lib/Storage';
import {getApiHost} from 'gelato/frontend/src/lib/urlConfig';
import {StaticSettings} from 'gelato/frontend/src/services/apis/start_code';

export type SlugResponse = {
  active?: boolean;
  return_url?: string;
  pk?: string;
  _static?: StaticSettings;
};

const cleanClientReference = (raw: string) => {
  return raw.replace(/[^a-zA-Z0-9-_.]/g, '');
};

const getSlugFromLocation = (pathname: string): string => {
  const match = VERIFY_URL_PATTERN.match(pathname);
  return (match && match.slug) || '';
};

const storeInfoFromWindowLocation = (queryString: string) => {
  const query = qs.parse(queryString.split('?')[1]);
  if (typeof query.email === 'string') {
    Storage.setPrefilledEmail(query.email);
  } else if (typeof query.prefilled_email === 'string') {
    Storage.setPrefilledEmail(query.prefilled_email);
  }

  if (typeof query.client_reference_id === 'string') {
    const cleanedReferenceId = cleanClientReference(query.client_reference_id);
    Storage.setClientReferenceId(cleanedReferenceId);
  }
};

export async function fetchStaticDataForTemplateSlug(
  slug: string,
): Promise<SlugResponse> {
  let headers;
  if (global.Headers) {
    headers = new Headers();
    const commitHash = getConfigValue('COMMIT_HASH');
    headers.set('Content-Type', 'application/json');
    headers.set('X-Stripe-Identity-Client-Version', commitHash);
    headers.set('X-Requested-With', 'fetch');
  }

  const url = `${getApiHost()}/api/template-data/${slug}`;

  let resp = new Response();
  try {
    resp = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers,
    });
  } catch (e) {
    Sentry.addBreadcrumb({
      category: 'link_slug',
      message: `Error fetching link slug: ${e}`,
      level: Sentry.Severity.Warning,
    });
  }

  if (resp.status === 401 || resp.status === 404 || !resp.ok) {
    throw Error(`Request rejected with status ${resp.status}`);
  }

  return resp.json();
}

const fetchStaticDataFromWindowLocation = async (
  queryString: string,
  pathname: string,
): Promise<SlugResponse> => {
  // Reset static data stored
  Storage.clearStaticContent();

  storeInfoFromWindowLocation(queryString);
  const slug = getSlugFromLocation(pathname);
  Storage.setTemplateSlug(slug);
  const response = await fetchStaticDataForTemplateSlug(slug);
  response._static && Storage.setStaticContent(response._static);
  response.pk && Storage.setMerchantPublishableKey(response.pk);
  return response;
};

export default fetchStaticDataFromWindowLocation;
