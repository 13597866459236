import analytics from 'gelato/frontend/src/lib/analytics';

const MAX_LOAD_TIME = 10000;

export const trackLoadTimeout = () => {
  // Track when page loading started
  analytics.track('pageLoading', {
    timeStartLoading: Date.now(),
  });

  let loadTimeout: ReturnType<typeof setTimeout> | null = setTimeout(() => {
    // Track if page loads more than the max time
    analytics.track('pageTimeout');
    if (loadTimeout) {
      stopLoadTimeout(loadTimeout, true);
      loadTimeout = null;
    }
  }, MAX_LOAD_TIME);
  return loadTimeout;
};

export function stopLoadTimeout(
  loadTimeout: NodeJS.Timeout | null,
  timedOut?: boolean,
) {
  if (loadTimeout) {
    try {
      clearTimeout(loadTimeout);
      if (!timedOut) {
        // If stopLoadTimeout was not called due to the max time being reached,
        // make sure to track that the page loaded
        analytics.track('pageLoaded', {
          timeStartLoaded: Date.now(),
        });
      }
    } catch (e: any) {
      // do nothing
    }
  }
}
