import type {AnalyticsConfig} from '../core/types';
import {setupScrubber} from './piiScrubber';

const GOOGLE_ANALYTICS_SCRIPT_LOCATION =
  'https://www.google-analytics.com/analytics.js';

/**
 * Method to check whether Google Analytics has already been loaded
 * or is in the process of loading.
 *
 * @return {Boolean}
 * Whether Google Analytics is already loaded.
 */
const googleAnalyticsIsPresent = (): boolean => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window.hasOwnProperty('ga') && typeof (window as any).ga === 'function') ||
    document.querySelectorAll(
      `script[src="${GOOGLE_ANALYTICS_SCRIPT_LOCATION}"]`,
    ).length > 0
  );
};

/**
 * Takes `{foo: true, bar: false, baz: true}` and returns `'foo,baz'`
 *
 * @method stringifyFlags
 * @private
 * @param flagsObj {Object<Boolean>}
 * @returns {String}
 */
const stringifyFlags = (flagsObj: {[key: string]: boolean}): string => {
  if (typeof flagsObj !== 'object') {
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const flagsArr: Array<any | string> = [];
  for (const flagName in flagsObj) {
    if (flagsObj[flagName] === true) {
      flagsArr.push(flagName);
    }
  }

  return flagsArr.join(',');
};

/**
 * This method injects the shared Google Analytics script for Stripe
 * domains. It listens to a few options on whether to automatically track
 * or not. It allows pass-through configuration from the outside world via
 * the `ga` property in the global config.
 *
 * @param {Object} [config={}]
 * This config object can be used to set GA Track Options and turn on or
 * off default pageview tracking.
 */
export const injectGoogleAnalytics = (
  config: Partial<AnalyticsConfig> = {},
): void => {
  // Use the Google Analytics injection code directly. It's asynchronous, so we start loading it
  // first, and can comfortably configure it directly afterwards.

  if (!googleAnalyticsIsPresent()) {
    /* eslint-disable */
    (function (i, s, o, g, r, a, m) {
      // @ts-ignore GA code
      i['GoogleAnalyticsObject'] = r;
      // @ts-ignore GA code
      (i[r] =
        // @ts-ignore GA code
        i[r] ||
        function () {
          // @ts-ignore GA code
          (i[r].q = i[r].q || []).push(arguments);
        }),
        // @ts-expect-error GA code
        (i[r].l = 1 * new Date());
      // @ts-expect-error GA code
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      // @ts-expect-error GA code
      a.async = true;
      // @ts-expect-error GA code
      a.src = g;
      // @ts-expect-error GA code
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', GOOGLE_ANALYTICS_SCRIPT_LOCATION, 'ga');
    /* eslint-enable */
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any).ga) {
    // legacyCookieDomain per
    // https://developers.google.com/analytics/devguides/collection/upgrade/reference/gajs-analyticsjs
    const gaSetupOptions = {
      cookieDomain: 'auto',
      legacyCookieDomain: '.stripe.com',
    } as const;

    // Configure Google Analytics
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).ga('create', 'UA-12675062-1', gaSetupOptions);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setupScrubber((window as any).ga);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).ga('require', 'displayfeatures');

    // Set loggedIn custom dimension to "true" or "false" if it is defined.
    if (
      config.googleAnalytics &&
      typeof config.googleAnalytics.isLoggedIn !== 'undefined'
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).ga(
        'set',
        'dimension1',
        String(config.googleAnalytics.isLoggedIn),
      );
    }

    // Set flags custom dimension.
    if (config.googleAnalyticsFlags) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).ga(
        'set',
        'dimension2',
        stringifyFlags(config.googleAnalyticsFlags),
      );
    }

    // Track pageview
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).ga('send', 'pageview');
  }
};
