import {view, css, ButtonGroup, Spinner} from '@sail/ui';
import * as React from 'react';

import BottomSheet from 'gelato/frontend/src/components/BottomSheetV2';
import Button from 'gelato/frontend/src/components/ButtonV2';
import AccountBox from 'gelato/frontend/src/components/Link/AccountBox';
import {messages} from 'gelato/frontend/src/components/Link/messages';
import Message from 'gelato/frontend/src/components/Message';
import {tSmall} from 'gelato/frontend/src/components/stylesV2';
import {LocaleContext} from 'gelato/frontend/src/lib/contexts';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const {useCallback, useState, useContext} = React;

const Styles = {
  body: css({
    color: 'subdued',
  }),
  heading: css({
    color: 'text',
    font: 'heading.large',
  }),
  sheet: css({
    gap: 'space.250',
    paddingBottom: 'space.150',
    stack: 'y',
  }),
  sheetContent: css({
    gap: 'small',
    stack: 'y',
  }),
  spinnerContainer: css({
    alignX: 'center',
    stack: 'x',
    width: 'fill',
  }),
};

export default function ReuseSheet() {
  const {appState, appController} = useAppController();
  const [loading, setLoading] = useState<boolean>(false);
  const {locale} = useContext(LocaleContext);

  const handleContinue = useCallback(async () => {
    // We expect appController.start to route to the next page so we don't set loading to false
    setLoading(true);
    await appController.signInConsumerAccount({locale});
    await appController.start({
      consentAccepted: true,
      // We require OTP for re-use
      networkedIdentityOtpRequired: true,
      networkedIdentityNetworkingOptIn: true,
    });
    setLoading(false);
  }, [appController, locale]);

  const handleSkipNetworking = useCallback(async () => {
    // We expect appController.start to route to the next page so we don't set loading to false
    setLoading(true);
    await appController.start({
      consentAccepted: true,
      reason: 'skip_networking',
    });
    setLoading(false);
  }, [appController]);

  const accountEmail =
    appState.networkedIdentity.consumerSession?.email_address;

  const footer = (
    <ButtonGroup direction="column">
      <Button
        data-testid="link-reuse-sheet-continue"
        type="primary"
        onPress={handleContinue}
        disabled={!accountEmail}
        pending={loading}
      >
        <Message {...messages.pageTitle} />
      </Button>
      <Button
        data-testid="link-reuse-sheet-skip"
        type="secondary"
        onPress={handleSkipNetworking}
        disabled={loading}
      >
        <Message {...messages.linkSkipSave} />
      </Button>
    </ButtonGroup>
  );
  return (
    <BottomSheet data-testid="link-reuse-sheet" footer={footer}>
      <view.div uses={[Styles.sheet]}>
        <view.div uses={[Styles.sheetContent]}>
          <view.div uses={[Styles.heading]}>
            <Message {...messages.pageTitle} />
          </view.div>
          <view.div uses={[tSmall, Styles.body]}>
            <Message {...messages.reuseBody} />
          </view.div>
        </view.div>
        {accountEmail ? (
          <AccountBox email={accountEmail} />
        ) : (
          <view.div uses={[Styles.spinnerContainer]}>
            <Spinner size="large" />
          </view.div>
        )}
      </view.div>
    </BottomSheet>
  );
}
