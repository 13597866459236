import * as React from 'react';

import {
  BreakpointContext,
  ConnectIframeContext,
} from 'gelato/frontend/src/lib/contexts';
import Box from 'sail/Box';

import type {Breakpoint} from 'gelato/frontend/src/lib/contexts';
import type {BackgroundColor, SpacingValue, SpacingMap} from 'sail/Box/types';

export const responsiveDivider = (breakpoint: Breakpoint) => {
  return breakpoint === 'mobile'
    ? undefined
    : {color: 'light', width: 1, side: 'bottom'};
};

type responsiveBackgroundProps = {
  background?: BackgroundColor;
  breakpoint: Breakpoint;
  inIframe: boolean;
};

export const responsiveBackground = ({
  background,
  breakpoint,
  inIframe,
}: responsiveBackgroundProps) => {
  if (breakpoint === 'mobile' && !inIframe) {
    return 'white';
  }
  return background;
};

type ResponsiveBoxProps = {
  children: React.ReactNode;
  background?: BackgroundColor;
  className?: string;
  padding?: SpacingValue | SpacingMap;
};

export const ResponsiveBox = ({
  background,
  children,
  className,
  padding,
}: ResponsiveBoxProps) => {
  const breakpoint = React.useContext(BreakpointContext);
  const inIframe = React.useContext(ConnectIframeContext);
  return (
    <Box
      className={className}
      // @ts-expect-error - TS2322 - Type '{ color: string; width: number; side: string; } | undefined' is not assignable to type 'DividerMap | null | undefined'.
      divider={responsiveDivider(breakpoint)}
      background={responsiveBackground({background, breakpoint, inIframe})}
      padding={padding}
    >
      {children}
    </Box>
  );
};
