import {intersection} from 'lodash';
import * as React from 'react';

import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {useRouter} from 'gelato/frontend/src/lib/hooks';

import type {IndividualFields} from '@stripe-internal/data-gelato/schema/types';
import type {Session} from 'gelato/frontend/src/lib/contexts';

type CallbackType = () => Promise<void>;
type Props = {
  requiredFields: ReadonlyArray<IndividualFields>;
  onSkip: CallbackType;
  pageFields: Array<IndividualFields>;
  children: React.ReactNode;
};

export default function SkippablePage({
  children,
  onSkip,
  pageFields,
  requiredFields,
}: Props) {
  const requiredPageFields = intersection(requiredFields, pageFields);

  if (!requiredPageFields.length) {
    onSkip();
    return <LoadingBox />;
  }

  return children;
}

type SkippableDataCollectionPageProps = {
  pageFields: Array<IndividualFields>;
  session: Session;
  children: React.ReactNode;
};

export function SkippableDataCollectionPage({
  pageFields,
  session,
  children,
}: SkippableDataCollectionPageProps) {
  const requiredFields = session.requiredFields;
  const router = useRouter();
  const handleOnSkip = React.useCallback(async () => {
    const skipToPage = await nextDataPageForSession(session);
    router.push(skipToPage);
  }, [router, session]);

  return (
    // @ts-expect-error - TS2786 - 'SkippablePage' cannot be used as a JSX component.
    <SkippablePage
      onSkip={handleOnSkip}
      pageFields={pageFields}
      requiredFields={requiredFields}
    >
      {children}
    </SkippablePage>
  );
}
