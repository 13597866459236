import * as React from 'react';
import {SafeAnchor} from '@stripe-internal/safe-links';
import {VIEW_CONFIG, getViewConfig} from './ViewConfig';
import type {ViewConfig} from './types';

const DEFAULT_ELEMENT = 'div';

export function createElement(props: unknown): React.ReactElement {
  const config = getViewConfig(props as {children?: React.ReactNode});
  let tag = (config.tag || DEFAULT_ELEMENT) as string | typeof SafeAnchor;

  if (tag === 'a') {
    tag = SafeAnchor;
  }

  const rawProps = config.props as {[VIEW_CONFIG]: ViewConfig | void};
  rawProps[VIEW_CONFIG] = undefined;
  const element = React.createElement(tag, config.props, config.props.children);
  rawProps[VIEW_CONFIG] = config;

  return element;
}
