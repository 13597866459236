import {ConsentDataInput} from '@stripe-internal/data-gelato/schema/types';

import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import updateConsentMutation from 'gelato/frontend/src/controllers/utils/updateConsentMutation';
import {getConfigValue} from 'gelato/frontend/src/lib/config';

import type {GraphQlField} from '@sail/data';
import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

type Session = GraphQlField<GetSessionQueryData, 'session'>;

/**
 * Updates the verification consent and proceeds to the next step.
 * @param state The application state.
 * @param runtime The application runtime.
 * @returns The updated session with the consent field changed.
 */
export default async function updateConsent(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
  payload: ConsentDataInput,
): Promise<Session> {
  const {session} = state;
  const {requiredFields, livemode} = session!;
  const {accepted, selfieVerificationMethod} = payload;

  if (!requiredFields?.includes('consent') && livemode) {
    // There's only 2 valid reasons why consent would not be given:
    // - Unless in test mode, the following rules must be followed.
    // Consent must be required so that user could accept or decline it.
    // - We only need to update the selfie_verification_method
    // Otherwise, user should not invoke this action at first place.
    throw new Error(ErrorCode.consentIsNotRequired);
  }

  const consentConfig = session!.consentConfig;

  const resp = await updateConsentMutation(runtime.apolloClient, {
    consentData: {
      accepted,
      gitHash: getConfigValue('COMMIT_HASH'),
      imageMode: consentConfig?.imageMode,
      permissions: ['just_verify_fraud_and_security'],
      selfieVerificationMethod,
      stripeInternal: consentConfig?.stripeInternal,
      useCase: consentConfig?.useCase,
    },
  });
  const nextSession = resp.data?.updateConsent?.session as Session | undefined;
  const needsConsent = nextSession!.missingFields!.includes('consent');

  if (accepted && needsConsent) {
    throw new Error(ErrorCode.sessionConsentDidNotUpdate);
  }

  return nextSession!;
}
