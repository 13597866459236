import {isEmail} from 'validator';

import NetworkedIdentityReuseSheet from 'gelato/frontend/src/components/Link/ReuseSheet';
import {openLayerAction} from 'gelato/frontend/src/controllers/actions/layerActions';
import {startAction} from 'gelato/frontend/src/controllers/actions/mutationActions';
import {lookupConsumerAccountAction} from 'gelato/frontend/src/controllers/actions/networkedIdentityActions';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {
  setEmail,
  setEmailIsValid,
  setClientReferenceId,
} from 'gelato/frontend/src/controllers/states/FlowsState';
import {isEmailOTPVerificationRequired} from 'gelato/frontend/src/controllers/states/SessionState';
import fetchStartCodeResponseForSlugAndEmail from 'gelato/frontend/src/controllers/utils/fetchStartCodeResponseForSlugAndEmail';
import fetchStaticDataFromWindowLocation from 'gelato/frontend/src/controllers/utils/fetchStaticDataFromWindowLocation';
import {getSessionQuery} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';
import asError from 'gelato/frontend/src/lib/asError';
import Storage from 'gelato/frontend/src/lib/Storage';
import {SlugResponse} from 'gelato/frontend/src/local_pages/verify';
import {StartCodeResponse} from 'gelato/frontend/src/services/apis/start_code';
import storeSessionStateFromStartCodeResponse from 'gelato/frontend/src/services/utils/storeSessionStateFromStartCodeResponse';

import type {GraphQlField} from '@sail/data';
import type {
  ApplicationAction,
  ApplicationActionWithPayload,
  ApplicationController,
} from 'gelato/frontend/src/controllers/types';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

export const updateFlowsEmailAction: ApplicationActionWithPayload<{
  email: string;
}> = async (controller, data) => {
  return controller.update((draft) => {
    setEmail(draft, data.email);
    setEmailIsValid(draft, isEmail(data.email));
  });
};

export const updateClientReferenceIdAction: ApplicationActionWithPayload<{
  clientReferenceId: string;
}> = async (controller, data) => {
  return controller.update((draft) => {
    setClientReferenceId(draft, data.clientReferenceId);
  });
};

export const initializeFlowsContinueAction: ApplicationAction = async (
  controller,
) => {
  return controller.update((draft) => {
    // set continuing state as begun so we know that a continue is in progress
    draft.flows.continuing = true;
  });
};

export const finalizeFlowsContinueAction: ApplicationAction = async (
  controller,
) => {
  return controller.update((draft) => {
    // mark continuing state as completed
    draft.flows.continuing = false;
  });
};

export const intializeSessionApplicationState = (
  controller: ApplicationController,
  session: GraphQlField<GetSessionQueryData, 'session'>,
  initialSessionResponse: StartCodeResponse,
) => {
  try {
    return controller.update((draft) => {
      draft.session = session;
      // Set up networked Identity
      draft.networkedIdentity.enabled =
        initialSessionResponse.settings?.networked_identity_enabled || false;
      draft.networkedIdentity.reuseEnabled =
        initialSessionResponse.settings?.networked_identity_reuse_available ||
        false;
    });
  } catch (e) {
    controller.update((draft) => {
      draft.error = asError(e);
    });
  }
};

export const getFlowsStaticDataFromLocationAction: ApplicationActionWithPayload<{
  location: Location;
}> = async (controller, {location}) => {
  if (controller.state.flows.fetching) {
    return;
  }

  let staticDataResponse: SlugResponse | undefined;
  try {
    controller.update((draft) => {
      draft.flows.fetching = true;
    });
    // exchange location data for the static configuration data for this session
    // this sets all of this data in session storage and includes the merchan pk for this template
    staticDataResponse = await fetchStaticDataFromWindowLocation(
      location.search,
      location.pathname,
    );

    controller.update((draft) => {
      draft.flows.staticData = staticDataResponse;
      draft.flows.fetching = false;
    });
    return true;
  } catch (e) {
    controller.update((draft) => {
      draft.error = asError(e);
      draft.flows.fetching = false;
    });
    return false;
  }
};

export const handleFlowsContinueAction: ApplicationAction = async (
  controller,
) => {
  if (!controller.state.flows.emailIsValid) {
    return;
  }

  let initialSessionResponse: StartCodeResponse | undefined;
  let getSessionResponse: {data: GetSessionQueryData} | undefined;
  try {
    // first send the email and slug to the server to create a new verification session
    initialSessionResponse = await fetchStartCodeResponseForSlugAndEmail(
      controller.state.flows.slug,
      controller.state.flows.email,
      controller.state.flows.clientReferenceId,
    );

    // store the response - this includes the api key that will be used to fetch
    // session details through the graphql API
    storeSessionStateFromStartCodeResponse(initialSessionResponse, false);
    // eslint-disable-next-line @sail/data-no-imperative-methods
    getSessionResponse = await controller.runtime!.apolloClient.query({
      query: getSessionQuery,
    });
  } catch (e) {
    const error = asError(e);
    if (
      error.message === ErrorCode.flowsSessionCreationRateLimited ||
      error.message === ErrorCode.flowsSessionCreationFailedOther
    ) {
      controller.update((draft) => {
        draft.error = error;
      });
    }
  }
  const session = getSessionResponse?.data?.session;

  if (initialSessionResponse && session) {
    return intializeSessionApplicationState(
      controller,
      session,
      initialSessionResponse,
    );
  }
};

export const handleCommonFlowsSessionAction: ApplicationActionWithPayload<{
  action: ApplicationAction;
}> = async (controller, data) => {
  // early exit if we are already continuing
  if (controller.state.flows.continuing) {
    return;
  }

  if (isEmailOTPVerificationRequired(controller.state)) {
    Storage.setEmailOTPConsent(true);
  }

  await initializeFlowsContinueAction(controller);
  await handleFlowsContinueAction(controller);
  await data.action(controller);

  await finalizeFlowsContinueAction(controller);
};

export const handleFlowsSessionInitializationAction: ApplicationAction = async (
  controller,
) => {
  await handleCommonFlowsSessionAction(controller, {
    action: async () => {
      const userProvidedEmail =
        controller.state.session?.collectedData?.individual?.email
          ?.userProvidedAddress;

      const shouldLookupConsumerAccount =
        userProvidedEmail &&
        controller.state.networkedIdentity.reuseEnabled &&
        !controller.state.networkedIdentity.accountSearchLoading &&
        !controller.state.networkedIdentity.consumerSession;

      if (shouldLookupConsumerAccount) {
        await lookupConsumerAccountAction(controller, {
          email: userProvidedEmail,
        });
        await openLayerAction(controller, NetworkedIdentityReuseSheet);
      } else {
        await startAction(controller, {
          consentAccepted: true,
          reason: 'leave_welcome',
        });
      }
    },
  });
};
