import * as React from 'react';
import {createViewConfig} from '@sail/engine';
import {tokens} from '../tokens';
import type {View} from '../view';

export const DividerConfig = createViewConfig({
  props: {} as View.IntrinsicElement<'span'>,
  name: 'Divider',
  flattens: true,
});

/**
 * @external
 */
export const Divider = DividerConfig.createView(
  (props) => {
    return <span {...props} role="separator" style={{flex: '0 0 1px'}} />;
  },
  {
    css: {
      display: 'block',
      backgroundColor: tokens.color.border,
      alignSelfX: 'stretch',
      alignSelfY: 'stretch',
      paddingLeft: '1px',
      paddingTop: '1px',
    },
  },
);
