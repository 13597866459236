import type {
  Collection,
  DOMAttributes,
  KeyboardDelegate,
  Node,
} from '@react-types/shared';
import type {Key} from 'react';
import {useMemo} from 'react';
import {useCollator} from '../i18n';
import {useSelectableCollection} from './useSelectableCollection';
import type {AriaSelectableCollectionOptions} from './useSelectableCollection';
import {ListKeyboardDelegate} from './ListKeyboardDelegate';

export interface AriaSelectableListOptions
  extends Omit<AriaSelectableCollectionOptions, 'keyboardDelegate'> {
  /**
   * State of the collection.
   */
  collection: Collection<Node<unknown>>;
  /**
   * A delegate object that implements behavior for keyboard focus movement.
   */
  keyboardDelegate?: KeyboardDelegate;
  /**
   * The item keys that are disabled. These items cannot be selected, focused, or otherwise interacted with.
   */
  disabledKeys: Set<Key>;
}

export interface SelectableListAria {
  /**
   * Props for the option element.
   */
  listProps: DOMAttributes;
}

/**
 * Handles interactions with a selectable list.
 */
export function useSelectableList(
  props: AriaSelectableListOptions,
): SelectableListAria {
  const {selectionManager, collection, disabledKeys, ref, keyboardDelegate} =
    props;

  // By default, a KeyboardDelegate is provided which uses the DOM to query layout information (e.g. for page up/page down).
  // When virtualized, the layout object will be passed in as a prop and override this.
  const collator = useCollator({usage: 'search', sensitivity: 'base'});
  const disabledBehavior = selectionManager.disabledBehavior;
  const delegate = useMemo(
    () =>
      keyboardDelegate ||
      new ListKeyboardDelegate(
        collection,
        disabledBehavior === 'selection' ? new Set() : disabledKeys,
        ref,
        collator,
      ),
    [
      keyboardDelegate,
      collection,
      disabledKeys,
      ref,
      collator,
      disabledBehavior,
    ],
  );

  const {collectionProps} = useSelectableCollection({
    ...props,
    ref,
    selectionManager,
    keyboardDelegate: delegate,
  });

  return {
    listProps: collectionProps,
  };
}
