import {view} from '@sail/ui';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {injectIntl} from 'react-intl';

import Generic from 'gelato/frontend/src/components/Invalid/Generic';
import invalidMessages from 'gelato/frontend/src/components/Invalid/messages';
import VerificationFlowRateLimited from 'gelato/frontend/src/components/Invalid/VerificationFlowRateLimiited';
import PageComponent from 'gelato/frontend/src/components/PageComponent';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {PageProps} from 'gelato/frontend/src/lib/localRouter';
import type {IntlShape} from 'react-intl';

type InactiveVerifyLinkPageInnerProps = {
  platformName: string;
  intl: IntlShape;
};

const InactiveVerifyLinkPageInner = (
  props: InactiveVerifyLinkPageInnerProps,
) => {
  const {
    platformName,
    intl: {formatMessage},
  } = props;
  const {appState} = useAppController();
  const errorMessage = appState.error?.message;

  const Component =
    errorMessage === ErrorCode.flowsSessionCreationRateLimited
      ? VerificationFlowRateLimited
      : Generic;

  return (
    <view.div
      css={{
        stack: 'y',
        alignY: 'center',
        alignX: 'center',
        width: 'fill',
        textAlign: 'center',
      }}
    >
      <Helmet>
        <title>{formatMessage(invalidMessages.documentTitle)}</title>
      </Helmet>
      <Component platformName={platformName} />
    </view.div>
  );
};

const InactiveVerifyLinkPage = (props: {intl: IntlShape} & PageProps) => {
  const {params, router} = props;

  const livemode = Storage.getLivemode();
  const {platformName, platformColor, platformIcon, buttonColor} =
    Storage.getBranding();

  const backgroundProps = React.useMemo(() => {
    return {
      platformColor,
      buttonColor,
    };
  }, [buttonColor, platformColor]);

  const pageCardProps = React.useMemo(() => {
    return {
      platformIcon,
      platformName,
      livemode,
      showBackLink: false,
      showProgressBar: false,
      height: 'fill',
    };
  }, [platformIcon, platformName, livemode]);

  const pageProps = React.useMemo(() => {
    return {
      ...props,
      platformName,
    };
  }, [props, platformName]);

  return (
    <PageComponent<InactiveVerifyLinkPageInnerProps>
      Component={InactiveVerifyLinkPageInner}
      pageProps={pageProps}
      backgroundProps={backgroundProps}
      pageCardProps={pageCardProps}
      params={params}
      router={router}
    />
  );
};

setComponentConfig(InactiveVerifyLinkPage, {skipApp: true});

export default injectIntl(InactiveVerifyLinkPage);
