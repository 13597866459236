import {Kind} from 'graphql';

import type {DocumentNode, OperationTypeNode} from 'graphql';

function findOperationDefinition(doc: DocumentNode) {
  return doc.definitions.find((def) => def.kind === Kind.OPERATION_DEFINITION);
}

export function getOperationName(doc: DocumentNode): string | null {
  return findOperationDefinition(doc)?.name?.value ?? null;
}

export function getOperationType(doc: DocumentNode): OperationTypeNode | null {
  return findOperationDefinition(doc)?.operation ?? null;
}
