/**
 * @fileoverview Actions that manage Networked Identity state for the controller.
 */

import Storage from 'gelato/frontend/src/lib/Storage';

import type {CountryCode} from '@sail/ui';
import type {
  ConsumerSession,
  ConsumerIdentityDocument,
  ConsumerIdentityDocumentApi,
} from 'gelato/frontend/src/api/Consumer/types';
import type {MessageDescriptor} from 'react-intl';

export type LookupError = 'generic' | 'rate_limited';

export enum ConsumerAccountLookupError {
  NOT_FOUND = 'not_found',
}

export enum LoadConsumerIdentityDocumentsError {
  NO_DOCUMENTS = 'no_documents',
}

export enum ConsumerAccountError {
  GENERIC = 'generic',
  RATE_LIMIT_EXCEEDED = 'rate_limit_exceeded',
}

export enum BottomSheetStep {
  SaveId,
  Continue,
  SignUp,
  AreYouSure,
}

export type NetworkedIdentityState = {
  networkedIdentity: {
    bottomSheetStep: BottomSheetStep;
    bottomSheetPreviousStep: BottomSheetStep;
    enabled: boolean;
    reuseEnabled: boolean;
    networkingOptIn: boolean;
    consumerAccountId: string | undefined;
    consumerAccountLoading: boolean;
    consumerAccountLookupError: ConsumerAccountLookupError | undefined;
    consumerSession: ConsumerSession | undefined;
    consumerDocuments: ConsumerIdentityDocument<ConsumerIdentityDocumentApi>[];
    consumerPhoneCountry: CountryCode | undefined;
    selectedDocument: string | undefined;
    selectedInvalidDocumentId: string | undefined;
    // If user skips logging into Link at the top of the funnel, we should
    // not show them the Link account login screen again.
    // TODO(colinlmcdonald, 2024-10-31): Remove this once networkingData.skipsDocumentNetworking has been rolled out
    skipped: boolean;
    consumerEmail: string;
    consumerPhone: string;
    accountSearchLoading: boolean;
    lookupError: LookupError | undefined;
    requestConsumerPhone: boolean;
    otpValue: string;
    otpLoading: boolean;
    otpError: MessageDescriptor | undefined;
    canResendOtp: boolean;
    otpSent: boolean;
    otpRequired: boolean;
    otpVerified: boolean;
    shareError: boolean;
    // Whether a document has been successfully shared or not.
    shareSuccess: boolean;
    signupConsumerAccountLoading: boolean;
    unavailable: boolean;
    uploadNewData: boolean;
  };
};

/**
 * Creates the initial networked identity state for the controller.
 * @returns The initial state.
 */
export function createNetworkedIdentityState(): NetworkedIdentityState {
  return {
    networkedIdentity: {
      bottomSheetStep: BottomSheetStep.SaveId,
      bottomSheetPreviousStep: BottomSheetStep.SaveId,
      enabled: false,
      reuseEnabled: false,
      networkingOptIn: false,
      consumerAccountLookupError: undefined,
      consumerAccountId: undefined,
      consumerAccountLoading: false,
      consumerPhoneCountry: undefined,
      consumerSession: undefined,
      consumerDocuments: [],
      selectedDocument: undefined,
      selectedInvalidDocumentId: undefined,
      skipped: false,
      consumerEmail: '',
      consumerPhone: '',
      accountSearchLoading: false,
      lookupError: undefined,
      requestConsumerPhone: false,
      otpValue: '',
      otpLoading: false,
      otpError: undefined,
      canResendOtp: false,
      otpSent: false,
      otpRequired: false,
      otpVerified: false,
      shareError: false,
      shareSuccess: false,
      signupConsumerAccountLoading: false,
      unavailable: false,
      uploadNewData: false,
    },
  };
}

/**
 * Sets whether or not user opted in to networking.
 * @param state The current state.
 * @param networkingOptIn Whether or not user opted in to networking.
 */
export function setNetworkingOptIn(
  state: NetworkedIdentityState,
  networkingOptIn: boolean,
) {
  state.networkedIdentity.networkingOptIn = networkingOptIn;
}

/**
 * Sets whether or not the user skipped networking.
 * @param state The current state.
 * @param skipped Whether or not user skipped networking.
 */
export function setNetworkingSkipped(
  state: NetworkedIdentityState,
  skipped: boolean,
) {
  state.networkedIdentity.skipped = skipped;
}

/**
 * Sets current consumer email address value.
 * @param state The current state.
 * @param consumerEmail Consumer email address input.
 */
export function setConsumerEmail(
  state: NetworkedIdentityState,
  consumerEmail: string,
) {
  state.networkedIdentity.consumerEmail = consumerEmail;
}

/**
 * Sets current consumer phone value.
 * @param state The current state.
 * @param consumerPhone Consumer phone input.
 */
export function setConsumerPhone(
  state: NetworkedIdentityState,
  consumerPhone: string,
) {
  state.networkedIdentity.consumerPhone = consumerPhone;
}

/**
 * Sets the consumer account lookup error.
 * @param state The current state.
 * @param consumerAccountLookupError Error from the consumer account lookup.
 */
export function setConsumerAccountLookupError(
  state: NetworkedIdentityState,
  consumerAccountLookupError: ConsumerAccountLookupError | undefined,
) {
  state.networkedIdentity.consumerAccountLookupError =
    consumerAccountLookupError;
}

/**
 * Sets Link consumer account ID.
 * @param state The current state.
 * @param consumerAccountId Link consumer account ID
 */
export function setConsumerAccountId(
  state: NetworkedIdentityState,
  consumerAccountId: string | undefined,
) {
  state.networkedIdentity.consumerAccountId = consumerAccountId;
}

/**
 * Sets the consumer session for networked identity.
 * @param state The current state.
 * @param consumerSession Link account consumer session
 */
export function setConsumerSession(
  state: NetworkedIdentityState,
  consumerSession: ConsumerSession | undefined,
) {
  state.networkedIdentity.consumerSession = consumerSession;
  // While we are rolling out Butter 2.0, we need to make sure
  // that the consumer session in AppController is aligned with
  // the consumer session in session storage.
  Storage.setConsumerSession(consumerSession);
}

/**
 * Sets whether or not to require phone number for consumer auth.
 * @param state The current state.
 * @param requestConsumerPhone whether or not to require phone number for consumer auth
 */
export function setRequestConsumerPhone(
  state: NetworkedIdentityState,
  requestConsumerPhone: boolean,
) {
  state.networkedIdentity.requestConsumerPhone = requestConsumerPhone;
}

/**
 * Sets whether or not Link consumer account search is loading.
 * @param state The current state.
 * @param accountSearchLoading Is Link consumer account search loading?
 */
export function setAccountSearchLoading(
  state: NetworkedIdentityState,
  accountSearchLoading: boolean,
) {
  state.networkedIdentity.accountSearchLoading = accountSearchLoading;
}

/**
 * Sets Link consumer account lookup error.
 * @param state The current state.
 * @param lookupError Link consumer account lookup error
 */
export function setLookupError(
  state: NetworkedIdentityState,
  lookupError: LookupError | undefined,
) {
  state.networkedIdentity.lookupError = lookupError;
}

/**
 * Sets whether or not Link account auth OTP is required.
 * @param state The current state.
 * @param otpRequired Whether or not Link account auth OTP is required.
 */
export function setOtpRequired(
  state: NetworkedIdentityState,
  otpRequired: boolean,
) {
  state.networkedIdentity.otpRequired = otpRequired;
}

/**
 * Sets the current Link account auth OTP value
 * @param state The current state.
 * @param otpValue Link account auth OTP value
 */
export function setOtpValue(state: NetworkedIdentityState, otpValue: string) {
  state.networkedIdentity.otpValue = otpValue;
}

/**
 * Sets the Link account auth OTP verification loading
 * @param state The current state.
 * @param otpLoading Link account auth OTP verification loading
 */
export function setOtpLoading(
  state: NetworkedIdentityState,
  otpLoading: boolean,
) {
  state.networkedIdentity.otpLoading = otpLoading;
}

/**
 * Sets the Link account auth OTP error
 * @param state The current state.
 * @param otpError Link account auth OTP error.
 */
export function setOtpError(
  state: NetworkedIdentityState,
  otpError: MessageDescriptor | undefined,
) {
  state.networkedIdentity.otpError = otpError;
}

/**
 * Sets whether or not Link account auth OTP can be resent
 * @param state The current state.
 * @param canResendOtp Whether or not Link account auth OTP can be resent.
 */
export function setCanResendOtp(
  state: NetworkedIdentityState,
  canResendOtp: boolean,
) {
  state.networkedIdentity.canResendOtp = canResendOtp;
}

/**
 * Sets whether or not Link consumer account auth was verified.
 * @param state The current state.
 * @param otpVerified Whether or not Link consumer account auth was verified.
 */
export function setOtpVerified(
  state: NetworkedIdentityState,
  otpVerified: boolean,
) {
  state.networkedIdentity.otpVerified = otpVerified;
}

/**
 * Sets whether or not Link consumer account auth OTP was sent.
 * @param state The current state.
 * @param otpSent Whether or not Link consumer account auth OTP was sent.
 */
export function setOtpSent(state: NetworkedIdentityState, otpSent: boolean) {
  state.networkedIdentity.otpSent = otpSent;
}

/**
 * Sets current user selected networked identity document for reuse.
 * @param state The current state.
 * @param selectedDocument User selected networked identity document for reuse.
 */
export function setSelectedDocument(
  state: NetworkedIdentityState,
  selectedDocument: string | undefined,
) {
  state.networkedIdentity.selectedDocument = selectedDocument;
  // While we are rolling out Butter 2.0, we need to make sure
  // that the selected document id in AppController is aligned with
  // the selected document id in session storage.
  Storage.setNetworkedDocumentId(selectedDocument);
}

/**
 * Sets current user available networked identity documents for reuse.
 * @param state The current state.
 * @param consumerDocuments User available networked identity documents for reuse.
 */
export function setConsumerDocuments(
  state: NetworkedIdentityState,
  consumerDocuments: ConsumerIdentityDocument<ConsumerIdentityDocumentApi>[],
) {
  state.networkedIdentity.consumerDocuments = consumerDocuments;
}

/**
 * Sets networked identity document reuse error.
 * @param state The current state.
 * @param shareError Networked identity document reuse error
 */
export function setShareError(
  state: NetworkedIdentityState,
  shareError: boolean,
) {
  state.networkedIdentity.shareError = shareError;
}

/**
 * Sets whether or not networked identity is unavailable.
 * @param state The current state.
 * @param unavailable Whether or not networked identity is unavailable.
 */
export function setUnavailable(
  state: NetworkedIdentityState,
  unavailable: boolean,
) {
  state.networkedIdentity.unavailable = unavailable;
}

/**
 * Sets whether or not the user wants to upload a new document.
 * @param state The current state.
 * @param uploadNewDocument Whether or not the user wants to upload a new document.
 */
export function setUploadNewData(
  state: NetworkedIdentityState,
  uploadNewData: boolean,
) {
  state.networkedIdentity.uploadNewData = uploadNewData;
}

/**
 * Sets whether or not the user wants to upload a new document.
 * @param state The current state.
 * @param uploadNewDocument Whether or not the user wants to upload a new document.
 */
export function setSignupConsumerAccountLoading(
  state: NetworkedIdentityState,
  loading: boolean,
) {
  state.networkedIdentity.signupConsumerAccountLoading = loading;
}

/**
 * Sets the bottom sheet step for save ID with Link flow.
 * @param state The current state.
 * @param step The bottom sheet step.
 */
export function setBottomSheetStep(
  state: NetworkedIdentityState,
  step: BottomSheetStep,
) {
  state.networkedIdentity.bottomSheetPreviousStep =
    state.networkedIdentity.bottomSheetStep;
  state.networkedIdentity.bottomSheetStep = step;
}
