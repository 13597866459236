import * as React from 'react';

import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {ContentState} from 'sail/Content';

export default function UnsupportedHandoffDevice() {
  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'device_error',
      code: 'device_unsupported',
    });
  });
  const title = <Message {...messages.unsupportedHandoffDeviceTitle} />;
  const description = <Message {...messages.unsupportedHandoffDeviceBody} />;
  return (
    <ContentState title={title} icon="dispute" description={description} />
  );
}
