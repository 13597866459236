export function isScrollable(node: Element): boolean {
  const style = window.getComputedStyle(node);
  return /(auto|scroll)/.test(
    style.overflow + style.overflowX + style.overflowY,
  );
}

export function getScrollParent(node: Element): Element {
  let currentNode: Element | null = node;
  if (isScrollable(currentNode)) {
    currentNode = currentNode.parentElement;
  }

  while (currentNode && !isScrollable(currentNode)) {
    currentNode = currentNode.parentElement;
  }

  return currentNode || document.scrollingElement || document.documentElement;
}
