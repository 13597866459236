import clamp from 'lodash/clamp';

import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import experiments from 'gelato/frontend/src/lib/experiments';
import {
  requestReusableCanvas,
  releaseReusableCanvas,
} from 'gelato/frontend/src/lib/ReusableCanvas';
import {handleException} from 'gelato/frontend/src/lib/sentry';

import type {PDFDocumentProxy, PDFPageProxy} from 'pdfjs-dist/legacy/build/pdf';

export type PDFPasswordProvider = () => string;

type PDFJSModule = typeof import('pdfjs-dist/legacy/build/pdf');

type HEIC2AnyModule = typeof import('heic2any');

type PDFImageObject = {
  data: Uint8Array;
  height: number;
  width: number;
};

export type InvalidImageInfo = {
  isValidImage: boolean;
  pixelCount: number;
  totalPixels: number;
  width: number;
  height: number;
  percentBlackPixels: number;
};

// Supported image frame sources.
// Includes only types compatible with CanvasImageSource.
type ImageFrameSource =
  // This is supported by most browsers.
  | ImageBitmap
  // This is supported by IOS 14 and lower.
  | HTMLImageElement;

// Map from ImageFrame to ImageFrameSource.
const ImageFrameSourceMap = new WeakMap<ImageFrame, ImageFrameSource>();

/**
 * The minimum pixel dimensions for a PDF image required to be extracted.
 * Images smaller than this size will be ignored.
 */
const MIN_PDF_IMAGE_DIMENSION_SIZE = 80;

/**
 * The maximum number of pages to extract from a PDF file.
 */
const MAX_PDF_PAGES_COUNT = 5;

/**
 * Track the error and report it to Sentry.
 * @param operation The operation that caused the error.
 * @param ex The error object.
 * @param relatedData The data that is related with the error.
 */
function trackError(
  operation: string,
  cause: Error,
  relatedData?:
    | File
    | HTMLCanvasElement
    | {width: number; height: number}
    | string,
): void {
  let relatedMessage = '';

  if (typeof relatedData === 'string') {
    relatedMessage = ` - ${relatedData}`;
  } else if (relatedData instanceof ImageFrame) {
    relatedMessage = ` - ${relatedData.toString()}`;
  } else if (relatedData instanceof File) {
    const {type, size} = relatedData;
    relatedMessage = ` - type = ${type}, size = ${size}`;
  } else if (relatedData) {
    const {width, height} = relatedData;
    relatedMessage = ` - width = ${width}, height = ${height}`;
  }

  const code = `${ErrorCode.failedToCreateImageFrame}:${operation}${relatedMessage}`;
  const error = new Error(code, {cause});

  const message = `${code} - ${cause.message}`;
  handleException(error, message);
}

/**
 * Create a file blob as a placeholder.
 * @returns The placeholder blob.
 */
function createPlaceholderBlob(): Blob {
  return new Blob([], {type: 'image/jpeg'});
}

/**
 * Create a placeholder image data.
 * @returns The placeholder image data.
 */
function createPlaceholderImageData(): ImageData {
  if (typeof ImageData === 'undefined') {
    // For Android 4.x
    const imageData: unknown = Object.freeze({
      width: 1,
      height: 1,
      data: [0, 0, 0, 0],
    });
    return imageData as ImageData;
  } else {
    return new ImageData(1, 1);
  }
}

// The native createImageBitmap function.
// This is null if the browser does not support it (e.g. IOS 14 or Android 4.x).
const createImageBitmapNative = window.createImageBitmap || null;

// The ImageBitmap class.
// This is a dumb class if the browser does not support it.
const ImageBitmapClass = window.ImageBitmap || class ImageBitmapMock {};

// All the canvas used by ImageFrame are meant to be used offscreen (i.e not
// being rendered within <body />). We can use the offscreen canvas context
// options to improve the performance.
const OFFSCREEN_CANVAS_CONTEXT_OPTIONS: CanvasRenderingContext2DSettings = {
  // We don't need alpha channel which is only useful for transparent pixels.
  alpha: false,
  // The canvas does not need to be synchronized since we don't display  it.
  desynchronized: true,
  // We will read the canvas frequently.
  willReadFrequently: true,
};

// Experimenting with the same settings as above, but with synchronized canvases
const OFFSCREEN_CANVAS_CONTEXT_OPTIONS_SYNC: CanvasRenderingContext2DSettings =
  {
    alpha: false,
    desynchronized: false,
    willReadFrequently: true,
  };

/**
 * The primary immutable data type representing an image frame in Butter.
 *
 * This provides a common interface for accessing image frames from different
 * sources, such as a video element, an image element, or a canvas element.
 *
 * The image frame is immutable, so it is safe to pass it around and use it
 * in different places.
 *
 * Note that the caller is responsible for calling dispose() to release the
 * underlying resources when the image frame is no longer needed.
 *
 * If the image frame fails to perform its openration, such as copying or
 * padding, it'd return a placeholder image frame. Caller should check the
 * placeholder property to see if the image frame is a placeholder.
 *
 * Note that ImageFrame interacts with canvas frequently, and it's likely that
 * the brower can't render canvas due to memory limitation. In this case, the
 * ImageFrame would return a placeholder image frame.
 */
export default class ImageFrame {
  /**
   * Create an image frame from a video element.
   */
  static fromVideoElement = async (
    ...args: Parameters<typeof createImageFrameByVideoElement>
  ) => {
    try {
      return await createImageFrameByVideoElement(...args);
    } catch (ex) {
      const error = asError(ex);
      trackError('fromVideoElement', error, args[0]);
      return createPlaceholderImageFrame(error);
    }
  };

  /**
   * Create an image frame from a image data.
   */
  static fromImageData = async (
    ...args: Parameters<typeof createImageFrameByImageData>
  ) => {
    try {
      return await createImageFrameByImageData(...args);
    } catch (ex) {
      const error = asError(ex);
      trackError('fromImageData', error, args[0]);
      return createPlaceholderImageFrame(error);
    }
  };

  /**
   * Create an empty image frame.
   */
  static createEmpty = async (
    ...args: Parameters<typeof createEmptyImageFrame>
  ) => {
    try {
      return await createEmptyImageFrame(...args);
    } catch (ex) {
      const error = asError(ex);
      trackError('createEmpty', error, {width: args[0], height: args[1]});
      return createPlaceholderImageFrame(error);
    }
  };

  /**
   * Create an image frame as a placeholder.
   */
  static createPlaceholder = createPlaceholderImageFrame;

  /**
   * Create an image frame from an object URL.
   */
  static fromObjectURL = async (url: string) => {
    try {
      return await createImageFrameByObjectURL(url);
    } catch (ex) {
      const error = asError(ex);
      trackError('fromObjectURL', error, url);
      return createPlaceholderImageFrame(error);
    }
  };

  static fromHEICFile = async (file: File) => {
    try {
      return await createImageFrameByHEICFile(file);
    } catch (ex) {
      const error = asError(ex);
      trackError('fromHEICFile', error, file);
      return createPlaceholderImageFrame(error);
    }
  };

  static fromPDFFile = async (
    file: File,
    providePassword: PDFPasswordProvider,
  ) => {
    try {
      return await createImageFrameByPDFFile(file, providePassword);
    } catch (ex) {
      const error = asError(ex);
      trackError('fromPDFFile', error, file);
      return createPlaceholderImageFrame(error);
    }
  };

  /**
   * Helper function to force the 2d context of a canvas with offscreen operations.
   */
  static forceOffscreenCanvasContext = forceOffscreenCanvasContext;

  /**
   * The width of the image frame.
   */
  public readonly width;

  /**
   * The height of the image frame.
   */
  public readonly height;

  /**
   * The related error of the image frame.
   */
  public readonly error: Error | null;

  /**
   * Whether the image frame is a placeholder.
   * A a placeholdere image frame should not be used for inspection or
   * uploading purpose since it has no real content.
   */
  public readonly placeholder;

  public readonly orientation: 'portrait' | 'landscape';

  /**
   * Whether the image frame is disposed.
   */
  private _disposed = false;

  constructor(source?: ImageFrameSource | Error | null | undefined) {
    if (source && !(source instanceof Error)) {
      this.width = source.width;
      this.height = source.height;
      this.placeholder = false;
      // Store the source in a WeakMap so that it can be garbage collected.
      ImageFrameSourceMap.set(this, source);
      this.error = null;
    } else {
      this.placeholder = true;
      this.width = 1;
      this.height = 1;
      this.placeholder = true;
      this.error = source instanceof Error ? source : null;
    }
    this.orientation = this.width >= this.height ? 'landscape' : 'portrait';
    this._disposed = false;
  }

  /**
   * Whether the image frame is disposed.
   */
  get disposed() {
    return this._disposed;
  }

  /**
   * Returns the string representation of the image frame.
   */
  toString(): string {
    const {width, height} = this;
    const source = ImageFrameSourceMap.get(this);
    let sourceType;
    if (this.placeholder) {
      sourceType = 'placeholder';
    } else if (source) {
      sourceType = Object.prototype.toString.call(source);
    } else {
      sourceType = 'null';
    }
    return `<ImageFrame(${sourceType})[${width},${height}]>`;
  }

  /**
   * Returns a JSON representation of the image frame.
   */
  toJSON(): {value: string} {
    return {value: this.toString()};
  }

  /**
   * Returns a string representation of the image frame.
   */
  valueOf(): string {
    return this.toString();
  }

  /**
   * Getter for the underlying image frame source.
   */
  getSource(): Readonly<ImageFrameSource> | null {
    const source = ImageFrameSourceMap.get(this);
    return source ?? null;
  }

  /**
   * Dispose the image frame and release the underlying resources.
   */
  dispose() {
    this._disposed = true;
    const source = this.getSource();
    if (source instanceof ImageBitmapClass) {
      source.close();
    } else if (source instanceof HTMLCanvasElement) {
      releaseReusableCanvas(source);
    } else if (source instanceof HTMLImageElement) {
      const {src} = source;
      src && URL.revokeObjectURL(src);
    } else {
      // The source is null or undefined.
      return;
    }
    ImageFrameSourceMap.delete(this);
  }

  /**
   * Clone the image frame.
   * @returns The cloned image frame.
   */
  async clone(): Promise<ImageFrame> {
    if (this.placeholder) {
      return createPlaceholderImageFrame();
    }
    return this.crop(0, 0, this.width, this.height);
  }

  /**
   * Convert the image frame to a Blob.
   * @returns The blob.
   */
  async toBlob(quality = 0.9): Promise<Blob> {
    if (this.placeholder) {
      return createPlaceholderBlob();
    }

    try {
      const canvas = await this.toHTMLCanvasElement();
      const blob = await canvasToBlob(canvas, quality);
      releaseReusableCanvas(canvas);
      return blob;
    } catch (ex) {
      const error = asError(ex);
      trackError('toBlob', error, this);
      return createPlaceholderBlob();
    }
  }

  /**
   * Convert the image frame to an ImageData.
   * @returns The image data.
   */
  async toImageData(): Promise<ImageData> {
    if (this.placeholder) {
      return createPlaceholderImageData();
    }

    try {
      const canvas = await this.toHTMLCanvasElement();
      const ctx = getOffscreenCanvas2dContext(canvas);
      const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
      releaseReusableCanvas(canvas);
      return data;
    } catch (ex) {
      const error = asError(ex);
      trackError('toImageData', error, this);
      return createPlaceholderImageData();
    }
  }

  /**
   * Convert the image frame to a HTMLCanvasElement.
   * Caller is responsible for calling `releaseReusableCanvas(canvas)` to
   * release the canvas.
   * @param imageSmoothingEnabled Whether to enable image smoothing.
   * @returns The canvas element.
   */
  private async toHTMLCanvasElement(
    imageSmoothingEnabled: boolean = true,
  ): Promise<HTMLCanvasElement> {
    const canvas = requestReusableCanvas();
    if (this.placeholder) {
      canvas.width = 1;
      canvas.height = 1;
      return canvas;
    }

    const source = this.getSource()!;
    canvas.width = source.width;
    canvas.height = source.height;
    const ctx = getOffscreenCanvas2dContext(canvas);
    setImageSmoothingEnabled(ctx, imageSmoothingEnabled);
    ctx.drawImage(source, 0, 0);
    return canvas;
  }

  /**
   * Compute the scale factor to fit the image frame into the specified size.
   * If the image frame is smaller than the specified size, the scale factor
   * will be greater than 1 (i.e. scale up). Otherwise, the scale factor will
   * be less than 1 (i.e. scale down).
   *
   * @param width The width of the container to fit the image frame.
   * @param height The height of the container to fit the image frame.
   * @returns The scale factor.
   */
  public computeFitToScale(width: number, height: number): number {
    // Choose the smaller scale value to ensure the content fits within the container
    if (this.placeholder) {
      // placeholder image cannot be scaled.
      return 1;
    }

    const source = this.getSource()!;
    const sw = source.width;
    const sh = source.height;

    // Calculate scale for width and height
    const widthScale = width / sw;
    const heightScale = height / sh;
    return Math.min(widthScale, heightScale);
  }

  /**
   * Compute the scale factor to ensure the image frame is the minimum size given
   * for both width and height.
   * If the image frame is smaller than the specified size, the scale factor
   * will be greater than 1 (i.e. scale up). Otherwise, the scale factor will
   * be less than 1 (i.e. scale down).
   * @param sideA One side of the container to fit the image frame.
   * @param sideB The other side of the container to fit the image frame.
   * @returns The scale factor.
   */
  public computeMinimumFitToScale(sideA: number, sideB: number): number {
    if (this.placeholder) {
      // placeholder image cannot be scaled.
      return 1;
    }

    const shortSide = Math.min(sideA, sideB);
    const longSide = Math.max(sideA, sideB);
    const source = this.getSource()!;
    const sw = source.width;
    const sh = source.height;

    // If width and height of image is the same, we'll use the max side
    const width = sw < sh ? shortSide : longSide;
    const height = sh < sw ? shortSide : longSide;

    // Calculate scale for width and height
    const widthScale = width / sw;
    const heightScale = height / sh;

    // Choose the bigger scale value to ensure the content is at least the
    // minimum height or width
    return Math.max(widthScale, heightScale);
  }

  /**
   * Detect issues with the image
   * @param enabled Whether or not we are allowed to perform validation
   */
  async maybeTrackImageIssues({
    enabled,
  }: {
    enabled: boolean;
  }): Promise<InvalidImageInfo | undefined> {
    if (!enabled || this.placeholder) {
      return;
    }

    try {
      const imageData = await this.toImageData();

      let count = 0;
      const totalPixels = imageData.data.length / 4;
      // We consider only single image value to be invalid
      let isValidImage = true;
      // Log the first pixel
      const r = imageData.data[0];
      const g = imageData.data[1];
      const b = imageData.data[2];
      if (r === 0 && g === 0 && b === 0) {
        count++;
      }

      for (let j = 1; j < totalPixels; j += 1) {
        const i = j * 4;
        const prevI = (j - 1) * 4;
        const currR = imageData.data[i + 0];
        const currG = imageData.data[i + 1];
        const currB = imageData.data[i + 2];
        // Compare value to previous pixel
        const prevR = imageData.data[prevI + 0];
        const prevG = imageData.data[prevI + 1];
        const prevB = imageData.data[prevI + 2];

        if (currR === 0 && currG === 0 && currB === 0) {
          count++;
        }

        if (
          isValidImage &&
          currR === prevR &&
          currG === prevG &&
          currB === prevB
        ) {
          isValidImage = false;
        }
      }

      return {
        isValidImage,
        pixelCount: count,
        width: imageData.width,
        height: imageData.height,
        totalPixels,
        percentBlackPixels: count / totalPixels,
      };
    } catch (ex) {
      const error = asError(ex);
      trackError('maybeTrackImageIssues', error, this);
    }
  }

  /**
   * Scale the image frame to the specified size.
   * @param scale The scale factor.
   * @param smoothingEnabled Whether to enable image smoothing.
   * @returns The scaled image frame.
   */
  async scaleTo(
    scale: number,
    smoothingEnabled: boolean = true,
  ): Promise<ImageFrame> {
    if (this.placeholder) {
      // placeholder image cannot be scaled.
      return this.clone();
    }

    try {
      if (isNaN(scale) || scale <= 0) {
        throw new Error(`${ErrorCode.invalidScale} "${scale}"`);
      }

      const source = this.getSource()!;

      const {width, height} = source;
      const canvas = requestReusableCanvas();
      const cw = Math.round(width * scale);
      const ch = Math.round(height * scale);
      canvas.width = cw;
      canvas.height = ch;
      const ctx = getOffscreenCanvas2dContext(canvas);
      setImageSmoothingEnabled(ctx, smoothingEnabled);

      ctx.drawImage(source, 0, 0, cw, ch);
      const image = await createImageFrameByCanvasElement(canvas);
      releaseReusableCanvas(canvas);

      return image;
    } catch (ex) {
      const error = asError(ex);
      trackError('scaleTo', error, this);
      return createPlaceholderImageFrame(error);
    }
  }

  /**
   * Converts to a square image. The resulting square image will have
   * dimensions equal to the larger of the original canvas's width and height.
   * The original content will be centered, and any added space will be filled
   * with a black background.
   *
   * For example, if the original image is 100x200, the resulting image will be
   * 200x200, with the original image centered in the middle.
   *
   * @returns The square image frame.
   */
  async toSquare(): Promise<ImageFrame> {
    if (this.placeholder) {
      // placeholder image is already square.
      return this.clone();
    }

    try {
      const source = this.getSource()!;
      const {width, height} = source;
      const canvas = requestReusableCanvas();

      const dimension = Math.max(width, height);
      canvas.width = dimension;
      canvas.height = dimension;

      const ctx = getOffscreenCanvas2dContext(canvas);
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, dimension, dimension);

      // Calculate the position to center the original image.
      const offsetX = (dimension - width) / 2;
      const offsetY = (dimension - height) / 2;
      ctx.drawImage(source, offsetX, offsetY);

      const image = await createImageFrameByCanvasElement(canvas);
      releaseReusableCanvas(canvas);
      return image;
    } catch (ex) {
      const error = asError(ex);
      trackError('toSquare', error, this);
      return createPlaceholderImageFrame(error);
    }
  }

  /**
   * Adds padding to the image frame.
   * @param paddingX The padding on the left and right.
   * @param paddingY The padding on the top and bottom.
   * @param backgroundColor The color of the padding. Defaults to #f0f0f0.
   * @returns The padded image frame.
   */
  async addPadding(
    paddingX: number,
    paddingY: number,
    backgroundColor: string = '#f0f0f0',
  ): Promise<ImageFrame> {
    if (this.placeholder) {
      // placeholder image does not resize.
      return this.clone();
    }

    try {
      if (paddingX < 0 || paddingY < 0) {
        throw new Error(
          `${ErrorCode.illegalArguments} "paddings: ${paddingX}, ${paddingY}"`,
        );
      }

      const source = this.getSource()!;
      const canvas = requestReusableCanvas();
      const width = source.width + paddingX * 2;
      const height = source.height + paddingY * 2;
      canvas.width = width;
      canvas.height = height;
      const context = getOffscreenCanvas2dContext(canvas);

      context.fillStyle = backgroundColor;
      context.fillRect(0, 0, width, height);

      context.drawImage(source, paddingX, paddingY);
      const newFrame = await createImageFrameByCanvasElement(canvas);
      releaseReusableCanvas(canvas);

      return newFrame;
    } catch (ex) {
      const error = asError(ex);
      trackError('addPadding', error, this);
      return createPlaceholderImageFrame(error);
    }
  }

  /**
   * Creates an ImageFrame with a cover image, akin to CSS object-fit: cover.
   * The image maintains its aspect ratio and fills the ImageFrame.
   * It may be cropped and centered to cover the entire frame.
   *
   * @param width Frame width.
   * @param height Frame height.
   * @returns Fitted ImageFrame.
   */
  async fitToCover(
    width: number,
    height: number,
    smoothingEnabled: boolean = true,
  ): Promise<ImageFrame> {
    if (this.placeholder) {
      // placeholder image does not resize.
      return this.clone();
    }

    try {
      if (width <= 0 || height <= 0) {
        throw new Error(
          `${ErrorCode.illegalArguments} "dimensions: ${width}, ${height}"`,
        );
      }
      const source = this.getSource()!;
      const sw = source.width;
      const sh = source.height;

      const canvas = requestReusableCanvas();
      canvas.width = width;
      canvas.height = height;

      const ctx = getOffscreenCanvas2dContext(canvas);
      setImageSmoothingEnabled(ctx, smoothingEnabled);

      const sx = 0;
      const sy = 0;
      const scale = Math.max(width, height) / Math.min(sw, sh);
      const dx = (width - sw * scale) / 2;
      const dy = (height - sh * scale) / 2;
      const dWidth = sw * scale;
      const dHeight = sh * scale;

      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(source, sx, sy, sw, sh, dx, dy, dWidth, dHeight);

      const image = await createImageFrameByCanvasElement(canvas);
      releaseReusableCanvas(canvas);
      return image;
    } catch (ex) {
      const error = asError(ex);
      trackError('fitToCover', error, this);
      return createPlaceholderImageFrame(error);
    }
  }

  /**
   * Resizes the image to fit within the specified dimensions while maintaining
   * its aspect ratio. Behaves similarly to CSS 'object-fit: contain'.
   *
   * @param {number} width - The desired width for the image.
   * @param {number} height - The desired height for the image.
   * @param {boolean} smoothingEnabled - (Optional) Indicates whether
   *   smoothing should be enabled during scaling. Defaults to true if not
   *   provided.
   * @returns {Promise<ImageFrame>} - A promise that resolves to the resized
   *   image.
   */
  async fitToContain(
    width: number,
    height: number,
    smoothingEnabled: boolean = true,
  ): Promise<ImageFrame> {
    if (this.placeholder) {
      // placeholder image does not resize.
      return this.clone();
    }

    try {
      if (width <= 0 || height <= 0) {
        throw new Error(
          `${ErrorCode.illegalArguments} "dimensions: ${width}, ${height}"`,
        );
      }
      const scale = this.computeFitToScale(width, height);
      if (scale < 0) {
        // The image is larger than the specified size.
        return this.scaleTo(scale, smoothingEnabled);
      } else {
        // The image is already smaller than the specified size.
        return this.clone();
      }
    } catch (ex) {
      const error = asError(ex);
      trackError('fitToContain', error, this);
      return createPlaceholderImageFrame(error);
    }
  }

  /**
   * Crop the image frame.
   * @param left The x coordinate of the upper-left corner of the sub-rectangle.
   * @param top The y coordinate of the upper-left corner of the sub-rectangle.
   * @param width The width of the sub-rectangle.
   * @param height The height of the sub-rectangle.
   * @param imageSmoothingEnabled Whether to enable image smoothing.
   * @returns The cropped image frame.
   */
  async crop(
    left: number,
    top: number,
    width: number,
    height: number,
    imageSmoothingEnabled: boolean = true,
  ): Promise<ImageFrame> {
    if (this.placeholder) {
      // placeholder image does not crop.
      return this.clone();
    }

    try {
      const source = this.getSource()!;

      // We need to clamp the values to make sure they are within the range.
      // This is because IDDetector may return the crop values that are out of
      // the range.
      const sx = clamp(left, 0, source.width);
      const sy = clamp(top, 0, source.height);
      const sw = clamp(width, 0, source.width - sx);
      const sh = clamp(height, 0, source.height - sy);

      if (typeof createImageBitmapNative === 'function') {
        const canvas = await this.toHTMLCanvasElement(imageSmoothingEnabled);
        const bitmap = await createImageBitmapNative(canvas, sx, sy, sw, sh);
        releaseReusableCanvas(canvas);
        return new ImageFrame(bitmap);
      } else {
        // For IOS 14
        const canvas = requestReusableCanvas();
        canvas.width = sw;
        canvas.height = sh;
        const ctx = getOffscreenCanvas2dContext(canvas);
        setImageSmoothingEnabled(ctx, imageSmoothingEnabled);
        ctx.drawImage(source, sx, sy, sw, sh, 0, 0, sw, sh);
        const blob = await canvasToBlob(canvas);
        const url = URL.createObjectURL(blob);
        releaseReusableCanvas(canvas);
        return createImageFrameByObjectURL(url);
      }
    } catch (ex) {
      const error = asError(ex);
      trackError('crop', error, this);
      return createPlaceholderImageFrame(error);
    }
  }
}

export async function createEmptyImageFrame(
  width: number,
  height: number,
  fillColor: string = 'white',
): Promise<ImageFrame> {
  const canvas = requestReusableCanvas();

  try {
    canvas.width = width;
    canvas.height = height;
    const ctx = getOffscreenCanvas2dContext(canvas);
    ctx.fillStyle = fillColor;
    ctx.fillRect(0, 0, width, height);
    return createImageFrameByCanvasElement(canvas);
  } catch (ex) {
    const error = asError(ex);
    trackError('createEmptyImageFrame', error, canvas);
    return createPlaceholderImageFrame();
  } finally {
    releaseReusableCanvas(canvas);
  }
}

/**
 * Create a image frame with zero pixels.
 * @param relatedError (optional) The related error that resulted in the image.
 * @returns The image frame with zero pixels.
 */
export function createPlaceholderImageFrame(
  relatedError?: Error | null | undefined,
): ImageFrame {
  return new ImageFrame(relatedError);
}

/**
 * Create an image frame from a video element.
 * @param video The video element.
 * @returns The image frame.
 */
async function createImageFrameByVideoElement(
  video: HTMLVideoElement,
): Promise<ImageFrame> {
  const {videoWidth, videoHeight} = video;

  if (typeof createImageBitmapNative === 'function') {
    const bitmap = await createImageBitmapNative(
      video,
      0,
      0,
      videoWidth,
      videoHeight,
    );
    return new ImageFrame(bitmap);
  }

  // For IOS 14.
  const canvas = requestReusableCanvas();
  try {
    const {videoWidth, videoHeight} = video;
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    const context = getOffscreenCanvas2dContext(canvas);
    // TODO: If the image is too big, we should resize it to a smaller size
    // so that they could be processed and uploaded properly.
    context.drawImage(video, 0, 0, videoWidth, videoHeight);
    return createImageFrameByCanvasElement(canvas);
  } finally {
    releaseReusableCanvas(canvas);
  }
}

/**
 * Create an image frame from an image data.
 * @param imageData The image data to create the image frame.
 * @returns The image frame created from the image data.
 */
async function createImageFrameByImageData(
  imageData: ImageData,
): Promise<ImageFrame> {
  const {width, height} = imageData;
  if (typeof createImageBitmapNative === 'function') {
    const bitmap = await createImageBitmapNative(
      imageData,
      0,
      0,
      width,
      height,
    );
    return new ImageFrame(bitmap);
  }

  // For IOS 14.
  const canvas = requestReusableCanvas();
  try {
    canvas.width = width;
    canvas.height = height;
    const context = getOffscreenCanvas2dContext(canvas);
    context.putImageData(imageData, 0, 0);
    return createImageFrameByCanvasElement(canvas);
  } finally {
    releaseReusableCanvas(canvas);
  }
}

/**
 * Create an image frame from a canvas element.
 * @param canvas The video element.
 * @returns The image frame.
 */
async function createImageFrameByCanvasElement(canvas: HTMLCanvasElement) {
  if (createImageBitmapNative) {
    const {width, height} = canvas;
    const bitmap = await createImageBitmapNative(canvas, 0, 0, width, height);
    return new ImageFrame(bitmap);
  }

  // For IOS 14.
  const blob = await canvasToBlob(canvas);
  const url = URL.createObjectURL(blob);
  return createImageFrameByObjectURL(url);
}

/**
 * Create an image frame from an object URL.
 * @param url The object URL returned from URL.createObjectURL().
 * @returns The image frame.
 */
async function createImageFrameByObjectURL(url: string): Promise<ImageFrame> {
  return new Promise<ImageFrame>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(new ImageFrame(img));
    };
    img.onerror = (e) => {
      reject(new Error(ErrorCode.failedToCreateImageFrame));
    };
    img.src = url;
  });
}

/**
 * Create an image frame from a HEIC file.
 * @param file The HEIC file.
 * @returns The image frame.
 */
async function createImageFrameByHEICFile(file: File): Promise<ImageFrame> {
  if (file.type !== 'image/heic') {
    throw new Error(ErrorCode.invalidFileType);
  }

  // The assets for heic2any is about 2MB, we should load it lazily.
  const module = (await import(
    // @ts-ignore: Could not find a declaration file for module ''heic2any/dist/heic2any.min.js'.
    'heic2any/dist/heic2any.min.js'
  )) as HEIC2AnyModule;

  const heic2any = module.default;
  try {
    const output = await heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.8,
    });

    let blob: Blob | undefined;
    if (output instanceof Blob) {
      blob = output;
    } else if (Array.isArray(output) && output[0] instanceof Blob) {
      blob = output[0] as Blob;
    }

    if (!blob) {
      const value = Object.prototype.toString.call(output);
      throw new Error(`Failed to create file blob. Got ${value}`);
    }

    const url = URL.createObjectURL(blob);
    return createImageFrameByObjectURL(url);
  } catch (ex) {
    throw new Error(`${ErrorCode.heic2anyFailed}: ${String(ex)}`);
  }
}

/**
 * Creates an ImageFrame from a PDF file.
 *
 * @param file The PDF file to be converted into an ImageFrame.
 * @returns A Promise that resolves to an ImageFrame created from the provided
 *   PDF file.
 */
async function createImageFrameByPDFFile(
  file: File,
  providePassword: PDFPasswordProvider,
): Promise<ImageFrame> {
  if (file.type !== 'application/pdf') {
    throw new Error(ErrorCode.invalidFileType);
  }

  if (!canBrowserUsePDFFile()) {
    throw new Error(ErrorCode.browserNotSupported);
  }

  const pdfjs = await import('pdfjs-dist/legacy/build/pdf');

  // This should inject the worker script with a global object `window.pdfjsWorker`.
  // @ts-ignore: Could not find a declaration file for module 'pdfjs-dist/build/pdf.worker.entry'.
  await import('pdfjs-dist/build/pdf.worker.entry');

  if (!('pdfjsWorker' in window)) {
    throw new Error(ErrorCode.browserNotSupported);
  }

  const pdfBytes = await readPDFFileAsArrayBuffer(file);
  const pdfDocument = await getPDFDocument(pdfjs, pdfBytes, providePassword);

  if (!pdfDocument) {
    throw new Error(ErrorCode.pdfJSNoPagesFound);
  }

  const {numPages} = pdfDocument;
  if (!numPages) {
    throw new Error(ErrorCode.pdfJSNoPagesFound);
  }

  // We'd like to learn if people are uploading PDF files with a lot of pages.
  analytics.track('pdfDocumentDetected', {
    pagesCount: numPages,
    fileSize: file.size,
  });

  if (numPages > MAX_PDF_PAGES_COUNT) {
    // We should not accept a PDF file with too many pages.
    throw new Error(ErrorCode.pdfJSTooManyPages);
  }

  let imageFound: ImageFrame | null = null;

  // Find the first image from the first few pages.
  // Once we find an image, we can stop searching.
  for (let pageNum = 1; pageNum <= numPages; pageNum++) {
    // eslint-disable-next-line no-await-in-loop
    const page = await pdfDocument.getPage(pageNum);
    // eslint-disable-next-line no-await-in-loop
    const image = await findImageFromPDFPage(pdfjs, page, pageNum);
    if (image) {
      imageFound = image;
      break;
    }
  }

  if (!imageFound) {
    throw new Error(ErrorCode.pdfJSImageNotFound);
  }

  return imageFound;
}

/**
 * Get the PDF document from the PDF bytes.
 * @param pdfjs The PDFJS module.
 * @param data The PDF bytes.
 * @returns The PDF document.
 */
export async function getPDFDocument(
  pdfjs: PDFJSModule,
  data: Uint8Array,
  providePassword: PDFPasswordProvider,
): Promise<PDFDocumentProxy> {
  let error: Error | null = null;

  try {
    const doc = await pdfjs.getDocument(data).promise;
    return doc;
  } catch (ex) {
    error = asError(ex);
  }

  if (!error) {
    throw new Error(ErrorCode.pdfJSFailedToAccessDocument);
  }

  if (error.name === 'PasswordException') {
    const password = providePassword();

    if (!password) {
      throw new Error(ErrorCode.pdfJSPasswordRequired, {cause: error});
    }

    try {
      const doc = await pdfjs.getDocument({data, password}).promise;
      return doc;
    } catch (ex) {
      error = asError(ex);
      // The password is invalid, we'll let user to see the error and try again.
      if (error.name === 'PasswordException') {
        error = new Error(ErrorCode.pdfJSPasswordInvalid, {cause: error});
      }
    }
  }

  throw error;
}

/**
 * Check if the browser can use PDF file.
 * @returns Whether the browser can use PDF file.
 */
export function canBrowserUsePDFFile(): boolean {
  // Check te required Browser APIs.
  const {FileReader, ImageData, Uint8Array, Uint8ClampedArray} = window;
  return (
    typeof FileReader === 'function' &&
    typeof ImageData === 'function' &&
    typeof Uint8Array === 'function' &&
    typeof Uint8ClampedArray === 'function'
  );
}

/**
 * Reads a PDF file and returns its content as a Uint8Array.
 *
 * @param file The PDF file to be read.
 * @returns A Promise that resolves to a Uint8Array containing the content of
 *   the provided PDF file.
 */
async function readPDFFileAsArrayBuffer(file: File): Promise<Uint8Array> {
  if (file.type !== 'application/pdf') {
    throw new Error(ErrorCode.invalidFileType);
  }

  // Check if the browser supports the required APIs.
  if (!canBrowserUsePDFFile()) {
    throw new Error(ErrorCode.browserNotSupported);
  }

  const fileReader = new FileReader();

  const arrayBuffer = await new Promise<Uint8Array>((resolve, reject) => {
    fileReader.onload = () => {
      const {result} = fileReader;
      if (result instanceof ArrayBuffer) {
        resolve(new Uint8Array(result));
      } else {
        const cause = new Error(
          `expect ArrayBuffer, got ${Object.prototype.toString.call(result)}`,
        );
        reject(new Error(ErrorCode.failedToReadPDFFile, {cause}));
      }
    };
    fileReader.onerror = () => {
      const {error} = fileReader;
      reject(
        new Error(ErrorCode.failedToReadPDFFile, {
          cause: error || new Error('unknown file reader error'),
        }),
      );
    };
    fileReader.readAsArrayBuffer(file);
  });

  fileReader.onload = null;
  fileReader.onerror = null;
  return arrayBuffer;
}

/**
 * Add an alpha channel to a Uint8ClampedArray.
 * @param uint8Array The Uint8Array to add an alpha channel.
 * @param imageWidth The width of the image.
 * @param imageHeight The height of the image.
 * @returns The new Uint8ClampedArray with an alpha channel.
 */
export function addAlphaChannelToUnit8ClampedArray(
  uint8Array: Uint8Array,
  imageWidth: number,
  imageHeight: number,
): Uint8ClampedArray {
  const newImageData = new Uint8ClampedArray(imageWidth * imageHeight * 4);

  for (let j = 0, k = 0, jj = imageWidth * imageHeight * 4; j < jj; ) {
    newImageData[j++] = uint8Array[k++];
    newImageData[j++] = uint8Array[k++];
    newImageData[j++] = uint8Array[k++];
    newImageData[j++] = 255;
  }

  return newImageData;
}

/**
 * Find the first image from a PDF page.
 * @param pdfjs The PDFJS module.
 * @param pdfPage The PDF page.
 * @returns The image frame found from the PDF page.
 */
async function findImageFromPDFPage(
  pdfjs: PDFJSModule,
  pdfPage: PDFPageProxy,
  pageNum: number,
): Promise<ImageFrame | null> {
  const operatorList = await pdfPage.getOperatorList();

  const validObjectTypes = new Set([
    pdfjs.OPS.paintImageXObject,
    pdfjs.OPS.paintImageXObjectRepeat,
    pdfjs.OPS.paintJpegXObject,
  ]);

  const {fnArray} = operatorList;
  const imageNames: string[] = [];

  for (let ii = 0, jj = fnArray.length; ii < jj; ii++) {
    const objectType = fnArray[ii];
    if (!validObjectTypes.has(objectType)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const args = operatorList.argsArray[ii];
    const imageName = Array.isArray(args) ? args[0] : null;
    if (!imageName || typeof imageName !== 'string') {
      // eslint-disable-next-line no-continue
      continue;
    }
    imageNames.push(imageName);
  }

  // We'd like to learn if people are uploading PDF files with many images.
  analytics.track('pdfImagesDetected', {
    pageNumber: pageNum,
    imagesCount: imageNames.length,
  });

  while (imageNames.length) {
    const imageName = imageNames.shift()!;

    // eslint-disable-next-line no-await-in-loop
    const imageObject = await findImageObject(pdfPage, imageName);

    if (!imageObject) {
      // This page does not have the image object.
      // eslint-disable-next-line no-continue
      continue;
    }

    const {width, height, data} = imageObject;
    if (
      width < MIN_PDF_IMAGE_DIMENSION_SIZE ||
      height < MIN_PDF_IMAGE_DIMENSION_SIZE
    ) {
      // Skip the small image. Likely to be a logo or icon.
      // eslint-disable-next-line no-continue
      continue;
    }

    const imageData = new ImageData(width, height);

    // "data" is an Uint8Array which only contains RGB data, it needs to be
    // copied to a new array to add an alpha channel (hardcoded to 255) and make
    // it RGBA data
    const rgbaData = addAlphaChannelToUnit8ClampedArray(data, width, height);
    imageData.data.set(rgbaData);

    // eslint-disable-next-line no-await-in-loop
    const imageFrame = await ImageFrame.fromImageData(imageData);
    return imageFrame;
  }

  return null;
}

/**
 * Find the image object from a PDF page.
 * @param pdfPage The PDF page.
 * @param imageName The name of the image.
 * @returns The image object found from the PDF page. Null if not found.
 */
async function findImageObject(
  pdfPage: PDFPageProxy,
  imageName: string,
): Promise<PDFImageObject | null> {
  return new Promise((resolve) => {
    if (!pdfPage.objs.has(imageName)) {
      resolve(null);
    } else {
      pdfPage.objs.get(imageName, (imageObject: PDFImageObject) => {
        resolve(imageObject);
      });
    }
  });
}

/**
 * Helper function to convert a canvas to a blob.
 * @param canvas The canvas element.
 * @param quality The quality of the image.
 * @returns The blob converted from the canvas.
 */
async function canvasToBlob(
  canvas: HTMLCanvasElement,
  quality = 0.9,
): Promise<Blob> {
  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob((blob) => resolve(blob), 'image/jpeg', quality);
  });
  if (blob === null) {
    throw new Error(ErrorCode.failedToCreateImageFrame);
  }
  return blob;
}

/**
 * Helper function to set the image smoothing quality.
 * @param ctx The canvas context.
 * @param smoothingEnabled Whether to enable image smoothing.
 */
function setImageSmoothingEnabled(
  ctx: CanvasRenderingContext2D,
  smoothingEnabled: boolean = true,
) {
  if (smoothingEnabled) {
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
  } else {
    ctx.imageSmoothingEnabled = false;
  }
}

/**
 * Get the 2d context of a canvas for offscreen operations.
 * @param canvas The canvas element to provide the 2d context.
 * @returns The 2d context of the canvas.
 */
function getOffscreenCanvas2dContext(
  canvas: HTMLCanvasElement,
): CanvasRenderingContext2D {
  const useSynchronizedCanvasOptions = experiments.isActive(
    'synchronized_canvas',
  );
  if (useSynchronizedCanvasOptions) {
    const context = canvas.getContext(
      '2d',
      OFFSCREEN_CANVAS_CONTEXT_OPTIONS_SYNC,
    );
    return context!;
  } else {
    const context = canvas.getContext('2d', OFFSCREEN_CANVAS_CONTEXT_OPTIONS);
    return context!;
  }
}

/**
 * Helper function to force the 2d context of a canvas with offscreen operations
 * while executing the specified process.
 * @param process The process to execute.
 * @returns The result of the process.
 */
export function forceOffscreenCanvasContext<T>(process: () => T) {
  const originalGetContext = HTMLCanvasElement.prototype.getContext;
  try {
    const getContextPatched: typeof originalGetContext = function patched(
      this: HTMLCanvasElement,
      id,
      opts,
    ) {
      let options = opts;
      if (id === '2d' && !options) {
        const useSynchronizedCanvasOptions = experiments.isActive(
          'synchronized_canvas',
        );
        if (useSynchronizedCanvasOptions) {
          options = OFFSCREEN_CANVAS_CONTEXT_OPTIONS_SYNC;
        } else {
          options = OFFSCREEN_CANVAS_CONTEXT_OPTIONS;
        }
      }
      const ctx: any = originalGetContext.call(this, id, options);
      return ctx;
    };
    // Temporarily patch the getContext method so that the canvas will be
    // created with the offscreen context options.
    HTMLCanvasElement.prototype.getContext = getContextPatched;
    return process();
  } finally {
    // Restore the original getContext method.
    HTMLCanvasElement.prototype.getContext = originalGetContext;
  }
}
