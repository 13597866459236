import {
  updateFlowsEmailAction,
  handleCommonFlowsSessionAction,
} from 'gelato/frontend/src/controllers/actions/flowsActions';
import {
  updateConsentAction,
  generateTestModeDataAction,
} from 'gelato/frontend/src/controllers/actions/mutationActions';
import {isVerificationFlowSession} from 'gelato/frontend/src/controllers/states/SessionState';
import {
  isVerifiedAutoComplete,
  isConsentDeclinedAutoComplete,
} from 'gelato/frontend/src/controllers/states/TestModeState';
import routeToNextPage from 'gelato/frontend/src/controllers/utils/routeToNextPage';
import {
  DEFAULT_VALID_EMAIL,
  DEFAULT_INVALID_EMAIL,
} from 'gelato/frontend/src/lib/constants';
import {reportMetric} from 'gelato/frontend/src/lib/metricsBatcher';
import {handleException} from 'gelato/frontend/src/lib/sentry';

import type {AutocompleteOptions} from 'gelato/frontend/src/controllers/states/TestModeState';
import type {
  ApplicationAction,
  ApplicationActionWithPayload,
} from 'gelato/frontend/src/controllers/types';

type TestModeActionWithPayload = ApplicationActionWithPayload<{
  message: string;
  action: () => Promise<void>;
}>;

const withExceptionHandler: TestModeActionWithPayload = async (
  controller,
  {message, action},
) => {
  try {
    await action();
  } catch (caughtError: any) {
    handleException(caughtError, message);
    controller.update((draft) => {
      draft.testMode.error = caughtError;
      draft.testMode.autocompletePending = false;
    });
  }
};

/**
 * Updates the autocomplete setting state for test mode
 * @param controller
 * @param value Valid autocomplete setting
 */
export const handleAutocompleteSettingChange: ApplicationActionWithPayload<{
  value: AutocompleteOptions;
}> = async (controller, {value}) => {
  controller.update((draft) => {
    draft.testMode.autocompleteSetting = value;
  });
};

const generateTestModeData: ApplicationAction = async (controller) => {
  const verified = isVerifiedAutoComplete(controller.state);
  await generateTestModeDataAction(controller, {verified});
  await routeToNextPage(
    controller.state,
    controller.runtime!,
    'autocomplete_test_mode',
    undefined,
    'testModeActions.generateTestModeData',
  );
};

const generateConsentDeclinedData: ApplicationAction = async (controller) => {
  await updateConsentAction(controller, {accepted: false});
  await routeToNextPage(
    controller.state,
    controller.runtime!,
    'autocomplete_test_mode',
    undefined,
    'testModeActions.generateConsentDeclinedData',
  );
};

/**
 * Handles when autocomplete action is submitted and the setting is decline consent
 * @param controller
 */
export const handleTestModeDeclineConsentAction: ApplicationAction = async (
  controller,
) => {
  await withExceptionHandler(controller, {
    message: 'testmode decline consent',
    action: async () => {
      if (isVerificationFlowSession(controller.state)) {
        await updateFlowsEmailAction(controller, {
          email: DEFAULT_INVALID_EMAIL,
        });
        await handleCommonFlowsSessionAction(controller, {
          action: generateConsentDeclinedData,
        });
      } else {
        await generateConsentDeclinedData(controller);
      }
    },
  });
};

/**
 * Handles when autocomplete action is submitted and the setting is not decline consent
 * @param controller
 */
export const handleSendTestModeSettingAction: ApplicationAction = async (
  controller,
) => {
  reportMetric({
    metric: 'gelato_frontend_testmode_document_submitted',
    operation: 'count',
    value: 1,
  });
  await withExceptionHandler(controller, {
    message: 'testmode complete',
    action: async () => {
      if (isVerificationFlowSession(controller.state)) {
        const defaultEmail = isVerifiedAutoComplete(controller.state)
          ? DEFAULT_VALID_EMAIL
          : DEFAULT_INVALID_EMAIL;

        await updateFlowsEmailAction(controller, {email: defaultEmail});
        await handleCommonFlowsSessionAction(controller, {
          action: generateTestModeData,
        });
      } else {
        await generateTestModeData(controller);
      }
    },
  });
};

/**
 * Callback to handle user choosing to proceed with test mode on autocomplete
 * @param controller The controller instance
 */
export const handleSubmitAutocompleteAction: ApplicationAction = async (
  controller,
) => {
  // If the current testMode state is already initialized, return early
  if (controller.state.testMode.autocompletePending) {
    return;
  }
  controller.update((draft) => {
    draft.testMode.error = null;
    draft.testMode.autocompletePending = true;
  });
  if (isConsentDeclinedAutoComplete(controller.state)) {
    await handleTestModeDeclineConsentAction(controller);
  } else {
    await handleSendTestModeSettingAction(controller);
  }
  controller.update((draft) => {
    draft.testMode.autocompletePending = false;
  });
};

/**
 * Callback to handle user choosing to proceed with test mode on manual
 * @param controller The controller instance
 */
export const handleSubmitManualAction: ApplicationAction = async (
  controller,
) => {
  controller.update((draft) => {
    draft.testMode.manualPending = true;
  });
  await routeToNextPage(
    controller.state,
    controller.runtime!,
    'manual_test_mode',
    undefined,
    'testModeActions.handleSubmitManualAction',
  );
  // This is technically not necessary since there is no way to go back to the test page after.
  // But since states are long-lived, it's better to reset and be sure
  controller.update((draft) => {
    draft.testMode.manualPending = false;
  });
};
