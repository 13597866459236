import * as React from 'react';
import {defineMessages} from 'react-intl';

import DevTools from 'gelato/frontend/src/components/DevTools';
import FeatureFlag from 'gelato/frontend/src/components/FeatureFlag';
import FeedbackDialog from 'gelato/frontend/src/components/Footer/FeedbackDialog';
import LocaleSelector from 'gelato/frontend/src/components/Footer/LocaleSelector';
import Message from 'gelato/frontend/src/components/Message';
import {setMockableTimeout} from 'gelato/frontend/src/lib/clock';
import {STRIPE_CONTACT_URL} from 'gelato/frontend/src/lib/constants';
import {usePageLayoutPortrait, useSession} from 'gelato/frontend/src/lib/hooks';
import {ButtonLink} from 'sail/Button';
import {Logo} from 'sail/Logo';
import {Body} from 'sail/Text';
import {Toast, ToastLayer} from 'sail/Toast';

import styles from './Footer.module.css';

import type {MessageDescriptor} from 'react-intl';

const messages = defineMessages({
  footer: {
    id: 'footer.message',
    description: 'Footer message',
    defaultMessage:
      'Powering online business for millions of companies around the world.',
  },
  poweredBy: {
    id: 'footer.poweredBy',
    description: 'Marketing slogan to mention Stripe',
    defaultMessage: 'Powered by',
  },
  privacyPolicy: {
    id: 'footer.links.privacyPolicy',
    description: 'Link to the privacy policy',
    defaultMessage: 'Privacy',
  },
  contactUs: {
    id: 'footer.links.contactUs',
    description: 'Link to contact Stripe',
    defaultMessage: 'Contact',
  },
  support: {
    id: 'footer.links.support',
    description: 'Link to Stripe support page',
    defaultMessage: 'Support',
  },
  feedback: {
    id: 'footer.links.feedback',
    description: 'Link to provide feedback',
    defaultMessage: 'Feedback?',
  },
  devTools: {
    id: 'footer.links.developerTools',
    description: 'Link to open developer menu for debugging',
    defaultMessage: 'Dev Tools',
  },
  feedbackSuccessToast: {
    id: 'footer.feedbackSuccessToast',
    description: 'Message to thank the user for providing feedback',
    defaultMessage: 'Thank you for your feedback!',
  },
});

const Footer = () => {
  const session = useSession();
  const [devToolsShown, setDevtoolsShown] = React.useState<boolean>(false);
  const [feedbackSuccess, setFeedbackSuccess] = React.useState<boolean>(false);
  const [feedbackDialogShown, setFeedbackDialogShown] =
    React.useState<boolean>(false);

  const pageLayoutPortraitEnabled = usePageLayoutPortrait();

  React.useEffect(() => {
    if (feedbackSuccess) {
      // fade after 2 sec
      setMockableTimeout(() => {
        setFeedbackSuccess(false);
      }, 2000);
    }
  }, [feedbackSuccess, setFeedbackSuccess]);

  const handleOpenDevTools = () => {
    setDevtoolsShown(true);
  };

  const closeDevTools = () => {
    setDevtoolsShown(false);
  };

  const handleOpenFeedbackDialog = () => {
    setFeedbackDialogShown(true);
  };

  // @ts-expect-error - TS7031 - Binding element 'success' implicitly has an 'any' type.
  const closeFeedbackDialog = ({success}) => {
    setFeedbackDialogShown(false);
    setFeedbackSuccess(!!success);
  };

  type ButtonLinkProp = {
    href?: string;
    label: MessageDescriptor;
    target?: string;
    onClick?: () => void;
  };

  const buttonLink = ({href, label, target, onClick}: ButtonLinkProp) => {
    const buttonProps: Record<string, any> = {};
    buttonProps.label = <Message {...label} />;
    buttonProps.target = target;
    if (onClick) {
      buttonProps.onClick = onClick;
    }
    if (href) {
      buttonProps.href = href;
    }
    return (
      // This div is needed since the async page load broke the CSS for these footer links
      // The styling should be applied to ButtonLink without this, but it is not.
      <div className={styles.footerLinkWrapper}>
        {/* @ts-expect-error - TS2769 - No overload matches this call. */}
        <ButtonLink {...buttonProps} />
      </div>
    );
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerRow}>
        <Body className={styles.poweredBy} color="gray">
          <Message {...messages.poweredBy} />
        </Body>
        <Logo className={styles.stripeLogo} color="gray" />
      </div>
      <div className={styles.footerRow}>
        {buttonLink({
          href: 'https://stripe.com/privacy',
          label: messages.privacyPolicy,
          target: '_blank',
        })}
        {buttonLink({
          href: STRIPE_CONTACT_URL,
          label: pageLayoutPortraitEnabled
            ? messages.support
            : messages.contactUs,
          target: '_blank',
        })}
        {!pageLayoutPortraitEnabled &&
          buttonLink({
            label: messages.feedback,
            onClick: handleOpenFeedbackDialog,
          })}
        {session && (
          // it is unfortunate that we need a session in order to accept
          // feedback. this is because we go through the graphql api to accept
          // this.
          <FeedbackDialog
            shown={feedbackDialogShown}
            handleClose={closeFeedbackDialog}
          />
        )}
        <LocaleSelector />
        <FeatureFlag flag="gelato_show_dev_tools">
          <>
            <ButtonLink
              label={<Message {...messages.devTools} />}
              icon="settings"
              onClick={handleOpenDevTools}
            />
            <DevTools shown={devToolsShown} handleClose={closeDevTools} />
          </>
        </FeatureFlag>
      </div>
      <ToastLayer position="top">
        {feedbackSuccess ? (
          // @ts-expect-error - TS2322 - Type '{ children: Element; icon: "checkmark"; color: "green"; duration: string; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Toast> & Readonly<Props> & Readonly<{ children?: ReactNode; }>'.
          <Toast icon="checkmark" color="green" duration="short">
            <Message {...messages.feedbackSuccessToast} />
          </Toast>
        ) : (
          <></>
        )}
      </ToastLayer>
    </footer>
  );
};

export default Footer;
