import {createObjectIntentType, getIntents, toIntent} from '../intent';
import {$subview} from './constants';
import type {Intent} from '../intent';
import type {View} from './types';
import {getRegisteredCssSerializer} from './css';
import {normalizeIntents} from './normalizeIntents';

export type InternalSubviews = Record<
  string,
  Intent<unknown> & Intent<unknown>[]
>;

export const createSubviewIntents = createObjectIntentType(
  'subview',
  (decorate) =>
    (obj: InternalSubviews): InternalSubviews & Intent =>
      decorate(obj),
  (target, source) => {
    Object.keys(source).forEach((key) => {
      if (target[key]) {
        target[key] = toIntent(target[key].concat(source[key]));
      } else {
        target[key] = toIntent(source[key].slice());
      }
    });
  },
);

export function populateSubviewIntents<
  Props,
  Options extends View.ConfigOptions = never,
>(
  revision: View.ViewRevision<Props>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subviewsProp: View.SubviewProps<any>,
  options: Options,
): void {
  const existingSubviewIntents = getIntents(createSubviewIntents, revision);

  const props = revision.props as {
    subviews?: View.Subviews<Record<string, unknown>>;
  };
  const subviews = {} as View.Subviews<Record<string, unknown>>;
  props.subviews = subviews;

  if (!subviewsProp && !existingSubviewIntents) {
    return;
  }

  if (subviewsProp) {
    Object.keys(subviewsProp).forEach((prop) => {
      const existingIntents = existingSubviewIntents?.[prop as string] as
        | View.Intent<unknown>[]
        | void;

      const propValue = (
        subviewsProp as Record<string, View.SubviewProp<unknown> | void>
      )[prop];

      const propIntents = (propValue as View.Subview<unknown>)?.[$subview];

      // Bail early if the only subview intents are passed to the prop
      if (propIntents && !existingIntents) {
        subviews[prop] = propValue as View.Subview<unknown>;
        return;
      }

      const subviewIntents = (
        propIntents && existingIntents
          ? existingIntents.concat(propIntents)
          : (propIntents ?? existingIntents ?? [])?.slice()
      ) as View.SubviewIntents<unknown>;

      const subview = {
        [$subview]: subviewIntents,
      } as View.Subview<unknown>;
      subviews[prop] = subview;

      if (!propIntents && propValue) {
        if (!normalizeIntents(propValue, subviewIntents)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const {css: cssProp, uses, ref} = propValue as any;
          subviewIntents.ref = ref;

          if (uses) {
            normalizeIntents(uses, subviewIntents);
          }

          if (cssProp) {
            const css = getRegisteredCssSerializer(options.css);
            if (css) {
              subviewIntents.push(css(cssProp));
            }
          }
        }
      }
    });
  }

  if (existingSubviewIntents) {
    Object.keys(existingSubviewIntents).forEach((prop) => {
      subviews[prop] ??= {
        [$subview]: existingSubviewIntents?.[prop as string],
      } as View.Subview<unknown>;
    });
  }
}
