import {
  historyReplaceState,
  historyPushState,
} from '@stripe-internal/safe-links';
import * as React from 'react';
import URLPattern from 'url-pattern';

import analytics from 'gelato/frontend/src/lib/analytics';
import {startPageTransition} from 'gelato/frontend/src/lib/pageTransitions';
import {importModules, PromiseStubPage} from 'gelato/frontend/src/lib/routes';

import type {AnalyticsEventName} from 'gelato/frontend/src/lib/analytics/types';

type QueryParams = {
  // document upload page
  flow?: any;
  // handoff page
  handoffMode?: any;
  // invalid
  redirectToUrl?: any;
  // submit page
  forceAsync?: any;
};

export type PageProps = {
  params: QueryParams;
  router: LocalRouter;
};

export type RouteComponent<T = {}> = React.ComponentType<T & PageProps> & {
  componentName?: string;
  isTerminal: boolean;
  ignoreExpiredSession: boolean;
  skipApp: boolean;
};
export type RouteMap = {
  [key: string]: RouteComponent | Promise<RouteComponent>;
};

export const START_URL_PATTERN = new URLPattern('/start(/:code)');
export const VERIFY_URL_PATTERN = new URLPattern('/v/(:slug)');

// The regex pattern that matches the paths that do not use page transitions.
// The path should map to the pages that are considered as the starting point
// of the user journey.
const NO_PAGE_TRANSITION_PATH_PATTERN =
  /^\/(welcome|testing|start|verify|continue)/;

// TODO - replace this hack with React-router once NextJS is removed.
export class LocalRouter {
  /**
   * The list of callbacks that are subscribed to the LocalRouter.
   */
  _callbacks: Function[];

  _setRoutedAt: number;

  currentPath: string;

  currentQuery: {
    [key: string]: string;
  };

  onChange: () => void;

  routes: RouteMap;

  routePatterns: {
    [key: string]: URLPattern;
  } = {};

  constructor(currentPath: string, onChange: () => void, routes: RouteMap) {
    let startPath = currentPath;
    this._setRoutedAt = 0;

    // We don't read query parameters from the URL on startup.
    this.currentQuery = {};
    if (currentPath.includes('#')) {
      startPath = startPath.split('#')[0];
    }
    if (currentPath.includes('?')) {
      startPath = startPath.split('?')[0];
    }

    this._callbacks = [];
    this.currentPath = startPath;
    this.onChange = onChange;
    this.routes = routes;
    Object.keys(routes).forEach((route) => {
      if (route === '/start') {
        // code is allowed to be present in path param but not required
        // todo - make it required once the server is updated
        this.routePatterns[route] = START_URL_PATTERN;
      } else if (route === '/v') {
        this.routePatterns[route] = VERIFY_URL_PATTERN;
      } else {
        // This insures we can match with optional query params
        this.routePatterns[route] = new URLPattern(`${route}(?*)`);
      }
    });

    if (typeof window !== 'undefined') {
      window.onpopstate = (event: any) => {
        // On some versions of safari, event.state may be undefined somehow
        const eventPath = event?.state?.path;
        const browserPath = window.location.pathname;

        if (eventPath && this.routePatterns.hasOwnProperty(eventPath)) {
          this.currentPath = eventPath;
        } else if (
          browserPath !== this.currentPath &&
          this.routePatterns.hasOwnProperty(browserPath)
        ) {
          // Browser path is different from current path, but is a valid route.
          // We should sync to it.
          this.currentPath = browserPath;
        } else {
          // Reset the default path.
          this.currentPath = '/welcome';
        }

        // Ensure that the browser path is synced to the current path.
        if (browserPath !== this.currentPath) {
          historyReplaceState(
            {path: this.currentPath, query: this.currentQuery},
            `/${this.currentPath.substring(1)}`,
          );
        }

        this._logCurrentPath();
        startPageTransition(() => {
          this.onChange();
          this.didChange();
        }, 'backward');
      };
    }
  }

  /**
   * Subscribes to the local router.
   * @param callback The callback function to be called when the route changes.
   * @returns A function that can be called to unsubscribe from the router.
   */
  subscribe = (callback: () => void) => {
    this._callbacks.push(callback);
    return () => {
      // remove the callback from the list of callbacks.
      const index = this._callbacks.indexOf(callback);
      if (index !== -1) {
        this._callbacks.splice(index, 1);
      }
    };
  };

  matchRoute = (url: string) => {
    // Exact matches
    if (this.routePatterns[url]) {
      return url;
    }

    // Pattern-based matches
    const keys = Object.keys(this.routePatterns);
    const matchedKey = (() => {
      for (const patternKey of keys) {
        const pattern = this.routePatterns[patternKey];
        if (pattern) {
          const match = pattern.match(url);

          if (match) {
            return patternKey;
          }
        }
      }
    })();

    return matchedKey || null;
  };

  setRoute(path: any, replace: boolean) {
    const prevPath = this.currentPath;

    if (typeof path === 'string') {
      this.currentPath = path;
      this.currentQuery = {};
    } else {
      this.currentPath = path.pathname;
      this.currentQuery = path.query;
    }

    if (window && window.history) {
      if (replace) {
        historyReplaceState(
          {path: this.currentPath, query: this.currentQuery},
          `/${this.currentPath.substring(1)}`,
        );
      } else {
        historyPushState(
          {path: this.currentPath, query: this.currentQuery},
          `/${this.currentPath.substring(1)}`,
        );
      }
      this._logCurrentPath();
    }

    const updatePage = () => {
      this.onChange();
      this.didChange();
    };

    const now = Date.now();
    const interval = now - this._setRoutedAt;
    this._setRoutedAt = now;

    if (NO_PAGE_TRANSITION_PATH_PATTERN.test(this.currentPath)) {
      // no transition when entering the page that does not use page
      // transitions.
      startPageTransition(updatePage, 'none');
    } else if (prevPath === this.currentPath) {
      // no transition when route does not change.
      startPageTransition(updatePage, 'none');
    } else if (interval < 350) {
      // No transition when route just changed quickly. This is to avoid
      // multiple transitions overlapping each other and cause flickering.
      startPageTransition(updatePage, 'none');
    } else if (replace) {
      // transtion with crossfade effect.
      startPageTransition(updatePage, 'center');
    } else {
      // transtion with slide effect.
      startPageTransition(updatePage, 'forward');
    }
  }

  replace = (path: any) => {
    this.setRoute(path, true);
  };

  push = (path: any) => {
    this.setRoute(path, false);
  };

  query = () => {
    return this.currentQuery;
  };

  path = () => {
    return '/';
  };

  getRoute = (): Promise<RouteComponent> | null | undefined => {
    const pattern = this.matchRoute(this.currentPath);
    if (pattern) {
      let component = this.routes[pattern];
      // If the component is 'PromiseStubPage' then we haven't loaded async pages yet, so do so.
      if (component) {
        // @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'RouteComponent | Promise<RouteComponent>' and 'string' have no overlap.
        if (component === PromiseStubPage) {
          importModules();
          component = this.routes[pattern];
        }
        return Promise.resolve(component);
      }
    }
    return null;
  };

  // Returns the RouteComponent if it's available syncronously, otherwise returns null
  getSyncRoute = (): RouteComponent | null | undefined => {
    const pattern = this.matchRoute(this.currentPath);
    if (pattern) {
      const component = this.routes[pattern];
      // If the component is 'PromiseStubPage' then we haven't loaded async pages yet, so do so.
      // If it has a "then"- then it's a Promise, otherwise return the sync page.
      if (component) {
        // @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'RouteComponent | Promise<RouteComponent>' and 'string' have no overlap.
        if (component === PromiseStubPage) {
          importModules();
        } else if ((component as any).then !== undefined) {
          return null;
        } else {
          return component as RouteComponent;
        }
      }
    }
    return null;
  };

  loadAsyncRoutes = () => {
    importModules();
  };

  _logCurrentPath() {
    const path = this.currentPath.substring(1);
    // TODO(IDPROD-4892):
    // We sould use a static event name instead of this dynamic path.
    path && analytics.viewed(path as AnalyticsEventName);
  }

  /**
   * Notifies all subscribers that the route has changed.
   */
  didChange = () => {
    this._callbacks.forEach((callback) => callback());
  };
}
let router: LocalRouter | null | undefined;

export function buildRouter(
  currentPath: string,
  onChange: () => void,
  routes: RouteMap,
) {
  router = new LocalRouter(currentPath, onChange, routes);
}

export function maybeGetRouter(): LocalRouter | null | undefined {
  return router;
}

export default function getRouter(): LocalRouter {
  if (!router) {
    throw Error('Router not configured');
  }
  return router;
}
