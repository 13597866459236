import * as React from 'react';

import getWelcomePage from 'gelato/frontend/src/lib/consent_utils';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {GraphQlField} from '@sail/data';
import type {
  SessionOperatingMode,
  Flags,
} from '@stripe-internal/data-gelato/schema/types';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

type FlagList = GraphQlField<GetSessionQueryData, 'flags'>;

export const flagChecker = (flags?: FlagList | null) => {
  return (flag: Flags) => {
    return flags ? flags.includes(flag) : false;
  };
};

export class SessionInfo {
  // information about the verification object
  token: string;

  livemode: boolean;

  operatingMode: SessionOperatingMode;

  // information about the merchant
  isStripeIntegration: boolean;

  platformName: string | null | undefined;

  returnUrl: string | null | undefined;

  // configured constraints on control flow
  skipWelcomePage: boolean;

  skipSuccessPage: boolean;

  useConnectIframeDesign: boolean;

  // which welcome page should we route to?
  welcomePageUrl: string;

  // global UX
  hideTestingUX: boolean;

  // feature flags & experiments
  isFlagSet: (arg1: Flags) => boolean;

  constructor(session?: GetSessionQueryData | null) {
    this.isFlagSet = flagChecker(session && session.flags);
    this.welcomePageUrl = '/welcome';

    const data = session && session.session;
    if (data) {
      const {
        id,
        livemode,
        skipWelcomePage,
        skipSuccessPage,
        useConnectIframeDesign,
        operatingMode,
        returnUrl,
      } = data;
      this.hideTestingUX =
        livemode === true ||
        this.isFlagSet('gelato_disable_testmode_ui_elements');
      this.livemode = livemode;
      this.operatingMode = operatingMode;
      this.returnUrl = returnUrl;
      this.skipWelcomePage = !!skipWelcomePage;
      this.skipSuccessPage = !!skipSuccessPage;
      this.useConnectIframeDesign =
        Storage.getOverrideUseConnectIframeDesign() || !!useConnectIframeDesign;
      this.token = id;
      this.welcomePageUrl = getWelcomePage(session);
    } else {
      this.hideTestingUX = false;
      this.livemode = false;
      this.operatingMode = 'primary';
      this.returnUrl = null;
      this.skipWelcomePage = false;
      this.skipSuccessPage = false;
      this.useConnectIframeDesign = false;
      this.token = '<uninitialized>';
    }

    if (data && data.branding) {
      const {isStripe, platformName} = data.branding;
      this.isStripeIntegration = isStripe;
      this.platformName = platformName;
    } else {
      this.isStripeIntegration = false;
      this.platformName = null;
    }
  }
}

const DEFAULT_SESSION_INFO = new SessionInfo();

export const SessionInfoContext =
  React.createContext<SessionInfo>(DEFAULT_SESSION_INFO);
export const useSessionInfo = () => {
  return React.useContext(SessionInfoContext);
};

type SessionInfoProviderProps = {
  session: GetSessionQueryData | null | undefined;
  children: React.ReactNode;
};

const SessionInfoProvider = ({session, children}: SessionInfoProviderProps) => {
  const sessionInfo = session ? new SessionInfo(session) : DEFAULT_SESSION_INFO;

  return (
    <SessionInfoContext.Provider value={sessionInfo}>
      {children}
    </SessionInfoContext.Provider>
  );
};

export default SessionInfoProvider;
