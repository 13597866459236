import {hexToHsluv, hsluvToRgb, rgbToHsluv} from 'hsluv-ts';

import {generateColorStep} from 'gelato/frontend/src/components/theming/generateColorStep';

const SCALE_STEPS = [0, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

function hexToRgb(string: string) {
  return hsluvToRgb(hexToHsluv(string));
}

type GenerateHueScaleBrandColorProps = {
  brandColor: string;
  backgroundColor?: string;
};

/**
 * Generates an accessible color scale based on a specified
 * brand color and the background color that the color scale
 * will be used on.
 *
 * @param {object}
 * - brandColor: The brand color to generate the scale from.
 * - backgroundColor: The background color that the color scale will be used on.
 * @returns {object}
 * - scale: The generated color scale.
 * - inputAtLevel: The input level that the brand color is at.
 */
export function generateHueScaleFromBrandColor({
  brandColor,
  backgroundColor,
}: GenerateHueScaleBrandColorProps) {
  const rgb = hexToRgb(brandColor);
  const hsl = rgbToHsluv(rgb);
  const [h, s, l] = hsl;
  const colorSet: Record<number, string> = {};

  // This logic fuzzy finds the step value closest to the
  // brand color and sets it to the brand color.
  let bestLightnessDiff = 999;
  let bestLevel = -1;

  SCALE_STEPS.forEach((step) => {
    const genColor = generateColorStep(step, {
      startHue: h,
      endHue: h,
      minChroma: s,
      maxChroma: s,
      backgroundColor,
    });

    const genL = rgbToHsluv(hexToRgb(genColor))[2];
    const diff = Math.abs(l - genL);
    if (diff < bestLightnessDiff) {
      bestLevel = step;
      bestLightnessDiff = diff;
    }

    colorSet[step] = genColor;
  });

  colorSet[bestLevel] = brandColor;

  return {
    scale: colorSet,
    inputAtLevel: bestLevel,
  };
}
