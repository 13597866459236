export type TestModeSetting =
  | 'verified'
  | 'unverified'
  | 'verifiedAsync'
  | 'unverifiedAsync';

export type AutocompleteOptions = TestModeSetting | 'consentDeclined';

/**
 * @fileoverview Actions that manage the states for Test mode
 */

export type TestModeState = {
  testMode: {
    autocompleteSetting: AutocompleteOptions;
    autocompletePending: boolean;
    manualPending: boolean;
    error: any;
  };
};

/**
 * Creates the initial test mode state for the controller.
 * @returns The initial test mode state.
 */
export function createTestModeState(): TestModeState {
  return {
    testMode: {
      autocompleteSetting: 'verified',
      autocompletePending: false,
      manualPending: false,
      error: null,
    },
  };
}

/**
 * @returns Whether the current testmode VS will result in verified VR
 */
export function isVerifiedAutoComplete(state: TestModeState): boolean {
  const {testMode} = state;

  return testMode?.autocompleteSetting.indexOf('verified') === 0;
}

/**
 * @returns Whether the current testmode VS will result in consent declined VF
 */
export function isConsentDeclinedAutoComplete(state: TestModeState): boolean {
  const {testMode} = state;

  return testMode?.autocompleteSetting === 'consentDeclined';
}

/**
 * @returns Whether the current testmode data generated will be async
 */
export function isForceDelayed(state: TestModeState): boolean {
  return state.testMode?.autocompleteSetting.includes('Async');
}
