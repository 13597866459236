import {produce} from 'immer';

import {Feedback} from 'gelato/frontend/src/controllers/states/DocumentState';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import trackAutoCaptureTimeoutError from 'gelato/frontend/src/controllers/utils/trackAutoCaptureTimeoutError';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import {errorInDev} from 'gelato/frontend/src/lib/assert';
import ImageFrame from 'gelato/frontend/src/lib/ImageFrame';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import waitForTimeout from 'gelato/frontend/src/lib/waitForTimeout';
import BaseInspector from 'gelato/frontend/src/ML/detectors/BaseInspector';
import BestFrameInspector from 'gelato/frontend/src/ML/detectors/BestFrameInspector';
import BlurInspector from 'gelato/frontend/src/ML/detectors/BlurInspector';
import FocusInspector from 'gelato/frontend/src/ML/detectors/FocusInspector';
import IDInspector, {
  MIN_VALID_PROBABILITY,
} from 'gelato/frontend/src/ML/detectors/IDInspector';
import MicroBlinkCaptureInspector from 'gelato/frontend/src/ML/detectors/MicroBlinkCaptureInspector';
import {
  DEFAULT_ID_CROP_PADDING,
  FRONT_DOCUMENT_DETECTED_TIMEOUT_EXTENSION,
  BACK_TIMEOUT_DURATION,
} from 'gelato/frontend/src/ML/lib/constants';

import type {
  FeedbackValue,
  InspectionState,
} from 'gelato/frontend/src/controllers/states/DocumentState';
import type {
  ApplicationState,
  ApplicationInspectionMethod,
} from 'gelato/frontend/src/controllers/types';

export type DocumentInspector = BaseInspector<
  [Readonly<ApplicationState>, Readonly<InspectionState>],
  Readonly<InspectionState>
>;

export type InspectorProvider = {
  displayName: string;
  getInstance: () => DocumentInspector;
  isSupported: () => boolean;
};

// The amount of padding the client should crop around the bounding box
// returned by the ML model as a percentage of the image width and height.
const USER_UPLOAD_CROP_PADDING = DEFAULT_ID_CROP_PADDING / 100;

// The maximum time in milliseconds to spend on building the inspector.
// If the time spent exceeds this value, the inspector will be skipped and
// tried again later.
const INSPECTOR_BUILD_TIMEOUT_MS = 6000;

// The set of providers that failed to execute.
const failedProviders = new Set<InspectorProvider>();

/**
 * Reset the failed providers for testing purposes.
 */
export function resetFailedProvidersForTest() {
  failedProviders.clear();
}

/**
 * Inspect the document using the given inspector.
 * @param provider The object that provides the inspector.
 * @param appState The application state to work on.
 * @param inspectionState The inspection state to work on.
 * @returns The updated inspection state.
 */
async function inspectBy(
  provider: InspectorProvider,
  appState: ApplicationState,
  inspectionState: InspectionState,
): Promise<InspectionState> {
  try {
    const {inputImage} = inspectionState;
    if (inputImage.placeholder) {
      // The input image is a placeholder. Skip the inspection.
      return inspectionState;
    }

    if (!provider.isSupported()) {
      throw new Error(ErrorCode.inspectorIsNotSupported);
    }

    const inspector = provider.getInstance();

    if (inspector.error) {
      failedProviders.add(provider);
      // The warm up or build process failed. This is fine since we can still
      // check other inspectors and ask for additional feedback.
      return inspectionState;
    }

    if (!inspector.ready) {
      const {workingInputMethod} = appState.document;
      if (workingInputMethod === 'auto_capture') {
        // Inspector is not ready yet, we'll buit it asynchronously.
        inspector.build();
        // Return the original state since the inspector is not ready yet.
        // We'll check the inspector again in the next iteration.
        return inspectionState;
      } else {
        // This is for manual capture (e.g: file upload or manual capture),
        // user likely only uploads the image once, so we should wait for the
        // little bit longer to build the inspector before we skip it to ensure
        // better detection.
        await Promise.race([
          inspector.build(),
          waitForTimeout(INSPECTOR_BUILD_TIMEOUT_MS),
        ]);
        if (!inspector.ready) {
          // Build timed out or failed. We should skip this inspector instead of
          // blocking the user.
          return inspectionState;
        }
      }
    }

    const result = await inspector.detect(appState, inspectionState);
    failedProviders.delete(provider);

    return result;
  } catch (ex) {
    const cause = asError(ex);

    // Remember the failed provider
    failedProviders.add(provider);

    // The detection failed. This is fine since we can still check other
    // inspectors and ask for additional feedback.
    // Note that the error would have been logged by the inspector already.

    const message = `Instector ${
      provider.displayName
    } failed to detect: ${String(cause)}`;

    const error = new Error(message, {cause});

    errorInDev(message);
    handleException(error, message);
    return inspectionState;
  }
}

/**
 * Resolve the document image and location based on the results from the
 * inspectors.
 * @param inspectionState
 * @returns
 */
async function resolveDocument(
  inspectionState: Readonly<InspectionState>,
): Promise<Readonly<InspectionState>> {
  const {idInspectorResult, microBlinkCaptureInspectorResult} = inspectionState;
  const documentLocation = idInspectorResult?.location || null;

  let state = inspectionState;
  let documentImage: ImageFrame | null = null;
  let documentIsValid = false;

  if (microBlinkCaptureInspectorResult) {
    const {detectedImage, isValid} = microBlinkCaptureInspectorResult;
    if (detectedImage && isValid) {
      documentImage = await detectedImage.clone();
      documentIsValid = isValid;
    }
  }

  // This is a fallback to use the ID inspector result if MicroBlink failed to
  // extract the document image.
  if (!documentImage && idInspectorResult) {
    const {inspectedImage, location, isValid} = idInspectorResult;
    if (inspectedImage && location && isValid) {
      const [width, height] = location.dimensions;
      const paddingX = width * USER_UPLOAD_CROP_PADDING;
      const paddingY = height * USER_UPLOAD_CROP_PADDING;
      documentImage = await inspectedImage.crop(
        location.topLeft[0] - paddingX,
        location.topLeft[1] - paddingY,
        width + paddingX * 2,
        height + paddingY * 2,
      );
    }
  }

  // Fall to the ID inspector result if MicroBlink failed to validate the
  // image.
  if (!documentIsValid && idInspectorResult?.isValid) {
    documentIsValid = true;
  }

  if (documentImage?.placeholder) {
    // If the document image is a placeholder, we should not use it.
    // This is likely a temporary issue caused by canvas operations
    // that failed to work.
    documentImage = null;
    documentIsValid = false;
  }

  // Release the memory.
  microBlinkCaptureInspectorResult?.inspectedImage?.dispose();
  microBlinkCaptureInspectorResult?.detectedImage?.dispose();
  idInspectorResult?.inspectedImage?.dispose();

  let feedback: FeedbackValue | null = null;

  if (
    idInspectorResult?.feedback === Feedback.invalidDocument &&
    !microBlinkCaptureInspectorResult?.detectedImage
  ) {
    // If the IDInspector says the document image is invalid and MicroBlink
    // did not detect any image, we should use the IDInspector feedback.
    // This means that IDInspector sees something wrong with the document image
    // while MicroBlink did not see anything.
    feedback = Feedback.invalidDocument;
  } else if (!documentIsValid) {
    // If document is not valid, we should use the feedback from the inspector
    // to show the user what's wrong with the document.
    // By default, prioritize the feedback from MicroBlink inspector.
    feedback =
      microBlinkCaptureInspectorResult?.feedback ||
      idInspectorResult?.feedback ||
      null;
  }

  // Prioritize the document type from MicroBlink inspector.
  const documentType =
    microBlinkCaptureInspectorResult?.documentType ||
    idInspectorResult?.documentType ||
    null;

  state = produce(state, (draft) => {
    draft.documentImage = documentImage;
    draft.documentLocation = documentLocation;
    draft.documentType = documentType;
    draft.feedback = feedback;
    draft.documentIsValid = documentIsValid;
  });

  return state;
}

const MAX_ANALYTICS_LOGS_COUNT = 25;

/**
 * Track the inspection state for debugging purposes.
 * @param inspectionState The inspection state to track.
 */
function trackInspectionState(inspectionState: InspectionState): void {
  const {
    iterationCount,
    idInspectorResult,
    microBlinkCaptureInspectorResult,
    side,
  } = inspectionState;

  // Do not track all the inspection states to avoid flooding the analytics.
  // We onlt track this for debugging purposes.
  if (iterationCount < MAX_ANALYTICS_LOGS_COUNT) {
    analytics.track('inspectDocumentResult', {
      idInspector: JSON.stringify(idInspectorResult),
      mbInspector: JSON.stringify(microBlinkCaptureInspectorResult),
      side,
    });
  }
}

/**
 * Whether we should skip the timeout error for auto-capture.
 * @param state The inspection state to check.
 */
function shouldSkipAutoCaptureTimeoutError(state: InspectionState): boolean {
  const {idInspectorResult} = state;

  if (idInspectorResult?.probability) {
    const {frontCard, frontPassport, back, noDocument, invalid} =
      idInspectorResult.probability;

    if (noDocument >= MIN_VALID_PROBABILITY) {
      // We think there is no document, we should expose the timout
      // error to the user.
      return false;
    }

    // Skip the timeout error if we could detect anything like a document.
    return (
      Math.max(frontCard, frontPassport, back, invalid) >=
      Math.min(0.2, MIN_VALID_PROBABILITY)
    );
  }

  return false;
}

/**
 * Inspect the document and return the result.
 *
 * @param appState The application state to work on.
 * @param inputState The inspection state which inlcudes the
 *  input image and the side of the document to inspect.
 * @returns The updated inspection state.
 */
const inspectDocument: ApplicationInspectionMethod<InspectionState> = async (
  appState,
  inputState,
): Promise<InspectionState> => {
  let state = inputState;

  if (!state.feedback) {
    // Collect feedback from the ML models.
    // Inspect the document with IDInspector and MicroBlinkCaptureInspector.

    state = await inspectBy(IDInspector, appState, state);
    state = await inspectBy(MicroBlinkCaptureInspector, appState, state);

    // Resolve the document image and location.
    state = await resolveDocument(state);

    state = await inspectBy(BlurInspector, appState, state);
    state = await inspectBy(FocusInspector, appState, state);

    // See if we can get a better frame while waiting for a little bit.
    state = await inspectBy(BestFrameInspector, appState, state);
  }

  // Apporve if it looks good.
  const {feedback, documentImage, documentIsValid} = state;
  if (!feedback && documentImage && documentIsValid) {
    state = produce(state, (draft) => {
      draft.approved = true;
    });
  }

  trackInspectionState(state);

  if (
    failedProviders.has(IDInspector) &&
    failedProviders.has(MicroBlinkCaptureInspector)
  ) {
    // Our inspectors are not working. This is a fatal error.
    // We should switch to manual capture mode.
    throw new Error(ErrorCode.documentAutoCaptureNotSupported);
  }

  // Check to see if we have timed out before approving the capture.
  const now = Date.now();
  if (now > state.timeoutAt && !state.approved) {
    if (shouldSkipAutoCaptureTimeoutError(state)) {
      // If a document image is provided, even if it has low quality,
      // the wrong document type, or the incorrect side, we should extend the
      // timeout. This allows real-time feedback to guide the user towards
      // capturing a better image.
      state = produce(state, (draft) => {
        draft.timeoutAt +=
          draft.side === 'front'
            ? FRONT_DOCUMENT_DETECTED_TIMEOUT_EXTENSION
            : BACK_TIMEOUT_DURATION;
      });
    } else {
      // Track the timeout error asynchronously.
      trackAutoCaptureTimeoutError(appState);

      // We have timed out and we don't have a document image.
      throw new Error(ErrorCode.documentAutoCaptureTimeout);
    }
  }

  return state;
};

export default inspectDocument;
