import 'mdn-polyfills/String.prototype.endsWith';
import 'mdn-polyfills/Object.entries';
import ResizeObserverPolyFill from 'resize-observer-polyfill';

if (!window.ResizeObserver) {
  // Older browsers don't support ResizeObserver, so we polyfill it.
  // This is needed for the `sails-next` package that uses `ResizeObserver`
  // without checking for its existence.
  window.ResizeObserver = ResizeObserverPolyFill;
}

// Explicitly declare the global variables to avoid errors like these:
// - "ReferenceError: Can't find variable: WebAssembly".
// - "ReferenceError: WebAssembly is not defined".
Object.assign(window, {
  structuredClone: window.structuredClone,
  WebAssembly: window.WebAssembly,
  FileReader: window.FileReader,
});
