import type {View} from './types';
import {isIntent} from '../intent';

export function normalizeIntents<Props>(
  input: unknown,
  // TODO(koop): Uncomment these lines to add function handling;
  //   add corresponding type definitions
  // | ((props: Props) => View.Intent<Props>[])
  intents: View.Intent<Props>[] = [],
): View.Intent<Props>[] | null {
  if (isIntent(input)) {
    intents.push(input);
    return intents;
  } else if (Array.isArray(input)) {
    intents.push(...input);
    return intents;
  }

  // If we wanted to support functions:
  // } else if (typeof input === 'function') {
  //   intents.push(dynamic(input as (props: Props) => View.Intent<Props>[]));
  // }

  return null;
}
