import {isValidElement} from 'react';

/**
 * Extract the data-testid attribute from a React element.
 * @param element The React element to extract the data-testid attribute from.
 * @returns The value of the data-testid attribute. Returns null if the
 *   attribute is not found.
 */
export default function getReactElementTestId(
  element: JSX.Element | string | null,
): string | null {
  if (!element || typeof element !== 'object' || !isValidElement(element)) {
    return null;
  }

  const props: unknown = element.props;

  if (!props || typeof props !== 'object' || !('data-testid' in props)) {
    return null;
  }

  const id = props['data-testid'];
  return id ? String(id) : null;
}
