/* eslint-disable @typescript-eslint/no-explicit-any */
import {css} from './css';
import type {
  CssPlugin,
  CssSelectorIndexer,
  CssSerializer,
  SerializedStyles,
} from './types';

export function cssDependency(...deps: SerializedStyles[]): {
  isolatedDependencies: string[];
} {
  return {
    isolatedDependencies: deps.map(({key}) => key),
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
type Serializer<Properties> = CssSerializer<Properties, {}, {}, {}, {}, {}>;

type ObjectPlugins<Properties, T> = {
  [K in keyof T]: CssPlugin<
    keyof T[K] | (K extends keyof Properties ? Properties[K] : never),
    Properties
  >;
};

export function cssPluginsFromObject<T>(
  fn: <Properties>(css: Serializer<Properties>) => T,
) {
  return <Properties>(
    css: Serializer<Properties>,
  ): ObjectPlugins<Properties, T> => {
    const obj = fn(css);
    const properties = Object.keys(obj as object);
    const plugins = {} as ObjectPlugins<Properties, T>;
    for (let i = 0; i < properties.length; i++) {
      const property = properties[i] as keyof T;
      const values = obj[property] as unknown as Record<string, string>;
      plugins[property] = function plugin(value: string) {
        return value in values ? values[value] || {} : {[property]: value};
      } as any;
    }
    return plugins;
  };
}

const cssReset = css.configure({layer: 'reset'});

export function cssPluginResetStyles(
  selector: CssSelectorIndexer,
  styles: typeof cssReset.ComputedProperties,
): {
  isolatedDependencies: string[];
} {
  return cssDependency(
    cssReset({
      [selector]: styles,
    }),
  );
}
