import Color from 'color';

export const stripeColors = (platformColor: string) => {
  const parsed = Color(platformColor);

  const [h, s, v] = parsed.hsv().array();
  const [, hslSat, hslLightness] = parsed.hsl().array();

  let lightened;
  let darkened;

  if (v < 15) {
    // Very dark colors
    lightened = Color.hsv(h, s, v + 15);
    darkened = Color.hsv(h, s, v + 25);
  } else if (v > 85) {
    // Very "bright" colors
    if (hslLightness > 92) {
      // Colors that are pastel
      lightened = Color.hsv(h, s * 0.9, v - 5);
      darkened = Color.hsv(h, s * 0.9, v - 10);
    } else if (s > 50) {
      // Colors that are light and fluorescent
      lightened = Color.hsv(h, s, v - 12);
      darkened = Color.hsv(h, s, v - 26);
    } else {
      // Colors that are light
      lightened = Color.hsl(h, hslSat * 0.85, Math.min(hslLightness + 4, 95));
      darkened = Color.hsl(h, hslSat * 0.75, Math.max(hslLightness - 7, 0));
    }
  } else {
    lightened = parsed.lighten(0.12);
    darkened = parsed.darken(0.15);
  }

  return {
    lightened,
    darkened,
  };
};
