import {useUpdatedRef} from '@sail/react';
import {once} from '@sail/utils';
import {useMemo} from 'react';
import {UninitializedObservabilityProviderError} from 'src/internal/common/errors';
import logErrorInDev from 'src/internal/common/logErrorInDev';
import {useObservabilityApi} from 'src/internal/provider/ObservabilityApiProvider';

import type {IAnalytics} from 'src/internal/analytics/types';

const logUninitializedErrorOnce = once(() => {
  logErrorInDev(new UninitializedObservabilityProviderError('useAnalytics'));
});

/**
 * React hook to report contextualized analytics events.
 *
 * Returns an object with the analytics methods contextualized with the
 * service, release, user ID, and analytics configuration provided by the
 * closest `<ObservabilityProvider />`.
 *
 * @example Basic {{include "./examples/useAnalytics.basic.tsx"}}
 *
 * @see https://sail.stripe.me/apis/observability/useAnalytics
 */
export default function useAnalytics(): IAnalytics {
  const apiRef = useUpdatedRef(useObservabilityApi());

  return useMemo((): IAnalytics => {
    if (!apiRef.current) {
      logUninitializedErrorOnce();
    }

    return {
      action(...args: Parameters<IAnalytics['action']>): void {
        return apiRef.current?.analytics?.action(...args);
      },

      link(...args: Parameters<IAnalytics['link']>): void {
        return apiRef.current?.analytics?.link(...args);
      },

      modal(...args: Parameters<IAnalytics['modal']>): void {
        return apiRef.current?.analytics?.modal(...args);
      },

      track(...args: Parameters<IAnalytics['track']>): void {
        return apiRef.current?.analytics?.track(...args);
      },

      viewed(...args: Parameters<IAnalytics['viewed']>): void {
        return apiRef.current?.analytics?.viewed(...args);
      },
    } as IAnalytics;
  }, [
    apiRef, // This is a ref object, so it never changes and thus the returned object is stable.
  ]);
}
