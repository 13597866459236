/* eslint-disable @typescript-eslint/ban-types */
import {forEachToken, isToken} from '@sail/engine';
import type {Style, Token} from '@sail/engine';

type TokenLookupTable = Record<string, string>;
const tokenLookupTables = new WeakMap<Token.AbstractMap, TokenLookupTable>();

function getTokenLookupTable(tokenMap: Token.AbstractMap): TokenLookupTable {
  if (tokenLookupTables.has(tokenMap)) {
    return tokenLookupTables.get(tokenMap) as TokenLookupTable;
  }
  const lookupTable = Object.create(null) as TokenLookupTable;
  forEachToken(tokenMap, (value, path) => {
    lookupTable[path] = value.toString();
  });
  tokenLookupTables.set(tokenMap, lookupTable);
  return lookupTable;
}

function createTokenLookupTable(
  tokenMaps: Token.AbstractMap[],
): TokenLookupTable {
  if (tokenMaps.length === 1) {
    return getTokenLookupTable(tokenMaps[0]);
  }
  return Object.assign(
    Object.create(null),
    ...tokenMaps
      .slice()
      .reverse()
      .map((map) => getTokenLookupTable(map)),
  );
}

type TokenLookup<V extends Token.AbstractMap> = <T>(
  value: T,
) => Exclude<T, Token.Key<V>> | string;

function createTokenLookup<V extends Token.AbstractMap>(
  tokenMaps?: V[],
): TokenLookup<V> {
  if (!tokenMaps) {
    return ((x: string) => x) as unknown as TokenLookup<V>;
  }

  let lookupTable: TokenLookupTable | void;
  return <T>(value: T): Exclude<T, Token.Key<V>> | string => {
    if (!lookupTable) {
      lookupTable = createTokenLookupTable(tokenMaps);
    }
    return typeof value === 'string' && value in lookupTable
      ? lookupTable[value as unknown as string]
      : (value as Exclude<T, Token.Key<V>>);
  };
}

export function setFormat<V>(value: V, format: string | void): V {
  if (format) {
    (value as unknown as {format: string}).format = format;
  }
  return value;
}

type Formatter = {format?: string};

type PropertyAPI<V extends Token.AbstractMap> = {
  accept<T = string & {}>(
    toString?: (value: T) => string,
  ): (value: T | Token.Value<T> | Token.Key<V>) => string;

  alias<T = string & {}>(
    name: string,
    toString?: (value: T) => string,
  ): (value: T | Token.Value<T> | Token.Key<V>, set: Style.PluginAPI) => void;

  decorate<F>(fn: F, formatter?: Formatter): F;

  transform<R = (value: Token.Key<V>) => string>(
    transform: (lookup: TokenLookup<V>) => R,
  ): R;
};

export function property<V extends Token.AbstractMap = Token.AbstractMap>(
  tokens?: V[],
): PropertyAPI<V> {
  const lookup = createTokenLookup(tokens);

  function decorate<F>(fn: F, formatter?: {format?: string}): F {
    (fn as unknown as {tokens?: V[]}).tokens = tokens;
    setFormat(fn, formatter?.format);
    return fn;
  }

  function property<T>(
    toString: (value: T) => string = (v) => `${v}`,
  ): (value: T | Token.Value<T> | Token.Key<V>) => string {
    return decorate((value) => {
      const v = lookup(value);
      if (isToken(v)) {
        return v.toString();
      } else {
        return toString(v as T);
      }
    }, toString as unknown as Formatter);
  }

  return {
    alias<T = string & {}>(name: string, toString?: (value: T) => string) {
      const fn = property(toString);
      return decorate(
        (value: T | Token.Value<T> | Token.Key<V>, set: Style.PluginAPI) => {
          set.property(name, fn(value));
        },
        fn as unknown as Formatter,
      );
    },
    decorate,
    accept: property,
    transform<R = (value: Token.Key<V>) => string>(
      transform?: (lookup: TokenLookup<V>) => R,
    ): R {
      return decorate(transform ? transform(lookup) : (lookup as unknown as R));
    },
  };
}

export const format = {
  number: (t: (number | string) & {}): string => `${t}`,
  px: setFormat(
    (t: (number | string) & {}) => (typeof t === 'number' ? `${t}px` : `${t}`),
    'px',
  ),
  ms: setFormat(
    (t: (number | string) & {}) => (typeof t === 'number' ? `${t}ms` : `${t}`),
    'ms',
  ),
  deg: setFormat(
    (t: (number | string) & {}) => (typeof t === 'number' ? `${t}deg` : `${t}`),
    'deg',
  ),
};
