import {refresh} from '@sail/icons/react/Icon';
import {Icon, view, css} from '@sail/ui';
import * as React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import Button from 'gelato/frontend/src/components/ButtonV2';
import {ErrorState} from 'gelato/frontend/src/components/ContentStateV2';
import Heading from 'gelato/frontend/src/components/HeadingV2';
import Message from 'gelato/frontend/src/components/Message';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import TopNavigationBar from 'gelato/frontend/src/components/TopNavigationBar';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import flags from 'gelato/frontend/src/lib/flags';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import Spinner from 'gelato/frontend/src/svg/SpinnerV2';

import type {IntlShape} from 'react-intl';

const Styles = {
  body: css({
    stack: 'y',
    alignX: 'center',
    alignSelfY: 'center',
    alignY: 'center',
    gap: 'large',
    textAlign: 'center',
  }),
  bodyWrapper: css({
    stack: 'y',
  }),
  h1: css({
    font: 'heading.large',
    margin: 'space.0',
  }),
};

const messages = defineMessages({
  documentTitle: {
    id: 'page.submit.documentTitle',
    description: 'HTML title for verification submission page',
    defaultMessage: 'Submitting your verification | Stripe',
  },
  loadingTitle: {
    id: 'page.submit.loadingTitle',
    description: 'Message shown while submitting verification',
    defaultMessage: 'Submitting...',
  },
  errorTitle: {
    id: 'page.submit.errorTitle',
    description:
      'Error title when when there is an error submitting the verification',
    defaultMessage: 'Something went wrong',
  },
  errorDescription: {
    id: 'page.submit.errorDescription',
    description:
      'Error message for there is an error submitting the verification',
    defaultMessage: 'We were unable to submit your verification.',
  },
  errorAction: {
    id: 'page.submit.errorAction',
    description: 'Label on button allowing user to retry submission',
    defaultMessage: 'Try again',
  },
  submitBodyHeader: {
    id: 'page.submit.bodyHeader',
    description: 'Header shown while submission is being processed',
    defaultMessage: 'Submitting your information for verification',
  },
});

type Props = {
  intl: IntlShape;
};

const {useEffect, useCallback, useRef} = React;

const SubmitPage = (props: Props) => {
  const {appController} = useAppController();
  const {session} = appController.state;
  const urlParams = new URLSearchParams(window.location.search);
  const forceDelay = urlParams.get('forceAsync') !== null;
  const redirectRef = useRef(false);

  const doSubmit = useCallback(
    async (forceDelay: boolean) => {
      await appController.submitVerification({forceDelay});
    },
    [appController],
  );

  const {closed, missingFields, submitted} = session ?? {};

  const {error, pending, value} = appController.state.mutation.submit;

  useEffect(() => {
    if (!missingFields || redirectRef.current) {
      // Session is not ready yet or redirect has already been done
      return;
    }
    if (closed || (submitted && missingFields.length > 0)) {
      appController.routeToNextPage({
        reason: 'submitted',
        caller: 'SubmitPage',
      });
      redirectRef.current = true;
    } else if (
      flags.isActive('idprod_submit_routing_rollout') &&
      missingFields.includes('id_document_images')
    ) {
      // if there are missing fields we should redirect them.
      // this is usually because a user has gone either directly to the /submit route or because
      // they have gone back to the /submit route from a different VS session.
      appController.routeToNextPage({
        reason: 'submit_redirect',
      });
      redirectRef.current = true;
    } else if (!pending && error === null) {
      // Only call submit request when we know there is no pending request
      // or that the request did not return
      doSubmit(forceDelay);
      redirectRef.current = true;
    }
  }, [
    appController,
    doSubmit,
    closed,
    error,
    forceDelay,
    missingFields,
    pending,
    submitted,
    value,
  ]);
  const header = <TopNavigationBar />;

  if (appController.state.mutation.submit.error) {
    const errorBody = (
      <ErrorState
        description={<Message {...messages.errorDescription} />}
        title={<Message {...messages.errorTitle} />}
      />
    );
    const footer = (
      <Button
        onPress={() => doSubmit(false)}
        data-testid="submit-refresh-button"
      >
        <Message {...messages.errorAction} />
        <Icon icon={refresh} size="xsmall" />
      </Button>
    );
    return <PageCard body={errorBody} header={header} footer={footer} />;
  }

  const loadingBody = (
    <view.div uses={[Styles.body]}>
      <Spinner size="xxlarge" data-testid="loading-spinner" />
      <Heading level={1} uses={[Styles.h1]}>
        <Message {...messages.submitBodyHeader} />
      </Heading>
    </view.div>
  );
  return (
    <PageCard
      header={header}
      body={loadingBody}
      bodySectionStyles={[Styles.bodyWrapper]}
    />
  );
};

setComponentConfig(SubmitPage, {
  componentName: 'submit',
});

export default injectIntl(SubmitPage);
