import {Observable} from '@apollo/client';

import type {SubscriptionObserver} from 'src/internal/prefetching/types';

export default function createMulticastObservable<T>(
  observable: Observable<T>,
  listener?: {
    next?: (value: T) => void;
    error?: (error: any) => void;
    complete?: () => void;
    unsubscribe?: () => void;
  },
) {
  const enablePruningSignal =
    typeof window !== 'undefined' &&
    !!(window as any).PRELOADED?.flags?.enable_prefetch_cache_manual_pruning;
  const observers = new Set<SubscriptionObserver>();
  const pastEvents = new Array<
    {result: T} | {error: any} | {complete: boolean}
  >();

  const subscription = observable.subscribe(
    function multicastObservableNext(result) {
      listener?.next?.(result);
      observers.forEach((observer) => {
        observer.next(result);
      });
      pastEvents.push({result});
    },
    function multicastObservableError(error) {
      listener?.error?.(error);
      observers.forEach((observer) => {
        observer.error(error);
      });
      pastEvents.push({error});
    },
    function multicastObservableComplete() {
      listener?.complete?.();
      observers.forEach((observer) => {
        observer.complete();
      });
      if (enablePruningSignal) {
        pastEvents.push({complete: true});
      }
    },
  );

  return new Observable<T>((observer) => {
    observers.add(observer);

    for (const event of pastEvents) {
      if ('result' in event) {
        observer.next(event.result);
      } else if ('complete' in event && event.complete) {
        // Propagating previous calls to complete is important as
        // we rely on it to mark the prefetch as usable and not
        // discard it.
        observer.complete();
      } else if ('error' in event) {
        observer.error(event.error);
      }
    }

    return function multicastObservableUnsubscribe() {
      observers.delete(observer);

      if (observers.size === 0) {
        listener?.unsubscribe?.();
        subscription.unsubscribe();
      }
    };
  });
}
