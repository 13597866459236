/**
 * Helper function to get the camera permission state.
 * @returns The camera permission state: `granted`, `denied`, `prompt`, or
 *   `null` when browser does not support the Permissions API.
 */
export default async function queryCameraPermissionState(): Promise<PermissionState | null> {
  try {
    const status = await window.navigator.permissions.query({
      name: 'camera' as PermissionName,
    });
    return status.state;
  } catch (ex) {
    // window.navigator.permissions.query isn't supported in some browsers.
    // just return null.
    return null;
  }
}
