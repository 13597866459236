import {ConsumerSession} from 'gelato/frontend/src/api/Consumer/api';
import {ConsumerSessionLookupResponse} from 'gelato/frontend/src/api/Consumer/types';

import type {
  GenericStripeError,
  FetchResult,
} from 'gelato/frontend/src/api/request';

export default async function lookupConsumerSession(
  emailAddress: string,
): Promise<FetchResult<GenericStripeError, ConsumerSessionLookupResponse>> {
  return ConsumerSession.lookup({
    email_address: emailAddress,
    request_surface: 'web_identity_product',
  });
}
