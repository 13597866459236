import {HttpLink} from '@apollo/client';

import type {Fetch} from '@sail/data';

export default function createApolloLink(fetch: Fetch, graphQlUrl: string) {
  return new HttpLink({
    uri: graphQlUrl,
    fetch,
  });
}
