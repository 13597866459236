import {clockSvg as Icon__small__clockSvg} from '../Icon__small/Icon__small--clock.js'
import {clockSvg as Icon__xsmall__clockSvg} from '../Icon__xsmall/Icon__xsmall--clock.js'

export var clock = {
  name: 'clock',
  category: 'icon',
  colorable: true,
  svg: Icon__small__clockSvg,
  alt: {
    Icon__small: Icon__small__clockSvg,
    Icon__xsmall: Icon__xsmall__clockSvg
  },
  combined: ['Icon__small', 'Icon__xsmall']
};
