import {useEffect} from 'react';

import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import {isSessionError} from 'gelato/frontend/src/lib/sessionError';

const INVALID_PAGE_PATH_PATTERN = /^\/invalid/;

/**
 * This hook will redirect to the `/invalid` page if the session error
 * is detected.
 *
 * @param enabled Whether the hook is enabled.
 * @returns Whether the hook is redirecting to the `/invalid` page.
 */
export default function useAppRedirectIfHasSessionError(
  enabled: boolean,
): boolean {
  const {appController, appState} = useAppController();
  const {mutation} = appState;
  const router = appController.runtime?.router;

  // The retryable error that should be displayed in the footer
  // (instead of redirecting to `/invalid` page).
  const error =
    appState.sessionError ||
    mutation.consent.error ||
    mutation.documentType.error ||
    mutation.start.error;

  const willRedirect =
    enabled &&
    router &&
    error &&
    isSessionError(error) &&
    !INVALID_PAGE_PATH_PATTERN.test(router.currentPath);

  useEffect(() => {
    if (willRedirect) {
      // Session error is not retryable, automatically redirect to the
      // `/invalid` page.
      appController.routeToPath('/invalid', {redirect: true});
    }
  }, [appController, willRedirect]);

  return willRedirect;
}
