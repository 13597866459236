import {Select, view, css} from '@sail/ui';
import {IndividualFields} from '@stripe-internal/data-gelato/schema/types';
import * as React from 'react';

import Button from 'gelato/frontend/src/components/ButtonV2';
import Message, {
  Props as MessageProps,
} from 'gelato/frontend/src/components/Message';
import NetworkErrorNotice from 'gelato/frontend/src/components/NetworkErrorNotice';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import {
  responsiveCss,
  deviceCss,
} from 'gelato/frontend/src/components/stylesV2';
import TestingBanner from 'gelato/frontend/src/components/TestingBanner';
import {AutocompleteOptions} from 'gelato/frontend/src/controllers/states/TestModeState';
import {ApplicationState} from 'gelato/frontend/src/controllers/types';
import analytics from 'gelato/frontend/src/lib/analytics';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import Storage from 'gelato/frontend/src/lib/Storage';
import messages from 'gelato/frontend/src/local_pages/messages/testing';
import {FooterLinksSection} from 'gelato/frontend/src/local_pages/welcome_v2';

type ButtonProps = {
  mobileHidden?: boolean;
  withIcon?: boolean;
  pending: boolean;
  onClick: () => void;
};

type AutoCompleteValues = {
  message: MessageProps;
  value: AutocompleteOptions;
  requiredField?: IndividualFields;
}[];

const testOptions: AutoCompleteValues = [
  {
    message: messages.autocompleteVerifiedTitle,
    value: 'verified',
  },
  {
    message: messages.autocompleteUnverifiedTitle,
    value: 'unverified',
  },
  {
    message: messages.autocompleteVerifiedAsyncTitle,
    value: 'verifiedAsync',
  },
  {
    message: messages.autocompleteUnverifiedAsyncTitle,
    value: 'unverifiedAsync',
  },
  {
    message: messages.autocompleteConsentDeclinedTitle,
    value: 'consentDeclined',
    requiredField: 'consent',
  },
];

function getUsableOptions(state: ApplicationState): AutoCompleteValues {
  const requiredFields = state.session?.requiredFields;
  return testOptions.filter(
    (option) =>
      !option.requiredField || requiredFields?.includes(option.requiredField),
  );
}

export const Styles = {
  footer: css({
    paddingBottom: 'space.250',
  }),
  footerResponsive: responsiveCss({
    compact: {
      paddingBottom: '8px',
    },
  }),
  card: css({
    paddingBottom: 'space.150',
    margin: '0 12px 15px 12px',
    boxShadow: 'small',
  }),
  borderBottom: css({
    borderBottomWidth: 'small',
    borderBottomStyle: 'solid',
    borderBottomColor: 'border',
  }),
  content: css({
    padding: 'medium',
  }),
  mobileHidden: deviceCss({
    desktop: {
      display: 'block',
    },
    mobile: {
      display: 'none',
    },
  }),
  mobileVisible: deviceCss({
    desktop: {
      display: 'none',
    },
    mobile: {
      display: 'block',
    },
  }),
  button: deviceCss({
    all: {
      font: 'body.medium',
      marginTop: '12px',
      paddingY: '6px',
      lineHeight: 'body.large.lineHeight',
    },
    mobile: {
      // Mobile only
      boxShadow: 'none',
      height: '100%',
      width: '100%',
    },
  }),
  title: css({
    font: 'heading.small',
  }),
  body: css({
    font: 'body.small',
  }),
  select: css({
    marginY: 'medium',
  }),
  brandingHeader: css({
    stack: 'x',
    alignY: 'center',
    bottom: 'space.0',
    gap: 'small',
    paddingBottom: 'large',
  }),
  brandingHeaderText: css({
    maxWidth: '240px',
    minWidth: 'space.0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
};

const {useEffect, useRef} = React;

const AutoCompleteButton = ({pending, withIcon, onClick}: ButtonProps) => (
  <Button
    onClick={onClick}
    pending={pending}
    id="testing-submit-autocomplete-button"
    data-testid="testing-submit-autocomplete-button"
    type="primary"
    uses={[Styles.button]}
  >
    <Message {...messages.autocompleteSectionButton} />
  </Button>
);

const ManualButton = ({pending, onClick}: ButtonProps) => (
  <Button
    pending={pending}
    onClick={onClick}
    id="testing-submit-manual-button"
    data-testid="testing-preview-button"
    type="primary"
    uses={[Styles.button]}
  >
    <Message {...messages.manualSectionButton} />
  </Button>
);

const VerifyTestingPage = () => {
  const needsRedirect = Storage.getLivemode();
  const {appController, appState} = useAppController();

  useEffect(() => {
    // Run these at least once
    postIframeEvent('load');
    // To ensure proper behavior when entering test mode,
    // ensure previous queries and mutation responses cached are deleted
    appController.resetApolloStore();
  }, [appController]);

  useEffect(() => {
    if (needsRedirect) {
      appController.routeToPath('/verify_welcome', {redirect: true});
    }
  }, [needsRedirect, appController, appState.session]);

  if (appState.testMode.error) {
    const body = <NetworkErrorNotice error={appState.testMode.error} />;
    return <PageCard body={body} />;
  }

  return <TestingPageCard />;
};

export const TestingPageCard = () => {
  const {appController, appState} = useAppController();

  const usableOptions: AutoCompleteValues = getUsableOptions(
    appController.state,
  );
  const startPageRedirectDone = useRef(false);

  useEffect(() => {
    if (startPageRedirectDone.current) {
      return;
    }
    analytics.track('startPageRedirectDone', {
      timeStartRedirectDone: Date.now(),
      startPageRedirectedTo: window.location.pathname,
    });
    startPageRedirectDone.current = true;
  });

  const header = (
    <TestingBanner
      titleMessage={messages.testmodeHeaderTitle}
      descriptionMessage={messages.testmodeHeaderDescription}
    />
  );
  const body = (
    <>
      <view.div uses={[Styles.card]}>
        <view.div uses={[Styles.content]}>
          <view.div uses={[Styles.title]}>
            <Message {...messages.autocompleteSectionTitle} />
          </view.div>
          <view.div uses={[Styles.body]}>
            <Message {...messages.autocompleteSectionDescription} />
          </view.div>
          <Select
            label="autocompleteSetting"
            size="large"
            id="autocomplete-setting-select"
            data-testid="autocomplete-setting-select"
            value={appState.testMode.autocompleteSetting}
            onChange={(e) =>
              appController.setTestModeAutocompleteSetting(
                e.target.value as AutocompleteOptions,
              )
            }
            hiddenElements={['label']}
            uses={[Styles.select]}
          >
            {usableOptions.map((testOption) => (
              <view.option
                key={testOption.value}
                value={testOption.value}
                data-testid={testOption.message.id.replaceAll('.', '-')}
              >
                {testOption.message.defaultMessage}
              </view.option>
            ))}
          </Select>
          <AutoCompleteButton
            onClick={appController.handleTestModeSubmitAutocomplete}
            pending={appState.testMode.autocompletePending}
          />
        </view.div>
      </view.div>
      <view.div uses={[Styles.card]}>
        <view.div uses={[Styles.content]}>
          <view.div uses={[Styles.title]}>
            <Message {...messages.manualSectionTitle} />
          </view.div>
          <view.div uses={[Styles.body]}>
            <Message {...messages.manualSectionDescription} />
          </view.div>
          <ManualButton
            onClick={appController.handleTestModeSubmitManual}
            pending={appState.testMode.manualPending}
          />
        </view.div>
      </view.div>
    </>
  );

  const footer = (
    <view.div uses={[Styles.footer, Styles.footerResponsive]}>
      <FooterLinksSection />
    </view.div>
  );

  return <PageCard header={header} body={body} footer={footer} />;
};

setComponentConfig(VerifyTestingPage, {skipApp: true, v2: true});

export default VerifyTestingPage;
