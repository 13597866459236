// we use (string & {}) to prevent types from narrowing to string literals
/* eslint-disable @typescript-eslint/ban-types */

import {hash, isToken, walkTokens} from '@sail/engine';
import type {Style, Token} from '@sail/engine';
import {tokens} from '../../tokens';
import {property} from './util';
import {setFontMetrics} from '../plugins/pluginsFont';
import type {FontPresetSchema, TypefaceSchema} from '../../tokens';

// FontPresetKeys is the union of the keys of font tokens that are 'full' presets,
// i.e. they include at least `size` and `lineHeight`.
export type FontPresetKeys = Token.FilterKey<
  typeof tokens.font,
  {size: string; lineHeight: string}
>;

// FontFamilyPresetKeys is the union of the keys of font tokens that have a `family` property.
// This is a superset of FontPresetKeys, since that's true of all font tokens.
// This covers 'monospace', which has `family` and nothing else.
export type FontFamilyPresetKeys = Exclude<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Token.FilterKey<typeof tokens.font, {family: any}>,
  ''
>;

type FontFamilyInput = {
  [K in keyof TypefaceSchema]:
    | TypefaceSchema[K]
    | Token.Value<TypefaceSchema[K]>;
};

type FontPresetToken = Token.Values<FontPresetSchema>;

const fontTokens = {} as Record<FontFamilyPresetKeys, FontPresetToken>;

walkTokens(tokens.font, (value, path) => {
  fontTokens[path as FontFamilyPresetKeys] =
    value as unknown as FontPresetToken;
});

const extractSubProperty =
  <T = FontPresetKeys | (string & {})>(
    property: keyof FontPresetSchema,
    formatter: (v: T) => T = (v) => v,
  ) =>
  (value: T) =>
    (
      fontTokens[value as FontPresetKeys]?.[property] ?? formatter(value)
    )?.toString();

export const fontSize = property([tokens.font]).decorate(
  extractSubProperty<FontPresetKeys | (string & {}) | number>('size', (v) =>
    typeof v === 'number' ? `${v}px` : v,
  ),
);

export const textTransform = property([tokens.font]).decorate(
  extractSubProperty('transform'),
);

export const lineHeight = property([tokens.font]).decorate(
  extractSubProperty<FontPresetKeys | (string & {}) | number>('lineHeight'),
);

export type FontWeightKeys = Token.Key<typeof tokens.font.weight>;

// weight is a special case because it's a sub-property of font presets, but
// it's also a top-level property of tokens.font
const weightTokens = {} as Record<FontWeightKeys, string>;

walkTokens(tokens.font.weight, (value, path) => {
  weightTokens[path as FontWeightKeys] = value.toString();
});

export const fontWeight = property([tokens.font]).decorate(
  (value: FontPresetKeys | FontWeightKeys | (string & {}) | number) =>
    weightTokens[value as FontWeightKeys] ??
    extractSubProperty('weight')(value as FontPresetKeys | (string & {})),
);

export const fontFamily = property([tokens.font]).decorate(
  (
    value: FontFamilyPresetKeys | (string & {}) | FontFamilyInput,
    set: Style.PluginAPI,
  ): string => {
    if (!value) {
      return 'inherit';
    }

    const fontMetric = property().accept<number>();

    let token;

    if (typeof value === 'string') {
      // if the user passes a string, try to get the family via its path
      token = fontTokens[value as FontFamilyPresetKeys]?.family;
      if (!token?.typeface) {
        // if there was no corresponding token, or the retrieved token wasn't a family,
        // return the passed string
        return value;
      }
    } else {
      // otherwise use the passed token
      token = value;
    }

    const fontFamilyValue = token.typeface.toString();
    setFontMetrics(
      set,
      fontMetric(token.unitsPerEm),
      fontMetric(token.ascender),
      fontMetric(token.capHeight),
      fontMetric(token.xHeight),
      fontMetric(token.descender),
      fontMetric(token.lineGap),
      hash(fontFamilyValue),
    );
    return fontFamilyValue;
  },
);

function createFontProperty(): (
  value: FontPresetKeys,
  set: Style.PluginAPI,
) => void {
  function isFontPreset(value: unknown): value is FontPresetToken {
    const v = value as FontPresetToken;
    return isToken(v?.size) && isToken(v?.lineHeight);
  }

  const fontPresetTokens = {} as Record<FontPresetKeys, FontPresetToken>;
  walkTokens(tokens.font, (value, path) => {
    if (isFontPreset(value)) {
      fontPresetTokens[path as FontPresetKeys] = value;
    }
  });

  return function font(value, set) {
    const token =
      typeof value === 'string'
        ? fontPresetTokens[value as FontPresetKeys]
        : value;

    if (!token) {
      return;
    }

    function setFontProperties(
      fontSize: string | undefined,
      lineHeight: string | undefined,
      fontWeight: string | undefined,
      fontFamily: string | undefined,
      textTransform: string | undefined,
    ) {
      set.property('fontSize', fontSize);
      set.property('lineHeight', lineHeight);
      if (token.weight) {
        set.property('fontWeight', fontWeight);
      }
      if (token.family) {
        set.property('fontFamily', fontFamily);
      }
      if (token.transform) {
        set.property('textTransform', textTransform);
      }
    }

    // Clear existing font properties
    setFontProperties(undefined, undefined, undefined, undefined, undefined);

    // Batch preset font properties
    set.property('--font', () => {
      setFontProperties(
        fontSize(value),
        lineHeight(value),
        fontWeight(value),
        fontFamily(value, set),
        token.transform?.toString(),
      );
    });
  };
}

export const font = createFontProperty();
