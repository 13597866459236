/* eslint-disable @typescript-eslint/no-explicit-any */
import {Trie} from './Trie';

export default function shallowMemoize<Fn extends (...args: any[]) => any>(
  makeData?: Fn,
): Fn {
  const trie = new Trie(
    true,
    makeData ? (args) => makeData(...args) : undefined,
  );
  return function () {
    // eslint-disable-next-line prefer-rest-params
    return trie.lookupArray(arguments);
  } as Fn;
}
