import {css} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import BottomSheet, {
  Messages as BottomSheetMessages,
} from 'gelato/frontend/src/components/BottomSheetV2';
import Button from 'gelato/frontend/src/components/ButtonV2';
import Heading from 'gelato/frontend/src/components/HeadingV2';
import Link from 'gelato/frontend/src/components/LinkV3';
import ListItem from 'gelato/frontend/src/components/ListItemV2';
import List from 'gelato/frontend/src/components/ListV2';
import Message from 'gelato/frontend/src/components/Message';
import {STRIPE_DATA_DELETION_REQUEST_URL} from 'gelato/frontend/src/lib/constants';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const Styles = {
  h2: css({
    font: 'heading.large',
  }),
  secondaryLink: css({
    textDecorationColor: '#99a5b8',
    font: 'body.small',
    fontWeight: 'normal',
  }),
};

// Messages for the welcome page.
// Sort alphabetically by id.
const Messages = defineMessages({
  biometrics: {
    defaultMessage: 'Biometrics',
    description: 'Reference to biometric data',
    id: 'verification.biometricVerificationSheet.biometrics',
  },
  biometricsDataShareability: {
    defaultMessage:
      'Stripe will not share your biometrics with {platformName}.',
    description: 'Explanation of the shareability of biometric data',
    id: 'verification.biometricVerificationSheet.biometricsDataShareability',
  },
  biometricsDescription: {
    defaultMessage:
      'Stripe will identify you using your biometrics for only one year. ' +
      'You can {optoutLink} at any time.',
    description: 'Explanation of how biometric data is used',
    id: 'verification.biometricVerificationSheet.biometricsDescription',
  },

  linkBiometricsDescription: {
    defaultMessage:
      'Link will identify you using your biometrics for only one year. ' +
      'You can {optoutLink} at any time.',
    description: 'Explanation of how biometric data is used',
    id: 'verification.biometricVerificationSheet.biometricsDescriptionForLink',
  },
  howWeVerifyYou: {
    defaultMessage: 'How we verify you',
    description: 'Description of how the verification process works',
    id: 'verification.biometricVerificationSheet.howWeVerifyYou',
  },
  identityVerificationService: {
    defaultMessage:
      "We leverage Stripe's own verification service to verify your identity " +
      'through the information and any images you provide.',
    description:
      "Explanation of how Stripe's identity verification service works",
    id: 'verification.biometricVerificationSheet.identityVerificationService',
  },

  identityVerificationServiceForLink: {
    defaultMessage:
      "We leverage Link's own verification service to verify your identity " +
      'through the information and any images you provide.',
    description:
      "Explanation of how Link's identity verification service works",
    id: 'verification.biometricVerificationSheet.identityVerificationServiceForLink',
  },

  optout: {
    defaultMessage: 'opt out',
    description: 'Link text to opt out of biometric data collection',
    id: 'verification.biometricVerificationSheet.optout',
  },
  stripeTechnology: {
    defaultMessage: 'Stripe technology',
    description: 'Information about the technology used by Stripe',
    id: 'verification.biometricVerificationSheet.stripeTechnology',
  },
  linkTechnology: {
    defaultMessage: 'Link technology',
    description: 'Information about the technology used by Link',
    id: 'verification.biometricVerificationSheet.linkTechnology',
  },
  thirdPartyPartners: {
    defaultMessage: 'Third-party partners',
    description: 'Reference to third-party partners involved',
    id: 'verification.biometricVerificationSheet.thirdPartyPartners',
  },
  trustedPartners: {
    defaultMessage:
      'We work with trusted partners ' +
      ' (e.g., document issuers, phone verification vendors and authorized record holders) ' +
      'to collect information about you to verify your identity and to improve how Stripe and our partners fight fraud.',
    description:
      'Explanation of working with trusted partners for identity verification',
    id: 'verification.biometricVerificationSheet.trustedPartners',
  },

  trustedPartnersLink: {
    defaultMessage:
      'We work with trusted partners ' +
      ' (e.g., document issuers, phone verification vendors and authorized record holders) ' +
      'to collect information about you to verify your identity and to improve how Link and our partners fight fraud.',
    description:
      'Explanation of working with trusted partners for identity verification',
    id: 'verification.biometricVerificationSheet.trustedPartnersForLink',
  },
});

// TODOs:
// - Add analytics.

/**
 * The bottom sheet that displays information about the biometric
 * verification process and policies.
 */
export default function BiometricVerificationSheet(): JSX.Element {
  const {appController, appState} = useAppController();
  const requiredFields = appState.session?.requiredFields;
  const platformName = getBrandingPlatformName(appState.session?.branding);
  const isStripe = appState.session?.branding?.isStripe;

  const needsSelfie = requiredFields?.includes('face');

  const onClose = () => appController.closeLayer(BiometricVerificationSheet);

  const items = [
    <ListItem key="stripeTechnology">
      <Heading level={3}>
        <Message {...Messages.stripeTechnology} />
      </Heading>
      <Message {...Messages.identityVerificationService} />
    </ListItem>,
    <ListItem icon="moved" key="thirdPartyPartners">
      <Heading level={3}>
        <Message {...Messages.thirdPartyPartners} />
      </Heading>
      <Message {...Messages.trustedPartners} />
    </ListItem>,
  ];

  if (needsSelfie) {
    let shareabilityMessage;
    if (!isStripe) {
      shareabilityMessage = (
        <>
          <Message
            {...Messages.biometricsDataShareability}
            values={{platformName}}
          />{' '}
        </>
      );
    }

    const optoutLink = (
      <Link
        uses={[Styles.secondaryLink]}
        type="secondary"
        target="_blank"
        href={STRIPE_DATA_DELETION_REQUEST_URL}
      >
        <Message {...Messages.optout} />
      </Link>
    );

    items.push(
      <ListItem icon="createIdentityVerification" key="biometrics">
        <Heading level={3}>
          <Message {...Messages.biometrics} />
        </Heading>
        {shareabilityMessage}
        <Message {...Messages.biometricsDescription} values={{optoutLink}} />
      </ListItem>,
    );
  }

  const footer = (
    <Button onPress={onClose} type="primary">
      <Message {...BottomSheetMessages.close} />
    </Button>
  );

  return (
    <BottomSheet data-testid="biometric-verification-sheet" footer={footer}>
      <Heading level={2} uses={[Styles.h2]}>
        <Message {...Messages.howWeVerifyYou} />
      </Heading>
      <List>{items}</List>
    </BottomSheet>
  );
}
