/**
 * Get the active video track from a video stream.
 */
export default function getActiveVideoTrack(
  stream: MediaStream,
): MediaStreamTrack | null {
  const videoTracks = stream.getVideoTracks();
  const activeTrack = videoTracks.filter(
    (track) => track.readyState === 'live',
  )[0];
  return activeTrack || null;
}
