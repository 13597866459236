import {clearDataFieldsMutation} from 'gelato/frontend/src/graphql/mutations/useClearDataFieldsMutation';

// eslint-disable-next-line @sail/data-no-imperative-methods
import type {ApolloClient, FetchResult} from '@apollo/client';
import type {
  ClearDataFieldsMutationVariables,
  ClearDataFieldsMutationData,
} from 'gelato/frontend/src/graphql/mutations/useClearDataFieldsMutation';

/**
 * Clears the data fields of the document.
 * @param apolloClient The apollo client.
 * @param variables The mutation variables.
 */
export default async function clearDataFieldsMutationMethod(
  apolloClient: ApolloClient<object>,
  variables: ClearDataFieldsMutationVariables,
): Promise<FetchResult<ClearDataFieldsMutationData>> {
  // eslint-disable-next-line @sail/data-no-imperative-methods
  return apolloClient.mutate({
    mutation: clearDataFieldsMutation,
    variables,
  });
}
