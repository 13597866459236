import {getCountries} from 'libphonenumber-js';
import type {PhoneNumberFieldProps} from './PhoneNumberField';
import {createViewConfig} from '@sail/engine';
const COUNTRIES = getCountries();

export const PhoneNumberFieldConfig = createViewConfig({
  props: {} as PhoneNumberFieldProps,
  name: 'PhoneNumberField',
  flattens: true,
  defaults: {
    countryAllowlist: COUNTRIES,
    size: 'medium',
    defaultCountry: 'US',
  },
});
