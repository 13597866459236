import {compile} from 'stylis';
import {
  addRulesetAfter,
  cloneDeclaration,
  cloneDeclarations,
  createInheritedProperty,
  createVariableProperty,
} from '../core';
import type {StylePlugin, StylisElement} from '../core';

// TODO(koop): These should lazily compile.
// Content area is based on definition of font height in Chromium:
//   https://github.com/chromium/chromium/blob/d7da0240cae77824d1eda25745c4022757499131/third_party/blink/renderer/platform/fonts/font_metrics.h#L136-L144
const fontMetricsDeclarations = compile(`
  --font-unit: calc(var(--font-size) / var(--units-per-em));
  --cap-height: calc(var(--cap-height-metric) * var(--font-size));
  --x-center: calc(var(--x-height) / 2);
  --cap-center: calc(var(--cap-height) / 2);
  --content-area: calc(var(--ascender) - var(--descender));
  --half-leading: calc((var(--line-height) - var(--content-area)) / 2);
  --baseline: calc(var(--half-leading) + var(--ascender));
`);

const alignWithDeclarations = compile(`
  --baseline-diff: calc(var(--baseline-inherited) - var(--baseline));
  margin-top: calc(var(--baseline-diff) - var(--padding-top, 0));
  margin-bottom: max(0, calc(
    var(--line-height-inherited) - var(--line-height) - var(--baseline-diff) -
      var(--padding-bottom, 0)
  ));
  align-self: baseline;
`);

// We conditionally set content based on whether we’re in flow layout.
// https://css-tricks.com/the-css-custom-property-toggle-trick/
const leadingTrimBeforeDeclarations = compile(`
  content: var(--when-flow-inherited, ) "";
  display: table;
  margin-bottom: var(--text-edge-over, 0);
`);

const leadingTrimAfterDeclarations = compile(`
  content: var(--when-flow-inherited, ) "";
  display: table;
  margin-top: var(--text-edge-under, 0);
`);

const textEdgeOverMap: Record<string, string> = {
  cap: 'calc(var(--cap-height) - var(--ascender) - var(--half-leading))',
  ex: 'calc(var(--x-height) - var(--ascender) - var(--half-leading))',
  text: 'calc(-1 * var(--half-leading))',
};
const textEdgeUnderMap: Record<string, string> = {
  alphabetic: 'calc(var(--descender) - var(--half-leading))',
  text: 'calc(-1 * var(--half-leading))',
};

function fontUnitDeclaration(
  element: StylisElement,
  property: string,
  value: string,
) {
  return cloneDeclaration(
    element,
    property,
    `calc(${value} * var(--font-unit))`,
  );
}

export const font: StylePlugin = {
  properties: {
    '--line-height': createInheritedProperty('--line-height'),
    '--baseline': createInheritedProperty('--baseline'),
    '--cap-height': createInheritedProperty('--cap-height'),
    '--cap-center': createInheritedProperty('--cap-center'),
    '--x-height': createInheritedProperty('--x-height'),
    '--x-center': createInheritedProperty('--x-center'),
    '--when-flow': createInheritedProperty('--when-flow'),

    'cap-size'(element) {
      return [
        cloneDeclaration(
          element,
          'font-size',
          `calc(${element.children} / var(--cap-height-metric))`,
        ),
      ];
    },

    'font-size': createVariableProperty('font-size'),

    'line-height'(element) {
      const customProperty = '--line-height';
      const contents = `calc(var(--line-height) * var(--line-height-scale, 1))`;
      const children = element.children as string;
      if (children === contents) {
        return;
      }
      const decl = cloneDeclaration(element, 'line-height', contents);
      if (children === 'inline') {
        return [decl, cloneDeclaration(element, '--line-height-scale', '0')];
      }
      return [decl, cloneDeclaration(element, customProperty, children)];
    },

    'font-metrics'(element) {
      const children = element.children as string;
      const parts = children.trim().split(/\s+/g);

      const isInvalid = parts.length < 5 || parts.length > 6;
      if (process.env.NODE_ENV === 'development' && isInvalid) {
        // eslint-disable-next-line no-console
        console.warn('Passed incorrect number of values to font-metrics.');

        parts.forEach((part) => {
          if (!part.match(/^-?\d+$/)) {
            // eslint-disable-next-line no-console
            console.warn('Passed invalid value to font-metrics.');
          }
        });
      }

      const [
        unitsPerEm,
        ascender,
        capHeight,
        xHeight,
        descender,
        lineGap = '0',
      ] = parts;

      return [
        cloneDeclaration(element, '--units-per-em', unitsPerEm),
        cloneDeclaration(
          element,
          '--cap-height-metric',
          `calc(${capHeight} / var(--units-per-em))`,
        ),
        fontUnitDeclaration(element, '--ascender', ascender),
        fontUnitDeclaration(element, '--x-height', xHeight),
        fontUnitDeclaration(element, '--descender', descender),
        fontUnitDeclaration(element, '--line-gap', lineGap),
        ...cloneDeclarations(element, fontMetricsDeclarations),
      ];
    },

    'align-with'(element) {
      const declarations = cloneDeclarations(element, alignWithDeclarations);
      if (element.children === 'middle') {
        declarations.push(
          cloneDeclaration(
            element,
            'transform',
            'priority(5) translateY(calc(var(--x-center) - var(--x-center-inherited)))',
          ),
        );
      }
      return declarations;
    },

    // https://www.w3.org/TR/css-inline-3/#leading-trim
    'leading-trim'(element) {
      const children = element.children;
      if (children === 'both' || children === 'start') {
        addRulesetAfter(
          element,
          'leading-trim-before',
          ['&::before'],
          leadingTrimBeforeDeclarations,
        );
      }
      if (children === 'both' || children === 'end') {
        addRulesetAfter(
          element,
          'leading-trim-after',
          ['&::after'],
          leadingTrimAfterDeclarations,
        );
      }
      return [];
    },

    // https://www.w3.org/TR/css-inline-3/#text-edges
    'text-edge'(element) {
      const children = element.children as string;
      const [over = 'leading', under = 'leading'] = children.trim().split(' ');
      const transformed = [];
      const overValue = textEdgeOverMap[over];
      const underValue = textEdgeUnderMap[under];
      if (overValue) {
        transformed.push(
          cloneDeclaration(element, '--text-edge-over', overValue),
        );
      }
      if (underValue) {
        transformed.push(
          cloneDeclaration(element, '--text-edge-under', underValue),
        );
      }
      return transformed;
    },
  },
};
