export const LinkURL = 'https://www.link.com';
export const LinkTermsURL = 'https://link.com/terms';
export const LinkPrivacyUrl = 'https://link.com/privacy';
export const LinkAboutURL = 'https://support.link.com/topics/about-link';

export const LINK_GREEN = '#00D66F';
export const LINK_HOVER_GREEN = '#00C767';
export const LINK_PRESSED_GREEN = '#5EEE97';
export const LINK_DISABLED_GREEN = '#61D36A';
export const LINK_DISABLED_FONT_GREEN = '#357548';

export const LINK_LINK_GREEN = LINK_GREEN;
export const LINK_LINK_HOVER_GREEN = '#00C767';
