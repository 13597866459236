import {isMobilePhone} from 'validator';

import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import updatePhoneMutation from 'gelato/frontend/src/controllers/utils/updatePhoneMutation';

import type {GraphQlField} from '@sail/data';
import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';
import type {UpdatePhoneMutationData} from 'gelato/frontend/src/graphql/mutations/useUpdatePhoneMutation';
import type {LocaleKey} from 'gelato/frontend/src/lib/locale';

// TODOs:
// Remove this once "validator" updates from 13.7.0
function checkCyprus(phone: string): boolean {
  return !!phone.match(/^(\+?357?)?(9(9|6)\d{6})$/);
}

/**
 * Validates the phone number and throws an error message if the phone number
 * is invalid.
 * @param phoneNumber The phone number to validate.
 */
function validatePhoneNumber(phoneNumber: string) {
  if (!phoneNumber) {
    throw new Error(ErrorCode.phoneNumberRequired);
  }

  if (!(isMobilePhone(phoneNumber) || checkCyprus(phoneNumber))) {
    throw new Error(ErrorCode.phoneNumberInvalid);
  }
}

/**
 * Send the handoff url via SMS.
 * @param state The application state.
 * @param runtime The application runtime.
 */
export default async function sendHandoffSMS(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
  data: {phoneNumber: string; locale: LocaleKey | null},
): Promise<GraphQlField<UpdatePhoneMutationData, 'updatePhone', 'session'>> {
  validatePhoneNumber(data.phoneNumber);

  const phoneResp = await updatePhoneMutation(runtime.apolloClient, {
    phoneData: {
      phoneNumber: data.phoneNumber,
    },
  });

  const sessionChanges = phoneResp?.data?.updatePhone.session;

  if (!sessionChanges?.phoneForHandoff) {
    throw new Error(ErrorCode.failedToSendSMS);
  }

  const resp = await runtime.api.mutation.sendSmsMutation({
    variables: {
      locale: data.locale,
    },
  });

  const success = resp?.data?.sendSmsLink.success;
  if (!success) {
    throw new Error(ErrorCode.failedToSendSMS);
  }

  return sessionChanges;
}
