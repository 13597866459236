import {urlRedirect} from '@stripe-internal/safe-links';

import analytics from 'gelato/frontend/src/lib/analytics';
import {DEFAULT_IFRAME} from 'gelato/frontend/src/lib/constants';

export function isInIframe(): boolean {
  // When NextJS does server-side render, `window` is not defined.
  return (process as any).browser ? window.self !== window.top : DEFAULT_IFRAME;
}

type DeprecatedFrameEventTypes = 'load' | 'error' | 'success' | 'reload';
type StripeFrameEventTypes =
  | 'STRIPE_IDENTITY_APP_LOAD'
  | 'STRIPE_IDENTITY_CLOSE'
  | 'STRIPE_IDENTITY_ERROR'
  | 'STRIPE_IDENTITY_SESSION_COMPLETE';
type FrameEventTypes = StripeFrameEventTypes | DeprecatedFrameEventTypes;

export const postIframeEvent = (() => {
  // don't spam the parent -- keep track of lastEvent
  let lastEvent: FrameEventTypes | null = null;

  return (
    type: FrameEventTypes,
    body: {type: string; code: string} | undefined = undefined,
  ) => {
    if (isInIframe() && lastEvent !== type) {
      lastEvent = type;
      let bodyType = body?.type;
      let bodyCode = body?.code;

      if (type === 'STRIPE_IDENTITY_ERROR') {
        bodyType = bodyType || 'unknown_error_type';
        bodyCode = bodyCode || 'unknown_error_code';
      }

      analytics.track('postIframeMessage', {
        iframe: true,
        state: {type, body},
      });

      window.parent.postMessage({type, body}, '*');
    }
  };
})();

// Setting `window.location.href` will cause the window to reload. When gelato
// is rendered in an iframe, this can cause a flash of unstyled content. Notify
// the parent that a reload will happen and only support redirects to "safe"
// links. A safe link is one that does a `postIframeEvent('load')` to notify
// the parent that loading is finished.
type SafeReloadLinks = '/verify_options';

export const reloadWithIframeEvent = (link: SafeReloadLinks) => {
  postIframeEvent('reload');
  urlRedirect(link);
};
