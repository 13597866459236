import * as React from 'react';
import {createViewConfig, view, toIntent} from '@sail/engine';
import type {View} from '@sail/engine';
import {useId, useControlledState} from '@sail/react-aria';
import type {IconAsset} from '@sail/icons/types';
import {Label} from './Label';
import {Icon, IconConfig} from './Icon';
import {tokens} from '../tokens';
import {LinkConfig} from './Link';
import {ActionConfig} from './Action';
import type {FormFieldGroupProps} from './FormFieldGroup';
import {FormFieldGroup, FormFieldGroupConfig} from './FormFieldGroup';
import type {FormFieldProps} from './FormField';

/* -------- Helpers --------*/

const horizontalPadding = {
  small: 'small',
  medium: 'small',
  large: 'medium',
};

const verticalPadding = {
  small: 'xsmall',
  medium: 'xsmall',
  large: 'small',
};

const gap = {
  small: 'space.75',
  medium: 'small',
  large: 'space.150',
};

const actionOverrides = toIntent([
  ActionConfig.customize({
    css: {
      // This ensures actions are clickable and sit above the clickable layer formed by the radio input
      position: 'relative',
    },
  }),
  LinkConfig.customize({
    defaults: {type: 'secondary'},
    css: {color: 'subdued'},
  }),
]);

/* -------- Context --------*/

export const ToggleContext = React.createContext<{
  selectedValue?: string;
  onSelectionChange?: (value: string) => void;
  size: 'small' | 'medium' | 'large';
  id?: string;
  name?: string;
  disabled: boolean;
}>({
  size: 'medium',
  disabled: false,
});

/* -------- Toggle --------*/

export type ToggleProps = FormFieldProps<{
  /**
   * For use with uncontrolled state management.
   */
  defaultValue?: string;
  /**
   * For use with controlled state management.
   */
  selectedValue?: string;
  /**
   * For use with controlled state management. An even which receives the value of the selected ToggleItem.
   */
  onSelectionChange?: (value: string) => void;
  /**
   * Passed to the `name` attribute on the underlying radio inputs which associates them together as a group. A randomly generated ID will be used if nothing is passed.
   */
  name?: string;
  size?: 'small' | 'medium' | 'large';
  layout: FormFieldGroupProps['layout'];
  disabled?: boolean;
}>;

export const ToggleConfig = createViewConfig({
  name: 'Toggle',
  props: {} as ToggleProps,
  defaults: {
    size: 'medium',
    layout: 'horizontal',
    disabled: false,
  },
});

export const Toggle = ToggleConfig.createView(
  ({
    children,
    defaultValue,
    selectedValue,
    onSelectionChange,
    size,
    label,
    hiddenElements,
    disabled = false,
    layout,
    name,
    ...rest
  }) => {
    const [selected, setSelected] = useControlledState(
      selectedValue,
      defaultValue,
      onSelectionChange,
    );
    const groupId = useId();

    return (
      <ToggleContext.Provider
        value={{
          selectedValue: selected,
          onSelectionChange: setSelected,
          size: size || 'medium',
          id: groupId,
          name,
          disabled,
        }}
      >
        <FormFieldGroupConfig.Customize
          subviews={{
            inputs: {
              css: {
                alignX: layout === 'horizontal' ? 'start' : 'stretch',
                gap: size === 'large' ? 'space.150' : 'small',
              },
            },
          }}
        >
          <FormFieldGroup
            legend={label}
            hiddenElements={hiddenElements?.map((element) => {
              if (element === 'label') {
                return 'legend';
              }
              return element;
            })}
            layout={layout}
            {...rest}
          >
            {children}
          </FormFieldGroup>
        </FormFieldGroupConfig.Customize>
      </ToggleContext.Provider>
    );
  },
  {
    css: {
      stack: 'y',
      width: 'fill',
    },
    subviews: {
      inputs: {
        css: {
          marginY: 'xsmall',
          alignY: 'stretch',
          width: 'fill',
        },
      },
    },
    forward: {
      size: true,
    },
  },
);

/* -------- ToggleItem --------*/

export type ToggleItemProps = View.IntrinsicElement<
  'div',
  {
    label: React.ReactNode;
    /**
     * Used as the `value` attribute on the underlying `<input type="radio">` element.
     */
    value: string;
    description?: React.ReactNode;
    disabled?: boolean;
    icon?: IconAsset;
    subviews?: View.Subviews<{
      icon: typeof Icon;
      label: typeof Label;
      description: 'div';
      input: 'input';
    }>;
  }
>;

export const ToggleItemConfig = createViewConfig({
  name: 'ToggleItem',
  props: {} as ToggleItemProps,
  defaults: {
    disabled: false,
  },
});

export const ToggleItem = ToggleItemConfig.createView(
  ({
    label,
    value,
    description,
    disabled,
    children,
    icon,
    subviews,
    ...rest
  }) => {
    const {
      selectedValue,
      onSelectionChange,
      size,
      id: groupId,
      disabled: disabledByParent,
      name,
    } = React.useContext(ToggleContext);
    const id = useId();
    const isSelected = value === selectedValue;

    const isDisabled = disabledByParent || disabled;

    return (
      <view.div
        data-checked={isSelected}
        data-disabled={isDisabled}
        css={{
          paddingX: horizontalPadding[size],
          paddingY: verticalPadding[size],
          gap: gap[size],
          font:
            // eslint-disable-next-line no-nested-ternary
            size === 'large'
              ? 'label.large'
              : size === 'medium'
              ? 'label.medium'
              : 'label.small',
          backgroundColor: isDisabled ? 'offset' : 'surface',
        }}
        uses={[
          IconConfig.customize({
            defaults: {size: size === 'large' ? 'small' : 'xsmall'},
          }),
        ]}
        {...rest}
      >
        <view.input
          type="radio"
          id={id}
          value={value}
          checked={isSelected}
          onChange={(e) =>
            onSelectionChange && onSelectionChange(e.target.value)
          }
          // Need a name attribute to group radio inputs together, which enables out-of-the-box keyboard interactions
          name={name || groupId}
          disabled={isDisabled}
          inherits={subviews.input}
        />

        {icon ? (
          <Icon
            icon={icon}
            inherits={subviews.icon}
            /*
              When set to `stack: x`, the height of ToggleItems much match the height of the Button and other form elements in their respective size. This means we can't adjust the padding/font size.

              When set to `stack: y`, the icon in the ToggleBoxItem is too close to the top border. We use a margin here to account for this. This margin is not visible in the horizontal orientation because of our alignment system.
            */
            css={{marginTop: 'xsmall'}}
          />
        ) : null}

        <view.div css={{width: 'fill'}}>
          <Label
            htmlFor={id}
            css={{
              font:
                // eslint-disable-next-line no-nested-ternary
                size === 'large'
                  ? 'label.large.emphasized'
                  : size === 'medium'
                  ? 'label.medium.emphasized'
                  : 'label.small.emphasized',
            }}
            inherits={subviews.label}
          >
            {label}
          </Label>
          {description ? (
            <view.div
              css={{
                font: size === 'large' ? 'body.medium' : 'body.small',
              }}
              inherits={subviews.description}
            >
              {description}
            </view.div>
          ) : null}

          {children ? (
            <view.div uses={[actionOverrides]}>{children}</view.div>
          ) : null}
        </view.div>
      </view.div>
    );
  },
  {
    css: {
      stack: 'x',
      position: 'relative',
      borderRadius: 'action',
      color: 'subdued',
      transition: 'all 0.2s',
      ':hover:not([data-disabled=true])': {
        backgroundColor: 'offset',
      },
      ':hover[data-checked=true]:not([data-disabled=true])': {
        backgroundColor: tokens.color.brand[25],
      },
      keyline: 'border',
      keylineWidth: 1,
      '[data-checked=true]': {
        color: 'action.primary',
        keyline: 'action.primary',
        keylineWidth: 2,
      },
      '[data-disabled=true]': {
        color: 'action.primary.disabled',
      },
    },
    subviews: {
      label: {
        css: {
          display: 'block',
          cursor: 'pointer',
          color: 'subdued',
          '[data-checked=true] &': {
            color: 'action.primary',
          },
          '[data-disabled=true] &': {
            color: 'action.primary.disabled',
          },
        },
      },
      description: {
        uses: [actionOverrides],
        css: {
          color: 'subdued',
        },
      },
      input: {
        css: {
          appearance: 'none',
          position: 'absolute',
          inset: 'space.0',
          margin: 'space.0',
          width: 'fill',
          height: 'fill',
          cursor: 'pointer',
          borderRadius: 'action',
          ':focus': {
            focusRing: 'focus',
          },
          ':disabled': {
            cursor: 'default',
          },
        },
      },
    },
  },
);
