import {defineMessages} from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'consent.header',
    description: 'page title',
    defaultMessage: 'We are going to verify your identity',
  },
  informedInternalSelfie: {
    id: 'consent.informed.internal.selfie',
    description: 'Informing of use for selfie check for Stripe',
    defaultMessage:
      'We would like to use images from your device to confirm your identity. We will capture ' +
      'images of you and your ID and check that they are the same person. ' +
      'We will store these images and the results of these checks.',
  },
  informedExternalSelfie: {
    id: 'consent.informed.external.selfie',
    description:
      'Informing of use for selfie check for external merchant who has not provided a privacy policy',
    defaultMessage:
      'We would like to use images from your device to confirm your identity. We will capture ' +
      'images of you and your ID and check that they are the same person. ' +
      'We will store these images and the results of these checks and share them with {platformName}.',
  },
  informedExternalSelfieWithPP: {
    id: 'consent.informed.external.selfie.pp',
    description:
      'Informing of use for selfie check for external merchant who has provided a privacy policy',
    defaultMessage:
      'We would like to use images from your device to confirm your identity. We will capture ' +
      'images of you and your ID and check that they are the same person. ' +
      'We will store these images and the results of these checks and share them with {platformName}. ' +
      'For more information regarding {platformName} use of your information, see their <PrivacyPolicy>privacy policy</PrivacyPolicy>.',
  },

  informedInternalIdentityDocument: {
    id: 'consent.informed.internal.identity_document',
    description: 'Informing of use for identity document check for Stripe',
    defaultMessage:
      'We would like to use images from your device to confirm your identity. We will capture images ' +
      'of your ID and check that the ID is valid. We will store these images and the results of this ' +
      'check.',
  },
  informedExternalIdentityDocument: {
    id: 'consent.informed.external.identity_document',
    description:
      'Informing of use for identity document check for external merchant who has not provided a privacy policy',
    defaultMessage:
      'We would like to use images from your device to confirm your identity. We will capture images ' +
      'of your ID and check that the ID is valid. We will store these images and the results of this ' +
      'check and share them with {platformName}.',
  },
  informedExternalIdentityDocumentWithPP: {
    id: 'consent.informed.external.identity_document.pp',
    description:
      'Informing of use for identity document check for external merchant who has provided a privacy policy',
    defaultMessage:
      'We would like to use images from your device to confirm your identity. We will capture images ' +
      'of your ID and check that the ID is valid. We will store these images and the results of this ' +
      'check and share them with {platformName}. For more information regarding {platformName} use of your information, ' +
      'see their <PrivacyPolicy>privacy policy</PrivacyPolicy>.',
  },
  informedInternalIdentityDocumentAndSelfie: {
    id: 'consent.informed.internal.identity_document_and_selfie',
    description:
      'Informing of use for identity document and selfie check for Stripe',
    defaultMessage:
      'We would like to use images from your device to confirm your identity. We will capture ' +
      'images of you and your ID and check that they are the same person and that the ID is valid. ' +
      'We will store these images and the results of these checks.',
  },
  informedExternalIdentityDocumentAndSelfie: {
    id: 'consent.informed.external.identity_document_and_selfie',
    description:
      'Informing of use for identity document and selfie check for external merchant who has not provided a privacy policy',
    defaultMessage:
      'We would like to use images from your device to confirm your identity. We will capture ' +
      'images of you and your ID and check that they are the same person and that the ID is valid. ' +
      'We will store these images and the results of these checks and share them with {platformName}.',
  },
  informedExternalIdentityDocumentAndSelfieWithPP: {
    id: 'consent.informed.external.identity_document_and_selfie.pp',
    description:
      'Informing of use for identity document and selfie check for external merchant who has provided a privacy policy',
    defaultMessage:
      'We would like to use images from your device to confirm your identity. We will capture ' +
      'images of you and your ID and check that they are the same person and that the ID is valid. ' +
      'We will store these images and the results of these checks and share them with {platformName}. ' +
      'For more information regarding {platformName} use of your information, see their <PrivacyPolicy>privacy policy</PrivacyPolicy>.',
  },

  withdraw: {
    id: 'consent.withdraw',
    description:
      "Text informing user that they can withdraw consent at any time and providing links to contact us and Stripe's privacy policy.",
    defaultMessage:
      'Allow Stripe to use your images and results to improve our services, ' +
      'including our biometric verification technology (<learnMore>learn more</learnMore>). ' +
      'You can withdraw your consent at any time by <contact>contacting us</contact>. ' +
      "For more information, please see Stripe's <privacyPolicy>privacy policy</privacyPolicy>.",
  },
  grantFullConsentButton: {
    id: 'consent.button.improve',
    description:
      'Text on button granting consent for full use of images for verification and improving our services.',
    defaultMessage: 'Allow full use', // 'Continue and allow full use of images',
  },
  grantJustVerifyConsentButton: {
    id: 'consent.button.just_verify',
    description:
      'Text on button granting allowing use of images for verification purposes only',
    defaultMessage: 'Verification only', // 'Continue but only use images for verification',
  },
  declineConsentButtonInternal: {
    id: 'consent.button.decline.internal',
    description:
      'Text on button declining to grant use of images for verification when verifying for Stripe',
    defaultMessage: "No, don't verify",
  },
  declineConsentButtonExternal: {
    id: 'consent.button.decline.external',
    description:
      'Text on button declining to grant use of images for verification when verifying for an external merchant',
    defaultMessage: 'No, go back to {platformName}',
  },
  declineConsentButtonExternalNoReturnUrl: {
    id: 'consent.button.decline.external.no_return_url',
    description:
      'Text on button declining to grant use of images for verification when verifying for an external merchant when no return_url provided',
    defaultMessage: "No, don't verify",
  },
  howStripeWillVerify: {
    id: 'consent.header.howStripeWillVerify',
    description: 'Page title for consent permission page',
    defaultMessage: 'How Stripe will verify your identity',
  },
  contactingStripe: {
    id: 'consent.language.contactingStripe',
    description: 'Text for an email link ',
    defaultMessage: 'contacting Stripe',
  },
  stripeFaqLink: {
    id: 'consent.language.stripeFaqLink',
    description: 'Text for the link to Stripe FAQ',
    defaultMessage: 'You can learn more in the FAQ',
  },
  biometricVerification: {
    id: 'consent.language.biometricVerification',
    description:
      "Language informing the user about how Stripe will use data to verify the person's identity",
    defaultMessage:
      'Stripe will use biometric technology (on images of you and your IDs), as well as other data sources and our service providers (such as document issuer or authorised record holder), to confirm your identity, and for fraud and security purposes. ' +
      'You can subsequently opt-out. {stripePrivacyLink}. ' +
      'Stripe will store the data it collects and the results of this check and share them with {platformName}.',
  },
  biometricVerificationConnect: {
    id: 'consent.language.biometricVerificationConnect',
    description:
      "Language informing the user about how Stripe will use data to verify the person's identity",
    defaultMessage:
      'Stripe will use biometric technology (on images of you and your IDs), as well as other data sources and our service providers (such as document issuer or authorised record holder), to confirm your identity, and for fraud and security purposes. ' +
      'You can subsequently opt-out. {stripePrivacyLink}. ' +
      'Stripe will store the data it collects and the results of this check and share them with {platformName} and {additionalPlatformName}.',
  },
  biometricVerificationInternal: {
    id: 'consent.language.biometricVerificationInternal',
    description:
      "Language informing the user about how Stripe will use data to verify the person's identity",
    defaultMessage:
      'Stripe will use biometric technology (on images of you and your IDs), as well as other data sources and our service providers (such as document issuer or authorised record holder), to confirm your identity, and for fraud and security purposes. ' +
      'You can subsequently opt-out. {stripePrivacyLink}.',
  },
  biometricVerificationInternalSharingDeprecated: {
    id: 'consent.language.biometricVerificationInternalSharing',
    description:
      "Language informing the user about how Stripe will share data to verify the person's identity",
    defaultMessage: `Stripe will store and use these images, other data collected, and the results of this check and share it with your merchant, in accordance with [Stripe's Privacy Policy](https://stripe.com/privacy)`,
  },
  biometricVerificationInternalSharingUpdated: {
    id: 'consent.language.biometricVerificationInternalSharingUpdated',
    description:
      "Language informing the user about how Stripe will share data to verify the person's identity",
    defaultMessage: `Stripe will store and use these images, other data collected, and the results of this check in accordance with [Stripe's Privacy Policy](https://stripe.com/privacy)`,
  },
  learnAboutIdentity: {
    id: 'consent.langauge.learnAboutIdentity',
    description: "Link to Stripe Identity API's privacy documentation",
    defaultMessage: 'Learn how Stripe Identity works',
  },
  accept: {
    id: 'consent.button.accept',
    description:
      "Button text to agree to grant Stripe permissions to use the user's data for identity verification",
    defaultMessage: 'Accept and continue',
  },
  decline: {
    id: 'consent.button.decline',
    description:
      "Button text to decline to grant Stripe permissions to use the user's data for identity verification",
    defaultMessage: "No, don't verify",
  },
});

export default messages;
