import type {Point} from 'gelato/frontend/src/ML/IDDetectorAPI';

export function getMidPatch(
  topLeft: Point,
  dimensions: Point,
  patchSize: number,
): Point {
  // Location of ID
  const [w, h] = dimensions;
  const [idX, idY] = topLeft;

  // Take a patch in the middle of the ID of size min(w, h)
  const x = idX + w / 2 - patchSize / 2;
  const y = idY + h / 2 - patchSize / 2;
  return [x, y];
}

export default getMidPatch;
