import {view} from '@sail/ui';
import * as React from 'react';
import {useIntl} from 'react-intl';

import Button from 'gelato/frontend/src/components/ButtonV2';
import Footer from 'gelato/frontend/src/components/Link/BottomSheet/Footer';
import LinkHeader from 'gelato/frontend/src/components/Link/BottomSheet/LinkHeader';
import LinkButton from 'gelato/frontend/src/components/Link/LinkButton';
import {messages} from 'gelato/frontend/src/components/Link/messages';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

export default function AreYouSure() {
  const {appState, appController} = useAppController();
  const intl = useIntl();
  const handleBackToLink = () => {
    const {bottomSheetPreviousStep} = appState.networkedIdentity;
    appController.setLinkSaveIdBottomStep(bottomSheetPreviousStep);
  };
  const handleContinueWithoutLink = async () => {
    appController.setConsumerAccountLoading(true);
    await appController.skipNetworking();
    appController.setConsumerAccountLoading(false);
  };
  return (
    <>
      <LinkHeader title={intl.formatMessage(messages.areYouSure)} />
      <view.span>
        {intl.formatMessage(messages.areYouSureDescription)}
      </view.span>
      <Footer>
        <LinkButton onPress={handleBackToLink} data-testid="link-back">
          {intl.formatMessage(messages.backToLink)}
        </LinkButton>
        <Button
          type="secondary"
          onPress={handleContinueWithoutLink}
          pending={appState.networkedIdentity.consumerAccountLoading}
          data-testid="link-continue-without-link"
        >
          {intl.formatMessage(messages.continueWithoutLink)}
        </Button>
      </Footer>
    </>
  );
}
