import {isMobileDevice} from 'gelato/frontend/src/lib/device';

import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

export function isUnsupportedCountry(
  state: Readonly<ApplicationState>,
): boolean {
  const {session} = state;
  return session?.unsupportedCountry === true;
}

export function isUnsupportedHandoffDevice(
  state: Readonly<ApplicationState>,
): boolean {
  const {session} = state;
  const {operatingMode, fMobile} = session || {};

  return operatingMode === 'secondary' && !!fMobile && !isMobileDevice();
}

export function isUnsupportedDevice(
  state: Readonly<ApplicationState>,
): boolean {
  const {session} = state;

  return session?.lastErrorCode === 'device_unsupported';
}
