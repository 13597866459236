import * as React from 'react';
import {defineMessages} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import ThemableToggleBox from 'gelato/frontend/src/components/ThemableToggleBox';
import {ToggleBoxItem} from 'sail/ToggleBox';

import styles from './DocumentTypeBox.module.css';

import type {DocumentTypes} from '@stripe-internal/data-gelato/schema/types';
import type {MessageDescriptor} from 'react-intl';

export const messages: Partial<Record<DocumentTypes, MessageDescriptor>> =
  defineMessages({
    passport: {
      id: 'document_type_toggle_box.document_type.label.passport',
      description: 'Passport',
      defaultMessage: 'Passport',
    },
    id_card: {
      id: 'document_type_toggle_box.document_type.label.id_card',
      description: 'Identity document card',
      defaultMessage: 'Identity card',
    },
    driving_license: {
      id: 'document_type_toggle_box.document_type.label.driver_licence',
      description: "Driver's license",
      defaultMessage: "Driver's license",
    },
  });

export const DOCUMENT_TYPES = [
  'driving_license',
  'id_card',
  'passport',
] as const;

type Props = {
  documentTypeOptions: ReadonlyArray<DocumentTypes>;
  value: DocumentTypes | null | undefined;
  onChange: (document: DocumentTypes) => Promise<void>;
};

class DocumentTypeBox extends React.Component<Props> {
  render() {
    const {documentTypeOptions, onChange, value} = this.props;
    return (
      <ThemableToggleBox
        className={styles.toggleBox}
        direction="vertical"
        layout="inline"
        name="id-select"
        connected={false}
      >
        {documentTypeOptions.map((choice) => {
          const selected = value === choice;
          return (
            <ToggleBoxItem
              checked={selected}
              label={<Message {...messages[choice]} />}
              key={choice}
              id={choice}
              onChange={() => {
                onChange(choice);
              }}
            />
          );
        })}
      </ThemableToggleBox>
    );
  }
}

export default DocumentTypeBox;
