import type {EnforcementMode} from '@stripe-internal/stripe-cookies';

export type AnalyticsConfig = {
  // Your service's client_id parameter, as configured in client_config.yaml
  // This is the only required config parameter.
  clientId: string;

  inProduction?: boolean; // default true

  /**
   * By default, events are sent to AEL (r.stripe.com/0) in production and to qa-r.stripe.com/0 in QA.
   *
   * @deprecated This property can be used to force sending events to the different tracking URL's, instead of AEL.
   * Any analytics endpoint other than AEL is deprecated and should not be used. q.stripe.com and t.stripe are unsupported.
   */
  overrideTrackingUrl?: {
    prod: string; // default r.stripe.com/0
    qa: string; // default qa-r.stripe.com/0
  };

  // True only for parameters that are "safe" (cannot contain PII). Used for redaction.
  safeParams?: {
    [key: string]: boolean;
  };

  // newCookies uses refactored setConfigCookieData() that widens the domain for merchant
  // disabled will not allow reading from cookies
  useCookies?: 'disabled' | 'newCookies'; // default newCookies

  // Represents the allow-listed cookies that can be set in Analytics-JS
  cookies?: {
    merchant?: string;
    user?: string;
  };

  // Caches the analytics ID for performance
  memoizeCookieID?: boolean; // default true

  // Cookies enforcement mode is usually set by the stripe-cookies library.
  // If manually overriding this config option, also notify #privacy-products.
  // Read http://go/cookies for more information.
  stripeCookiesEnforcementMode?: EnforcementMode; // default based on geolocation

  // Not a full enumeration of all Google Analytics properties, just the subset used here.
  googleAnalytics?: {
    isLoggedIn?: boolean;
  };
  googleAnalyticsFlags?: {
    [key: string]: boolean;
  };

  // Enables batched requests to AEL's batching endpoint, r.stripe.com/b
  batchSettings?: {
    enableBatching?: boolean;
    batchSize?: number; // optional parameter for batch size, since each client receives varying levels of traffic and requests per second
  };

  // config.maxPageLength sets the maximum number of characters for the page field on tracked events
  // Because of limitations in the analytics pipeline, very long events can be dropped by nginx (and cause other problems
  // on pages using analytics-js). Truncating the page field is a short-term fix to avoid this issue.
  // See https://jira.corp.stripe.com/browse/DASH-7802 and https://jira.corp.stripe.com/browse/DASH-7781
  // Events are POSTed to AEL (r.stripe.com), so this field is only necessary when publishing to q|t.stripe.com
  maxPageLength?: number;

  // Adds a prefix to the events logged to the endpoint
  namespace?: string;

  version?: string;
  locale?: string;
  pageviewtoken?: string;

  // Enables sessionization
  sessionization?: boolean;
};

// Default values for the AnalyticsReporter constructor
export const defaultAnalyticsConfig: AnalyticsConfig = {
  clientId: '',
  inProduction: true,
  useCookies: 'newCookies',
  memoizeCookieID: true,
  maxPageLength: 150,
  version: '',
  locale: '',
  namespace: '',
  pageviewtoken: '',
  stripeCookiesEnforcementMode: undefined,
  sessionization: false,
};

// Analytics Event Logger Endpoint
export const prodUrl = 'https://r.stripe.com/0';
export const qaUrl = 'https://qa-r.stripe.com/0';

// Analytics Event Logger Batched Endpoint
export const batchProdUrl = 'https://r.stripe.com/b';
export const batchQaUrl = 'https://qa-r.stripe.com/b';
