import {ButtonGroup, view, css, Divider} from '@sail/ui';
import * as React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import BottomSheet from 'gelato/frontend/src/components/BottomSheetV2';
import Button from 'gelato/frontend/src/components/ButtonV2';
import ConfirmCancelVerificationSheet from 'gelato/frontend/src/components/ConfirmCancelVerificationSheet';
import Link from 'gelato/frontend/src/components/LinkV2';
import LocaleSelector from 'gelato/frontend/src/components/LocaleSelectorV2';
import Message from 'gelato/frontend/src/components/Message';
import {
  alignItemsCenter,
  mB200,
  tCenter,
  tColorDestructive,
  tColorSubdued,
  tGrey,
  tSmall,
  transformOriginCenter,
} from 'gelato/frontend/src/components/stylesV2';
import {
  STRIPE_CONTACT_URL,
  STRIPE_DATA_SECURITY_PRIVACY_SERVICES_AGREEMENT_URL,
  STRIPE_PRIVACY_POLICY_URL,
} from 'gelato/frontend/src/lib/constants';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import {Logo as LegacySailStripeLogo} from 'sail/Logo';

import type {IntlProps} from 'gelato/frontend/src/components/Message';

const {useCallback} = React;

// Sort alphabetically by id.
const Messages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel verification',
    description: 'Button label for cancelling verification button',
    id: 'verification.sideMenuSheet.cancel',
  },
  continue: {
    defaultMessage: 'Continue verification',
    description: 'Button label for continuing verification button',
    id: 'verification.sideMenuSheet.continue',
  },
  poweredBy: {
    defaultMessage: 'Powered by {brandName}',
    description:
      'Attribution text for the brand powering the verification flow',
    id: 'verification.sideMenuSheet.poweredBy',
  },
  privacy: {
    defaultMessage: 'Privacy',
    description: 'Link text for the privacy link',
    id: 'verification.sideMenuSheet.privacy',
  },
  support: {
    defaultMessage: 'Support',
    description: 'Link text for the support link',
    id: 'verification.sideMenuSheet.support',
  },
  terms: {
    defaultMessage: 'Terms',
    description: 'Link text for the terms link',
    id: 'verification.sideMenuSheet.terms',
  },
});

const Styles = {
  divider: css({
    marginY: 'large',
  }),
  links: css({
    alignX: 'center',
    alignY: 'center',
    gap: 'medium',
    marginY: 'small',
    stack: 'x',
  }),
  poweredBy: css({
    alignX: 'center',
    alignY: 'top',
    gap: 'xsmall',
    stack: 'x',
  }),
  section: css({
    marginY: 'medium',
  }),
  stripeLogo: css({
    position: 'relative',
    top: 'space.1',
    transform: 'scale(0.8)',
  }),
};
/**
 * The flyout sheet that lets user perform actions from the side menu.
 */
const SideMenuSheetWithIntl = injectIntl((props: IntlProps): JSX.Element => {
  const {appController, appState} = useAppController();

  const handleContinue = useCallback(() => {
    appController.closeAllLayers();
  }, [appController]);

  const handleCancel = useCallback(() => {
    appController.openLayer(ConfirmCancelVerificationSheet);
  }, [appController]);

  let cancelButton;

  if (appState.session?.operatingMode === 'primary') {
    // Only show the cancel button if the session is in primary window.
    // If the session is in secondary window, user must return the primary
    // window to cancel the verification. This is to prevent having stale
    // primary window that keeps polling for the status of the secondary
    // window that is already abandoned.
    cancelButton = (
      <Button borderless onPress={handleCancel} type="secondary">
        <view.span uses={[tColorDestructive]}>
          <Message {...Messages.cancel} />
        </view.span>
      </Button>
    );
  }

  return (
    <BottomSheet data-testid="side-menu-sheet">
      <view.div uses={[Styles.section]}>
        <LocaleSelector />
      </view.div>
      <view.div uses={[Styles.section, Styles.divider]}>
        <Divider />
      </view.div>
      <view.div uses={[Styles.section, mB200]}>
        <ButtonGroup direction="column">
          <Button onPress={handleContinue} type="primary">
            <Message {...Messages.continue} />
          </Button>
          {cancelButton}
        </ButtonGroup>
      </view.div>
      <view.div
        uses={[
          alignItemsCenter,
          tCenter,
          tGrey,
          tColorSubdued,
          tSmall,
          Styles.poweredBy,
        ]}
      >
        <Message
          {...Messages.poweredBy}
          values={{
            brandName: (
              <view.div uses={[Styles.stripeLogo, transformOriginCenter]}>
                <LegacySailStripeLogo color="gray" />
              </view.div>
            ),
          }}
        />
      </view.div>
      <view.div uses={[Styles.links, tSmall]}>
        <Link href={STRIPE_DATA_SECURITY_PRIVACY_SERVICES_AGREEMENT_URL}>
          <Message {...Messages.terms} />
        </Link>
        <Link href={STRIPE_CONTACT_URL}>
          <Message {...Messages.support} />
        </Link>
        <Link href={STRIPE_PRIVACY_POLICY_URL}>
          <Message {...Messages.privacy} />
        </Link>
      </view.div>
    </BottomSheet>
  );
});

export default function SideMenuSheet(): JSX.Element {
  return <SideMenuSheetWithIntl />;
}
