import {hasConsentValue} from 'gelato/frontend/src/controllers/states/SessionState';
import isEmailOTPDeclined from 'gelato/frontend/src/controllers/utils/isEmailOTPDeclined';
import isPhoneOTPDeclined from 'gelato/frontend/src/controllers/utils/isPhoneOTPDeclined';
import {
  isUnsupportedCountry,
  isUnsupportedDevice,
  isUnsupportedHandoffDevice,
} from 'gelato/frontend/src/controllers/utils/supportability';
import {isSessionExpired} from 'gelato/frontend/src/lib/sessionError';

import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

// It's important that these camel case keys match the params in
// https://git.corp.stripe.com/stripe-internal/pay-server/blob/master/gelato/frontend/src/lib/analytics/AnalyticsSafeParams.ts
// Since we track these states in our analytics.
export enum InvalidStateEnums {
  unsupportedIpCountry = 'unsupportedIpCountry',
  consentDeclined = 'consentDeclined',
  emailOTPDeclined = 'emailOTPDeclined',
  phoneOTPDeclined = 'phoneOTPDeclined',
  isExpired = 'isExpired',
  sanctionedDocumentCountry = 'sanctionedDocumentCountry',
  underConsentAge = 'underConsentAge',
  deviceUnsupported = 'deviceUnsupported',
  unsupportedHandoffDevice = 'unsupportedHandoffDevice',
  unsupportedCountry = 'unsupportedCountry',
}

export type InvalidState = keyof typeof InvalidStateEnums;

export function getInvalidStates(
  state: Readonly<ApplicationState>,
): Record<InvalidState, boolean> {
  const {session} = state;

  // Default to allowing sessions where IP supportability is unknown
  const ipCountrySupportable = session?.ipCountrySupportable ?? true;

  return {
    unsupportedIpCountry: !ipCountrySupportable,
    consentDeclined: hasConsentValue(state, false),
    emailOTPDeclined: !!session && isEmailOTPDeclined(state),
    phoneOTPDeclined: !!session && isPhoneOTPDeclined(state),
    isExpired: isSessionExpired(),
    sanctionedDocumentCountry: session?.sanctionedDocumentCountry === true,
    underConsentAge: session?.underConsentAge === true,
    deviceUnsupported: !!session && isUnsupportedDevice(state),
    unsupportedHandoffDevice: !!session && isUnsupportedHandoffDevice(state),
    unsupportedCountry: !!session && isUnsupportedCountry(state),
  };
}

export const makeInvalidState = (
  val: InvalidState,
): Record<InvalidState, boolean> => {
  const base = {
    unsupportedIpCountry: false,
    consentDeclined: false,
    emailOTPDeclined: false,
    phoneOTPDeclined: false,
    isExpired: false,
    sanctionedDocumentCountry: false,
    underConsentAge: false,
    deviceUnsupported: false,
    unsupportedHandoffDevice: false,
    unsupportedCountry: false,
  };
  base[val] = true;
  return base;
};

export const makeGenericInvalidState = (): Record<InvalidState, boolean> => {
  return {
    unsupportedIpCountry: false,
    consentDeclined: false,
    emailOTPDeclined: false,
    phoneOTPDeclined: false,
    isExpired: false,
    sanctionedDocumentCountry: false,
    underConsentAge: false,
    deviceUnsupported: false,
    unsupportedHandoffDevice: false,
    unsupportedCountry: false,
  };
};
