/**
 * A set that contains all canvas 2d context instances that should be cleared
 * manually.
 */
const canvas2DContextSet: Set<CanvasRenderingContext2D> = new Set();

/**
 * Patch the canvas's API to mitigate the memory leak.
 * The patch would allow developer to force the canvas context to be cleared
 * manually.
 * @returns A function to restore the original canvas API.
 */
export function addCanvasMemoryLeakPatch(): () => void {
  const originalGetContext = HTMLCanvasElement.prototype.getContext;

  const getContextPatched: typeof originalGetContext =
    function getContextPatched(this: HTMLCanvasElement, id, opts) {
      const ctx: any = originalGetContext.call(this, id, opts);
      if (ctx && id === '2d') {
        // Store the canvas 2d context and we'd clear them manually later.
        canvas2DContextSet.add(ctx);
      }
      return ctx;
    };

  // Patch the canvas's API to mitigate the memory leak.
  HTMLCanvasElement.prototype.getContext = getContextPatched;

  // Return a function to restore the original canvas API.
  return () => {
    HTMLCanvasElement.prototype.getContext = originalGetContext;
  };
}

/**
 * Clear the canvas context to mitigate the memory leak.
 * @param context The canvas 2d context to clear.
 */
export function clearCanvasRenderingContext2D(
  context: CanvasRenderingContext2D,
) {
  // Setting the width and height to 1 effectively clears the canvas context.
  // Avoid setting the width or height to 0, as it can cause the canvas context
  // to be detached from its canvas, resulting in a memory leak.
  context.canvas.width = 1;
  context.canvas.height = 1;
  context.clearRect(0, 0, 1, 1);
}

/**
 * Clear all canvas 2d context stored to mitigate the memory leak.
 */
export function clearCanvasMemoryLeak() {
  for (const context of canvas2DContextSet) {
    clearCanvasRenderingContext2D(context);
  }
  canvas2DContextSet.clear();
}
