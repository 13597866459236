/**
 * @file Constants for IDCapture and IDDetector.
 */

// --- IDCapture ---

import {getMajorIOSVersion} from 'gelato/frontend/src/lib/userAgent';

// Default padding to use when cropping IDs. Pretty large since ID Detector V1 has issues.
export const DEFAULT_ID_CROP_PADDING = 5;

// MicroBlink plausibility models thresholds
export const MB_FRONT_START_THRESHOLD = 0.85;
export const MB_BACK_START_THRESHOLD = 0.85;

// This matches IOS Devices (iPhone8+) that are good enough to capture high
// quality image.
export const IOS_DEVICE_WITH_HIGH_QUALITY_CAMERA = getMajorIOSVersion() >= 14;

// Typically iPhones produce blur scores 10-25.
export const IOS_BLUR_THRESHOLD = 5.0;

// Minimum plausibility score when "high quality" flag is set.
export const MB_FRONT_END_HIGH_QUALITY_THRESHOLD = 0.6;

export const MB_FRONT_END_THRESHOLD = 0.4;

// Low back threshold since back is hard to collect and not strictly needed.
export const MB_BACK_END_THRESHOLD = 0.3;

// We still use simple blur model with very low thresholds- MB models seem
// confused by this.
// Use higher threshold since typically iPhones produce blur scores 10-25.
export const BACK_BLUR_THRESHOLD =
  (process.env.PUPPETEER && 0.1) ||
  (IOS_DEVICE_WITH_HIGH_QUALITY_CAMERA && IOS_BLUR_THRESHOLD) ||
  5.0;

// The lower back side blur score threshold for the experiment
// 'lower_back_side_blur_score_threshold'
// The value was chosen based on the results of the query
// https://hubble.corp.stripe.com/queries/hedger/ebf7f920 where the threshold
// is good enough to provide clear images for verified documents.
export const BACK_BLUR_THRESHOLD_EXPERIMENT =
  (process.env.PUPPETEER && 0.1) || 2.0;

// If the back is not MB decodable, then we use a *higher* blur threshold
// This seems weird, but since we don't check MB score, we need *some* focus
// metric, so we can just use the normal blur score with a low threshold
// to ensure images aren't terrible
export const BACK_NO_MB_THRESHOLD = 5.0;

// Docs with scores <0.8 get verified
// Use higher threshold since typically iPhones produce blur scores 10-25.
export const FRONT_BLUR_THRESHOLD =
  (process.env.PUPPETEER && 0.1) ||
  (IOS_DEVICE_WITH_HIGH_QUALITY_CAMERA && IOS_BLUR_THRESHOLD) ||
  5.0;

// Images with a darkness score higher than this are considered dark.
export const DARKNESS_THRESHOLD = 0.85;

// IMPORTANT: after running an experiment, this threshold is now
// used across the app. If this value is decreased, then the "forceHighQuality"
// threshold will be lower than the base
export const HIGH_QUALITY_BLUR_THRESHOLD = 5.0;

// On Android, low quality auto-capture indicates the wrong camera is being used.
export const ANDROID_BLUR_THRESHOLD = 5.0;

// Min auto capture front blur threshold
export const AUTO_FRONT_BLUR_THRESHOLD = 5.0;

// SSD ID detector threshold for assuming a document is present.
export const PROBABILTY_THRESHOLD = 0.5;

// SSD ID detector minmum threshold
export const MINIMUM_PROBABILTY_THRESHOLD = 0.4;

// The minimum probability threshold for a ID card to be considered present.
export const MINIMUM_ID_CARD_FRONT_PROBABILTY_THRESHOLD = 0.5;

// The minimum probability threshold for a passport to be considered present.
export const MINIMUM_PASSPORT_FRONT_PROBABILTY_THRESHOLD = 0.5;

// SSD ID detector threshold for what denotes a strong prediction.
export const STRONG_IDDETECTOR_PREDICTION_THRESHOLD = 0.75;

// Time to wait for document to focus in autocapture experiment
export const AUTOCAPTURE_FOCUS_WAIT_TIME_MS = process.env.PUPPETEER
  ? 1000
  : 3000;

// Time to wait for document to focus.
export const FOCUS_WAIT_TIME_MS = 8 * 1000;
export const IMPROVE_LIGHTING_WAIT_TIME_MS = 8 * 1000;
export const MAX_TIME_SINCE_DOCUMENT_DETECTED = 16 * 1000;
export const MAX_TIME_FOR_AUTO_CAPTURE = 40 * 1000;

// Microblink uses 480 as the minimum size- docs below this are generally very hard to read.
export const MINIMUM_AXIS_DOCUMENT_PIXELS = 480;

export const MAX_ANALYTICS_REPORTS = 600;

export const MAX_VARIANCE_SCORES = 3;

// --- IDDetector---

export const BLUR_THRESHOLD = 250;
export const BLUR_IMAGE_SIZE = 224;
export const MB_IMAGE_SIZE = 200;
export const IMAGE_SIZE = 224;
export const SUPPORT_IMAGE_SIZE = 256;

// If the max dimension is larger than this, image will be resized when computing a darkness score
export const LIGHT_DETECTOR_MAX_DIMENSION = 224;
// The upper bound of what is considered a "dark pixel" when computing a darkness score
export const LIGHT_DETECTOR_LUMINANCE_UPPER_BOUND = 0.5;

// If height/width > CONST then consider this a vertically oriented doc
export const VERTICAL_ASPECT_THRESHOLD = 1.1;

// --- Butter 2.0 ---

/**
 * The duration to wait for the frontside of a document to first be detected.
 */
export const FRONT_TIMEOUT_DURATION = 60000;
/**
 * The duration added to the auto capture timeout period when a document is detected.
 * The document might not pass detectors at this point.
 */
export const FRONT_DOCUMENT_DETECTED_TIMEOUT_EXTENSION = 15000;
/**
 * The duration added to the auto capture timeout period when a document has started sampling with
 * the detectors.
 */
export const FRONT_DOCUMENT_SAMPLING_TIMEOUT_EXTENSION = 15000;
/**
 * The duration to wait for the backside of a document to first be detected. This is longer than
 * the front timeout because we have already captured a frontside, but we don't extend the backside.
 */
export const BACK_TIMEOUT_DURATION = 60000;

/**
 * The dimensions of the image that the fraud model expects.
 */
export const DOC_FRAUD_MODEL_IMAGE_MIN_WIDTH = 576;
export const DOC_FRAUD_MODEL_IMAGE_MIN_HEIGHT = 448;
