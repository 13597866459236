import {createSetIntentType, getIntents} from '../../intent';
import {addIntentsToProps} from './props';
import type {Intent, IntentMap} from '../../intent';
import type {PropsTransform} from './types';

export const onComponentWillReceiveProps = createSetIntentType(
  'mapProps',
  (decorate) =>
    <Props = never>(
      render: PropsTransform<Props>,
    ): PropsTransform<Props> & Intent<Props> => {
      return decorate(render);
    },
);

export function runPropTransforms(props: IntentMap): void {
  const transforms = getIntents(onComponentWillReceiveProps, props);

  transforms?.forEach((callback) => {
    addIntentsToProps(props, callback(props));
  });
}
