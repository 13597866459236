import * as React from 'react';

import {
  BREAKPOINTS,
  DEFAULT_BREAKPOINT,
} from 'gelato/frontend/src/lib/constants';
import {ViewportSizeListener} from 'sail/contrib/Responsive';

export type Breakpoint = 'mobile' | 'desktop';
type Props = {
  children: (breakpoint: Breakpoint) => React.ReactElement;
};

const ViewportSizeListenerWrapper = ({children}: Props) => {
  const childrenWrapper = (breakpoint?: Breakpoint | null) => {
    return children(breakpoint || DEFAULT_BREAKPOINT);
  };

  return (process as any).browser ? (
    // @ts-expect-error - TS4104 - The type 'readonly [{ readonly name: "mobile"; readonly breakpoint: WidthAtOrBelowBreakpoint; }, { readonly name: "desktop"; readonly breakpoint: WidthAboveBreakpoint; }]' is 'readonly' and cannot be assigned to the mutable type 'Breakpoints<"mobile" | "desktop">'.
    <ViewportSizeListener breakpoints={BREAKPOINTS}>
      {childrenWrapper}
    </ViewportSizeListener>
  ) : (
    children(DEFAULT_BREAKPOINT)
  );
};

export default ViewportSizeListenerWrapper;
