import {prepareVerificationSessionForConsumerNetworkingMutation} from 'gelato/frontend/src/graphql/mutations/usePrepareVerificationSessionForConsumerNetworkingMutation';

// eslint-disable-next-line @sail/data-no-imperative-methods
import type {ApolloClient, FetchResult} from '@apollo/client';
import type {
  PrepareVerificationSessionForConsumerNetworkingMutationVariables,
  PrepareVerificationSessionForConsumerNetworkingMutationData,
} from 'gelato/frontend/src/graphql/mutations/usePrepareVerificationSessionForConsumerNetworkingMutation';

/**
 * Performs the mutation that prepares the verification session for consumer networking.
 * @param apolloClient The apollo client.
 * @param variables The mutation variables.
 */
export default async function prepareVerificationSessionForConsumerNetworkingMutationMethod(
  apolloClient: ApolloClient<object>,
  variables: PrepareVerificationSessionForConsumerNetworkingMutationVariables,
): Promise<
  FetchResult<PrepareVerificationSessionForConsumerNetworkingMutationData>
> {
  // eslint-disable-next-line @sail/data-no-imperative-methods
  return apolloClient.mutate({
    mutation: prepareVerificationSessionForConsumerNetworkingMutation,
    variables,
  });
}
