import {sleep} from 'gelato/frontend/src/api/functions/sleep';
import {request} from 'gelato/frontend/src/api/request';
import {SerializableObject} from 'gelato/frontend/src/api/serializable';
import {getStripeApiHost} from 'gelato/frontend/src/lib/urlConfig';

import type {
  GenericStripeError,
  RequestOptions,
  HttpMethod,
  DefaultFetchResult,
} from 'gelato/frontend/src/api/request';

const DEFAULT_OPTS = Object.freeze({});

type RequestConfig = {
  retries: {
    shouldRetry: (error: GenericStripeError) => boolean;
    maxAttempts: number;
    delay?: number;
  };
};

export const apiRequest = (
  path: string,
  method: HttpMethod,
  data: SerializableObject,
  opts: RequestOptions = DEFAULT_OPTS,
  config?: RequestConfig,
): Promise<DefaultFetchResult> => {
  const host = getStripeApiHost();
  const url = `${host}${path}`;

  const options = {...opts};
  // When retries are desired, ensure error status is returned from the request
  if (config?.retries) {
    options.includeErrorStatus = true;
  }

  return request(url, method, data, options).then(
    (requestResponse: DefaultFetchResult) => {
      const {type} = requestResponse;

      if (type === 'error' && config?.retries) {
        const shouldRetry =
          config.retries.maxAttempts > 1 &&
          config.retries.shouldRetry(requestResponse.error);

        if (!shouldRetry) {
          return requestResponse;
        }

        return sleep(config.retries?.delay || 1000).then(() =>
          apiRequest(path, method, data, options, {
            ...config,
            retries: {
              ...config.retries,
              // Decrement maxAttempts recursively
              maxAttempts: config.retries.maxAttempts - 1,
            },
          }),
        );
      }

      return requestResponse;
    },
  );
};
