import React, {useEffect, useState} from 'react';
import {createKeyframes, createView, createViewConfig} from '@sail/engine';
import type {IconAsset, SvgType} from '@sail/icons/types';
import type {View} from '../view';
import type {IconProps} from './Icon';
import {Icon} from './Icon';
import {css} from '../css';

const fadeIn = createKeyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const rotate = createKeyframes({
  0: {
    transform: 'scaleX(-1) rotateZ(0)',
  },
  50: {
    transform: 'scaleX(-1) rotateZ(-180deg)',
  },
  100: {
    transform: 'scaleX(-1) rotateZ(-360deg)',
  },
});

/**
 * @external
 */
export interface SpinnerProps extends Omit<IconProps, 'icon' | 'size'> {
  /**
   * Delay applied to animation
   * @external
   */
  delay?: number;

  /**
   * Size of Spinner component
   * @external
   */
  size?: 'small' | 'medium' | 'large';
}

const SpinnerAsset = createView((props: View.IntrinsicElement<'svg'>) => {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1 1)" fillRule="nonzero">
        <circle cx="11" cy="11" r="11" fill="none" />
        <path
          d="M10.998 22a.846.846 0 0 1 0-1.692 9.308 9.308 0 0 0 0-18.616 9.286 9.286 0 0 0-7.205 3.416.846.846 0 1 1-1.31-1.072A10.978 10.978 0 0 1 10.998 0c6.075 0 11 4.925 11 11s-4.925 11-11 11z"
          fill="inherit"
        />
      </g>
    </svg>
  );
});

const spinnerIcon: IconAsset = {
  category: 'icon',
  colorable: true,
  name: 'spinner',
  svg: SpinnerAsset as SvgType,
};

export const SpinnerConfig = createViewConfig({
  props: {} as SpinnerProps,
  name: 'Spinner',
  defaults: {
    size: 'medium',
  },
});

/**
 * @external
 */
export const Spinner = SpinnerConfig.createView(
  ({delay, ...props}) => {
    const [shown, setShown] = useState(!delay);

    useEffect(() => {
      if (delay) {
        const timeout = setTimeout(() => {
          setShown(true);
        }, delay);

        return () => clearTimeout(timeout);
      }
    }, [delay, setShown]);

    if (!shown) {
      return null;
    }

    // TODO: SAIL-3215 no hardcoded strings
    return (
      <Icon role="status" aria-label="Loading" {...props} icon={spinnerIcon} />
    );
  },
  {
    css: {
      animation: `${fadeIn} 250ms normal ease, ${rotate} .7s linear infinite`,
      transitionProperty: 'opacity, transform',
      transitionTimingFunction: 'ease',
      transformOrigin: '50% 50%',
      fill: 'subdued',
      baseline: 'cap-middle object-middle',
    },
    variants: {
      size: {
        small: css({
          height: 'space.150',
          width: 'space.150',
        }),
        medium: css({
          height: 'medium',
          width: 'medium',
        }),
        large: css({
          height: 'large',
          width: 'large',
        }),
      },
    },
  },
);
