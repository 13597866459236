import {useEffect} from 'react';

// This side-effect that prevents pull-to-refresh on mobile.
// This is done by blocking the touchmove event. Note that this also blocks
// the native drag-to-scroll behavior on mobile, developers should use the hook
// `useDragToScrollGesture()` to enable drag-to-scroll on mobile explicitly.
export default function usePullToRefreshBlocker(): void {
  useEffect(() => {
    const el = document.documentElement;
    if (el) {
      // To make testing easier, we block the touchmove event for both mobile
      // and desktop devices.
      const block = (e: Event) => e.preventDefault();
      el.addEventListener('touchmove', block, {passive: false});
      return () => {
        el.removeEventListener('touchmove', block, false);
      };
    }
  }, []);
}
