import {createTokens} from '@sail/engine';

/**
 * A typeface and its corresponding font metrics.
 *
 * To determine font metrics, run a font file through [ttx](https://fonttools.readthedocs.io/en/latest/ttx.html), a script provided by [fonttools](https://fonttools.readthedocs.io/en/latest/), and look up the data that corresponds
 * to each property below.
 */
export type TypefaceSchema = {
  /**
   * The font families to be applied. Metrics should be provided for the most common typeface.
   *
   * In the future, we will be able to normalize font sizes with the upcoming
   * `font-size-adjust` CSS property: https://w3c.github.io/csswg-drafts/css-fonts-5/#propdef-font-size-adjust
   */
  typeface: string;
  /**
   * Size of the typeface’s cap height.
   *
   * Specified in font tables as:
   *   HEAD unitsPerEm
   */
  unitsPerEm: number;
  /**
   * Size of the typeface’s ascender.
   *
   * Specified in font tables as:
   *   OS/2 sTypoAscender
   *   HHEA ascent
   * https://drafts.csswg.org/css-inline-3/#ascent-descent
   * https://github.com/w3c/csswg-drafts/issues/5485
   *
   * While the spec says to prefer OS/2, this may change because some platforms
   * use HHEA. Consider recording both values if they differ.
   * https://github.com/w3c/csswg-drafts/issues/5485#issuecomment-771065783
   */
  ascender: number;
  /**
   * Size of the typeface’s cap height.
   *
   * Specified in font tables as:
   *   OS/2 sCapHeight
   */
  capHeight: number;
  /**
   * Size of the typeface’s cap height.
   *
   * Specified in font tables as:
   *   OS/2 sxHeight
   */
  xHeight: number;
  /**
   * Size of the typeface’s descender.
   *
   * Specified in font tables as:
   *   OS/2 sTypoDescender
   *   HHEA descent
   * https://drafts.csswg.org/css-inline-3/#ascent-descent
   * https://github.com/w3c/csswg-drafts/issues/5485
   *
   * While the spec says to prefer OS/2, this may change because some platforms
   * use HHEA. Consider recording both values if they differ.
   * https://github.com/w3c/csswg-drafts/issues/5485#issuecomment-771065783
   */
  descender: number;
  /**
   * The default leading added to a typeface.
   * Used for line-height: normal;
   *
   * Specified in font tables as:
   *   OS/2 sTypoLineGap
   *   HHEA lineGap
   *
   * While the spec says to prefer OS/2, this may change because some platforms
   * use HHEA. Consider recording both values if they differ.
   * https://github.com/w3c/csswg-drafts/issues/5485#issuecomment-771065783
   */
  lineGap: number;
};

export type FontPresetSchema = {
  family: TypefaceSchema;
  size: string | number;
  lineHeight: string | number;
  weight: string | number;
  transform?: 'lowercase' | 'none' | 'uppercase';
};

const family = createTokens(
  {path: 'font.family', schema: {} as TypefaceSchema},
  {
    typeface: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    unitsPerEm: 2048,
    ascender: 1980,
    capHeight: 1443,
    xHeight: 1078,
    descender: -432,
    lineGap: 0,
  },
);

const monospaceFamily = createTokens(
  {path: 'font.monospace.family', schema: {} as TypefaceSchema},
  {
    // NOTE(koop): We may want to consider updating this font stack to account
    // for font availability and other operating systems. Source Code Pro isn't
    // installed on many machines, and Menlo and Monaco are typically only
    // installed on macOS (to my knowledge).
    typeface: `'Source Code Pro', Menlo, Monaco, monospace`,
    // Based on installation rates above, the following metrics are for Menlo:
    unitsPerEm: 2048,
    ascender: 1556,
    capHeight: 1493,
    xHeight: 1120,
    descender: -492,
    lineGap: 410,
  },
);

const weight = createTokens('font.weight', {
  light: '300',
  normal: '400',
  semibold: '600',
  bold: '700',
});

const labelFamily = createTokens('font.label.family', family);
const bodyFamily = createTokens('font.body.family', family);
const headingFamily = createTokens('font.heading.family', family);
const displayFamily = createTokens('font.display.family', family);

const displayXlarge = createTokens('font.display.xlarge', {
  family: displayFamily,
  size: '56px', // space[700]
  lineHeight: '64px', // space[800]
  weight: weight.bold,
});

const displayLarge = createTokens('font.display.large', {
  family: displayFamily,
  size: '48px', // space[600]
  lineHeight: '56px', // space[700]
  weight: weight.bold,
});

const displayMedium = createTokens('font.display.medium', {
  family: displayFamily,
  size: '40px', // space[500]
  lineHeight: '48px', // space[600]
  weight: weight.bold,
});

const displaySmall = createTokens('font.display.small', {
  family: displayFamily,
  size: '32px', // space[400]
  lineHeight: '40px', // space[500]
  weight: weight.bold,
});

const headingXlarge = createTokens('font.heading.xlarge', {
  family: headingFamily,
  size: '28px', // space[350]
  lineHeight: '36px', // space[450]
  weight: weight.bold,
  transform: 'none',
});

const headingLarge = createTokens('font.heading.large', {
  family: headingFamily,
  size: '24px', // space[300]
  lineHeight: '32px', // space[400]
  weight: weight.bold,
  transform: 'none',
});

const headingMedium = createTokens('font.heading.medium', {
  family: headingFamily,
  size: '20px', // space[250]
  lineHeight: '28px', // space[350]
  weight: weight.bold,
  transform: 'none',
});

const headingSmall = createTokens('font.heading.small', {
  family: headingFamily,
  size: '16px', // space[200]
  lineHeight: '24px', // space[300]
  weight: weight.bold,
  transform: 'none',
});

const headingXsmall = createTokens('font.heading.xsmall', {
  family: headingFamily,
  size: '12px', // space[150]
  lineHeight: '20px', // space[250]
  weight: weight.bold,
  transform: 'none',
});

const bodyLarge = createTokens('font.body.large', {
  family: bodyFamily,
  size: '18px', // space[225]
  lineHeight: '28px', // space[350]
  weight: weight.normal,
  transform: 'none',
});

const bodyMedium = createTokens('font.body.medium', {
  family: bodyFamily,
  size: '16px', // space[200]
  lineHeight: '24px', // space[300]
  weight: weight.normal,
  transform: 'none',
});

const bodySmall = createTokens('font.body.small', {
  family: bodyFamily,
  size: '14px', // space[175]
  lineHeight: '20px', // space[250]
  weight: weight.normal,
  transform: 'none',
});

const labelLarge = createTokens('font.label.large', {
  family: labelFamily,
  size: '16px', // space[200]
  lineHeight: '24px', // space[300]
  weight: weight.normal,
  transform: 'none',
});

const labelMedium = createTokens('font.label.medium', {
  family: labelFamily,
  size: '14px', // space[175]
  lineHeight: '20px', // space[250]
  weight: weight.normal,
  transform: 'none',
});

const labelSmall = createTokens('font.label.small', {
  family: labelFamily,
  size: '12px', // space[150]
  lineHeight: '16px', // space[200]
  weight: weight.normal,
  transform: 'none',
});

export const font = createTokens('font', {
  family,
  weight,

  monospace: {
    family: monospaceFamily,
  },

  display: {
    family: displayFamily,
    xlarge: {
      $default: displayXlarge,
      subdued: {
        $default: displayXlarge,
        weight: weight.normal,
      },
    },
    large: {
      $default: displayLarge,
      subdued: {
        $default: displayLarge,
        weight: weight.normal,
      },
    },
    medium: {
      $default: displayMedium,
      subdued: {
        $default: displayMedium,
        weight: weight.normal,
      },
    },
    small: {
      $default: displaySmall,
      subdued: {
        $default: displaySmall,
        weight: weight.normal,
      },
    },
  },
  heading: {
    family: headingFamily,
    xlarge: {
      $default: headingXlarge,
      subdued: {
        $default: headingXlarge,
        weight: weight.normal,
      },
    },
    large: {
      $default: headingLarge,
      subdued: {
        $default: headingLarge,
        weight: weight.normal,
      },
    },
    medium: {
      $default: headingMedium,
      subdued: {
        $default: headingMedium,
        weight: weight.normal,
      },
    },
    small: {
      $default: headingSmall,
      subdued: {
        $default: headingSmall,
        weight: weight.normal,
      },
    },
    xsmall: {
      $default: headingXsmall,
      subdued: {
        $default: headingXsmall,
        weight: weight.normal,
      },
    },
  },
  body: {
    family: bodyFamily,
    large: {
      $default: bodyLarge,
      emphasized: {
        $default: bodyLarge,
        weight: weight.semibold,
      },
    },
    medium: {
      $default: bodyMedium,
      emphasized: {
        $default: bodyMedium,
        weight: weight.semibold,
      },
    },
    small: {
      $default: bodySmall,
      emphasized: {
        $default: bodySmall,
        weight: weight.semibold,
      },
    },
  },
  label: {
    family: labelFamily,
    large: {
      $default: labelLarge,
      emphasized: {
        $default: labelLarge,
        weight: weight.semibold,
      },
    },
    medium: {
      $default: labelMedium,
      emphasized: {
        $default: labelMedium,
        weight: weight.semibold,
      },
    },
    small: {
      $default: labelSmall,
      emphasized: {
        $default: labelSmall,
        weight: weight.semibold,
      },
    },
  },
});
