import {shallowMemoize} from '@sail/utils';

import type {DocumentNode} from 'graphql';

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

type DirectiveName = 'tag' | 'hash';

type Output = {
  gql: DocumentNode;
  directives: PartialRecord<DirectiveName, string>;
};

// Please keep this in sync with the list of directives in annotateWithDirectives.js
const directivesToRemove: Array<DirectiveName> = ['tag', 'hash'];

export default shallowMemoize((query: DocumentNode): Output => {
  const output: PartialRecord<DirectiveName, string> = {};

  const gql = {
    ...query,

    definitions: query.definitions.map((definition) => {
      if (!('directives' in definition) || !definition.directives) {
        return definition;
      }

      return {
        ...definition,

        directives: definition.directives.filter((node) => {
          const isRelevantDirective = isInArray(
            directivesToRemove,
            node.name.value,
          );

          if (isRelevantDirective) {
            const id = node.arguments?.find(({name}) => name.value === 'id');

            if (id && id.value.kind === 'StringValue') {
              output[node.name.value] = id.value.value;
            }

            return false;
          }

          return true;
        }),
      };
    }),
  };

  return {gql, directives: output};
});

function isInArray<T extends string>(
  array: ReadonlyArray<T>,
  value: string,
): value is T {
  return array.includes(value as T);
}
