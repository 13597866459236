import {fetch} from 'gelato/frontend/src/api/fetch';

import type {DefaultFetchResult, GenericStripeError} from './types';
import type {FetchResult} from 'gelato/frontend/src/api/fetch';

// Only ones we care about for now:
export type HttpMethod = 'POST' | 'GET' | 'DELETE';

export type RequestOptions = {
  includeErrorStatus?: boolean;
  logResult?: (fetchResult: FetchResult) => void;
  headers?: {
    [key: string]: string;
  };
  // If true, includes the Request-Id response header value, if any,
  // within the FetchResult as the requestId prop.
  // This header is a Stripe-specified value: https://stripe.com/docs/api/request_ids
  // and will not exist for non-Stripe-API requests.
  includeRequestId?: boolean;
};

export const isInternalServerError = ({
  result,
  error,
}: {
  result?: DefaultFetchResult;
  error?: GenericStripeError;
}): boolean => {
  const status =
    (result?.type === 'error' && result?.error?.status) || error?.status;
  return !!status && status >= 500 && status < 600;
};

const DEFAULT_OPTS = Object.freeze({});

export const request = (
  url: string,
  method: HttpMethod,
  data: Record<string, unknown>,
  opts: RequestOptions = DEFAULT_OPTS,
): Promise<DefaultFetchResult> => {
  return fetch({
    url,
    method,
    data,
    headers: opts.headers,
  }).then((fetchResult) => {
    if (opts.logResult) {
      opts.logResult(fetchResult);
    }

    let requestId;
    let result: any = {};
    if (opts.includeRequestId && fetchResult.getResponseHeader) {
      // If the server doesn't include Access-Control-Expose-Headers
      // getResponseHeader will throw.
      // All BAPI requests specify Request-Id as an exposed header
      // for cross-origin requests:
      // https://livegrep.corp.stripe.com/view/stripe-internal/pay-server/api/lib/middleware/cross_origin_requests.rb#L62
      try {
        requestId = fetchResult.getResponseHeader('Request-Id');
      } catch (e) {}
    }

    try {
      result = JSON.parse(fetchResult.responseText);
    } catch {
      result.error = {
        message: 'client generated error: could not parse responseText',
        status: null,
        type: '',
      };
    }

    if (fetchResult.status === 200) {
      return {
        type: 'object',
        object: result,
        ...(requestId ? {requestId} : null),
      };
    } else {
      return {
        type: 'error',
        error: opts.includeErrorStatus
          ? {
              ...result.error,
              status: fetchResult.status || 0,
            }
          : result.error,
        ...(requestId ? {requestId} : null),
      };
    }
  });
};
