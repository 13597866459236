import * as React from 'react';
import {useContext, useMemo, useRef, useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {
  provider,
  useIsomorphicLayoutEffect,
  animate,
  view,
  unprocessedCss,
  createViewConfig,
  usePrevious,
} from '@sail/engine';
import {
  ClassicLayerContext,
  LayerContext,
  useLayer,
  getZIndex,
  getPortalNodeForTesting,
  LAYERS_CONTAINER_DEFAULT_Z,
  LAYERS_CONTAINER_NAME,
  RESERVED_MODAL_LAYER,
} from '@sail/global-state';
import type {
  LayerContextType,
  LayerType,
  LayerDescription,
} from '@sail/global-state';
import type {View} from '../../view';
import {css} from '../../css';
import {LayerInterop} from '../../components/layers/LayerInterop';

export function useCurrentLayer(): LayerContextType | null {
  const layer = useContext(LayerContext);
  return layer == null || layer.type === 'root' ? null : layer;
}

export function useRootLayer(): LayerContextType {
  let layer = useContext(LayerContext);
  while (layer && layer.parent) {
    layer = layer.parent;
  }
  if (layer == null || layer.type !== 'root') {
    throw new Error(
      'No root layer available. Did you wrap your app with <RootLayer>?',
    );
  }
  return layer;
}

function getScrollbarWidth() {
  return window.innerWidth - document.documentElement.clientWidth;
}

type RootLayerProps = View.IntrinsicElement<
  'div',
  {
    containerZ: number;
    mountTarget?: HTMLElement;
    layers?: Array<string | LayerDescription>;
  }
>;

export const RootLayerConfig = createViewConfig({
  name: 'RootLayer',
  props: {} as RootLayerProps,
  element: true,
  defaults: {
    containerZ: LAYERS_CONTAINER_DEFAULT_Z,
  },
});

/**
 * The root layer is responsible for coordinating layers within an application,
 * allowing both adjacent and nested layers to stack in a predictable and
 * logical order.
 */
export const RootLayer = RootLayerConfig.createView(
  ({containerZ, mountTarget, layers: userLayers, ...props}) => {
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const hasParentLayer = useContext(LayerContext) != null;

    // We provide a builtin `toast` isolated layer which sits on top
    // of everything
    const layers = useMemo(() => {
      const builtinLayers = [{name: 'toast', isolated: true}];
      return (userLayers || []).concat(builtinLayers);
    }, [userLayers]);

    const layer = useLayer({type: 'root', container, layers});
    const ref = useRef<HTMLDivElement | null>(null);

    // We need to create a container that we use to mount layers into. This lets
    // us create our own stacking context and explicitly order layers
    useIsomorphicLayoutEffect(() => {
      const container = document.createElement('div');
      container.className = LAYERS_CONTAINER_NAME;
      container.style.zIndex = containerZ.toString();
      container.style.position = 'absolute';
      container.style.top = '0';
      container.style.bottom = '0';
      container.style.left = '0';
      container.style.right = '0';
      container.style.pointerEvents = 'none';

      if (mountTarget) {
        mountTarget.appendChild(container);
      } else {
        document.body.appendChild(container);
      }

      setContainer(container);

      return () => {
        container.remove();
      };
    }, [mountTarget]);

    // Attempt to clean up our global overflow property write
    const initialOverflowProp = useRef<string | null>(null);
    useIsomorphicLayoutEffect(() => {
      // We only write global properties if we are the outermost layer
      if (hasParentLayer) {
        return;
      }

      if (initialOverflowProp.current === null) {
        initialOverflowProp.current = document.body.style.overflow;
      }

      return () => {
        // When unmounted, write back the overflow property that we first read. Note that this
        // can still conflict; if it was changed from underneath us we will restore it back
        // to a different value. There's no way around this; overall only 1 RootLayer should
        // exist across the app
        if (
          initialOverflowProp.current !== null &&
          document.body.style.overflow !== initialOverflowProp.current &&
          // As a precautionary measure, we never set it back to hidden which would disable scrolling
          initialOverflowProp.current !== 'hidden'
        ) {
          document.body.style.overflow = initialOverflowProp.current;
        }
      };
    }, [hasParentLayer]);

    useIsomorphicLayoutEffect(() => {
      const el = ref.current;

      // We only write global properties if we are the outermost layer
      if (hasParentLayer || el == null) {
        return;
      }

      if (layer.overlayCount > 0) {
        if (!el.style.paddingRight) {
          // There are layers open on top of the app. Disable all pointer events in
          // the base content and don't allow it to scroll. We need to add some
          // padding if there was a visible scrollbar that was taking up space,
          // since the scrollbar will be removed
          //
          // Note that we scope these effects to our div. This is important to for
          // pointer-events especially; it allows apps to integrate other systems
          // that may be adding stuff outside of our layers that needs to be
          // interacted with
          el.style.paddingRight = `${getScrollbarWidth()}px`;
        }
        el.style.pointerEvents = 'none';

        // We have to touch the body to turn off scrolling
        document.body.style.overflow = 'hidden';

        // If persistent scrollbars are enabled, they will be disppearing.
        // Adjust marked elements to remove the margin to account for them
        document
          .querySelectorAll('[data-needs-scrollbar-adjustment]')
          .forEach((el) => {
            (el as HTMLElement).style.marginRight = '';
          });
      } else {
        // No layers are open. Enable pointer events and scrolling again, and
        // reset the padding.
        //
        // See above for why we touch different elements here
        el.style.pointerEvents = '';
        el.style.paddingRight = '';
        document.body.style.overflow = '';

        // If persistent scrollbars are enabled, they will be appearing. Add
        // negative margin to pull the marked elements behind the scrollbar to
        // avoid layout shifts
        document
          .querySelectorAll('[data-needs-scrollbar-adjustment]')
          .forEach((el) => {
            (el as HTMLElement).style.marginRight = `-${getScrollbarWidth()}px`;
          });
      }
    }, [layer.overlayCount]);

    return (
      <LayerContext.Provider value={layer}>
        <view.div {...props} ref={ref} />
      </LayerContext.Provider>
    );
  },
);

type LayerProps = View.IntrinsicElement<
  'div',
  | {
      /**
       * A specific config for this layer. Layers can be configured on the
       * parent layer (usually the root layer) to explicitly order layers. "modal"
       * is a special system layer which puts the layer on top of everything else
       * and switches modality (disables all interactions underneath it). If not
       * specified, it defaults to a non-modal layer ordered by the latest opened
       * layer.
       *
       * If specified, `container` cannot be specified.
       */
      name?: string;
      container?: null;
    }
  | {
      /**
       * The element to use as the container for this layer. If not specified, the
       * layer will be mounted into the nearest parent layer, or the document body
       * if there is no parent layer.
       *
       * If specified, `name` cannot be specified.
       */
      container: HTMLElement;
      name: undefined;
    }
>;

export const LayerConfig = createViewConfig({
  name: 'Layer',
  props: {} as LayerProps,
});

/**
 * Layers are used to create overlays, allowing content to visually stack on top of other content.
 */
export const Layer = LayerConfig.createView(({name, container, ...props}) => {
  // `type` is deprecated but we continue to use it internally until we can
  // remove the old `Layer`. We can infer it from `name`
  let type: LayerType = 'partial';
  if (name === RESERVED_MODAL_LAYER) {
    type = 'overlay';
  }

  const parentContext = useContext(LayerContext);
  if (parentContext == null && process.env.NODE_ENV !== 'test') {
    throw new Error(
      'Parent layer not available; did you render a <RootLayer /> around your app?',
    );
  }

  const isClassicContext = useContext(ClassicLayerContext);

  const prevType = usePrevious(type);

  // if the type of the layer changes, update the layer state accordingly
  useEffect(() => {
    if (prevType && prevType !== type) {
      parentContext && parentContext.removeLayer(prevType);
      parentContext && parentContext.addLayer(type);
    }
  }, [parentContext, prevType, type]);

  animate.useTimelineListeners(
    useMemo(
      () => ({
        in() {
          parentContext && parentContext.addLayer(type);
        },

        out() {
          parentContext && parentContext.removeLayer(type);
        },
      }),
      [parentContext, type],
    ),
  );

  useIsomorphicLayoutEffect(() => {
    if (type === 'overlay') {
      parentContext && parentContext.addMountedOverlay();
    }

    return () => {
      if (type === 'overlay') {
        parentContext && parentContext.removeMountedOverlay();
      }
    };
  }, [type]);

  const context = useLayer({type, name});
  const zIndex = useMemo(() => getZIndex(context), [context]);

  let portalNode = container || context.container;
  if (process.env.NODE_ENV === 'test') {
    portalNode = getPortalNodeForTesting(context, portalNode);
  }

  if (isClassicContext) {
    // We can't render partial layers in our container in a Sail
    // Classic context. If we did, you wouldn't be able to render
    // Sail Next tooltips/popovers/etc inside a Sail Classic
    // layer. We explicitly want to support that, so we render it
    // outside the container and customize the z-index to make it
    // play nicely.
    if (type === 'partial') {
      portalNode = container || (context.container?.parentNode as HTMLElement);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'Warning: a Sail Next overlay was rendered from a Sail Classic ' +
          "overlay. This isn't supported and you may run into problems. " +
          'Sail Next overlays will not render above Sail Classic overlays.',
      );
    }
  }

  // If no portal node, we must be in a server-rendered environment. Render
  // nothing.
  if (portalNode == null) {
    return null;
  }

  const contents = (
    <LayerInterop
      data-overlay-container
      // If the overlay count is greater than zero, we add `aria-hidden` to this
      // layer to hide its content from screen readers.
      //
      // TODO: We probably want to change this to `overlayCount` and also apply
      // `aria-hidden` to the root layer as well
      aria-hidden={context && context.layerCount > 0 ? true : undefined}
      css={{
        zIndex: type === 'partial' && isClassicContext ? 400 : zIndex,
        pointerEvents: context.hasOverlay() ? 'none' : 'auto',
      }}
      uses={[
        provider(LayerContext, context),
        ...(name === RESERVED_MODAL_LAYER
          ? [
              // It's important that we apply `position: fixed` to overlays. We don't
              // want consumers to have to worry about z-indexes, so we apply it here, but we
              // also need to create our own stacking context so it actually takes effect.
              // This way no matter what you do inside an overlay layer, it will be on top of
              // everything else. Partial layers get a z-index, but to avoid being too
              // prescriptive, we don't apply any positioning. We assume the consumer will
              // apply what they want on the `Layer` component.
              //
              // Overlay layers apply `contain: content` which requires us to manually specify
              // its size. This isolates all layout/paint from the rest of the document which
              // improves performance
              css({
                position: 'fixed',
                inset: 'space.0',
              }),
              unprocessedCss({
                contain: 'strict',
              }),
            ]
          : [
              // By default we apply absolute positioning so our z-index
              // actually takes effect. The user can do anything else inside
              // of here (use `position: fixed` inside this) and it'll still
              // work, but this forces our z-index ordering to strictly apply.
              css({position: 'absolute'}),
            ]),
      ]}
      {...props}
    />
  );

  return createPortal(contents, portalNode);
});
