import {ButtonGroup, view} from '@sail/ui';
import * as React from 'react';
import {injectIntl} from 'react-intl';

import Button from 'gelato/frontend/src/components/ButtonV2';
import Message from 'gelato/frontend/src/components/Message';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import {isConsentRequired} from 'gelato/frontend/src/controllers/states/SessionState';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {
  Body,
  FooterLinksSection,
  Header,
  Messages,
  Styles,
} from 'gelato/frontend/src/local_pages/welcome_v2';

import type {PageCardProps} from 'gelato/frontend/src/components/PageCardV2';

const {useCallback} = React;

function Footer(): JSX.Element {
  const {appController, appState} = useAppController();
  const needsConsent = isConsentRequired(appState);
  const consentAccepted =
    needsConsent && !appState.session?.missingFields.includes('consent');

  const handlContinue = useCallback(() => {
    appController.routeToPath('/verify_auth');
  }, [appController]);

  const buttonMessage = React.useMemo(() => {
    if (!needsConsent || consentAccepted) {
      return Messages.continue;
    } else {
      return Messages.agreeAndContinue;
    }
  }, [needsConsent, consentAccepted]);

  return (
    <view.div uses={[Styles.footer, Styles.footerResponsive]}>
      <FooterLinksSection />
      <ButtonGroup direction="column" uses={[Styles.footerButtons]}>
        <Button
          onPress={handlContinue}
          type="primary"
          data-testid="flows-welcome-continue"
        >
          <Message {...buttonMessage} />
        </Button>
      </ButtonGroup>
    </view.div>
  );
}

function VerifyWelcomePage(): JSX.Element {
  const props: PageCardProps = {
    header: <Header />,
    body: <Body />,
    footer: <Footer />,
  };

  postIframeEvent('load');

  return <PageCard {...props} />;
}

setComponentConfig(VerifyWelcomePage, {
  componentName: 'verify_welcome',
  skipApp: true,
  v2: true,
});

export default injectIntl(VerifyWelcomePage);
