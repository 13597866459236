import {defineMessages} from 'react-intl';

import type {FieldErrors} from '@stripe-internal/data-gelato/schema/types';

const validationErrorMessages: {[index: string]: any} = defineMessages({
  driving_license_required: {
    id: 'collectedData.individual.error.driving_license_required',
    description: 'Error message when driving license is required.',
    defaultMessage: `A non-expired driver's license is required. Temporary documents are not accepted.`,
  },
  future_date: {
    id: 'collectedData.individual.error.future_date',
    description: 'Error message when there is a future date validation error',
    defaultMessage: 'Date must be in the past',
  },
  id_document_required: {
    id: 'collectedData.individual.error.id_document_required',
    description: 'Error message when ID document is required',
    defaultMessage: 'A valid Identification document is required',
  },
  invalid_date: {
    id: 'collectedData.individual.error.invalid_date',
    description: 'Error message when there is a invalid date validation error',
    defaultMessage: 'Date does not look valid',
  },
  invalid_email: {
    id: 'collectedData.individual.error.invalid_email',
    description: 'Error message when there is a invalid email validation error',
    defaultMessage: 'Email does not look valid',
  },
  invalid_id_number: {
    id: 'collectedData.individual.error.invalid_id_number',
    description:
      'Error message when there is a invalid id number validation error',
    defaultMessage: 'SSN does not look valid',
  },
  face_not_present: {
    id: 'collectedData.individual.error.face_not_present',
    description:
      'Error message when the uploaded image does not have a visible face',
    defaultMessage:
      'We could not find a face in the image. Please ensure the face is clearly visible and try again.',
  },
  face_extraction_quality_low: {
    id: 'collectedData.individual.error.face_extraction_quality_low',
    description:
      'Error message when we are not capable of extracting a high quality face',
    defaultMessage:
      'Please ensure there is no glare obscuring the face and that there is enough light for a high quality photo.',
  },
  selfie_extraction_quality_low: {
    id: 'collectedData.individual.error.selfie_extraction_quality_low',
    description:
      'Error message when we are not capable of extracting a high quality face',
    defaultMessage:
      'Please ensure there is nothing covering your face, you are directly facing the camera, and that there is enough light for a high quality photo.',
  },
  passport_required: {
    id: 'collectedData.individual.error.passport_required',
    description: 'Error message when passport is required.',
    defaultMessage: 'A non-expired passport is required.',
  },
  poor_selfie_quality: {
    id: 'collectedData.individual.error.poor_selfie_quality',
    description:
      'Error message when the uploaded image does not pass our quality checks',
    defaultMessage:
      'The image quality is poor. Try holding the camera at eye level, facing a light source or window, and avoiding bright lights behind you.',
  },
  no_document: {
    id: 'collectedData.individual.error.no_document',
    description:
      'Error message when the uploaded image contains no identity document',
    defaultMessage:
      "No identity document detected in the image. Ensure that the identity document is clearly visible, not cropped, and that there's enough light for a high quality photo.",
  },
  implausibly_old_date: {
    id: 'collectedData.individual.error.implausibly_old_date',
    description:
      'Error message when the year in birth date is too far in the past to be a birthdate',
    defaultMessage: 'Date is too far in the past.',
  },
  microblink_decode_failed: {
    id: 'collectedData.individual.error.microblink_decode_failed',
    description:
      'Error message when the uploaded cannot be decoded by backend server',
    defaultMessage: 'Check that all details are visible and in focus.',
  },
  microblink_decode_failed_hard_block: {
    id: 'collectedData.individual.error.microblink_decode_failed_hard_block',
    description:
      'Error message when the uploaded cannot be decoded by backend server due to image quality issues',
    defaultMessage: 'Check that all details are visible and in focus.',
  },
  microblink_decode_failed_hard_block_back: {
    id: 'collectedData.individual.error.microblink_decode_failed_hard_block_back',
    description:
      'Error message when the uploaded cannot be decoded by backend server due to image quality issues on the back',
    defaultMessage:
      "Barcode could not be verified. Make sure the barcode is fully visible, in focus, and there's no glare in the image",
  },
  mrz_decode_failed: {
    id: 'collectedData.individual.error.mrz_decode_failed',
    description:
      'Error message when the Machine-Readable Zone (MRZ) cannot be decoded',
    defaultMessage:
      "Machine-Readable Zone could not be read. Make sure it is fully visible, in focus, and there's no glare in the image",
  },
  barcode_decode_failed: {
    id: 'collectedData.individual.error.barcode_decode_failed',
    description:
      'Error message when the image contains a barcode that cannot be decoded by the server',
    defaultMessage:
      "Barcode could not be verified. Make sure the barcode is fully visible, in focus, and there's no glare in the image",
  },
  microblink_decode_failed_desktop_webcam: {
    id: 'collectedData.individual.error.microblink_decode_failed_desktop_webcam',
    description:
      'Error message when the uploaded cannot be decoded by backend server from a desktop webcam',
    defaultMessage:
      'The image could not be verified. For faster verification, try capturing a higher quality image with your phone.',
  },
  expired_document: {
    id: 'collectedData.individual.error.expired_document',
    description: 'Error message when the document is expired',
    defaultMessage: 'Document Expired. Please use a non-expired document',
  },
  under_consent_age: {
    id: 'collectedData.individual.error.under_consent_age',
    description:
      'Error message when the end user is under the minimum supported age',
    defaultMessage: 'Under the required age.',
  },
  screenshot_not_allowed: {
    id: 'collectedData.individual.error.screenshot_not_allowed',
    description:
      'Error message when we detect that the user is taking a photo of a screen rather than an original document',
    defaultMessage:
      'Photos of screens are not valid. Please use the original document.',
  },
  document_processing_failed: {
    id: 'collectedData.individual.error.document_processing_failed',
    description:
      'Error message when there was an unspecified error processing the document.',
    defaultMessage:
      "Ensure that the identity document is clearly visible, not cropped, and that there's enough light for a high quality photo. Try reducing glare and making sure all parts are visible.",
  },
  selfie_processing_failed: {
    id: 'collectedData.individual.error.selfie_processing_failed',
    description: 'Error message when there was an error processing the selfie.',
    defaultMessage:
      'Please make sure that your face is clearly visible in the photo and that you have provided your own ID Document.',
  },
  unexpected_document_type: {
    id: 'collectedData.individual.error.unexpected_document_type',
    description: 'Error message when the document is an invalid type.',
    defaultMessage:
      'Please choose another type of identity document and try again.',
  },
  unsupported_country: {
    id: 'collectedData.individual.error.unsupported_country',
    description:
      'Error message when the document is issued by an unsupported country.',
    defaultMessage:
      'Please choose a document from a different country and try again.',
  },
  glare: {
    id: 'collectedData.individual.error.glare',
    description: 'Error message when the document has too much glare.',
    defaultMessage: 'The image has too much glare.',
  },
  expected_document_front: {
    id: 'collectedData.individual.error.expected_document_front',
    description:
      'Error message when the image is of the back of a document and we expected the front.',
    defaultMessage:
      'Please ensure the front of the document is clearly visible.',
  },
  expected_document_back: {
    id: 'collectedData.individual.error.expected_document_back',
    description:
      'Error message when the image is of the front of a document and we expected the back.',
    defaultMessage:
      'Please ensure the back of the document is clearly visible.',
  },
});

export default validationErrorMessages;

export function getValidationMessage(fieldError: FieldErrors) {
  if (validationErrorMessages[fieldError]) {
    return validationErrorMessages[fieldError];
  }

  throw new Error(`No validation message for "${fieldError}"`);
}
