import React from 'react';
import type {RefObject} from 'react';
import {useId} from '@sail/react-aria';

export const LABEL_OPT_OUT_STRING = 'i-am-providing-my-own-label' as const;

type UseInputIdType = (args: {
  label: React.ReactNode;
  id?: string;
  outerRef?: RefObject<HTMLDivElement>;
}) => {
  id: string;
  rootNode?: RefObject<HTMLDivElement>;
};

export const useInputId: UseInputIdType = ({id: propsId, label, outerRef}) => {
  const id = useId(propsId);
  const newRef = React.useRef<HTMLDivElement>(null);
  const rootNode = outerRef ?? newRef;

  if (process.env.NODE_ENV !== 'production') {
    // we don't care about rules of hooks here since this condition will never change per env
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      if (
        // If the user has opted out of label auto-generation...
        label === LABEL_OPT_OUT_STRING &&
        // ...and the node is mounted...
        rootNode?.current !== null &&
        rootNode?.current.isConnected
      ) {
        if (
          // ...and there's nothing in the DOM that corresponds to our id...
          (
            rootNode.current.getRootNode() as Document | ShadowRoot
          )?.querySelectorAll(`[for='${id}']`).length === 0 &&
          // ...or the node doesn't have a label as an ancestor...
          rootNode.current.closest('label') === null
        ) {
          // ...throw an error
          throw new Error(
            `You opted out of control auto-labeling for element #${id}, but that element is unlabeled.`,
          );
        }
      }
    }, [label, id, rootNode]);
  }

  return {id, rootNode};
};
