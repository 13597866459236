import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  individualCountryRequiredValue: {
    id: 'individual.validation.generic.country.required',
    description: 'Error message shown when country is not selected',
    defaultMessage: 'Country is required',
  },
  dobRequiredValue: {
    id: 'individual.validation.dob.required',
    description: 'Error message when date of birth values are incomplete',
    defaultMessage: 'Day, month and year are required',
  },
  dobInvalidDate: {
    id: 'individual.validation.dob.invalid',
    description: 'Error message when the DOB field has the wrong format',
    defaultMessage: 'Date of birth does not look valid',
  },
  dobInvalidDateWithReason: {
    id: 'individual.validation.dob.invalidWithReason',
    description: 'Error message when the DOB field has the wrong format',
    defaultMessage: 'Date of birth does not look valid. {reason}',
  },
  dobFutureDate: {
    id: 'individual.validation.dob.future',
    description: 'Error message when the DOB field has the wrong format',
    defaultMessage: 'Date of birth is in the future',
  },
  dobImplausiblyOldError: {
    id: 'individual.validation.dob.implausibly_old_date',
    description:
      'Error message when the year in birth date is too far in the past to be a birthdate',
    defaultMessage: 'Date is too far in the past.',
  },
  emailRequiredValue: {
    id: 'individual.validation.email.required',
    description: 'Error message when date of birth values are incomplete',
    defaultMessage: 'Email is required',
  },
  emailInvalidValue: {
    id: 'individual.validation.email.invalid',
    description: 'Error message when email value given has the wrong format',
    defaultMessage: 'Email does not look valid',
  },
  phoneNumberRequiredValue: {
    id: 'individual.validation.phoneNumber.required',
    description: 'Error message when phone number is not given',
    defaultMessage: 'Phone number is required',
  },
  phoneNumberInvalidValue: {
    id: 'individual.validation.phoneNumber.invalid',
    description: 'Error message when phone number is not given',
    defaultMessage: 'Phone number does not look valid',
  },
  nameFirstNameRequiredValue: {
    id: 'individual.validation.name.firstName.required',
    description: 'Error message when first name is not given',
    defaultMessage: 'First name is required',
  },
  nameLastNameRequiredValue: {
    id: 'individual.validation.name.lastName.required',
    description: 'Error message when last name is not given',
    defaultMessage: 'Last name is required',
  },
  idNumberValueRequired: {
    id: 'individual.validation.name.idNumber.required',
    description: 'Error message when ID number is not given',
    defaultMessage: 'ID number is required',
  },
  idNumberValueInvalid: {
    id: 'individual.validation.name.idNumber.invalid',
    description: 'Error message when ID number is not given',
    defaultMessage: 'Invalid ID number is given.',
  },
  idNumberValueInvalidWithExample: {
    id: 'individual.validation.name.idNumber.invalidWithExample',
    description: 'Error message when ID number is not given',
    defaultMessage:
      'Invalid ID number is given. Example valid format: {placeholder}',
  },
  addressErrors: {
    id: 'individual.validation.address.errors',
    description: 'Error message when ID number is not given',
    defaultMessage: 'Address is invalid because of the following: {reasons}',
  },
});
