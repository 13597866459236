export const tokensColor = {
  hue: {
    gray0: '#ffffff',
    gray50: '#f6f8fa',
    gray100: '#ebeef1',
    gray150: '#d5dbe1',
    gray200: '#c0c8d2',
    gray300: '#a3acba',
    gray400: '#87909f',
    gray500: '#687385',
    gray600: '#545969',
    gray700: '#414552',
    gray800: '#30313d',
    gray900: '#1a1b25',
    gray950: '#10111a',

    blue50: '#ddfffe',
    blue100: '#cff5f6',
    blue150: '#a2e5ef',
    blue200: '#75d5e8',
    blue300: '#06b9ef',
    blue400: '#0096eb',
    blue500: '#0570de',
    blue600: '#0055bc',
    blue700: '#04438c',
    blue800: '#003262',
    blue900: '#011c3a',

    green50: '#ecfed7',
    green100: '#d7f7c2',
    green150: '#a6eb84',
    green200: '#76df47',
    green300: '#48c404',
    green400: '#3fa40d',
    green500: '#228403',
    green600: '#006908',
    green700: '#0b5019',
    green800: '#043b15',
    green900: '#02220d',

    orange50: '#fef9da',
    orange100: '#fcedb9',
    orange150: '#fcd579',
    orange200: '#fcbd3a',
    orange300: '#ff8f0e',
    orange400: '#ed6704',
    orange500: '#c84801',
    orange600: '#a82c00',
    orange700: '#842106',
    orange800: '#5f1a05',
    orange900: '#331302',

    red50: '#fff5fa',
    red100: '#ffe7f2',
    red150: '#ffccdf',
    red200: '#ffb1cd',
    red300: '#fe87a1',
    red400: '#fc526a',
    red500: '#df1b41',
    red600: '#b3093c',
    red700: '#890d37',
    red800: '#68052b',
    red900: '#3e021a',

    purple50: '#f9f7ff',
    purple100: '#f2ebff',
    purple150: '#dfd3fc',
    purple200: '#d1befe',
    purple300: '#b49cfc',
    purple400: '#8d7ffa',
    purple500: '#625afa',
    purple600: '#513dd9',
    purple700: '#3f32a1',
    purple800: '#302476',
    purple900: '#14134e',
  },
  color: {
    neutral0: '$hue.gray0',
    neutral50: '$hue.gray50',
    neutral100: '$hue.gray100',
    neutral150: '$hue.gray150',
    neutral200: '$hue.gray200',
    neutral300: '$hue.gray300',
    neutral400: '$hue.gray400',
    neutral500: '$hue.gray500',
    neutral600: '$hue.gray600',
    neutral700: '$hue.gray700',
    neutral800: '$hue.gray800',
    neutral900: '$hue.gray900',
    neutral950: '$hue.gray950',

    brand50: '$hue.purple50',
    brand100: '$hue.purple100',
    brand200: '$hue.purple200',
    brand300: '$hue.purple300',
    brand400: '$hue.purple400',
    brand500: '$hue.purple500',
    brand600: '$hue.purple600',
    brand700: '$hue.purple700',
    brand800: '$hue.purple800',
    brand900: '$hue.purple900',

    info50: '$hue.blue50',
    info100: '$hue.blue100',
    info150: '$hue.blue150',
    info200: '$hue.blue200',
    info300: '$hue.blue300',
    info400: '$hue.blue400',
    info500: '$hue.blue500',
    info600: '$hue.blue600',
    info700: '$hue.blue700',
    info800: '$hue.blue800',
    info900: '$hue.blue900',

    success50: '$hue.green50',
    success100: '$hue.green100',
    success150: '$hue.green150',
    success200: '$hue.green200',
    success300: '$hue.green300',
    success400: '$hue.green400',
    success500: '$hue.green500',
    success600: '$hue.green600',
    success700: '$hue.green700',
    success800: '$hue.green800',
    success900: '$hue.green900',

    attention50: '$hue.orange50',
    attention100: '$hue.orange100',
    attention150: '$hue.orange150',
    attention200: '$hue.orange200',
    attention300: '$hue.orange300',
    attention400: '$hue.orange400',
    attention500: '$hue.orange500',
    attention600: '$hue.orange600',
    attention700: '$hue.orange700',
    attention800: '$hue.orange800',
    attention900: '$hue.orange900',

    critical50: '$hue.red50',
    critical100: '$hue.red100',
    critical150: '$hue.red150',
    critical200: '$hue.red200',
    critical300: '$hue.red300',
    critical400: '$hue.red400',
    critical500: '$hue.red500',
    critical600: '$hue.red600',
    critical700: '$hue.red700',
    critical800: '$hue.red800',
    critical900: '$hue.red900',
  },
  backgroundColor: {
    surface: '$color.neutral0',
    container: '$color.neutral50',
  },
  borderColor: {
    neutral: '$color.neutral150',
    critical: '$color.critical500',
  },
  iconColor: {
    primary: '$color.neutral800',
    secondary: '$color.neutral500',
    disabled: '$color.neutral300',
    brand: '$color.brand500',
    info: '$color.info500',
    success: '$color.success500',
    attention: '$color.attention500',
    critical: '$color.critical500',
  },
  textColor: {
    primary: '$color.neutral700',
    secondary: '$color.neutral500',
    disabled: '$color.neutral300',
    brand: '$color.brand500',
    info: '$color.info500',
    success: '$color.success500',
    attention: '$color.attention500',
    critical: '$color.critical500',
  },
};
