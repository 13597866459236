import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {
  IndividualDataVariables,
  convertStateToDataVariables,
} from 'gelato/frontend/src/controllers/states/IndividualState';

import type {GraphQlField} from '@sail/data';
import type {FieldErrors} from '@stripe-internal/data-gelato/schema/types';
import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';
import type {UpdateIndividualMutationData} from 'gelato/frontend/src/graphql/mutations/useUpdateIndividualMutation';

export type UpdateIndividualResponse = Partial<{
  session: GraphQlField<
    UpdateIndividualMutationData,
    'updateIndividual',
    'session'
  >;
  validationErrors: UpdateIndividualValidationErrors;
}>;

export type UpdateIndividualValidationErrors = readonly {
  readonly __typename: 'ValidationError';
  readonly type: FieldErrors;
  readonly path: readonly string[];
}[];

/**
 * Mutation request to update individual collected data
 * @param state The application state.
 * @param runtime The application runtime.
 * @param individualData Optional field to specify which data to update
 * @returns The updated session with the consent field changed.
 */
export default async function updateIndividual(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
  individualData?: Partial<IndividualDataVariables>,
): Promise<UpdateIndividualResponse> {
  const resp = await runtime.api.mutation.updateIndividualMutation({
    variables: individualData || convertStateToDataVariables(state.individual),
  });
  if (!resp?.data?.updateIndividual) {
    throw new Error(ErrorCode.failedToUpdateIndividualMutation);
  }

  const {session, validationErrors} = resp.data?.updateIndividual;

  return {session, validationErrors};
}
