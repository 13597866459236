import {urlRedirect} from '@stripe-internal/safe-links';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {injectIntl} from 'react-intl';

import ConsentDeclined from 'gelato/frontend/src/components/Invalid/ConsentDeclined';
import DeviceUnsupported from 'gelato/frontend/src/components/Invalid/DeviceUnsupported';
import ExpiredSession from 'gelato/frontend/src/components/Invalid/ExpiredSession';
import Generic from 'gelato/frontend/src/components/Invalid/Generic';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import OTPDeclined from 'gelato/frontend/src/components/Invalid/OTPDeclined';
import SanctionedDocumentCountry from 'gelato/frontend/src/components/Invalid/SanctionedDocumentCountry';
import UnderConsentAge from 'gelato/frontend/src/components/Invalid/UnderConsentAge';
import UnsupportedCountry from 'gelato/frontend/src/components/Invalid/UnsupportedCountry';
import UnsupportedHandoffDevice from 'gelato/frontend/src/components/Invalid/UnsupportedHandoffDevice';
import UnsupportedIpCountry from 'gelato/frontend/src/components/Invalid/UnsupportedIpCountry';
import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import {OTPMode} from 'gelato/frontend/src/components/OTPVerification';
import analytics from 'gelato/frontend/src/lib/analytics';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {LOADING_PAGE_HEIGHT} from 'gelato/frontend/src/lib/constants';
import {SetPageCardPropsContext} from 'gelato/frontend/src/lib/contexts';
import {getDebugInfo, isMobileDevice} from 'gelato/frontend/src/lib/device';
import {
  useSession,
  useBranding,
  useFeatureFlags,
} from 'gelato/frontend/src/lib/hooks';
import {isSessionExpired} from 'gelato/frontend/src/lib/sessionError';

import styles from './invalid.module.css';

import type {Flags} from '@stripe-internal/data-gelato/schema/types';
import type {Session} from 'gelato/frontend/src/lib/contexts';
import type {PageProps} from 'gelato/frontend/src/lib/localRouter';
import type {IntlShape} from 'react-intl';

type Props = PageProps & {
  intl: IntlShape;
};

type ContentState = {
  unsupportedIpCountry: boolean;
  consentDeclined: boolean;
  deviceUnsupported: boolean;
  isExpired: boolean;
  sanctionedDocumentCountry: boolean;
  underConsentAge: boolean;
  unsupportedCountry: boolean;
  unsupportedHandoffDevice: boolean;
  emailOTPDeclined: boolean;
  phoneOTPDeclined: boolean;
};

function createContentState(
  session: Session | null | undefined,
  flags: readonly Flags[] | null | undefined,
): ContentState {
  const emailOTPRequiredAndDeclined =
    !!session?.missingFields?.includes('email_otp') &&
    !!session?.collectedData?.individual?.email?.otpDeclined === true;

  const phoneOTPRequiredAndDeclined =
    !!session?.missingFields?.includes('phone_otp') &&
    !!session?.collectedData?.individual?.phoneNumber?.otpDeclined === true;

  const unsupportedHandoffDevice =
    session?.operatingMode === 'secondary' &&
    session?.fMobile &&
    !isMobileDevice();

  // Default to allowing sessions where IP supportability is unknown
  const ipCountrySupportable = session?.ipCountrySupportable ?? true;

  return {
    unsupportedIpCountry: !ipCountrySupportable,
    consentDeclined:
      session?.collectedData?.individual?.consent?.accepted === false,
    deviceUnsupported: session?.lastErrorCode === 'device_unsupported',
    emailOTPDeclined: emailOTPRequiredAndDeclined,
    isExpired: isSessionExpired(),
    phoneOTPDeclined: phoneOTPRequiredAndDeclined,
    sanctionedDocumentCountry: session?.sanctionedDocumentCountry === true,
    underConsentAge: session?.underConsentAge === true,
    unsupportedCountry: session?.unsupportedCountry === true,
    unsupportedHandoffDevice,
  };
}

export function InvalidPage(props: Props) {
  const {
    intl: {formatMessage},
    params,
  } = props;
  const session = useSession();
  const flags = useFeatureFlags();
  const branding = useBranding();
  const setPageCardProps = React.useContext(SetPageCardPropsContext);

  const [contentState, setContentState] = React.useState<ContentState | null>(
    null,
  );

  React.useEffect(() => {
    setPageCardProps({
      showBackLink: false,
      showPageHeader: true,
      size: 'single',
    });
  }, [setPageCardProps]);

  React.useEffect(() => {
    setContentState(createContentState(session, flags));
  }, [session, flags]);

  React.useEffect(() => {
    (async () => {
      if (contentState) {
        if (contentState.deviceUnsupported) {
          // We wanna know why the device is unsopported.
          const debugInfo = await getDebugInfo();
          analytics.track('invalidDebugInfo', {
            ...contentState,
            ...debugInfo,
          });
        } else {
          analytics.track('invalidDebugInfo', contentState);
        }
      }
    })();
  }, [contentState]);

  // TODO(jjs) - should we redirect to Storage.expiredUrl if present?
  // This seems like a dead end that never goes away?
  if (!session || !contentState) {
    return <LoadingBox height={LOADING_PAGE_HEIGHT} />;
  }

  if (params && params.redirectToUrl) {
    // this setTimeout avoids the WarnOnUnload alert popping up because we don't think we are on the
    // invalid page yet.
    setTimeout(() => urlRedirect(encodeURI(params.redirectToUrl)), 0);
    return <LoadingBox height={LOADING_PAGE_HEIGHT} />;
  }

  const component = () => {
    if (contentState.unsupportedIpCountry) {
      return <UnsupportedIpCountry />;
    } else if (contentState.unsupportedHandoffDevice) {
      return <UnsupportedHandoffDevice />;
    } else if (contentState.consentDeclined) {
      return <ConsentDeclined />;
    } else if (contentState.deviceUnsupported) {
      return <DeviceUnsupported session={session} />;
    } else if (contentState.underConsentAge) {
      return <UnderConsentAge session={session} />;
    } else if (contentState.unsupportedCountry) {
      return <UnsupportedCountry session={session} />;
    } else if (contentState.sanctionedDocumentCountry) {
      return <SanctionedDocumentCountry session={session} />;
    } else if (contentState.isExpired) {
      return <ExpiredSession session={session} />;
    } else if (contentState.emailOTPDeclined) {
      return <OTPDeclined session={session} mode={OTPMode.email} />;
    } else if (contentState.phoneOTPDeclined) {
      return <OTPDeclined session={session} mode={OTPMode.phone} />;
    } else {
      return <Generic platformName={branding?.platformName} />;
    }
  };

  return (
    <div className={styles.invalidPage}>
      <Helmet>
        <title>{formatMessage(messages.documentTitle)}</title>
      </Helmet>
      {component()}
    </div>
  );
}

setComponentConfig(InvalidPage, {
  componentName: 'invalid_page',
  isTerminal: true,
  ignoreExpiredSession: true,
});

export default injectIntl(InvalidPage);
