import {ApolloLink} from '@apollo/client';
import {stableStringify} from '@sail/utils';
import createMulticastObservable from 'src/internal/prefetching/createMulticastObservable';
import {getOperationType} from 'src/internal/utils/gql';

import type {FetchResult, NextLink, Observable} from '@apollo/client';
import type {InternalOperation} from 'src/internal/apollo/types';

type CacheVars = Map<string, Observable<FetchResult>>;

export default function createDeduplicationLink(): ApolloLink {
  const cacheByGql = new Map<string, CacheVars>();

  return new ApolloLink(function deduplicationLink(
    operation: InternalOperation,
    forward: NextLink,
  ) {
    if (getOperationType(operation.query) === 'mutation') {
      return forward(operation);
    }

    const context = operation.getContext();
    const hash = context?.operationHash ?? stableStringify(operation.query);

    const cacheByVars: CacheVars = cacheByGql.get(hash) ?? new Map();
    const vars = stableStringify(operation.variables);
    const cachedObservable = cacheByVars.get(vars);

    if (cachedObservable) {
      operation.setContext({isDeduplicated: true});

      return cachedObservable;
    }

    cacheByGql.set(hash, cacheByVars);

    const requestObservable = forward(operation);

    function cleanupCache() {
      cacheByVars.delete(vars);

      if (cacheByVars.size === 0) {
        cacheByGql.delete(hash);
      }
    }

    const newObservable = createMulticastObservable(requestObservable, {
      complete: cleanupCache,
      unsubscribe: cleanupCache,
    });

    cacheByVars.set(vars, newObservable);

    return newObservable;
  });
}
