import * as React from 'react';
import {Helmet} from 'react-helmet';
import {defineMessages, injectIntl} from 'react-intl';

import DocumentTypeBox from 'gelato/frontend/src/components/DocumentTypeBox';
import {SkippableDataCollectionPage} from 'gelato/frontend/src/components/DynamicForm/SkippablePage';
import ErrorContainer from 'gelato/frontend/src/components/ErrorContainer';
import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import Message from 'gelato/frontend/src/components/Message';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import useClearDataFieldsMutation from 'gelato/frontend/src/graphql/mutations/useClearDataFieldsMutation';
import useUpdateDocumentMetadataMutation from 'gelato/frontend/src/graphql/mutations/useUpdateDocumentMetadataMutation';
import {PAGE_TITLE_MESSAGE} from 'gelato/frontend/src/lib/constants';
import {
  LocaleContext,
  SetPageCardPropsContext,
} from 'gelato/frontend/src/lib/contexts';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {isExperimentActive} from 'gelato/frontend/src/lib/experiments';
import {
  useRouter,
  useFeatureFlags,
  useConnectIframe,
  useSession,
  useExperiments,
} from 'gelato/frontend/src/lib/hooks';
import {LOCALE_MAP} from 'gelato/frontend/src/lib/locale';
import getRouter from 'gelato/frontend/src/lib/localRouter';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import Button from 'sail/Button';
import Group from 'sail/Group';
import {Title} from 'sail/Text';

import styles from './document_select.module.css';

import type {
  Flags,
  IndividualFields,
  DocumentTypes,
  Country,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  Session,
  SetPageCardProps,
  Experiments,
} from 'gelato/frontend/src/lib/contexts';
import type {LocaleKey} from 'gelato/frontend/src/lib/locale';
import type {PageProps} from 'gelato/frontend/src/lib/localRouter';
import type {IntlShape} from 'react-intl';

const PAGE_FIELDS = ['id_document_metadata'] as Array<IndividualFields>;

const messages = defineMessages({
  documentType: {
    id: 'pages.welcome.identity_document.document_type_header',
    description: 'Header label in document type page',
    defaultMessage: 'Select identification type',
  },
  testmodeUploadValidId: {
    id: 'testmode.action.uploadValidId',
    description:
      'The user is making a test verification; upload a fake id and mark it valid',
    defaultMessage: 'Upload valid ID',
  },
  testmodeUploadInvalidId: {
    id: 'testmode.action.uploadInvalidId',
    description:
      'The user is making a test verification; upload a fake id and mark it invalid',
    defaultMessage: 'Upload invalid ID',
  },
  testmodeDeclineConsent: {
    id: 'testmode.action.declineConsent',
    description:
      'The user is making a test verification; simulate consent being declined',
    defaultMessage: 'Decline consent',
  },
  nextButton: {
    id: 'pages.welcome.identity_document.nextButton',
    description: 'Next button text',
    defaultMessage: 'Next',
  },
  goBack: {
    id: 'handoff.sms.goBack',
    description: 'Allow the user to go back a page',
    defaultMessage: 'Go back',
  },
});

type Props = PageProps & {
  locale: LocaleKey;
  session: Session;
  flags: ReadonlyArray<Flags> | null | undefined;
  experiments: Experiments | null | undefined;
  setPageCardProps: SetPageCardProps;
  updateDocumentMetadata: ReturnType<
    typeof useUpdateDocumentMetadataMutation
  >[0];
  clearDataFieldsMutation: ReturnType<typeof useClearDataFieldsMutation>[0];
  isConnectIframe: boolean;
};

type State = {
  documentType: DocumentTypes;
  country: Country;
  pending: boolean;
  error: never | null | undefined;
  throwErrorInRender: boolean | null | undefined;
};

// When the end-user is in a region where the domestic ID doesn't contain
// roman characters, Microblink cannot extract any useful fields
// (ShuftiPro sometimes extracts the name to the full_name field, but
// we're not using that field right now.)
// Use browser locale to approximate the region, and ask for a passport
// (change the order of options in /document_select) in certain locales:
// - all Chinese (PRC, HK, Taiwan)
// - Japanese
// - Korean
// - Turkish (old domestic IDs have no photos)
// Default to asking for passport in locales where the domestic ID does
// not contain roman characters.
const PREFERRED_PASSPORT_LOCALES: Set<LocaleKey> = new Set([
  LOCALE_MAP.jp,
  LOCALE_MAP.kr,
  LOCALE_MAP.tr,
  LOCALE_MAP.zh,
  LOCALE_MAP['zh-CN'],
  LOCALE_MAP['zh-HK'],
  LOCALE_MAP['zh-TW'],
]);

/**
 * Given the country and locale that we know, returns the ordered
 * list of document type options allowed.
 */
function getDocumentTypeOptions(args: {
  country: Country;
  documentTypeAllowlist: ReadonlyArray<DocumentTypes> | null | undefined;
  experiments: Experiments | null | undefined;
  locale: LocaleKey;
}): ReadonlyArray<DocumentTypes> {
  const {experiments, documentTypeAllowlist, country, locale} = args;

  let isPassportPreferred = country !== 'US';
  if (
    !isPassportPreferred &&
    PREFERRED_PASSPORT_LOCALES.has(locale) &&
    isExperimentActive(
      'show_passport_first_for_non_roman_characters',
      experiments,
    )
  ) {
    isPassportPreferred = true;
  }

  const passport = 'passport';
  const driving_license = 'driving_license';
  const id_card = 'id_card';

  const docTypes: ReadonlyArray<DocumentTypes> = isPassportPreferred
    ? [passport, driving_license, id_card]
    : [driving_license, passport, id_card];

  return documentTypeAllowlist
    ? docTypes.filter((type) => documentTypeAllowlist.includes(type))
    : docTypes;
}

class DocumentSelectPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {experiments, session, locale} = this.props;
    const country =
      session.collectedData.individual.idDocument.country ||
      session.defaultCountry;
    const {documentTypeAllowlist} = session;

    const availableDocumentTypes = getDocumentTypeOptions({
      country,
      documentTypeAllowlist,
      experiments,
      locale,
    });

    const defaultDocumentType = availableDocumentTypes[0];

    const documentType =
      session.collectedData.individual.idDocument.type || defaultDocumentType;

    this.state = {
      country,
      documentType,
      pending: false,
      error: null,
      throwErrorInRender: null,
    };
  }

  componentDidMount() {
    const {setPageCardProps} = this.props;
    setPageCardProps({
      showBackLink: true,
      showPageHeader: true,
      size: 'single',
    });
  }

  /**
   * Updates & Network Requests
   */

  // tryTo consolidates async error handling. Goals here are:
  // manage `pending` state safely, catch and surface errors
  // appropriately. Caught errors are set as state so they
  // can be rendered as an error notice or rethrown up to ErrorProvider.
  //
  // tryTo params
  // message - error message to post to sentry in case of failure
  // silent - if true, will swallow any errors, not even reporting them to sentry
  // action - tryTo calls this async method and returns the result
  // pending - if true will update pending state on this comment while `action` is being executed
  // throwInRender - in case of error set this state to component to throw in the render thread any
  //   error that was raised calling `action`
  // clearState - if true, then clear error and throwErrorInRender state from component after calling action
  //   you may set this to be false if `action` will have a side effect that causes the component to
  //   unmount like perform a routing change

  tryTo = async ({
    message,
    silent,
    pending,
    throwInRender,
    action,
    clearState,
  }: {
    message: string;
    silent: boolean;
    pending: boolean;
    throwInRender: boolean;
    action: () => any;
    clearState: boolean;
  }) => {
    try {
      this.setState({pending});
      const res = await action();
      if (clearState) {
        this.setState({error: null, throwErrorInRender: null});
      }
      return res;
    } catch (error: any) {
      handleException(error, message);
      !silent && this.setState({error, throwErrorInRender: throwInRender});
      pending && this.setState({pending: false});
      throw error;
    }
  };

  updateIndividual = async ({
    message,
    silent,
    type,
  }: {
    message: string;
    silent: boolean;
    type: DocumentTypes | null | undefined;
  }) => {
    return this.tryTo({
      message,
      silent,
      pending: !silent,
      throwInRender: false,
      clearState: true,
      action: async () => {
        const {updateDocumentMetadata} = this.props;
        return updateDocumentMetadata({
          variables: {
            documentMetadata: {type},
          },
        });
      },
    });
  };

  setDocumentType = (documentType: DocumentTypes) => {
    this.setState((oldState) => {
      return {...oldState, documentType};
    });
  };

  // handleChange is called when the user has updated the country selector
  // or document type selector, but has not clicked Next yet.
  handleChange = async ({type}: {type?: DocumentTypes}) => {
    await this.updateIndividual({
      message: 'handleChange auto-save',
      silent: true,
      type,
    });
  };

  // handleNext is called when the user clicks a button to proceed
  // with identity document collection.
  handleNext = async () => {
    const {clearDataFieldsMutation} = this.props;
    const {documentType: type} = this.state;
    await clearDataFieldsMutation({
      variables: {
        clearIdDocumentFront: true,
        clearIdDocumentBack: true,
      },
    });
    const {data} = await this.updateIndividual({
      message: 'handleNext save',
      silent: false,
      type,
    });

    await this.tryTo({
      message: 'handleNext redirect',
      pending: true,
      silent: false,
      throwInRender: true,
      clearState: false,
      action: async () => {
        const {
          updateDocumentMetadata: {session},
        } = data;
        const nextPage = await nextDataPageForSession(session);
        getRouter().push(nextPage);
      },
    });

    return true;
  };

  handleGoBack = () => window.history.go(-1);

  render() {
    const {country, documentType, pending, error, throwErrorInRender} =
      this.state;
    const {
      session: {documentTypeAllowlist},
      experiments,
      isConnectIframe,
      locale,
    } = this.props;

    if (throwErrorInRender) {
      throw error;
    }

    const documentTypeOptions = getDocumentTypeOptions({
      country,
      documentTypeAllowlist,
      experiments,
      locale,
    });

    return (
      <>
        <ErrorContainer error={error} />
        <div className={styles.documentSelect}>
          <Group margin={{vertical: 12}} spacing={24}>
            <Title>
              <Message {...messages.documentType} />
            </Title>
            <DocumentTypeBox
              documentTypeOptions={documentTypeOptions}
              value={documentType}
              onChange={(documentType) => {
                this.setDocumentType(documentType);
                return this.handleChange({type: documentType});
              }}
            />
          </Group>
          <div className={styles.buttonContainer}>
            <ThemableButton
              color="blue"
              size="large"
              width="maximized"
              disabled={documentType === null}
              label={<Message {...messages.nextButton} />}
              onClick={this.handleNext}
              pending={pending}
              // @ts-expect-error - TS2322 - Type '{ color: "blue"; size: "large"; width: "maximized"; disabled: boolean; label: Element; onClick: () => Promise<boolean>; pending: boolean; id: string; "data-testid": string; }' is not assignable to type 'IntrinsicAttributes & { className?: string | undefined; color?: ButtonColor | undefined; disabled?: boolean | undefined; disabledWhenPending?: boolean | undefined; ... 14 more ...; to?: undefined; } & LinkBehaviorProps'.
              id="next"
              data-testid="next-button"
            />
            {isConnectIframe && (
              <Button
                className={styles.goBack}
                label={<Message {...messages.goBack} />}
                // @ts-expect-error - TS2769 - No overload matches this call.
                id="next"
                data-testid="next-button"
                icon="arrowLeft"
                iconPosition="left"
                onClick={this.handleGoBack}
                width="maximized"
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export function DocumentSelect({
  intl: {formatMessage},
  ...props
}: PageProps & {
  intl: IntlShape;
}) {
  const router = useRouter();
  const session = useSession();
  const experiments = useExperiments();
  const setPageCardProps = React.useContext(SetPageCardPropsContext);
  const {locale} = React.useContext(LocaleContext);
  const isConnectIframe = useConnectIframe();
  const [updateDocumentMetadata] = useUpdateDocumentMetadataMutation();
  const [clearDataFieldsMutation] = useClearDataFieldsMutation();
  const flags = useFeatureFlags();

  if (!session) {
    return <LoadingBox />;
  }

  return (
    <SkippableDataCollectionPage session={session} pageFields={PAGE_FIELDS}>
      <Helmet>
        <title>{formatMessage(PAGE_TITLE_MESSAGE)}</title>
      </Helmet>
      <DocumentSelectPage
        {...props}
        locale={locale}
        flags={flags}
        router={router}
        session={session}
        experiments={experiments}
        updateDocumentMetadata={updateDocumentMetadata}
        clearDataFieldsMutation={clearDataFieldsMutation}
        setPageCardProps={setPageCardProps}
        isConnectIframe={isConnectIframe}
      />
    </SkippableDataCollectionPage>
  );
}

export default injectIntl(DocumentSelect);
