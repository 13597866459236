import {COMMENT, DECLARATION, IMPORT, RULESET} from 'stylis';
import type {StylisElement, StylisMiddleware} from './types';

export function serialize(
  elements: StylisElement[],
  callback: StylisMiddleware,
): string {
  let output = '';
  for (let i = 0; i < elements.length; i++) {
    output += callback(elements[i], i, elements, callback) || '';
  }
  return output;
}

export function stringify(
  element: StylisElement,
  _index: number,
  _elements: (StylisElement | string)[],
  callback: StylisMiddleware,
): string {
  switch (element.type) {
    case IMPORT:
    case DECLARATION:
      element.return = element.return || element.value;
      return element.return;
    case COMMENT:
      return '';
    case RULESET:
      element.value = (element.props as string[]).join(',');
      break;
    default:
      break;
  }

  const children = serialize(element.children as StylisElement[], callback);
  if (children.length) {
    element.return = `${element.value}{${children}}`;
    return element.return;
  }
  return '';
}
