import {ApolloLink, Observable} from '@apollo/client';

import type {InternalOperation} from 'src/types';

function traceResponseOverhead(operation: InternalOperation) {
  try {
    const {observabilityFns, tracingResponseV1, tracingResponseV2} =
      operation.getContext();

    if (tracingResponseV1) {
      // performance.mark('E: V1 Response ' + operation.operationName);

      // performance.measure(
      //   'V1 Response ' + operation.operationName,
      //   'S: V1 Response ' + operation.operationName,
      //   'E: V1 Response ' + operation.operationName,
      // );

      observabilityFns?.analytics.track('graphql_v1_response_overhead', {
        operation_name: operation.operationName,
        runtime_overhead: performance.now() - tracingResponseV1,
      });
    }

    if (tracingResponseV2) {
      // performance.mark('E: V2 Response ' + operation.operationName);

      // performance.measure(
      //   'V2 Response ' + operation.operationName,
      //   'S: V2 Response ' + operation.operationName,
      //   'E: V2 Response ' + operation.operationName,
      // );

      observabilityFns?.analytics.track('graphql_v2_response_overhead', {
        operation_name: operation.operationName,
        runtime_overhead: performance.now() - tracingResponseV2,
      });
    }
  } catch {}
}

export default function createOverheadLink(): ApolloLink {
  return new ApolloLink(function overheadLink(
    operation: InternalOperation,
    forward,
  ) {
    operation.setContext({
      tracingRequest: performance.now(),
    });

    // performance.mark('S: Request ' + operation.operationName);

    return new Observable((observer) => {
      const subscription = forward(operation).subscribe({
        next(result) {
          traceResponseOverhead(operation);

          operation.setContext({
            tracingResponseV1: undefined,
            tracingResponseV2: undefined,
          });

          return observer.next(result);
        },

        error(error) {
          traceResponseOverhead(operation);

          operation.setContext({
            tracingResponseV1: undefined,
            tracingResponseV2: undefined,
          });

          return observer.error(error);
        },

        complete() {
          return observer.complete();
        },
      });

      return (): void => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    });
  });
}
