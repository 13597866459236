import {assignProps} from '@sail/engine';
import {CountryCode, PhoneNumberField} from '@sail/ui';
import {isValidPhoneNumber} from 'libphonenumber-js';
import * as React from 'react';
import {useIntl} from 'react-intl';

import Link from 'gelato/frontend/src/components/FCLink';
import Acknowledgements from 'gelato/frontend/src/components/Link/BottomSheet/Acknowledgements';
import Footer from 'gelato/frontend/src/components/Link/BottomSheet/Footer';
import LinkDescription from 'gelato/frontend/src/components/Link/BottomSheet/LinkDescription';
import LinkHeader from 'gelato/frontend/src/components/Link/BottomSheet/LinkHeader';
import NotNow from 'gelato/frontend/src/components/Link/BottomSheet/NotNow';
import {
  LinkTermsURL,
  LinkPrivacyUrl,
  LinkAboutURL,
} from 'gelato/frontend/src/components/Link/constants';
import LinkButton from 'gelato/frontend/src/components/Link/LinkButton';
import {messages} from 'gelato/frontend/src/components/Link/messages';
import {PHONE_COUNTRY_WHITELIST} from 'gelato/frontend/src/lib/constants';
import {LocaleContext} from 'gelato/frontend/src/lib/contexts';
import getDefaultCountryForSMS from 'gelato/frontend/src/lib/getDefaultCountryForSMS';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

export default function SignUp() {
  const {appState, appController} = useAppController();
  const {locale} = React.useContext(LocaleContext);
  const intl = useIntl();

  const [loading, setLoading] = React.useState(false);
  const [consumerPhoneCountry, setConsumerPhoneCountry] =
    React.useState<CountryCode>(
      getDefaultCountryForSMS(appState.session, locale),
    );

  const handlePhoneInput = React.useCallback(
    (value) => {
      appController.updateConsumerPhone({phone: value});
    },
    [appController],
  );

  const startSignUp = React.useCallback(async () => {
    setLoading(true);
    await appController.signUpConsumerAccountV2({
      consumerPhoneCountry,
      locale,
    });
    setLoading(false);
  }, [appController, locale, consumerPhoneCountry]);

  return (
    <>
      <LinkHeader
        title={intl.formatMessage(messages.postUploadPageTitle)}
        subtitle={intl.formatMessage(messages.enterPhoneNumberSubheader)}
      />
      <LinkDescription />
      <PhoneNumberField
        data-testid="link-start-phone-input"
        label={intl.formatMessage(messages.phoneNumber)}
        value={appState.networkedIdentity.consumerPhone}
        countryAllowlist={phoneCountryAllowlist}
        defaultCountry={consumerPhoneCountry}
        onChange={handlePhoneInput}
        size="large"
        subviews={{
          countryInput: {
            uses: [
              assignProps({
                onChange: (e: React.ChangeEvent<HTMLSelectElement>) =>
                  setConsumerPhoneCountry(e.target.value as CountryCode),
              }),
            ],
          },
        }}
        disabled={loading}
      />

      <Footer>
        <Acknowledgements>
          {intl.formatMessage(messages.linkBottomSheetSignUpAcknowledgements, {
            terms: (
              <Link href={LinkTermsURL}>
                {intl.formatMessage(messages.linkTerms)}
              </Link>
            ),
            privacyPolicy: (
              <Link href={LinkPrivacyUrl}>
                {intl.formatMessage(messages.linkPrivacyPolicy)}
              </Link>
            ),
            learnMore: (
              <Link href={LinkAboutURL}>
                {intl.formatMessage(messages.learnMore)}
              </Link>
            ),
          })}
        </Acknowledgements>
        <SignUpButton
          startSignUp={startSignUp}
          loading={loading}
          disabled={
            !isValidPhoneNumber(
              appState.networkedIdentity.consumerPhone,
              appState.networkedIdentity.consumerPhoneCountry,
            )
          }
        />
        <NotNow />
      </Footer>
    </>
  );
}

const phoneCountryAllowlist = PHONE_COUNTRY_WHITELIST as CountryCode[];

type SignUpButtonProps = {
  startSignUp: () => void;
  loading: boolean;
  disabled: boolean;
};

function SignUpButton({startSignUp, loading, disabled}: SignUpButtonProps) {
  const intl = useIntl();
  return (
    <LinkButton
      onPress={startSignUp}
      pending={loading}
      disabled={disabled}
      data-testid="link-start-sign-up"
    >
      {intl.formatMessage(messages.saveYourId)}
    </LinkButton>
  );
}
