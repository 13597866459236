import {cancel} from '@sail/icons/react/Icon';
import {view, css, Icon} from '@sail/ui';
import * as React from 'react';

import BottomSheet from 'gelato/frontend/src/components/Link/BottomSheet/BottomSheet';
import LinkLogo from 'gelato/frontend/src/components/Link/LinkLogo';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

type LinkHeaderProps = {
  title: string;
  subtitle?: string;
};

export default function LinkHeader({title, subtitle}: LinkHeaderProps) {
  const {appController} = useAppController();
  const handleClick = () => {
    appController.closeLayer(BottomSheet);
  };
  return (
    <>
      <view.div>
        <view.div
          uses={[Styles.topLine]}
          style={{
            display: 'flex',
          }}
        >
          <view.div
            uses={[Styles.logoWrapper]}
            style={{
              flex: '1 0 100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LinkLogo type="green" />
          </view.div>
          <view.div
            uses={[Styles.iconWrapper]}
            style={{
              marginLeft: '-16px',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={handleClick}
          >
            <Icon icon={cancel} />
          </view.div>
        </view.div>
      </view.div>
      <view.div>
        <view.div uses={[Styles.heading]}>{title}</view.div>
        {subtitle && <view.div uses={[Styles.subheading]}>{subtitle}</view.div>}
      </view.div>
    </>
  );
}
const Styles = {
  topLine: css({
    paddingBottom: 'large',
  }),
  logoWrapper: css({
    alignSelfX: 'center',
  }),
  headingContainer: css({}),
  heading: css({
    font: 'heading.xlarge',
  }),
  iconWrapper: css({
    ':hover': {
      cursor: 'pointer',
    },
  }),
  subheading: css({
    font: 'body.medium',
    marginTop: 'medium',
  }),
};
