import {
  getMajorAndroidVersion,
  getMajorIOSVersion,
  getMajorSafariVersion,
  isAndroid,
  isAndroidWebView,
  isIOSUA,
  isLinux,
  isMac,
  isMobileUA,
  isSafariUA,
  isWindows,
} from 'gelato/frontend/src/lib/userAgent';

import type {VerificationIntentErrorDetail} from '@stripe-internal/data-gelato/schema/types';

// The Network Information API provides information about the system's
// connection.
// See https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API
type NetworkInformation = {
  downlink: number;
  effectiveType: 'slow-2g' | '2g' | '3g' | '4g' | '5g';
  rtt: number;
  saveData: boolean;
};

type DeviceDebugInfo = {
  androidMajorVersion: number;
  cameraLabels: Array<string>;
  errorMessage: string | null;
  errorName: string | null;
  errorReason: string | null;
  errorType: string | null;
  hasWebRTC: boolean;
  iOSMajorVersion: number;
  isAndroid: boolean;
  isAndroidWebView: boolean;
  isIOS: boolean;
  isRNWebView: boolean;
  isUIWebView: boolean;
  isWindows: boolean;
  isWKWebView: boolean;
};

/**
 * See https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
 */
export function getNetworkInformation(): Readonly<NetworkInformation> | null {
  // @ts-expect-error Property 'connection' does not exist on type 'Navigator'.ts(2339)
  const connection: NetworkInformation | null = window.navigator.connection;
  if (!connection) {
    return null;
  }
  const {downlink, effectiveType, rtt, saveData} = connection;
  // Return as JSON value so that it can be serialized.
  return {downlink, effectiveType, rtt, saveData};
}

export function isMobileDevice(): boolean {
  return isMobileUA() || isAndroidDesktop() || isIOSDesktop();
}

// Whether it's an IOS browser requesting desktop site.
export function isIOSDesktop(): boolean {
  // IOS browser => Request Desktop Site.
  return isMac() && window.navigator.maxTouchPoints > 1;
}

export function isIOS(): boolean {
  return isIOSUA() || isIOSDesktop();
}

/**
 * If the device can use the HTML Input element (e.g.
 * <input type="file" accept="image/*" capture="environment" />)
 * to capture image from its camera app.
 */
export function canUseInputImageCapture(): boolean {
  // https://caniuse.com/mdn-api_htmlinputelement_capture
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/capture
  if (!isMobileDevice()) {
    return false;
  }
  const el = document.createElement('input');
  el.setAttribute('capture', 'environment');
  // Unsopported browsers would return `undefined` for `el.capture`.
  return el.capture === 'environment';
}

export function isIOS15OrGreater(): boolean {
  if (isIOSDesktop()) {
    // In the past, the version of Safari has been in sync with the version of
    // iOS. Therefore, we could use Safari's version as a proxy to determine
    // the version of iOS. However, it's not possible to determine the iOS
    // version using other types of browsers.
    return getMajorSafariVersion() >= 15;
  } else {
    // iOS Mobile.
    return getMajorIOSVersion() >= 15;
  }
}

// Whether it's an Android browser requesting desktop site.
export function isAndroidDesktop(): boolean {
  // Android browser => Request Desktop Site.
  return isLinux() && window.navigator.maxTouchPoints > 1;
}

// Error message raised by react-webcam.tsx from
// https://github.com/mozmorris/react-webcam/blob/10fdd2125339e9e8b92819bab93e509eeb92528e/src/react-webcam.tsx#L30
const REACT_WEBCAM_WEB_RTC_NOT_IMPLEMENTED_ERROR =
  /getUserMedia\sis\snot\simplemented/;

// Create the error that can be reasoned as error.
// See errorToReason() below.
export function reasonToError(
  reason: VerificationIntentErrorDetail,
  message?: string,
): Error {
  let name = '';
  switch (reason) {
    case 'no_web_rtc':
      name = 'NoWebRtcError';
      break;
    case 'permission_denied':
      name = 'PermissionDeniedError';
      break;
    case 'no_webcam':
      name = 'DevicesNotFoundError';
      break;
    case 'unknown':
      name = 'UnknownError';
      break;
    default:
      name = 'UnknownError';
      break;
  }
  const error = new Error(message || `[reason]: ${reason}`);
  error.name = name;
  return error;
}

export function errorToReason(
  error?: Error | null,
): VerificationIntentErrorDetail {
  // https://blog.addpipe.com/common-getusermedia-errors/
  const errorName = error?.name || '';
  const errorMessage = error?.message || '';
  let reason: VerificationIntentErrorDetail = 'unknown';

  if (errorName === 'NotFoundError' || errorName === 'DevicesNotFoundError') {
    reason = 'no_webcam';
  } else if (
    errorName === 'NotAllowedError' ||
    errorName === 'PermissionDeniedError'
  ) {
    reason = 'permission_denied';
  } else if (
    errorName === 'NoWebRtcError' ||
    REACT_WEBCAM_WEB_RTC_NOT_IMPLEMENTED_ERROR.test(errorMessage)
  ) {
    reason = 'no_web_rtc';
  } else if (errorName === 'NotReadableError') {
    reason = 'not_readable';
  }
  return reason;
}

function getErrorType(error: Error): string {
  return Object.prototype.toString.call(error).replace(/object\s+/, '');
}

function isVideoDevice(info: MediaDeviceInfo) {
  return info.kind === 'videoinput';
}

function hasDevicePermissions(info: MediaDeviceInfo) {
  return !!(info.deviceId && info.label);
}

export async function getDebugInfo(): Promise<DeviceDebugInfo> {
  const webview: any = window;
  const {mediaDevices} = window.navigator;
  const {getUserMedia, enumerateDevices} = mediaDevices ?? {};
  const hasWebRTC = !!getUserMedia && !!enumerateDevices;

  const cameras: Array<MediaDeviceInfo> = await new Promise(async (resolve) => {
    if (mediaDevices?.enumerateDevices) {
      const devices = await mediaDevices.enumerateDevices();
      resolve(devices?.filter(isVideoDevice) || []);
    } else {
      resolve([]);
    }
  });

  const allowedBrowserCameras = cameras.filter(hasDevicePermissions);

  // https://developer.apple.com/documentation/webkit/wkscriptmessagehandler
  const isWKWebView =
    !!webview.webkit?.messageHandlers && !isSafariUA() && isIOSUA();

  // UIWebView does not support IndexedDB , but WKWebView does.
  const isUIWebView =
    !isWKWebView &&
    !webview.statusbar?.visible &&
    !webview.indexedDB &&
    !isSafariUA() &&
    isIOSUA();

  // https://stackoverflow.com/questions/44843064/determine-if-running-inside-a-react-native-webview
  const isRNWebView = !!webview.originalPostMessage;

  const debugError: Error | null = await new Promise(async (resolve) => {
    if (!hasWebRTC) {
      resolve(reasonToError('no_web_rtc'));
      return;
    }

    if (!cameras.length) {
      resolve(reasonToError('no_webcam'));
      return;
    }

    if (!allowedBrowserCameras.length) {
      resolve(
        reasonToError(
          'permission_denied',
          `allowedBrowserCameras.length = ${allowedBrowserCameras.length}`,
        ),
      );
      return;
    }

    try {
      // This detects the system-level error such as another application might
      // use the camera or the system setting does not allow camera.
      const stream = await getUserMedia.call(mediaDevices, {video: true});
      stream.getTracks().forEach((track) => track.stop());
      resolve(null);
    } catch (err) {
      if (err instanceof Error) {
        resolve(err);
      } else {
        resolve(reasonToError('unknown', String(err)));
      }
    }
  });

  return {
    androidMajorVersion: getMajorAndroidVersion(),
    cameraLabels: cameras.map((info) => info.label || 'not_allowed'),
    errorName: debugError?.name || null,
    errorMessage: debugError?.message || null,
    errorType: debugError ? getErrorType(debugError) : null,
    errorReason: debugError ? errorToReason(debugError) : null,
    hasWebRTC,
    iOSMajorVersion: getMajorIOSVersion(),
    isAndroid: isAndroid(),
    isIOS: isIOSUA(),
    isRNWebView,
    isUIWebView,
    isWindows: isWindows(),
    isWKWebView,
    isAndroidWebView: isAndroidWebView(),
  };
}
