import {useState} from 'react';
import {useTokenValue} from '@sail/engine';

import {tokens} from '../../tokens';

import {useResizeObserver} from './useResizeObserver';
import {
  anyCoarsePointerCheck,
  smallScreenCheck,
} from '../utils/mobileDeviceCheck';

export const useIsMobileAndTouchCheck = (): boolean => {
  const viewport = useTokenValue(tokens.viewport.small);

  const isSmallTouchScreen = () =>
    smallScreenCheck(viewport) && anyCoarsePointerCheck();
  const [isMobileAndTouch, setIsMobileAndTouch] = useState(isSmallTouchScreen);

  const handleResize = () => {
    setIsMobileAndTouch(isSmallTouchScreen());
  };

  useResizeObserver({
    node: typeof window !== 'undefined' ? window : null,
    handler: handleResize,
    avoidInitialEvent: true,
  });

  return isMobileAndTouch;
};
