import {env} from 'process';

import {Analytics} from '@sail/observability';
import * as Sentry from '@sentry/browser';

import formatEventName from 'gelato/frontend/src/lib/analytics/formatEventName';
import formatEventParams from 'gelato/frontend/src/lib/analytics/formatEventParams';
import {logInDev} from 'gelato/frontend/src/lib/assert';
import flags from 'gelato/frontend/src/lib/flags';
import {handleException} from 'gelato/frontend/src/lib/sentry';

import type {
  AnalyticsClient,
  AnalyticsEventName,
  AnalyticsParams,
  AnalyticsMethod,
  AnalyticsConfig,
} from 'gelato/frontend/src/lib/analytics/types';

/**
 * The main analytics client. This is a composite analytics client that
 * sends data to its child analytics clients. We'd use this as the primary
 * analytics instance for our app.
 *
 * The actual setup of these analytics client are done at
 * `setupAnalyticsClient()`.
 */
export default class MainAnalyticsClient
  implements AnalyticsClient<AnalyticsEventName>
{
  /**
   * The child analytics clients that the main analytics send the events to.
   */
  _childClients: Array<AnalyticsClient<string>> = [];

  /**
   * The global params that should be sent to every event.
   */
  _globalParams: Readonly<AnalyticsParams> = {};

  /**
   * Whether the it's sending event.
   */
  _isSendingEvent = false;

  /**
   * Number of skipped tracking events.
   */
  skippedTrackingEventsCount = 0;

  addGlobalParams(params: AnalyticsParams): MainAnalyticsClient {
    const {length} = this._childClients;
    if (length > 0) {
      throw new Error(
        `global params should be added before using any child analytics ` +
          ` client. Found ${length} child analytics clients`,
      );
    }

    const formatedParams = formatEventParams(params) || {};

    this._globalParams = Object.freeze({
      ...this._globalParams,
      ...patchExperimentParams(formatedParams),
    });

    return this;
  }

  getGlobalParams(): Readonly<AnalyticsParams> {
    return this._globalParams;
  }

  addChildClient(analytics: AnalyticsClient<string>): MainAnalyticsClient {
    this._childClients.push(analytics);
    return this;
  }

  reset(): MainAnalyticsClient {
    this._globalParams = {};
    this._childClients = [];
    return this;
  }

  action = async (
    event: AnalyticsEventName,
    params?: AnalyticsParams,
    config?: AnalyticsConfig,
  ) => {
    if (flags.isActive('idprod_frontend_analytics_update')) {
      return this._sendEvent('action', event, params, config);
    } else {
      this._sendEvent('action', event, params, config);
    }
  };

  link = async (
    event: AnalyticsEventName,
    params?: AnalyticsParams,
    config?: AnalyticsConfig,
  ) => {
    if (flags.isActive('idprod_frontend_analytics_update')) {
      return this._sendEvent('link', event, params, config);
    } else {
      this._sendEvent('link', event, params, config);
    }
  };

  modal = async (
    event: AnalyticsEventName,
    params?: AnalyticsParams,
    config?: AnalyticsConfig,
  ) => {
    if (flags.isActive('idprod_frontend_analytics_update')) {
      return this._sendEvent('modal', event, params, config);
    } else {
      this._sendEvent('modal', event, params, config);
    }
  };

  track = async (
    event: AnalyticsEventName,
    params?: AnalyticsParams,
    config?: AnalyticsConfig,
  ) => {
    if (flags.isActive('idprod_frontend_analytics_update')) {
      return this._sendEvent('track', event, params, config);
    } else {
      this._sendEvent('track', event, params, config);
    }
  };

  viewed = async (
    event: AnalyticsEventName,
    params?: AnalyticsParams,
    config?: AnalyticsConfig,
  ) => {
    if (flags.isActive('idprod_frontend_analytics_update')) {
      return this._sendEvent('viewed', event, params, config);
    } else {
      this._sendEvent('viewed', event, params, config);
    }
  };

  _sendEvent = async (
    method: AnalyticsMethod,
    event: AnalyticsEventName,
    params?: AnalyticsParams,
    config?: AnalyticsConfig,
  ) => {
    if (flags.isActive('idprod_frontend_analytics_update')) {
      if (this._isSendingEvent) {
        console.warn('_isSendingEvent is true');
      }
      const eventName = formatEventName(event);
      const formatedParams = {
        ...this._globalParams,
        ...formatEventParams(params),
      };

      logInDev('analytics', method, eventName, formatedParams);
      this._isSendingEvent = true;
      const responses = await Promise.allSettled(
        this._childClients.map((child) =>
          child[method](eventName, formatedParams),
        ),
      );

      logInDev('analytics - responses', responses);

      const allSucceeded = responses.every((result) => {
        if (result.status === 'rejected') {
          return false;
        }
        return true;
      });

      responses.forEach((result) => {
        if (result.status === 'rejected') {
          this.track('analyticsError', {
            error_message: result.reason.message,
          });
        }
      });

      this._isSendingEvent = false;

      if (allSucceeded && config?.onSuccess) {
        logInDev('analytics - onSuccess');
        config.onSuccess();
      }
    } else {
      if (this._isSendingEvent) {
        // Nested tracking loop happened, we should skip it to avoid stacks
        // overflow.
        // Record this for debugging or testing purpose.
        this.skippedTrackingEventsCount++;

        // eslint-disable-next-line no-console
        console.warn(`skip nested tracking ${this.skippedTrackingEventsCount}`);
        return;
      }

      try {
        this._isSendingEvent = true;
        const eventName = formatEventName(event);
        const formatedParams = {
          ...this._globalParams,
          ...formatEventParams(params),
        };
        this._childClients.forEach((child) =>
          child[method](eventName, formatedParams),
        );
        logInDev('analytics', method, eventName, formatedParams);
      } catch (ex) {
        const error = ex instanceof Error ? ex : new Error(String(ex));
        // Sentry would pick up the error sent via `console.error()`.
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this._isSendingEvent = false;
      }
    }
  };
}

/**
 * By default, the experiment keys are formatted as snake cases. Therefore,
 * the experiment name ends with version suffix (e.g "my_experiment_v1") would
 * be formatted as "my_experiment_v_1". This function patches the experiment
 * params to make sure the experiment keys are consistent with their names.
 * @param params The params to patch.
 * @returns The patched params.
 */
export function patchExperimentParams(
  params: AnalyticsParams,
): AnalyticsParams {
  const jsonText = params.experiments;

  if (typeof jsonText !== 'string' || !jsonText) {
    // No experiments to patch.
    return params;
  }

  const experiments = JSON.parse(jsonText);

  // This matches the experiment key that has the wrong version suffix.
  // For example, `my_experiment_v_1`.
  const versionPattern = /_v_(\d+)$/;

  Object.keys(experiments).forEach((key) => {
    const matches = key.match(versionPattern);
    if (matches) {
      // If the key ends with version suffix, we'd fix it.
      // E.g. `my_experiment_v_1` => `my_experiment_v1`.
      const prefix = key.replace(versionPattern, '');
      const suffix = `_v${matches![1]}`;
      const newKey = `${prefix}${suffix}`;
      const value = experiments[key];
      delete experiments[key];
      experiments[newKey] = value;
    }
  });

  return {
    ...params,
    experiments: JSON.stringify(experiments),
  };
}
