import {defineMessages} from 'react-intl';

import {MessageDescriptorMap} from 'gelato/frontend/src/components/Message';
import supportedCountries from 'gelato/frontend/src/generated/supportedCountries';

import type {
  Country,
  DocumentTypes,
} from '@stripe-internal/data-gelato/schema/types';

type SupportedCountries = Partial<
  Record<
    Country,
    {
      documentTypes: Array<DocumentTypes>;
    }
  >
>;

export const SUPPORTED_COUNTRIES = supportedCountries as SupportedCountries;

// @ts-expect-error - TS2322 - Type 'string[]' is not assignable to type 'Country[]'.
export const COUNTRY_LIST: Array<Country> = Object.keys(SUPPORTED_COUNTRIES);

export const COUNTRY_SELECT_OPTION_MESSAGES: MessageDescriptorMap =
  defineMessages({
    AD: {
      id: 'country_select.option.AD',
      description: 'Country picker. This value is AD.',
      defaultMessage: 'Andorra',
    },
    AE: {
      id: 'country_select.option.AE',
      description: 'Country picker. This value is AE.',
      defaultMessage: 'United Arab Emirates',
    },
    AF: {
      id: 'country_select.option.AF',
      description: 'Country picker. This value is AF.',
      defaultMessage: 'Afghanistan',
    },
    AG: {
      id: 'country_select.option.AG',
      description: 'Country picker. This value is AG.',
      defaultMessage: 'Antigua and Barbuda',
    },
    AI: {
      id: 'country_select.option.AI',
      description: 'Country picker. This value is AI.',
      defaultMessage: 'Anguilla',
    },
    AL: {
      id: 'country_select.option.AL',
      description: 'Country picker. This value is AL.',
      defaultMessage: 'Albania',
    },
    AM: {
      id: 'country_select.option.AM',
      description: 'Country picker. This value is AM.',
      defaultMessage: 'Armenia',
    },
    AO: {
      id: 'country_select.option.AO',
      description: 'Country picker. This value is AO.',
      defaultMessage: 'Angola',
    },
    AQ: {
      id: 'country_select.option.AQ',
      description: 'Country picker. This value is AQ.',
      defaultMessage: 'Antarctica',
    },
    AR: {
      id: 'country_select.option.AR',
      description: 'Country picker. This value is AR.',
      defaultMessage: 'Argentina',
    },
    AS: {
      id: 'country_select.option.AS',
      description: 'Country picker. This value is AS.',
      defaultMessage: 'American Samoa',
    },
    AT: {
      id: 'country_select.option.AT',
      description: 'Country picker. This value is AT.',
      defaultMessage: 'Austria',
    },
    AU: {
      id: 'country_select.option.AU',
      description: 'Country picker. This value is AU.',
      defaultMessage: 'Australia',
    },
    AW: {
      id: 'country_select.option.AW',
      description: 'Country picker. This value is AW.',
      defaultMessage: 'Aruba',
    },
    AX: {
      id: 'country_select.option.AX',
      description: 'Country picker. This value is AX.',
      defaultMessage: 'Åland Islands',
    },
    AZ: {
      id: 'country_select.option.AZ',
      description: 'Country picker. This value is AZ.',
      defaultMessage: 'Azerbaijan',
    },
    BA: {
      id: 'country_select.option.BA',
      description: 'Country picker. This value is BA.',
      defaultMessage: 'Bosnia and Herzegovina',
    },
    BB: {
      id: 'country_select.option.BB',
      description: 'Country picker. This value is BB.',
      defaultMessage: 'Barbados',
    },
    BD: {
      id: 'country_select.option.BD',
      description: 'Country picker. This value is BD.',
      defaultMessage: 'Bangladesh',
    },
    BE: {
      id: 'country_select.option.BE',
      description: 'Country picker. This value is BE.',
      defaultMessage: 'Belgium',
    },
    BF: {
      id: 'country_select.option.BF',
      description: 'Country picker. This value is BF.',
      defaultMessage: 'Burkina Faso',
    },
    BG: {
      id: 'country_select.option.BG',
      description: 'Country picker. This value is BG.',
      defaultMessage: 'Bulgaria',
    },
    BH: {
      id: 'country_select.option.BH',
      description: 'Country picker. This value is BH.',
      defaultMessage: 'Bahrain',
    },
    BI: {
      id: 'country_select.option.BI',
      description: 'Country picker. This value is BI.',
      defaultMessage: 'Burundi',
    },
    BJ: {
      id: 'country_select.option.BJ',
      description: 'Country picker. This value is BJ.',
      defaultMessage: 'Benin',
    },
    BL: {
      id: 'country_select.option.BL',
      description: 'Country picker. This value is BL.',
      defaultMessage: 'Saint Barthélemy',
    },
    BM: {
      id: 'country_select.option.BM',
      description: 'Country picker. This value is BM.',
      defaultMessage: 'Bermuda',
    },
    BN: {
      id: 'country_select.option.BN',
      description: 'Country picker. This value is BN.',
      defaultMessage: 'Brunei Darussalam',
    },
    BO: {
      id: 'country_select.option.BO',
      description: 'Country picker. This value is BO.',
      defaultMessage: 'Bolivia',
    },
    BQ: {
      id: 'country_select.option.BQ',
      description: 'Country picker. This value is BQ.',
      defaultMessage: 'Bonaire, Sint Eustatius and Saba',
    },
    BR: {
      id: 'country_select.option.BR',
      description: 'Country picker. This value is BR.',
      defaultMessage: 'Brazil',
    },
    BS: {
      id: 'country_select.option.BS',
      description: 'Country picker. This value is BS.',
      defaultMessage: 'Bahamas',
    },
    BT: {
      id: 'country_select.option.BT',
      description: 'Country picker. This value is BT.',
      defaultMessage: 'Bhutan',
    },
    BV: {
      id: 'country_select.option.BV',
      description: 'Country picker. This value is BV.',
      defaultMessage: 'Bouvet Island',
    },
    BW: {
      id: 'country_select.option.BW',
      description: 'Country picker. This value is BW.',
      defaultMessage: 'Botswana',
    },
    BY: {
      id: 'country_select.option.BY',
      description: 'Country picker. This value is BY.',
      defaultMessage: 'Belarus',
    },
    BZ: {
      id: 'country_select.option.BZ',
      description: 'Country picker. This value is BZ.',
      defaultMessage: 'Belize',
    },
    CA: {
      id: 'country_select.option.CA',
      description: 'Country picker. This value is CA.',
      defaultMessage: 'Canada',
    },
    CC: {
      id: 'country_select.option.CC',
      description: 'Country picker. This value is CC.',
      defaultMessage: 'Cocos (Keeling) Islands',
    },
    CD: {
      id: 'country_select.option.CD',
      description: 'Country picker. This value is CD.',
      defaultMessage: 'Congo, the Democratic Republic of the',
    },
    CF: {
      id: 'country_select.option.CF',
      description: 'Country picker. This value is CF.',
      defaultMessage: 'Central African Republic',
    },
    CG: {
      id: 'country_select.option.CG',
      description: 'Country picker. This value is CG.',
      defaultMessage: 'Congo',
    },
    CH: {
      id: 'country_select.option.CH',
      description: 'Country picker. This value is CH.',
      defaultMessage: 'Switzerland',
    },
    CI: {
      id: 'country_select.option.CI',
      description: 'Country picker. This value is CI.',
      defaultMessage: "Cote D'Ivoire",
    },
    CK: {
      id: 'country_select.option.CK',
      description: 'Country picker. This value is CK.',
      defaultMessage: 'Cook Islands',
    },
    CL: {
      id: 'country_select.option.CL',
      description: 'Country picker. This value is CL.',
      defaultMessage: 'Chile',
    },
    CM: {
      id: 'country_select.option.CM',
      description: 'Country picker. This value is CM.',
      defaultMessage: 'Cameroon',
    },
    CN: {
      id: 'country_select.option.CN',
      description: 'Country picker. This value is CN.',
      defaultMessage: 'China',
    },
    CO: {
      id: 'country_select.option.CO',
      description: 'Country picker. This value is CO.',
      defaultMessage: 'Colombia',
    },
    CR: {
      id: 'country_select.option.CR',
      description: 'Country picker. This value is CR.',
      defaultMessage: 'Costa Rica',
    },
    CU: {
      id: 'country_select.option.CU',
      description: 'Country picker. This value is CU.',
      defaultMessage: 'Cuba',
    },
    CV: {
      id: 'country_select.option.CV',
      description: 'Country picker. This value is CV.',
      defaultMessage: 'Cape Verde',
    },
    CW: {
      id: 'country_select.option.CW',
      description: 'Country picker. This value is CW.',
      defaultMessage: 'Curaçao',
    },
    CX: {
      id: 'country_select.option.CX',
      description: 'Country picker. This value is CX.',
      defaultMessage: 'Christmas Island',
    },
    CY: {
      id: 'country_select.option.CY',
      description: 'Country picker. This value is CY.',
      defaultMessage: 'Cyprus',
    },
    CZ: {
      id: 'country_select.option.CZ',
      description: 'Country picker. This value is CZ.',
      defaultMessage: 'Czech Republic',
    },
    DE: {
      id: 'country_select.option.DE',
      description: 'Country picker. This value is DE.',
      defaultMessage: 'Germany',
    },
    DJ: {
      id: 'country_select.option.DJ',
      description: 'Country picker. This value is DJ.',
      defaultMessage: 'Djibouti',
    },
    DK: {
      id: 'country_select.option.DK',
      description: 'Country picker. This value is DK.',
      defaultMessage: 'Denmark',
    },
    DM: {
      id: 'country_select.option.DM',
      description: 'Country picker. This value is DM.',
      defaultMessage: 'Dominica',
    },
    DO: {
      id: 'country_select.option.DO',
      description: 'Country picker. This value is DO.',
      defaultMessage: 'Dominican Republic',
    },
    DZ: {
      id: 'country_select.option.DZ',
      description: 'Country picker. This value is DZ.',
      defaultMessage: 'Algeria',
    },
    EC: {
      id: 'country_select.option.EC',
      description: 'Country picker. This value is EC.',
      defaultMessage: 'Ecuador',
    },
    EE: {
      id: 'country_select.option.EE',
      description: 'Country picker. This value is EE.',
      defaultMessage: 'Estonia',
    },
    EG: {
      id: 'country_select.option.EG',
      description: 'Country picker. This value is EG.',
      defaultMessage: 'Egypt',
    },
    EH: {
      id: 'country_select.option.EH',
      description: 'Country picker. This value is EH.',
      defaultMessage: 'Western Sahara',
    },
    ER: {
      id: 'country_select.option.ER',
      description: 'Country picker. This value is ER.',
      defaultMessage: 'Eritrea',
    },
    ES: {
      id: 'country_select.option.ES',
      description: 'Country picker. This value is ES.',
      defaultMessage: 'Spain',
    },
    ET: {
      id: 'country_select.option.ET',
      description: 'Country picker. This value is ET.',
      defaultMessage: 'Ethiopia',
    },
    FI: {
      id: 'country_select.option.FI',
      description: 'Country picker. This value is FI.',
      defaultMessage: 'Finland',
    },
    FJ: {
      id: 'country_select.option.FJ',
      description: 'Country picker. This value is FJ.',
      defaultMessage: 'Fiji',
    },
    FK: {
      id: 'country_select.option.FK',
      description: 'Country picker. This value is FK.',
      defaultMessage: 'Falkland Islands (Malvinas)',
    },
    FM: {
      id: 'country_select.option.FM',
      description: 'Country picker. This value is FM.',
      defaultMessage: 'Micronesia, Federated States of',
    },
    FO: {
      id: 'country_select.option.FO',
      description: 'Country picker. This value is FO.',
      defaultMessage: 'Faroe Islands',
    },
    FR: {
      id: 'country_select.option.FR',
      description: 'Country picker. This value is FR.',
      defaultMessage: 'France',
    },
    GA: {
      id: 'country_select.option.GA',
      description: 'Country picker. This value is GA.',
      defaultMessage: 'Gabon',
    },
    GB: {
      id: 'country_select.option.GB',
      description: 'Country picker. This value is GB.',
      defaultMessage: 'United Kingdom',
    },
    GD: {
      id: 'country_select.option.GD',
      description: 'Country picker. This value is GD.',
      defaultMessage: 'Grenada',
    },
    GE: {
      id: 'country_select.option.GE',
      description: 'Country picker. This value is GE.',
      defaultMessage: 'Georgia',
    },
    GF: {
      id: 'country_select.option.GF',
      description: 'Country picker. This value is GF.',
      defaultMessage: 'French Guiana',
    },
    GG: {
      id: 'country_select.option.GG',
      description: 'Country picker. This value is GG.',
      defaultMessage: 'Guernsey',
    },
    GH: {
      id: 'country_select.option.GH',
      description: 'Country picker. This value is GH.',
      defaultMessage: 'Ghana',
    },
    GI: {
      id: 'country_select.option.GI',
      description: 'Country picker. This value is GI.',
      defaultMessage: 'Gibraltar',
    },
    GL: {
      id: 'country_select.option.GL',
      description: 'Country picker. This value is GL.',
      defaultMessage: 'Greenland',
    },
    GM: {
      id: 'country_select.option.GM',
      description: 'Country picker. This value is GM.',
      defaultMessage: 'Gambia',
    },
    GN: {
      id: 'country_select.option.GN',
      description: 'Country picker. This value is GN.',
      defaultMessage: 'Guinea',
    },
    GP: {
      id: 'country_select.option.GP',
      description: 'Country picker. This value is GP.',
      defaultMessage: 'Guadeloupe',
    },
    GQ: {
      id: 'country_select.option.GQ',
      description: 'Country picker. This value is GQ.',
      defaultMessage: 'Equatorial Guinea',
    },
    GR: {
      id: 'country_select.option.GR',
      description: 'Country picker. This value is GR.',
      defaultMessage: 'Greece',
    },
    GS: {
      id: 'country_select.option.GS',
      description: 'Country picker. This value is GS.',
      defaultMessage: 'South Georgia and the South Sandwich Islands',
    },
    GT: {
      id: 'country_select.option.GT',
      description: 'Country picker. This value is GT.',
      defaultMessage: 'Guatemala',
    },
    GU: {
      id: 'country_select.option.GU',
      description: 'Country picker. This value is GU.',
      defaultMessage: 'Guam',
    },
    GW: {
      id: 'country_select.option.GW',
      description: 'Country picker. This value is GW.',
      defaultMessage: 'Guinea-Bissau',
    },
    GY: {
      id: 'country_select.option.GY',
      description: 'Country picker. This value is GY.',
      defaultMessage: 'Guyana',
    },
    HK: {
      id: 'country_select.option.HK',
      description: 'Country picker. This value is HK.',
      defaultMessage: 'Hong Kong',
    },
    HM: {
      id: 'country_select.option.HM',
      description: 'Country picker. This value is HM.',
      defaultMessage: 'Heard Island and Mcdonald Islands',
    },
    HN: {
      id: 'country_select.option.HN',
      description: 'Country picker. This value is HN.',
      defaultMessage: 'Honduras',
    },
    HR: {
      id: 'country_select.option.HR',
      description: 'Country picker. This value is HR.',
      defaultMessage: 'Croatia',
    },
    HT: {
      id: 'country_select.option.HT',
      description: 'Country picker. This value is HT.',
      defaultMessage: 'Haiti',
    },
    HU: {
      id: 'country_select.option.HU',
      description: 'Country picker. This value is HU.',
      defaultMessage: 'Hungary',
    },
    ID: {
      id: 'country_select.option.ID',
      description: 'Country picker. This value is ID.',
      defaultMessage: 'Indonesia',
    },
    IE: {
      id: 'country_select.option.IE',
      description: 'Country picker. This value is IE.',
      defaultMessage: 'Ireland',
    },
    IL: {
      id: 'country_select.option.IL',
      description: 'Country picker. This value is IL.',
      defaultMessage: 'Israel',
    },
    IM: {
      id: 'country_select.option.IM',
      description: 'Country picker. This value is IM.',
      defaultMessage: 'Isle of Man',
    },
    IN: {
      id: 'country_select.option.IN',
      description: 'Country picker. This value is IN.',
      defaultMessage: 'India',
    },
    IO: {
      id: 'country_select.option.IO',
      description: 'Country picker. This value is IO.',
      defaultMessage: 'British Indian Ocean Territory',
    },
    IQ: {
      id: 'country_select.option.IQ',
      description: 'Country picker. This value is IQ.',
      defaultMessage: 'Iraq',
    },
    IR: {
      id: 'country_select.option.IR',
      description: 'Country picker. This value is IR.',
      defaultMessage: 'Iran, Islamic Republic of',
    },
    IS: {
      id: 'country_select.option.IS',
      description: 'Country picker. This value is IS.',
      defaultMessage: 'Iceland',
    },
    IT: {
      id: 'country_select.option.IT',
      description: 'Country picker. This value is IT.',
      defaultMessage: 'Italy',
    },
    JE: {
      id: 'country_select.option.JE',
      description: 'Country picker. This value is JE.',
      defaultMessage: 'Jersey',
    },
    JM: {
      id: 'country_select.option.JM',
      description: 'Country picker. This value is JM.',
      defaultMessage: 'Jamaica',
    },
    JO: {
      id: 'country_select.option.JO',
      description: 'Country picker. This value is JO.',
      defaultMessage: 'Jordan',
    },
    JP: {
      id: 'country_select.option.JP',
      description: 'Country picker. This value is JP.',
      defaultMessage: 'Japan',
    },
    KE: {
      id: 'country_select.option.KE',
      description: 'Country picker. This value is KE.',
      defaultMessage: 'Kenya',
    },
    KG: {
      id: 'country_select.option.KG',
      description: 'Country picker. This value is KG.',
      defaultMessage: 'Kyrgyzstan',
    },
    KH: {
      id: 'country_select.option.KH',
      description: 'Country picker. This value is KH.',
      defaultMessage: 'Cambodia',
    },
    KI: {
      id: 'country_select.option.KI',
      description: 'Country picker. This value is KI.',
      defaultMessage: 'Kiribati',
    },
    KM: {
      id: 'country_select.option.KM',
      description: 'Country picker. This value is KM.',
      defaultMessage: 'Comoros',
    },
    KN: {
      id: 'country_select.option.KN',
      description: 'Country picker. This value is KN.',
      defaultMessage: 'Saint Kitts and Nevis',
    },
    KP: {
      id: 'country_select.option.KP',
      description: 'Country picker. This value is KP.',
      defaultMessage: 'North Korea',
    },
    KR: {
      id: 'country_select.option.KR',
      description: 'Country picker. This value is KR.',
      defaultMessage: 'South Korea',
    },
    KW: {
      id: 'country_select.option.KW',
      description: 'Country picker. This value is KW.',
      defaultMessage: 'Kuwait',
    },
    KY: {
      id: 'country_select.option.KY',
      description: 'Country picker. This value is KY.',
      defaultMessage: 'Cayman Islands',
    },
    KZ: {
      id: 'country_select.option.KZ',
      description: 'Country picker. This value is KZ.',
      defaultMessage: 'Kazakhstan',
    },
    LA: {
      id: 'country_select.option.LA',
      description: 'Country picker. This value is LA.',
      defaultMessage: "Lao People's Democratic Republic",
    },
    LB: {
      id: 'country_select.option.LB',
      description: 'Country picker. This value is LB.',
      defaultMessage: 'Lebanon',
    },
    LC: {
      id: 'country_select.option.LC',
      description: 'Country picker. This value is LC.',
      defaultMessage: 'Saint Lucia',
    },
    LI: {
      id: 'country_select.option.LI',
      description: 'Country picker. This value is LI.',
      defaultMessage: 'Liechtenstein',
    },
    LK: {
      id: 'country_select.option.LK',
      description: 'Country picker. This value is LK.',
      defaultMessage: 'Sri Lanka',
    },
    LR: {
      id: 'country_select.option.LR',
      description: 'Country picker. This value is LR.',
      defaultMessage: 'Liberia',
    },
    LS: {
      id: 'country_select.option.LS',
      description: 'Country picker. This value is LS.',
      defaultMessage: 'Lesotho',
    },
    LT: {
      id: 'country_select.option.LT',
      description: 'Country picker. This value is LT.',
      defaultMessage: 'Lithuania',
    },
    LU: {
      id: 'country_select.option.LU',
      description: 'Country picker. This value is LU.',
      defaultMessage: 'Luxembourg',
    },
    LV: {
      id: 'country_select.option.LV',
      description: 'Country picker. This value is LV.',
      defaultMessage: 'Latvia',
    },
    LY: {
      id: 'country_select.option.LY',
      description: 'Country picker. This value is LY.',
      defaultMessage: 'Libya',
    },
    MA: {
      id: 'country_select.option.MA',
      description: 'Country picker. This value is MA.',
      defaultMessage: 'Morocco',
    },
    MC: {
      id: 'country_select.option.MC',
      description: 'Country picker. This value is MC.',
      defaultMessage: 'Monaco',
    },
    MD: {
      id: 'country_select.option.MD',
      description: 'Country picker. This value is MD.',
      defaultMessage: 'Moldova, Republic of',
    },
    ME: {
      id: 'country_select.option.ME',
      description: 'Country picker. This value is ME.',
      defaultMessage: 'Montenegro',
    },
    MF: {
      id: 'country_select.option.MF',
      description: 'Country picker. This value is MF.',
      defaultMessage: 'Saint Martin (French part)',
    },
    MG: {
      id: 'country_select.option.MG',
      description: 'Country picker. This value is MG.',
      defaultMessage: 'Madagascar',
    },
    MH: {
      id: 'country_select.option.MH',
      description: 'Country picker. This value is MH.',
      defaultMessage: 'Marshall Islands',
    },
    MK: {
      id: 'country_select.option.MK',
      description: 'Country picker. This value is MK.',
      defaultMessage: 'North Macedonia, Republic of',
    },
    ML: {
      id: 'country_select.option.ML',
      description: 'Country picker. This value is ML.',
      defaultMessage: 'Mali',
    },
    MM: {
      id: 'country_select.option.MM',
      description: 'Country picker. This value is MM.',
      defaultMessage: 'Myanmar',
    },
    MN: {
      id: 'country_select.option.MN',
      description: 'Country picker. This value is MN.',
      defaultMessage: 'Mongolia',
    },
    MO: {
      id: 'country_select.option.MO',
      description: 'Country picker. This value is MO.',
      defaultMessage: 'Macau',
    },
    MP: {
      id: 'country_select.option.MP',
      description: 'Country picker. This value is MP.',
      defaultMessage: 'Northern Mariana Islands',
    },
    MQ: {
      id: 'country_select.option.MQ',
      description: 'Country picker. This value is MQ.',
      defaultMessage: 'Martinique',
    },
    MR: {
      id: 'country_select.option.MR',
      description: 'Country picker. This value is MR.',
      defaultMessage: 'Mauritania',
    },
    MS: {
      id: 'country_select.option.MS',
      description: 'Country picker. This value is MS.',
      defaultMessage: 'Montserrat',
    },
    MT: {
      id: 'country_select.option.MT',
      description: 'Country picker. This value is MT.',
      defaultMessage: 'Malta',
    },
    MU: {
      id: 'country_select.option.MU',
      description: 'Country picker. This value is MU.',
      defaultMessage: 'Mauritius',
    },
    MV: {
      id: 'country_select.option.MV',
      description: 'Country picker. This value is MV.',
      defaultMessage: 'Maldives',
    },
    MW: {
      id: 'country_select.option.MW',
      description: 'Country picker. This value is MW.',
      defaultMessage: 'Malawi',
    },
    MX: {
      id: 'country_select.option.MX',
      description: 'Country picker. This value is MX.',
      defaultMessage: 'Mexico',
    },
    MY: {
      id: 'country_select.option.MY',
      description: 'Country picker. This value is MY.',
      defaultMessage: 'Malaysia',
    },
    MZ: {
      id: 'country_select.option.MZ',
      description: 'Country picker. This value is MZ.',
      defaultMessage: 'Mozambique',
    },
    NA: {
      id: 'country_select.option.NA',
      description: 'Country picker. This value is NA.',
      defaultMessage: 'Namibia',
    },
    NC: {
      id: 'country_select.option.NC',
      description: 'Country picker. This value is NC.',
      defaultMessage: 'New Caledonia',
    },
    NE: {
      id: 'country_select.option.NE',
      description: 'Country picker. This value is NE.',
      defaultMessage: 'Niger',
    },
    NF: {
      id: 'country_select.option.NF',
      description: 'Country picker. This value is NF.',
      defaultMessage: 'Norfolk Island',
    },
    NG: {
      id: 'country_select.option.NG',
      description: 'Country picker. This value is NG.',
      defaultMessage: 'Nigeria',
    },
    NI: {
      id: 'country_select.option.NI',
      description: 'Country picker. This value is NI.',
      defaultMessage: 'Nicaragua',
    },
    NL: {
      id: 'country_select.option.NL',
      description: 'Country picker. This value is NL.',
      defaultMessage: 'Netherlands',
    },
    NO: {
      id: 'country_select.option.NO',
      description: 'Country picker. This value is NO.',
      defaultMessage: 'Norway',
    },
    NP: {
      id: 'country_select.option.NP',
      description: 'Country picker. This value is NP.',
      defaultMessage: 'Nepal',
    },
    NR: {
      id: 'country_select.option.NR',
      description: 'Country picker. This value is NR.',
      defaultMessage: 'Nauru',
    },
    NU: {
      id: 'country_select.option.NU',
      description: 'Country picker. This value is NU.',
      defaultMessage: 'Niue',
    },
    NZ: {
      id: 'country_select.option.NZ',
      description: 'Country picker. This value is NZ.',
      defaultMessage: 'New Zealand',
    },
    OM: {
      id: 'country_select.option.OM',
      description: 'Country picker. This value is OM.',
      defaultMessage: 'Oman',
    },
    PA: {
      id: 'country_select.option.PA',
      description: 'Country picker. This value is PA.',
      defaultMessage: 'Panama',
    },
    PE: {
      id: 'country_select.option.PE',
      description: 'Country picker. This value is PE.',
      defaultMessage: 'Peru',
    },
    PF: {
      id: 'country_select.option.PF',
      description: 'Country picker. This value is PF.',
      defaultMessage: 'French Polynesia',
    },
    PG: {
      id: 'country_select.option.PG',
      description: 'Country picker. This value is PG.',
      defaultMessage: 'Papua New Guinea',
    },
    PH: {
      id: 'country_select.option.PH',
      description: 'Country picker. This value is PH.',
      defaultMessage: 'Philippines',
    },
    PK: {
      id: 'country_select.option.PK',
      description: 'Country picker. This value is PK.',
      defaultMessage: 'Pakistan',
    },
    PL: {
      id: 'country_select.option.PL',
      description: 'Country picker. This value is PL.',
      defaultMessage: 'Poland',
    },
    PM: {
      id: 'country_select.option.PM',
      description: 'Country picker. This value is PM.',
      defaultMessage: 'Saint Pierre and Miquelon',
    },
    PN: {
      id: 'country_select.option.PN',
      description: 'Country picker. This value is PN.',
      defaultMessage: 'Pitcairn',
    },
    PR: {
      id: 'country_select.option.PR',
      description: 'Country picker. This value is PR.',
      defaultMessage: 'Puerto Rico',
    },
    PS: {
      id: 'country_select.option.PS',
      description: 'Country picker. This value is PS.',
      defaultMessage: 'Palestinian Territory, Occupied',
    },
    PT: {
      id: 'country_select.option.PT',
      description: 'Country picker. This value is PT.',
      defaultMessage: 'Portugal',
    },
    PW: {
      id: 'country_select.option.PW',
      description: 'Country picker. This value is PW.',
      defaultMessage: 'Palau',
    },
    PY: {
      id: 'country_select.option.PY',
      description: 'Country picker. This value is PY.',
      defaultMessage: 'Paraguay',
    },
    QA: {
      id: 'country_select.option.QA',
      description: 'Country picker. This value is QA.',
      defaultMessage: 'Qatar',
    },
    RE: {
      id: 'country_select.option.RE',
      description: 'Country picker. This value is RE.',
      defaultMessage: 'Reunion',
    },
    RO: {
      id: 'country_select.option.RO',
      description: 'Country picker. This value is RO.',
      defaultMessage: 'Romania',
    },
    RS: {
      id: 'country_select.option.RS',
      description: 'Country picker. This value is RS.',
      defaultMessage: 'Serbia',
    },
    RU: {
      id: 'country_select.option.RU',
      description: 'Country picker. This value is RU.',
      defaultMessage: 'Russian Federation',
    },
    RW: {
      id: 'country_select.option.RW',
      description: 'Country picker. This value is RW.',
      defaultMessage: 'Rwanda',
    },
    SA: {
      id: 'country_select.option.SA',
      description: 'Country picker. This value is SA.',
      defaultMessage: 'Saudi Arabia',
    },
    SB: {
      id: 'country_select.option.SB',
      description: 'Country picker. This value is SB.',
      defaultMessage: 'Solomon Islands',
    },
    SC: {
      id: 'country_select.option.SC',
      description: 'Country picker. This value is SC.',
      defaultMessage: 'Seychelles',
    },
    SD: {
      id: 'country_select.option.SD',
      description: 'Country picker. This value is SD.',
      defaultMessage: 'Sudan',
    },
    SE: {
      id: 'country_select.option.SE',
      description: 'Country picker. This value is SE.',
      defaultMessage: 'Sweden',
    },
    SG: {
      id: 'country_select.option.SG',
      description: 'Country picker. This value is SG.',
      defaultMessage: 'Singapore',
    },
    SH: {
      id: 'country_select.option.SH',
      description: 'Country picker. This value is SH.',
      defaultMessage: 'Saint Helena',
    },
    SI: {
      id: 'country_select.option.SI',
      description: 'Country picker. This value is SI.',
      defaultMessage: 'Slovenia',
    },
    SJ: {
      id: 'country_select.option.SJ',
      description: 'Country picker. This value is SJ.',
      defaultMessage: 'Svalbard and Jan Mayen',
    },
    SK: {
      id: 'country_select.option.SK',
      description: 'Country picker. This value is SK.',
      defaultMessage: 'Slovakia',
    },
    SL: {
      id: 'country_select.option.SL',
      description: 'Country picker. This value is SL.',
      defaultMessage: 'Sierra Leone',
    },
    SM: {
      id: 'country_select.option.SM',
      description: 'Country picker. This value is SM.',
      defaultMessage: 'San Marino',
    },
    SN: {
      id: 'country_select.option.SN',
      description: 'Country picker. This value is SN.',
      defaultMessage: 'Senegal',
    },
    SO: {
      id: 'country_select.option.SO',
      description: 'Country picker. This value is SO.',
      defaultMessage: 'Somalia',
    },
    SR: {
      id: 'country_select.option.SR',
      description: 'Country picker. This value is SR.',
      defaultMessage: 'Suriname',
    },
    SS: {
      id: 'country_select.option.SS',
      description: 'Country picker. This value is SS.',
      defaultMessage: 'South Sudan',
    },
    ST: {
      id: 'country_select.option.ST',
      description: 'Country picker. This value is ST.',
      defaultMessage: 'Sao Tome and Principe',
    },
    SV: {
      id: 'country_select.option.SV',
      description: 'Country picker. This value is SV.',
      defaultMessage: 'El Salvador',
    },
    SX: {
      id: 'country_select.option.SX',
      description: 'Country picker. This value is SX.',
      defaultMessage: 'Sint Maarten (Dutch part)',
    },
    SY: {
      id: 'country_select.option.SY',
      description: 'Country picker. This value is SY.',
      defaultMessage: 'Syrian Arab Republic',
    },
    SZ: {
      id: 'country_select.option.SZ',
      description: 'Country picker. This value is SZ.',
      defaultMessage: 'Eswatini',
    },
    TC: {
      id: 'country_select.option.TC',
      description: 'Country picker. This value is TC.',
      defaultMessage: 'Turks and Caicos Islands',
    },
    TD: {
      id: 'country_select.option.TD',
      description: 'Country picker. This value is TD.',
      defaultMessage: 'Chad',
    },
    TF: {
      id: 'country_select.option.TF',
      description: 'Country picker. This value is TF.',
      defaultMessage: 'French Southern Territories',
    },
    TG: {
      id: 'country_select.option.TG',
      description: 'Country picker. This value is TG.',
      defaultMessage: 'Togo',
    },
    TH: {
      id: 'country_select.option.TH',
      description: 'Country picker. This value is TH.',
      defaultMessage: 'Thailand',
    },
    TJ: {
      id: 'country_select.option.TJ',
      description: 'Country picker. This value is TJ.',
      defaultMessage: 'Tajikistan',
    },
    TK: {
      id: 'country_select.option.TK',
      description: 'Country picker. This value is TK.',
      defaultMessage: 'Tokelau',
    },
    TL: {
      id: 'country_select.option.TL',
      description: 'Country picker. This value is TL.',
      defaultMessage: 'Timor-Leste',
    },
    TM: {
      id: 'country_select.option.TM',
      description: 'Country picker. This value is TM.',
      defaultMessage: 'Turkmenistan',
    },
    TN: {
      id: 'country_select.option.TN',
      description: 'Country picker. This value is TN.',
      defaultMessage: 'Tunisia',
    },
    TO: {
      id: 'country_select.option.TO',
      description: 'Country picker. This value is TO.',
      defaultMessage: 'Tonga',
    },
    TR: {
      id: 'country_select.option.TR',
      description: 'Country picker. This value is TR.',
      defaultMessage: 'Turkey',
    },
    TT: {
      id: 'country_select.option.TT',
      description: 'Country picker. This value is TT.',
      defaultMessage: 'Trinidad and Tobago',
    },
    TV: {
      id: 'country_select.option.TV',
      description: 'Country picker. This value is TV.',
      defaultMessage: 'Tuvalu',
    },
    TW: {
      id: 'country_select.option.TW',
      description: 'Country picker. This value is TW.',
      defaultMessage: 'Taiwan',
    },
    TZ: {
      id: 'country_select.option.TZ',
      description: 'Country picker. This value is TZ.',
      defaultMessage: 'Tanzania, United Republic of',
    },
    UA: {
      id: 'country_select.option.UA',
      description: 'Country picker. This value is UA.',
      defaultMessage: 'Ukraine',
    },
    UG: {
      id: 'country_select.option.UG',
      description: 'Country picker. This value is UG.',
      defaultMessage: 'Uganda',
    },
    UM: {
      id: 'country_select.option.UM',
      description: 'Country picker. This value is UM.',
      defaultMessage: 'United States Minor Outlying Islands',
    },
    US: {
      id: 'country_select.option.US',
      description: 'Country picker. This value is US.',
      defaultMessage: 'United States',
    },
    UY: {
      id: 'country_select.option.UY',
      description: 'Country picker. This value is UY.',
      defaultMessage: 'Uruguay',
    },
    UZ: {
      id: 'country_select.option.UZ',
      description: 'Country picker. This value is UZ.',
      defaultMessage: 'Uzbekistan',
    },
    VA: {
      id: 'country_select.option.VA',
      description: 'Country picker. This value is VA.',
      defaultMessage: 'Holy See (Vatican City State)',
    },
    VC: {
      id: 'country_select.option.VC',
      description: 'Country picker. This value is VC.',
      defaultMessage: 'Saint Vincent and the Grenadines',
    },
    VE: {
      id: 'country_select.option.VE',
      description: 'Country picker. This value is VE.',
      defaultMessage: 'Venezuela',
    },
    VG: {
      id: 'country_select.option.VG',
      description: 'Country picker. This value is VG.',
      defaultMessage: 'Virgin Islands, British',
    },
    VI: {
      id: 'country_select.option.VI',
      description: 'Country picker. This value is VI.',
      defaultMessage: 'Virgin Islands, U.S.',
    },
    VN: {
      id: 'country_select.option.VN',
      description: 'Country picker. This value is VN.',
      defaultMessage: 'Viet Nam',
    },
    VU: {
      id: 'country_select.option.VU',
      description: 'Country picker. This value is VU.',
      defaultMessage: 'Vanuatu',
    },
    WF: {
      id: 'country_select.option.WF',
      description: 'Country picker. This value is WF.',
      defaultMessage: 'Wallis and Futuna',
    },
    WS: {
      id: 'country_select.option.WS',
      description: 'Country picker. This value is WS.',
      defaultMessage: 'Samoa',
    },
    YE: {
      id: 'country_select.option.YE',
      description: 'Country picker. This value is YE.',
      defaultMessage: 'Yemen',
    },
    YT: {
      id: 'country_select.option.YT',
      description: 'Country picker. This value is YT.',
      defaultMessage: 'Mayotte',
    },
    ZA: {
      id: 'country_select.option.ZA',
      description: 'Country picker. This value is ZA.',
      defaultMessage: 'South Africa',
    },
    ZM: {
      id: 'country_select.option.ZM',
      description: 'Country picker. This value is ZM.',
      defaultMessage: 'Zambia',
    },
    ZW: {
      id: 'country_select.option.ZW',
      description: 'Country picker. This value is ZW.',
      defaultMessage: 'Zimbabwe',
    },
  });
