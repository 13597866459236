import type {Direction} from '@react-types/shared';
import {useEffect, useState} from 'react';
import {useIsSSR} from '../ssr';
import {isRTL} from './utils';

export interface Locale {
  /** The [BCP47](https://www.ietf.org/rfc/bcp/bcp47.txt) language code for the locale. */
  locale: string;
  /** The writing direction for the locale. */
  direction: Direction;
}

/**
 * Gets the locale setting of the browser.
 */
export function getDefaultLocale(): Locale {
  let locale =
    (typeof navigator !== 'undefined' &&
      (navigator.language ||
        // @ts-expect-error - Property 'userLanguage' does not exist on type 'Navigator'. Did you mean 'language'?
        navigator.userLanguage)) ||
    'en-US';
  try {
    Intl.DateTimeFormat.supportedLocalesOf([locale]);
  } catch (_err) {
    locale = 'en-US';
  }
  return {
    locale,
    direction: isRTL(locale) ? 'rtl' : 'ltr',
  };
}

let currentLocale = getDefaultLocale();
const listeners = new Set<(locale: Locale) => void>();

function updateLocale() {
  currentLocale = getDefaultLocale();
  // eslint-disable-next-line no-restricted-syntax
  for (const listener of listeners) {
    listener(currentLocale);
  }
}

/**
 * Returns the current browser/system language, and updates when it changes.
 */
export function useDefaultLocale(): Locale {
  const isSSR = useIsSSR();
  const [defaultLocale, setDefaultLocale] = useState(currentLocale);

  useEffect(() => {
    if (listeners.size === 0) {
      window.addEventListener('languagechange', updateLocale);
    }

    listeners.add(setDefaultLocale);

    return () => {
      listeners.delete(setDefaultLocale);
      if (listeners.size === 0) {
        window.removeEventListener('languagechange', updateLocale);
      }
    };
  }, []);

  // We cannot determine the browser's language on the server, so default to
  // en-US. This will be updated after hydration on the client to the correct value.
  if (isSSR) {
    return {
      locale: 'en-US',
      direction: 'ltr',
    };
  }

  return defaultLocale;
}
