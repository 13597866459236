import {addressTokens, createTokens} from '@sail/engine';
import {color} from './color';
import {font} from './font';
import {space} from './space';
import {border} from './border';
import {size} from './size';
import {shadow} from './shadow';
import {viewport} from './viewport';
import {animation} from './animation';

// The rgba(1, 150, 237) color should be using Blue 400
const focus = createTokens('focus', {
  $default: '0 0 0 4px rgba(1, 150, 237, .36)',
});

export const tokens = (() => {
  const tokens = createTokens('', {
    animation,
    color,
    font,
    space,
    border,
    size,
    shadow,
    viewport,
    focus,
  });
  addressTokens(tokens);
  return tokens;
})();

export type {FontPresetSchema, TypefaceSchema} from './font';
