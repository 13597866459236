import {updateDocumentMetadataMutation} from 'gelato/frontend/src/graphql/mutations/useUpdateDocumentMetadataMutation';

// eslint-disable-next-line @sail/data-no-imperative-methods
import type {ApolloClient, FetchResult} from '@apollo/client';
import type {
  UpdateDocumentMetadataMutationVariables,
  UpdateDocumentMetadataMutationData,
} from 'gelato/frontend/src/graphql/mutations/useUpdateDocumentMetadataMutation';

/**
 * Performs the mutation that updates the document metadata.
 * @param apolloClient The apollo client.
 * @param variables The mutation variables.
 */
export default async function updateDocumentMetadataMutationMethod(
  apolloClient: ApolloClient<object>,
  variables: UpdateDocumentMetadataMutationVariables,
): Promise<FetchResult<UpdateDocumentMetadataMutationData>> {
  // eslint-disable-next-line @sail/data-no-imperative-methods
  return apolloClient.mutate({
    mutation: updateDocumentMetadataMutation,
    variables,
  });
}
