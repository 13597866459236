import * as React from 'react';

export function mergeRefs<T>(refs: React.Ref<T>[]): (value: T) => void {
  return (value: T) => {
    for (let i = 0; i < refs.length; i++) {
      const ref = refs[i];
      if (ref) {
        if (typeof ref === 'function') {
          ref(value);
        } else {
          (ref as {current: T}).current = value;
        }
      }
    }
  };
}

export function useRefs<T>(refs: React.Ref<T>[]): React.RefCallback<T> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(mergeRefs(refs), refs);
}
