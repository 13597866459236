import {Country} from '@stripe-internal/data-gelato/schema/types';

export const DEFAULT_LANG = 'en';
export const DEFAULT_LOCALE = 'en-US';

// labels for each language in the localization dropdown selector
export const AVAILABLE_LOCALES = {
  'bg-BG': 'Български',
  'bn-IN': 'বাংলা',
  'ca-ES': 'Català',
  'cs-CZ': 'Čeština',
  'cy-GB': 'Cymraeg',
  'da-DK': 'Dansk',
  'de-DE': 'Deutsch',
  'et-EE': 'Eesti',
  'el-GR': 'Ελληνικά',
  'en-GB': 'English (United Kingdom)',
  'en-US': 'English (United States)',
  'es-ES': 'Español (España)',
  'es-419': 'Español (América Latina)',
  'eu-ES': 'Euskara',
  'fr-CA': 'Français (Canada)',
  'fr-FR': 'Français (France)',
  'fil-PH': 'Filipino',
  'gl-ES': 'Galego',
  'gu-IN': 'ગુજરાતી',
  'hi-IN': 'हिन्दी',
  'hr-HR': 'Hrvatski',
  'hu-HU': 'Magyar',
  'id-ID': 'Bahasa Indonesia',
  'it-IT': 'Italiano',
  'ja-JP': '日本語',
  'kn-IN': 'ಕನ್ನಡ',
  'ko-KR': '한국어',
  'lv-LV': 'Latviešu',
  'lt-LT': 'Lietuvių',
  'ml-IN': 'മലയാളം',
  'mr-IN': 'मराठी',
  'ms-MY': 'Bahasa Melayu',
  'mt-MT': 'Malti',
  'nl-NL': 'Nederlands',
  'nb-NO': 'Norsk',
  'or-IN': 'ଓଡ଼ିଆ',
  'pa-IN': 'ਪੰਜਾਬੀ',
  'pl-PL': 'Polski',
  'pt-BR': 'Português (Brasil)',
  'pt-PT': 'Português (Portugal)',
  'ro-RO': 'română',
  'sk-SK': 'Slovenčina',
  'sl-SI': 'Slovenščina',
  'fi-FI': 'Suomi',
  'sv-SE': 'Svenska',
  'ta-IN': 'தமிழ்',
  'te-IN': 'తెలుగు',
  'th-TH': 'ไทย',
  'tr-TR': 'Türkçe',
  'vi-VN': 'Tiếng Việt',
  'zh-Hans': '简体中文',
  'zh-Hant': '繁體中文',
  'zh-Hant-TW': '繁體中文 (Taiwan)',
};

export type LocaleKey = keyof typeof AVAILABLE_LOCALES;

// mapping browser locale/language to the translation files (gelato/frontend/src/intl/dist/)
export const LOCALE_MAP: {
  [lang: string]: LocaleKey;
} = {
  bg: 'bg-BG',
  bn: 'bn-IN',
  ca: 'ca-ES',
  cs: 'cs-CZ',
  cy: 'cy-GB',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  en: 'en-US',
  es: 'es-ES',
  'es-MX': 'es-419',
  et: 'et-EE',
  eu: 'eu-ES',
  fi: 'fi-FI',
  fil: 'fil-PH',
  fr: 'fr-FR',
  gl: 'gl-ES',
  gu: 'gu-IN',
  hi: 'hi-IN',
  hr: 'hr-HR',
  hu: 'hu-HU',
  id: 'id-ID',
  it: 'it-IT',
  ja: 'ja-JP',
  kn: 'kn-IN',
  ko: 'ko-KR',
  lv: 'lv-LV',
  lt: 'lt-LT',
  ml: 'ml-IN',
  mr: 'mr-IN',
  ms: 'ms-MY',
  mt: 'mt-MT',
  or: 'or-IN',
  nb: 'nb-NO',
  nl: 'nl-NL',
  no: 'nb-NO',
  nn: 'nb-NO',
  pa: 'pa-IN',
  pl: 'pl-PL',
  pt: 'pt-PT',
  sk: 'sk-SK',
  sl: 'sl-SI',
  sv: 'sv-SE',
  ta: 'ta-IN',
  te: 'te-IN',
  th: 'th-TH',
  tr: 'tr-TR',
  vi: 'vi-VN',
  zh: 'zh-Hans',
  'zh-CN': 'zh-Hans',
  'zh-HK': 'zh-Hant',
  'zh-TW': 'zh-Hant-TW',
};

// https://git.corp.stripe.com/stripe-internal/pay-server/tree/master/site/views/legal/privacy/translations
export const PRIVACY_POLICY_LOCALE_MAP: Partial<Record<LocaleKey, string>> = {
  'bg-BG': '',
  'bn-IN': '',
  'ca-ES': 'ca',
  'cs-CZ': '',
  'cy-GB': '',
  'da-DK': '',
  'de-DE': 'de',
  'el-GR': '',
  'en-GB': '',
  'en-US': '', // this should stay '' as it gets default privacy policy url which is in english
  'es-ES': 'es',
  'es-419': 'mx',
  'et-EE': '',
  'eu-ES': '',
  'fi-FI': '',
  'fil-PH': '',
  'fr-CA': '',
  'fr-FR': 'fr',
  'gl-ES': '',
  'gu-IN': '',
  'hi-IN': '',
  'hr-HR': '',
  'hu-HU': '',
  'id-ID': '',
  'it-IT': 'it',
  'ja-JP': 'jp',
  'kn-IN': '',
  'ko-KR': '',
  'lt-LT': '',
  'lv-LV': '',
  'ml-IN': '',
  'mr-IN': '',
  'ms-MY': 'my',
  'mt-MT': '',
  'nb-NO': '',
  'nl-NL': '',
  'or-IN': '',
  'pa-IN': '',
  'pl-PL': '',
  'pt-BR': 'br',
  'pt-PT': 'pt',
  'ro-RO': '',
  'sk-SK': '',
  'sl-SI': '',
  'sv-SE': '',
  'ta-IN': '',
  'te-IN': '',
  'th-TH': '',
  'tr-TR': '',
  'vi-VN': '',
  'zh-Hans': '',
  'zh-Hant': '',
  'zh-Hant-TW': '',
};

type LOCALE_KEY_TYPE = keyof typeof AVAILABLE_LOCALES;

export const LocaleToCountry: Record<LOCALE_KEY_TYPE, Country> = {
  'bg-BG': 'BG',
  'bn-IN': 'IN',
  'ca-ES': 'ES',
  'cs-CZ': 'CZ',
  'cy-GB': 'GB',
  'da-DK': 'DK',
  'de-DE': 'DE',
  'et-EE': 'EE',
  'el-GR': 'GR',
  'en-GB': 'GB',
  'en-US': 'US',
  'es-ES': 'ES',
  'es-419': 'MX',
  'eu-ES': 'ES',
  'fr-CA': 'CA',
  'fr-FR': 'FR',
  'fil-PH': 'PH',
  'gl-ES': 'ES',
  'gu-IN': 'IN',
  'hi-IN': 'IN',
  'hr-HR': 'HR',
  'hu-HU': 'HU',
  'id-ID': 'ID',
  'it-IT': 'IT',
  'ja-JP': 'JP',
  'kn-IN': 'IN',
  'ko-KR': 'KR',
  'lv-LV': 'LV',
  'lt-LT': 'LT',
  'ml-IN': 'IN',
  'mr-IN': 'IN',
  'ms-MY': 'MY',
  'mt-MT': 'MT',
  'nl-NL': 'NL',
  'nb-NO': 'NO',
  'or-IN': 'IN',
  'pa-IN': 'IN',
  'pl-PL': 'PL',
  'pt-BR': 'BR',
  'pt-PT': 'PT',
  'ro-RO': 'RO',
  'sk-SK': 'SK',
  'sl-SI': 'SI',
  'fi-FI': 'FI',
  'sv-SE': 'SE',
  'ta-IN': 'IN',
  'te-IN': 'IN',
  'th-TH': 'TH',
  'tr-TR': 'TR',
  'vi-VN': 'VN',
  'zh-Hans': 'CN',
  'zh-Hant': 'HK',
  'zh-Hant-TW': 'TW',
};

export const getLocale = (overrideLocale?: string | null): LocaleKey => {
  let stringLocale: string = '';

  // an override supercedes the default and the browser language -- it may have come in
  // as the `locale` query string parameter.
  if (overrideLocale) {
    stringLocale = overrideLocale;
  }
  // there's no window in Dev mode, because it's Node
  else if (typeof window !== 'undefined' && window.navigator) {
    // use the browser language
    stringLocale =
      // @ts-expect-error - TS2339 - Property 'userLanguage' does not exist on type 'Navigator'.
      window.navigator.userLanguage ||
      window.navigator.language ||
      DEFAULT_LOCALE;
  }

  let locale: LocaleKey;
  if (!(stringLocale in AVAILABLE_LOCALES)) {
    // sometimes the language code is too specific or too general
    const shortLangCode = stringLocale.split(/[-_]/)[0];
    locale =
      LOCALE_MAP[stringLocale] || LOCALE_MAP[shortLangCode] || DEFAULT_LOCALE;
  } else {
    // $ExpectError -- suppress the type error: based on conditional logic, this string _must_ be in LocaleKey
    locale = stringLocale as LocaleKey;
  }

  return locale;
};

export const getBrowserLangCountry = (): string | undefined => {
  if (typeof window !== 'undefined' && window.navigator) {
    // @ts-expect-error - TS2339 - Property 'userLanguage' does not exist on type 'Navigator'.
    const lang = window.navigator.userLanguage || window.navigator.language;
    return lang.split(/[-_]/)[1];
  }
  return undefined;
};

export const sanitizeLocale = (locale: LocaleKey): LocaleKey => {
  return locale in AVAILABLE_LOCALES ? locale : DEFAULT_LOCALE;
};

export const importLocaleJsons = async (locale: LocaleKey) => {
  if (locale === DEFAULT_LOCALE) {
    return {};
  }
  // lazy load the translation chunk
  // eslint-disable-next-line no-unsanitized/method
  const localeMessages = await import(
    `translations/${sanitizeLocale(locale)}.json`
  );
  return localeMessages.default;
};

export const getPrivacyPolicyLocale = (locale: LocaleKey) =>
  PRIVACY_POLICY_LOCALE_MAP[locale];
