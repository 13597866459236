import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';

import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';

/**
 * Mutation request to generate an OTP
 * @param state The application state.
 * @param runtime The application runtime.
 * @returns boolean whether or not the generate request was successfully executed
 */
export default async function generateOTP(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
): Promise<boolean> {
  const resp = await runtime.api.mutation.generateOTPMutation({
    variables: {
      email: state.individual.emailOtp?.shouldGenerateOTP,
      phone: state.individual.phoneOtp?.shouldGenerateOTP,
    },
  });
  if (!resp?.data?.generateOneTimePassword.success) {
    throw new Error(ErrorCode.failedToGenerateOtp);
  }

  return true;
}
