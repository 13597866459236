import {css, cssPluginResetStyles} from '../core';
import type {CssProperties, CssSelectorIndexer} from '../core';

type TransformValue = CssProperties['transform'];

export interface TransformResult {
  [key: `--${string}`]: TransformValue;
  transform?: TransformValue;
}

export interface TransformPlugin {
  (value: TransformValue, selector: CssSelectorIndexer): TransformResult;
}

const transformMixin: CssProperties = {
  '--transform-x': css.value('0'),
  '--transform-y': css.value('0'),
  '--transform-scale': css.value('1'),
  '--transform-rotate': css.value('0'),
  '--transform': css.value(' '),

  // https://www.w3.org/TR/css-transforms-2/#ctm
  transform: css.value(`
    translate(var(--transform-x), var(--transform-y))
    rotate(var(--transform-rotate))
    scale(var(--transform-scale))
    var(--transform)
  `),
};

function createTransformPlugin(customProperty: string): TransformPlugin {
  return function transformPlugin(value, selector) {
    return {
      ...cssPluginResetStyles(selector, transformMixin),
      [customProperty]: value,
    };
  };
}

export const pluginsTransform = {
  x: createTransformPlugin('--transform-x'),
  y: createTransformPlugin('--transform-y'),
  scale: createTransformPlugin('--transform-scale'),
  rotate: createTransformPlugin('--transform-rotate'),
  transform: createTransformPlugin('--transform'),
};
