import {TextField} from '@sail/ui';
import * as React from 'react';
import {IntlShape, defineMessages, injectIntl} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const {useCallback} = React;

const Messages = defineMessages({
  emailLabel: {
    id: 'form.individual.email.label',
    description: "Label for the individual's email form",
    defaultMessage: 'Email',
  },
});

type EmailInputProps = {
  intl: IntlShape;
  handleKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
};

const EmailInput = ({intl, handleKeyDown}: EmailInputProps) => {
  const {appController} = useAppController();
  const {session, individual} = appController.state;

  const handleChange = useCallback(
    (emailValue: string) => {
      appController.setIndividualCollectedData({
        email: {userProvidedAddress: emailValue},
      });
    },
    [appController],
  );

  const handleBlur = useCallback(() => {
    appController.validateIndividualData({email: individual.email}, intl);
  }, [appController, intl, individual.email]);

  const collectedEmail = session?.collectedData?.individual.email;

  return (
    <TextField
      label={<Message {...Messages.emailLabel} />}
      type="email"
      // Value only accepts undefined and not null
      value={individual.email?.userProvidedAddress || ''}
      disabled={!session || !!collectedEmail?.merchantProvidedAddress}
      onChange={(e) => handleChange(e.target.value)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      error={individual.email?.validationMsg}
    />
  );
};

export default injectIntl(EmailInput);
