import * as React from 'react';

import {useSessionInfo} from 'gelato/frontend/src/layout/contexts/SessionInfoProvider';
import {ConnectIframeContext} from 'gelato/frontend/src/lib/contexts';
import {isInIframe} from 'gelato/frontend/src/lib/iframe';

type Props = {
  children: (iframe: boolean) => React.ReactElement;
};

const ConnectIframeProvider = ({children}: Props) => {
  const [iframe] = React.useState<boolean>(isInIframe());
  const info = useSessionInfo();

  return (
    <ConnectIframeContext.Provider
      value={iframe && info.useConnectIframeDesign}
    >
      {children(iframe)}
    </ConnectIframeContext.Provider>
  );
};

export default ConnectIframeProvider;
