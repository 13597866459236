/**
 * All documentation comments for CSS properties should be declared within this
 * file to ensure that TypeScript includes them in the type declaration for CSS.
 */
import type {Style} from '@sail/engine';
import {tokens} from '../../tokens';
import {
  backgroundColor,
  borderBottomColor,
  borderColor,
  borderLeftColor,
  borderRightColor,
  borderTopColor,
  color,
  fill,
  stroke,
  textColor,
} from './propertiesColor';
import type {FontPresetKeys} from './propertiesFont';
import {
  font,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from './propertiesFont';
import type {NativeProperty} from './types';
import {format, property} from './util';

const sizeProperty = property([tokens.size, tokens.space]).accept(format.px);

const propertiesSize = {
  /**
   * Sets the `height` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/height
   */
  height: sizeProperty,
  /**
   * Sets the `min-width` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/min-width
   */
  minWidth: sizeProperty,
  /**
   * Sets the `max-width` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/max-width
   */
  maxWidth: sizeProperty,
  /**
   * Sets the `min-height` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/min-height
   */
  minHeight: sizeProperty,
  /**
   * Sets the `max-height` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/max-height
   */
  maxHeight: sizeProperty,
  /**
   * Sets the `width` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/width
   */
  width: sizeProperty,
};

const spaceProperty = property([tokens.space]).accept(format.px);

const propertiesSpace = {
  /**
   * Bleeding an element allows it to extend past the boundaries of its container.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/bleed
   */
  bleed: spaceProperty,
  /**
   * Allows an element to extend past the boundaries of its container on the x axis.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/bleed
   */
  bleedX: spaceProperty,
  /**
   * Allows an element to extend past the boundaries of its container on the y axis.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/bleed
   */
  bleedY: spaceProperty,
  /**
   * Allows an element to extend past the top boundary of its container.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/bleed
   */
  bleedTop: spaceProperty,
  /**
   * Allows an element to extend past the bottom boundary of its container.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/bleed
   */
  bleedBottom: spaceProperty,
  /**
   * Allows an element to extend past the left boundary of its container.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/bleed
   */
  bleedLeft: spaceProperty,
  /**
   * Allows an element to extend past the right boundary of its container.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/bleed
   */
  bleedRight: spaceProperty,
  /**
   * Sets the horizontal and vertical postion of a positioned element, applying offsets from the edges of the element’s containing block.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/inset
   */
  inset: spaceProperty,
  /**
   * Sets the horiztonal placement of a positioned element, applying an offset from the left and right edges of the element’s containing block.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/inset
   */
  insetX: spaceProperty,
  /**
   * Sets the placement of a positioned element, applying an offset from the top and bottom edges of the element’s containing block.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/inset
   */
  insetY: spaceProperty,
  /**
   * Sets the margin area on all four sides of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  margin: spaceProperty,
  /**
   * Sets the margin area on the bottom of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin
   */
  marginBottom: spaceProperty,
  /**
   * Sets the margin area on the left of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin
   */
  marginLeft: spaceProperty,
  /**
   * Sets the margin area on the right of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin
   */
  marginRight: spaceProperty,
  /**
   * Sets the margin area on the top of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin
   */
  marginTop: spaceProperty,
  /**
   * Sets the margin area on the left and right of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin
   */
  marginX: spaceProperty,
  /**
   * Sets the margin area on the top and bottom of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin
   */
  marginY: spaceProperty,
  /**
   * Sets the padding area on all four sides of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  padding: spaceProperty,
  /**
   * Sets the height of the padding area on the bottom of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  paddingBottom: spaceProperty,
  /**
   * Sets the width of the padding area on the left of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  paddingLeft: spaceProperty,
  /**
   * Sets the width of the padding area on the right of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  paddingRight: spaceProperty,
  /**
   * Sets the height of the padding area on the top of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  paddingTop: spaceProperty,
  /**
   * Sets the width of the padding area on the left and right of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  paddingX: spaceProperty,
  /**
   * Sets the height of the padding area on the top and bottom of an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/padding
   */
  paddingY: spaceProperty,
};

const transformProperty = property().accept(format.px);

const propertiesTransform = {
  /**
   * Translates the position of an element on the x axis.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/translate
   */
  x: transformProperty,
  /**
   * Translates the position of an element on the y axis.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/translate
   */
  y: transformProperty,
  /**
   * Resizes an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/scale
   */
  scale: property().accept(format.number),
  /**
   * Rotates an element through the specified angle.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/rotate
   */
  rotate: property().accept(format.deg),
  /**
   * Specifies a CSS transform to rotate, scale, skew or translate an element.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transform
   */
  transform: transformProperty,
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Layout {
  export type AlignX = 'start' | 'center' | 'end' | 'stretch';
  export type AlignY = 'top' | 'center' | 'baseline' | 'bottom' | 'stretch';
  export type Axis = 'x' | 'y' | 'z';
  export type Distribute = 'packed' | 'space-between';
  export type Display = 'block' | 'inline' | 'none';
  export type Wrap = 'wrap' | 'nowrap' | 'wrap-reverse';
}

const propertiesLayout = {
  /**
   * Sets an item's alignment on the x axis, overriding the `alignX` value on the parent
   * [stack](https://sail.corp.stripe.com/next/apis/layout/stack) or [grid](https://sail.corp.stripe.com/next/foundations/layout#grids).
   *
   * **Initial value**: `stretch`
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/stack
   */
  alignSelfX: property().accept<Layout.AlignX>(),
  /**
   * Sets an item's alignment on the y axis, overriding the `alignY` value on the parent
   * [stack](https://sail.corp.stripe.com/next/apis/layout/stack) or [grid](https://sail.corp.stripe.com/next/foundations/layout#grids).
   *
   * **Initial value**: `stretch`
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/stack
   */
  alignSelfY: property().accept<Layout.AlignY>(),

  /**
   * Controls the alignment of items on the x axis. Items in [stacks](https://sail.corp.stripe.com/next/apis/layout/stack) stretch by default to fill the
   * available space, unless a specific alignment is set.
   *
   * **Initial value**: `stretch`
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/stack
   */
  alignX: property().accept<Layout.AlignX>(),
  /**
   * Controls the alignment of items on the y axis. Items in [stacks](https://sail.corp.stripe.com/next/apis/layout/stack) stretch by default to fill the
   * available space, unless a specific alignment is set.
   *
   * **Initial value**: `stretch`
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/stack
   */
  alignY: property().accept<Layout.AlignY>(),

  /**
   * An element can be displayed as a **block** that fills available space or
   * **inline** and adjacent to other inline elements. To control how items are
   * rendered _within_ an element, use the
   * [stack](https://sail.corp.stripe.com/next/apis/layout/stack)
   * or [grid](https://sail.corp.stripe.com/next/foundations/layout#grids)
   * layout patterns.
   *
   * @see https://sail.corp.stripe.com/next/foundations/layout#displays-and-layouts
   */
  display: property().accept<Layout.Display>(),

  /**
   * Controls the spacing between rows and columns of items in a [stack](https://sail.corp.stripe.com/next/apis/layout/stack)
   * or [grid](https://sail.corp.stripe.com/next/foundations/layout#grids).
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/gap
   */
  gap: property([tokens.space]).accept(format.px),
  /**
   * Controls the spacing between columns of items in a [stack](https://sail.corp.stripe.com/next/apis/layout/stack)
   * or [grid](https://sail.corp.stripe.com/next/foundations/layout#grids).
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/column-gap
   */
  gapX: property([tokens.space]).accept(format.px),
  /**
   * Controls the spacing between rows of items in a [stack](https://sail.corp.stripe.com/next/apis/layout/stack)
   * or [grid](https://sail.corp.stripe.com/next/foundations/layout#grids).
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/row-gap
   */
  gapY: property([tokens.space]).accept(format.px),

  /**
   * A stack is an uniformly-spaced arrangement of items along either an x or y
   * axis. Stacks provide various options for spacing, aligning, and
   * distributing items. Stacks can also optionally wrap onto multiple lines to
   * create clusters of items.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/stack
   */
  stack: property().accept<Layout.Axis>(),
  /**
   * Controls whether items in a stack are packed together, or spread out. When packed, the
   * maximum space between items will be equal to the stack's gap. When spread out, items
   * will not stretch to fill the stack and the space between items will be maximized.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/stack
   */
  distribute: property().accept<Layout.Distribute>(),
  /**
   * Controls whether a stack is allowed to wrap onto multiple lines.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/stack/#line-wrapping
   */
  wrap: property().accept<Layout.Wrap>(),
  gridColumns: property().accept(format.number),
  /**
   * Arranges items into a tiled grid. Items will be a minimum of `minTileWidth` wide
   * and the number of columns will be determined by the available space.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/grid
   */
  minTileWidth: property().accept(format.px),
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Font {
  export type Preset = FontPresetKeys;

  export type BaselineReference =
    | 'alphabetic'
    | 'cap-middle'
    | 'x-middle'
    | 'x-height';

  export type BaselineTarget =
    | BaselineReference
    | 'object-bottom'
    | 'object-middle'
    | 'object-top';

  export type Baseline =
    | BaselineReference
    | `${BaselineReference} ${BaselineTarget}`;

  export type BaselineAlign = 'auto' | 'manual' | 'none';

  // https://www.w3.org/TR/css-inline-3/#leading-trim
  // https://github.com/w3c/csswg-drafts/issues/8067
  export type TextBoxTrim = 'none' | 'start' | 'end' | 'both';

  // https://www.w3.org/TR/css-inline-3/#text-edges
  export type TextBoxEdgeOver = 'normal' | 'cap' | 'ex' | 'text';
  export type TextBoxEdgeUnder = 'normal' | 'alphabetic' | 'text';
  export type TextBoxEdge =
    | TextBoxEdgeOver
    | TextBoxEdgeUnder
    | `${TextBoxEdgeOver} ${TextBoxEdgeUnder}`;
}

const propertiesFont = {
  /**
   * The `baseline` property sets how an element aligns itself in relation
   * to its parent’s reference baseline. It accepts two keywords: a reference
   * baseline, and an optional alignment baseline.
   *
   * @example
   * ```
   * <baseline>: 'normal' | 'cap-middle' | 'x-middle' | 'x-height';
   * ```
   *
   * @example
   * Defining a **reference baseline**:
   * ```
   * baseline: 'x-middle'
   * ```
   *
   * @example
   * Defining a **reference baseline** with a custom **alignment baseline**:
   * ```
   * baseline: 'x-middle normal'
   * ```
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/vertical-alignment
   */
  baseline: property().accept<Font.Baseline>(),
  /**
   * The `baselineAlign` property controls whether an element’s children
   * automatically snap to the baseline.
   *
   * `baselineAlign: 'auto'` enables automatic baseline alignment and reserves
   * the `::before` pseudo-element to ensure that the reference font is
   * guaranteed to be the first available font in the baseline-sharing group’s
   * alignment context.
   *
   * `baselineAlign: 'manual'` enables automatic baseline alignment without
   * impacting the `::before` pseudo-element. Use with care; the first child
   * will establish the baseline-sharing group’s alignment context.
   *
   * `baselineAlign: 'none'` disables automatic baseline alignment.
   *
   * @see https://www.w3.org/TR/css-align-3/#align-by-baseline
   * @see https://sail.corp.stripe.com/next/apis/layout/vertical-alignment
   */
  baselineAlign: property().accept<Font.BaselineAlign>(),
  /**
   * Controls the font size of an element based on the height of a capital letter.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/cap-height
   */
  capHeight: property().accept(format.number),
  /**
   * Sets the text size and line-height and any preset font-weight, font-family, and text-transform values.
   *
   * @see https://sail.stripe.me/tokens/typography/
   */
  font,
  /**
   * Sets the `font-family` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/font-family
   */
  fontFamily,
  /**
   * Sets the `font-size` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/font-size
   */
  fontSize,
  /**
   * Controls the weight (or boldness) of the font, setting the `font-weight` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
   */
  fontWeight,
  /**
   * Controls the height of a line box, setting the `line-height` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/line-height
   */
  lineHeight,
  /**
   * Controls the spacing above the first line and below the last line of a block of text. The
   * `leadingTrim` property specifies the baselines to use for the first and last line and the
   * `textEdge` property specifies which lines will have adjusted spacing.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/text-box-trim
   */
  textBoxEdge: property().accept<Font.TextBoxEdge>(),
  /**
   * Controls the spacing above the first line and below the last line of a block of text. The
   * `textBoxTrim` property specifies the baselines to use for the first and last line and the
   * `textBoxEdge` property specifies which lines will have adjusted spacing.
   *
   * @see https://sail.corp.stripe.com/next/apis/layout/text-box-trim
   */
  textBoxTrim: property().accept<Font.TextBoxTrim>(),
};

const propertiesColor = {
  /**
   * Sets the text color and any corresponding icon, border, and background colors.
   *
   * @see https://sail.corp.stripe.com/next/tokens/color
   */
  color,
  /**
   * Sets the `background-color` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/background-color
   */
  backgroundColor,
  /**
   * Sets the `border-color` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-color
   */
  borderColor,
  /**
   * Sets the `border-top-color` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-color
   */
  borderTopColor,
  /**
   * Sets the `border-right-color` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-right-color
   */
  borderRightColor,
  /**
   * Sets the `border-bottom-color` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-color
   */
  borderBottomColor,
  /**
   * Sets the `border-left-color` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-left-color
   */
  borderLeftColor,
  /**
   * Sets the fill color of an icon rendered via Sail's [Icon component](https://sail.corp.stripe.com/next/components/icon).
   *
   * @see https://sail.corp.stripe.com/next/tokens/color
   */
  fill,
  /**
   * Sets the stroke color of an icon rendered via Sail's [Icon component](https://sail.corp.stripe.com/next/components/icon).
   *
   * @see https://sail.corp.stripe.com/next/tokens/color
   */
  stroke,
  /**
   * Sets the text color without also setting any corresponding icon, border, and background colors.
   *
   * @see https://sail.corp.stripe.com/next/tokens/color
   */
  textColor,
};

const propertiesBoxShadow = {
  /**
   * Sets the `box-shadow` CSS property while retaining applied `focusRing`, `keyline` or `topShadow` styles.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/box-shadow
   */
  boxShadow: property([tokens.shadow]).accept<NativeProperty<'boxShadow'>>(),
  /**
   * Applies focus indicator styles to an element, replacing the browser default [CSS outline](https://developer.mozilla.org/en-US/docs/Web/CSS/outline).
   */
  focusRing: property([tokens.focus]).accept<NativeProperty<'boxShadow'>>(),
  /**
   * Applies a visual outline or border around an element. Unlike the [CSS border property](https://developer.mozilla.org/en-US/docs/Web/CSS/border), a keyline
   * will not affect the size of an element.
   */
  keyline: property([tokens.color.border]).accept<
    NativeProperty<'borderColor'>
  >(),
  /**
   * Sets the visual width of the applied keyline.
   */
  keylineWidth: property([tokens.border.width]).accept(format.px),
  /**
   * Sets an additional `box-shadow` on an element that can overlay other applied shadows.
   */
  topShadow: property([tokens.shadow]).accept<NativeProperty<'boxShadow'>>(),
  /**
   * Rounds the corners of an element's outer border edge. Sets the `border-radius` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius
   */
  borderRadius: property([tokens.border.radius]).accept(format.px),
  /**
   * Rounds the corners of an element's top left outer border edge. Sets the `border-top-left-radius` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-left-radius
   */
  borderTopLeftRadius: property([tokens.border.radius]).accept(format.px),
  /**
   * Rounds the corners of an element's top right outer border edge. Sets the `border-top-right-radius` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-right-radius
   */
  borderTopRightRadius: property([tokens.border.radius]).accept(format.px),
  /**
   * Rounds the corners of an element's bottom right outer border edge. Sets the `border-bottom-right-radius` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-right-radius
   */
  borderBottomRightRadius: property([tokens.border.radius]).accept(format.px),
  /**
   * Rounds the corners of an element's bottom left outer border edge. Sets the `border-bottom-left-radius` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-left-radius
   */
  borderBottomLeftRadius: property([tokens.border.radius]).accept(format.px),
};

const propertiesNative = {
  /**
   * The **`white-space`** CSS property sets how white space inside an element is handled.
   *
   * **Initial value**: `normal`
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/white-space
   */
  whiteSpace: property().accept<NativeProperty<'whiteSpace'>>(),
  /**
   * Sets the `background-clip` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/background-clip
   */
  backgroundClip: property().accept<NativeProperty<'backgroundClip'>>(),
  /**
   * Sets the `background-image` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/background-image
   */
  backgroundImage: property().accept<NativeProperty<'backgroundImage'>>(),
  /**
   * Sets the `background-position` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/background-position
   */
  backgroundPosition: property().accept<NativeProperty<'backgroundPosition'>>(),
  /**
   * Sets the `background-repeat` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/background-repeat
   */
  backgroundRepeat: property().accept<NativeProperty<'backgroundRepeat'>>(),
  /**
   * Sets the `background-size` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/background-size
   */
  backgroundSize: property().accept<NativeProperty<'backgroundSize'>>(),
  /**
   * Sets the `border` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border
   */
  border: property().accept<NativeProperty<'border'>>(),
  /**
   * Sets the `border-top` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-top
   */
  borderTop: property().accept<NativeProperty<'borderTop'>>(),
  /**
   * Sets the `border-right` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-right
   */
  borderRight: property().accept<NativeProperty<'borderRight'>>(),
  /**
   * Sets the `border-bottom` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom
   */
  borderBottom: property().accept<NativeProperty<'borderBottom'>>(),
  /**
   * Sets the `border-left` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-left
   */
  borderLeft: property().accept<NativeProperty<'borderLeft'>>(),
  /**
   * Sets the `border-style` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-style
   */
  borderStyle: property([tokens.border.style]).accept<
    NativeProperty<'borderStyle'>
  >(),
  /**
   * Sets the `border-top-style` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-style
   */
  borderTopStyle: property([tokens.border.style]).accept<
    NativeProperty<'borderTopStyle'>
  >(),
  /**
   * Sets the `border-right-style` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-style
   */
  borderRightStyle: property([tokens.border.style]).accept<
    NativeProperty<'borderRightStyle'>
  >(),
  /**
   * Sets the `border-bottom-style` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-style
   */
  borderBottomStyle: property([tokens.border.style]).accept<
    NativeProperty<'borderBottomStyle'>
  >(),
  /**
   * Sets the `border-left-style` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-left-style
   */
  borderLeftStyle: property([tokens.border.style]).accept<
    NativeProperty<'borderLeftStyle'>
  >(),
  /**
   * Sets the `border-width` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-width
   */
  borderWidth: property([tokens.border.width]).accept(format.px),
  /**
   * Sets the `border-top-width` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-width
   */
  borderTopWidth: property([tokens.border.width]).accept(format.px),
  /**
   * Sets the `border-right-width` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-right-width
   */
  borderRightWidth: property([tokens.border.width]).accept(format.px),
  /**
   * Sets the `border-bottom-width` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-width
   */
  borderBottomWidth: property([tokens.border.width]).accept(format.px),
  /**
   * Sets the `border-left-width` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/border-left-width
   */
  borderLeftWidth: property([tokens.border.width]).accept(format.px),
  /**
   * Sets the `content` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/content
   */
  content: property().accept<NativeProperty<'content'>>(),
  /**
   * Sets the `position` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/position
   */
  position: property().accept<NativeProperty<'position'>>(),
  /**
   * Sets the `top` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/top
   */
  top: property([tokens.space]).accept(format.px),
  /**
   * Sets the `right` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/right
   */
  right: property([tokens.space]).accept(format.px),
  /**
   * Sets the `bottom` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/bottom
   */
  bottom: property([tokens.space]).accept(format.px),
  /**
   * Sets the `left` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/left
   */
  left: property([tokens.space]).accept(format.px),
  /**
   * Sets the `boxSizing` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/box-sizing
   */
  boxSizing: property().accept<NativeProperty<'boxSizing'>>(),
  /**
   * Sets the `animation` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/animation
   */
  animation: property().accept<NativeProperty<'animation'>>(),
  /**
   * Sets the `animation-fill-mode` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/animation-fill-mode
   */
  animationFillMode: property().accept<NativeProperty<'animationFillMode'>>(),
  /**
   * Sets the `animation-timeline` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/animation-timeline
   */
  animationTimeline: property().accept<NativeProperty<'animationTimeline'>>(),
  /**
   * Sets the `appearance` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/appearance
   */
  appearance: property().accept<NativeProperty<'appearance'>>(),
  /**
   * Sets the `clip` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/clip
   */
  clip: property().accept<NativeProperty<'clip'>>(),
  /**
   * Sets the `clip-path` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/clip-path
   */
  clipPath: property().accept<NativeProperty<'clipPath'>>(),
  /**
   * Sets the `cursor` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/cursor
   */
  cursor: property().accept<NativeProperty<'cursor'>>(),
  /**
   * Sets the `filter` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/filter
   */
  filter: property().accept<NativeProperty<'filter'>>(),
  /**
   * Sets the `font-variant-numeric` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-numeric
   */
  fontVariantNumeric: property().accept<NativeProperty<'fontVariantNumeric'>>(),
  /**
   * Sets the `list-style` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/list-style
   */
  listStyle: property().accept<NativeProperty<'listStyle'>>(),
  /**
   * Sets the `list-style-type` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type
   */
  listStyleType: property().accept<NativeProperty<'listStyleType'>>(),
  /**
   * Sets the `object-fit` CSS property.
   *
   * see https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit
   */
  objectFit: property().accept<NativeProperty<'objectFit'>>(),
  /**
   * Sets the `overflow` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/overflow
   */
  overflow: property().accept<NativeProperty<'overflow'>>(),
  /**
   * Sets the `overflow-y` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-y
   */
  overflowY: property().accept<NativeProperty<'overflowY'>>(),
  /**
   * Sets the `overflow-x` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-x
   */
  overflowX: property().accept<NativeProperty<'overflowX'>>(),
  /**
   * Sets the `opacity` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/opacity
   */
  opacity: property().accept<NativeProperty<'opacity'>>(),
  /**
   * Sets the `pointerEvents` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/pointer-events
   */
  pointerEvents: property().accept<NativeProperty<'pointerEvents'>>(),
  /**
   * Sets the `resize` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/resize
   */
  resize: property().accept<NativeProperty<'resize'>>(),
  /**
   * Sets the `scroll-margin-top` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/scroll-margin-top
   */
  scrollMarginTop: spaceProperty,
  /**
   * Sets the `scrollbar-width` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/scrollbar-width
   */
  scrollbarWidth: property().accept<NativeProperty<'scrollbarWidth'>>(),
  /**
   * Sets the `tab-size` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/tab-size
   */
  tabSize: property().accept<NativeProperty<'tabSize'>>(),
  /**
   * Sets the `transition` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/transition
   */
  transition: property().accept<NativeProperty<'transition'>>(),
  /**
   * Sets the `transition-property` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transition-property
   */
  transitionProperty: property().accept<NativeProperty<'transitionProperty'>>(),
  /**
   * Sets the `transition-duration` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transition-duration
   */
  transitionDuration: property([tokens.animation.duration]).accept(format.ms),
  /**
   * Sets the `transition-timing-function` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transition-timing-function
   */
  transitionTimingFunction: property([tokens.animation.easing]).accept<
    NativeProperty<'transitionTimingFunction'>
  >(),
  /**
   * Sets the `transform-origin` CSS property.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin
   */
  transformOrigin: property().accept<NativeProperty<'transformOrigin'>>(),
  /**
   * Sets the `textAlign` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/text-align
   */
  textAlign: property().accept<NativeProperty<'textAlign'>>(),
  /**
   * Sets the `textDecoration` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/text-decoration
   */
  textDecoration: property().accept<NativeProperty<'textDecoration'>>(),
  /**
   * Sets the `textDecorationStyle` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/text-decoration-style
   */
  textDecorationStyle:
    property().accept<NativeProperty<'textDecorationStyle'>>(),
  /**
   * Sets the `textDecorationColor` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/text-decoration-color
   */
  textDecorationColor:
    property().accept<NativeProperty<'textDecorationColor'>>(),
  /**
   * Sets the `textOverflow` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/text-overflow
   */
  textOverflow: property().accept<NativeProperty<'textOverflow'>>(),
  /**
   * Sets the `textTransform` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/text-transform
   */
  textTransform: property().accept<NativeProperty<'textTransform'>>(),
  /**
   * Sets the `textUnderlineOffset` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/text-underline-offset
   */
  textUnderlineOffset:
    property().accept<NativeProperty<'textUnderlineOffset'>>(),
  /**
   * Sets the `user-select` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/user-select
   */
  userSelect: property().accept<NativeProperty<'userSelect'>>(),
  /**
   * Sets the `visibility` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/visibilty
   */
  visibility: property().accept<NativeProperty<'visibility'>>(),
  /**
   * Sets the `zIndex` CSS property.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/z-index
   */
  zIndex: property().accept<NativeProperty<'zIndex'>>(),
};

export function isCssCustomProperty(key: string): boolean {
  // A property must follow this format:
  //   --{ident-start}{ident}
  //
  // Where `ident-start` is defined in the spec here:
  // https://drafts.csswg.org/css-syntax/#ident-start-code-point
  //
  // And `ident` is defined here:
  // https://drafts.csswg.org/css-syntax/#ident-code-point
  //
  // Note that `ident` is the same as `ident-start` with digits
  // and `-` added. This regex is slightly more permissive by using
  // the \p regex classes but is easier than encoding the explicit
  // unicode ranges
  return /^--[\p{L}\p{S}_][\p{L}\p{S}_\d-]*$/u.test(key);
}

const customProperties = {
  '*'(value: string, _set: Style.PluginAPI, key: string): string {
    if (!isCssCustomProperty(key) && process.env.NODE_ENV !== 'production') {
      throw new Error(`"${key}" is not a valid custom property`);
    }

    return `${value}`;
  },
} as {
  [customProperty: `--${string}`]: Style.Property<string>;
};

const builtInProperties = {
  ...propertiesSize,
  ...propertiesSpace,
  ...propertiesTransform,
  ...propertiesLayout,
  ...propertiesFont,
  ...propertiesColor,
  ...propertiesBoxShadow,
  ...propertiesNative,
  ...customProperties,
};

type BuiltInProperties = typeof builtInProperties;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Properties extends BuiltInProperties {
  // Ensure TypeScript emits an interface when it generates declaration files,
  // which persists the property comments written in this file.
  //
  // For more on this issue, see:
  // https://github.com/microsoft/TypeScript/issues/34777#issuecomment-551993933
}

export const properties = builtInProperties as Properties;
