/* eslint-disable @typescript-eslint/no-use-before-define */
// We store a global list of elements that are currently transitioning,
// mapped to a set of CSS properties that are transitioning for that element.
// This is necessary rather than a simple count of transitions because of browser
// bugs, e.g. Chrome sometimes fires both transitionend and transitioncancel rather
// than one or the other. So we need to track what's actually transitioning so that
// we can ignore these duplicate events.
const transitionsByElement = new Map<EventTarget, Set<string>>();

// A list of callbacks to call once there are no transitioning elements.
const transitionCallbacks = new Set<() => void>();

function setupGlobalEvents() {
  if (typeof window === 'undefined') {
    return;
  }

  const onTransitionStart = (e: TransitionEvent) => {
    // Add the transitioning property to the list for this element.
    // @ts-expect-error - Type 'null' is not assignable to type 'EventTarget'
    let transitions = transitionsByElement.get(e.target);
    if (!transitions) {
      transitions = new Set();
      // @ts-expect-error - Type 'null' is not assignable to type 'EventTarget'
      transitionsByElement.set(e.target, transitions);

      // The transitioncancel event must be registered on the element itself, rather than as a global
      // event. This enables us to handle when the node is deleted from the document while it is transitioning.
      // In that case, the cancel event would have nowhere to bubble to so we need to handle it directly.
      // @ts-expect-error - Type 'Event' is missing the following properties from type 'TransitionEvent'
      e.target?.addEventListener('transitioncancel', onTransitionEnd);
    }

    transitions.add(e.propertyName);
  };

  let onTransitionEnd = (e: TransitionEvent) => {
    // Remove property from list of transitioning properties.
    // @ts-expect-error - Type 'null' is not assignable to type 'EventTarget'
    const properties = transitionsByElement.get(e.target);
    if (!properties) {
      return;
    }

    properties.delete(e.propertyName);

    // If empty, remove transitioncancel event, and remove the element from the list of transitioning elements.
    if (properties.size === 0) {
      // @ts-expect-error - Argument of type '(e: TransitionEvent) => void' is not assignable to parameter of type 'EventListenerOrEventListenerObject | null'
      e.target?.removeEventListener('transitioncancel', onTransitionEnd);
      // @ts-expect-error - Type 'null' is not assignable to type 'EventTarget'
      transitionsByElement.delete(e.target);
    }

    // If no transitioning elements, call all of the queued callbacks.
    if (transitionsByElement.size === 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const cb of transitionCallbacks) {
        cb();
      }

      transitionCallbacks.clear();
    }
  };

  document.body.addEventListener('transitionrun', onTransitionStart);
  document.body.addEventListener('transitionend', onTransitionEnd);
}

if (typeof document !== 'undefined') {
  if (document.readyState !== 'loading') {
    setupGlobalEvents();
  } else {
    document.addEventListener('DOMContentLoaded', setupGlobalEvents);
  }
}

export function runAfterTransition(fn: () => void) {
  // Wait one frame to see if an animation starts, e.g. a transition on mount.
  requestAnimationFrame(() => {
    // If no transitions are running, call the function immediately.
    // Otherwise, add it to a list of callbacks to run at the end of the animation.
    if (transitionsByElement.size === 0) {
      fn();
    } else {
      transitionCallbacks.add(fn);
    }
  });
}
