import {dispute} from '@sail/icons/react/Icon';
import * as React from 'react';

import InvalidLayout from 'gelato/frontend/src/components/Invalid/InvalidLayout';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';

export default function UnsupportedHandoffDevice() {
  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'device_error',
      code: 'device_unsupported',
    });
  });
  const title = <Message {...messages.unsupportedHandoffDeviceTitle} />;
  const description = <Message {...messages.unsupportedHandoffDeviceBody} />;
  return (
    <InvalidLayout title={title} icon={dispute} description={description} />
  );
}
