import * as React from 'react';

const {useLayoutEffect} = React;

/**
 * This hook sets the viewport size as CSS variables on the element.
 * @returns The ref to the element that the CSS variables are set on.
 */
export default function useViewportSizeAsCSSVariables(): React.RefObject<HTMLDivElement> {
  const domRef = React.useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const dom = domRef.current;
    if (dom) {
      let currentHeight = NaN;
      let currentWidth = NaN;
      const setCSSVars = (cssTransitionValue: string) => {
        const vh = window.innerHeight;
        const vw = window.innerWidth;
        if (currentHeight !== vh || currentWidth !== vw) {
          currentHeight = vh;
          currentWidth = vw;
          dom.style.setProperty('--viewport-height', `${vh}px`);
          dom.style.setProperty('--viewport-width', `${vw}px`);
          dom.style.setProperty('--viewport-transition', cssTransitionValue);
        }
      };

      let tid: ReturnType<typeof setTimeout> | null = null;

      const handleResize = () => {
        setCSSVars('none');

        // Recheck the viewport size after a brief delay. This is necessary
        // because sometimes browsers animate the viewport size after screen
        // rotation, and we want to make sure that the CSS variables are set
        // correctly after the animation.
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
          tid = null;
          // If the viewport size changes again post-delay,
          // reapply the CSS variables with transition.
          setCSSVars('height 250ms cubic-bezier(0.76, 0, 0.24, 1)');
        }, 500);
      };

      window.addEventListener('resize', handleResize, true);
      setCSSVars('none');
      return () => {
        window.removeEventListener('resize', handleResize, true);
        tid && clearTimeout(tid);
      };
    }
  }, []);
  return domRef;
}
