import * as React from 'react';
import {createViewConfig} from '@sail/engine';
import type {View} from '../view';
import {Input} from './Input';
import type {FormFieldProps} from './FormField';
import {useFormFieldProps, FormField} from './FormField';
import {css} from '../css';

export type NativeTextAreaProps = View.IntrinsicElement<
  'textarea',
  {
    /**
     * Programmatically mark the value as invalid
     * @external
     */
    invalid?: boolean;
    /**
     * The size of the input
     * @external
     */
    size?: 'small' | 'medium' | 'large';
    /**
     * Allow the user to resize the textarea
     * @external
     */
    resizeable?: boolean;
    /**
     * Rows of text that are visible in the input
     * @external
     */
    rows?: number;
  }
>;

export const NativeTextAreaConfig = createViewConfig({
  props: {} as NativeTextAreaProps,
  name: 'NativeTextArea',
  flattens: true,
  defaults: {
    size: 'medium',
    resizeable: true,
    rows: 3,
  },
});

export const NativeTextArea = NativeTextAreaConfig.createView(
  (props) => <Input as="textarea" {...props} />,
  {
    css: {
      // Shadows get clipped on ios safari without appearance: none
      appearance: 'none',
      backgroundColor: 'form',
      border: 'none',
      keyline: 'form',
      borderRadius: 'form',
      textColor: 'form',
      font: 'label.medium',
      stack: 'x',
      transition: 'box-shadow 240ms',
      width: 'fill',
      boxSizing: 'border-box',

      '::placeholder': {
        color: 'form.placeholder',
      },

      ':disabled': {
        backgroundColor: 'form.disabled',
        color: 'form.disabled',
        overflow: 'hidden',
      },

      ':focus': {
        focusRing: 'focus',
        keyline: 'form.focused',
      },

      // Hack to get around invalid state not coming through
      // props which means it can't be wired to variants
      '[aria-invalid="true"]': {
        keyline: 'form.error',
      },
    },
    variants: {
      resizeable: {
        true: css({
          resize: 'vertical',
        }),
        false: css({
          resize: 'none',
        }),
      },
      size: {
        small: css({
          paddingX: 'small',
          paddingY: 'xsmall',
          font: 'label.small',
          // It wants to use 'large' here, but it doesn't feel
          // great to set the small variant to large, and then use the
          // spacing scale for medium and large.
          minHeight: `space.300`,
        }),
        medium: css({
          paddingX: 'small',
          paddingY: 'xsmall',
          minHeight: 'space.350',
        }),
        large: css({
          paddingX: 'space.150',
          paddingY: 'small',
          font: 'label.large',
          minHeight: 'space.450',
        }),
      },
    },
  },
);

export const TextAreaConfig = createViewConfig({
  props: {} as FormFieldProps<NativeTextAreaProps, typeof NativeTextArea>,
  name: 'TextArea',
  flattens: true,
});

/**
 * @external
 * @param autoComplete
 * @param autoFocus
 * @param cols
 * @param defaultValue
 * @param disabled
 * @param form
 * @param maxLength
 * @param minLength
 * @param name
 * @param onChange
 * @param onKeyPress
 * @param onKeyDown
 * @param onKeyUp
 * @param placeholder
 * @param readOnly
 * @param required
 * @param spellCheck
 * @param tabIndex
 * @param wrap
 */
export const TextArea = TextAreaConfig.createView((props) => {
  const [fieldProps, controlProps] = useFormFieldProps(props);
  return (
    <FormField {...fieldProps}>
      <NativeTextArea {...controlProps} inherits={props.subviews.input} />
    </FormField>
  );
});
