/**
 * Whether the analytics client should send requests to the server.
 * @returns {boolean} Whether the analytics client should be enabled.
 */
export default function isAnalyticsClientEnabled(): boolean {
  if (process.env.BROWSERSTACK) {
    // Browserstack tests don't handle analytics requests, so we need to
    // disable analytics.
    return false;
  }

  if (process.env.NODE_ENV === 'production') {
    // Production environment.
    return true;
  }

  if (process.env.PUPPETEER) {
    // In Puppeteer, the HTTP response for analytics requests are mocked, and
    // there are tests that depend on this. So we need to enable analytics
    return true;
  }

  return false;
}
