import type {FetchPolicy, WatchQueryFetchPolicy} from '@apollo/client';

export default function ensureCorrectFetchPolicy(
  fetchPolicy: WatchQueryFetchPolicy | undefined,
): FetchPolicy {
  // We need to override the fetch policy in case it is cache-and-network.
  // This is so that we don't cause additional rerenders in the prefetching tree.
  // We can only use network-only as it's the only policy with which we can ensure
  // that query will hit the prefetch link during the prefetching phase.
  // This condition would not be met with "cache-first" policy as
  // in case of prefetching a cache-and-network query that already
  // exists in the cache the following would happen:
  // 1. prefetching phase would retrieve query from apollo cache
  // 2. rendering phase would 1st retrieve query from apollo and then issue a network request.
  if (!fetchPolicy || fetchPolicy === 'cache-and-network') {
    return 'network-only';
  }

  return fetchPolicy;
}
