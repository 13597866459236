import {SafeLinkOptions, UrlLike, isSafeUrl} from './utils';

interface WindowFeatures {
  popup?: boolean;

  /**
   * If this feature is set, the new window will not have access to the originating window via `window.opener` and returns `null`.
   */
  noopener?: boolean;

  /**
   * If this feature is set, the browser will omit the `Referer` header, as well as set `noopener` to true.
   */
  noreferrer?: boolean;

  /**
   * Specifies the width of the content area, including scrollbars. The minimum required value is 100.
   * @alias innerWidth
   */
  width?: number;

  /**
   * Specifies the height of the content area, including scrollbars. The minimum required value is 100.
   * @alias innerHeight
   */
  height?: number;

  /**
   * Specifies the distance in pixels from the left side of the work area as defined by the user's operating system where the new window will be generated.
   * @alias screenX
   */
  left?: number;

  /**
   * Specifies the distance in pixels from the top side of the work area as defined by the user's operating system where the new window will be generated.
   * @alias screenY
   */
  top?: number;

  /**
   * menubar is now considered a legacy feature in modern browsers
   * @deprecated
   */
  menubar?: boolean;

  /**
   * toolbar is now considered a legacy feature in modern browsers
   * @deprecated
   */
  toolbar?: boolean;

  /**
   * location is now considered a legacy feature in modern browsers
   * @deprecated
   */
  location?: boolean;

  /**
   * status is now considered a legacy feature in modern browsers
   * @deprecated
   */
  status?: boolean;

  /**
   * directories is now considered a legacy feature in modern browsers
   * @deprecated
   */
  resizable?: boolean;

  /**
   * scrollbars is now considered a legacy feature in modern browsers
   * @deprecated
   */
  scrollbars?: boolean;

  [key: string]: unknown;
}

// Construct features string to spec https://developer.mozilla.org/en-US/docs/Web/API/Window/open#windowfeatures
const featuresToString = (features: WindowFeatures) => {
  return Object.keys(features)
    .map((key) => {
      const value = features[key];
      return `${key}=${value}`;
    })
    .join(',');
};

/**
 * Opens a new resource with the given URL into a new browsing context (i.e. tab, window, or iframe).
 * Use this instead of the native method: `window.open()`
 * This applies additional security measures to prevent malicious URLs from being opened.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
 * @deprecated Use `safeWindowOpen` instead.
 * @example
 * ```
 * windowOpen("/user-feedback", "_blank", "noopener noreferrer"); // equivalent to window.open("/user-feedback", "_blank", "noopener noreferrer")
 * ```
 * @param url The URL to be opened. If a string is passed, it is parsed as a URL
 * @param target The name of the browsing context (e.g. tab, window, or iframe). _self, _blank, _parent, or _top can also be used. If omitted, it defaults to _blank
 * @param features A string containing a comma-separated list of window features given with their corresponding values (e.g. "height=400,width=400,top=100,left=100")
 */
export const windowOpen = (
  url?: UrlLike,
  target?: string,
  features?: string,
  options?: SafeLinkOptions,
) => {
  if (url && !isSafeUrl(url, options)) {
    return;
  }

  return window.open(url, target, features);
};

/**
 * Opens a new resource with the given URL into a new browsing context (i.e. tab, window, or iframe).
 * Use this instead of the native method: `window.open()`
 * This applies additional security measures to prevent malicious URLs from being opened.
 * It also sets `noopener` and `noreferrer` features by default.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
 * @example
 * ```
 * safeWindowOpen("/user-feedback", "_blank", {width: 400, height: 400}); // equivalent to window.open("/user-feedback", "_blank", "width=400,height=400,noopener=true,noreferrer=true")
 * ```
 * @param url The URL to be opened. If a string is passed, it is parsed as a URL
 * @param target The name of the browsing context (e.g. tab, window, or iframe). _self, _blank, _parent, or _top can also be used. If omitted, it defaults to _blank
 * @param features A map containing window features
 */
export const safeWindowOpen = (
  url?: UrlLike,
  target?: string,
  features?: WindowFeatures,
  options?: SafeLinkOptions,
) => {
  if (url && !isSafeUrl(url, options)) {
    return;
  }

  // Set default features
  const fullFeatues = {
    noopener: true,
    noreferrer: true,
    ...features,
  };
  const featuresStr = featuresToString(fullFeatues);

  return window.open(url, target, featuresStr);
};
