import analytics from 'gelato/frontend/src/lib/analytics';
import blobToCanvasImageSource from 'gelato/frontend/src/lib/blobToCanvasImageSource';
import {clearCanvasRenderingContext2D} from 'gelato/frontend/src/lib/CanvasMemoryLeakPatch';
import isImageSingleColor, {
  MAX_PIXEL_SIZE,
} from 'gelato/frontend/src/lib/isImageSingleColor';
import {handleException} from 'gelato/frontend/src/lib/sentry';

/**
 * Reports an invalid image to analytics, depending on the feature flag.
 * @param blob The source image to check for validity.
 * @param fileInfo The file information to report.
 */
export default async function maybeReportInvalidImage(
  blob: Blob,
  fileInfo: {
    file: string;
    fileSize: number;
    fileType: string;
  },
) {
  if (!(blob instanceof Blob)) {
    return;
  }

  const visibilityState = document.visibilityState;

  try {
    const isInvalid = await isInvalidImage(blob);
    if (isInvalid) {
      // The image appears "blank", log it for debugging purpose.
      analytics.track('reportInvalidImage', {
        ...fileInfo,
        visibilityState,
      });
    }
  } catch (ex) {
    // Something went wrong, log the error.
    analytics.track('reportInvalidImage', {
      ...fileInfo,
      visibilityState,
      error: String(ex),
    });
  }
}

// A reusable canvas for thumbnail generation.
const thumbnailCanvas = document.createElement('canvas');

export async function isInvalidImage(blob: Blob & {_invalidImage?: boolean}) {
  if (typeof blob._invalidImage === 'boolean') {
    // Blob is immutable, so we could used the cached the result.
    // This is useful when we have multiple calls to this function.
    // Note that we are using a non-standard property '_invalidImage'.
    return blob._invalidImage;
  }

  const imageData = await blobToThumbnail(blob);
  const result = !!imageData && isImageSingleColor(imageData);
  // Add a flag to the blob so later we can reuse it to avoid checking it again.
  Object.assign(blob, {_invalidImage: result});
  return result;
}

// Convert the blob into a thumbnail that we can check quickly.
async function blobToThumbnail(blob: Blob): Promise<ImageData | null> {
  const canvas = thumbnailCanvas;
  const size = MAX_PIXEL_SIZE;
  canvas.width = size;
  canvas.height = size;

  let imageData: ImageData | null = null;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    try {
      const {imageSource, release} = await blobToCanvasImageSource(blob);
      ctx.drawImage(imageSource, 0, 0, size, size);
      imageData = ctx.getImageData(0, 0, size, size);
      release();
    } catch (ex) {
      // This rarely (< 0.05%) happens, but we should report it.
      // Normally, this could be one of these types of error.
      // "InvalidStateError: The ImageBitmap could not be allocated."
      // "InvalidStateError: The object is in an invalid state.".
      handleException(ex, 'blobToThumbnail:drawImage');
    } finally {
      clearCanvasRenderingContext2D(ctx);
    }
  } else {
    // This is likely a memory limit issue, so we should report it.
    throw new Error('Could not get 2D context');
  }
  if (!imageData) {
    throw new Error('Could not get ImageData');
  }
  return imageData;
}
