import * as Sentry from '@sentry/browser';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {injectIntl} from 'react-intl';

import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import {WelcomeLayout} from 'gelato/frontend/src/components/Welcome/WelcomeLayout';
import useSetStartedAtMutation from 'gelato/frontend/src/graphql/mutations/useSetStartedAtMutation';
import analytics from 'gelato/frontend/src/lib/analytics';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {PAGE_TITLE_MESSAGE} from 'gelato/frontend/src/lib/constants';
import {
  Experiments,
  SetPageCardPropsContext,
} from 'gelato/frontend/src/lib/contexts';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {isMobileDevice} from 'gelato/frontend/src/lib/device';
import {
  useRouter,
  useSession,
  useFeatureFlags,
  useExperiments,
  useBranding,
  useConsentConfig,
  useWelcomeHandoff,
} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {LocalRouter} from 'gelato/frontend/src/lib/localRouter';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import Storage from 'gelato/frontend/src/lib/Storage';
import messages from 'gelato/frontend/src/local_pages/messages/welcome';
import {DetectorError} from 'gelato/frontend/src/ML/detectors/DetectorError';

import type {GraphQlField} from '@sail/data';
import type {Flags} from '@stripe-internal/data-gelato/schema/types';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';
import type {Session, SetPageCardProps} from 'gelato/frontend/src/lib/contexts';
import type {PageProps} from 'gelato/frontend/src/lib/localRouter';
import type {IntlShape} from 'react-intl';

type WrapperProps = PageProps & {
  intl: IntlShape;
};

const loadAsyncContent = (
  router: LocalRouter,
  session: GraphQlField<GetSessionQueryData, 'session'>,
  flags: ReadonlyArray<Flags> | null | undefined,
  experiments:
    | Experiments
    | null
    | undefined
    | ReadonlyArray<GraphQlField<GetSessionQueryData, 'experiments'>>,
) => {
  // Pre-build the IDDetector if we need it.
  if (session && session.missingFields && flags && experiments) {
    if (session.missingFields.includes('id_document_images')) {
      import('gelato/frontend/src/ML/detectors/IDDetector')
        .then((mod) => mod.default)
        .then(async (mod) => {
          try {
            Sentry.addBreadcrumb({
              category: 'ML',
              message: 'Warm up models',
              level: Sentry.Severity.Info,
            });
            await mod.getInstance().warmup();
            Sentry.addBreadcrumb({
              category: 'ML',
              message: 'Models warmed up',
              level: Sentry.Severity.Info,
            });
          } catch (e) {
            Sentry.addBreadcrumb({
              category: 'ML',
              message: 'Model warm up failed',
              level: Sentry.Severity.Error,
              data:
                e instanceof DetectorError
                  ? {
                      cause: e.cause,
                    }
                  : undefined,
            });
            handleException(e, 'IDDetector failed to `warmup` on welcome');
          }
        });
    }
  }
  router.loadAsyncRoutes();
};

enum HandoffNudgeMode {
  none = 'none',
  force = 'force',
  encourage = 'encourage',
}

type WelcomeLayoutWrapperProps = PageProps & {
  session: Session;
  setPageCardProps: SetPageCardProps;
  handoffNudgeMode: HandoffNudgeMode;
  hasReusableNetworkedIdentity: boolean;
  welcomeHandoff: boolean;
};

const WelcomeLayoutWrapper = ({
  session,
  setPageCardProps,
  handoffNudgeMode,
  hasReusableNetworkedIdentity,
  welcomeHandoff,
}: WelcomeLayoutWrapperProps) => {
  const router = useRouter();
  const flags = useFeatureFlags();
  const experiments = useExperiments();
  const branding = useBranding();
  const consentConfig = useConsentConfig();

  const [primaryPending, setPrimaryPending] = React.useState(false);
  const [secondaryPending, setSecondaryPending] = React.useState(false);
  const [setStartedAt] = useSetStartedAtMutation();
  const startPageRedirectDone = React.useRef(false);

  React.useEffect(() => {
    if (startPageRedirectDone.current) {
      return;
    }
    analytics.track('startPageRedirectDone', {
      timeStartRedirectDone: Date.now(),
      startPageRedirectedTo: window.location.pathname,
    });
    startPageRedirectDone.current = true;
  }, [startPageRedirectDone]);

  const platformName = branding?.platformName || '';
  const additionalPlatformName = branding?.additionalPlatformName;

  const hasDocumentRequirement =
    !!session?.missingFields?.includes('id_document_images');

  let titleMessage;
  if (branding?.isStripe) {
    titleMessage =
      platformName === 'Stripe' ? messages.titleStripe : messages.titleNoStripe;
  } else {
    titleMessage = messages.title;
  }

  const privacyMessage = branding?.isStripe
    ? messages.privacyStripe
    : messages.privacy;
  const [needMessage, setNeedMessage] = React.useState(messages.needFallback);
  const {privacyPolicyUrl, additionalPrivacyPolicyUrl, imageMode} =
    consentConfig as GraphQlField<
      GetSessionQueryData,
      'session',
      'consentConfig'
    >;

  React.useEffect(() => {
    if (imageMode === 'identity_document_and_selfie') {
      setNeedMessage(messages.needIdAndSelfie);
    } else if (imageMode === 'identity_document') {
      setNeedMessage(messages.needId);
    } else {
      setNeedMessage(messages.needFallback);
    }
  }, [imageMode]);

  const routeToNextDataPage = async (session: Session) => {
    loadAsyncContent(router, session, flags, experiments);
    const nextRoute = await nextDataPageForSession(session);
    router.push(nextRoute);
  };

  const primaryMessage =
    (handoffNudgeMode === HandoffNudgeMode.encourage ||
      handoffNudgeMode === HandoffNudgeMode.force) &&
    !isMobileDevice()
      ? messages.completeMobileDevice
      : messages.getStarted;

  const onPrimaryClick = () => {
    setPrimaryPending(true);
    if (
      handoffNudgeMode === HandoffNudgeMode.encourage ||
      handoffNudgeMode === HandoffNudgeMode.force
    ) {
      router.push({pathname: '/handoff'});
    } else if (hasReusableNetworkedIdentity && hasDocumentRequirement) {
      router.push({pathname: '/link'});
    } else if (session) {
      setStartedAt();
      routeToNextDataPage(session);
    }
  };

  const onSecondaryClick = () => {
    setSecondaryPending(true);
    if (hasReusableNetworkedIdentity && hasDocumentRequirement) {
      // If we continue on this device, we should push the user to login with link
      setStartedAt();
      router.push({pathname: '/link'});
    } else if (session && handoffNudgeMode === HandoffNudgeMode.encourage) {
      // Continue on the same (desktop) device.
      setStartedAt();
      routeToNextDataPage(session);
    } else {
      router.push({pathname: '/handoff'});
    }
  };

  const continueOnDeviceMessage = isMobileDevice()
    ? messages.completeOnAnotherDevice
    : messages.completeMobileDevice;
  let secondaryMessage;

  // If we are forcing handoff or we don't support handoff from this screen,
  // then there is no secondary message since only option is complete on mobile or to get started
  if (welcomeHandoff && handoffNudgeMode !== HandoffNudgeMode.force) {
    if (handoffNudgeMode === HandoffNudgeMode.encourage) {
      secondaryMessage = messages.continueOnThisDevice;
    } else {
      secondaryMessage = continueOnDeviceMessage;
    }
  }

  return (
    <WelcomeLayout
      primaryMessage={primaryMessage}
      onPrimaryClick={onPrimaryClick}
      secondaryMessage={secondaryMessage}
      onSecondaryClick={onSecondaryClick}
      platformName={platformName}
      additionalPlatformName={additionalPlatformName}
      primaryPending={primaryPending}
      secondaryPending={secondaryPending}
      titleMessage={titleMessage}
      needMessage={needMessage}
      privacyMessage={privacyMessage}
      privacyPolicyUrl={privacyPolicyUrl}
      additionalPrivacyPolicyUrl={additionalPrivacyPolicyUrl}
    />
  );
};

export function WelcomePage({intl: {formatMessage}, ...props}: WrapperProps) {
  const flags = useFeatureFlags();
  const router = useRouter();
  const session = useSession();
  const experiments = useExperiments();
  const welcomeHandoff = useWelcomeHandoff();

  const setPageCardProps = React.useContext(SetPageCardPropsContext);
  const [skipping, setSkipping] = React.useState<boolean>(false);

  React.useEffect(() => {
    const maybeSkipWelcomePage = async () => {
      if (session) {
        // Skip welcome in secondary mode
        const skipInSecondary = session.operatingMode === 'secondary';
        // Skip welcome if VI was created with skip_welcome_page set
        const skipFromVI = session.skipWelcomePage;

        if (skipInSecondary || skipFromVI) {
          setSkipping(true);
          loadAsyncContent(router, session, flags, experiments);
          const nextRoute = await nextDataPageForSession(session);
          router.replace(nextRoute);
        }
      }
    };
    maybeSkipWelcomePage();
  }, [session, flags, router, experiments]);

  if (!session || !flags || skipping) {
    return <LoadingBox />;
  }

  let handoffNudgeMode = HandoffNudgeMode.none;

  if (!isMobileDevice() && welcomeHandoff) {
    // Desktop.
    if (session.fMobile) {
      handoffNudgeMode = HandoffNudgeMode.force;
    } else {
      handoffNudgeMode = HandoffNudgeMode.encourage;
    }
  }

  const settings = Storage.getIdentitySettings();
  const hasReusableNetworkedIdentity =
    !!settings?.networked_identity_reuse_available;
  postIframeEvent('load');

  return (
    <>
      <Helmet>
        <title>{formatMessage(PAGE_TITLE_MESSAGE)}</title>
      </Helmet>
      <WelcomeLayoutWrapper
        {...props}
        router={router}
        session={session}
        setPageCardProps={setPageCardProps}
        handoffNudgeMode={handoffNudgeMode}
        hasReusableNetworkedIdentity={hasReusableNetworkedIdentity}
        welcomeHandoff={!!welcomeHandoff}
      />
    </>
  );
}

setComponentConfig(WelcomePage, {componentName: 'welcome'});

export default injectIntl(WelcomePage);
