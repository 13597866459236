import type {GraphQlField} from '@sail/data';
import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';
import type {GetSessionLazyQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionLazyQuery';

/**
 * Refreshes the session at the GraphQL store.
 * @param state The application state.
 * @param runtime The application runtime.
 * @returns The session refreshed.
 */
export default async function refreshSession(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
): Promise<GraphQlField<GetSessionLazyQueryData, 'session'>> {
  const resp = await runtime.api.query.getSessionQuery();
  return resp.data?.session!;
}
