/**
 * @fileoverview This file contains the camera actions for the application
 *   controller. Each action is a function that takes the application controller
 *   and update the application state accordingly.
 */

import {
  ErrorCode,
  convertToErrorDetail,
} from 'gelato/frontend/src/controllers/states/ErrorState';
import updateDeviceStatusMutation from 'gelato/frontend/src/controllers/utils/updateDeviceStatusMutation';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import enqueuePromise from 'gelato/frontend/src/lib/enqueuePromise';
import isBrowserCameraPermissionEnabled from 'gelato/frontend/src/lib/isBrowserCameraPermissionEnabled';
import {reportMetric} from 'gelato/frontend/src/lib/metricsBatcher';
import queryCameraPermissionState from 'gelato/frontend/src/lib/queryCameraPermissionState';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';
import {
  isAndroid,
  isIOSUA,
  isMobileUA,
} from 'gelato/frontend/src/lib/userAgent';
import waitUntil from 'gelato/frontend/src/lib/waitUntil';

import type {
  ApplicationAction,
  ApplicationActionWithPayload,
} from 'gelato/frontend/src/controllers/types';

// The backend requires images to be at most 1000x1000 pixels. Images exceeding
// this limit are resized for compatibility with the ML models. Locally, we use
// larger image sizes to prompt the browser to select a high-resolution camera
// via video constraints. Note that larger images do increase memory usage and
// will slow down performance. To balance quality and performance, we
// use larger sizes on iOS (to select the highest-quality camera) and smaller
// sizes on other devices (to ensure smoother performance).
// Check https://webrtchacks.com/video-constraints-2/ for varies camera
// constraints.
export const IMAGE_MAX_DIMENSION =
  // For iOS, use 2160x2160 to select the highest-quality camera.
  (isIOSUA() && 2160) ||
  // For Android, use 1080x1080 to save memory and improve performance.
  // Note that the camera selection on Android is handled by the
  // getDefaultDeviceId() function separately.
  (isAndroid() && 1080) ||
  // Default value for other devices.
  1920;

// The names of the camera lens that represent the "Back Triple Camera" on
// iPhone in different locales.
// This list is populated based on this hubble query
// https://hubble.corp.stripe.com/queries/hedger/83be5586
const IPHONE_BACK_TRIPLE_CAMERA_NAMES = new Set([
  'Back Triple Camera', // en_US
  'Bakre trippelkamera', // sv_SE
  'Cameră triplă spate', // ro_RO
  'Cámara posterior triple', // es_MX
  'Cámara trasera triple', // es_ES
  'Câmara tripla traseira', // pt_PT
  'Câmera Tripla Traseira', // pt_BR
  'Fotocamera tripla (posteriore)', // it_IT
  'Hátsó, tripla kamera', // hu_HU
  'Kolmoistakakamera', // fi_FI
  'Rückseitige Triple-Kamera', // de_DE
  'Stražnja trostruka kamera', // hr_HR
  'Tiga Kamera Belakang', // id_ID
  'Triple appareil photo arrière', // fr_FR
  'Trippelt kamera bak', // no_NO
  'Tylny aparat trójobiektywowy', // pl_PL
  'Zadní trojitý fotoaparát', // cs_CZ
  'Üçlü Arka Kamera', // tr_TR
  'Πίσω τριπλή κάμερα', // el_GR
  'Задна тройна камера', // bg_BG
  'Задня потроєна камера', // uk_UA
  'Задняя тройная камера', // ru_RU
  'מצלמה משולשת אחורית', // he_IL
  'كاميرا خلفية ثلاثية', // ar_SA
  'กล้องด้านหลังสามกล้อง', // th_TH
  '后置三镜头', // zh_CN
  '後置三鏡頭相機', // zh_TW
  '背面トリプルカメラ', // ja_JP
  '후면 트리플 카메라', // ko_KR
]);

const FirstVideoMetricRecord = {
  availableAt: 0,
  startedAt: 0,
};

// The test ID for the video element that plays `FakeMediaStream`.
export const FAKE_VIDEO_ELEMENT_TEST_ID = 'fake-camera-video';

// The camera ID cache is used to cache the device ID of the camera.
const devideIdCache = new Map<'user' | 'environment', string>();

/**
 * The pending promise to enumerate the camera devices.
 */
let enumerateCameraDevicesPromise: Promise<MediaDeviceInfo[]> | null = null;

/**
 * Helper function to enumerate the camera devices.
 * @returns The list of camera devices.
 */
export function enumerateCameraDevices(): Promise<MediaDeviceInfo[]> {
  if (enumerateCameraDevicesPromise) {
    // There is a pending promise to enumerate the camera devices.
    // Return the pending promise.
    return enumerateCameraDevicesPromise;
  }

  if (
    !window.navigator.mediaDevices ||
    !window.navigator.mediaDevices.enumerateDevices
  ) {
    return Promise.reject(new Error(ErrorCode.browserNotSupported));
  }

  // Calling `navigator.mediaDevices.enumerateDevices()` could be slow on some
  // devices. We cache the promise to avoid calling it multiple times
  // concurrently.

  enumerateCameraDevicesPromise = new Promise(async (resolve, reject) => {
    try {
      const devices = await window.navigator.mediaDevices.enumerateDevices();
      const cameraDevices = devices
        ? devices.filter((d) => d.kind === 'videoinput')
        : [];
      resolve(cameraDevices);
    } catch (ex) {
      // Possible errors (most likely found from Android Webview):
      // - NotSupportedError: The browser does not support the media devices API.
      // - SecurityError: The browser does not allow the page to access the media devices.
      // - AbortError: The browser does not allow the page to access the media devices.
      // - NotFoundError: The browser cannot find the media devices.
      // - NotReadableError: The browser cannot read the media devices.
      // - NotAllowedError: The user has explicitly denied permission to use the media devices.
      reject(asError(ex));
    } finally {
      // Clear the pending promise.
      enumerateCameraDevicesPromise = null;
    }
  });

  return enumerateCameraDevicesPromise;
}

/**
 * Propmt user to grant the camera permission.
 *
 * The prompt can only be triggered by user's action (e.g. clicked a button)
 * otherwise browser would raise error.
 *
 * Also, browser might not prompt the permission request dialog if user had
 * dismissed the dialog for several times previously. In this case, the
 * permission request would be rejected immediately.
 *
 * Consider using `promptCameraPermission()` instead of `startCameraAction()`
 * if all you need is to prompt the user to grant the camera permission without
 * actually starting the camera.
 *
 * @returns Whether the permission is granted.
 */
export async function promptCameraPermission(): Promise<boolean> {
  // Because the camera is a shared resource, we need to enqueue the action
  // to ensure that the camera is not changed by other actions while the
  // current action is in progress.
  return enqueuePromise(() => promptCameraPermissionEnqueued());
}

/**
 * The number of times the permission prompt is denied by the user or the
 * browser.
 */
let promptCameraPermissionFailedCount = 0;

/**
 * Prompt user to grant the camera permission.
 * @returns Whether the permission is granted.
 */
export async function promptCameraPermissionEnqueued(): Promise<boolean> {
  const startTime = Date.now();
  try {
    const videoDevices = await enumerateCameraDevices();
    if (!videoDevices || !videoDevices.length) {
      // Calling `window.navigator.mediaDevices.getUserMedia()` would result in
      // "DOMException: Requested device not found" if there is
      // no camera device. Therefore, we should check if there is any camera
      // device before calling it.
      throw new Error(ErrorCode.webcamNotFound);
    }

    if (window.navigator.userActivation?.isActive === false) {
      // The prompt can only be triggered by user's action (e.g. clicked a
      // button) otherwise browser would not allow it.
      // See https://developer.mozilla.org/en-US/docs/Web/Security/User_activation
      throw new Error(ErrorCode.userActivationRequired);
    }

    if (
      !window.navigator.mediaDevices ||
      !window.navigator.mediaDevices.getUserMedia
    ) {
      throw new Error(ErrorCode.browserNotSupported);
    }

    // This will prompt the user to grant the camera permission, if browser
    // allows it.
    // The video stream is not for display, therefore we set the minimal
    // resolution to 1x1 to reduce the performance overhead.
    const stream = await window.navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width: {ideal: 1},
        height: {ideal: 1},
      },
    });
    await clearStream(stream);
    return true;
  } catch (ex) {
    const cause = asError(ex);
    const error = toWebcamError(cause);

    // If the permission is rejected immediately, it means the browser does
    // not allow the page to prompt the permission request anymore.
    const duration = Date.now() - startTime;

    // Safari lets the permission prompt to be denied manually at most for 3
    // times, and then it will not prompt the permission request anymore.
    const count = promptCameraPermissionFailedCount++;

    // This helps us to understand how many times the permission prompt failed.
    analytics.track('webcamError', {
      duration,
      count,
      errorMessage: error.message,
      errorReason: cause.message,
    });

    // Report error to Sentry, too.
    handleException(
      error,
      `promptCameraPermission: ${duration}ms: ${error.message}`,
    );
    return false;
  }
}

/**
 * Start the camera.
 * @returns Whether the action is successful.
 */
export const startCameraAction: ApplicationActionWithPayload<{
  facing: 'user' | 'environment';
  onboarded?: boolean;
}> = async (controller, payload) => {
  if (!FirstVideoMetricRecord.startedAt) {
    FirstVideoMetricRecord.startedAt = Date.now();
  }

  // Because the camera is a shared resource, we need to enqueue the action
  // to ensure that the camera is not changed by other actions while the
  // current action is in progress.
  return enqueuePromise(() => startCameraActionEnqueued(controller, payload));
};

/**
 * Perform the task of starting the camera.
 * @returns Whether the action is successful.
 */
export const startCameraActionEnqueued: ApplicationActionWithPayload<{
  facing: 'user' | 'environment';
  onboarded?: boolean;
}> = async (controller, payload) => {
  const {
    onboarded: oldOnboarded,
    error: oldError,
    video: oldVideo,
    stream: oldStream,
  } = controller.state.camera;

  const {facing, onboarded} = payload;

  let isUserOnboarded =
    typeof onboarded === 'boolean' ? onboarded : oldOnboarded;

  let doNotReportCameraStatus = false;

  try {
    if (!isUserOnboarded) {
      // User should be onboarded before starting the camera.
      // This is needed to help user better understand the permission prompt
      // before it actually appears since some browsers does not show
      // the prompt more than once.

      // By default, camera permission is mostly not granted. Just throw an
      // error to indicate that the camera is not started and we'd show
      // the UI to ask user to grant the permission.
      // That said, this error should not be reported to server.
      doNotReportCameraStatus = true;

      const hasPermission = await isBrowserCameraPermissionEnabled();
      const permissionState = await queryCameraPermissionState();
      if (hasPermission) {
        // User has granted the camera permission already, do not
        // prompt the permission request again.
        isUserOnboarded = true;
      } else if (permissionState === 'denied') {
        // User had explicitly denied the camera permission.
        throw asError(ErrorCode.webcamPermissionNotGranted);
      } else {
        // permissionState = 'prompt' or 'null'.
        // User has not granted the camera permission yet.
        throw asError(ErrorCode.userIsNotOnboarded);
      }
    }

    if (
      controller.state.camera.status === 'started' &&
      controller.state.camera.facing === facing
    ) {
      // Already started.
      return true;
    }

    controller.update((draft) => {
      shallowMergeInto(draft.camera, {
        facing,
        onboarded: isUserOnboarded,
        pending: true,
        previousError: oldError,
        status: 'started',
      });
    });

    // Clear the old video & stream if they exist.
    // This must be done before requesting a new video stream.
    if (oldVideo && oldStream) {
      if (oldVideo.srcObject === oldStream) {
        // The video is still using the old stream. We need to clear the video.
        await clearVideoStream(oldVideo);
      } else {
        // The video is using a different stream. We can clear both.
        await clearStream(oldStream);
        await clearVideoStream(oldVideo);
      }
    } else if (oldVideo) {
      await clearVideoStream(oldVideo);
    } else if (oldStream) {
      await clearStream(oldStream);
    }

    // Request a video stream from the browser.
    const stream = await requestStream({
      facing: payload.facing,
      userSelectedDeviceId: controller.state.camera.userSelectedDeviceId,
    });

    // Create a new video element if it doesn't exist.
    const video = controller.state.camera.video || createVideoElement();

    // Now play the video stream.
    await assignVideoStream(video, stream);

    const activeDeviceId = getActiveDeviceId(stream);
    let userSelectedDeviceId = controller.state.camera.userSelectedDeviceId;
    if (userSelectedDeviceId !== activeDeviceId) {
      // User selected device ID does not match the active device ID.
      // We should clear it.
      userSelectedDeviceId = null;
    }

    controller.update((draft) => {
      shallowMergeInto(draft.camera, {
        userSelectedDeviceId,
        error: null,
        pending: false,
        ready: true,
        stream,
        video,
      });
    });

    if (!FirstVideoMetricRecord.availableAt) {
      // This is the first time the video is available.
      // Record how long it takes to acquire the video stream.
      const now = Date.now();
      FirstVideoMetricRecord.availableAt = now;
      reportMetric({
        metric: 'gelato_frontend_time_to_webcam_load_ms',
        operation: 'timing',
        value: now - FirstVideoMetricRecord.startedAt,
      });
    }
    // Report the device error to the server asynchrnously.
    updateDeviceStatusMutation(controller.runtime!.apolloClient, {
      deviceData: {
        supported: true,
        reason: undefined,
      },
    });

    return true;
  } catch (ex) {
    const error = asError(ex);

    controller.update((draft) => {
      shallowMergeInto(draft.camera, {
        error,
        pending: false,
        ready: false,
        status: 'stopped',
      });
    });

    if (!doNotReportCameraStatus) {
      // Report the device error to the server asynchrnously.
      updateDeviceStatusMutation(controller.runtime!.apolloClient, {
        deviceData: {
          supported: false,
          reason: convertToErrorDetail(error),
        },
      });
    }

    if (error.message === ErrorCode.userIsNotOnboarded) {
      // The error is jsut a soft error. It's a signal to indicate that
      // that we had not prompted the user to grant the camera permission yet.
      // Our frontend will handle this error and show the UI to ask user to
      // grant the permission.
      //
      // Instead of reporting this error to the Sentry, just log it as
      // analytics.
      analytics.track('cameraPermissionNotPrompted');
    } else {
      handleException(error, `startCamera: ${error.message}`);
    }

    return false;
  }
};

/**
 * Stop the camera and release the camera resources.
 * You may specify an error to indicate that the camera is stopped due to an
 * error.
 * @returns Whether the action is successful.
 */
export const stopCameraAction: ApplicationActionWithPayload<{
  error?: Error | null;
}> = async (controller, payload) => {
  // Because the camera is a shared resource, we need to enqueue the action
  // to ensure that the camera is not changed by other actions while the
  // current action is in progress.
  return enqueuePromise(() => stopCameraActionEnqueued(controller, payload));
};

/**
 * Perform the task of stopping the camera.
 * @param controller The application controller.
 * @param payload The payload of the action.
 * @returns Whether the action is successful.
 */
export const stopCameraActionEnqueued: ApplicationActionWithPayload<{
  error?: Error | null;
}> = async (controller, payload) => {
  try {
    const error = payload.error || null;
    if (
      controller.state.camera.status === 'stopped' &&
      controller.state.camera.error === error
    ) {
      // Already stopped.
      return true;
    }

    const {stream, video} = controller.state.camera;

    controller.update((draft) => {
      shallowMergeInto(draft.camera, {
        pending: true,
        ready: false,
        status: 'stopped',
      });
    });

    video && video.pause();
    stream && (await clearStream(stream));
    video && (await clearVideoStream(video));

    controller.update((draft) => {
      shallowMergeInto(draft.camera, {
        error: error || draft.error,
        pending: false,
        stream: null,
      });
    });
    return true;
  } catch (ex) {
    const error = asError(ex);
    controller.update((draft) => {
      shallowMergeInto(draft.camera, {
        error,
        pending: false,
      });
    });
    handleException(error, `stopCamera: ${error.message}`);
    return false;
  }
};

export const selectCameraDeviceAction: ApplicationActionWithPayload<{
  deviceId: string;
}> = async (controller, payload) => {
  // Because the camera is a shared resource, we need to enqueue the action
  // to ensure that the camera is not changed by other actions while the
  // current action is in progress.
  return enqueuePromise(() =>
    selectCameraDeviceActionEnqueued(controller, payload),
  );
};

export const selectCameraDeviceActionEnqueued: ApplicationActionWithPayload<{
  deviceId: string;
}> = async (controller, payload) => {
  controller.update((draft) => {
    draft.camera.userSelectedDeviceId = payload.deviceId;
  });
  await stopCameraActionEnqueued(controller, {error: null});
  await startCameraActionEnqueued(controller, {
    facing: controller.state.camera.facing,
    onboarded: controller.state.camera.onboarded,
  });
};

/**
 * Pause the camera.
 * @returns Whether the action is successful.
 */
export const pauseCameraAction: ApplicationAction = async (controller) => {
  // Because the camera is a shared resource, we need to enqueue the action
  // to ensure that the camera is not changed by other actions while the
  // current action is in progress.
  return enqueuePromise(() => pauseCameraActionEnqueued(controller));
};

/**
 * Perform the task of pausing the camera.
 * @param controller The application controller.
 * @returns Whether the action is successful.
 */
export const pauseCameraActionEnqueued: ApplicationAction = async (
  controller,
) => {
  // TODO: Implement pause camera.
  return false;
};

// Helper functions ////////////////////////////////////////////////////////////

/**
 * Request a video stream from the browser.
 * @param payload The payload for the request.
 * @returns The video stream.
 */
async function requestStream(payload: {
  facing: 'user' | 'environment';
  userSelectedDeviceId: string | null;
}): Promise<MediaStream> {
  if (
    !window.navigator.mediaDevices ||
    !window.navigator.mediaDevices.getUserMedia
  ) {
    throw new Error(ErrorCode.browserNotSupported);
  }

  const videoDevices = await enumerateCameraDevices();
  if (!videoDevices || !videoDevices.length) {
    throw new Error(ErrorCode.webcamNotFound);
  }

  const {facing: facingMode, userSelectedDeviceId} = payload;

  try {
    let initialDeviceId;

    if (userSelectedDeviceId) {
      initialDeviceId = {ideal: userSelectedDeviceId};
    } else if (devideIdCache.has(facingMode)) {
      initialDeviceId = {ideal: devideIdCache.get(facingMode)};
    }

    const constraints: MediaTrackConstraints = {
      deviceId: initialDeviceId,
      // When `initialDeviceId` is provided, we should not specify the
      // `facingMode` otherwise the browser will ignore the `deviceId`
      // constraint.
      facingMode: initialDeviceId ? undefined : {ideal: facingMode},
      width: {ideal: IMAGE_MAX_DIMENSION},
      // We need to set the `resizeMode` to `none` to prevent the browser from
      // resizing the video stream. This is needed to avoid unncecessary
      // performance overhead.
      // https://caniuse.com/mdn-api_mediatrackconstraints_resizemode
      // @ts-expect-error: The `resizeMode` is not part of the standard.
      resizeMode: 'none',
    };

    let stream = await window.navigator.mediaDevices.getUserMedia({
      audio: false,
      video: constraints,
    });

    const activeId = getActiveDeviceId(stream);
    if (activeId && activeId !== initialDeviceId?.ideal) {
      // The initial device ID is not the active device ID. This means
      // that the initial device ID is not valid. Try if we could switch to
      // the default device ID instead.

      const defaultId = await getDefaultDeviceId(facingMode);
      // If the default device ID is different from the active device ID, we
      // should try to switch to the default device ID.
      // This is needed to ensure that the camera is using the correct camera
      // lens.
      if (defaultId && defaultId !== activeId) {
        // Clear the old stream.
        await clearStream(stream);
        // Request a new stream with the default device ID.
        stream = await window.navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            ...constraints,
            facingMode: {
              ideal: facingMode,
            },
            deviceId: {
              ideal: defaultId,
            },
          },
        });

        const deviceId = getActiveDeviceId(stream);
        // Cache the device ID so the next time we request a video stream, we
        // could start faster with the cached device ID.
        deviceId && devideIdCache.set(facingMode, deviceId);
      }
    }

    const currentDeviceId = getActiveDeviceId(stream);
    const currentDevice = videoDevices.find(
      (d) => d.deviceId === currentDeviceId,
    );
    analytics.track('videoDevices', {
      active: currentDevice?.label || 'unknown',
      devices: videoDevices.map((d) => d.label).sort(),
    });

    // TODO: we might need to switch the camera len (i.e device_id) on Android
    // if the current camera is not the one that we want.
    return stream;
  } catch (ex) {
    const error = asError(ex);
    throw toWebcamError(error);
  }
}

/**
 * Transforms a browser error to a known webcam error. If the error is already
 * a known webcam error, it will be returned as is.
 * @param cause The error to be transformed.
 * @returns The transformed error.
 */
export function toWebcamError(cause: Error): Error {
  const knownErrors = [
    ErrorCode.webcamMightBeUsedByAnotherProcess,
    ErrorCode.webcamPermissionNotGranted,
    ErrorCode.webcamNotFound,
    ErrorCode.webcamMightBeUsedByAnotherProcess,
    ErrorCode.videoStreamNotAvailable,
  ];

  if (knownErrors.includes(cause.message)) {
    return cause;
  }

  switch (cause.name) {
    case 'AbortError':
      // Likely the camera is using by another browser.
      return new Error(ErrorCode.webcamMightBeUsedByAnotherProcess, {
        cause,
      });
    case 'NotAllowedError':
      // The user has explicitly denied permission to use the camera.
      return new Error(ErrorCode.webcamPermissionNotGranted, {
        cause,
      });
    case 'NotFoundError':
      // The browser cannot find the camera.
      return new Error(ErrorCode.webcamNotFound, {
        cause,
      });
    case 'NotReadableError':
      // Likely the camera is using by another browser.
      return new Error(ErrorCode.webcamMightBeUsedByAnotherProcess, {
        cause,
      });
    default:
      return new Error(ErrorCode.videoStreamNotAvailable, {cause});
  }
}

/**
 * Get the active device ID of a video stream.
 * @param stream The video stream.
 * @returns The device ID.
 */
export function getActiveDeviceId(stream: MediaStream): string | undefined {
  const track = stream.getVideoTracks()[0];
  const settings = track?.getSettings();
  return settings?.deviceId;
}

/**
 * Resolves the default device ID for the given facing mode.
 * Note that this function requires the camera permission to be granted first
 * otherwise it will always return `undefined`.
 * @param facing The facing mode.
 * @returns The default device ID.
 */
export async function getDefaultDeviceId(
  facing: 'user' | 'environment',
): Promise<string | undefined> {
  const videoDevices = await enumerateCameraDevices();

  if (isAndroid() && facing === 'environment') {
    // On Android, the back side could have multiple cameras. Try find the
    // best camera to use.
    const device = videoDevices.find((obj) => {
      // Typically, Android has these cameras:
      // - "camera2 1, facing front"
      // - "camera2 3, facing front"
      // - "camera2 2, facing back" <- This is what Android uses by default.
      // - "camera2 0, facing back" <- This is what we want to use.
      // On Android, look for "camera2 0". The overwhelming majority of Android
      // devices. seem to get better blur scores using this camera.
      return obj.label.includes('camera2 0,');
    });

    if (device) {
      analytics.track('defaultCameraForIDDocument', {device: device.label});
    }
    return device?.deviceId;
  }

  if (isIOSUA() && facing === 'environment') {
    const device = videoDevices.find((obj) => {
      // Look for "Back Triple Camera" if it exists. This makes the camera
      // to keep the len without switching to another one such as
      // "Back Ultra Wide Camera" or "Desk View Camera" that tend to have
      // distortion or lower quality.
      return IPHONE_BACK_TRIPLE_CAMERA_NAMES.has(obj.label);
    });

    if (device) {
      analytics.track('defaultCameraForIDDocument', {device: device.label});
    }
    return device?.deviceId;
  }

  if (!isMobileUA()) {
    // Select the default camera for desktop.
    // On Desktop, avoid using the virtual camera and use the physical camera
    // with the highest resolution if possible.
    // See the query below for the list of devices:
    // https://hubble.corp.stripe.com/queries/hedger/3ba2ba5e
    const device =
      // The HD camera.
      videoDevices.find((x) => x.label.includes('HD')) ||
      // The USB camera.
      videoDevices.find((x) => x.label.includes('USB')) ||
      // The built-in camera.
      videoDevices.find((x) => x.label.includes('Integrated')) ||
      // The non-virtual camera.
      videoDevices.find((x) => !x.label.includes('Virtual') && !!x.label);

    if (device) {
      analytics.track('defaultCameraForIDDocument', {device: device.label});
      return device?.deviceId;
    }
  }

  // TODO(hedger): Resolve the default device ID for facing mode "user".
  return undefined;
}

/**
 * Assigns a video stream to a video element.
 * @param video The video element to be assigned.
 * @param stream The video stream to be assigned.
 */
export async function assignVideoStream(
  video: HTMLVideoElement,
  stream: MediaStream,
): Promise<void> {
  try {
    if (video.srcObject) {
      // You should have called `clearVideoStream` before calling this function.
      throw new Error(ErrorCode.videoAlreadyHasStream);
    }

    const playVideoPromise = new Promise(async (resolve, reject) => {
      if (video.getAttribute('data-testid') === FAKE_VIDEO_ELEMENT_TEST_ID) {
        // This is a fake video element with a fake video stream.
        // Pretend that the video has started.
        video.srcObject = stream;
        resolve(undefined);
        return;
      }

      const cleanup = () => {
        video.onerror = null;
        video.onloadedmetadata = null;
      };

      // The video isn't usable until the metadata has been loaded.
      video.onloadedmetadata = async () => {
        try {
          await video.play();
          resolve(undefined);
        } catch (error) {
          reject(error);
        } finally {
          cleanup();
        }
      };

      video.onerror = (error) => {
        reject(error);
        cleanup();
      };

      video.srcObject = stream;
    });

    // The video stream might not be ready immediately after the video starts
    // playing. We need to wait until the video stream is ready.
    const verifyVideoPromise = waitUntil(
      () => {
        const tracks = stream.getVideoTracks();
        return !!(tracks.length > 0 && tracks.every(isTrackReady));
      },
      10000,
      200,
    );

    await Promise.all([playVideoPromise, verifyVideoPromise]);
  } catch (error) {
    throw new Error(ErrorCode.videoDidNotStart);
  }
}

/**
 * Clears the video stream from a video element.
 * @param video The video element to be cleared.
 */
export async function clearVideoStream(video: HTMLVideoElement): Promise<void> {
  const {srcObject} = video;
  video.srcObject = null;

  if (typeof MediaStream !== 'undefined' && srcObject instanceof MediaStream) {
    await clearStream(srcObject);
  }
}

/**
 * Clears a media stream.
 * @param stream The media stream to be cleared.
 */
export async function clearStream(stream: MediaStream): Promise<void> {
  const tracks = Array.from(stream.getVideoTracks());
  tracks.forEach((track) => {
    track.stop();
    stream.removeTrack(track);
  });
  try {
    if (tracks.length) {
      await waitUntil(() => tracks.every(isTrackStopped));
    }
  } catch (error) {
    throw new Error(ErrorCode.videoDidNotStop);
  }
}

export function createVideoElement(): HTMLVideoElement {
  const video = document.createElement('video');
  video.setAttribute('autoplay', 'true');
  video.setAttribute('playsinline', 'true');
  video.setAttribute('muted', 'true');

  shallowMergeInto(video.style, {
    backgroundColor: 'black',
    height: '100%',
    left: '0',
    objectFit: 'cover',
    position: 'absolute',
    top: '0',
    width: '100%',
    zIndex: '1',
  });
  return video;
}

export function isTrackStopped(tr: MediaStreamTrack): boolean {
  return tr.readyState === 'ended';
}

export function isTrackReady(tr: MediaStreamTrack): boolean {
  return tr.readyState === 'live';
}
