import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {
  Exact,
  InputMaybe,
  Scalars,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

const doc = graphql`
  mutation ValidateOneTimePasswordMutation(
    $emailCode: String
    $emailDecline: Boolean
    $phoneCode: String
    $phoneDecline: Boolean
  ) {
    validateOneTimePassword(
      emailCode: $emailCode
      emailDecline: $emailDecline
      phoneCode: $phoneCode
      phoneDecline: $phoneDecline
    ) {
      success
      session {
        id
        ...SessionDynamicFragment
      }
    }
  }

  ${sessionDynamicFragment}
`;

export const validateOneTimePasswordMutation: ValidateOneTimePasswordMutationDocument =
  doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const validateOneTimePasswordMutationOptions: GelatoMutationOptionsWithoutVariables<ValidateOneTimePasswordMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useValidateOneTimePasswordMutation(
  options: GelatoMutationOptions<ValidateOneTimePasswordMutationDocument> = {},
): GelatoMutationReturn<ValidateOneTimePasswordMutationDocument> {
  return useGelatoMutation(validateOneTimePasswordMutation, {
    ...validateOneTimePasswordMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type ValidateOneTimePasswordMutationVariables = Exact<{
  emailCode?: InputMaybe<Scalars['String']>;
  emailDecline?: InputMaybe<Scalars['Boolean']>;
  phoneCode?: InputMaybe<Scalars['String']>;
  phoneDecline?: InputMaybe<Scalars['Boolean']>;
}>;

export type ValidateOneTimePasswordMutationData = {
  readonly __typename: 'Mutation';
  readonly validateOneTimePassword: {
    readonly __typename: 'SuccessWithSessionResponse';
    readonly success: boolean;
    readonly session: GraphQlFragmentUnion<
      {readonly __typename: 'Session'; readonly id: string},
      [SessionDynamicFragmentData]
    >;
  };
};

const validateOneTimePasswordMutationPermissions = {
  ...sessionDynamicFragment.permissions,
} as const;

doc.permissions = validateOneTimePasswordMutationPermissions;

export type ValidateOneTimePasswordMutationPermissions = GelatoPermissionToken<
  typeof validateOneTimePasswordMutationPermissions
>;

export type ValidateOneTimePasswordMutationDocument = GraphQlDocument<
  ValidateOneTimePasswordMutationData,
  ValidateOneTimePasswordMutationVariables,
  ValidateOneTimePasswordMutationPermissions
>;
