import {isMobileDevice} from 'gelato/frontend/src/lib/device';
import {breakpoints} from 'sail/contrib/Responsive';

import type {Country} from 'sail/PhoneInput';

export const ACCEPTED_MIME_TYPES: Array<string> = ['image/jpeg', 'image/png'];
export const ANALYTICS_NAMESPACE = 'idprod.frontend';

// The client id of the analytics-js V6+. The value is copied from
// stripe-internal/zoolander/uppsala/src/main/resources/com/stripe/dscore/analyticseventlogger/server/rpcserver/client_config.yaml
export const ANALYTICS_CLIENT_ID = 'identity-frontend';

export const DEFAULT_VALID_EMAIL = 'verified@example.com';
export const DEFAULT_INVALID_EMAIL = 'unverified@example.com';

// Style constants
export const CSS_TRANSITION_DURATION_MS = 200;
export const CSS_TRANSITION_DURATION = `${CSS_TRANSITION_DURATION_MS}ms`;
export const DEFAULT_PAGE_CARD_PROPS = {
  showBackLink: false,
  showPageHeader: true,
  showProgressBar: true,
  size: 'single',
} as const;
export const DEFAULT_IFRAME = false;
export const DEFAULT_EXPERIMENT_VARIANT = 'control';
export const DEFAULT_BREAKPOINT = isMobileDevice() ? 'mobile' : 'desktop';
export const DEFAULT_BRANDING_BUTTON_COLOR = '#5469d4';
export const DEFAULT_BRANDING_PLATFORM_COLOR = '#f7fafc';
export const LOADING_PAGE_HEIGHT = '264px';
export const MASK_FILL_COLOR = '#2A2F45';
export const MASK_OPACITY = 0.4;
export const MAX_FILE_SIZE_MB = 4; // 4MB
export const MOBILE_LAYOUT_BREAKPOINT = 768; // iPad in portrait mode
export const OUTLINE_BORDER_RADIUS = 16;
export const OUTLINE_BORDER_WIDTH = 4;
export const BAD_DOCUMENT_COLOR = '#808080';
export const FACE_OUTLINE_HEIGHT = 390;
export const FACE_OUTLINE_WIDTH = 300;
export const FACE_OUTLINE_ASPECT_RATIO =
  FACE_OUTLINE_HEIGHT / FACE_OUTLINE_WIDTH;
export const PAGE_CARD_DOUBLE_WIDTH = 728;
export const PAGE_CARD_HEIGHT = 450;
export const PAGE_CARD_SINGLE_WIDTH = 460;
export const TITLE_BAR_HEIGHT = 50;
export const IFRAME_HEIGHT_PADDING = 4;
export const PAGE_TITLE_MESSAGE = {
  id: 'page.title',
  description: 'Page Title',
  defaultMessage: 'Identity Verification | Stripe',
} as const;
export const STRIPE_CONTACT_URL = 'https://support.stripe.com/topics/identity';
export const STRIPE_PRIVACY_POLICY_URL = 'https://stripe.com/privacy';
export const STRIPE_PRIVACY_CENTER_URL =
  'https://stripe.com/privacy-center/legal#stripe-identity';
export const STRIPE_IDENTITY_LEARN_MORE_URL =
  'https://support.stripe.com/questions/common-questions-about-stripe-identity';
export const STRIPE_SMS_SERVICES_AGREEMENT_URL =
  'https://stripe.com/ssa#152-notices-and-communications';
export const STRIPE_DATA_SECURITY_PRIVACY_SERVICES_AGREEMENT_URL =
  'https://stripe.com/ssa#9-data-security';
export const STRIPE_DATA_DELETION_REQUEST_URL =
  'https://support.stripe.com/questions/managing-your-id-verification-information#data-deletion';

export const BREAKPOINTS = [
  {
    name: 'mobile',
    breakpoint: breakpoints.widthAtOrBelow(MOBILE_LAYOUT_BREAKPOINT),
  },
  {
    name: 'desktop',
    breakpoint: breakpoints.widthAbove(MOBILE_LAYOUT_BREAKPOINT),
  },
] as const;

export const PHONE_COUNTRY_WHITELIST: ReadonlyArray<Country> = [
  'AO',
  'AR',
  'AS',
  'AT',
  'AU',
  'AW',
  'AX',
  'AZ',
  'BA',
  'BB',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BL',
  'BM',
  'BN',
  'BO',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BW',
  'BY',
  'BZ',
  'CA',
  'CC',
  'CD',
  'CF',
  'CG',
  'CH',
  'CI',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CR',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
  'DE',
  'DJ',
  'DK',
  'DM',
  'DO',
  'DZ',
  'EC',
  'EE',
  'EG',
  'EH',
  'ER',
  'ES',
  'ET',
  'FI',
  'FJ',
  'FK',
  'FM',
  'FO',
  'FR',
  'GA',
  'GB',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GL',
  'GM',
  'GN',
  'GP',
  'GQ',
  'GR',
  'GT',
  'GU',
  'GW',
  'GY',
  'HK',
  'HN',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IM',
  'IN',
  'IO',
  'IQ',
  'IR',
  'IS',
  'IT',
  'JE',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KI',
  'KM',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
  'LB',
  'LC',
  'LI',
  'LK',
  'LR',
  'LS',
  'LT',
  'LU',
  'LV',
  'LY',
  'MA',
  'MC',
  'MD',
  'ME',
  'MF',
  'MG',
  'MH',
  'MK',
  'ML',
  'MM',
  'MN',
  'MO',
  'MP',
  'MQ',
  'MR',
  'MS',
  'MT',
  'MU',
  'MV',
  'MW',
  'MX',
  'MY',
  'MZ',
  'NA',
  'NC',
  'NE',
  'NF',
  'NG',
  'NI',
  'NL',
  'NO',
  'NP',
  'NR',
  'NU',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PF',
  'PG',
  'PH',
  'PK',
  'PL',
  'PM',
  'PR',
  'PS',
  'PT',
  'PW',
  'PY',
  'QA',
  'RE',
  'RO',
  'RS',
  'RU',
  'RW',
  'SA',
  'SB',
  'SC',
  'SD',
  'SE',
  'SG',
  'SH',
  'SI',
  'SJ',
  'SK',
  'SL',
  'SM',
  'SN',
  'SO',
  'SR',
  'SS',
  'ST',
  'SV',
  'SX',
  'SY',
  'SZ',
  'TC',
  'TD',
  'TG',
  'TH',
  'TJ',
  'TK',
  'TL',
  'TM',
  'TN',
  'TO',
  'TR',
  'TT',
  'TV',
  'TW',
  'TZ',
  'UA',
  'UG',
  'US',
  'UY',
  'UZ',
  'VA',
  'VC',
  'VE',
  'VG',
  'VI',
  'VN',
  'VU',
  'WF',
  'WS',
  'YE',
  'YT',
  'ZA',
  'ZM',
  'ZW',
] as const;
