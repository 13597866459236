export function memoizeForHalfSecond<
  TArgs extends [...any],
  TReturn extends Promise<any>,
>(builder: (...args: TArgs) => TReturn): (...args: TArgs) => TReturn {
  let memoized: TReturn | null = null;

  return (...args: TArgs): TReturn => {
    if (!memoized) {
      setTimeout(() => (memoized = null), 500);
      memoized = builder(...args);
    }

    return memoized;
  };
}
