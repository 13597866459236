import {getPixelSourceDimensions} from 'gelato/frontend/src/lib/canvas';
import ImageFrame from 'gelato/frontend/src/lib/ImageFrame';

import type {Point, Rectangle} from 'gelato/frontend/src/ML/IDDetectorAPI';

export function findPatchForBlurDetector(
  pixelSource: HTMLCanvasElement | ImageFrame,
  topLeft: Point,
  dimensions: Point,
): Rectangle {
  // Location of ID
  const [w, h] = dimensions;
  const [idX, idY] = topLeft;

  const {sourceWidth, sourceHeight} = getPixelSourceDimensions(pixelSource);

  // Take a patch in the middle of the ID of size min(w, h)
  // Make sure x,y >= 0 and x+patchSize < sourceWidth, y+patchSize< sourceheight
  const patchSize = Math.min(w, h, sourceWidth, sourceHeight);
  const x = Math.max(
    0,
    Math.min(sourceWidth - patchSize, idX + w / 2 - patchSize / 2),
  );
  const y = Math.max(
    0,
    Math.min(sourceHeight - patchSize, idY + h / 2 - patchSize / 2),
  );

  return {
    topLeft: [x, y],
    dimensions: [patchSize, patchSize],
  };
}

export default findPatchForBlurDetector;
