// @ts-nocheck

// eslint-disable-next-line @sail/data-no-imperative-methods
import {ApolloLink} from '@apollo/client';
// eslint-disable-next-line @sail/data-no-imperative-methods
import {onError} from '@apollo/client/link/error';
// eslint-disable-next-line @sail/data-no-imperative-methods
import {RetryLink} from '@apollo/client/link/retry';
import * as Sentry from '@sentry/browser';
import fetch from 'isomorphic-unfetch';

import {getSessionQuery} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';
import {getConfigValue} from 'gelato/frontend/src/lib/config';
import {
  NO_TOKEN_ERROR_MESSAGE,
  NO_TOKEN_PASTED_URL,
  NO_TOKEN_OLD_TOKEN,
  EXPIRED_TOKEN_ERROR_MESSAGE,
  SessionError,
  isSessionExpired,
  isRateLimitedError,
  isExpiredTokenError,
  isInvalidUpdateError,
  getStatusCode,
} from 'gelato/frontend/src/lib/sessionError';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {InternalOperationContext} from '@sail/data';

// tokens expire after 30 minutes
const OLD_TOKEN_AGE = 30 * 60 * 1000;

const throwOnMissingToken = () => {
  // Figure out what's going on with the missing token.
  const tokenCreated = Storage.getSessionAPIKeyCreateTime();

  if (tokenCreated && Date.now() - tokenCreated > OLD_TOKEN_AGE) {
    // If token is old, it might just be an old tab that's redrawn but has
    // lost session state.
    throw new SessionError(NO_TOKEN_OLD_TOKEN);
  } else if (
    window.history.length < 3 &&
    !window.location.href.includes('/welcome')
  ) {
    // This is a pasted URL- There's no history, and we're not at welcome page.
    throw new SessionError(NO_TOKEN_PASTED_URL);
  } else {
    if (typeof window !== 'undefined' && window.history) {
      Sentry.setTag('historyLengthInApollo', window.history.length);
    }
    // Dunno- how did this happen?
    throw new SessionError(NO_TOKEN_ERROR_MESSAGE);
  }
};

export const graphQLFetcher = async (url: string = '', _options = {}) => {
  // This has the downside of reading from sessionStorage every API call
  const token = Storage.getSessionAPIKey();
  const serverUrl = Storage.getGraphqlUrl();

  if (isSessionExpired()) {
    throw new SessionError(EXPIRED_TOKEN_ERROR_MESSAGE);
  }

  if (!token || !url) {
    throwOnMissingToken(token);
  }
  const options = {..._options};

  // Using the Official headers object ensures we don't send duplicate headers regardless of casing
  const headers = new Headers();
  if (options.headers) {
    Object.keys(options.headers).forEach((header) => {
      headers.set(header, options.headers[header]);
    });
  }
  headers.set('Accept', '*/*');
  headers.set('Authorization', `Bearer ${token}`);
  headers.set('Content-Type', 'application/json');
  headers.set(
    'X-Stripe-Identity-Client-Version',
    getConfigValue('COMMIT_HASH'),
  );
  headers.set('X-Requested-With', 'fetch');

  // TODO(IDPROD-4893)
  // Add server-side logic to detect client that requests for desktop site
  // headers.set('X-Stripe-Identity-Client-Is-Mobile-Device', '');

  return fetch(serverUrl, {
    ...options,
    method: 'post',
    headers,
    credentials: 'include',
  });
};

export function createApolloLinks() {
  const maybeRefreshSession = onError((errorData) => {
    // we careful that we do not cause an infinite loop here
    // only trigger a refetch if  we are quite sure it will succeed
    if (isInvalidUpdateError(errorData)) {
      const context: InternalOperationContext =
        errorData.operation.getContext();

      // TODO(rwashburne 2024-02-01): There should be an explict test that any 409 gets the session refreshed

      // if we get an invalid update error then we are confused about state we are in
      // refetch the Session in this case so that we can be rerouted approprately
      // eslint-disable-next-line @sail/data-no-imperative-methods
      context.client?.query({
        query: getSessionQuery,
        fetchPolicy: 'network-only',
      });
    }
  });

  const maybeClearSessionOnError = onError((errorData) => {
    const isSessionExpired = isExpiredTokenError(errorData);
    if (isSessionExpired || isRateLimitedError(errorData)) {
      Sentry.addBreadcrumb({
        category: 'Storage',
        message: `Session cleared on statusCode=${getStatusCode(errorData)}`,
        level: Sentry.Severity.Info,
      });
      Storage.clearSession();

      // Mark session as expired if we detect that it is.
      if (isSessionExpired) {
        // This is needed for the `/invalid` page to know that the session is
        // expired.
        Storage.setSessionExpiresAt(Date.now());
      }

      if (window && window.location.pathname !== '/invalid') {
        import('gelato/frontend/src/lib/localRouter').then((mod) => {
          const router = mod.maybeGetRouter();
          router && router.push('/invalid');
        });
      }
    }
  });

  const errorLogging = onError(
    ({operation, response, graphQLErrors, networkError}) => {
      const operationName = operation.operationName;

      if (networkError) {
        Sentry.addBreadcrumb({
          category: 'NetworkError',
          message: `Network Error: "${networkError}" ${operationName} Response: ${JSON.stringify(
            response,
          )}`,
          level: Sentry.Severity.Error,
        });
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({message, locations, path}) => {
          Sentry.addBreadcrumb({
            category: 'GraphQLError',
            message: `${message} for ${operationName} at ${path}`,
            level: Sentry.Severity.Error,
          });
        });
      }
    },
  );

  const SKIP_BREADCRUMB_OPERATIONS = [
    'RecordMetricsMutation', // low information content
    'GetSessionQuery', // we do this on every page load
    'OperatingModePollingQuery', // we poll with this query
  ];

  const addOperationBreadcrumb = new ApolloLink((operation, forward) => {
    const operationName = operation.operationName;
    if (!SKIP_BREADCRUMB_OPERATIONS.includes(operationName)) {
      Sentry.addBreadcrumb({
        category: 'GraphQL',
        message: 'Made GraphQL request',
        level: Sentry.Severity.Info,
        data: {operationName},
      });
    }
    // this is we send the request down the chain of links on it's way out
    return forward(operation);
  });

  // Retry 3 times 300ms apart.
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error) =>
        error &&
        // Retry if either the statuscode > 500 (server error) or there's no statusCode
        // which indicates a complete Network failure
        (error.statusCode >= 500 || error.statusCode === undefined) &&
        !!Storage.getSessionAPIKey(),
    },
  });

  return [
    addOperationBreadcrumb,
    retryLink,
    maybeRefreshSession,
    errorLogging,
    maybeClearSessionOnError,
  ];
}
