import * as React from 'react';

import Background from 'gelato/frontend/src/components/Background';
import LandscapeMessage from 'gelato/frontend/src/components/LandscapeMessage';
import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import PageCardV1 from 'gelato/frontend/src/components/PageCard';
import {OuterFrame as PageCardV2} from 'gelato/frontend/src/components/PageCardV2';
import PageLayout from 'gelato/frontend/src/components/PageLayout';
import {PageModalRoot} from 'gelato/frontend/src/components/PageModal';
import {getComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {SetPageCardPropsContext} from 'gelato/frontend/src/lib/contexts';
import {logExperimentExposure} from 'gelato/frontend/src/lib/experiments';
import {
  usePageLayoutPortrait,
  useExperiments,
} from 'gelato/frontend/src/lib/hooks';
import {LocalRouter} from 'gelato/frontend/src/lib/localRouter';

import type {Props as BackgroundProps} from 'gelato/frontend/src/components/Background';
import type {Props as PageCardProps} from 'gelato/frontend/src/components/PageCard';
import type {
  ConfigurableComponent,
  ExperimentPageRenderer,
} from 'gelato/frontend/src/lib/ComponentConfig';
import type {SetPageCardProps} from 'gelato/frontend/src/lib/contexts';
import type {PageProps} from 'gelato/frontend/src/lib/localRouter';

// This component renders the content for <PageComponent />.
export default function PageComponentContent(props: {
  // The experiment setting that configures whether the new Button 2.0 design
  // should be used or not.
  experimentPageRenderer: ExperimentPageRenderer;
  loading: boolean;
  pageCardProps: PageCardProps;
  backgroundProps?: BackgroundProps;
  pageProps: PageProps;
  params: {};
  redirecting: boolean;
  router: LocalRouter;
  setPageCardProps: SetPageCardProps;
}) {
  const {
    experimentPageRenderer,
    loading,
    pageCardProps,
    backgroundProps,
    pageProps,
    params,
    redirecting,
    router,
    setPageCardProps,
  } = props;
  const pageLayoutPortraitEnabled = usePageLayoutPortrait();

  const {experimentName} = experimentPageRenderer;
  const experiments = useExperiments();
  const exp = experiments?.find((e) => e.name === experimentName);
  const variant = exp?.variant;
  const isV1 = variant === 'control';
  const isV2 = variant === 'treatment' && pageLayoutPortraitEnabled;

  // Check whether we should use V1 or V2 page.
  let PageCard: ConfigurableComponent | null;
  let Component: ConfigurableComponent | null;

  if (isV1) {
    PageCard = PageCardV1;
    Component = experimentPageRenderer.control;
  } else if (isV2) {
    PageCard = PageCardV2;
    Component = experimentPageRenderer.treatment;
  } else if (experiments) {
    // User is neither in the control group nor the treatment group, default to
    // V1 since the experiments data has been finalized.
    PageCard = PageCardV1;
    Component = experimentPageRenderer.control;
  } else {
    // The experiments data hasn't loaded yet, so we can't tell whether the
    // user is in the control group or the treatment group. In this case,
    // we should show a loading indicator.
    PageCard = null;
    Component = null;
  }

  // If the component is new page that always uses v2 design, we should use
  // the new PageCardV2 component.
  const config = Component ? getComponentConfig(Component) : null;
  if (config?.v2) {
    PageCard = PageCardV2;
    Component = experimentPageRenderer.control;
  }

  const page =
    // If PageCard is null, it means that the experiment hasn't
    // loaded yet, so we can't tell whether the user is in the control group or
    // the treatment group. In this case, we should show a loading indicator.
    redirecting || !PageCard || !Component ? (
      <LoadingBox />
    ) : (
      <PageCard {...pageCardProps}>
        <PageModalRoot />
        <Component
          {...pageProps}
          params={params}
          loading={loading}
          router={router}
        />
      </PageCard>
    );

  // Log the exposure event if the user is assigned to the experiment
  // and the page is visble.
  React.useEffect(() => {
    if (PageCard && !redirecting && experiments && variant) {
      logExperimentExposure(experimentName, variant);
    }
  }, [PageCard, experimentName, experiments, redirecting, variant]);

  return (
    <PageLayout>
      <LandscapeMessage />
      <Background {...backgroundProps} />
      <SetPageCardPropsContext.Provider value={setPageCardProps}>
        {page}
      </SetPageCardPropsContext.Provider>
    </PageLayout>
  );
}
