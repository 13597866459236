import * as React from 'react';

import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {useBranding} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import Storage from 'gelato/frontend/src/lib/Storage';
import {ContentState} from 'sail/Content';
import {InlineLink} from 'sail/InlineLink';

import type {Session} from 'gelato/frontend/src/lib/contexts';

const ExpiredSession = (props: {session: Session}) => {
  const {session} = props;
  const refreshUrl = Storage.getRefreshUrl();
  const returnUrl = Storage.getReturnUrl();
  const url = refreshUrl || returnUrl;
  const branding = useBranding();
  const {platformName} = branding || {};

  React.useEffect(() => {
    Storage.clearSession();
    Storage.setRefreshUrl(refreshUrl);
    Storage.setReturnUrl(returnUrl);
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'authentication_error',
      code: 'session_expired',
    });
  });

  const title = <Message {...messages.expiredHeading} />;
  const description =
    session?.operatingMode === 'secondary' ? (
      <Message {...messages.expiredSecondarySessionBody} />
    ) : (
      <Message {...messages.expiredSessionBody} />
    );

  const actions = url && (
    <InlineLink id="refresh-session-link" href={url}>
      <Message {...messages.failureLink} values={{platformName}} />
    </InlineLink>
  );

  return (
    <ContentState
      title={title}
      icon="dispute"
      description={description}
      actions={actions}
    />
  );
};

export default ExpiredSession;
