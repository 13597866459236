import {Observable} from '@apollo/client';
import {noop} from '@sail/utils';

import type {ObservableSubscription} from '@apollo/client';

export function observableFromPromise<T>(
  promiseOrValue: Promise<T> | T,
): Observable<T> {
  return new Observable<T>((observer) => {
    if (promiseOrValue instanceof Promise) {
      promiseOrValue
        .then((value: T) => {
          observer.next(value);
          observer.complete();
        })
        .catch((e) => observer.error(e));
    } else {
      observer.next(promiseOrValue);
      observer.complete();
    }
  });
}

export function promiseFromObservable<T>(
  observable: Observable<T>,
): Promise<T> {
  let subscription: ObservableSubscription | null = null;

  const promise = new Promise<T>((resolve, reject) => {
    subscription = observable.subscribe({
      // returned promise is resolved with the first result of Observable
      next: resolve,
      error: reject,
    });
  });

  const output = promise.finally(() => {
    if (subscription) {
      subscription.unsubscribe();
    }
  });

  output.catch(noop);

  return output;
}

export function completeWithError(error: Error): Observable<any> {
  return new Observable((observer) => observer.error(error));
}
