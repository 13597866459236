import {createViewConfig} from '@sail/engine';
import {view, css, Icon, IconProps} from '@sail/ui';
import {backgroundColor} from '@sail/ui/dist/css/properties';
import * as React from 'react';

const Styles = {
  iconContainer: css({
    width: 'space.700',
    height: 'space.700',
    backgroundColor: 'action.primary',
    borderRadius: 'rounded',
    stack: 'x',
    alignX: 'center',
    alignY: 'center',
  }),
  icon: css({
    fill: 'onAction.primary',
  }),
};

const IconConfig = createViewConfig({
  name: 'ThemedIcon',
  props: {} as IconProps,
});

type ThemedIconProps = IconProps & {
  backgroundColor?: string;
};

const ThemedIcon = IconConfig.createView((props: ThemedIconProps) => {
  return (
    <view.div
      css={{backgroundColor: props.backgroundColor}}
      uses={[Styles.iconContainer]}
    >
      <Icon uses={[Styles.icon]} {...props} />
    </view.div>
  );
});

export default ThemedIcon;
