import {view} from '@sail/ui';
import * as React from 'react';

type AcknowledgementsProps = {
  children: React.ReactNode;
};

export default function Acknowledgements({children}: AcknowledgementsProps) {
  return (
    <view.div
      css={{
        font: 'label.small',
        textAlign: 'center',
        color: 'subdued',
      }}
    >
      {children}
    </view.div>
  );
}
