import {warning} from '@sail/icons/react/Icon';
import {view, css, Icon} from '@sail/ui';
import * as React from 'react';
import {injectIntl} from 'react-intl';

import {
  LINK_GREEN,
  LINK_HOVER_GREEN,
  LINK_PRESSED_GREEN,
} from 'gelato/frontend/src/components/Link/constants';
import DocumentSelection from 'gelato/frontend/src/components/Link/DocumentSelection/DocumentSelection';
import LinkButton from 'gelato/frontend/src/components/Link/LinkButton';
import {messages} from 'gelato/frontend/src/components/Link/messages';
import Message from 'gelato/frontend/src/components/Message';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import TopNavigationBar from 'gelato/frontend/src/components/TopNavigationBar';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

import type {PageCardProps} from 'gelato/frontend/src/components/PageCardV2';

const {useState} = React;

const Styles = {
  body: css({
    gap: 'medium',
    stack: 'y',
  }),
  errorIcon: css({
    boxSizing: 'border-box',
    fill: 'feedback.critical',
    marginTop: 'xsmall',
  }),
  errorText: css({
    alignX: 'center',
    alignY: 'top',
    color: 'feedback.critical',
    font: 'body.small',
    gap: 'small',
    stack: 'x',
  }),
  footer: css({
    alignX: 'center',
    gap: 'medium',
    padding: 'space.250',
    stack: 'y',
  }),
  footerButtonCaption: css({
    color: 'subdued',
    font: 'body.small',
    textAlign: 'center',
  }),
  heading: css({
    color: 'text',
    font: 'heading.large',
  }),
  phoneInput: css({
    margin: '0 0 0 0',
    width: 'fill',
  }),
  ctaButton: css({
    backgroundColor: LINK_GREEN,
    color: 'text',
    borderWidth: 'small',
    borderStyle: 'solid',
    borderColor: LINK_GREEN,
    width: 'fill',
    textAlign: 'center',
    ':hover:not(:active):not([aria-disabled="true"])': {
      backgroundColor: LINK_HOVER_GREEN,
      color: 'text',
      borderColor: LINK_GREEN,
    },
    ':hover:not([aria-disabled="true"])': {
      backgroundColor: LINK_HOVER_GREEN,
      color: 'text',
      borderColor: LINK_GREEN,
    },
    ':active:not([aria-disabled="true"])': {
      backgroundColor: LINK_PRESSED_GREEN,
      color: 'text',
      borderColor: LINK_GREEN,
    },
  }),
};

function ReusePage() {
  const {appState, appController} = useAppController();
  const [loading, setLoading] = useState<boolean>(false);

  const {shareError} = appState.networkedIdentity;

  const branding = appController.runtime?.branding;
  const merchantName = getBrandingPlatformName(branding);

  const handleContinue = React.useCallback(async () => {
    setLoading(true);
    await appController.reuseConsumerDocument();
    setLoading(false);
  }, [appController]);

  const selectedDocument = appState.networkedIdentity.selectedDocument;

  const body = appState.session && (
    <view.div uses={[Styles.body]}>
      <view.div uses={[Styles.heading]} id="select-an-id">
        <Message {...messages.shareTitle} values={{merchantName}} />
      </view.div>
      <DocumentSelection
        consumerDocuments={appState.networkedIdentity.consumerDocuments}
        selectedDocument={selectedDocument}
        setSelectedDocument={appController.setValidOrInvalidIdentityDocument}
        addNewDocument={appController.setValidOrInvalidIdentityDocument}
        disabled={loading}
      />
    </view.div>
  );

  const footer = appState.session && (
    <view.div uses={[Styles.footer]}>
      {shareError && (
        <view.div uses={[Styles.errorText]}>
          <Icon icon={warning} size="xsmall" uses={[Styles.errorIcon]} />
          <Message {...messages.genericError} />
        </view.div>
      )}
      <view.div uses={[Styles.footerButtonCaption]}>
        <Message {...messages.linkShareContext} values={{merchantName}} />
      </view.div>
      <LinkButton
        data-testid="link-share-cta"
        pending={loading}
        onPress={handleContinue}
      >
        {selectedDocument ? (
          <Message {...messages.securelyShareID} />
        ) : (
          <Message {...messages.reuseContinueCta} />
        )}
      </LinkButton>
    </view.div>
  );

  const props: PageCardProps = appState.session
    ? {
        header: <TopNavigationBar hideBackButton />,
        body,
        footer,
      }
    : {loading: true};

  return <PageCard {...props} />;
}

setComponentConfig(ReusePage, {componentName: 'link', v2: true});

export default injectIntl(ReusePage);
