type HeadersEntries = {[Symbol.iterator](): IterableIterator<[string, string]>};
type HeadersObject = {[name: string]: string | string[]};

type RawHeaders = {[name: string]: string[]};

function fromHeadersEntries(entries: HeadersEntries): RawHeaders {
  const data: RawHeaders = {};

  for (const [key, value] of entries) {
    const name = normalizeHeaderName(key);

    if (data[name]) {
      data[name].push(value);
    } else {
      data[name] = [value];
    }
  }

  return data;
}

function fromHeadersObject(object: HeadersObject): RawHeaders {
  const data: RawHeaders = {};

  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const name = normalizeHeaderName(key);
      const value = object[key];

      if (Array.isArray(value)) {
        data[name] = [...value];
      } else {
        data[name] = [value];
      }
    }
  }

  return data;
}

function normalizeHeaderName(name: string): string {
  return name
    .trim()
    .toLowerCase()
    .replace(/(?:^|-)./g, (c) => c.toUpperCase());
}

function keys(rawHeaders: RawHeaders): string[] {
  // Provide deterministic iteration.
  return Object.keys(rawHeaders).sort();
}

export default class Headerz {
  private rawHeaders: RawHeaders;

  constructor(init: Headers | Headerz | HeadersEntries | HeadersObject = {}) {
    if (init instanceof Headers || Array.isArray(init)) {
      this.rawHeaders = fromHeadersEntries(init);
    } else if (init instanceof Headerz) {
      this.rawHeaders = init.getRawHeaders();
    } else {
      this.rawHeaders = fromHeadersObject(init);
    }
  }

  getRawHeaders(): RawHeaders {
    const rawHeaders = this.rawHeaders;
    const copy: RawHeaders = {};

    // This method returns a copy of the internal structure to avoid
    // modifications affecting the internal object.
    keys(rawHeaders).forEach((key) => (copy[key] = rawHeaders[key].concat()));

    return copy;
  }

  getFetchHeaders(): {[key: string]: string} {
    const rawHeaders = this.rawHeaders;
    const copy: {[key: string]: string} = {};

    keys(rawHeaders).forEach((key) => (copy[key] = rawHeaders[key].join(', ')));

    return copy;
  }

  getAll(key: string): string[] {
    const name = normalizeHeaderName(key);
    const all = this.rawHeaders[name];

    // This method returns a copy of the internal structure to avoid
    // modifications affecting the internal object.
    return all ? all.concat() : [];
  }

  get(key: string): string | null {
    const name = normalizeHeaderName(key);

    return this.rawHeaders[name]?.[0] ?? null;
  }

  set(key: string, value: string | string[]): this {
    const name = normalizeHeaderName(key);

    if (Array.isArray(value)) {
      this.rawHeaders[name] = value;
    } else {
      this.rawHeaders[name] = [value];
    }

    return this;
  }

  append(key: string, value: string | string[]): this {
    const name = normalizeHeaderName(key);

    this.rawHeaders[name] = (this.rawHeaders[name] || []).concat(value);

    return this;
  }

  delete(key: string): this {
    const name = normalizeHeaderName(key);

    delete this.rawHeaders[name];

    return this;
  }

  forEach(
    callback: (key: string, values: string[], headers: this) => void,
  ): this {
    const rawHeaders = this.rawHeaders;

    keys(rawHeaders).forEach((key) => {
      if (rawHeaders[key] !== undefined) {
        callback(key, rawHeaders[key], this);
      }
    });

    return this;
  }

  forEachValue(
    callback: (key: string, value: string, headers: this) => void,
  ): this {
    const rawHeaders = this.rawHeaders;

    keys(rawHeaders).forEach((key) => {
      if (rawHeaders[key] !== undefined) {
        rawHeaders[key].forEach((value) => callback(key, value, this));
      }
    });

    return this;
  }
}
