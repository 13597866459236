import * as React from 'react';

import Box from 'sail/Box';

import type {TextProps} from 'sail/Text/types';
import type {Color} from 'sail/types';

type Props = {
  color: Color;
  reference: TextProps;
  width: number;
};

const PlaceholderText = (props: Props): React.ReactElement => {
  const {width, color, reference} = props;
  const height = reference.size - 4;

  const borderRadius = Math.ceil(height / 2);
  return (
    <Box flex={{alignItems: 'center'}} style={{height: reference.lineHeight}}>
      <Box style={{height, width, borderRadius}} background={color} />
    </Box>
  );
};

export default PlaceholderText;
