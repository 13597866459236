import * as React from 'react';

import NetworkErrorNotice from 'gelato/frontend/src/components/NetworkErrorNotice';

export default function ErrorContainer({error}: {error: any}) {
  if (!error) {
    return <></>;
  }
  return <NetworkErrorNotice error={error} />;
}
