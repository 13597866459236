import * as Sentry from '@sentry/browser';

import {START_URL_PATTERN} from 'gelato/frontend/src/lib/localRouter';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {LocationType} from 'gelato/frontend/src/services/types';
/**
 * A link code appears in the start url like this: https://verify.stripe.com/start/$code
 */

export default function storeLinkCodeFromWindowLocation(
  location: LocationType,
): string | null | undefined {
  const match = START_URL_PATTERN.match(location.pathname);
  const linkCode = (match && match.code) || Storage.getLinkCode();

  Storage.setLinkCode(linkCode);
  Sentry.setTag('linkCode', linkCode);
  return linkCode;
}
