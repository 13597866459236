import {view, ButtonGroup} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import BottomSheet from 'gelato/frontend/src/components/BottomSheetV2';
import Button from 'gelato/frontend/src/components/ButtonV2';
import Heading from 'gelato/frontend/src/components/HeadingV2';
import Message from 'gelato/frontend/src/components/Message';
import {mB100, tCenter} from 'gelato/frontend/src/components/stylesV2';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const {useCallback} = React;

const Messages = defineMessages({
  confirmMessage: {
    defaultMessage: `Photos of your ID will be deleted.`,
    description:
      'The message that asks the user to confirm restart the upload process',
    id: 'verification.documentUploadResetConfirmSheet.confirmMessage',
  },
  confirmNavigation: {
    defaultMessage: 'Start over?',
    description:
      'The header that asks the user to confirm restart the upload process',
    id: 'verification.documentUploadResetConfirmSheet.confirmNavigation',
  },
  leaveButton: {
    defaultMessage: `Start over and delete photos`,
    description: 'Confirm upload restart button',
    id: 'verification.documentUploadResetConfirmSheet.leaveButton',
  },
  stayButton: {
    defaultMessage: `Stay`,
    description: 'The button that cancels navigation',
    id: 'verification.documentUploadResetConfirmSheet.stayButton',
  },
});

/**
 * The flyout sheet asking the user to confirm navigation away from the
 * document upload step.
 */
export default function DocumentUploadResetConfirmSheet(): JSX.Element {
  const {appController} = useAppController();

  const handleStay = useCallback(() => {
    appController.closeLayer(DocumentUploadResetConfirmSheet);
  }, [appController]);

  const handleLeave = useCallback(async () => {
    await appController.closeLayer(DocumentUploadResetConfirmSheet);
    appController.resetDocumentUploadStep();
  }, [appController]);

  const footer = (
    <ButtonGroup direction="column">
      <Button onPress={handleStay} type="primary">
        <Message {...Messages.stayButton} />
      </Button>
      <Button onPress={handleLeave} type="secondary">
        <Message {...Messages.leaveButton} />
      </Button>
    </ButtonGroup>
  );

  return (
    <BottomSheet
      data-testid="document-upload-reset-confirm-sheet"
      footer={footer}
    >
      <Heading level={2} uses={[tCenter]}>
        <Message {...Messages.confirmNavigation} />
      </Heading>
      <view.div uses={[mB100, tCenter]}>
        <Message {...Messages.confirmMessage} />
      </view.div>
    </BottomSheet>
  );
}
