// @ts-nocheck

// Copied from `dashboard/lib/intl_format`

import _ from 'lodash';
import * as React from 'react';

import MarkdownTextWithConfig, {
  fullRuleSet,
} from 'gelato/frontend/src/components/MarkdownTextWithConfig';

const hasPrecedingSpaceRE = /^\s+/;
const hasTrailingSpaceRE = /\S\s+$/;

/**
 * A collection of functions to help with react-intl string formatting
 */

export function linesToParagraphs(...nodes) {
  return (
    <span>
      {nodes.map((node) => {
        if (typeof node === 'string') {
          return node.split('\n').map((text) => <p>{text}</p>);
        } else {
          return node;
        }
      })}
    </span>
  );
}

const generateNodeKeyFromNodes = (nodes: any) => {
  return nodes
    .map((node, i) => {
      if (typeof node === 'string') {
        return `${i}--${node}`;
      } else if (node && node.type) {
        return `${i}--${node.type.name}`;
      } else {
        return `${i}`;
      }
    })
    .join('-');
};

const wrapperTags = {
  '`': 'code',
  _: 'em',
  '**': 'strong',
};

const wrapNode = (mdWrapper: any, node: any) => {
  const wrapper = wrapperTags[mdWrapper];
  if (wrapper === 'em' || wrapper === 'strong' || wrapper === 'code') {
    return React.createElement(wrapper, [], node);
  } else {
    return node;
  }
};

const componentWrapperRegex = /\[([^[\]]+)\]\(<([a-zA-Z0-9]+)>\)/;
const componentWrapperSplitRegex = /\[[^[\]]+\]\(<[a-zA-Z0-9]+>\)([\s\S]*)/;

const maybeHasWrapperSuffixRegex = /(`|_|\*\*)$/;
const wrapperPrefixRegex = (suffix: any) => {
  switch (suffix) {
    case '`':
      return /^`/;

    case '_':
      return /^_/;

    case '**':
      return /^\*\*/;

    default:
      return null;
  }
};

/**
 * Takes a string with injected components and turns them into nodes.
 * e.g.
 * "You agree to our [Services Agreement](<SSALink>) and [Connected Account Agreement](<CAALink>)."
 * turns into
 * ["You agree to our ", <SSALink text="Services Agreement" />, " and ", <CAALink text="Connected Account Agreement" />, "."]
 */
const parseInjectedComponents = (nodes: any, values: any) =>
  nodes.reduce<Array<any>>((output, originalNode, i) => {
    const parse = (node: any) => {
      // Don't try to do anything on non-string node types (probably already components)
      if (typeof node === 'string') {
        if (!node) {
          return [];
        }

        const localOutput: any = [];
        let finalNode = node;

        // Find the first match to our component wrapper regex (see if we're using the injected component feature)
        const matches = node.match(componentWrapperRegex);
        const hasInjectedComponent = matches && matches.length >= 3;
        // If we are doing the injected component thing, let's modify the nodes, otherwise, just carry on with the next node
        if (hasInjectedComponent) {
          // split apart the string into sections that don't include the component syntax:
          // e.g.: "This is some [wrapped text](<SpanComponent>) here." -> ["This is some ", " here."]
          // the `matches` array has the opposite info in it: [..., "wrapped text", "SpanComponent", ...]
          const parts = node.split(componentWrapperSplitRegex);

          const componentName = matches[2];
          const innerText = matches[1];
          const injectedComponentRenderer = values[componentName];
          // Make sure we have a value in our values object that sends the expected format (minimally a component)
          if (!injectedComponentRenderer) {
            throw new Error(
              `Tried to use inline component renderer '${componentName}' but could not find it in the values object.`,
            );
          }
          // Make sure component renderer is a function
          if (typeof injectedComponentRenderer !== 'function') {
            throw new TypeError(
              `Expected inline component renderer '${componentName}' to be a function but got ${typeof injectedComponentRenderer}.`,
            );
          }
          // Push on any text before the injected component
          localOutput.push(parts[0]);
          // Push on our generated component
          localOutput.push(injectedComponentRenderer(innerText));
          // If it exists, push on trailing text from the component
          finalNode = parts[1] && parts[1]?.length ? parts[1] : null;
        }

        if (finalNode) {
          const suffixMatch = finalNode.match(maybeHasWrapperSuffixRegex);

          if (suffixMatch) {
            const matchedSuffix = suffixMatch[1];
            const maybeWrappedNode = nodes[i + 1];
            const maybeClosingTextNode = nodes[i + 2];

            if (
              typeof maybeWrappedNode === 'object' &&
              typeof maybeClosingTextNode === 'string'
            ) {
              const checkPrefixRegex = wrapperPrefixRegex(matchedSuffix);
              const prefixMatch = maybeClosingTextNode.match(checkPrefixRegex);

              if (prefixMatch) {
                finalNode = finalNode.replace(maybeHasWrapperSuffixRegex, '');
                nodes[i + 1] = wrapNode(matchedSuffix, maybeWrappedNode);
                nodes[i + 2] = maybeClosingTextNode.replace(
                  checkPrefixRegex,
                  '',
                );
              }
            }
          }

          if (hasInjectedComponent) {
            // This string had an injected component.
            // The remaining string may have more, so we should parse it too!
            localOutput.push(...parse(finalNode));
          } else {
            localOutput.push(finalNode);
          }
        }

        return localOutput;
      } else {
        return [node];
      }
    };

    output.push(...parse(originalNode));
    return output;
  }, []);

export function markdownifyWithLinkTarget(config = {}, values = {}) {
  return (nodes: any) => {
    const resultingNodes = parseInjectedComponents(nodes, values);

    // Use our new generated `resultingNodes` array for the output instead of the `nodes` array
    // that we originally received.
    return (
      <span key={generateNodeKeyFromNodes(resultingNodes)}>
        {resultingNodes.map((node, i) => {
          if (typeof node === 'string') {
            const hasPrecedingSpace = hasPrecedingSpaceRE.test(node);
            const hasTrailingSpace = hasTrailingSpaceRE.test(node);
            const MarkdownText = MarkdownTextWithConfig({
              linkify: true,
              rules: fullRuleSet,
              inline: node.indexOf('\n') === -1,
              ...config,
            });
            const markdownNode = <MarkdownText text={node} key={i} />;

            // The markdown engine trims preceding and trailing spaces,
            // which can be a problem when placing two spans next to eachother.
            // this forces a space back in if it existed. It's a bit of a hack
            // but runs relatively infrequently.
            if (hasPrecedingSpace || hasTrailingSpace) {
              return (
                <span key={i}>
                  {hasPrecedingSpace ? ' ' : null}
                  {markdownNode}
                  {hasTrailingSpace ? ' ' : null}
                </span>
              );
            } else {
              return markdownNode;
            }
          } else {
            return React.cloneElement(node, {key: i});
          }
        })}
      </span>
    );
  };
}

export const markdownify =
  (config: any, values = {}) =>
  (...nodes) => {
    return markdownifyWithLinkTarget(config, values).apply(this, nodes);
  };

export function breakLines(...nodes) {
  return (
    <span>
      {nodes.map((node) => {
        if (typeof node === 'string') {
          return _.reduce(
            node.split('\n'),
            (arr, str) => {
              if (!arr.length) {
                return [str];
              } else {
                return [...arr, <br />, str];
              }
            },
            [],
          );
        } else {
          return node;
        }
      })}
    </span>
  );
}
