import * as React from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {$as, $extends, $render} from './constants';
import {continuationIntent} from './continuation';
import {getIntents} from '../../intent';
import {createElement, getViewConfig, createPropsFacade} from './props';
import {runProviders} from './provider';
import {runRenderIntents} from './render';
import {createSlots} from './slots';
import type {IntentMap, Intent} from '../../intent';
import type {ComponentType, ComputedCacheRecord} from './props';
import type {AnyCssSerializer} from '../css';
import type {
  AbstractComponent,
  AbstractRender,
  Component,
  CreateSlots,
  CreateView,
  DeprecatedCreateView,
  InferredCreateView,
  InternalProps,
  IntrinsicViews,
} from './types';
import {applyDefaults} from './setDefaults';
import {runPropTransforms} from './transformProps';

function ViewImpl(facade: unknown): React.ReactNode {
  const props = facade as InternalProps;

  // Call render intents
  runRenderIntents(props);

  // Create the React element
  const element = createElement(props);

  // Wrap the React element with context providers (using intents)
  return runProviders(props, element);
}

function createViewComponent<
  Props = unknown,
  Slots = undefined,
  Instance extends ComponentType | undefined = undefined,
  Styles = undefined,
>(
  render: AbstractRender,
  tag?: Instance,
  extend?: AbstractComponent | undefined,
  css?: AnyCssSerializer | undefined,
  intents?: Intent[],
): Component<Props, Instance, Slots, Styles> {
  function Render(props: IntentMap): JSX.Element | null {
    const continuationIntents = getIntents(
      continuationIntent,
      getViewConfig(props),
    );
    const continuation = continuationIntents?.pop();
    if (continuation) {
      return continuation(React.createElement(Render, props));
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return render(props, extend || BaseView) as JSX.Element | null;
  }

  const component = React.forwardRef(
    (props: Props, forwardedRef): JSX.Element | null => {
      // This cache allows us to maintain stable identities of computed values
      // across renders
      const cache = React.useRef<ComputedCacheRecord>({});

      const facade = createPropsFacade(
        props as InternalProps<any>,
        cache.current,
        tag,
        css,
        forwardedRef,
        intents,
      );

      runPropTransforms(facade);

      applyDefaults(facade);

      return Render(facade);
    },
  ) as unknown as Component<Props, Instance, Slots, Styles>;

  component[$as] = tag;
  component[$extends] = extend;
  component[$render] = render;

  component.as = function as(
    newTag: ComponentType | undefined,
  ): AbstractComponent {
    return createViewComponent(
      render,
      newTag,
      component as any,
      css,
      intents,
    ) as any;
  } as any;

  component.styles = function styles(css: AnyCssSerializer): AbstractComponent {
    return createViewComponent(
      render,
      tag,
      component as any,
      css,
      intents,
    ) as any;
  } as any;

  component.create = function create(
    newRender: AbstractRender,
    intents: Intent[],
  ): AbstractComponent {
    return createViewComponent(
      newRender,
      tag,
      component as any,
      css,
      intents,
    ) as any;
  } as unknown as CreateView<Props, Instance, Slots, Styles>;

  component.extend = function extendView(
    renderExtendingView: any,
    additionalIntents: Intent[] = [],
  ): AbstractComponent {
    const newComponent = createViewComponent(
      (props) => {
        // Call the extending render method with the current props and the
        // extended view.
        const element = renderExtendingView(props, component);
        if (process.env.NODE_ENV !== 'production') {
          if (element.type !== component) {
            throw new Error(
              'View.extend must always return <View> as the outermost element',
            );
          }
        }

        // Create a new cache object for the extended view and attach it to the
        // existing view config to ensure they both remain referentially stable.
        const config = getViewConfig(props);
        if (!config.cache.extendedViewCache) {
          config.cache.extendedViewCache = {};
        }
        const cache = config.cache.extendedViewCache as ComputedCacheRecord;

        // Create a new props facade for the extended view using the returned
        // element’s props and ref, as well as the new cache.
        const facade = createPropsFacade(
          element.props,
          cache,
          tag,
          css,
          element.ref,
        );

        runPropTransforms(facade);

        applyDefaults(facade);

        // Immediately render the extended view. This collapses the extending
        // view and extended view into a single React component.
        return Render(facade);
      },
      tag,
      extend as any,
      css,
      intents ? intents.concat(additionalIntents) : additionalIntents,
    ) as any;
    if (renderExtendingView.name) {
      newComponent.displayName = renderExtendingView.name;
    }
    return newComponent;
  } as any;

  component.displayName = 'view.unknown';
  if (render.name && render !== ViewImpl) {
    component.displayName = render.name;
  } else if (typeof tag === 'string') {
    component.displayName = `view.${tag}`;
  } else if (typeof tag === 'object' || typeof tag === 'function') {
    component.displayName = `view.${
      (tag as unknown as {displayName?: string}).displayName ||
      tag.name ||
      'unknown'
    }`;
  }
  Render.displayName = `→ ${component.displayName}`;

  return component;
}

/** @deprecated */
export const createView =
  createViewComponent as unknown as DeprecatedCreateView;

const BaseView = createViewComponent(ViewImpl);
BaseView.displayName = 'View';

/** @deprecated */
export const View = BaseView;

/**
 * Creates a `view` API bound to the provided `css` serializer. The computed
 * properties type must be supplied manually, like so:
 *
 *   createIntrinsicViews<typeof css.ComputedProperties>(css);
 */
export function createIntrinsicViews<T = undefined>(
  // We don’t infer ComputedProperties from this serializer because it causes a
  // substantial TypeScript performance hit whenever the method is invoked.
  css?: AnyCssSerializer,
): IntrinsicViews<T> {
  return new Proxy(
    {
      create: createViewComponent as unknown as InferredCreateView,
      slots: createSlots as unknown as CreateSlots,
    } as IntrinsicViews<T>,
    {
      get(target: IntrinsicViews<T>, tag: 'div'): IntrinsicViews<T>['div'] {
        const cached = target[tag];
        if (cached) {
          return cached;
        }
        const component = createViewComponent(
          ViewImpl,
          tag,
          undefined,
          css,
        ) as unknown as IntrinsicViews<T>['div'];
        target[tag] = component;
        return component;
      },
    },
  );
}

export const view = createIntrinsicViews();
