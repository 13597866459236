import {useLazyQuery as useApolloLazyQuery} from '@apollo/client';
import {DO_NOT_USE_platformMetrics} from '@sail/observability';
import {useMemoizedValue} from '@sail/react';
import {useCallback, useMemo} from 'react';
import useGetApolloContext from 'src/internal/apollo/useGetApolloContext';
import {
  DEFAULT_IGNORE_TRACKING_FIELDS,
  DEFAULT_TRACK_ACCESSES_SAMPLE_RATE,
  useTrackGraphQlFieldAccesses,
} from 'src/internal/deadwood/deadwood';

import type {
  GraphQlDocument,
  LazyQueryPromiseReturn,
  PermissionToken,
  UnverifiedToken,
  UseLazyQueryOptions,
  UseLazyQueryReturn,
  VariablesOfDoc,
} from 'src/internal/apollo/types';

/**
 * React hook to execute GraphQL queries in a lazy way using `@sail/data`.
 *
 * This hook is equivalent to the
 * [`useLazyQuery()` Apollo hook](https://www.apollographql.com/docs/react/data/queries/#manual-execution-with-uselazyquery)
 * but it has a few differences, all of them described
 * [here](/apis/data/graphql-usage/the-basics-runtime#option-differences).
 *
 * For more detailed documentation about how to use this method, please head to the
 * ["Using GraphQL / Defining operations"](/apis/data/graphql-usage/defining-operations) page.
 *
 * @example Basic {{include './examples/useLazyQuery.basic.tsx'}}
 *
 * @see https://sail.stripe.me/apis/data/useLazyQuery
 */
export default function useLazyQuery<
  /** The data returned by the GraphQL query (inferred from the passed GraphQL document). */
  TData,
  /** The variables that the GraphQL query accept (inferred from the passed GraphQL document). */
  TVariables,
  /** The permissions needed by the GraphQL query (inferred from the passed GraphQL document). */
  TPermissions,
  /** (internal) The token passed to the lazy query via the hook (inferred from the passed options). */
  THookToken extends TPermissions | undefined = undefined,
  /** (internal) The variables passed to the lazy query via the hook (inferred from the passed options). */
  THookVariables extends Partial<TVariables> | undefined = undefined,
  BaseTokenType extends PermissionToken = PermissionToken,
  UnverifiedTokenType extends UnverifiedToken = UnverifiedToken,
>(
  /**
   * The GraphQL query that is going to be executed. This must be created with the
   * [`gql`](/apis/data/gql) or
   * [`graphql`](/apis/data/graphql) template literal.
   */
  query: GraphQlDocument<TData, TVariables, TPermissions>,
  /**
   * The default options to pass to the query. This argument is optional.
   */
  options: UseLazyQueryOptions<
    GraphQlDocument<TData, TVariables, TPermissions>,
    THookToken,
    THookVariables
  > = {},
): /**
 * Array with two elements:
 *
 * **callback**: Function to execute the actual query. Accepts an options object that will override any options passed to the hook (including the variables and the permissions token).
 *
 * **result**: Object with `data`, `loading` and `error` (similar to the one that the
 * [`useQuery` Apollo hook](https://www.apollographql.com/docs/react/api/react/hooks/#result) returns).
 */
UseLazyQueryReturn<
  GraphQlDocument<TData, TVariables, TPermissions>,
  THookToken,
  THookVariables,
  BaseTokenType,
  UnverifiedTokenType
> {
  DO_NOT_USE_platformMetrics.useTrackMount('sail_data_useLazyQuery');

  const baseContext = useGetApolloContext(options);

  const queryOptions = useMemo(
    () => ({
      ...options,
      context: baseContext,
    }),
    [baseContext, options],
  );

  const {hookVariables, hookOptionsWithoutVariables} = useMemo(() => {
    const {variables, ...rest} = queryOptions;

    return {
      hookVariables: variables,
      hookOptionsWithoutVariables: rest,
    };
  }, [queryOptions]);

  const memoizedHookVariables = useMemoizedValue(hookVariables);

  const [callback, result] = useApolloLazyQuery(
    query,
    hookOptionsWithoutVariables,
  );

  const callbackWrapped = useCallback(
    async (
      ...restArgs: Parameters<
        UseLazyQueryReturn<
          GraphQlDocument<TData, TVariables, TPermissions>,
          THookToken,
          THookVariables
        >[0]
      >
    ): Promise<
      LazyQueryPromiseReturn<GraphQlDocument<TData, TVariables, TPermissions>>
    > => {
      const cbOptions = restArgs[0];

      const mergedVariables = {
        ...memoizedHookVariables,
        ...cbOptions?.variables,
      } as VariablesOfDoc<GraphQlDocument<TData, TVariables, TPermissions>>;

      const mergedOptions = {
        ...cbOptions,
        variables: mergedVariables,
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {refetch, ...result} = await callback(mergedOptions);

      return result;
    },
    [callback, memoizedHookVariables],
  );

  return [
    callbackWrapped,

    useTrackGraphQlFieldAccesses(result, {
      query,
      sampleRate: options.trackAccesses ?? DEFAULT_TRACK_ACCESSES_SAMPLE_RATE,
      ignoreFields: DEFAULT_IGNORE_TRACKING_FIELDS,
    }),
  ];
}
