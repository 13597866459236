import {handleException} from 'gelato/frontend/src/lib/sentry';
import {isInvalidUpdateError} from 'gelato/frontend/src/lib/sessionError';

import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';

/**
 * Perform the mutation to reclaim the primary session.
 * @param state
 * @param runtime
 */
export default async function resetHandoff(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
): Promise<void> {
  if (state.session?.operatingMode === 'waiting') {
    await runtime.api.mutation.reclaimPrimaryMutation().catch((error) => {
      // Invalid update errors are more common on reclaiming primary because
      // the session may have been completed on the handoff session.
      if (!isInvalidUpdateError(error)) {
        handleException(error, 'Failed to reclaim primary session');
      }
    });
  }
}
