const size = 'size' as const;

export const aliasSize = {
  width: size,
  height: size,
  minWidth: size,
  minHeight: size,
  maxWidth: size,
  maxHeight: size,
  minTileWidth: size,
} as const;
