import {graphql} from '@sail/data';
import {useGelatoLazyQuery} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';
import {sessionStaticFragment} from 'gelato/frontend/src/graphql/fragments/sessionStaticFragment';

import type {GraphQlDocument, GraphQlFragmentUnion} from '@sail/data';
import type {
  Exact,
  Flags,
  ExperimentName,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoLazyQueryReturn,
  GelatoLazyQueryOptions,
  GelatoLazyQueryOptionsWithoutVariables,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';
import type {SessionStaticFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionStaticFragment';

const doc = graphql`
  query GetSessionQuery {
    session {
      id
      ...SessionDynamicFragment
      ...SessionStaticFragment
    }
    person {
      id
    }
    flags
    experiments {
      name
      variant
    }
  }

  ${sessionDynamicFragment}
  ${sessionStaticFragment}
`;

export const getSessionLazyQuery: GetSessionLazyQueryDocument = doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const getSessionLazyQueryOptions: GelatoLazyQueryOptionsWithoutVariables<GetSessionLazyQueryDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...
  };

export default function useGetSessionLazyQuery(
  options: GelatoLazyQueryOptions<GetSessionLazyQueryDocument> = {},
): GelatoLazyQueryReturn<GetSessionLazyQueryDocument> {
  return useGelatoLazyQuery(getSessionLazyQuery, {
    ...getSessionLazyQueryOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type GetSessionLazyQueryVariables = Exact<{[key: string]: never}>;

export type GetSessionLazyQueryData = {
  readonly __typename: 'Query';
  readonly flags: ReadonlyArray<Flags>;
  readonly session: GraphQlFragmentUnion<
    {readonly __typename: 'Session'; readonly id: string},
    [SessionDynamicFragmentData, SessionStaticFragmentData]
  >;
  readonly person: {readonly __typename: 'Person'; readonly id: string} | null;
  readonly experiments: ReadonlyArray<{
    readonly __typename: 'Experiments';
    readonly name: ExperimentName;
    readonly variant: string;
  }>;
};

const getSessionLazyQueryPermissions = {
  ...sessionDynamicFragment.permissions,
  ...sessionStaticFragment.permissions,
} as const;

doc.permissions = getSessionLazyQueryPermissions;

export type GetSessionLazyQueryPermissions = GelatoPermissionToken<
  typeof getSessionLazyQueryPermissions
>;

export type GetSessionLazyQueryDocument = GraphQlDocument<
  GetSessionLazyQueryData,
  GetSessionLazyQueryVariables,
  GetSessionLazyQueryPermissions
>;
