import moment from 'moment';

export function getMomentDate(
  day: string,
  month: string,
  year: string,
): moment.Moment {
  const paddedDay = `0${day}`.slice(-2);
  const paddedMonth = `0${month}`.slice(-2);
  return moment.utc(
    `${year}-${paddedMonth}-${paddedDay}`,
    'YYYY-MM-DD',
    true, // strict mode
  );
}
