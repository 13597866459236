import {DocumentTypes} from '@stripe-internal/data-gelato/schema/types';
import moment from 'moment';

import type {
  ConsumerIdentityDocumentApi,
  ConsumerIdentityDocument,
  ExpirationDateDetails,
  InvalidUseReasons,
} from 'gelato/frontend/src/api/Consumer/types';
import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

export const transformConsumerIdentityDocuments = (
  docs: ConsumerIdentityDocumentApi[],
  state: Readonly<ApplicationState>,
): ConsumerIdentityDocument<ConsumerIdentityDocumentApi>[] => {
  const docsWithExpirationDetails = addExpirationDateDetails(docs);
  const docsWithExpirationDetailsAndInvalidUseReasons = addInvalidUseReasons(
    docsWithExpirationDetails,
    state.session?.documentTypeAllowlist || [],
    Boolean(state.session?.rLCapture),
  );

  return sortDocsByValidDocsAndExpirationDate(
    docsWithExpirationDetailsAndInvalidUseReasons,
  );
};

export function addExpirationDateDetails(
  consumerIdentityDocuments: ConsumerIdentityDocumentApi[],
): (ConsumerIdentityDocumentApi & {
  expirationDateDetails: ExpirationDateDetails;
})[] {
  return consumerIdentityDocuments.map((doc) => {
    return {
      ...doc,
      expirationDateDetails: calculateExpirationDateDetails(
        doc.expiration_date,
      ),
    };
  });
}

function calculateExpirationDateDetails(
  expirationDate: number | undefined | null,
): ExpirationDateDetails {
  if (typeof expirationDate === 'number') {
    const dateInMilliseconds = expirationDate * 1000;

    return {
      hasExpirationDate: true,
      expirationDateInMilliseconds: dateInMilliseconds,
      isExpired: isExpiringSoon(dateInMilliseconds),
    };
  }

  return {
    hasExpirationDate: false,
  };
}

/**
 * Determines whether or not an expiration date will expire within 1 day in a users time zone. We translate
 * the expiration date into the current time as this is what will make the most sense to the user.
 * @param dateInMilliseconds Date in milliseconds
 * @returns {boolean} representing whether or not the expiration date will occur within the next day
 * @example
 * // returns true
 * const currentDate = '2024-12-31T16:00:00
 * const expirationDate = new Date('2025-01-01T00:00:00Z').valueOf() // date in UTC
 * isExpiringSoon(expirationDate)
 */

export function isExpiringSoon(dateInMilliseconds: number) {
  const oneDayFromNow = moment().add(1, 'day');
  const expirationDateInLocalTime = new Date(dateInMilliseconds);

  return expirationDateInLocalTime.valueOf() <= oneDayFromNow.valueOf();
}

export function addInvalidUseReasons(
  documents: (ConsumerIdentityDocumentApi & {
    expirationDateDetails: ExpirationDateDetails;
  })[],
  documentTypeAllowlist: readonly DocumentTypes[],
  rLCapture: boolean,
): ConsumerIdentityDocument<ConsumerIdentityDocumentApi>[] {
  return documents.map((doc) => {
    const isExpired = Boolean(
      doc.expirationDateDetails.hasExpirationDate &&
        doc.expirationDateDetails.isExpired,
    );
    const isAllowedDoc = calculateIsDocumentTypeAllowed(
      doc,
      documentTypeAllowlist,
    );
    const isCorrectCaptureMethod = calculateIsCorrectCaptureMethod(
      doc,
      rLCapture,
    );

    return {
      ...doc,
      invalidUseReasons: calculateInvalidUseReasons(
        isExpired,
        isAllowedDoc,
        isCorrectCaptureMethod,
      ),
    };
  });
}

/**
 * Creates an object of invalid uses reasons
 * @param isExpired If the document is expired
 * @param isAllowedDoc If the document is allowed based on the session allow list criteria
 * @param isCorrectCaptureMethod  If the document is correct based on the sessoin require live capture criteria
 * @returns {InvalidUseReasons}
 */

function calculateInvalidUseReasons(
  isExpired: boolean,
  isAllowedDoc: boolean,
  isCorrectCaptureMethod: boolean,
): InvalidUseReasons {
  const isNotAllowedDocType = !isAllowedDoc;
  const isNotLiveCaptured = !isCorrectCaptureMethod;
  return {
    isInvalid: Boolean(isNotAllowedDocType || isExpired || isNotLiveCaptured),
    isExpired: Boolean(isExpired),
    isNotAllowedDocType,
    isNotLiveCaptured,
  };
}

function calculateIsCorrectCaptureMethod(
  doc: ConsumerIdentityDocumentApi,
  rLCapture: boolean,
) {
  // If live capture is required, make sure the document is live captured.
  // Otherwise we don't care how it was captured and just return true.
  if (rLCapture === true) {
    return doc.live_captured === true;
  }
  return true;
}

function calculateIsDocumentTypeAllowed(
  doc: ConsumerIdentityDocumentApi,
  documentTypeAllowlist: readonly DocumentTypes[],
) {
  if (documentTypeAllowlist && documentTypeAllowlist.length) {
    return documentTypeAllowlist.some((type) => type === doc.document_type);
  }
  return true;
}

/**
 * Separates docs into valid and invalid docs. Sorts each by expiration date. Concats invalid docs onto the end of valid docs so that invalid docs appear last.
 * @param docs Consumer identity documents
 * @returns Consumer identity documents sorted into valid and invalid docs by expiration date.
 */
export function sortDocsByValidDocsAndExpirationDate(
  docs: ConsumerIdentityDocument<ConsumerIdentityDocumentApi>[],
) {
  const docSeparator: {
    validDocs: ConsumerIdentityDocument<ConsumerIdentityDocumentApi>[];
    invalidDocs: ConsumerIdentityDocument<ConsumerIdentityDocumentApi>[];
  } = {
    validDocs: [],
    invalidDocs: [],
  };
  const {validDocs, invalidDocs} = docs.reduce((acc, doc) => {
    const {validDocs, invalidDocs} = acc;

    if (doc.invalidUseReasons.isInvalid) {
      invalidDocs.push(doc);
    } else {
      validDocs.push(doc);
    }

    return {
      validDocs,
      invalidDocs,
    };
  }, docSeparator);

  const sortedValidDocs = sortDocsByExpirationDate(validDocs);
  const sortedInvalidDocs = sortDocsByExpirationDate(invalidDocs);

  return sortedValidDocs.concat(sortedInvalidDocs);
}

function sortDocsByExpirationDate(
  docs: ConsumerIdentityDocument<ConsumerIdentityDocumentApi>[],
) {
  return docs.sort((a, b) => {
    if (
      a.expirationDateDetails.hasExpirationDate &&
      b.expirationDateDetails.hasExpirationDate
    ) {
      return (
        b.expirationDateDetails.expirationDateInMilliseconds -
        a.expirationDateDetails.expirationDateInMilliseconds
      );
    } else if (a.expirationDateDetails.hasExpirationDate) {
      return 1;
    } else if (b.expirationDateDetails.hasExpirationDate) {
      return -1;
    } else {
      return 0;
    }
  });
}
