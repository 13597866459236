import {addRulesetAfter, cloneDeclaration} from './utils';
import type {PropertyPlugin} from './utils';

let counter = 0;
function createUniquePropertyName(property: string) {
  const id = (counter++).toString(36);
  return `${property}-${id}`;
}

// Creates an inherited property that can resolve cyclic dependencies.
// For background and research on this approach, see:
// https://observablehq.com/@koop/cyclic-dependencies-in-css
export function createInheritedProperty(property: string): PropertyPlugin {
  const inheritedProperty = `${property}-inherited`;
  return function inheritedPropertyPlugin(element) {
    const children = element.children as string;
    const ruleset = element.root;
    if (!ruleset) {
      return;
    }
    const uniquePropertyName = createUniquePropertyName(property);
    const uniqueVar = `var(${uniquePropertyName})`;

    addRulesetAfter(
      element,
      'inherited',
      ['& :not(&)', '&::before', '&::after'],
      [cloneDeclaration(element, inheritedProperty, uniqueVar)],
    );

    return [
      cloneDeclaration(element, uniquePropertyName, children),
      cloneDeclaration(element, property, uniqueVar),
    ];
  };
}
