export const tokensColor = {
  iconColor: {
    primary: '$color.neutral600',
    secondary: '$color.neutral400',
    disabled: '$color.neutral200',
    brand: '$color.brand400',
    info: '$color.info400',
    success: '$color.success400',
    attention: '$color.attention400',
    critical: '$color.critical400',
  },
  textColor: {
    primary: '$color.neutral700',
    secondary: '$color.neutral500',
    disabled: '$color.neutral300',
    brand: '$color.brand500',
    info: '$color.info500',
    success: '$color.success500',
    attention: '$color.attention500',
    critical: '$color.critical500',
  },
};
