import {Priority} from './constants';
import {expectActiveViewRevision} from './createView';
import {dynamic} from './dynamic';
import {setPriority} from './priority';
import type {Intent} from '../intent';
import type {View} from './types';

export function applyDefaults<Props>(
  revision: View.ViewRevision<Props>,
  defaults: Props,
): void {
  const keys = Object.keys(defaults as object) as (keyof Props)[];

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    // We check for undefined to match the behavior of ES6 default assignment
    if (typeof revision.initialProps[key] === 'undefined') {
      revision.props[key] = defaults[key];
    }
  }
}

/**
 * Sets or overrides a View’s default value for one or more props.
 *
 * @see https://sail.stripe.me/apis/setdefaults
 */
export const setDefaults = <Props>(defaults: Props): Intent<Props> =>
  setPriority(
    Priority.High,
    dynamic(() => {
      applyDefaults(expectActiveViewRevision(), defaults);
    }),
  );
