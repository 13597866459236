import {AnalyticsReporter} from './AnalyticsReporter';
import type {AnalyticsConfig} from './types';
import {
  Reporter,
  TrackResult,
  TrackingIssue,
  ExtendedEventName,
} from './reporter';

// List events that are produced a lot by the frontend since the legacy
// reporter sends one request per event. Events can still be read through the
// AEL path.
const FREQUENT_EVENTS = new Set([
  'frontend.data.success',
  'frontend.data.permissions_success',
  'frontend.data.permissions_error',
  'frontend.data.prefetch.hover.abort.350ms',
  'frontend.data.prefetch.hover.success.350ms',
  'frontend.data.overprefetch',
  'frontend.data.underprefetch',
  'preloaded_experiment_retrieved',
]);

export class DualReporter<T extends string> implements Reporter<T> {
  aelReporter: AnalyticsReporter<T>;

  deprecatedReporter: AnalyticsReporter<T>;

  experimentsList: Array<string>;

  filterExperiments: (
    eventName: ExtendedEventName<T>,
    experimentName: unknown,
    experimentsList: Array<string>,
  ) => boolean;

  constructor(
    aelConfig: AnalyticsConfig,
    deprecatedConfig: AnalyticsConfig,
    experimentsList: Array<string>,
    filterExperiments: (
      eventName: ExtendedEventName<T>,
      experimentName: unknown,
      experimentsList: Array<string>,
    ) => boolean,
  ) {
    this.aelReporter = new AnalyticsReporter(aelConfig);
    this.deprecatedReporter = new AnalyticsReporter(deprecatedConfig);
    this.experimentsList = experimentsList;
    this.filterExperiments = filterExperiments;
  }

  injectGoogleAnalytics() {
    return (
      !!this.aelReporter.config.googleAnalytics ||
      !!this.deprecatedReporter.config.googleAnalytics
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configure(params: {[key: string]: any}) {
    this.aelReporter.configure(params);
    this.deprecatedReporter.configure(params);
  }

  callLogFiltered(
    eventName: ExtendedEventName<T>,
    experimentName: unknown,
    loggerFn: Function,
  ) {
    if (
      this.filterExperiments(eventName, experimentName, this.experimentsList)
    ) {
      return loggerFn();
    }
  }

  trackOnce(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
    onTrackingIssue?: (arg1: TrackingIssue) => void,
  ): Promise<TrackResult> | null | undefined {
    this.callLogFiltered(eventName, params.experiment_name, () =>
      this.deprecatedReporter.trackOnce(
        eventName,
        params,
        options,
        onTrackingIssue,
      ),
    );
    return this.aelReporter.trackOnce(
      eventName,
      params,
      options,
      onTrackingIssue,
    );
  }

  track(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
    doubleSnakeCase: boolean = true,
    onTrackingIssue: (arg1: TrackingIssue) => void = () => {},
  ): Promise<TrackResult> {
    if (!FREQUENT_EVENTS.has(eventName)) {
      this.callLogFiltered(eventName, params.experiment_name, () =>
        this.deprecatedReporter.track(
          eventName,
          params,
          options,
          doubleSnakeCase,
          onTrackingIssue,
        ),
      );
    }
    return this.aelReporter.track(
      eventName,
      params,
      options,
      doubleSnakeCase,
      onTrackingIssue,
    );
  }

  viewedOnce(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> | null | undefined {
    this.callLogFiltered(eventName, params.experiment_name, () =>
      this.deprecatedReporter.viewedOnce(eventName, params, options),
    );
    return this.aelReporter.viewedOnce(eventName, params, options);
  }

  viewed(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> {
    this.callLogFiltered(eventName, params.experiment_name, () =>
      this.deprecatedReporter.viewed(eventName, params, options),
    );
    return this.aelReporter.viewed(eventName, params, options);
  }

  modalOnce(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> | null | undefined {
    this.callLogFiltered(eventName, params.experiment_name, () =>
      this.deprecatedReporter.modalOnce(eventName, params, options),
    );
    return this.aelReporter.modalOnce(eventName, params, options);
  }

  modal(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> {
    this.callLogFiltered(eventName, params.experiment_name, () =>
      this.deprecatedReporter.modal(eventName, params, options),
    );
    return this.aelReporter.modal(eventName, params, options);
  }

  actionOnce(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> | null | undefined {
    this.callLogFiltered(eventName, params.experiment_name, () =>
      this.deprecatedReporter.actionOnce(eventName, params, options),
    );
    return this.aelReporter.actionOnce(eventName, params, options);
  }

  action(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> {
    this.callLogFiltered(eventName, params.experiment_name, () =>
      this.deprecatedReporter.action(eventName, params, options),
    );
    return this.aelReporter.action(eventName, params, options);
  }

  linkOnce(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> | null | undefined {
    this.callLogFiltered(eventName, params.experiment_name, () =>
      this.deprecatedReporter.linkOnce(eventName, params, options),
    );
    return this.aelReporter.linkOnce(eventName, params, options);
  }

  link(
    eventName: ExtendedEventName<T>,
    params: {
      [key: string]: unknown;
    } = {},
    options: {
      [key: string]: unknown;
    } = {},
  ): Promise<TrackResult> {
    this.callLogFiltered(eventName, params.experiment_name, () =>
      this.deprecatedReporter.link(eventName, params, options),
    );
    return this.aelReporter.link(eventName, params, options);
  }

  getPreviousEventId() {
    // Both reporters eventId's should be in sync, just return the aelReporter one
    return this.aelReporter.getPreviousEventId();
  }
}
