import {useQuery as useApolloQuery} from '@apollo/client';
import {DO_NOT_USE_platformMetrics} from '@sail/observability';
import {getEnvironment} from '@sail/utils';
import {useContext, useMemo} from 'react';
import useGetApolloContext from 'src/internal/apollo/useGetApolloContext';
import {
  DEFAULT_IGNORE_TRACKING_FIELDS,
  DEFAULT_TRACK_ACCESSES_SAMPLE_RATE,
  useTrackGraphQlFieldAccesses,
} from 'src/internal/deadwood/deadwood';
import PrefetchEnvContext from 'src/internal/prefetching/PrefetchEnvContext';
import ensureCorrectFetchPolicy from 'src/internal/prefetching/ensureCorrectFetchPolicy';
import ensureCorrectNextFetchPolicy from 'src/internal/prefetching/ensureCorrectNextFetchPolicy';
import useDefaultFetchPolicy from 'src/internal/prefetching/useDefaultFetchPolicy';
import usePrefetchCounter from 'src/internal/prefetching/usePrefetchCounter';

import type {OperationVariables, WatchQueryFetchPolicy} from '@apollo/client';
import type {
  GraphQlDocument,
  UseQueryOptions,
  UseQueryOptionsRest,
  UseQueryReturn,
} from 'src/internal/apollo/types';

const EMPTY_OPTIONS: UseQueryOptions<GraphQlDocument<any, any, any>> = {};

/**
 * React hook to execute GraphQL queries using `@sail/data`.
 *
 * This hook is equivalent to the
 * [`useQuery()` Apollo hook](https://www.apollographql.com/docs/react/api/react/hooks#function-signature)
 * but it has a few differences, all of them described
 * [here](/apis/data/graphql-usage/the-basics-runtime/#option-differences).
 *
 * For more detailed documentation about how to use this method, please head to the
 * ["Using GraphQL / Defining operations"](/apis/data/graphql-usage/defining-operations) page.
 *
 * @example Basic {{include './examples/useQuery.basic.tsx'}}
 *
 * @see https://sail.stripe.me/apis/data/useQuery
 */
export default function useQuery<
  /** The data returned by the GraphQL query (inferred from the passed GraphQL document). */
  TData,
  /** The variables that the GraphQL query accept (inferred from the passed GraphQL document). */
  TVariables extends OperationVariables,
  /** The permissions needed by the GraphQL query (inferred from the passed GraphQL document). */
  TPermissions,
>(
  /**
   * The GraphQL query that is going to be executed. This must be created with the
   * [`gql`](/apis/data/gql) or
   * [`graphql`](/apis/data/graphql) template literal.
   */
  query: GraphQlDocument<TData, TVariables, TPermissions>,
  /**
   * The options to pass to the query. This argument is optional in the case the query does not
   * have any required variable or [permission](/apis/data/permissions).
   */
  ...optionsRest: UseQueryOptionsRest<
    GraphQlDocument<TData, TVariables, TPermissions>
  >
): /**
 * Object with `data`, `loading` and `error` (similar to the one that the
 * [`useQuery` Apollo hook](https://www.apollographql.com/docs/react/api/react/hooks/#result) returns).
 */
UseQueryReturn<GraphQlDocument<TData, TVariables, TPermissions>> {
  DO_NOT_USE_platformMetrics.useTrackMount('sail_data_useQuery');
  const isDev = getEnvironment() !== 'production';
  const nfpCacheFirstFeatureFlag =
    typeof window !== 'undefined' &&
    !!(window as any).PRELOADED?.flags
      ?.sail_enable_nfp_cache_first_for_cache_and_network;
  const enableNextFetchPolicyCheck = isDev || nfpCacheFirstFeatureFlag;

  const options = optionsRest[0] || EMPTY_OPTIONS;
  const defaultFetchPolicy = useDefaultFetchPolicy();
  const {isPrefetchMode} = useContext(PrefetchEnvContext);

  let correctFetchPolicy: WatchQueryFetchPolicy | undefined =
    options.fetchPolicy || defaultFetchPolicy;
  let correctNextFetchPolicy: WatchQueryFetchPolicy | undefined =
    options.nextFetchPolicy;

  if (isPrefetchMode) {
    correctFetchPolicy = ensureCorrectFetchPolicy(correctFetchPolicy);
  }

  if (enableNextFetchPolicyCheck) {
    correctNextFetchPolicy = ensureCorrectNextFetchPolicy(
      correctFetchPolicy,
      correctNextFetchPolicy,
    );
  }

  const optionsWithCorrectFetchPolicy = useMemo(() => {
    const updatedOptions = {
      ...options,
      fetchPolicy: correctFetchPolicy,
    };

    if (correctNextFetchPolicy && enableNextFetchPolicyCheck) {
      updatedOptions.nextFetchPolicy = correctNextFetchPolicy;
    }

    return updatedOptions;
  }, [
    options,
    correctFetchPolicy,
    correctNextFetchPolicy,
    enableNextFetchPolicyCheck,
  ]);

  const operationContext = useGetApolloContext(optionsWithCorrectFetchPolicy);
  const queryOptions = useMemo(() => {
    return {
      ...optionsWithCorrectFetchPolicy,
      context: operationContext,
    };
  }, [operationContext, optionsWithCorrectFetchPolicy]);

  usePrefetchCounter({
    operationContext,
    query,
    variables: queryOptions.variables,
    skip: queryOptions.skip || false,
  });

  const execResult = useApolloQuery(query, queryOptions);
  const {client, ...data} = useTrackGraphQlFieldAccesses(execResult, {
    query,
    sampleRate: options.trackAccesses ?? DEFAULT_TRACK_ACCESSES_SAMPLE_RATE,
    ignoreFields: DEFAULT_IGNORE_TRACKING_FIELDS,
  });

  return data;
}
