import {IconAsset} from '@sail/icons/types';
import {view, Icon} from '@sail/ui';
import * as React from 'react';

import ThemedIcon from 'gelato/frontend/src/components/ThemedIcon';

type InvalidLayoutProps = {
  title: JSX.Element;
  icon: IconAsset;
  description: JSX.Element;
  children?: React.ReactNode;
};

export default function InvalidLayout({
  title,
  icon,
  description,
  children,
}: InvalidLayoutProps) {
  return (
    <view.div css={{stack: 'y', alignY: 'center'}}>
      <view.div css={{marginBottom: 'large', stack: 'y', alignX: 'center'}}>
        <ThemedIcon icon={icon} size="large" />
      </view.div>
      <view.div css={{stack: 'y', gap: 'medium', font: 'body.medium'}}>
        <view.div css={{font: 'heading.large'}}>{title}</view.div>
        <view.div>{description}</view.div>
        {children}
      </view.div>
    </view.div>
  );
}
