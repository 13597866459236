import * as Sentry from '@sentry/browser';
import {AnalyticsReporter} from '@stripe-internal/analytics-js';

import AnalyticsSafeParams from 'gelato/frontend/src/lib/analytics/AnalyticsSafeParams';
import isAnalyticsClientEnabled from 'gelato/frontend/src/lib/analytics/isAnalyticsClientEnabled';
import {ANALYTICS_CLIENT_ID} from 'gelato/frontend/src/lib/constants';
import flags from 'gelato/frontend/src/lib/flags';

import type {
  AnalyticsClient,
  AnalyticsMethod,
  AnalyticsParams,
  AnalyticsOptions,
} from 'gelato/frontend/src/lib/analytics/types';

/**
 * Creates analytics client that sends data to https://r.stripe.com/0
 */
export default class ProductionAnalyticsClient
  implements AnalyticsClient<string>
{
  _reporter: AnalyticsReporter<string> | null;

  constructor(options: AnalyticsOptions) {
    this._reporter = isAnalyticsClientEnabled()
      ? new AnalyticsReporter({
          clientId: ANALYTICS_CLIENT_ID,
          inProduction: true,
          locale: options.locale,
          safeParams: AnalyticsSafeParams,
          useCookies: 'disabled',
        })
      : null;
  }

  action = async (event: string, params?: AnalyticsParams) => {
    if (flags.isActive('idprod_frontend_analytics_update')) {
      return this._sendEvent('action', event, params);
    } else {
      this._sendEvent('action', event, params);
    }
  };

  link = async (event: string, params?: AnalyticsParams) => {
    if (flags.isActive('idprod_frontend_analytics_update')) {
      return this._sendEvent('link', event, params);
    } else {
      this._sendEvent('link', event, params);
    }
  };

  modal = async (event: string, params?: AnalyticsParams) => {
    if (flags.isActive('idprod_frontend_analytics_update')) {
      return this._sendEvent('modal', event, params);
    } else {
      this._sendEvent('modal', event, params);
    }
  };

  track = async (event: string, params?: AnalyticsParams) => {
    if (flags.isActive('idprod_frontend_analytics_update')) {
      return this._sendEvent('track', event, params);
    } else {
      this._sendEvent('track', event, params);
    }
  };

  viewed = async (event: string, params?: AnalyticsParams) => {
    if (flags.isActive('idprod_frontend_analytics_update')) {
      return this._sendEvent('viewed', event, params);
    } else {
      this._sendEvent('viewed', event, params);
    }
  };

  _sendEvent = async (
    method: AnalyticsMethod,
    event: string,
    params?: AnalyticsParams,
  ) => {
    const reporter = this._reporter;
    if (reporter) {
      if (flags.isActive('idprod_frontend_analytics_update')) {
        return reporter[method](event, params);
      } else {
        try {
          await reporter[method](event, params);
        } catch {
          Sentry.addBreadcrumb({
            category: 'Analytics',
            message: 'Error sending analytics event',
            level: Sentry.Severity.Error,
          });
        }
      }
    }
  };
}
