import isEqual from 'lodash/isEqual';
import * as React from 'react';

import PageComponentContent from 'gelato/frontend/src/components/PageComponentContent';
import {
  getComponentConfig,
  setComponentConfig,
} from 'gelato/frontend/src/lib/ComponentConfig';

import type {ExperimentName} from '@stripe-internal/data-gelato/schema/types';
import type {ConfigurableComponent} from 'gelato/frontend/src/lib/ComponentConfig';

/**
 * Creates the page container that renders the V1 or v2 page conditionally.
 * @oaram experimentName The name of the experiment.
 * @param control The V1 page without Butter 2.0 design.
 * @param treatment The V2 page with Butter 2.0 design.
 */
export default function createExperimentPageRenderer(
  experimentName: ExperimentName,
  control: ConfigurableComponent,
  treatment: ConfigurableComponent,
): React.FunctionComponent {
  const component = function PageComponentContentWrapper(props: any) {
    return <PageComponentContent {...props} />;
  };

  // The treatment is 'undefined' when running Jest tests.
  // This is because when there is a circular dependency import,
  // it defaults to using 'undefined' instead of the actual component.
  if (!treatment) {
    return component;
  }

  const controlConfig = getComponentConfig(control);
  const treatmentConfig = getComponentConfig(treatment);

  // Both V1 and V2 page should have the same configurations so that their
  // navigation flow would behave the same, only their internal designs are
  // different.
  if (!isEqual(controlConfig, treatmentConfig)) {
    throw new Error(`Expect both pages to have the same configurations`);
  }

  // Copy the configurations to the container.
  setComponentConfig(component, {
    ...controlConfig,
    experimentPageRenderer: {
      experimentName,
      control,
      treatment,
    },
  });

  return component;
}
