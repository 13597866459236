import {defaultStylePlugin} from './core';
import {
  axisProperties,
  boxShadow,
  font,
  layout,
  size,
  transforms,
} from './plugins';

export const presetCssPlugins = [
  defaultStylePlugin,
  axisProperties,
  boxShadow,
  font,
  layout,
  size,
  transforms,
];
