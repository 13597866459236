import * as Sentry from '@sentry/browser';
import * as React from 'react';

import {
  useBreakpoint,
  useExperiments,
  useFeatureFlags,
  useConnectIframe,
  useSession,
  useBranding,
} from 'gelato/frontend/src/lib/hooks';
import Storage from 'gelato/frontend/src/lib/Storage';

type Props = {
  children: React.ReactNode;
};

// Sets and updates relevant tags in the Sentry client
const SentryContextProvider = ({children}: Props) => {
  const breakpoint = useBreakpoint();
  const experiments = useExperiments();
  const featureFlags = useFeatureFlags();
  const isConnectIframe = useConnectIframe();
  const session = useSession();
  const branding = useBranding() || Storage.getBranding();
  const templateId = Storage.getTemplateId();
  const merchant = Storage.getMerchant();

  const setSentryTag = React.useCallback((name: string, value: any) => {
    // Shorten tag since Sentry only supports tags of 30 chars max for keys and 200 for values
    Sentry.setTag(
      name.slice(0, 30),
      value !== undefined && value !== null
        ? value.toString().slice(0, 200)
        : 'undefined',
    );
  }, []);

  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.history) {
      setSentryTag('historyLength', window.history.length);
    }
  }, [setSentryTag]);

  React.useEffect(() => {
    if (session) {
      setSentryTag('VI', session.id);
      setSentryTag('livemode', session.livemode);
      setSentryTag('operatingMode', session.operatingMode);
    }
  }, [session, setSentryTag]);

  React.useEffect(() => {
    if (branding) {
      setSentryTag('platformName', branding.platformName);
    }
  }, [branding, setSentryTag]);

  React.useEffect(() => {
    if (templateId) {
      setSentryTag('templateId', templateId);
    }
  }, [templateId, setSentryTag]);

  React.useEffect(() => {
    if (merchant) {
      setSentryTag('merchant', merchant);
    }
  }, [merchant, setSentryTag]);

  React.useEffect(() => {
    if (featureFlags) {
      featureFlags.forEach((flag) => setSentryTag(flag, true));
    }
  }, [featureFlags, setSentryTag]);

  React.useEffect(() => {
    if (experiments) {
      experiments.forEach(({name, variant}) => setSentryTag(name, variant));
    }
  }, [experiments, setSentryTag]);

  React.useEffect(() => {
    setSentryTag('iframe', isConnectIframe);
  }, [isConnectIframe, setSentryTag]);

  React.useEffect(() => {
    setSentryTag('breakpoint', breakpoint);
  }, [breakpoint, setSentryTag]);

  return children;
};

export default SentryContextProvider;
