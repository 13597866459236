import {Intent} from '@sail/engine';
import {css, view} from '@sail/ui';
import * as React from 'react';

import {
  tBoldL,
  tColorNormal,
  tCenter,
  responsiveCss,
} from 'gelato/frontend/src/components/stylesV2';

type Props = {
  children?: React.ReactNode;
  level?: 1 | 2 | 3;
  uses?: Intent[];
};

const Styles = {
  h1: responsiveCss({
    compact: {
      fontSize: '16px',
      lineHeight: '1.333',
      margin: '0 0 4px 0',
    },
    regular: {
      fontSize: '28px',
      lineHeight: '1.333',
      margin: '0 0 8px 0',
    },
  }),
  h2: responsiveCss({
    compact: {
      fontSize: '16px',
      lineHeight: '24px',
      margin: '0 0 8px 0',
    },
    regular: {
      fontSize: '24px',
      lineHeight: '32px',
      margin: '0 0 16px 0',
    },
  }),
  h3: css({
    font: 'body.medium.emphasized',
    margin: 'space.0',
  }),
};

export default function Heading({
  children,
  level = 1,
  uses = [],
}: Props): JSX.Element {
  if (level === 1) {
    return (
      <view.h1 uses={[Styles.h1, tBoldL, tColorNormal, tCenter, ...uses]}>
        {children}
      </view.h1>
    );
  }

  if (level === 2) {
    return (
      <view.h2 uses={[Styles.h2, tBoldL, tColorNormal, ...uses]}>
        {children}
      </view.h2>
    );
  }

  // 'tertiary' heading
  return (
    <view.h3 uses={[Styles.h3, tBoldL, tColorNormal, ...uses]}>
      {children}
    </view.h3>
  );
}
