import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

export default function isPhoneOTPDeclined(
  state: Readonly<ApplicationState>,
): boolean {
  const {session} = state;
  const {missingFields, collectedData} = session || {};
  return (
    !!missingFields?.includes('phone_otp') &&
    !!collectedData?.individual?.phoneNumber?.otpDeclined === true
  );
}
