import type * as React from 'react';
import type {View} from './types';

export function isView<
  Props,
  T extends React.JSXElementConstructor<View.ViewProps<Props>> = (
    props: View.ViewProps<Props>,
  ) => JSX.Element | null,
>(value: unknown): value is View.ViewComponent<T> {
  type VC = View.ViewComponent<T>;
  return !!(
    value &&
    typeof value === 'object' &&
    (value as VC).useView &&
    (value as VC).config
  );
}

export function getElementRef(
  element: React.ReactElement,
): void | React.Ref<unknown> {
  return (element as unknown as {ref: React.Ref<unknown>}).ref;
}

export function getViewComponent<
  Props,
  T extends React.JSXElementConstructor<View.ViewProps<Props>>,
>(value: string | T): View.ViewComponent<T> | void {
  if (isView(value)) {
    return value as unknown as View.ViewComponent<T> | void;
  }
}
