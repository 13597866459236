import {useEffect} from 'react';

import {enumerateCameraDevices} from 'gelato/frontend/src/controllers/actions/cameraActions';
import {serializeCameraDeviceInfoToString} from 'gelato/frontend/src/controllers/states/CameraState';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';

import type {
  CameraDeviceInfo,
  CameraState,
} from 'gelato/frontend/src/controllers/states/CameraState';

/**
 * This hook observes the camera devices list and update the application state
 * accordingly.
 * @returns The camera devices list.
 */
export default function useAppCameraDevicesList(): CameraState['cameraDeviceList'] {
  const {appController, appState} = useAppController();

  const needsCamera =
    appState.session?.requiredFields.includes('id_document_images') ||
    appState.session?.requiredFields.includes('face');

  // The MediaDevices API could be injected / replaced in Syntheetic tests.
  // We should explicitly use it as a dependency to ensure that the hook is
  // updated when the API is replaced.
  const {mediaDevices} = window.navigator;

  useEffect(() => {
    if (!needsCamera) {
      // We don't care about the camera devices list if the camera is not
      // needed.
      return;
    }

    if (
      !mediaDevices ||
      !mediaDevices.enumerateDevices ||
      !mediaDevices.addEventListener
    ) {
      // mediaDevices is not available.
      return;
    }

    const lookupDevices = async () => {
      const oldList: CameraDeviceInfo[] = appController.state.cameraDeviceList;

      try {
        const newList = await enumerateCameraDevices();

        // Do not update the state if the list of camera devices is the same.
        const oldIDs = oldList
          .map((d) => serializeCameraDeviceInfoToString(d))
          .sort()
          .join('|');

        const newIDs = newList
          .map((d) => serializeCameraDeviceInfoToString(d))
          .sort()
          .join('|');

        if (oldIDs !== newIDs || newIDs === '') {
          analytics.track('videoDevices', {
            // Track if the mediaDevices is synthetic or not.
            mediaDevicesType: Object.prototype.toString.call(mediaDevices),
            devices: newList.map((d) => d.label).sort(),
          });

          appController.update((draft) => {
            shallowMergeInto(draft, {
              // Convert the MediaDeviceInfo to JSON so they could be stored
              // as immutable data by immer.
              cameraDeviceList: newList.map((d) => d.toJSON()),
              cameraDeviceListResolved: true,
            });
          }, 'skip_unchanged_state');
        }
      } catch (ex) {
        if (oldList.length) {
          // Clear the old camera device list when error occurred.
          appController.update((draft) => {
            shallowMergeInto(draft, {
              cameraDeviceList: [],
              cameraDeviceListResolved: true,
            });
          }, 'skip_unchanged_state');
        }

        const cause = asError(ex);
        const error = new Error(ErrorCode.failedToEnumerateCameraDevices, {
          cause,
        });

        handleException(error, cause.message);
      }
    };

    // Debounce the lookupDevices function to avoid too many calls.
    const rid = setTimeout(() => lookupDevices(), 16);

    // This event fires whenever a media device such as a camera, microphone, or
    // speaker is connected to or removed from the system.
    mediaDevices.addEventListener('devicechange', lookupDevices, true);

    return () => {
      clearTimeout(rid);
      mediaDevices.removeEventListener('devicechange', lookupDevices, true);
    };
  }, [appController, mediaDevices, needsCamera]);

  return appState.cameraDeviceList;
}
