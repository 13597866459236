import {ApolloLink} from '@apollo/client';
import {checkPermissions} from 'src/index';
import {SELF} from 'src/internal/apollo/permissions';

import type {NextLink, RequestHandler} from '@apollo/client';
import type {
  GraphQlDocument,
  InternalOperation,
} from 'src/internal/apollo/types';

export default function createRuntimePermissionsCheckLink(
  {
    enable,
  }: {
    enable: boolean;
  } = {enable: false},
): ApolloLink {
  return new ApolloLink(function runtimePermissionsCheckLink<
    TDocumentNode extends GraphQlDocument<any, any, any>,
  >(operation: InternalOperation<TDocumentNode>, forward: NextLink) {
    if (!enable) {
      return forward(operation);
    }

    const {token, isPrefetchMode, observabilityFns} = operation.getContext();

    if (!observabilityFns || !token || !operation.query.permissions) {
      return forward(operation);
    }

    const {
      errors: {warning},
      metrics,
    } = observabilityFns;

    const refinedToken = checkPermissions(token, operation.query.permissions);
    const successfulCheck = !!refinedToken;

    if (successfulCheck) {
      metrics.increment('frontend.data.permissions_success', {
        operation_name: operation.operationName,
        operation_prefetch: !!isPrefetchMode,
      });
    } else {
      metrics.increment('frontend.data.permissions_error', {
        operation_name: operation.operationName,
        operation_prefetch: !!isPrefetchMode,
      });

      const tokenPermissions = Object.keys(token[SELF]);

      warning(`Permissions check doesn't pass for ${operation.operationName}`, {
        project: 'sail_core',
        tags: {isPrefetchMode: isPrefetchMode?.toString() ?? 'false'},
        extras: {
          missingPermissions: Object.keys(operation.query.permissions).filter(
            (perm) => !tokenPermissions.includes(perm),
          ),
          token,
        },
      });
    }

    return forward(operation).map(function runtimePermissionsCheckOnResponse(
      data,
    ) {
      const errorCodes =
        data.errors?.map((error) => error.extensions?.error?.code) ?? [];

      if (!successfulCheck) {
        if (errorCodes.length > 0) {
          warning(
            `A client side unsuccessful permissions check for ${operation.operationName} resulted in server error: ${errorCodes}`,
            {
              project: 'sail_core',
              tags: {isPrefetchMode: isPrefetchMode?.toString() ?? 'false'},
            },
          );
        } else {
          warning(
            `A client side unsuccessful permissions check for ${operation.operationName} didn't result in reported server errors`,
            {
              project: 'sail_core',
              tags: {isPrefetchMode: isPrefetchMode?.toString() ?? 'false'},
            },
          );
        }
      }

      return data;
    });
  } as RequestHandler);
}
