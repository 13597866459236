import {GraphQlField} from '@sail/data';
import * as React from 'react';

import {FormattedMarkdownMessage} from 'gelato/frontend/src/components/Message';
import {STRIPE_PRIVACY_CENTER_URL} from 'gelato/frontend/src/lib/constants';
import Storage from 'gelato/frontend/src/lib/Storage';
import messages from 'gelato/frontend/src/local_pages/messages/image_consent';
import welcomePageMessages from 'gelato/frontend/src/local_pages/messages/welcome_consent_page';
import {InlineLink} from 'sail/InlineLink';

import type {ConsentImageMode} from '@stripe-internal/data-gelato/schema/types';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';
import type {MessageDescriptor} from 'react-intl';

// Utilities for working with, and collecting Consent
// These are the pages where we collect consent to verify.
type ConsentPageName = 'image_consent' | 'welcome';

type InformedSectionParams = {
  isInternal?: boolean;
  useCase?: string;
  imageMode?: ConsentImageMode;
  privacyPolicyUrl?: string;
  platformName?: string;
  consentPage: ConsentPageName;
  addLearnMoreLink?: boolean;
};

/*
 * Several cases we need to handle when deciding what informed consent to display
 *      isInternal      (Is this an internal use case? E.g. security, fraud intelligence, direct onboarding...)
 *         /\
 *       /    \
 *     False    True
 *     /\         |
 *    /  \        |
 *  PP   No PP    |     (PP = Privacy Policy)
 *  \      |      |
 *    \    |      |
 *      \  |      |
 *    - document-only
 *    - document+selfie
 *
 *  Soon, we'll update this to include whether or not we are rolling out the new consent flow which lives in the welcome page
 * */

// These are all the base combinations of consent types we might need,
// depending on internal vs. external, privacy policy provided or not, and what is being verified
const CONSENT_TYPES = {
  INTERNAL_DOCUMENT_ONLY: {
    IMAGE_CONSENT: messages.informedInternalIdentityDocument,
    WELCOME_PAGE: welcomePageMessages.internalInformedConsent,
  },
  INTERNAL_SELFIE: {
    IMAGE_CONSENT: messages.informedInternalSelfie,
    WELCOME_PAGE: welcomePageMessages.internalInformedConsent,
  },
  INTERNAL_SELFIE_AND_DOCUMENT: {
    IMAGE_CONSENT: messages.informedInternalIdentityDocumentAndSelfie,
    WELCOME_PAGE: welcomePageMessages.internalInformedConsent,
  },
  // External
  EXTERNAL_DOCUMENT: {
    IMAGE_CONSENT: messages.informedExternalIdentityDocument,
    WELCOME_PAGE: welcomePageMessages.externalInformedConsent,
  },
  EXTERNAL_DOCUMENT_PP: {
    IMAGE_CONSENT: messages.informedExternalIdentityDocumentWithPP,
    WELCOME_PAGE: welcomePageMessages.externalInformedConsentWithPP,
  },
  // As of 2021-10-02 we should never actually see just SELFIE image_modes
  // This was part of a prior version of the Gelato API, which has been deprecated
  EXTERNAL_SELFIE: {
    IMAGE_CONSENT: messages.informedExternalSelfie,
    WELCOME_PAGE: welcomePageMessages.externalInformedConsent,
  },
  EXTERNAL_SELFIE_PP: {
    IMAGE_CONSENT: messages.informedExternalSelfieWithPP,
    WELCOME_PAGE: welcomePageMessages.externalInformedConsentWithPP,
  },
  EXTERNAL_SELFIE_AND_DOCUMENT: {
    IMAGE_CONSENT: messages.informedExternalIdentityDocumentAndSelfie,
    WELCOME_PAGE: welcomePageMessages.externalInformedConsent,
  },
  EXTERNAL_SELFIE_AND_DOCUMENT_PP: {
    IMAGE_CONSENT: messages.informedExternalIdentityDocumentAndSelfieWithPP,
    WELCOME_PAGE: welcomePageMessages.externalInformedConsentWithPP,
  },
};

function renderMessage(
  consentType: any,
  consentPage: ConsentPageName,
  platformName?: string | null,
  privacyPolicyUrl?: string | null,
  addLearnMoreLink?: boolean | null,
) {
  const learnMoreLink = (
    <InlineLink href={STRIPE_PRIVACY_CENTER_URL} target="_blank">
      <FormattedMarkdownMessage {...welcomePageMessages.learnMore} />
    </InlineLink>
  );

  let values = {learnMoreLink};
  let PrivacyPolicy = null;
  if (platformName) {
    // @ts-expect-error - TS2322 - Type '{ platformName: string; learnMoreLink: JSX.Element; }' is not assignable to type '{ learnMoreLink: JSX.Element; }'.
    values = {platformName, learnMoreLink};
    // Add in privacy policy text if needed
    if (privacyPolicyUrl) {
      PrivacyPolicy = (text: any) => (
        <InlineLink href={privacyPolicyUrl} target="_blank">
          {text}
        </InlineLink>
      );

      // @ts-expect-error - TS2322 - Type '{ PrivacyPolicy: (text: any) => JSX.Element; platformName: string; learnMoreLink: JSX.Element; }' is not assignable to type '{ learnMoreLink: JSX.Element; }'.
      values = {PrivacyPolicy, platformName, learnMoreLink};
    }
  }

  let message: MessageDescriptor = {};
  if (consentPage === 'image_consent') {
    message = consentType.IMAGE_CONSENT;
  } else if (consentPage === 'welcome') {
    message = consentType.WELCOME_PAGE;
  }

  return <FormattedMarkdownMessage {...message} values={values} />;
}

export const renderInformedConsentSection = ({
  isInternal,
  useCase,
  imageMode,
  privacyPolicyUrl,
  platformName,
  consentPage,
}: InformedSectionParams) => {
  if (isInternal) {
    if (imageMode === 'identity_document') {
      return renderMessage(CONSENT_TYPES.INTERNAL_DOCUMENT_ONLY, consentPage);
    } else if (imageMode === 'selfie') {
      return renderMessage(CONSENT_TYPES.INTERNAL_SELFIE, consentPage);
    } else {
      return renderMessage(
        CONSENT_TYPES.INTERNAL_SELFIE_AND_DOCUMENT,
        consentPage,
      );
    }
  } else if (imageMode === 'identity_document') {
    if (privacyPolicyUrl) {
      return renderMessage(
        CONSENT_TYPES.EXTERNAL_DOCUMENT_PP,
        consentPage,
        platformName,
        privacyPolicyUrl,
      );
    } else {
      return renderMessage(
        CONSENT_TYPES.EXTERNAL_DOCUMENT,
        consentPage,
        platformName,
      );
    }
  } else if (imageMode === 'selfie') {
    if (privacyPolicyUrl) {
      return renderMessage(
        CONSENT_TYPES.EXTERNAL_SELFIE_PP,
        consentPage,
        platformName,
        privacyPolicyUrl,
      );
    } else {
      return renderMessage(
        CONSENT_TYPES.EXTERNAL_SELFIE,
        consentPage,
        platformName,
      );
    }
  } else if (privacyPolicyUrl) {
    return renderMessage(
      CONSENT_TYPES.EXTERNAL_SELFIE_AND_DOCUMENT_PP,
      consentPage,
      platformName,
      privacyPolicyUrl,
    );
  } else {
    return renderMessage(
      CONSENT_TYPES.EXTERNAL_SELFIE_AND_DOCUMENT,
      consentPage,
      platformName,
    );
  }
};

export type WelcomeRouterPath = '/welcome' | '/welcome_consent';

export const getWelcomePageV2 = (
  session: GraphQlField<GetSessionQueryData, 'session'>,
  experiments: ReadonlyArray<GraphQlField<GetSessionQueryData, 'experiments'>>,
): WelcomeRouterPath => {
  let welcomePage: WelcomeRouterPath = '/welcome';
  const settings = Storage.getIdentitySettings();
  const hasReusableNetworkedIdentity =
    !!settings?.networked_identity_reuse_available;

  const useButterNetworkedIdentity =
    experiments.find((e) => e.name === 'welcome_page_v2')?.variant ===
    'treatment';

  if (hasReusableNetworkedIdentity && !useButterNetworkedIdentity) {
    welcomePage = '/welcome_consent';
  }

  if (session.missingFields.includes('selfie_verification_method')) {
    welcomePage = '/welcome_consent';
  }
  return welcomePage;
};

export const getWelcomePage = (
  session: GetSessionQueryData,
): WelcomeRouterPath => {
  // Note: 2021-10-11
  // We choose which welcome page based on the consent flow we are using.
  // If this field has been served, it means we should use the new /welcome_consent page
  // See https://paper.dropbox.com/doc/AV-Selfie-Alternative-to-Verify-Frontend-26oP8PvBoRSdnPaq63ePd for more info
  return getWelcomePageV2(session.session, session.experiments);
};

export default getWelcomePage;
