import {get} from 'lodash';
import * as React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import {NegativeNotice} from 'sail/Notice';

import type {IntlProps} from 'gelato/frontend/src/components/Message';

const messages = defineMessages({
  genericErrorTitle: {
    id: 'error.generic.title',
    description:
      'Error title when when there is a generic error when submitting individual data',
    defaultMessage: 'Something went wrong',
  },
  genericErrorDescription: {
    id: 'error.generic.description',
    description:
      'Error message for when there is a generic error when submitting individual data',
    defaultMessage: 'Please try again.',
  },
  networkErrorTitle: {
    id: 'error.network.title',
    description:
      'Error message when there is a network error when saving information input by the user',
    defaultMessage: 'There was a problem with your internet connection',
  },
});

type Props = {
  error: any;
};

const NetworkErrorNotice = injectIntl(({error, intl}: Props & IntlProps) => {
  const formatErrorMessage = (): {
    title: string;
    description: string;
  } => {
    const {graphQLErrors, networkError} = error;

    if (networkError) {
      return {
        title: intl.formatMessage(messages.networkErrorTitle),
        description: intl.formatMessage(messages.genericErrorDescription),
      };
    } else {
      return {
        title: get(
          graphQLErrors,
          '[0].message',
          intl.formatMessage(messages.genericErrorTitle),
        ),
        description: intl.formatMessage(messages.genericErrorDescription),
      };
    }
  };

  return <NegativeNotice {...formatErrorMessage()} />;
});

export default NetworkErrorNotice;
