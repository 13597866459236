import * as React from 'react';

const {useLayoutEffect} = React;

/**
 * The ResizeObserver instance that observes the size of the element.
 */
const ElementSizeObserver: ResizeObserver | null = window.ResizeObserver
  ? new ResizeObserver(handlerElementSizeObserver)
  : null;

/**
 * The handler that is called when the element changes size.
 */
function handlerElementSizeObserver(entries: ResizeObserverEntry[]): void {
  entries.forEach((entry) => {
    const {target, contentRect} = entry;
    setElementSizeCSSVariables(target as HTMLDivElement, {
      height: contentRect.height,
      width: contentRect.width,
    });
  });
}

/**
 * The function that sets the CSS variables to the element.
 * @param element The element to set the CSS variables to.
 * @param size The size of the element.
 */
function setElementSizeCSSVariables(
  element: HTMLDivElement,
  size: {width: number; height: number},
): void {
  const {width, height} = size;
  const {style} = element;

  // The camera size is the largest square that fits in the element.
  const cameraSize = Math.max(260, Math.min(height, width));
  style.setProperty('--element-offset-height', `${height}px`);
  style.setProperty('--element-offset-width', `${width}px`);
  style.setProperty('--camera-size', `${cameraSize}px`);
}

/**
 * The hook that observes the size of the element and sets CSS variables
 * accessible to its descendants. The CSS variables are:
 * - --element-offset-height: The height of the element.
 * - --element-offset-width: The width of the element.
 * - --camera-size: The size of the camera. *
 * @param domRef The ref of the element.
 */
export default function useElementSizeAsCSSVariables(
  domRef: React.RefObject<HTMLDivElement>,
): void {
  useLayoutEffect(() => {
    const element = domRef.current;

    if (element) {
      const reflow = () => {
        setElementSizeCSSVariables(element, {
          height: element.offsetHeight,
          width: element.offsetWidth,
        });
      };
      if (ElementSizeObserver) {
        reflow();
        ElementSizeObserver.observe(element);
        return () => ElementSizeObserver.unobserve(element);
      } else {
        reflow();
        // Fallback for browsers that don't support ResizeObserver.
        window.addEventListener('resize', reflow, true);
        return () => window.removeEventListener('resize', reflow, true);
      }
    }
  }, [domRef]);
}
