import {splitBalanced} from '../../../util';
import {css, cssPluginResetStyles} from '../core';
import type {CssProperties, CssValue, CssSelectorIndexer} from '../core';

type PaddingValue = CssProperties['padding'];

export interface PaddingResult {
  [key: `--${string}`]: PaddingValue;
  padding?: PaddingValue;
}

export interface PaddingPlugin {
  (value: PaddingValue, selector: CssSelectorIndexer): PaddingResult;
}

const paddingReset: CssProperties = {
  '--padding-top': css.value(0),
  '--padding-right': css.value(0),
  '--padding-bottom': css.value(0),
  '--padding-left': css.value(0),
  padding: css.value(
    'var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left)',
  ),
};

const marginX = (
  value: CssProperties['marginLeft'],
): Pick<CssProperties, 'marginLeft' | 'marginRight'> => ({
  marginLeft: value,
  marginRight: value,
});

const marginY = (
  value: CssProperties['marginTop'],
): Pick<CssProperties, 'marginTop' | 'marginBottom'> => ({
  marginTop: value,
  marginBottom: value,
});

const padding = (
  shorthand: CssProperties['padding'],
): {[key: `--${string}`]: PaddingValue} => {
  // shorthand value may be calc or var, so we need to be careful to match parens when splitting
  const [top, right, bottom, left] = splitBalanced(
    shorthand?.value.toString() || '',
  );

  return {
    '--padding-top': css.value(top),
    '--padding-right': css.value(right ?? top),
    '--padding-bottom': css.value(bottom ?? top),
    '--padding-left': css.value(left ?? right ?? top),
  };
};

function createPaddingPlugin(customProperty?: string): PaddingPlugin {
  return function paddingPlugin(value, selector) {
    const paddingValue = customProperty
      ? {[customProperty]: value}
      : padding(value);

    return Object.assign(
      {},
      cssPluginResetStyles(selector, paddingReset),
      paddingValue,
    );
  };
}

const paddingX = (
  value: CssProperties['paddingLeft'],
): Pick<CssProperties, 'paddingLeft' | 'paddingRight'> => ({
  paddingLeft: value,
  paddingRight: value,
});

const paddingY = (
  value: CssProperties['paddingTop'],
): Pick<CssProperties, 'paddingTop' | 'paddingBottom'> => ({
  paddingTop: value,
  paddingBottom: value,
});

const negative = <T>(value?: CssValue<T>) =>
  value ? css.value(`calc(-1 * ${value})`) : undefined;

type MarginProperty =
  | 'marginTop'
  | 'marginBottom'
  | 'marginLeft'
  | 'marginRight';

const bleedMap = {
  marginTop: '--padding-top',
  marginBottom: '--padding-bottom',
  marginLeft: '--padding-left',
  marginRight: '--padding-right',
} as const;

type BleedValue<T extends MarginProperty> = Pick<
  CssProperties,
  MarginProperty
>[T];

export type BleedResult<T extends MarginProperty> = Record<T, BleedValue<T>>;

export interface BleedPlugin<T extends MarginProperty> {
  (value: BleedValue<T> | 'auto'): BleedResult<T>;
}

function createBleedPlugin<T extends MarginProperty>(
  ...marginProperties: T[]
): BleedPlugin<T> {
  return function bleedPlugin(value) {
    const properties = marginProperties.map((marginProperty) => {
      if (value === 'auto') {
        const ancestorPaddingVar = `var(${bleedMap[marginProperty]})`;
        return {
          [marginProperty]: negative(css.value(ancestorPaddingVar)),
        };
      }
      return {
        [marginProperty]: negative(value),
      };
    });
    return Object.assign({}, ...properties);
  };
}

export const pluginsBoxModel = {
  marginX,
  marginY,
  padding: createPaddingPlugin(),
  paddingTop: createPaddingPlugin('--padding-top'),
  paddingBottom: createPaddingPlugin('--padding-bottom'),
  paddingLeft: createPaddingPlugin('--padding-left'),
  paddingRight: createPaddingPlugin('--padding-right'),
  paddingX,
  paddingY,
  bleed: createBleedPlugin(
    'marginTop',
    'marginBottom',
    'marginLeft',
    'marginRight',
  ),
  bleedX: createBleedPlugin('marginLeft', 'marginRight'),
  bleedY: createBleedPlugin('marginTop', 'marginBottom'),
  bleedTop: createBleedPlugin('marginTop'),
  bleedBottom: createBleedPlugin('marginBottom'),
  bleedLeft: createBleedPlugin('marginLeft'),
  bleedRight: createBleedPlugin('marginRight'),
};
