import {Rectangle} from 'gelato/frontend/src/ML/IDDetectorAPI';

/**
 * Check if a rectangle is fully contained within another rectangle.
 *
 * @param container The rectangle that should contain the content.
 * @param content The rectangle that should be contained within the container.
 * @returns True if the container fully contains the content, false otherwise.
 */
export default function containsRectangle(
  container: Rectangle,
  content: Rectangle,
): boolean {
  return (
    container.topLeft[0] <= content.topLeft[0] &&
    container.topLeft[1] <= content.topLeft[1] &&
    container.topLeft[0] + container.dimensions[0] >=
      content.topLeft[0] + content.dimensions[0] &&
    container.topLeft[1] + container.dimensions[1] >=
      content.topLeft[1] + content.dimensions[1]
  );
}
