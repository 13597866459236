import {isEmail} from 'validator';

import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import updateEmailMutation from 'gelato/frontend/src/controllers/utils/updateEmailMutation';
import asError from 'gelato/frontend/src/lib/asError';

import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';
import type {LocaleKey} from 'gelato/frontend/src/lib/locale';

/**
 * Perform the mutation to send the handoff email.
 * @param state The current application state.
 * @param runtime The application runtime.
 * @param data The data to send the handoff email.
 */
export default async function sendHandoffEmail(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
  data: {email: string; locale: LocaleKey | null},
): Promise<void> {
  if (!isEmail(data.email)) {
    throw new Error(ErrorCode.invalidEmail);
  }

  try {
    await updateEmailMutation(runtime.apolloClient, {
      emailData: {address: data.email},
    });
  } catch (error: any) {
    if (error && error.networkError && error.networkError.statusCode === 400) {
      throw new Error(ErrorCode.invalidEmail);
    } else {
      throw asError(error);
    }
  }

  const resp = await runtime.api.mutation.sendHandoffEmailMutation({
    variables: {
      locale: data.locale,
    },
  });

  if (!resp.data?.sendEmailLink?.success) {
    throw new Error(ErrorCode.failedToSendEmail);
  }
}
