import * as Sentry from '@sentry/browser';
import DetectRTC from 'detectrtc';
import {head} from 'lodash';
import * as React from 'react';

import useUpdateDeviceStatusMutation from 'gelato/frontend/src/graphql/mutations/useUpdateDeviceStatusMutation';
import analytics from 'gelato/frontend/src/lib/analytics';
import {supportsGetUserMedia} from 'gelato/frontend/src/lib/browser-support';
import {errorToReason, isMobileDevice} from 'gelato/frontend/src/lib/device';
import {useCameraStreamState} from 'gelato/frontend/src/lib/hooks';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import {
  getMajorChromeVersion,
  isAndroid,
  isAndroid12OrAbove,
  isFirefoxUA,
  isSamsungUA,
} from 'gelato/frontend/src/lib/userAgent';
import {ensure} from 'gelato/frontend/src/lib/utils';

export type UserMedia = {
  stream?: MediaStream | null | undefined;
  error?: Error | null | undefined;
};

// from: https://github.com/BlinkID/blinkid-in-browser/blob/master/src/MicroblinkSDK/VideoRecognizer.ts#L562
const BACK_CAMERA_WORDS = [
  'rear',
  'back',
  'rück',
  'arrière',
  'trasera',
  'trás',
  'traseira',
  'posteriore',
  '后面',
  '後面',
  '背面',
  '后置', // alternative
  '後置', // alternative
  '背置', // alternative
  'задней',
  'الخلفية',
  '후',
  'arka',
  'achterzijde',
  'หลัง',
  'baksidan',
  'bagside',
  'sau',
  'bak',
  'tylny',
  'takakamera',
  'belakang',
  'אחורית',
  'πίσω',
  'spate',
  'hátsó',
  'zadní',
  'darrere',
  'zadná',
  'задня',
  'stražnja',
  'belakang',
  'बैक',
];

const isBackCameraLabel = (label: string): boolean => {
  const lowerCase = label.toLowerCase();
  return BACK_CAMERA_WORDS.some((keyword) => lowerCase.includes(keyword));
};

export type Device = {
  deviceId: string;
  label: string;
};

type CameraPermission = 'granted' | 'denied' | 'prompt' | 'unknown';

/**
 * This might help the memory leak bug or race conditions when multiple
 * media streamsare present.
 *
 * During our testing on iOs15+, we found out that calling `getUserMedia()` has
 * unpredictable side-effect that new stream could be affected by exising
 * streams.
 *
 * As far as calling .getUserMedia() multiple times, it fails in some browser
 * situations if you do it without doing track.stop() on the tracks of an
 * existing stream. Giving different constraints to different calls might also
 * cause failure.
 *
 * Given that `getUserMedia()` could be called from multiple places for
 * different purposes and we do not really have a safe way to watch the
 * life-cycle of the all media streams, it's our responsibility to stop the
 * streams that we know diligently when they are no longer used.
 *
 * Also, before starting a new stream, it'd be safer to stop the existing
 * streams.
 *
 */
export function stopStream(stream: MediaStream) {
  stream.getTracks().forEach((track) => {
    track.stop();
    track.enabled = false;
  });
}

export const getCameraPermission = async (): Promise<CameraPermission> => {
  if (!navigator.permissions) {
    // new API, low browser support — https://caniuse.com/permissions-api
    return Promise.resolve('unknown');
  }

  const cameraPermission = await navigator.permissions
    // @ts-expect-error - TS2322 - Type '"camera"' is not assignable to type 'PermissionName'.
    .query({name: 'camera'})
    .then(({state}) => state)
    .catch((error) => 'unknown' as const);

  return cameraPermission;
};

export const hasCamera = async (): Promise<boolean> => {
  return new Promise(
    (
      resolve: (result: Promise<boolean> | boolean) => void,
      reject: (error?: any) => void,
    ) => {
      try {
        DetectRTC.load(async () => {
          resolve(supportsGetUserMedia() && DetectRTC.hasWebcam);
        });
      } catch (error: any) {
        reject(error);
      }
    },
  );
};

// Returns an array of dict listing the available cameras with devideId and camera WebRTC label.
export const getCameraList = async (
  backOnly: boolean,
): Promise<Array<Device>> => {
  try {
    if (!navigator.mediaDevices) {
      return [];
    }
    return navigator.mediaDevices.enumerateDevices().then((devices) =>
      devices
        .filter((device) => {
          return (
            device.kind === 'videoinput' &&
            (!backOnly || isBackCameraLabel(device.label))
          );
        })
        .map((device) => {
          return {deviceId: device.deviceId, label: device.label};
        })
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    );
  } catch (error: any) {
    handleException(error, 'error listing cameras');
    return [];
  }
};

// Returns true if the Stream comes from a device which
// reports on manual focus capabilities.
export const isStreamManualFocus = (
  stream: any,
): {
  isManualFocus: boolean;
  capabilities: any;
} => {
  const {capabilities} = getCapabilities(stream);
  if (capabilities) {
    const {focusMode} = capabilities;
    // todo(aywang): figure out how to strongly type this
    const isManualFocus =
      focusMode && focusMode.length === 1 && focusMode[0] === 'manual';
    return {isManualFocus, capabilities};
  }
  return {isManualFocus: false, capabilities: undefined};
};

// Returns the track capabilities for the stream playing on the webcam object.
export const getCapabilities = (
  stream: any,
): {
  capabilities?: any;
  error?: any;
} => {
  try {
    const track = head(stream.getVideoTracks());
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    const capabilities = track.getCapabilities();
    return {capabilities};
  } catch (error: any) {
    return {
      error: {
        name: error.name,
        message: error.message,
      },
    };
  }
};

export const getVideoConstraints = (
  availableDevices?: Array<Device> | null,
) => {
  // Use 1.5 ratio. US DL are 1.58, passport are 1.42, so take something in the middle
  const deviceId = head(availableDevices);
  let resizeMode = 'crop-and-scale';
  if (isMobileDevice()) {
    // Known cases that 'crop-and-scale' does not work.
    if (
      // Chrome 107+ on Android shows broken video in 'crop-and-scale'.
      (isAndroid() && getMajorChromeVersion() >= 107) ||
      // Samsung video driver has a bug when using resizeMode
      (isSamsungUA() && !isAndroid12OrAbove())
    ) {
      resizeMode = 'none';
    }

    return {
      facingMode: deviceId ? undefined : 'environment',
      resizeMode,
      width: 1440,
      height: 2160,
      deviceId: deviceId ? deviceId.deviceId : undefined,
    };
  } else {
    return {
      aspectRatio: 1.5,
      deviceId: deviceId ? deviceId.deviceId : undefined,
      height: 1080,
      resizeMode,
    };
  }
};

class NoWebRtcError extends Error {
  constructor() {
    super();
    this.name = 'NoWebRtcError';
  }
}

// The latest stream requested.
let userMediaServiceStream: MediaStream | null = null;

export const userMediaService = async (
  videoConstraints: any,
  callback: (arg1?: any) => void,
) => {
  // Ensure that we onluy call getUserMedia once at a time.
  // This method sets a window global that has all the callbacks
  // which want to be callbacked from getUserMedia.

  // @ts-expect-error - TS2339 - Property 'mediaServiceListenerList' does not exist on type 'Window & typeof globalThis'.
  if (window.mediaServiceListenerList === undefined) {
    // @ts-expect-error - TS2339 - Property 'mediaServiceListenerList' does not exist on type 'Window & typeof globalThis'.
    window.mediaServiceListenerList = [callback];
    // @ts-expect-error - TS2339 - Property 'mediaPromise' does not exist on type 'Window & typeof globalThis'.
    window.mediaPromise = new Promise(
      async (
        resolve: (result: Promise<undefined> | undefined) => void,
        reject: (error?: any) => void,
      ) => {
        try {
          Sentry.addBreadcrumb({
            category: 'WebRTC',
            message: `getUserMedia: constraints ${JSON.stringify(
              videoConstraints,
            )}`,
            level: Sentry.Severity.Info,
          });

          const mediaDevicesAPI = ensure(navigator.mediaDevices);

          // Stop the old stream before starting a new one.
          userMediaServiceStream && stopStream(userMediaServiceStream);
          userMediaServiceStream = null;

          const stream = await mediaDevicesAPI.getUserMedia({
            audio: false,
            video: videoConstraints,
          });

          userMediaServiceStream = stream;

          Sentry.addBreadcrumb({
            category: 'WebRTC',
            message: `getUserMedia: success: ${JSON.stringify(
              videoConstraints,
            )}`,
            level: Sentry.Severity.Info,
          });
          // @ts-expect-error - TS2339 - Property 'mediaServiceListenerList' does not exist on type 'Window & typeof globalThis'. | TS7006 - Parameter 'callback' implicitly has an 'any' type.
          window.mediaServiceListenerList.forEach((callback) =>
            callback(stream),
          );
          // @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
          resolve();
        } catch (err: any) {
          const {message, name} = err;
          Sentry.addBreadcrumb({
            category: 'WebRTC',
            message: `getUserMedia: failure: ${message || name || err}`,
            level: Sentry.Severity.Error,
          });
          reject(err);
        } finally {
          // @ts-expect-error - TS2339 - Property 'mediaServiceListenerList' does not exist on type 'Window & typeof globalThis'.
          window.mediaServiceListenerList = undefined;
        }
      },
    );
  } else {
    // @ts-expect-error - TS2339 - Property 'mediaServiceListenerList' does not exist on type 'Window & typeof globalThis'.
    window.mediaServiceListenerList.push(callback);
  }
  // Wait until getUserMedia returns.
  // @ts-expect-error - TS2339 - Property 'mediaPromise' does not exist on type 'Window & typeof globalThis'.
  await window.mediaPromise;
};

export function useUserVideo(videoConstraints: any): UserMedia {
  // this was a fork of https://www.npmjs.com/package/@vardius/react-user-media
  // that does not have an infinite render loop bug when stream is not available

  // it has been significantly reworked and stitched into our specific app context
  // on firefox, this will store a reference to the camera and error state using the
  // useCameraStreamState hook which is meant to attach these data to the app itself at a higher
  // level so that these references can be persisted and user will not be prompted for permissions multiple times.

  // other browsers have more lax permission models so we do not need to store refs. the reason why
  // we only store the camera and error state locally for other browsers though is that unlike
  // firefox they do NOT respect the enabled setting on video tracks and so the webcam active green
  // light will stay until the track is actually stopped.

  // sigh. . . its a mess.

  // for Firefox we pull the stream and error out of this hook which gets them from context set
  // higher up in the app state where they are persisted througout the session
  const [{stream: appStream, error: appError}, setCameraStreamState] =
    useCameraStreamState();

  // for other browsers we store the stream and error in state local to this hook so that we do not
  // retain references to them
  const [localStream, setLocalStream] = React.useState<any>(undefined);
  const [localError, setLocalError] = React.useState<Error | null | undefined>(
    undefined,
  );

  const isFirefox = isFirefoxUA();
  const [updateDeviceStatus] = useUpdateDeviceStatusMutation();

  React.useEffect(() => {
    const mounted = true;

    const handleSetError = (err: any) => {
      if (isFirefox) {
        setCameraStreamState &&
          setCameraStreamState({stream: undefined, error: err});
      } else {
        setLocalStream(undefined);
        setLocalError(err);
      }
    };

    const handleSetStream = (stream: any) => {
      if (isFirefox) {
        setCameraStreamState &&
          setCameraStreamState({stream, error: undefined});
      } else {
        setLocalStream(stream);
        setLocalError(undefined);
      }
    };

    const loadStream = async () => {
      Sentry.addBreadcrumb({
        category: 'WebRTC',
        message: `loading video stream- constracts ${JSON.stringify(
          videoConstraints,
        )}`,
        level: Sentry.Severity.Info,
      });
      try {
        if (supportsGetUserMedia()) {
          await userMediaService(videoConstraints, (stream) => {
            Sentry.addBreadcrumb({
              category: 'WebRTC',
              message: `video Stream- ismounted: ${
                mounted ? 'Mounted' : 'Unmounted'
              }`,
              level: Sentry.Severity.Info,
            });
            if (mounted) {
              handleSetStream(stream);
            }
          });
        } else {
          throw new NoWebRtcError();
        }
      } catch (err: any) {
        if (mounted) {
          analytics.track('webcamError', {
            errorName: err.name,
            message: err.message,
            reason: errorToReason(err),
            location: 'userMedia',
          });
          if (errorToReason(err) === 'unknown') {
            // we handle many / most errors gracefully but if we encounter one we don't know what to
            // do with log it to sentry
            handleException(err, 'Error: getUserMedia');
          }
          handleSetError(err);
        }
      }
    };

    if (appStream || localStream) {
      appStream &&
        appStream.getVideoTracks().map((track) => (track.enabled = true));
    } else if (appError || localError) {
      const reason = errorToReason(appError || localError);
      updateDeviceStatus({
        variables: {
          deviceData: {
            supported: false,
            reason,
          },
        },
      });
    } else {
      loadStream();
    }
  }, [
    appError,
    appStream,
    isFirefox,
    localError,
    localStream,
    setCameraStreamState,
    updateDeviceStatus,
    videoConstraints,
  ]);
  return React.useMemo(() => {
    return isFirefox
      ? {stream: appStream, error: appError}
      : {stream: localStream, error: localError};
  }, [appError, appStream, isFirefox, localError, localStream]);
}
