import {createViewConfig} from '@sail/engine';
import type {PopoverProps} from './Popover';

export const PopoverConfig = createViewConfig({
  props: {} as PopoverProps,
  name: 'Popover',
  defaults: {
    placement: 'bottom',
    floatingStrategy: 'absolute',
    dismissible: true,
    showOnPress: false,
    showOnFocus: false,
    showOnHover: false,
  },
});
