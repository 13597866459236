import {documentSvg as Icon__small__documentSvg} from '../Icon__small/Icon__small--document.js'
import {documentSvg as Icon__xsmall__documentSvg} from '../Icon__xsmall/Icon__xsmall--document.js'

export var document = {
  name: 'document',
  category: 'icon',
  colorable: true,
  svg: Icon__small__documentSvg,
  alt: {
    Icon__small: Icon__small__documentSvg,
    Icon__xsmall: Icon__xsmall__documentSvg
  },
  combined: ['Icon__small', 'Icon__xsmall']
};
