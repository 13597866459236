import {getActiveRule} from './rule';
import {serialize} from './serialize';
import {convertAtRuleToRuleset} from './utils';
import type {StylisElement, StylisMiddleware} from './types';

export interface Layer {
  layers: Record<string, Layer>;
  order: string[];
  rulesets: string[];
}

const DELIMITER = '.';

// https://drafts.csswg.org/css-cascade-5/#layer-names
const activeLayerName = [] as string[];
export function getActiveLayerName(): string[] {
  return activeLayerName.slice();
}

function getLayerName(str: string) {
  return str.split(DELIMITER).map((s) => s.trim());
}

export function createLayer(): Layer {
  return {
    layers: {},
    order: [],
    rulesets: [],
  };
}

function getLayerForSegments(layer: Layer, segments: string[]): Layer {
  let currentLayer = layer;
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    if (!currentLayer.layers[segment]) {
      currentLayer.layers[segment] = createLayer();
      currentLayer.order.push(segment);
    }
    currentLayer = currentLayer.layers[segment];
  }
  return currentLayer;
}

export function getActiveLayer(): Layer {
  const rule = getActiveRule();
  const layerName = getActiveLayerName();
  return getLayerForSegments(rule.layer, layerName);
}

export function conditionLayer(
  element: StylisElement,
  _index: number,
  _elements: (StylisElement | string)[],
  callback: StylisMiddleware,
): void {
  if (!element.children?.length) {
    // This is a comma-separated list of layer names to specify layer order.
    const activeLayer = getActiveLayer();

    // stylis discards the final element in the list for some reason, so we
    // reconstruct the props ourselves
    element.props = element.value
      .replace(/^@layer/, '')
      .replace(/;$/, '')
      .split(',')
      .map((s) => s.trim());

    for (let i = 0; i < element.props.length; i++) {
      const segments = getLayerName(element.props[i]);
      getLayerForSegments(activeLayer, segments);
    }
    return;
  }

  const layerElements = [element] as StylisElement[];
  const segments = getLayerName(element.props[0]);
  // Ensure the layer is added to the order
  getLayerForSegments(getActiveLayer(), segments);
  try {
    activeLayerName.push(...segments);
    convertAtRuleToRuleset(element, 0, layerElements);
    serialize(layerElements, callback);
  } finally {
    activeLayerName.splice(-1 * segments.length, segments.length);
  }

  // Clear out the element so we don’t process its contents twice.
  element.children = [];
  element.return = '';
}
