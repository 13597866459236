import * as Sentry from '@sentry/browser';
import {Dedupe} from '@sentry/integrations';

import analytics from 'gelato/frontend/src/lib/analytics';
import {getConfigValue} from 'gelato/frontend/src/lib/config';
import {reportMetric} from 'gelato/frontend/src/lib/metricsBatcher';
import {isSessionExpired} from 'gelato/frontend/src/lib/sessionError';
import Storage from 'gelato/frontend/src/lib/Storage';

// List of soft errors that should be reported to Sentry as warning.
const SOFT_ERRORS: RegExp[] = [
  /Invalid video readyState/,
  /Video is pause/,
  /Video stream contains no active track/,
  /Video stream contains no tracks/,
  /Video track is muted/,
  /Video track not found/,
  /video element not found/,
  /video has no stream/,
];

export const IGNORED_ERRORS: RegExp[] = [
  /ResizeObserver loop limit exceeded/,
  /ResizeObserver loop completed with undelivered notifications/,
  /is not a child of this node/,
  /i.measureText/,
  /WebGL+WASM not supported/,
  // Error in react rendering autocapture page. We don't understand why this happens
  // but it doesn't seem to negatively affect anything
  /Failed to execute 'removeChild'/,
  /Failed to execute 'insertBefore' on 'Node'/,

  // Local network errors that should be ignored
  // Happens when network fails loading async JS chunks
  /Loading chunk .* failed/,
  /The network connection was lost/,
  /Loading CSS chunk .* failed/,
  /TypeError: Load failed/,

  // Network error when loading MB WASM
  /NetworkError for/,
  /Failed to fetch/,

  // 'NetworkError when attempting to fetch resource.' unhandled rejections
  /TypeError: NetworkError when attempting to fetch resource./,

  // When a user cancels the network, probably by reloading page or navigating away.
  /AbortError/,

  // Thrown by Microblink- we have no control over this, so for the time being
  // don't report.
  /DataCloneError/,

  // https://jira.corp.stripe.com/browse/RUN_DASHPLAT-2060
  /TypeError: cancelled/,
  /TypeError: Abgebrochen/,
  /TypeError: cancelado/,
  /TypeError: 취소됨/,
  /TypeError: annulé/,
  /TypeError: annullato/,
  /TypeError: anulat/,
  /TypeError: geannuleerd/,
  /TypeError: 網絡連線中斷。/,
  /TypeError: avbruten/,
  /TypeError: kumottu/,
  /TypeError: отменено/,
  /TypeError: zrušené/,

  // 'Failed to fetch' unhandled rejections
  /TypeError: Failed to fetch/,
  /TypeError: Load failed/,

  // 'cannot parse response' unhandled rejections
  /TypeError: cannot parse response/,
  /TypeError: impossible d’analyser la réponse/,
  /TypeError: impossibile analizzare la risposta/,
  /TypeError: 応答を解析できません/,
  /TypeError: Parsen der Antwort nicht möglich./,
  /TypeError: не удается произвести анализ ответа/,

  // 'NetworkError when attempting to fetch resource.' unhandled rejections
  // Handles cases where original error message is wrapped in another string
  /.*NetworkError when attempting to fetch resource.*/,

  // Occurs in Safari when a TCP connection fails while the request was in progress
  /TypeError: The network connection was lost./,
  /TypeError: La conexión de red se perdió./,
  /TypeError: la connessione è stata persa./,
  /TypeError: La connexion réseau a été perdue./,
  /TypeError: Die Netzwerkverbindung wurde unterbrochen./,
  /TypeError: Połączenie sieciowe zostało przerwane./,

  // No internet seems inactionable.
  /TypeError: The internet connection appears to be offline./,
  /TypeError: The Internet connection appears to be offline./,

  // This error most commonly happens in Mobile Safari.
  // It has happened a few times a day across projects for years
  // and has not been linked to a real isssue.
  /NotFoundError: The object can not be found here./,

  // Most of these happen on Firefox iOS 14 (released Nov 2018)
  // We don't have any references to 'webkit.messageHandlers' in our JS code.
  /undefined is not an object \(evaluating '(window\.)?webkit\.messageHandlers.*/,

  // All of these happen on Safari 12.0 and Mobile Safari 12.0 (released Sep 2018)
  // We don't have any references to 'ceCurrentVideo.currentTime' in our JS code.
  // We don't have any references to 'n.length' in our JS code and it does not seem to impact the experience
  /undefined is not an object (evaluating 'ceCurrentVideo.currentTime')/,
  /undefined is not an object (evaluating 'n.length')/,
];

export const denylistUrls: Array<string | RegExp> = [
  /draft-js\/lib\/.*/,
  /draft-js\/node_modules\/.*/,
  /.*moz-extension.*/,
  /.*safari-extension.*/,
  /.*chrome-extension.*/,
  /\/\/www\.google-analytics\.com.*/,
  // These appear to be just from browser plugins
  /<anonymous>/,
  /EventEmitter.<anonymous>.*/,

  'file*',
  /\?\(blob:.*/,
  /\?\(undefined\)/,
  /dev\.stripe\.me/,
  /localhost:3000/,
];

/**
 * Whether the error is a soft error that should be reported to Sentry as
 * warning.
 */
const isSoftError = (error: any): boolean => {
  const message = error instanceof Error ? error.message : String(error);
  return SOFT_ERRORS.some((re) => re.test(message));
};

export const isIgnoredError = (err: any): boolean => {
  const error = err instanceof Error ? err : new Error(err);
  return IGNORED_ERRORS.some(
    (regex) =>
      regex.test(error.message) ||
      regex.test(error.name) ||
      regex.test(`${error.name}: ${error.message}`),
  );
};

const hasSessionStorage = (function checkSessionStorage() {
  try {
    // Check if we update the session storage successfully.
    window.sessionStorage?.setItem('test', 'sentry');
    window.sessionStorage?.removeItem('test');
    return !!window.sessionStorage;
  } catch (ex) {
    // DOMException could be raised.
    // This can happen in Safari private browsing model, or it could be
    // the app is running in an iframe with a different origin with
    // ingonito mode.
    return false;
  }
})();

const hasLocalStorage = (function checkLocalStorage() {
  try {
    // Check if we update the session storage successfully.
    window.localStorage?.setItem('test', 'sentry');
    window.localStorage?.removeItem('test');
    return !!window.localStorage;
  } catch (ex) {
    // DOMException could be raised.
    // This can happen in Safari private browsing model, or it could be
    // the app is running in an iframe with a different origin with
    // ingonito mode.
    return false;
  }
})();

function trackSentryError(event: Sentry.Event, hint?: Sentry.EventHint): void {
  // Attempt to find the exception that triggered this.
  let exception = hint?.originalException || hint?.syntheticException;
  if (!exception) {
    return;
  }
  if (typeof exception === 'string') {
    exception = new Error(exception);
  }

  const {graphQLErrors, networkError} = exception as Error & {
    graphQLErrors?: Array<{originalError?: Error | undefined}>;
    networkError?: {name: string; statusCode: number; message: string};
  };

  let statusCode: number | string = '';

  if (networkError) {
    statusCode = networkError.statusCode;
    // Only track error with HTTP status code between 400 ~ 599.
    // The rest would be ignored.
    if (statusCode >= 400 && statusCode < 600) {
      exception = networkError;
    } else {
      return;
    }
  } else if (graphQLErrors && graphQLErrors[0]) {
    exception = graphQLErrors[0].originalError || exception;
  }

  let cause = '';
  if (exception instanceof Error && exception.cause) {
    cause = `- ${String(exception.cause)}`;
  }

  if (event.tags?.errorCategory === 'uncaughtError') {
    try {
      reportMetric({
        metric: 'gelato_frontend_uncaught_exception',
        operation: 'count',
        value: 1,
        storytime: [
          {
            key: 'errorName',
            value: event.event_id || '',
          },
          {
            key: 'errorMessage',
            value: event.message || '',
          },
        ],
      });
    } catch {}
  }

  analytics.track('sentryError', {
    sentryError: `${exception.name}:${statusCode} ${exception.message}${cause}`,

    // We'd like to know if the app is at the background when the error happens.
    visibilityState: document.visibilityState,
    hasSessionStorage,
    hasLocalStorage,
  });
}

class MetricsReporterIntegration {
  static id: string;

  static paused: boolean;

  name: string;

  errorWhileReporting: boolean;

  constructor() {
    this.name = 'MetricsReporterIntegration';
    this.errorWhileReporting = false;
  }

  setupOnce() {
    // @ts-expect-error - TS2345 - Argument of type '(event: Event) => Event | undefined' is not assignable to parameter of type 'EventProcessor'.
    Sentry.addGlobalEventProcessor((event, hint) => {
      const self = Sentry.getCurrentHub().getIntegration(
        MetricsReporterIntegration,
      );

      if (!self || MetricsReporterIntegration.paused) {
        return event;
      }
      // If an error occurs while reporting metrics, just stop
      // reporting error metrics, otherwise we may recurse.
      if (this.errorWhileReporting) {
        return;
      }

      const error = hint?.originalException || hint?.syntheticException;
      let errorTag;
      if (error instanceof Error) {
        errorTag = getErrorTag(error);
      } else if (error && typeof error === 'string') {
        errorTag = getErrorTag(new Error(error));
      } else {
        errorTag = 'unknown';
      }

      // Track the first Sentry event (per page load) - we care what % of users
      // run into an exception, not the total # of exceptions generally.
      const firstEvent =
        typeof window !== 'undefined' &&
        // @ts-expect-error - TS2339 - Property 'reportedSentryEvent' does not exist on type 'Window & typeof globalThis'.
        typeof window.reportedSentryEvent === 'undefined';
      reportMetric({
        metric: 'gelato_frontend_errors_sentry',
        operation: 'count',
        value: 1,
        tags: [
          {key: 'error_tag', value: errorTag},
          {key: 'first_sentry_event', value: firstEvent ? 'true' : 'false'},
        ],
      });
      if (firstEvent) {
        // @ts-expect-error - TS2339 - Property 'reportedSentryEvent' does not exist on type 'Window & typeof globalThis'.
        window.reportedSentryEvent = true;
      }
      return event;
    });
  }

  static withPaused(paused: boolean | null | undefined, callback: () => any) {
    const previousState = this.paused;
    try {
      this.paused = !!paused;
      callback();
    } finally {
      this.paused = previousState;
    }
  }
}

MetricsReporterIntegration.id = 'MetricsReporterIntegration';

const MAX_ERRORS_PER_MESSAGE = 2;
// tracks error count by message to prevent spamming detectors from a single client.
// We only send errors with the same messasge twice - after that no error reports are sent.
class LimitErrorsPerMessageIntegration {
  static id: string;

  static paused: boolean;

  name: string;

  errorCountByMessage;

  constructor() {
    this.name = 'LimitErrorsPerMessageIntegration';
    this.errorCountByMessage = {};
  }

  setupOnce() {
    // @ts-expect-error - TS2345 - Argument of type '(event: Event) => Event | undefined' is not assignable to parameter of type 'EventProcessor'.
    Sentry.addGlobalEventProcessor((event) => {
      // Pull the exception message out from the first exception if present.
      // We track how many reports with this message we've sent, and limit
      // to 2 per message.
      const exception = event.exception;
      if (!exception || !exception.values || exception.values.length === 0) {
        return event;
      }
      const message = exception.values[0].value;
      if (!message) {
        return event;
      }

      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      const currentCount = this.errorCountByMessage[message] || 0;

      if (currentCount >= MAX_ERRORS_PER_MESSAGE) {
        return;
      }

      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      this.errorCountByMessage[message] = currentCount + 1;

      return event;
    });
  }

  static withPaused(paused: boolean | null | undefined, callback: () => any) {
    const previousState = this.paused;
    try {
      this.paused = !!paused;
      callback();
    } finally {
      this.paused = previousState;
    }
  }
}

LimitErrorsPerMessageIntegration.id = 'LimitErrorsPerMessageIntegration';

Sentry.init({
  release: getConfigValue('COMMIT_HASH'),
  dsn: getConfigValue('SENTRY_URL'),
  environment: 'Production', // In development config, sentryURL is empty (points nowhere)
  attachStacktrace: true,
  maxBreadcrumbs: 60, // Increase from 30 default.
  ignoreErrors: IGNORED_ERRORS,
  integrations: [
    // Don't try and send errors from development- they just fail
    // and SPAM the console
    new Sentry.Integrations.InboundFilters({
      blacklistUrls: denylistUrls,
    }),
    new Sentry.Integrations.FunctionToString(),
    new Sentry.Integrations.TryCatch(),
    new Sentry.Integrations.Breadcrumbs(),
    new Sentry.Integrations.GlobalHandlers({
      onerror: true,
      onunhandledrejection: true,
    }),
    new Sentry.Integrations.LinkedErrors(),
    new Sentry.Integrations.UserAgent(),
    new Dedupe(),
    new MetricsReporterIntegration(),
    new LimitErrorsPerMessageIntegration(),
  ],
  beforeSend(event: Sentry.Event, hint?: Sentry.EventHint) {
    if (isSessionExpired()) {
      return null;
    }
    trackSentryError(event, hint);
    return event;
  },
  beforeBreadcrumb(
    breadcrumb: Sentry.Breadcrumb,
    hint?: Sentry.BreadcrumbHint,
  ) {
    const {category} = breadcrumb;

    // Record element ID if present into breadcrumb clicks
    if (category && hint && category.startsWith('ui') && hint.event) {
      const id = hint?.event?.target?.id;
      if (id) {
        breadcrumb.message = `Clicked on ${id}`;
      }
    }

    return breadcrumb;
  },
});

Sentry.configureScope((scope) => {
  // 'error' is the implicit default that sentry uses, but we're setting it
  // here as the explicit default so that integrations like
  // `MetricsReporterIntegration` have reliable access to the level per
  // event.
  // @ts-expect-error - TS2345 - Argument of type '"error"' is not assignable to parameter of type 'Severity'.
  scope.setLevel('error');
});

export type SentryLevel = 'info' | 'warning' | 'error';

export type SentryOptions = {
  level?: SentryLevel;
  tags?: {
    [key: string]: string;
  };
  extra?: any;
  pauseMetrics?: boolean;
};

export const makeSafe = (extra?: any) => {
  if (extra) {
    try {
      return JSON.parse(JSON.stringify(extra));
    } catch (e: any) {
      return {
        // If you see this in a sentry, it means you attempted to pass it data that caused an exception
        // when attempting serialization (like a circular reference).
        // Instead of failing to send the sentry entirely, we'll still let it through but with the
        // non-serializable extra stripped and replaced with this.
        bad_extra: 'Extra data could not be serialized',
      };
    }
  }
  return extra;
};

const handleExceptionInternal = (
  message: string,
  options: SentryOptions = {},
  error?: any,
) => {
  const {tags, extra} = options;
  let {level} = options;

  Sentry.withScope((scope) => {
    // ResizeLoop error is a known problem- don't report.
    const isResizeLoopError =
      message && message.includes('ResizeObserver loop');

    // Don't report WebGL/WASM errors- we know some platforms don't support
    // and track via an explicit signalFX stat.
    const isWasmError =
      (error && error.message === 'WebGL+WASM not supported') ||
      (message && message === 'WebGL+WASM not supported');
    if (isResizeLoopError || isWasmError) {
      return;
    }

    if (!level) {
      level = isSoftError(error) ? 'warning' : 'error';
    }

    // @ts-expect-error - TS2345 - Argument of type 'SentryLevel' is not assignable to parameter of type 'Severity'.
    scope.setLevel(level);
    scope.setTag('error_level', level);
    scope.setTag('error_message', message);

    try {
      scope.setTag('platformName', Storage.getPlatformName());
      scope.setTag('lastSessionState', Storage.getSessionTracking());
      // Set last 4 chars of the EAK so we can track which session is having the error
      let token = Storage.getSessionAPIKey();
      if (token) {
        token = token.slice(-4);
      }
      // We'd like to know if the app is at the background when the error happens.
      scope.setTag('visibilityState', document.visibilityState);
      scope.setTag('hasSessionStorage', hasSessionStorage);
      scope.setTag('hasLocalStorage', hasLocalStorage);
      scope.setTag('eak4', token);
    } catch (err: any) {
      // Oh well, something broken with storage
      scope.setTag('storageError', err);
    }

    if (tags) {
      Object.keys(tags).forEach((key) => {
        scope.setTag(key, tags[key]);
      });
    }

    const safeExtra = makeSafe(extra);
    if (safeExtra) {
      Object.keys(safeExtra).forEach((key) => {
        scope.setExtra(key, safeExtra[key]);
      });
    }
    MetricsReporterIntegration.withPaused(true, () => {
      if (error) {
        Sentry.captureException(error);
      } else {
        Sentry.captureMessage(message);
      }
    });
  });
};

// Reports messages to Sentry.
export const captureMessage = (
  message: string,
  options: SentryOptions = {},
) => {
  handleExceptionInternal(message, options);
};

// Reports Exceptions to Sentry.
export const handleException = (
  error: any,
  message: string,
  options: SentryOptions = {},
) => {
  // Don't report any errors when a session is expired. These are uniformly uninformative.
  if (!isSessionExpired()) {
    handleExceptionInternal(message, options, error);
  }
};

export const handleUncaughtException = (
  error: Error,
  message: string,
  options: SentryOptions = {},
) => {
  const {tags, ...others} = options;
  // Don't report any errors when a session is expired. These are uniformly uninformative.
  if (!isSessionExpired()) {
    handleExceptionInternal(
      message,
      {
        ...others,
        tags: {
          ...tags,
          errorCategory: 'uncaughtError',
        },
      },
      error,
    );
  }
};

/**
 * Get the tag for the error. The tag can be used to search for the error in
 * Grafana.
 * @param error The error object.
 * @returns The error tag.
 */
export const getErrorTag = (error: Error) => {
  const {name, message} = error;
  if (name !== 'Error') {
    // If the error has custom name, use it.
    return name;
  }
  const errorCodePattern = /app_error_([a-z_])+/;
  const matches = message.match(errorCodePattern);
  if (matches && matches[0]) {
    return matches[0];
  }
  // Error message is not an error code, use the first 20 characters of the
  // message as the error tag.
  return message
    .toLowerCase()
    .substring(0, 20)
    .replace(/[^a-z\d]/g, '_')
    .replace(/_+/g, '_');
};

// Add a breadcrumb on first load that has the URL.
// This way we can track if users are starting at pages that aren't /start/token
Sentry.addBreadcrumb({
  category: 'navigation',
  message: 'first load',
  level: Sentry.Severity.Info,
  data: {
    url: typeof window !== 'undefined' ? window.location.href : 'NO WINDOW',
  },
});
