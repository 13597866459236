import {css, cssPluginResetStyles} from '../core';
import type {
  CssNestedProperties,
  CssProperties,
  CssValue,
  CssSelectorIndexer,
} from '../core';
import {isCssValue} from '../core/value';
import {PROPERTY_PREFIX} from '../../../style/createCustomProperty';

export type Distribute = 'packed' | 'space-between';
export type AlignX = 'start' | 'center' | 'end' | 'stretch';
export type AlignY = 'top' | 'center' | 'baseline' | 'bottom' | 'stretch';
export type Layout =
  | 'column'
  | 'row'
  | 'inline'
  | 'inline-column'
  | 'inline-row';

export type Axis = 'x' | 'y' | 'z';
export type Wrap = 'wrap' | 'nowrap' | 'wrap-reverse';

const flexAlignXMap = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  stretch: 'stretch',
} as const;

const flexAlignYMap = {
  top: 'flex-start',
  center: 'center',
  baseline: 'baseline',
  bottom: 'flex-end',
  stretch: 'stretch',
} as const;

type GapValue = CssProperties['gap'];

export interface GapResult {
  [key: `--${string}`]: GapValue;
  gap?: GapValue;
}

export interface GapPlugin {
  (value: GapValue, selector: CssSelectorIndexer): GapResult;
}

const gapReset: CssProperties = {
  '--row-gap': css.value('normal'),
  '--column-gap': css.value('normal'),
  gap: css.value('var(--row-gap) var(--column-gap)'),
};

// TODO(koop): Need to somehow add a modifier to transform the tree when gap
// is unsupported.
function createGapPlugin(...customProperties: string[]): GapPlugin {
  return function gapPlugin(value, selector) {
    const properties = customProperties.map((customProperty) => ({
      [customProperty]: value,
    }));
    return Object.assign(
      {},
      cssPluginResetStyles(selector, gapReset),
      ...properties,
    );
  };
}

function distribute(value: Distribute): CssNestedProperties<CssProperties> {
  if (value === 'space-between') {
    return {
      '--distribute-x': css.value(`var(--when-flex-x) space-between`),
      '--distribute-y': css.value(`var(--when-flex-y) space-between`),
      '& > *': {
        '--flex-x': css.value(`var(--when-flex-x) initial`),
        '--flex-y': css.value(`var(--when-flex-y) initial`),
      },
    };
  }
  // use the default browser styling (typically stretch)
  return {};
}

function wrap(
  value: Wrap,
): CssNestedProperties<Pick<CssProperties, 'flexWrap'>> {
  return {flexWrap: css.value(value)};
}

type SizeKey =
  | 'width'
  | 'height'
  | 'minWidth'
  | 'minHeight'
  | 'maxWidth'
  | 'maxHeight';

type SizeTokens =
  /**
   * Can use this token to set an remove an element's assigned size, instead applying the browser's default.
   */
  'auto';

export type SizeValue = CssProperties[SizeKey];

export interface SizePlugin {
  (value: SizeValue | SizeTokens): CssProperties;
}

/**
 * Calculates if a fractional size needs to be adjusted by the current flex gap
 * and returns an adjusted size value.
 */
const applySizeOffset = (sizeValueOrVariable: SizeValue, axis: Axis) => {
  let size = sizeValueOrVariable;

  const [, numerator, denominator] = (
    `${size}`.match(/(\d+)\\?\/(\d+)/) || []
  ).map(Number);

  // if size is a percentage, return a calculated value that reduces
  // the size proportionally to account for gaps applied on that axis.
  if (numerator && denominator) {
    const percentage = Number((numerator / denominator) * 100).toFixed(6);
    const proportionalGap = (denominator - numerator) / denominator;
    const gapVar = axis === 'x' ? '--column-gap' : '--row-gap';

    size = css.value(
      `calc(${percentage}% - (var(${gapVar}, 0px) * ${proportionalGap}))`,
    );
  }

  return size;
};

function createSizePlugin(
  property: SizeKey,
  axis: Exclude<Axis, 'z'>,
): SizePlugin {
  return function sizePlugin(value) {
    if (value === 'auto' || value?.value === 'auto') {
      // "auto" is a special case, as unlike other explicit size values,
      // we want the item to continue to flex-grow
      return {[property]: css.value('auto')};
    }

    const size = applySizeOffset(value, axis);

    return {
      [property]: size,
      [`--flex-${axis}`]: css.value('initial'),
      // NOTE(koop): Ensure compatibility with beta CSS APIs, change with care.
      [`--${PROPERTY_PREFIX}flex-${axis}`]: css.value('0 1 auto'),
    };
  };
}

const inflexibleX: CssNestedProperties<CssProperties> = {
  '& > *': {
    '--flex-x': css.value('initial'),
    // NOTE(koop): Ensure compatibility with beta CSS APIs, change with care.
    [`--${PROPERTY_PREFIX}flex-x` as '--property']: css.value('0 1 auto'),
  },
};

const inflexibleY: CssNestedProperties<CssProperties> = {
  '& > *': {
    '--flex-y': css.value('initial'),
    // NOTE(koop): Ensure compatibility with beta CSS APIs, change with care.
    [`--${PROPERTY_PREFIX}flex-y` as '--property']: css.value('0 1 auto'),
  },
};

function alignX(value: AlignX): CssNestedProperties<CssProperties> {
  const flexValue = flexAlignXMap[value];
  // any value other than stretch disables default flex grow/shrink behavior
  const reset = flexValue === 'stretch' ? {} : inflexibleX;
  return {
    '--align-x': css.value(flexValue),
    ...reset,
  };
}

function alignY(value: AlignY): CssNestedProperties<CssProperties> {
  const flexValue = flexAlignYMap[value];
  // any value other than stretch disables default flex grow/shrink behavior
  const reset = flexValue === 'stretch' ? {} : inflexibleY;
  return {
    '--align-y': css.value(flexValue),
    ...reset,
  };
}

function alignSelfX(value: AlignX): {'--align-self-x'?: CssValue<string>} {
  const flexValue = flexAlignXMap[value];
  return {'--align-self-x': css.value(flexValue)};
}

function alignSelfY(value: AlignY): {'--align-self-y'?: CssValue<string>} {
  const flexValue = flexAlignYMap[value];
  return {'--align-self-y': css.value(flexValue)};
}

const rowDependencies: CssProperties = {
  width: css.value('100%'),
};

// stacked items are full width by default (via flex grow)
const resetStack: CssNestedProperties<CssProperties> = {
  '--distribute-x': css.value('initial'),
  '--distribute-y': css.value('initial'),
  '--align-x': css.value('initial'),
  '--align-y': css.value('initial'),
  '& > *': {
    '--align-self-x': css.value('initial'),
    '--align-self-y': css.value('initial'),
    '--flex-x': css.value('1 1 auto'),
    '--flex-y': css.value('1 1 auto'),
  },
};

function stack(value: Axis): CssNestedProperties<CssProperties> {
  switch (value) {
    case 'x':
      return {
        display: css.value('flex'),
        flexDirection: css.value('row'),
        alignItems: css.value('var(--distribute-y, var(--align-y))'),
        justifyContent: css.value('var(--distribute-x, var(--align-x))'),

        // expose that we're flexing on the x-axis
        '--when-flex-x': css.value(' '),
        '--when-flex-y': css.value('initial'),

        '& > *': {
          flex: css.value('var(--flex-x)'),
          alignSelf: css.value('var(--align-self-y)'),
          justifySelf: css.value('var(--align-self-x)'),
        },
      };
    case 'y':
      return {
        display: css.value('flex'),
        flexDirection: css.value('column'),
        alignItems: css.value('var(--distribute-x, var(--align-x))'),
        justifyContent: css.value('var(--distribute-y, var(--align-y))'),

        // expose that we're flexing on the y-axis
        '--when-flex-y': css.value(' '),
        '--when-flex-x': css.value('initial'),

        '& > *': {
          flex: css.value('var(--flex-y)'),
          alignSelf: css.value('var(--align-self-x)'),
          justifySelf: css.value('var(--align-self-y)'),
        },
      };
    case 'z':
      return {
        display: css.value('grid'),
        alignItems: css.value('var(--align-y)'),
        justifyItems: css.value('var(--align-x)'),
        '& > *': {
          alignSelf: css.value('var(--align-self-y)'),
          justifySelf: css.value('var(--align-self-x)'),
          gridColumn: css.value('1 / auto'),
          gridRow: css.value('1 / auto'),
        },
      };
    case null:
    case undefined:
      return {};
    default:
      // eslint-disable-next-line no-console
      console.warn('Unexpected layout');
      return {};
  }
}

const tileReset: CssNestedProperties<CssProperties> = {
  '--col-repeat': css.value('initial'),
  '--align-x': css.value('initial'),
  '--align-y': css.value('initial'),
  gridTemplateColumns: css.value(
    'repeat(var(--col-repeat, auto-fill), var(--col-width))',
  ),
  alignItems: css.value('var(--align-y)'),
  justifyItems: css.value('var(--align-x)'),

  '& > *': {
    '--align-self-x': css.value('initial'),
    '--align-self-y': css.value('initial'),
    alignSelf: css.value('var(--align-self-y)'),
    justifySelf: css.value('var(--align-self-x)'),
  },
};

function minTileWidth(
  value: SizeValue,
  selector: CssSelectorIndexer,
): CssNestedProperties<CssProperties> {
  const trackSize = applySizeOffset(value, 'x');
  return {
    '--col-width': css.value(`minmax(min(${trackSize}, 100%), 1fr)`),
    ...cssPluginResetStyles(selector, tileReset),
  };
}

function gridColumns(value: number): CssNestedProperties<CssProperties> {
  return {'--col-repeat': css.value(value)};
}

function createStackPlugin(): (
  value: Axis,
  selector: CssSelectorIndexer,
) => CssNestedProperties<CssProperties> {
  return function stackPlugin(value, selector) {
    return {
      ...cssPluginResetStyles(selector, resetStack),
      ...stack(value),
    };
  };
}

export type DisplayValue = CssProperties['display'] | 'grid';

function display(value: DisplayValue): CssNestedProperties<CssProperties> {
  const cssValue = isCssValue(value) || !value ? value : css.value(value);
  return {display: cssValue};
}

function layout(
  value: Layout,
  selector: CssSelectorIndexer,
): CssNestedProperties<
  Pick<
    CssProperties,
    | 'alignItems'
    | 'alignSelf'
    | 'display'
    | 'flexDirection'
    | 'justifyContent'
    | 'justifySelf'
  >
> {
  if (value === 'inline') {
    return {display: css.value('inline')};
  }

  const isInline = value === 'inline-row' || value === 'inline-column';
  const display = isInline ? 'inline-flex' : 'flex';
  switch (value) {
    case 'row':
    case 'inline-row':
      return {
        ...(isInline ? {} : cssPluginResetStyles(selector, rowDependencies)),
        display: css.value(display),
        flexDirection: css.value('row'),
        alignItems: css.value('var(--align-y)'),
        justifyContent: css.value('var(--align-x)'),
        '& > *': {
          alignSelf: css.value('var(--align-self-y)'),
          justifySelf: css.value('var(--align-self-x)'),
        },
      };
    case 'column':
    case 'inline-column':
      return {
        display: css.value(display),
        flexDirection: css.value('column'),
        alignItems: css.value('var(--align-x)'),
        justifyContent: css.value('var(--align-y)'),
        '& > *': {
          alignSelf: css.value('var(--align-self-x)'),
          justifySelf: css.value('var(--align-self-y)'),
        },
      };
    case null:
    case undefined:
      return {};
    default:
      // eslint-disable-next-line no-console
      console.warn('Unexpected layout');
      return {};
  }
}

export const pluginsLayout = {
  alignSelfX,
  alignSelfY,
  alignX,
  alignY,
  gapX: createGapPlugin('--column-gap'),
  gapY: createGapPlugin('--row-gap'),
  gap: createGapPlugin('--column-gap', '--row-gap'),
  layout,
  stack: createStackPlugin(),
  distribute,
  wrap,
  width: createSizePlugin('width', 'x'),
  minWidth: createSizePlugin('minWidth', 'x'),
  maxWidth: createSizePlugin('maxWidth', 'x'),
  height: createSizePlugin('height', 'y'),
  minHeight: createSizePlugin('minHeight', 'y'),
  maxHeight: createSizePlugin('maxHeight', 'y'),
  minTileWidth,
  gridColumns,
  display,
};
