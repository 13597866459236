import * as Sentry from '@sentry/browser';

import getApolloClientOnlyForMetrics from 'gelato/frontend/src/components/getApolloClientOnlyForMetrics';
import {recordMetricsMutation} from 'gelato/frontend/src/graphql/mutations/useRecordMetricsMutation';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import {isSessionError} from 'gelato/frontend/src/lib/sessionError';
import Storage from 'gelato/frontend/src/lib/Storage';
import {
  getMajorMinorOsVersion,
  getPlatform,
  isIOSUA,
} from 'gelato/frontend/src/lib/userAgent';

import type {MetricPayload} from '@stripe-internal/data-gelato/schema/types';

const FLUSH_MS = 4000;

const batchData = {metrics: []};
let metricsErrorCount = 0;

// Low cardinality tags that apply to all metrics
const defaultTags = [{key: 'platform', value: getPlatform()}];

// Track minor version on iOS only. We ignore patch value since it would result in high cardinality tags
if (isIOSUA()) {
  defaultTags.push({key: 'ios_version', value: getMajorMinorOsVersion()});
}

export const reportMetricWithExceptionHandling = (payload: MetricPayload) => {
  try {
    reportMetric(payload);
  } catch (err: any) {
    if (err.name !== 'NetworkError') {
      handleException(err, 'Error reporting metric', {
        tags: {
          metric: payload.metric,
        },
      });
    }
  }
};

export const reportMetric = (payload: MetricPayload) => {
  // Add default tags if not already present
  const tags = [...(payload.tags || [])];
  defaultTags.forEach((defaultTag) => {
    const existingTag = tags.find((t) => t.key === defaultTag.key);
    if (!existingTag) {
      tags.push(defaultTag);
    }
  });

  // @ts-expect-error - TS2339 - Property 'push' does not exist on type 'readonly []'.
  batchData.metrics.push({
    metric: payload.metric,
    operation: payload.operation,
    storytime: payload.storytime,
    tags,
    value: payload.value,
  });

  if (payload.metric === 'gelato_frontend_uncaught_exception') {
    // if the metric we are pushing is for an uncaught exception, then we may be crashing and should
    // just flush metrics immediately. we may not be around when the batch is ready to go!
    flushMetrics();
  }
};

let flushing = false;

const handleErrorWhileReportingMetrics = (error: any) => {
  Sentry.addBreadcrumb({
    category: 'NetworkError',
    message: `Metrics Error: "${error.networkError}" name: "${
      error.name
    }" isSessionError: ${isSessionError(error) ? 'true' : 'false'}`,
    level: Sentry.Severity.Info,
  });
  // Only report metrics errors if it's not a SessionError and this
  // is not the first error. Sometimes metrics responses just get lost due to
  // http, so if it just happens once ignore.
  if (!isSessionError(error)) {
    if (metricsErrorCount !== 0) {
      handleException(error, 'Error reporting metrics');
    }
    metricsErrorCount++;
  }
};

async function flushMetrics() {
  if (flushing) {
    return;
  }

  if (batchData.metrics.length > 0) {
    const client = getApolloClientOnlyForMetrics();

    // Protect against unexpected logging before the apollo client has been initialized
    // or the session has been established.
    if (client && Storage.getSessionAPIKey()) {
      try {
        const toFlush = batchData.metrics;

        flushing = true;
        batchData.metrics = [];

        // eslint-disable-next-line @sail/data-no-imperative-methods
        await client.mutate({
          mutation: recordMetricsMutation,
          variables: {metrics: toFlush},
        });
      } catch (error: any) {
        handleErrorWhileReportingMetrics(error);
      } finally {
        flushing = false;
      }
    }
  }
}

setInterval(flushMetrics, FLUSH_MS);
