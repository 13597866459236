import {add as addIcon, check, info} from '@sail/icons/react/Icon';
import {
  license as licenseIcon,
  idCard as idCardIcon,
  passport as passportIcon,
} from '@sail/icons/react/IdentificationMethodIcon';
import {IconAsset} from '@sail/icons/types';
import {Icon, Action, view, css} from '@sail/ui';
import * as React from 'react';
import {useIntl} from 'react-intl';

import {LINK_GREEN} from 'gelato/frontend/src/components/Link/constants';
import DocumentExpiration from 'gelato/frontend/src/components/Link/DocumentSelection/Document/DocumentExpiration';
import {Styles as SharedStyles} from 'gelato/frontend/src/components/Link/DocumentSelection/styles';
import {messages} from 'gelato/frontend/src/components/Link/messages';
import Message from 'gelato/frontend/src/components/Message';
import {tGrey} from 'gelato/frontend/src/components/stylesV2';
import {DEFAULT_BRANDING_BUTTON_COLOR} from 'gelato/frontend/src/lib/constants';
import {COUNTRY_SELECT_OPTION_MESSAGES} from 'gelato/frontend/src/lib/countries';
import flags from 'gelato/frontend/src/lib/flags';
import useBranding from 'gelato/frontend/src/lib/hooks/useBranding';

import type {
  ConsumerIdentityDocumentApi,
  ConsumerIdentityDocument,
  ConsumerIdentityDocumentType,
} from 'gelato/frontend/src/api/Consumer/types';
import type {MessageDescriptor} from 'react-intl';

type IconType = ConsumerIdentityDocumentType | 'add';

const DOCUMENT_TYPE_TO_LABEL: Record<
  ConsumerIdentityDocumentType,
  MessageDescriptor
> = {
  passport: messages.passport,
  driving_license: messages.driversLicense,
  id_card: messages.idCard,
};

const iconToAsset: Record<IconType, IconAsset> = {
  passport: passportIcon,
  driving_license: licenseIcon,
  id_card: idCardIcon,
  add: addIcon,
};

type DocumentProps = {
  disabled?: boolean;
  document: ConsumerIdentityDocument<ConsumerIdentityDocumentApi>;
  selectedDocument: string | undefined;
  setSelectedDocument:
    | React.Dispatch<React.SetStateAction<string | undefined>>
    | ((selectedDocument: string) => void);
  index: number;
};
const Styles = {
  largeLabel: css({
    font: 'label.large.emphasized',
  }),
  mediumLabel: css({
    color: 'subdued',
    font: 'label.medium',
  }),
  selectContent: css({
    stack: 'y',
  }),
  selectWrapper: css({
    gap: 'space.150',
    stack: 'y',
  }),
  typeIconCss: css({
    padding: 'xsmall',
  }),
};

const Document = ({
  document,
  disabled,
  selectedDocument,
  setSelectedDocument,
  index,
}: DocumentProps) => {
  const {
    region,
    country,
    document_type,
    expirationDateDetails,
    id,
    invalidUseReasons: {isInvalid, isNotAllowedDocType, isNotLiveCaptured},
  } = document;
  const {buttonColor} = useBranding();
  const safeButtonColor = buttonColor || DEFAULT_BRANDING_BUTTON_COLOR;

  const intl = useIntl();

  const selected = selectedDocument === id;

  const actionInnerDynamic = SharedStyles.calculateActionInnerDynamic(
    selected,
    flags.isActive('idprod_ni_implementation_review')
      ? LINK_GREEN
      : safeButtonColor,
  );

  const checkColor = flags.isActive('idprod_ni_implementation_review')
    ? LINK_GREEN
    : undefined;

  const countryName = Object.entries(COUNTRY_SELECT_OPTION_MESSAGES).find(
    (entry) => {
      const countryCode = entry[0];
      return countryCode === country;
    },
  )?.[1];

  const regionCountryText = [
    region,
    countryName ? intl.formatMessage(countryName) : country,
  ]
    .filter((s) => !!s)
    .join(', ');

  const handleClick = () => {
    setSelectedDocument(id);
  };

  const showNotAccepted = isNotAllowedDocType || isNotLiveCaptured;
  const showExpirationDate =
    !showNotAccepted && expirationDateDetails.hasExpirationDate;

  return (
    <view.div
      role="option"
      aria-selected={selected}
      uses={[SharedStyles.action]}
    >
      <Action
        onPress={handleClick}
        disabled={disabled}
        data-testid={`link-reuse-document-option-${index}`}
      >
        <view.div uses={[SharedStyles.actionInnerStatic, actionInnerDynamic]}>
          <view.div uses={[SharedStyles.typeIconWrapperStatic]}>
            <Icon
              icon={iconToAsset[document_type]}
              size="large"
              uses={[Styles.typeIconCss]}
            />
          </view.div>
          <view.div uses={[SharedStyles.text]}>
            <view.div uses={[Styles.selectContent]}>
              <view.div uses={[Styles.largeLabel, isInvalid && tGrey]}>
                <Message {...DOCUMENT_TYPE_TO_LABEL[document_type]} />
              </view.div>
              {regionCountryText && (
                <view.div uses={[Styles.mediumLabel, isInvalid && tGrey]}>
                  {regionCountryText}
                </view.div>
              )}
              {showNotAccepted && (
                <view.div uses={[Styles.mediumLabel, tGrey]}>
                  <Message {...messages.notAccepted} />
                </view.div>
              )}
              {showExpirationDate && (
                <DocumentExpiration {...expirationDateDetails} />
              )}
            </view.div>
          </view.div>
          {selected && (
            <Icon icon={check} size="small" css={{fill: checkColor}} />
          )}
          {isInvalid && <Icon icon={info} size="small" />}
        </view.div>
      </Action>
    </view.div>
  );
};

export default Document;
