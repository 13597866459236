import {createDependency, getActiveRule} from './rule';
import {cloneDeclaration, getPathFromRoot} from './utils';
import type {StylisElement} from './types';
import {SPECIFICITY_TOKEN} from '../../../stylesheets';

type MergeOptions = {
  calc?: boolean;
  delimiter?: string;
  defaultValue?: string;
  reverseOrder?: boolean;
};

function mergeDeclarationValues(
  values: string[],
  {
    calc = false,
    delimiter = ' ',
    defaultValue = '',
    reverseOrder = false,
  }: MergeOptions,
): string {
  let result = '';
  const lastIndex = values.length - 1;
  for (let i = 0; i < values.length; i++) {
    const index = reverseOrder ? lastIndex - i : i;
    const value = values[index];
    result = result ? result + delimiter + value : value;
  }
  if (calc && values.length > 1) {
    return `calc(${result})`;
  }
  return result || defaultValue;
}

type CompositeTransform = (str: string) => string;

type CompositeProperty = (
  transform?: CompositeTransform,
) => (decl: StylisElement) => StylisElement[] | void;

type CompositePropertyConfig = {
  merge?: MergeOptions;
  order: string[];
  fallback?: string | ((property: string) => string);
};

function toCompositePartProperty(property: string) {
  return `--composite-${property}`;
}

export function createCompositeProperty(
  compositeProperty: string,
  {merge = {}, order, fallback = ''}: CompositePropertyConfig,
): CompositeProperty {
  const parts = order.map((property) => toCompositePartProperty(property));
  const compositeValue = mergeDeclarationValues(
    parts.map((part) => `var(${part})`),
    merge,
  );
  let compositeDecls = `${compositeProperty}: ${compositeValue};`;
  parts.forEach((part) => {
    let value = typeof fallback === 'string' ? fallback : fallback(part);
    if (value === ' ') {
      // Stylis removes properties with a space value even though they’re valid.
      // Use an undefined variable that will fall back to the empty space.
      value = 'var(--unset, )';
    }
    compositeDecls += `\n${part}: ${value};`;
  });

  return function (transform?: CompositeTransform) {
    return function (element): StylisElement[] | void {
      if (element.children === compositeValue) {
        return;
      }

      const property = element.props as string;
      let contents = element.children as string;
      if (transform) {
        contents = transform(contents);
      }

      const activeRuleSelector = `${SPECIFICITY_TOKEN} .${
        getActiveRule().className
      }`;
      const path = getPathFromRoot(element).map(
        (s) => `${s.replace(activeRuleSelector, '&')} {\n`,
      );
      const open = path.join('');
      const close = '\n}'.repeat(path.length);
      createDependency(
        `composite-${compositeProperty}-${open}`,
        (deprecatedCss) => deprecatedCss(`${open}${compositeDecls}${close}`),
      )();

      const customProperty = toCompositePartProperty(property);
      return [cloneDeclaration(element, customProperty, contents)];
    };
  };
}
