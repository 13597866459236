import * as React from 'react';

import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {NoTokenError} from 'gelato/frontend/src/lib/sessionError';
import fetchApiKeyForLinkCode from 'gelato/frontend/src/services/apis/start_code';
import storeLinkCodeFromWindowLocation from 'gelato/frontend/src/services/utils/storeLinkCodeFromWindowLocation';
import storeSessionStateFromStartCodeResponse from 'gelato/frontend/src/services/utils/storeSessionStateFromStartCodeResponse';

import type {
  SessionState,
  LocationType,
} from 'gelato/frontend/src/services/types';

type LinkCodeMutation = [
  (arg1: LocationType) => void,
  {
    data: SessionState | null | undefined;
    loading: boolean;
    error: Error | null | undefined;
  },
];

const useLinkCode = (): LinkCodeMutation => {
  const [data, setData] = React.useState<SessionState | null | undefined>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>(null);
  const [location, setLocation] = React.useState<
    LocationType | null | undefined
  >(null);

  React.useEffect(() => {
    (async () => {
      if (!location || loading || data || error) {
        return;
      }

      const getLinkCode = () => {
        if (process.env.PUPPETEER) {
          // Browser tests do not have a link code
          return '';
        } else {
          const linkCode = storeLinkCodeFromWindowLocation(location);

          if (!linkCode) {
            throw new NoTokenError();
          }

          return linkCode;
        }
      };

      try {
        setLoading(true);

        const linkCode = getLinkCode();
        const resp = await fetchApiKeyForLinkCode(linkCode);

        const state = storeSessionStateFromStartCodeResponse(resp);
        if (state.noToken && !state.refreshUrl) {
          throw new NoTokenError();
        }

        setData(state);
      } catch (err: any) {
        setError(err);
        postIframeEvent('STRIPE_IDENTITY_ERROR', {
          type: 'authentication_error',
          code: 'session_does_not_exist',
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [location, data, error, loading, setData, setLoading, setError]);

  return [setLocation, {data, loading, error}];
};

export default useLinkCode;
