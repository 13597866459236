import {memoize} from 'lodash';
import {useContext} from 'react';

import {PreviewBrandingContext} from 'gelato/frontend/src/lib/contexts';
import useSession from 'gelato/frontend/src/lib/hooks/useSession';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {GraphQlField} from '@sail/data';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

const formatOverrideColorString = memoize(
  (color: string | undefined): string | undefined => {
    const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    if (color && hexColorRegex.test(color)) {
      return color;
    }
    return undefined;
  },
);

export default function useBranding() {
  const session = useSession();
  const staticBranding = {
    ...Storage.getBranding(),
    __typename: 'BrandingData' as const,
  };
  const previewBranding = useContext(PreviewBrandingContext);
  const overrideBranding = String(Storage.getOverrideBranding()) === 'true';

  const overridePrimaryColor = formatOverrideColorString(
    Storage.getOverridePrimaryColor(),
  );

  const overrideSecondaryColor = formatOverrideColorString(
    Storage.getOverrideSecondaryColor(),
  );

  return computeBranding(
    session,
    staticBranding,
    previewBranding,
    overrideBranding,
    overridePrimaryColor,
    overrideSecondaryColor,
  );
}

const PREVIEW_PAGE_PATH_PATTERN = /^\/preview([/?])?$/;

function computeBranding(
  session: GraphQlField<GetSessionQueryData, 'session'> | undefined | null,
  staticBranding: GraphQlField<GetSessionQueryData, 'session', 'branding'>,
  previewBranding:
    | GraphQlField<GetSessionQueryData, 'session', 'branding'>
    | undefined
    | null,
  overrideBranding: boolean,
  overridePrimaryColor: string | undefined,
  overrideSecondaryColor: string | undefined,
) {
  const sessionBranding = session?.branding;

  if (
    PREVIEW_PAGE_PATH_PATTERN.test(window.location.pathname) &&
    previewBranding
  ) {
    return previewBranding;
  } else if (sessionBranding?.isStripe && overrideBranding) {
    return {
      ...sessionBranding,
      buttonColor: sessionBranding.buttonColorOverride,
      platformColor: sessionBranding.platformColorOverride,
      platformIcon: sessionBranding.platformIconOverride,
      platformName: sessionBranding.platformNameOverride,
    };
  } else if (session && sessionBranding) {
    return {
      ...sessionBranding,
      platformColor: overridePrimaryColor || sessionBranding.platformColor,
      buttonColor: overrideSecondaryColor || sessionBranding.buttonColor,
    };
  } else {
    return {
      ...staticBranding,
      platformColor: overridePrimaryColor || staticBranding.platformColor,
      buttonColor: overrideSecondaryColor || staticBranding.buttonColor,
    };
  }
}
