import * as React from 'react';

export const IndexContext = React.createContext<string | null>(null);
IndexContext.displayName = 'IndexContext';

export type Index = {
  index: number;
  indexPath: Array<number>;
  indexPathString: string;
};

function parseIndexPath(indexPathString: string) {
  return indexPathString.split('.').map((index) => parseInt(index, 10));
}

// Returns the index path data based on the closest `useIndexedChildren`
export function useIndex(): Index | null {
  const indexPathString = React.useContext(IndexContext);

  return React.useMemo(() => {
    if (indexPathString === null) {
      return null;
    }

    const indexPath = parseIndexPath(indexPathString);
    const index = indexPath[indexPath.length - 1];

    return {
      index,
      indexPath,
      indexPathString,
    };
  }, [indexPathString]);
}

// Provides the current index path for each child
export function useIndexedChildren(
  children: React.ReactNode,
  nested = false,
): React.ReactNode {
  const indexPathString = React.useContext(IndexContext);
  const sep = nested ? '>' : '.';

  return React.Children.map(children, (child, index) =>
    React.isValidElement(child) ? (
      <IndexContext.Provider
        key={child.key}
        value={
          indexPathString
            ? `${indexPathString}${sep}${index.toString()}`
            : index.toString()
        }
      >
        {child}
      </IndexContext.Provider>
    ) : (
      child
    ),
  );
}

export function IndexedChildren({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <>{useIndexedChildren(children)}</>;
}

export function useIndexedGroup(children: React.ReactNode): React.ReactNode {
  return useIndexedChildren(children, true);
}

export function IndexedGroup({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <>{useIndexedGroup(children)}</>;
}

const indexSeparatorRegex = /[.>]/;

export function compareIndexPaths(a = '', b = ''): number {
  const aArray = a.split(indexSeparatorRegex).map(Number);
  const bArray = b.split(indexSeparatorRegex).map(Number);

  if (aArray.includes(NaN) || bArray.includes(NaN)) {
    throw new Error('Version contains parts that are not numbers');
  }

  const maxLength = Math.max(a.length, b.length);
  for (let index = 0; index < maxLength; index++) {
    if (index >= aArray.length && index < bArray.length) {
      return -1;
    } else if (index >= bArray.length && index < aArray.length) {
      return 1;
    }

    const difference = (aArray[index] ?? 0) - (bArray[index] ?? 0);

    if (difference === 0) {
      // eslint-disable-next-line no-continue
      continue;
    }

    return difference > 0 ? 1 : -1;
  }

  return 0;
}

// Processing a list of indexed items into a tree
//
// The indexing technique above gives us "paths" which we can use to
// determinstically order a set of items. We also encode something else in the
// paths: when to flatten items.
//
// Items are always registered into a flat map. When we process the items, we
// first order them and then convert them into a tree. The algorithm below takes
// the encoded paths and generates the tree; it can assume items are already
// ordered (parents always above children).
//
// The paths looks like this: "1.0.0". The "dots" represent items that are
// nested a cross multiple component boundaries, but this is purely for the sake
// of abstraction. We have to generate these paths because otherwise multiple
// items inside the same abstracted-out component would get the same path (or
// "id"). Even though the user is rendering out items across component
// boundaries, these items are all meant to be seen as a flat list.
//
// On the contrary, sometimes you want nested data. Think of a `MenuGroup`
// component which nests menu items together. You *don't* want the items to be
// flattened back in, and you can use the above "group" hooks to generate a path
// which stops the flattening. We encode this behavior with the ">" token, so
// the path looks like this: "1.0>0". We will generate a node with the path "1.0"
// first and then nest the path "1.0>0" inside of it.

type IndexedItem = {
  indexPathString: string;
  childItems?: Array<IndexedItem>;
};

function getChildPathIndex(item: IndexedItem, parent: IndexedItem) {
  if (item.indexPathString.startsWith(parent.indexPathString)) {
    const idx = item.indexPathString.indexOf(
      '>',
      parent.indexPathString.length,
    );
    return idx === -1 ? idx : idx + 1;
  }
  return -1;
}

function isChild(item: IndexedItem, parent: IndexedItem) {
  return getChildPathIndex(item, parent) !== -1;
}

function getRelativePath(item: IndexedItem, parent?: IndexedItem) {
  let path = item.indexPathString;

  if (parent) {
    const idx = getChildPathIndex(item, parent);
    if (idx === -1) {
      return '';
    }

    path = item.indexPathString.slice(idx);
  }

  if (path.includes('>')) {
    // This is a very low-level error and there isn't a great way to explain
    // this. External users shouldn't see this as components will set this up
    // correctly. This happens if you use `useIndexedGroup` in a place where
    // there is no `useItem` registered above it. Nested items must always have
    // a parent; we don't support nested arrays, it must always be an item with
    // a `childNodes` prop
    throw new Error(
      'A collection group was used outside of a collection item. ' +
        'Groups must have a parent. Register the parent item or move the group inside ' +
        'an item.',
    );
  }

  return item.indexPathString;
}

export function _treeFromItems(
  items: Array<IndexedItem>,
  initialPointer = 0,
  parent?: IndexedItem,
): {results: Array<IndexedItem>; pointer: number} {
  const results = [];
  let pointer = initialPointer;

  while (pointer < items.length) {
    const item = {...items[pointer]};
    const path = getRelativePath(item, parent);

    if (path === '') {
      return {results, pointer};
    }

    results.push(item);
    pointer++;

    if (pointer < items.length && isChild(items[pointer], item)) {
      const recursed = _treeFromItems(items, pointer, item);
      item.childItems = recursed.results;
      pointer = recursed.pointer;
    }
  }

  return {results, pointer};
}

export function treeFromItems(items: Array<IndexedItem>): Array<IndexedItem> {
  return _treeFromItems(items).results;
}
