import {useContext} from 'react';

import {AppControllerContext} from 'gelato/frontend/src/components/AppControllerContextProvider';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';

import type {AppControllerContextValue} from 'gelato/frontend/src/components/AppControllerContextProvider';

/**
 * The hook to get the the context value from <AppControllerContextProvider />.
 */
export default function useAppController(): AppControllerContextValue {
  const contextValue = useContext(AppControllerContext);
  if (!contextValue) {
    // The component should be contained within <AppControllerContextProvider />
    // which provides the context value.
    throw new Error(ErrorCode.appControllerContextValueMissing);
  }
  return contextValue;
}
