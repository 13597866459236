import {GraphQlField} from '@sail/data';
import * as Sentry from '@sentry/browser';
import min from 'lodash/min';

import {ApplicationRouterPath} from 'gelato/frontend/src/controllers/types';
import routeToLinkForConsumerDocumentSave from 'gelato/frontend/src/controllers/utils/routeToLinkForConsumerDocumentSave';
import {isMobileDevice} from 'gelato/frontend/src/lib/device';
import experiments from 'gelato/frontend/src/lib/experiments';
import flags from 'gelato/frontend/src/lib/flags';
import isBrowserCameraPermissionEnabled from 'gelato/frontend/src/lib/isBrowserCameraPermissionEnabled';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {IndividualFields} from '@stripe-internal/data-gelato/schema/types';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

const SUBMIT_ROUTE = '/submit';

enum VirtualIndividualFields {
  permissions = 'permissions',
  verify_options = 'verify_options',
}

/**
 * Get the routes ordered by the order in which they should be visited.
 * @returns
 */
function getOrderedRoutes(): ReadonlyArray<ApplicationRouterPath> {
  const routes: ApplicationRouterPath[] = [
    // TODO(cjmisenas, 5-30-2024): Clean this up when we are done with
    // Butter migration since these pages will no longer exist
    '/continue',
    '/email_verification',
    '/phone_verification',
    '/selfie_verification_method',
    '/verify_options',
    '/document_select',
    '/consent',
    '/permissions',
    '/document_upload',
    '/face_upload',
    '/individual',
  ];

  if (experiments.isActive('welcome_page_v2')) {
    // The consent flow is merged into the page mapped to `/continue`
    const consentPos = routes.indexOf('/consent');
    routes.splice(consentPos, 1);
    routes.unshift('/continue');

    // The verify options (choose webcam, file upload, handoff) page is removed
    // from the Butter 2.0 flow.
    const veriryOptionsPos = routes.indexOf('/verify_options');
    routes.splice(veriryOptionsPos, 1);

    // We'd no longer let user to choose document type.
    // Instead, we'd show them the document type they need to prepare for
    // if they haven't seen it before.
    const documentSelectPos = routes.indexOf('/document_select');
    if (Storage.hasVisitedPath('/document_types')) {
      // Remove document select page.
      routes.splice(documentSelectPos, 1);
    } else {
      // Replace document select page with document types page.
      routes[documentSelectPos] = '/document_types';
    }
  }

  return routes;
}

export function getConsentRoute(): String {
  const settings = Storage.getIdentitySettings();
  const networkedIdentityReuseAvailable =
    !!settings?.networked_identity_reuse_available;

  if (networkedIdentityReuseAvailable) {
    return '/welcome_consent';
  } else {
    return '/consent';
  }
}

// we have some tom-foolery here to get page order right for document capture
type AugmentedIndividualFields = IndividualFields | VirtualIndividualFields;
type NetworkingData = GraphQlField<
  GetSessionQueryData,
  'session',
  'networkingData'
>;

export type NextDataPageSession = Readonly<{
  missingFields: ReadonlyArray<IndividualFields>;
  requiredFields: ReadonlyArray<IndividualFields>;
  rLCapture: boolean;
  networkingData?: NetworkingData;
}>;

export async function nextDataPageForSession(session: NextDataPageSession) {
  const missingFields = [...session.missingFields] as IndividualFields[];
  return nextDataPage(
    missingFields,
    session.requiredFields,
    session.rLCapture,
    undefined,
    session.networkingData,
  );
}

export async function nextDataPage(
  missingFields: ReadonlyArray<IndividualFields>,
  requiredFields: ReadonlyArray<IndividualFields>,
  requireLiveCapture: boolean,
  skipNetworking: boolean = false,
  networkingData?: NetworkingData,
): Promise<ApplicationRouterPath> {
  const augmentedMissingFields = [
    ...missingFields,
  ] as Array<AugmentedIndividualFields>;

  if (augmentedMissingFields.length === 0) {
    const settings = Storage.getIdentitySettings();
    const networkedIdentityEnabled = !!settings?.networked_identity_enabled;
    const hasDocumentRequirement =
      requiredFields.includes('id_document_images');
    const sharedNetworkedDocument = Storage.getSharedNetworkedDocument();
    const consentsToDocumentNetworking =
      networkingData?.consentsToDocumentNetworking;
    const skipsDocumentNetworking =
      flags.isActive('idprod_update_consumer_networking_data') &&
      Boolean(networkingData?.skipsDocumentNetworking);

    // If a document is uploaded without a consumer session,
    // let's route the user to /link to offer them to network
    // todo(aywang, 2023-12-11): Remove the use of hasDocumentRequirement and move the check to the backend
    if (
      routeToLinkForConsumerDocumentSave(
        networkedIdentityEnabled,
        hasDocumentRequirement,
        sharedNetworkedDocument,
        skipNetworking || skipsDocumentNetworking,
        Boolean(consentsToDocumentNetworking),
      )
    ) {
      return '/link';
    }

    return SUBMIT_ROUTE;
  }

  const needsPhoto =
    augmentedMissingFields.includes('face') ||
    augmentedMissingFields.includes('id_document_images');
  // If user wants to retake photo,
  // don't take them back to /verify_options
  if (needsPhoto && !Storage.getVerifyOption()) {
    if (!isMobileDevice()) {
      augmentedMissingFields.push(VirtualIndividualFields.verify_options);
    }
  }

  // We need camera access if:
  // 1) Doing selfie
  // or
  // 2) ID Document + either LiveCapture or Mobile or explicitly choosen webcam)
  const needCamera =
    augmentedMissingFields.includes('face') ||
    (augmentedMissingFields.includes('id_document_images') &&
      (requireLiveCapture ||
        isMobileDevice() ||
        Storage.getVerifyOption() === 'webcam'));

  if (needCamera) {
    let alreadySeenPermissions = '';
    try {
      // Note `Storage.getWebcamPermission()` only means whether user had
      // manually clicked the "Continue" button previously. It does not tell
      // whether user currently has a camera accessible.
      alreadySeenPermissions = Storage.getWebcamPermission() || '';
    } catch (error: any) {
      Sentry.addBreadcrumb({
        category: 'Permissions',
        message: `Storage.getWebcamPermission error: ${error}`,
        level: Sentry.Severity.Info,
      });
    }

    if (alreadySeenPermissions !== 'true') {
      const skipPermissionsPage = await isBrowserCameraPermissionEnabled();

      if (!skipPermissionsPage) {
        // for firefox the user will still be asked for permissions but that is ok because we expect
        // this request to succeed if it has succeeded before. only in the sad path is it important
        // that we ask for permissions early so we can route users to a happy path sooner.
        augmentedMissingFields.push(VirtualIndividualFields.permissions);
      }
    }
  }

  const routes = getOrderedRoutes();

  const nextIndexes = augmentedMissingFields
    .map((field) => routes.indexOf(fieldToRoute(field, augmentedMissingFields)))
    .filter((i) => i >= 0);

  if (nextIndexes.length === 0) {
    return SUBMIT_ROUTE;
  }
  const nextPageIndex = min(nextIndexes) as number;
  return routes[nextPageIndex];
}

/**
 * Get the mapping of fields to routes.
 */
function getFieldToRouteMapping(): Readonly<
  Record<string, ApplicationRouterPath>
> {
  const fieldPageMapping: Record<string, ApplicationRouterPath> = {
    address: '/individual',
    consent: '/consent',
    dob: '/individual',
    email: '/individual',
    face: '/face_upload',
    id_document_images: '/document_upload',
    id_document_metadata: '/document_select',
    id_number: '/individual',
    name: '/individual',
    permissions: '/permissions',
    phone_number: '/individual',
    selfie_verification_method: '/selfie_verification_method',
    verify_options: '/verify_options',
    email_otp: '/email_verification',
    phone_otp: '/phone_verification',

    // Unimplemented -- TAX_ID is not supported in Gelato UI
    company_name: '/individual',
    company_id_number: '/individual',
  };

  if (experiments.isActive('welcome_page_v2')) {
    // The consent flow is renamed as `accept_terms` and merged into the
    // initial welcome page.
    fieldPageMapping.consent = '/continue';
    // Show user the document types they need to prepare for.
    fieldPageMapping.id_document_metadata = '/document_types';
  }

  return fieldPageMapping;
}

function fieldToRoute(
  field: AugmentedIndividualFields,
  missingFields: Array<AugmentedIndividualFields>,
) {
  // If the user has selected file upload for document iamges, but camera permissions
  // are still required (i.e. for selfie capture), allow the user to upload the document
  // first, then prompt for camera permissions
  if (
    field === VirtualIndividualFields.permissions &&
    Storage.getVerifyOption() === 'upload' &&
    missingFields.includes('id_document_images')
  ) {
    return '/document_upload';
  }

  return getFieldToRouteMapping()[field];
}
