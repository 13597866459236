import {externalSvg as Icon__small__externalSvg} from '../Icon__small/Icon__small--external.js'
import {externalSvg as Icon__xsmall__externalSvg} from '../Icon__xsmall/Icon__xsmall--external.js'

export var external = {
  name: 'external',
  category: 'icon',
  colorable: true,
  svg: Icon__small__externalSvg,
  alt: {
    Icon__small: Icon__small__externalSvg,
    Icon__xsmall: Icon__xsmall__externalSvg
  },
  combined: ['Icon__small', 'Icon__xsmall']
};
