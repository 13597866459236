import {VERSION} from '../../../version';
import {hash} from '../../../util/hash';
import {isServer} from '../../../util/isServer';

export const CSS_REGISTRY_DEFAULT = 'default' as const;
export const DEFAULT_PREFIX = 'sn-';
export const DYNAMIC_LAYER = 'dynamic';
export const TRANSITIVE_KEY = '♲';

export const $cssValue = Symbol('css value');

let prefixByEnv;

switch (process.env.NODE_ENV) {
  // in development, use a per-request hash, so it can't be coded against
  case 'development':
    prefixByEnv = `j${hash(`${new Date().toString()}${VERSION}`)}-`;
    break;
  // in production, use a per-version hash, so it won't collide
  case 'production':
    prefixByEnv = `j${hash(VERSION)}-`;
    break;
  // in test, use a stable prefix, so it won't break snapshots
  default:
    prefixByEnv = DEFAULT_PREFIX;
}

// try to hydrate in case we're in an SSR context. otherwise, use the prefix
export const DYNAMIC_TOKEN_PREFIX = isServer
  ? prefixByEnv
  : document.querySelector('style[data-prefix]')?.getAttribute('data-prefix') ??
    prefixByEnv;
