import {logInDev} from 'gelato/frontend/src/lib/assert';
import Experiments from 'gelato/frontend/src/lib/experiments';
import {releaseReusableCanvas} from 'gelato/frontend/src/lib/ReusableCanvas';
import {handleException} from 'gelato/frontend/src/lib/sentry';

/**
 * Resizes the given HTMLCanvasElement to a very small size and clears its
 * contents. This is a workaround for an issue in Safari on iOS, where every
 * canvas created is kept in memory and not released even when no longer
 * referenced. This can cause memory usage to exceed 384MB and all canvas
 * contexts to become null. By resizing the canvas to a very small size and
 * clearing its contents, Safari replaces the original canvas in its storage
 * with our compressed version.
 * @see https://pqina.nl/blog/total-canvas-memory-use-exceeds-the-maximum-limit/
 *
 * @param canvas - The HTMLCanvasElement to release.
 */
export default function releaseCanvas(canvas: HTMLCanvasElement) {
  try {
    if (
      Experiments.isActive('fix_canvas_memory_leak', {
        logExposure: true,
      })
    ) {
      // Display the ID of the canvas so we could tell how many canvas
      // elements were created. Ideally the less new canvas elements created,
      // the less memory would be consumed.
      logInDev('releaseCanvas', {
        width: canvas.width,
        height: canvas.height,
        id: canvas.id,
      });
      releaseReusableCanvas(canvas);
    }
  } catch (ex) {
    handleException(ex, 'releaseCanvas error');
  }
}
