import * as React from 'react';

import addDOMPointerEventDragHandlers from 'gelato/frontend/src/lib/addDOMPointerEventDragHandlers';
import getDOMEventClientPosition from 'gelato/frontend/src/lib/getDOMEventClientPosition';

type Movement = [
  number, // The position
  number, // The timestamp
];

const {useEffect, useRef} = React;

// Regular expression to match HTML elements that are clickable.
const CLICKABLE_ELEMENT_REGEX = /^(a|button|input|option|select|textarea)$/i;

/**
 * Checks if the event target is an clickable HTML element.
 *
 * @param event - The Event object representing the interaction.
 * @returns boolean - Returns true if the target element or its ancestors are
 *   clickable.
 */
function isEventTargetClickable(event: Event): boolean {
  const {target} = event;

  if (!(target instanceof HTMLElement)) {
    return false;
  }

  // Initialize iteration count to guard against excessive looping
  let iteration = 0;
  // Start with the target element
  let el: HTMLElement | null = target;

  // Loop to check if the target element or any of its ancestor is clickable.
  while (el && iteration < 10) {
    if (CLICKABLE_ELEMENT_REGEX.test(el.nodeName)) {
      return true;
    }
    iteration++;
    el = el.parentElement;
  }
  return false;
}

/**
 * The effect to enable dragging to scroll on the scrollable element.
 * @param domRef The ref to the scrollable element which should have the CSS
 *   style "overflow: scroll" or "overflow: auto" set already.
 */
export default function useDragToScroll(
  domRef: React.RefObject<HTMLElement>,
): void {
  // The function to cancel the inertial scrolling.
  const cancelInertialScrollingRef = useRef<Function>(() => {});

  useEffect(() => {
    const el = domRef.current;
    if (!el) {
      return;
    }

    // Keep track of the drag movements.
    const dragMoves: Movement[] = [];

    let isDragging = false;
    let isDraggable = false;
    let startEventY = 0;
    let startScrollY = 0;

    // Start dragging.
    const handleDragStart = (event: PointerEvent) => {
      dragMoves.length = 0;
      cancelInertialScrollingRef.current();

      const eventPos = getDOMEventClientPosition(event);
      if (!eventPos) {
        return;
      }

      // Disable dragging when the event target is clickable, assuming the user
      // intends to click on the element rather than drag it.
      isDraggable = !isEventTargetClickable(event);

      startEventY = eventPos[1];
      startScrollY = el.scrollTop;
      dragMoves.push([eventPos[1], event.timeStamp]);
    };

    // Handle dragging.
    const handleDragMove = (event: PointerEvent) => {
      if (!dragMoves.length) {
        // The first drag move event should have been created in
        // handleDragStart. Exit early if it's not.
        return;
      }

      const eventPos = getDOMEventClientPosition(event);
      if (!eventPos) {
        return;
      }

      if (!isDraggable) {
        // The user has moved the pointer further, suggesting they intend to
        // drag the scroll element rather than click on it.
        dragMoves.push([eventPos[1], event.timeStamp]);
        isDraggable = dragMoves.length > 3;
      }

      if (!isDraggable) {
        return;
      }

      const deltaY = eventPos[1] - startEventY;

      if (!isDragging) {
        isDragging = true;
        // Capture the pointer to the element once dragging starts.
        if (el.setPointerCapture) {
          el.setPointerCapture(event.pointerId);
        }
      }

      el.scrollTop = Math.max(0, startScrollY - deltaY);

      event.stopPropagation();
      event.preventDefault();

      dragMoves.push([eventPos[1], event.timeStamp]);
      while (dragMoves.length > 2) {
        // We only need the last two drag movements to keep the final drag
        // velocity.
        dragMoves.shift();
      }
    };

    // End dragging.
    const handleDragEnd = (event: PointerEvent) => {
      isDraggable = false;
      dragMoves.length = 0;
      if (isDragging) {
        isDragging = false;
        if (el.releasePointerCapture) {
          el.releasePointerCapture(event.pointerId);
        }
        // The drag gesture has ended. Start the inertial scrolling based
        // on the momentum of the drag gesture.
        doInitialScroll();
      }
    };

    const doInitialScroll = () => {
      const pt2: Movement | undefined = dragMoves.pop();
      const pt1: Movement | undefined = dragMoves.pop();
      dragMoves.length = 0;

      if (!(pt2 && pt1)) {
        // The drag did not move. Do not start the inertial scrolling.
        return;
      }

      const distance = pt2[0] - pt1[0];
      const duration = pt2[1] - pt1[1];
      if (duration <= 5 || Math.abs(distance) <= 1) {
        // The drag did not move enough, and it's likely a click action instead.
        // Do not start the inertial scrolling.
        return;
      }

      // Scaling the velocity by 8 to make the scrolling faster.
      const velocity = -8 * (distance / duration);

      cancelInertialScrollingRef.current = inertialScrolling(
        el.scrollTop,
        velocity,
        (scrollPos) => (el.scrollTop = scrollPos),
        2500,
      );
    };

    const cancelHandlers = addDOMPointerEventDragHandlers(el, {
      handleDragStart,
      handleDragMove,
      handleDragEnd,
    });

    return () => {
      cancelHandlers();
      cancelInertialScrollingRef.current();
    };
  }, [cancelInertialScrollingRef, domRef]);
}

/**
 * This replicates the scrolling behavior of iOS's inertial scrolling. iOS uses
 * a specific damping function to achieve its characteristic scrolling effect,
 * which slows down more gradually than linear damping.
 *
 * @param initialScrollPos The initial scroll position
 * @param initialScrollVelocity The initial scroll velocity
 * @param scrollCallback The callback to call when the scroll position changes.
 * @param duration The duration of the inertial scrolling in milliseconds.
 * @returns A function to stop the scrolling.
 */
export function inertialScrolling(
  initialScrollPos: number,
  initialScrollVelocity: number,
  scrollCallback: (scrollPos: number) => void,
  duration: number,
): () => void {
  const startTime = performance.now();

  let currentScrollPos = initialScrollPos;
  let animationFrameId: number | null = null;

  const animate = () => {
    const elapsed = performance.now() - startTime;
    const t = Math.min(elapsed / duration, 1);
    const damping = cubicEaseOut(t);

    // Apply the damping effect
    const delta = initialScrollVelocity * (1 - damping);
    currentScrollPos += delta;

    // Call the scroll callback with the updated position
    scrollCallback(currentScrollPos);

    // Continue animating if the time has not yet elapsed
    if (t < 1) {
      animationFrameId = window.requestAnimationFrame(animate);
    } else {
      stop();
    }
  };

  // Start the animation
  animationFrameId = window.requestAnimationFrame(animate);

  // Function to stop the scrolling
  function stop() {
    if (animationFrameId !== null) {
      window.cancelAnimationFrame(animationFrameId);
    }
    animationFrameId = null;
  }

  // Return the stop function
  return stop;
}

/**
 * The cubic ease out function.
 * @param value The value to animate.
 * @returns The animated value.
 */
function cubicEaseOut(value: number): number {
  const f = value - 1;
  return f * f * f + 1;
}
