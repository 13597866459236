export const tokensSize = {
  size: {
    0: '0px',
    1: '$space.1',
    25: '$space.25',
    50: '$space.50',
    75: '$space.75',
    100: '$space.100',
    150: '$space.150',
    200: '$space.200',
    250: '$space.250',
    300: '$space.300',
    350: '$space.350',
    400: '$space.400',
    500: '$space.500',
    600: '$space.600',

    /**
     * Fills the available size of the containing block. Shorthand for `100%`.
     */
    fill: '100%',
    /**
     * Intrinsic minimum size of the content.
     */
    min: 'min-content',
    /**
     * Intrinsic maximum size of the content.
     */
    max: 'max-content',
    /**
     * Fills the available space, up to the maximum size of the content.
     */
    fit: 'fit-content',

    // halves
    '1/2': '50%',
    // thirds
    '1/3': '33.3333%',
    '2/3': '66.6667%',
    // quarters
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    // fifths
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    // sixths
    '1/6': '16.6667%',
    '2/6': '33.3333%',
    '3/6': '50%',
    '4/6': '66.6667%',
    '5/6': '83.3333%',
    // twelfths
    '1/12': '8.3333%',
    '2/12': '16.6667%',
    '3/12': '25%',
    '4/12': '33.3333%',
    '5/12': '41.6667%',
    '6/12': '50%',
    '7/12': '58.3333%',
    '8/12': '66.6667%',
    '9/12': '75%',
    '10/12': '83.3333%',
    '11/12': '91.6667%',
  },
};
