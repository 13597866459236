import * as React from 'react';

export type PrefetchEnv = {
  // Defines whether a given query should be executed in a regular/rendering
  // mode or prefetching one.
  isPrefetchMode: boolean;
};

export default React.createContext<PrefetchEnv>({
  isPrefetchMode: false,
});
