import {StyleSheets} from '../../../stylesheets';
import {TRANSITIVE_KEY} from './constants';
import {flattenStyles} from './flatten';
import {hash} from '../../../util';
import type {SerializedStyles} from './types';

export const StyleCache = {
  inserted: {} as Record<string, boolean>,

  map: {} as Record<string, SerializedStyles>,

  register(styles: SerializedStyles): void {
    this.map[styles.key] = styles;
  },

  get(key: string): SerializedStyles | void {
    return this.map[key] as SerializedStyles;
  },

  insert(styles: SerializedStyles): string {
    // Ensure stylesheets are frozen and hydrated before inserting any styles.
    StyleSheets.freeze();

    const isTransitive = styles.key === TRANSITIVE_KEY;
    if (isTransitive) {
      styles.key += hash(styles.dependencies.join(''));
    }

    const cachedClasses = StyleSheets.getClasses(styles.key);
    if (cachedClasses) {
      return cachedClasses;
    }
    if (isTransitive) {
      flattenStyles(styles as SerializedStyles);
    }

    for (let i = 0; i < styles.isolated.length; i++) {
      const isolated = this.get(styles.isolated[i]);
      if (isolated) {
        this.insert(isolated);
      }
    }

    for (let i = 0; i < styles.dependencies.length; i++) {
      const dep = this.get(styles.dependencies[i]);
      if (dep) {
        this.insert(dep);
      }
    }

    for (
      let i = 0, layers = Object.keys(styles.layers);
      i < layers.length;
      i++
    ) {
      const layer = layers[i];
      const rulesets = styles.layers[layer];
      const id = `${layer}.${rulesets.className}`;
      if (!this.inserted[id]) {
        StyleSheets.schedule(() => {
          if (!this.inserted[id]) {
            this.inserted[id] = true;
            StyleSheets.insert(rulesets, layer);
          }
        });
      }
    }

    const classes = Array.from(styles.classes).join(' ');
    StyleSheets.setClasses(styles.key, classes);
    return classes;
  },
};

StyleSheets.onFlush(() => {
  StyleCache.inserted = {};
});
