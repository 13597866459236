import {historyReplaceState, urlRedirect} from '@stripe-internal/safe-links';
import * as React from 'react';

import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {isExperimentActive} from 'gelato/frontend/src/lib/experiments';
import {
  useRouter,
  useSession,
  useFeatureFlags,
  useExperiments,
} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';

/**
 * ReloadPage component reloads the page in the browser and redirects to the
 * appropriate next page. The file size of this page should be minimized to
 * reduce the time taken for reloading.
 */
function ReloadPage() {
  const [isReloaded, setIsReloaded] = React.useState(false);
  const router = useRouter();
  const session = useSession();
  const flags = useFeatureFlags();
  const experiments = useExperiments();

  React.useEffect(() => {
    // To prevent "Flash of Unstyled Content" when reloading the iframe, we
    // notify the parent before and after reloading.
    //
    // The owner of the iframe will hide it and show a loading indicator
    // temporarily, and show the iframe again when the page is reloaded.
    //
    // GelatoVerificationIframe and its forks implement this seamless
    // experience for our users.

    // Check if a reload token exists in the URL and parse it if it does.
    const matches = window.location.search.match(/rt=([\d]+)/);
    const reloadToken = parseInt(String(matches?.[1]), 10);

    if (reloadToken) {
      // Notify the parent element (i.e. the owner) of the containing iframe
      // that we have reloaded the page.
      postIframeEvent('load');
      setIsReloaded(true);
    } else {
      // Notify the parent element (i.e. the owner) of the containing iframe
      // that we're about to reload the whole page.
      postIframeEvent('reload');
      // Add a token to the URL and check it later after reloading the page.
      const token = String(Date.now());
      const url = `/reload?rt=${token}`;
      // Update the current history's entry. This would make the Back button
      // work correctly.
      historyReplaceState(null, url);
      // Reload page.
      urlRedirect(url);
    }
  }, []);

  React.useEffect(() => {
    if (!isReloaded || !session || !router) {
      return;
    }

    let aborted = false;

    const run = async () => {
      // Get the path of the next data page and replace the current page with
      // it.

      const nextPath = await nextDataPageForSession(session);
      if (aborted) {
        return;
      }

      if (
        // These are the deprecated routes that should be replaced in Butter M2.
        // Proceed to document_upload if the experiment is active.
        (nextPath === '/permissions' || nextPath === '/document_types') &&
        isExperimentActive('document_upload_page_v2', experiments)
      ) {
        router.replace('/document_upload');
      } else {
        router.replace(nextPath);
      }
    };

    run();

    return () => {
      aborted = true;
    };
  }, [experiments, flags, isReloaded, router, session]);

  return <LoadingBox />;
}

setComponentConfig(ReloadPage, {
  componentName: 'reload',
  skipWarnOnUnload: true,
});

export default ReloadPage;
