import * as React from 'react';
import {Helmet} from 'react-helmet';
import {injectIntl} from 'react-intl';

import Message, {
  FormattedMarkdownMessage,
} from 'gelato/frontend/src/components/Message';
import NetworkErrorNotice from 'gelato/frontend/src/components/NetworkErrorNotice';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import ThemableToggleBox from 'gelato/frontend/src/components/ThemableToggleBox';
import useSetTestmodeSettingMutation from 'gelato/frontend/src/graphql/mutations/useSetTestmodeSettingMutation';
import useUpdateConsentMutation from 'gelato/frontend/src/graphql/mutations/useUpdateConsentMutation';
import {useSessionInfo} from 'gelato/frontend/src/layout/contexts/SessionInfoProvider';
import {SetPageCardPropsContext} from 'gelato/frontend/src/lib/contexts';
import {useBreakpoint, useRouter} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {reportMetric} from 'gelato/frontend/src/lib/metricsBatcher';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import messages from 'gelato/frontend/src/local_pages/messages/testing';
import Box from 'sail/Box';
import Card from 'sail/Card';
import {ContentBlock, ContentHeader} from 'sail/Content';
import {Notice} from 'sail/Notice';
import {ToggleBoxItem} from 'sail/ToggleBox';

import styles from './testing.module.css';

import type {PageProps} from 'gelato/frontend/src/lib/localRouter';
import type {IntlShape} from 'react-intl';

type Props = PageProps & {
  intl: IntlShape;
};

type TestmodeSetting =
  | 'verified'
  | 'unverified'
  | 'verifiedAsync'
  | 'unverifiedAsync';

type AutocompleteOptions = TestmodeSetting | 'consentDeclined';

const OldTestingLayout = ({intl: {formatMessage}}: Props) => {
  const router = useRouter();
  const breakpoint = useBreakpoint();
  const [autocompleteSetting, setAutocompleteSetting] =
    React.useState<AutocompleteOptions>('verified');
  const [autocompletePending, setAutocompletePending] = React.useState(false);
  const [manualPending, setManualPending] = React.useState(false);
  const [error, setError] = React.useState<any>(null);
  const [throwErrorInRender, setThrowErrorInRender] = React.useState<any>(null);
  const [setTestModeSetting] = useSetTestmodeSettingMutation();
  const [updateConsent] = useUpdateConsentMutation();
  const info = useSessionInfo();

  const tryTo = React.useCallback(
    async ({message, silent, pending: pendingArg, throwInRender, action}) => {
      try {
        pendingArg && setAutocompletePending(true);
        await action();
        setError(null);
        setThrowErrorInRender(null);
      } catch (caughtError: any) {
        handleException(caughtError, message);
        if (!silent) {
          setError(caughtError);
          setThrowErrorInRender(throwInRender);
        }
        pendingArg && setAutocompletePending(false);
      }
    },
    [setError, setThrowErrorInRender],
  );

  const handleSetTestModeSetting = React.useCallback(
    (setting: TestmodeSetting) => {
      reportMetric({
        metric: 'gelato_frontend_testmode_document_submitted',
        operation: 'count',
        value: 1,
      });

      const verify = setting === 'verified' || setting === 'verifiedAsync';
      const forceAsync =
        setting === 'verifiedAsync' || setting === 'unverifiedAsync';

      tryTo({
        message: 'testmode complete',
        pending: true,
        silent: false,
        throwInRender: true,
        action: async () => {
          await setTestModeSetting({variables: {verify}});
          router.push({pathname: '/submit', query: {forceAsync}});
        },
      });
      return undefined;
    },
    [router, setTestModeSetting, tryTo],
  );

  const handleTestmodeDeclineConsent = React.useCallback(() => {
    tryTo({
      message: 'testmode decline consent',
      pending: true,
      silent: false,
      throwInRender: true,
      action: async () => {
        await updateConsent({
          variables: {
            consentData: {
              accepted: false,
              version: 'us_en_v1',
            },
          },
        });
        router.push('/invalid');
      },
    });
  }, [tryTo, updateConsent, router]);

  const handleAutocompleteClick = React.useCallback(() => {
    if (autocompleteSetting === 'consentDeclined') {
      handleTestmodeDeclineConsent();
    } else {
      handleSetTestModeSetting(autocompleteSetting);
    }
  }, [
    autocompleteSetting,
    handleSetTestModeSetting,
    handleTestmodeDeclineConsent,
  ]);

  const welcomePage = info.welcomePageUrl;
  const handleManualClick = React.useCallback(() => {
    setManualPending(true);
    router.push(welcomePage);
  }, [router, welcomePage]);

  const setPageCardProps = React.useContext(SetPageCardPropsContext);
  React.useEffect(() => {
    setPageCardProps({
      showBackLink: false,
      showPageHeader: true,
      showProgressBar: false,
      size: 'single',
    });
  }, [setPageCardProps]);

  if (throwErrorInRender) {
    throw error;
  }

  const autoCompleteButton = (breakpoint: string) => (
    <ThemableButton
      // @ts-expect-error - TS2322 - Type '{ id: string; color: "blue"; label: Element; icon: "arrowRight" | undefined; onClick: () => void; iconPosition: "right"; pending: boolean; }' is not assignable to type 'IntrinsicAttributes & { className?: string | undefined; color?: ButtonColor | undefined; disabled?: boolean | undefined; disabledWhenPending?: boolean | undefined; ... 14 more ...; to?: undefined; } & LinkBehaviorProps'.
      id="testing-submit-autocomplete-button"
      color="blue"
      label={<Message {...messages.autocompleteSectionButton} />}
      icon={breakpoint === 'mobile' ? undefined : 'arrowRight'}
      onClick={handleAutocompleteClick}
      iconPosition="right"
      pending={autocompletePending}
    />
  );

  const manualButton = (breakpoint: string) => (
    <ThemableButton
      // @ts-expect-error - TS2322 - Type '{ id: string; color: "blue"; label: Element; onClick: () => void; icon: "arrowRight" | undefined; iconPosition: "right"; pending: boolean; }' is not assignable to type 'IntrinsicAttributes & { className?: string | undefined; color?: ButtonColor | undefined; disabled?: boolean | undefined; disabledWhenPending?: boolean | undefined; ... 14 more ...; to?: undefined; } & LinkBehaviorProps'.
      id="testing-submit-manual-button"
      data-testid="testing-preview-button"
      color="blue"
      label={<Message {...messages.manualSectionButton} />}
      onClick={handleManualClick}
      icon={breakpoint === 'mobile' ? undefined : 'arrowRight'}
      iconPosition="right"
      pending={manualPending}
    />
  );

  return (
    <div className={styles.TestingPage}>
      <Helmet>
        <title>{formatMessage(messages.documentTitle)}</title>
      </Helmet>
      {error && <NetworkErrorNotice error={error} />}
      <Box padding={{top: 12}} margin={12}>
        <Card shadow="keyline">
          <Notice
            title={<Message {...messages.testmodeHeaderTitle} />}
            color="yellow"
            description={
              <FormattedMarkdownMessage
                {...messages.testmodeHeaderDescription}
              />
            }
          />
        </Card>
      </Box>
      <Box padding={12}>
        <Box padding={{bottom: 12}}>
          <Card shadow="small">
            <ContentHeader
              title={<Message {...messages.autocompleteSectionTitle} />}
              description={
                <Message {...messages.autocompleteSectionDescription} />
              }
              end={{
                content:
                  breakpoint === 'mobile' || autoCompleteButton(breakpoint),
              }}
            />
            <ContentBlock padding={{horizontal: 20, vertical: 16}}>
              <ThemableToggleBox
                direction="vertical"
                name="togglebox-autocomplete-setting"
              >
                <ToggleBoxItem
                  label={<Message {...messages.autocompleteVerifiedTitle} />}
                  checked={autocompleteSetting === 'verified'}
                  onChange={() => setAutocompleteSetting('verified')}
                />
                <ToggleBoxItem
                  label={<Message {...messages.autocompleteUnverifiedTitle} />}
                  checked={autocompleteSetting === 'unverified'}
                  onChange={() => setAutocompleteSetting('unverified')}
                />
                <ToggleBoxItem
                  label={
                    <Message {...messages.autocompleteVerifiedAsyncTitle} />
                  }
                  checked={autocompleteSetting === 'verifiedAsync'}
                  onChange={() => setAutocompleteSetting('verifiedAsync')}
                />
                <ToggleBoxItem
                  label={
                    <Message {...messages.autocompleteUnverifiedAsyncTitle} />
                  }
                  checked={autocompleteSetting === 'unverifiedAsync'}
                  onChange={() => setAutocompleteSetting('unverifiedAsync')}
                />
                <ToggleBoxItem
                  label={
                    <Message {...messages.autocompleteConsentDeclinedTitle} />
                  }
                  checked={autocompleteSetting === 'consentDeclined'}
                  onChange={() => setAutocompleteSetting('consentDeclined')}
                />
              </ThemableToggleBox>
              {breakpoint === 'mobile' && (
                <Box padding={{top: 12}}>{autoCompleteButton(breakpoint)}</Box>
              )}
            </ContentBlock>
          </Card>
        </Box>
        <Box padding={{bottom: 12}}>
          <Card shadow="small">
            <ContentHeader
              title={<Message {...messages.manualSectionTitle} />}
              description={<Message {...messages.manualSectionDescription} />}
              end={{
                content: breakpoint === 'mobile' || manualButton(breakpoint),
              }}
            />
            {breakpoint === 'mobile' && (
              <ContentBlock
                padding={{
                  horizontal: 20,
                  vertical: 12,
                }}
              >
                <Box padding={{bottom: 4}}>{manualButton(breakpoint)}</Box>
              </ContentBlock>
            )}
          </Card>
        </Box>
      </Box>
    </div>
  );
};

const TestingPage = (props: Props) => {
  const router = useRouter();
  const info = useSessionInfo();

  if (info.livemode) {
    throw new Error('testing page disabled in livemode');
  } else if (info.isFlagSet('gelato_disable_testmode_ui_elements')) {
    // TODO(weaver) -- this is a temporary workaround to skip past the testing
    // page for iframe use inside the Stripe Dashboard. If we want to GA iframe
    // mode to external customers (e.g. Web SDK), this needs to change.
    //
    // update 2020-04-10(dodgejoel) testing whether or not this is an iframe is being replaced with
    // whether a feature flag for disabling testmode elements in general
    //
    // Why do we want to skip past /testing?
    //
    // * Direct & Standard Onboarding is always livemode unless its an automated
    //   test with a fake merchant, but we don't need `/testing` for that.
    // * Express (Hosted Onboarding) has buttons to control testmode flow outside
    //   the iframe already, so the iframe needs to render as if the user has
    //   clicked Proceed -- Express integrators will use Express controls to
    //   "skip to the end", so we don't need `/testing`
    // * The dashboard iframe component needs to know when gelato has "loaded"
    //   and the most convenient way to do that is watch for `postMessage`
    //   events emitted from the /welcome or /welcome_consent route; if `/testing` loads first
    //   the parent window needs to command gelato to skip past testing, which
    //   we're not ready for yet.
    //
    // Why are we doing this routing here?
    //
    // * The session needs to be loaded because iframe-mode is feature flagged
    // * This means we can't route straight to `/welcome or /welcome_consent` in `/start`
    router.replace(info.welcomePageUrl);
  } else {
    postIframeEvent('load');
  }

  return <OldTestingLayout {...props} />;
};

export default injectIntl(TestingPage);
