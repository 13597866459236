import createExperimentPageRenderer from 'gelato/frontend/src/lib/createExperimentPageRenderer';
import DocumentSelectPage from 'gelato/frontend/src/local_pages/document_select';
import EmailVerificationPage from 'gelato/frontend/src/local_pages/email_verification';
import EmailVerificationPageV2 from 'gelato/frontend/src/local_pages/email_verification_v2';
import IndexPage from 'gelato/frontend/src/local_pages/index';
import InvalidPage from 'gelato/frontend/src/local_pages/invalid';
import InvalidPageV2 from 'gelato/frontend/src/local_pages/invalid_v2';
import LinkOTPPage from 'gelato/frontend/src/local_pages/link_otp';
import LinkReusePage from 'gelato/frontend/src/local_pages/link_reuse';
import PhoneVerificationPage from 'gelato/frontend/src/local_pages/phone_verification';
import PhoneVerificationPageV2 from 'gelato/frontend/src/local_pages/phone_verification_v2';
import ReloadPage from 'gelato/frontend/src/local_pages/reload';
import ReloadPageV2 from 'gelato/frontend/src/local_pages/reload_v2';
import SelfieVerificationMethodSelectPage from 'gelato/frontend/src/local_pages/selfie_verification_method_select';
import SelfieVerificationMethodPageV2 from 'gelato/frontend/src/local_pages/selfie_verification_method_select_v2';
import StartPage from 'gelato/frontend/src/local_pages/start';
import SubmitPage from 'gelato/frontend/src/local_pages/submit';
import SubmitPageV2 from 'gelato/frontend/src/local_pages/submit_v2';
import SuccessPage from 'gelato/frontend/src/local_pages/success';
import SuccessPageV2 from 'gelato/frontend/src/local_pages/success_v2';
import TestPage from 'gelato/frontend/src/local_pages/testing';
import TestPageV2 from 'gelato/frontend/src/local_pages/testing_v2';
import VerifyPage from 'gelato/frontend/src/local_pages/verify';
import VerifyAuthPage from 'gelato/frontend/src/local_pages/verify_auth';
import VerifyInactivePage from 'gelato/frontend/src/local_pages/verify_inactive';
import VerifyOptionsPage from 'gelato/frontend/src/local_pages/verify_options';
import VerifyTestPage from 'gelato/frontend/src/local_pages/verify_testing';
import VerifyWelcomePage from 'gelato/frontend/src/local_pages/verify_welcome';
import WelcomePage from 'gelato/frontend/src/local_pages/welcome';
import WelcomeConsentPage from 'gelato/frontend/src/local_pages/welcome_consent_page';
import WelcomePageV2 from 'gelato/frontend/src/local_pages/welcome_v2';

// const to use to stub out an async loaded promise.
// These values must be replaced in `importModules`
export const PromiseStubPage = 'STUB_FOR_FUTURE_PROMISE';

type Routes = Record<string, React.ReactNode>;

// The routes defined here are what is used to determine what's included
// in the production build. All routes in the /local_pages folder should be
// available in development.
// WARNING: The block below is parsed from Ruby in gelato/service/id_prod_server.rb
//   If making formatting changes, that naive parsing could stop working.
//   If changing routes, update the test in gelato/service/test/idprod_server.rb
// NOTE: All the variable names of pages must end with the suffix "Page" that
//   is checked by "Opus::Gelato::Service::IdProdServer.get_js_page_routes".
export const routes: Routes = {
  '/start': StartPage,
  '/testing': createExperimentPageRenderer(
    'testing_page_v2',
    TestPage,
    TestPageV2,
  ),
  '/': IndexPage,
  '/continue': createExperimentPageRenderer(
    'welcome_page_v2',
    WelcomePage,
    WelcomePageV2,
  ),
  '/consent': PromiseStubPage,
  '/document_types': PromiseStubPage,
  '/document_upload': PromiseStubPage,
  '/document_select': DocumentSelectPage,
  '/end': PromiseStubPage,
  '/face_upload': PromiseStubPage,
  '/submit': createExperimentPageRenderer(
    'butter_m4_1_end_pages',
    SubmitPage,
    SubmitPageV2,
  ),
  '/individual': PromiseStubPage,
  '/invalid': createExperimentPageRenderer(
    'butter_m4_1_end_pages',
    InvalidPage,
    InvalidPageV2,
  ),
  '/success': createExperimentPageRenderer(
    'butter_m4_1_end_pages',
    SuccessPage,
    SuccessPageV2,
  ),
  '/welcome': createExperimentPageRenderer(
    'welcome_page_v2',
    WelcomePage,
    WelcomePageV2,
  ),
  '/welcome_consent': createExperimentPageRenderer(
    'butter_m4_1_end_pages',
    WelcomeConsentPage,
    WelcomePageV2,
  ),
  '/email_verification': createExperimentPageRenderer(
    'butter_m4_1_end_pages',
    EmailVerificationPage,
    EmailVerificationPageV2,
  ),
  '/phone_verification': createExperimentPageRenderer(
    'butter_m4_1_end_pages',
    PhoneVerificationPage,
    PhoneVerificationPageV2,
  ),
  '/preview': PromiseStubPage,
  '/handoff': PromiseStubPage,
  '/link': PromiseStubPage,
  '/link_otp': LinkOTPPage,
  '/link_reuse': LinkReusePage,
  '/permissions': PromiseStubPage,
  '/v': VerifyPage,
  '/verify_auth': VerifyAuthPage,
  '/verify_inactive': VerifyInactivePage,
  '/verify_welcome': VerifyWelcomePage,
  '/verify_options': VerifyOptionsPage,
  '/verify_testing': VerifyTestPage,
  '/selfie_verification_method': createExperimentPageRenderer(
    'butter_m4_1_end_pages',
    SelfieVerificationMethodSelectPage,
    SelfieVerificationMethodPageV2,
  ),
  '/reload': PromiseStubPage,
};

// When called, loads the async pages.
// This is not called until after we complete the start session cycle so it
// does not delay initial welcome screen load.
let loaded = false;
export const importModules = () => {
  if (loaded) {
    return;
  }
  routes['/consent'] = import(
    'gelato/frontend/src/local_pages/image_consent'
  ).then((mod) => mod.default);
  routes['/preview'] = import('gelato/frontend/src/local_pages/preview').then(
    (mod) => mod.default,
  );
  routes['/document_types'] = import(
    'gelato/frontend/src/local_pages/document_types'
  ).then((mod) => mod.default);
  routes['/document_upload'] = Promise.all([
    import('gelato/frontend/src/local_pages/document_upload'),
    import('gelato/frontend/src/local_pages/document_upload_v2'),
  ]).then((mods) => {
    return createExperimentPageRenderer(
      'document_upload_page_v2',
      mods[0].default,
      mods[1].default,
    );
  });

  routes['/reload'] = createExperimentPageRenderer(
    'document_upload_page_v2',
    ReloadPage,
    ReloadPageV2,
  );

  routes['/end'] = import('gelato/frontend/src/local_pages/session_end').then(
    (mod) => mod.default,
  );

  routes['/individual'] = Promise.all([
    import('gelato/frontend/src/local_pages/individual'),
    import('gelato/frontend/src/local_pages/individual_v2'),
  ]).then((mods) => {
    return createExperimentPageRenderer(
      'butter_m4_1_end_pages',
      mods[0].default,
      mods[1].default,
    );
  });

  routes['/face_upload'] = Promise.all([
    import('gelato/frontend/src/local_pages/face_upload'),
    import('gelato/frontend/src/local_pages/face_upload_v2'),
  ]).then((mods) => {
    return createExperimentPageRenderer(
      'butter_m3',
      mods[0].default,
      mods[1].default,
    );
  });

  routes['/handoff'] = Promise.all([
    import('gelato/frontend/src/local_pages/handoff'),
    import('gelato/frontend/src/local_pages/handoff_v2'),
  ]).then((mods) => {
    return createExperimentPageRenderer(
      'welcome_page_v2',
      mods[0].default, // HandoffPage V1.
      mods[1].default, // HandoffPage V2.
    );
  });

  routes['/link'] = Promise.all([
    import('gelato/frontend/src/local_pages/link'),
    import('gelato/frontend/src/local_pages/link_v2'),
  ]).then((mods) =>
    createExperimentPageRenderer(
      'welcome_page_v2',
      mods[0].default, // LinkPage V1.
      mods[1].default, // LinkPage V2.
    ),
  );

  routes['/permissions'] = Promise.all([
    import('gelato/frontend/src/local_pages/permissions'),
    import('gelato/frontend/src/local_pages/permissions_v2'),
  ]).then((mods) =>
    createExperimentPageRenderer(
      'butter_m_3_1',
      mods[0].default,
      mods[1].default,
    ),
  );

  Object.entries(routes).forEach(([path, value]: [any, any]) => {
    // There should be no PromiseStubPage values left
    if (value === PromiseStubPage) {
      throw new Error(`No replacement for stubbed page ${path}`);
    }
  });
  loaded = true;
};

export const defaultComponent = IndexPage;

if (process.env.NODE_ENV !== 'production') {
  routes['/model-debug'] = import(
    'gelato/frontend/src/local_pages/model-debug'
  ).then((mod) => mod.default);
  routes['/autocapture-debug'] = import(
    'gelato/frontend/src/local_pages/autocapture-debug'
  ).then((mod) => mod.default);
  routes['/face-debug'] = import(
    'gelato/frontend/src/local_pages/face-debug'
  ).then((mod) => mod.default);
}
