import type {RefObject} from 'react';
import {useEffect} from 'react';

// This behavior moved from useOverlayTrigger to useOverlayPosition.
// For backward compatibility, where useOverlayTrigger handled hiding the popover on close,
// it sets a close function here mapped from the trigger element. This way we can avoid
// forcing users to pass an onClose function to useOverlayPosition which could be considered
// a breaking change.
export const onCloseMap: WeakMap<Element, () => void> = new WeakMap();

interface CloseOnScrollOptions {
  triggerRef: RefObject<Element>;
  isOpen?: boolean;
  onClose?: () => void;
}

/** @private */
export function useCloseOnScroll(opts: CloseOnScrollOptions) {
  const {triggerRef, isOpen, onClose} = opts;

  useEffect(() => {
    if (!isOpen || onClose === null) {
      return;
    }

    const onScroll: EventListener = (e) => {
      // Ignore if scrolling an scrollable region outside the trigger's tree.
      const target = e.target;
      // window is not a Node and doesn't have contain, but window contains everything
      if (
        !triggerRef.current ||
        (target instanceof Node && !target.contains(triggerRef.current))
      ) {
        return;
      }

      const onCloseHandler = onClose || onCloseMap.get(triggerRef.current);
      if (onCloseHandler) {
        onCloseHandler();
      }
    };

    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [isOpen, onClose, triggerRef]);
}
