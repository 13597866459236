import {useMutation} from '@sail/data';

import type {GraphQlDocument, OperationVariables} from '@sail/data';

import type {GelatoMutationOptions, GelatoMutationReturn} from '../types';

export default function useGelatoMutation<
  TData,
  TVariables extends OperationVariables,
  TPermissions,
  THookVariables extends Partial<TVariables> = {},
>(
  query: GraphQlDocument<TData, TVariables, TPermissions>,
  options: GelatoMutationOptions<
    GraphQlDocument<TData, TVariables, TPermissions>,
    TPermissions,
    THookVariables
  >,
): GelatoMutationReturn<
  GraphQlDocument<TData, TVariables, TPermissions>,
  undefined,
  THookVariables
> {
  return useMutation(query, options) as GelatoMutationReturn<
    GraphQlDocument<TData, TVariables, TPermissions>,
    undefined,
    THookVariables
  >;
}
