import FetchError from 'gelato/frontend/src/api/errors/FetchError';
import NetworkError from 'gelato/frontend/src/api/errors/NetworkError';
import {serialize} from 'gelato/frontend/src/api/urls';

import type {XHROptions, FetchResult} from './types';

const buildRequest = ({
  data,
  contentType = 'application/x-www-form-urlencoded',
  method,
  url,
}: XHROptions) => {
  let serialized = '';

  if (data && contentType === 'application/x-www-form-urlencoded') {
    serialized = serialize(data);
  } else if (data && contentType === 'application/json') {
    serialized = JSON.stringify(data);
  }

  // For GET requests, use query strings.
  const requestUrl =
    method === 'GET' && serialized ? `${url}?${serialized}` : url;
  const requestData = method === 'GET' ? '' : serialized;
  return {requestUrl, requestData, contentType};
};

// Fetch backed fetch-like implementation
export const fetch = (options: XHROptions): Promise<FetchResult> => {
  return new Promise<FetchResult>(
    (
      resolve: (result: Promise<FetchResult> | FetchResult) => void,
      reject: (error?: any) => void,
    ) => {
      const {
        method,
        headers = {},
        keepalive,
        withCredentials,
        priority = 'auto',
      } = options;

      const {requestUrl, requestData, contentType} = buildRequest(options);

      const sanitizedHeaders: {[key: string]: string} = {
        Accept: 'application/json',
        'Content-Type': contentType,
      };

      if (headers) {
        Object.keys(headers).forEach((key) => {
          const value = headers[key];
          if (typeof value === 'string') {
            sanitizedHeaders[key] = value;
          }
        });
      }

      window
        .fetch(requestUrl, {
          method,
          keepalive,
          headers: sanitizedHeaders,
          body: requestData || undefined,
          mode: 'cors',
          credentials: withCredentials ? 'include' : 'omit',
          // not worth updating the whole TS library to get this type.
          priority,
        })
        .then((res: Response) => {
          if (res.status === 0) {
            if (withCredentials) {
              reject(new NetworkError(requestUrl));
            } else {
              return fetch({...options, withCredentials: true}).then(
                resolve,
                reject,
              );
            }
          }
          return res.text().then((responseText) => {
            resolve({
              responseURL: res.url,
              status: res.status,
              json: () => Promise.resolve(JSON.parse(responseText)),
              getResponseHeader(key) {
                return res.headers.get(key) || '';
              },
              responseText,
            });
          });
        })
        .catch((err) => {
          reject(new FetchError(err, requestUrl));
        });
    },
  );
};
