import {useMemoizedValue} from '@sail/react';
import {useCallback, useContext, useMemo, useRef} from 'react';
import {
  DEFAULT_SET_AND_UNSET_VALUE,
  DEFAULT_START_VALUE,
} from 'src/internal/constants';
import ObservabilityApiProvider from 'src/internal/provider/ObservabilityApiProvider';
import ObservabilityConfigProvider from 'src/internal/provider/ObservabilityConfigProvider';
import PerformanceMonitorStartContext from 'src/internal/provider/PerformanceMonitorStartContext';
import PerformanceMonitorTreeContext from 'src/internal/provider/PerformanceMonitorTreeContext';

import type {ReactElement, ReactNode} from 'react';
import type {ObservabilityConfig} from 'src/types';

type Props = {
  children?: ReactNode;
} & ObservabilityConfig;

/**
 * Sets the observability context for the current component tree.
 *
 * `ObservabilityProvider` can be nested to override values deeper in the tree,
 * specifically to reset the owner of a particular section of the React tree.
 *
 * The configuration provided to ObservabilityProvider` is merged with the
 * configuration provided by the closest `ObservabilityProvider` in the
 * component tree. The configuration provided to the current provider takes
 * precedence over the ancestor. Configurations are merged in a shallow manner,
 * meaning that nested objects are not merged.
 *
 * It is also worth noting that `ObservabilityProvider` does not capture
 * errors. If you would like to do that you should nest an `ErrorBoundary`.
 *
 * @example Basic {{include "./examples/ObservabilityProvider.basic.tsx"}}
 *
 * @see https://sail.stripe.me/apis/observability/ObservabilityProvider
 * @see https://sail.stripe.me/apis/observability/ErrorBoundary
 */
export default function ObservabilityProvider({
  children,
  ...config
}: Props): ReactElement {
  const stableConfig = useMemoizedValue(config);
  const startsRef = useRef(new Set<() => void>());
  const setAndUnsetParent = useContext(PerformanceMonitorTreeContext);
  const startParent = useContext(PerformanceMonitorStartContext);

  const setAndUnsetCurrent = useMemo(() => {
    return {
      set(callback: () => void) {
        startsRef.current.add(callback);
      },

      unset(callback: () => void) {
        startsRef.current.delete(callback);
      },
    };
  }, []);

  const startCurrent = useCallback(() => {
    startsRef.current.forEach((start) => start());
  }, []);

  const setAndUnset =
    setAndUnsetParent === DEFAULT_SET_AND_UNSET_VALUE
      ? setAndUnsetCurrent
      : setAndUnsetParent;

  const start =
    startParent === DEFAULT_START_VALUE ? startCurrent : startParent;

  return (
    <PerformanceMonitorTreeContext.Provider value={setAndUnset}>
      <PerformanceMonitorStartContext.Provider value={start}>
        <ObservabilityConfigProvider config={stableConfig}>
          <ObservabilityApiProvider>{children}</ObservabilityApiProvider>
        </ObservabilityConfigProvider>
      </PerformanceMonitorStartContext.Provider>
    </PerformanceMonitorTreeContext.Provider>
  );
}
