import * as React from 'react';
import type {ReactNode} from 'react';
import {more} from '@sail/icons/react/Icon';
import {createViewConfig} from '@sail/engine';
import type {View} from '../view';
import {Button, ButtonConfig} from './Button';
import {Icon} from './Icon';
import {Menu, MenuGroup, MenuItem} from './Menu';
import {useOverflowCollapse} from './hooks/useOverflowCollapse';
import {css} from '../css';
import {rovingTabIndex, composite} from './rovingTabIndex';

type TextDirection = 'ltr' | 'rtl';

export type ButtonGroupProps = View.IntrinsicElement<
  'div',
  {
    /**
     * Controls which axis the button group should span.
     * @external
     */
    direction?: 'row' | 'column';
    /**
     * Controls direction of text and horizontal overflow. Use `rtl` for languages written from right to left and `ltr` for those written from left to right.
     * @external
     */
    dir?: 'ltr' | 'rtl';

    /**
     * Controls whether or not buttons within the group collapse when there isn't enough space to display them without overflowing.
     * @external
     */
    collapse?: 'auto' | 'none';

    /**
     * Allows overriding the trigger element used for an overflow menu. Must be a component that supports press events.
     * @external
     */
    menuTrigger?: ReactNode;

    /**
     * The Buttons contained within the ButtonGroup.
     */
    children: ReactNode;
  }
>;

const DEFAULT_MENU_TRIGGER = (
  <Button aria-label="…">
    <Icon icon={more} />
  </Button>
);

export const ButtonGroupConfig = createViewConfig({
  props: {} as ButtonGroupProps,
  name: 'ButtonGroup',
  defaults: {
    direction: 'row',
    collapse: 'auto',
    dir: 'ltr',
    menuTrigger: DEFAULT_MENU_TRIGGER,
  },
  flattens: true,
});

/**
 * @external
 */
export const ButtonGroup = ButtonGroupConfig.createView(
  ({direction, collapse, dir, menuTrigger, children, ...props}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const {hiddenChildrenIndexes} = useOverflowCollapse({
      ref,
      menuTrigger,
      collapse,
      direction,
      textDirection: dir as TextDirection,
    });

    return (
      <div
        {...props}
        ref={ref}
        role="toolbar"
        aria-orientation={direction === 'row' ? 'horizontal' : 'vertical'}
      >
        {React.Children.map(children, (child) =>
          React.isValidElement<{'data-priority': number; type: string}>(
            child,
          ) && child.type === Button
            ? React.cloneElement<{'data-priority': number}>(child, {
                'data-priority': child.props.type === 'primary' ? 1 : 2,
              })
            : child,
        )}

        {collapse === 'auto' ? (
          <Menu trigger={menuTrigger}>
            {React.Children.map(children, (child, index) => {
              if (
                React.isValidElement(child) &&
                child.type === Button &&
                hiddenChildrenIndexes.has(index)
              ) {
                const menuItem = (
                  // TODO(cina): figure out what to do with Buttons that have href instead of onPress
                  <MenuItem
                    // eslint-disable-next-line react/jsx-handler-names
                    onAction={child.props.onPress}
                    disabled={child.props.disabled}
                    type={
                      child.props.type === 'destructive'
                        ? 'destructive'
                        : 'default'
                    }
                  >
                    {child.props.children}
                  </MenuItem>
                );

                return child.props.type === 'primary' ? (
                  <MenuGroup>{menuItem}</MenuGroup>
                ) : (
                  menuItem
                );
              } else {
                return null;
              }
            })}
          </Menu>
        ) : null}
      </div>
    );
  },
  {
    css: {
      gap: 'small',
    },
    forward: {direction: true},
    variants: {
      direction: {
        row: css({stack: 'x'}),
        column: css({stack: 'y'}),
      },
    },
    uses: [composite, ButtonConfig.customize({uses: [rovingTabIndex]})],
  },
);
