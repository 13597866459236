import {useEffect} from 'react';
import type {RefObject} from 'react';

/**
 * Applies props to an element, via a React ref.
 *
 * @param ref The element to apply the props to.
 * @param props The props to apply to the element.
 */
export const useProps = (
  ref: RefObject<HTMLElement>,
  props: React.DOMAttributes<HTMLElement> & {[key: `data-${string}`]: unknown},
) => {
  useEffect(() => {
    const listeners: (() => void)[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(props)) {
      // for each event handler prop, wire up an event listener on the reference element
      if (key.startsWith('on')) {
        const eventName = key.slice(2).toLowerCase();
        const handler = value as EventListener;
        const el = ref.current;
        if (el) {
          el.addEventListener(eventName, handler);
          listeners.push(() => {
            el.removeEventListener(eventName, handler);
          });
        }
      } else if (typeof value === 'boolean') {
        // if the value is a boolean, set or remove the attribute as appropriate
        // see: https://developer.mozilla.org/en-US/docs/Web/API/Element/setAttribute
        if (value) {
          ref.current?.setAttribute(key, '');
        } else {
          ref.current?.removeAttribute(key);
        }
      } else if (!value && value !== 0) {
        // if the value is empty, null or undefined, remove the attribute
        ref.current?.removeAttribute(key);
      } else {
        // otherwise, set the attribute to the value
        ref.current?.setAttribute(key, value);
      }
    }
    return () => {
      listeners.forEach((listener) => listener());
    };
  }, [ref, props]);
};
