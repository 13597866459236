import {createTokens} from '@sail/engine';

// TODO: the rgba(48, 49, 61) color in each of these values should be using the Neutral 800 token
const small = createTokens(
  'shadow.small',
  '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 2px 5px 0px rgba(48, 49, 61, 0.08)',
);

const medium = createTokens(
  'shadow.medium',
  '0px 3px 6px 0px rgba(0, 0, 0, 0.12), 0px 7px 14px 0px rgba(48, 49, 61, 0.08)',
);
const large = createTokens(
  'shadow.large',
  '0px 5px 15px 0px rgba(0, 0, 0, 0.12), 0px 15px 35px 0px rgba(48, 49, 61, 0.08)',
);
const xlarge = createTokens(
  'shadow.xlarge',
  '0px 5px 15px 0px rgba(0, 0, 0, 0.12), 0px 15px 35px 0px rgba(48, 49, 61, 0.08), 0px 50px 100px 0px rgba(48, 49, 61, 0.08)',
);

export const shadow = createTokens('shadow', {
  small,
  medium,
  large,
  xlarge,
  overlay: {
    $default: large,
    inverted:
      '0px 0px 15px 0px rgba(0, 0, 0, 0.12), 0px 0px 35px 0px rgba(48, 49, 61, 0.08)',
  },
  action: {
    primary: {
      // brand 900
      $default: '0px 1px 1px 0px rgba(20, 19, 78, 0.32)',
      pressed: '0px -1px 1px 0px rgba(20, 19, 78, 0.32)',
      hovered: '0px 1px 1px 0px rgba(20, 19, 78, 0.32)',
      focused: '0px 1px 1px 0px rgba(20, 19, 78, 0.32)',
      disabled: '0px 1px 1px 0px rgba(20, 19, 78, 0.32)',
    },
    secondary: {
      // neutral 900
      $default: '0px 1px 1px 0px rgba(16, 17, 26, 0.16)',
      pressed: '0px -1px 1px 0px rgba(16, 17, 26, 0.16)',
      hovered: '0px 1px 1px 0px rgba(16, 17, 26, 0.16)',
      focused: '0px 1px 1px 0px rgba(16, 17, 26, 0.16)',
      disabled: '0px 1px 1px 0px rgba(16, 17, 26, 0.16)',
    },
    destructive: {
      // critical 900
      $default: '0px 1px 1px 0px rgba(62, 2, 26, 0.32)',
      pressed: '0px -1px 1px 0px rgba(62, 2, 26, 0.32)',
      hovered: '0px 1px 1px 0px rgba(62, 2, 26, 0.32)',
      focused: '0px 1px 1px 0px rgba(62, 2, 26, 0.32)',
      disabled: '0px 1px 1px 0px rgba(62, 2, 26, 0.32)',
    },
  },
  form: {
    $default: {
      // neutral 900
      $default: '0px 1px 1px 0px rgba(16, 17, 26, 0.16)',
      pressed: '0px -1px 1px 0px rgba(16, 17, 26, 0.16)',
      hovered: '0px 1px 1px 0px rgba(16, 17, 26, 0.16)',
      focused: '0px 1px 1px 0px rgba(16, 17, 26, 0.16)',
      disabled: '0px 1px 1px 0px rgba(16, 17, 26, 0.16)',
    },
    accent: {
      // info 900
      $default: '0px 1px 1px 0px rgba(1, 28, 58, 0.16)',
      pressed: '0px -1px 1px 0px rgba(1, 28, 58, 0.16)',
      hovered: '0px 1px 1px 0px rgba(1, 28, 58, 0.16)',
      focused: '0px 1px 1px 0px rgba(1, 28, 58, 0.16)',
      disabled: '0px 1px 1px 0px rgba(1, 28, 58, 0.16)',
    },
    error: {
      // critical 900
      $default: '0px 1px 1px 0px rgba(62, 2, 26, .16)',
      pressed: '0px -1px 1px 0px rgba(62, 2, 26, .16)',
      hovered: '0px 1px 1px 0px rgba(62, 2, 26, .16)',
      focused: '0px 1px 1px 0px rgba(62, 2, 26, .16)',
      disabled: '0px 1px 1px 0px rgba(62, 2, 26, .16)',
    },
  },
});
