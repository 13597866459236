import {createCustomProperty} from '@sail/engine';
import type {Style} from '@sail/engine';
import {paddingBottomProp, paddingTopProp} from './pluginsBoxModel';
import {
  baselineAlignmentContent,
  hasBaselineLayout,
  columnGap,
  objectHeight,
} from './pluginsLayout';
import {transformAlignY} from './pluginsTransform';
import {universalSelectorDisabled} from '../utils';

type FontProperties = {
  current: Style.CustomProperty;
  inherited: Style.CustomProperty;
};

function createFontProperties(
  name: `--${string}`,
  options: Style.CustomPropertyOptions,
): FontProperties {
  return {
    current: createCustomProperty(name, options),
    inherited: createCustomProperty(`${name}-ref`, options),
  };
}

function setFontProperties(
  set: Style.PluginAPI,
  properties: FontProperties,
  value: string,
) {
  set.property(properties.current, value);

  if (!universalSelectorDisabled) {
    set.selector(`& > *, &::before, &::after`, () => {
      set.property(properties.inherited, value);
    });
  }
}

const fontSizeProp = createFontProperties('--font-size', {
  initialValue: '0px',
  inherits: true,
});
const lineHeightProp = createFontProperties('--line-height', {
  initialValue: '0px',
  inherits: true,
});
const ascenderProp = createFontProperties('--ascender', {
  initialValue: '0',
  inherits: true,
});
const capHeightProp = createFontProperties('--cap-height', {
  initialValue: '0',
  inherits: true,
});
const xHeightProp = createFontProperties('--x-height', {
  initialValue: '0',
  inherits: true,
});
const descenderProp = createFontProperties('--descender', {
  initialValue: '0',
  inherits: true,
});

const textBoxEdgeOverProp = createCustomProperty('--text-box-edge-over', {
  initialValue: '0px',
});
const textBoxEdgeUnderProp = createCustomProperty('--text-box-edge-under', {
  initialValue: '0px',
});
const hasBaselineFont = createCustomProperty('--font-metrics-multiplier', {
  initialValue: '0',
});

function capHeight(value: string, set: Style.PluginAPI): void {
  set.property('fontSize', () => {
    set.property(
      'fontSize',
      `calc(${value} / ${set.var(capHeightProp.current)})`,
    );
  });
}

function divide(a: string, b: string) {
  return `calc(${a} / ${b})`;
}

export function setFontMetrics(
  set: Style.PluginAPI,
  unitsPerEm: string,
  ascender: string,
  capHeight: string,
  xHeight: string,
  descender: string,
  _lineGap: string,
  _valueHash: string,
): void {
  set.property('--font-metrics', () => {
    setFontProperties(set, ascenderProp, divide(ascender, unitsPerEm));
    setFontProperties(set, capHeightProp, divide(capHeight, unitsPerEm));
    setFontProperties(set, xHeightProp, divide(xHeight, unitsPerEm));
    setFontProperties(set, descenderProp, divide(descender, unitsPerEm));
  });
}

function createFontPropertyPlugin(
  name: 'fontSize' | 'lineHeight',
  properties: FontProperties,
) {
  return (value: string, set: Style.PluginAPI): void => {
    set.property(name, () => {
      if (value !== 'inherit') {
        setFontProperties(set, properties, value);
      }
      set.property(name, value);
      set.property('baselineAlign', 'auto');
    });
  };
}

function getFontMetric(
  set: Style.PluginAPI,
  property: FontProperties,
  key: 'current' | 'inherited' = 'current',
) {
  const fontSize = set.var(fontSizeProp[key]);
  const metric = set.var(property[key]);
  return `calc(${fontSize} * ${metric})`;
}

function getReferenceBaseline(
  set: Style.PluginAPI,
  value: string,
  key: 'current' | 'inherited',
) {
  switch (value) {
    case 'cap-middle':
      return `calc(${getFontMetric(set, capHeightProp, key)} / 2)`;
    case 'x-middle':
      return `calc(${getFontMetric(set, xHeightProp, key)} / 2)`;
    case 'x-height':
      return getFontMetric(set, xHeightProp, key);
    case 'alphabetic':
    default:
      return '0px';
  }
}

function getTargetBaseline(set: Style.PluginAPI, value: string) {
  switch (value) {
    case 'object-bottom':
      return '0%';
    case 'object-middle':
      return '50%';
    case 'object-top':
      return '100%';
    default:
      return getReferenceBaseline(set, value, 'current');
  }
}

function getAlphabeticBaseline(
  set: Style.PluginAPI,
  key: 'current' | 'inherited',
): string {
  const ascender = getFontMetric(set, ascenderProp, key);
  const descender = getFontMetric(set, descenderProp, key);
  const lineHeight = set.var(lineHeightProp[key]);
  return `calc((${ascender} + ${descender} + ${lineHeight}) / 2)`;
}

function baselineAlign(value: string, set: Style.PluginAPI): void {
  set.property('--baseline-align', () => {
    const isActive = value === 'auto' || value === 'manual';

    if (!universalSelectorDisabled) {
      set.selector('& > *', () => {
        set.property(hasBaselineFont, isActive ? '1' : '0');
      });
    }

    if (value === 'auto') {
      set.selector('&::before', () => {
        set.reset({
          content: set.var(baselineAlignmentContent),
          userSelect: 'none',
          alignSelf: 'baseline',
          // The CSS `gap` property injects a gap between the ::before pseudo-element
          // and the node’s first child. We use a negative margin to remove that gap.
          marginRight: `calc(-1 * ${set.var(columnGap)})`,
        });
      });
    }
  });
}

function baseline(value: string, set: Style.PluginAPI): void {
  const inheritedBaseline = getAlphabeticBaseline(set, 'inherited');
  const currentBaseline = getAlphabeticBaseline(set, 'current');
  const baselineDiff = `(${inheritedBaseline} - ${set.var(
    objectHeight,
    currentBaseline,
  )})`;
  const multiplier = `${set.var(hasBaselineLayout)} * ${set.var(
    hasBaselineFont,
  )} * clamp(0, 10000 * ${set.var(ascenderProp.inherited)}, 1)`;
  function calc(value: string) {
    return `calc((${value}) * ${multiplier})`;
  }

  const marginTop = calc(
    `${baselineDiff} - ${set.var(paddingTopProp)} - 0.5px`,
  );
  set.property('marginTop', marginTop);

  const inheritedLineHeight = set.var(lineHeightProp.inherited);
  const currentLineHeight = set.var(lineHeightProp.current);
  const paddingBottom = set.var(paddingBottomProp);
  const marginBottom = `max(0, ${calc(
    `${inheritedLineHeight} - ${currentLineHeight} - ${baselineDiff} - ${paddingBottom}`,
  )})`;
  set.property('marginBottom', marginBottom);

  // eslint-disable-next-line prefer-const
  let [reference = 'alphabetic', target] = value.trim().split(' ');

  if (!target) {
    switch (reference) {
      case 'cap-middle':
      case 'x-middle':
      case 'x-height':
        target = 'cap-middle';
        break;
      default:
        target = 'alphabetic';
        break;
    }
  }

  const referenceBaseline = getReferenceBaseline(set, reference, 'inherited');
  const targetBaseline = getTargetBaseline(set, target);
  if (!referenceBaseline || !targetBaseline) {
    throw new Error(`Invalid baseline "${value}"`);
  }
  set.property(
    transformAlignY,
    calc(`${targetBaseline} - ${referenceBaseline}`),
  );
}

// https://www.w3.org/TR/css-inline-3/#leading-trim
// https://github.com/w3c/csswg-drafts/issues/8067
function textBoxTrim(value: string, set: Style.PluginAPI): void {
  // Leading trim only works on flow content
  set.property('displayInside', 'flow');

  const isStart = value === 'both' || value === 'start';
  const isEnd = value === 'both' || value === 'end';
  set.selector('&::before', () => {
    set.property('content', '""');
    set.property('display', isStart ? 'table' : 'none');
    set.property('marginBottom', isStart ? set.var(textBoxEdgeOverProp) : '0');
  });

  set.selector('&::after', () => {
    set.property('content', '""');
    // https://nicolasgallagher.com/micro-clearfix-hack/
    set.property('display', isEnd ? 'table' : 'none');
    set.property('marginTop', isEnd ? set.var(textBoxEdgeUnderProp) : '0');
  });
}

function getHalfLeading(set: Style.PluginAPI): string {
  const ascender = getFontMetric(set, ascenderProp);
  const descender = getFontMetric(set, descenderProp);
  const lineHeight = set.var(lineHeightProp.current);
  // Content area is based on definition of font height in Chromium:
  //   https://github.com/chromium/chromium/blob/d7da0240cae77824d1eda25745c4022757499131/third_party/blink/renderer/platform/fonts/font_metrics.h#L136-L144
  const contentArea = `(${ascender} - ${descender})`;
  return `calc((${lineHeight} - ${contentArea}) / 2)`;
}

function getTextBoxEdgeOver(set: Style.PluginAPI, value: string) {
  switch (value) {
    case 'cap':
      const capHeight = getFontMetric(set, capHeightProp);
      const ascenderCap = getFontMetric(set, ascenderProp);
      return `calc(${capHeight} - ${ascenderCap} - ${getHalfLeading(set)})`;
    case 'ex':
      const xHeight = getFontMetric(set, xHeightProp);
      const ascenderEx = getFontMetric(set, ascenderProp);
      return `calc(${xHeight} - ${ascenderEx} - ${getHalfLeading(set)})`;
    case 'normal':
      return '0px';
    case 'text':
    default:
      return `calc(-1 * ${getHalfLeading(set)})`;
  }
}

function getTextBoxEdgeUnder(set: Style.PluginAPI, value: string) {
  switch (value) {
    case 'alphabetic':
      const descender = getFontMetric(set, descenderProp);
      return `calc(${descender} - ${getHalfLeading(set)})`;
    case 'normal':
      return '0px';
    case 'text':
    default:
      return `calc(-1 * ${getHalfLeading(set)})`;
  }
}

// https://www.w3.org/TR/css-inline-3/#text-edges
// https://github.com/w3c/csswg-drafts/issues/8067
function textBoxEdge(value: string, set: Style.PluginAPI): void {
  // “The first value specifies the text over edge; the second value specifies
  // the text under edge. If only one value is specified, both edges are
  // assigned that same keyword if possible; else text is assumed as the
  // missing value.”
  const [over = 'normal', under = over] = value.trim().split(' ');

  set.selector('&::before', () => {
    const overValue = getTextBoxEdgeOver(set, over);
    if (overValue) {
      set.property(textBoxEdgeOverProp, overValue);
    }
  });
  set.selector('&::after', () => {
    const underValue = getTextBoxEdgeUnder(set, under);
    if (underValue) {
      set.property(textBoxEdgeUnderProp, underValue);
    }
  });
}

export const pluginsFont = {
  baseline,
  baselineAlign,
  capHeight,
  fontSize: createFontPropertyPlugin('fontSize', fontSizeProp),
  lineHeight: createFontPropertyPlugin('lineHeight', lineHeightProp),
  textBoxTrim,
  textBoxEdge,
};
