/**
 * Wait until a predicate is true or timeout is reached.
 * @param predicate The predicate to be evaluated.
 * @param timeout (optional) The timeout in milliseconds.
 * @param interval (optional) The interval in milliseconds.
 * @param timeoutMessage (optional) The error message to be thrown when timeout is reached.
 * @returns A promise that resolves with the time spent in milliseconds.
 */
export default function waitUntil(
  predicate: () => boolean,
  timeout: number = 10000,
  interval: number = 500,
  timeoutMessage: string = '',
): Promise<number> {
  return new Promise((resolve, reject) => {
    const start = Date.now();
    const check = () => {
      const timespent = Date.now() - start;
      if (predicate()) {
        resolve(timespent);
      } else if (timespent > timeout) {
        reject(new Error(timeoutMessage || 'waitUntil timeout'));
      } else {
        setTimeout(check, interval);
      }
    };
    check();
  });
}
