import {produce} from 'immer';
import {clamp} from 'lodash';

import {
  Feedback,
  IDInspectorResult,
  InputMethod,
  MicroBlinkCaptureInspectorResult,
} from 'gelato/frontend/src/controllers/states/DocumentState';
import ImageFrame from 'gelato/frontend/src/lib/ImageFrame';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';
import BaseInspector from 'gelato/frontend/src/ML/detectors/BaseInspector';
import {BLUR_THRESHOLD} from 'gelato/frontend/src/ML/lib/constants';

import type {DocumentSide} from '@stripe-internal/data-gelato/schema/types';
import type {InspectionState} from 'gelato/frontend/src/controllers/states/DocumentState';
import type {ApplicationState} from 'gelato/frontend/src/controllers/types';
import type {
  Rectangle,
  ModelProbabilities,
} from 'gelato/frontend/src/ML/IDDetectorAPI';

// The time to wait before using the best frame in milliseconds.
// 1500ms ~= 3 frames.
export const BEST_FRAME_WAIT_TIME = 1500;

// The singleton instance.
let instance: BestShotInspector | null = null;

// The record of the best frame.
type ScoreRecord = {
  // The inspection state of the best frame.
  inspectionState: InspectionState;
  // The score of the best frame.
  score: number;
  // The time when the best frame should be used by.
  useBy: number;
};

/**
 * This inspector to identify the best frame to use for document detection.
 *
 * The best frame is the frame with the highest score. The score is computed
 * based on the following factors:
 * - The coverage of the document in the image.
 * - The blurriness of the document.
 * - The probability of the document.
 *
 * When a new frame is received, the inspector will compute the score of the
 * frame. If the score is higher than the score of the best frame, the new frame
 * will be used as the best frame. Otherwise, the new frame will be discarded.
 *
 * The inspector will wait for a certain amount of time before using the best
 * frame. This is to allow the user to hold the camera steady to improve the
 * best frame.ㄋ
 */
export default class BestShotInspector extends BaseInspector<
  [Readonly<ApplicationState>, Readonly<InspectionState>],
  Readonly<InspectionState>
> {
  /**
   * The recorded data
   */
  _data: Map<string, ScoreRecord> = new Map();

  static displayName = 'BestShotInspector';

  static getInstance(): BestShotInspector {
    if (!instance) {
      instance = new BestShotInspector();
    }
    return instance;
  }

  /**
   * Whether the inspector is supported in the current environment.
   */
  static isSupported(): boolean {
    return true;
  }

  constructor() {
    super(BestShotInspector.displayName);
  }

  /**
   * @implements {BaseInspector}
   */
  protected async buildImpl(): Promise<void> {
    this._data.clear();
  }

  /**
   * @implements {BaseInspector}
   */
  protected async warmUpImpl(): Promise<void> {
    // Do nothing.
  }

  /**
   * @implements {BaseInspector}
   */
  protected async detectImpl(
    appState: Readonly<ApplicationState>,
    inspectionState: Readonly<InspectionState>,
  ): Promise<Readonly<InspectionState>> {
    // TODO(hedger): Add analytics to track the number of times we use the best
    // frame.

    const {workingInputMethod} = appState.document;

    if (workingInputMethod !== InputMethod.cameraAutoCapture) {
      // We can't resolve the best frame if we are not using the camera
      // with auto capture which provides multiple frames simultaneously.
      return inspectionState;
    }

    const score = computeFrameScore(inspectionState);

    const {id} = inspectionState;

    let record = this._data.get(id);

    const hasBetterFrame =
      score > 0 && ((record && record.score < score) || !record);

    if (hasBetterFrame) {
      // Found a better frame. Dispose the old frame.
      record?.inspectionState.inputImage.dispose();
      record?.inspectionState.documentImage?.dispose();
      record = {
        useBy: record?.useBy ?? Date.now() + BEST_FRAME_WAIT_TIME,
        inspectionState,
        score,
      };

      this._data.set(id, record);
    }

    if (record && record.useBy < Date.now()) {
      // We've waited long enough. Use the best frame.
      this._data.delete(id); // Release the record.
      return record.inspectionState;
    }

    if (record) {
      // While we have the best frame, we are still waiting for a better frame
      // to come in. Hold steady and wait for the best frame.

      return produce(inspectionState, (draft) => {
        // Replace the input image with a dummy image so that the real input
        // image will not be disposed.
        const dummyImage = new Image(
          inspectionState.inputImage.width,
          inspectionState.inputImage.height,
        );
        shallowMergeInto(draft, {
          documentImage: null,
          feedback: Feedback.holdSteady,
          inputImage: new ImageFrame(dummyImage),
        });
      });
    }

    // The current frame is not better than the best frame. Do nothing.
    return inspectionState;
  }

  /**
   * @implements {BaseInspector}
   */
  protected async disposeImpl(): Promise<void> {
    for (const record of this._data.values()) {
      record.inspectionState.inputImage.dispose();
      record.inspectionState.documentImage?.dispose();
    }
    this._data.clear();

    if (instance === this) {
      // The instance is being disposed and no longer usable. Clear the
      // singleton instance.
      instance = null;
    }
  }
}

/**
 * Compute the score of the frame. The score ranges from 0 to 1. The higher the
 * better.
 * @param inspectionState The inspection state to compute the score for.
 * @returns The score of the frame.
 */
function computeFrameScore(inspectionState: InspectionState): number {
  const {
    documentBlurScore,
    documentImage,
    documentIsValid,
    documentLocation,
    feedback,
    idInspectorResult,
    inputImage,
    microBlinkCaptureInspectorResult,
    side,
  } = inspectionState;

  const documentProbability = idInspectorResult?.probability;

  if (
    !documentBlurScore ||
    !documentImage ||
    !documentIsValid ||
    !documentLocation ||
    !documentProbability ||
    !inputImage ||
    feedback
  ) {
    return 0;
  }

  // We should look at multiple factors to determine the best frame.
  // For instance, high `coverage` often comes with low `sharpness`.
  const coverage = computeCoverage(inputImage, documentLocation);
  const sharpness = computeSharpness(documentBlurScore);
  const idInspectorScore = computeIDInspectorScore(side, idInspectorResult);
  const mbScore = computeMicroBlinkInspectorScore(
    side,
    microBlinkCaptureInspectorResult,
  );

  const scores = [coverage, sharpness, idInspectorScore, mbScore];
  return average(scores);
}

/**
 * Compute the coverage of the document in the image. The coverage ranges from
 * 0 to 1. The higher the better (i.e. higher resolutions).
 * @param inputImage The input image to compute the coverage for.
 * @param documentLocation The location of the document in the image.
 * @returns The coverage of the document in the image.
 */
function computeCoverage(
  inputImage: ImageFrame,
  documentLocation: Rectangle,
): number {
  if (!documentLocation) {
    return 0;
  }

  const {width, height} = inputImage;
  if (!width || !height) {
    return 0;
  }

  const [x, y] = documentLocation.topLeft;
  const [w, h] = documentLocation.dimensions;
  const vw = clamp(w, 0, width - x);
  const vh = clamp(h, 0, height - y);
  const visibleArea = vw * vh;
  const fullArea = width * height;
  return visibleArea / fullArea;
}

/**
 * Compute the sharpness of the document. The sharpness value ranges from 0 to
 * 1. The higher the better.
 * @param documentBlurScore The blur score of the document.
 * @returns The sharpness of the document.
 */
function computeSharpness(documentBlurScore: number): number {
  return clamp(documentBlurScore / BLUR_THRESHOLD, 0, 1);
}

/**
 * Compute the probability of the document. The probability ranges from 0 to 1.
 * The higher the better.
 * @param side The side of the document to compute the probability for.
 * @param documentProbability The probability of the document.
 * @returns The probability of the document.
 */
function computeDocumentProbability(
  side: 'front' | 'back',
  documentProbability: ModelProbabilities,
): number {
  if (!documentProbability) {
    return 0;
  }

  const {frontCard, frontPassport, back, invalid, noDocument} =
    documentProbability;

  // The probability of the document being valid. This ranges from 0 to 1.
  const validScore = (2 - invalid - noDocument) / 2;

  if (side === 'front') {
    return (frontCard + frontPassport + validScore) / 3;
  }

  return (back + validScore) / 2;
}

/**
 * Compute the score based on the ID inspector result.
 * @param side The side of the document to compute the score for.
 * @param result The result of the ID inspector.
 * @returns The score based on the ID inspector result. The score ranges from 0
 *   to 1. The higher the better.
 */
function computeIDInspectorScore(
  side: DocumentSide,
  result: IDInspectorResult | null,
): number {
  if (!result) {
    return 0;
  }
  const {probability} = result;
  return probability ? computeDocumentProbability(side, probability) : 0;
}

/**
 * Compute the score based on the MicroBlink ID inspector result.
 * @param side The side of the document to compute the score for.
 * @param result The result of the MicroBlink ID inspector.
 * @returns The score based on the MicroBlink ID inspector result. The score
 *  ranges from 0 to 1. The higher the better.
 */
function computeMicroBlinkInspectorScore(
  side: DocumentSide,
  result: MicroBlinkCaptureInspectorResult | null,
): number {
  if (!result) {
    return 0;
  }
  const {documentType, detectedImage, isValid} = result;
  if (!documentType || !detectedImage || !isValid) {
    // The detection failed.
    return 0;
  }
  return 1;
}

/**
 * Helper function to compute the average of the given numbers.
 * @param numbers The numbers to compute the average for.
 * @returns The average of the given numbers.
 */
function average(numbers: number[]): number {
  const total = numbers.reduce((sum, s) => sum + s, 0);
  const count = numbers.length;
  if (count) {
    return total / count;
  }
  return 0;
}
