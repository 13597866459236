import type {AriaButtonProps} from '@react-types/button';

import type {MenuTriggerType} from '@react-types/menu';
import type {RefObject} from 'react';
import type {KeyboardEvent, PressEvent} from '@react-types/shared';
import {useLongPress} from '../interactions';
import {useId} from '../utils';
import {useLocalizedStringFormatter} from '../i18n';
import type {MenuTriggerState} from '../../@react-stately/menu';
import {useOverlayTrigger} from '../overlays';
import intlMessages from './intl';
import type {AriaMenuOptions} from './useMenu';

export interface AriaMenuTriggerProps {
  /** The type of menu that the menu trigger opens. */
  type?: 'menu' | 'listbox';
  /** Whether menu trigger is disabled. */
  isDisabled?: boolean;
  /** How menu is triggered. */
  trigger?: MenuTriggerType;
}

export interface MenuTriggerAria<T> {
  /** Props for the menu trigger element. */
  menuTriggerProps: AriaButtonProps;

  /** Props for the menu. */
  menuProps: AriaMenuOptions<T>;
}

/**
 * Provides the behavior and accessibility implementation for a menu trigger.
 * @param props - Props for the menu trigger.
 * @param state - State for the menu trigger.
 */
export function useMenuTrigger<T>(
  props: AriaMenuTriggerProps,
  state: MenuTriggerState,
  ref: RefObject<Element>,
): MenuTriggerAria<T> {
  const {type = 'menu', isDisabled, trigger = 'press'} = props;
  const menuTriggerId = useId();
  const {triggerProps, overlayProps} = useOverlayTrigger({type}, state, ref);

  const onKeyDown = (e: KeyboardEvent | React.KeyboardEvent) => {
    if (isDisabled) {
      return;
    }

    if (trigger === 'longPress' && !e.altKey) {
      return;
    }

    if (ref && ref.current) {
      switch (e.key) {
        case 'Enter':
        // @ts-expect-error expected fallthrough
        case ' ':
          if (trigger === 'longPress') {
            return;
          }
        // fallthrough
        case 'ArrowDown':
          // Stop propagation, unless it would already be handled by useKeyboard.
          if (!('continuePropagation' in e)) {
            e.stopPropagation();
          }
          e.preventDefault();
          state.toggle('first');
          break;
        case 'ArrowUp':
          if (!('continuePropagation' in e)) {
            e.stopPropagation();
          }
          e.preventDefault();
          state.toggle('last');
          break;
        default:
          // Allow other keys.
          if ('continuePropagation' in e) {
            e.continuePropagation();
          }
      }
    }
  };

  const stringFormatter = useLocalizedStringFormatter(intlMessages);
  const {longPressProps} = useLongPress({
    isDisabled: isDisabled || trigger !== 'longPress',
    accessibilityDescription: stringFormatter.format('longPressMessage'),
    onLongPressStart() {
      state.close();
    },
    onLongPress() {
      state.open('first');
    },
  });

  const pressProps = {
    onPress(e: PressEvent) {
      if (isDisabled) {
        return;
      }

      if (e.pointerType !== 'touch' && e.pointerType !== 'keyboard') {
        // If opened with a screen reader, auto focus the first item.
        // Otherwise, the menu itself will be focused.
        state.toggle(e.pointerType === 'virtual' ? 'first' : null);
      }

      if (e.pointerType === 'touch') {
        state.toggle();
      }
    },
  };

  // omit onPress from triggerProps since we override it above.
  delete triggerProps.onPress;

  return {
    // @ts-expect-error - onFocus events between longpress and menutrigger are incompatible
    menuTriggerProps: {
      ...triggerProps,
      ...(trigger === 'press' ? pressProps : longPressProps),
      id: menuTriggerId,
      onKeyDown,
    },
    menuProps: {
      ...overlayProps,
      'aria-labelledby': menuTriggerId,
      autoFocus: state.focusStrategy || true,
      onClose: state.close,
    },
  };
}
