import {clearConsumerDataMutation} from 'gelato/frontend/src/graphql/mutations/useClearConsumerDataMutation';

// eslint-disable-next-line @sail/data-no-imperative-methods
import type {ApolloClient, FetchResult} from '@apollo/client';
import type {ClearConsumerDataMutationData} from 'gelato/frontend/src/graphql/mutations/useClearConsumerDataMutation';

/**
 * Performs the mutation that clears the consumer data.
 * @param apolloClient The apollo client.
 * @param variables The mutation variables.
 */
export default async function clearConsumerDataMutationMethod(
  apolloClient: ApolloClient<object>,
): Promise<FetchResult<ClearConsumerDataMutationData>> {
  // eslint-disable-next-line @sail/data-no-imperative-methods
  return apolloClient.mutate({
    mutation: clearConsumerDataMutation,
  });
}
