import {Label} from '@sail/ui';
import {ButtonLink} from '@stripe-internal/sail/Button';
import * as React from 'react';
import {IntlShape, defineMessages, injectIntl} from 'react-intl';
import {isEmail} from 'validator';

import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import Message from 'gelato/frontend/src/components/Message';
import OTPPageContent, {
  OTPMode,
} from 'gelato/frontend/src/components/OTPVerification';
import {SingleInputPageSkeleton} from 'gelato/frontend/src/components/SingleInputPageSkeleton';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import useUpdateIndividualMutation from 'gelato/frontend/src/graphql/mutations/useUpdateIndividualMutation';
import useValidateOneTimePasswordMutation from 'gelato/frontend/src/graphql/mutations/useValidateOneTimePasswordMutation';
import {PAGE_TITLE_MESSAGE} from 'gelato/frontend/src/lib/constants';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {useRouter, useSession} from 'gelato/frontend/src/lib/hooks';
import getRouter from 'gelato/frontend/src/lib/localRouter';
import Storage from 'gelato/frontend/src/lib/Storage';
import {TextInput} from 'sail/Input';
import {Body, Title} from 'sail/Text';

const messages = defineMessages({
  emailHeader: {
    id: 'otp.email.header',
    description: 'Header for email verification page',
    defaultMessage: 'Verify your email',
  },
  emailInputLabel: {
    id: 'otp.email.input.label',
    description: 'Label for input box for user provided email address',
    defaultMessage: 'Email',
  },
  sendOTPButton: {
    id: 'otp.email.send.otp',
    description: 'Text on button to send OTP code in email',
    defaultMessage: 'Send',
  },
  pageDescriptionEnter: {
    id: 'otp.email.description.enter',
    description:
      'Text requesting that you input an email address and explaining that we will send an OTP code to your email',
    defaultMessage:
      'Please enter your email address to receive a verification code.',
  },
  pageDescriptionConfirm: {
    id: 'otp.email.description.confirm',
    description: 'Text explaining that we will send an OTP code to your email',
    defaultMessage:
      'Confirm your email address to receive a verification code.',
  },
  decline: {
    id: 'otp.email.decline',
    description:
      'Text for link allowing user to say that they cannot verify the given email',
    defaultMessage: 'I cannot verify this email',
  },
});

const EmailVerificationPage = (props: {intl: IntlShape}) => {
  const {
    intl: {formatMessage},
  } = props;
  const session = useSession();
  const router = useRouter();
  const [updateIndividual] = useUpdateIndividualMutation();
  const [validateOTPMutation] = useValidateOneTimePasswordMutation();
  const needsUserEmail = session?.missingFields?.includes('email');
  const [confirmed, setConfirmed] = React.useState(needsUserEmail);
  const [confirming, setConfirming] = React.useState(false);
  const [declining, setDeclining] = React.useState(false);

  const needsOTP = React.useMemo(
    () => session?.missingFields?.includes('email_otp'),
    [session],
  );

  const merchantEmail =
    session?.collectedData?.individual?.email?.merchantProvidedAddress;
  const canUpdateEmail = !merchantEmail && !!session;
  const userEmail =
    session?.collectedData?.individual?.email?.userProvidedAddress;

  // The email address to show.
  const [email, setEmail] = React.useState<string>('');

  // Whether user had manually edited the email.
  const [isEmailEdited, setIsEmailEdited] = React.useState(false);

  // The side effect that initializes the email value.
  React.useEffect(() => {
    if (!email) {
      if (merchantEmail) {
        // For merchant provided email, we don't allow user to edit it.
        setEmail(merchantEmail);
      } else if (userEmail && !isEmailEdited) {
        // Default to user provided email if it's available and user hasn't
        // manually edited it.
        setEmail(userEmail);
      }
    }
  }, [email, isEmailEdited, merchantEmail, setEmail, userEmail]);

  const doRoute = React.useCallback(async () => {
    if (session) {
      const nextPage = await nextDataPageForSession(session);
      getRouter().push(nextPage);
    }
  }, [session]);

  React.useEffect(() => {
    if (!session) {
      // Wait for session to be ready
      return;
    }
    if (!needsOTP) {
      // Automatically route to the next page if we don't need to verify email
      // when session is ready.
      doRoute();
    } else if (Storage.getEmailOTPConsent()) {
      setConfirmed(true);
    }
  }, [doRoute, needsOTP, session]);

  const emailValid = isEmail(email);

  const onChange = (value: string) => {
    setIsEmailEdited(true);
    setEmail(value);
  };

  const confirm = React.useCallback(async () => {
    setConfirming(true);
    await updateIndividual({
      variables: {emailData: {address: email}},
    });
    setConfirmed(true);
  }, [email, updateIndividual]);

  const handleKeydown = React.useCallback(
    // this onKeyDown handler is just to support pressing enter to submit email
    (event) => {
      if (confirming || declining) {
        return;
      }
      // Pressed the Enter key
      if (event.keyCode === 13) {
        confirm();
      }
    },
    [confirming, declining, confirm],
  );

  const decline = React.useCallback(async () => {
    setDeclining(true);
    const declineResponse = await validateOTPMutation({
      variables: {emailDecline: true},
    });
    // in these cases, we know that the user just declined to otp verify and yet
    // they still have the requirement so we can send them to the invalid page
    const missingFields =
      declineResponse.data?.validateOneTimePassword?.session?.missingFields ||
      [];
    if (missingFields.includes('email_otp')) {
      router.push('/invalid');
    }
    setDeclining(false);
  }, [router, validateOTPMutation]);

  const generateDescription = React.useCallback(() => {
    if (canUpdateEmail) {
      return messages.pageDescriptionEnter;
    } else {
      return messages.pageDescriptionConfirm;
    }
  }, [canUpdateEmail]);

  const generateDeclineLink = React.useCallback(() => {
    if (!canUpdateEmail) {
      return (
        <ButtonLink
          id="decline-otp"
          label={<Message {...messages.decline} />}
          size="large"
          width="maximized"
          color="blue"
          onClick={decline}
          pending={declining}
          disabled={confirming || declining}
        />
      );
    }
  }, [declining, confirming, canUpdateEmail, decline]);

  if (!session) {
    // Wait for session to be ready.
    return <LoadingBox />;
  } else if (!confirmed && !confirming) {
    return (
      <SingleInputPageSkeleton
        helmet={<title>{formatMessage(PAGE_TITLE_MESSAGE)}</title>}
        title={
          <Title>
            <Message {...messages.emailHeader} />
          </Title>
        }
        inputDescription={
          <Body>
            <Message {...generateDescription()} />
          </Body>
        }
        inputLabel={
          <Label>
            <Message {...messages.emailInputLabel} />
          </Label>
        }
        inputElement={
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <TextInput
            autoFocus
            disabled={!canUpdateEmail}
            onChange={onChange}
            placeholder="jennyrosen@example.com"
            value={email}
            width="maximized"
            size="large"
            onKeyDown={handleKeydown}
          />
        }
        primaryButton={
          <ThemableButton
            color="blue"
            label={<Message {...messages.sendOTPButton} />}
            disabled={!emailValid || confirming || declining}
            onClick={confirm}
            width="maximized"
            size="large"
          />
        }
        secondaryLink={generateDeclineLink()}
      />
    );
  } else {
    return (
      <OTPPageContent mode={OTPMode.email} skipping={!needsOTP} email={email} />
    );
  }
};

export default injectIntl(EmailVerificationPage);
