import type {CSSProperties} from 'react';
import {createDependency} from './rule';
import type {StylisElement} from './types';

const SCOPED_PREFIX = '$$';
const CASCADING_PREFIX = '$';

// Capture groups: preceding character, variable type, variable name
const VAR_REGEXP = /(^|[\W])(\$\$?)([-\w]*)/g;

export function getCascadingProperty(property: string): string {
  return `--js-${property}`;
}

export function getScopedProperty(property: string): string {
  return `--local-${property}`;
}

const depCache = {} as Record<string, () => void>;

function addLocalDependency(key: string) {
  if (!depCache[key]) {
    const property = getScopedProperty(key);
    depCache[key] = createDependency(
      property,
      (deprecatedCss) => deprecatedCss.options({global: true})`
        * {
          ${property}: var(--unset);
        }
      `,
    );
  }
  depCache[key]();
}

export function declarationVariables(element: StylisElement): void {
  let needsUpdate = false;
  const property = element.props as string;

  if (property[0] === SCOPED_PREFIX[0] && property[0] === SCOPED_PREFIX[1]) {
    element.props = getScopedProperty(property.slice(SCOPED_PREFIX.length));
    needsUpdate = true;
  }

  const children = element.children as string;
  if (children.indexOf(CASCADING_PREFIX) !== -1) {
    element.children = children.replace(
      VAR_REGEXP,
      (match, previous, type, name) => {
        if (type === SCOPED_PREFIX) {
          needsUpdate = true;
          addLocalDependency(name);
          return `${previous}var(${getScopedProperty(name)})`;
        }
        if (type === CASCADING_PREFIX) {
          needsUpdate = true;
          return `${previous}var(${getCascadingProperty(name)})`;
        }
        return match;
      },
    );
  }

  if (needsUpdate) {
    element.value = element.children
      ? `${element.props}:${element.children};`
      : '';
  }
}

export function variableInlineStyles(
  map: Record<string, string>,
  scoped = true,
): CSSProperties {
  const result = {} as Record<string, string>;
  const keys = Object.keys(map);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const property = scoped
      ? getScopedProperty(key)
      : getCascadingProperty(key);
    result[property] = map[key];
  }
  return result;
}

/** @deprecated */
export function deprecatedVars(map: Record<string, string>): {
  style: CSSProperties;
} {
  return {style: variableInlineStyles(map)};
}
