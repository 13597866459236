import {dispute} from '@sail/icons/react/Icon';
import {view} from '@sail/ui';
import {SessionOperatingMode} from '@stripe-internal/data-gelato/schema/types';
import * as React from 'react';

import InvalidLayout from 'gelato/frontend/src/components/Invalid/InvalidLayout';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Link from 'gelato/frontend/src/components/LinkV3';
import Message from 'gelato/frontend/src/components/Message';
import {useBranding} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import Storage from 'gelato/frontend/src/lib/Storage';
import {InlineLink} from 'sail/InlineLink';

type Props = {
  operatingMode: SessionOperatingMode;
};

const ExpiredSession = (props: Props) => {
  const {operatingMode} = props;
  const refreshUrl = Storage.getRefreshUrl();
  const returnUrl = Storage.getReturnUrl();
  const url = refreshUrl || returnUrl;
  const branding = useBranding();
  const {platformName} = branding || {};

  React.useEffect(() => {
    Storage.clearSession();
    Storage.setRefreshUrl(refreshUrl);
    Storage.setReturnUrl(returnUrl);
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'authentication_error',
      code: 'session_expired',
    });
  });

  const title = <Message {...messages.expiredHeading} />;
  const description =
    operatingMode === 'secondary' ? (
      <Message {...messages.expiredSecondarySessionBody} />
    ) : (
      <Message {...messages.expiredSessionBody} />
    );

  const actions = url && (
    <InlineLink id="refresh-session-link" href={url}>
      <Message {...messages.failureLink} values={{platformName}} />
    </InlineLink>
  );

  return (
    <InvalidLayout title={title} icon={dispute} description={description}>
      <view.div>{actions}</view.div>
    </InvalidLayout>
  );
};

export const generateExpiredSessionPageContent = (
  platformName: string,
  operatingMode: SessionOperatingMode,
) => {
  const refreshUrl = Storage.getRefreshUrl();
  const returnUrl = Storage.getReturnUrl();
  const url = refreshUrl || returnUrl;
  return {
    icon: dispute,
    title: <Message {...messages.expiredHeading} />,
    description:
      operatingMode === 'secondary' ? (
        <Message {...messages.expiredSecondarySessionBody} />
      ) : (
        <Message {...messages.expiredSessionBody} />
      ),

    footer: (
      <view.div
        css={{stack: 'y', alignX: 'center', paddingBottom: 'space.150'}}
      >
        <Link id="refresh-session-link" href={url} type="secondary">
          <Message {...messages.failureLink} values={{platformName}} />
        </Link>
      </view.div>
    ),
  };
};
export default ExpiredSession;
