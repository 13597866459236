import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {updateDeviceStatusMutation} from 'gelato/frontend/src/graphql/mutations/useUpdateDeviceStatusMutation';

// eslint-disable-next-line @sail/data-no-imperative-methods
import type {ApolloClient, FetchResult} from '@apollo/client';
import type {
  UpdateDeviceStatusMutationVariables,
  UpdateDeviceStatusMutationData,
} from 'gelato/frontend/src/graphql/mutations/useUpdateDeviceStatusMutation';

/**
 * Performs the mutation to update the device status.
 * @param apolloClient The apollo client.
 * @param variables The mutation variables.
 */
export default async function updateDeviceStatusMutationMethod(
  apolloClient: ApolloClient<object>,
  variables: UpdateDeviceStatusMutationVariables,
): Promise<FetchResult<UpdateDeviceStatusMutationData>> {
  // eslint-disable-next-line @sail/data-no-imperative-methods
  const resp = await apolloClient.mutate({
    mutation: updateDeviceStatusMutation,
    variables,
  });

  if (resp.errors) {
    const message = resp.errors.map((e) => e.message).join('\n');
    throw new Error(message || ErrorCode.failedToUpdateDeviceStatusMutation);
  }
  return resp;
}
