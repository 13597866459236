import * as React from 'react';

import addDOMPointerEventDragHandlers from 'gelato/frontend/src/lib/addDOMPointerEventDragHandlers';
import getDOMEventClientPosition from 'gelato/frontend/src/lib/getDOMEventClientPosition';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const {useCallback, useEffect, useLayoutEffect, useRef, useState} = React;

type ScrollState = {
  offsetHeight: number;
  scrollHeight: number;
  scrollbar: boolean;
};

const DEFAULT_SCROLL_STATE: ScrollState = {
  // The height of the scrollable element.
  offsetHeight: 0,
  // The scrollable height.
  scrollHeight: 0,
  // Whether the scroll indicator is present.
  scrollbar: false,
};

/**
 * Helper function to create the scroll indicator element.
 * @returns The scroll indicator element.
 */
function createIndicatorElement(): HTMLDivElement {
  const el = document.createElement('div');
  el.style.cssText = `
    height: 0px;
    background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    border-radius: 6px;
    right: 0;
    top: 0;
    width: 6px;
  `.replace(/\s+/g, ' ');
  return el;
}

/**
 * Stop the event from bubbling up and prevent the default behavior.
 */
function stopEvent(e: Event): void {
  e.stopPropagation();
  e.preventDefault();
}

/**
 * Add the drag handlers to the indicator element. This allows the user to drag
 * the indicator to scroll the scrollable element.
 * @param indicatorElement The indicator element.
 * @param scrollAreaElement The scrollable element.
 * @returns The function to cancel the handlers.
 */
function addIndicatorDragHandlers(
  indicatorElement: HTMLElement,
  scrollAreaElement: HTMLElement,
): () => void {
  let bgColor = '';
  let clientY = 0;
  let dragging = false;
  let scrollTop = 0;
  return addDOMPointerEventDragHandlers(indicatorElement, {
    handleDragStart: (e) => {
      const pos = getDOMEventClientPosition(e);
      if (!pos) {
        // The DOM event isn't supported.
        return;
      }
      stopEvent(e);
      dragging = true;
      scrollTop = scrollAreaElement.scrollTop;
      clientY = pos[1];
      // Capture the pointer to the element once dragging starts.
      if (indicatorElement.setPointerCapture) {
        indicatorElement.setPointerCapture(e.pointerId);
      }
      // Make the indicator darker when dragging.
      bgColor = indicatorElement.style.backgroundColor;
      indicatorElement.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
    },
    handleDragMove: (e) => {
      if (dragging) {
        stopEvent(e);
        const posY = getDOMEventClientPosition(e)![1];
        const deltaY = (posY - clientY) * 4;
        // Drag to scroll.
        scrollAreaElement.scrollTop = scrollTop + deltaY;
      }
    },
    handleDragEnd: (e) => {
      if (dragging) {
        stopEvent(e);
        dragging = false;
        indicatorElement.style.backgroundColor = bgColor;
        if (indicatorElement.releasePointerCapture) {
          indicatorElement.releasePointerCapture(e.pointerId);
        }
      }
    },
  });
}

/**
 * The effect to display a scroll indicator to the scrollable element. The
 * scroll indicator is a thin bar that indicates the current scroll position and
 * the total scrollable height. When it's present, the native scrollbar will
 * be hidden and user would know that the element is scrollable.
 *
 * @param domRef The ref to the scrollable element.
 * @returns The scroll state.
 */
export default function useScrollIndicator(
  domRef: React.RefObject<HTMLElement>,
): ScrollState {
  const {appState} = useAppController();

  const indicatorRef = useRef<HTMLDivElement | null>(null);
  const cancelIndicatorHandlers = useRef<Function | null>(null);

  // The key to identify the scroll state.
  const scrollKey = useRef<string>('');

  // The scroll state.
  const [scrollState, setScrollState] =
    useState<ScrollState>(DEFAULT_SCROLL_STATE);

  // The callback to compute the scroll state.
  const computeScrollState = useCallback(() => {
    const dom = domRef.current;
    if (!dom) {
      return;
    }
    const {offsetHeight, scrollHeight} = dom;
    const scrollbar = scrollHeight > offsetHeight;
    const key = `${offsetHeight}-${scrollHeight}-${scrollbar}`;
    if (scrollKey.current !== key) {
      // The scroll state has changed.
      scrollKey.current = key;
      setScrollState({scrollbar, scrollHeight, offsetHeight});
    }
  }, [domRef, scrollKey, setScrollState]);

  useLayoutEffect(() => {
    // When the window resizes, the scroll state might have changed.
    window.addEventListener('resize', computeScrollState, true);
    return () => window.removeEventListener('resize', computeScrollState, true);
  }, [computeScrollState]);

  useLayoutEffect(() => {
    // When the app state changes, the scroll state might have changed.
    computeScrollState();
  }, [appState, computeScrollState]);

  // The effect to render the scroll indicator.
  useLayoutEffect(() => {
    const dom = domRef.current;
    if (!dom) {
      return;
    }
    const {offsetHeight, scrollHeight, scrollbar} = scrollState;
    if (!scrollbar) {
      // No need to render the scroll indicator.
      const indicator = indicatorRef.current;
      if (indicator) {
        indicator.style.visibility = 'hidden';
      }
      return;
    }

    if (!indicatorRef.current) {
      const el = createIndicatorElement();
      indicatorRef.current = el;
      cancelIndicatorHandlers.current = addIndicatorDragHandlers(el, dom);
    }

    const indicator = indicatorRef.current!;

    const indicatorHeight = Math.round(
      (offsetHeight / scrollHeight) * offsetHeight,
    );

    const position = window.getComputedStyle(dom).position;

    if (position === 'static') {
      dom.style.position = 'relative';
    }

    // Prevent the native scrollbar from showing up.
    dom.style.overflow = 'hidden';

    indicator.style.height = `${indicatorHeight}px`;
    indicator.style.visibility = 'visible';

    const scrollableHeight = Math.max(offsetHeight - indicatorHeight - 2, 0);
    const scale = (1 / (scrollHeight - offsetHeight)) * scrollableHeight;

    const moveIndicator = () => {
      const {scrollTop} = dom;
      const indicatorTop =
        scrollTop + Math.min(Math.floor(scrollTop * scale), scrollableHeight);
      indicator.style.transform = `translate3d(-4px,${indicatorTop}px,0)`;
    };

    let rid: number = 0;
    const handleScroll = () => {
      rid = window.requestAnimationFrame(moveIndicator);
    };

    dom.addEventListener('scroll', handleScroll, true);
    moveIndicator();

    if (!indicator.parentNode) {
      dom.appendChild(indicator);
    }

    return () => {
      // Clean up because the scroll state will change.
      // indicator.remove();
      dom.removeEventListener('scroll', handleScroll, true);
      window.cancelAnimationFrame(rid);
      rid = 0;
    };
  }, [domRef, scrollState]);

  useEffect(() => {
    return () => {
      // Clean up when the component unmounts.
      if (indicatorRef.current) {
        indicatorRef.current.remove();
        indicatorRef.current = null;
        const cancel = cancelIndicatorHandlers.current;
        cancelIndicatorHandlers.current = null;
        cancel && cancel();
      }
    };
  }, [indicatorRef]);

  return scrollState;
}
