import React from 'react';
import {createViewConfig} from '@sail/engine';
import type {View} from '../view';
import {visuallyHidden} from '../utilStyles/visuallyHidden';

type LabelProps = View.IntrinsicElement<
  'label',
  {
    /**
     * Used to refer to the id of the form control this input is labeling.
     * @external
     */
    htmlFor?: string;
    hidden?: boolean;
    id?: string;
    children?: React.ReactNode;
    /**
     * The type of label to use. This will determine the semantic meaning of the label.
     *
     * default: `label`
     */
    as: 'label' | 'legend';
  }
>;

export const LabelConfig = createViewConfig({
  props: {} as LabelProps,
  name: 'Label',
  flattens: true,
  defaults: {
    as: 'label',
  },
});

export const Label = LabelConfig.createView(
  (props) => {
    const Element = props.as as string;
    return <Element {...props} />;
  },
  {
    css: {
      color: 'text',
      font: 'label.medium.emphasized',
    },
    variants: {
      hidden: {
        true: visuallyHidden,
      },
    },
  },
);
