import {ApolloLink} from '@apollo/client';

import type {GraphQlConfig, InternalOperation} from 'src/types';

const WILDCARD = '*';

const errorLink = new ApolloLink(function errorLink(
  operation: InternalOperation,
) {
  const operationContext = operation.getContext();
  const queryTag = operationContext.queryTag;

  if (!queryTag) {
    throw new Error(
      'Could not detect @tag directive, make sure GraphQL operations are being transpiled',
    );
  }

  throw new Error(
    `Cannot find schema with key ${queryTag} in @sail/data configuration`,
  );
});

const getSplitFunction =
  (queryTag: string) =>
  (operation: InternalOperation): boolean =>
    queryTag === WILDCARD || operation.getContext().queryTag === queryTag;

function splitMultiple(
  schemas: Array<[string, GraphQlConfig['schemas'][string]]>,
): ApolloLink {
  const firstEntry = schemas.shift();

  if (!firstEntry) {
    return errorLink;
  }

  const [firstKey, first] = firstEntry;

  return ApolloLink.split(
    getSplitFunction(firstKey),
    first.apolloLink,
    splitMultiple(schemas),
  );
}

export default function createSplitLink(
  graphqlConfig: GraphQlConfig,
): ApolloLink {
  let schemasConfig = Object.entries(graphqlConfig.schemas);
  const wildcardConfig = graphqlConfig.schemas[WILDCARD];

  // The wildcard config must always be checked at the end
  // so we delete it from the array and re-add it so it's the
  // last one to be checked.
  if (wildcardConfig) {
    schemasConfig = schemasConfig.filter(([key]) => key !== WILDCARD);

    schemasConfig.push([WILDCARD, wildcardConfig]);
  }

  return splitMultiple(schemasConfig);
}
