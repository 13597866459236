import * as Sentry from '@sentry/browser';
import qs from 'qs';
import * as React from 'react';

import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import analytics from 'gelato/frontend/src/lib/analytics';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {getConfigValue} from 'gelato/frontend/src/lib/config';
import {useIsMounted} from 'gelato/frontend/src/lib/hooks';
import getRouter, {
  VERIFY_URL_PATTERN,
} from 'gelato/frontend/src/lib/localRouter';
import Storage from 'gelato/frontend/src/lib/Storage';
import {getApiHost} from 'gelato/frontend/src/lib/urlConfig';
import {StaticSettings} from 'gelato/frontend/src/services/apis/start_code';

export type SlugResponse = {
  active?: boolean;
  return_url?: string;
  pk?: string;
  _static?: StaticSettings;
};

const {useEffect, useRef} = React;

const fetchStaticDataForTemplateSlug = async (
  slug: string,
): Promise<SlugResponse> => {
  let headers;
  if (global.Headers) {
    headers = new Headers();
    const commitHash = getConfigValue('COMMIT_HASH');
    headers.set('Content-Type', 'application/json');
    headers.set('X-Stripe-Identity-Client-Version', commitHash);
    headers.set('X-Requested-With', 'fetch');
  }

  const url = `${getApiHost()}/api/template-data/${slug}`;

  let resp = new Response();
  try {
    resp = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers,
    });
  } catch (e) {
    Sentry.addBreadcrumb({
      category: 'link_slug',
      message: `Error fetching link slug: ${e}`,
      level: Sentry.Severity.Warning,
    });
  }

  if (resp.status === 401 || resp.status === 404) {
    return {};
  } else if (!resp.ok) {
    throw Error(`Request rejected with status ${resp.status}`);
  }

  return resp.json();
};

const fetchSlugFromLocation = (location: Location): string => {
  const match = VERIFY_URL_PATTERN.match(location.pathname);
  return (match && match.slug) || '';
};

type ExchangeLocationForStaticData = [
  (arg1: Location) => void,
  {
    data: SlugResponse | null | undefined;
    error: Error | null | undefined;
  },
];

const cleanClientReference = (raw: string) => {
  return raw.replace(/[^a-zA-Z0-9-_.]/g, '');
};

const useExchangeLocationForStaticData = (): ExchangeLocationForStaticData => {
  const [location, setLocation] = React.useState<Location>(window.location);
  const [data, setData] = React.useState<SlugResponse | null>(null);
  const [error, setError] = React.useState<any>(null);
  const isMounted = useIsMounted();

  const handleLocation = (loc: Location) => {
    const query = qs.parse(window.location.search.split('?')[1]);
    if (typeof query.email === 'string') {
      Storage.setPrefilledEmail(query.email);
    } else if (typeof query.prefilled_email === 'string') {
      Storage.setPrefilledEmail(query.prefilled_email);
    }

    if (typeof query.client_reference_id === 'string') {
      const cleanedReferenceId = cleanClientReference(
        query.client_reference_id,
      );
      Storage.setClientReferenceId(cleanedReferenceId);
    }

    setLocation(loc);
  };

  useEffect(() => {
    (async () => {
      Storage.clearStaticContent();

      try {
        const slug = fetchSlugFromLocation(location);
        Storage.setTemplateSlug(slug);
        const response = await fetchStaticDataForTemplateSlug(slug);
        response._static && Storage.setStaticContent(response._static);
        response.pk && Storage.setMerchantPublishableKey(response.pk);
        isMounted.current && setData(response);
      } catch (e) {
        isMounted.current && setError(e);
      }
    })();
  }, [isMounted, location]);

  return [handleLocation, {data, error}];
};

const VerifyPage = () => {
  const router = getRouter();
  // exchange location data for the static configuration data for this session
  // this sets all of this data in session storage and includes the merchan pk for this template
  const [setLocation, {data, error}] = useExchangeLocationForStaticData();
  const startPageLoaded = useRef(false);
  const startPageRedirecting = useRef(false);

  useEffect(() => {
    if (startPageLoaded.current) {
      return;
    }
    analytics.track('startPageLoaded', {
      timeStartLoaded: Date.now(),
    });
    startPageLoaded.current = true;
  }, [startPageLoaded]);

  useEffect(() => {
    setLocation(window.location);

    let nextPage;
    if (!error && !!data) {
      // if we have succeeded then indeed we are able to go right ahead to the welcome page
      if (data.active) {
        if (!Storage.getLivemode()) {
          nextPage = '/verify_testing';
        } else {
          nextPage = '/verify_welcome';
        }
      } else {
        nextPage = '/verify_inactive';
      }
      router.push(nextPage);
      if (startPageRedirecting.current) {
        return;
      }
      analytics.track('startPageRedirecting', {
        timeStartRedirecting: Date.now(),
        startPageRedirectedTo: nextPage,
      });
      startPageRedirecting.current = true;
    }
  }, [setLocation, data, error, router]);

  if (error) {
    throw error;
  }

  // this page is just a loading box plus some javascript to move us off of this page when finished
  return <LoadingBox height="100vh" />;
};

setComponentConfig(VerifyPage, {skipApp: true, v2: true});

export default VerifyPage;
