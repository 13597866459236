import snakeCase from 'lodash/snakeCase';

import asError from 'gelato/frontend/src/lib/asError';

import type {VerificationIntentErrorDetail} from '@stripe-internal/data-gelato/schema/types';

/**
 * @fileoverview Actions that manage the error state for the controller.
 */

export type ErrorState = {
  error: Error | null;
};

type State = ErrorState;

/**
 * Creates the initial error state for the controller.
 * @returns The initial state.
 */
export function createErrorState(): State {
  return {
    error: null,
  };
}

/**
 * Sets the error in the application state.
 * @param state The current state.
 * @param anyError The error to set.
 */
export function setError(state: State, anyError: any) {
  state.error = asError(anyError);
  // eslint-disable-next-line no-console
  console.error(state.error);
}

/**
 * The coded application errors.
 * Please sort the keys alphabetically.
 */
export const ErrorCode = {
  /* eslint sort-keys: ["error", "asc"] */
  appControllerContextValueMissing: '',
  browserNotSupported: '',
  cameraIsNotReady: '',
  canvasIsNotUsable: '',
  consentIsNotRequired: '',
  cropSizeIsInvalid: '',
  documentAutoCaptureNotSupported: '',
  documentAutoCaptureTimeout: '',
  documentMetadataFailedToUpdate: '',
  documentMetadataIsNotRequired: '',
  failedToConfirmDocumentImages: '',
  failedToCreateHandoffUrl: '',
  failedToCreateImageFrame: '',
  failedToEnumerateCameraDevices: '',
  failedToFetchTestImage: '',
  failedToGenerateOtp: '',
  failedToInitializeSession: '',
  failedToInitializeTensorflow: '',
  failedToInspect: '',
  failedToInspectDocument: '',
  failedToPostMessage: '',
  failedToReadPDFFile: '',
  failedToRefreshSession: '',
  failedToSendDocumentImage: '',
  failedToSendEmail: '',
  failedToSendSMS: '',
  failedToSubmit: '',
  failedToUpdateDeviceStatusMutation: '',
  failedToUpdateIndividualMutation: '',
  failedToUploadFile: '',
  failedToValidateOTP: '',
  fileDoesNotExist: '',
  fileSizeIsTooLarge: '',
  flowsSessionCreationFailedOther: '',
  flowsSessionCreationRateLimited: '',
  heic2anyFailed: '',
  illegalArguments: '',
  illegalDocumentUploadState: '',
  individualSanctionedCountry: '',
  individualUnderConsentAge: '',
  individualUnsupportedCountry: '',
  individualUnsupportedField: '',
  inspectionStopped: '',
  inspectorIsDisposed: '',
  inspectorIsNotReady: '',
  inspectorIsNotSupported: '',
  invalidEmail: '',
  invalidFileType: '',
  invalidInputMethod: '',
  invalidPointerId: '',
  invalidScale: '',
  invalidStep: '',
  microBlinkInspectorError: '',
  missingTranslationForFeedback: '',
  noTokenError: '',
  pdfJSFailedToAccessDocument: '',
  pdfJSImageNotFound: '',
  pdfJSNoPagesFound: '',
  pdfJSPasswordInvalid: '',
  pdfJSPasswordRequired: '',
  pdfJSTooManyPages: '',
  phoneNumberInvalid: '',
  phoneNumberRequired: '',
  routeToSamePath: '',
  sessionConsentDidNotUpdate: '',
  sessionDidNotRefresh: '',
  sessionDocumentTypeDidNotUpdate: '',
  sessionIsClosed: '',
  sessionIsEmpty: '',
  sessionStartedAtDidNotUpdate: '',
  testModeDataNotGenerated: '',
  testModeNotAllowed: '',
  unableToHandoffToMobileDevice: '',
  unexpectedFieldError: '',
  unexpectedHandoffSessionStarted: '',
  unexpectedOperatingMode: '',
  unexpectedRoutingReason: '',
  unexpectedSource: '',
  uploadHostIsNotSet: '',
  userActivationRequired: '',
  userIsNotOnboarded: '',
  validateOTPNotRequired: '',
  videoAlreadyHasStream: '',
  videoDidNotStart: '',
  videoDidNotStop: '',
  videoStreamNotAvailable: '',
  webcamMightBeUsedByAnotherProcess: '',
  webcamNotFound: '',
  webcamPermissionNotGranted: '',
};

// Prefix the error codes with `app_error` so that we could easily search for
// them in the logs / Sentry and Hubble.
Object.keys(ErrorCode).forEach((key: string) => {
  const name = key as keyof typeof ErrorCode;
  const code = `app_error_${snakeCase(name)}`;
  ErrorCode[name] = code;
});

/**
 * Translates the error message to predefined error codes at the GraphQL layer.
 * @param error
 * @returns The error detail.
 */
export function convertToErrorDetail(
  error: Error,
): VerificationIntentErrorDetail {
  switch (error.message) {
    case ErrorCode.browserNotSupported:
      return 'no_web_rtc';
    case ErrorCode.webcamPermissionNotGranted:
      return 'permission_denied';
    case ErrorCode.webcamNotFound:
      return 'no_webcam';
    case ErrorCode.videoDidNotStart:
    // fallthrough
    case ErrorCode.videoAlreadyHasStream:
    // fallthrough
    case ErrorCode.webcamMightBeUsedByAnotherProcess:
    // fallthrough
    case ErrorCode.videoStreamNotAvailable:
      return 'not_readable';

    default:
      return 'unknown';
  }
}
