import {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
} from 'gelato/frontend/src/lib/locale';

/**
 * @fileoverview Actions that manage the locale state for the controller.
 */

export type Locale = keyof typeof AVAILABLE_LOCALES;

export type LocaleLabel = (typeof AVAILABLE_LOCALES)[Locale];

export type LocaleValue = [Locale, LocaleLabel];

export type LocaleState = {
  locale: {
    availableValues: LocaleValue[];
    currentValue: LocaleValue;
    suggestedValues: LocaleValue[];
  };
};

const DEFAULT_LOCALE_VALUE: LocaleValue = [
  DEFAULT_LOCALE,
  AVAILABLE_LOCALES[DEFAULT_LOCALE],
];

export function createLocaleState(): LocaleState {
  return {
    locale: {
      availableValues: getAvailableOptions(),
      currentValue: DEFAULT_LOCALE_VALUE,
      suggestedValues: [DEFAULT_LOCALE_VALUE],
    },
  };
}

/**
 * Helper function to get the available locale options.
 * @returns The available locale options.
 */
function getAvailableOptions(): [Locale, LocaleLabel][] {
  const availableOptions: [Locale, LocaleLabel][] = Object.keys(
    AVAILABLE_LOCALES,
  ).map((key) => {
    const locale = key as Locale;
    const label = AVAILABLE_LOCALES[locale];
    return [locale, label];
  });
  availableOptions.sort(sortByLabel);
  return availableOptions;
}

/**
 * Sort the locale options by label.
 */
function sortByLabel(a: [Locale, LocaleLabel], b: [Locale, LocaleLabel]) {
  const labelA = a[1];
  const labelB = b[1];
  return labelA.localeCompare(labelB);
}
