import React, {forwardRef} from 'react';
import type {Ref, ComponentPropsWithoutRef} from 'react';
import {SafeLinkOptions, isSafeUrl} from './utils';

const cleanUrl = (url?: string, options?: SafeLinkOptions) => {
  if (!url) {
    return;
  }
  if (!isSafeUrl(url, options)) {
    return;
  }
  return url;
};

type SafeAnchorProps = ComponentPropsWithoutRef<'a'> & {
  safeLinkOptions?: SafeLinkOptions;
};
export const SafeAnchor = forwardRef(
  (
    {safeLinkOptions, ...props}: SafeAnchorProps,
    ref: Ref<HTMLAnchorElement>,
  ) => {
    return (
      <a ref={ref} {...props} href={cleanUrl(props.href, safeLinkOptions)} />
    );
  },
);

SafeAnchor.displayName = `SafeAnchor`;
SafeAnchor.defaultProps = {
  safeLinkOptions: undefined,
};
