import {clearCanvasRenderingContext2D} from 'gelato/frontend/src/lib/CanvasMemoryLeakPatch';
import {handleException} from 'gelato/frontend/src/lib/sentry';

// This module contains functions for managing a pool of reusable
// HTMLCanvasElement objects. The purpose of the module is to improve
// performance by reusing existing canvas objects instead of creating new ones
// each time they are needed.

// A <canvas> element can only have one rendering context at a time.
// Once the canvas is associated with a specific rendering context type,
// requesting a different rendering context type for the same canvas
// is not allowed by the browser and will return null. In order to make canvas
// truly usable, we'd only support one rendering context type "2d".
interface ReusableCanvas extends HTMLCanvasElement {
  __reusable?: boolean;
}

/**
 * An array that stores the reusable canvases.
 */
const canvasPool: HTMLCanvasElement[] = [];

let domElementIdCounter = 0;

/**
 * Returns an reusable canvas from the canvas pool, or creates a new one if
 * the pool is empty.
 */
export function requestReusableCanvas(): ReusableCanvas {
  return canvasPool.pop() || toReusableCanvas(document.createElement('canvas'));
}

/**
 * Releases an canvas back to the canvas pool for reuse.
 */
export function releaseReusableCanvas(canvas: ReusableCanvas): void {
  // Resizes the given HTMLCanvasElement to a very small size and clears its
  // contents. This is a workaround for an issue in Safari on iOS, where every
  // canvas created is kept in memory and not released even when no longer
  // referenced. This can cause memory usage to exceed 384MB and all canvas
  // contexts to become null. By resizing the canvas to a very small size and
  // clearing its contents, Safari replaces the original canvas in its storage
  // with our compressed version.
  const ctx = canvas.getContext('2d');
  if (ctx) {
    clearCanvasRenderingContext2D(ctx);
  }

  // Only reusable canvas could be safely added back to the pool.
  if (canvas.__reusable) {
    if (canvasPool.includes(canvas)) {
      reportError(`canvas had been released already`);
    } else {
      canvasPool.push(canvas);
    }
  }
}

/**
 * Report an error with a message on development environments, or sends the
 * error to Sentry on production.
 */
function reportError(message: string): void {
  const error = new Error(`CanvasPoolError: ${message}`);
  if (process.env.NODE_ENV === 'production') {
    // This sends error to Sentry without breaking the app.
    handleException(error, message);
  } else {
    // In DEV box, it should break the app.
    throw error;
  }
}

/**
 * Report the error when context of a released canvas is being accessed.
 */
function checkCanvasContextAccess(canvas: ReusableCanvas): void {
  if (canvasPool.includes(canvas)) {
    // Attempting to access the context of a released canvas is not allowed,
    // as it indicates that the canvas has been prematurely released while
    // it's still being used elsewhere in the code. Please make sure that
    // the canvas is no longer needed before releasing it using the
    // releaseCanvas() function.
    reportError(`Reading canvas context from a released canvas is not allowed`);
  }
}

/**
 * Helper function.
 * Checks if an canvas is reusable.
 */
export function isReusableCanvas(canvas: ReusableCanvas): boolean {
  return !!canvas.__reusable && canvas instanceof HTMLCanvasElement;
}

/**
 * Helper function.
 * Makes canvas reusable.
 */
export function toReusableCanvas(canvas: ReusableCanvas): ReusableCanvas {
  if (!canvas.__reusable) {
    const originalGetContext = canvas.getContext;

    Object.assign(canvas, {
      // Inject the property `__reusable` which indicates that the canvas is
      // reusable.
      __reusable: true,
      // Inject the property `id` which indicates the number of reusable canvas
      // created.
      id: canvas.id || `reusable-canvas-${domElementIdCounter++}`,
      // Inject the property `getContext` which will catch the case when
      // a released canvas is being accessed.
      getContext: (contextId: any, options: any) => {
        if (contextId !== '2d') {
          // We only support reusable CanvasRenderingContext2D.
          // The rest rendering context types are not reusable.
          canvas.__reusable = false;
          // Catch the incorrect context type in case TypeScript does not catch
          // it.
          reportError(`Unsupported rendering context type ${contextId}`);
        }

        // No one should access to canvas's context when the canvas is released.
        checkCanvasContextAccess(canvas);

        const context = originalGetContext.call(canvas, contextId, options);
        if (!context) {
          throw new Error(`Failed to get canvas context ${contextId}`);
        }
        return context;
      },
    });
  }
  return canvas;
}

/**
 * Resets the canvas pool for JS tests.
 */
export function resetForJSTests(): void {
  if (process.env.PUPPETEER) {
    canvasPool.length = 0;
  }
}

/**
 * Get the canvas pool for JS tests.
 */
export function getCanvasPoolForJSTests(): HTMLCanvasElement[] {
  if (process.env.PUPPETEER) {
    return canvasPool.slice(0);
  }
  return [];
}
