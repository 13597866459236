import {ConsumerIdentityDocuments} from 'gelato/frontend/src/api/Consumer/api';

import type {ConsumerIdentityDocumentApi} from 'gelato/frontend/src/api/Consumer/types';
import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

export default async function loadConsumerIdentityDocuments(
  state: Readonly<ApplicationState>,
): Promise<ConsumerIdentityDocumentApi[]> {
  const docsResult = await ConsumerIdentityDocuments.list({
    credentials: {
      consumer_session_client_secret:
        state.networkedIdentity.consumerSession!.client_secret,
    },
    request_surface: 'web_identity_product',
  });

  return docsResult.type === 'object' ? docsResult.object.data : [];
}
