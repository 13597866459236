import WebcamException from 'gelato/frontend/src/components/webcam/WebcamException';

/**
 * Checks if the video element is ready to be used as a source for a canvas.
 * This throws an exception if the video element is not ready. Callers should
 * catch the exception and handle it appropriately.
 * @param video The video element to check.
 */
export default function checkWebcamVideo(video: HTMLVideoElement | null) {
  try {
    if (!video) {
      throw new WebcamException('video element not found');
    }

    const stream: MediaStream | null = video.srcObject as MediaStream;
    if (!stream) {
      throw new WebcamException(`video stream not found`);
    }

    // See https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
    const {readyState: videoReadyState} = video;
    if (!(videoReadyState >= 2)) {
      // We need at least HAVE_CURRENT_DATA (2) to be able to draw the current
      // frame of the video otherwise we get a black canvas.
      // See http://go/the-first-frame-captured-by-the-canvas-becomes-completely-black-after-outputting
      throw new WebcamException(`Invalid video readyState: ${videoReadyState}`);
    }

    const tracks = stream.getVideoTracks();

    const videoTrack = tracks.find((track) => track.kind === 'video');

    if (!videoTrack) {
      throw new WebcamException(`Video track not found`);
    }

    const {readyState: trackReadyState, muted} = videoTrack;
    if (trackReadyState !== 'live') {
      throw new WebcamException(
        `Invalid video track readyState: ${trackReadyState}`,
      );
    }

    if (muted === true) {
      // This happens when the app is in the background or the screen is locked.
      // Allowing the muted video would result in black canvas.
      // See DEMO https://jsfiddle.net/stripehedger/65n0kb2z/66/show
      throw new WebcamException(`Video track is muted`);
    }
  } catch (e) {
    if (e instanceof WebcamException) {
      throw e;
    } else {
      // We might have a compatibility issue with the browser.
      // Rethrow the exception as a WebcamException.
      throw new WebcamException(`checkWebcamVideo: ${String(e)}`);
    }
  }
}
