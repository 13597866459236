/* This file used to be generated from config by a script but we found that it was never updated and so removed the
 * script and left it as static configuration */

export default {
  AD: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AF: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AI: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AL: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AQ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AS: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AT: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AU: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AW: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AX: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  AZ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BB: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BD: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BF: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BH: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BI: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BJ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BL: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BN: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BQ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BS: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BT: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BV: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BW: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BY: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  BZ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CC: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CD: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CF: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CH: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CI: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CK: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CL: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CN: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CU: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CV: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CW: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CX: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CY: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  CZ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  DE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  DJ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  DK: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  DM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  DO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  DZ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  EC: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  EE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  EG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  EH: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  ER: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  ES: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  ET: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  FI: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  FJ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  FK: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  FM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  FO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  FR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GB: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GD: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GF: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GH: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GI: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GL: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GN: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GP: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GQ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GS: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GT: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GU: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GW: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  GY: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  HK: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  HM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  HN: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  HR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  HT: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  HU: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  ID: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  IE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  IL: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  IM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  IN: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  IO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  IQ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  IR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  IS: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  IT: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  JE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  JM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  JO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  JP: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  KE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  KG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  KH: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  KI: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  KM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  KN: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  KP: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  KR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  KW: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  KY: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  KZ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  LA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  LB: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  LC: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  LI: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  LK: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  LR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  LS: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  LT: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  LU: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  LV: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  LY: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MC: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MD: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  ME: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MF: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MH: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MK: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  ML: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MN: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MP: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MQ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MS: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MT: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MU: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MV: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MW: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MX: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MY: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  MZ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NC: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NF: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NI: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NL: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NP: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NU: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  NZ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  OM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PF: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PH: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PK: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PL: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PN: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PS: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PT: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PW: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  PY: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  QA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  RE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  RO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  RS: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  RU: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  RW: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SB: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SC: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SD: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SH: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SI: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SJ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SK: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SL: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SN: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SS: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  ST: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SV: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SX: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SY: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  SZ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TC: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TD: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TF: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TH: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TJ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TK: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TL: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TN: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TO: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TR: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TT: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TV: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TW: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  TZ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  UA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  UG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  UM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  US: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  UY: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  UZ: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  VA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  VC: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  VE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  VG: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  VI: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  VN: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  VU: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  WF: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  WS: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  YE: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  YT: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  ZA: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  ZM: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },

  ZW: {
    documentTypes: ['driving_license', 'id_card', 'passport'],
  },
};
