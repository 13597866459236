import * as React from 'react';

import type {ExperimentName} from '@stripe-internal/data-gelato/schema/types';

export type ExperimentPageRenderer = {
  control: ConfigurableComponent;
  experimentName: ExperimentName;
  treatment: ConfigurableComponent;
};

// Define a union type for different types of React components.
export type ConfigurableComponent = React.ComponentType<any>;

// Define the configuration object for a component.
export type ComponentConfig = {
  componentName?: string;
  experimentPageRenderer?: ExperimentPageRenderer;
  ignoreExpiredSession?: boolean;
  isTerminal?: boolean;
  skipApp?: boolean;
  skipWarnOnUnload?: boolean;
  v2?: boolean;
};

const ComponentConfigMap: Map<string, ComponentConfig> = new Map();

let configIDCounter = 0;

/**
 * Function to set the static configuration for a component.
 */
export function setComponentConfig(
  component: ConfigurableComponent,
  config: ComponentConfig,
) {
  const id = `config_${configIDCounter++}`;
  ComponentConfigMap.set(id, {...config});

  // We need to store the id of the config directly at the component so that
  // `injectIntl()` can copy it over.
  Object.assign(component, {_componentConfigID: id});
}

/**
 * Return the static configuration for the component.
 */
export function getComponentConfig(
  component: ConfigurableComponent & {_componentConfigID?: string},
): Readonly<ComponentConfig> {
  const id = component._componentConfigID;
  return ComponentConfigMap.get(id || 'nan') || {};
}
