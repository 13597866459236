import {secretToString} from 'gelato/frontend/src/api/Consumer/private/secrets';
import prepareVerificationSessionForConsumerNetworkingMutation from 'gelato/frontend/src/controllers/utils/prepareVerificationSessionForConsumerNetworkingMutation';

import type {ApolloError, MutationPromiseReturn} from '@sail/data';
import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';
import type {PrepareVerificationSessionForConsumerNetworkingMutationDocument} from 'gelato/frontend/src/graphql/mutations/usePrepareVerificationSessionForConsumerNetworkingMutation';

export default async function prepareVerificationForNetworking(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
): Promise<
  MutationPromiseReturn<PrepareVerificationSessionForConsumerNetworkingMutationDocument>
> {
  const resp = await prepareVerificationSessionForConsumerNetworkingMutation(
    runtime.apolloClient,
    {
      // Use of secretToString is dangerous and it should not be
      // broadly used. It is used here because we need to pass the
      // consumer session secret to the backend so that we can
      // identify the consumer account to attach the document to.
      consumerSessionClientSecret: secretToString(
        state.networkedIdentity.consumerSession!.client_secret,
      ),
    },
  );
  const {errors, data} = resp;
  return {
    // Fix for the mistyping of errors in Apollo
    // https://github.com/apollographql/apollo-client/blob/eb2cfee1846b6271e438d1a268e187151e691db4/src/react/hooks/useMutation.ts#L165-L166
    errors: errors as unknown as ApolloError | undefined, // Deprecated
    error: errors as unknown as ApolloError | undefined,
    data,
  };
}
