import {createTokens} from '@sail/engine';

// TODO: should these reference spacing tokens?
const radiusScale = createTokens('border.radius', {
  none: 'none',
  xsmall: '4px',
  small: '6px',
  medium: '8px',
  large: '12px',
  rounded: '9999em',
});

const radius = createTokens('border.radius', {
  $default: radiusScale,
  action: radiusScale.small,
  form: radiusScale.small,
});

const style = createTokens('border.style', {
  solid: 'solid',
  dashed: 'dashed',
});

const width = createTokens('border.width', {
  small: '1px',
  medium: '2px',
  large: '4px',
});

export const border = createTokens('border', {
  radius,
  style,
  width,
});
