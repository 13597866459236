import type {
  LocalizedString,
  LocalizedStrings,
} from '@internationalized/string';
import {
  LocalizedStringDictionary,
  LocalizedStringFormatter,
} from '@internationalized/string';
import {useMemo} from 'react';
import {useLocale} from './context';

const cache = new WeakMap();
function getCachedDictionary<K extends string, T extends LocalizedString>(
  strings: LocalizedStrings<K, T>,
): LocalizedStringDictionary<K, T> {
  let dictionary = cache.get(strings);
  if (!dictionary) {
    dictionary = new LocalizedStringDictionary(strings);
    cache.set(strings, dictionary);
  }

  return dictionary;
}

/**
 * Provides localized string formatting for the current locale. Supports interpolating variables,
 * selecting the correct pluralization, and formatting numbers. Automatically updates when the locale changes.
 * @param strings - A mapping of languages to localized strings by key.
 */
export function useLocalizedStringFormatter<
  K extends string = string,
  T extends LocalizedString = string,
>(strings: LocalizedStrings<K, T>): LocalizedStringFormatter<K, T> {
  const {locale} = useLocale();
  const dictionary = useMemo(() => getCachedDictionary(strings), [strings]);
  return useMemo(
    () => new LocalizedStringFormatter(locale, dictionary),
    [locale, dictionary],
  );
}
