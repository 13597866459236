import {createViewConfig} from '@sail/engine';
import {Link as SailLink, LinkProps as SailLinkProps, css} from '@sail/ui';
import * as React from 'react';

// LinkV3 is being introduced so that we can begin leveraging sail Link
// component directly while not yet trying to slice through the complexity of
// all the concerns that are handled by the current LinkV2.
// It is obviously unfortunate that we have 3 link components in our app :sob:

const Styles = {
  link: css({
    textAlign: 'center',
    userSelect: 'none',
    whiteSpace: 'normal',
    width: 'fill',
  }),
};

type Props = SailLinkProps & {
  children: React.ReactNode;
};

const ButtonConfig = createViewConfig({
  name: 'LinkV3',
  props: {} as Props,
  flattens: true,
  defaults: {
    size: 'large',
    type: 'primary',
  },
});

const Link = ButtonConfig.createView(({children, ...props}: Props) => {
  return (
    <SailLink uses={[Styles.link]} {...props}>
      {children}
    </SailLink>
  );
});

export default Link;
