import getActiveVideoTrack from 'gelato/frontend/src/components/webcam/getActiveVideoTrack';
import WebcamException from 'gelato/frontend/src/components/webcam/WebcamException';

type VideoDimensions = {
  width: number;
  height: number;
};

export const getVideoDimensions = (
  source: HTMLVideoElement | null | undefined,
): VideoDimensions => {
  if (!source) {
    throw new WebcamException('Video element not found');
  }
  const video: HTMLVideoElement = source;
  const stream = video.srcObject as any;
  if (!stream) {
    throw new WebcamException('Video stream not found');
  }
  const streamTracks = stream.getTracks();
  // todo(aywang): figure out how to strongly type this
  if (!streamTracks || streamTracks.length === 0) {
    throw new WebcamException('Video stream contains no tracks');
  }
  const activeTrack = getActiveVideoTrack(stream);
  if (!activeTrack) {
    throw new WebcamException('Video stream contains no active track');
  }
  const trackSettings = activeTrack.getSettings();
  const {width = 0, height = 0} = trackSettings;

  return {width, height};
};

export default getVideoDimensions;
