type ConfigValues =
  | 'API_HOST'
  | 'STRIPE_API_HOST'
  | 'ASSETS_CDN_PREFIX' // ASSETS_CDN_PREFIX is generated dynamically in gelato/service/id_prod_server.rb -> self.start_html
  | 'COMMIT_HASH' // COMMIT_HASH is generated dynamically in gelato/service/id_prod_server.rb -> self.start_html
  | 'MANAGE_HOST'
  | 'MANAGE_ADMIN_HOST'
  | 'MICROBLINK_LICENSE'
  | 'SENTRY_URL'
  | 'STATIC_CDN_PREFIX'
  | 'UPLOAD_HOST';

const CONFIG_AVAILABLE =
  process.env.NODE_ENV === 'production' &&
  !process.env.IS_CI &&
  !process.env.PUPPETEER;

// In production, config values are injected at runtime (when serving start.html from idprod-srv)
// into the HTML as a data value. Values are defined in gelato/frontend/config/{production|qa}.json
function parseConfig() {
  if (!CONFIG_AVAILABLE) {
    return {};
  }

  const root = document.getElementById('root');
  if (!root) {
    throw new Error('Could not find root dom element');
  }

  const dataConfig = root.getAttribute('data-config');
  if (!dataConfig) {
    throw new Error('Could not find data-config attribute in root element');
  }

  return JSON.parse(dataConfig);
}

// Cache the parsed config
// @ts-expect-error - TS7034 - Variable 'config' implicitly has type 'any' in some locations where its type cannot be determined.
let config;

export function getConfigValue(name: ConfigValues): string {
  // @ts-expect-error - TS7005 - Variable 'config' implicitly has an 'any' type.
  if (!config) {
    config = parseConfig();
  }

  const value = CONFIG_AVAILABLE ? config[name] : process.env[name];

  if (value == null) {
    throw new Error(`Config value undefined: ${name} ${config.toString()}`);
  }

  return value;
}
