import * as React from 'react';

import Background from 'gelato/frontend/src/components/Background'; // this is terrible but removing this import causes a weird build error =(
import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import useGetSessionQuery from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';
import {
  SessionInfo,
  SessionInfoContext,
} from 'gelato/frontend/src/layout/contexts/SessionInfoProvider';
import {
  ExperimentsContext,
  FeatureFlagsContext,
  SessionContext,
} from 'gelato/frontend/src/lib/contexts';
import experiments from 'gelato/frontend/src/lib/experiments';
import flags from 'gelato/frontend/src/lib/flags';
import useCanvasMemoryLeakPatch from 'gelato/frontend/src/lib/hooks/useCanvasMemoryLeakPatch';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {GraphQlField} from '@sail/data';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

type SessionQueryProps = {
  onSession: (arg1: GetSessionQueryData) => void;
};

/**
 * The SessionQuery component works around rules of hooks. Loading the
 * session is essentially a conditional operation because the EAK
 * needs to be present in local storage first. The app's goal is to
 * maintain a single, consistent loading state the entire time. So,
 * wrap `useSessionQuery` in a "component" that does nothing (always
 * renders `null`) and is purely used as a side-effect.
 *
 */

const SessionQuery = ({onSession}: SessionQueryProps) => {
  const {data, error} = useGetSessionQuery();

  if (error) {
    throw error;
  }

  React.useEffect(() => {
    let mounted = true;
    if (mounted && !error && data) {
      onSession(data);
    }
    return () => {
      mounted = false;
    };
  }, [data, error, onSession]);

  return null;
};

/**
 * This is global state boostrapped via `/start` or `WithSessionContext`.
 * The intent of SessionInfoContext is to have SessionInfo calculated exactly
 * once at the time of initial app load and then be re-used for all pages.
 * This means that SessionInfo can only contain "invariants" of the session
 * that are true for the entire flow through a single app load.
 *
 * These invairants include feature flags and experiments. The app takes
 * care to set up this global SessionInfo so that decisions about layout that
 * depend on knowledge of flags or experiements never need to block on
 * loading a fresh session. Overall, this allows the app to substantially
 * reduce the number of intermediate loading states that block initial render
 * of a page.
 */

type FlagList = GraphQlField<GetSessionQueryData, 'flags'>;

type ExperimentList = ReadonlyArray<
  GraphQlField<GetSessionQueryData, 'experiments'>
>;

let sessionInfo: SessionInfo | null | undefined = null;
let initialSession: GetSessionQueryData | null | undefined = null;

// For backward-compatibiltiy with v1, also keep track of flags and
// experiments so they can be provided as context. Direct use of
// these contexts is deprecated -- the preferred way to check flags
// is to use methods on the SessionInfo instance.
let flagList: FlagList = [];
let experimentList: ExperimentList = [];

// This is exported for testing only! See test-utils/renderWithProviders.js
export const storeGlobalSessionState = (
  session: GetSessionQueryData,
): GetSessionQueryData => {
  initialSession = session;
  sessionInfo = new SessionInfo(session);
  flagList = session.flags;
  experimentList = session.experiments;
  // Make experiments globally accessible.
  experiments.setExperiments(experimentList);
  // Make flags globally accessible.
  flags.setFlags(flagList);
  return session;
};

/**
 * Show loading UX, waiting for a signal that the session has started (EAK obtained
 * via `useLinkCode`) before attempting to `useSessionQuery`. This loading
 * UX is shared between the `/start` page and `WithSessionContext` that is used
 * by all other pages.
 */
type SharedSessionLoadingScreenProps = {
  sessionStarted: boolean;
  onReady: (arg1: GetSessionQueryData) => void;
};

export const SharedSessionLoadingScreen = ({
  sessionStarted,
  onReady,
}: SharedSessionLoadingScreenProps) => {
  const isUnmounted = React.useRef(false);
  React.useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, [isUnmounted]);

  const onSession = React.useCallback(
    (session) => {
      // To avoid race conditions, only store the session if the component
      // remains mounted. If the component has been unmounted, the next
      // component is responsible for fetching and storing the session.
      if (!isUnmounted.current) {
        const storedSession = storeGlobalSessionState(session);
        onReady(storedSession);
      }
      return session;
    },
    [isUnmounted, onReady],
  );

  return (
    <>
      <LoadingBox height="100vh" />
      {sessionStarted && <SessionQuery onSession={onSession} />}
    </>
  );
};

/**
 * SessionContextProvider is a hack that allows PageComponent to re-use
 * the session that SharedSessionLoadingScreen queried to provide SessionInfo
 * context. The initial session can be re-used exactly once. Once it is used,
 * make an unconditional `useSessionQuery()` because PageComponent relies on
 * global availability of a freshly-queried session.
 */
type SessionContextProviderProps = {
  children: React.ReactNode;
  ignoreExpiredSession: boolean | null | undefined;
};

const SessionContextProvider = ({
  children,
  ignoreExpiredSession,
}: SessionContextProviderProps) => {
  const {data: queryData, error} = useGetSessionQuery({
    // this is an absolute disaster of a messy hack
    // forcing no skip for the flows case so that we don't end up in trouble on the handoff page
    // unable to transition to the waiting view
    skip: !!initialSession && !Storage.getTemplateSlug(),
  });
  const data = initialSession || queryData;
  initialSession = null;

  // This was lifted out of PageComponent and brought here so that loading
  // and error status don't need to be passed down (loading status is calculated
  // in PageComponent as `session = useSession(); loading = !session`).
  if (error) {
    // Some pages can ignore expiredSession if there's already one in memory.
    if (!data || !ignoreExpiredSession) {
      throw error;
    }
  }

  return (
    <SessionContext.Provider value={data ? data.session : null}>
      {children}
    </SessionContext.Provider>
  );
};

/**
 * WithSessionContext is used to bootstrap SessionInfo when the app
 * is loaded on a page other than `/start`. This can happen if the app
 * refreshes or a link is pasted. The logic here is to use identical loading
 * state as the `/start` page, but to assume that an EAK was previously
 * obtained. If there is no EAK code, it's an error (full stop);
 *
 * When it is not boostrapping (i.e. `sessionInfo` is available), it
 * provides global context forward-compatible with v2 and backward-compatible
 * with v1.
 */

type WithSessionContextProps = {
  ignoreExpiredSession: boolean | null | undefined;
  children: React.ReactNode;
};

const WithSessionContext = ({
  children,
  ignoreExpiredSession,
}: WithSessionContextProps) => {
  useCanvasMemoryLeakPatch({experiments: experimentList});

  const [data, setData] = React.useState<
    GetSessionQueryData | null | undefined
  >(initialSession);

  if (data && sessionInfo) {
    return (
      <SessionInfoContext.Provider value={sessionInfo}>
        <FeatureFlagsContext.Provider value={flagList}>
          <ExperimentsContext.Provider value={experimentList}>
            <SessionContextProvider ignoreExpiredSession={ignoreExpiredSession}>
              {children}
            </SessionContextProvider>
          </ExperimentsContext.Provider>
        </FeatureFlagsContext.Provider>
      </SessionInfoContext.Provider>
    );
  } else {
    return <SharedSessionLoadingScreen sessionStarted onReady={setData} />;
  }
};

export default WithSessionContext;
