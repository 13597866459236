import {logInDev} from 'gelato/frontend/src/lib/assert';
import Experiments from 'gelato/frontend/src/lib/experiments';
import {requestReusableCanvas} from 'gelato/frontend/src/lib/ReusableCanvas';
import {handleException} from 'gelato/frontend/src/lib/sentry';

/**
 * Request a reusable canvas (when flag is enabled).
 */
export default function requestCanvas(purpose: string): HTMLCanvasElement {
  try {
    if (
      Experiments.isActive('fix_canvas_memory_leak', {
        logExposure: true,
      })
    ) {
      const canvas = requestReusableCanvas();
      // Display the ID of the canvas so we could tell how many canvas
      // elements were created. Ideally the less new canvas elements created,
      // the less memory would be consumed.
      logInDev('requestCanvas', {purpose, id: canvas.id});
      return canvas;
    }
  } catch (ex) {
    handleException(ex, 'requestCanvas error');
  }

  return document.createElement('canvas');
}
