import * as React from 'react';
import {createViewConfig} from '@sail/engine';
import {Action} from './Action';
import type {ActionProps, Href} from './Action';
import {IconConfig} from './Icon';
import {css} from '../css';
import {tokens} from '../tokens';

export type LinkProps = ActionProps & {
  /**
   * Controls the visual prominence of the link.
   */
  type?: 'primary' | 'secondary';
  /**
   * The URL to navigate to when the link is clicked.
   */
  href?: Href;
};

export const LinkConfig = createViewConfig({
  props: {} as LinkProps,
  name: 'Link',
  flattens: true,
  defaults: {
    type: 'primary',
    disabled: false,
  },
});

/**
 * A link is a clickable element that navigates to another page or resource.
 */
export const Link = LinkConfig.createView(
  (props) => {
    return <Action {...props} />;
  },
  {
    uses: [
      IconConfig.customize({
        defaults: {size: 'xsmall'},
      }),
    ],
    forward: {disabled: true},
    variants: {
      type: {
        primary: css({
          color: 'action.primary',
          ':hover': {
            color: 'action.primary.hovered',
          },
        }),
        secondary: css({
          color: 'action.secondary',
          textDecorationColor: tokens.color.neutral[300],
          textDecoration: 'underline',
          textDecorationStyle: 'dashed',
          textUnderlineOffset: '1.5px',
          ':hover': {
            textDecorationColor: tokens.color.text.action.primary,
            textDecorationStyle: 'solid',
          },
        }),
      },
      disabled: {
        true: css({
          color: 'action.primary.disabled',
          ':hover': {
            color: 'action.primary.disabled',
            cursor: 'default',
          },
        }),
      },
    },
    css: {
      baselineAlign: 'auto',
      fontWeight: 'semibold',
      // TODO(koop): We likely can remove the height/width properties here,
      //   which will make it easier to create block links.
      height: 'fit',
      width: 'fit',
      borderRadius: 'xsmall',
      gap: 'small',
    },
  },
);
