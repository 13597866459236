import {isInIframe} from 'gelato/frontend/src/lib/iframe';

export const hasiOSSheetCloseWindowHandler = () =>
  // @ts-expect-error - TS2339 - Property 'webkit' does not exist on type 'Window & typeof globalThis'.
  typeof window.webkit !== 'undefined' &&
  // @ts-expect-error - TS2339 - Property 'webkit' does not exist on type 'Window & typeof globalThis'.
  window.webkit.messageHandlers &&
  // @ts-expect-error - TS2339 - Property 'webkit' does not exist on type 'Window & typeof globalThis'.
  window.webkit.messageHandlers.closeWindow;

export const shouldShowCloseButton = (connectIframed: boolean) =>
  hasiOSSheetCloseWindowHandler() || (isInIframe() && !connectIframed);
