import {view, Select} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import analytics from 'gelato/frontend/src/lib/analytics';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

import type {Locale} from 'gelato/frontend/src/controllers/states/LocaleState';

const {useCallback} = React;

const Messages = defineMessages({
  language: {
    defaultMessage: 'Language',
    description: 'Label text for the language selector',
    id: 'verification.localeSelector.language',
  },
});

/**
 * The locale selector allows the user to change the language of the app.
 */
export default function LocaleSelector(): JSX.Element {
  const {appState, appController} = useAppController();
  const {locale} = appState;
  const handleSelect = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const index = event.target.selectedIndex;
      const selectedLocale = event.target.options[index].value;
      const root = document.documentElement;
      if (root) {
        root.lang = selectedLocale;
      }
      appController.changeLocale(selectedLocale as Locale);
      analytics.track('setLocale', {locale: selectedLocale});
    },
    [appController],
  );

  const options = locale.availableValues.map(([locale, label]) => {
    return (
      <view.option key={locale} value={locale}>
        {label}
      </view.option>
    );
  });

  return (
    <view.div>
      <Select
        label={<Message {...Messages.language} />}
        onChange={(e) => handleSelect(e)}
        value={locale.currentValue[0]}
      >
        {options}
      </Select>
    </view.div>
  );
}
