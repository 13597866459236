// We want to use WeakMaps with keys as Symbols to give a nice external API while at the same time
// provide garbage collection of apollo caches.
// To do so, we need the browser to support using Symbols as keys in WeakMaps. At the moment of writing
// this that's an experimental feature that's in stage 2 and is supported by chromium based browsers + safari.
// More info: https://www.proposals.es/proposals/Symbols%20as%20WeakMap%20keys
export default function getCompatibleMap(): WeakMapConstructor {
  if (typeof WeakMap === 'undefined') {
    return Map;
  }

  try {
    const map = new WeakMap();

    map.set(Symbol(''), true);

    return WeakMap;
  } catch {
    return Map;
  }
}
