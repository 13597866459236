import {cloneDeclaration, createInheritedProperty} from '../core';
import type {StylePlugin} from '../core';

export type AlignX = 'start' | 'center' | 'end';
export type AlignY = 'top' | 'center' | 'baseline' | 'bottom';

const flexAlignXMap: Record<string, string> = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
};

const flexAlignYMap: Record<string, string> = {
  top: 'flex-start',
  center: 'center',
  baseline: 'flex-start',
  bottom: 'flex-end',
};

export const layout: StylePlugin = {
  properties: {
    '--when-flow': createInheritedProperty('--when-flow'),

    'align-x': (element) => [
      cloneDeclaration(
        element,
        '--align-x',
        flexAlignXMap[element.children as string],
      ),
    ],

    'align-y': (element) => [
      cloneDeclaration(
        element,
        '--align-y',
        flexAlignYMap[element.children as string],
      ),
    ],

    display(element) {
      let isFlow = false;
      // eslint-disable-next-line default-case
      switch (element.children) {
        case 'block':
        case 'inline-block':
        case 'inline':
          isFlow = true;
          break;
      }

      const transformed = [
        cloneDeclaration(element, '--when-flow', isFlow ? ' ' : 'invalid'),
        element,
      ];

      if (element.children === 'inline') {
        transformed.push(cloneDeclaration(element, 'line-height', 'inline'));
      }

      return transformed;
    },

    layout(element) {
      switch (element.children) {
        case 'row':
          return [
            cloneDeclaration(element, 'display', 'flex'),
            cloneDeclaration(element, 'flex-direction', 'row'),
            cloneDeclaration(element, 'align-items', 'var(--align-y)'),
            cloneDeclaration(element, 'justify-content', 'var(--align-x)'),
          ];
        case 'column':
          return [
            cloneDeclaration(element, 'display', 'flex'),
            cloneDeclaration(element, 'flex-direction', 'column'),
            cloneDeclaration(element, 'align-items', 'var(--align-x)'),
            cloneDeclaration(element, 'justify-content', 'var(--align-y)'),
          ];
        default:
          return undefined;
      }
    },
  },
};
