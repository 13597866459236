import type {Context} from 'react';
import {useContext} from 'react';
import {createSetIntentType, getIntents} from '../../intent';
import {mergeProperty} from './props';
import type {IntentMap, Intent} from '../../intent';
import {onComponentWillReceiveProps} from './transformProps';

const setDefaultsIntent = createSetIntentType(
  'setDefaults',
  (decorate) =>
    <Props = unknown>(props: Props) =>
      decorate(props),
);

export const setDefaults = <Props = unknown>(
  props: Props,
  Context: Context<unknown>,
): Intent<unknown> => {
  return onComponentWillReceiveProps(() => {
    const overrides = useContext(Context) || {};
    return [setDefaultsIntent({...props, ...(overrides as Props)})];
  });
};

export function applyDefaults(props: IntentMap): void {
  const overrides = getIntents(setDefaultsIntent, props) || [];

  // flatten overrides into a single object, last call wins
  const defaults = Object.assign({}, ...Array.from(overrides));

  const target = props as unknown as Record<string, unknown>;
  const keys = Object.keys(defaults);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i] as string;
    if (!(key in props)) {
      mergeProperty(target, key, defaults[key]);
    }
  }
}
