import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import asError from 'gelato/frontend/src/lib/asError';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import fetchApiKeyForLinkCode from 'gelato/frontend/src/services/apis/start_code';
import storeLinkCodeFromWindowLocation from 'gelato/frontend/src/services/utils/storeLinkCodeFromWindowLocation';
import storeSessionStateFromStartCodeResponse from 'gelato/frontend/src/services/utils/storeSessionStateFromStartCodeResponse';

import type {
  SessionState,
  LocationType,
} from 'gelato/frontend/src/services/types';

export type LinkCodeResponse = {
  data: SessionState | null;
  error: Error | null;
};

export default async function fetchStartCodeResponseForLinkCode(
  location: LocationType,
): Promise<LinkCodeResponse> {
  try {
    const linkCode = storeLinkCodeFromWindowLocation(location);
    if (!linkCode) {
      throw new Error(ErrorCode.failedToInitializeSession);
    }

    const resp = await fetchApiKeyForLinkCode(linkCode);

    const state = storeSessionStateFromStartCodeResponse(resp);
    if (state.noToken && !state.refreshUrl) {
      throw new Error(ErrorCode.noTokenError);
    }
    return {
      data: state,
      error: null,
    };
  } catch (err: any) {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'authentication_error',
      code: 'session_does_not_exist',
    });

    return {
      data: null,
      error: asError(err),
    };
  }
}
