import {provider, animate} from '@sail/engine';
import {useContext, createContext, useState, useMemo} from 'react';
import type {MobileDisplay} from '../utilStyles/sheet';

type SheetStackContext = {
  registerChild(sheetHeight: number): void;
  unregisterChild(): void;
  parentContext?: SheetStackContext;
  showSheetOverlay: boolean;
};

export const SheetStackContext = createContext<SheetStackContext | undefined>(
  undefined,
);

export const useNestedSheets = (
  sheetRef: React.RefObject<HTMLElement>,
  mobileDisplay: MobileDisplay | 'desktop',
) => {
  const parentContext = useContext(SheetStackContext);
  const [isBackgroundSheet, setIsBackgroundSheet] = useState(false);

  const context = {
    registerChild() {
      setIsBackgroundSheet(true);
    },
    unregisterChild() {
      // when you remove the child, this sheet is no longer a background sheet
      // you can only remove the top sheet, so the parent of the top sheet
      // always becomes the top sheet when it's child is removed.
      setIsBackgroundSheet(false);
    },
    parentContext,
    showSheetOverlay: mobileDisplay === 'bottom-sheet' && isBackgroundSheet,
  };

  animate.useTimelineListeners(
    useMemo(
      () => ({
        in() {
          if (mobileDisplay !== 'bottom-sheet') {
            return;
          }
          // if there's a parent context, that means this sheet is being added
          // on top of another sheet. set that sheet's isBackgroundSheet to true
          // and update the height of the top sheet to the current sheet's height
          const sheet = sheetRef.current;
          if (parentContext && sheet) {
            parentContext.registerChild(sheet.offsetHeight);
          }
        },

        out() {
          if (parentContext) {
            parentContext.unregisterChild();
          }
        },
      }),
      [parentContext, sheetRef, mobileDisplay],
    ),
  );

  return {
    sheetStackProvider: provider(SheetStackContext, context),
  };
};
