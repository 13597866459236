import * as React from 'react';

import {useFeatureFlags} from 'gelato/frontend/src/lib/hooks';

import type {Flags} from '@stripe-internal/data-gelato/schema/types';

type Props = {
  children: React.ReactNode;
  flag: Flags;
};

// Hides children if a feature flag is not available
const FeatureFlag = ({children, flag}: Props) => {
  const flags = useFeatureFlags();

  if (!flags || !flags.includes(flag)) {
    return null;
  }

  return <>{children}</>;
};

export default FeatureFlag;
