/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

import type {FocusableElement} from '@react-types/shared';

import type {ForwardedRef, MutableRefObject, ReactNode} from 'react';
// no op comment to avoid playwright duplicate react import bug
import React, {useContext, useEffect, useRef} from 'react';
import {mergeProps, useSyncRef} from '../utils';
import type {PressProps} from './usePress';
import type {IPressResponderContext} from './context';
import {PressResponderContext} from './context';

interface PressResponderProps extends PressProps {
  children: ReactNode;
}

export const PressResponder = React.forwardRef(
  (
    {children, ...props}: PressResponderProps,
    ref: ForwardedRef<FocusableElement>,
  ) => {
    const isRegistered = useRef(false);
    const prevContext = useContext(PressResponderContext);
    const context: IPressResponderContext = mergeProps(prevContext || {}, {
      ...props,
      ref: (ref || prevContext?.ref) as MutableRefObject<FocusableElement>,
      register() {
        isRegistered.current = true;
        if (prevContext) {
          prevContext.register();
        }
      },
    });

    useSyncRef(
      prevContext as IPressResponderContext,
      ref as MutableRefObject<FocusableElement>,
    );

    useEffect(() => {
      if (!isRegistered.current) {
        // eslint-disable-next-line no-console
        console.warn(
          'A PressResponder was rendered without a pressable child. ' +
            'Either call the usePress hook, or wrap your DOM node with <Pressable> component.',
        );
        isRegistered.current = true; // only warn once in strict mode.
      }
    }, []);

    return (
      <PressResponderContext.Provider value={context}>
        {children}
      </PressResponderContext.Provider>
    );
  },
);

export function ClearPressResponder({children}: {children: ReactNode}) {
  return (
    <PressResponderContext.Provider value={undefined}>
      {children}
    </PressResponderContext.Provider>
  );
}
