import {updateConsentMutation} from 'gelato/frontend/src/graphql/mutations/useUpdateConsentMutation';

// eslint-disable-next-line @sail/data-no-imperative-methods
import type {ApolloClient, FetchResult} from '@apollo/client';
import type {
  UpdateConsentMutationVariables,
  UpdateConsentMutationData,
} from 'gelato/frontend/src/graphql/mutations/useUpdateConsentMutation';

/**
 * Performs the mutation that updates the consent.
 * @param apolloClient The apollo client.
 * @param variables The mutation variables.
 */
export default async function updateConsentMutationMethod(
  apolloClient: ApolloClient<object>,
  variables: UpdateConsentMutationVariables,
): Promise<FetchResult<UpdateConsentMutationData>> {
  // eslint-disable-next-line @sail/data-no-imperative-methods
  return apolloClient.mutate({
    mutation: updateConsentMutation,
    variables,
  });
}
