/* eslint-disable no-continue */
import {SPECIFICITY_TOKEN} from '../../../stylesheets';
import {hash, toHyphenCase} from '../../../util';
import {StyleCache} from './cache';
import {flattenStyles} from './flatten';
import {$cssValue} from './constants';
import {toCssIntent} from './intent';
import {nestSelectors} from './selector';
import {createValue} from './value';
import type {
  CssAliasShape,
  CssInputShape,
  CssPlugin,
  CssSelectorIndexer,
  CssSerializerShape,
  CssTokensShape,
  SerializedStyles,
  SerializedRulesets,
} from './types';

const whitespaceRegExp = /\s+/g;

function serializeDeclaration(key: string, value: string): string {
  return `${toHyphenCase(key)}: ${String(value).replace(
    whitespaceRegExp,
    ' ',
  )}; `;
}

interface Selectors {
  processed: string[];
  selector: CssSelectorIndexer;
}

function createSelectors(key: string, processed: string[]): Selectors {
  const className = new RegExp(`\\.${key}`, 'g');
  let isGlobal = true;
  let selector = processed.join(', ').replace(className, () => {
    isGlobal = false;
    return '&';
  });
  if (isGlobal) {
    selector = `:global ${selector}`;
  }
  return {
    processed,
    selector: selector as CssSelectorIndexer,
  };
}

function serializeDeclarations(
  serializer: CssSerializerShape,
  styles: SerializedStyles,
  layer: string,
  selectors: Selectors,
  properties: CssInputShape,
  activePlugins: Record<string, boolean> = {},
  isPartialRuleset = false,
): string {
  const tokenMap = serializer.token as unknown as CssTokensShape;
  const plugins = serializer.options.plugins as Record<
    string,
    CssPlugin<unknown, unknown>
  >;
  const aliases = serializer.options.alias as CssAliasShape<CssTokensShape>;

  const index = selectors ? styles.layers[layer].length : -1;
  const keys = Object.keys(properties);

  let serialized = '';

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    let value = properties[key];

    if (key === 'when' || value === undefined) {
      continue;
    }
    if (key === 'isolatedDependencies') {
      const dependencies = value as unknown as string[];
      for (let j = 0; j < dependencies.length; j++) {
        if (styles.isolated.indexOf(dependencies[j]) === -1) {
          styles.isolated.push(dependencies[j]);
        }
      }
      continue;
    }

    const alias = aliases[key];
    const tokens = alias && tokenMap[alias];
    if (tokens && typeof value !== 'object' && value in tokens) {
      value = createValue(
        tokenMap,
        `var(${tokens[value]})`,
      ) as unknown as string;
    }

    const plugin = !activePlugins[key] && plugins[key];
    if (plugin) {
      activePlugins[key] = true;

      const result = plugin(value, selectors.selector);
      if (result) {
        serialized += serializeDeclarations(
          serializer,
          styles,
          layer,
          selectors,
          result as unknown as CssInputShape,
          activePlugins,
          true,
        );
      }

      activePlugins[key] = false;
      continue;
    }

    if (typeof value === 'object' && !($cssValue in value)) {
      serializeDeclarations(
        serializer,
        styles,
        layer,
        createSelectors(styles.key, nestSelectors(selectors.processed, key)),
        value,
        activePlugins,
      );
      continue;
    }

    serialized += serializeDeclaration(key, value.toString());
  }

  const selectorsWithSpecificityToken = selectors.processed.map((selector) =>
    /**
     * /html(\s|\.|:|\[|$)/
     * \s -> match html tag followed by whitespace, i.e. `html div`
     * \. -> match classed html, i.e. `html.foo`
     * :  -> match html with pseudo-selector, i.e. `html:is(.foo)`
     * \[ -> match html with attribute selector, i.e. `html[foo]`
     * $  -> match html by its lonesome, i.e. `html`
     */
    selector.match(/html(\s|\.|:|\[|$)/)
      ? selector.replace('html', `html${SPECIFICITY_TOKEN}`)
      : `${SPECIFICITY_TOKEN} ${selector}`,
  );

  if (!isPartialRuleset && serialized) {
    styles.layers[layer].splice(
      index,
      0,
      `${selectorsWithSpecificityToken.join(', ')} { ${serialized}}`,
    );
    return '';
  }
  return serialized;
}

export function serialize(
  serializer: CssSerializerShape,
  propertiesArray: CssInputShape[],
): SerializedStyles {
  // TODO(koop): Can we speed this up?
  const key = `${serializer.options.prefix}${hash(
    `${JSON.stringify(propertiesArray)}${serializer.hash}`,
  )}`;
  const cached = StyleCache.get(key);
  if (cached) {
    return cached;
  }

  const className = key;
  const layer = serializer.options.layer;

  let serialized = false;
  function lazySerialize(styles: SerializedStyles) {
    if (serialized) {
      return;
    }
    serialized = true;
    for (let i = 0; i < propertiesArray.length; i++) {
      const properties = propertiesArray[i];
      const selectors = createSelectors(styles.key, [`.${className}`]);
      serializeDeclarations(serializer, styles, layer, selectors, properties);
    }

    flattenStyles(styles);
  }

  const classes = new Set([key]);
  const dependencies = [] as string[];
  const isolated = [] as string[];

  const rulesets = [] as string[] as SerializedRulesets;
  rulesets.className = className;
  const layers = {[layer]: rulesets};

  const styles = toCssIntent({
    key,

    get classes() {
      lazySerialize(styles);
      return classes;
    },
    get dependencies() {
      lazySerialize(styles);
      return dependencies;
    },
    get isolated() {
      lazySerialize(styles);
      return isolated;
    },
    get layers() {
      lazySerialize(styles);
      return layers;
    },
  });

  StyleCache.register(styles);

  if (process.env.NODE_ENV === 'test') {
    Object.defineProperties(styles, {
      serialized: {
        get() {
          return serialized;
        },
      },
      snapshot: {
        get() {
          return (
            styles.isolated
              .map((dep) => StyleCache.get(dep)?.snapshot || '')
              .join('\n') +
            Object.entries(styles.layers)
              .map(([layer, rulesets]) => {
                const css = (rulesets || []).join('\n');
                return layer && css ? `@layer ${layer} {${css}}` : css;
              })
              .join('\n')
          );
        },
      },
    });
  }

  return styles;
}
