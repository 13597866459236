import isEqual from 'lodash/isEqual';

import type {GraphQlField} from '@sail/data';
import type {DocumentTypes} from '@stripe-internal/data-gelato/schema/types';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

/**
 * @fileoverview Actions that manage the session state for the controller.
 * All session actions such mutations and queries should be defined here.
 */

export type SessionState = {
  // "session" is effectively owned and updated by GraphQL mutations and
  // queries. Therefore, it can't be mutated by the controller.
  session: Readonly<GraphQlField<GetSessionQueryData, 'session'>> | null;
  sessionCanceledByUserAt: number | null;
  sessionError: Error | null;
  sessionPending: boolean;
};

export const FLOW_SESSION_ID = 'synthetic_session';

type State = SessionState;

/**
 * Creates the initial session state for the controller.
 * @returns The initial session state.
 */
export function createSessionState(): State {
  return {
    session: null,
    sessionCanceledByUserAt: null,
    sessionPending: false,
    sessionError: null,
  };
}

/**
 * @returns Whether the session requires consent.
 */
export function isConsentRequired(state: State): boolean {
  return !!state.session?.requiredFields?.includes('consent');
}

/**
 * @returns Whether the session requires email otp verification.
 */
export function isEmailOTPVerificationRequired(state: State): boolean {
  return !!state.session?.requiredFields?.includes('email_otp');
}

/**
 * @param state The application state.
 * @param accepted Whether the consent is accepted.
 * @returns Whether the session has any consent value specified.
 */
export function hasConsentValue(state: State, accepted: boolean): boolean {
  return state.session?.collectedData?.individual.consent.accepted === accepted;
}

/**
 * @returns Whether the session requires document type.
 */
export function isDocumentTypeRequired(state: State): boolean {
  return !!state.session?.requiredFields?.includes('id_document_metadata');
}

export function isVerificationFlowSession(state: State): boolean {
  return state.session?.id === FLOW_SESSION_ID;
}

/**
 * @returns Whether the session has any document type provided.
 */
export function hasAnyDocumentType(state: State): boolean {
  return !!state.session?.collectedData?.individual.idDocument.type;
}

/**
 * @returns Whether the session has the document type provided.
 */
export function hasDocumentType(state: State, type: DocumentTypes): boolean {
  return state.session?.collectedData?.individual.idDocument.type === type;
}

/**
 * Whether the list of document types are the same regardless of their order.
 */
export function areSameDocumentTypes(
  expected: ReadonlyArray<DocumentTypes>,
  actual: ReadonlyArray<DocumentTypes>,
): boolean {
  if (expected.length !== actual.length) {
    return false;
  }
  return isEqual(new Set(expected), new Set(actual));
}
