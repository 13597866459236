import type {CssProperties, CssSerializer} from '@sail/engine/deprecated';
import {
  css as engineCss,
  pluginsBoxModel,
  pluginsBoxShadow,
  pluginsLayout,
  pluginsTransform,
  unstable_createMediaIntent,
} from '@sail/engine/deprecated';
import {StyleSheets} from '@sail/engine';
import {
  aliasColor,
  aliasOverflow,
  aliasRadius,
  aliasShadow,
  aliasSize,
  aliasSpace,
  aliasTypography,
  aliasZIndex,
} from './alias';
import {font} from './plugins';
import {
  tokensColor,
  tokensOverflow,
  tokensRadius,
  tokensShadow,
  tokensSize,
  tokensSpace,
  tokensTypography,
  tokensZIndex,
} from './tokens';
import {
  variantsColorScheme,
  variantsInputDevice,
  variantsPreferences,
  variantsViewport,
} from './variants';

const layer = 'app';

const variants = {
  ...variantsColorScheme,
  ...variantsInputDevice,
  ...variantsPreferences,
  ...variantsViewport,
};

const tokens = {
  ...tokensColor,
  ...tokensOverflow,
  ...tokensRadius,
  ...tokensShadow,
  ...tokensSize,
  ...tokensSpace,
  ...tokensTypography,
  ...tokensZIndex,
};

const alias = {
  ...aliasColor,
  ...aliasOverflow,
  ...aliasRadius,
  ...aliasShadow,
  ...aliasSize,
  ...aliasSpace,
  ...aliasTypography,
  ...aliasZIndex,
};

const plugins = {
  ...pluginsBoxModel,
  ...pluginsBoxShadow,
  ...pluginsLayout,
  ...pluginsTransform,
};

const cssA: CssSerializer<
  CssProperties,
  typeof variants,
  typeof plugins,
  typeof tokens,
  typeof alias
> = engineCss.configure({
  layer,
  variants,
  tokens,
  alias,
  plugins,
});

export const css = cssA.configure((css) => ({
  plugins: {
    ...font(css),
  },
}));

StyleSheets.insert([], 'reset');
StyleSheets.insert([], 'headless');
StyleSheets.insert([], 'theme');
StyleSheets.insert([], 'app');

export const unstable_media = unstable_createMediaIntent(css);

// TODO(koop): Uncomment this when we begin to use CssRegistry
// declare module '@sail/engine' {
//   interface CssRegistry {
//     default: typeof css;
//   }
// }
