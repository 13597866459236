import {logInDev} from 'gelato/frontend/src/lib/assert';

import type {Flags as FlagEnum} from '@stripe-internal/data-gelato/schema/types';

type FlagStringValue = FlagEnum & string;

/**
 * This class is used to store the flags that are returned by the GraphQL
 */
export class FlagsStore {
  _flags: FlagEnum[];

  constructor() {
    this._flags = [];
  }

  setFlags(flags: Readonly<FlagEnum[]>) {
    this._flags = flags.slice(0);
    logInDev(`FlagsStore : setFlags`, flags);
  }

  isActive(flag: FlagEnum | FlagStringValue): boolean {
    return !!this._flags?.find((f: FlagEnum) => f === flag);
  }
}

export default new FlagsStore();
