import type {Token} from '@sail/engine';
import type {
  ColorSpectrum,
  ColorStates,
  ColorProminencesAndStates,
} from './types';

export function createColorStates(
  spectrum: Token.Map<ColorSpectrum>,
  defaultIndex: keyof ColorSpectrum,
  disabledSpectrum: Token.Map<ColorSpectrum> = spectrum,
): ColorStates {
  // TODO: we need a helper for incrementing in *scale steps*, not integer amounts
  return {
    $default: spectrum[defaultIndex],
    hovered: spectrum[(defaultIndex + 100) as keyof ColorSpectrum],
    pressed: spectrum[(defaultIndex + 200) as keyof ColorSpectrum],
    focused: spectrum[defaultIndex],
    disabled: disabledSpectrum[(defaultIndex - 200) as keyof ColorSpectrum],
  };
}

export function createColorProminencesAndStates(
  spectrum: Token.Map<ColorSpectrum>,
): ColorProminencesAndStates {
  return {
    $default: createColorStates(spectrum, 500 as keyof ColorSpectrum),
    emphasized: createColorStates(spectrum, 600 as keyof ColorSpectrum),
    subdued: createColorStates(spectrum, 400 as keyof ColorSpectrum),
  };
}

const colorScales: Token.Key<ColorSpectrum>[] = [
  0 as Token.Key<ColorSpectrum>,
  25 as Token.Key<ColorSpectrum>,
  50 as Token.Key<ColorSpectrum>,
  100 as Token.Key<ColorSpectrum>,
  200 as Token.Key<ColorSpectrum>,
  300 as Token.Key<ColorSpectrum>,
  400 as Token.Key<ColorSpectrum>,
  500 as Token.Key<ColorSpectrum>,
  600 as Token.Key<ColorSpectrum>,
  700 as Token.Key<ColorSpectrum>,
  800 as Token.Key<ColorSpectrum>,
  900 as Token.Key<ColorSpectrum>,
];

const colorScalePosition: Record<Token.Key<ColorSpectrum>, number> = {
  0: 0,
  50: 1,
  100: 2,
  150: 3,
  200: 4,
  300: 5,
  400: 6,
  500: 7,
  600: 8,
  700: 9,
  800: 10,
  900: 11,
  950: 12,
};

export function incrementScaleValueBy(
  scaleValue: Token.Key<ColorSpectrum>,
  incrementBy: number,
): Token.Key<ColorSpectrum> {
  const scaleValueIndex = colorScalePosition[scaleValue];
  const newValueIndex = scaleValueIndex + incrementBy;

  if (newValueIndex < 0 || newValueIndex >= colorScales.length) {
    throw new Error(
      `Cannot increment color scale "${scaleValue}" by ${incrementBy}`,
    );
  }

  return colorScales[newValueIndex];
}

interface ColorTokenRoot {
  root: Token.Value<string>;
  scale: Token.Map<ColorSpectrum>;
  scaleValue: Token.Key<ColorSpectrum>;
  increment: (amount: number) => Token.Value<string>;
  decrement: (amount: number) => Token.Value<string>;
}

export function createColorRoot(
  scale: Token.Map<ColorSpectrum>,
  scaleValue: Token.Key<ColorSpectrum>,
): ColorTokenRoot {
  return {
    root: scale[scaleValue],
    scale,
    scaleValue,
    increment: (amount) => {
      return scale[incrementScaleValueBy(scaleValue, amount)];
    },
    decrement: (amount) => {
      return scale[incrementScaleValueBy(scaleValue, -1 * amount)];
    },
  };
}

function hexToRgb(hexColor: string): [number, number, number] {
  const hex = hexColor.replace('#', '');

  if (hex.length === 3) {
    const [r, g, b] = hex.split('');
    return [
      parseInt(`${r}${r}`, 16),
      parseInt(`${g}${g}`, 16),
      parseInt(`${b}${b}`, 16),
    ];
  }

  if (hex.length === 6) {
    const [r, g, b] = hex.match(/.{1,2}/g) ?? [];
    return [parseInt(r as string, 16), parseInt(g, 16), parseInt(b, 16)];
  }

  throw new Error(`Invalid hex color: ${hexColor}`);
}

export function hexWithAlpha(hexColor: string, alpha: number): string {
  const [r, g, b] = hexToRgb(hexColor);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
