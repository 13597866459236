import * as React from 'react';
import type {Key, ReactNode} from 'react';
import {Fragment, useContext, useRef} from 'react';
import {
  provider,
  assignProps,
  createView,
  createViewConfig,
  view,
  media,
  unprocessedCss,
} from '@sail/engine';
import type {TreeState} from '@sail/react-aria';
import type {AriaLabelingProps, LinkDOMProps} from '@react-types/shared';
import {
  useFocusRing,
  FocusScope,
  PressResponder,
  useMenuTriggerState,
  useMenu,
  useMenuItem,
  useMenuTrigger,
  useMenuSection,
} from '@sail/react-aria';
import {Action} from './Action';
import type {Href} from './Action';

import type {View} from '../view';

import {
  useTreeState,
  useCollection,
  CollectionProvider,
  useItem,
  IndexedGroup,
} from '../deprecated/components/collections';
import {walkForKey} from '../collections-deprecated';
import type {ActionCallback} from '../deprecated/components/collections/types';
import {Popover} from './Popover';
import {visuallyHidden} from '../utilStyles/visuallyHidden';
import {css} from '../css';
import {Divider} from './Divider';
import {Heading} from './Heading';
import {tokens} from '../tokens';
import {MenuConfig} from './MenuConfig';

export interface MenuProps extends AriaLabelingProps {
  /**
   * Handler that is called when an item is selected.
   */
  onAction?: (key: Key) => void;
  /**
   * Handler that is called when the menu closes.
   */
  onClose?: () => void;
  /**
   * Handler that is called when the menu opens/closes.
   */
  onOpenChange?: (isOpen: boolean) => void;
  /**
   * The trigger Element to show/hide the menu. Must be a component that supports press events, such as a Button or Link.
   */
  trigger?: ReactNode;
  /** The contents of the menu. */
  children: ReactNode;

  /**
   * className to be applied to the containing div
   */
  className?: string;

  ref?: React.Ref<HTMLDivElement>;

  subviews?: View.Subviews<{
    popover: typeof Popover;
  }>;
}

export interface MenuGroupProps {
  /**
   * A heading grouping the menu items.
   */
  title?: ReactNode;
  /** Child items that belong to the group. */
  children: ReactNode;
  /**
   * An accessibility label for the group.
   */
  'aria-label'?: string;

  subviews?: View.Subviews<{
    separator: typeof Divider;
    heading: typeof Heading;
  }>;
}

export interface MenuItemProps extends Omit<LinkDOMProps, 'href'> {
  /** Rendered contents of the item */
  children: ReactNode;
  /**
   * Handler that is called when an item is selected.
   */
  onAction?: (key: Key) => void;
  /**
   * The id of the item. This will be passed into the `onAction` handler of `Menu`
   */
  id?: string;
  /**
   * Marks an item as disabled. Disabled items cannot be selected, focused, or otherwise interacted with.
   */
  disabled?: boolean;
  /**
   * An accessibility label for this item.
   */
  'aria-label'?: string;
  /**
   * The content of the MenuItem as text. Useful if the MenuItem contains
   * nested children, but you still want it to be accessible via typeahead.
   */
  textValue?: string;
  /**
   * The type of the item
   */
  type?: 'default' | 'destructive';
  /**
   * Native `href` attribute that can be augmented by the `@sail/navigation` integration.
   */
  href?: Href;
}

interface MenuContextValue {
  id?: string;
  onClose?: () => void;
  state?: TreeState<unknown>;
  onAction?: ActionCallback;
}

const MenuStateContext = React.createContext<MenuContextValue>({});

function useMenuState(): MenuContextValue {
  return useContext(MenuStateContext);
}

export const MenuItemConfig = createViewConfig({
  name: 'MenuItem',
  props: {} as MenuItemProps,
  flattens: true,
  defaults: {
    disabled: false,
  },
});

const focus = css({focusRing: 'focus'});
const noOutline = css({focusRing: 'none'});

export const MenuItem = MenuItemConfig.createView(
  ({onAction, disabled, id, children, textValue, ...props}) => {
    const {onClose, onAction: onMenuAction, state} = useMenuState();
    const {key} = useItem({
      'aria-label': props['aria-label'] ?? '',
      typeaheadValue:
        typeof children === 'string'
          ? children
          : textValue ?? props['aria-label'] ?? '',
      id,
    });

    if (state == null) {
      throw new Error('<MenuItem> rendered outside <Menu>');
    }

    const ref = useRef<HTMLLIElement>(null);
    const {menuItemProps} = useMenuItem(
      {
        key,
        onAction: () => {
          onAction?.(key);
          onMenuAction?.(key);
        },
        onClose,
        isDisabled: disabled,
        closeOnSelect: true,
        'aria-label': props['aria-label'],
      },
      state,
      ref,
    );

    const {isFocusVisible, focusProps} = useFocusRing();
    const isLink = !!props.href;
    const Element = isLink ? Action : view.div;

    return (
      <Element
        {...props}
        {...menuItemProps}
        {...focusProps}
        {...(isLink ? {disabled} : {})}
        ref={ref}
        uses={[
          isFocusVisible ? focus : noOutline,
          isLink ? unprocessedCss({display: 'list-item'}) : null,
        ]}
      >
        {children}
      </Element>
    );
  },
  {
    css: {
      marginY: 'space.0',
      color: 'action.primary',
      fontWeight: 'semibold',
      minWidth: '160px',
      borderRadius: `calc(${tokens.border.radius.medium} - 1px - 4px)`, // 1px (Popover border width) - 4px (focus ring width).
      cursor: 'pointer',
      [media.query(
        `${media.up(
          tokens.viewport.mobile,
        )}, not all and (any-pointer: coarse)`,
      )]: {
        font: 'label.medium.emphasized',
        paddingX: 'space.75',
        paddingY: 'space.50',
        marginX: 'space.50',
      },
      [media.query(
        `${media.down(tokens.viewport.mobile)} and (any-pointer: coarse)`,
      )]: {
        font: 'label.large.emphasized',
        paddingX: 'small',
        paddingY: 'xsmall',
        marginX: 'space.150',
      },
    },
    forward: {disabled: true},
    variants: {
      type: {
        destructive: css({
          color: 'action.destructive',
        }),
      },
      disabled: {
        true: css({
          cursor: 'default',
          color: 'form.disabled',
        }),
        false: css({
          ':hover': {
            backgroundColor: 'offset',
            color: 'text',
          },
        }),
      },
    },
  },
);

export const MenuGroupConfig = createViewConfig({
  name: 'MenuGroup',
  props: {} as MenuGroupProps,
});

export const MenuGroup = MenuGroupConfig.createView(
  ({title, children, subviews, ...props}) => {
    const {key} = useItem({
      'aria-label': props['aria-label'] ?? '',
      type: 'section',
    });

    const {state} = useMenuState();

    const {itemProps, headingProps, groupProps} = useMenuSection({
      heading: title,
      'aria-label': props['aria-label'],
    });

    if (state == null) {
      throw new Error('<MenuGroup> rendered outside <Menu>');
    }

    return (
      <>
        {key !== state.collection.getFirstKey() ? (
          <view.li role="presentation">
            <Divider inherits={subviews.separator} />
          </view.li>
        ) : null}
        <view.li {...props} {...itemProps}>
          {title ? (
            <Heading {...headingProps} inherits={subviews.heading}>
              {title}
            </Heading>
          ) : null}
          <view.ul
            {...groupProps}
            css={{
              listStyle: 'none',
              margin: 'space.0',
              padding: 'space.0',
              stack: 'y',
              [media.query(
                `${media.down(
                  tokens.viewport.mobile,
                )} and (any-pointer: coarse)`,
              )]: {
                gap: 'space.50',
              },
            }}
          >
            <IndexedGroup>{children}</IndexedGroup>
          </view.ul>
        </view.li>
      </>
    );
  },
  {
    subviews: {
      separator: {
        css: {
          [media.query(
            `${media.up(
              tokens.viewport.mobile,
            )}, not all and (any-pointer: coarse)`,
          )]: {
            marginY: 'space.50',
          },
          [media.query(
            `${media.down(tokens.viewport.mobile)} and (any-pointer: coarse)`,
          )]: {
            marginY: 'space.100',
          },
        },
      },
      heading: {
        css: {
          display: 'block',
          font: 'heading.xsmall',
          color: 'subdued',
          paddingY: 'space.50',
          [media.query(
            `${media.up(
              tokens.viewport.mobile,
            )}, not all and (any-pointer: coarse)`,
          )]: {
            marginX: 'space.50',
            marginY: 'space.0',
            paddingX: 'space.75',
          },
          [media.query(
            `${media.down(tokens.viewport.mobile)} and (any-pointer: coarse)`,
          )]: {
            marginX: 'space.150',
            marginTop: 'space.0',
            marginBottom: 'space.50',
            paddingX: 'small',
          },
        },
      },
    },
  },
);

interface MenuItems extends MenuProps, MenuContextValue {}

const MenuItems = createView(
  ({
    children,
    className,
    inherits,
    onAction,
    ...props
  }: View.RenderProps<MenuItems>) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const didFocusRef = useRef<boolean>(false);
    const collection = useCollection(children);
    const {onClose} = useMenuState();

    const state = useTreeState({
      getItems: collection.getItems,
    });

    const {menuProps} = useMenu(
      {
        autoFocus: 'first',
        ...props,
      },
      state,
      ref,
    );

    // this recreates `autoFocus` behavior in `useMenu`. because we do two-pass
    // rendering to get the collection children, the collection is empty the first
    // time we render the menu, and there's nothing to focus.
    React.useEffect(() => {
      const collectionFirstKey = state.collection.getFirstKey();
      if (collectionFirstKey) {
        const firstKey = walkForKey(collectionFirstKey, state);
        if (didFocusRef.current === false && typeof firstKey === 'string') {
          state.selectionManager.setFocusedKey(firstKey);
          state.selectionManager.setFocused(true);
          didFocusRef.current = true;
        }
      }
    }, [state]);

    return (
      <CollectionProvider collection={collection}>
        <view.div
          className={className}
          inherits={inherits}
          ref={ref}
          {...menuProps}
          uses={[
            provider(MenuStateContext, {
              state,
              onClose,
              onAction,
            }),
          ]}
        >
          {collection.children}
        </view.div>
      </CollectionProvider>
    );
  },
);

export const Menu = MenuConfig.createView(
  ({subviews, ...props}) => {
    const triggerRef = useRef<HTMLElement>(null);
    const state = useMenuTriggerState({
      onOpenChange: (isOpen) => {
        props.onOpenChange?.(isOpen);
        !isOpen && props.onClose?.();
      },
    });
    const {menuTriggerProps, menuProps} = useMenuTrigger({}, state, triggerRef);
    const onClose = state.close;

    // if there is no trigger element, fall back to rendering the menu inline in an open state
    if (!props.trigger) {
      return <MenuItems {...props} />;
    }

    // if there *is* a trigger, render the menu relative to the trigger (via a popover)
    return (
      <Fragment>
        <PressResponder
          // trigger props contain aria-{expanded|haspopup|controls|pressed}
          {...menuTriggerProps}
          ref={triggerRef}
          isPressed={state.isOpen}
          // this is to fix https://jira.corp.stripe.com/browse/SAIL-2565
          // and can be removed if we migrate from usePress
          // @ts-expect-error need to put inline styles on this component, confirmed they get passed down
          style={{userSelect: 'none'}}
        >
          {props.trigger}
        </PressResponder>
        <MenuStateContext.Provider value={{onClose}}>
          <Popover
            targetRef={triggerRef}
            onClose={onClose}
            shouldCloseOnBlur
            inherits={subviews.popover}
            open={state.isOpen}
          >
            <FocusScope restoreFocus>
              <view.button
                type="button"
                tabIndex={-1}
                // TODO: SAIL-3215 no hardcoded strings
                aria-label="Dismiss"
                onClick={onClose}
                uses={[visuallyHidden]}
              />
              <MenuItems {...props} uses={[assignProps(menuProps)]}>
                {props.children}
              </MenuItems>
              <view.button
                type="button"
                tabIndex={-1}
                // TODO: SAIL-3215 no hardcoded strings
                aria-label="Dismiss"
                onClick={onClose}
                uses={[visuallyHidden]}
              />
            </FocusScope>
          </Popover>
        </MenuStateContext.Provider>
      </Fragment>
    );
  },
  {
    css: {
      margin: 'space.0',
      listStyle: 'none',
      overflowY: 'auto',
      userSelect: 'none',
      paddingX: 'space.0',
      height: 'fill',
      focusRing: 'none',
      [media.query(
        `${media.up(
          tokens.viewport.mobile,
        )}, not all and (any-pointer: coarse)`,
      )]: {
        paddingY: 'xsmall',
      },
      [media.query(
        `${media.down(tokens.viewport.mobile)} and (any-pointer: coarse)`,
      )]: {
        stack: 'y',
        gap: 'space.50',
        paddingTop: 'xsmall',
        paddingBottom: 'medium',
      },
    },
  },
);
