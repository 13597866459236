import {useMemo} from 'react';
import {createArrayIntentType} from '../../intent';
import {addRenderIntent, mergeProperty} from '../view';
import {configureRules, insertRule, mergeRules} from './core';
import type {Intent} from '../../intent';
import type {CssTemplateLiteral, Rule, RuleOptions, StylePlugin} from './core';

/** @deprecated */
export interface CssIntent extends Rule, Intent {}

/** @deprecated */
export interface CreateCss extends CssTemplateLiteral<CssIntent> {
  global: CssTemplateLiteral<CssIntent>;
  headless: CssTemplateLiteral<CssIntent>;
  reset: CssTemplateLiteral<CssIntent>;
  deprecatedOverride: CssTemplateLiteral<CssIntent>;
  plugins: StylePlugin[];
}

let createIntent: (rule: Rule) => CssIntent;
const cssIntent = createArrayIntentType('deprecatedCss', (ci): CreateCss => {
  createIntent = ci;
  return {} as unknown as CreateCss;
});

addRenderIntent(cssIntent, function useCssIntents(rules: CssIntent[], props) {
  const rule = useMemo(() => mergeRules(rules || []), [rules]);
  if (rule) {
    insertRule(rule);
    mergeProperty(props, 'className', rule.classes);
  }
});

const globalCss = configureRules(undefined, {global: true});
insertRule(globalCss`@layer reset, headless, theme, app;`);

/** @deprecated */
export function createCss(
  plugins: StylePlugin[],
  options: Partial<RuleOptions> = {layer: 'app'},
): CreateCss {
  const create = configureRules(plugins, options, createIntent);
  const deprecatedCss = ((...args) => create(...args)) as CreateCss;
  deprecatedCss.plugins = plugins;

  const globalCss = create.options({global: true});
  deprecatedCss.global = (...args) => globalCss(...args);

  const headless = create.options({layer: 'headless'});
  deprecatedCss.headless = (...args) => headless(...args);

  const reset = create.options({layer: 'reset'});
  deprecatedCss.reset = (...args) => reset(...args);

  const deprecatedOverride = create.options({layer: 'deprecatedOverride'});
  deprecatedCss.deprecatedOverride = (...args) => deprecatedOverride(...args);

  return deprecatedCss;
}
