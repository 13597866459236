const space = 'space' as const;

export const aliasSpaceBuiltIns = {
  inset: space,
  bottom: space,
  left: space,
  right: space,
  top: space,

  margin: space,
  marginBottom: space,
  marginLeft: space,
  marginRight: space,
  marginTop: space,

  padding: space,
  paddingBottom: space,
  paddingLeft: space,
  paddingRight: space,
  paddingTop: space,

  gap: space,
  columnGap: space,
  rowGap: space,
} as const;

export const aliasSpace = {
  ...aliasSpaceBuiltIns,
  marginX: space,
  marginY: space,
  paddingX: space,
  paddingY: space,
  gapX: space,
  gapY: space,
  bleed: space,
  bleedX: space,
  bleedY: space,
  bleedTop: space,
  bleedBottom: space,
  bleedLeft: space,
  bleedRight: space,
} as const;
