export const tokensSpace = {
  space: {
    0: '0px',
    1: '1px',
    25: '2px',
    50: '4px',
    75: '6px',
    100: '8px',
    150: '12px',
    200: '16px',
    250: '20px',
    300: '24px',
    350: '28px',
    400: '32px',
    500: '40px',
    600: '48px',
    xxsmall: '$space.25',
    xsmall: '$space.50',
    small: '$space.100',
    medium: '$space.200',
    large: '$space.300',
    xlarge: '$space.400',
    xxlarge: '$space.600',
  },
};
