import {messages} from 'gelato/frontend/src/components/Link/messages';

export function calculateConsumerAccountLookupError(lookupError: string) {
  let error;
  switch (lookupError) {
    case 'rate_limit_exceeded':
      error = messages.rateLimitExceededError;
      break;
    default:
      error = messages.genericError;
      break;
  }
  return error;
}
