import {createTokens} from '@sail/engine';

const duration = createTokens('animation.duration', {
  instant: '0ms',
  xshort: '50ms',
  short: '100ms',
  medium: '150ms',
  long: '300ms',
});

const easing = createTokens('animation.easing', {
  $default: 'cubic-bezier(0, .09, .4, 1)',
});

export const animation = createTokens('animation', {
  duration,
  easing,
});
