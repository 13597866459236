import UAParser from 'ua-parser-js';

export function getUserAgent(): string | null | undefined {
  const userAgent =
    typeof window !== 'undefined' && navigator && window.navigator.userAgent;
  return userAgent || undefined;
}

export function parseUserAgent() {
  // This maybe evaluated in next.js Node rather than in Browser
  if (!getUserAgent()) {
    return undefined;
  }
  const parser = new UAParser();
  return parser.getResult();
}

export function isMobileUA(): boolean {
  const result = parseUserAgent();
  if (!result || !result.device || !result.device.type) {
    return false;
  }
  return ['mobile', 'tablet'].includes(result.device.type);
}

export function isFirefoxUA() {
  const result = parseUserAgent();
  return result?.browser?.name === 'Firefox';
}

export function isSafariUA() {
  const result = parseUserAgent();
  const name = result?.browser?.name;
  return name === 'Safari' || name === 'Mobile Safari';
}

export function isChromeUA() {
  const result = parseUserAgent();
  return result?.browser?.name === 'Chrome';
}

export function isEdgeBrowserUA() {
  const result = parseUserAgent();
  return result?.browser?.name === 'Edge';
}

export function isSamsungUA() {
  const result = parseUserAgent();
  return result?.device?.vendor === 'Samsung';
}

export function isAndroid() {
  const result = parseUserAgent();
  return result?.os?.name === 'Android';
}

/**
 * See https://developer.chrome.com/docs/multidevice/user-agent/#webview_user_agent
 * This onloy detects the WebView after Android 4.4. It'd return false for older
 * Android version.
 */
export function isAndroidWebView() {
  const result = parseUserAgent();
  // If the user-agent string contains "wv", 'ua-parser-js' will return the
  // string "WebView" as part of its browser name.
  return (
    result?.os?.name === 'Android' &&
    /WebView/.test(String(result?.browser?.name))
  );
}

export function isAndroid12OrAbove() {
  return getMajorAndroidVersion() >= 12;
}

export function isIOSUA() {
  const result = parseUserAgent();
  return result?.os?.name === 'iOS';
}

export function getMajorAndroidVersion(): number {
  const result = parseUserAgent();
  const matched =
    result?.os?.name === 'Android'
      ? String(result?.os?.version || '').match(/^\d+/)
      : null;
  return matched ? parseInt(matched[0], 10) : NaN;
}

export function getMajorSafariVersion(): number {
  const result = parseUserAgent();
  const name = result?.browser?.name;
  if (name === 'Safari' || name === 'Mobile Safari') {
    return parseInt(result?.browser?.version || '', 10);
  }
  return NaN;
}

export function getMajorChromeVersion(): number {
  const result = parseUserAgent();
  return result?.browser?.name === 'Chrome'
    ? parseInt(result?.browser?.version || '', 10)
    : NaN;
}

export function getMajorIOSVersion(): number {
  const result = parseUserAgent();
  const matched =
    result?.os?.name === 'iOS'
      ? String(result?.os?.version || '').match(/^\d+/)
      : null;
  return matched ? parseInt(matched[0], 10) : NaN;
}

export function getMajorMinorOsVersion() {
  const result = parseUserAgent();
  const version = String(result?.os?.version || '');
  const matched = version.match(/(^\d+)(\.\d+)?/);
  return (matched && matched[0]) || 'unknown';
}

export function isWindows(): boolean {
  return (
    getPlatform() === 'desktop' && parseUserAgent()?.os?.name === 'Windows'
  );
}

export function isMac(): boolean {
  return getPlatform() === 'desktop' && parseUserAgent()?.os?.name === 'Mac OS';
}

export function isLinux(): boolean {
  const ua = parseUserAgent();
  return ua?.os?.name === 'Linux';
}

// This returns the "platform"- iphone vs android vs desktop used for reporting metrics.
// Do not make this into something with high cardinality- that will impact SignalFX.
// If you need to alter/add to these values, please make sure to update the relevant
// SignalFX metrics as well.
export function getPlatform(): string {
  if (isAndroid()) {
    return 'android';
  } else if (isIOSUA()) {
    return 'iphone';
  }
  return 'desktop';
}
