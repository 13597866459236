import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import isFieldNeeded from 'gelato/frontend/src/controllers/utils/isFieldNeeded';

import type {GraphQlField} from '@sail/data';
import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';
import type {ValidateOneTimePasswordMutationData} from 'gelato/frontend/src/graphql/mutations/useValidateOneTimePasswordMutation';

export type ValidateOTPResponse = Partial<{
  session: GraphQlField<
    ValidateOneTimePasswordMutationData,
    'validateOneTimePassword',
    'session'
  >;
}>;

/**
 * Mutation request to validate an OTP code
 * @param state The application state.
 * @param runtime The application runtime.
 * @returns ValidateOTPResponse containing the new session if successfully validated
 */
export default async function validateOTP(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
): Promise<ValidateOTPResponse> {
  const phoneVariables = isFieldNeeded(state, 'phone_otp')
    ? {
        phoneCode: state.individual.phoneOtp?.otpDeclined
          ? null
          : state.individual.phoneOtp?.code,
        phoneDecline: state.individual.phoneOtp?.otpDeclined,
      }
    : null;
  const emailVariables = isFieldNeeded(state, 'email_otp')
    ? {
        emailCode: state.individual.emailOtp?.otpDeclined
          ? null
          : state.individual.emailOtp?.code,
        emailDecline: state.individual.emailOtp?.otpDeclined,
      }
    : null;

  if (!phoneVariables && !emailVariables) {
    throw new Error(ErrorCode.validateOTPNotRequired);
  }

  const resp = await runtime.api.mutation.validateOTPMutation({
    variables: {
      ...phoneVariables,
      ...emailVariables,
    },
  });
  if (!resp?.data?.validateOneTimePassword?.success) {
    throw new Error(ErrorCode.failedToValidateOTP);
  }

  return {
    session: resp?.data?.validateOneTimePassword.session,
  };
}
