import {ApolloLink} from '@apollo/client';
import removeDirectives from 'src/internal/utils/removeDirectives';

import type {InternalOperation} from 'src/types';

export default function createDirectivesToContextLink(): ApolloLink {
  return new ApolloLink(function directivesToContextLink(
    operation: InternalOperation,
    forward,
  ) {
    const {gql, directives} = removeDirectives(operation.query);

    const overridenContext: ReturnType<InternalOperation['getContext']> = {};

    if (directives.tag) {
      overridenContext.queryTag = directives.tag;
    }

    if (directives.hash) {
      overridenContext.operationHash = directives.hash;
    }

    operation.query = gql;

    operation.setContext(overridenContext);

    return forward(operation);
  });
}
