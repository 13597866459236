import {RULESET} from 'stylis';
import {convertAtRuleToRuleset} from './utils';
import type {StylisElement} from './types';
import {SPECIFICITY_TOKEN} from '../../../stylesheets';

const DELIMITER = ':';
const GLOBAL = 'global:';

export enum VariantScope {
  Cascading = 'is',
  Scoped = 'when',
}

export type VariantValue = string | number | boolean | void;

function getVariantProperty(variant: string): string {
  return variant.slice(0, variant.indexOf(DELIMITER));
}

function getVariantValue(variant: string): string {
  return variant.slice(variant.indexOf(DELIMITER) + 1);
}

export function getVariantClass(
  scope: VariantScope,
  property: string,
  value: VariantValue,
): string {
  return `${scope}-${property}-${value}`;
}

function getVariantClassForExpression(
  scope: VariantScope,
  expression: string,
): string {
  return getVariantClass(
    scope,
    getVariantProperty(expression),
    getVariantValue(expression),
  );
}

function removeSpecificityTokenFromParent(
  elementParent: StylisElement['parent'],
) {
  if (!elementParent) {
    return elementParent;
  }

  // Since the SPECIFICITY_TOKEN was prepended to the rule, the rule needs
  // to be updated to not include the SPECIFICITY_TOKEN.
  // This breaks for any other transformations (like composite styles).
  if (Array.isArray(elementParent.props)) {
    elementParent.props = elementParent.props.map((prop) => {
      return prop.replace(SPECIFICITY_TOKEN, '');
    });
  } else {
    elementParent.props = elementParent.props.replace(SPECIFICITY_TOKEN, '');
  }

  elementParent.return = elementParent.return.replace(SPECIFICITY_TOKEN, '');
  elementParent.value = elementParent.value.replace(SPECIFICITY_TOKEN, '');

  return elementParent;
}

export function conditionWhen(
  element: StylisElement,
  index: number,
  elements: (StylisElement | string)[],
): void {
  const ruleset = element.parent;
  if (!ruleset || ruleset.type !== RULESET) {
    throw new Error('@when conditions must be declared within a ruleset.');
  }

  const selectors = [] as string[];
  const expressions = element.props as string[];
  expressions.forEach((expression) => {
    if (expression.startsWith(GLOBAL)) {
      const variantSelector = `.${getVariantClassForExpression(
        VariantScope.Cascading,
        expression.slice(GLOBAL.length),
      )}`;
      selectors.push(
        `${SPECIFICITY_TOKEN} ${variantSelector} &`,
        `${SPECIFICITY_TOKEN} ${variantSelector}&`,
      );

      element.parent = removeSpecificityTokenFromParent(element.parent);
    } else {
      const variantSelector = `.${getVariantClassForExpression(
        VariantScope.Scoped,
        expression,
      )}`;
      selectors.push(`&${variantSelector}`);
    }
  });

  convertAtRuleToRuleset(element, index, elements, selectors);
}
