/**
 * @fileoverview Actions that manage Verification Flows state for the controller.
 */

import {SlugResponse} from 'gelato/frontend/src/local_pages/verify';

export type FlowsState = {
  flows: {
    slug: string;
    email: string;
    staticData: SlugResponse | undefined;
    fetching: boolean;
    emailIsValid: boolean;
    continuing: boolean;
    clientReferenceId: string | undefined;
  };
};

export function createFlowsState(): FlowsState {
  return {
    flows: {
      slug: '',
      email: '',
      staticData: undefined,
      fetching: false,
      emailIsValid: false,
      continuing: false,
      clientReferenceId: undefined,
    },
  };
}

export function setSlug(state: FlowsState, slug: string): void {
  state.flows.slug = slug;
}

export function setEmail(state: FlowsState, email: string): void {
  state.flows.email = email;
}

export function setClientReferenceId(
  state: FlowsState,
  clientReferenceId: string,
): void {
  state.flows.clientReferenceId = clientReferenceId;
}

export function setEmailIsValid(state: FlowsState, isValid: boolean): void {
  state.flows.emailIsValid = isValid;
}

export function setContinuing(state: FlowsState, continuing: boolean): void {
  state.flows.continuing = continuing;
}
