/* eslint-disable @typescript-eslint/no-explicit-any */
const isObject = (obj: any) => obj && typeof obj === 'object';

/**
 * Deeply merge nested objects; behavior compatible with lodash.merge
 *
 * @param target
 * @param source
 * @returns
 */
export const deepMerge = <T extends Record<string, any>>(
  target: T,
  source: Partial<T>,
): T => {
  if (!isObject(target) || !isObject(source)) {
    return source as T;
  }

  Object.keys(source).forEach((key: keyof T) => {
    const targetValue = target[key];
    const sourceValue = source[key] as any;

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = sourceValue as any;
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = deepMerge<any>(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
};
