import React, {useRef, useEffect} from 'react';

/**
 * useSelection provides a method to set the cursor selection of an input
 * from within an event handler. Normally when a controlled input's value
 * changes, React resets the cursor position to the end of the input, so
 * this method updates the selection from within an effect to avoid being
 * clobbered by React.
 */
export function useSelection(
  ref: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
): {
  setSelection: (
    start: number,
    end?: number,
    direction?: 'forward' | 'backward' | 'none',
  ) => void;
} {
  const [signal, commit] = React.useReducer((x) => x + 1, 0);
  const selectionRangeRef = useRef<{
    start: number;
    end: number;
    direction?: 'forward' | 'backward' | 'none';
  } | null>(null);
  function setSelection(
    start: number,
    end: number = start,
    direction?: 'forward' | 'backward' | 'none',
  ) {
    selectionRangeRef.current = {
      start,
      end,
      direction,
    };
    commit();
  }

  useEffect(() => {
    const input = ref.current;
    if (input && selectionRangeRef.current !== null) {
      const {start, end, direction} = selectionRangeRef.current;
      input.setSelectionRange(start, end, direction);
      selectionRangeRef.current = null;
    }
  }, [signal, ref]);

  return {setSelection};
}
