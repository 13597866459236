import {DECLARATION, RULESET} from 'stylis';
import type {
  StylisElement,
  StylisExtensionData,
  StylisMiddleware,
} from './types';
import {assign, concat, escapeRegExp} from '../../../util';

export type Conditions = {[key: string]: StylisMiddleware};

export type DeclarationPlugin = (decl: StylisElement) => void;
export type Declarations = Array<DeclarationPlugin>;

export type PropertyPlugin = (
  decl: StylisElement,
) => Array<StylisElement> | void;
export type Properties = {
  [key: string]: PropertyPlugin;
};

export type StylePlugin = {
  conditions?: Conditions;
  declarations?: Declarations;
  properties?: Properties;
};

export function mergeStylePlugin(
  target: StylePlugin,
  source: StylePlugin,
): void {
  const {conditions, declarations, properties} = source;
  if (conditions) {
    if (!target.conditions) {
      target.conditions = {};
    }
    assign(target.conditions, conditions);
  }
  if (declarations) {
    if (!target.declarations) {
      target.declarations = [];
    }
    concat(target.declarations, declarations);
  }
  if (properties) {
    if (!target.properties) {
      target.properties = {};
    }
    assign(target.properties, properties);
  }
}

export function mergeStylePlugins(plugins: StylePlugin[]): StylePlugin {
  const target: StylePlugin = {};
  for (let i = 0; i < plugins.length; i++) {
    mergeStylePlugin(target, plugins[i]);
  }
  return target;
}

export function cloneDeclaration(
  element: StylisElement,
  props: string = element.props as string,
  children: string = element.children as string,
): StylisElement {
  return {
    ...element,
    data: undefined,
    type: DECLARATION,
    props,
    children,
    value: children ? `${props}:${children};` : '',
    length: props.length,
  };
}

export function cloneDeclarations(
  element: StylisElement,
  declarations: StylisElement[],
): StylisElement[] {
  return declarations.map((decl) => ({
    ...decl,
    parent: element.type === DECLARATION ? element.parent : element,
    root: element.type === DECLARATION ? element.root : element,
  }));
}

export function matchOutsideParens(
  value: string,
  input: string | RegExp,
  callback: (match: string, depth: number) => string,
): string {
  const source = typeof input === 'string' ? escapeRegExp(input) : input.source;
  const withParens = new RegExp(`[()]|${source}`, 'gi');
  let depth = 0;
  return value.replace(withParens, (match) => {
    switch (match) {
      case '(':
        depth += 1;
        return match;
      case ')':
        depth -= 1;
        return match;
      default:
        return callback(match, depth);
    }
  });
}

const pseudoElementRegExp =
  /(:(?:before|after|first-letter|first-line)|::\w+(?:[^(\w]|$))/;
function includesPseudoElement(selector: string): boolean {
  return !!selector.match(pseudoElementRegExp);
}

export function nestSelectors(
  ancestors: string[],
  nested: string[],
  prefix = true,
): string[] {
  const result = [] as string[];
  for (let i = 0; i < ancestors.length; i++) {
    const ancestor = ancestors[i];
    const ancestorIsPseudo = includesPseudoElement(ancestor);
    for (let j = 0; j < nested.length; j++) {
      const current = nested[j];
      const currentIsPseudo = includesPseudoElement(ancestor);
      if (ancestorIsPseudo && currentIsPseudo) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const selector =
        prefix && current.indexOf('&') === -1
          ? `${ancestor} ${current}`
          : current.replace(/&[\f]?/g, ancestor);
      if (!result.includes(selector)) {
        result.push(selector);
      }
    }
  }
  return result;
}

const supportedAtRules = ['@media', '@supports'];

function nestAtRules(element: StylisElement, selectors: string[]) {
  if (!supportedAtRules.includes(element.type)) {
    return;
  }

  const children = element.children as StylisElement[];
  for (let j = 0; j < children.length; j++) {
    const child = children[j];
    if (child.type === RULESET) {
      // @media rules sometimes create implicit rules that include
      // ancestor at-rules.
      if (child.value.startsWith('@')) {
        child.value = '&';
      }
      if (selectors.length) {
        child.props = nestSelectors(
          selectors,
          // We can't use child.props here because Stylis removes & characters
          // from the processed props.
          matchOutsideParens(child.value, ',', (match, depth) => {
            return depth === 0 ? '::split::' : match;
          }).split('::split::'),
        );
      }
    } else {
      nestAtRules(child, selectors);
    }
  }
}

export function convertAtRuleToRuleset(
  element: StylisElement,
  index: number,
  elements: (StylisElement | string)[],
  selectors = ['&'],
): void {
  let parent = element.parent;
  if (parent?.type !== RULESET) {
    parent = undefined;
  }

  // Hoist any at-rules and rulesets after the current element.
  const children = element.children as StylisElement[];
  let currentIndex = index;

  element.type = RULESET;
  element.value = selectors.join(', ');
  element.props = parent
    ? nestSelectors(parent.props as string[], selectors)
    : [];

  element.children = [];
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (child.type === RULESET) {
      elements.splice(++currentIndex, 0, child);
      child.root = element.root;
      if (parent) {
        child.props = nestSelectors(
          element.props as string[],
          // We can't use child.props here because Stylis removes & characters
          // from the processed props.
          matchOutsideParens(child.value, ',', (match, depth) => {
            return depth === 0 ? '::split::' : match;
          }).split('::split::'),
        );
      }
    } else if (child.type.startsWith('@')) {
      elements.splice(++currentIndex, 0, child);
      child.root = element.root;
      nestAtRules(child, element.props);
    } else {
      element.children.push(child);
    }
  }
}

export function cloneRuleset(
  element: StylisElement,
  props: string[] = (element.props as string[]).slice(),
  children: StylisElement[] = element.children as StylisElement[],
): StylisElement {
  const ruleset: StylisElement = {
    ...element,
    data: undefined,
    type: RULESET,
    props,
    children,
    value: '',
    length: props.length,
  };
  ruleset.children = cloneDeclarations(ruleset, children);
  return ruleset;
}

export function createAlias(
  target: string | string[],
): (decl: StylisElement) => Array<StylisElement> | void {
  const targets = Array.isArray(target) ? target : [target];
  return function (element: StylisElement): Array<StylisElement> | void {
    const children = element.children as string;
    return targets.map((prop) => cloneDeclaration(element, prop, children));
  };
}

export function getPathFromRoot(element: StylisElement): string[] {
  let r = element.root;
  const path = [];
  while (r) {
    path.unshift(r.value);
    r = r.root;
  }
  return path;
}

export function getStyleData(
  element: StylisElement,
  key?: string,
): StylisExtensionData {
  if (!element.data) {
    element.data = {};
  }
  if (key) {
    if (!element.data[key]) {
      element.data[key] = {};
    }
    return element.data[key] as StylisExtensionData;
  }
  return element.data;
}

export function recordDeclaration(
  element: StylisElement,
  property: string,
  value: string,
): void {
  const ruleset = element.root;
  if (!ruleset) {
    return;
  }

  const declarations = getStyleData(ruleset, 'declarations');
  declarations[property] = cloneDeclaration(element, property, value);
}

export function addRulesetAfter(
  element: StylisElement,
  id: string,
  selectors: string[] = [],
  declarations: StylisElement[] = [],
): StylisElement | void {
  const ruleset = element.root as StylisElement;
  if (!ruleset || ruleset.type !== RULESET) {
    return;
  }

  const after = getStyleData(ruleset, 'after');
  const existing = after[id] as StylisElement;
  if (existing) {
    const existingDeclarations = existing.children as StylisElement[];
    existingDeclarations.push(...cloneDeclarations(existing, declarations));
  } else {
    const props = ruleset.props as string[];
    const nestedSelectors = selectors.length
      ? nestSelectors(props, selectors, false)
      : props.slice();
    if (nestedSelectors.length) {
      after[id] = cloneRuleset(ruleset, nestedSelectors, declarations);
    }
  }
}

export function createVariableProperty(
  property: string,
  fallback = '',
): PropertyPlugin {
  const contents = `var(--${property}${fallback ? `,${fallback}` : ''})`;
  const customProperty = `--${property}`;
  return function (element) {
    const children = element.children as string;
    if (children === contents) {
      return;
    }
    return [
      cloneDeclaration(element, property, contents),
      cloneDeclaration(element, customProperty, children),
    ];
  };
}
