import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';

type Handlers = {
  handleDragStart: (event: PointerEvent) => void;
  handleDragMove: (event: PointerEvent) => void;
  handleDragEnd: (event: PointerEvent) => void;
};

// The global unique pointer id for the fallback pointer event handlers.
const FALLBACK_POINTER_ID = {
  toString: () => 'FALLBACK_POINTER_ID',
  valueOf: () => -1,
};

/**
 * Creates a synthetic pointer event from a mouse or touch event.
 * @param event The mouse or touch event to create from.
 * @param capturingTarget The target to capture the pointer events on.
 * @returns The synthetic pointer event.
 */
function createSyntheticPointerEvent(
  event: Event,
  capturingTarget?: HTMLElement,
): PointerEvent {
  // `TouchEvent` is undefined in Safari desktop, therefore we need to cast it
  // instead of using `instanceof`.
  const touchEvent = event as TouchEvent;
  const touch = (touchEvent.touches && touchEvent.touches[0]) || null;

  const mouse = event instanceof MouseEvent ? event : null;

  const pointerEvent = {
    clientX: touch?.clientX || mouse?.clientX || 0,
    clientY: touch?.clientY || mouse?.clientY || 0,
    height: 0,
    isPrimary: true, // For the first touch, set it to true
    pageX: touch?.pageX || mouse?.pageX || 0,
    pageY: touch?.pageY || mouse?.pageY || 0,
    pointerId: FALLBACK_POINTER_ID,
    pointerType: (touch && 'touch') || (mouse && 'mouse') || 'unknown',
    pressure: touch?.force || 0.5, // Pressure can be approximated with force
    preventDefault: event.preventDefault.bind(event),
    screenX: touch?.screenX || mouse?.screenX || 0,
    screenY: touch?.screenY || mouse?.screenY || 0,
    stopPropagation: event.stopPropagation.bind(event),
    target: capturingTarget || event.target,
    tiltX: 0, // These properties are specific to PointerEvent.
    tiltY: 0,
    twist: 0,
    type: event.type,
    width: 0,
  };

  return pointerEvent as PointerEvent;
}

/**
 * Attaches pointer event handlers to an element to handle dragging.
 * @param element The element to attach the handlers to.
 * @param handlers The handlers to attach.
 * @returns A function to remove the handlers.
 */
export default function addDOMPointerEventDragHandlers(
  element: HTMLElement,
  handlers: Handlers,
) {
  if (window.PointerEvent) {
    return addNativeDOMPointerEventDragHandlers(element, handlers);
  } else {
    // For Android 4.x.x and lower devices, PointerEvent is not supported.
    return addFallbackDOMEventDragHandlers(element, handlers);
  }
}

/**
 * Attaches pointer event handlers to an element to handle dragging with native
 * pointer events.
 */
function addNativeDOMPointerEventDragHandlers(
  element: HTMLElement,
  handlers: Handlers,
) {
  const {handleDragStart, handleDragMove, handleDragEnd} = handlers;
  // start
  element.addEventListener('pointerdown', handleDragStart, false);
  // move
  element.addEventListener('pointermove', handleDragMove, false);
  // end
  element.addEventListener('pointerup', handleDragEnd, false);
  element.addEventListener('pointercancel', handleDragEnd, false);
  element.addEventListener('pointerleave', handleDragEnd, false);

  return () => {
    // start
    element.removeEventListener('pointerdown', handleDragStart, false);
    // move
    element.removeEventListener('pointermove', handleDragMove, false);
    // end
    element.removeEventListener('pointerup', handleDragEnd, false);
    element.removeEventListener('pointercancel', handleDragEnd, false);
    element.removeEventListener('pointerleave', handleDragEnd, false);
  };
}

/**
 * Attaches pointer event handlers to an element to handle dragging with
 * fallback mouse or touch events.
 */
function addFallbackDOMEventDragHandlers(
  element: HTMLElement,
  handlers: Handlers,
) {
  const {handleDragStart, handleDragMove, handleDragEnd} = handlers;
  const {setPointerCapture, releasePointerCapture} = element;

  const canUseTouchEvents = 'ontouchstart' in window;

  const events = {
    start: canUseTouchEvents ? 'touchstart' : 'mousedown', // 'pointerdown',
    move: canUseTouchEvents ? 'touchmove' : 'mousemove', // 'pointermove',
    end: canUseTouchEvents ? 'touchend' : 'mouseup', // 'pointerup',
  };

  let isCapturing = false;

  const handleStartCapture = (event: Event) => {
    event.stopPropagation();
    handleDragStart(createSyntheticPointerEvent(event, element));
  };

  const handleMoveCapture = (event: Event) => {
    event.stopPropagation();
    handleDragMove(createSyntheticPointerEvent(event, element));
  };

  const handleEndCapture = (event: Event) => {
    event.stopPropagation();
    handleDragEnd(createSyntheticPointerEvent(event, element));
  };

  const handleStart = (event: Event) => {
    handleDragStart(createSyntheticPointerEvent(event));
  };

  const handleMove = (event: Event) => {
    handleDragMove(createSyntheticPointerEvent(event));
  };

  const handleEnd = (event: Event) => {
    handleDragEnd(createSyntheticPointerEvent(event));
  };

  // start
  element.addEventListener(events.start, handleStart, false);
  // move
  element.addEventListener(events.move, handleMove, false);
  // end
  element.addEventListener(events.end, handleEnd, false);

  const setPointerCaptureOverride = (pointerId: any) => {
    if (pointerId !== FALLBACK_POINTER_ID) {
      throw new Error(ErrorCode.invalidPointerId);
    }
    if (isCapturing) {
      return;
    }
    // While capturing, only the document will receive events.
    isCapturing = true;
    document.addEventListener(events.start, handleStartCapture, true);
    document.addEventListener(events.move, handleMoveCapture, true);
    document.addEventListener(events.end, handleEndCapture, true);
  };

  const releasePointerCaptureOverride = (pointerId: any) => {
    if (pointerId !== FALLBACK_POINTER_ID) {
      throw new Error(ErrorCode.invalidPointerId);
    }
    if (!isCapturing) {
      return;
    }
    isCapturing = false;
    document.removeEventListener(events.start, handleStartCapture, true);
    document.removeEventListener(events.move, handleMoveCapture, true);
    document.removeEventListener(events.end, handleEndCapture, true);
  };

  // Override the original methods.
  Object.assign(element, {
    releasePointerCapture: releasePointerCaptureOverride,
    setPointerCapture: setPointerCaptureOverride,
  });

  return () => {
    // start
    element.removeEventListener(events.start, handleStart, false);
    // move
    element.removeEventListener(events.move, handleMove, false);
    // end
    element.removeEventListener(events.end, handleEnd, false);

    releasePointerCaptureOverride(FALLBACK_POINTER_ID);

    // Restore the original methods.
    Object.assign(element, {
      releasePointerCapture,
      setPointerCapture,
    });
  };
}
