import type {ProjectName, ServiceName} from '@sail/observability';
import type {InternalOperation} from 'src/types';

export const REQUEST_SOURCE_HEADER = 'X-Request-Source';

export type RequestSourceInformation = {
  service: ServiceName | '<unknown>';
  project: ProjectName | '<unknown>';
  component: string;
  operation: string;
};

export function getRequestSourceInformation({
  service,
  project,
  component,
  operation,
}: {
  service?: ServiceName;
  project?: ProjectName;
  component?: string;
  operation?: string;
}): RequestSourceInformation {
  return {
    service: service ?? '<unknown>',
    project: project ?? '<unknown>',
    operation: operation ?? '<unknown>',
    component: component ?? '<unknown>',
  };
}

export function getOperationInformation(
  operation: InternalOperation,
): RequestSourceInformation {
  const context = operation.getContext();

  return getRequestSourceInformation({
    service: context.service,
    project: context.queryProject,
    component: context.component,
    operation: operation.operationName,
  });
}

export function getRequestSourceHeader(info: RequestSourceInformation) {
  const parts: string[] = [];

  for (const name in info) {
    if (info.hasOwnProperty(name)) {
      const value = info[name as keyof RequestSourceInformation];
      const cleanValue = value.replace(/[^\x20-\x7E]/g, '?');

      parts.push(`${name}=${JSON.stringify(cleanValue)}`);
    }
  }

  return parts.join('; ');
}
