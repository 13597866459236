import {updateEmailMutation} from 'gelato/frontend/src/graphql/mutations/useUpdateEmailMutation';

// eslint-disable-next-line @sail/data-no-imperative-methods
import type {ApolloClient, FetchResult} from '@apollo/client';
import type {
  UpdateEmailMutationVariables,
  UpdateEmailMutationData,
} from 'gelato/frontend/src/graphql/mutations/useUpdateEmailMutation';

/**
 * Performs the mutation that updates the email for the handoff url.
 * @param apolloClient The apollo client.
 * @param variables The mutation variables.
 */
export default async function updateEmailMutationMethod(
  apolloClient: ApolloClient<object>,
  variables: UpdateEmailMutationVariables,
): Promise<FetchResult<UpdateEmailMutationData>> {
  // eslint-disable-next-line @sail/data-no-imperative-methods
  return apolloClient.mutate({
    mutation: updateEmailMutation,
    variables,
  });
}
