export const tokensRadius = {
  radius: {
    none: 'none',
    xsmall: '4px',
    small: '6px',
    medium: '8px',
    large: '10px',
    rounded: '999em',
  },
};
