import {unprocessedCss} from '@sail/engine';
import {css, tokens} from '@sail/ui';

import {PageTransitionStyles} from 'gelato/frontend/src/lib/pageTransitions';
/**
 * Shared atomic styles for components.
 */

// Empty style.
export const noop = css({});

// Using "all: unset" as a base style provides a clean slate for
// the styling of elements. This is particularly useful when dealing with
// elements that might inherit complex styles from parent elements or the
// browser.
export const allReset = unprocessedCss({
  all: 'unset',
});

export const noWrap = css({
  whiteSpace: 'nowrap',
});

export const ellipsis = unprocessedCss({
  textOverflow: 'ellipsis',
});

// Font
export const fS50 = css({
  fontSize: 'small',
});

export const fS100 = css({
  fontSize: 'medium',
});

export const fS200 = css({
  fontSize: 'large',
});

export const fS250 = css({
  fontSize: '20px',
});

// Colors
export const gray400 = tokens.color.hue.gray[400];

// Background styles.

export const bgCenter = unprocessedCss({
  backgroundPosition: 'center',
});

export const bgCover = unprocessedCss({
  backgroundSize: 'cover',
});

export const bgContain = unprocessedCss({
  backgroundSize: 'contain',
});

export const bgNoRepeat = unprocessedCss({
  backgroundRepeat: 'no-repeat',
});

export const bgOffset = css({
  backgroundColor: tokens.color.background.offset,
});

// Text colors
// See https://sail.stripe.me/beta/tokens/color/ for more information.

export const tColorNormal = css({
  textColor: tokens.color.hue.gray[800],
});

export const tColorSubdued = css({
  textColor: tokens.color.hue.gray[500],
});

export const tColorDestructive = css({
  textColor: tokens.color.hue.red[500],
});

// Text boldness styles.
// Text boldness medium.
export const tBoldM = unprocessedCss({
  fontVariationSettings: `'wght' 500`,
});

// Text boldness large.
export const tBoldL = unprocessedCss({
  fontVariationSettings: `'wght' 600`,
});

// Text color.
export const tGrey = css({
  textColor: gray400,
});

export const tRed = css({
  textColor: 'action.destructive',
});

// Text aigned in center.
export const tCenter = css({
  textAlign: 'center',
});

export const tSmall = css({
  font: 'body.small',
});

export const tMonospace = unprocessedCss({
  fontFamily: `'Source Code Pro', Menlo, Monaco, monospace`,
});

// Spacing styles.
// See https://sail.stripe.me/beta/tokens/space/ for more information.

export const m0 = css({
  margin: 'space.0',
});

export const m50 = css({
  margin: 'xsmall',
});

export const m100 = css({
  margin: 'small',
});

export const m200 = css({
  margin: 'medium',
});

export const m400 = css({
  margin: 'xlarge',
});

export const mX50 = css({
  marginX: 'xsmall',
});

export const mX100 = css({
  marginX: 'small',
});

export const mX200 = css({
  marginX: 'medium',
});

export const mY50 = css({
  marginY: 'xsmall',
});

export const mY100 = css({
  marginY: 'small',
});

export const mY600 = css({
  marginY: 'xxlarge',
});

export const nmT20 = css({
  marginTop: '-20px',
});

export const mT50 = css({
  marginTop: 'xsmall',
});

export const mT100 = css({
  marginTop: 'small',
});

export const mT200 = css({
  marginTop: 'medium',
});

export const mB0 = css({
  marginBottom: 'space.0',
});

export const mB50 = css({
  marginBottom: 'xsmall',
});

export const mB100 = css({
  marginBottom: 'small',
});

export const mB200 = css({
  marginBottom: 'medium',
});

export const mB300 = css({
  marginBottom: 'large',
});

export const mB400 = css({
  marginBottom: 'xlarge',
});

export const mY200 = css({
  marginY: 'medium',
});

export const p200 = css({
  padding: 'medium',
});

export const tapHighlightColorTransparent = unprocessedCss({
  WebkitTapHighlightColor: 'transparent',
});

// Underline styles.
export const underline = css({
  textDecoration: 'underline',
});

export const underlineHover = css({
  ':active': {
    textDecoration: 'underline',
  },
  ':focus': {
    textDecoration: 'underline',
  },
  ':hover': {
    textDecoration: 'underline',
  },
  textDecoration: 'none',
});

export const hidden = css({
  visibility: 'hidden',
});

export const visible = css({
  visibility: 'visible',
});

export const transformOriginCenter = unprocessedCss({
  transformOrigin: 'center',
});

export const transformOriginTopLeft = unprocessedCss({
  transformOrigin: 'top left',
});

// Filter styles.
export const filterBrightness80 = unprocessedCss({
  filter: 'brightness(80%)',
});

// Display styles

export const displayContents = unprocessedCss({
  display: 'contents',
});

export const inlineBlock = unprocessedCss({
  display: 'inline-block',
});

export const table = unprocessedCss({
  display: 'table',
});

export const tableRow = unprocessedCss({
  display: 'table-row',
});

export const tableCell = unprocessedCss({
  display: 'table-cell',
});

// Outline styles.

export const outlineNone = unprocessedCss({
  outline: 'none',
});

export const outlineGrey1 = unprocessedCss({
  outline: 'solid 1px rgba(0,0,0,0.20)',
});

// Align styles.

export const alignItemsCenter = unprocessedCss({
  alignItems: 'center',
});

export const alignItemsFlexStart = unprocessedCss({
  alignItems: 'flex-start',
});

// Icon styles.

export const iconSubdued = css({
  fill: 'subdued',
});

// Outline styles.

// Border styles.

export const borderSolid = css({
  borderStyle: 'solid',
});

/**
 * Helper function to create responsive styles.
 * @param styles The unprocessed CSS styles to apply.
 * - `compact` styles are applied when the viewport height is short.
 * - `minimal` styles are applied when the viewport height is very short.
 * - `regular` styles are applied when the viewport height is normal
 * @returns The responsive styles
 */
export function responsiveCss(styles: {
  compact?: Parameters<typeof unprocessedCss>[0] | Parameters<typeof css>[0];
  minimal?: Parameters<typeof unprocessedCss>[0] | Parameters<typeof css>[0];
  regular?: Parameters<typeof unprocessedCss>[0] | Parameters<typeof css>[0];
}): ReturnType<typeof unprocessedCss> {
  // iPhone SE has a 4.0-inch screen with a screen size 320px × 568px.
  // Use 570px as the breakpoint for compact styles.
  const {compact, minimal, regular} = styles;
  const none = {};
  return unprocessedCss({
    [`@media (max-height: 450px)` as `@media SAIL-3040`]: minimal || none,
    // @ts-expect-error - TS1117 - An object literal cannot have multiple properties with the same name.
    [`@media (max-height: 570px)` as `@media SAIL-3040`]: compact || none,
    // @ts-expect-error - TS1117 - An object literal cannot have multiple properties with the same name.
    [`@media (min-height: 570px)` as `@media SAIL-3040`]: regular || none,
  });
}

/**
 * Device specific styles.
 * @param styles The device specific CSS styles to apply.
 * @returns The device specific CSS values.
 */
export function deviceCss(styles: {
  all?: Parameters<typeof css>[0];
  desktop?: Parameters<typeof css>[0];
  mobile?: Parameters<typeof css>[0];
}): ReturnType<typeof css> {
  const {all, desktop, mobile} = styles;
  const none = {};
  return css({
    ...all,
    [`@media (min-width: 768px)` as `@media SAIL-3040`]: desktop || none,
    // @ts-expect-error - TS1117 - An object literal cannot have multiple properties with the same name.
    [`@media (max-width: 768px)` as `@media SAIL-3040`]: mobile || none,
  });
}

// Composite styles.

// CSS Scroll Shadows
// generated by https://css-scroll-shadows.vercel.app/
// https://css-scroll-shadows.vercel.app/?bgColor=ffffff&shadowColor=808080&pxSize=9
const scrollShadows = [
  `linear-gradient(#ffffff 33%, rgba(255,255,255, 0))`,
  `linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%`,
  `radial-gradient(farthest-side at 50% 0, rgba(128,128,128, 0.2), rgba(0,0,0,0))`,
  `radial-gradient(farthest-side at 50% 100%, rgba(128,128,128, 0.2), rgba(0,0,0,0)) 0 100%`,
].join(',');

// This adds a top and bottom shadow while scrolling when needed.
export const withScrollShadows = unprocessedCss({
  background: scrollShadows,
  backgroundAttachment: 'local, local, scroll, scroll',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 27px, 100% 27px, 100% 9px, 100% 9px',
});

// Page Transition styles.

export const withPageTransitionAnimation = unprocessedCss(
  PageTransitionStyles.withPageTransitionAnimation,
);

export const withoutPageTransitionAnimation = unprocessedCss(
  PageTransitionStyles.withoutPageTransitionAnimation,
);
