import {useEffect, useState} from 'react';

type HistoryState = {
  length: number;
};

function createHistoryState(): HistoryState {
  return {
    length: window.history.length,
  };
}

/**
 * This hook reacts to browser history changes and returns the current history
 * state.
 * @returns The current history state.
 */
export default function useBrowserHistory() {
  const [history, setHistory] = useState<HistoryState>(createHistoryState);

  useEffect(() => {
    const onNavigation = () => {
      setHistory(createHistoryState());
    };
    window.addEventListener('popstate', onNavigation, false);
    return () => {
      window.removeEventListener('popstate', onNavigation, false);
    };
  }, []);

  return history;
}
