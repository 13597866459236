import isDeepEqual from 'fast-deep-equal/react';
import * as React from 'react';

/**
 * This React Hook is used to create a memoized version of a value.
 * It uses 'fast-deep-equal' to check the equality of the current
 * and input value deeply and if they are not equal, the reference
 * value gets updated with the input value. This helps in efficient rendering.
 *
 * @see https://sail.stripe.me/apis/react/useMemoizedValue
 */
export default function useMemoizedValue<T>(value: T): T {
  const valueRef = React.useRef(value);

  if (!isDeepEqual(valueRef.current, value)) {
    valueRef.current = value;
  }

  return valueRef.current;
}
