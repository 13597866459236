import {produce} from 'immer';
import {clamp} from 'lodash';

import {Feedback} from 'gelato/frontend/src/controllers/states/DocumentState';
import ImageFrame from 'gelato/frontend/src/lib/ImageFrame';
import BaseInspector from 'gelato/frontend/src/ML/detectors/BaseInspector';

import type {InspectionState} from 'gelato/frontend/src/controllers/states/DocumentState';
import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

// The singleton instance.
let instance: FocusInspector | null = null;

// The record of the blur score data obtained at a zoom level.
type ScoreRecord = {
  avarage: number;
  denominator: number;
  numerator: number;
};

const EMPTY_RECORD: Readonly<ScoreRecord> = {
  avarage: 0,
  denominator: 0,
  numerator: 0,
};

// The minimum number of data points to make a decision to adjust the zoom.
const MIN_DATA_SIZE_TO_ADJUST_ZOOM = 10;

// This is close to the minimum zoom level that we could still detect
// the document. We'd almost unlikely to get a document if we move
// farther.
const MIN_ZOOM_LEVEL = 2;

// This is close to the maximum zoom level that we could image would
// overflow the camera frame.
const MAX_ZOOM_LEVEL = 9;

// The minimum difference between the current score and the score at
// the next zoom level to adjust the zoom.
const MIN_SCORE_DIFFERENCE_TO_ADJUST_ZOOM = 2;

/**
 * This inspector detects if the document is in focus and provides feedback to
 * the user to adjust the distance between the camera and the document if
 * necessary.
 */
export default class FocusInspector extends BaseInspector<
  [Readonly<ApplicationState>, Readonly<InspectionState>],
  Readonly<InspectionState>
> {
  /**
   * The recorded blur score data per zoom level.
   */
  _data: Map<number, ScoreRecord> = new Map();

  /**
   *  The number of data points recorded.
   */
  _dataSize = 0;

  static displayName = 'FocusInspector';

  /**
   * Whether the inspector is supported in the current environment.
   */
  static isSupported(): boolean {
    return true;
  }

  static getInstance(): FocusInspector {
    if (!instance) {
      instance = new FocusInspector();
    }
    return instance;
  }

  constructor() {
    super(FocusInspector.displayName);
  }

  /**
   * @implements {BaseInspector}
   */
  protected async buildImpl(): Promise<void> {
    this._data.clear();
    this._dataSize = 0;
  }

  /**
   * @implements {BaseInspector}
   */
  protected async warmUpImpl(): Promise<void> {
    // Do nothing.
  }

  /**
   * @implements {BaseInspector}
   */
  protected async detectImpl(
    appState: Readonly<ApplicationState>,
    inspectionState: Readonly<InspectionState>,
  ): Promise<Readonly<InspectionState>> {
    const {documentBlurScore, documentLocation, inputImage} = inspectionState;

    if (!documentBlurScore || !documentLocation) {
      return inspectionState;
    }

    const zoomLevel = computeZoomLevel(inputImage, documentLocation);

    const currentRecord = this._data.get(zoomLevel) ?? {
      ...EMPTY_RECORD,
    };

    currentRecord.numerator += documentBlurScore;
    currentRecord.denominator += 1;
    currentRecord.avarage = Math.round(
      currentRecord.numerator / currentRecord.denominator,
    );

    // Record the blur score data per zoom level.
    this._data.set(zoomLevel, currentRecord);

    // Record the number of data points.
    this._dataSize += 1;

    if (inspectionState.feedback) {
      // We already have a feedback. Do not overwrite it.
      return inspectionState;
    }

    if (!isCentered(inputImage, documentLocation)) {
      return produce(inspectionState, (draft) => {
        draft.feedback = Feedback.moveToCenter;
      });
    }

    if (zoomLevel < MIN_ZOOM_LEVEL) {
      // Ask the user to move closer.
      return produce(inspectionState, (draft) => {
        draft.feedback = Feedback.moveCloser;
      });
    }

    if (zoomLevel > MAX_ZOOM_LEVEL) {
      //  Ask the user to move farther.
      return produce(inspectionState, (draft) => {
        draft.feedback = Feedback.moveFarther;
      });
    }

    // Check if we could dynamically adjust the zoom level based on the
    // recorded data.
    // Different devices have different camera focus range. We need to
    // find the possible optimal zoom level for each device.
    if (this._dataSize >= MIN_DATA_SIZE_TO_ADJUST_ZOOM) {
      // We have enough data points to make a decision.
      const moveFartherData = this._data.get(zoomLevel - 1);
      const moveCloserData = this._data.get(zoomLevel + 1);
      const moveFartherScore = moveFartherData?.avarage ?? -1;
      const moveCloserScore = moveCloserData?.avarage ?? -1;
      const currentScore = currentRecord.avarage;
      if (
        moveFartherScore > currentScore + MIN_SCORE_DIFFERENCE_TO_ADJUST_ZOOM &&
        moveFartherScore > moveCloserScore
      ) {
        // Move farther could meaniningfully improve the blur score.
        return produce(inspectionState, (draft) => {
          draft.feedback = Feedback.moveFarther;
        });
      }
      if (
        moveCloserScore > currentScore + MIN_SCORE_DIFFERENCE_TO_ADJUST_ZOOM &&
        moveCloserScore > moveFartherScore
      ) {
        // Move closer could meaniningfully improve the blur score.
        return produce(inspectionState, (draft) => {
          draft.feedback = Feedback.moveCloser;
        });
      }
    }

    // The document is in focus.
    return inspectionState;
  }

  /**
   * @implements {BaseInspector}
   */
  protected async disposeImpl(): Promise<void> {
    this._data.clear();
    this._dataSize = 0;
    if (instance === this) {
      // The instance is being disposed and no longer usable. Clear the
      // singleton instance.
      instance = null;
    }
  }
}

/**
 * Check if the document is centered within the camera frame.
 * @param inputImage The full image captured by the camera.
 * @param documentLocation The location of the document in the image.
 * @returns If the document is centered.
 */
function isCentered(
  inputImage: ImageFrame,
  documentLocation: InspectionState['documentLocation'],
): boolean {
  if (!documentLocation) {
    return false;
  }
  const {width, height} = inputImage;
  const cx = width / 2;
  const cy = height / 2;
  const [x, y] = documentLocation.topLeft;
  const [w, h] = documentLocation.dimensions;
  const dx = x + w / 2 - cx;
  const dy = y + h / 2 - cy;
  const px = Math.abs(dx) / cx;
  const py = Math.abs(dy) / cy;
  return Math.max(px, py) < 0.2;
}

/**
 * Compute the zoom level based on the document location.
 * - zoomLevel: 0: The document is not found or the document is too far from the camera.
 * - zoomLevel: 10: The document is too close to the camera.
 * @param inputImage The full image captured by the camera.
 * @param documentLocation The location of the document in the image.
 * @returns The zoom level that ranges from 0 to 10.
 */
function computeZoomLevel(
  inputImage: ImageFrame,
  documentLocation: InspectionState['documentLocation'],
): number {
  if (!documentLocation) {
    return 0;
  }
  const {width, height} = inputImage;
  // Since the camera frame is square, we use the smaller dimension to compute
  // the zoom level.
  const squareSize = Math.min(width, height);
  const [w, h] = documentLocation.dimensions;
  const pw = w / squareSize;
  const ph = h / squareSize;
  const zoomLevel = Math.round(Math.max(pw, ph) * 10);
  return clamp(zoomLevel, 0, 10);
}
