import type {RefObject} from 'react';
import {useEffect} from 'react';
import {useEffectEvent} from './useEffectEvent';

export function useEvent<K extends keyof GlobalEventHandlersEventMap>(
  ref: RefObject<EventTarget>,
  event: K,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: (this: Document, ev: GlobalEventHandlersEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
) {
  const handleEvent = useEffectEvent(handler);
  const isDisabled = handler == null;

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const element = ref.current;
    element?.addEventListener(event, handleEvent, options);
    return () => {
      element?.removeEventListener(event, handleEvent, options);
    };
  }, [ref, event, options, isDisabled, handleEvent]);
}
