import {view} from '@sail/ui';
import * as React from 'react';

import useThemeOverride from 'gelato/frontend/src/components/theming/useThemeOverride';
import {DEFAULT_BRANDING_BUTTON_COLOR} from 'gelato/frontend/src/lib/constants';
import {useBranding} from 'gelato/frontend/src/lib/hooks';

type Props = {
  children: React.ReactElement;
};

const SailNextThemeProvider = ({children}: Props) => {
  const {buttonColor} = useBranding();
  // At App start, there may not be a buttonColor, so we'll safely set a default
  const merchantButtonColor = buttonColor || DEFAULT_BRANDING_BUTTON_COLOR;
  const theme = useThemeOverride(merchantButtonColor);

  return (
    <view.div uses={theme} css={{height: 'fill', width: 'fill'}}>
      {children}
    </view.div>
  );
};

export default SailNextThemeProvider;
