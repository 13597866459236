import {MessageDescriptor, defineMessages} from 'react-intl';

import autocorrect, {PatternType} from 'sail/lib/autocorrect';

import type {Country} from '@stripe-internal/data-gelato/schema/types';

export const ID_NUMBER_COUNTRY_LABEL: Partial<
  Record<Country, MessageDescriptor>
> & {fallback: MessageDescriptor} = defineMessages({
  fallback: {
    id: 'individualIdNumber.option.fallback.label',
    description:
      'Label for the personal id number field in the hosted verification details collection form for countries without an exception',
    defaultMessage: 'Personal ID number',
  },
  AE: {
    id: 'individualIdNumber.option.AE.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for United Arab Emirates',
    defaultMessage: 'Emirates ID number',
  },
  AR: {
    id: 'individualIdNumber.option.AR.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Argentina',
    defaultMessage: 'Individual CUIT/CUIL',
  },
  AT: {
    id: 'individualIdNumber.option.AT.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Austria ("Steuernummer")',
    defaultMessage: 'Personal identification number (St.Nr.)',
  },
  BE: {
    id: 'individualIdNumber.option.BE.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Belgium',
    defaultMessage: 'National Registration Number',
  },
  BR: {
    id: 'individualIdNumber.option.BR.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Brazil',
    defaultMessage: 'Individual CPF',
  },
  CA: {
    id: 'individualIdNumber.option.CA.label',
    description:
      'Label for the personal id number field in the hosted verification details collection form for Canada.',
    defaultMessage: 'Social insurance number',
  },
  CH: {
    id: 'individualIdNumber.option.CH.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Switzerland',
    defaultMessage: 'OASI / AHV-Nr.',
  },
  DE: {
    id: 'individualIdNumber.option.DE.label',
    description:
      'Label for the personal id numer field in the Connect onboarding forms for Germany',
    defaultMessage: 'Steuer-Identifikationsnummer',
  },
  DK: {
    id: 'individualIdNumber.option.DK.label',
    description:
      'Label for the personal id numer field in the Connect onboarding forms for Denmark',
    defaultMessage: 'Personal identification number',
  },
  EE: {
    id: 'individualIdNumber.option.EE.label',
    description:
      'Label for the personal tax id number field in the Connect onboarding forms for Estonia.',
    defaultMessage: 'Personal Tax ID',
  },
  ES: {
    id: 'individualIdNumber.option.ES.label',
    description:
      'Label for the personal id numer field in the Connect onboarding forms for Spain ("Número de Identificación Fiscal (NIF)")',
    defaultMessage: 'Fiscal Identification Number (NIF)',
  },
  FI: {
    id: 'individualIdNumber.option.FI.label',
    description:
      'Label for the personal id numer field in the Connect onboarding forms for Finland',
    defaultMessage: 'Personal identity code (HETU)',
  },
  FR: {
    id: 'individualIdNumber.option.FR.label',
    description:
      'Label for the personal id numer field in the Connect onboarding forms for France ("Numéro de sécurité sociale (NIR)")',
    defaultMessage: 'Social Security number (NIR)',
  },
  GR: {
    id: 'individualIdNumber.option.GR.label',
    description:
      'Label for the personal tax id number field in the Connect onboarding forms for Greece.',
    defaultMessage: 'Personal Tax ID',
  },
  HK: {
    id: 'individualIdNumber.option.HK.label',
    description:
      'Label for the personal id number field in the hosted verification details collection form for Hong Kong.',
    defaultMessage: 'Hong Kong Identity Card Number',
  },
  ID: {
    id: 'individualIdNumber.option.ID.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Indonesia.',
    defaultMessage: 'KTP Number',
  },
  IN: {
    id: 'individualIdNumber.option.IN.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for India.',
    defaultMessage: 'Permanent Account Number (PAN)',
  },
  IT: {
    id: 'individualIdNumber.option.IT.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Italy.',
    defaultMessage: 'Fiscal code (codice fiscale)',
  },
  LI: {
    id: 'individualIdNumber.option.LI.label',
    description:
      'Label for the personal tax id number field in the Connect onboarding forms for Liechtenstein (DE: "Personenidentifikationsnummer").',
    defaultMessage: 'Personal Tax ID (PEID)',
  },
  LT: {
    id: 'individualIdNumber.option.LT.label',
    description:
      'Label for the personal tax id number field in the Connect onboarding forms for Lithuania.',
    defaultMessage: 'Personal Tax ID',
  },
  LV: {
    id: 'individualIdNumber.option.LV.label',
    description:
      'Label for the personal tax id number field in the Connect onboarding forms for Latvia.',
    defaultMessage: 'Personal Tax ID',
  },
  MT: {
    id: 'individualIdNumber.option.MT.label',
    description:
      'Label for the personal tax id number field in the Connect onboarding forms for Malta.',
    defaultMessage: 'Personal Tax ID',
  },
  MX: {
    id: 'individualIdNumber.option.MX.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Mexico.',
    defaultMessage: 'Personal RFC',
  },
  MY: {
    id: 'individualIdNumber.option.MY.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Malaysia.',
    defaultMessage: 'Individual NRIC (MyKad)',
  },
  NL: {
    id: 'individualIdNumber.option.NL.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for the Netherlands.',
    defaultMessage: 'Personal identification number',
  },
  NO: {
    id: 'individualIdNumber.option.NO.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Norway.',
    defaultMessage: 'National identity number',
  },
  NZ: {
    id: 'individualIdNumber.option.NZ.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for New Zeland.',
    defaultMessage: 'IRD Number',
  },
  PL: {
    id: 'individualIdNumber.option.PL.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Poland.',
    defaultMessage: 'PESEL number',
  },
  PT: {
    id: 'individualIdNumber.option.PT.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Portugal.',
    defaultMessage: 'Fiscal identification number (NIF)',
  },
  RO: {
    id: 'individualIdNumber.option.RO.label',
    description:
      'Label for the personal id number field in the Connect onboarding forms for Romania (CNP).',
    defaultMessage: 'Personal Numeric Code (CNP)',
  },
  SE: {
    id: 'individualIdNumber.option.SE.label',
    description:
      'Label for the personal tax id number field in the Connect onboarding forms for Sweden.',
    defaultMessage: 'Personal identity number',
  },
  SI: {
    id: 'individualIdNumber.option.SI.label',
    description:
      'Label for the personal tax id number field in the Connect onboarding forms for Slovenia.',
    defaultMessage: 'Personal Tax ID',
  },
  SK: {
    id: 'individualIdNumber.option.SK.label',
    description:
      'Label for the personal tax id number field in the Connect onboarding forms for Slovakia.',
    defaultMessage: 'Personal Tax ID',
  },
  SG: {
    id: 'individualIdNumber.option.SG.label',
    description:
      'Label for the personal id number field in the hosted verification details collection form for Singapore.',
    defaultMessage: 'NRIC or FIN',
  },
  US: {
    id: 'individualIdNumber.option.US.label',
    description:
      'Label for the personal id number field in the hosted verification details collection form for the United States.',
    defaultMessage: 'Last 4 of Social Security number',
  },
});

export const ID_NUMBER_COUNTRY_PLACEHOLDERS: {
  [key: string]: string;
} = {
  AT: '12-123/1234',
  BE: '01.01.01-344.21',
  BR: '000.000.000-00',
  CA: '123 123 123',
  CH: '756.1234.5678.97',
  CY: '1234567890',
  DE: '1121081508150',
  DK: '190483-1234',
  ES: '12345678A',
  FR: '18504XX123001 XX',
  HK: 'X123456(A)',
  ID: '1234567890123456',
  IT: 'MRTMTT25D09F205Z',
  MT: '1234567M',
  MX: 'DAXR870101VY7',
  MY: '000101-12-3456',
  RO: '1234567890123',
  SE: '830419-1234',
  SI: '12345678',
  SG: 'S1234567A',
  US: '1234',
};

export const ID_NUMBER_COUNTRY_PARTIALS: {
  [key: string]: any;
} = {
  US: {'aria-label': '••• – •• –', content: '••• – •• –'},
};

export const ID_NUMBER_COUNTRY_AUTOCORRECT_PATTERNS: Partial<
  Record<Country, PatternType[]>
> = {
  CA: [
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.optionalCharacter('-'),
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.optionalCharacter('-'),
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
  ],
  US: [
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
  ],
  // CUIT/CUIL are of format XX-XXXXXXXX-X
  AR: [
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.optionalCharacter('-'),
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.optionalCharacter('-'),
    autocorrect.Pattern.digit,
  ],
  // BR CPF are of format 'xxx.xxx.xxx-xx'
  BR: [
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.optionalCharacter('.'),
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.optionalCharacter('.'),
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.optionalCharacter('-'),
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
  ],
  // SE PIN are of format 'xxxxxx-xxxx'
  SE: [
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.optionalCharacter('-'),
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
  ],
  // DK CPF are in format 'xxxxxx-xxxx
  DK: [
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.optionalCharacter('-'),
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
    autocorrect.Pattern.digit,
  ],
};
