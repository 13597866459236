import {accept} from '@sail/icons/react/Icon';
import {Icon, css, view} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import BottomSheet, {
  Messages as BottomSheetMessages,
} from 'gelato/frontend/src/components/BottomSheetV2';
import Button from 'gelato/frontend/src/components/ButtonV2';
import Heading from 'gelato/frontend/src/components/HeadingV2';
import Message from 'gelato/frontend/src/components/Message';
import ScrollableArea from 'gelato/frontend/src/components/ScrollableArea';
import {
  hidden,
  mB200,
  mB50,
  mX100,
  outlineNone,
  tBoldL,
  visible,
  allReset,
} from 'gelato/frontend/src/components/stylesV2';
import {LocaleValue} from 'gelato/frontend/src/controllers/states/LocaleState';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

const {useCallback} = React;

const Messages = defineMessages({
  selectLanguage: {
    defaultMessage: 'Select a language',
    description: 'Heading for the select language section',
    id: 'verification.languageSettingSheet.selectLanguage',
  },
  suggestedLanguages: {
    defaultMessage: 'Suggested languages',
    description: 'Heading for the suggested languages section',
    id: 'verification.languageSettingSheet.suggestedLanguages',
  },
});

const Styles = {
  allOptions: css({
    maxHeight: 'calc(min(260px, 40vh))',
  }),
  option: css({
    ':hover': {
      backgroundColor: 'feedback.neutral.subdued',
    },
    ':focus': {
      backgroundColor: 'feedback.neutral.subdued',
    },
    borderRadius: 'small',
    cursor: 'pointer',
    userSelect: 'none',
  }),
  optionContent: css({
    paddingY: 'small',
  }),
  optionsList: css({
    stack: 'y',
    gap: 'xsmall',
  }),
};

/**
 * The option for selecting a language.
 */
function LocaleOption(props: {
  onSelect: (value: LocaleValue) => void;
  selected: boolean;
  value: LocaleValue;
}): JSX.Element {
  const {value, onSelect, selected} = props;

  const onPress = useCallback(() => {
    onSelect(value);
  }, [value, onSelect]);

  const styles = [allReset, outlineNone, Styles.option];
  if (selected) {
    styles.push(tBoldL);
  }

  return (
    <view.button onClick={onPress} uses={styles}>
      <view.div uses={[Styles.optionContent]}>
        <Icon
          icon={accept}
          size="small"
          uses={[selected ? visible : hidden, mX100]}
        />
        {value[1]}
      </view.div>
    </view.button>
  );
}

/**
 * The bottom sheet for selecting a language.
 */
export default function LanguageSettingSheet() {
  const {appController, appState} = useAppController();

  const onClose = useCallback(() => {
    appController.closeLayer(LanguageSettingSheet);
  }, [appController]);

  const handleChangeLocale = useCallback(
    (value: LocaleValue) => {
      appController.changeLocale(value[0]);
    },
    [appController],
  );

  const currentLocale = appState.locale.currentValue[0];

  const renderOption = (value: LocaleValue) => (
    <LocaleOption
      key={value[0]}
      onSelect={handleChangeLocale}
      selected={value[0] === currentLocale}
      value={value}
    />
  );

  const allOptions = appState.locale.availableValues.map(renderOption);
  const suggestedOptions = appState.locale.suggestedValues.map(renderOption);
  const footer = (
    <Button onPress={onClose} type="primary">
      <Message {...BottomSheetMessages.close} />
    </Button>
  );

  return (
    <BottomSheet data-testid="language-setting-sheet" footer={footer}>
      <view.div>
        <Heading level={3} uses={[mB50]}>
          <Message {...Messages.suggestedLanguages} />
        </Heading>
        <view.div uses={[mB200, Styles.optionsList]}>
          {suggestedOptions}
        </view.div>
      </view.div>
      <view.div>
        <Heading level={3} uses={[mB50]}>
          <Message {...Messages.selectLanguage} />
        </Heading>
        <ScrollableArea uses={[mB50, Styles.allOptions, Styles.optionsList]}>
          {allOptions}
        </ScrollableArea>
      </view.div>
    </BottomSheet>
  );
}
