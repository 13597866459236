import * as BlinkIDSDK from '@microblink/blinkid-imagecapture-in-browser-sdk';
import * as Sentry from '@sentry/browser';

import {
  logInDev,
  timeInDev,
  timeEndInDev,
  warnInDev,
} from 'gelato/frontend/src/lib/assert';
import {getConfigValue} from 'gelato/frontend/src/lib/config';
import ImageFrame from 'gelato/frontend/src/lib/ImageFrame';
import {reportMetric} from 'gelato/frontend/src/lib/metricsBatcher';
import {captureMessage, handleException} from 'gelato/frontend/src/lib/sentry';
import Detector from 'gelato/frontend/src/ML/detectors/Detector';
import {DetectorSoftError} from 'gelato/frontend/src/ML/detectors/DetectorSoftError';

import type {DocumentSide} from '@stripe-internal/data-gelato/schema/types';

class MicroblinkLicenseException extends Error {
  constructor(message: string) {
    super();
    this.name = 'MicroblinkLicenseException';
    this.message = message;
  }
}

export default class MicroblinkWasm extends Detector<
  [HTMLCanvasElement | ImageFrame, DocumentSide],
  boolean
> {
  public readonly detectorName = 'MicroblinkWasm';

  private microblinkWasmSdk: BlinkIDSDK.WasmSDK | undefined;

  private recognizerRunnerPromise:
    | Promise<BlinkIDSDK.RecognizerRunner>
    | undefined;

  private recognizer: BlinkIDSDK.BlinkIdImageCaptureRecognizer | undefined;

  private side: DocumentSide;

  private previousSideResult: boolean | undefined;

  private frontCanvas: HTMLCanvasElement | ImageFrame | undefined;

  constructor(side: DocumentSide) {
    super();
    this.side = side;
  }

  // See Microblink documentation: https://github.com/BlinkID/blinkid-imagecapture-in-browser
  protected async _build() {
    // License comes from Next config. The license we use depends on the domain (Dev vs Prod)
    const license = getConfigValue('MICROBLINK_LICENSE');
    try {
      const loadStartTime = Date.now();
      const loadSettings = new BlinkIDSDK.WasmSDKLoadSettings(license);

      loadSettings.allowHelloMessage = false;

      // Load WASM modules from static directory.
      // Note that when upgrading MB WASM, you *must* copy the WASM & other resources from the
      // NPM package into payserver/gelato/frontend/public/assets/microblink-wasm
      const prefix = getConfigValue('ASSETS_CDN_PREFIX');
      const staticHost = prefix !== '' ? prefix : window.location.origin;
      loadSettings.engineLocation = `${staticHost}/assets/microblink-wasm`;

      // Don't track download progress.
      loadSettings.loadProgressCallback = null;

      const microblinkWasmSdk = await BlinkIDSDK.loadWasmModule(loadSettings);
      reportMetric({
        metric: 'gelato_frontend_mb_wasm_load_time',
        operation: 'timing',
        value: Date.now() - loadStartTime,
      });
      logInDev('Microblink build success');
      reportMetric({
        metric: 'gelato_frontend_mb_wasm_supported',
        operation: 'count',
        value: 1,
      });
      this.microblinkWasmSdk = microblinkWasmSdk;
    } catch (e: any) {
      logInDev('Microblink build error', e);
      // Catch any errors coming from MB and just report the failure counts.
      reportMetric({
        metric: 'gelato_frontend_mb_wasm_not_supported',
        operation: 'count',
        value: 1,
      });
      let error: Error;
      if (e?.code === 'LICENSE_UNLOCK_ERROR') {
        error = new MicroblinkLicenseException(
          String(`${e.message}. ${e.details}`),
        );
        // This is very bad, we should escalate this to Sentry.
        handleException(error, 'MicroblinkWasm build() error');
      } else if (e instanceof Error) {
        error = e;
      } else {
        error = new Error(`MicroblinkWasm build() error: ${e}`);
      }

      // throw a soft error so it doesn't break other models
      throw new DetectorSoftError({
        message: 'MicroblinkWasm build() error',
        name: 'DETECTOR_BUILD_SOFT_ERROR',
        cause: error,
      });
    }
  }

  protected async _warmup() {
    await this.setSide(this.side);
  }

  private async _setSide(side: DocumentSide): Promise<boolean> {
    let mbWasmSdk = this.microblinkWasmSdk;
    if (!mbWasmSdk) {
      await this.build();
      if (!this.microblinkWasmSdk) {
        throw new Error('microblinkWasmSdk is still undefined after build');
      }
      mbWasmSdk = this.microblinkWasmSdk;
    }

    const oldRecognizer = this.recognizer;

    this.recognizer = await BlinkIDSDK.createBlinkIdImageCaptureRecognizer(
      mbWasmSdk,
    );
    const settings = await this.recognizer.currentSettings();
    settings.captureModeFilter = {
      enableMrzId: true,
      enableMrzPassport: true,
      enableMrzVisa: true,
      // PhotoID will accept anything document-shaped with a face on it!
      enablePhotoId: false,
      enableBarcodeId: true,
      enableFullDocumentRecognition: true,
    };
    settings.captureBothDocumentSides = side === 'back';
    await this.recognizer.updateSettings(settings);

    if (this.recognizerRunnerPromise) {
      const runner = await this.recognizerRunnerPromise;
      await runner.resetRecognizers(true);
      await runner.reconfigureRecognizers([this.recognizer], true);
    } else {
      // Note that recognizerRunnerPromise is a promise so that it is atomically set
      // here. Otherwise if we await the underlying value, we can end up calling createRecognizerRunner()

      // twice since this.recognizerRunnerPromise will be null while waiting for it to build.
      this.recognizerRunnerPromise = BlinkIDSDK.createRecognizerRunner(
        mbWasmSdk,
        [this.recognizer],
        true,
      );
    }

    if (oldRecognizer) {
      oldRecognizer.delete();
    }

    if (side === 'back') {
      if (!this.frontCanvas) {
        throw new Error(
          'setSide(DocumentSide.back) called without front Canvas',
        );
      }
      // first call on a 2-sided document has to always be the front
      return this.detect(this.frontCanvas, 'back');
    }
    return true;
  }

  private async setSide(side: DocumentSide): Promise<boolean | undefined> {
    if (side === this.side && this.previousSideResult !== undefined) {
      return this.previousSideResult;
    }
    this.side = side;
    try {
      const sideResult = await this._setSide(side);
      this.previousSideResult = sideResult;
      return sideResult;
    } catch (e) {
      warnInDev(e);
      return undefined;
    }
  }

  public getPreviousSideResult() {
    return this.previousSideResult;
  }

  protected async _detect(
    canvas: HTMLCanvasElement | ImageFrame,
    side: DocumentSide,
  ) {
    try {
      timeInDev(`MBRecognize_${side}`);
      Sentry.addBreadcrumb({
        category: 'MicroblinkWasm',
        message: 'Running MB WASM',
        level: Sentry.Severity.Info,
      });

      // this should just resolve if the same side is called multiple times in a row
      await this.setSide(side);

      if (!this.recognizerRunnerPromise || !this.recognizer) {
        throw new Error('recognizerRunnerPromise or recognizer is undefined');
      }

      const source =
        canvas instanceof ImageFrame ? canvas.getSource()! : canvas;
      const capturedFrame = BlinkIDSDK.captureFrame(source);
      const runner = await this.recognizerRunnerPromise;
      const processResult = await runner.processImage(capturedFrame);
      logInDev('MB results', processResult);
      if (processResult === BlinkIDSDK.RecognizerResultState.Empty) {
        return false;
      } else {
        const genericIDResults = await this.recognizer.getResult();
        logInDev('MB results', genericIDResults);
        const isValid =
          genericIDResults.state === BlinkIDSDK.RecognizerResultState.Valid ||
          genericIDResults.state ===
            BlinkIDSDK.RecognizerResultState.StageValid;

        if (side === 'front' && isValid) {
          if (this.frontCanvas && this.frontCanvas instanceof ImageFrame) {
            this.frontCanvas.dispose();
            this.frontCanvas = undefined;
          }

          if (canvas instanceof ImageFrame) {
            // We use ImageFrame in Butter 2.0.
            // We need to make a copy since the provided bitmap is thrown away after each cycle.
            // TODO: need to dispose of bitmap during detector cleanup
            this.frontCanvas = await canvas.clone();
          } else {
            this.frontCanvas = canvas;
          }
        }

        return isValid;
      }
    } catch (e: any) {
      Sentry.addBreadcrumb({
        category: 'MicroblinkWasm',
        message: `Exception in MB WASM: ${e}`,
        level: Sentry.Severity.Error,
      });
      // The errors thrown by MB WASM appear to cause Sentry to choke, so just pretend it's a string
      try {
        const jsonString = JSON.stringify(e);
        captureMessage(`MB Wasm failed. error: ${jsonString}`);
      } catch (e2) {
        captureMessage(`MB Wasm failed. But error could not be jsonfied: ${e}`);
      }
      return false;
    } finally {
      Sentry.addBreadcrumb({
        category: 'MicroblinkWasm',
        message: 'Done MB WASM',
        level: Sentry.Severity.Info,
      });
      timeEndInDev(`MBRecognize_${side}`);
    }
  }
}
