import {FLOW_SESSION_ID} from 'gelato/frontend/src/controllers/states/SessionState';
import {
  DEFAULT_BRANDING_PLATFORM_COLOR,
  DEFAULT_BRANDING_BUTTON_COLOR,
} from 'gelato/frontend/src/lib/constants';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {GraphQlField} from '@sail/data';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

type VerificationFlowStoragePayload = {
  branding: ReturnType<typeof Storage.getBranding>;
  consentConfig: ReturnType<typeof Storage.getConsentConfig>;
  livemode: ReturnType<typeof Storage.getLivemode>;
  options: ReturnType<typeof Storage.getOptions>;
  requiredFields: ReturnType<typeof Storage.getRequiredFields>;
  returnUrl: ReturnType<typeof Storage.getReturnUrl>;
  support: ReturnType<typeof Storage.getSupport>;
  welcomeHandoff: ReturnType<typeof Storage.getWelcomeHandoff>;
};

/**
 * Create the payload for `createVerificationFlowSessionFromStorage()`.
 * The payload is extracted from the session storage.
 */
export function createVerificationFlowStoragePayload(): VerificationFlowStoragePayload {
  return {
    branding: Storage.getBranding(),
    consentConfig: Storage.getConsentConfig(),
    livemode: Storage.getLivemode(),
    options: Storage.getOptions(),
    requiredFields: Storage.getRequiredFields(),
    returnUrl: Storage.getReturnUrl(),
    support: Storage.getSupport(),
    welcomeHandoff: Storage.getWelcomeHandoff(),
  };
}

/**
 * Create the synthetic session from the session storage.
 */
export function createVerificationFlowSessionFromStorage(
  payload: VerificationFlowStoragePayload,
): GraphQlField<GetSessionQueryData, 'session'> {
  const {
    branding,
    consentConfig,
    livemode,
    options,
    requiredFields,
    returnUrl,
    support,
    welcomeHandoff,
  } = payload;

  return {
    /* eslint sort-keys: ["error", "asc"] */
    __typename: 'Session',
    addressSupportedCountries: [],
    branding: {
      __typename: 'BrandingData',
      ...branding,
    },
    closed: false,
    collectedData: {
      __typename: 'CollectedData',
      individual: {
        __typename: 'IndividualData',
        address: {
          __typename: 'AddressData',
          city: null,
          country: null,
          line1: null,
          line2: null,
          state: null,
          zip: null,
        },
        consent: {
          __typename: 'ConsentData',
          accepted: null,
          permissions: null,
          selfieVerificationMethod: null,
          version: null,
        },
        dob: {
          __typename: 'DobData',
          day: null,
          month: null,
          year: null,
        },
        email: {
          __typename: 'EmailData',
          merchantProvidedAddress: null,
          otpDeclined: null,
          userProvidedAddress: null,
        },
        face: {
          __typename: 'FaceData',
          image: null,
        },
        idDocument: {
          __typename: 'DocumentData',
          back: null,
          country: null,
          docTypeHasMbDecodableBack: null,
          forceSkipBackImage: null,
          front: null,
          mbFrontDecoded: null,
          type: null,
        },
        idNumber: {
          __typename: 'IdNumberData',
          country: null,
        },
        name: {
          __typename: 'NameData',
          firstName: null,
          lastName: null,
        },
        phoneNumber: {
          __typename: 'PhoneNumberData',
          merchantProvidedPhoneNumber: null,
          otpDeclined: null,
          userProvidedPhoneNumber: null,
        },
      },
    },
    consentConfig: {
      __typename: 'ConsentConfig',
      additionalPrivacyPolicyUrl: consentConfig.additionalPrivacyPolicyUrl,
      imageMode: consentConfig.imageMode,
      privacyPolicyUrl: consentConfig.privacyPolicyUrl,
      stripeInternal: consentConfig.stripeInternal,
      useCase: consentConfig.useCase,
    },
    creationMethod: 'verification_link',
    defaultCountry: 'US',
    disableImageValidation: null,
    documentTypeAllowlist: options?.document_options.document_types || null,
    emailForHandoff: null,
    fMobile: options?.document_options.require_mobile_capture || false,
    id: FLOW_SESSION_ID,
    idNumberCountryAllowlist: [],
    idNumberSupportedCountries: [],
    ipCountry: null,
    ipCountrySupportable: true,
    lastErrorCode: null,
    lastErrorDetails: null,
    livemode,
    missingFields: requiredFields,
    networkingData: {
      __typename: 'NetworkingData',
      consentsToDocumentNetworking: null,
      networkedDirection: null,
      skipsDocumentNetworking: null,
    },
    operatingMode: 'primary',
    phoneForHandoff: null,
    phoneSupportedCountries: [],
    rLCapture: options?.document_options.require_live_capture || false,
    redacted: false,
    requiredFields,
    returnUrl,
    sanctionedDocumentCountry: null,
    skipSuccessPage: null,
    // We should not skip the welcome page for Butter
    skipWelcomePage: false,
    status: 'requires_action',
    submitted: false,
    support: {
      __typename: 'SupportData',
      ...support,
    },
    underConsentAge: null,
    unsupportedCountry: null,
    useConnectIframeDesign: null,
    welcomeHandoff,
  };
}

export function createPreviewSession(
  branding:
    | GraphQlField<GetSessionQueryData, 'session', 'branding'>
    | null
    | undefined,
): GraphQlField<GetSessionQueryData, 'session'> {
  return {
    /* eslint sort-keys: ["error", "asc"] */
    __typename: 'Session',
    addressSupportedCountries: [],
    branding: {
      __typename: 'BrandingData',
      additionalPlatformName: null,
      buttonColor: DEFAULT_BRANDING_BUTTON_COLOR,
      buttonColorOverride: DEFAULT_BRANDING_BUTTON_COLOR,
      isStripe: false,
      platformColor: DEFAULT_BRANDING_PLATFORM_COLOR,
      platformColorOverride: DEFAULT_BRANDING_PLATFORM_COLOR,
      platformIcon: '',
      platformIconOverride: '',
      platformName: 'Rocket Rides',
      platformNameOverride: 'Rocket Rides',
      ...branding,
    },
    closed: false,
    collectedData: {
      __typename: 'CollectedData',
      individual: {
        __typename: 'IndividualData',
        address: {
          __typename: 'AddressData',
          city: null,
          country: null,
          line1: null,
          line2: null,
          state: null,
          zip: null,
        },
        consent: {
          __typename: 'ConsentData',
          accepted: null,
          permissions: null,
          selfieVerificationMethod: null,
          version: null,
        },
        dob: {
          __typename: 'DobData',
          day: null,
          month: null,
          year: null,
        },
        email: {
          __typename: 'EmailData',
          merchantProvidedAddress: null,
          otpDeclined: null,
          userProvidedAddress: null,
        },
        face: {
          __typename: 'FaceData',
          image: null,
        },
        idDocument: {
          __typename: 'DocumentData',
          back: null,
          country: null,
          docTypeHasMbDecodableBack: null,
          forceSkipBackImage: null,
          front: null,
          mbFrontDecoded: null,
          type: null,
        },
        idNumber: {
          __typename: 'IdNumberData',
          country: null,
        },
        name: {
          __typename: 'NameData',
          firstName: null,
          lastName: null,
        },
        phoneNumber: {
          __typename: 'PhoneNumberData',
          merchantProvidedPhoneNumber: null,
          otpDeclined: null,
          userProvidedPhoneNumber: null,
        },
      },
    },
    consentConfig: {
      __typename: 'ConsentConfig',
      additionalPrivacyPolicyUrl: null,
      imageMode: 'identity_document_and_selfie',
      privacyPolicyUrl: 'https://www.example.com',
      stripeInternal: false,
      useCase: 'trust_and_safety',
    },
    creationMethod: 'verification_link',
    defaultCountry: 'US',
    disableImageValidation: null,
    documentTypeAllowlist: null,
    emailForHandoff: null,
    fMobile: false,
    id: FLOW_SESSION_ID,
    idNumberCountryAllowlist: [],
    idNumberSupportedCountries: [],
    ipCountry: null,
    ipCountrySupportable: true,
    lastErrorCode: null,
    lastErrorDetails: null,
    livemode: true,
    missingFields: ['consent', 'id_document_images', 'face'],
    networkingData: {
      __typename: 'NetworkingData',
      consentsToDocumentNetworking: null,
      networkedDirection: null,
      skipsDocumentNetworking: null,
    },
    operatingMode: 'primary',
    phoneForHandoff: null,
    phoneSupportedCountries: [],
    rLCapture: false,
    redacted: false,
    requiredFields: ['consent', 'id_document_images', 'face'],
    returnUrl: null,
    sanctionedDocumentCountry: null,
    skipSuccessPage: null,
    skipWelcomePage: false,
    status: 'requires_action',
    submitted: false,
    support: {
      __typename: 'SupportData',
      email: 'example@example.com',
      url: 'https://www.example.com',
    },
    underConsentAge: null,
    unsupportedCountry: null,
    useConnectIframeDesign: null,
    welcomeHandoff: false,
  };
}
