import {pluginsAnimation} from './pluginsAnimation';
import {pluginsBoxModel} from './pluginsBoxModel';
import {pluginsBoxShadow} from './pluginsBoxShadow';
import {pluginsFont} from './pluginsFont';
import {pluginsLayout} from './pluginsLayout';
import {pluginsTransform} from './pluginsTransform';

export const plugins = {
  ...pluginsAnimation,
  ...pluginsBoxModel,
  ...pluginsBoxShadow,
  ...pluginsFont,
  ...pluginsLayout,
  ...pluginsTransform,
};
