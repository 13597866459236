import * as React from 'react';

import LoadingSpinner from 'gelato/frontend/src/components/LoadingSpinner';
import {LOADING_PAGE_HEIGHT} from 'gelato/frontend/src/lib/constants';
import Box from 'sail/Box';

type Props = {
  height?: string;
};

export default function LoadingBox({height = LOADING_PAGE_HEIGHT}: Props) {
  return (
    <Box style={{height}}>
      <Box
        flex={{alignItems: 'center', justifyContent: 'center'}}
        style={{height: '100%'}}
      >
        <LoadingSpinner />
      </Box>
    </Box>
  );
}
