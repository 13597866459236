import {ConsumerSession} from 'gelato/frontend/src/api/Consumer/api';
import {ConsumerSessionConfirmVerificationResponse} from 'gelato/frontend/src/api/Consumer/types';

import type {
  GenericStripeError,
  FetchResult,
} from 'gelato/frontend/src/api/request';
import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

export default async function confirmConsumerSessionVerification(
  state: Readonly<ApplicationState>,
  otpValue: string,
): Promise<
  FetchResult<GenericStripeError, ConsumerSessionConfirmVerificationResponse>
> {
  return ConsumerSession.confirmVerification({
    type: 'SMS',
    code: otpValue,
    credentials: {
      consumer_session_client_secret:
        state.networkedIdentity.consumerSession!.client_secret,
    },
    request_surface: 'web_identity_product',
  });
}
