import {StyleCache, getAnimationNames} from '@sail/engine';
import type {Style} from '@sail/engine';

export const pluginsAnimation = {
  animation(value: string, set: Style.PluginAPI): void {
    const animationNames = getAnimationNames(value);
    animationNames.forEach((animationName) => {
      const intent = StyleCache.get(animationName);
      if (intent) {
        set.dependency(intent);
      }
    });
    set.property('animation', value);
  },
};
