import {createContext, useContext, useMemo} from 'react';
import {
  createObjectIntentType,
  getIntents,
  toIntent,
  provider,
} from '../../../intent';
import {onRender} from '../../intentTypes';
import {VERSION} from '../../../version';
import {DEFAULT_PREFIX} from './constants';
import {serialize} from './serialize';
import {replaceTokens} from './replaceTokens';
import type {Intent} from '../../../intent';
import type {CssTokens, CssTokensShape, SerializedStyles} from './types';

const TOKEN_VAR_REGEXP = /var\(([-\w]+)\)/g;

export type AssignedTokens = Record<string, string>;

function escape(str: string) {
  // would ideally use `CSS.escape()` here, but that isn't available in all browsers (or JSDOM)
  return str.replace(/\//g, '\\/');
}

function getCustomProperty(
  prefix: string,
  property: string,
  token: string,
): string {
  return `--${prefix}${property}-${escape(token)}`;
}

interface TokenOptions {
  prefix?: string;
}

interface TokenContextShape {
  tokens: AssignedTokens;
  styles: SerializedStyles;
}

const TokenContext = createContext<TokenContextShape>({
  tokens: {} as AssignedTokens,
  styles: {} as SerializedStyles,
});

const assignTokensIntent = createObjectIntentType(
  'assignTokens',
  (decorate) => (obj: AssignedTokens) => decorate(obj),
);

const processAssignedTokens = onRender((props) => {
  const inherited = useContext(TokenContext);
  const overrides = getIntents(assignTokensIntent, props) as AssignedTokens;
  const context = useMemo(() => {
    const tokens = {...inherited.tokens, ...overrides};
    const styles = serialize(
      {
        hash: '',
        options: {
          prefix: DEFAULT_PREFIX,
          alias: {},
          variants: {},
          layer: '',
          plugins: {},
          tokens: {},
          version: VERSION,
        },
        token: {} as CssTokensShape,
        variants: {},
      },
      [tokens],
    );
    return {tokens, styles};
  }, [inherited, overrides]);
  return [provider(TokenContext, context), context.styles];
});

function forEachToken(
  values: CssTokensShape,
  callback: (category: string, name: string) => void,
) {
  const categories = Object.keys(values);

  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];
    const tokens = Object.keys(values[category]);
    for (let j = 0; j < tokens.length; j++) {
      callback(category, tokens[j]);
    }
  }
}

function assignTokens(
  values: CssTokensShape,
  definitions: CssTokensShape<string>,
) {
  const result = {} as AssignedTokens;

  forEachToken(values, (category: string, name: string) => {
    const customProperty = definitions?.[category]?.[name];
    if (!customProperty) {
      return;
    }

    const value = values[category][name];
    result[customProperty] = replaceTokens(definitions, value).toString();
  });

  return toIntent([assignTokensIntent(result), processAssignedTokens]);
}

export function createTokens<T>(
  input: T,
  {prefix = DEFAULT_PREFIX}: TokenOptions = {},
): CssTokens<T> {
  const fallbacks = input as unknown as CssTokensShape;
  const definitions = {} as CssTokensShape<string>;
  forEachToken(fallbacks, (category, name) => {
    if (!definitions[category]) {
      definitions[category] = {};
    }
    definitions[category][name] = getCustomProperty(prefix, category, name);
  });

  const tokens = definitions as unknown as CssTokens<T>;
  let reset: Intent | void;
  tokens.assign = (input) => {
    if (!input) {
      if (!reset) {
        reset = assignTokens(fallbacks, definitions);
      }
      return reset;
    }
    return assignTokens(input as CssTokensShape, definitions);
  };

  return tokens;
}

export function resolveTokens(tokens: AssignedTokens, key: string): string {
  const value = tokens[key];
  if (typeof value !== 'string') {
    return value;
  }
  return value.replace(TOKEN_VAR_REGEXP, (_match, customProperty) =>
    resolveTokens(tokens, customProperty),
  );
}

export function useTokenContext(): TokenContextShape {
  return useContext(TokenContext);
}

export function useToken(token: string): string {
  const {tokens} = useTokenContext();
  return useMemo(() => resolveTokens(tokens, token), [tokens, token]);
}

export function useTokens(tokens: string[]): string[] {
  const {tokens: assigned} = useTokenContext();
  return useMemo(
    () => tokens.map((token) => resolveTokens(assigned, token)),
    [assigned, tokens],
  );
}
