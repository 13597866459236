import {view, css, tokens, useTokenValue} from '@sail/ui';
import * as React from 'react';

import {DEFAULT_BRANDING_PLATFORM_COLOR} from 'gelato/frontend/src/lib/constants';
import {useBranding, useExperiments} from 'gelato/frontend/src/lib/hooks';

const ProgressBar = ({value}: {value: number}) => {
  const branding = useBranding();
  const fillColor = branding
    ? branding.buttonColor
    : DEFAULT_BRANDING_PLATFORM_COLOR;

  const backgroundColor = useTokenValue(tokens.color.background.offset);

  // Regrettably, we cannot utilize isExperimentActive() or
  // experiments.isActive() in this context due to a longstanding circular
  // dependency. This component will be discarded once Butter is fully launched.
  const experiments = useExperiments();
  const exp = experiments?.find((e) => e.name === 'welcome_page_v2');

  const cssStyles = css({
    width: 'fill',
    height: 'xsmall',
    appearance: 'none',
    '::-webkit-progress-bar': {
      backgroundColor,
      overflow: 'hidden',
    },
    '::-webkit-progress-value': {
      backgroundColor: fillColor,
      borderTopRightRadius: 'medium',
      borderBottomRightRadius: 'medium',
      transition: 'width 0.5s ease',
    },
    '::-moz-progress-bar': {
      backgroundColor: fillColor,
      borderTopRightRadius: 'medium',
      borderBottomRightRadius: 'medium',
      transition: 'width 0.5s ease',
    },
  });

  if (exp?.variant === 'treatment') {
    Object.assign(cssStyles, {
      clipPath: 'inset(0% 20px 0% 20px round 10px)',
      paddingX: '20px',
    });
  }

  return <view.progress max="100" value={value} uses={[cssStyles]} />;
};

export default ProgressBar;
