import tag from 'src/internal/apollo/tag';

/**
 * Template literal function used for defining GraphQL operations. This tag can
 * be imported with any name in order to proxy different queries to different
 * schemas in `@sail/data`.
 *
 * @example Basic {{include './examples/gql.basic.tsx'}}
 */
export default tag;
