import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';
import {validationErrorFragment} from 'gelato/frontend/src/graphql/fragments/validationErrorFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {
  Exact,
  PhoneNumberDataInput,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';
import type {ValidationErrorFragmentData} from 'gelato/frontend/src/graphql/fragments/validationErrorFragment';

const doc = graphql`
  mutation UpdatePhoneMutation($phoneData: PhoneNumberDataInput!) {
    updatePhone(phoneData: $phoneData) {
      session {
        id
        ...SessionDynamicFragment
      }
      validationErrors {
        ...ValidationErrorFragment
      }
    }
  }

  ${sessionDynamicFragment}
  ${validationErrorFragment}
`;

export const updatePhoneMutation: UpdatePhoneMutationDocument = doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const updatePhoneMutationOptions: GelatoMutationOptionsWithoutVariables<UpdatePhoneMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useUpdatePhoneMutation(
  options: GelatoMutationOptions<UpdatePhoneMutationDocument> = {},
): GelatoMutationReturn<UpdatePhoneMutationDocument> {
  return useGelatoMutation(updatePhoneMutation, {
    ...updatePhoneMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type UpdatePhoneMutationVariables = Exact<{
  phoneData: PhoneNumberDataInput;
}>;

export type UpdatePhoneMutationData = {
  readonly __typename: 'Mutation';
  readonly updatePhone: {
    readonly __typename: 'MutationResponse';
    readonly session: GraphQlFragmentUnion<
      {readonly __typename: 'Session'; readonly id: string},
      [SessionDynamicFragmentData]
    >;
    readonly validationErrors: ReadonlyArray<
      GraphQlFragmentUnion<
        {readonly __typename: 'ValidationError'},
        [ValidationErrorFragmentData]
      >
    >;
  };
};

const updatePhoneMutationPermissions = {
  ...sessionDynamicFragment.permissions,
  ...validationErrorFragment.permissions,
} as const;

doc.permissions = updatePhoneMutationPermissions;

export type UpdatePhoneMutationPermissions = GelatoPermissionToken<
  typeof updatePhoneMutationPermissions
>;

export type UpdatePhoneMutationDocument = GraphQlDocument<
  UpdatePhoneMutationData,
  UpdatePhoneMutationVariables,
  UpdatePhoneMutationPermissions
>;
