import * as Sentry from '@sentry/browser';

import {
  IdentitySettings,
  StaticSettings,
  StaticBrandingSettings,
  StaticSupportSettings,
  StaticConsentConfig,
  StaticFlagsSettings,
} from 'gelato/frontend/src/services/apis/start_code';

import type {
  IndividualFields,
  ConsentUseCase,
  ExperimentName,
  ConsentImageMode,
} from '@stripe-internal/data-gelato/schema/types';
import type {ConsumerSession} from 'gelato/frontend/src/api/Consumer/types';

const BASE_KEY = 'gelato';

export const buildKey = (key: string) => `${BASE_KEY}@${key}`;

const CONSUMER_KEYS = {
  email_otp_consent: buildKey('email_otp_consent'),
};

const KEYS = {
  last_session_state: buildKey('last_session_state'),
  link_code: buildKey('link_code'),
  locale: buildKey('locale'),
  template_slug: buildKey('template_slug'),
  platform_name: buildKey('platform_name'),
  token_create_epoch_millis: buildKey('token_create_epoch_millis'),
  ui_version: buildKey('ui_version'),
  upload_option: buildKey('upload_option'),
  verification_token: buildKey('verification_token'),
  graphql_url: buildKey('graphql_url'),
  webcam_permission: buildKey('webcam_permission'),
  session_expires_at: buildKey('session_expires_at'),
  not_readable_webcam_error_count: buildKey('not_readable_webcam_error_count'),
  merchant_publishable_key: buildKey('merchant_publishable_key'),
  consumer_publishable_key: buildKey('consumer_publishable_key'),
  override_branding: buildKey('override_branding'),
  override_primary_color: buildKey('override_primary_color'),
  override_secondary_color: buildKey('override_secondary_color'),
  experiment_exposures: buildKey('experiment_exposures'),
  user_session_id: buildKey('user_session_id'),
  experiment_assignments: buildKey('experiment_assignments'),
  override_use_connect_iframe_design: buildKey(
    'override_use_connect_iframe_design',
  ),
  consumer_session: buildKey('consumer_session'),
  consumer_session_networked_document: buildKey(
    'consumer_session_networked_document',
  ),
  identity_settings: buildKey('identity_settings'),
  visited_path_prefix: buildKey('visited_path'),
  prefilled_email: buildKey('prefilledEmail'),
  client_reference_id: buildKey('client_reference_id'),
  shared_networked_document: buildKey('shared_networked_document'),
  consents_to_document_networking: buildKey('consents_to_document_networking'),
};

const STATIC_KEYS = {
  // this is static content populated by the initial response that sets up the authentication for this session
  refresh_url: buildKey('refresh_url'),
  return_url: buildKey('return_url'),
  livemode: buildKey('livemode'),
  welcome_handoff: buildKey('welcome_handoff'),
  skip_welcome_page: buildKey('skip_welcome_page'),
  template_id: buildKey('template_id'),
  merchant: buildKey('merchant'),
  required_fields: buildKey('required_fields'),
  options: buildKey('options'),

  // consent config keys
  consent__privacy_policy_url: buildKey('consent__privacy_policy_url'),
  consent__additional_privacy_policy_url: buildKey(
    'consent__additional_privacy_policy_url',
  ),
  consent__stripe_internal: buildKey('consent__stripe_internal'),
  consent__use_case: buildKey('consent__use_case'),
  consent__image_mode: buildKey('consent__image_mode'),

  // support keys
  support__email: buildKey('support__email'),
  support__url: buildKey('support__url'),

  // branding keys
  branding__button_color: buildKey('branding__button_color'),
  branding__platform_name: buildKey('branding__platform_name'),
  branding__platform_icon: buildKey('branding__platform_icon'),
  branding__platform_color: buildKey('branding__platform_color'),
  branding__additional_platform_name: buildKey(
    'branding__additional_platform_name',
  ),
  branding__is_stripe: buildKey('branding__is_stripe'),
  branding__button_color_override: buildKey('branding__button_color_override'),
  branding__platform_color_override: buildKey(
    'branding__platform_color_override',
  ),
  branding__platform_name_override: buildKey(
    'branding__platform_name_override',
  ),
  branding__platform_icon_override: buildKey(
    'branding__platform_icon_override',
  ),
};

type ValidKeys =
  | (typeof KEYS)[keyof typeof KEYS]
  | (typeof STATIC_KEYS)[keyof typeof STATIC_KEYS];
export type SessionStatus = 'started' | 'finished';
export type UIVersions = 'v1' | 'v2';
export type VerifyOptions =
  | 'webcam'
  | 'upload'
  | 'mobile'
  | 'email'
  | 'unsupported';

/**
 * Get the key for the specified visited path.
 * @param path The path to get the key for.
 * @returns The key for the specified path.
 */
function keyForVisitedPath(path: string): string {
  return `${KEYS.visited_path_prefix}${path}>`;
}
export class StorageBackup {
  // Storage/Local state can be erased by Safari Mobile if it cleans the cache.
  // Stores session/local state into a local variable.  If we find that session/local storage has been erased,
  // we restore state from this local variable.
  vals: {[key: ValidKeys]: any};

  storage: Storage | undefined;

  constructor(isSession?: boolean) {
    if (typeof window === 'undefined') {
      this.storage = undefined;
    } else {
      try {
        this.storage = isSession ? window.sessionStorage : window.localStorage;
      } catch (exception: any) {
        Sentry.addBreadcrumb({
          category: 'Storage',
          message: `Storage disabled: ${exception.message}`,
          level: Sentry.Severity.Info,
        });
        this.storage = undefined;
      }
    }
    this.vals = {};
  }

  keys(): string[] {
    if (this.storage) {
      // in-browser storage keys
      return Object.keys(this.storage);
    } else {
      // in-memory storage keys
      return Object.keys(this.vals);
    }
  }

  setItem(key: ValidKeys, value: string) {
    this.vals[key] = value;
    this.storage?.setItem(key, value);
  }

  removeItem(key: ValidKeys) {
    delete this.vals[key];
    this.storage?.removeItem(key);
  }

  setBooleanItem(key: ValidKeys, value: boolean) {
    const bool = value ? 'true' : 'false';
    this.vals[key] = bool;
    this.storage?.setItem(key, bool);
  }

  getBooleanItem(key: ValidKeys) {
    return this.getItem(key) === 'true';
  }

  getStringItem(key: ValidKeys): string {
    return this.getItem(key) || '';
  }

  getItem(key: ValidKeys): string | null | undefined {
    if (!this.storage) {
      return this.vals[key];
    }

    let storageValue: string | null | undefined;
    try {
      storageValue = this.storage.getItem(key);
    } catch {}
    if (!storageValue) {
      const localValue = this.vals[key];
      if (localValue) {
        this.setItem(key, localValue);
        Sentry.addBreadcrumb({
          category: 'Storage',
          message: `missing key from storage: ${key}`,
          level: Sentry.Severity.Info,
        });
        return localValue;
      }
    }
    return storageValue;
  }
}

export default class GelatoStorage {
  static session = new StorageBackup(true);

  static local = new StorageBackup(false);

  /* Getters */

  static getTemplateSlug() {
    return GelatoStorage.session.getStringItem(KEYS.template_slug);
  }

  static getEmailOTPConsent() {
    return GelatoStorage.session.getBooleanItem(
      CONSUMER_KEYS.email_otp_consent,
    );
  }

  static getClientReferenceId() {
    return GelatoStorage.session.getStringItem(KEYS.client_reference_id);
  }

  static getLinkCode() {
    return GelatoStorage.session.getItem(KEYS.link_code);
  }

  static getPrefilledEmail() {
    return GelatoStorage.session.getItem(KEYS.prefilled_email);
  }

  static getPlatformName() {
    return GelatoStorage.local.getItem(KEYS.platform_name);
  }

  static getSessionTracking() {
    return GelatoStorage.local.getItem(KEYS.last_session_state);
  }

  static getUIVersion() {
    return GelatoStorage.session.getItem(KEYS.ui_version);
  }

  static getConsentConfig(): {
    additionalPrivacyPolicyUrl: string;
    imageMode: ConsentImageMode;
    privacyPolicyUrl: string;
    stripeInternal: boolean;
    useCase: ConsentUseCase;
  } {
    return {
      privacyPolicyUrl: GelatoStorage.session.getStringItem(
        STATIC_KEYS.consent__privacy_policy_url,
      ),
      additionalPrivacyPolicyUrl: GelatoStorage.session.getStringItem(
        STATIC_KEYS.consent__additional_privacy_policy_url,
      ),
      useCase: GelatoStorage.session.getStringItem(
        STATIC_KEYS.consent__use_case,
      ) as ConsentUseCase,
      stripeInternal: GelatoStorage.session.getBooleanItem(
        STATIC_KEYS.consent__stripe_internal,
      ),
      imageMode: GelatoStorage.session.getStringItem(
        STATIC_KEYS.consent__image_mode,
      ) as ConsentImageMode,
    };
  }

  static getBranding() {
    return {
      buttonColor: GelatoStorage.session.getStringItem(
        STATIC_KEYS.branding__button_color,
      ),
      isStripe: GelatoStorage.session.getBooleanItem(
        STATIC_KEYS.branding__is_stripe,
      ),
      platformColor: GelatoStorage.session.getStringItem(
        STATIC_KEYS.branding__platform_color,
      ),
      platformIcon: GelatoStorage.session.getStringItem(
        STATIC_KEYS.branding__platform_icon,
      ),
      platformName: GelatoStorage.session.getStringItem(
        STATIC_KEYS.branding__platform_name,
      ),
      additionalPlatformName: GelatoStorage.session.getStringItem(
        STATIC_KEYS.branding__additional_platform_name,
      ),
      buttonColorOverride: GelatoStorage.session.getStringItem(
        STATIC_KEYS.branding__button_color_override,
      ),
      platformColorOverride: GelatoStorage.session.getStringItem(
        STATIC_KEYS.branding__platform_color_override,
      ),
      platformNameOverride: GelatoStorage.session.getStringItem(
        STATIC_KEYS.branding__platform_name_override,
      ),
      platformIconOverride: GelatoStorage.session.getStringItem(
        STATIC_KEYS.branding__platform_icon_override,
      ),
    };
  }

  static getSupport() {
    return {
      email: GelatoStorage.session.getStringItem(STATIC_KEYS.support__email),
      url: GelatoStorage.session.getStringItem(STATIC_KEYS.support__url),
    };
  }

  static getFlags() {
    return {};
  }

  static getVerifyOption(): VerifyOptions | null | undefined {
    return GelatoStorage.session.getItem(KEYS.upload_option) as
      | VerifyOptions
      | null
      | undefined;
  }

  static getSessionAPIKey() {
    return GelatoStorage.session.getStringItem(KEYS.verification_token);
  }

  static getGraphqlUrl() {
    return GelatoStorage.session.getStringItem(KEYS.graphql_url);
  }

  static getRefreshUrl() {
    return GelatoStorage.session.getStringItem(STATIC_KEYS.refresh_url);
  }

  static getReturnUrl() {
    return GelatoStorage.session.getStringItem(STATIC_KEYS.return_url);
  }

  static getSessionAPIKeyCreateTime(): number | null | undefined {
    try {
      const val = GelatoStorage.local.getItem(KEYS.token_create_epoch_millis);
      return val ? parseInt(val, 10) : undefined;
    } catch (err: any) {}
  }

  static getWebcamPermission() {
    return GelatoStorage.session.getItem(KEYS.webcam_permission);
  }

  static getSessionExpiresAt() {
    return GelatoStorage.session.getItem(KEYS.session_expires_at);
  }

  static getMerchantPublishableKey() {
    return GelatoStorage.session.getItem(KEYS.merchant_publishable_key);
  }

  static getConsumerPublishableKey() {
    return GelatoStorage.session.getItem(KEYS.consumer_publishable_key);
  }

  static getNotReadableWebcamErrorCount() {
    const val = GelatoStorage.local.getItem(
      KEYS.not_readable_webcam_error_count,
    );
    return val ? parseInt(val, 10) : 0;
  }

  static getLivemode() {
    return GelatoStorage.session.getBooleanItem(STATIC_KEYS.livemode);
  }

  static getRequiredFields(): IndividualFields[] {
    const fields = GelatoStorage.session.getItem(STATIC_KEYS.required_fields);
    if (fields) {
      return JSON.parse(fields);
    }
    return [];
  }

  static getOptions(): StaticSettings['options'] {
    const types = GelatoStorage.session.getItem(STATIC_KEYS.options);
    if (types) {
      return JSON.parse(types);
    }
    return undefined;
  }

  static getWelcomeHandoff() {
    return GelatoStorage.session.getBooleanItem(STATIC_KEYS.welcome_handoff);
  }

  static getSkipWelcomePage() {
    return GelatoStorage.session.getBooleanItem(STATIC_KEYS.skip_welcome_page);
  }

  static getTemplateId(): string {
    return GelatoStorage.session.getStringItem(STATIC_KEYS.template_id);
  }

  static getMerchant(): string {
    return GelatoStorage.session.getStringItem(STATIC_KEYS.merchant);
  }

  static getOverrideBranding() {
    return GelatoStorage.session.getItem(KEYS.override_branding) || undefined;
  }

  static getOverridePrimaryColor(): string | undefined {
    return (
      GelatoStorage.session.getItem(KEYS.override_primary_color) || undefined
    );
  }

  static getOverrideSecondaryColor(): string | undefined {
    return (
      GelatoStorage.session.getItem(KEYS.override_secondary_color) || undefined
    );
  }

  static getUserSessionId() {
    return GelatoStorage.session.getItem(KEYS.user_session_id);
  }

  static getExperimentAssignments(): {name: ExperimentName; variant: string}[] {
    const assignments = GelatoStorage.session.getItem(
      KEYS.experiment_assignments,
    );
    if (assignments) {
      return JSON.parse(assignments);
    }
    return [];
  }

  static getConsumerSession(): ConsumerSession | undefined {
    const session = GelatoStorage.session.getItem(KEYS.consumer_session);
    if (session) {
      return JSON.parse(session);
    }
    return undefined;
  }

  static getNetworkedDocumentId(): string | undefined {
    const documentId = GelatoStorage.session.getItem(
      KEYS.consumer_session_networked_document,
    );
    if (documentId) {
      return documentId;
    }
    return undefined;
  }

  static getSharedNetworkedDocument(): boolean {
    return GelatoStorage.session.getBooleanItem(KEYS.shared_networked_document);
  }

  static getIdentitySettings(): IdentitySettings | undefined {
    const settings = GelatoStorage.session.getItem(KEYS.identity_settings);
    if (settings) {
      return JSON.parse(settings);
    }
    return undefined;
  }

  static getOverrideUseConnectIframeDesign() {
    return GelatoStorage.session.getBooleanItem(
      KEYS.override_use_connect_iframe_design,
    );
  }

  static getLocale(): string | null {
    return GelatoStorage.session.getItem(KEYS.locale) || null;
  }

  static setLocale(locale: string) {
    GelatoStorage.session.setItem(KEYS.locale, locale);
  }

  /**
   * Indicates whether the current session was exposed to the specified
   * experiment.
   */
  static hasExperimentExposure(experiment: ExperimentName) {
    const value = GelatoStorage.session.getItem(KEYS.experiment_exposures);
    const regex = new RegExp(`(^|,)${experiment}(,|$)`);
    return regex.test(String(value || ''));
  }

  /**
   * Whether the current session has visited the specified path.
   */
  static hasVisitedPath(path: string): boolean {
    return GelatoStorage.session.getBooleanItem(keyForVisitedPath(path));
  }

  /* Setters */

  /**
   * Mark the current session as having visited the specified path.
   * @param path The path to mark as visited.
   */
  static setVisitedPath(path: string) {
    GelatoStorage.session.setBooleanItem(keyForVisitedPath(path), true);
  }

  /**
   * Set the current session as exposed to the specified experiment.
   */
  static setExperimentExposure(experiment: ExperimentName) {
    if (!GelatoStorage.hasExperimentExposure(experiment)) {
      const value = GelatoStorage.session.getItem(KEYS.experiment_exposures);
      GelatoStorage.session.setItem(
        KEYS.experiment_exposures,
        value ? `${value},${experiment}` : experiment,
      );
    }
  }

  static setTemplateSlug(slug: string) {
    return GelatoStorage.session.setItem(KEYS.template_slug, slug);
  }

  static setEmailOTPConsent(consent: boolean) {
    return GelatoStorage.session.setBooleanItem(
      CONSUMER_KEYS.email_otp_consent,
      consent,
    );
  }

  static setClientReferenceId(clientReferenceId: string) {
    return GelatoStorage.session.setItem(
      KEYS.client_reference_id,
      clientReferenceId,
    );
  }

  static setLinkCode(code: string) {
    return GelatoStorage.session.setItem(KEYS.link_code, code);
  }

  static setPrefilledEmail(email: string) {
    return GelatoStorage.session.setItem(KEYS.prefilled_email, email);
  }

  static setPlatformName(name?: string | null) {
    if (name === null || name === undefined) {
      return GelatoStorage.local.removeItem(KEYS.platform_name);
    }

    return GelatoStorage.local.setItem(KEYS.platform_name, name);
  }

  static setSessionStarted() {
    return GelatoStorage.local.setItem(KEYS.last_session_state, 'started');
  }

  static setSessionFinished() {
    return GelatoStorage.local.setItem(KEYS.last_session_state, 'finished');
  }

  static setUIVersion(version: UIVersions) {
    return GelatoStorage.session.setItem(KEYS.ui_version, version);
  }

  static setVerifyOption(value: VerifyOptions) {
    return GelatoStorage.session.setItem(KEYS.upload_option, value);
  }

  static clearVerifyOption() {
    return GelatoStorage.session.removeItem(KEYS.upload_option);
  }

  static setSessionAPIKey(token: string) {
    try {
      GelatoStorage.local.setItem(
        KEYS.token_create_epoch_millis,
        `${Date.now()}`,
      );
    } catch (err: any) {}
    return GelatoStorage.session.setItem(KEYS.verification_token, token);
  }

  static setGraphqlUrl(url: string) {
    return GelatoStorage.session.setItem(KEYS.graphql_url, url);
  }

  static setRefreshUrl(url: string) {
    return GelatoStorage.session.setItem(STATIC_KEYS.refresh_url, url);
  }

  static setReturnUrl(url: string) {
    return GelatoStorage.session.setItem(STATIC_KEYS.return_url, url);
  }

  static setTemplateId(templateId: string) {
    return GelatoStorage.session.setItem(STATIC_KEYS.template_id, templateId);
  }

  static setMerchant(merchant: string) {
    return GelatoStorage.session.setItem(STATIC_KEYS.merchant, merchant);
  }

  static setSkipWelcomePage(skipWelcomePage: boolean) {
    return GelatoStorage.session.setBooleanItem(
      STATIC_KEYS.skip_welcome_page,
      skipWelcomePage,
    );
  }

  static setLivemode(livemode: boolean) {
    return GelatoStorage.session.setBooleanItem(STATIC_KEYS.livemode, livemode);
  }

  static setRequiredFields(fields: IndividualFields[]) {
    return GelatoStorage.session.setItem(
      STATIC_KEYS.required_fields,
      JSON.stringify(fields),
    );
  }

  static setOptions(options: StaticSettings['options']) {
    return GelatoStorage.session.setItem(
      STATIC_KEYS.options,
      JSON.stringify(options),
    );
  }

  static setWelcomeHandoff(welcomeHandoff: boolean) {
    return GelatoStorage.session.setBooleanItem(
      STATIC_KEYS.welcome_handoff,
      welcomeHandoff,
    );
  }

  static setWebcamPermission(found: string) {
    return GelatoStorage.session.setItem(KEYS.webcam_permission, found);
  }

  static setSessionExpiresAt(sessionExpiresAt: number) {
    return GelatoStorage.session.setItem(
      KEYS.session_expires_at,
      `${sessionExpiresAt}`,
    );
  }

  static setMerchantPublishableKey(merchantPublishableKey: string) {
    return GelatoStorage.session.setItem(
      KEYS.merchant_publishable_key,
      merchantPublishableKey,
    );
  }

  static setConsumerPublishableKey(consumerPublishableKey: string) {
    return GelatoStorage.session.setItem(
      KEYS.consumer_publishable_key,
      consumerPublishableKey,
    );
  }

  static setOverrideBranding(overrideBranding: string) {
    return GelatoStorage.session.setItem(
      KEYS.override_branding,
      overrideBranding,
    );
  }

  static setOverridePrimaryColor(overridePrimaryColor: string | undefined) {
    if (overridePrimaryColor) {
      return GelatoStorage.session.setItem(
        KEYS.override_primary_color,
        overridePrimaryColor,
      );
    }
  }

  static setOverrideSecondaryColor(overrideSecondaryColor: string | undefined) {
    if (overrideSecondaryColor) {
      return GelatoStorage.session.setItem(
        KEYS.override_secondary_color,
        overrideSecondaryColor,
      );
    }
  }

  static setUserSessionId(userSessionId: string) {
    return GelatoStorage.session.setItem(KEYS.user_session_id, userSessionId);
  }

  static setExperimentAssignments(
    experimentAssignments: {name: ExperimentName; variant: string}[],
  ) {
    return GelatoStorage.session.setItem(
      KEYS.experiment_assignments,
      JSON.stringify(experimentAssignments),
    );
  }

  static setConsumerSession(consumerSession: ConsumerSession | undefined) {
    if (consumerSession) {
      GelatoStorage.session.setItem(
        KEYS.consumer_session,
        JSON.stringify(consumerSession),
      );
    }
  }

  static setNetworkedDocumentId(networkedDocument: string | undefined) {
    if (networkedDocument) {
      GelatoStorage.session.setItem(
        KEYS.consumer_session_networked_document,
        networkedDocument,
      );
    }
  }

  static setSharedNetworkedDocument(shared: boolean) {
    GelatoStorage.session.setBooleanItem(
      KEYS.shared_networked_document,
      shared,
    );
  }

  static setIdentitySettings(settings: IdentitySettings | undefined) {
    if (settings) {
      GelatoStorage.session.setItem(
        KEYS.identity_settings,
        JSON.stringify(settings),
      );
    }
  }

  static clearConsumerSession() {
    GelatoStorage.session.removeItem(KEYS.consumer_session);
  }

  static clearConsumerPublishableKey() {
    GelatoStorage.session.removeItem(KEYS.consumer_publishable_key);
  }

  static clearNetworkedDocumentId() {
    GelatoStorage.session.removeItem(KEYS.consumer_session_networked_document);
  }

  static clearSharedNetworkedDocument() {
    GelatoStorage.session.removeItem(KEYS.shared_networked_document);
  }

  static clearLinkCode() {
    GelatoStorage.session.removeItem(KEYS.link_code);
  }

  static setOverrideUseConnectIframeDesign(
    overrideUseConnectIframeDesign: boolean,
  ) {
    return GelatoStorage.session.setBooleanItem(
      KEYS.override_use_connect_iframe_design,
      overrideUseConnectIframeDesign,
    );
  }

  /**
   * Increments the WebcamError count, returning the current (pre-increment) value
   */
  static getAndIncrementNotReadableWebcamErrorCount() {
    const count = GelatoStorage.getNotReadableWebcamErrorCount();
    GelatoStorage.local.setItem(
      KEYS.not_readable_webcam_error_count,
      `${count + 1}`,
    );
    return count;
  }

  static setConsentConfig(params: StaticConsentConfig) {
    params.privacy_policy_url &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.consent__privacy_policy_url,
        params.privacy_policy_url,
      );
    params.additional_privacy_policy_url &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.consent__additional_privacy_policy_url,
        params.additional_privacy_policy_url,
      );
    typeof params.stripe_internal === 'boolean' &&
      GelatoStorage.session.setBooleanItem(
        STATIC_KEYS.consent__stripe_internal,
        params.stripe_internal,
      );
    params.use_case &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.consent__use_case,
        params.use_case,
      );
    params.image_mode &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.consent__image_mode,
        params.image_mode,
      );
  }

  static setBranding(params: StaticBrandingSettings) {
    params.button_color &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.branding__button_color,
        params.button_color,
      );
    params.button_color &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.branding__button_color,
        params.button_color,
      );
    typeof params.is_stripe === 'boolean' &&
      GelatoStorage.session.setBooleanItem(
        STATIC_KEYS.branding__is_stripe,
        params.is_stripe,
      );
    params.platform_color &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.branding__platform_color,
        params.platform_color,
      );
    params.platform_icon &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.branding__platform_icon,
        params.platform_icon,
      );
    params.platform_name &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.branding__platform_name,
        params.platform_name,
      );
    params.additional_platform_name &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.branding__additional_platform_name,
        params.additional_platform_name,
      );
    params.button_color_override &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.branding__button_color_override,
        params.button_color_override,
      );
    params.platform_color_override &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.branding__platform_color_override,
        params.platform_color_override,
      );
    params.platform_name_override &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.branding__platform_name_override,
        params.platform_name_override,
      );
    params.platform_icon_override &&
      GelatoStorage.session.setItem(
        STATIC_KEYS.branding__platform_icon_override,
        params.platform_icon_override,
      );
  }

  static setFlags(params: StaticFlagsSettings) {
    // This is currently a noop as there are no flags set
  }

  static setSupport(params: StaticSupportSettings) {
    params.email &&
      GelatoStorage.session.setItem(STATIC_KEYS.support__email, params.email);
    params.url &&
      GelatoStorage.session.setItem(STATIC_KEYS.support__url, params.url);
  }

  static setStaticContent(params: StaticSettings) {
    params.branding && this.setBranding(params.branding);
    params.support && this.setSupport(params.support);
    params.consent && this.setConsentConfig(params.consent);
    params.livemode && this.setLivemode(params.livemode);
    params.flags && this.setFlags(params.flags);
    params.welcome_handoff && this.setWelcomeHandoff(params.welcome_handoff);
    params.template_id && this.setTemplateId(params.template_id);
    params.merchant && this.setMerchant(params.merchant);
    params.skip_welcome_page &&
      this.setSkipWelcomePage(params.skip_welcome_page);
    params.return_url && this.setReturnUrl(params.return_url);
    params.refresh_url && this.setRefreshUrl(params.refresh_url);
    params.welcome_handoff && this.setWelcomeHandoff(params.welcome_handoff);
    params.consent && this.setConsentConfig(params.consent);

    typeof params.livemode === 'boolean' && this.setLivemode(params.livemode);

    params.required_fields && this.setRequiredFields(params.required_fields);
    params.options && this.setOptions(params.options);
  }

  static clearStaticContent() {
    GelatoStorage.session.removeItem(STATIC_KEYS.refresh_url);
    GelatoStorage.session.removeItem(STATIC_KEYS.return_url);
    GelatoStorage.session.removeItem(STATIC_KEYS.livemode);
    GelatoStorage.session.removeItem(STATIC_KEYS.welcome_handoff);
    GelatoStorage.session.removeItem(STATIC_KEYS.skip_welcome_page);
    GelatoStorage.session.removeItem(STATIC_KEYS.template_id);
    GelatoStorage.session.removeItem(STATIC_KEYS.merchant);
    GelatoStorage.session.removeItem(STATIC_KEYS.required_fields);

    // clear support contact keys
    GelatoStorage.session.removeItem(STATIC_KEYS.support__email);
    GelatoStorage.session.removeItem(STATIC_KEYS.support__url);

    // clear branding keys
    GelatoStorage.session.removeItem(STATIC_KEYS.branding__button_color);
    GelatoStorage.session.removeItem(STATIC_KEYS.branding__platform_name);
    GelatoStorage.session.removeItem(STATIC_KEYS.branding__platform_icon);
    GelatoStorage.session.removeItem(STATIC_KEYS.branding__platform_color);
    GelatoStorage.session.removeItem(
      STATIC_KEYS.branding__additional_platform_name,
    );
    GelatoStorage.session.removeItem(STATIC_KEYS.branding__is_stripe);
    GelatoStorage.session.removeItem(
      STATIC_KEYS.branding__button_color_override,
    );
    GelatoStorage.session.removeItem(
      STATIC_KEYS.branding__platform_color_override,
    );
    GelatoStorage.session.removeItem(
      STATIC_KEYS.branding__platform_name_override,
    );
    GelatoStorage.session.removeItem(
      STATIC_KEYS.branding__platform_icon_override,
    );

    // clear consent keys
    GelatoStorage.session.removeItem(STATIC_KEYS.consent__privacy_policy_url);
    GelatoStorage.session.removeItem(
      STATIC_KEYS.consent__additional_privacy_policy_url,
    );
    GelatoStorage.session.removeItem(STATIC_KEYS.consent__stripe_internal);
    GelatoStorage.session.removeItem(STATIC_KEYS.consent__use_case);
    GelatoStorage.session.removeItem(STATIC_KEYS.consent__image_mode);
  }

  static clearFlowsContent() {
    GelatoStorage.session.removeItem(KEYS.prefilled_email);
    GelatoStorage.session.removeItem(KEYS.client_reference_id);
    GelatoStorage.session.removeItem(KEYS.template_slug);
  }

  /* Session lifecycle */

  static startSession(params: {
    sessionApiKey: string;
    sessionExpiresAt: number;

    graphqlUrl?: string | null;
    refreshUrl?: string | null;
    returnUrl?: string | null;
    platformName?: string | null;
    overrideBranding?: string;
    overridePrimaryColor?: string;
    overrideSecondaryColor?: string;
    merchantPublishableKey?: string | null;
    userSessionId?: string;
    experimentAssignments?: {name: ExperimentName; variant: string}[];
    settings?: IdentitySettings;
    _static?: StaticSettings;
    overrideUseConnectIframeDesign?: boolean | null;
    locale?: string | null;
  }) {
    const {locale} = params;

    this.setSessionAPIKey(params.sessionApiKey);
    this.setGraphqlUrl(params.graphqlUrl || '');
    this.setRefreshUrl(params.refreshUrl || '');
    this.setReturnUrl(params.returnUrl || '');
    this.setSessionStarted();
    this.setPlatformName(params.platformName);
    this.setSessionExpiresAt(params.sessionExpiresAt);
    this.setMerchantPublishableKey(params.merchantPublishableKey || '');
    this.setOverrideBranding(params.overrideBranding || '');
    this.setOverridePrimaryColor(params.overridePrimaryColor);
    this.setOverrideSecondaryColor(params.overrideSecondaryColor);
    this.setUserSessionId(params.userSessionId || '');
    this.setExperimentAssignments(params.experimentAssignments || []);
    this.setOverrideUseConnectIframeDesign(
      !!params.overrideUseConnectIframeDesign,
    );
    this.setIdentitySettings(params.settings);
    params._static && this.setStaticContent(params._static);

    locale && this.setLocale(locale);
  }

  static clearVisitedPaths() {
    GelatoStorage.session.keys().forEach((key) => {
      if (key.indexOf(KEYS.visited_path_prefix) === 0) {
        GelatoStorage.session.removeItem(key);
      }
    });
  }

  static clearSession() {
    this.clearVerifyOption();
    GelatoStorage.local.removeItem(KEYS.last_session_state);
    GelatoStorage.local.removeItem(KEYS.token_create_epoch_millis);
    GelatoStorage.session.removeItem(KEYS.verification_token);
    GelatoStorage.session.removeItem(KEYS.override_branding);
    GelatoStorage.session.removeItem(KEYS.override_primary_color);
    GelatoStorage.session.removeItem(KEYS.override_secondary_color);
    this.clearStaticContent();
    this.clearVisitedPaths();
    this.clearConsumerSession();
    this.clearNetworkedDocumentId();
    this.clearSharedNetworkedDocument();
    this.clearConsumerPublishableKey();
    this.clearFlowsContent();
    // Don't clear expires_at. expires_at tells us if the problem is token expiration.
    // If we clear this, we cannot tell in /invalid what the problem is.
  }
}
