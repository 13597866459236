import {apiRequest} from 'gelato/frontend/src/api/apiRequest';
import {secretToString} from 'gelato/frontend/src/api/Consumer/private/secrets';
import {getConfigValue} from 'gelato/frontend/src/lib/config';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {DeepSecretsToString} from 'gelato/frontend/src/api/Consumer/private/secrets';
import type {
  ConsumerAccountSignUpRequest,
  ConsumerAccountSignUpResponse,
  ConsumerSessionLogOutRequest,
  ConsumerSessionLogOutResponse,
  ConsumerSessionConfirmVerificationRequest,
  ConsumerSessionConfirmVerificationResponse,
  ConsumerSessionExtendRequest,
  ConsumerSessionExtendResponse,
  ConsumerSessionLookupRequest,
  ConsumerSessionLookupResponse,
  ConsumerSessionStartVerificationRequest,
  ConsumerSessionStartVerificationResponse,
  ConsumerIdentityDocumentsListRequest,
  ConsumerIdentityDocumentsListResponse,
  ConsumerIdentityDocumentCreateAssociationTokenRequest,
  ConsumerIdentityDocumentCreateAssociationTokenResponse,
  CookiesSnapshot,
  Credentials,
} from 'gelato/frontend/src/api/Consumer/types';
import type {
  RequestOptions,
  GenericStripeError,
  FetchResult,
} from 'gelato/frontend/src/api/request';

const requestOptions: (
  merchantKey: string | null | undefined,
) => RequestOptions = (merchantKey) => {
  const commitHash = getConfigValue('COMMIT_HASH');
  return {
    includeErrorStatus: true,
    includeRequestId: true,
    headers: {
      'X-Stripe-Identity-Client-Version': commitHash,
      'X-Requested-With': 'fetch',
      Authorization: `Bearer ${merchantKey}`,
    },
  };
};

const convertRequestCookies = (
  cookies?: CookiesSnapshot,
): DeepSecretsToString<{cookies?: CookiesSnapshot}> => {
  return {
    cookies: cookies && {
      ...cookies,
      verification_session_client_secrets:
        cookies.verification_session_client_secrets.map(secretToString),
    },
  };
};

const convertRequestCredentials = (
  credentials: Credentials,
): DeepSecretsToString<{credentials: Credentials}> => {
  return {
    credentials: {
      ...credentials,
      consumer_session_client_secret: secretToString(
        credentials.consumer_session_client_secret,
      ),
    },
  };
};

export const ConsumerAccount = {
  signUp: async ({
    cookies,
    ...data
  }: ConsumerAccountSignUpRequest): Promise<
    FetchResult<GenericStripeError, ConsumerAccountSignUpResponse>
  > => {
    const merchantKey = Storage.getMerchantPublishableKey();
    const result = await apiRequest(
      '/v1/consumers/accounts/sign_up',
      'POST',
      {...data, ...convertRequestCookies(cookies)},
      requestOptions(merchantKey),
    );
    if (result.type === 'object') {
      Storage.setConsumerPublishableKey(result.object.publishable_key);
    }
    return result;
  },
};

export const ConsumerSession = {
  lookup: async ({
    cookies,
    ...data
  }: ConsumerSessionLookupRequest): Promise<
    FetchResult<GenericStripeError, ConsumerSessionLookupResponse>
  > => {
    const merchantKey = Storage.getMerchantPublishableKey();
    const result = await apiRequest(
      '/v1/consumers/sessions/lookup',
      'POST',
      {...data, ...convertRequestCookies(cookies)},
      requestOptions(merchantKey),
    );
    if (result.type === 'object') {
      if (result.object.exists) {
        Storage.setConsumerPublishableKey(result.object.publishable_key);
      }
    }
    return result;
  },

  startVerification: async ({
    cookies,
    credentials,
    ...data
  }: ConsumerSessionStartVerificationRequest): Promise<
    FetchResult<GenericStripeError, ConsumerSessionStartVerificationResponse>
  > => {
    const consumerKey = Storage.getConsumerPublishableKey();
    return apiRequest(
      '/v1/consumers/sessions/start_verification',
      'POST',
      {
        ...data,
        ...convertRequestCookies(cookies),
        ...convertRequestCredentials(credentials),
      },
      requestOptions(consumerKey),
    );
  },

  confirmVerification: async ({
    cookies,
    credentials,
    ...data
  }: ConsumerSessionConfirmVerificationRequest): Promise<
    FetchResult<GenericStripeError, ConsumerSessionConfirmVerificationResponse>
  > => {
    const consumerKey = Storage.getConsumerPublishableKey();
    return apiRequest(
      '/v1/consumers/sessions/confirm_verification',
      'POST',
      {
        ...data,
        ...convertRequestCookies(cookies),
        ...convertRequestCredentials(credentials),
      },
      requestOptions(consumerKey),
    );
  },

  logOut: async ({
    cookies,
    credentials,
    ...data
  }: ConsumerSessionLogOutRequest): Promise<
    FetchResult<GenericStripeError, ConsumerSessionLogOutResponse>
  > => {
    const consumerKey = Storage.getConsumerPublishableKey();
    return apiRequest(
      '/v1/consumers/sessions/log_out',
      'POST',
      {
        ...data,
        ...convertRequestCookies(cookies),
        ...convertRequestCredentials(credentials),
      },
      requestOptions(consumerKey),
    );
  },

  extend: async ({
    credentials,
    ...data
  }: ConsumerSessionExtendRequest): Promise<
    FetchResult<GenericStripeError, ConsumerSessionExtendResponse>
  > => {
    const consumerKey = Storage.getConsumerPublishableKey();
    return apiRequest(
      '/v1/consumers/sessions/extend',
      'POST',
      {...data, ...convertRequestCredentials(credentials)},
      requestOptions(consumerKey),
    );
  },
};

export const ConsumerIdentityDocuments = {
  list: async ({
    credentials,
    ...data
  }: ConsumerIdentityDocumentsListRequest): Promise<
    FetchResult<GenericStripeError, ConsumerIdentityDocumentsListResponse>
  > => {
    const consumerKey = Storage.getConsumerPublishableKey();
    return apiRequest(
      '/v1/consumers/identity_documents/list',
      'POST',
      {...data, ...convertRequestCredentials(credentials)},
      requestOptions(consumerKey),
      {
        retries: {
          shouldRetry: (error) => {
            // Retry on 5xx errors
            return !!error.status && error.status >= 500;
          },
          maxAttempts: 2,
          delay: 250,
        },
      },
    );
  },

  createAssociationToken: async (
    identity_document: string,
    {
      credentials,
      ...data
    }: ConsumerIdentityDocumentCreateAssociationTokenRequest,
  ): Promise<
    FetchResult<
      GenericStripeError,
      ConsumerIdentityDocumentCreateAssociationTokenResponse
    >
  > => {
    const consumerKey = Storage.getConsumerPublishableKey();

    return apiRequest(
      `/v1/consumers/identity_documents/${identity_document}/association_token`,
      'POST',
      {...data, ...convertRequestCredentials(credentials)},
      requestOptions(consumerKey),
    );
  },
};
