const isConsoleAllowed = () => {
  const isProdEnv = process.env.NODE_ENV === 'production';
  const isQaUrl =
    window?.location?.hostname && window.location.hostname.indexOf('qa') > -1;
  // Allow console in QA and non-prod environments for testing purposes
  return (!isProdEnv || isQaUrl) && console;
};

export const logInDev = (...args: Array<unknown>): void => {
  // eslint-disable-next-line no-console
  if (isConsoleAllowed() && console.log) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

export const warnInDev = (...args: Array<unknown>): void => {
  // eslint-disable-next-line no-console
  if (isConsoleAllowed() && console.warn) {
    // eslint-disable-next-line no-console
    console.warn(...args);
  }
};

export const errorInDev = (...args: Array<unknown>): void => {
  // eslint-disable-next-line no-console
  if (isConsoleAllowed() && console.error) {
    // eslint-disable-next-line no-console
    console.error(...args);
  }
};

export const timeInDev = (text: string): void => {
  // eslint-disable-next-line no-console
  if (isConsoleAllowed() && console.time) {
    // eslint-disable-next-line no-console
    console.time(text);
  }
};

export const timeEndInDev = (text: string): void => {
  // eslint-disable-next-line no-console
  if (isConsoleAllowed() && console.timeEnd) {
    // eslint-disable-next-line no-console
    console.timeEnd(text);
  }
};
