import {ConsumerSession} from 'gelato/frontend/src/api/Consumer/api';
import {ConsumerSessionStartVerificationResponse} from 'gelato/frontend/src/api/Consumer/types';

import type {
  GenericStripeError,
  FetchResult,
} from 'gelato/frontend/src/api/request';
import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

export default async function startConsumerSessionVerification(
  state: Readonly<ApplicationState>,
  locale: string,
): Promise<
  FetchResult<GenericStripeError, ConsumerSessionStartVerificationResponse>
> {
  return ConsumerSession.startVerification({
    type: 'SMS',
    locale,
    credentials: {
      consumer_session_client_secret:
        state.networkedIdentity.consumerSession!.client_secret,
    },
    request_surface: 'web_identity_product',
  });
}
