import {ApolloLink} from '@apollo/client';

export default function createExtensionsLink() {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map((result) => {
      if (result.data) {
        result.data._extensions = result.extensions;
      }

      return result;
    });
  });
}
