import {Deferred, nextTick, noop} from '@sail/utils';

import type {FetchResult} from '@apollo/client';

type CacheItem = {
  promise: Promise<FetchResult>;
  result: FetchResult | undefined;
  // TODO(@guille): If the new approach gets promoted, remove `count` entirely
  count: number;
  queue: Array<() => void>;
};

type CacheDeferred = {
  promise: Promise<FetchResult>;
  resolve: (result: FetchResult) => void;
  reject: (error: any) => void;
};

export const defaultExpireAfterMs = 60 * 1000;

export default class PrefetchCache {
  private _items: Map<string, CacheItem> = new Map();
  private _deferreds: Map<string, CacheDeferred> = new Map();
  private _expireAfterMs: number;

  constructor(options?: {expireAfterMs?: number}) {
    this._expireAfterMs = options?.expireAfterMs ?? defaultExpireAfterMs;
  }

  addItem(hash: string, pruningSignal?: AbortSignal) {
    const enablePruningSignal =
      typeof window !== 'undefined' &&
      !!(window as any).PRELOADED?.flags?.enable_prefetch_cache_manual_pruning;

    if (enablePruningSignal) {
      // Branch off to a duplicated method with the new approach
      // with manual pruning support.
      return this.addItemWithQueue(hash, pruningSignal);
    }

    const existingItem = this._items.get(hash);
    const existingDeferred = this._deferreds.get(hash);

    if (existingItem && existingDeferred) {
      existingItem.count++;

      return existingItem;
    }

    const deferred = new Deferred<FetchResult>();

    deferred.promise.catch(noop);

    const deferredItem: CacheDeferred = existingDeferred ?? {
      promise: deferred.promise,

      resolve: (result: FetchResult) => {
        if (cacheItem.result !== undefined) {
          return;
        }

        cacheItem.result = result;
        deferred.resolve(result);

        this._deferreds.delete(hash);

        setTimeout(() => {
          this._items.delete(hash);
        }, this._expireAfterMs);
      },

      reject: (error: any) => {
        deferred.reject(error);

        this._items.delete(hash);
        this._deferreds.delete(hash);
      },
    };

    const cacheItem: CacheItem = {
      promise: deferredItem.promise,
      result: undefined,
      count: 1,
      queue: [],
    };

    this._items.set(hash, cacheItem);
    this._deferreds.set(hash, deferredItem);

    return cacheItem;
  }

  addItemWithQueue(hash: string, pruningSignal?: AbortSignal) {
    const existingItem = this._items.get(hash);
    const existingDeferred = this._deferreds.get(hash);

    const prune = () => {
      pruningSignal?.removeEventListener('abort', prune);
      const item = this._items.get(hash);

      if (!item) {
        this._deferreds.delete(hash);
        return;
      }

      const selfIndex = item.queue.findIndex((el) => el === prune);

      if (selfIndex === -1) {
        return;
      }

      item.queue.splice(selfIndex, 1);

      if (item.queue.length === 0) {
        this._items.delete(hash);

        if (item.result !== undefined) {
          // Only remove the underlying deferred if the promise was resolved.
          this._deferreds.delete(hash);
        }
      }
    };

    if (existingItem && existingDeferred) {
      existingItem.queue.push(prune);
      pruningSignal?.addEventListener('abort', prune);

      return existingItem;
    }

    const deferred = new Deferred<FetchResult>();

    deferred.promise.catch(noop);

    const queue = [];

    queue.push(prune);

    const deferredItem: CacheDeferred = existingDeferred ?? {
      promise: deferred.promise,

      resolve: (result: FetchResult) => {
        if (cacheItem.result !== undefined) {
          return;
        }

        cacheItem.result = result;
        deferred.resolve(result);

        if (cacheItem.queue.length === 0) {
          // If the queue is empty at the time the promise is resolved,
          // we can remove the underlying deferred.
          this._deferreds.delete(hash);
        }

        if (pruningSignal?.aborted) {
          prune();
        } else {
          pruningSignal?.addEventListener('abort', () => {
            prune();
          });
        }

        setTimeout(() => {
          // Unconditionally remove the item from the cache
          // after the expiration time has passed, instead
          // of simply calling `prune()` (i.e. popping an element
          // out of the queue) to ensure possibly stale values
          // don't live in the cache for too long.
          this._items.delete(hash);
          this._deferreds.delete(hash);
        }, this._expireAfterMs);
      },

      reject: (error: any) => {
        deferred.reject(error);

        this._items.delete(hash);
        this._deferreds.delete(hash);
      },
    };

    const cacheItem: CacheItem = {
      promise: deferredItem.promise,
      result: undefined,
      count: 1,
      queue,
    };

    this._items.set(hash, cacheItem);
    this._deferreds.set(hash, deferredItem);

    return cacheItem;
  }

  resolve(hash: string, result: FetchResult) {
    return this._deferreds.get(hash)?.resolve(result);
  }

  reject(hash: string, error: any) {
    return this._deferreds.get(hash)?.reject(error);
  }

  getItem(hash: string): FetchResult | Promise<FetchResult> | undefined {
    const enablePruningSignal =
      typeof window !== 'undefined' &&
      !!(window as any).PRELOADED?.flags?.enable_prefetch_cache_manual_pruning;

    if (enablePruningSignal) {
      // Branch off to new method with the new appraoch based on
      // queue inspection instead of counter.
      return this.getItemFromQueue(hash);
    }

    const existingItem = this._items.get(hash);

    if (!existingItem) {
      return undefined;
    }

    if (existingItem.count === 1) {
      nextTick(() => {
        const existingItem = this._items.get(hash);

        // Due to the nextTick a request could have increased
        // again the prefetch count so we need to recheck it.
        if (existingItem) {
          existingItem.count--;

          if (existingItem.count === 0) {
            this._items.delete(hash);
          }
        }
      });
    } else {
      existingItem.count--;
    }

    return existingItem.result ?? existingItem.promise;
  }

  getItemFromQueue(
    hash: string,
  ): FetchResult | Promise<FetchResult> | undefined {
    const existingItem = this._items.get(hash);

    if (!existingItem) {
      return undefined;
    }

    if (existingItem.queue.length === 1) {
      nextTick(() => {
        const existingItem = this._items.get(hash);

        // Due to the nextTick a request could have increased
        // again the prefetch count so we need to recheck it.
        if (existingItem) {
          const pruneFn = existingItem.queue[0];
          pruneFn?.();
        }
      });
    } else {
      // FIFO criteria for removing the item from the cache
      const pruneFn = existingItem.queue[0];
      pruneFn?.();
    }

    return existingItem.result ?? existingItem.promise;
  }

  clear() {
    this._items.clear();
  }
}
