import {checkSvg as Icon__small__checkSvg} from '../Icon__small/Icon__small--check.js'
import {checkSvg as Icon__xsmall__checkSvg} from '../Icon__xsmall/Icon__xsmall--check.js'
import {checkSvg as Icon__xxsmall__checkSvg} from '../Icon__xxsmall/Icon__xxsmall--check.js'

export var check = {
  name: 'check',
  category: 'icon',
  colorable: true,
  svg: Icon__small__checkSvg,
  alt: {
    Icon__small: Icon__small__checkSvg,
    Icon__xsmall: Icon__xsmall__checkSvg,
    Icon__xxsmall: Icon__xxsmall__checkSvg
  },
  combined: ['Icon__small', 'Icon__xsmall', 'Icon__xxsmall']
};
