import * as React from 'react';

import Card from 'sail/Card';
import ContentList, {ContentListItem} from 'sail/ContentList';
import Group from 'sail/Group';
import Radio from 'sail/Radio';

import styles from './ConsentDevTools.module.css';

import type {
  ConsentImageMode,
  ConsentUseCase,
} from '@stripe-internal/data-gelato/schema/types';

export const ConsentDevTools = (props: {
  isInternal: boolean;
  setIsInternalOverride: (val: boolean) => void;
  useCase: ConsentUseCase;
  setUseCaseOverride: (val: ConsentUseCase) => void;
  imageMode: ConsentImageMode;
  setImageModeOverride: (val: ConsentImageMode) => void;
  privacyPolicy: string | null | undefined;
  setPrivacyPolicyOverride: (val?: string | null | undefined) => void;
  returnUrl: string | null | undefined;
  setReturnUrlOverride: (val?: string | null | undefined) => void;
  isConnectIframeOverride: boolean;
  setIsConnectIframeOverride: (val: boolean) => void;
}) => {
  const {
    isInternal,
    setIsInternalOverride,
    isConnectIframeOverride,
    setIsConnectIframeOverride,
    useCase,
    setUseCaseOverride,
    imageMode,
    setImageModeOverride,
    privacyPolicy,
    setPrivacyPolicyOverride,
    returnUrl,
    setReturnUrlOverride,
  } = props;
  return (
    // @ts-expect-error - TS2769 - No overload matches this call.
    <Card className={styles.consentDevTools} background="white">
      <ContentList>
        <ContentListItem
          title="Set internal / external integration"
          description={
            <Group direction="horizontal" spacing={12}>
              <Radio
                checked={isInternal}
                label="internal"
                onChange={() => setIsInternalOverride(true)}
              />
              <Radio
                checked={!isInternal}
                label="external"
                onChange={() => setIsInternalOverride(false)}
              />
            </Group>
          }
        />
        <ContentListItem
          title="Set isConnectIframe"
          description={
            <Group direction="horizontal" spacing={12}>
              <Radio
                checked={isConnectIframeOverride}
                label="true"
                onChange={() => setIsConnectIframeOverride(true)}
              />
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <Radio
                checked={!isConnectIframeOverride}
                label="false"
                onChange={() => setIsConnectIframeOverride(false)}
              />
            </Group>
          }
        />
        <ContentListItem
          title="Set use case"
          description={
            <Group direction="horizontal" spacing={12}>
              <Radio
                checked={useCase === 'kyc'}
                label="kyc"
                onChange={() => setUseCaseOverride('kyc')}
              />
              <Radio
                label="legal_light"
                checked={useCase === 'legal_light'}
                onChange={() => setUseCaseOverride('legal_light')}
              />
              <Radio
                label="trust_and_safety"
                checked={useCase === 'trust_and_safety'}
                onChange={() => setUseCaseOverride('trust_and_safety')}
              />
            </Group>
          }
        />
        <ContentListItem
          title="Images"
          description={
            <Group direction="horizontal" spacing={12}>
              <Radio
                checked={imageMode === 'identity_document'}
                label="id_document"
                onChange={() => setImageModeOverride('identity_document')}
              />
              <Radio
                checked={imageMode === 'identity_document_and_selfie'}
                label="id_document and selfie"
                onChange={() =>
                  setImageModeOverride('identity_document_and_selfie')
                }
              />
              <Radio
                checked={imageMode === 'selfie'}
                label="selfie"
                onChange={() => setImageModeOverride('selfie')}
              />
            </Group>
          }
        />
        <ContentListItem
          title="merchant privacy policy"
          description={
            <Group direction="horizontal" spacing={12}>
              <Radio
                checked={!!privacyPolicy}
                label="provided"
                onChange={() =>
                  setPrivacyPolicyOverride('https://www.example.com/privacy')
                }
              />
              <Radio
                checked={!privacyPolicy}
                label="none"
                onChange={() => setPrivacyPolicyOverride(null)}
              />
            </Group>
          }
        />
        <ContentListItem
          title="merchant return url"
          description={
            <Group direction="horizontal" spacing={12}>
              <Radio
                checked={!!returnUrl}
                label="provided"
                onChange={() =>
                  setReturnUrlOverride('https://www.example.com/return')
                }
              />
              <Radio
                checked={!returnUrl}
                label="none"
                onChange={() => setReturnUrlOverride(null)}
              />
            </Group>
          }
        />
      </ContentList>
    </Card>
  );
};
