import type {SelfieVerificationMethod} from '@stripe-internal/data-gelato/schema/types';

/**
 * @fileoverview This contains state related to config set by the user
 * for the lifecycle of a session
 */

export type ConfigState = {
  config: {
    selfieVerificationMethod: SelfieVerificationMethod;
  };
};

/**
 * Creates the initial config state for the controller.
 * @returns The initial config state.
 */
export function createConfigState(): ConfigState {
  return {
    config: {
      selfieVerificationMethod: 'automated',
    },
  };
}

/**
 * Sets the selfie verification method
 * @param state The current state.
 * @param value One of the
 */
export function setSelfieVerificationMethod(
  state: ConfigState,
  value: SelfieVerificationMethod,
) {
  state.config.selfieVerificationMethod = value;
}
