import * as Sentry from '@sentry/browser';
import fetch from 'isomorphic-unfetch';

import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import experiments from 'gelato/frontend/src/lib/experiments';
import maybeReportInvalidImage from 'gelato/frontend/src/lib/maybeReportInvalidImage';
import {reportMetric} from 'gelato/frontend/src/lib/metricsBatcher';
import {handleException} from 'gelato/frontend/src/lib/sentry';

import type {
  CaptureFrameTypes,
  FileUploadMethod,
} from '@stripe-internal/data-gelato/schema/types';

// 'identity_private' is the only file purpose we should ever upload with
const IDENTITY_PRIVATE_PURPOSE = 'identity_private';

type File = {
  id: string;
  size: number;
  type: string;
};

type UploadParams = {
  createLink: boolean;
  ownedBy: string;
  fileName: string;
  host: string;
  livemode: boolean;
  token: string;
  blob: Blob;
  uploadMethod: FileUploadMethod;
  frameType: CaptureFrameTypes;
  isSelfie: boolean;
};

export const uploadFile = async ({
  blob,
  ownedBy,
  fileName,
  host,
  livemode,
  token,
  uploadMethod,
  frameType,
  isSelfie,
}: UploadParams): Promise<File> => {
  const formData = new FormData();
  formData.append('file', blob, fileName);
  formData.append('purpose', IDENTITY_PRIVATE_PURPOSE);
  formData.append('owned_by', ownedBy);

  const headers = new Headers();
  headers.set('Authorization', `Bearer ${token}`);
  headers.set('Stripe-Livemode', livemode ? 'true' : 'false');
  headers.set('Stripe-Version', '2020-03-02');
  headers.set('X-Requested-With', 'fetch');

  analytics.track('fileUploadSize', {
    file_size: blob.size,
  });

  const resp = await fetch(`${host}/v1/files`, {
    method: 'POST',
    credentials: 'include',
    body: formData,
    headers,
  }).catch((e) => {
    // Do one retry if network failed
    Sentry.addBreadcrumb({
      category: 'FileUpload',
      message: `Error uploading file ${fileName}: ${e}`,
      level: Sentry.Severity.Warning,
    });
    return fetch(`${host}/v1/files`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
      headers,
    }).catch((e) => {
      Sentry.addBreadcrumb({
        category: 'FileUpload',
        message: `Error uploading file ${fileName}: ${e}`,
        level: Sentry.Severity.Error,
      });
      throw e;
    });
  });

  if (!resp.ok) {
    const error = Error(
      `Request rejected with status: ${
        resp.status
      }; body: ${await resp.text()}`,
    );

    if (resp.status === 408) {
      const cause = asError(error);
      handleException(
        new Error(ErrorCode.failedToUploadFile, {cause}),
        cause.message,
      );
    }
  }

  const jsonPayload = await resp.json();
  reportMetric({
    metric: 'gelato_frontend_file_uploaded',
    operation: 'count',
    tags: [
      {key: 'upload_method', value: uploadMethod},
      {key: 'frame_type', value: frameType},
      {
        // the value here is used for filtering metrics,
        // so although this is not the actualy 'purpose' value
        // it should not be updated to `identity_private`
        key: 'purpose',
        value: isSelfie ? 'selfie' : 'identity_document',
      },
    ],
    value: 1,
  });

  // NOTE: In Butter, we do a separate check (see ImageFrame.ts)
  if (!experiments.isActive('document_upload_page_v2')) {
    // Asynchronously check if the image is blank and report it to analytics.
    maybeReportInvalidImage(blob, {
      file: jsonPayload.id,
      fileSize: jsonPayload.size,
      fileType: jsonPayload.type,
    });
  }

  return jsonPayload;
};
