import type {Placement as FloatingPlacement} from '@floating-ui/react';
import type {Placement} from '@react-types/overlays';

/**
 * Converts a placement string from `@react-types/overlays`
 * to a value that `@floating-ui` can accept.
 */
export const toFloatingPlacement = (
  placement: Placement,
): FloatingPlacement => {
  const [side, align] = placement.split(' ');

  if (!align) {
    return side
      .replace('start', 'left')
      .replace('end', 'right') as FloatingPlacement;
  }

  switch (side) {
    case 'top':
      return ['start', 'left'].includes(align) ? 'top-start' : 'top-end';
    case 'bottom':
      return ['start', 'left'].includes(align) ? 'bottom-start' : 'bottom-end';
    case 'left':
      return ['start', 'top'].includes(align) ? 'left-start' : 'left-end';
    case 'right':
      return ['start', 'top'].includes(align) ? 'right-start' : 'right-end';
    default:
      // Placement accepts side/align in any order, but FloatingPlacement requires
      // side to come first, so we need to flip them here.
      return toFloatingPlacement(`${align} ${side}` as Placement);
  }
};
