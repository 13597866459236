import {graphql} from '@sail/data';

import type {GraphQlDocument} from '@sail/data';
import type {
  Exact,
  FieldErrors,
} from '@stripe-internal/data-gelato/schema/types';
import type {GelatoPermissionToken} from '@stripe-internal/data-gelato/types';

const doc = graphql`
  fragment ValidationErrorFragment on ValidationError {
    type
    path
  }
`;

export const validationErrorFragment: ValidationErrorFragmentDocument = doc;

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type ValidationErrorFragmentData = {
  readonly __typename: 'ValidationError';
  readonly type: FieldErrors;
  readonly path: ReadonlyArray<string>;
};

export type ValidationErrorFragmentVariables = Exact<{[key: string]: never}>;

const validationErrorFragmentPermissions = {} as const;

doc.permissions = validationErrorFragmentPermissions;

export type ValidationErrorFragmentPermissions = GelatoPermissionToken<
  typeof validationErrorFragmentPermissions
>;

export type ValidationErrorFragmentDocument = GraphQlDocument<
  ValidationErrorFragmentData,
  ValidationErrorFragmentVariables,
  ValidationErrorFragmentPermissions
>;
