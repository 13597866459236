import {logInDev, warnInDev} from 'gelato/frontend/src/lib/assert';
import {DetectorSoftError} from 'gelato/frontend/src/ML/detectors/DetectorSoftError';

/**
 * This is the base class for all detectors. It provides a common
 * interface for all detectors to implement.
 * @template T The type of the detector's result.
 */
export default abstract class Detector<
  TDetectArguments extends any[],
  TDetectResult,
> {
  protected abstract detectorName: string;

  private _warmedUp = false;

  private _built = false;

  private warmupPromise: Promise<void> | undefined;

  private buildPromise: Promise<void> | undefined;

  /**
   * This method should be implemented by subclasses. It should return
   * a promise that resolves to the result of the detector.
   * @param args The arguments to pass to the detector's constructor.
   * @returns a promise that resolves to the result of the detector.
   */
  protected abstract _detect(...args: TDetectArguments): Promise<TDetectResult>;

  async detect(...args: TDetectArguments): Promise<TDetectResult> {
    if (!this._warmedUp) {
      await this.warmup();
    }
    return this._detect(...args);
  }

  /**
   * This is an optional method that can be implemented by subclasses.
   * It should return a promise that resolves when the detector has
   * been warmed up.
   */
  protected _warmup?(): Promise<void>;

  async warmup() {
    if (!this._built) {
      // if warmup is called before build
      await this.build();
    }
    if (!this.warmupPromise) {
      if (!this._warmup) {
        this.warmupPromise = Promise.resolve();
        return this.warmupPromise;
      }
      this.log('warmup', 'start');
      this.warmupPromise = this._warmup()
        .then(() => {
          this.log('warmup', 'stop');
          this._warmedUp = true;
        })
        .catch((e) => {
          this.log('warmup', 'stop', e);
          warnInDev(e);
          if (!(e instanceof DetectorSoftError)) {
            throw e;
          }
        });
    }
    return this.warmupPromise;
  }

  /**
   * This is a required method that should be implemented by subclasses.
   * It should return a promise that resolves when the detector is
   * ready to be used.
   */
  protected abstract _build(): Promise<void>;

  async build() {
    if (!this.buildPromise) {
      this.log('build', 'start');
      this.buildPromise = this._build()
        .then(() => {
          this.log('build', 'stop');
          this._built = true;
        })
        .catch((e) => {
          this.log('build', 'stop', e);
          warnInDev(e);
          if (!(e instanceof DetectorSoftError)) {
            throw e;
          }
        });
    }
    return this.buildPromise;
  }

  /**
   * This method logs a formatted message to the console in dev.
   * @param message The message to log.
   * @param status The status of the state. Either 'start' or 'stop'.
   * @param args Any additional arguments to log.
   */
  private log(message: string, status: 'start' | 'stop', ...args: any[]) {
    logInDev(`${this.detectorName}: ${message} [${status}]`);
    if (args.length > 0) {
      logInDev(args);
    }
  }
}
