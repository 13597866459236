/* eslint-disable @typescript-eslint/no-use-before-define */
import {getScrollParent} from '.';

interface ScrollIntoViewportOpts {
  /** The optional containing element of the target to be centered in the viewport. */
  containingElement?: Element;
}

/**
 * Scrolls `scrollView` so that `element` is visible.
 * Similar to `element.scrollIntoView({block: 'nearest'})` (not supported in Edge),
 * but doesn't affect parents above `scrollView`.
 */
export function scrollIntoView(scrollView: HTMLElement, element: HTMLElement) {
  const offsetX = relativeOffset(scrollView, element, 'left');
  const offsetY = relativeOffset(scrollView, element, 'top');
  const width = element.offsetWidth;
  const height = element.offsetHeight;
  let x = scrollView.scrollLeft;
  let y = scrollView.scrollTop;

  // Account for top/left border offsetting the scroll top/Left
  const {borderTopWidth, borderLeftWidth} = getComputedStyle(scrollView);
  const borderAdjustedX = scrollView.scrollLeft + parseInt(borderLeftWidth, 10);
  const borderAdjustedY = scrollView.scrollTop + parseInt(borderTopWidth, 10);
  // Ignore end/bottom border via clientHeight/Width instead of offsetHeight/Width
  const maxX = borderAdjustedX + scrollView.clientWidth;
  const maxY = borderAdjustedY + scrollView.clientHeight;

  if (offsetX <= x) {
    x = offsetX - parseInt(borderLeftWidth, 10);
  } else if (offsetX + width > maxX) {
    x += offsetX + width - maxX;
  }
  if (offsetY <= borderAdjustedY) {
    y = offsetY - parseInt(borderTopWidth, 10);
  } else if (offsetY + height > maxY) {
    y += offsetY + height - maxY;
  }
  scrollView.scrollLeft = x;
  scrollView.scrollTop = y;
}

/**
 * Computes the offset left or top from child to ancestor by accumulating
 * offsetLeft or offsetTop through intervening offsetParents.
 */
function relativeOffset(
  ancestor: HTMLElement,
  child: HTMLElement,
  axis: 'left' | 'top',
) {
  const prop = axis === 'left' ? 'offsetLeft' : 'offsetTop';
  let sum = 0;
  let currentChild = child;
  while (currentChild.offsetParent) {
    sum += currentChild[prop];
    if (currentChild.offsetParent === ancestor) {
      // Stop once we have found the ancestor we are interested in.
      break;
    } else if (currentChild.offsetParent.contains(ancestor)) {
      // If the ancestor is not `position:relative`, then we stop at
      // _its_ offset parent, and we subtract off _its_ offset, so that
      // we end up with the proper offset from child to ancestor.
      sum -= ancestor[prop];
      break;
    }
    currentChild = currentChild.offsetParent as HTMLElement;
  }
  return sum;
}

/**
 * Scrolls the `targetElement` so it is visible in the viewport. Accepts an optional `opts.containingElement`
 * that will be centered in the viewport prior to scrolling the targetElement into view. If scrolling is prevented on
 * the body (e.g. targetElement is in a popover), this will only scroll the scroll parents of the targetElement up to but not including the body itself.
 */
export function scrollIntoViewport(
  targetElement: Element,
  opts?: ScrollIntoViewportOpts,
) {
  let currentTargetElement = targetElement;
  if (document.contains(currentTargetElement)) {
    const root = document.scrollingElement || document.documentElement;
    const isScrollPrevented =
      window.getComputedStyle(root).overflow === 'hidden';
    // If scrolling is not currently prevented then we aren’t in a overlay nor is a overlay open, just use element.scrollIntoView to bring the element into view
    if (!isScrollPrevented) {
      const {left: originalLeft, top: originalTop} =
        currentTargetElement.getBoundingClientRect();

      // use scrollIntoView({block: 'nearest'}) instead of .focus to check if the element is fully in view or not since .focus()
      // won't cause a scroll if the element is already focused and doesn't behave consistently when an element is partially out of view horizontally vs vertically
      currentTargetElement?.scrollIntoView?.({block: 'nearest'});
      const {left: newLeft, top: newTop} =
        currentTargetElement.getBoundingClientRect();
      // Account for sub pixel differences from rounding
      if (
        Math.abs(originalLeft - newLeft) > 1 ||
        Math.abs(originalTop - newTop) > 1
      ) {
        opts?.containingElement?.scrollIntoView?.({
          block: 'center',
          inline: 'center',
        });
        currentTargetElement.scrollIntoView?.({block: 'nearest'});
      }
    } else {
      let scrollParent = getScrollParent(currentTargetElement);
      // If scrolling is prevented, we don't want to scroll the body since it might move the overlay partially offscreen and the user can't scroll it back into view.
      while (
        currentTargetElement &&
        scrollParent &&
        currentTargetElement !== root &&
        scrollParent !== root
      ) {
        scrollIntoView(
          scrollParent as HTMLElement,
          currentTargetElement as HTMLElement,
        );
        currentTargetElement = scrollParent;
        scrollParent = getScrollParent(currentTargetElement);
      }
    }
  }
}
