import {usePress as reactAriaUsePress} from '@sail/react-aria';
import {useBatchedHandler} from '@sail/engine';

export const usePress: typeof reactAriaUsePress = ({
  onPress,
  onPressChange,
  onPressEnd,
  onPressStart,
  onPressUp,
  ...rest
}) => {
  const batchedOnPress = useBatchedHandler(onPress);
  const batchedOnPressStart = useBatchedHandler(onPressStart);
  const batchedOnPressChange = useBatchedHandler(onPressChange);
  const batchedOnPressUp = useBatchedHandler(onPressUp);
  const batchedOnPressEnd = useBatchedHandler(onPressEnd);

  return reactAriaUsePress({
    onPress: onPress && batchedOnPress,
    onPressStart: onPressStart && batchedOnPressStart,
    onPressEnd: onPressEnd && batchedOnPressEnd,
    onPressUp: onPressUp && batchedOnPressUp,
    onPressChange: onPressChange && batchedOnPressChange,
    ...rest,
  });
};
