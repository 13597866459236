import {ApolloLink, Observable} from '@apollo/client';

import type {InternalOperation} from 'src/types';

const LIMIT = 60000;

/**
 * @see https://sail.stripe.me/apis/data/createTimeoutLink
 */
export default function createTimeoutLink(): ApolloLink {
  return new ApolloLink(function timeoutLink(
    operation: InternalOperation,
    forward,
  ) {
    return new Observable(function timeoutObserver(observer) {
      const timeout = setTimeout(() => {
        const context = operation.getContext();

        context.observabilityFns?.metrics.increment(
          'frontend.data.long_request',
          {
            service: context.service,
            project: context.queryProject,
            component: context.component,

            operation_name: operation.operationName ?? '<unknown>',
            operation_prefetch: !!context.isPrefetchMode,
            operation_deduplicate: !!context.isDeduplicated,
          },
        );
      }, LIMIT);

      const subscription = forward(operation).subscribe({
        next: function timeoutObserverNext(result) {
          clearTimeout(timeout);

          return observer.next(result);
        },

        error: function timeoutObserverError(error) {
          clearTimeout(timeout);

          return observer.error(error);
        },

        complete: function timeoutObserverComplete() {
          clearTimeout(timeout);

          return observer.complete();
        },
      });

      return function timeoutObserverUnsubscribe(): void {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    });
  });
}
