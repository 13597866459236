import {StyleSheets} from '../../../stylesheets';
import type {Layer} from './layer';
import type {Rule} from './rule';

export class RuleCache {
  map: Record<string, Rule> = {};

  constructor() {
    StyleSheets.onFlush(() => {
      this.map = {};
    });
  }

  add(rule: Rule): void {
    this.map[rule.key] = rule;
  }

  get(key: string): Rule | void {
    return this.map[key];
  }

  _insertLayer(layer: Layer, path = ''): void {
    StyleSheets.schedule(() => {
      StyleSheets.insert(layer.rulesets, path);
    });

    for (let i = 0; i < layer.order.length; i++) {
      const segment = layer.order[i];
      this._insertLayer(
        layer.layers[segment],
        path ? `${path}.${segment}` : segment,
      );
    }
  }

  insert(rule: Rule): void {
    if (rule.inserted) {
      return;
    }

    this.forEachDependency(rule, (dep) => this.insert(dep));
    this._insertLayer(rule.layer);
    rule.inserted = true;
  }

  forEachRule(keys: string[], callback: (rule: Rule) => void): void {
    for (let i = 0; i < keys.length; i++) {
      const rule = this.get(keys[i]);
      if (rule) {
        callback(rule);
      }
    }
  }

  forEachDependency(rule: Rule, callback: (rule: Rule) => void): void {
    if (rule.dependencies) {
      this.forEachRule(Object.values(rule.dependencies), callback);
    }
    if (rule.merged) {
      this.forEachRule(rule.merged, callback);
    }
  }
}
