import {forEachToken} from '@sail/engine';
import type {Style, Token} from '@sail/engine';
import {tokens} from '../../tokens';
import {property} from './util';

export const backgroundColor = property([tokens.color.background]).accept();
export const borderColor = property([tokens.color.border]).accept();
export const borderTopColor = property([tokens.color.border]).accept();
export const borderRightColor = property([tokens.color.border]).accept();
export const borderBottomColor = property([tokens.color.border]).accept();
export const borderLeftColor = property([tokens.color.border]).accept();
export const textColor = property([tokens.color.text]).alias('color');
export const stroke = property([tokens.color.icon]).accept();
export const fill = property([tokens.color.icon]).accept();

function createColorRelationshipsMap() {
  const map = {} as Record<
    string,
    {
      background?: Token.Value<string>;
      border?: Token.Value<string>;
      icon?: Token.Value<string>;
      text?: Token.Value<string>;
    }
  >;

  (['background', 'border', 'icon', 'text'] as const).forEach((key) => {
    forEachToken(tokens.color[key], (value, path) => {
      if (key !== 'background') {
        map[path] ??= {};
        map[path][key] = value;
      }
      if (key === 'background' || key === 'border') {
        const onPath = path.replace(
          /^([a-z])/,
          (_, c) => `on${c.toUpperCase()}`,
        );
        map[onPath] ??= {};
        map[onPath][key] = value;
      }
    });
  });

  return map;
}

const relationshipsMap = createColorRelationshipsMap();

export const color = property([tokens.color.text]).decorate(function color(
  // eslint-disable-next-line @typescript-eslint/ban-types
  input: (string & {}) | Token.Key<typeof tokens.color.text>,
  set: Style.PluginAPI,
): void {
  if (input in relationshipsMap) {
    const relationships = relationshipsMap[input];
    relationships.border &&
      set.property('borderColor', borderColor(relationships.border));
    relationships.background &&
      set.property(
        'backgroundColor',
        backgroundColor(relationships.background),
      );
    relationships.text && textColor(relationships.text, set);
    relationships.icon && set.property('fill', fill(relationships.icon));
  } else {
    textColor(input, set);
    set.property('fill', fill(input));
  }
});
