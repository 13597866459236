import {view} from '@sail/ui';
import * as React from 'react';

import {Styles as BottomSheetV2Styles} from 'gelato/frontend/src/components/BottomSheetV2';

export default function Footer({children}: FooterProps) {
  return (
    <view.div
      uses={[BottomSheetV2Styles.footer]}
      css={{
        stack: 'y',
        gap: 'small',
        paddingX: 'none',
        paddingY: 'none',
        marginBottom: 'xsmall',
      }}
    >
      {children}
    </view.div>
  );
}

type FooterProps = {
  children: React.ReactNode;
};
