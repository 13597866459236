/**
 * Calls all functions in the order they were chained with the same arguments.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function chain(...callbacks: any[]): (...args: any[]) => void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const callback of callbacks) {
      if (typeof callback === 'function') {
        callback(...args);
      }
    }
  };
}
