import {getPixelSourceDimensions} from 'gelato/frontend/src/lib/canvas';
import ImageFrame from 'gelato/frontend/src/lib/ImageFrame';
import BlurDetector from 'gelato/frontend/src/ML/detectors/BlurDetector';

import type {Point} from 'gelato/frontend/src/ML/IDDetectorAPI';

export async function findSharpestPatch(
  pixelSource: HTMLCanvasElement | ImageFrame,
  topLeft: Point,
  dimensions: Point,
  blurDetector: BlurDetector,
  imageSize: number,
): Promise<Point> {
  // Location of ID
  const [w, h] = dimensions;
  const [idX, idY] = topLeft;

  // Select a patch in the middle with highest sharpness index (blur score)
  const isVertical = h > w * 1.1;
  const blurScorePromises: Array<Promise<any>> = [];
  const {sourceWidth, sourceHeight} = getPixelSourceDimensions(pixelSource);

  const step = isVertical ? w / 4 : h / 4;
  const xConst = Math.min(
    sourceWidth - imageSize,
    Math.max(0, idX + w / 2 - imageSize),
  );
  const yConst = Math.min(
    sourceHeight - imageSize,
    Math.max(0, idY + h / 2 - imageSize),
  );
  let i;
  for (i = 0; i < 3; i++) {
    let x0;
    let y0;
    if (isVertical) {
      const xMid = idX + step * i;
      x0 = Math.min(sourceWidth - imageSize, Math.max(0, xMid + imageSize / 2));
      y0 = yConst;
    } else {
      const yMid = idY + step * i;
      y0 = Math.min(
        sourceHeight - imageSize,
        Math.max(0, yMid + imageSize / 2),
      );
      x0 = xConst;
    }
    blurScorePromises.push(
      blurDetector
        .detect(
          pixelSource,
          {topLeft: [x0, y0], dimensions: [imageSize, imageSize]},
          [imageSize, imageSize],
        )
        .then((score) => [score, [x0, y0]]),
    );
  }
  // Find y coordinate of the patch with highest sharpness
  const blurScores = await Promise.all(blurScorePromises);

  const blurScoresSorted = blurScores.sort((a, b) => {
    return b[0] - a[0];
  });
  return blurScoresSorted[0][1];
}

export default findSharpestPatch;
