import * as React from 'react';
import {createContext, useContext, useMemo} from 'react';
import {onRender} from './intentTypes';
import {createView, View} from './view';
import {createCss, deprecatedCss} from './deprecatedCss';
import {StyleSheets} from '../stylesheets';
import type {Intent} from '../intent';
import type {Component} from './view';
import type {CreateCss} from './deprecatedCss';
import type {AnyCssSerializer} from './css';
import {onComponentWillReceiveProps} from './view/transformProps';

export interface Theme<Css extends AnyCssSerializer = AnyCssSerializer> {
  (intent: ThemedIntent, intents: Intent<unknown>[]): ThemedIntent;
  css: Css;
  /** @deprecated */
  deprecatedCss: CreateCss;
  attributes: (intents: Intent<unknown>[]) => void;
  global: (intents: Intent<unknown>[]) => void;
  id: string;
}

export interface ThemedIntent extends Intent {
  byTheme: {[key: string]: Intent[]};
}

export function isThemedIntent(intent: unknown): intent is ThemedIntent {
  return !!(intent as ThemedIntent).byTheme;
}

export function createTheme<Css extends AnyCssSerializer>(
  id: string,
  css: Css,
): Theme<Css> {
  function theme(
    intent: ThemedIntent,
    intents: Intent<unknown>[],
  ): ThemedIntent {
    if (intent.byTheme[id]) {
      intent.byTheme[id].push(...(intents as Intent[]));
    } else {
      intent.byTheme[id] = intents.slice() as Intent[];
    }
    return intent;
  }

  theme.css = css.configure({
    layer: `theme.${id}`,
  }) as Css;
  StyleSheets.insert([], theme.css.options.layer);
  theme.deprecatedCss = createCss(deprecatedCss.plugins, {
    layer: `theme.${id}`,
  });
  theme.attributes = (intents: Intent<unknown>[]) =>
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    theme(themeAttributes, intents);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  theme.global = (intents: Intent<unknown>[]) => theme(themeGlobals, intents);
  theme.id = id;

  return theme;
}

const ThemeContext = createContext([] as Theme[]);

function getIntentsForThemes(themes: Theme[], intent: ThemedIntent): Intent[] {
  const intents = [] as Intent[];
  function flatten(current: ThemedIntent) {
    themes.forEach((theme) => {
      const themeIntents = current.byTheme[theme.id];
      if (!themeIntents) {
        return;
      }
      themeIntents.forEach((i) => {
        if (isThemedIntent(i)) {
          flatten(i);
        } else {
          intents.push(i);
        }
      });
    });
  }

  flatten(intent);
  return intents;
}

export function useTheme(registry: ThemedIntent): Intent<unknown>[] {
  const themes = useContext(ThemeContext);
  return useMemo(
    () => getIntentsForThemes(themes, registry),
    [registry, themes],
  );
}

export function themed(): ThemedIntent {
  // TODO(koop): Consider making this a proxied IntentMap to make merging the
  // resulting intents more efficient and/or add intents directly.
  const intent = onComponentWillReceiveProps(function useTheme() {
    const themes = useContext(ThemeContext);
    return useMemo(() => getIntentsForThemes(themes, intent), [themes]);
  }) as Intent as ThemedIntent;

  intent.byTheme = {};

  return intent;
}

// This is an internal function to create a render intent that only applies
// specific themes from a themed intent. We need this to apply global styles
// only for the immediate themes and none of the parent themes, since they
// have already been applied
function useOnlyThemes(intent: ThemedIntent, themes: Theme[]): Intent {
  return useMemo(
    () =>
      onRender<never>(function useTheme() {
        return useMemo(() => getIntentsForThemes(themes, intent), []);
      }),
    [intent, themes],
  );
}

export const themeGlobals = themed();
export const themeAttributes = themed();

// TODO(jlongster, SAIL-2644): In our next major version, change this back to
// not combine themes and instead reset everything
const ThemeProvider = createView<{
  themes: Theme[];
}>(function ThemeProvider({themes, ...props}): JSX.Element {
  const previousThemes = useContext(ThemeContext);
  const combinedThemes = useMemo(
    () => previousThemes.concat(themes),
    [previousThemes, themes],
  );

  const key = combinedThemes.map(({id}) => id).join('---');
  const memoized = useMemo(
    () => ({
      themes: combinedThemes,
      css: themes.map((theme) => theme.css.token.assign(null)),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key],
  );
  return (
    <ThemeContext.Provider value={memoized.themes}>
      <View
        uses={[
          useOnlyThemes(themeGlobals, themes),
          useOnlyThemes(themeAttributes, themes),
          props,
          ...memoized.css,
        ]}
      />
    </ThemeContext.Provider>
  );
});

export function createThemeProvider(
  themes: Theme[],
): Component<unknown, undefined, undefined, undefined> {
  return createView<unknown, undefined, undefined>(function Provider(props) {
    return <ThemeProvider themes={themes} uses={[props]} />;
  });
}

const MergeThemeProvider = createView<{
  themes: Theme[];
}>(function ThemeProvider({themes, ...props}): JSX.Element {
  const previousThemes = useContext(ThemeContext);
  const combinedThemes = useMemo(
    () => previousThemes.concat(themes),
    [previousThemes, themes],
  );

  const key = combinedThemes.map(({id}) => id).join('---');
  const memoized = useMemo(
    () => ({themes: combinedThemes}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key],
  );
  return (
    <ThemeContext.Provider value={memoized.themes}>
      <View
        uses={[
          useOnlyThemes(themeGlobals, themes),
          useOnlyThemes(themeAttributes, themes),
          props,
        ]}
      />
    </ThemeContext.Provider>
  );
});

export function createMergeThemeProvider(
  themes: Theme[],
): Component<unknown, undefined, undefined, undefined> {
  return createView<unknown, undefined, undefined>(function Provider(props) {
    return <MergeThemeProvider themes={themes} uses={[props]} />;
  });
}
