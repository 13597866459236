import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';

import type {GraphQlField} from '@sail/data';
import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

type Session = GraphQlField<GetSessionQueryData, 'session'>;

/**
 * Sends a request to generate a VR for testmode VS
 * @param state The application state.
 * @param runtime The application runtime.
 * @returns The updated session with the consent field changed.
 */
export default async function generateTestModeData(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
  verify: boolean,
): Promise<Session> {
  const resp = await runtime.api.mutation.setTestmodeSettingMutation({
    variables: {
      verify,
    },
  });
  const nextSession = resp.data?.setTestMode?.session as Session | undefined;

  if (!resp.data?.setTestMode?.success) {
    throw new Error(ErrorCode.testModeDataNotGenerated);
  }

  return nextSession!;
}
