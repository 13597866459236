export type CameraDeviceInfo = {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
};

/**
 * The camera state.
 */
export type CameraState = {
  camera: {
    error: Error | null;
    facing: 'user' | 'environment';
    onboarded: boolean;
    pending: boolean;
    previousError: Error | null;
    ready: boolean;
    status: 'paused' | 'started' | 'stopped';
    stream: MediaStream | null;
    userSelectedDeviceId: string | null;
    video: HTMLVideoElement | null;
  };
  cameraDeviceList: CameraDeviceInfo[];
  cameraDeviceListResolved: boolean;
};

/**
 * Creates the initial camera state for the controller.
 * @returns The initial state.
 */
export function createCameraState(): CameraState {
  return {
    camera: {
      error: null,
      facing: 'environment',
      onboarded: false,
      pending: false,
      previousError: null,
      ready: false,
      status: 'stopped',
      stream: null,
      userSelectedDeviceId: null,
      video: null,
    },
    cameraDeviceList: [],
    cameraDeviceListResolved: false,
  };
}

/**
 * Serializes a camera device info to a string.
 * @param info CameraDeviceInfo
 * @returns string The serialized string.
 */
export function serializeCameraDeviceInfoToString(
  info: CameraDeviceInfo,
): string {
  return JSON.stringify(info);
}
