import {useReports} from '@sail/observability';
import {once} from '@sail/utils';
import {createContext, useContext} from 'react';

import type {Errors} from '@sail/observability';
import type {DataConfig} from 'src/internal/config/types';

class DataConfigError extends Error {
  constructor(message: string) {
    super(`[@sail/data] ${message}`);
  }
}

export const uninitializedDataConfigError = () =>
  new DataConfigError(
    'Make sure to provide the required config for data to DataProvider upon initialization.',
  );

const reportUninitializedErrorOnce = once((reportError: Errors['error']) => {
  reportError(uninitializedDataConfigError(), {
    project: 'sail_core',
    tags: {namespace: 'data'},
  });
});

export const DataConfigContext = createContext<DataConfig | undefined>(
  undefined,
);

export function useDataConfig(): DataConfig {
  const config = useContext(DataConfigContext);
  const {error} = useReports();

  if (!config) {
    reportUninitializedErrorOnce(error);

    throw uninitializedDataConfigError();
  }

  return config;
}
