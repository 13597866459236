import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import Storage from 'gelato/frontend/src/lib/Storage';
import {uploadFile} from 'gelato/frontend/src/lib/uploadFile';
import {getUploadHost} from 'gelato/frontend/src/lib/urlConfig';
import IDInspector from 'gelato/frontend/src/ML/detectors/IDInspector';
import MicroBlinkCaptureInspector from 'gelato/frontend/src/ML/detectors/MicroBlinkCaptureInspector';

import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

async function trackAutoCaptureTimeoutErrorInternal(
  appState: ApplicationState,
) {
  const {session, document} = appState;
  const token = Storage.getSessionAPIKey();
  const host = getUploadHost();
  if (!token || !session) {
    throw new Error(ErrorCode.sessionIsEmpty);
  }
  if (!host) {
    throw new Error(ErrorCode.uploadHostIsNotSet);
  }

  const {id, livemode} = session;
  const {workingInputMethod, workingSide} = document;
  const {inspectionState} = appState.document[workingSide]!;
  const {inputImage, idInspectorResult, microBlinkCaptureInspectorResult} =
    inspectionState!;

  // Upload the image for debugging purposes. Make it smaller to save the
  // bandwidth.
  const thumbnailImage = await inputImage.fitToContain(350, 350);
  const blob = await thumbnailImage.toBlob();
  thumbnailImage.dispose();

  const frameType = 'auto_capture_timeout_full_frame';
  const fileName = `${id}_${workingSide}_${workingInputMethod}_${frameType}.jpg`;
  const uploadStartTime = Date.now();
  const uploadedFile = await uploadFile({
    blob,
    createLink: false,
    fileName,
    frameType,
    host: getUploadHost(),
    isSelfie: false,
    livemode,
    ownedBy: id,
    token,
    uploadMethod: 'auto_capture',
  });

  const idInspector = IDInspector.getInstance();
  const mbInspector = MicroBlinkCaptureInspector.getInstance();

  analytics.track('fileUploaded', {
    file: uploadedFile.id,
    fileSize: blob.size,
    frameType,
    uploadMethod: workingInputMethod,
    uploadTime: Date.now() - uploadStartTime,
    // We wanna know if IDInspector and MicroBlinkCaptureInspector worked or
    // not during the auto-capture timeout.
    idInspector: {
      status: idInspector.status,
      error: idInspector.error,
      result: JSON.stringify(idInspectorResult),
    },
    mbInspector: {
      error: mbInspector.error,
      status: mbInspector.status,
      result: JSON.stringify(microBlinkCaptureInspectorResult),
    },
  });
}

/**
 * Track the auto-capture timeout error. Consider using this function when the
 * auto-capture times out.
 *
 * This does the following:
 * - Uploads the image for debugging purposes.
 * - Send analytics event.
 *
 * @param appState The application state to work on.
 */
export default async function trackAutoCaptureTimeoutError(
  appState: ApplicationState,
) {
  try {
    await trackAutoCaptureTimeoutErrorInternal(appState);
  } catch (e) {
    handleException(asError(e), `Failed to track auto-capture timeout error.`);
  }
}
