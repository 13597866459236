import {animate, media} from '@sail/engine';
import {css} from '../css';
import {tokens} from '../tokens';

export type MobileDisplay = 'bottom-sheet' | 'fullscreen-sheet';

const fullscreen = {
  width: '100%',
  height:
    'calc(100% - var(--sail-layer-margin-top, 0px) - var(--sail-layer-margin-bottom, 0px))',
  borderRadius: '0px',
  maxHeight: '100%',
  borderWidth: '0px',
  top: 'var(--sail-layer-margin-top, 0px)',
  boxShadow: 'none',
};

export const bottomSheetStyles = css({
  [media.query(
    `${media.down(tokens.viewport.mobile)} and (any-pointer: coarse)`,
  )]: {
    position: 'absolute',
    bottom: '0px',
    top: 'initial',
    right: 'initial',
    left: 'initial',
    width: '100%',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    borderTopLeftRadius: tokens.space[150],
    borderTopRightRadius: tokens.space[150],
    maxHeight: 'calc(100% - 40px)',
    boxShadow: 'overlay.inverted',
    transition: 'box-shadow 200ms ease-in-out 150ms, transform 250ms ease-out',
    borderWidth: '0px',
  },
  // if the user is on a non-touch device, actually display it as a fullscreen sheet
  // this is unfortunate but there currently isn't another way to force fullscreen styles
  // when the user passes in 'bottom-sheet' as the preferredMobileDisplay prop until we have
  // the ability to create compound variants based on a prop + doing something in JS/the render function
  // and these styles must be passed via variants because that seems to be the only way
  // to get the precedence right
  [media.query(`${media.down(tokens.viewport.mobile)} and (pointer: fine)`)]:
    fullscreen,
});

export const fullscreenSheetStyles = css({
  [media.down(tokens.viewport.mobile)]: fullscreen,
});

export const bottomSheetAnimation = (disabled: boolean) =>
  animate.inout({
    duration: 250,
    transform: 'translateY(100%)',
    easing: 'ease-out',
    disabled,
  });

export const fullscreenAnimation = (disabled: boolean) =>
  animate.inout({
    duration: 250,
    opacity: 0,
    transform: 'scale(0.95, 0.95)',
    easing: 'ease-in-out',
    disabled,
  });
