import {createViewConfig} from '@sail/engine';
import {Link, LinkProps, css} from '@sail/ui';
import * as React from 'react';

const LinkConfig = createViewConfig({
  name: 'LinkButton',
  props: {} as LinkProps,
  flattens: true,
  defaults: {
    type: 'secondary',
  },
});

const Styles = {
  link: css({
    textDecoration: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: 'text.subdued',
    color: 'subdued',
    font: 'label.small',
    fontWeight: 'normal',
    ':hover': {
      color: 'subdued',
      font: 'label.small',
      textDecoration: 'underline',
      textDecorationStyle: 'solid',
      textDecorationColor: 'text.subdued',
    },
  }),
};

/**
 * Styled like Financial Connections Links. See https://docs.stripe.com/financial-connections for example.
 */

const FCLink = LinkConfig.createView(({...props}: LinkProps) => {
  return <Link uses={[Styles.link]} {...props} />;
});

export default FCLink;
