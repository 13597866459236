import * as React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import ThemableToggleBox from 'gelato/frontend/src/components/ThemableToggleBox';
import Badge from 'sail/Badge';
import Group from 'sail/Group';
import {ToggleBoxItem} from 'sail/ToggleBox';

import styles from './SelfieVerificationMethodSelectBox.module.css';

import type {SelfieVerificationMethod} from '@stripe-internal/data-gelato/schema/types';
import type {MessageDescriptor} from 'react-intl';

const selfieVerificationMethods: ReadonlyArray<SelfieVerificationMethod> = [
  'automated',
  'manual',
];

export const labelMessages: Partial<
  Record<SelfieVerificationMethod, MessageDescriptor>
> = defineMessages({
  automated: {
    id: 'selfie_verification_method_select_box.selfie_verification_method.label.automated',
    description: 'Automated verification type',
    defaultMessage: 'Automated Verification',
  },
  manual: {
    id: 'selfie_verification_method_select_box.selfie_verification_method.label.manual',
    description: 'Manual verification type',
    defaultMessage: 'Manual Verification',
  },
});

export const descriptionMessages: Partial<
  Record<SelfieVerificationMethod, MessageDescriptor>
> = defineMessages({
  automated: {
    id: 'selfie_verification_method_select_box.selfie_verification_method.description.automated',
    description: 'Description for automated verification type',
    defaultMessage:
      'Biometric fingerprint technology will use your ID card, images and other data sources to verify your identity',
  },
  manual: {
    id: 'selfie_verification_method_select_box.selfie_verification_method.description.manual',
    description: 'Description for manual verification type',
    defaultMessage:
      'A qualified specialist will verify your ID card and selfie, which takes more time',
  },
});

export const SLAMessages: Partial<
  Record<SelfieVerificationMethod, MessageDescriptor>
> = defineMessages({
  automated: {
    id: 'selfie_verification_method_select_box.selfie_verification_method.sla.automated',
    description: 'SLA for automated verification type',
    defaultMessage: '1-2 minutes',
  },
  manual: {
    id: 'selfie_verification_method_select_box.selfie_verification_method.sla.manual',
    description: 'SLA for manual verification type',
    defaultMessage: 'up to 2-3 days',
  },
});

type Props = {
  value: SelfieVerificationMethod | null | undefined;
  // Pass in what should happen when choice is made
  onChange: (method: SelfieVerificationMethod) => Promise<void>;
};

class SelfieVerificationMethodSelectBox extends React.Component<Props> {
  render() {
    const {onChange, value} = this.props;
    const choices = selfieVerificationMethods;

    return (
      <ThemableToggleBox
        className={styles.toggleBox}
        direction="vertical"
        layout="inline"
        name="selfie-verification-method-select"
        connected={false}
      >
        {choices.map((choice) => {
          const selected = value === choice;
          return (
            <ToggleBoxItem
              checked={selected}
              label={
                <Group direction="horizontal" spacing={20}>
                  <Message {...labelMessages[choice]} />
                  <Badge
                    label={<FormattedMessage {...SLAMessages[choice]} />}
                  />
                </Group>
              }
              description={<Message {...descriptionMessages[choice]} />}
              key={choice}
              onChange={() => {
                onChange(choice);
              }}
            />
          );
        })}
      </ThemableToggleBox>
    );
  }
}

export default SelfieVerificationMethodSelectBox;
