import {GraphQlField} from '@sail/data';
import {urlRedirect} from '@stripe-internal/safe-links';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {defineMessages, injectIntl} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {SetPageCardPropsContext} from 'gelato/frontend/src/lib/contexts';
import getGoBackButtonText from 'gelato/frontend/src/lib/getGoBackButtonText';
import {
  useConnectIframe,
  useSession,
  useBranding,
} from 'gelato/frontend/src/lib/hooks';
import {isInIframe, postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import Storage from 'gelato/frontend/src/lib/Storage';
import {hasiOSSheetCloseWindowHandler} from 'gelato/frontend/src/lib/windowHelpers';
import Icon from 'sail/Icon';
import Spinner from 'sail/Spinner';
import {Body, Title} from 'sail/Text';

import styles from './success.module.css';

import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';
import type {PageProps} from 'gelato/frontend/src/lib/localRouter';
import type {IntlShape} from 'react-intl';

type Props = PageProps & {
  intl: IntlShape;
};

const messages = defineMessages({
  documentTitle: {
    id: 'success.documentTitle',
    description: 'HTML title for verification submitted page',
    defaultMessage: 'Verification submitted | Stripe',
  },
  successTitle: {
    id: 'pages.success.heading',
    description: 'Heading on page after user submits verification',
    defaultMessage: 'Verification submitted',
  },
  successBody: {
    id: 'pages.success.body',
    description: 'Body of page explaining verification was submitted',
    defaultMessage: 'Thank you for providing your information.',
  },
  safeToClose: {
    id: 'pages.success.safeToClose',
    description: 'Message telling the user to close the tab',
    defaultMessage: 'You can now close this tab.',
  },
  successBodySecondary: {
    id: 'pages.success.body.secondary',
    description:
      'Body of page explaining verification was submitted in a mobile handoff session',
    defaultMessage:
      'Thank you for providing your information. You can return to your other device.',
  },
  successLink: {
    id: 'pages.success.successLink',
    description:
      'Link taking user back to dashboard after successful verification',
    defaultMessage: 'Go back to {platformName}',
  },
  redirectingTitle: {
    id: 'pages.success.redirecting.title',
    description:
      'Title for when the user is being redirected back to the merchant',
    defaultMessage: 'Redirecting to {platformName}',
  },
  done: {
    id: 'pages.success.done',
    description:
      'Button text that closes the page after successful verification',
    defaultMessage: 'Done',
  },
  continue: {
    id: 'pages.success.continue',
    description: 'Button text to continue if there are more actions to take.',
    defaultMessage: 'Continue',
  },
});

// only show the close button if we're in an iframe or in an iOS sheet
// this shouldShowCloseButton is subtly different from the one implemented in windowHelpers.ts - should they have the same logic or not?
const shouldShowCloseButton = () =>
  isInIframe() || hasiOSSheetCloseWindowHandler();

const handleDone = () => {
  // tell the Stripe.js iframe to close
  postIframeEvent('STRIPE_IDENTITY_CLOSE');

  // tell the iOS sheet to close
  if (hasiOSSheetCloseWindowHandler()) {
    // @ts-expect-error - TS2339 - Property 'webkit' does not exist on type 'Window & typeof globalThis'.
    window.webkit.messageHandlers.closeWindow.postMessage(null);
  }
};

// @ts-expect-error - TS7031 - Binding element 'redirecting' implicitly has an 'any' type. | TS7031 - Binding element 'isConnectIframe' implicitly has an 'any' type. | TS7031 - Binding element 'platformName' implicitly has an 'any' type.
const renderPageHeader = ({redirecting, isConnectIframe, platformName}) => {
  let titleIcon = <Icon icon="clock" color="green" size={20} />;
  let titleMessage = <Message {...messages.successTitle} />;

  if (redirecting || isConnectIframe) {
    // show loading state while waiting to redirect
    titleIcon = <Spinner size="large" />;
    titleMessage = (
      <Message {...messages.redirectingTitle} values={{platformName}} />
    );
  }

  return (
    <div
      className={styles.pageHeader}
      data-testid="verification-submitted-header"
    >
      {titleIcon}
      <Title className={styles.title}>{titleMessage}</Title>
    </div>
  );
};

export function SuccessPage({intl: {formatMessage}}: Props) {
  const session = useSession();
  const branding = useBranding();
  const {platformName} = branding || {};
  const {operatingMode, returnUrl, skipSuccessPage} = session || {};

  const [redirecting, setRedirecting] = React.useState<boolean>(false);

  const isConnectIframe = useConnectIframe();

  postIframeEvent('success');
  postIframeEvent('STRIPE_IDENTITY_SESSION_COMPLETE');

  const redirectToReturnUrl = React.useCallback(() => {
    // `returnUrl` could have been redacted. Use `cachedReturnUrl` instead.
    const cachedReturnUrl = returnUrl && Storage.getReturnUrl();
    if (cachedReturnUrl) {
      setRedirecting(true);
      urlRedirect(cachedReturnUrl);
    }
  }, [returnUrl]);

  React.useEffect(() => {
    if (skipSuccessPage && returnUrl && operatingMode !== 'secondary') {
      redirectToReturnUrl();
    }
  }, [operatingMode, redirectToReturnUrl, returnUrl, skipSuccessPage]);

  const setPageCardProps = React.useContext(SetPageCardPropsContext);
  React.useEffect(() => {
    setPageCardProps({
      showBackLink: false,
      showPageHeader: true,
      showProgressBar: false,
      size: 'single',
    });
  }, [setPageCardProps]);

  const renderCloseMessage = (returnUrl: string | null | undefined) => {
    if (!returnUrl && !shouldShowCloseButton()) {
      return <Message {...messages.safeToClose} />;
    }
  };

  const renderCtaButton = (
    session: GraphQlField<GetSessionQueryData, 'session'> | null | undefined,
  ) => {
    if (!session || session?.operatingMode === 'secondary') {
      // This is a secondary session. Regardless of whether there is a return URL, the user should close this and rejoin the original session.
      return null;
    }

    if (returnUrl && !isInIframe()) {
      return (
        <ThemableButton
          onClick={redirectToReturnUrl}
          color="blue"
          label={getGoBackButtonText(session)}
          width="maximized"
        />
      );
    } else if (shouldShowCloseButton()) {
      return (
        <ThemableButton
          onClick={handleDone}
          color="blue"
          label={<Message {...messages.done} />}
          width="maximized"
        />
      );
    }
    // fallback: don't show a CTA button, just let the user close the tab
    return null;
  };

  const renderContent = () => {
    // EXPERIMENTAL(weaver) -- in iframe-mode, the dashboard doesn't need to show
    // success content because the frame will be closed. There is a race condition
    // between this render and the action taken by the parent window upon receiving
    // a success message. Just show a spinner.
    if (redirecting || isConnectIframe) {
      return null;
    } else if (operatingMode === 'secondary') {
      // this is a secondary session. regardless of whether there is a returnUrl, the user should close this and rejoin the original session.
      return (
        <Body>
          <Message {...messages.successBodySecondary} />
        </Body>
      );
    } else {
      // Show successful state with button if returnUrl is available
      return (
        <Body>
          <Message {...messages.successBody} values={{platformName}} />
          <p>{renderCloseMessage(returnUrl)}</p>
        </Body>
      );
    }
  };

  return (
    <div className={styles.successPage}>
      <Helmet>
        <title>{formatMessage(messages.documentTitle)}</title>
      </Helmet>
      <div className={styles.pageContent}>
        {renderPageHeader({isConnectIframe, platformName, redirecting})}
        {renderContent()}
      </div>
      <div className={styles.pageButtons}>{renderCtaButton(session)}</div>
    </div>
  );
}

setComponentConfig(SuccessPage, {
  isTerminal: true,
  ignoreExpiredSession: true,
});

export default injectIntl(SuccessPage);
