import {IconConfig} from '../components/Icon';
import {css} from '../css';
import {tokens} from '../tokens';

export const actionButton = [
  css({
    padding: 'small',
    borderRadius: 'action',
    stack: 'x',
    alignX: 'center',
    alignY: 'center',
    transition: 'background-color 150ms ease-out',
    width: '28px',
    height: '28px',
    ':hover:not(:disabled)': {
      backgroundColor: tokens.color.neutral[25],
    },
    ':hover:active:not(:disabled)': {
      backgroundColor: tokens.color.neutral[50],
    },
  }),
  IconConfig.customize({
    defaults: {size: 'xsmall'},
  }),
];
