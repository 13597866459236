import {createCompositeProperty} from '../core';
import type {StylePlugin} from '../core';

const outsetFallback = '0 0 0 0 transparent';
const insetFallback = 'inset 0 0 0 0 transparent';

const composite = createCompositeProperty('box-shadow', {
  order: ['box-shadow', 'focus-ring'],
  merge: {
    delimiter: ',',
    defaultValue: 'none',
    reverseOrder: true,
  },
  fallback: (property: string) =>
    property.match(/\binset\b/) ? insetFallback : outsetFallback,
});

export const boxShadow: StylePlugin = {
  properties: {
    'box-shadow': composite(),
    'focus-ring': composite(),
  },
};
