import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import injectHandoffUrlParams from 'gelato/frontend/src/lib/injectHandoffUrlParams';

import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';

/**

/**
 * Makes the handoff url.
 * @param state The application state.
 * @param runtime The application runtime.
 * @returns The handoff url.
 */
export default async function makeHandoffUrl(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
): Promise<string> {
  const resp = await runtime.api.mutation.generateSecondaryHandoffUrlMutation();
  const url = resp?.data?.generateSecondaryHandoffUrl?.secondaryRedirectUrl;
  if (!url || resp.errors) {
    throw new Error(ErrorCode.failedToCreateHandoffUrl);
  }
  const locale = state.locale.currentValue[0];
  return injectHandoffUrlParams(url, {
    locale,
  });
}
