import type {CssTokensShape} from './types';

const TOKEN_NAME_REGEXP = /\$(\w+)\.([\w|/]+)/g;
const UNKNOWN_TOKEN = 'var(--unknown-token)';

export function replaceTokens<T extends string | number | void>(
  definitions: CssTokensShape,
  value: T,
): T {
  if (typeof value !== 'string' || !value.includes('$')) {
    return value;
  }
  return value.replace(
    TOKEN_NAME_REGEXP,
    (_match, tokenCategory, tokenName) => {
      const token = definitions?.[tokenCategory]?.[tokenName]?.toString();
      return token ? `var(${token})` : UNKNOWN_TOKEN;
    },
  ) as T;
}
