import {css, view, ButtonGroup} from '@sail/ui';
import * as React from 'react';
import {
  IntlShape,
  MessageDescriptor,
  defineMessages,
  injectIntl,
} from 'react-intl';

import Button from 'gelato/frontend/src/components/ButtonV2';
import EmailInput from 'gelato/frontend/src/components/IndividualV2/EmailInputV2';
import OTPPageContent from 'gelato/frontend/src/components/IndividualV2/OTPVerificationV2';
import ValidationMsg from 'gelato/frontend/src/components/IndividualV2/ValidationMsg';
import Message from 'gelato/frontend/src/components/Message';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import {p200} from 'gelato/frontend/src/components/stylesV2';
import TopNavigationBar from 'gelato/frontend/src/components/TopNavigationBar';
import {
  IndividualStateFields,
  OTPMode,
} from 'gelato/frontend/src/controllers/states/IndividualState';
import isFieldNeeded from 'gelato/frontend/src/controllers/utils/isFieldNeeded';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import Storage from 'gelato/frontend/src/lib/Storage';

const Styles = {
  container: css({
    gap: 'xsmall',
    stack: 'y',
  }),
  body: css({
    stack: 'y',
    font: 'body.small',
    gap: 'medium',
  }),
  title: css({
    font: 'heading.medium.subdued',
  }),
  description: css({
    stack: 'y',
    gap: 'medium',
  }),
};

const messages = defineMessages({
  emailHeader: {
    id: 'otp.email.header',
    description: 'Header for email verification page',
    defaultMessage: 'Verify your email',
  },
  emailInputLabel: {
    id: 'otp.email.input.label',
    description: 'Label for input box for user provided email address',
    defaultMessage: 'Email',
  },
  sendOTPButton: {
    id: 'otp.email.send.otp',
    description: 'Text on button to send OTP code in email',
    defaultMessage: 'Send',
  },
  codeResend: {
    id: 'otp.code.resend',
    description: 'Text on button to resend code',
    defaultMessage: 'Send again',
  },
  pageDescriptionEnter: {
    id: 'otp.email.description.enter',
    description:
      'Text requesting that you input an email address and explaining that we will send an OTP code to your email',
    defaultMessage:
      'Please enter your email address to receive a verification code.',
  },
  pageDescriptionConfirm: {
    id: 'otp.email.description.confirm',
    description: 'Text explaining that we will send an OTP code to your email',
    defaultMessage:
      'Confirm your email address to receive a verification code.',
  },
  decline: {
    id: 'otp.email.decline',
    description:
      'Text for link allowing user to say that they cannot verify the given email',
    defaultMessage: 'I cannot verify this email',
  },
  errorSend: {
    id: 'otp.email.error.send',
    description:
      'Error message saying that the one time password verification code could not be sent (or resent)',
    defaultMessage:
      'Error sending verification code. Check the email address entered.',
  },
});

const {useCallback, useRef, useState, useEffect} = React;

const SendButton = ({send}: {send: () => void}) => {
  const {appController} = useAppController();
  const {session, mutation, individual} = appController.state;
  const pending = mutation.validateOTP.pending || mutation.generateOTP.pending;
  const hasGeneratedOTP = mutation.generateOTP.value?.email;
  const savedMerchantEmail =
    session?.collectedData?.individual?.email?.merchantProvidedAddress;
  const savedUserEmail =
    session?.collectedData?.individual?.email?.userProvidedAddress;
  const hasEmail =
    !!savedMerchantEmail ||
    !!savedUserEmail ||
    !!individual.email?.userProvidedAddress;
  const disableButton = !session || pending || !hasEmail;

  const message = hasGeneratedOTP
    ? messages.codeResend
    : messages.sendOTPButton;

  return (
    <Button
      id="send-otp"
      data-testid="send-otp"
      disabled={disableButton}
      onPress={send}
    >
      <Message {...message} />
    </Button>
  );
};

const DeclineButton = ({intl}: {intl: IntlShape}) => {
  const {appController} = useAppController();
  const {session, mutation} = appController.state;
  const merchantEmail =
    session?.collectedData?.individual?.email?.merchantProvidedAddress;
  const canUpdateEmail = !merchantEmail && !!session;
  const {pending} = mutation.validateOTP;

  const decline = useCallback(async () => {
    await appController.setIndividualCollectedData(
      {
        emailOtp: {otpDeclined: true},
      },
      intl,
    );
    await appController.validateOTP({mode: OTPMode.email});
  }, [appController, intl]);

  if (canUpdateEmail) {
    return <></>;
  }

  return (
    <Button
      id="decline-otp"
      data-testid="decline-otp"
      type="secondary"
      onPress={decline}
      disabled={!session || pending}
    >
      <Message {...messages.decline} />
    </Button>
  );
};

const Description = () => {
  const {appController} = useAppController();
  const {session} = appController.state;
  const merchantEmail =
    session?.collectedData?.individual?.email?.merchantProvidedAddress;
  const canUpdateEmail = !merchantEmail && !!session;
  const message = canUpdateEmail
    ? messages.pageDescriptionEnter
    : messages.pageDescriptionConfirm;
  return <Message {...message} />;
};

const EmailVerificationPage = ({intl}: {intl: IntlShape}) => {
  const {appController} = useAppController();
  const {session, mutation} = appController.state;
  const {validateOTP, generateOTP} = mutation;

  const merchantEmail =
    session?.collectedData?.individual?.email?.merchantProvidedAddress;
  const userEmail =
    session?.collectedData?.individual?.email?.userProvidedAddress;
  const sessionEmail = merchantEmail || userEmail;
  const canUpdateEmail = !!session && !merchantEmail;

  const needsOTP = !!session && isFieldNeeded(appController.state, 'email_otp');
  const needsEmail =
    !!session &&
    (isFieldNeeded(appController.state, 'email') ||
      // In some cases we require email_otp but not email
      // even though email has not been collected yet
      (!sessionEmail && needsOTP));
  const submitted = !!session && !needsOTP;

  const initializeRef = useRef(false);
  const checkNeedsRedirect = useRef(false);
  const initialSendOTP = useRef(false);

  const hasGeneratedOTP = generateOTP.value?.email;
  const hasValidatedOTP = validateOTP.value?.email;
  const isLoading = !session || validateOTP.pending || hasValidatedOTP;
  const autosendOTP =
    !isLoading && needsOTP && (!canUpdateEmail || !needsEmail);
  const showEmailInputContent = !autosendOTP && !hasGeneratedOTP;
  const {error: generateOTPError} = mutation.generateOTP;
  const [errorMessage, setErrorMessage] = useState<MessageDescriptor | null>(
    null,
  );

  useEffect(() => {
    if (initializeRef.current) {
      return;
    }
    // Ensure we initialize the individual state on first load of the page
    if (session) {
      appController.initializeIndividualState();
      initializeRef.current = true;
    }
  }, [appController, session]);

  useEffect(() => {
    if (generateOTPError) {
      setErrorMessage(messages.errorSend);
    } else {
      setErrorMessage(null);
    }
  }, [generateOTPError]);

  useEffect(() => {
    // Automatically route to the next page if we don't need
    // to verify email when session is ready.
    if (submitted) {
      if (!checkNeedsRedirect.current) {
        appController.routeToNextPage({
          reason: 'email_otp_submitted',
          caller: 'EmailVerificationPage',
        });
      }
      checkNeedsRedirect.current = true;
    }
  }, [appController, submitted]);

  const send = useCallback(async () => {
    const partialData: Partial<IndividualStateFields> = {};
    let canGenerateOTP = true;
    const userProvidedEmail =
      appController.state.individual.email?.userProvidedAddress;
    if (canUpdateEmail && userProvidedEmail !== sessionEmail) {
      partialData.email = {
        userProvidedAddress:
          appController.state.individual.email?.userProvidedAddress,
      };
      const response = await appController.updateIndividual({
        intl,
        partialData,
      });
      canGenerateOTP = !!response;
    }
    if (canGenerateOTP) {
      await appController.setIndividualCollectedData({
        emailOtp: {shouldGenerateOTP: true},
      });
      await appController.generateOTP({mode: OTPMode.email});
    }
  }, [appController, canUpdateEmail, intl, sessionEmail]);

  const handleKeydown = useCallback(
    // this onKeyDown handler is just to support pressing enter to submit email
    (event) => {
      // Pressed the Enter key
      if (event.keyCode === 13) {
        send();
      }
    },
    [send],
  );

  useEffect(() => {
    // Autosend OTP at most once on load if we already have an email for the user
    if (!initialSendOTP.current && autosendOTP) {
      send();
      initialSendOTP.current = true;
    }
  }, [autosendOTP, send]);

  // Early return for loading state
  if (isLoading) {
    return <PageCard loading />;
  }

  const header = <TopNavigationBar />;
  const footer = (
    <view.div uses={[p200]}>
      <ButtonGroup direction="column">
        <SendButton send={send} />
        <DeclineButton intl={intl} />
      </ButtonGroup>
    </view.div>
  );
  let body;

  if (showEmailInputContent) {
    body = (
      <view.div uses={[Styles.container]}>
        <view.div uses={[Styles.title]}>
          <Message {...messages.emailHeader} />
        </view.div>
        <view.div uses={[Styles.body]}>
          <Description />
          <EmailInput handleKeyDown={handleKeydown} />

          <view.div css={{width: 'fill', alignX: 'center', stack: 'y'}}>
            {errorMessage && (
              <ValidationMsg>
                <Message {...errorMessage} />
              </ValidationMsg>
            )}
          </view.div>
        </view.div>
      </view.div>
    );
  } else {
    body = (
      <view.div uses={[Styles.container]}>
        <view.div uses={[Styles.body]}>
          <OTPPageContent mode={OTPMode.email} />
          <view.div css={{width: 'fill', alignX: 'center', stack: 'y'}}>
            {errorMessage && (
              <ValidationMsg>
                <Message {...errorMessage} />
              </ValidationMsg>
            )}
          </view.div>
        </view.div>
      </view.div>
    );
  }

  return <PageCard body={body} header={header} footer={footer} />;
};

export default injectIntl(EmailVerificationPage);
