import type {AriaAttributes} from 'react';
import React from 'react';

import {FormFieldMetadataContext} from '../components/FormField';
import {FormFieldGroupContext} from '../components/FormFieldGroup';
import type {ControlProps} from '../components/FormField';

type AriaAttributesForFields = Pick<
  AriaAttributes,
  'aria-describedby' | 'aria-labelledby'
>;

export type useFieldContextsType = (
  args: Partial<
    ControlProps &
      AriaAttributes & {
        invalid: boolean;
        id: HTMLElement['id'];
        disabled: HTMLInputElement['disabled'];
      }
  >,
) => {
  id?: HTMLElement['id'];
  invalid?: boolean;
  disabled?: boolean;
} & AriaAttributesForFields;

export const useFieldContexts: useFieldContextsType = (props) => {
  const {controlId, elements: field} = React.useContext(
    FormFieldMetadataContext,
  );
  const {disabled, elements: group} = React.useContext(FormFieldGroupContext);

  const describedby = [
    group.description?.present && group.description.id,
    field.description?.present && field.description.id,
    field.error?.present && field.error.id,
  ]
    .filter(Boolean)
    .join(' ')
    .trim();

  const labelledby = [
    group.legend?.present && group.legend.id,
    field.label?.present && field.label.id,
  ]
    .filter(Boolean)
    .join(' ')
    .trim();

  return {
    disabled: props.disabled ?? disabled ?? false,
    // certain fields have an `invalid` prop, and it should take precedence over whether an error message is provided
    'aria-invalid': props.invalid ?? field.error.present,
    // `invalid` is not a valid html attribute though, so it should not be passed to the DOM
    invalid: undefined,
    // let users override generated aria-* label and description props
    'aria-describedby': props['aria-describedby'] || describedby || undefined,
    'aria-labelledby': props['aria-labelledby'] || labelledby || undefined,
    id: controlId,
  };
};
