import clearConsumerDataMutation from 'gelato/frontend/src/controllers/utils/clearConsumerDataMutation';

import type {ApolloError, MutationPromiseReturn} from '@sail/data';
import type {ApplicationRuntime} from 'gelato/frontend/src/controllers/types';
import type {ClearConsumerDataMutationDocument} from 'gelato/frontend/src/graphql/mutations/useClearConsumerDataMutation';

export default async function clearConsumerData(
  runtime: ApplicationRuntime,
): Promise<MutationPromiseReturn<ClearConsumerDataMutationDocument>> {
  const resp = await clearConsumerDataMutation(runtime.apolloClient);
  const {errors, data} = resp;
  return {
    // Fix for the mistyping of errors in Apollo
    // https://github.com/apollographql/apollo-client/blob/eb2cfee1846b6271e438d1a268e187151e691db4/src/react/hooks/useMutation.ts#L165-L166
    errors: errors as unknown as ApolloError | undefined, // Deprecated
    error: errors as unknown as ApolloError | undefined,
    data,
  };
}
