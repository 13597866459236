// This defines the event params that should not be scrubbed (i.e. redacted) by
// analytics-js which by default scrubs all the custom param values.
// Please read manage/frontend/packages/analytics-js/src/core/AnalyticsReporter.ts
// to learn how the redaction work.
const AnalyticsSafeParams = {
  /* eslint sort-keys: ["error", "asc"] */
  accepted: true,
  active: true,
  after: true,
  android_major_version: true,
  arb_id: true,
  auth_response_variant: true,
  back: true,
  before: true,
  blur_score: true,
  blur_time_millis: true,
  breakpoint: true,
  button_id: true,
  camera_labels: true,
  capabilities: true,
  cause: true,
  clear_both_sides: true,
  consent_accepted: true,
  consent_declined: true,
  constraints_size: true,
  consumer_account: true,
  consumer_identity_document: true,
  content_orientation: true,
  content_size: true,
  count: true,
  coverage: true,
  creation_method: true,
  darkness_score: true,
  detection_status: true,
  detection_time: true,
  detection_time_millis: true,
  detector_model_time_millis: true,
  device: true,
  device_unsupported: true,
  devices: true,
  disposed: true,
  downlink: true,
  duration: true,
  duration_ms: true,
  eak_4: true,
  effective_type: true,
  element_orientation: true,
  element_size: true,
  email: true,
  email_otp_declined: true,
  error: true,
  error_message: true,
  error_name: true,
  error_reason: true,
  error_type: true,
  experiment: true,
  experiment_retrieved: true,
  experiments: true,
  experiments_diff: true,
  experiments_match: true,
  face_blur: true,
  face_score: true,
  faces: true,
  facing_mode: true,
  feedback: true,
  file: true,
  file_size: true,
  file_size_after: true,
  file_size_before: true,
  file_type: true,
  flags: true,
  force_back: true,
  frame_names: true,
  frame_type: true,
  front_card: true,
  front_passport: true,
  function_name: true,
  git_hash: true,
  has_local_storage: true,
  has_session_storage: true,
  has_web_rtc: true,
  height: true,
  i_os_major_version: true,
  id_inspector: true,
  iframe: true,
  image_debug_info: true,
  image_frame: true,
  image_mode: true,
  image_status: true,
  images_count: true,
  input_height: true,
  input_width: true,
  inspector_name: true,
  invalid: true,
  is_android: true,
  is_android_web_view: true,
  is_expired: true,
  is_ios: true,
  is_rn_web_view: true,
  is_ui_web_view: true,
  is_windows: true,
  is_wk_web_view: true,
  layer_name: true,
  left: true,
  livemode: true,
  local_eval_error: true,
  locale: true,
  location: true,
  mb_decoded: true,
  mb_inspector: true,
  mb_score: true,
  mb_score_time_millis: true,
  mb_support_score: true,
  mb_support_time_millis: true,
  mb_wasm_decoded: true,
  mb_wasm_time: true,
  media_devices_type: true,
  merchant_id: true,
  message: true,
  minutes: true,
  mode: true,
  model_data: true,
  no_document: true,
  operating_mode: true,
  page: true,
  page_number: true,
  pages_count: true,
  permission_mode: true,
  phone_otp_declined: true,
  pixel_count: true,
  platform_name: true,
  probability: true,
  property_name: true,
  reason: true,
  result: true,
  rtt: true,
  sanctioned_document_country: true,
  save_data: true,
  scale: true,
  selected_option: true,
  selfie_verification_method: true,
  sentry_breadcrumb: true,
  sentry_breadcrumb_data: true,
  sentry_error: true,
  side: true,
  start_page_redirected_to: true,
  state: true,
  status: true,
  step: true,
  stripe_internal: true,
  success: true,
  template_id: true,
  time_focussing: true,
  time_since_first_doc: true,
  time_since_start_detection: true,
  time_spent: true,
  time_start_loaded: true,
  time_start_loading: true,
  time_start_redirect_done: true,
  time_start_redirecting: true,
  to: true,
  top: true,
  ua_browser: true,
  ua_browser_name: true,
  ua_browser_version: true,
  ua_device_model: true,
  ua_device_type: true,
  ua_device_vendor: true,
  ua_engine_name: true,
  ua_engine_version: true,
  ua_mobile: true,
  ua_model: true,
  ua_os_name: true,
  ua_os_version: true,
  ua_platform: true,
  ua_platform_version: true,
  under_consent_age: true,
  unsupported_country: true,
  unsupported_handoff_device: true,
  unsupported_ip_country: true,
  update_time: true,
  upload_method: true,
  upload_time: true,
  url: true,
  use_case: true,
  validation_errors: true,
  variant: true,
  verification_intent: true,
  video_paused: true,
  viewport_orientation: true,
  viewport_size: true,
  visibility_state: true,
  wait_time_ms: true,
  width: true,
};

// Convert snake case to camel case
type SnakeToCamelCase<T extends string> = Uncapitalize<
  T extends `${infer A}_${infer B}`
    ? `${A}${Capitalize<SnakeToCamelCase<B>>}`
    : T
>;

// Include both the snake case and camel cased versions of the keys since
// we will convert them to camel case later
export type AnalyticsSafeParamsType =
  | keyof typeof AnalyticsSafeParams
  | SnakeToCamelCase<keyof typeof AnalyticsSafeParams>;

export default AnalyticsSafeParams;
