import {MutationPromiseReturn} from '@sail/data';

import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';
import type {SubmitMutationDocument} from 'gelato/frontend/src/graphql/mutations/useSubmitMutation';

export default function submitVerification(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
  data: {forceDelay?: boolean},
): Promise<MutationPromiseReturn<SubmitMutationDocument>> {
  return runtime.api.mutation.submitVerification({
    variables: {
      forceDelay: !!data.forceDelay,
    },
  });
}
