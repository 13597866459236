import {useApolloClient} from '@apollo/client';

import type {WatchQueryFetchPolicy} from '@apollo/client';

export default function useDefaultFetchPolicy():
  | WatchQueryFetchPolicy
  | undefined {
  const client = useApolloClient();

  return client.defaultOptions?.watchQuery?.fetchPolicy;
}
