import type {Collection, Node} from '@react-types/shared';
import {getChildNodes} from './getChildNodes';

const cache = new WeakMap<Iterable<unknown>, number>();

export function getItemCount<T>(collection: Collection<Node<T>>): number {
  const cachedCount = cache.get(collection);
  if (cachedCount != null) {
    return cachedCount;
  }

  let count = 0;
  const countItems = (items: Iterable<Node<T>>) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      if (item.type === 'section') {
        countItems(getChildNodes(item, collection));
      } else {
        count++;
      }
    }
  };

  countItems(collection);
  cache.set(collection, count);
  return count;
}
