import {warningSvg as Icon__small__warningSvg} from '../Icon__small/Icon__small--warning.js'
import {warningSvg as Icon__xsmall__warningSvg} from '../Icon__xsmall/Icon__xsmall--warning.js'

export var warning = {
  name: 'warning',
  category: 'icon',
  colorable: true,
  svg: Icon__small__warningSvg,
  alt: {
    Icon__small: Icon__small__warningSvg,
    Icon__xsmall: Icon__xsmall__warningSvg
  },
  combined: ['Icon__small', 'Icon__xsmall']
};
