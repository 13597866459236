import * as React from 'react';
import type {FocusEvent} from 'react';
import {
  provider,
  toIntent,
  createViewConfig,
  dynamic,
  assignProps,
} from '@sail/engine';
import type {View} from '@sail/engine';
import {FormFieldGroup} from './FormFieldGroup';
import type {FormFieldGroupProps, InputWrapperProps} from './FormFieldGroup';
import {CompositeFieldContext as DeprecatedCompositeFieldContext} from '../deprecated/components/CompositeFieldContext';
import {NativeTextFieldConfig} from './TextField';
import {NativeSelectConfig, SelectConfig} from './Select';
import {FormFieldConfig} from './FormField';
import {css} from '../css';
import {bleedAutoDisabled, bleedAutoUniversalSelectorDisabled} from '../utils';
import {NativeTextAreaConfig} from './TextArea';

export type CompositeFieldProps = Omit<
  FormFieldGroupProps,
  'layout' | 'legend' | 'hiddenElements'
> & {
  /**
   * Text that describes the control. Will be both visible and clickable.
   * @external
   */
  label?: React.ReactNode;
  /**
   * Mark the field as disabled
   * @external
   */
  disabled?: boolean;
  /**
   * Programmatically mark the field as invalid
   * @external
   */
  invalid?: boolean;
  /**
   * Visually hides the specified elements. The hidden elements will still be present and visible to screen readers.
   * @external
   */
  hiddenElements?: ('error' | 'description' | 'label')[];

  subviews?: View.Subviews<{
    inputs: (props: View.ViewProps<InputWrapperProps>) => JSX.Element | null;
  }>;
};

/**
 * Remove focus within styles if a select inside of the fieldset is focused.
 * If someone clicks on anything inside of the fieldset other than an
 * interactive element, move the focus to the first input, otherwise move it to a select or button.
 */

const focusComposite = toIntent([
  assignProps((props: View.IntrinsicElement<'div', {disabled?: boolean}>) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [showFocusIndicator, setShowFocusIndicator] = React.useState(
      !props.disabled,
    );

    return {
      ref,
      tabIndex: -1,
      'data-focus-visible': showFocusIndicator,
      onFocus(e: FocusEvent) {
        if (props.disabled) {
          return;
        }
        if (ref.current && e.target === ref.current) {
          const input = ref?.current?.querySelector('input');

          if (input) {
            input.focus();
          } else {
            ref.current?.querySelector<HTMLElement>('select,button')?.focus();
          }
        }
      },
      onBlur(e: FocusEvent) {
        if (props.disabled) {
          return;
        }
        const element = e.relatedTarget as HTMLElement;
        setShowFocusIndicator(element?.tagName !== 'SELECT');
      },
    };
  }),
  dynamic(
    (
      props: View.IntrinsicElement<
        'div',
        {disabled?: boolean; invalid?: boolean}
      >,
    ) => {
      return props['data-focus-visible']
        ? [
            css({
              ':focus-within': {
                focusRing: 'focus',
                keyline: 'form.focused',
              },
              '[data-invalid="true"] :focus-within&': {
                keyline: 'form.error.focused',
              },
            }),
          ]
        : [];
    },
  ),
]);

const disableBleedAuto =
  bleedAutoDisabled || bleedAutoUniversalSelectorDisabled;

export const CompositeFieldConfig = createViewConfig({
  props: {} as CompositeFieldProps,
  flattens: true,
  name: 'CompositeField',
  defaults: {
    // should match the defaults in FormFieldGroup
    size: 'medium',
  },
});

const selectStyles = {
  marginX: '-8px',
  marginY: '-4px',
};
const selectLargeStyles = {
  marginX: '-12px',
  marginY: '-8px',
};

export const CompositeField = CompositeFieldConfig.createView(
  ({label, hiddenElements, invalid, children, ...props}) => {
    const content = disableBleedAuto ? (
      <SelectConfig.Customize
        css={props.size === 'large' ? selectLargeStyles : selectStyles}
      >
        {children}
      </SelectConfig.Customize>
    ) : (
      children
    );
    return (
      <FormFieldGroup
        {...props}
        data-invalid={invalid}
        legend={label}
        hiddenElements={hiddenElements?.map((el) =>
          el === 'label' ? 'legend' : el,
        )}
      >
        {content}
      </FormFieldGroup>
    );
  },
  {
    subviews: {
      inputs: {
        css: {
          stack: 'x',
          gap: 'small',
          keyline: 'form',
          borderRadius: 'form',
          backgroundColor: 'form',
          color: 'form',
          font: 'body.small',
          transition: 'box-shadow 240ms',
          alignY: 'center',
          paddingY: 'xsmall',
          paddingX: 'small',
        },
        uses: [focusComposite],
      },
    },
    forward: {
      disabled: true,
      size: true,
      invalid: true,
    },
    variants: {
      size: {
        small: {
          subviews: {
            inputs: {
              css: {
                fontSize: '13px',
                lineHeight: '16px',
              },
            },
          },
        },
        large: {
          subviews: {
            inputs: {
              css: {
                paddingY: 'small',
                paddingX: 'space.150',
              },
            },
          },
        },
      },
      invalid: {
        true: {
          subviews: {
            inputs: {
              css: {
                keyline: 'form.error',
              },
            },
          },
        },
      },
      disabled: {
        true: {
          subviews: {
            inputs: {
              css: {
                color: 'form.disabled',
                backgroundColor: 'form.disabled',
              },
            },
          },
        },
      },
    },
    uses: [
      FormFieldConfig.customize({
        defaults: {isInCompositeField: true},
      }),
      // this provides backwards compatibility with older inputs
      // new inputs leverage Context.customize
      provider(DeprecatedCompositeFieldContext, {isInCompositeField: true}),
      // resize native inputs to fit inside the composite field
      // and remove borders and box shadows
      dynamic(({size}) => {
        const intents = [
          NativeSelectConfig.customize({
            css: {
              // This unsets the minWidth: 0 that's used to prevent
              // grid width blow-out in Select.
              minWidth: 'initial',
              width: 'auto',
              ':not(:focus)': {
                boxShadow: 'none',
              },
              ':hover:not(:disabled)': {
                boxShadow: 'none',
              },
            },
            defaults: {size},
          }),
          NativeTextFieldConfig.customize({
            css: {
              boxShadow: 'none',
              padding: 'space.0',
              borderRadius: 'none',
            },
            defaults: {size},
          }),
          NativeTextAreaConfig.customize({defaults: {size}}),
        ];

        return disableBleedAuto
          ? intents
          : [
              SelectConfig.customize({
                css: {
                  bleed: 'auto',
                },
              }),
              ...intents,
            ];
      }),
      // if the composite field has an error, make sure the input is marked as invalid
      assignProps(({error}) => {
        return error ? {invalid: true} : {};
      }),
    ],
  },
);
