import {historyReplaceState, urlRedirect} from '@stripe-internal/safe-links';
import * as React from 'react';

import PageCard from 'gelato/frontend/src/components/PageCardV2';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';

const {useEffect, useState} = React;

/**
 * The ReloadPage component manages iframe reloads for refreshing camera
 * permissions, allowing re-prompting of permission requests after reloads.
 * It aims for minimal file size for quick reloads and smooth transitions.
 * It also communicates with the iframe about upcoming reloads to update the
 * UI accordingly. This is vital for refreshed camera permissions and pre/post
 * reload UI adjustments.
 */
function ReloadPage() {
  const {appState, appController} = useAppController();
  const [isReloaded, setIsReloaded] = useState(false);
  const {session} = appState;

  useEffect(() => {
    // This hook manages iframe reloads to prevent "Flash of Unstyled Content,"
    // refresh camera permissions, and inform the iframe of reloads for UI
    // updates. It ensures seamless user experience by coordinating with the
    // iframe's parent for smooth transitions and is essential for clean
    // re-requesting of permissions in a new context after a full page reload.

    // Detects a reload token in the URL to check for a reload.
    const matches = window.location.search.match(/rt=([\d]+)/);
    const reloadToken = parseInt(String(matches?.[1]), 10);

    if (reloadToken) {
      // A reload token indicates a successful reload, crucial for refreshing
      // the camera permission state. It communicates with the parent to
      // reveal the iframe, signaling UI readiness post-reload.
      postIframeEvent('load');
      setIsReloaded(true);
    } else {
      // No reload token initiates the reload process, crucial for refreshing
      // permissions and pre-reload UI adjustments. It involves notifying the
      // iframe of the reload for UI updates, adding a reload token, and
      // executing the reload.
      postIframeEvent('reload');
      // Generates a new reload token.
      const token = String(Date.now());
      const url = `/reload?rt=${token}`;
      // Updates browser history state for correct navigation post-reload.
      historyReplaceState(null, url);
      // Executes the reload for permissions refresh and UI updates.
      urlRedirect(url);
    }
  }, []);

  useEffect(() => {
    if (isReloaded && session) {
      // After reload and permission refresh, navigate to the next page.
      // This ensures the user moves to a context where permissions can
      // be re-requested, aligning with the updated UI post-reload.
      appController.routeToNextPage({
        reason: 'leave_reload',
        redirect: true,
        caller: 'ReloadPage',
      });
    }
  }, [appController, isReloaded, session]);

  return <PageCard data-testid="reload-page-card" loading />;
}

setComponentConfig(ReloadPage, {
  componentName: 'reload',
  skipWarnOnUnload: true,
});

export default ReloadPage;
