import {css, cssPluginResetStyles} from '../core';
import type {CssProperties, CssSelectorIndexer} from '../core';

type ShadowValue = CssProperties['boxShadow'];

export interface ShadowResult {
  [key: `--${string}`]: ShadowValue;
  transform?: ShadowValue;
}

export interface ShadowPlugin {
  (value: ShadowValue, selector: CssSelectorIndexer): ShadowResult;
}

const fallback = '0 0 0 0 transparent';

// Earlier shadows appear atop later shadows
const orderedShadowVars =
  'var(--top-shadow), var(--keyline) 0 0 0 var(--keyline-width), var(--focus-ring), var(--box-shadow)';

function createShadowPlugin(customProperty: string): ShadowPlugin {
  return function shadowPlugin(value, selector) {
    return {
      ...cssPluginResetStyles(selector, {
        // We need to reset the css vars to ensure that vars set on the main element don't cascade down
        '--focus-ring': css.value(fallback),
        '--box-shadow': css.value(fallback),
        '--top-shadow': css.value(fallback),
        '--keyline': css.value('transparent'),
        '--keyline-width': css.value('1px'),
        boxShadow: css.value(orderedShadowVars),
      }),
      [customProperty]: value,
    };
  };
}

export const pluginsBoxShadow = {
  boxShadow: createShadowPlugin('--box-shadow'),
  topShadow: createShadowPlugin('--top-shadow'),
  focusRing: createShadowPlugin('--focus-ring'),
  keyline: createShadowPlugin('--keyline'),
  keylineWidth(val: CssProperties['borderWidth']): {
    [key: `--${string}`]: CssProperties['borderWidth'];
  } {
    return {
      '--keyline-width': val,
    };
  },
};
