import {addSvg as Icon__small__addSvg} from '../Icon__small/Icon__small--add.js'
import {addSvg as Icon__xsmall__addSvg} from '../Icon__xsmall/Icon__xsmall--add.js'
import {addSvg as Icon__xxsmall__addSvg} from '../Icon__xxsmall/Icon__xxsmall--add.js'

export var add = {
  name: 'add',
  category: 'icon',
  colorable: true,
  svg: Icon__small__addSvg,
  alt: {
    Icon__small: Icon__small__addSvg,
    Icon__xsmall: Icon__xsmall__addSvg,
    Icon__xxsmall: Icon__xxsmall__addSvg
  },
  combined: ['Icon__small', 'Icon__xsmall', 'Icon__xxsmall']
};
