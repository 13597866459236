import {windowOpen} from '@stripe-internal/safe-links';
import copy from 'copy-to-clipboard';
import * as React from 'react';

import {getConfigValue} from 'gelato/frontend/src/lib/config';
import {
  useExperiments,
  useFeatureFlags,
  useSession,
} from 'gelato/frontend/src/lib/hooks';
import Button from 'sail/Button';
import ContentList, {ContentListItem} from 'sail/ContentList';
import {Dialog, DialogContent, DialogHeader} from 'sail/Dialog';
import List, {ListItem} from 'sail/List';
import ModalLayer from 'sail/ModalLayer';

type Props = {
  shown: boolean;
  handleClose: () => void;
};

const DevTools = ({shown, handleClose}: Props) => {
  const session = useSession();
  const flags = useFeatureFlags();
  const experiments = useExperiments();

  const handleOpenObjectInAdmin = React.useCallback(
    (objectId: string): void => {
      let hostname = 'admin.corp.stripe.com';
      if (window.location.hostname.indexOf('stripe.me') > -1) {
        hostname = window.location.hostname.replace('idprodui', 'admin');
      } else if (
        window.location.hostname.indexOf('qa') > -1 ||
        window.location.hostname.indexOf('localhost') > -1
      ) {
        hostname = 'admin.qa.corp.stripe.com';
      }
      windowOpen(`https://${hostname}/objects/${objectId}`, '_blank');
    },
    [],
  );

  const handleOpenAmpFlagsPage = React.useCallback((): void => {
    let hostname = 'https://amp.corp.stripe.com/';
    if (window.location.hostname.indexOf('stripe.me') > -1) {
      hostname = window.location.hostname.replace('idprodui', 'amp');
    } else if (
      window.location.hostname.indexOf('qa') ||
      window.location.hostname.indexOf('localhost') > -1
    ) {
      hostname = 'amp.qa.corp.stripe.com';
    }
    windowOpen(`https://${hostname}/feature-flags?query=gelato`, '_blank');
  }, []);

  const handleOpenRoute = React.useCallback((route: string): void => {
    windowOpen(`${window.location.origin}/${route}`, '_blank');
  }, []);

  return (
    <ModalLayer shown={shown} onWashClick={handleClose} onEscape={handleClose}>
      <Dialog width="large">
        <DialogHeader>Gelato Developer Tools</DialogHeader>
        <DialogContent>
          <ContentList>
            <ContentListItem
              background="offset"
              title="Git Commit"
              description={getConfigValue('COMMIT_HASH')}
              end={
                <Button
                  icon="clipboard"
                  label="Copy to clipboard"
                  size="small"
                  onClick={() => copy(getConfigValue('COMMIT_HASH'))}
                />
              }
            />
            <ContentListItem
              title="Session ID"
              description={session ? session.id : ''}
              end={
                <Button
                  icon="review"
                  label="View in Admin"
                  size="small"
                  onClick={() => {
                    if (session) {
                      handleOpenObjectInAdmin(session.id);
                    }
                  }}
                />
              }
            />
            {flags && (
              <ContentListItem
                background="offset"
                title="Active feature flags"
                description={
                  <List>
                    {flags.map((flags) => (
                      <ListItem key={flags}>{flags}</ListItem>
                    ))}
                  </List>
                }
                end={
                  <Button
                    icon="edit"
                    label="Make changes"
                    size="small"
                    onClick={() => {
                      handleOpenAmpFlagsPage();
                    }}
                  />
                }
              />
            )}
            {experiments && (
              <ContentListItem
                title="Active experiment variants"
                description={
                  <List>
                    {experiments.map(({name, variant}) => (
                      <ListItem key={name}>
                        <b>{name}:</b> {variant}
                      </ListItem>
                    ))}
                  </List>
                }
              />
            )}
            <ContentListItem
              background="offset"
              title="Document Model Debug"
              end={
                <Button
                  icon="export"
                  label="Go to Document Model Debug"
                  size="small"
                  onClick={() => {
                    handleOpenRoute('model-debug');
                  }}
                />
              }
            />
            <ContentListItem
              title="AutoCapture Debug"
              end={
                <Button
                  icon="export"
                  label="Go to AutoCapture Debug"
                  size="small"
                  onClick={() => {
                    handleOpenRoute('autocapture-debug');
                  }}
                />
              }
            />
            <ContentListItem
              background="offset"
              title="Face Model Debug"
              end={
                <Button
                  icon="export"
                  label="Go to Face Model Debug"
                  size="small"
                  onClick={() => {
                    handleOpenRoute('face-debug');
                  }}
                />
              }
            />
          </ContentList>
        </DialogContent>
      </Dialog>
    </ModalLayer>
  );
};

export default DevTools;
