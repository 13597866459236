import clsx from 'clsx';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {injectIntl} from 'react-intl';

import {ConsentDevTools} from 'gelato/frontend/src/components/ConsentDevTools';
import {SkippableDataCollectionPage} from 'gelato/frontend/src/components/DynamicForm/SkippablePage';
import FeatureFlag from 'gelato/frontend/src/components/FeatureFlag';
import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import Message from 'gelato/frontend/src/components/Message';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import useUpdateConsentMutation from 'gelato/frontend/src/graphql/mutations/useUpdateConsentMutation';
import analytics from 'gelato/frontend/src/lib/analytics';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {getConfigValue} from 'gelato/frontend/src/lib/config';
import {renderInformedConsentSection} from 'gelato/frontend/src/lib/consent_utils';
import {PAGE_TITLE_MESSAGE} from 'gelato/frontend/src/lib/constants';
import {SetPageCardPropsContext} from 'gelato/frontend/src/lib/contexts';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {
  useRouter,
  useSession,
  useReturnUrl,
  useBranding,
  useConnectIframe,
} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import Storage from 'gelato/frontend/src/lib/Storage';
import Box from 'sail/Box';
import {Button, ButtonLink} from 'sail/Button';
import {Title} from 'sail/Text';

import welcomePageMessages from './messages/welcome_consent_page';
import styles from './welcome_consent_page.module.css';

import type {
  IndividualFields,
  ConsentImageMode,
} from '@stripe-internal/data-gelato/schema/types';
import type {IntlProps} from 'gelato/frontend/src/components/Message';
import type {Session} from 'gelato/frontend/src/lib/contexts';
import type {PageProps} from 'gelato/frontend/src/lib/localRouter';
import type {IntlShape} from 'react-intl';

const PAGE_FIELDS = ['consent'] as Array<IndividualFields>;
type Props = PageProps &
  IntlProps & {
    updateConsent: ReturnType<typeof useUpdateConsentMutation>[0];
    session: Session;
  };

const {useCallback, useMemo, useContext, useEffect, useState} = React;

const WelcomeConsentPage = (props: Props) => {
  // This flow error is related to Props being inexact. See
  // https://paper.dropbox.com/doc/QInexact-intersections-for-Props-type-in-gelato-code--BSwX983SvAYOvTP0uwWZQNAFAg-2URpkQMjoskFNwWgnSrqs for more info
  const {updateConsent, session} = props;
  const {consentConfig} = session;

  const sessionReturnUrl = useReturnUrl();
  const {platformName} = useBranding() || {};
  const [configShown, setConfigShown] = useState(false);
  const [isInternalOverride, setIsInternalOverride] = useState<any>(null);
  const [useCaseOverride, setUseCaseOverride] = useState<any>(null);
  const [imageModeOverride, setImageModeOverride] = useState<any>(null);
  const [privacyPolicyOverride, setPrivacyPolicyOverride] = useState<any>(null);
  const [isConnectIframeOverride, setIsConnectIframeOverride] = useState(false);
  const [returnUrlOverride, setReturnUrlOverride] = useState<any>(null);
  const router = useRouter();
  const isConnectIframe = useConnectIframe();

  const privacyPolicyUrl =
    privacyPolicyOverride === null
      ? consentConfig.privacyPolicyUrl
      : privacyPolicyOverride;
  const returnUrl =
    returnUrlOverride === null ? sessionReturnUrl : returnUrlOverride;

  const useCase = useCaseOverride || consentConfig.useCase;
  const isInternal =
    isInternalOverride === null
      ? consentConfig.stripeInternal
      : isInternalOverride;
  const imageMode = imageModeOverride || consentConfig.imageMode;
  const setPageCardProps = useContext(SetPageCardPropsContext);
  const consentPage = 'welcome';

  const hideDeclineButton =
    // This is stripe hosted onboarding collecting a document for KYC
    (isInternal && useCase === 'kyc' && imageMode === 'identity_document') ||
    // This is a connect onboarding scenario where users have a button to skip or cancel
    // outside the context of gelato that stands in for decline.
    useCase === 'additional_verification';

  const hideHandoffButton = session.operatingMode !== 'primary';

  const settings = Storage.getIdentitySettings();
  const hasReusableNetworkedIdentity =
    !!settings?.networked_identity_reuse_available;

  const consentDevButton = useMemo(
    () => (
      <FeatureFlag flag="gelato_show_dev_tools">
        <Button
          className={styles.welcomeConsentDevButton}
          // @ts-expect-error - TS2769 - No overload matches this call.
          padding={8}
          label="Consent DevTools"
          onClick={() => {
            if (configShown) {
              setConfigShown(false);
              setIsInternalOverride(null);
              setIsConnectIframeOverride(false);
              setUseCaseOverride(null);
              setImageModeOverride(null);
              setPrivacyPolicyOverride(null);
              setReturnUrlOverride(null);
            } else {
              setIsInternalOverride(true);
              setUseCaseOverride('kyc');
              setImageModeOverride('identity_document');
              setPrivacyPolicyOverride('https://www.example.com/privacy');
              setReturnUrlOverride('https://www.example.com/return');
              setConfigShown(true);
              setIsConnectIframeOverride(true);
            }
          }}
        />
      </FeatureFlag>
    ),
    [configShown],
  );

  useEffect(() => {
    setPageCardProps({
      showBackLink: false,
      showPageHeader: true,
      showProgressBar: false,
      size: 'single',
      title: undefined,
    });
  }, [setPageCardProps]);

  // Note: 2021-09-30, this is part of a new consent flow where
  // we are just asking "consent to be verified, Yes or No?". At a later start in the user flow
  // we ask for consent to improve ML/services
  //
  // This is different than how it has been done in `image_consent.js`
  const setConsent = useCallback(
    async (accepted: boolean, nextPath?: string | null) => {
      analytics.track('welcomeConsentPage', {
        accepted,
        useCase,
        imageMode,
        stripeInternal: isInternal,
      });

      const {data} = await updateConsent({
        variables: {
          consentData: {
            accepted,
            useCase,
            imageMode,
            stripeInternal: isInternal,
            gitHash: getConfigValue('COMMIT_HASH'),
          },
        },
      });

      if (data) {
        if (accepted) {
          if (nextPath) {
            router.push({pathname: nextPath});
          } else if (hasReusableNetworkedIdentity) {
            router.push({pathname: '/link'});
          } else {
            const {missingFields, requiredFields} = data.updateConsent.session;
            const nextPage = await nextDataPageForSession({
              missingFields,
              requiredFields,
              rLCapture: session.rLCapture,
            });
            router.push(nextPage);
          }
        } else {
          router.push('/invalid');
        }
      }
    },
    [
      imageMode,
      isInternal,
      router,
      updateConsent,
      useCase,
      hasReusableNetworkedIdentity,
      session.rLCapture,
    ],
  );

  // ////////////////////////////////////////
  // Build text for the page
  // /////////////////////////////////
  const title = welcomePageMessages.documentTitle;
  const textForPrompt =
    imageMode === 'identity_document'
      ? welcomePageMessages.idDocVerification
      : welcomePageMessages.idDocAndSelfieVerification;

  const informedSection = useMemo(
    () =>
      renderInformedConsentSection({
        consentPage,
        isInternal,
        useCase,
        imageMode,
        privacyPolicyUrl,
        platformName,
      }),
    [
      consentPage,
      isInternal,
      useCase,
      imageMode,
      privacyPolicyUrl,
      platformName,
    ],
  );

  const renderHandoffButton = () => {
    if (hideHandoffButton) {
      return null;
    }

    // Always render as a button inside the connect iframe. Otherwise,
    // render as a button if the decline button is visible so we don't
    // have stacked links.
    else if (!hideDeclineButton || isConnectIframe) {
      return (
        <ThemableButton
          data-testid="verify-handoff"
          label={<Message {...welcomePageMessages.deviceHandoff} />}
          size="large"
          width="maximized"
          // set consent, and navigate to device handoff page
          onClick={() => setConsent(true, '/handoff')}
        />
      );
    }

    // This is not the connect iframe and the decline link is hidden, so
    // render this as a link instead of a button.
    else {
      return (
        <Box
          background="white"
          flex={{
            justifyContent: 'center',
          }}
        >
          <ButtonLink
            data-testid="verify-handoff"
            label={<Message {...welcomePageMessages.deviceHandoff} />}
            size="large"
            width="maximized"
            // set consent, and navigate to device handoff page
            onClick={() => setConsent(true, '/handoff')}
          />
        </Box>
      );
    }
  };

  // //////////////////////////
  // Buttons
  // //////////////////////////
  type ButtonGroupProps = {
    imageMode: ConsentImageMode;
    platformName: string;
    returnUrl: string | null | undefined;
    setConsent: any;
    useCase: string | null | undefined;
  };

  // In a few cases we may not want users to have access to the Decline button.
  // For example, it could be that the Gelato flow is embedded in an iframe, in which you can skip entering information at all
  // (e.g. connect onboarding)
  const buttonGroupFromUserBucket = ({
    imageMode,
    platformName,
    returnUrl,
    setConsent,
    useCase,
  }: ButtonGroupProps) => {
    return (
      <div className={styles.buttonContainer}>
        <ThemableButton
          data-testid="verify-start"
          label={<Message {...welcomePageMessages.startButton} />}
          size="large"
          color="blue"
          width="maximized"
          // Let dataRouting figure out where to take us next
          onClick={() => setConsent(true)}
        />
        {renderHandoffButton()}
        {
          // We don't show this if we are in connect iframe since Users can
          // skip past this step outside the iframe
          !hideDeclineButton && (
            <Box
              background="white"
              padding={12}
              flex={{
                justifyContent: 'center',
              }}
            >
              <ButtonLink
                id="decline-and-exit"
                size="large"
                width="maximized"
                label={<Message {...welcomePageMessages.declineButton} />}
                onClick={() => setConsent(false)}
              />
            </Box>
          )
        }
      </div>
    );
  };

  // //////////////////////////
  // Assemble Final Page
  // //////////////////////////
  return (
    <div className={styles.welcomeConsent}>
      <div>
        <Title className={clsx(styles.title)}>
          <Message {...title} />
        </Title>
        <p>
          <Message {...welcomePageMessages.intro} />
        </p>
        <p>
          <Message {...textForPrompt} />
        </p>
        <p className={styles.informed}>{informedSection}</p>
      </div>

      <div className={styles.actions}>
        {buttonGroupFromUserBucket({
          imageMode,
          platformName,
          returnUrl,
          setConsent,
          useCase,
        })}
      </div>
      {consentDevButton}
      {configShown && (
        <FeatureFlag flag="gelato_show_dev_tools">
          <ConsentDevTools
            isInternal={isInternal}
            isConnectIframeOverride={isConnectIframeOverride}
            useCase={useCase}
            imageMode={imageMode}
            privacyPolicy={privacyPolicyOverride}
            returnUrl={returnUrlOverride}
            setIsInternalOverride={setIsInternalOverride}
            setUseCaseOverride={setUseCaseOverride}
            setImageModeOverride={setImageModeOverride}
            setPrivacyPolicyOverride={setPrivacyPolicyOverride}
            setReturnUrlOverride={setReturnUrlOverride}
            setIsConnectIframeOverride={setIsConnectIframeOverride}
          />
        </FeatureFlag>
      )}
    </div>
  );
};

const WelcomeConsentPageWithIntl = injectIntl(
  WelcomeConsentPage as React.ComponentType<Props>,
);

export function WelcomeConsent({
  intl: {formatMessage},
  ...props
}: PageProps & {
  intl: IntlShape;
}) {
  const session = useSession();
  const [updateConsent] = useUpdateConsentMutation();

  useEffect(() => {
    analytics.track('startPageRedirectDone', {
      timeStartRedirectDone: Date.now(),
      startPageRedirectedTo: '/welcome_consent',
    });
  });

  if (!session) {
    return <LoadingBox />;
  }

  postIframeEvent('load');

  return (
    <SkippableDataCollectionPage session={session} pageFields={PAGE_FIELDS}>
      <Helmet>
        <title>{formatMessage(PAGE_TITLE_MESSAGE)}</title>
      </Helmet>
      <WelcomeConsentPageWithIntl
        {...props}
        session={session}
        updateConsent={updateConsent}
      />
    </SkippableDataCollectionPage>
  );
}

setComponentConfig(WelcomeConsent, {componentName: 'welcome'});

export default injectIntl(WelcomeConsent);
