import * as React from 'react';
import {themeGlobals, useTokenContext} from '@sail/engine/deprecated';
import {createViewConfig, dynamic, usePortalTokenStyles} from '@sail/engine';
import type {View} from '@sail/engine';
import {view} from '../../deprecated/view';

type InteropProps = Omit<View.IntrinsicElement<'div'>, 'inherits'> & {
  /**
   * Supports intent types otherwise unsupported by [createView](https://sail.stripe.me/beta/apis/createview/), such as:
   * - `ThemedIntent`
   * - `SerizalizedStyles`
   * - `Partial<MergedProps>`
   */
  legacyIntents?: React.ComponentProps<typeof view.div>['uses'];
};

export const InteropConfig = createViewConfig({
  context: false,
  element: true,
  props: {} as InteropProps,
});

/**
 * This component reapplies design tokens in both the former and refreshed token systems for use
 * within a React portal.
 *
 * This enables layer interoperability with components that use tokens from either system.
 */
export const LayerInterop = InteropConfig.createView(
  ({legacyIntents = [], ...props}: InteropProps) => {
    const tokens = useTokenContext();
    return (
      <view.div
        {...props}
        uses={[...legacyIntents, themeGlobals, tokens.styles]}
      />
    );
  },
  {
    uses: dynamic(() => [usePortalTokenStyles()]),
  },
);
