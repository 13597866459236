const radius = 'radius' as const;

export const aliasRadiusBuiltIns = {
  borderRadius: radius,
  borderTopLeftRadius: radius,
  borderTopRightRadius: radius,
  borderBottomRightRadius: radius,
  borderBottomLeftRadius: radius,
} as const;

export const aliasRadius = {
  ...aliasRadiusBuiltIns,
} as const;
