import React from 'react';
import {Helmet} from 'react-helmet';

import {getConfigValue} from 'gelato/frontend/src/lib/config';

const DEFAULT_TITLE = 'Identity Verification | Stripe';
const DEFAULT_DESCRIPTION =
  'Stripe helps you securely verify your identity online';
const DEFAULT_OG_URL = '';
const DEFAULT_OG_IMAGE = '';

const Head = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  url = DEFAULT_OG_URL,
  ogImage = DEFAULT_OG_IMAGE,
}: any) => {
  const prefix = getConfigValue('ASSETS_CDN_PREFIX');
  const usePuppeteerCSS = process.env.PUPPETEER && !process.env.BROWSERSTACK;

  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <link rel="stylesheet" href={`${prefix}/assets/sail.css`} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${prefix}/assets/apple-touch-icon.png`}
      />
      <link rel="icon" href={`${prefix}/assets/favicon.ico`} />
      {usePuppeteerCSS ? (
        <link rel="stylesheet" href={`${prefix}/assets/puppeteer.css`} />
      ) : null}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:site" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={ogImage} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta
        httpEquiv="Cache-Control"
        content="no-cache, no-store, must-revalidate"
      />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />
    </Helmet>
  );
};

export default Head;
