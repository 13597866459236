import * as React from 'react';
import {
  useDebounce,
  createViewConfig,
  dynamic,
  unprocessedCss,
} from '@sail/engine';
import {Action} from './Action';
import {Spinner, SpinnerConfig} from './Spinner';
import type {ActionProps, Href} from './Action';
import {css} from '../css';
import {tokens} from '../tokens';
import {IconConfig} from './Icon';

export type ButtonProps = {
  /**
   * The size of the button
   * @external
   */
  size: 'small' | 'medium' | 'large';

  /**
   * The type of the button
   * @external
   */
  type: 'primary' | 'secondary' | 'destructive';

  /**
   * Allows the button to submit or reset a form
   * @external
   */
  behavior?: 'submit' | 'reset';

  /**
   * Sets the visual style to pending and functionally disables the button
   * @external
   */
  pending?: boolean;

  /**
   * The id of the form element to associate the button with (if it's not an ancestor of the button)
   */
  form?: string;

  /**
   * Content concisely describing the button's functionality.
   */
  children?: React.ReactNode;

  href?: Href;
} & Omit<ActionProps, 'children'>;

const buttonSizeToIconSizeMap = {
  small: 'xsmall' as const,
  medium: 'xsmall' as const,
  large: 'small' as const,
};

const pendingSpinnerCss = {
  position: 'absolute',
  top: `calc(50% - ${tokens.space.small})`,
} as const;

/**
 * @param autofocus
 * @param className
 * @param disabled
 * @param form
 * @param name
 * @param onPress
 * @param type
 * @param value
 * @param target
 */
export const ButtonConfig = createViewConfig({
  name: 'Button',
  props: {} as ButtonProps,
  flattens: true,
  defaults: {
    size: 'medium',
    type: 'secondary',
  },
});

/**
 * Buttons let users take action.
 */
export const Button = ButtonConfig.createView(
  ({behavior, form, pending, type, ...props}) => {
    // `pending` is *visually* debounced in order to avoid overly-quick flashes
    // of visual styles, but the disabled behvior is immediately applied
    const [isVisuallyPending] = useDebounce(pending, 100);

    const resultingProps = {
      ...props,
      children:
        // We don't want to debounce turning off the spinner, so it needs both to be true
        isVisuallyPending && pending ? (
          <>
            {props.children}
            <Spinner />
          </>
        ) : (
          props.children
        ),
      onPress: pending ? undefined : props.onPress,
      behavior: pending ? undefined : behavior,
      ...(pending ? {'aria-disabled': true} : {}),
    };

    return (
      <Action
        {...resultingProps}
        {...(isVisuallyPending
          ? {'data-state-isvisuallypending': 'true'}
          : undefined)}
        {...(behavior
          ? {
              onPress: (e) => {
                const button = Object.assign(document.createElement('button'), {
                  type: behavior,
                  hidden: true,
                  'aria-hidden': 'true',
                });
                form && button.setAttribute('form', form);
                e.target.parentNode?.appendChild(button);
                button.click();
                button.remove();
              },
            }
          : {})}
      />
    );
  },
  {
    uses: [
      // TODO(koop): investigate if we can remove ButtonProps (used to avoid size being inferred as optional)
      dynamic(({size, type, pending}: ButtonProps) => [
        IconConfig.customize({
          defaults: {size: buttonSizeToIconSizeMap[size]},
        }),
        SpinnerConfig.customize({
          css: {
            ...(pending ? pendingSpinnerCss : {}),
            fill: type === 'secondary' ? 'subdued' : 'onAction.primary',
          },
        }),
      ]),
    ],

    css: {
      stack: 'x',
      display: 'inline',
      alignX: 'center',
      alignY: 'center',
      whiteSpace: 'nowrap',
      fontWeight: 'semibold',
      position: 'relative',
      zIndex: 0,
      borderRadius: 'action',
      transitionProperty: 'background-color, box-shadow',
      transitionDuration: 'medium',
      transitionTimingFunction: 'easing',
      height: 'fit',
      width: 'fit',
      ':hover': {
        transitionDuration: 'instant',
      },
      ':focus': {
        focusRing: 'focus',
      },
    },

    forward: {
      disabled: true,
      pending: true,
      type: true,
    },

    variants: {
      disabled: {
        true: css({
          cursor: 'default',
          '::after': {
            display: 'block',
            content: '""',
            position: 'absolute',
            top: '-1px',
            left: '-1px',
            bottom: '-1px',
            right: '-1px',
            borderRadius: 'action',
            zIndex: 1,
            backgroundColor: 'surface',
            pointerEvents: 'none',
            opacity: 0.5,
            margin: 'space.0',
          },
        }),
      },

      size: {
        small: css({
          font: 'label.small.emphasized',
          minHeight: 'large',
          paddingX: 'small',
          paddingY: 'xsmall',
          gap: 'xsmall',
        }),
        medium: css({
          font: 'label.medium.emphasized',
          minHeight: 'space.350',
          paddingX: 'small',
          paddingY: 'xsmall',
          gap: 'space.75',
        }),
        large: css({
          font: 'label.large.emphasized',
          minHeight: 'space.500',
          paddingX: 'medium',
          paddingY: 'small',
          gap: 'small',
        }),
      },

      type: {
        primary: css({
          color: 'onAction.primary',
          keyline: 'action.primary',
          topShadow: 'action.primary',
          ':hover:not(:active):not([aria-disabled="true"])': {
            backgroundColor: 'action.primary.hovered',
            keyline: 'action.primary.hovered',
            topShadow: 'action.primary.hovered',
            color: 'onAction.primary.hovered',
          },
          ':active:not([aria-disabled="true"])': {
            topShadow: 'action.primary.pressed',
            backgroundColor: 'action.primary.pressed',
            keyline: 'action.primary.pressed',
            color: 'onAction.primary.pressed',
          },
        }),
        secondary: css({
          color: 'onAction.secondary',
          keyline: 'action.secondary',
          topShadow: 'action.secondary',
          ':hover:not(:active):not([aria-disabled="true"])': {
            backgroundColor: 'action.secondary.hovered',
            keyline: 'action.secondary.hovered',
            topShadow: 'action.secondary.hovered',
            color: 'onAction.secondary.hovered',
          },
          ':active:not([aria-disabled="true"])': {
            topShadow: 'action.secondary.pressed',
            backgroundColor: 'action.secondary.pressed',
            color: 'onAction.secondary.pressed',
            keyline: 'action.secondary.pressed',
          },
        }),
        destructive: css({
          color: 'onAction.destructive',
          keyline: 'action.destructive',
          topShadow: 'action.destructive',
          ':hover:not(:active):not([aria-disabled="true"])': {
            backgroundColor: 'action.destructive.hovered',
            keyline: 'action.destructive.hovered',
            topShadow: 'action.destructive.hovered',
            color: 'onAction.destructive.hovered',
          },
          ':active:not([aria-disabled="true"])': {
            topShadow: 'action.destructive.pressed',
            backgroundColor: 'action.destructive.pressed',
            keyline: 'action.destructive.pressed',
            color: 'onAction.destructive.pressed',
          },
        }),
      },

      pending: {
        // TODO(alex): Replace once we have state-based variants
        true: unprocessedCss.global({
          '[data-state-isvisuallypending="true"]': {
            color: 'rgba(0,0,0,0) !important',
          },
          '[data-state-isvisuallypending="true"] > ': {
            opacity: '0 !important',
          },
          '[data-state-isvisuallypending="true"] > :last-child': {
            opacity: '1 !important',
          },
        }),
      },
    },
  },
);
