/* eslint-disable no-continue */
import {createContext} from 'react';
import {DEFAULT_PREFIX} from './constants';
import {hash, isServer} from '../../../util';
import type {
  CreateVariants,
  CssVariantAssignment,
  CssVariantAssignmentShape,
  CssVariants,
  CssVariantsShape,
} from './types';
import {setDefaults} from '../../view/setDefaults';
import {onComponentWillReceiveProps} from '../../view/transformProps';
import {provider} from '../../../intent';

const $id = Symbol('id');

export function getVariantClassName(
  key: string,
  value: string,
  id: string,
): string {
  return `${id}-${key}-${value}`;
}

function getVariantSelector(
  key: string,
  value: string,
  id: string,
  definitionId = id,
  cascading = false,
): `&${string}` | `@nest ${string}` {
  const className = getVariantClassName(key, value, definitionId);
  return cascading ? `@nest .${id}.${className} &` : `&.${id}.${className}`;
}

const deprecatedIdMap = new WeakMap();

function getIdForLastProperty(
  extend: CssVariants<unknown, unknown>[],
  property: string,
): string {
  for (let j = extend.length - 1; j >= 0; j--) {
    const extendVariants = extend[j];
    if (property in extendVariants) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (extendVariants as any)[property][$id];
    }
  }
  return '';
}

const isBoolean = (input: string): boolean => {
  return input === 'true' || input === 'false';
};

const getDefaultPropsFromVariants = (variantsMap: CssVariantsShape) => {
  const defaults: Record<string, unknown> = {};
  const properties = Object.keys(variantsMap);
  for (let i = 0; i < properties.length; i++) {
    const property = properties[i];

    const variants = Object.keys(variantsMap[property]);
    for (let j = 0; j < variants.length; j++) {
      const variant = variants[j];
      const fallback = variantsMap[property][variant];

      if (fallback === true) {
        if (!isNaN(Number(variant))) {
          defaults[property] = Number(variant);
        } else if (isBoolean(variant)) {
          defaults[property] = variant === 'true';
        } else {
          defaults[property] = variant;
        }
      }
    }
  }
  return defaults;
};
// @ts-expect-error - TS2322: Type '<T, V>(salt: string, input: T, { cascading, extend, prefix }?: CssVariantsOptions<V extends CssVariants<any, any>[] ? V : []> | undefined) => CssVariants<any, any>' is not assignable to type 'CreateVariants'.
export const createVariants: CreateVariants = function createVariants(
  salt,
  input,
  {cascading = false, extend, prefix = DEFAULT_PREFIX} = {},
) {
  const extendIds = extend ? extend.map(({id}) => id).join('') : '';
  const id = `${prefix}${hash(salt + JSON.stringify(input) + extendIds)}`;

  const variantsMap = input as unknown as CssVariantsShape;
  const properties = Object.keys(variantsMap);
  if (extend) {
    for (let i = 0; i < extend.length; i++) {
      const extendProperties = Object.keys(extend[i]);
      for (let j = 0; j < extendProperties.length; j++) {
        const property = extendProperties[j];
        if (!properties.includes(property)) {
          properties.push(property);
        }
      }
    }
  }
  const VariantContext = createContext<unknown>({});
  const defaults = getDefaultPropsFromVariants(variantsMap);

  const result = {} as unknown as CssVariantsShape<string>;

  for (let i = 0; i < properties.length; i++) {
    const property = properties[i];
    const fallbacks = variantsMap[property];
    let definitionId = fallbacks ? id : '';
    if (!definitionId && extend) {
      definitionId = getIdForLastProperty(extend, property);
    }

    result[property] = new Proxy({} as Record<string, string>, {
      get(target, key: string) {
        if ((key as unknown as symbol) === $id) {
          return definitionId;
        }
        if (!(key in target) && typeof key !== 'symbol') {
          target[key] = getVariantSelector(
            property,
            key,
            id,
            definitionId,
            cascading,
          );
        }
        return target[key];
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const record = result as unknown as CssVariants<any, any>;
  deprecatedIdMap.set(record, id);

  Object.defineProperty(record, 'assign', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: function assignVariants(
      input: CssVariantAssignment<unknown> = {} as CssVariantAssignment<unknown>,
    ) {
      const setMap = input as unknown as CssVariantAssignmentShape;
      let classes = id;
      function add(className: string) {
        if (className) {
          classes += ` ${className}`;
        }
      }

      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const values = setMap[property];
        if (property in setMap) {
          if (Array.isArray(values)) {
            values.forEach((v) => {
              add(getVariantClassName(property, v, id));
            });
            continue;
          } else if (values != null) {
            const variant = values.toString();
            const variants = variantsMap[property];
            if (variants && variant in variants) {
              add(getVariantClassName(property, variant, id));
            }
            continue;
          }
        }

        const variants = Object.keys(variantsMap[property]);
        for (let j = 0; j < variants.length; j++) {
          const variant = variants[j];
          const fallback = variantsMap[property][variant];
          if (fallback === true) {
            add(getVariantClassName(property, variant, id));
          } else if (
            !isServer &&
            typeof fallback === 'string' &&
            window.matchMedia // jsdom doesn’t define matchMedia
          ) {
            const query = window.matchMedia(fallback);
            if (query.matches) {
              add(getVariantClassName(property, variant, id));
            }
          }
        }
      }
      return {className: classes};
    },
  });

  Object.defineProperty(record, 'id', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: id,
  });

  Object.defineProperty(record, 'selector', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: `&.${id}`,
  });

  Object.defineProperty(record, 'internal_setDefaults', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: function assignDefaults(
      input: CssVariantAssignment<unknown> = {} as CssVariantAssignment<unknown>,
    ) {
      return setDefaults(
        {...defaults, ...(input as Record<string, unknown>)},
        VariantContext,
      );
    },
  });

  Object.defineProperty(record, 'internal_setAdaptiveDefaults', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: function assignAdaptiveDefaults(
      input: CssVariantAssignment<unknown> = {} as CssVariantAssignment<unknown>,
    ) {
      return provider(VariantContext, input);
    },
  });

  Object.defineProperty(record, 'internal_autoAssign', {
    enumerable: false,
    configurable: false,
    get() {
      return onComponentWillReceiveProps<CssVariantAssignment<unknown>>(
        (props) => [record.assign(props)],
      );
    },
  });

  Object.defineProperty(record, 'internal_defaults', {
    enumerable: false,
    configurable: false,
    get() {
      return setDefaults(defaults, VariantContext);
    },
  });

  return record;
};
