import React from 'react';
import ReactDOM from 'react-dom';

import App from 'gelato/frontend/src/app';

const root = document.getElementById('root');

if (root) {
  ReactDOM.render(
    <React.StrictMode>
      {/* @ts-expect-error - TS2741 - Property 'pageProps' is missing in type '{}' but required in type 'Readonly<WrapperProps>' */}
      <App />
    </React.StrictMode>,
    root,
  );
}
