import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';

import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';

export default async function startSession(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
): Promise<void> {
  // Note that the server only sets the "started_at" in the session only if it
  // is absent.
  const resp = await runtime.api.mutation.setStartedAtMutation();
  if (!resp?.data?.setStartedAt?.success) {
    throw new Error(ErrorCode.sessionStartedAtDidNotUpdate);
  }
}
