import React, {useState, useRef} from 'react';
import {createViewConfig, dynamic} from '@sail/engine';
import type {IconAsset, SvgType} from '@sail/icons/types';
import type {View} from '../view';

import {css} from '../css';
import type {IconSet, LazyIconAsset} from './LazyIcons/types';

type AltIconSetName = 'Icon';
type IconSize = 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

export type IconProps = View.IntrinsicElement<
  'svg',
  {
    /**
     * @external
     */
    'aria-hidden'?: boolean;
    /**
     * The icon asset to display.
     */
    icon: IconAsset;
    /**
     * @external
     */
    size?: IconSize;
    /**
     * The SVG element
     */
    ref?: React.Ref<SVGSVGElement>;
    appearance?: 'default' | 'contained';
  }
>;

export const IconConfig = createViewConfig({
  props: {} as IconProps,
  name: 'Icon',
  flattens: true,
  defaults: {
    'aria-hidden': true,
    size: 'small',
    appearance: 'default',
  },
});

const colorableStyles = css({
  fill: 'inherit',
});

const uncolorableStyles = css({
  // Colorable icons inherit the icon color, but icons that
  // aren't colorable should have this default set (often redundant)
  fill: 'icon',
});

// Small is already the default, so nothing needs to fall back to it
const SIZE_FALLBACKS: Partial<Record<IconSize, IconSize[]>> = {
  xxsmall: ['xsmall'],
  large: ['medium'],
  xlarge: ['large', 'medium'],
};

function getIconSizeName(iconSet: AltIconSetName | undefined, size?: IconSize) {
  return [iconSet, size].filter(Boolean).join('__') || '';
}

function getClosestSizeIcon(
  alt: Record<string, SvgType>,
  iconSet: AltIconSetName | undefined,
  size?: IconSize,
) {
  // Grab the name for the specific icon size
  const sizeName = getIconSizeName(iconSet, size);

  // If we get a valid icon name back, and it exists in the alt set, return it
  if (sizeName && alt[sizeName]) {
    return alt[sizeName];
  }

  // If we do pass a size, but it didn't directly match, try to find a fallback
  if (size) {
    // Look up the valid fallbacks for the provided size
    const fallbacks = SIZE_FALLBACKS[size];

    if (fallbacks) {
      // For each fallback in order
      for (let i = 0; i < fallbacks.length; i++) {
        // See if we have a specific icon in *that* size
        const fallbackSizeName = getIconSizeName(iconSet, fallbacks[i]);
        // If the fallback size name exists in the alt set, return it
        if (fallbackSizeName && alt[fallbackSizeName]) {
          return alt[fallbackSizeName];
        }
      }
    }
  }

  // If after all that we don't have an icon that directly matched, just return the default icon set, unsized
  return alt[iconSet || ''];
}

/**
 * Display icons from the `@sail/icons` package.
 *
 * @see https://sail.stripe.me/components/icon/
 */
export const Icon = IconConfig.createView(
  ({icon, size, ...props}) => {
    let SvgElem = icon.svg;

    if (icon.alt) {
      // Currently we only support alt icons on the `Icon` icon set
      const altIconSetName = 'Icon';
      const alt = getClosestSizeIcon(icon.alt, altIconSetName, size);

      if (alt) {
        SvgElem = alt;
      }
    }

    return (
      <SvgElem
        /*
         * According to W3C [0] SVG presentation attributes have lower priority
         * than CSS rules. Given that we're always setting the `fill` property
         * through CSS we shouldn't need to delete the SVG `fill` attribute.
         * However, it seems that there are some cases where the `fill` attribute
         * takes precedence over the CSS `fill` property causing icons not to
         * show [1].
         * This workaround ensures that the `fill` attribute is removed
         * from the SVG element and only the `fill` CSS property is applied.
         *
         * [0]: https://www.w3.org/TR/SVG2/styling.html#PresentationAttributes
         * [1]: go/jira/SAILUI-4096
         **/
        // @ts-expect-error - Type 'null' is not assignable to type 'string | undefined'
        fill={null}
        {...props}
      />
    );
  },
  {
    uses: [
      css({
        baseline: 'cap-middle object-middle',
        display: 'inline',
      }),
      dynamic(({icon}: {icon: IconAsset}) => [
        icon.colorable ? colorableStyles : uncolorableStyles,
      ]),
    ],
    forward: {size: true},
    variants: {
      appearance: {
        contained: {
          css: {
            boxSizing: 'content-box',
            display: 'block',
            backgroundColor: 'offset',
          },
        },
      },
      size: {
        // We don't use tokens for the sizes here intentionally https://jira.corp.stripe.com/browse/SAIL-3322
        xxsmall: {
          // space.100 / small
          css: {height: '8px', width: '8px'},
          variants: {
            appearance: {
              contained: {
                css: {
                  padding: 'xsmall',
                  borderRadius: 'xsmall',
                },
              },
            },
          },
        },
        xsmall: {
          // space.150
          css: {height: '12px', width: '12px'},
          variants: {
            appearance: {
              contained: {
                css: {
                  padding: 'small',
                  borderRadius: 'xsmall',
                },
              },
            },
          },
        },
        small: {
          // space.200 / medium
          css: {height: '16px', width: '16px'},
          variants: {
            appearance: {
              contained: {
                css: {
                  padding: 'space.150',
                  borderRadius: 'xsmall',
                },
              },
            },
          },
        },
        medium: {
          // space.250
          css: {height: '20px', width: '20px'},
          variants: {
            appearance: {
              contained: {
                css: {
                  padding: 'medium',
                  borderRadius: 'small',
                },
              },
            },
          },
        },
        large: {
          // space.400 / xlarge
          css: {height: '32px', width: '32px'},
          variants: {
            appearance: {
              contained: {
                css: {
                  padding: 'space.250',
                  borderRadius: 'medium',
                },
              },
            },
          },
        },
        xlarge: {
          // space.800
          css: {height: '64px', width: '64px'},
          variants: {
            appearance: {
              contained: {
                css: {
                  padding: 'large',
                  borderRadius: 'large',
                },
              },
            },
          },
        },
      },
    },
  },
);

export type DynamicIconProps = Omit<IconProps, 'icon'> & {icon: LazyIconAsset};

export const DynamicIconConfig = createViewConfig({
  // The configured view is the internal icon so users have direct access to the
  // icon asset as they would expect. In this case, the `icon` prop is always
  // the asset so we need to tweak the props
  props: {} as DynamicIconProps,
  name: 'DynamicIcon',
  flattens: true,
});

const iconSetCache = new Map<string, IconSet>();

/**
 * Similar to `Icon` but support lazily loading an icon from an
 * icon set based off a loader
 */
export const DynamicIcon = DynamicIconConfig.createView(({icon, ...props}) => {
  const cachedIcons = iconSetCache.get(icon.loader.name);
  const iconsLoaded = useRef(!!cachedIcons);
  const [icons, setIcons] = useState<IconSet | undefined>(cachedIcons);

  if (!iconsLoaded.current) {
    iconsLoaded.current = true;

    icon.loader.load().then((icons: IconSet) => {
      setIcons(icons);
      iconSetCache.set(icon.loader.name, icons);
    });
  }

  const iconAsset = icons?.[icon.name];

  return (
    <Icon
      {...props}
      icon={iconAsset?.svg ? iconAsset : icon.loader.defaultIcon}
    />
  );
});
