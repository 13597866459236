// eslint-disable-next-line @sail/data-no-imperative-methods
import {useApolloClient} from '@apollo/client';
import * as React from 'react';

// eslint-disable-next-line @sail/data-no-imperative-methods
import type {ApolloClient} from '@apollo/client';

let clientOnlyForMetrics: ApolloClient<unknown> | undefined;

export default function getApolloClientOnlyForMetrics() {
  return clientOnlyForMetrics;
}

// This is quite hacky...
// This component gets rendered just below the SdkProvider and it's
// used to make the apollo client available to metricsBatcher imperatively.
// Ideally metricBatcher should be a hook that gets the apollo client
// via context.
export function ObtainClientOnlyForMetrics({
  children,
}: {
  children: React.ReactNode;
}) {
  clientOnlyForMetrics = useApolloClient();

  return <>{children}</>;
}
