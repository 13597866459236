/**
 * Converts a blob into a CanvasImageSource.
 * @param blob The blob to convert to a CanvasImageSource.
 * @returns A CanvasImageSource and a release function to call when the image
 *   source is no longer needed. Caller must call release() to avoid memory
 *   leak.
 */
export default async function blobToCanvasImageSource(blob: Blob): Promise<{
  imageSource: CanvasImageSource;
  release: () => void;
}> {
  if (typeof createImageBitmap === 'function') {
    let bitmap: ImageBitmap | null = await createImageBitmap(blob);
    const release = () => {
      if (bitmap) {
        bitmap.close();
        bitmap = null;
      }
    };
    return {
      imageSource: bitmap!,
      release,
    };
  } else {
    // IOS14 does not support createImageBitmap, so we need to use a fallback.
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(blob);
      let image: CanvasImageSource | null = new Image();
      const release = () => {
        if (image) {
          image = null;
          URL.revokeObjectURL(url);
        }
      };
      image.onload = () => {
        resolve({
          imageSource: image!,
          release,
        });
      };
      image.onerror = () => {
        release();
        reject(new Error('Could not load blob as image'));
      };
      image.src = url;
    });
  }
}
