import {UserAgentData} from 'gelato/frontend/src/lib/analytics/calculateUserAgentData';

export function convertUserAgentDataToAnalyticsEvents(
  userAgentData: UserAgentData,
) {
  return Object.keys(userAgentData).reduce<UserAgentDataKeyMapper>(
    (acc, key) => {
      const newKeyString = `ua${key
        .substring(0, 1)
        .toUpperCase()}${key.substring(1)}`;
      acc[newKeyString] = userAgentData[key as keyof UserAgentData];

      return acc;
    },
    {} as UserAgentDataKeyMapper,
  );
}

type UserAgentDataKeyMapper = {
  [key: string]: string | boolean | undefined;
};
