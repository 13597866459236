import * as React from 'react';
import {createViewConfig, view, assignProps} from '@sail/engine';
import type {View} from '../view';
import {useCurrentLayer} from './layers/Layer';
import {usePress} from './hooks/usePress';
import type {PressEvents} from './types';

const isNestedOverlay = (
  layerContext: ReturnType<typeof useCurrentLayer> | null | undefined,
): boolean => {
  if (!layerContext) {
    return false;
  }

  if (layerContext.type === 'overlay') {
    return true;
  }

  return isNestedOverlay(layerContext.parent);
};

export type BackdropProps = View.IntrinsicElement<
  'div',
  Pick<PressEvents, 'onPress'> & {
    /**
     * @deprecated Use onPress instead of onClick
     * @ignore
     */
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  }
>;

export const BackdropConfig = createViewConfig({
  props: {} as BackdropProps,
  name: 'Backdrop',
  flattens: true,
});

export const Backdrop = BackdropConfig.createView(
  ({onPress, ...props}) => {
    const layerContext = useCurrentLayer();
    const ref = React.useRef<HTMLDivElement>(null);
    const {pressProps} = usePress({
      onPress,
      ref,
    });

    return (
      <view.div
        data-testid="backdrop"
        ref={ref}
        uses={[assignProps(pressProps as View.IntrinsicElement<'div'>)]}
        css={{
          // For **stacked Drawers** to not display Backdrops between them, we set their opacity to 0 when in a nested layer,
          // this way Drawers can still be closed when clicking on the backdrop, even if it's not visible.

          // For **stacked Dialogs**, we do want to display a Backdrop behind each Dialog, even if they are nested. 
          // For that we force Backdrop opacity to always be 1 in the Dialog itself.
          opacity: isNestedOverlay(layerContext?.parent) ? 0 : undefined,
        }}
        {...props}
      />
    );
  },
  {
    css: {
      backgroundColor: 'backdrop',
      position: 'fixed',
      inset: 'space.0',
    },
  },
);
