import {
  MINIMUM_ID_CARD_FRONT_PROBABILTY_THRESHOLD,
  MINIMUM_PASSPORT_FRONT_PROBABILTY_THRESHOLD,
} from 'gelato/frontend/src/ML/lib/constants';

import type {DocumentTypes} from '@stripe-internal/data-gelato/schema/types';
import type {DetectionResult} from 'gelato/frontend/src/ML/IDDetectorAPI';

/**
 * Helper to compose an document type from a DetectionResult.
 * @param detectionResult The DetectionResult to compose the ImageStatus from.
 * @returns The document type composed from the DetectionResult.
 */
export default function getDocumentTypeByDetectionResult(
  detectionResult?: {probability: DetectionResult['probability']} | null,
): DocumentTypes | undefined {
  if (!detectionResult) {
    return undefined;
  } else if (
    detectionResult.probability.frontPassport >
    MINIMUM_PASSPORT_FRONT_PROBABILTY_THRESHOLD
  ) {
    return 'passport';
  } else if (
    detectionResult.probability.frontCard >
    MINIMUM_ID_CARD_FRONT_PROBABILTY_THRESHOLD
  ) {
    return 'id_card';
  } else {
    return undefined;
  }
}
