import * as React from 'react';
import {injectIntl} from 'react-intl';

import PageCard from 'gelato/frontend/src/components/PageCardV2';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {useSessionInfo} from 'gelato/frontend/src/layout/contexts/SessionInfoProvider';
import {useRouter} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {TestingPageCard} from 'gelato/frontend/src/local_pages/verify_testing';

import type {PageProps} from 'gelato/frontend/src/lib/localRouter';
import type {IntlShape} from 'react-intl';

type Props = PageProps & {
  intl: IntlShape;
};

const {useEffect} = React;

const TestingPage = (props: Props) => {
  const router = useRouter();
  const info = useSessionInfo();

  const {livemode, welcomePageUrl} = info;
  const shouldRedirect = info.isFlagSet('gelato_disable_testmode_ui_elements');

  useEffect(() => {
    if (livemode) {
      throw new Error(
        `${ErrorCode.testModeNotAllowed}: testing page disabled in livemode`,
      );
    } else if (shouldRedirect) {
      // update 2020-04-10(dodgejoel) testing whether or not this is an iframe is being replaced with
      // whether a feature flag for disabling testmode elements in general

      // update 2024-03-21(cjmisenas) This is maintained behavior we do not want to invest in right now
      // for the product stripedemo (Rocket Rides) and the direct onboarding internal integration
      //
      // Why do we want to skip past /testing?
      //
      // * Direct & Standard Onboarding is always livemode unless its an automated
      //   test with a fake merchant, but we don't need `/testing` for that.
      router.replace(welcomePageUrl);
    } else {
      postIframeEvent('load');
    }
  }, [shouldRedirect, livemode, welcomePageUrl, router]);

  if (shouldRedirect) {
    return <PageCard loading />;
  }

  return <TestingPageCard />;
};

export default injectIntl(TestingPage);
