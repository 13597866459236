import {flushSync} from 'react-dom';

import {isMobileDevice} from 'gelato/frontend/src/lib/device';
import './pageTransitions.module.css'; // This is required to load the CSS file.

export const PageTransitionStyles = {
  // This should be synchronized with the style `.withPageTransitionAnimation`
  // defined at `gelato/frontend/src/lib/pageTransitions.module.css
  withPageTransitionAnimation: {
    viewTransitionName: 'page-transition-element',
  },
  // This should be synchronized with the style `.withoutPageTransitionAnimation`
  // defined at `gelato/frontend/src/lib/pageTransitions.module.css
  withoutPageTransitionAnimation: {
    viewTransitionName: 'no-transition',
  },
};

/**
 * Start a page transition effect that plays animation between pages.
 * The transition effect is only applied if the browser supports it and it's
 * used primarily for UX enhancement.
 *
 * @param updatePage The function that updates the React page.
 * @param direction The direction of the page transition animation.
 */
export function startPageTransition(
  updatePage: () => void,
  direction: 'backward' | 'center' | 'forward' | 'none',
) {
  const root = document.documentElement;

  // @ts-ignore: document.startViewTransition is not typed yet.
  const {startViewTransition} = document;

  // The page transition is OFF on mobile devices because we've not tested it
  // enough to make sure it works well on mobile devices yet.
  if (
    direction !== 'none' &&
    root &&
    typeof startViewTransition === 'function' &&
    !isMobileDevice()
  ) {
    // This defines the direction of the page transition animation.
    root.setAttribute('data-page-transition-direction', direction);

    // This performs the page transition animation.
    startViewTransition.call(document, () => {
      flushSync(() => updatePage());
    });
  } else {
    root.removeAttribute('data-page-transition-direction');
    updatePage();
  }
}
