import fetchStartCodeResponseForLinkCode from 'gelato/frontend/src/controllers/utils/fetchStartCodeResponseForLinkCode';
import {getInvalidStates} from 'gelato/frontend/src/controllers/utils/getInvalidStates';
import {getSessionQuery} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import {getDebugInfo} from 'gelato/frontend/src/lib/device';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import {LocationType} from 'gelato/frontend/src/services/types';

import type {
  ApplicationAction,
  ApplicationActionWithPayload,
} from 'gelato/frontend/src/controllers/types';
import type {LinkCodeResponse} from 'gelato/frontend/src/controllers/utils/fetchStartCodeResponseForLinkCode';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

// This file holds actions related to the entire lifecycle of the session
export const handleSessionInitializationAction: ApplicationActionWithPayload<{
  location: LocationType;
}> = async (controller, {location}) => {
  // early exit if we are already continuing
  if (controller.state.sessionPending) {
    return;
  }

  let initialSessionResponse: LinkCodeResponse | undefined;
  let getSessionResponse: {data: GetSessionQueryData} | undefined;
  try {
    initialSessionResponse = await fetchStartCodeResponseForLinkCode(location);
    // If there is a refresh url defined, this generally means the session
    // is expired/done and we need to redirect to the refresh URL instead.
    if (!initialSessionResponse.data?.refreshUrl) {
      // eslint-disable-next-line @sail/data-no-imperative-methods
      getSessionResponse = await controller.runtime!.apolloClient.query({
        query: getSessionQuery,
      });

      const session = getSessionResponse?.data?.session;

      if (initialSessionResponse && session) {
        controller.update((draft) => {
          draft.session = session;
        });
      }
    }
  } catch (e) {
    const error = asError(e);
    handleException(
      error,
      `fetchStartCodeResponseForLinkCode: ${error.message}`,
    );
    controller.update((draft) => {
      draft.error = error;
    });
    return false;
  }

  return true;
};

// Record whenever a session goes into an invalid state
export const recordInvalidStateAction: ApplicationAction = async (
  controller,
) => {
  const contentState = getInvalidStates(controller.state);
  if (contentState.deviceUnsupported) {
    // Log why the device is unsupported.
    const debugInfo = await getDebugInfo();
    analytics.track('invalidDebugInfo', {
      ...contentState,
      ...debugInfo,
    });
  } else {
    analytics.track('invalidDebugInfo', contentState);
  }
};
