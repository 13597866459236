import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import type {ApolloError, GraphQlDocument} from '@sail/data';
import type {
  Exact,
  InputMaybe,
  Scalars,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';

const doc = graphql`
  mutation SendHandoffEmailMutation($locale: String) {
    sendEmailLink(locale: $locale) {
      success
    }
  }
`;

export const sendHandoffEmailMutation: SendHandoffEmailMutationDocument = doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const sendHandoffEmailMutationOptions: GelatoMutationOptionsWithoutVariables<SendHandoffEmailMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useSendHandoffEmailMutation(
  options: GelatoMutationOptions<SendHandoffEmailMutationDocument> = {},
): GelatoMutationReturn<SendHandoffEmailMutationDocument> {
  return useGelatoMutation(sendHandoffEmailMutation, {
    ...sendHandoffEmailMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type SendHandoffEmailMutationVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
}>;

export type SendHandoffEmailMutationData = {
  readonly __typename: 'Mutation';
  readonly sendEmailLink: {
    readonly __typename: 'SuccessResult';
    readonly success: boolean;
  };
};

const sendHandoffEmailMutationPermissions = {} as const;

doc.permissions = sendHandoffEmailMutationPermissions;

export type SendHandoffEmailMutationPermissions = GelatoPermissionToken<
  typeof sendHandoffEmailMutationPermissions
>;

export type SendHandoffEmailMutationDocument = GraphQlDocument<
  SendHandoffEmailMutationData,
  SendHandoffEmailMutationVariables,
  SendHandoffEmailMutationPermissions
>;
