import {view} from '@sail/ui';
import * as React from 'react';
import {Helmet} from 'react-helmet';

type SingleInputPageSkeletonProps = {
  helmet: JSX.Element;
  title: JSX.Element;
  inputDescription: JSX.Element;
  inputLabel?: JSX.Element;
  inputElement?: JSX.Element;
  secondaryInputs?: JSX.Element;
  primaryButton: JSX.Element;
  secondaryLink?: JSX.Element;
};

/* This component implements the shared structure betweent the
`email_verification` and `phone_verification` pages that need to:
  1. show a title and description
  2. optionally collect some data with a single input that has a label
  3. show a primary CTA button
  4. optionally show a secondary Link

The type of these components is not very prescriptive as this is really just a
wrapper around a bunch of divs but the layout is expected to used with the
components the names are suggestive of.
*/
export const SingleInputPageSkeleton = (
  props: SingleInputPageSkeletonProps,
) => {
  const {
    helmet,
    title,
    inputDescription,
    inputLabel,
    inputElement,
    secondaryInputs,
    primaryButton,
    secondaryLink,
  } = props;
  return (
    <>
      <Helmet>{helmet}</Helmet>
      <view.div
        css={{paddingY: 'space.150', paddingX: 'space.250', gap: 'medium'}}
      >
        <view.div>{title}</view.div>
        <view.div css={{stack: 'y', gap: 'medium'}}>
          {/* Helpful context to explain that we are going to send an OTP code */}
          <view.div>{inputDescription}</view.div>
          <view.div css={{stack: 'y', gap: 'xsmall', font: 'body.small'}}>
            {inputLabel}
            {inputElement}
          </view.div>
          {secondaryInputs && (
            <view.div css={{stack: 'y', gap: 'xsmall', font: 'body.small'}}>
              {secondaryInputs}
            </view.div>
          )}
        </view.div>
      </view.div>
      {/* Button to confirm email and send OTP code */}
      <>
        <view.div css={{height: 'fill'}} />
        <view.div css={{padding: 'space.250', stack: 'y', gap: 'medium'}}>
          <view.div css={{width: 'fill', alignX: 'center'}}>
            {primaryButton}
            <view.div
              css={{stack: 'x', paddingTop: 'medium', alignX: 'center'}}
            >
              {secondaryLink}
            </view.div>
          </view.div>
        </view.div>
      </>
    </>
  );
};
