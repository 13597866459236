import * as React from 'react';

type Report = (
  error: Error,
  scope?: {
    tags?: Record<string, string>;
    extras?: Record<string, unknown>;
  },
) => void;

export type UiConfig = Readonly<{
  // We cannot import '@sail/observability' from '@sail/ui' since
  // '@sail/observability' is not versioned so we have to inject it externally.
  _internal_observability?: Readonly<{
    reports?: Readonly<{
      error: Report;
      warning: Report;
    }>;
    analytics?: Readonly<{
      track: (eventName: string, parameters?: Record<string, unknown>) => void;
    }>;
  }>;
  // The bottom sheet feature is disabled by default while it's being tested.
  enableBottomSheets?: boolean;
}>;

const UiConfigContext = React.createContext<UiConfig | null>(null);

export function useUiConfig(): UiConfig | null {
  return React.useContext(UiConfigContext);
}

export const UiConfigProvider: React.FC<{config: UiConfig}> = ({
  config,
  children,
}) => {
  const parentConfig = useUiConfig();
  const mergedConfig = React.useMemo(
    () => ({
      ...(parentConfig ?? {}),
      ...config,
    }),
    [parentConfig, config],
  );

  return (
    <UiConfigContext.Provider value={mergedConfig}>
      {children}
    </UiConfigContext.Provider>
  );
};
