import Storage from 'gelato/frontend/src/lib/Storage';

type Params = {
  locale?: string;
  overridePrimaryColor?: string;
  overrideSecondaryColor?: string;
};

/**
 * Injects additional params to the handoff url.
 * This is helpful for url-determined options such as overriding colors and locale.
 * @param url The handoff url.
 * @returns The handoff url with additional params.
 */
function injectHandoffUrlParams(url: string, params?: Params) {
  const urlWithParams = new URL(url);

  // Load url params from Storage.
  const locale = params?.locale ?? Storage.getLocale();
  const overridePrimaryColor =
    params?.overridePrimaryColor ?? Storage.getOverridePrimaryColor();
  const overrideSecondaryColor =
    params?.overrideSecondaryColor ?? Storage.getOverrideSecondaryColor();

  // Append params to newly created url.
  if (locale) {
    urlWithParams.searchParams.set('locale', locale);
  }
  if (overridePrimaryColor) {
    urlWithParams.searchParams.set(
      'overridePrimaryColor',
      overridePrimaryColor,
    );
  }
  if (overrideSecondaryColor) {
    urlWithParams.searchParams.set(
      'overrideSecondaryColor',
      overrideSecondaryColor,
    );
  }

  return urlWithParams.toString();
}

export default injectHandoffUrlParams;
