export default class FetchError extends Error {
  constructor(error: Error | string, requestUrl: string) {
    super();
    this.name = 'FetchError';
    this.type = 'fetch_error';
    this.message = `Error fetching ${requestUrl}`;
    this.requestUrl = requestUrl;

    if (typeof error === 'string') {
      this.message += `: ${error}`;
    } else {
      this.originalError = error;
      this.message += `: ${error.message}`;
    }
  }

  type: string;

  requestUrl: string;

  originalError?: Error;
}
