import {
  cropAndPad,
  getPixelSourceDimensions,
} from 'gelato/frontend/src/lib/canvas';
import ImageFrame from 'gelato/frontend/src/lib/ImageFrame';

import type {
  Rectangle,
  DetectionResult,
} from 'gelato/frontend/src/ML/IDDetectorAPI';

export async function cropImageAroundIDInternal(
  pixelSource: HTMLCanvasElement | ImageFrame,
  padding: number = 0,
  location?: Rectangle | null,
): Promise<HTMLCanvasElement> {
  const {sourceWidth, sourceHeight} = getPixelSourceDimensions(pixelSource);

  const {topLeft, dimensions} = location || {
    topLeft: [0, 0],
    dimensions: [sourceWidth, sourceHeight],
  };
  const left = Math.min(Math.max(topLeft[0], 0), sourceWidth);
  const top = Math.min(Math.max(topLeft[1], 0), sourceHeight);
  const width = Math.max(0, Math.min(sourceWidth - left, dimensions[0]));
  const height = Math.max(0, Math.min(sourceHeight - top, dimensions[1]));

  // NOTE: These could be negative now
  // console.log(
  //   topLeftX,
  //   topLeftY,
  //   sourceWidth - (topLeftX + width),
  //   sourceHeight - (topLeftY + height),
  // );
  return cropAndPad({
    pixelSource,
    padding: (padding / 100) * Math.max(height, width),
    sHeight: height,
    sWidth: width,
    sx: left,
    sy: top,
  });
}

async function cropImageAroundID(
  pixelSource: HTMLCanvasElement,
  padding: number = 0,
  detectionResult: DetectionResult,
): Promise<HTMLCanvasElement> {
  const {probability, location} = detectionResult;

  return cropImageAroundIDInternal(
    pixelSource,
    padding,
    probability.noDocument < 0.3 ? location : undefined,
  );
}

export default cropImageAroundID;
