import type {CSSProperties} from 'react';
import {configureStyle} from './configureStyle';

type UnprocessedCssProperties = {
  [K in keyof CSSProperties]-?: (value: CSSProperties[K]) => string;
} & {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]: (input: (string & {}) | {}) => string;
};

/**
 * Creates style rules using CSS properties outside of those supported by Sail’s core styling APIs.
 *
 * @see https://sail.stripe.me/apis/unprocessed-css
 */
export const unprocessedCss = configureStyle({
  '*'(str: string): string {
    return `${str}`;
  },
} as unknown as UnprocessedCssProperties);
