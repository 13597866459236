import {css, view} from '@sail/ui';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import flags from 'gelato/frontend/src/lib/flags';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import Storage from 'gelato/frontend/src/lib/Storage';

const Messages = defineMessages({
  title: {
    defaultMessage: 'Test mode',
    description: 'title text that appears on the testmode banner',
    id: 'verification.topNavigationBar.title',
  },
});

const Styles = {
  main: css({
    alignX: 'center',
    alignY: 'center',
    backgroundColor: 'feedback.attention.emphasized',
    font: 'label.small.emphasized',
    height: 'large',
    paddingX: 'small',
    stack: 'x',
    textColor: 'white',
    width: 'fill',
    // match the top border radius from page card
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  }),
};

/**
 * The banner that appears at the top of the page when the user is in test mode.
 */
export default function TestModeBanner(): JSX.Element | null {
  const {appState, appController} = useAppController();
  // Read the livemode from the local storage before the session is loaded.
  // This allow us to pupolate the banner before the session is initialized.
  // Note that this only applies to the Flows case where the livemode is
  // "populated" saved to the local storage.
  const livemode = Storage.getLivemode() || appState.session?.livemode;
  const path = appController.runtime?.router?.currentPath;

  if (
    livemode || // In live mode
    livemode === undefined || // Unclear if in livemode, so hide banner until we are sure
    path === '/preview' || // In preview mode
    flags.isActive('gelato_disable_testmode_ui_elements') // In DEMO app.
  ) {
    return null;
  }

  return (
    <view.div data-testid="test-mode-banner-v2" uses={[Styles.main]}>
      <Message {...Messages.title} />
    </view.div>
  );
}
