/* eslint-disable @typescript-eslint/no-use-before-define */
import type * as React from 'react';
import {
  addIntent,
  createIntentMap,
  isIntent,
  mergeIntents,
} from '../../../intent';
import {VIEW_CONFIG, getViewConfig} from './ViewConfig';
import {setCss, setEvent, setRef} from './propertyProcessors';
import type {
  AbstractPropsFacade,
  PropertyProcessor,
  ViewConfig,
  ComponentType,
  ComputedCacheRecord,
  PropsFacade,
} from './types';
import type {Intent, IntentMap} from '../../../intent';
import type {AnyCssSerializer} from '../../css';

const $facade = Symbol('facade');
const EMPTY_OBJECT = {};

function isPropsFacade<T extends object>(props: T): props is PropsFacade<T> {
  return $facade in props;
}

export function createPropsFacade<T extends object>(
  props: T,
  cache: ComputedCacheRecord,
  tag: ComponentType | undefined,
  css?: AnyCssSerializer,
  ref?: React.Ref<unknown>,
  intents?: Intent<unknown>[],
): T {
  if (isPropsFacade(props)) {
    return props;
  }

  const viewConfig = createIntentMap({
    cache,
    css,
    props: EMPTY_OBJECT,
    state: {},
    tag,
  });

  let propsFacade = createIntentMap(
    {
      [VIEW_CONFIG]: viewConfig,
      [$facade]: true,
    },
    viewConfig,
  );

  viewConfig.props = propsFacade;

  // In development mode, wrap the facade in a proxy that throws when anyone
  // attempts to assign a property without using mergeProperty.
  if (process.env.NODE_ENV !== 'production') {
    propsFacade = new Proxy(propsFacade, {
      set() {
        throw new Error('Cannot assign properties to props.');
      },
    });
  }

  addIntentsToProps(propsFacade, intents);

  const result = propsFacade as unknown as T;
  mergeProps(result as T & IntentMap, props as PropsFacade<T>);
  if (ref) {
    mergeProperty(result as unknown as {ref: unknown}, 'ref', ref);
  }
  return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const eventProcessors = {} as Record<string, PropertyProcessor<any> | null>;
const eventRegExp = /^on[A-Z]/;

export function mergeProperty<T, K extends keyof T>(
  obj: T,
  key: K,
  value: T[K],
): void {
  const target = getViewConfig(obj).props as AbstractPropsFacade;
  if (typeof key !== 'string') {
    return;
  }
  switch (key) {
    case VIEW_CONFIG:
      const config = getViewConfig(target);
      const valueConfig = value as unknown as ViewConfig;
      mergeIntents(config, valueConfig);
      if (valueConfig.tag) {
        config.tag = valueConfig.tag;
      }
      return;
    case 'className':
      target.className = target.className
        ? `${target.className} ${value}`
        : value;
      return;
    case 'ref':
      setRef(value as unknown as React.Ref<unknown>, target, key);
      return;
    case 'style':
      if (!target.style) {
        target.style = {};
      }
      Object.assign(target.style as object, value);
      return;
    case 'css':
      setCss(value, target, key);
      return;
    case 'slot':
    case 'slots':
    case 'uses':
      return;
    default:
      if (!(key in eventProcessors)) {
        eventProcessors[key] = key.match(eventRegExp) ? setEvent : null;
      }
      const processor = eventProcessors[key];
      if (processor) {
        processor(value, target, key);
      } else {
        (target as T)[key as K] = value;
      }
  }
}

export function addIntentsToProps<T>(
  target: IntentMap & T,
  source: (Intent<T> | Partial<T>)[] | void,
): void {
  if (!source) {
    return;
  }
  for (let i = 0; i < source.length; i++) {
    const intent = source[i];
    if (intent == null) {
      if (process.env.NODE_ENV !== 'production' && intent === undefined) {
        /* eslint-disable no-console */
        console.warn(
          'You are passing an `undefined` intent. This might indicate a bug. For conditional intents, consider using `null` or `false` instead',
        );
        console.warn('The intent came from:');
        console.warn(source);
        /* eslint-enable no-console */
      }
    } else if (isIntent(intent)) {
      addIntent(target, intent);
    } else {
      mergeProps(target, intent as PropsFacade<T>);
    }
  }
}

export function mergeProps<T>(target: IntentMap & T, source: T): void {
  addIntentsToProps(target, (source as PropsFacade<T>).uses);

  const keys = Object.keys(source as object);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    mergeProperty(target, key as keyof T, (source as T)[key as keyof T]);
  }
}
