import {view} from '@sail/ui';
import * as React from 'react';

import AddNewDocument from 'gelato/frontend/src/components/Link/DocumentSelection/Document/AddNewDocument';
import Document from 'gelato/frontend/src/components/Link/DocumentSelection/Document/Document';
import {Styles} from 'gelato/frontend/src/components/Link/DocumentSelection/styles';

import type {
  ConsumerIdentityDocument,
  ConsumerIdentityDocumentApi,
} from 'gelato/frontend/src/api/Consumer/types';

type DocumentSelectionProps = {
  addNewDocument: () => void;
  consumerDocuments: ConsumerIdentityDocument<ConsumerIdentityDocumentApi>[];
  selectedDocument: string | undefined;
  setSelectedDocument:
    | React.Dispatch<React.SetStateAction<string | undefined>>
    | ((selectedDocument: string | undefined) => void);
  disabled?: boolean;
};

const DocumentSelection = ({
  addNewDocument,
  consumerDocuments,
  selectedDocument,
  setSelectedDocument,
  disabled,
}: DocumentSelectionProps) => {
  return (
    <view.div
      uses={[Styles.selectWrapper]}
      role="listbox"
      aria-labelledby="select-an-id"
    >
      {consumerDocuments.map((doc, index) => {
        return (
          <Document
            disabled={disabled}
            document={doc}
            index={index}
            key={doc.id}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
          />
        );
      })}
      <AddNewDocument
        addNewDocument={addNewDocument}
        data-testid="link-upload-new-document"
        disabled={disabled}
        selected={selectedDocument === undefined}
      />
    </view.div>
  );
};

export default DocumentSelection;
