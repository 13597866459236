import {useUpdatedRef} from '@sail/react';
import {noop, once, shallowMemoize} from '@sail/utils';
import {useMemo} from 'react';
import {UninitializedObservabilityProviderError} from 'src/internal/common/errors';
import logErrorInDev from 'src/internal/common/logErrorInDev';
import {useObservabilityApi} from 'src/internal/provider/ObservabilityApiProvider';

import type {IReports} from 'src/internal/errors/types';

const logUninitializedErrorOnce = once(() => {
  logErrorInDev(new UninitializedObservabilityProviderError('useReports'));
});

const memoizedReports = shallowMemoize(
  (apiRef: NonNullable<ReturnType<typeof useObservabilityApi>>): IReports => {
    return {
      error(...args: Parameters<IReports['error']>): void {
        return apiRef.reports.error(...args);
      },

      warning(...args: Parameters<IReports['warning']>): void {
        return apiRef.reports.warning(...args);
      },
    };
  },
);

/**
 * React hook to send error and warning reports to Sentry.
 *
 * It returns an object with reporting methods contextualized with the
 * Sentry project, release, tags, and extras provided by the
 * closest `<ObservabilityProvider />`.
 *
 * For example, consider the following:
 * ```tsx
 * <ObservabilityProvider project="atlas" errors={{tags: {foo: '1', bar: '1'}}}>
 *    ...
 *    const {reportError} = useReports();
 *    reportError(error, {
 *      tags: {
 *        foo: '2'
 *      }
 *    });
 *    ...
 * </ObservabilityProvider>
 * ```
 *
 * The actual data sent to Sentry will be:
 * ```tsx
 * {
 *   project: "atlas",
 *   tags: {
 *     foo: '2',
 *     bar: '1'
 *   },
 * }
 * ```
 *
 * @example Basic {{include "./examples/useReports.basic.tsx"}}
 *
 * @see https://sail.stripe.me/apis/observability/useReports
 */
export default function useReports(): IReports {
  const apiRef = useUpdatedRef(useObservabilityApi());

  return useMemo((): IReports => {
    if (!apiRef.current) {
      logUninitializedErrorOnce();

      return {
        error(): void {
          noop();
        },
        warning(): void {
          noop();
        },
      };
    }

    return memoizedReports(apiRef.current);
  }, [
    apiRef, // This is a ref object, so it never changes and thus the returned object is stable.
  ]);
}
