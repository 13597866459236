import {useEffect} from 'react';
import {trackers} from 'src/internal/platformMetrics/trackers';

export default {
  trackOrgGlobalsAreaCount: trackers.orgGlobalsAreaCount.track,
  trackBannerCount: trackers.bannerCount.track,
  trackDashboardPageTemplates: trackers.dashboardPageTemplates.track,
  trackStripeletCount: trackers.stripeletCount.track,
  trackTransalationCount: trackers.translationCount.track,
  translationCountForMessageLength:
    trackers.translationCountForMessageLength.track,
  useTrackMount: function useTrackMount(
    id: Parameters<typeof trackers.usageByMount.track>[0],
  ) {
    useEffect(() => trackers.usageByMount.track(id), [id]);
  },
};
