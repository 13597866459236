import {view, css} from '@sail/ui';
import * as React from 'react';

import {messages} from 'gelato/frontend/src/components/Link/messages';
import Message from 'gelato/frontend/src/components/Message';
import {tGrey} from 'gelato/frontend/src/components/stylesV2';
import {LocaleContext} from 'gelato/frontend/src/lib/contexts';

import type {ExpirationDateDetails} from 'gelato/frontend/src/api/Consumer/types';

type DocumentExpirationProps<T> = T & {
  hasExpirationDate: true;
};

const Styles = {
  mediumLabel: css({
    font: 'label.medium',
  }),
};

const DocumentExpiration = ({
  isExpired,
  expirationDateInMilliseconds,
}: DocumentExpirationProps<ExpirationDateDetails>) => {
  const {locale} = React.useContext(LocaleContext);

  return isExpired ? (
    <view.div uses={[Styles.mediumLabel, tGrey]}>
      <Message
        {...messages.expiredLabel}
        values={{
          expirationDate: new Date(
            expirationDateInMilliseconds,
          ).toLocaleDateString(locale),
        }}
      />
    </view.div>
  ) : (
    <view.div uses={[Styles.mediumLabel]} css={{color: 'subdued'}}>
      <Message
        {...messages.expirationLabel}
        values={{
          expirationDate: new Date(
            expirationDateInMilliseconds,
          ).toLocaleDateString(locale),
        }}
      />
    </view.div>
  );
};

export default DocumentExpiration;
