import * as Sentry from '@sentry/browser';
import ReactWebcam from 'react-webcam';

import getVideoDimensions from 'gelato/frontend/src/components/webcam/getVideoDimensions';
import {isIOS15OrGreater} from 'gelato/frontend/src/lib/device';
import ImageFrame from 'gelato/frontend/src/lib/ImageFrame';
import requestCanvas from 'gelato/frontend/src/lib/requestCanvas';
import {captureMessage, handleException} from 'gelato/frontend/src/lib/sentry';

// HTMLCanvasElement doesn't exist in Node, so polyfill this only after the app mounts
import('mdn-polyfills/HTMLCanvasElement.prototype.toBlob');

const CANVAS_IMAGE_FORMAT = 'image/jpeg';
const CANVAS_ENCODER_OPTIONS = 0.92; // image quality to use for image formats that use lossy compression

let tempCanvas: HTMLCanvasElement;

export const getCanvas = ({
  webcam,
}: {
  webcam: ReactWebcam;
}): HTMLCanvasElement | null => {
  // Note webcam.getCanvas is typed improperly. It can in fact return null if there's no video stream.

  const canvas = webcam.getCanvas();

  if (!canvas) {
    Sentry.addBreadcrumb({
      category: 'Webcam',
      message: `Null canvas from webcam`,
      level: Sentry.Severity.Error,
    });

    return canvas;
  }
  // HACK - iOS15 has a bug where the camera reports the wrong size sometimes.
  // issue: https://github.com/mozmorris/react-webcam/issues/167
  if (isIOS15OrGreater() && webcam.video) {
    try {
      const {width, height} = getVideoDimensions(webcam.video);
      if (!tempCanvas) {
        tempCanvas = requestCanvas('Canvas for iOS15+');
      }
      const canvasContext = tempCanvas.getContext('2d');
      if (!canvasContext) {
        captureMessage('Canvas Context2D is null');
        return canvas;
      }
      canvasContext.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
      if (tempCanvas.height !== height || tempCanvas.width !== width) {
        // Check if we can use an existing canvas which is the same size
        tempCanvas.width = width;
        tempCanvas.height = height;
      }
      canvasContext.drawImage(webcam.video, 0, 0, width, height);
      return tempCanvas;
    } catch (e: any) {
      handleException(e, 'Error painting canvas');
      return canvas;
    }
  } else {
    return canvas;
  }
};

export function canvasToBlob(
  source: HTMLCanvasElement,
  quality: number = CANVAS_ENCODER_OPTIONS,
): Promise<Blob | null | undefined> {
  return new Promise(
    (
      resolve: (
        result?: Promise<Blob | null | undefined> | Blob | null | undefined,
      ) => void,
      reject: (error?: any) => void,
    ) => {
      return source.toBlob(
        (blob?: Blob | null) => {
          resolve(blob);
        },
        CANVAS_IMAGE_FORMAT,
        quality,
      );
    },
  );
}

export function getPixelSourceDimensions(
  pixelSource: HTMLCanvasElement | ImageFrame,
): {
  sourceWidth: number;
  sourceHeight: number;
} {
  return {
    sourceWidth: pixelSource.width,
    sourceHeight: pixelSource.height,
  };
}

/**
 * Creates a new Canvas from pixelSource, preserving aspect ratio.
 * Output canvas has its longest dimension as the maxDimension pixels
 */
export function resizeCanvas(
  pixelSource: HTMLCanvasElement,
  maxDimension: number,
): HTMLCanvasElement {
  const {sourceWidth, sourceHeight} = getPixelSourceDimensions(pixelSource);
  const ratio = Math.max(sourceWidth, sourceHeight) / maxDimension;

  return cropCanvas({
    pixelSource,
    smoothingEnabled: true,
    dWidth: sourceWidth / ratio,
    dHeight: sourceHeight / ratio,
  });
}

/**
 * Creates a new Canvas from pixelSource, preserving aspect ratio.
 * Output canvas has it's longest dimension is maxDimension pixels
 */
export function resizeTooSmallCanvas(
  pixelSource: HTMLCanvasElement,
  minDimensions: {width: number; height: number},
): HTMLCanvasElement {
  const {sourceWidth, sourceHeight} = getPixelSourceDimensions(pixelSource);
  let scale = 1;

  // If minimum dimensions are given, make sure we set the width/height to the minimum
  if (minDimensions) {
    scale = Math.max(
      minDimensions.width / sourceWidth,
      minDimensions.height / sourceHeight,
    );
  }
  return cropCanvas({
    pixelSource,
    smoothingEnabled: true,
    dWidth: sourceWidth * scale,
    dHeight: sourceHeight * scale,
    minDimensions,
  });
}

export function cropCanvas({
  backgroundColor,
  pixelSource,
  dHeight,
  dWidth,
  height,
  sHeight,
  sWidth,
  sx = 0,
  sy = 0,
  center = false,
  smoothingEnabled = true,
  width,
  minDimensions,
}: {
  backgroundColor?: string | CanvasGradient | CanvasPattern;
  pixelSource: HTMLCanvasElement | ImageFrame;
  dHeight?: number;
  dWidth?: number;
  height?: number;
  padding?: number;
  sHeight?: number;
  sWidth?: number;
  sx?: number;
  sy?: number;
  center?: boolean;
  smoothingEnabled?: boolean;
  width?: number;
  minDimensions?: {width: number; height: number};
}): HTMLCanvasElement {
  const croppedImageCanvas = requestCanvas('crop canvas');
  if (!dWidth && !sWidth) {
    throw new Error('dwidth or sWidth must be specified');
  }

  if (!dHeight && !sHeight) {
    throw new Error('dHeight or sHeight must be specified');
  }

  const actualHeight = height || dHeight || sHeight || 0;
  const actualWidth = width || dWidth || sWidth || 0;

  croppedImageCanvas.width = actualWidth;
  croppedImageCanvas.height = actualHeight;

  try {
    const canvasContext = croppedImageCanvas.getContext('2d');
    // This affects blur score. Also removing this should make it a bit faster.
    if (smoothingEnabled) {
      // @ts-expect-error - TS2531 - Object is possibly 'null'.
      canvasContext.imageSmoothingEnabled = true;
      // @ts-expect-error - TS2531 - Object is possibly 'null'.
      canvasContext.imageSmoothingQuality = 'high';
    } else {
      // @ts-expect-error - TS2531 - Object is possibly 'null'.
      canvasContext.imageSmoothingEnabled = false;
    }

    if (backgroundColor) {
      // @ts-expect-error - TS2531 - Object is possibly 'null'.
      canvasContext.fillStyle = 'black';
      // @ts-expect-error - TS2531 - Object is possibly 'null'.
      canvasContext.fillRect(
        0,
        0,
        croppedImageCanvas.width,
        croppedImageCanvas.height,
      );
    }
    const {sourceWidth, sourceHeight} = getPixelSourceDimensions(pixelSource);

    const computedDestWidth = dWidth || sWidth || 0;
    const computedDestHeight = dHeight || dHeight || 0;
    let dx = 0;
    let dy = 0;

    if (center) {
      dx = (croppedImageCanvas.width - computedDestWidth) / 2;
      dy = (croppedImageCanvas.height - computedDestHeight) / 2;
    }

    const source =
      pixelSource instanceof ImageFrame
        ? pixelSource.getSource()!
        : pixelSource;

    canvasContext!.drawImage(
      source,
      sx,
      sy,
      sWidth || sourceWidth,
      sHeight || sourceHeight,
      dx,
      dy,
      computedDestWidth,
      computedDestHeight,
    );
  } catch (e: any) {
    handleException(e, 'Canvas context is null');
  }

  return croppedImageCanvas;
}

export function cropAndPad({
  pixelSource,
  padding = 0,
  sHeight,
  sWidth,
  sx = 0,
  sy = 0,
}: {
  pixelSource: HTMLCanvasElement | ImageFrame;
  padding?: number;
  sHeight: number;
  sWidth: number;
  sx: number;
  sy: number;
}): HTMLCanvasElement {
  const {sourceWidth, sourceHeight} = getPixelSourceDimensions(pixelSource);
  const croppedImageCanvas = requestCanvas('crop and pad canvas');
  const paddingSx = Math.max(sx - padding, 0);
  const paddingSy = Math.max(sy - padding, 0);
  const paddingWidth = Math.min(
    (sWidth || sourceWidth) + padding * 2,
    sourceWidth - paddingSx,
  );
  const paddingHeight = Math.min(
    (sHeight || sourceHeight) + padding * 2,
    sourceHeight - paddingSy,
  );

  croppedImageCanvas.width = paddingWidth;
  croppedImageCanvas.height = paddingHeight;
  try {
    const canvasContext = croppedImageCanvas.getContext('2d');
    const source =
      pixelSource instanceof ImageFrame
        ? pixelSource.getSource()!
        : pixelSource;

    // @ts-expect-error - TS2531 - Object is possibly 'null'.
    canvasContext.drawImage(
      source,
      paddingSx,
      paddingSy,
      paddingWidth,
      paddingHeight,
      0,
      0,
      croppedImageCanvas.width,
      croppedImageCanvas.height,
    );
  } catch (e: any) {
    handleException(e, 'Canvas context is null');
  }

  return croppedImageCanvas;
}
