import toSanitizedParams from 'gelato/frontend/src/lib/analytics/toSanitizedParams';
import toSnakeCasesParams from 'gelato/frontend/src/lib/analytics/toSnakeCasesParams';

import type {AnalyticsParams} from 'gelato/frontend/src/lib/analytics/types';

export default function formatEventParams(
  params?: AnalyticsParams,
): AnalyticsParams | undefined {
  return params ? toSanitizedParams(toSnakeCasesParams(params)) : undefined;
}
