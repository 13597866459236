import * as React from 'react';

import {DEFAULT_BRANDING_BUTTON_COLOR} from 'gelato/frontend/src/lib/constants';
import {useBranding} from 'gelato/frontend/src/lib/hooks';
import {PressableThemeProvider} from 'sail/Pressable';
import {createPressableTheme} from 'sail/Pressable/theme';
import {ToggleBox} from 'sail/ToggleBox';

const ThemableToggleBox = ({
  children,
  ...rest
}: React.ComponentProps<typeof ToggleBox>) => {
  const branding = useBranding();
  const brandingButtonColor = branding
    ? branding.buttonColor
    : DEFAULT_BRANDING_BUTTON_COLOR;

  const theme = createPressableTheme(brandingButtonColor);

  return (
    <PressableThemeProvider theme={theme}>
      <ToggleBox {...rest}>{children}</ToggleBox>
    </PressableThemeProvider>
  );
};

export default ThemableToggleBox;
