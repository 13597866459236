import {warning} from '@sail/icons/react/Icon';
import {IconAsset} from '@sail/icons/types';
import {Spinner, view, css, Icon} from '@sail/ui';
import * as React from 'react';

const Styles = {
  container: css({
    alignX: 'center',
    textAlign: 'center',
    stack: 'y',
    gap: 'medium',
    padding: 'medium',
    boxSizing: 'border-box',
  }),
  header: css({
    stack: 'x',
    gap: 'small',
    alignX: 'center',
    alignY: 'center',
    font: 'heading.medium.subdued',
  }),
  body: css({
    stack: 'y',
    gap: 'medium',
    alignY: 'center',
    height: 'fill',
  }),
  description: css({
    font: 'body.small',
  }),
  actions: css({
    width: 'fill',
  }),
};

type Props = {
  title: React.ReactNode;
  icon?: IconAsset;
  description?: React.ReactNode;
  actions?: React.ReactNode;
};

const ContentState = ({title, icon, description, actions}: Props) => {
  return (
    <view.div uses={[Styles.container]}>
      <view.div uses={[Styles.body]}>
        <view.div uses={[Styles.header]}>
          {icon && <Icon icon={icon} size="medium" />}
          <view.div>{title}</view.div>
        </view.div>
        {description && (
          <view.div uses={[Styles.description]}>{description}</view.div>
        )}
      </view.div>
      {actions && <view.div uses={[Styles.actions]}>{actions}</view.div>}
    </view.div>
  );
};

export const ErrorState = ({
  title,
  description,
  actions,
}: Omit<Props, 'icon'>) => {
  return (
    <ContentState
      icon={warning}
      title={title}
      description={description}
      actions={actions}
    />
  );
};

export const LoadingState = ({
  title,
  description,
  actions,
}: Omit<Props, 'icon'>) => {
  return (
    <>
      <Spinner />
      <ContentState title={title} description={description} actions={actions} />
    </>
  );
};

export default ContentState;
