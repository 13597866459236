import * as React from 'react';
import {useState, useMemo, useEffect} from 'react';
import {
  parsePhoneNumber,
  parseIncompletePhoneNumber,
  formatIncompletePhoneNumber as _formatIncompletePhoneNumber,
  getCountryCallingCode,
  getExampleNumber,
  parseDigits,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import type {PhoneNumber, CountryCode} from 'libphonenumber-js';
import {view} from '@sail/engine';
import type {View} from '../view';

import {TextField} from './TextField';
import type {CompositeFieldProps} from './CompositeField';
import {CompositeField} from './CompositeField';

import {Select} from './Select';

import {useSelection} from './hooks/useSelection';
import type {InputWrapperProps} from './FormFieldGroup';
import {PhoneNumberFieldConfig} from './PhoneNumberFieldConfig';

export {CountryCode};

export type PhoneNumberFieldProps = Omit<
  CompositeFieldProps & View.IntrinsicElement<'fieldset'>,
  'onChange' | 'subviews'
> & {
  /**
   * An E.164 format telephone number, e.g. "+12024561111"
   */
  value?: string;

  /**
   * An E.164 format telephone number, e.g. "+12024561111"
   */
  defaultValue?: string;

  /**
   * A two-letter international country code (ISO 3166-1 alpha-2), e.g. "US"
   */
  defaultCountry: CountryCode;

  /**
   * A list of countries that are selectable from the country selector
   */
  countryAllowlist: CountryCode[];

  /**
   * Handler that is called when the input value is changed
   */
  onChange?: (value: string) => void;

  /**
   * Text that describes the control. Will be both visible and clickable.
   */
  label?: React.ReactNode;

  /**
   * Descriptive text that will be rendered adjacent to the control's label
   */
  description?: React.ReactNode;

  /**
   * Error text that will be rendered below the control
   */
  error?: React.ReactNode;

  /**
   * Disables the field and removes it from submission
   */
  disabled?: boolean;

  /**
   * Makes the field non-editable
   */
  readOnly?: boolean;

  /**
   * Sets the size of the field
   */
  size: 'small' | 'medium' | 'large';

  /**
   * Controls whether the control should be automatically focused
   */
  autoFocus?: boolean;

  subviews: View.Subviews<{
    inputs: (props: View.ViewProps<InputWrapperProps>) => JSX.Element | null;
    countryInput: typeof Select;
    countryCallingCode: 'span';
    phoneNumberInput: typeof TextField;
  }>;
};

function stripCountryCallingCode(value: string, countryCallingCode: string) {
  return value.replace(new RegExp(`^\\+?\\D*${countryCallingCode} ?`), '');
}

function formatNumber(phoneNumber: PhoneNumber) {
  return phoneNumber
    .formatInternational()
    .replace(/^\+\d*/, '')
    .trim();
}

function formatIncompletePhoneNumber(e164PhoneNumber: string) {
  return _formatIncompletePhoneNumber(e164PhoneNumber)
    .replace(/^\+\d*/, '')
    .trim();
}

function parseValue(value: string, defaultCountry: CountryCode) {
  let country: CountryCode | undefined;
  let countryCallingCode: string;
  let nationalNumber: string;
  let e164PhoneNumber: string;
  let formattedNumber: string | undefined;

  try {
    // first try to parse the number as-is
    const phoneNumber = parsePhoneNumber(value, defaultCountry);
    country = phoneNumber.country;
    countryCallingCode = phoneNumber.countryCallingCode;
    nationalNumber = phoneNumber.nationalNumber;
    e164PhoneNumber = phoneNumber.number;

    if (phoneNumber.isPossible()) {
      formattedNumber = formatNumber(phoneNumber);
    }
  } catch (err) {
    // if it fails (not enough input, or invalid input)
    countryCallingCode = getCountryCallingCode(defaultCountry);
    nationalNumber = parseIncompletePhoneNumber(
      parseDigits(stripCountryCallingCode(value, countryCallingCode)),
    );
    e164PhoneNumber = `+${countryCallingCode}${nationalNumber}`;
  }

  // if we couldn't get a formatted number, or what we got doesn't have any
  // formatting characters, let's format it assuming it's an incomplete number
  if (formattedNumber === undefined || !formattedNumber.match(/\D/)) {
    formattedNumber = formatIncompletePhoneNumber(e164PhoneNumber);
  }

  return {
    country,
    countryCallingCode,
    nationalNumber,
    e164PhoneNumber,
    formattedNumber,
  };
}

function digitCount(value: string) {
  return [...value.matchAll(/\d/g)].length;
}

function isPossibleE164PhoneNumber(value: string) {
  try {
    return value.startsWith('+') && parsePhoneNumber(value).isPossible();
  } catch (err) {
    return false;
  }
}

export const PhoneNumberField = PhoneNumberFieldConfig.createView(function ({
  countryAllowlist,
  description,
  error,
  size,
  // TODO: use a locale provider to determine a better default country for the user
  defaultCountry,
  value: valueProp,
  defaultValue,
  onChange,
  name,
  autoFocus,
  placeholder: placeholderProp,
  disabled,
  readOnly,
  subviews,
  ...props
}) {
  const [value, setValue] = useState(valueProp ?? defaultValue ?? '');
  const [country, setCountry] = useState(
    (countryAllowlist.length === 1 && countryAllowlist[0]) || defaultCountry,
  );

  const {
    country: parsedCountry,
    e164PhoneNumber,
    formattedNumber,
    nationalNumber,
    countryCallingCode,
  } = useMemo(() => parseValue(value, country), [value, country]);

  const placeholder = useMemo(() => {
    if (placeholderProp) {
      return placeholderProp;
    }

    const number = getExampleNumber(country, examples);
    if (number) {
      return formatNumber(number);
    }
  }, [country, placeholderProp]);

  const phoneNumberInputRef = React.useRef<HTMLInputElement>(null);
  const {setSelection} = useSelection(phoneNumberInputRef);

  useEffect(() => {
    valueProp && setValue(valueProp);
  }, [valueProp]);

  useEffect(() => {
    parsedCountry && setCountry(parsedCountry);
  }, [parsedCountry]);

  useEffect(() => {
    if (e164PhoneNumber !== value) {
      setValue(e164PhoneNumber);
      nationalNumber.length && onChange?.(e164PhoneNumber);
    }
  }, [e164PhoneNumber, nationalNumber, value, onChange]);

  return (
    <CompositeField
      {...props}
      description={description}
      error={error}
      size={size}
      disabled={disabled}
      subviews={{
        inputs: subviews.inputs,
      }}
    >
      {countryAllowlist.length > 1 ? (
        <Select
          inherits={subviews.countryInput}
          label="Country"
          value={country}
          disabled={readOnly}
          onChange={(e) => {
            const nextCountry = e.target.value as CountryCode;
            const nextCountryCallingCode = getCountryCallingCode(nextCountry);
            const nextE164PhoneNumber = `+${nextCountryCallingCode}${nationalNumber}`;
            setValue(nextE164PhoneNumber);
            setCountry(nextCountry);
            if (nationalNumber.length) {
              onChange?.(nextE164PhoneNumber);
            }
          }}
          css={{width: 'fit'}}
        >
          {!countryAllowlist.includes(country) ? (
            <option value={country} disabled>
              {country}
            </option>
          ) : null}
          {countryAllowlist.map((countryCode) => (
            <option value={countryCode} key={countryCode}>
              {countryCode}
            </option>
          ))}
        </Select>
      ) : null}
      <view.div
        css={{
          marginLeft: countryAllowlist.length > 1 ? 'small' : 0,
          width: '20ch',
          stack: 'x',
          alignY: 'center',
        }}
      >
        <view.span
          // TODO: SAIL-3215 no hardcoded strings
          aria-label="Country calling code"
          inherits={subviews.countryCallingCode}
          css={{
            marginRight: 'small',
            userSelect: 'none',
            font: `label.${size}`,
          }}
        >
          +{countryCallingCode}
        </view.span>
        <TextField
          type="tel"
          ref={phoneNumberInputRef}
          inherits={subviews.phoneNumberInput}
          // TODO: SAIL-3215 no hardcoded strings
          aria-label="Phone number"
          value={formattedNumber}
          placeholder={placeholder}
          autoComplete="tel-national"
          autoFocus={autoFocus}
          readOnly={readOnly}
          onChange={(e) => {
            const input = e.target as HTMLInputElement;
            const cursorIndex = input.selectionStart ?? undefined;

            const next = parseValue(
              // If a fully-formed e.164 number was pasted in, use that.
              // Otherwise, prepend the current country code.
              isPossibleE164PhoneNumber(input.value)
                ? input.value
                : `+${countryCallingCode} ${input.value}`,
              country,
            );

            if (
              // Reject any inputs that produce the same normalized value (e.g. attempting to delete
              // a formatting character, typing a non-digit, etc.)
              next.e164PhoneNumber === value ||
              // Reject any input additions that cause the value to lose its formatting.
              // (Workaround for libphonenumber-js behavior of accepting extra digits and
              // losing the formatting, e.g. (800) 555-1234 => 80055512345)
              (value.length < next.e164PhoneNumber.length &&
                formattedNumber.match(/\D+/) &&
                !next.formattedNumber.match(/\D+/))
            ) {
              if (cursorIndex !== undefined) {
                const diffLength = input.value.length - formattedNumber.length;
                setSelection(cursorIndex - Math.max(0, diffLength));
              }
              return;
            }

            // React doesn't know what to do with the cursor when updating a controlled input
            // so it just defaults it to the end of the input when it receives a new controlled value.
            // To maintain consistency when a user edits the value from somewhere in the middle,
            // we need to restore the cursor position to be after the same number of digits as before.
            const numDigitsBeforeCursor = digitCount(
              input.value.slice(0, cursorIndex),
            );

            // Get the set of digits and their indexes in the upcoming formatted input string.
            const matchedDigits = [...next.formattedNumber.matchAll(/\d/g)];

            const previousDigitCount = digitCount(input.value);
            const nextDigitCount = matchedDigits.length;

            // It's possible that some characters (such as leading digits) got stripped
            // so we need to account for them when setting the next cursor position.
            const digitCountDelta = previousDigitCount - nextDigitCount;

            // If the cursor precedes all digits (e.g. user deleted all input from the cursor
            // to the start of the value), keep the cursor at the start of the input.
            // Otherwise, place it after the same number of digits as before.
            setSelection(
              numDigitsBeforeCursor === 0
                ? 0
                : (matchedDigits[numDigitsBeforeCursor - digitCountDelta - 1]
                    ?.index ?? 0) + 1,
            );

            setValue(next.e164PhoneNumber);

            if (value !== next.e164PhoneNumber) {
              onChange?.(
                next.nationalNumber.length ? next.e164PhoneNumber : '',
              );
            }
          }}
        />
      </view.div>
      <view.input
        type="hidden"
        name={name}
        // we don't want value to ever be just the country calling code
        // since that would make it more difficult to do `required` field validation
        value={nationalNumber.length ? e164PhoneNumber : ''}
        disabled={disabled}
      />
    </CompositeField>
  );
});
