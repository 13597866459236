import {infoSvg as Icon__small__infoSvg} from '../Icon__small/Icon__small--info.js'
import {infoSvg as Icon__xsmall__infoSvg} from '../Icon__xsmall/Icon__xsmall--info.js'

export var info = {
  name: 'info',
  category: 'icon',
  colorable: true,
  svg: Icon__small__infoSvg,
  alt: {
    Icon__small: Icon__small__infoSvg,
    Icon__xsmall: Icon__xsmall__infoSvg
  },
  combined: ['Icon__small', 'Icon__xsmall']
};
