import {cancelSvg as Icon__small__cancelSvg} from '../Icon__small/Icon__small--cancel.js'
import {cancelSvg as Icon__xsmall__cancelSvg} from '../Icon__xsmall/Icon__xsmall--cancel.js'
import {cancelSvg as Icon__xxsmall__cancelSvg} from '../Icon__xxsmall/Icon__xxsmall--cancel.js'

export var cancel = {
  name: 'cancel',
  category: 'icon',
  colorable: true,
  svg: Icon__small__cancelSvg,
  alt: {
    Icon__small: Icon__small__cancelSvg,
    Icon__xsmall: Icon__xsmall__cancelSvg,
    Icon__xxsmall: Icon__xxsmall__cancelSvg
  },
  combined: ['Icon__small', 'Icon__xsmall', 'Icon__xxsmall']
};
