import {css, view} from '@sail/ui';
import * as React from 'react';

import BottomSheetV2 from 'gelato/frontend/src/components/BottomSheetV2';
import AreYouSure from 'gelato/frontend/src/components/Link/BottomSheet/AreYouSure';
import Continue from 'gelato/frontend/src/components/Link/BottomSheet/ContinueWithLink';
import SaveId from 'gelato/frontend/src/components/Link/BottomSheet/SaveId';
import SignUp from 'gelato/frontend/src/components/Link/BottomSheet/SignUp';
import {BottomSheetStep} from 'gelato/frontend/src/controllers/states/NetworkedIdentityState';
import analytics from 'gelato/frontend/src/lib/analytics';
import {AnalyticsEventName} from 'gelato/frontend/src/lib/analytics/types';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

export default function BottomSheet() {
  const {appState} = useAppController();

  const {bottomSheetStep: step} = appState.networkedIdentity;

  const Component = stepToComponentMap[step];

  React.useEffect(() => {
    analytics.viewed(stepToAnalyticsEventMap[step]);
  }, [step]);

  return (
    <BottomSheetV2
      data-testid="link-save-id-sheet"
      bottomSheetStyles={Styles.bottomSheetStyles}
    >
      <view.div uses={[Styles.body]}>
        <Component />
      </view.div>
    </BottomSheetV2>
  );
}

const stepToComponentMap = {
  [BottomSheetStep.SaveId]: SaveId,
  [BottomSheetStep.Continue]: Continue,
  [BottomSheetStep.SignUp]: SignUp,
  [BottomSheetStep.AreYouSure]: AreYouSure,
};

interface AnalyticsEventMapping {
  [key: string]: AnalyticsEventName;
}

const stepToAnalyticsEventMap: AnalyticsEventMapping = {
  [BottomSheetStep.SaveId]: 'linkBottomSheetSaveId',
  [BottomSheetStep.Continue]: 'linkBottomSheetContinue',
  [BottomSheetStep.SignUp]: 'linkBottomSheetSignUp',
  [BottomSheetStep.AreYouSure]: 'linkBottomSheetAreYouSure',
};

const Styles = {
  body: css({
    gap: 'large',
    stack: 'y',
  }),
  bottomSheetStyles: css({
    paddingY: 'large',
    gap: 'none',
  }),
};
