import useFeatureFlags from 'gelato/frontend/src/lib/hooks/useFeatureFlags';
import useSession from 'gelato/frontend/src/lib/hooks/useSession';

export default function usePageLayoutPortrait(): boolean {
  const session = useSession();
  const flags = useFeatureFlags();

  // Verification Flows always uses the portrait layout
  if (session?.creationMethod === 'verification_link') {
    return true;
  }

  // Check if the holdout flag for the new layout is enabled for this merchant
  // or if we can use the portrait page layout

  if (!flags || flags?.includes('idprod_use_page_layout_portrait_holdback')) {
    // If the holdout flag is enabled, we can't use the portrait layout.
    return false;
  }
  return true;
}
