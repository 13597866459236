import type {LayerContextType} from './layer';
import {RESERVED_NONMODAL_LAYER} from './constants';

function isIsolated(layer: LayerContextType): boolean {
  if (layer.name && layer.sublayers) {
    const layerConfig = layer.sublayers.find((l) => l.name === layer.name);
    return !!(layerConfig && layerConfig.isolated);
  }
  return false;
}

function getIsolatedZIndex(root: LayerContextType, layerName: string) {
  const idx = root.sublayers?.findIndex((d) => d.name === layerName) || 0;
  // It's important to use the "mounted" overlay count instead of the
  // normal overlay count because we want to ignore animations and
  // bump it up above the component the entire time it's mounted,
  // including while it's animating out
  return root.mountedOverlayCount * 100 + idx + 1;
}

// This function generates a z-index based on the layer stack. This will
// deterministically order all the layers based on our system:
//
// * Nested modal layers will render on top of each other in the order of the
//   parent->child relationship. We do this by incrementing the z-index by 100
//   for each child modal layer.
// * Non-modal layers will render as last-mount-wins, but always render on the
//   modal layer that created them. We do this by using the z-index of the
//   closest modal layer and incrementing it by 1
// * Non-modal layers given an explicit name will be ordered based on the given
//   layer name configuration. Layers without an explicit config will always
//   render on top of explicitly ordered non-modal layers.
// * An "isolated" layer may be configured which is a special layer config which
//   renders non-modal layers on top of all modal layers
export function getZIndex(layer: LayerContextType): number {
  if (layer.parent == null) {
    return 0;
  }

  // We find the root layer as well as counting modal layers along the way. Why
  // not use `overlayCount` on the root? We could, but that is updated in an
  // effect so we go through this to get the right depth on first render.
  let modalIndex = 0;
  let nonmodalIndex = 0;
  let isolatedLayer = null;
  let current: LayerContextType = layer;
  while (current) {
    if (current.type === 'root') {
      break;
    } else if (current.name === 'modal') {
      modalIndex++;
    } else if (isIsolated(current) && isolatedLayer == null) {
      isolatedLayer = current.name;
    } else if (
      current.parent != null &&
      // We only want to count non-modals on the top-most modal
      modalIndex === 0
    ) {
      nonmodalIndex++;
    }

    // This looks similar to the check above, but combining them is awkard
    if (current.parent == null) {
      break;
    }
    current = current.parent;
  }

  const root = current;
  const baseZ = modalIndex * 100;

  if (layer.name === 'modal') {
    return baseZ;
  }

  if (layer.name && layer.name !== RESERVED_NONMODAL_LAYER && layer.sublayers) {
    const idx = layer.sublayers.findIndex((d) => d.name === layer.name);

    if (idx !== -1) {
      const layerConfig = layer.sublayers[idx];

      // If it's an isolated layer, we want to render over all existing modal
      // layers, even if they were created in sibling components. Using the
      // `overlayCount` accounts for all modals in the world.
      //
      // TODO: Ideally we'd allow this layer to create new modal layers that
      // render on top of it. We need more sophisticated tracking to do that,
      // however.
      if (layerConfig.isolated) {
        return getIsolatedZIndex(root, layer.name);
      }

      return baseZ + idx + 1;
    }
  }

  // If rendering within an "isolated" layer we need to take into
  // account that parent layer's z-index because it stays on top of
  // all modals
  let isolatedIndex = 0;
  if (isolatedLayer) {
    isolatedIndex = getIsolatedZIndex(root, isolatedLayer);
  }

  // By default, non-modal layers are rendered on top of explicitly ordered layers
  // (thus 50) but below any modal layers on top of it (which would
  // increment by 100)
  return baseZ + 50 + nonmodalIndex + isolatedIndex;
}
