import {view} from '@sail/ui';
import * as React from 'react';

export default function LinkLogo({type = 'black'}: LinkLogoProps) {
  return type === 'black' ? (
    <view.svg
      css={{transform: 'scale(0.825)'}}
      width="72"
      height="24"
      viewBox="0 0 72 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.12 3.67683C36.12 2.54882 37.0704 1.63202 38.1888 1.63202C39.3072 1.63202 40.2576 2.55362 40.2576 3.67683C40.2576 4.80003 39.3408 5.74563 38.1888 5.74563C37.0368 5.74563 36.12 4.82883 36.12 3.67683Z"
        fill="#011E0F"
      />
      <path
        d="M29.9808 1.92001H33.5808V22.08H29.9808V1.92001Z"
        fill="#011E0F"
      />
      <path d="M40.008 7.68001H36.3792V22.08H40.008V7.68001Z" fill="#011E0F" />
      <path
        d="M66.0961 14.3904C68.8273 12.7104 70.6848 10.2096 71.4192 7.67524H67.7904C66.8448 10.0944 64.6752 11.9136 62.2896 12.6864V1.91523H58.6608V22.0752H62.2896V16.08C65.0592 16.7712 67.248 19.1664 67.9968 22.0752H71.6496C71.0928 19.0224 69.0049 16.1664 66.0961 14.3904Z"
        fill="#011E0F"
      />
      <path
        d="M46.44 9.29283C47.3904 8.03043 49.2432 7.29602 50.7456 7.29602C53.5488 7.29602 55.8672 9.34563 55.872 12.4416V22.0752H52.2432V13.2432C52.2432 11.9712 51.6768 10.5024 49.8384 10.5024C47.6784 10.5024 46.4352 12.4176 46.4352 14.6592V22.0848H42.8064V7.68962H46.44V9.29283Z"
        fill="#011E0F"
      />
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37257 18.6274 0 12 0C5.37259 0 0 5.37257 0 12C0 18.6274 5.37259 24 12 24Z"
        fill="#011E0F"
      />
      <path
        d="M11.4479 4.80005H7.74707C8.46707 7.80965 10.5695 10.3824 13.1999 12C10.5647 13.6176 8.46707 16.1904 7.74707 19.2H11.4479C12.3647 16.416 14.9039 13.9968 18.0239 13.5024V10.4929C14.8991 10.0033 12.3599 7.58405 11.4479 4.80005Z"
        fill="white"
      />
    </view.svg>
  ) : (
    // SVG pulled from Link "Atoms" figma:
    // https://www.figma.com/design/roHIQ86Mz1s4E6cffNm0Cy/%F0%9F%A6%8B-Distinctly-Link-in-PE-%26-CPL?node-id=8022-19825&node-type=frame&t=QAHLKxxS6Pjzk9yv-0
    <view.svg
      width="71"
      height="22"
      viewBox="0 0 71 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16274_61340)">
        <path
          d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92486 0 0 4.92487 0 11C0 17.0751 4.92486 22 11 22Z"
          fill="#00D66F"
        />
        <path
          d="M10.4908 4.40149H7.09863C7.76047 7.16023 9.68481 9.52142 12.0984 11.0024C9.68262 12.4833 7.76047 14.8445 7.09863 17.6032H10.4908C11.3296 15.052 13.658 13.0556 16.5194 12.6034V9.40566C13.6602 8.95351 11.3317 6.9549 10.4908 4.40367V4.40149Z"
          fill="#011E0F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.4755 1.49542C33.4467 1.49542 32.5752 2.34074 32.5752 3.36953C32.5752 4.42453 33.4205 5.26985 34.4755 5.26985C35.5305 5.26985 36.3758 4.39832 36.3758 3.36953C36.3758 2.34074 35.5043 1.49542 34.4755 1.49542ZM36.1399 7.04129H32.8133V20.2452H36.1399V7.04129ZM30.2516 1.75983H26.9512V20.2431H30.2516V1.75983ZM50.7964 12.0588V20.2454H47.4698V12.8779C47.4698 10.7919 46.6769 9.92038 44.9339 9.92038C43.1908 9.92038 42.0288 11.2397 42.0288 13.59V20.2433H38.7021V7.03932H42.0288V8.75616C42.9003 7.5417 44.2742 6.77502 46.334 6.77502C49.2129 6.77502 50.7964 8.51807 50.7964 12.0566V12.0588ZM61.9122 20.2431H65.2629C64.7518 17.4451 62.8318 14.8196 60.1692 13.1923C62.6724 11.6546 64.3761 9.35891 65.0488 7.03922H61.7266C60.855 9.25844 58.8739 10.9229 56.6831 11.6349V1.75983H53.3564V20.2431H56.6831V14.7497C59.219 15.3853 61.2264 17.5761 61.9122 20.2431Z"
          fill="#011E0F"
        />
      </g>
      <defs>
        <clipPath id="clip0_16274_61340">
          <rect width="70.7826" height="22" fill="white" />
        </clipPath>
      </defs>
    </view.svg>
  );
}

type LinkLogoProps = {
  type?: 'green' | 'black';
};
