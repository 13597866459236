import * as React from 'react';
import ReactDOM from 'react-dom';

import styles from './PageModal.module.css';

type PageModalProps = {
  // Whether focus should move into the modal dialog automatically.
  autoFocus?: boolean;
  // Whether the modal dialog should have backdrop (i.e. shadow).
  backdrop?: boolean;
  // The content of the modal dialog.
  children: React.ReactNode;
};

const PAGE_MODAL_ROOT_ELEMENT_ID = 'page-modal-root';

const {useEffect, useState} = React;

/**
 * Creates the DOM element that the <PageModal /> will be render at.
 */
function createModalElement(): HTMLDivElement {
  const el = document.createElement('div');
  el.className = styles.pageModal;
  return el;
}

/**
 * Whether an DOM element is focusable. For now, we'd only support Button
 * and Input.
 */
function isFocusable(el: Element): boolean {
  return (
    (el instanceof HTMLButtonElement || el instanceof HTMLInputElement) &&
    !el.disabled
  );
}

/**
 * The Side-effect that focuses the first focusable element when the modal
 * dialog shows if auto-focus is enabled.
 * @param modalElement The element that contains the <PageModal />.
 * @param autoFocus Whether the auto-focus is enabled.
 */
function useAutoFocus(modalElement: HTMLElement, autoFocus: boolean) {
  useEffect(() => {
    // Find the first focusable element.
    if (autoFocus) {
      const timer = setTimeout(() => {
        const els: Array<Element> = Array.from(
          modalElement.querySelectorAll('button, input'),
        ).filter(isFocusable);
        const el = els.pop();
        if (el instanceof HTMLElement) {
          el.focus();
        }
      }, 150);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [autoFocus, modalElement]);
}

/**
 * The Side-effect that toggles the backdrop (i.e. the shadow) of the modal
 * dialog.
 * @param modalElement The element that contains the <PageModal />.
 * @param backdrop Whether the backdrop is visible.
 */
function useBackdrop(modalElement: HTMLElement, backdrop: boolean) {
  useEffect(() => {
    if (backdrop) {
      modalElement.classList.add(styles.withBackDrop);
    } else {
      modalElement.classList.remove(styles.withBackDrop);
    }
  }, [backdrop, modalElement]);
}

/**
 * The Side-effect that attaches the containing DOM element of the <PageModal />
 * to the DOM tree.
 */
function usePageModalElement(): HTMLElement {
  const [modalElement] = useState<HTMLElement>(createModalElement);

  useEffect(() => {
    const modalRoot = document.getElementById(PAGE_MODAL_ROOT_ELEMENT_ID);
    if (!modalRoot) {
      throw new Error(`Unable to find <PageModalRoot/ >`);
    }
    modalRoot.appendChild(modalElement);
    return () => modalElement.remove();
  }, [modalElement]);

  return modalElement;
}

/**
 * The root element that contains the <PageModal />.
 */
export function PageModalRoot() {
  return (
    <div id={PAGE_MODAL_ROOT_ELEMENT_ID} className={styles.pageModalRoot} />
  );
}

/**
 * The containing UI for page level modal dialog.
 */
export default function PageModal(props: PageModalProps) {
  const {autoFocus, backdrop, children} = props;
  const modalEl = usePageModalElement();
  useAutoFocus(modalEl, !!autoFocus);
  useBackdrop(modalEl, !!backdrop);
  return ReactDOM.createPortal(
    <div className={styles.pageModalBody}>{children}</div>,
    modalEl,
  );
}
