import {buildKey, StorageBackup} from 'gelato/frontend/src/lib/Storage';

type params = {
  initialState?: any;
  errorLimit?: number;
};

class ErrorCap {
  store: StorageBackup;

  errorLimit: number;

  static createErrorKey = (error: Error) =>
    buildKey(`${error.name}_${error.message}`);

  constructor({initialState = {}, errorLimit = 5}: params = {}) {
    this.store = new StorageBackup(true);
    for (const [key, count] of Object.entries(initialState)) {
      // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'string'.
      if (key && count && !isNaN(parseInt(count, 10))) {
        // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'string'.
        this.store.setItem(key, parseInt(count, 10).toString());
      }
    }
    this.errorLimit = errorLimit;
  }

  getErrorCount = (error: Error): number | null | undefined => {
    const errorKey = ErrorCap.createErrorKey(error);
    const existingCount = this.store.getItem(errorKey);
    return existingCount ? parseInt(existingCount, 10) : undefined;
  };

  incrementErrorCount = (error: Error): void => {
    const errorKey = ErrorCap.createErrorKey(error);
    const existingCount = this.store.getItem(errorKey);
    if (existingCount) {
      this.store.setItem(
        errorKey,
        (parseInt(existingCount, 10) + 1).toString(),
      );
    } else {
      this.store.setItem(errorKey, '1');
    }
  };

  forgetError = (error: Error): void => {
    const errorKey = ErrorCap.createErrorKey(error);
    this.store.removeItem(errorKey);
  };

  shouldDropError = (error: Error): boolean => {
    const errorKey = ErrorCap.createErrorKey(error);
    const existingCount = this.store.getItem(errorKey);
    if (existingCount) {
      return parseInt(existingCount, 10) > this.errorLimit;
    }
    return false;
  };
}

export default ErrorCap;
