import {useUpdatedRef} from '@sail/react';
import {once} from '@sail/utils';
import {useMemo} from 'react';
import {UninitializedObservabilityProviderError} from 'src/internal/common/errors';
import logErrorInDev from 'src/internal/common/logErrorInDev';
import {useObservabilityApi} from 'src/internal/provider/ObservabilityApiProvider';

import type {IMetrics} from 'src/internal/metrics/types';

const logUninitializedErrorOnce = once(() => {
  logErrorInDev(new UninitializedObservabilityProviderError('useMetrics'));
});

/**
 * React hook to report contextualized metrics to monitoring services.
 *
 * It returns an object with the metrics methods contextualized with the
 * service, timestamp, and other metrics configuration provided by the
 * closest `<ObservabilityProvider />`.
 *
 * @example Basic {{include "./examples/useMetrics.basic.tsx"}}
 *
 * @see https://sail.stripe.me/apis/observability/useMetrics
 */
export default function useMetrics(): IMetrics {
  const apiRef = useUpdatedRef(useObservabilityApi());

  return useMemo((): IMetrics => {
    if (!apiRef.current) {
      logUninitializedErrorOnce();
    }

    return {
      increment(...args: Parameters<IMetrics['increment']>): void {
        return apiRef.current?.metrics.increment(...args);
      },

      gauge(...args: Parameters<IMetrics['gauge']>): void {
        return apiRef.current?.metrics.gauge(...args);
      },

      set(...args: Parameters<IMetrics['set']>): void {
        return apiRef.current?.metrics.set(...args);
      },
    };
  }, [
    apiRef, // This is a ref object, so it never changes and thus the returned object is stable.
  ]);
}
