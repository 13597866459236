import analytics from 'gelato/frontend/src/lib/analytics';

import type {AnalyticsParams} from 'gelato/frontend/src/lib/analytics/types';

/**
 * Higher order function that wraps a function and sends an analytics event with the duration of the function call.
 * @param name The name of the function to be used in the analytics event.
 * @param fn The function to be wrapped.
 * @param other Additional parameters to be sent with the analytics event.
 * @returns A wrapped function that sends an analytics event with the duration of the function call.
 */
export default function timedFunction<T extends (...args: any[]) => any>(
  name: string,
  fn: T,
  other: AnalyticsParams = {},
): (...args: Parameters<T>) => ReturnType<T> {
  return (...args: Parameters<T>): ReturnType<T> => {
    const start = performance.now();
    const result = fn(...args);
    const end = performance.now();
    const durationMs = end - start;
    analytics.track('timedFunction', {
      function_name: name,
      duration_ms: durationMs,
      ...other,
    });
    return result;
  };
}
