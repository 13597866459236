import {css} from '../css';

export const visuallyHidden = css({
  border: '0',
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 'space.1',
  margin: '0 -1px -1px 0',
  overflow: 'hidden',
  padding: 'space.0',
  position: 'absolute',
  width: 'space.1',
  maxWidth: 'space.1',
  whiteSpace: 'nowrap',
});
