import type {Key} from 'react';
import type {ListState} from '../deprecated/components/collections/react-aria-list';

export function walkForKey<T>(key: Key, state: ListState<T>): Key | null {
  const item = state.collection.getItem(key);
  if (item) {
    if (item.type === 'item') {
      return item.key;
    } else if (item.nextKey) {
      return walkForKey(item.nextKey, state);
    }
  }
  return null;
}
