export const tokensTypography = {
  typeface: {
    ui: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    monospace: `'Source Code Pro', Menlo, Monaco, monospace`,
  },
  weight: {
    regular: 400,
    semibold: 600,
    bold: 700,
  },
};
