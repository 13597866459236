/**
 * Query-string-serializes the provided object.
 */
export const serialize = (
  object: Record<string, unknown>,
  scope?: string | null,
): string => {
  let result: Array<any> | Array<string> = [];
  Object.keys(object).forEach((key) => {
    const value = object[key];
    const scopedKey = scope ? `${scope}[${key}]` : key;
    if (value && typeof value === 'object') {
      // @ts-expect-error - TS2345 - Argument of type 'object' is not assignable to parameter of type 'Record<string, unknown>'.
      const nestedResult = serialize(value, scopedKey);
      if (nestedResult !== '') {
        result = [...result, nestedResult];
      }
    } else if (value !== undefined && value !== null) {
      result = [...result, `${scopedKey}=${encodeURIComponent(String(value))}`];
    }
  });
  return result.join('&').replace(/%20/g, '+');
};
