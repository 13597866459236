import hash from '@emotion/hash';

export {hash};

export function assign<T extends {[key: string]: unknown}>(
  target: T,
  source: T,
): void {
  const keys = Object.keys(source);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    target[key as keyof T] = source[key] as T[keyof T];
  }
}

export function concat<T extends Array<unknown>>(target: T, source: T): void {
  for (let i = 0; i < source.length; i++) {
    target.push(source[i]);
  }
}

export function deepMerge<T>(target: T, source: Partial<T>): T {
  const result = {...target} as T;
  const keys = Object.keys(source);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i] as keyof T;
    const value = source[key] as T[keyof T];
    if (typeof value === 'object' && typeof target[key] === 'object') {
      result[key] = deepMerge(target[key], value);
    } else {
      result[key] = value;
    }
  }
  return result;
}

export function escapeRegExp(value: string): string {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function hasOwnProperty<T>(obj: T, property: string): boolean {
  return Object.prototype.hasOwnProperty.call(obj, property);
}

export function shallowEqualArray<T extends Array<unknown>>(
  a: T,
  b: T,
): boolean {
  if (Object.is(a, b)) {
    return true;
  }
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (!Object.is(a[i], b[i])) {
      return false;
    }
  }
  return true;
}

export function sortedIndex(
  list: number[],
  value: number,
  last = false,
): number {
  let low = 0;
  let high = list.length;
  while (low < high) {
    // eslint-disable-next-line no-bitwise
    const mid = (low + high) >>> 1;
    if (last ? list[mid] <= value : list[mid] < value) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }
  return high;
}

export function toNumber(value: string) {
  const number = parseInt(value, 10);
  return isNaN(number) ? 0 : number;
}

export const bleedAutoDisabled = !!(
  typeof window !== 'undefined' &&
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)?.PRELOADED?.flags?.disable_sail_ui_bleed_auto
);

export const bleedAutoUniversalSelectorDisabled = !!(
  typeof window !== 'undefined' &&
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)?.PRELOADED?.flags
    ?.disable_sail_ui_bleed_auto_universal_selector
);
