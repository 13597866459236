import {TextField, view, css} from '@sail/ui';
import * as React from 'react';
import {useIntl} from 'react-intl';
import {isEmail} from 'validator';

import Link from 'gelato/frontend/src/components/FCLink';
import Acknowledgements from 'gelato/frontend/src/components/Link/BottomSheet/Acknowledgements';
import Footer from 'gelato/frontend/src/components/Link/BottomSheet/Footer';
import LinkDescription from 'gelato/frontend/src/components/Link/BottomSheet/LinkDescription';
import LinkHeader from 'gelato/frontend/src/components/Link/BottomSheet/LinkHeader';
import NotNow from 'gelato/frontend/src/components/Link/BottomSheet/NotNow';
import {calculateConsumerAccountLookupError} from 'gelato/frontend/src/components/Link/BottomSheet/utils';
import {
  LinkTermsURL,
  LinkPrivacyUrl,
  LinkAboutURL,
} from 'gelato/frontend/src/components/Link/constants';
import LinkButton from 'gelato/frontend/src/components/Link/LinkButton';
import {messages} from 'gelato/frontend/src/components/Link/messages';
import {
  BottomSheetStep,
  ConsumerAccountLookupError,
} from 'gelato/frontend/src/controllers/states/NetworkedIdentityState';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';

export default function SaveId() {
  const {appState, appController} = useAppController();
  const merchantProvidedEmail =
    appState.session?.collectedData?.individual?.email?.merchantProvidedAddress;
  const consumerSessionEmail =
    appState.networkedIdentity.consumerSession?.email_address;
  const userEnteredEmail = appState.networkedIdentity.consumerEmail;
  const [email, setEmail] = React.useState(
    merchantProvidedEmail || consumerSessionEmail || userEnteredEmail || '',
  );
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const intl = useIntl();

  const handleSaveId = async (email: string) => {
    if (email && isEmail(email)) {
      setLoading(true);
      setError('');
      try {
        await appController.lookupConsumerAccountV2({email});
        setLoading(false);
        appController.setLinkSaveIdBottomStep(BottomSheetStep.Continue);
      } catch (e: any) {
        if (e.message === ConsumerAccountLookupError.NOT_FOUND) {
          setLoading(false);
          appController.setLinkSaveIdBottomStep(BottomSheetStep.SignUp);
        } else {
          setError(e.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const errorMessage = calculateConsumerAccountLookupError(error);

  const formattedError = intl.formatMessage(errorMessage);
  return (
    <>
      <LinkHeader
        title={intl.formatMessage(messages.postUploadPageTitle)}
        subtitle={intl.formatMessage(messages.enterEmailSubheader)}
      />
      <LinkDescription />
      <view.div uses={[Styles.emailInput]}>
        <TextField
          label={intl.formatMessage(messages.bottomSheetEmailAddress)}
          aria-label="Email"
          error={error && formattedError}
          data-testid="link-start-email-input"
          type="email"
          size="large"
          value={email}
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          onChange={(e) => handleEmailInput(e)}
          css={{
            padding: 'none',
          }}
          disabled={loading}
        />
      </view.div>
      <Footer>
        <Acknowledgements>
          {intl.formatMessage(messages.linkBottomSheetSignUpAcknowledgements, {
            terms: (
              <Link href={LinkTermsURL}>
                {intl.formatMessage(messages.linkTerms)}
              </Link>
            ),
            privacyPolicy: (
              <Link href={LinkPrivacyUrl}>
                {intl.formatMessage(messages.linkPrivacyPolicy)}
              </Link>
            ),
            learnMore: (
              <Link href={LinkAboutURL}>
                {intl.formatMessage(messages.learnMore)}
              </Link>
            ),
          })}
        </Acknowledgements>
        <SaveYourId
          email={email}
          loading={loading}
          handleSaveId={handleSaveId}
        />
        <NotNow />
      </Footer>
    </>
  );
}

const Styles = {
  emailInput: css({
    width: 'fill',
  }),
  acknowledgement: css({
    font: 'heading.xsmall.subdued',
    color: 'text.subdued',
  }),
};

function SaveYourId({email, handleSaveId, loading}: SaveYourIdProps) {
  const intl = useIntl();

  const validEmail = email && isEmail(email);
  return (
    <LinkButton
      onPress={() => handleSaveId(email)}
      disabled={!validEmail}
      pending={loading}
      data-testid="link-start-save-id"
    >
      {intl.formatMessage(messages.saveYourId)}
    </LinkButton>
  );
}

type SaveYourIdProps = {
  email: string;
  handleSaveId: (email: string) => void;
  loading: boolean;
};
