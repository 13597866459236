import {debounce} from 'lodash';
import * as React from 'react';

import {usePageLayoutPortrait} from 'gelato/frontend/src/lib/hooks';

import styles from './PageLayout.module.css';

const {useState, useLayoutEffect, useEffect} = React;

// Custom hook to get the current viewport size (width and height)
function useViewportSize() {
  // Initialize state with the current window inner width and height.
  const [size, setSize] = useState<[number, number]>([
    window.innerWidth,
    window.innerHeight,
  ]);

  // Use effect to handle window resize events.
  useEffect(() => {
    const handler = debounce(() => {
      setSize([window.innerWidth, window.innerHeight]);
    });
    window.addEventListener('resize', handler, true);
    return () => window.removeEventListener('resize', handler, true);
  }, [setSize]);

  return size;
}

/**
 * This performs a side effect that updates root element's DOM properties that
 * impacts the page layout.
 */
function usePageLayoutRootDOMProperties(
  layout: string | undefined,
  viewportSize: [number, number],
) {
  useLayoutEffect(() => {
    if (layout) {
      const root = document.documentElement!;
      root.setAttribute('data-page-layout', layout);
      root.style.setProperty(
        '--page-layout-viewport-width',
        `${viewportSize[0]}px`,
      );
      root.style.setProperty(
        '--page-layout-viewport-height',
        `${viewportSize[1]}px`,
      );
      return () => {
        // In case the layout changes, we need to clean up the old layout.
        root.removeAttribute('data-page-layout');
        root.style.removeProperty('--page-layout-viewport-width');
        root.style.removeProperty('--page-layout-viewport-height');
      };
    }
  }, [layout, viewportSize]);
}

// PageLayout component
export default function PageLayout(props: {
  children?: React.ReactNode;
}): JSX.Element {
  const {children} = props;
  const pageLayout = usePageLayoutPortrait() ? 'portrait' : undefined;

  // In Webkit-based browsers, the CSS value `100vh` includes the height
  // of the full window which includes the height of the address bar,
  // which isn't ideal because what we need is the actual viewport size of
  // the web page instead. The solution is to use JS to compute the CSS value
  // instead.
  const viewportSize = useViewportSize();

  usePageLayoutRootDOMProperties(pageLayout, viewportSize);

  return <div className={styles.PageLayout}>{children}</div>;
}
