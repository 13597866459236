import * as React from 'react';
import {IntlProvider} from 'react-intl';

import {LocaleContext} from 'gelato/frontend/src/lib/contexts';
import {
  getLocale,
  importLocaleJsons,
  DEFAULT_LOCALE,
} from 'gelato/frontend/src/lib/locale';
import {localeReducer} from 'gelato/frontend/src/lib/reducers';
import Storage from 'gelato/frontend/src/lib/Storage';

type WithLocaleContextProps = {
  locale: string | null | undefined;
  children: React.ReactNode;
};

const WithLocaleContext = ({
  locale: externalLocale,
  children,
}: WithLocaleContextProps) => {
  const storedLocale = Storage.getLocale();

  const [locale, dispatchLocale] = React.useReducer(
    localeReducer,
    getLocale(storedLocale || externalLocale || DEFAULT_LOCALE),
  );

  React.useEffect(() => {
    // If locale changed, store it.
    if (typeof locale === 'string' && locale !== storedLocale) {
      Storage.setLocale(locale);
    }
  }, [locale, storedLocale]);

  const localeValue = React.useMemo(() => {
    return {locale, dispatchLocale};
  }, [locale, dispatchLocale]);

  // load translated strings from the stored locale or browser locale.
  const [appMessages, setAppMessages] = React.useState({});
  React.useEffect(() => {
    const browserLocale = getLocale(storedLocale || externalLocale);
    importLocaleJsons(browserLocale).then((messages) => {
      if (document.documentElement) {
        document.documentElement.lang = browserLocale;
      }
      // @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.
      dispatchLocale(browserLocale);
      setAppMessages(messages);
    });
  }, [dispatchLocale, externalLocale, storedLocale]);

  // load translated strings when locale selector changes
  React.useEffect(() => {
    // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type '"en-US" | "bg-BG" | "cs-CZ" | "da-DK" | "de-DE" | "et-EE" | "el-GR" | "en-GB" | "es-ES" | "es-419" | "fr-CA" | "fr-FR" | "fil-PH" | "hr-HR" | "hu-HU" | "id-ID" | "it-IT" | "ja-JP" | ... 20 more ... | "zh-Hant-TW"'.
    importLocaleJsons(locale).then((messages) => {
      setAppMessages(messages);
    });
  }, [locale]);

  return (
    // @ts-expect-error - TS2322 - Type '{ locale: unknown; dispatchLocale: React.DispatchWithoutAction; }' is not assignable to type 'LocaleState'.
    <LocaleContext.Provider value={localeValue}>
      <IntlProvider
        defaultLocale={DEFAULT_LOCALE}
        // @ts-expect-error - TS2769 - No overload matches this call.
        locale={locale}
        messages={appMessages}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default WithLocaleContext;
