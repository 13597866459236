/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {hash} from '../../../util';
import {VERSION} from '../../../version';
import {DEFAULT_PREFIX, DYNAMIC_LAYER, DYNAMIC_TOKEN_PREFIX} from './constants';
import {serialize} from './serialize';
import {createTokens} from './token';
import {createValue} from './value';
import {createVariants} from './variant';
import type {
  CssAliasShape,
  CssInputShape,
  CssVariantsShape,
  CssSerializer,
  CssSerializerOptions,
  CssProperties,
  CssPluginsShape,
  CssTokensShape,
} from './types';

function createCss<
  Properties = CssProperties,
  Variants extends CssVariantsShape = {},
  Plugins extends CssPluginsShape = {},
  Tokens extends CssTokensShape = {},
  Alias extends CssAliasShape<Tokens> = {},
>(
  options: CssSerializerOptions<Variants, Plugins, Tokens, Alias>,
): CssSerializer<Properties, Variants, Plugins, Tokens, Alias> {
  const tokens = createTokens(options.tokens, {prefix: DYNAMIC_TOKEN_PREFIX});

  const css = ((...properties: CssInputShape[]) =>
    serialize(css as any, properties)) as unknown as CssSerializer<
    Properties,
    Variants,
    Plugins,
    Tokens,
    Alias
  >;
  css.configure = (opts = {}) => {
    const o = typeof opts === 'function' ? opts(css) : opts;
    return createCss({
      ...options,
      ...o,
      alias: {...options.alias, ...o.alias},
      variants: {...options.variants, ...o.variants},
      plugins: {...options.plugins, ...o.plugins},
      tokens: {...options.tokens, ...o.tokens},
    } as any);
  };

  css.variants = createVariants('', options.variants, {
    cascading: true,
    prefix: options.prefix,
  });
  css.hash = hash(JSON.stringify(options));
  css.options = options;
  css.value = (value) => createValue(tokens, value);
  css.token = tokens;
  css[DYNAMIC_LAYER] =
    options.layer === DYNAMIC_LAYER
      ? css
      : createCss({...options, layer: DYNAMIC_LAYER});

  return css;
}

export const css = createCss({
  alias: {},
  variants: {},
  layer: '',
  plugins: {},
  prefix: DEFAULT_PREFIX,
  tokens: {},
  version: VERSION,
});
