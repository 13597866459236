import {tokens, assignTokens} from '@sail/ui';
import {useState, useEffect} from 'react';

import {generateHueScaleFromBrandColor} from 'gelato/frontend/src/components/theming/generateHueScaleFromBrandColor';

function createTheme(merchantButtonColor: string) {
  // Check if merchantButtonColor is a valid hex color and return
  // empty theme if it is not.
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$$/.test(merchantButtonColor)) {
    return [];
  }

  const {scale, inputAtLevel} = generateHueScaleFromBrandColor({
    brandColor: merchantButtonColor,
  });

  // Merchant-specified brand color using derived scale
  const brandColor = scale[inputAtLevel];

  // Alternate colors based on merchant-specified brand color using derived scale
  const bcAlt100 =
    scale[inputAtLevel >= 400 ? inputAtLevel - 100 : inputAtLevel + 100];
  const bcAlt200 =
    scale[inputAtLevel >= 400 ? inputAtLevel - 200 : inputAtLevel + 200];

  // Foreground colors on top of the merchant specified brand colors
  const onBrandColor = scale[inputAtLevel >= 400 ? 0 : 900];
  const onBrandColorPressed = scale[inputAtLevel >= 400 ? 100 : 800];

  const onActionPrimary = {
    $default: onBrandColor,
    hovered: onBrandColor,
    pressed: onBrandColorPressed,
    focused: onBrandColor,
    disabled: onBrandColor,
  };

  return [
    assignTokens(tokens.color, {
      brand: scale,
      background: {
        action: {
          primary: {
            $default: brandColor,
            hovered: brandColor,
            pressed: bcAlt100,
            focused: brandColor,
            disabled: brandColor,
          },
        },
      },
      border: {
        action: {
          primary: {
            $default: brandColor,
            hovered: bcAlt200,
            pressed: bcAlt200,
            focused: brandColor,
            disabled: brandColor,
          },
        },
      },
      text: {
        onAction: {
          primary: onActionPrimary,
        },
        action: {
          primary: {
            $default: brandColor,
            hovered: bcAlt100,
            pressed: bcAlt200,
            focused: brandColor,
            // This is based on Sail's hard coded values
            disabled: tokens.color.neutral[400],
          },
        },
      },
      icon: {
        onAction: {
          primary: onActionPrimary,
        },
      },
    }),
  ];
}

export default function useThemeOverride(merchantButtonColor: string) {
  const [theme, setTheme] = useState(() => createTheme(merchantButtonColor));

  useEffect(() => {
    const newTheme = createTheme(merchantButtonColor);
    setTheme(newTheme);
  }, [merchantButtonColor]);

  return theme;
}
