import * as React from 'react';

import Storage from 'gelato/frontend/src/lib/Storage';

type WarnProps = {
  enabled: boolean;
  children: React.ReactNode;
};

export function WarnOnUnload(props: WarnProps) {
  const {enabled, children} = props;
  React.useLayoutEffect(() => {
    const eventListener = (e: any) => {
      // HACK - we reload the page if we hit a not-readable webcam error.
      // Don't want the beforeunload handler to stop the page reload.
      const notReadableErrorCount = Storage.getNotReadableWebcamErrorCount();
      if (enabled && notReadableErrorCount !== 1) {
        // preventDefault makes it so that the user is prompted to confirm that they want to leave
        e.preventDefault();
        // not all browsers support preventDefault so we set returnValue as well as this is meant to
        // trigger the same behavior it is unclear to me if some browsers display this message but
        // chrome and safari do not.
        e.returnValue = 'Are you sure you want to leave before completing?';
      }
    };

    window.addEventListener('beforeunload', eventListener);
    return () => window.removeEventListener('beforeunload', eventListener);
  }, [enabled]);

  return children;
}
