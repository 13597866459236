export const variantsPreferences = {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-contrast
  contrast: {
    more: 'not (prefers-contrast: less)',
    less: '(prefers-contrast: less)',
  },
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
  motion: {
    supported: 'not (prefers-reduced-motion)',
    reduced: '(prefers-reduced-motion)',
  },
};
