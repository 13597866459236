import * as React from 'react';

const {useEffect} = React;

/**
 * The effect to enable mouse wheel to scroll on the element with
 * `overflow: hidden`.
 * @param domRef The ref to the scrollable element.
 */
export default function useMouseWheelToScroll(
  domRef: React.RefObject<HTMLElement>,
): void {
  useEffect(() => {
    const el = domRef.current;
    if (!el) {
      return;
    }
    const onWheel = (event: WheelEvent) => {
      el.scrollBy(0, event.deltaY);
      event.preventDefault();
    };
    el.addEventListener('wheel', onWheel, {passive: false});
    return () => el.removeEventListener('wheel', onWheel, false);
  }, [domRef]);
}
