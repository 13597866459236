import React from 'react';
import {defineMessages} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import business from 'gelato/frontend/src/images/business.svg';
import {CSS_TRANSITION_DURATION} from 'gelato/frontend/src/lib/constants';
import {BreakpointContext} from 'gelato/frontend/src/lib/contexts';
import {useBranding} from 'gelato/frontend/src/lib/hooks';
import {maybe} from 'gelato/frontend/src/lib/utils';
import Box from 'sail/Box';
import Group from 'sail/Group';
import Icon from 'sail/Icon';
import {Headline} from 'sail/Text';
import Text from 'sail/Text/Text';

import styles from './CardHeader.module.css';

const messages = defineMessages({
  verificationSubheading: {
    id: 'verification.subheading',
    description:
      'Text with a lock icon to the left of it describing secure identity verification',
    defaultMessage: 'Secure identity verification',
  },
});

type Props = {
  platformIcon?: string;
  platformName?: string;
};

const CardHeader = (props: Props) => {
  const breakpoint = React.useContext(BreakpointContext);
  const branding = useBranding();
  const {platformIcon: propsPlatformIcon, platformName: propsPlatformName} =
    props;
  const platformIcon =
    propsPlatformIcon || maybe(branding, (branding) => branding.platformIcon);
  const platformName =
    propsPlatformName || maybe(branding, (branding) => branding.platformName);

  return (
    <Box
      divider={{color: 'light', width: 1, side: 'bottom'}}
      padding={{bottom: 20}}
      background={breakpoint === 'mobile' ? 'offset' : undefined}
    >
      <Group
        alignItems="center"
        direction="vertical"
        justifyContent="center"
        spacing={12}
      >
        <Box
          style={{
            marginTop: breakpoint === 'mobile' ? '32px' : '-32px',
          }}
        >
          <Box className={styles.background}>
            <img
              alt="business icon"
              src={platformIcon || business}
              className={styles.platformIcon}
            />
          </Box>
        </Box>
        <Group
          alignItems="center"
          direction="vertical"
          justifyContent="center"
          spacing={4}
        >
          <Headline
            // @ts-expect-error - TS2322 - Type '{ children: string; style: { opacity: number; transition: string; }; }' is not assignable to type 'IntrinsicAttributes & CommonProps & SettableTextProps'.
            style={{
              opacity: platformName ? 1 : 0,
              transition: `all ${CSS_TRANSITION_DURATION} ease-in-out`,
            }}
          >
            {platformName || 'Loading'}
          </Headline>
          <Box flex={{direction: 'row'}}>
            <Icon size={12} icon="lock" style={{paddingRight: '4px'}} />
            <Text size={12} typeface="upper">
              <Message {...messages.verificationSubheading} />
            </Text>
          </Box>
        </Group>
      </Group>
    </Box>
  );
};

export default CardHeader;
