// https://webpack-v3.jsx.app/guides/public-path/#on-the-fly
if (
  process.env.NODE_ENV === 'production' &&
  !process.env.IS_CI &&
  !process.env.PUPPETEER
) {
  const publicPath = JSON.parse(
    /* $FlowFixMe */
    // @ts-expect-error - TS2531 - Object is possibly 'null'. | TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
    document.getElementById('root').getAttribute('data-config'),
  )['STATIC_CDN_PREFIX'];

  __webpack_public_path__ = `${publicPath}/`; // eslint-disable-line
}

export {};
