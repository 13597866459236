import clsx from 'clsx';
import * as React from 'react';

import {DEFAULT_BRANDING_BUTTON_COLOR} from 'gelato/frontend/src/lib/constants';
import {useBranding} from 'gelato/frontend/src/lib/hooks';
import Button from 'sail/Button';
import {PressableThemeProvider} from 'sail/Pressable';
import {createPressableTheme} from 'sail/Pressable/theme';

import styles from './ThemableButton.module.css';

import type {ButtonProps} from 'sail/Button/Button';

type Props = ButtonProps & {
  buttonColor?: string;
};
const ThemableButton = ({
  className,
  buttonColor: propsButtonColor,
  ...props
}: Props) => {
  const branding = useBranding();
  const brandingButtonColor = branding
    ? branding.buttonColor
    : DEFAULT_BRANDING_BUTTON_COLOR;
  const buttonColor = propsButtonColor || brandingButtonColor;

  const theme = createPressableTheme(buttonColor);
  return (
    <PressableThemeProvider theme={theme}>
      <Button
        {...props}
        className={clsx(styles.ctaButton, className)}
        size="large"
      />
    </PressableThemeProvider>
  );
};

export default ThemableButton;
