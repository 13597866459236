import {urlRedirect} from '@stripe-internal/safe-links';
import * as React from 'react';

import {SharedSessionLoadingScreen} from 'gelato/frontend/src/components/WithSessionContext';
import analytics from 'gelato/frontend/src/lib/analytics';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import getWelcomePage from 'gelato/frontend/src/lib/consent_utils';
import getRouter from 'gelato/frontend/src/lib/localRouter';
import useLinkCode from 'gelato/frontend/src/services/hooks/useLinkCode';

import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';
import type {WelcomeRouterPath} from 'gelato/frontend/src/lib/consent_utils';

type StartRouterPath = WelcomeRouterPath | '/testing';

const {useEffect, useRef} = React;

const routeToFirstPage = (data: GetSessionQueryData) => {
  const {operatingMode, livemode} = data.session;

  let firstPage: StartRouterPath = getWelcomePage(data);
  if (livemode === false && operatingMode !== 'secondary') {
    firstPage = '/testing';
  }
  analytics.track('startPageRedirecting', {
    timeStartRedirecting: Date.now(),
    startPageRedirectedTo: firstPage,
  });

  getRouter().replace(firstPage);
};

const StartPage = () => {
  const [setLocation, {data, error}] = useLinkCode();
  const [sessionStarted, setSessionStarted] = React.useState<boolean>(false);
  const startPageLoaded = useRef(false);

  useEffect(() => {
    if (startPageLoaded.current) {
      return;
    }
    analytics.track('startPageLoaded', {
      timeStartLoaded: Date.now(),
    });
    startPageLoaded.current = true;
  }, [startPageLoaded]);

  useEffect(() => {
    setLocation(window.location);
  }, [setLocation]);

  useEffect(() => {
    const refreshUrl = data && data.refreshUrl;
    if (refreshUrl) {
      urlRedirect(encodeURI(refreshUrl));
    } else {
      setSessionStarted(!error && !!data);
    }
  }, [data, error, setSessionStarted]);

  if (error) {
    throw error;
  }

  return (
    <SharedSessionLoadingScreen
      sessionStarted={sessionStarted}
      onReady={routeToFirstPage}
    />
  );
};

setComponentConfig(StartPage, {skipApp: true});

export default StartPage;
