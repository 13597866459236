import Storage from 'gelato/frontend/src/lib/Storage';

export const NO_TOKEN_ERROR_MESSAGE = 'No session token';
export const EXPIRED_TOKEN_ERROR_MESSAGE = 'Expired token';
export const NO_TOKEN_PASTED_URL = 'Invalid session due to pasted URL';
export const NO_TOKEN_OLD_TOKEN = 'Invalid session with old token';
export const NO_TOKEN_INVALID = 'Invalid session with expired/bad token';
export const NO_TOKEN_NETWORK_ERROR = `Network error: ${NO_TOKEN_ERROR_MESSAGE}`;
export const UNEXPECTED_START_STATE = 'Unexpected start state';
// This message must match the message in gelato/service/route_handlers.ts
// so that the UI will indicate to the user that their IP country is not supported.
export const RESTRICTED_IP_COUNTRY_ERROR = 'IP country not supported';

export class SessionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'SessionError';
  }
}

export class NoTokenError extends SessionError {
  constructor() {
    super(NO_TOKEN_ERROR_MESSAGE);
  }
}

export const getStatusCode = (error: any) =>
  (error && error.networkError && error.networkError.statusCode) ||
  (error && error.statusCode);

// Returns true if the session has an expiration date and
// we are already past that expiration.
export const isSessionExpired = (): boolean => {
  try {
    const sessionExpiresAt = Storage.getSessionExpiresAt();
    return !!(
      sessionExpiresAt && parseInt(sessionExpiresAt, 10) - Date.now() <= 0
    );
  } catch (e: any) {
    return false;
  }
};

export const isInvalidUpdateError = (error: any) =>
  getStatusCode(error) === 409;

export const isExpiredTokenError = (error: any) => getStatusCode(error) === 401;

export const isRateLimitedError = (error: any) => getStatusCode(error) === 429;

export const redirectUrlFromError = (error: any): string | null | undefined => {
  const errorInfo =
    error &&
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.error;
  return errorInfo ? errorInfo.refresh_url || errorInfo.return_url : undefined;
};

export const isRestrictedIpCountryError = (error: any) =>
  error.message.includes(RESTRICTED_IP_COUNTRY_ERROR);

export const isSessionError = (error: any) => {
  // This complicated check is needed since some components
  // (Apollo) wrap Exceptions only preserving the message.

  return (
    isExpiredTokenError(error) ||
    isInvalidUpdateError(error) ||
    isRateLimitedError(error) ||
    error.name === 'SessionError' ||
    error.message.includes(EXPIRED_TOKEN_ERROR_MESSAGE) ||
    error.message.includes(NO_TOKEN_ERROR_MESSAGE) ||
    error.message.includes(NO_TOKEN_PASTED_URL) ||
    error.message.includes(NO_TOKEN_OLD_TOKEN) ||
    error.message.includes(NO_TOKEN_NETWORK_ERROR) ||
    error.message.includes(UNEXPECTED_START_STATE)
  );
};
