import {Children} from 'react';

export type Slots = Record<string, JSX.Element[]>;

function getElementProps(node: JSX.Element): {slot?: string} | null {
  const props = node && node.props;
  // This adds remote-ui support for slots. remote-ui wraps all
  // rendered elements so we need to get the props from the
  // internal state instead. There's no fool-proof way to detect a
  // remote-ui component, so we check these specific props
  if (
    props &&
    props.controller &&
    props.receiver &&
    props.component &&
    props.component.props
  ) {
    return props.component.props;
  }
  return props;
}

export function createSlotsFromNode(node: React.ReactNode | undefined): Slots {
  const slots = {} as Slots;
  if (!node) {
    return slots;
  }
  const children = Children.toArray(node);
  for (let i = 0; i < children.length; i++) {
    // TODO(koop): Consider overwriting the default generated `key` of the
    // element with a new key that includes the slot name.
    const child = children[i] as JSX.Element;
    const props = getElementProps(child);
    const slot = (props && props.slot) || 'default';

    if (slots[slot]) {
      slots[slot].push(child);
    } else {
      slots[slot] = [child];
    }
  }
  return slots;
}

export function createSlots(props: {children?: React.ReactNode}): Slots {
  return createSlotsFromNode(props.children);
}
