import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {
  Exact,
  DocumentMetadataInput,
  FieldErrors,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

const doc = graphql`
  mutation UpdateDocumentMetadataMutation(
    $documentMetadata: DocumentMetadataInput!
  ) {
    updateDocumentMetadata(documentMetadata: $documentMetadata) {
      session {
        id
        ...SessionDynamicFragment
      }
      validationErrors {
        path
        type
      }
    }
  }

  ${sessionDynamicFragment}
`;

export const updateDocumentMetadataMutation: UpdateDocumentMetadataMutationDocument =
  doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const updateDocumentMetadataMutationOptions: GelatoMutationOptionsWithoutVariables<UpdateDocumentMetadataMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useUpdateDocumentMetadataMutation(
  options: GelatoMutationOptions<UpdateDocumentMetadataMutationDocument> = {},
): GelatoMutationReturn<UpdateDocumentMetadataMutationDocument> {
  return useGelatoMutation(updateDocumentMetadataMutation, {
    ...updateDocumentMetadataMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type UpdateDocumentMetadataMutationVariables = Exact<{
  documentMetadata: DocumentMetadataInput;
}>;

export type UpdateDocumentMetadataMutationData = {
  readonly __typename: 'Mutation';
  readonly updateDocumentMetadata: {
    readonly __typename: 'MutationResponse';
    readonly session: GraphQlFragmentUnion<
      {readonly __typename: 'Session'; readonly id: string},
      [SessionDynamicFragmentData]
    >;
    readonly validationErrors: ReadonlyArray<{
      readonly __typename: 'ValidationError';
      readonly path: ReadonlyArray<string>;
      readonly type: FieldErrors;
    }>;
  };
};

const updateDocumentMetadataMutationPermissions = {
  ...sessionDynamicFragment.permissions,
} as const;

doc.permissions = updateDocumentMetadataMutationPermissions;

export type UpdateDocumentMetadataMutationPermissions = GelatoPermissionToken<
  typeof updateDocumentMetadataMutationPermissions
>;

export type UpdateDocumentMetadataMutationDocument = GraphQlDocument<
  UpdateDocumentMetadataMutationData,
  UpdateDocumentMetadataMutationVariables,
  UpdateDocumentMetadataMutationPermissions
>;
