import {ApolloLink} from '@apollo/client';

import type {Fetch} from '@sail/data';
import createHttpHeadersLink from './links/createHttpHeadersLink';
import createTerminatingLink from './links/createTerminatingLink';
import createExtensionsLink from './links/createExtensionsLink';

export default function createGelatoApolloLink(
  fetch: Fetch,
  graphQlUrl: string,
) {
  return ApolloLink.from([
    createExtensionsLink(),
    createHttpHeadersLink(),
    createTerminatingLink(fetch, graphQlUrl),
  ]);
}
