/**
 * @fileoverview This file contains the layer actions for the application
 *   controller. Each action is a function that takes the application controller
 *   and update the application state accordingly.
 */

import {
  closeLayer,
  findLayerIndex,
  openLayer,
} from 'gelato/frontend/src/controllers/states/LayerState';

import type {LayerContentRenderer} from 'gelato/frontend/src/controllers/states/LayerState';
import type {
  ApplicationAction,
  ApplicationActionWithPayload,
} from 'gelato/frontend/src/controllers/types';

/**
 * Opens a layer.
 * @param contentRenderer  The content renderer for the layer.
 */
export const openLayerAction: ApplicationActionWithPayload<
  LayerContentRenderer
> = async (controller, contentRenderer) => {
  controller.update((draft) => {
    openLayer(draft, contentRenderer);
  });
};

/**
 * Close a layer.
 * @param contentRenderer  The content renderer for the layer.
 */
export const closeLayerAction: ApplicationActionWithPayload<
  LayerContentRenderer
> = async (controller, contentRenderer) => {
  if (findLayerIndex(controller.state, contentRenderer) !== -1) {
    controller.update((draft) => {
      closeLayer(draft, contentRenderer);
    });
    // Wait for the animation to finish.
    await new Promise((resolve) => setTimeout(resolve, 600));
  }
};

/**
 * Close all layers.
 */
export const closeAllLayersAction: ApplicationAction = async (controller) => {
  controller.update((draft) => {
    const items = draft.layer.items.slice(0);
    items.forEach((item) => {
      closeLayer(draft, item.contentRenderer);
    });
  });
};
