import {urlReplace} from '@stripe-internal/safe-links';
import * as React from 'react';

import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';

import type {PageProps} from 'gelato/frontend/src/lib/localRouter';

class Index extends React.Component<PageProps> {
  componentDidMount() {
    const origin = window.location.origin;
    if (
      process.env.NODE_ENV !== 'production' &&
      origin.includes('stripe.me') &&
      origin.includes('idprodui')
    ) {
      // if coming from idprodui, redirect to the test link
      urlReplace(`${origin.replace('idprodui', 'idprod')}/gelato/test_link`);
    } else {
      urlReplace('https://www.stripe.com');
    }
  }

  render() {
    return <div />;
  }
}

setComponentConfig(Index, {skipApp: true});

export default Index;
