import {createCompositeProperty, matchOutsideParens} from '../core';
import type {StylePlugin} from '../core';

const transformSuffixes = {
  [1]: 'X',
  [3]: '3d',
} as Record<number, string>;

const axisRegExp = /^\s*([xyz])\s/;
const valueRegExp = /\s+/gi;

// TODO(koop): Replace this with a value parser.
function spaceToComma(value: string): [string, number] {
  let count = 1;
  const transformed = matchOutsideParens(
    value.trim(),
    valueRegExp,
    (match, depth): string => {
      if (!depth) {
        count += 1;
        return ', ';
      }
      return match;
    },
  );
  return [transformed, count];
}

function transformLevel2ToLevel1(name: string): (value: string) => string {
  return function (value: string): string {
    const [transformed, count] = spaceToComma(value);
    return `${name}${transformSuffixes[count] || ''}(${transformed})`;
  };
}

const transformScale = transformLevel2ToLevel1('scale');
const transformTranslate = transformLevel2ToLevel1('translate');

function transformRotate(contents: string): string {
  let matched = false;
  const prefixed = contents.replace(axisRegExp, (_s, axis) => {
    matched = true;
    return `rotate${axis.toUpperCase()}(`;
  });
  if (matched) {
    return `${prefixed})`;
  }
  const [transformed, count] = spaceToComma(contents);
  return count === 1 ? `rotate(${transformed})` : `rotate3d(${transformed})`;
}

const composite = createCompositeProperty('transform', {
  fallback: ' ',
  order: ['translate', 'rotate', 'scale', 'transform'],
  merge: {
    delimiter: ' ',
    defaultValue: 'none',
  },
});

export const transforms: StylePlugin = {
  properties: {
    rotate: composite(transformRotate),
    scale: composite(transformScale),
    translate: composite(transformTranslate),
    transform: composite(),
  },
};
