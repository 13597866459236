import {view as engineView} from '@sail/engine';
import {usesCssSerializer} from './css';

/**
 * Create intrinsic HTML elements supercharged with support for the css prop and engine intents.
 *
 * @see https://sail.stripe.me/apis/view-tag
 */
export const view: typeof engineView = usesCssSerializer(engineView);
// don't know why, but without the cast to typeof engineView, view.* won't take
// intents in the uses prop without a type error - but only in packages that depend on ui

export type {View} from '@sail/engine';
