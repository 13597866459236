import * as Sentry from '@sentry/browser';
import {useEffect} from 'react';

import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import IDDetector from 'gelato/frontend/src/ML/detectors/IDDetector';

let preloaded = false;
let preloading = false;

/**
 * Preload ID detectors as soon as we know that an ID document is required.
 */
export function usePreloadIDDetectors(enabled: boolean) {
  const {appState} = useAppController();
  const session = appState.session;
  const sessionId = session?.id || '';
  const missingFields = session?.missingFields;
  const needsIDImage = missingFields?.includes('id_document_images');

  useEffect(() => {
    if (!enabled) {
      return;
    }

    // Hack to ensure that we don't run anything until the session is loaded.
    // IDDetector_v2.getInstance() call below currently relies on experiments
    // being present and otherwise raises a hard error. To prevent this, we
    // should only run this once a session is present. However, we bootstrapped
    // the session with information from the link code mutation to power the
    // /verify_welcome page, which means we need to distinguish between when
    // the VerificationSession is created vs. when we are still interacting
    // with a VerificaitonFlow.
    if (!sessionId.startsWith('vs_')) {
      return;
    }
    if (!needsIDImage && preloaded) {
      // TODO: @rwashburne add detector cleanup
      return;
    }
    if (preloaded || preloading) {
      return;
    }
    preloading = true;
    const idDetector = IDDetector.getInstance();
    idDetector
      .warmup()
      .then(() =>
        Sentry.addBreadcrumb({
          category: 'ML',
          message: 'Models warmed up',
          level: Sentry.Severity.Info,
        }),
      )
      .catch((e) => {
        Sentry.addBreadcrumb({
          category: 'ML',
          message: 'Model warm up failed',
          level: Sentry.Severity.Error,
          data: e,
        });
        handleException(
          e,
          `IDDetector warmup failed at 'usePreloadIDDetectors'`,
        );
      })
      .finally(() => {
        preloading = false;
        preloaded = true;
      });
  }, [enabled, needsIDImage, sessionId]);
}

export default usePreloadIDDetectors;
