export default async function calculateUserAgentData(): Promise<
  UserAgentData | undefined
> {
  if (navigator.userAgentData) {
    const {fullVersionList, brands, ...rest} =
      await navigator.userAgentData.getHighEntropyValues([
        'fullVersionList',
        'model',
        'platformVersion',
        'platform',
        'mobile',
      ]);

    let browser = parseBrands(fullVersionList);

    if (!browser) {
      browser = parseBrands(brands);
    }

    return {
      ...rest,
      browser: browser?.brand || 'Unknown',
      browserVersion: browser?.version || 'Unknown',
    };
  }
}

export type UserAgentData = {
  mobile?: boolean;
  model?: string;
  platform?: string;
  platformVersion?: string;
  browser: string;
  browserVersion: string;
};

function parseBrands(brands: NavigatorUABrandVersion[] | undefined) {
  if (brands) {
    const browser = brands.find(
      (entry) =>
        entry.brand !== 'Chromium' && !regexMatchGreasyBrand(entry.brand),
    );
    if (browser) {
      return browser;
    }
    // Chrome may just report the Chromium brand and version.
    // More info: https://wicg.github.io/ua-client-hints/#grease
    return brands.find((entry) => entry.brand === 'Chromium');
  }
}

/*
  Web spec for brands attempts to prevent ossifying on certain browsers by introducing this
  idea of "GREASE". Basically, browsers will introduce a fake brand and change the string of that
  fake brand for each significant version they release.

  GREASE: Generate Random Extensions And Sustain Extensibility

  More info: https://wicg.github.io/ua-client-hints/#grease
*/
export function regexMatchGreasyBrand(brand: string) {
  return brand.match(/^(?=.*not)(?=.*brand).*/i);
}
