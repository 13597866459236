import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import analytics from 'gelato/frontend/src/lib/analytics';
import asError from 'gelato/frontend/src/lib/asError';
import {logInDev, errorInDev} from 'gelato/frontend/src/lib/assert';
import {getNetworkInformation} from 'gelato/frontend/src/lib/device';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import {
  initializeTensorflow,
  supportsWebGL,
} from 'gelato/frontend/src/ML/utils';

export const InspectorStatus = {
  /* eslint sort-keys: ["error", "asc"] */
  BUILD_DONE: 'build_done',
  BUILD_PENDING: 'build_pending',
  DETECT_DONE: 'detect_done',
  DETECT_PENDING: 'detect_pending',
  DISPOSE_DONE: 'dispose_done',
  DISPOSE_PENDING: 'dispose_pending',
  ERROR: 'error',
  UNINITIALIZED: 'uninitialized',
  WARM_UP_DONE: 'warm_up_done',
  WARM_UP_PENDING: 'warm_up_pending',
};

export type InspectorStatusType =
  (typeof InspectorStatus)[keyof typeof InspectorStatus];

// Cache the promise to initialize tensorflow.
let tensorflowPromise: Promise<void> | null = null;

/**
 * Initializes Tensorflow and sets the backend to WebGL or WASM.
 */
async function setUpTensorflow(): Promise<void> {
  if (!tensorflowPromise) {
    tensorflowPromise = new Promise(async (resolve, reject) => {
      try {
        await initializeTensorflow();
        logInDev('[Inspector] Tensorflow initialized');
        resolve();
      } catch (ex) {
        const cause = asError(ex);
        const error = new Error(ErrorCode.failedToInitializeTensorflow, {
          cause,
        });
        handleException(error, cause.message);
        reject(error);
      }
    });
  }
  return tensorflowPromise;
}

// Cache the result of whether the current environment supports WebGL.
let isWebGLSupportedResult: boolean | undefined;

/**
 * Whether the current environment supports WebGL.
 */
function isWebGLSupported(): boolean {
  if (isWebGLSupportedResult === undefined) {
    // Cache the result. supportsWebGL() is an expensive operation that can
    // perform a lot of checks against canvas elements and WebGL contexts.
    isWebGLSupportedResult = supportsWebGL();
  }
  return isWebGLSupportedResult;
}

/**
 * This is the base class for all inspectors. It provides a common
 * interface for all inspectors to implement.
 */
export default abstract class BaseInspector<TArguments extends any[], TResult> {
  /**
   * Whether the inspector is ready to be used.
   */
  private _ready: boolean = false;

  /**
   * Whether the inspector has been disposed.
   */
  private _disposed: boolean = false;

  /**
   * The error that occurred during the build or warmup process.
   */
  private _error: Error | null = null;

  /**
   * The status of the inspector.
   */
  private _status: InspectorStatusType = InspectorStatus.UNINITIALIZED;

  /**
   * The promise that resolves when the inspector is built.
   */
  private _buildPromise: Promise<void> | undefined;

  /**
   * The promise that resolves when the inspector is warmed up.
   */
  private _warmupPromise: Promise<void> | undefined;

  /**
   * The name of the inspector.
   */
  public readonly name: string;

  /**
   * Whether the current environment supports WebGL.
   * Consider using this if TensorFlow is required by the inspector.
   */
  static isWebGLSupported = isWebGLSupported;

  /**
   * Initializes Tensorflow and sets the backend to WebGL or WASM.
   */
  static setUpTensorflow = setUpTensorflow;

  /**
   * The abstract constructor for the inspector.
   * @param name The name of the inspector.
   */
  constructor(name: string) {
    this.name = name;
  }

  /**
   * Returns the status of the inspector.
   */
  public get status(): InspectorStatusType {
    return this._status;
  }

  /**
   * Returns whether the inspector is ready to be used for detection.
   */
  public get ready(): boolean {
    return this._ready;
  }

  /**
   * Return the error that occurred during the build or warmup process.
   */
  public get error(): Error | null {
    return this._error;
  }

  /**
   * The process of loading the model into memory and preparing it for use.
   */
  readonly build = async (): Promise<void> => {
    if (!this._buildPromise) {
      this._buildPromise = new Promise<void>(async (resolve) => {
        try {
          if (this._disposed || this._error) {
            // It can't warm up if it's disposed or errored.
            throw new Error(ErrorCode.inspectorIsNotReady);
          }
          const startTime = Date.now();
          this._updateStatus(InspectorStatus.BUILD_PENDING);

          const networkInfo = getNetworkInformation();

          analytics.track('inspectorBuildStarted', {
            ...networkInfo,
            inspectorName: this.name,
          });

          await new Promise<void>((resolve, reject) => {
            const buildTask = async () => {
              try {
                await this.buildImpl();
                resolve();
              } catch (ex) {
                reject(ex);
              }
            };

            // We'd like to build the inspector at the next idle frame.
            if (typeof requestIdleCallback === 'function') {
              // Build the inspector when the main thred is not busy. This
              // allows the UI actions, animations and network requests to be
              // processed first.
              // See https://developer.mozilla.org/en-US/docs/Web/API/Background_Tasks_API
              requestIdleCallback(buildTask, {timeout: 5000});
            } else {
              // Use setTimeout as a fallback if requestIdleCallback is not
              // available. 16ms is the time for a frame at 60fps.
              setTimeout(buildTask, 16);
            }
          });

          if (this._status !== InspectorStatus.BUILD_PENDING) {
            // The inspector status has changed, this is likely a race
            // condition.
            throw new Error(
              this._disposed
                ? ErrorCode.inspectorIsDisposed
                : ErrorCode.inspectorIsNotReady,
            );
          }

          this._updateStatus(InspectorStatus.BUILD_DONE);

          const endTime = Date.now();
          const duration = endTime - startTime;
          logInDev(`[Inspector] ${this.name} built in ${duration}ms`);

          analytics.track('inspectorBuilt', {
            ...networkInfo,
            duration,
            inspectorName: this.name,
          });

          // The inspector is ready to be used for inspection.
          this._ready = true;
        } catch (ex) {
          this._handleException(ex);
        } finally {
          resolve();
        }
      });
    }
    return this._buildPromise;
  };

  /**
   * The process of preparing the model for optimal performance before serving
   * real-time or batch predictions. This is particularly important for
   * certain types of machine learning deployments where initial latency is a
   * concern.
   */
  readonly warmUp = async () => {
    if (!this._warmupPromise) {
      this._warmupPromise = new Promise<void>(async (resolve) => {
        try {
          if (this._disposed || this._error) {
            // It can't warm up if it's disposed or errored.
            throw new Error(ErrorCode.inspectorIsNotReady);
          }
          await this.build();

          this._updateStatus(InspectorStatus.WARM_UP_PENDING);
          const startTime = Date.now();
          await this.warmUpImpl();
          const endTime = Date.now();
          const duration = endTime - startTime;
          analytics.track('inspectorWarmedUp', {
            duration,
            inspectorName: this.name,
          });

          if (this._status !== InspectorStatus.WARM_UP_PENDING) {
            // The inspector status has changed, this is likely a race
            // condition.
            throw new Error(ErrorCode.inspectorIsNotReady);
          }
          this._updateStatus(InspectorStatus.WARM_UP_DONE);
        } catch (ex) {
          this._handleException(ex);
        } finally {
          resolve();
        }
      });
    }
    return this._warmupPromise;
  };

  /**
   * The process of using the model to make predictions on new data.
   * @param args The arguments to pass to the detector's constructor.
   * @returns a promise that resolves to the result of the detector.
   */
  readonly detect = async (...args: TArguments): Promise<TResult> => {
    await this.build();
    try {
      if (this._ready) {
        const result = this.detectImpl(...args);
        return result;
      } else {
        throw new Error(ErrorCode.inspectorIsNotReady);
      }
    } catch (ex) {
      this._handleException(ex);
      // Rethrow the exception so that the caller can handle it (e.g. stop
      // auto-capture and show proper error.) .
      throw ex;
    }
  };

  /**
   * The process of releasing the resources used by the inspector.
   */
  readonly dispose = async (): Promise<void> => {
    if (!this._disposed) {
      try {
        // If we have any pending warmup or build promises, we need to wait
        // for them to finish before disposing to avoid race conditions.
        if (this._warmupPromise) {
          await this._warmupPromise;
        } else if (this._buildPromise) {
          await this._buildPromise;
        }

        this._disposed = true;
        this._ready = false;
        this._updateStatus(InspectorStatus.DISPOSE_PENDING);
        await this.disposeImpl();
      } catch (ex) {
        this._handleException(ex);
      } finally {
        this._updateStatus(InspectorStatus.DISPOSE_DONE);
      }
    }
  };

  /**
   * Handles errors that occur during the build, warmup and detect process.
   * @param ex The exception that occurred.
   */
  private readonly _handleException = (ex: any): void => {
    const error = asError(ex);
    const message =
      `Inspector ${this.name} had an error: ` +
      `${error.message}, status: ${this._status}`;

    // Report the error to Sentry.
    handleException(error, message);
    analytics.track('inspectorFailed', {
      ...getNetworkInformation(),
      disposed: this._disposed,
      errorMessage: error.message,
      errorName: this.name,
      status: this._status,
    });

    this._updateStatus(InspectorStatus.ERROR);
    this._ready = false;
    this._error = error;

    errorInDev(error, message);
  };

  /**
   * Updates the status of the inspector.
   * @param status The new status.
   */
  readonly _updateStatus = (status: InspectorStatusType) => {
    this._status = status;
    logInDev(`[Inspector] ${this.name} status: ${status}`);
  };

  /**
   * The process of loading the model into memory and preparing it for use.
   * The implementation of this method should be provided by subclasses.
   */
  protected abstract buildImpl(): Promise<void>;

  /**
   * The process of preparing the model for optimal performance before serving.
   * The implementation of this method should be provided by subclasses.
   */
  protected abstract warmUpImpl(): Promise<void>;

  /**
   * The process of using the model to make predictions on new data.
   * The implementation of this method should be provided by subclasses.
   */
  protected abstract detectImpl(...args: TArguments): Promise<TResult>;

  /**
   * The process of releasing the resources used by the inspector.
   * The implementation of this method should be provided by subclasses.
   */
  protected abstract disposeImpl(): Promise<void>;
}
