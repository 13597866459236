import {arrowLeftSvg as Icon__small__arrowLeftSvg} from '../Icon__small/Icon__small--arrowLeft.js'
import {arrowLeftSvg as Icon__xsmall__arrowLeftSvg} from '../Icon__xsmall/Icon__xsmall--arrowLeft.js'

export var arrowLeft = {
  name: 'arrowLeft',
  category: 'icon',
  colorable: true,
  svg: Icon__small__arrowLeftSvg,
  alt: {
    Icon__small: Icon__small__arrowLeftSvg,
    Icon__xsmall: Icon__xsmall__arrowLeftSvg
  },
  combined: ['Icon__small', 'Icon__xsmall']
};
