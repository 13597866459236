import {transformInput} from '@stripe-internal/sail/lib/autocorrect';
import {isEmpty, flatten, mapValues, startsWith} from 'lodash';
import {IntlShape} from 'react-intl';
import {isMobilePhone, isEmail} from 'validator';

import {messages} from 'gelato/frontend/src/components/IndividualV2/messages';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {
  IndividualStateFields,
  IndividualDob,
  IndividualEmail,
  IndividualName,
  IndividualIdNumber,
  IndividualPhoneNumber,
  IndividualStateFieldKeys,
  IndividualAddress,
  OTPMode,
  InnerIndividualStateFieldKeys,
  ADDRESS_DATA_INPUT_TO_ADDRESS_VALUE_MAP,
} from 'gelato/frontend/src/controllers/states/IndividualState';
import routeToNextPage from 'gelato/frontend/src/controllers/utils/routeToNextPage';
import {UpdateIndividualResponse} from 'gelato/frontend/src/controllers/utils/updateIndividual';
import {addressErrorLabelMessages} from 'gelato/frontend/src/lib/address';
import {getMomentDate} from 'gelato/frontend/src/lib/dateUtils';
import {
  ID_NUMBER_COUNTRY_AUTOCORRECT_PATTERNS,
  ID_NUMBER_COUNTRY_PLACEHOLDERS,
} from 'gelato/frontend/src/lib/idNumber';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';
import validationErrorMessages from 'gelato/frontend/src/lib/validationErrorMessages';
import {getEvaluatedCountry, validateWithMessages} from 'sail/AddressInput';

import type {
  Country,
  IndividualFields,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  ApplicationActionWithPayload,
  ApplicationController,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';

/**
 * Callback to set individual data
 * @param controller The controller instance.
 * @param payload The payload for the action.
 * @returns A promise that resolves to true if the action was successful.
 */
export const setIndividualCollectedDataAction: ApplicationActionWithPayload<{
  individualData: Partial<IndividualStateFields>;
}> = async (controller, {individualData}) => {
  controller.update((draft: ApplicationState) => {
    (Object.keys(individualData) as Array<keyof typeof individualData>).forEach(
      (field) => {
        if (!draft.individual[field]) {
          draft.individual[field] = {};
        }
        shallowMergeInto(draft.individual[field]!, individualData[field]!);
      },
    );
  });
};

export const setDeclineOTPAction: ApplicationActionWithPayload<{
  mode: OTPMode;
}> = async (controller, {mode}) => {
  const field = mode === OTPMode.email ? 'emailOtp' : 'phoneOtp';
  setIndividualCollectedDataAction(controller, {
    individualData: {
      [field]: {
        otpDeclined: true,
      },
    },
  });
};

export const setOTPCodeAction: ApplicationActionWithPayload<{
  mode: OTPMode;
  code: string;
}> = async (controller, {mode, code}) => {
  const field = mode === OTPMode.email ? 'emailOtp' : 'phoneOtp';
  setIndividualCollectedDataAction(controller, {
    individualData: {
      [field]: {
        code,
        otpDeclined: undefined,
      },
    },
  });
};

/**
 * Callback to set ID number data correctly
 * @param controller The controller instance.
 * @param payload The payload for the action.
 * @param intl
 * @returns A promise that resolves to true if the action was successful.
 */
export const setIdNumberDataAction: ApplicationActionWithPayload<{
  idNumberValue: {
    country?: Country;
    idValue?: string;
  };
  intl: IntlShape;
}> = async (controller, payload) => {
  const {idNumberValue, intl} = payload;
  const {country, idValue} = idNumberValue;
  const {idNumber} = controller.state.individual;
  const selectedCountry = country || idNumber?.country;
  const autocorrectPattern =
    selectedCountry && ID_NUMBER_COUNTRY_AUTOCORRECT_PATTERNS[selectedCountry];
  const correctedIdValue =
    autocorrectPattern && idValue
      ? transformInput(autocorrectPattern, idValue).join('')
      : idValue;

  const value =
    selectedCountry === 'US'
      ? {
          country: selectedCountry,
          partialValue: correctedIdValue,
        }
      : {
          country: selectedCountry,
          idNumber: correctedIdValue,
        };
  setIndividualCollectedDataAction(controller, {
    individualData: {
      idNumber: value,
    },
  });
  validateIdNumber(controller, value, intl);
};

function setValidationMsg(
  controller: ApplicationController,
  field: IndividualStateFieldKeys,
  validationMsg:
    | string
    | Partial<Record<InnerIndividualStateFieldKeys, string>>
    | null,
) {
  setIndividualCollectedDataAction(controller, {
    individualData: {
      [field]: {
        validationMsg,
      },
    },
  });
}

function validateName(
  controller: ApplicationController,
  name: IndividualName,
  intl: IntlShape,
) {
  const {validationMsg} = controller.state.individual.name || {};
  const validationMsgValue = {
    firstName:
      (typeof validationMsg !== 'string' && validationMsg?.firstName) || '',
    lastName:
      (typeof validationMsg !== 'string' && validationMsg?.lastName) || '',
  };
  if ('firstName' in name) {
    if (!name.firstName) {
      validationMsgValue.firstName = intl.formatMessage(
        messages.nameFirstNameRequiredValue,
      );
    } else {
      validationMsgValue.firstName = '';
    }
  }
  if ('lastName' in name) {
    if (!name.lastName) {
      validationMsgValue.lastName = intl.formatMessage(
        messages.nameLastNameRequiredValue,
      );
    } else {
      validationMsgValue.lastName = '';
    }
  }

  setValidationMsg(controller, 'name', validationMsgValue);
}

function validateDob(
  controller: ApplicationController,
  dob: IndividualDob,
  intl: IntlShape,
) {
  if (!dob.month || !dob.day || !dob.year) {
    setValidationMsg(
      controller,
      'dob',
      intl.formatMessage(messages.dobRequiredValue),
    );
    return;
  }

  const momentDate = getMomentDate(dob.day, dob.month, dob.year);
  const minDobYear = 1900;
  const today = new Date();

  if (momentDate.toDate() > today) {
    setValidationMsg(
      controller,
      'dob',
      intl.formatMessage(messages.dobFutureDate),
    );
    return;
  }

  if (!momentDate.isValid()) {
    const message = intl.formatMessage(messages.dobInvalidDate);

    setValidationMsg(controller, 'dob', message);
    return;
  }

  if (momentDate.year() < minDobYear) {
    setValidationMsg(
      controller,
      'dob',
      intl.formatMessage(messages.dobImplausiblyOldError),
    );
    return;
  }

  // If no errors, make sure to set validationMsg to null
  setValidationMsg(controller, 'dob', null);
}

function validateEmail(
  controller: ApplicationController,
  email: IndividualEmail,
  intl: IntlShape,
) {
  if (!email.userProvidedAddress) {
    setValidationMsg(
      controller,
      'email',
      intl.formatMessage(messages.emailRequiredValue),
    );
    return;
  }

  if (!isEmail(email.userProvidedAddress)) {
    setValidationMsg(
      controller,
      'email',
      intl.formatMessage(messages.emailInvalidValue),
    );
    return;
  }

  setValidationMsg(controller, 'email', null);
}

function validateIdNumber(
  controller: ApplicationController,
  idNumberValue: IndividualIdNumber,
  intl: IntlShape,
) {
  const {country, partialValue, idNumber} = idNumberValue;
  if (!country) {
    setValidationMsg(
      controller,
      'idNumber',
      intl.formatMessage(messages.individualCountryRequiredValue),
    );
    return;
  }

  const idNumberUserValue = country === 'US' ? partialValue : idNumber;
  if (!idNumberUserValue) {
    setValidationMsg(
      controller,
      'idNumber',
      intl.formatMessage(messages.idNumberValueRequired),
    );
    return;
  }

  const autocorrectPattern =
    country && ID_NUMBER_COUNTRY_AUTOCORRECT_PATTERNS[country];
  // We automatically transform the input in setIdNumber action if there is a pattern
  // which is why even if some of the characters may be optional in the pattern,
  // we should still expect a similar length string
  if (
    autocorrectPattern &&
    idNumberUserValue.length !== autocorrectPattern.length
  ) {
    const placeholder = ID_NUMBER_COUNTRY_PLACEHOLDERS[country];
    const message = placeholder
      ? intl.formatMessage(messages.idNumberValueInvalidWithExample, {
          placeholder,
        })
      : intl.formatMessage(messages.idNumberValueInvalid);
    setValidationMsg(controller, 'idNumber', message);
    return;
  }

  setValidationMsg(controller, 'idNumber', null);
}

function validatePhone(
  controller: ApplicationController,
  phoneNumber: IndividualPhoneNumber,
  intl: IntlShape,
) {
  const {userProvidedPhoneNumber} = phoneNumber;

  if ('userProvidedPhoneNumber' in phoneNumber && !userProvidedPhoneNumber) {
    setValidationMsg(
      controller,
      'phone',
      intl.formatMessage(messages.phoneNumberRequiredValue),
    );
    return;
  }

  if (userProvidedPhoneNumber && !isMobilePhone(userProvidedPhoneNumber)) {
    setValidationMsg(
      controller,
      'phone',
      intl.formatMessage(messages.phoneNumberInvalidValue),
    );
    return;
  }

  setValidationMsg(controller, 'phone', null);
}

function validateAddress(
  controller: ApplicationController,
  address: IndividualAddress,
  intl: IntlShape,
) {
  const {country} = address;
  if (!country) {
    setValidationMsg(
      controller,
      'address',
      intl.formatMessage(messages.individualCountryRequiredValue),
    );
    return;
  }

  // Use the validation by old Sail library
  const validationSchema = getEvaluatedCountry(country);
  const mappedAddressValue = mapValues(
    ADDRESS_DATA_INPUT_TO_ADDRESS_VALUE_MAP,
    (field) => (field && address[field]) || '',
  );
  const intlAddressErrorMessages = addressErrorLabelMessages(intl);

  if (validationSchema) {
    const getErrorMessages = validateWithMessages(
      validationSchema,
      intlAddressErrorMessages,
    );
    const errors = getErrorMessages(mappedAddressValue);
    if (!isEmpty(errors)) {
      // TODO: Sail Next does not currently support field-level errors
      // Replace this when supported https://sail.stripe.me/components/address-field#field-level-errors
      const reasons = flatten(Object.values(errors))
        .map((error) => error.message)
        .join(', ');

      setValidationMsg(
        controller,
        'address',
        intl.formatMessage(messages.addressErrors, {
          reasons,
        }),
      );
      return;
    }
  }
  setValidationMsg(controller, 'address', null);
}

/**
 * Callback to validate individual data
 * @param controller The controller instance.
 * @param payload The payload for the action.
 * @returns A promise that resolves to true if the action was successful.
 */
export const validateIndividualDataAction: ApplicationActionWithPayload<{
  individualData: Partial<IndividualStateFields>;
  intl: IntlShape;
}> = async (controller, {individualData, intl}) => {
  const {dob, email, name, idNumber, phone, address} = individualData;

  // Run validations if the field is required
  if (dob) {
    validateDob(controller, dob, intl);
  }
  if (email) {
    validateEmail(controller, email, intl);
  }
  if (name) {
    validateName(controller, name, intl);
  }
  if (idNumber) {
    validateIdNumber(controller, idNumber, intl);
  }
  if (phone) {
    validatePhone(controller, phone, intl);
  }
  if (address) {
    validateAddress(controller, address, intl);
  }
};

/**
 * Function that handles the update individual mutation response
 * @param controller
 * @param response This can either be the next session, a list of errors or an error thrown
 * @param intl
 */
export const handleResponseAction = (
  controller: ApplicationController,
  response: UpdateIndividualResponse,
  intl: IntlShape,
): boolean => {
  const {formatMessage} = intl;
  const {session, validationErrors} = response;

  // If the data given is unsupported, immediately route to the invalid page
  if (
    session &&
    (session?.underConsentAge ||
      session?.unsupportedCountry ||
      session?.sanctionedDocumentCountry)
  ) {
    controller.update((draft) => {
      shallowMergeInto(draft.session!, session);
    });
    routeToNextPage(
      controller.state,
      controller.runtime!,
      'unsupported',
      undefined,
      'individualActions.handleResponseAction',
    );
    return false;
  }

  // Check if there are validation errors returned by the BE that are actionable
  if (Array.isArray(validationErrors) && validationErrors.length > 0) {
    const fieldErrors: Partial<Record<IndividualFields, string[]>> = {};
    validationErrors
      .filter(({path}) =>
        startsWith(path.join('.'), 'collectedData.individual.'),
      )
      .forEach(({path, type}) => {
        const individualField: IndividualFields = path[2];
        fieldErrors[individualField] = fieldErrors[individualField] || [];
        fieldErrors[individualField]?.push(
          formatMessage(validationErrorMessages[type]),
        );
      });
    Object.entries(fieldErrors).forEach(([individualField, errors]) => {
      setFieldError(
        controller,
        individualField as IndividualFields,
        errors.join(', '),
      );
    });

    return false;
  }

  return true;
};

/**
 * Function that sets the error state for an individual field
 * @param controller
 * @param field
 * @param error
 */
export const setFieldError = (
  controller: ApplicationController,
  field: IndividualFields,
  error: string,
) => {
  let stateField: IndividualStateFieldKeys;
  if (field === 'id_number') {
    stateField = 'idNumber';
  } else if (field === 'phone_number') {
    stateField = 'phone';
  } else if (
    field === 'dob' ||
    field === 'email' ||
    field === 'address' ||
    field === 'name'
  ) {
    stateField = field;
  } else {
    const cause = new Error(`field ${field} is not supported`);
    const error = new Error(ErrorCode.individualUnsupportedField, {cause});
    handleException(error, cause.message);
    return;
  }
  setValidationMsg(controller, stateField, error);
};
