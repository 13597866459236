import DOMPurify from 'dompurify';
import * as React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {isEmail} from 'validator';

import Message from 'gelato/frontend/src/components/Message';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import useFeedbackMutation from 'gelato/frontend/src/graphql/mutations/useFeedbackMutation';
import {
  BreakpointContext,
  LocaleContext,
} from 'gelato/frontend/src/lib/contexts';
import {parseUserAgent} from 'gelato/frontend/src/lib/userAgent';
import Box from 'sail/Box';
import Dialog, {DialogContent, DialogHeader} from 'sail/Dialog';
import {FormError} from 'sail/FormLayout';
import Group from 'sail/Group';
import {TextArea, TextInput} from 'sail/Input';
import ModalLayer from 'sail/ModalLayer';
import {Body, BodyAlt} from 'sail/Text';

import styles from './FeedbackDialog.module.css';

import type {IntlShape} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'feedback.title',
    description: 'Title of the feedback pop-up',
    defaultMessage: 'Feedback',
  },
  email: {
    id: 'feedback.email',
    defaultMessage: 'Email',
    description: 'Label for email field',
  },
  instructions: {
    id: 'feedback.instructions',
    description:
      'Instruction text to clarify that the feedback is for Stripe and not for the merchant',
    defaultMessage:
      'Please leave feedback for Stripe on the identity verification process.',
  },
  invalidEmail: {
    id: 'feedback.formErrors.email',
    description: 'Error message for invalid email address',
    defaultMessage: 'Please provide a valid email',
  },
  message: {
    id: 'feedback.message',
    description: 'Label for message field',
    defaultMessage: 'Message',
  },
  cancel: {
    id: 'feedback.cancel',
    description: 'Button text to cancel and close the feedback form',
    defaultMessage: 'Cancel',
  },
  submit: {
    id: 'feedback.submit',
    description: 'Button text to submit the feedback form',
    defaultMessage: 'Submit',
  },
  placeholder: {
    id: 'feedback.placeholder',
    description: 'Placeholder prompt for text field',
    defaultMessage: 'Feedback about this page?',
  },
  invalidFeedback: {
    id: 'feedback.formErros.feedback',
    description: 'Error message for feedback message is too short',
    defaultMessage: 'Please include more details.',
  },
  error: {
    id: 'feedback.error',
    description: 'API error message',
    defaultMessage: 'Oops, the submission failed. Please try again.',
  },
});

type FormErrors = {
  email?: boolean;
  feedback?: boolean;
};

export const FeedbackDialog = ({
  intl,
  shown,
  handleClose,
}: {
  intl: IntlShape;
  shown: boolean;
  handleClose: (arg1: {success: boolean}) => void;
}) => {
  const [email, setEmail] = React.useState<string>('');
  const [feedback, setFeedback] = React.useState<string>('');
  const [formErrors, setFormErrors] = React.useState<FormErrors>({});
  const [sendFeedback, {loading, error}] = useFeedbackMutation();
  const {locale} = React.useContext(LocaleContext);
  const breakpoint = React.useContext(BreakpointContext);

  const closeModal = () => {
    handleClose({success: false});
  };

  const isInvalidFeedbackMessage = (): boolean =>
    !!feedback && feedback.trim().split(' ').length < 3;

  const handleEmailChange = (value: string): void => {
    setEmail(value);
    setFormErrors(
      (previousState: FormErrors): FormErrors => ({
        ...previousState,
        email: false,
      }),
    );
  };

  const handleEmailBlur = (): void =>
    setFormErrors(
      (previousState: FormErrors): FormErrors => ({
        ...previousState,
        email: !!email && !isEmail(email),
      }),
    );

  const handleFeedbackChange = (value: string): void => {
    setFeedback(value);
    setFormErrors(
      (previousState: FormErrors): FormErrors => ({
        ...previousState,
        feedback: false,
      }),
    );
  };

  const handleFeedbackBlur = (): void =>
    setFormErrors(
      (previousState: FormErrors): FormErrors => ({
        ...previousState,
        feedback: isInvalidFeedbackMessage(),
      }),
    );

  const handleSubmit = async (): Promise<void> => {
    if (!formErrors.email && !formErrors.feedback) {
      const ua = parseUserAgent();
      const res = await sendFeedback({
        variables: {
          email,
          message: DOMPurify.sanitize(feedback),
          browser: `${ua?.browser?.name} ${ua?.browser?.version}`,
          operatingSystem: `${ua?.os?.name} ${ua?.os?.version}`,
          userAgent: navigator.userAgent,
          width: window.innerWidth,
          height: window.innerHeight,
          locale: locale.toString(),
        },
      });

      if (!res) {
        return Promise.reject();
      }
      const {errors} = res;
      if (!error && !errors) {
        setFormErrors({});
        setFeedback('');
        handleClose({success: true});
        return Promise.resolve();
      }
    } else {
      return Promise.reject();
    }
  };

  return (
    <ModalLayer shown={shown} onWashClick={closeModal} onEscape={closeModal}>
      <Dialog className={styles.feedbackDialog} width="medium">
        <DialogHeader>{intl.formatMessage(messages.title)}</DialogHeader>
        <DialogContent padding={{all: 20}}>
          <Group spacing={20}>
            <Body>
              <Message {...messages.instructions} />
            </Body>
            <Group spacing={4}>
              <label htmlFor="Feedback-email">
                <BodyAlt>
                  <Message {...messages.email} />
                </BodyAlt>
              </label>
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <TextInput
                id="Feedback-email"
                type="email"
                width="maximized"
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                value={email}
                invalid={formErrors.email}
              />
              <Box margin={{top: 8}}>
                {formErrors.email && (
                  <FormError>
                    <Message {...messages.invalidEmail} />
                  </FormError>
                )}
              </Box>
            </Group>
            <Group spacing={4}>
              <label htmlFor="Feedback-message">
                <BodyAlt>
                  <Message {...messages.message} />
                </BodyAlt>
              </label>
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <TextArea
                id="Feedback-message"
                width="maximized"
                invalid={formErrors.feedback}
                onBlur={handleFeedbackBlur}
                onChange={handleFeedbackChange}
                placeholder={intl.formatMessage(messages.placeholder)}
                rows={breakpoint === 'mobile' ? 8 : 5}
                value={feedback}
              />
              <Box margin={{top: 8}}>
                {formErrors.feedback && (
                  <FormError>
                    <Message {...messages.invalidFeedback} />
                  </FormError>
                )}
              </Box>
            </Group>
          </Group>
          <Box margin={{top: 8}}>
            <Group spacing={8}>
              {error && (
                <FormError>
                  <Message {...messages.error} />
                </FormError>
              )}
            </Group>
          </Box>
        </DialogContent>
        <Group
          className={styles.feedbackFooter}
          direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'}
          padding={{horizontal: 20, vertical: 16}}
          justifyContent="flex-end"
          spacing={breakpoint === 'mobile' ? 16 : 8}
        >
          <ThemableButton
            label={intl.formatMessage(messages.cancel)}
            onClick={closeModal}
          />
          <ThemableButton
            label={intl.formatMessage(messages.submit)}
            color="blue"
            onClick={handleSubmit}
            pending={loading}
            disabled={!email || !feedback}
          />
        </Group>
      </Dialog>
    </ModalLayer>
  );
};

export default injectIntl(FeedbackDialog);
