import {ApplicationBranding} from 'gelato/frontend/src/controllers/types';

const PLACE_HOLDER = 'Business Name';

/**
 * Get the displayed platform name from the branding.
 * @param branding The branding of the application.
 * @returns The displayed platform name.
 */
export default function getBrandingPlatformName(
  branding: ApplicationBranding | null | undefined,
): string {
  if (!branding) {
    return PLACE_HOLDER;
  }

  const {platformName, additionalPlatformName, isStripe} = branding;
  return (
    additionalPlatformName || // White label branding
    platformName || // Default branding
    (isStripe && 'Stripe') ||
    PLACE_HOLDER
  );
}
