import {ApolloLink} from '@apollo/client';
import {Headers} from '@sail/data';

export default function createHttpHeadersLink() {
  return new ApolloLink((operation, forward) => {
    operation.setContext((prevContext: {headers: Headers}) => {
      const headers = new Headers([
        ...Object.entries(prevContext?.headers ?? {}),
      ]);

      headers.set('Content-Type', 'application/json');

      return {
        ...prevContext,
        headers: headers.getFetchHeaders(),
      };
    });

    return forward(operation)?.map((res) => {
      const {response} = operation.getContext();

      res.errors?.forEach((error) => {
        // @ts-expect-error Allow re-defining extensions in case it doesn't exist.
        error.extensions = error.extensions || {};

        error.extensions.error = error.extensions.error || {};
        error.extensions.error.source = '<unknown>';
        error.extensions.error.status = response.status;

        error.extensions.__DO_NOT_USE_requestId =
          response.headers.get('Request-Id');
      });

      return res;
    });
  });
}
