import {ConsumerAccount} from 'gelato/frontend/src/api/Consumer/api';
import {
  ConsumerAccountSignUpResponse,
  CountryInferringMethod,
} from 'gelato/frontend/src/api/Consumer/types';

import type {CountryCode} from '@sail/ui';
import type {
  GenericStripeError,
  FetchResult,
} from 'gelato/frontend/src/api/request';
import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

export default async function signUpConsumerAccount(
  state: Readonly<ApplicationState>,
  data: {
    phone_number: string;
    country: CountryCode;
    country_inferring_method: CountryInferringMethod;
    locale: string;
  },
): Promise<FetchResult<GenericStripeError, ConsumerAccountSignUpResponse>> {
  const {phone_number, country, country_inferring_method, locale} = data;
  return ConsumerAccount.signUp({
    email_address: state.networkedIdentity.consumerEmail,
    phone_number,
    country,
    country_inferring_method,
    locale,
    request_surface: 'web_identity_product',
    consent_action:
      'entered_phone_number_email_clicked_save_with_link_identity',
  });
}
