import React from 'react';

import BottomSheet from 'gelato/frontend/src/components/Link/BottomSheet/BottomSheet';
import InvalidReasonsSheet from 'gelato/frontend/src/components/Link/DocumentSelection/Document/InvalidReasonsSheet/InvalidReasonsSheet';
import {messages} from 'gelato/frontend/src/components/Link/messages';
import {
  closeLayerAction,
  openLayerAction,
} from 'gelato/frontend/src/controllers/actions/layerActions';
import {updateConsentAndDocumentTypeAction} from 'gelato/frontend/src/controllers/actions/mutationActions';
import {
  setConsumerSession,
  setConsumerAccountLookupError,
  setConsumerAccountId,
  setLookupError,
  setOtpValue,
  setOtpLoading,
  setOtpError,
  setCanResendOtp,
  setUnavailable,
  setSelectedDocument,
  setConsumerDocuments,
  setOtpVerified,
  setOtpSent,
  setShareError,
  setNetworkingOptIn,
  setConsumerEmail,
  setConsumerPhone,
  setUploadNewData,
  setNetworkingSkipped,
  ConsumerAccountLookupError,
  setBottomSheetStep,
  BottomSheetStep,
  LoadConsumerIdentityDocumentsError,
  ConsumerAccountError,
} from 'gelato/frontend/src/controllers/states/NetworkedIdentityState';
import clearConsumerData from 'gelato/frontend/src/controllers/utils/clearConsumerData';
import cloneConsumerIdentityDocument from 'gelato/frontend/src/controllers/utils/cloneConsumerIdentityDocument';
import confirmConsumerSessionVerification from 'gelato/frontend/src/controllers/utils/confirmConsumerSessionVerification';
import createConsumerDocumentAssociationToken from 'gelato/frontend/src/controllers/utils/createConsumerDocumentAssociationToken';
import loadConsumerIdentityDocuments from 'gelato/frontend/src/controllers/utils/loadConsumerIdentityDocuments';
import lookupConsumerSession from 'gelato/frontend/src/controllers/utils/lookupConsumerSession';
import prepareVerificationForNetworking from 'gelato/frontend/src/controllers/utils/prepareVerificationForNetworking';
import routeToNextPage from 'gelato/frontend/src/controllers/utils/routeToNextPage';
import signUpConsumerAccount from 'gelato/frontend/src/controllers/utils/signUpConsumerAccount';
import startConsumerSessionVerification from 'gelato/frontend/src/controllers/utils/startConsumerSessionVerification';
import {transformConsumerIdentityDocuments} from 'gelato/frontend/src/controllers/utils/transformConsumerIdentityDocuments';
import analytics from 'gelato/frontend/src/lib/analytics';
import getDefaultCountryForSMS from 'gelato/frontend/src/lib/getDefaultCountryForSMS';
import {LocaleKey} from 'gelato/frontend/src/lib/locale';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';
import Storage from 'gelato/frontend/src/lib/Storage';
import CodePuncher from 'sail/CodePuncher';

import type {CountryCode} from '@sail/ui';
import type {
  ConsumerIdentityDocumentApi,
  ConsumerSessionConfirmVerificationResponse,
} from 'gelato/frontend/src/api/Consumer/types';
import type {
  ApplicationAction,
  ApplicationActionWithPayload,
} from 'gelato/frontend/src/controllers/types';

/**
 * Updates state after Link consumer account verification is successful
 * @param controller The application controller.
 * @param response API Response from confirming Link consumer account verification
 */
const consumerOtpVerifiedAction: ApplicationActionWithPayload<
  ConsumerSessionConfirmVerificationResponse
> = async (controller, response) => {
  analytics.track('linkAuthenticationSuccess', {
    consumer_account: controller.state.networkedIdentity.consumerAccountId,
  });

  const consumerSession = response.consumer_session;

  controller.update((draft) => {
    setConsumerSession(draft, consumerSession);
  });

  await fetchAndLoadConsumerDocumentsAction(controller);
};

/**
 * Updates state after fetching consumer account identity documents
 * @param controller The application controller.
 */
export const fetchAndLoadConsumerDocumentsAction: ApplicationAction = async (
  controller,
) => {
  const loadedDocuments = await loadConsumerIdentityDocuments(controller.state);

  if (loadedDocuments.length === 0) {
    // Skip document select page if there are no documents to select
    controller.update((draft) => {
      setOtpVerified(draft, true);
      setNetworkingOptIn(draft, false);
    });

    try {
      await prepareVerificationForNetworking(
        controller.state,
        controller.runtime!,
      );
    } catch {
      throw new Error(ConsumerAccountError.GENERIC);
    }

    throw new Error(LoadConsumerIdentityDocumentsError.NO_DOCUMENTS);
  }

  return loadConsumerDocumentsAction(controller, loadedDocuments);
};

/**
 * Updates state after with consumer account identity documents transformed for UI requirements
 * @param controller The application controller.
 * @param response API response from consumer account identity documents.
 * @param featureFlags Array of feature flags.
 */
export const loadConsumerDocumentsAction: ApplicationActionWithPayload<
  ConsumerIdentityDocumentApi[]
> = async (controller, response) => {
  const transformedDocuments = transformConsumerIdentityDocuments(
    response,
    controller.state,
  );
  controller.update((draft) => {
    const firstDocument = transformedDocuments.at(0);

    if (firstDocument) {
      // Documents are sorted in transformConsumerDocuments by valid and then invalid documents.
      // If the first document is invalid, can assume that all following documents are also invalid.
      if (firstDocument.invalidUseReasons.isInvalid) {
        setSelectedDocument(draft, undefined);
      } else {
        setSelectedDocument(draft, firstDocument.id);
      }
    } else {
      setSelectedDocument(draft, undefined);
    }

    setConsumerDocuments(draft, transformedDocuments);
    setOtpVerified(draft, true);
  });

  await routeToNextPage(
    controller.state,
    controller.runtime!,
    'link_reuse_page',
    undefined,
    'networkedIdentityActions.loadConsumerDocumentsAction',
  );
};

/**
 * Looks up a Link consumer account by email address.
 * @param controller The application controller.
 * @param data The data passed to the action
 *   - email: This email address may be merchant proided or entered by the consumer.
 */
export const lookupConsumerAccountAction: ApplicationActionWithPayload<{
  email: string;
}> = async (controller, data) => {
  const {email} = data;

  controller.update((draft) => {
    setConsumerEmail(draft, email);
  });

  const lookupResult = await lookupConsumerSession(email);

  if (lookupResult.type === 'object') {
    if (lookupResult.object.exists) {
      const result = lookupResult.object;

      controller.update((draft) => {
        setConsumerSession(draft, result.consumer_session);
        setConsumerAccountId(draft, result.account_id);
      });

      analytics.track('linkLookupSuccess', {
        consumer_account: result.account_id,
      });
    } else {
      throw new Error(ConsumerAccountLookupError.NOT_FOUND);
    }
  } else {
    const {code} = lookupResult.error;
    if (code === 'rate_limit_exceeded') {
      throw new Error(ConsumerAccountError.RATE_LIMIT_EXCEEDED);
    }
    throw new Error(ConsumerAccountError.GENERIC);
  }
};

/**
 * Updates state after user confirms decision to reuse networked identity document.
 * @param controller The application controller.
 */
export const reuseConsumerDocumentAction: ApplicationAction = async (
  controller,
) => {
  if (!controller.state.session) {
    return;
  }

  const missingFields = controller.state.session.missingFields;
  if (missingFields.includes('id_document_images')) {
    if (controller.state.networkedIdentity.selectedDocument) {
      analytics.track('linkReuseConsumerDocumentStarted', {
        consumer_account: controller.state.networkedIdentity.consumerAccountId,
        consumer_identity_document:
          controller.state.networkedIdentity.selectedDocument,
      });

      const associationTokenResult =
        await createConsumerDocumentAssociationToken(
          controller.state,
          controller.state.networkedIdentity.selectedDocument,
        );

      if (associationTokenResult.type === 'object') {
        let cloneRes;
        try {
          cloneRes = await cloneConsumerIdentityDocument(
            controller.runtime!,
            associationTokenResult.object.association_token,
          );
        } catch {
          controller.update((draft) => {
            setShareError(draft, true);
          });
          return;
        }

        const updatedPartialSession =
          cloneRes?.data?.cloneConsumerIdentityDocument?.session;

        controller.update((draft) => {
          // Update the session after document data is cloned
          if (draft.session) {
            if (updatedPartialSession) {
              shallowMergeInto(draft.session, updatedPartialSession);
            }
          }
          draft.networkedIdentity.shareSuccess = true;
        });

        Storage.setSharedNetworkedDocument(true);

        analytics.track('linkReuseConsumerDocumentSuccess', {
          consumer_account:
            controller.state.networkedIdentity.consumerAccountId,
          consumer_identity_document:
            controller.state.networkedIdentity.selectedDocument,
        });
      } else {
        controller.update((draft) => {
          setShareError(draft, true);
        });
      }
    } else {
      analytics.track('linkCloneConsumerDocumentConsentStarted', {
        consumer_account: controller.state.networkedIdentity.consumerAccountId,
      });

      try {
        await prepareVerificationForNetworking(
          controller.state,
          controller.runtime!,
        );
        controller.update((draft) => {
          setUploadNewData(draft, true);
        });
      } catch {
        controller.update((draft) => {
          setUnavailable(draft, true);
        });
        return;
      }

      analytics.track('linkCloneConsumerDocumentConsentSuccess', {
        consumer_account: controller.state.networkedIdentity.consumerAccountId,
      });
    }
  }
  await routeToNextPage(
    controller.state,
    controller.runtime!,
    'reuse_networked_document',
    undefined,
    'networkedIdentityActions.reuseConsumerDocumentAction',
  );
};

/**
 * Starts sign in for an existing Link consumer account.
 * @param controller The application controller.
 * @param data The data passed to the action.
 *   - locale: user locale
 */
export const signInConsumerAccountAction: ApplicationActionWithPayload<{
  locale: string;
}> = async (controller, {locale}) => {
  if (controller.state.networkedIdentity.consumerSession) {
    controller.update((draft) => {
      setOtpValue(draft, '');
      setOtpError(draft, undefined);
    });
    setConsumerAccountLoadingAction(controller, true);
    analytics.track('linkAuthenticationStarted', {
      consumer_account: controller.state.networkedIdentity.consumerAccountId,
    });

    const result = await startConsumerSessionVerification(
      controller.state,
      locale,
    );

    if (result.type === 'object') {
      controller.update((draft) => {
        setNetworkingOptIn(draft, true);
      });
      await closeLayerAction(controller, BottomSheet);
      routeToNextPage(
        controller.state,
        controller.runtime!,
        'link_otp_page',
        undefined,
        'networkedIdentityActions.signInConsumerAccountAction',
      );
    } else {
      const {code} = result.error;

      if (code?.includes('rate_limit_exceeded')) {
        throw new Error(ConsumerAccountError.RATE_LIMIT_EXCEEDED);
      } else {
        throw new Error(ConsumerAccountError.GENERIC);
      }
    }
    setConsumerAccountLoadingAction(controller, false);
    controller.update((draft) => {
      setOtpLoading(draft, false);
    });
  }
};

/**
 * Starts sign up for a new Link consumer account.
 * @param controller The application controller.
 * @param data The data passed to the action.
 *   - consumerPhoneCountry: consumer phone number country
 *   - locale: user locale
 */
export const signUpConsumerAccountAction: ApplicationActionWithPayload<{
  consumerPhoneCountry: CountryCode | undefined;
  locale: LocaleKey;
}> = async (controller, {consumerPhoneCountry, locale}) => {
  const countryCode =
    consumerPhoneCountry ||
    getDefaultCountryForSMS(controller.state.session, locale);

  const signUpResult = await signUpConsumerAccount(controller.state, {
    phone_number: controller.state.networkedIdentity.consumerPhone,
    country: countryCode,
    country_inferring_method: 'PHONE_NUMBER',
    locale,
  });

  if (signUpResult.type === 'object') {
    controller.update((draft) => {
      setConsumerAccountId(draft, signUpResult.object.account_id);
      setConsumerSession(draft, signUpResult.object.consumer_session);
    });

    analytics.track('linkSignupSuccess', {
      consumer_account: signUpResult.object.account_id,
    });

    analytics.track('linkCloneConsumerDocumentConsentStarted', {
      consumer_account: signUpResult.object.account_id,
    });

    await prepareVerificationForNetworking(
      controller.state,
      controller.runtime!,
    );

    analytics.track('linkCloneConsumerDocumentConsentSuccess', {
      consumer_account: signUpResult.object.account_id,
    });

    controller.update((draft) => {
      setConsumerAccountLookupError(draft, undefined);
    });

    await closeLayerAction(controller, BottomSheet);

    return routeToNextPage(
      controller.state,
      controller.runtime!,
      'leave_link',
      undefined,
      'networkedIdentityActions.signUpConsumerAccountAction',
    );
  } else {
    throw new Error(ConsumerAccountError.GENERIC);
  }
};

/**
 * Updates state after user decision to skip networking.
 * @param controller The application controller.
 */
export const skipNetworkingAction: ApplicationAction = async (controller) => {
  await clearConsumerData(controller.runtime!);

  controller.update((draft) => {
    setNetworkingOptIn(draft, false);
    setNetworkingSkipped(draft, true);
  });

  routeToNextPage(
    controller.state,
    controller.runtime!,
    'skip_networking',
    undefined,
    'networkedIdentityActions.skipNetworkingAction',
  );
};

/**
 * Resends Link consumer account verification SMS OTP
 * @param controller The application controller.
 * @param data The data passed to the action.
 *   - locale: user locale
 *   - codePuncherRef: reference to the code puncher component
 */
export const resendConsumerOtpAction: ApplicationActionWithPayload<{
  locale: string;
  codePuncherRef: React.RefObject<CodePuncher>;
}> = async (controller, data) => {
  const {locale, codePuncherRef} = data;
  controller.update((draft) => {
    setOtpLoading(draft, true);
  });

  const result = await startConsumerSessionVerification(
    controller.state,
    locale,
  );

  controller.update((draft) => {
    if (result.type === 'object') {
      setOtpSent(draft, true);
      setCanResendOtp(draft, false);
      // Set input focus to the code puncher
      codePuncherRef.current?.handleControlInputFocus();
      setCanResendOtp(draft, true);
    } else {
      const {code} = result.error;
      if (code?.includes('rate_limit_exceeded')) {
        setUnavailable(draft, true);
      } else {
        setOtpError(draft, messages.genericError);
      }
    }

    setOtpLoading(draft, false);
  });
};

/**
 * Updates state after consumer updates email address.
 * @param controller The application controller.
 * @param data The data passed to the action.
 *   - email: consumer email address
 */
export const updateConsumerEmailAction: ApplicationActionWithPayload<{
  email: string;
}> = async (controller, {email}) => {
  controller.update((draft) => {
    setConsumerEmail(draft, email);
    setLookupError(draft, undefined);
  });
};

/**
 * Updates state after Link consumer account verification OTP is updated
 * @param controller The application controller.
 * @param data The data passed to the action.
 *   - otpInput: user entered OTP
 *   - locale: user locale
 *   - codePuncherRef: reference to the code puncher component
 */
export const updateConsumerOtpInputAction: ApplicationActionWithPayload<{
  otpInput: string;
  locale: string;
  codePuncherRef: React.RefObject<CodePuncher>;
}> = async (controller, data) => {
  const {otpInput, locale, codePuncherRef} = data;
  controller.update((draft) => {
    setOtpValue(draft, otpInput);
  });

  if (otpInput.length === 6) {
    controller.update((draft) => {
      setOtpLoading(draft, true);
    });

    const confirmVerificationResult = await confirmConsumerSessionVerification(
      controller.state,
      otpInput,
    );

    if (confirmVerificationResult.type === 'object') {
      controller.update((draft) => {
        setConsumerSession(
          draft,
          confirmVerificationResult.object.consumer_session,
        );
      });
      const consumerSession = confirmVerificationResult.object.consumer_session;
      const otpVerified = consumerSession.verification_sessions.some(
        ({type, state}) => type === 'SMS' && state === 'VERIFIED',
      );

      if (
        otpVerified &&
        confirmVerificationResult.object.auth_session_client_secret
      ) {
        try {
          await consumerOtpVerifiedAction(
            controller,
            confirmVerificationResult.object,
          );
          // there are many places in this call stack
          // where we route somewhere else if there are no documents
          // this would override that routing
          await routeToNextPage(
            controller.state,
            controller.runtime!,
            'link_reuse_page',
            undefined,
            'networkedIdentityActions.updateConsumerOtpInputAction',
          );
        } catch (e: any) {
          if (e.message === ConsumerAccountError.GENERIC) {
            // prepareVerificationForNetworking failed
            // route them to an invalid page of some sort
          } else if (
            e.message === LoadConsumerIdentityDocumentsError.NO_DOCUMENTS
          ) {
            // have no documents, don't route them to reuse
            await routeToNextPage(
              controller.state,
              controller.runtime!,
              'leave_link',
              undefined,
              'networkedIdentityActions.updateConsumerOtpInputAction',
            );
          }
        }
      }
      controller.update((draft) => {
        setOtpLoading(draft, false);
      });
    } else {
      const {code} = confirmVerificationResult.error;
      if (code === 'consumer_session_expired') {
        // session expired, lookup session and resend code
        controller.update((draft) => {
          setOtpError(draft, messages.sessionExpiredError);
        });

        const lookupResult = await lookupConsumerSession(
          controller.state.networkedIdentity.consumerSession!.email_address,
        );

        controller.update((draft) => {
          if (lookupResult.type === 'object') {
            if (lookupResult.object.exists) {
              const lookupResultFound = lookupResult.object;
              setConsumerSession(draft, lookupResultFound.consumer_session);
              resendConsumerOtpAction(controller, {locale, codePuncherRef});
            } else {
              setOtpError(draft, messages.genericError);
            }
          } else {
            setOtpError(draft, messages.genericError);
          }
        });
      } else if (code === 'consumer_verification_expired') {
        controller.update((draft) => {
          setOtpError(draft, messages.expiredOtpError);
        });
        resendConsumerOtpAction(controller, {locale, codePuncherRef});
      } else if (code === 'consumer_verification_code_invalid') {
        controller.update((draft) => {
          setOtpError(draft, messages.incorrectOtpCodeError);
        });
      } else if (code === 'consumer_verification_max_attempts_exceeded') {
        controller.update((draft) => {
          setOtpError(draft, messages.maxOtpAttemptsError);
        });
      } else {
        controller.update((draft) => {
          setOtpError(draft, messages.genericError);
        });
      }
      controller.update((draft) => {
        setOtpValue(draft, '');
        // Allow resending OTP after first failed attempt
        setCanResendOtp(draft, true);
        setOtpLoading(draft, false);
      });
    }
  }
};

/**
 * Updates state after consumer updates phone number.
 * @param controller The application controller.
 * @param data The data passed to the action.
 *   - phone: consumer phone number
 */
export const updateConsumerPhoneAction: ApplicationActionWithPayload<{
  phone: string;
}> = async (controller, {phone}) => {
  controller.update((draft) => {
    setConsumerPhone(draft, phone);
    setLookupError(draft, undefined);
  });
};

/**
 * Calculates whether clicked consumer document is valid or invalid and calls correct setter
 * @param selectedDocument ID of the selected document
 */
export const setValidOrInvalidIdentityDocument: ApplicationActionWithPayload<{
  documentId: string;
}> = async (controller, {documentId}) => {
  const clickedConsumerDocument =
    controller.state.networkedIdentity.consumerDocuments.find(
      (doc) => doc.id === documentId,
    );

  if (clickedConsumerDocument?.invalidUseReasons.isInvalid) {
    setInvalidDocument(controller, {documentId});
  } else {
    controller.update((draft) => {
      setSelectedDocument(draft, documentId);
    });
  }
};

export const clearInvalidDocument: ApplicationAction = async (controller) => {
  await closeLayerAction(controller, InvalidReasonsSheet);
  controller.update((draft) => {
    draft.networkedIdentity.selectedInvalidDocumentId = undefined;
  });
};

export const startLinkLoginFlow: ApplicationAction = async (controller) => {
  const success = await updateConsentAndDocumentTypeAction(controller, {
    consentAccepted: true,
  });

  if (!success) {
    return success;
  }

  const merchantProvidedEmail =
    controller.state.session?.collectedData?.individual?.email
      .merchantProvidedAddress;

  // reset bottom sheet step in case user has closed bottom sheet
  setBottomSheetStepAction(controller, BottomSheetStep.SaveId);

  if (merchantProvidedEmail) {
    try {
      await lookupConsumerAccountAction(controller, {
        email: merchantProvidedEmail,
      });
      setBottomSheetStepAction(controller, BottomSheetStep.Continue);
    } catch (e) {
      setBottomSheetStepAction(controller, BottomSheetStep.SaveId);
    }
  }

  openLayerAction(controller, BottomSheet);
};

/**
 * Updates state after user selects invalid networked identity document
 * @param selectedDocument ID of the selected document
 */
export const setInvalidDocument: ApplicationActionWithPayload<{
  documentId: string;
}> = async (controller, {documentId}) => {
  openLayerAction(controller, InvalidReasonsSheet);
  controller.update((draft) => {
    draft.networkedIdentity.selectedInvalidDocumentId = documentId;
  });
};

/**
 * Updates state to reflect user's decision to upload a new document and not re-use a previously saved document.
 */
export const addNewDocument: ApplicationAction = async (controller) => {
  controller.update((draft) => {
    draft.networkedIdentity.selectedDocument = undefined;
  });
};

/**
 * @param isLoading boolean signifying whether or not a consumer account API action has been taken
 */
export const setConsumerAccountLoadingAction: ApplicationActionWithPayload<
  boolean
> = async (controller, isLoading) => {
  controller.update((draft) => {
    draft.networkedIdentity.consumerAccountLoading = isLoading;
  });
};

export const setConsumerPhoneCountryAction: ApplicationActionWithPayload<{
  phoneCountry: CountryCode;
}> = async (controller, {phoneCountry}) => {
  controller.update((draft) => {
    draft.networkedIdentity.consumerPhoneCountry = phoneCountry;
  });
};

export const setOtpRequiredAction: ApplicationActionWithPayload<
  boolean
> = async (controller, otpRequired) => {
  controller.update((draft) => {
    draft.networkedIdentity.otpRequired = otpRequired;
  });
};

export const setBottomSheetStepAction: ApplicationActionWithPayload<
  BottomSheetStep
> = async (controller, step) => {
  controller.update((draft) => {
    setBottomSheetStep(draft, step);
  });
};
