import * as React from 'react';

import useDragToScroll from 'gelato/frontend/src/lib/hooks/useDragToScroll';
import useMouseWheelToScroll from 'gelato/frontend/src/lib/hooks/useMouseWheelToScroll';
import useScrollIndicator from 'gelato/frontend/src/lib/hooks/useScrollIndicator';

/**
 * The effect to improve the scroll experience of the scrollable element.
 * @param domRef
 */
export default function useScrollableArea(
  domRef: React.RefObject<HTMLElement>,
) {
  useDragToScroll(domRef);
  useScrollIndicator(domRef);
  useMouseWheelToScroll(domRef);
}
