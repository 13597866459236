import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import {sessionDynamicFragment} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

import type {
  GraphQlDocument,
  GraphQlFragmentUnion,
  ApolloError,
} from '@sail/data';
import type {Exact, Scalars} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';
import type {SessionDynamicFragmentData} from 'gelato/frontend/src/graphql/fragments/sessionDynamicFragment';

const doc = graphql`
  mutation SubmitMutation($forceDelay: Boolean!) {
    submit(forceDelay: $forceDelay) {
      session {
        id
        ...SessionDynamicFragment
      }
      success
    }
  }

  ${sessionDynamicFragment}
`;

export const submitMutation: SubmitMutationDocument = doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const submitMutationOptions: GelatoMutationOptionsWithoutVariables<SubmitMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useSubmitMutation(
  options: GelatoMutationOptions<SubmitMutationDocument> = {},
): GelatoMutationReturn<SubmitMutationDocument> {
  return useGelatoMutation(submitMutation, {
    ...submitMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type SubmitMutationVariables = Exact<{forceDelay: Scalars['Boolean']}>;

export type SubmitMutationData = {
  readonly __typename: 'Mutation';
  readonly submit: {
    readonly __typename: 'SubmissionResponse';
    readonly success: boolean;
    readonly session: GraphQlFragmentUnion<
      {readonly __typename: 'Session'; readonly id: string},
      [SessionDynamicFragmentData]
    >;
  };
};

const submitMutationPermissions = {
  ...sessionDynamicFragment.permissions,
} as const;

doc.permissions = submitMutationPermissions;

export type SubmitMutationPermissions = GelatoPermissionToken<
  typeof submitMutationPermissions
>;

export type SubmitMutationDocument = GraphQlDocument<
  SubmitMutationData,
  SubmitMutationVariables,
  SubmitMutationPermissions
>;
