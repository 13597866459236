/* eslint sort-keys: ["error", "asc"] */
import useGenerateOneTimePasswordMutation from 'gelato/frontend/src/graphql/mutations/useGenerateOneTimePasswordMutation';
import useGenerateSecondaryHandoffUrlMutation from 'gelato/frontend/src/graphql/mutations/useGenerateSecondaryHandoffUrlMutation';
import useReclaimPrimaryMutation from 'gelato/frontend/src/graphql/mutations/useReclaimPrimaryMutation';
import useSendHandoffEmailMutation from 'gelato/frontend/src/graphql/mutations/useSendHandoffEmailMutation';
import useSendSmsMutation from 'gelato/frontend/src/graphql/mutations/useSendSmsMutation';
import useSetStartedAtMutation from 'gelato/frontend/src/graphql/mutations/useSetStartedAtMutation';
import useSetTestmodeSettingMutation from 'gelato/frontend/src/graphql/mutations/useSetTestmodeSettingMutation';
import useSubmitMutation from 'gelato/frontend/src/graphql/mutations/useSubmitMutation';
import useUpdateConsentMutation from 'gelato/frontend/src/graphql/mutations/useUpdateConsentMutation';
import useUpdateIndividualMutation from 'gelato/frontend/src/graphql/mutations/useUpdateIndividualMutation';
import useValidateOneTimePasswordMutation from 'gelato/frontend/src/graphql/mutations/useValidateOneTimePasswordMutation';
import useGetSessionLazyQuery from 'gelato/frontend/src/graphql/queries/useGetSessionLazyQuery';

export type DataHooks = ReturnType<typeof useDataHooks>;

// TODO(rwashburne, 2024-02-01): Continue to add all remaining data hooks here after confirming 409s are resolved.

/**
 * Initialize all the data hooks so that we have access to the 'client' in the context.
 * @returns The data hooks.
 */
function useDataHooks() {
  // --- Queries ---
  const [getSessionQuery] = useGetSessionLazyQuery();

  const query = {
    getSessionQuery,
  };

  // --- Mutations ---
  const [generateSecondaryHandoffUrlMutation] =
    useGenerateSecondaryHandoffUrlMutation();
  const [reclaimPrimaryMutation] = useReclaimPrimaryMutation();
  const [sendHandoffEmailMutation] = useSendHandoffEmailMutation();
  const [sendSmsMutation] = useSendSmsMutation();
  const [submitVerification] = useSubmitMutation();
  const [setStartedAtMutation] = useSetStartedAtMutation();
  const [updateConsentMutation] = useUpdateConsentMutation();
  const [setTestmodeSettingMutation] = useSetTestmodeSettingMutation();
  const [updateIndividualMutation] = useUpdateIndividualMutation();
  const [generateOTPMutation] = useGenerateOneTimePasswordMutation();
  const [validateOTPMutation] = useValidateOneTimePasswordMutation();

  const mutation = {
    generateOTPMutation,
    generateSecondaryHandoffUrlMutation,
    reclaimPrimaryMutation,
    sendHandoffEmailMutation,
    sendSmsMutation,
    setStartedAtMutation,
    setTestmodeSettingMutation,
    submitVerification,
    updateConsentMutation,
    updateIndividualMutation,
    validateOTPMutation,
  };

  return {
    mutation,
    query,
  };
}

export default useDataHooks;
