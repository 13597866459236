import * as React from 'react';
import {useIsomorphicLayoutEffect} from '@sail/engine';
import {toNumber} from '../../utils';
import {useResizeObserver} from './useResizeObserver';

// defining this manually instead of just using React.RefObject
// because our flowify transform doesn't handle the mapping correctly
type RefObject<T> = {readonly current: null | T};

interface Config {
  ref: RefObject<HTMLElement>;
  menuTrigger: React.ReactNode;
  collapse?: 'none' | 'auto';
  direction?: 'row' | 'column';
  textDirection?: 'ltr' | 'rtl';
}

export function useOverflowCollapse({
  ref,
  menuTrigger,
  collapse = 'auto',
  direction = 'row',
  textDirection = 'ltr',
}: Config): {hiddenChildrenIndexes: Set<number>} {
  const [hiddenChildrenIndexes, setHiddenChildrenIndexes] = React.useState(
    new Set<number>(),
  );

  const measureElement = React.useCallback(
    (element: HTMLElement) => {
      const rect = element.getBoundingClientRect();
      const style = window.getComputedStyle(element);
      const isHorizontal = direction === 'row';
      const measurement = {
        contentSize: isHorizontal
          ? element.clientWidth -
            toNumber(style.paddingLeft) -
            toNumber(style.paddingRight)
          : element.clientHeight -
            toNumber(style.paddingTop) -
            toNumber(style.paddingBottom),
        size: isHorizontal ? rect.width : rect.height,
        start: isHorizontal ? rect.left : rect.top,
        end: isHorizontal ? rect.right : rect.bottom,
      };

      if (isHorizontal && textDirection === 'rtl') {
        const {start, end} = measurement;
        measurement.start = end;
        measurement.end = start;
      }

      return measurement;
    },
    [direction, textDirection],
  );

  const updateOverflow = React.useCallback(() => {
    const wrapperElement = ref.current;
    const parentElement = wrapperElement?.parentElement;

    if (collapse === 'auto' && wrapperElement) {
      // show all the children so we can measure them
      [...wrapperElement.children].forEach((child) => {
        (child as HTMLElement).style.display = '';
      });

      const nextHiddenChildren = new Set<number>();

      // measure everything in one pass to avoid layout thrashing
      const parentMeasurement = measureElement(parentElement!);
      const childEntries = [...wrapperElement.children].map((child, index) => {
        const element = child as HTMLElement;

        return {
          element,
          index,
          measurement: measureElement(element),
        };
      });

      const menuTrigger = childEntries.pop() as (typeof childEntries)[number];

      if (wrapperElement.children.length <= 1) {
        // eslint-disable-next-line no-console
        console.error('No children supplied to ButtonGroup.');
        menuTrigger.element.style.display = 'none';
        return;
      }

      const {length, 0: first, [length - 1]: last} = childEntries;
      let contentSpace = Math.abs(
        last.measurement.end - first.measurement.start,
      );
      let availableSpace = parentMeasurement.contentSize;

      const isOverflowing = contentSpace > availableSpace;
      const gapSize = menuTrigger.measurement.start - last.measurement.end;

      const activeElement = document.activeElement;

      if (isOverflowing) {
        // be sure to account for the space we'll need for the menu trigger
        availableSpace -= menuTrigger.measurement.size + gapSize;

        childEntries.sort(
          (a, b) =>
            // first sort according to priority (higher numbers get hidden first)
            Number(b.element.getAttribute('data-priority') ?? 2) -
              Number(a.element.getAttribute('data-priority') ?? 2) ||
            // then sort by element size (larger elements get hidden first)
            b.measurement.size - a.measurement.size ||
            // lastly, hide later elements first
            b.index - a.index,
        );

        // start hiding elements until we've reclaimed enough space
        childEntries.forEach((child) => {
          if (contentSpace > availableSpace) {
            nextHiddenChildren.add(child.index);
            child.element.style.display = 'none';
            contentSpace -= child.measurement.size + gapSize;
          }
        });
      } else {
        menuTrigger.element.style.display = 'none';
      }

      // handle loss of focus due to overflow collapsing
      if ((activeElement as HTMLElement)?.style.display === 'none') {
        if (activeElement === menuTrigger.element) {
          const firstFocusableChild =
            wrapperElement.querySelector('[tabindex]');
          (firstFocusableChild as HTMLElement | null)?.focus();
        } else if (activeElement?.parentElement === wrapperElement) {
          menuTrigger.element.focus();
        }
      }

      if (hiddenChildrenIndexes.size !== nextHiddenChildren.size) {
        setHiddenChildrenIndexes(nextHiddenChildren);
      }
    }
  }, [ref, collapse, measureElement, hiddenChildrenIndexes]);

  useIsomorphicLayoutEffect(updateOverflow, [updateOverflow, menuTrigger]);
  useResizeObserver({
    node: ref.current?.parentElement,
    handler: updateOverflow,
  });

  return {hiddenChildrenIndexes};
}
