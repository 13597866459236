import {useCallback, useRef} from 'react';
import {useLayoutEffect} from './useLayoutEffect';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useEffectEvent<F extends (...args: any[]) => void>(fn: F) {
  const ref = useRef<F | null>(null);
  useLayoutEffect(() => {
    ref.current = fn;
  }, [fn]);
  return useCallback((...args) => {
    const f = ref.current;
    return f?.(...args);
  }, []);
}
