export const variantsInputDevice = {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover
  hover: {
    hover: '(hover: hover)',
    none: '(hover: none)',
  },
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer
  pointer: {
    coarse: '(pointer: coarse)',
    fine: '(pointer: fine)',
    none: '(pointer: none)',
  },
};
