import isEqual from 'lodash/isEqual';

import {getLocale} from 'gelato/frontend/src/lib/locale';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';

import type {Locale} from 'gelato/frontend/src/controllers/states/LocaleState';
import type {ApplicationActionWithPayload} from 'gelato/frontend/src/controllers/types';

/**
 * Callback for when the locale changes.
 * @param controller The controller instance.
 * @param payload The payload for the action.
 * @returns A promise that resolves to true if the action was successful.
 */
export const localeDidChangeAction: ApplicationActionWithPayload<{
  locale: Locale;
}> = async (controller, payload) => {
  const {locale} = payload;

  controller.update((draft) => {
    const newValue = draft.locale.availableValues.find((item) => {
      return item[0] === locale;
    });

    if (!newValue) {
      // The locale specified is invalid.
      return;
    }

    if (!isEqual(draft.locale.currentValue, newValue)) {
      shallowMergeInto(draft.locale, {currentValue: newValue});
    }

    const browserLocale = getLocale();
    const suggestedLocales: Set<string> = new Set([browserLocale]);
    const ipCountry = draft.session?.ipCountry;

    if (ipCountry === 'CA' || suggestedLocales.has('fr-CA')) {
      // Suggest both French and English for Canadian users.
      suggestedLocales.add('fr-CA');
      suggestedLocales.add('en-US');
    } else if (ipCountry === 'US') {
      // Suggest both English and Spanish for US users.
      suggestedLocales.add('en-US');
      suggestedLocales.add('es-419');
    } else if (ipCountry === 'CN') {
      // Suggest simplified Chinese and traditional Chinese for China users.
      suggestedLocales.add('zh-Hans');
      suggestedLocales.add('zh-Hant');
    } else if (ipCountry) {
      // Suggest the locale for the user's country.
      const suffix = `-${ipCountry}`;
      draft.locale.availableValues.forEach((value) => {
        const locale = value[0];
        if (locale.endsWith(suffix)) {
          suggestedLocales.add(locale);
        }
      });
    }

    const suggestedValues = draft.locale.availableValues.filter((value) => {
      return suggestedLocales.has(value[0]);
    });

    if (!isEqual(suggestedValues, draft.locale.suggestedValues)) {
      shallowMergeInto(draft.locale, {suggestedValues});
    }
  }, 'skip_unchanged_state');

  return true;
};

/**
 * Change the current locale for the runtime.
 * @param controller The controller instance.
 * @param payload The payload for the action.
 * @returns A promise that resolves to true if the action was successful.
 */
export const changeLocaleAction: ApplicationActionWithPayload<{
  locale: Locale;
}> = async (controller, payload) => {
  const {locale} = payload;
  controller.runtime?.updateLocale(locale);
  return true;
};
