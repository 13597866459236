import {getViewConfig} from './ViewConfig';
import {mergeRefs} from './useRefs';
import {DYNAMIC_LAYER} from '../../css/core/constants';
import {addIntent} from '../../../intent';
import type {AbstractPropsFacade, PropertyProcessor} from './types';

interface ListState<T> {
  list: T[];
  merged: T[];
}

function createListPropertyProcessor<T>(
  merge: (list: T[]) => T,
): PropertyProcessor<T> {
  return function setListProperty(
    value: T,
    target: AbstractPropsFacade,
    property: string,
  ) {
    const config = getViewConfig(target);
    if (!config.state[property]) {
      config.state[property] = {
        list: [],
        merged: [],
      };
    }

    const state = config.state[property] as ListState<T>;
    const cache = config.cache[property] as ListState<T>;
    const index = state.list.length;
    if (typeof value !== 'undefined') {
      state.list.push(value);
    }
    if (cache && cache !== state && cache.list[index] === value) {
      state.merged.push(cache.merged[index]);
    } else {
      state.merged.push(index === 0 ? value : merge(state.list));
      config.cache[property] = state;
    }
    config.props[property] = state.merged[index];
  };
}

export const setEvent = createListPropertyProcessor(
  (clonedHandlers: EventListener[]) =>
    function mergedEvent(event: Event) {
      for (let i = 0; i < clonedHandlers.length; i++) {
        clonedHandlers[i](event);
      }
    },
);

export const setRef =
  createListPropertyProcessor<React.Ref<unknown>>(mergeRefs);

export const setCss: PropertyProcessor<unknown> = (
  value: unknown,
  facade: AbstractPropsFacade,
) => {
  const config = getViewConfig(facade);
  if (value && config.css) {
    addIntent(config, config.css[DYNAMIC_LAYER](value));
  }
};
