import {ApolloProvider} from '@apollo/client';
import * as React from 'react';
import {createApolloClient} from 'src/internal/apollo/client';
import {DataConfigContext} from 'src/internal/config/DataConfigContext';

import type {DataConfig} from 'src/internal/config/types';

type Props = {
  children: React.ReactNode;
  config: DataConfig;
};

export default function DataProvider({
  children,
  config,
}: Props): React.ReactElement {
  const client = React.useMemo(
    () => (config.graphql ? createApolloClient(config.graphql) : null),
    [config],
  );

  if (!client) {
    return (
      <DataConfigContext.Provider value={config}>
        {children}
      </DataConfigContext.Provider>
    );
  }

  return (
    <DataConfigContext.Provider value={config}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </DataConfigContext.Provider>
  );
}
