import clsx from 'clsx';
import * as React from 'react';
import Div100vh from 'react-div-100vh';
import {defineMessages} from 'react-intl';

import CardHeader from 'gelato/frontend/src/components/CardHeader';
import Footer from 'gelato/frontend/src/components/Footer';
import Message from 'gelato/frontend/src/components/Message';
import MobileHeader, {
  BackLink,
} from 'gelato/frontend/src/components/MobileHeader';
import ProgressBar from 'gelato/frontend/src/components/ProgressBar';
import TestmodeBanner from 'gelato/frontend/src/components/TestmodeBanner';
import TestmodeBannerV2 from 'gelato/frontend/src/components/TestModeBannerV2';
import analytics from 'gelato/frontend/src/lib/analytics';
import computeProgressValue from 'gelato/frontend/src/lib/computeProgressValue';
import {
  CSS_TRANSITION_DURATION,
  CSS_TRANSITION_DURATION_MS,
  TITLE_BAR_HEIGHT,
  IFRAME_HEIGHT_PADDING,
} from 'gelato/frontend/src/lib/constants';
import {PageCardAnimatingContext} from 'gelato/frontend/src/lib/contexts';
import {
  useBreakpoint,
  useFeatureFlags,
  useConnectIframe,
  useDebouncedResizeObserver,
  useSession,
  useRouter,
  usePageLayoutPortrait,
} from 'gelato/frontend/src/lib/hooks';
import {isInIframe} from 'gelato/frontend/src/lib/iframe';
import pageTransitionStyles from 'gelato/frontend/src/lib/pageTransitions.module.css';
import {responsiveBackground} from 'gelato/frontend/src/lib/responsive';
import Box from 'sail/Box';
import {ButtonLink} from 'sail/Button';
import Card from 'sail/Card';
import Menu, {MenuGroup} from 'sail/Menu';
import {Body, Heading} from 'sail/Text';

import styles from './PageCard.module.css';

import type {OptionRenderer} from 'gelato/frontend/src/components/OtherOptions';

export type Props = {
  background?: 'white' | 'transparent';
  children?: React.ReactNode;
  height?: string;
  onBack?: () => void;
  otherOptions?: OptionRenderer[];
  right?: React.ReactNode;
  showBackLink?: boolean;
  showPageHeader?: boolean;
  showPageFooter?: boolean;
  showProgressBar?: boolean;
  size?: 'single' | 'double';
  style?: Record<any, any>;
  title?: React.ReactNode | null | undefined;

  // the platformIcon and platformName props are used to provide branding
  // settings directly to this component in contexts where branding is not
  // available via graphql in the useBranding hook. e.g. in the no-code flows
  // this component is rendered on some pages before authentication has occurred
  // and so before there is a VerificationSession to provide branding.
  platformIcon?: string;
  platformName?: string;
  livemode?: boolean;
};

const messages = defineMessages({
  otherOptions: {
    id: 'verification.other_options.button',
    description:
      'Button that allows the user to open the other options dropdown during a verification',
    defaultMessage: 'Other options',
  },
  more: {
    id: 'verification.more.button',
    description:
      'Button that allows the user to open more options dropdown during a verification',
    defaultMessage: 'More',
  },
  back: {
    id: 'verification.more.back',
    description:
      'Button that goes back in the UI (same as browser back button)',
    defaultMessage: 'Back',
  },
});

const PageCard = ({
  children,
  otherOptions = [],
  right,
  showBackLink = false,
  showPageHeader = false,
  showPageFooter = true,
  showProgressBar = true,
  size = 'single',
  height,
  title,
  platformIcon,
  platformName,
  livemode,
}: Props) => {
  const router = useRouter();
  const breakpoint = useBreakpoint();
  const flags = useFeatureFlags();
  const isConnectIframe = useConnectIframe();
  const background = responsiveBackground({
    breakpoint,
    inIframe: isConnectIframe,
  });
  const session = useSession();

  let useLivemode = true;
  if (typeof livemode === 'boolean') {
    useLivemode = livemode;
  } else if (session) {
    useLivemode = session.livemode;
  }

  const handleGoBack = React.useCallback(() => window.history.go(-1), []);
  const showHeader = !isConnectIframe && showPageHeader;
  const showFooter =
    !isConnectIframe && (breakpoint === 'desktop' ? true : showPageFooter);

  const pageLayoutPortraitEnabled = usePageLayoutPortrait();

  // The BackLink would only be within one of these places:
  // 1. header
  // 2. page title
  // 3. page content
  let showBackLinkAtTitle = showBackLink && breakpoint === 'mobile';
  let showBackLinkAtPageContent = showBackLink && breakpoint === 'desktop';

  if (showHeader && showBackLink && pageLayoutPortraitEnabled) {
    // The back link shows inside header instead.
    showBackLinkAtTitle = false;
    showBackLinkAtPageContent = false;
  } else if (
    pageLayoutPortraitEnabled &&
    showBackLinkAtPageContent &&
    !showHeader &&
    title
  ) {
    // Move the back link from the page content into the title.
    showBackLinkAtTitle = true;
    showBackLinkAtPageContent = false;
  }

  const isEmbedded = isInIframe();

  // Show fake progress if no progress is made
  const shouldShowProgressBar = showProgressBar && !isConnectIframe;
  const progressToShow = shouldShowProgressBar
    ? computeProgressValue(session)
    : 0;

  // TODO: https://jira.corp.stripe.com/browse/IDPROD-4990
  // Need an API to configure whether the embedded the page need to use the full
  // height of the containing iframe or not.
  // For now, use a flag to gate this feature for specific merchants.
  const isPageWithFullHeight =
    (isEmbedded &&
      !!flags &&
      !!flags.includes('idprod_use_full_page_height')) ||
    !isEmbedded;

  // start animations
  const [animating, setAnimating] = React.useState(false);
  React.useEffect(() => {
    let mounted = true;

    setAnimating(true);
    const timeoutFunc = setTimeout(() => {
      if (mounted) {
        setAnimating(false);
      }
    }, CSS_TRANSITION_DURATION_MS);

    // clean up
    return () => {
      mounted = false;
      clearTimeout(timeoutFunc);
    };
  }, [size]);

  // dont animate the transition from mobile to desktop views
  const [transition, setTransition] = React.useState('');
  React.useEffect(() => {
    setTransition('');
    const timeoutFunc = setTimeout(
      () => setTransition(`all ${CSS_TRANSITION_DURATION} ease-in-out`),
      500,
    );
    return () => clearTimeout(timeoutFunc);
  }, [breakpoint]);

  const showTestmodeBanner =
    useLivemode === false &&
    !(flags && flags.includes('gelato_disable_testmode_ui_elements'));

  const renderAdditionalOptionsMenu = (
    otherOptions: OptionRenderer[] | undefined | null,
  ) => {
    // meatball menu
    if (otherOptions && otherOptions.length > 0) {
      return (
        <Menu
          items={[
            <MenuGroup>{otherOptions.map((render) => render())}</MenuGroup>,
          ]}
        >
          {/* @ts-expect-error - TS2322 - Type '{ children: Element; style: { cursor: string; }; }' is not assignable to type 'IntrinsicAttributes & CommonProps & SettableTextProps'. */}
          <Body style={{cursor: 'pointer'}}>
            {breakpoint === 'mobile' || pageLayoutPortraitEnabled ? (
              <ButtonLink
                data-testid="page-card-other-options"
                color="blue"
                icon="chevronDown"
                iconPosition="right"
                label={
                  <Heading color="blue">
                    <Message {...messages.more} />
                  </Heading>
                }
              />
            ) : (
              <ButtonLink
                data-testid="page-card-other-options"
                color="blue"
                icon="chevronDown"
                iconPosition="right"
                label={
                  <Heading color="blue">
                    <Message {...messages.otherOptions} />
                  </Heading>
                }
              />
            )}
          </Body>
        </Menu>
      );
    }
    return null;
  };

  const {ref: cardRef, height: cardHeight} = useDebouncedResizeObserver(16);
  const {ref: cardContentRef, height: contentHeight} =
    useDebouncedResizeObserver(16);

  const routerPath = router.currentPath;

  React.useEffect(() => {
    // This effect calculates the full height of a page card, which consists of
    // three sections: header, inner content, and footer.

    // The effect is disabled when either the Butter 2.0 layout is disabled
    // or the page is not embedded within an iframe.
    if (!pageLayoutPortraitEnabled || !isEmbedded) {
      return;
    }

    // Debounce the action that calculates the height.
    const el = cardRef.current;
    const contentEl = cardContentRef.current;

    // Request an animation frame to perform the height calculation.
    const rid = requestAnimationFrame(() => {
      // Obtain the parent element of the content element.
      const innerEl = contentEl?.parentElement;

      // Get the heights of the outer element, inner element, and content element.
      const outerHeight = el?.offsetHeight || 0;
      const innerHeight = innerEl?.offsetHeight || 0;
      const contentHeight = contentEl?.scrollHeight || 0;

      // Calculate the iframe height based on the obtained heights.
      const iframeHeight =
        outerHeight && innerHeight && contentHeight
          ? outerHeight - innerHeight + contentHeight
          : window.innerHeight;

      // Send a message to the parent window to resize the iframe.
      window.parent.postMessage({height: iframeHeight, type: 'resize'}, '*');

      analytics.track('postIframeMessage', {
        iframe: true,
        state: {type: 'resize', body: {height: iframeHeight}},
      });
    });

    // Clean up the effect by canceling the animation frame request.
    return () => cancelAnimationFrame(rid);
  }, [
    cardContentRef,
    cardRef,
    contentHeight,
    cardHeight,
    isEmbedded,
    pageLayoutPortraitEnabled,
    routerPath,
  ]);

  React.useEffect(() => {
    if (pageLayoutPortraitEnabled) {
      // This effect is disabled.
      return;
    }

    const calculatedHeight = cardHeight || 0;
    const iframeHeight = isPageWithFullHeight
      ? // The page height is the height of the iframe.
        calculatedHeight
      : // The IFRAME_HEIGHT_PADDING exists to prevent scrollbars in certain
        //  modals where the iframe is rendered.
        calculatedHeight + IFRAME_HEIGHT_PADDING;
    window.parent.postMessage({height: iframeHeight, type: 'resize'}, '*');

    analytics.track('postIframeMessage', {
      iframe: true,
      state: {type: 'resize', body: {height: iframeHeight}},
    });
  }, [cardHeight, isPageWithFullHeight, pageLayoutPortraitEnabled]);

  const renderHeader = () => {
    if (showHeader) {
      if (breakpoint === 'mobile' || pageLayoutPortraitEnabled) {
        return (
          <MobileHeader
            showBackLink={showBackLink}
            platformName={platformName}
            platformIcon={platformIcon}
          />
        );
      }
      return (
        <CardHeader platformIcon={platformIcon} platformName={platformName} />
      );
    }
  };

  const renderPageCardContent = () => {
    const innerCardClass = clsx(styles.innerCard, {
      [styles.innerCardWithHeader]: title || showPageHeader,
    });

    return (
      <Box
        className={styles.pageCardRoot}
        flex={{direction: 'column', alignItems: 'center'}}
      >
        <Box
          className={styles.pageCardContainer}
          background={
            breakpoint === 'mobile' && !isConnectIframe ? 'white' : undefined
          }
          flex={{direction: 'column', alignItems: 'center'}}
        >
          <PageCardAnimatingContext.Provider value={animating}>
            <Box
              style={{
                flex: 1,
                transition,
              }}
              className={`Container--${
                breakpoint === 'mobile' ? 'mobile' : String(size)
              }`}
            >
              <Card
                className={clsx(
                  styles.card,
                  pageTransitionStyles.withoutPageTransitionAnimation,
                  showHeader && styles.cardWithHeader,
                )}
                radius={breakpoint === 'mobile' ? 'none' : 'all'}
                shadow={breakpoint === 'mobile' ? 'none' : 'medium'}
              >
                {showTestmodeBanner && pageLayoutPortraitEnabled && (
                  <TestmodeBannerV2 />
                )}
                <Box
                  background={background}
                  className={styles.titleContainer}
                  style={{
                    height: title ? `${TITLE_BAR_HEIGHT}px` : undefined,
                  }}
                >
                  {title && (
                    <div className={styles.titleContainer}>
                      <div className={styles.titleLeft}>
                        {showBackLinkAtTitle && <BackLink />}
                        <Heading>{title}</Heading>
                      </div>
                      {renderAdditionalOptionsMenu(otherOptions)}
                    </div>
                  )}
                  <Box padding={{right: 8}}>{right}</Box>
                </Box>
                {renderHeader()}
                {shouldShowProgressBar && (
                  <ProgressBar value={progressToShow} />
                )}
                <Box
                  className={innerCardClass}
                  background={background}
                  style={{
                    transition,
                  }}
                >
                  <div
                    className={
                      height === 'fill'
                        ? styles.pageCardContentHeightFill
                        : styles.pageCardContent
                    }
                    // @ts-expect-error - TS2322 - Type 'RefObject<HTMLElement>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
                    ref={cardContentRef}
                  >
                    {showTestmodeBanner && !pageLayoutPortraitEnabled && (
                      <TestmodeBanner
                        explicitOffset={
                          title && breakpoint === 'mobile'
                            ? TITLE_BAR_HEIGHT
                            : undefined
                        }
                      />
                    )}
                    {showBackLinkAtPageContent && (
                      <ButtonLink
                        className={styles.backLink}
                        color="blue"
                        icon="arrowLeft"
                        label={<Message {...messages.back} />}
                        size="small"
                        onClick={handleGoBack}
                      />
                    )}
                    {children}
                  </div>
                </Box>
              </Card>
            </Box>
          </PageCardAnimatingContext.Provider>
          {showFooter && <Footer />}
        </Box>
      </Box>
    );
  };

  const content = isPageWithFullHeight ? (
    <Div100vh>{renderPageCardContent()}</Div100vh>
  ) : (
    renderPageCardContent()
  );
  return (
    <div
      className={isConnectIframe ? 'seamless' : styles.pageCard}
      // @ts-expect-error - TS2322 - Type 'RefObject<HTMLElement>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
      ref={cardRef}
    >
      {content}
    </div>
  );
};

export default PageCard;
