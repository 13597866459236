import mapValues from 'lodash/mapValues';

import type {AnalyticsParams} from 'gelato/frontend/src/lib/analytics/types';

/**
 * Helper function that converts the params values to primitives.
 */
export default function toSanitizedParams(
  params: AnalyticsParams,
): AnalyticsParams {
  return mapValues(params, (value, key) => {
    if (key === 'flags') {
      // `params.flags` is special param that should not be serialized
      // as string. Instead, we'd keep it as it is.
      // See analytics-js/src/core/AnalyticsReporter.ts for more details.
      return value;
    } else if (value !== null && typeof value === 'object') {
      return JSON.stringify(value);
    } else {
      // primitive value.
      return value;
    }
  });
}
