const backgroundColor = 'backgroundColor' as const;
const borderColor = 'borderColor' as const;
const iconColor = 'iconColor' as const;
const textColor = 'textColor' as const;

export const aliasColorBuiltIns = {
  background: backgroundColor,
  backgroundColor,

  borderColor,
  borderBottomColor: borderColor,
  borderLeftColor: borderColor,
  borderRightColor: borderColor,
  borderTopColor: borderColor,

  color: textColor,

  fill: iconColor,
} as const;

export const aliasColor = {
  ...aliasColorBuiltIns,
  keyline: borderColor,
} as const;
