import {info} from '@sail/icons/react/Icon';
import {Icon, css, view, tokens} from '@sail/ui';
import * as React from 'react';

import Message from 'gelato/frontend/src/components/Message';

export const Styles = {
  header: css({
    margin: '20px 30px 0 30px',
  }),
  banner: css({
    stack: 'x',
    gap: 'small',
    height: 'fill',
    keyline: 'border',
    borderRadius: 'medium',
    padding: 'medium',
    width: 'fill',
    backgroundColor: tokens.color.feedback.attention[25],
  }),
  bannerTitle: css({
    font: 'body.small.emphasized',
  }),
  bannerDescription: css({
    font: 'body.small',
  }),
};

type Props = {
  titleMessage: {
    id: string;
    description: string;
    defaultMessage: string;
  };
  descriptionMessage: {
    id: string;
    description: string;
    defaultMessage: string;
  };
};

const TestingBanner = (props: Props) => {
  const {titleMessage, descriptionMessage} = props;
  return (
    <view.div uses={[Styles.header]}>
      <view.div uses={[Styles.banner]}>
        <Icon
          icon={info}
          size="xsmall"
          css={{fill: tokens.color.icon.feedback.attention.subdued}}
        />
        <view.div>
          <view.div uses={[Styles.bannerTitle]}>
            <Message {...titleMessage} />
          </view.div>
          <view.div uses={[Styles.bannerDescription]}>
            <Message {...descriptionMessage} />
          </view.div>
        </view.div>
      </view.div>
    </view.div>
  );
};

export default TestingBanner;
