import {warningCircleSvg as Icon__small__warningCircleSvg} from '../Icon__small/Icon__small--warningCircle.js'
import {warningCircleSvg as Icon__xsmall__warningCircleSvg} from '../Icon__xsmall/Icon__xsmall--warningCircle.js'

export var warningCircle = {
  name: 'warningCircle',
  category: 'icon',
  colorable: true,
  svg: Icon__small__warningCircleSvg,
  alt: {
    Icon__small: Icon__small__warningCircleSvg,
    Icon__xsmall: Icon__xsmall__warningCircleSvg
  },
  combined: ['Icon__small', 'Icon__xsmall']
};
