import type {IndividualFields} from '@stripe-internal/data-gelato/schema/types';
import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

export default function hasRequiredField(
  state: Readonly<ApplicationState>,
  field: IndividualFields,
): boolean {
  const {session} = state;
  const {requiredFields} = session!;
  return requiredFields?.includes(field);
}
