import * as React from 'react';
import {useState, useRef, useEffect} from 'react';
import {useIsomorphicLayoutEffect} from '../util';
import {useTimeline, TimelineProvider} from './timeline';

import type {AnimationTask} from './animate';
import type {Timeline} from './timeline';

// TODO: A transition can interrupt an `out` animation. If that happens, the
// element is not removed, but when that transition is removed, it reverts back
// to the final state that the out animation was animating to. For example: an
// out animation fades a box to 0.2 opacity, but a hover transition fades to it
// 1 opacity. If you hover while the box is animating out, it will interrupt it,
// but when stopping hover the will fade to 0.2 opacity and not be removed.

export function AnimatePresence(props: {
  children: React.ReactNode;
  timeline?: Timeline;
  /* content to render instead of null when component is unmounted and animation is finished. 
     This is a necessary workaround to get AnimatePresence working properly with SelectField, 
     which needs to render its listbox items even when the SelectField is closed in order to
     register items when using the <SelectFieldItem> component.
  */
  emptyRender?: React.ReactNode;
}): JSX.Element {
  const [retain, setRetain] = useState(true);
  const animPhase = useRef<'in' | 'out' | null>(null);
  const timeline = useTimeline(props.timeline);
  const task = useRef<AnimationTask | null>(null);

  const committed = useRef<React.ReactNode | null>(null);
  const previous = committed.current;
  // This is what gets rendered. If we don't have `children` from
  // props, we check if we should retain the previous children and
  // render that instead to allow for out animations. We also only do
  // that if any out animations exist; if not we want to go ahead and
  // stop rendering it immediately to avoid an extra render cycle
  const children =
    props.children || (retain && timeline.has('out') ? previous : null);
  useEffect(() => {
    committed.current = children;
  });

  // Here's what's really nice doing it here in the outer component: presence
  // animations will always take precedence over transition animations when they
  // are executed within the same tick
  useIsomorphicLayoutEffect(() => {
    if (animPhase.current !== 'in' && props.children) {
      animPhase.current = 'in';

      if (task.current) {
        // If already going out, we can reverse that animation. We still want to
        // give the timeline a chance to do anything it needs to so we call
        // `prepare`, but `removeAndPlay` does the actual animation
        timeline.prepare('in');
        task.current.removeAndPlay();
        task.current = null;
      } else {
        timeline.start('in');
      }
    } else if (animPhase.current !== 'out' && !props.children && children) {
      animPhase.current = 'out';

      task.current = timeline.start('out');
      task.current
        .getFinished()
        .then(() => {
          setRetain(false);
        })
        .finally(() => {
          task.current = null;
        });
    } else if (!props.children) {
      // If we are here, there is no transitioning happening. Since
      // there is no children on props, make sure the animation phase
      // is set correctly. We need to do this because we check
      // `timeline.has('out')` above when determining the `children`
      // variable, which is an optimization worth keeping because we
      // can immediately unmount when there are no out animations
      animPhase.current = 'out';
    }
  });

  useIsomorphicLayoutEffect(() => {
    // We must render it with `retain` as false to get rid of children, but we
    // always want to flip it back to true for the next out animation
    //
    // TODO: This will cause an unnecessary rerender. Should fix.
    if (retain === false) {
      setRetain(true);
    }
  }, [retain]);

  return (
    <TimelineProvider timeline={timeline}>
      {children ?? props.emptyRender}
    </TimelineProvider>
  );
}
