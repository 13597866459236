import {createElement} from 'react';
import type {ReactElement} from 'react';
import {getIntents, provider} from '../../intent';
import type {IntentMap} from '../../intent';

export function runProviders(
  props: IntentMap,
  element: ReactElement,
): ReactElement {
  const providers = getIntents(provider, props);
  if (providers) {
    let currentElement: React.ReactElement = element;
    providers.forEach(([Context, value]) => {
      currentElement = createElement(Context.Provider, {value}, currentElement);
    });
    return currentElement;
  }
  return element;
}
