import * as React from 'react';
import {assignProps, createViewConfig} from '@sail/engine';
import type {View} from '../view';
import {useFieldContexts} from '../hooks/useFieldContexts';

type FormInputTypes = 'input' | 'select' | 'textarea';

// TODO(craigcav): Ideally we'd use View.IntrinsicElement<T> instead of PropsOf<T>,
// but that results in "union type that is too complex to represent" error.
type PropsOf<C extends keyof JSX.IntrinsicElements> =
  JSX.LibraryManagedAttributes<C, React.ComponentPropsWithoutRef<C>>;

type InputProps<T extends FormInputTypes> = PropsOf<T> & {
  /**
   * Allows you to specify the underlying HTML form element to render.
   */
  as: T;
};

export const InputConfig = createViewConfig({
  props: {} as InputProps<FormInputTypes>,
  name: 'Input',
  flattens: true,
  defaults: {as: 'input'},
});

/**
 * Create interactive controls to accept data from users.
 */
export const Input = InputConfig.createGenericView(
  <T extends FormInputTypes = 'input'>({
    as,
    ...props
  }: View.RenderProps<InputProps<T>>) => {
    const Element = as as React.ElementType;
    return <Element {...props} />;
  },
  {
    uses: [assignProps((props) => useFieldContexts(props))],
  },
);
