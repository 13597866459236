import {createViewConfig} from '@sail/engine';
import type {SelectFieldProps} from './SelectField';

export const SelectFieldConfig = createViewConfig({
  name: 'SelectField',
  props: {} as SelectFieldProps<unknown>,
  defaults: {
    defaultExpandedKeys: 'all',
    expandable: false,
    size: 'medium',
  },
});
