// Internal state to help not warn more than once per type.
const warnCounts: Record<string, boolean> = {};

// A list of warnings to be referenced internally.
const WARNINGS = {
  empty_config: 'You called `Analytics.configure` without any arguments.',
  empty_snake_case:
    'An empty string was passed to toSnakeCase, implying an empty event name.',
  event_with_no_viewed:
    'An event was called, but no viewed_name, or previous view call found.',
  id_mismatch: 'A different ID exists in cookies vs. localStorage',
  json_stringify_error:
    'JSON.stringify threw an error when it was invoked. Ensure it exists.',
  missing_merchant_token:
    'A track function was called with a user token but no merchant token.',
  no_previous_event_id:
    'The previous event id was requested but an analytics call has not been made yet.',
  pixel_onerror_event: 'A tracking pixel threw an error event.',
  pixel_thrown_error:
    'An error was thrown while trying to inject a pixel into the page.',
  unable_to_set_cookie_data:
    'An error was thrown while setting additional cookie information from config.',
  no_client_id:
    'The Analytics Event Logger was turned on, but no clientId was provided.',
  batching_disabled:
    'An attempt to make a batched request was made, but batching is not enabled for this client',
} as const;

/**
 * @param warnName {String}
 * This is a string that points to a warning in the `WARNINGS` hash. It
 * ensures that a single type of warning can only be shown to the developer
 * once. If the warning isn't found, the warning name will be directly
 * printed to the console.
 *
 * @param {Any} [additionalInfo]
 * This is anything that you'd like to be added to the output of the warn.
 * It's often contextual information, or an error object.
 */
export const warn = (
  warnName: keyof typeof WARNINGS,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalInfo?: any,
): boolean | null | undefined => {
  // TODO: consider tracking these warnings in certain conditions to find
  // common breakages.

  // Don't warn in production
  if (window.location.hostname.match(/(^|\.)stripe\.com$/)) {
    return false;
  }

  if (!(warnName in warnCounts) && window.console && window.console.warn) {
    warnCounts[warnName] = true;
    window.console.warn(
      `Stripe Analytics: ${WARNINGS[warnName] || warnName}`,
      additionalInfo,
    );
  }
};
