import type {
  Collection as ReactAriaCollection,
  CollectionBase,
  Expandable,
  MultipleSelection,
  Node,
} from '@react-types/shared';
import type {Key} from 'react';
import {useEffect, useMemo} from 'react';
import {
  SelectionManager,
  useMultipleSelectionState,
  useControlledState,
} from '@sail/react-aria';
import {TreeCollection} from './ReactAriaTreeCollection';
import type {Collection, Item} from './hooks';

function getItemNode<T>(
  item: Item<T>,
  index: number,
  parentItem?: Item<T>,
  level = 0,
): Node<T> {
  return {
    type: item.type,
    props: item.data,
    rendered: null,
    textValue: item.typeaheadValue || '',
    'aria-label': item['aria-label'],
    hasChildNodes: Boolean(item.childItems?.length),
    childNodes:
      item.childItems?.map((child, i) =>
        getItemNode(child, i, item, level + 1),
      ) || [],

    key: item.key,
    parentKey: parentItem ? parentItem.key : undefined,
    level,
    index,
    value: item.data,
  };
}

export function toReactAriaCollection<T>(
  getItems: Collection<T>['getItems'],
  filter?: (items: Array<Item<T>>) => Array<Item<T>>,
): Array<Node<T>> {
  const items = filter ? filter(getItems()) : getItems();
  return items.map((item: Item<T>, i: number) => getItemNode(item, i));
}

export interface TreeProps<T>
  extends Omit<CollectionBase<T>, 'children'>,
    Expandable,
    MultipleSelection {
  getItems: Collection<T>['getItems'];
  filter?: (items: Array<Item<T>>) => Array<Item<T>>;
}

export interface TreeState<T> {
  /** A collection of items in the tree. */
  readonly collection: ReactAriaCollection<Node<T>>;

  /** A set of keys for items that are disabled. */
  readonly disabledKeys: Set<Key>;

  /** A set of keys for items that are expanded. */
  readonly expandedKeys: Set<Key>;

  /** Replaces the set of expanded keys. */
  setExpandedKeys(keys: Set<string>): void;

  /** Toggles the expanded state for an item by its key. */
  toggleKey(key: Key): void;

  /** A selection manager to read and update multiple selection state. */
  readonly selectionManager: SelectionManager;
}

function toggleKey(set: Set<Key>, key: Key): Set<Key> {
  const res = new Set(set);
  if (res.has(key)) {
    res.delete(key);
  } else {
    res.add(key);
  }

  return res;
}

/**
 * Provides state management for tree-like components. Handles building a collection
 * of items from props, item expanded state, and manages multiple selection state.
 */
export function useTreeState<T>({
  expandedKeys,
  defaultExpandedKeys,
  onExpandedChange,
  disabledKeys,
  getItems,
  filter,
  defaultSelectedKeys,
  selectedKeys,
  disallowEmptySelection,
  onSelectionChange,
  selectionMode,
}: TreeProps<T>): TreeState<T> {
  const [expandedKeysSet, setExpandedKeysSet] = useControlledState(
    expandedKeys ? new Set(expandedKeys) : new Set<Key>(),
    defaultExpandedKeys ? new Set(defaultExpandedKeys) : new Set(),
    onExpandedChange ?? (() => null),
  );

  const selectionState = useMultipleSelectionState({
    selectionMode,
    disallowEmptySelection,
    selectedKeys,
    defaultSelectedKeys,
    onSelectionChange,
    disabledKeys,
  });

  const disabledKeysSet = useMemo(
    () => (disabledKeys ? new Set(disabledKeys) : new Set<Key>()),
    [disabledKeys],
  );

  const collection = useMemo(
    () =>
      new TreeCollection(toReactAriaCollection(getItems, filter), {
        expandedKeys: expandedKeysSet,
      }) as unknown as ReactAriaCollection<Node<T>>,
    [getItems, filter, expandedKeysSet],
  );

  // Reset focused key if that item is deleted from the collection.
  useEffect(() => {
    if (
      selectionState.focusedKey != null &&
      !collection.getItem(selectionState.focusedKey)
    ) {
      selectionState.setFocusedKey(null);
    }
    // This is copied from react-aria. We cannot add `selectionState.setFocusedKey`
    // here as it's a new function each render. All that function does it set refs
    // so this is safe, but we must disabled this rule because of react-aria's code
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection, selectionState.focusedKey]);

  const onToggle = (key: Key) => {
    setExpandedKeysSet(toggleKey(expandedKeysSet, key));
  };

  return {
    collection,
    expandedKeys: expandedKeysSet,
    disabledKeys: disabledKeysSet,
    toggleKey: onToggle,
    setExpandedKeys: setExpandedKeysSet,
    selectionManager: new SelectionManager(collection, selectionState),
  };
}
