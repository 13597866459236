import type {DataConfig, Fetch} from '@sail/data';
import {ApolloLink} from '@apollo/client';
import PossibleTypes from '../../schema/possibleTypes.json';
import createGelatoLink from './createGelatoLink';
import anonymousTypes from '../../schema/anonymousTypes.json';

const {possibleTypes} = PossibleTypes;

export default function getGelatoSchemaConfig(
  fetch: Fetch,
  graphqlUrl: string,
  apolloLinks: ApolloLink[] = [],
): NonNullable<DataConfig['graphql']>['schemas'] {
  return {
    graphql: {
      apolloLink: ApolloLink.from([
        ...apolloLinks,
        createGelatoLink(fetch, graphqlUrl),
      ]),
      possibleTypes,
      anonymousTypes,
    },
  };
}
