// Maximum size for canvas pixels to process.
// Keep this number small to avoid performance issues.
export const MAX_PIXEL_SIZE = 10;

const MAX_PIXEL_DATA_LENGTH = MAX_PIXEL_SIZE * MAX_PIXEL_SIZE;
/**
 * Checks if an ImageData object represents an image with a single color.
 * @param image The ImageData object to check.
 * @returns True if the image is single-colored, false otherwise.
 */
export default function isImageSingleColor(image: ImageData): boolean {
  // Check if the Uint32Array type is available, as it's required for the
  // function to work properly.
  if (typeof Uint32Array === 'undefined') {
    throw new Error('Uint32Array is not available');
  }

  const data32 = new Uint32Array(image.data.buffer);

  if (data32.length <= 1) {
    return true;
  }

  // Do the quick check, most images could exit here.
  const first = data32[0];
  const mid = data32[Math.floor(data32.length / 2)];
  const last = data32[data32.length - 1];
  if (first !== mid || mid !== last || last !== first) {
    return false;
  }

  if (data32.length > MAX_PIXEL_DATA_LENGTH) {
    // We don't wanna process too many pixels, just throw the error.
    // This expect the caller to resize the image to a small thumbnail
    // before calling this function.
    throw new Error('Image is too large');
  }

  // Iterate over the pixel data array and check if all values are the same.
  const jj = data32.length;
  for (let ii = 1; ii < jj; ii++) {
    if (data32[ii] !== data32[ii - 1]) {
      // Found the pixel that isn't the same.
      return false;
    }
  }

  // All pixels are the same.
  return true;
}
