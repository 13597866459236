import {ApolloLink, Observable} from '@apollo/client';
import logHttp from 'src/internal/logHttp';
import {
  REQUEST_SOURCE_HEADER,
  getOperationInformation,
  getRequestSourceHeader,
} from 'src/internal/requestSource';
import {getOperationType} from 'src/internal/utils/gql';

import type {DataMetricSuccessReport} from 'src/internal/telemetry/types';
import type {GraphQlResponseError, InternalOperation} from 'src/types';

type InternalOperationContext = ReturnType<InternalOperation['getContext']>;

const errorType = {
  expected: 'expected',
  muted: 'muted',
  network: 'network',
  unexpected: 'unexpected',
} as const;

type ErrorType = typeof errorType;

const EXCLUDE_FROM_SENTRY = Symbol.for('ExpectedErrorDoesNotLogToSentry');

const networkErrorExtensions: GraphQlResponseError['extensions'] = {
  actionId: null,
  traceId: null,
  error: {
    code: 'network_error',
    source: 'network',
    status: -1,
  },
  __DO_NOT_USE_requestId: null,
};

function now() {
  return typeof performance !== 'undefined' ? performance.now() : Date.now();
}

function getType(
  context: InternalOperationContext,
  code: string,
): ErrorType[keyof ErrorType] {
  const {
    expectedErrors = [],
    mutedErrors = [],
    muteAllUnexpected = false,
  } = context || {};

  if (expectedErrors.includes(code)) {
    return errorType.expected;
  }

  if (mutedErrors.includes(code) || muteAllUnexpected) {
    return errorType.muted;
  }

  return errorType.unexpected;
}

function getColor(context: InternalOperationContext, isRead: boolean) {
  if (!isRead) {
    return 'primary-light';
  }
  if (context.isPrefetchMode && !context.isDeduplicated) {
    return 'secondary-light';
  }

  if (context.isDeduplicated) {
    return 'primary-light';
  }

  if (context.isPrefetchHit) {
    return 'tertiary';
  }

  return 'error';
}

function getTooltipText(context: InternalOperationContext, isRead: boolean) {
  if (!isRead) {
    return 'Mutation hits the network';
  }

  if (context.isPrefetchMode && !context.isDeduplicated) {
    return `Issuing a network request for a prefetched query`;
  }

  if (context.isDeduplicated) {
    return `Not issuing a network request as there was already a request in flight`;
  }

  if (context.isPrefetchHit) {
    return `Retrieving result from prefetch cache (no network request)`;
  }

  return 'This query was not prefetched. Consider adding it to prefetch config.';
}

function shouldMarkPerformance(operationName: string): boolean {
  try {
    const ls = typeof window !== 'undefined' && window.localStorage;

    if (!ls) {
      return true;
    }

    const debugConfigItem = ls.getItem('DEBUG_SAIL_DATA');

    if (!debugConfigItem) {
      return true;
    }

    const debugConfigValue = JSON.parse(debugConfigItem);

    if (Array.isArray(debugConfigValue)) {
      return debugConfigValue.includes(operationName);
    }

    return true;
  } catch {
    return false;
  }
}

function markPerformance(operationId: string, operation: InternalOperation) {
  if (typeof performance === 'undefined' || !performance.measure) {
    return;
  }

  if (!shouldMarkPerformance(operation.operationName)) {
    return;
  }

  const context = operation.getContext();
  const variables = operation.variables;
  const operationName = operation.operationName;
  const isRead = getOperationType(operation.query) !== 'mutation';
  const isDeduplicated = !!context.isDeduplicated;
  const isPrefetchMode = !!context.isPrefetchMode;
  const isPrefetchHit = !!context.isPrefetchHit;
  const color = getColor(context, isRead);

  try {
    performance.measure(operationName, {
      start: `${operationId}-start`,
      detail: {
        devtools: {
          dataType: 'track-entry',
          track: 'Apollo Links',
          trackGroup: '@sail/data',
          color,
          properties: [
            ['isPrefetchMode', isPrefetchMode],
            ['isPrefetchHit', isPrefetchHit],
            ['isDeduplicated', isDeduplicated],
            ['variables', JSON.stringify(variables)],
          ],
          tooltipText: getTooltipText(context, isRead),
        },
      },
    });
  } catch {
    context.observabilityFns?.errors?.warning(
      `performance.measure failed to measure ${operationName}`,
      {
        project: 'sail_core',
        extras: {
          operationName,
          isDeduplicated,
          isPrefetchMode,
          isPrefetchHit,
        },
      },
    );
  }
}

function processSuccess(
  operation: InternalOperation,
  duration: number,
  operationId: string,
) {
  markPerformance(operationId, operation);

  const context = operation.getContext();
  const {service, project, component} = getOperationInformation(operation);

  const tags: DataMetricSuccessReport = {
    service,
    project,
    component,

    operation_name: operation.operationName,
    operation_deduplicate: !!context.isDeduplicated,
    operation_prefetch: !!context.isPrefetchMode,
    operation_prefetch_hit: !!context.isPrefetchHit,
  };

  context.observabilityFns?.metrics.increment('frontend.data.success', tags);

  // TODO: This should also use tags, but we need to remove SDK-PARAMS before.
  context.observabilityFns?.analytics.track('frontend.data.success', {
    // Ownership information:
    service,
    project,
    component,

    // Operation information:
    operation_name: operation.operationName,
    operation_deduplicate: !!context.isDeduplicated,
    operation_prefetch: !!context.isPrefetchMode,
    operation_prefetch_hit: !!context.isPrefetchHit,
    // TODO(mjimenez): SDK-PARAMS (remove)
    operationName: operation.operationName,
    // TODO(mjimenez): SDK-PARAMS (remove)
    operationDeduplicated: context.isDeduplicated ?? null,
    // TODO(mjimenez): SDK-PARAMS (remove)
    operationPrefetch: !!context.isPrefetchMode,

    // Network information:
    network_duration: duration ?? null,
    // TODO(mjimenez): SDK-PARAMS (remove)
    networkDuration: duration ?? null,
  });
}

function processError(
  operation: InternalOperation,
  duration: number,
  error: GraphQlResponseError,
  operationId: string,
): void {
  markPerformance(operationId, operation);

  const context = operation.getContext();
  const {service, project, component} = getOperationInformation(operation);

  if (context.observabilityFns) {
    const code = error.extensions?.error?.code || 'could_not_get_error_code';
    const type = getType(context, code);

    logHttp('GraphQL', context.observabilityFns, {
      // Ownership information:
      service,
      project,
      component,

      // Error information:
      errorCode: code,
      errorMessage: error.message || 'No message provided',
      errorPath: (error.path || []).join('.'),
      errorExpected: type === errorType.expected,
      errorMuted: type === errorType.muted,
      errorSource: error.extensions?.error?.source ?? '<unknown>',

      // Operation information:
      operationName: operation.operationName,
      operationPrefetch: !!context.isPrefetchMode,
      operationPrefetchHit: !!context.isPrefetchHit,
      operationDeduplicate: !!context.isDeduplicated,

      // Network information:
      networkDuration: duration ?? null,
      networkStatus: error.extensions?.error?.status ?? null,
      networkActionId: error.extensions?.actionId ?? null,
      networkRequestId: error.extensions?.__DO_NOT_USE_requestId ?? null,
      networkTraceId: error.extensions?.traceId ?? null,
    });
  }
}

/**
 * @see https://sail.stripe.me/apis/data/createObservabilityLink
 */
export default function createObservabilityLink(): ApolloLink {
  let count = 0;

  return new ApolloLink(function observabilityLink(
    operation: InternalOperation,
    forward,
  ) {
    const tmpCount = count;
    count += 1;

    const context = operation.getContext();
    const {headers} = context;

    const info = getOperationInformation(operation);
    const xRequestSource = getRequestSourceHeader(info);
    const operationId = `${operation.operationName}-${tmpCount}`;

    operation.setContext({
      headers: {
        [REQUEST_SOURCE_HEADER]: xRequestSource,
        ...headers,
      },
    });

    return new Observable(function observabilityObserver(observer) {
      const start = now();

      // Only track when we're not prefetching and it's not a mutation (queries
      // and subscriptions are tracked).
      const stop =
        context.isPrefetchMode ||
        getOperationType(operation.query) === 'mutation'
          ? undefined
          : context.performanceMonitorTrack?.(operation.operationName);

      typeof performance !== 'undefined' &&
        performance.mark &&
        performance.mark(`${operationId}-start`);

      const subscription = forward(operation).subscribe({
        next: function observabilityLinkNext(result) {
          const duration = Math.round(now() - start);

          stop?.();

          if (result.errors) {
            result.errors.forEach((err: GraphQlResponseError) => {
              if (typeof err === 'object') {
                (err as any)[EXCLUDE_FROM_SENTRY] = true;
              }

              processError(operation, duration, err, operationId);
            });
          } else {
            processSuccess(operation, duration, operationId);
          }

          return observer.next(result);
        },

        error: function observabilityLinkError(_error) {
          const error = sanitizeError(_error);
          const duration = Math.round(now() - start);

          stop?.();

          processError(
            operation,
            duration,
            {
              message: error.message,
              locations: [],
              path: ['_network_'],
              extensions: networkErrorExtensions,
            },
            operationId,
          );

          // Flag the error object as handled by us.
          error[EXCLUDE_FROM_SENTRY] = true;

          return observer.error(error);
        },

        complete: function observabilityLinkComplete() {
          return observer.complete();
        },
      });

      return function observabilityLinkUnsubscribe(): void {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    });
  });
}

function sanitizeError(error: any): Error & {[EXCLUDE_FROM_SENTRY]?: boolean} {
  if (error instanceof Error) {
    return error;
  }

  if (typeof error === 'string') {
    return new Error(error);
  }

  if (error && 'message' in error) {
    return new Error(String(error.message));
  }

  return new Error(`Unknown error caught by observability link. ${error}`);
}
