import * as Sentry from '@sentry/browser';

import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {getConfigValue} from 'gelato/frontend/src/lib/config';
import {getApiHost} from 'gelato/frontend/src/lib/urlConfig';

import type {StartCodeResponse} from 'gelato/frontend/src/services/apis/start_code';

export default async function fetchStartCodeResponseForSlugAndEmail(
  slug: string,
  email: string,
  clientReferenceId?: string,
): Promise<StartCodeResponse> {
  const headers = new Headers();
  const commitHash = getConfigValue('COMMIT_HASH');
  headers.set('Content-Type', 'application/json');
  headers.set('X-Stripe-Identity-Client-Version', commitHash);
  headers.set('X-Requested-With', 'fetch');

  let body: object;
  if (clientReferenceId) {
    body = {email, client_reference_id: clientReferenceId};
  } else {
    body = {email};
  }

  const url = `${getApiHost()}/api/create-session/${slug}`;
  const resp = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(body),
  }).catch((e) => {
    Sentry.addBreadcrumb({
      category: 'link_slug',
      message: `Error fetching link slug: ${e}`,
      level: Sentry.Severity.Warning,
    });
    return new Response();
  });

  if (resp.status === 401 || resp.status === 404) {
    throw new Error(ErrorCode.flowsSessionCreationFailedOther);
  } else if (resp.status === 429) {
    throw new Error(ErrorCode.flowsSessionCreationRateLimited);
  } else if (!resp.ok) {
    throw new Error(`Request rejected with status ${resp.status}`);
  }

  return resp.json();
}
