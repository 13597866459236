import {ConsumerIdentityDocuments} from 'gelato/frontend/src/api/Consumer/api';

import type {ConsumerIdentityDocumentCreateAssociationTokenResponse} from 'gelato/frontend/src/api/Consumer/types';
import type {
  GenericStripeError,
  FetchResult,
} from 'gelato/frontend/src/api/request';
import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

export default async function createConsumerDocumentAssociationToken(
  state: Readonly<ApplicationState>,
  selectedDocument: string,
): Promise<
  FetchResult<
    GenericStripeError,
    ConsumerIdentityDocumentCreateAssociationTokenResponse
  >
> {
  return ConsumerIdentityDocuments.createAssociationToken(selectedDocument, {
    credentials: {
      consumer_session_client_secret:
        state.networkedIdentity.consumerSession!.client_secret,
    },
    request_surface: 'web_identity_product',
  });
}
