import {warning} from '@sail/icons/react/Icon';
import {view, Icon} from '@sail/ui';
import * as React from 'react';

const ValidationMsg = ({children}: {children: React.ReactNode}) => {
  return (
    <view.span
      css={{
        color: 'feedback.critical',
        font: 'label.small',
        stack: 'x',
        gap: 'xsmall',
      }}
    >
      <Icon icon={warning} css={{color: 'feedback.critical'}} size="xsmall" />
      {children}
    </view.span>
  );
};

export default ValidationMsg;
