import {dispute} from '@sail/icons/react/Icon';
import {css, view, Icon, ButtonGroup} from '@sail/ui';
import {urlRedirect} from '@stripe-internal/safe-links';
import * as React from 'react';

import Button from 'gelato/frontend/src/components/ButtonV2';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import ThemedIcon from 'gelato/frontend/src/components/ThemedIcon';
import {LocaleContext} from 'gelato/frontend/src/lib/contexts';
import {getConsentRoute} from 'gelato/frontend/src/lib/dataRouting';
import experiments from 'gelato/frontend/src/lib/experiments';
import getGoBackButtonText from 'gelato/frontend/src/lib/getGoBackButtonText';
import {
  useBranding,
  useConnectIframe,
  useReturnUrl,
  useRouter,
  useSession,
} from 'gelato/frontend/src/lib/hooks';
import {isInIframe, postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {
  shouldShowCloseButton,
  hasiOSSheetCloseWindowHandler,
} from 'gelato/frontend/src/lib/windowHelpers';

const Styles = {
  title: css({
    font: 'heading.large',
  }),
  body: css({
    font: 'body.medium',
    stack: 'y',
    gap: 'medium',
  }),
  pageContent: css({
    textAlign: 'center',
    stack: 'y',
    alignY: 'center',
  }),
  pageButtons: css({
    height: 'min',
  }),
};

const {useContext, useCallback, useEffect} = React;

const ConsentDeclined = () => {
  const router = useRouter();
  const session = useSession();
  const {locale} = useContext(LocaleContext);
  const proceedCallback = useCallback(() => {
    if (experiments.isActive('welcome_page_v2')) {
      // Route to welcome page to collect consent.
      if (session?.submitted) {
        router.push('/continue');
      } else {
        router.push('/welcome');
      }
    } else {
      // Route to consent page
      const nextPage = getConsentRoute();
      router.push(nextPage);
    }
  }, [router, session]);
  const branding = useBranding();
  const isConnectIframe = useConnectIframe();
  const returnUrl = useReturnUrl();
  const {platformName} = branding || {};

  useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'user_action',
      code: 'consent_declined',
    });
  });

  const renderPageHeader = () => {
    return (
      <view.div css={{marginBottom: 'large', alignX: 'center', stack: 'x'}}>
        <ThemedIcon icon={dispute} size="large" />
      </view.div>
    );
  };

  const renderPageBody = (platformName: string) => {
    return (
      <view.div uses={[Styles.body]}>
        <view.div uses={[Styles.title]}>
          <Message {...messages.consentHeading} />
        </view.div>
        <Message {...messages.consentBody} values={{platformName}} />
      </view.div>
    );
  };

  const handleClose = () => {
    // tell the Stripe.js iframe to close
    postIframeEvent('STRIPE_IDENTITY_CLOSE');

    // tell the iOS sheet to close
    if (hasiOSSheetCloseWindowHandler()) {
      // @ts-expect-error - TS2339 - Property 'webkit' does not exist on type 'Window & typeof globalThis'.
      window.webkit.messageHandlers.closeWindow.postMessage(null);
    }
  };

  const renderCtaButtons = (proceedCallback: () => void, returnUrl: string) => {
    let exitButton = null;
    if (returnUrl && !isInIframe()) {
      const handleClose = () => {
        urlRedirect(returnUrl);
      };

      const message = getGoBackButtonText(session!);

      exitButton = <Button onPress={handleClose}>{message}</Button>;
    } else if (shouldShowCloseButton(isConnectIframe)) {
      exitButton = (
        <Button onPress={handleClose}>
          <Message {...messages.failureLinkNoReturnUrl} />
        </Button>
      );
    }

    return (
      <>
        {exitButton}
        <Button onPress={proceedCallback} type="primary">
          <Message {...messages.consentBodyLink} />
        </Button>
      </>
    );
  };

  return (
    <>
      <view.div uses={[Styles.pageContent]}>
        {renderPageHeader()}
        {renderPageBody(platformName)}
      </view.div>
      <view.div uses={[Styles.pageButtons]}>
        {renderCtaButtons(proceedCallback, returnUrl)}
      </view.div>
    </>
  );
};

const ConsentDeclinedFooter = () => {
  const session = useSession();
  const router = useRouter();
  const isConnectIframe = useConnectIframe();
  const returnUrl = useReturnUrl();

  const proceedCallback = useCallback(() => {
    if (experiments.isActive('welcome_page_v2')) {
      // Route to welcome page to collect consent.
      if (session?.submitted) {
        router.push('/continue');
      } else {
        router.push('/welcome');
      }
    } else {
      // Route to consent page
      const nextPage = getConsentRoute();
      router.push(nextPage);
    }
  }, [router, session]);

  const footerContent = React.useMemo(() => {
    const buttons = [
      <Button onPress={proceedCallback} key="exit" type="primary">
        <Message {...messages.consentBodyLink} />
      </Button>,
    ];
    if (returnUrl && !isInIframe()) {
      if (session) {
        buttons.push(
          <Button
            key="exit"
            onPress={() => urlRedirect(returnUrl)}
            type="secondary"
          >
            {getGoBackButtonText(session)}
          </Button>,
        );
      }
    } else if (shouldShowCloseButton(isConnectIframe)) {
      const handleClose = () => {
        // tell the Stripe.js iframe to close
        postIframeEvent('STRIPE_IDENTITY_CLOSE');

        // tell the iOS sheet to close
        if (hasiOSSheetCloseWindowHandler()) {
          // @ts-expect-error - TS2339 - Property 'webkit' does not exist on type 'Window & typeof globalThis'.
          window.webkit.messageHandlers.closeWindow.postMessage(null);
        }
      };
      buttons.push(
        <Button key="close" onPress={handleClose} type="secondary">
          <Message {...messages.failureLinkNoReturnUrl} />
        </Button>,
      );
    }

    return buttons;
  }, [isConnectIframe, proceedCallback, returnUrl, session]);

  return (
    <ButtonGroup direction="column" css={{gap: 'space.150'}}>
      {footerContent}
    </ButtonGroup>
  );
};
const ConsentDeclinedTitle = () => <Message {...messages.consentHeading} />;
const ConsentDeclinedDescription = (props: {platformName: string}) => {
  const {platformName} = props;
  return <Message {...messages.consentBody} values={{platformName}} />;
};

export const generateConsentDeclinedPageContent = (platformName: string) => {
  return {
    icon: dispute,
    title: <ConsentDeclinedTitle />,
    description: <ConsentDeclinedDescription platformName={platformName} />,
    footer: <ConsentDeclinedFooter />,
  };
};

export default ConsentDeclined;
