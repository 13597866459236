import {defineMessages} from 'react-intl';

const messages = defineMessages({
  documentTitle: {
    id: 'pages.invalid.documentTitle',
    description: 'HTML page title',
    defaultMessage: "There's a problem | Stripe",
  },

  // generic page layout
  heading: {
    id: 'pages.invalid.heading',
    description: 'Heading on page for invalid verification',
    defaultMessage: "There's been a problem",
  },
  body: {
    id: 'pages.invalid.body',
    description: 'Body of page explaining verification is invalid',
    defaultMessage: 'This verification cannot be completed.',
  },
  tryAgain: {
    id: 'pages.invalid.tryAgain',
    description: 'Link text to try the process again',
    defaultMessage: 'Try again',
  },

  // expired session
  expiredHeading: {
    id: 'pages.invalid.expiredHeading',
    description: 'Heading on page when session expires',
    defaultMessage: 'Session expired',
  },
  expiredSessionBody: {
    id: 'pages.invalid.expiredSessionBody',
    description: 'Body of page explaining the session expired',
    defaultMessage: 'Your identity verification session has expired.',
  },
  expiredSecondarySessionBody: {
    id: 'pages.invalid.expiredSecondarySessionBody',
    description:
      'Body of page explaining the session expired in case of a secondary session',
    defaultMessage:
      `This device isn't linked for verification anymore. ` +
      `Please go back to the first device you were using to finish the verification.`,
  },
  // otp declined email
  otpDeclinedHeadingEmail: {
    id: 'pages.invalid.otpDeclinedHeading.email',
    description:
      'Heading on page for invalid verification if otp verification was declined for email',
    defaultMessage: 'Email verification declined',
  },
  otpDeclinedBodyEmail: {
    id: 'pages.invalid.otpDeclinedBody.email',
    description:
      'Body of page explaining verification is invalid if email otp verification is declined',
    defaultMessage:
      'Email verification is required. Contact {platformName} for assistance.',
  },
  otpDeclinedBodyEmailNoReturnUrl: {
    id: 'pages.invalid.otpDeclinedBody.email.noReturnUrl',
    description:
      'Body of page explaining verification is invalid if email otp verification is declined and no return url',
    defaultMessage:
      'This verification method requires that you verify your email to proceed. Contact {platformName} for assistance.',
  },
  otpDeclinedBodyEmailLink: {
    id: 'pages.invalid.otpDeclinedBody.email.link',
    description:
      'Text on link guiding user back to verify their email to proceed',
    defaultMessage: 'Try verifying email again',
  },

  // otp declined phone
  otpDeclinedHeadingPhone: {
    id: 'pages.invalid.otpDeclinedHeading.phone',
    description:
      'Heading on page for invalid verification if otp verification was declined for phone',
    defaultMessage: 'Phone verification declined',
  },
  otpDeclinedBodyPhone: {
    id: 'pages.invalid.otpDeclinedBody.phone',
    description:
      'Body of page explaining verification is invalid if phone otp verification is declined',
    defaultMessage:
      'This verification method requires that you verify your phone to proceed. Contact {platformName} for assistance.',
  },
  otpDeclinedBodyPhoneNoReturnUrl: {
    id: 'pages.invalid.otpDeclinedBody.phone.noReturnUrl',
    description:
      'Body of page explaining verification is invalid if phone otp verification is declined and no return url',
    defaultMessage:
      'Phone verification is required. Contact {platformName} for assistance.',
  },
  otpDeclinedBodyPhoneLink: {
    id: 'pages.invalid.otpDeclinedBody.phone.link',
    description:
      'Text on link guiding user back to verify their phone to proceed',
    defaultMessage: 'Try verifying phone again',
  },

  // no consent
  consentHeading: {
    id: 'pages.invalid.consentHeading',
    description: 'Heading on page for invalid verification if consent declined',
    defaultMessage: 'Consent declined',
  },

  consentBody: {
    id: 'pages.invalid.consentBody',
    description:
      'Body of page explaining verification is invalid if consent declined',
    defaultMessage:
      'This verification method requires consent. Contact {platformName} for assistance.',
  },
  consentBodyLink: {
    id: 'pages.invalid.consentBodyLink',
    description:
      'Body of page explaining verification is invalid if consent declined',
    defaultMessage: 'Back to consent',
  },
  failureLinkNoPlatformName: {
    id: 'pages.invalid.noPlatform.exit',
    description:
      'text of link to follow returnUrl back to return url and admit failure when branding is missing',
    defaultMessage: 'Go back.',
  },
  failureLink: {
    id: 'pages.invalid.exit',
    description:
      'text of link to follow returnUrl back to Merchant and admit failure',
    defaultMessage: 'Go back to {platformName}',
  },
  failureLinkNoReturnUrl: {
    id: 'pages.invalid.closeTab',
    description: 'text of button to close tab',
    defaultMessage: 'Exit',
  },
  safeToClose: {
    id: 'pages.invalid.safeToClose',
    description: 'text to indicate that the page is safe to close',
    defaultMessage: 'You can now close this tab.',
  },

  // under consent age
  underConsentAgeHeading: {
    id: 'pages.invalid.underConsentAgeHeading',
    description:
      'Heading on page for invalid verification if user is under age of 16',
    defaultMessage: 'We cannot verify your identity',
  },
  underConsentAgeBody: {
    id: 'pages.invalid.underConsentAgeBody',
    description:
      'Body of page explaining verification is invalid if user is under supportable age.',
    defaultMessage:
      'The date of birth you provided indicates you are under our minimum supportable age. Unfortunately, we cannot verify your identity.',
  },
  underConsentAgeBodyLink: {
    id: 'pages.invalid.underConsentAgeBodyLink',
    description:
      'Body of page explaining verification is invalid if user is under age of 16',
    defaultMessage: 'Correct my date of birth',
  },

  // unsupported country
  unsupportedCountryHeading: {
    id: 'pages.invalid.unsupportedCountryHeading',
    description:
      'Heading on page for invalid verification if country is not supported',
    defaultMessage: 'We cannot verify your identity',
  },
  unsupportedCountryBody: {
    id: 'pages.invalid.unsupportedCountryBody',
    description:
      'Body of page explaining verification is invalid if country is not supported',
    defaultMessage:
      'The country you provided is not yet supported. Unfortunately, we cannot verify your identity.',
  },
  unsupportedCountryIdNumberBodyLink: {
    id: 'pages.invalid.unsupportedCountryIdNumberBodyLink',
    description:
      'Body of page explaining verification is invalid if country is not supported for id number verification',
    defaultMessage: 'Have an ID number from another country?',
  },
  unsupportedCountryAddressBodyLink: {
    id: 'pages.invalid.unsupportedCountryAddressBodyLink',
    description:
      'Body of page explaining verification is invalid if country is not supported for address verification',
    defaultMessage: 'Have an address from another country?',
  },
  unsupportedCountryDefaultBodyLink: {
    id: 'pages.invalid.unsupportedCountryDefaultBodyLink',
    description:
      'Body of page explaining verificaiton is invalid if country is not supported',
    defaultMessage: 'Try a another country?',
  },

  // sanctioned document country
  sanctionedDocumentCountryHeading: {
    id: 'pages.invalid.sanctionedDocumentCountryHeading',
    description:
      'Heading on page for invalid verification if country document is not supported',
    defaultMessage: 'We cannot verify your identity using this document',
  },
  sanctionedDocumentCountryBody: {
    id: 'pages.invalid.sanctionedDocumentCountryBody',
    description:
      'Body of page explaining verification is invalid if document from unsupported country',
    defaultMessage:
      'Identity documents from this country are not yet supported.',
  },
  sanctionedDocumentCountryBodyLink: {
    id: 'pages.invalid.sanctionedDocumentCountryBodyLink',
    description:
      'Body of page explaining verification is invalid if document country is not supported',
    defaultMessage: 'Have an identity document from another country?',
  },

  // no webcam
  noWebcamTitle: {
    id: 'pages.invalid.noWebcam.title',
    description:
      'Title of page explaining verification is invalid if no webcam.',
    defaultMessage: 'We could not find a camera',
  },
  noWebcamBody: {
    id: 'pages.invalid.noWebcam.body',
    description:
      'Body of page giving call to action in case there is no webcam',
    defaultMessage: 'A camera is required to complete the verification.',
  },
  noWebcamBodySecondary: {
    id: 'pages.invalid.noWebcam.body.secondary',
    description:
      'Body of page giving call to action in case there is no webcam and we are already in a mobile handoff.',
    defaultMessage:
      'A camera is required to complete the verification. Please ensure your camera is connected and try again.',
  },

  // webcam permission denied
  permissionDeniedTitle: {
    id: 'pages.invalid.permissionDeniedTitle',
    description:
      'Title of page explaining verification is invalid if consent declined',
    defaultMessage: 'Camera permission needed',
  },
  permissionDeniedBody: {
    id: 'pages.invalid.permissionDeniedBody',
    description:
      'Body of page explaining verification is invalid if consent declined',
    defaultMessage: 'Camera access is needed.',
  },
  permissionDeniedBodySecondary: {
    id: 'pages.invalid.permissionDeniedBody.secondary',
    description:
      'Body of page explaining verification is invalid if consent declined',
    defaultMessage:
      'Camera access is needed. Please update your browser permissions to allow camera access and try again.',
  },
  permissionDeniedHelp: {
    id: 'pages.invalid.permissionHelp',
    description:
      'Help text linking to a page where they can fix their camera permissions. PermissionHelpLink will point to a page with browser specific instructions for updating permissions',
    defaultMessage:
      'To update permissions, see the instructions <PermissionHelpLink>here</PermissionHelpLink>.',
  },

  // device unsupported
  deviceUnsupportedTitle: {
    id: 'pages.invalid.deviceUnsupported.title',
    description: 'Title of page explaining unknown webcam device error.',
    defaultMessage: 'Camera error',
  },
  deviceUnsupportedBody: {
    id: 'pages.invalid.deviceUnsupported.body',
    description: 'Body of page explaining unknown webcam device error.',
    defaultMessage:
      'This verification requires access to a camera on your device.',
  },
  deviceUnsupportedBodySecondary: {
    id: 'pages.invalid.deviceUnsupported.body.secondary',
    description: 'Body of page explaining unknown webcam device error.',
    defaultMessage:
      'This verification requires access to a camera on your device. Please provide camera access and try again.',
  },

  // unsupported browser
  browserUnsupportedTitle: {
    id: 'pages.invalid.browserUnsupported.title',
    description: 'Title of page explaining unsupported browser webcam error.',
    defaultMessage: 'Unsupported Browser',
  },
  browserUnsupportedBody: {
    id: 'pages.invalid.browserUnsupported.body',
    description: 'Body of page explaining unsupported browser webcam error.',
    defaultMessage:
      'This verification requires a camera. Unfortunately, your browser can not access a camera.',
  },
  browserUnsupportedBodySecondary: {
    id: 'pages.invalid.browserUnsupported.body.secondary',
    description:
      'Body of page explaining unsupported browser webcam error in case of an already handed off session.',
    defaultMessage:
      'This verification requires a camera. Unfortunately, your browser can not access a camera.',
  },
  browserUnsupportedButton: {
    id: 'pages.invalid.browserUnsupported.button',
    description:
      'Button text to prompt the user to generate a link for handoff to a different browser',
    defaultMessage: 'Generate Link',
  },
  useDifferentBrowser: {
    id: 'pages.invalid.useDifferentBrowser.button',
    description: 'Button text to prompt the user to use a different browser',
    defaultMessage: 'Use a different browser',
  },
  enabledCamera: {
    id: 'pages.invalid.enabledCamera.link',
    description:
      'Button text to prompt the user to generate a link for handoff to a different browser',
    defaultMessage: "I've enabled the camera",
  },

  // verificationFlow session creation rate limited
  flowRateLimitedBody: {
    id: 'pages.invalid.flowRateLimited.body',
    description:
      'Body of page explaining that the user has been rate limited while creating verifciation sessions for this verification flow.',
    defaultMessage:
      'There have been too many verification attempts. Please try again later.',
  },
  flowRateLimitedTitle: {
    id: 'pages.invalid.flowRateLimited.title',
    description:
      'Title of page explaining that the user has been rate limited while creating verifciation sessions for this verification flow.',
    defaultMessage: 'Too many attempts',
  },

  // unsupportedHandoffDevice
  unsupportedHandoffDeviceBody: {
    id: 'pages.invalid.unsupportedHandoffDevice.body',
    description: 'Body of page explaining unsupported handoff device error.',
    defaultMessage:
      'This verification requires access to a mobile device. Please close ' +
      'this tab and try again from your original device.',
  },
  unsupportedHandoffDeviceTitle: {
    id: 'pages.invalid.unsupportedHandoffDevice.title',
    description: 'Title of page explaining unsupported handoff device error.',
    defaultMessage: 'Mobile Device Required',
  },

  // unsupportedIpCountry
  unsupportedIpCountryBody: {
    id: 'pages.invalid.unsupportedIpCountry.body',
    description: 'Body of page explaining unsupported ip country error.',
    defaultMessage:
      'This verification cannot be completed from your current country.',
  },
  unsupportedIpCountryTitle: {
    id: 'pages.invalid.unsupportedIpCountry.title',
    description: 'Title of page explaining unsupported ip country error.',
    defaultMessage: 'Unsupported Country',
  },
});

export default messages;
