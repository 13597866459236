import {createArrayIntentType} from '../../intent';
import type {Intent} from '../../intent';

type Continuation = (children: JSX.Element) => JSX.Element | null;

export const continuationIntent = createArrayIntentType(
  'continuation',
  (decorate) =>
    (render: Continuation): Continuation & Intent => {
      return decorate(render);
    },
);
