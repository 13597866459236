// Copied from `dashboard/components` - ylukem

import DOMPurify from 'dompurify';
import * as React from 'react';
import Remarkable from 'remarkable';

type CoreRule =
  | 'block'
  | 'abbr'
  | 'references'
  | 'inline'
  | 'footnote_tail'
  | 'abbr2'
  | 'replacements'
  | 'smartquotes'
  | 'linkify';
type BlockRule =
  | 'code'
  | 'fences'
  | 'blockquote'
  | 'hr'
  | 'list'
  | 'footnote'
  | 'heading'
  | 'lheading'
  | 'htmlblock'
  | 'table'
  | 'deflist'
  | 'paragraph';
type InlineRule =
  | 'text'
  | 'newline'
  | 'escape'
  | 'backticks'
  | 'del'
  | 'ins'
  | 'mark'
  | 'emphasis'
  | 'sub'
  | 'sup'
  | 'links'
  | 'footnote_inline'
  | 'footnote_ref'
  | 'autolink'
  | 'htmltag'
  | 'entity';

export const fullRuleSet = {
  core: [
    'block',
    'abbr',
    'references',
    'inline',
    'footnote_tail',
    'abbr2',
    'replacements',
    'smartquotes',
    'linkify',
  ],
  block: [
    'code',
    'fences',
    'blockquote',
    'hr',
    'list',
    'footnote',
    'heading',
    'lheading',
    'htmlblock',
    'table',
    'deflist',
    'paragraph',
  ],
  inline: [
    'text',
    'newline',
    'escape',
    'backticks',
    'del',
    'ins',
    'mark',
    'emphasis',
    'sub',
    'sup',
    'links',
    'footnote_inline',
    'footnote_ref',
    'autolink',
    'htmltag',
    'entity',
  ],
};

type RuleConfig = {
  core: CoreRule[];
  block: BlockRule[];
  inline: InlineRule[];
};

export type RemarkableOptions = {
  disableLinkifyMailtoLinks?: boolean;
  inline: boolean;
  linkify: boolean;
  rules: RuleConfig;
  plugins?: Array<(md: any, options: any) => void>;
  linkTarget?: string | null | undefined;
};

export type Props = {
  text: string;
};

const isEmailLink = (href: any) => href && href.startsWith('mailto:');

/**
 * HOC Builder for the MarkdownText Component which takes in {text: string} as Props and render Markdown in a span.
 *
 * All markdown features are supported by default, when rules are specified, only specified markdown features will be rendered
 * all other features will be left untouched.
 */
const MarkdownTextWithConfig = (config: RemarkableOptions) => {
  const {
    linkify,
    inline,
    rules,
    plugins,
    disableLinkifyMailtoLinks = false,
    linkTarget = '_blank',
  } = config;

  const options = {
    linkify,
    linkTarget,
  };
  // @ts-expect-error - TS2769 - No overload matches this call.
  const re = new Remarkable(options);

  // jdivock: I'm sorry
  // Auto-linking emails needs to be disabled for users mentioning via a user's email
  // in payment timeline notes
  if (linkify && disableLinkifyMailtoLinks) {
    const origLinkOpen = re.renderer.rules.link_open;
    const origLinkClose = re.renderer.rules.link_close;

    re.renderer.rules.link_open = (tokens: any, idx: any, options: any) => {
      if (isEmailLink(tokens[idx].href)) {
        return '';
      }

      return origLinkOpen(tokens, idx, options);
    };

    re.renderer.rules.link_close = (tokens: any, idx: any, options: any) => {
      if (isEmailLink(tokens[idx].href)) {
        return '';
      }

      return origLinkClose(tokens, idx, options);
    };
  }

  if (inline) {
    re.renderer.rules.paragraph_open = () => '';
    re.renderer.rules.paragraph_close = () => '';
  }

  // We add what's absolutely necessary to render the text itself, if you haven't added those yet.
  const coreRules = [...rules.core, 'block', 'inline'];
  const blockRules = [...rules.block, 'paragraph'];
  const inlineRules = [...rules.inline];

  re.core.ruler.enable(coreRules, true);
  re.block.ruler.enable(blockRules, true);
  re.inline.ruler.enable(inlineRules, true);

  if (plugins) {
    plugins.forEach((plugin) => re.use(plugin));
  }

  return class MarkdownText extends React.Component<Props> {
    render() {
      const {text} = this.props;
      const html = re.render(text);
      return (
        // eslint-disable-next-line react/no-danger
        <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(html)}} />
      );
    }
  };
};

export default MarkdownTextWithConfig;
