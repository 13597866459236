import * as Sentry from '@sentry/browser';
import fetch from 'isomorphic-unfetch';

import {getConfigValue} from 'gelato/frontend/src/lib/config';
import {getApiHost} from 'gelato/frontend/src/lib/urlConfig';

import type {
  DocumentTypes,
  IndividualFields,
  SessionOperatingMode,
  ExperimentName,
} from '@stripe-internal/data-gelato/schema/types';

export type StaticFlagsSettings = {};

export type StaticConsentConfig = {
  privacy_policy_url?: string;
  additional_privacy_policy_url?: string;
  stripe_internal?: boolean;
  use_case?: string;
  image_mode?: string;
};

export type StaticBrandingSettings = {
  button_color?: string;
  is_stripe?: boolean;
  platform_color?: string;
  platform_icon?: string;
  platform_name?: string;
  additional_platform_name?: string;
  button_color_override?: string;
  platform_color_override?: string;
  platform_name_override?: string;
  platform_icon_override?: string;
};

export type StaticSettings = {
  branding?: StaticBrandingSettings;
  support?: StaticSupportSettings;
  consent?: StaticConsentConfig;
  flags?: StaticFlagsSettings;
  livemode?: boolean;
  welcome_handoff?: boolean;
  skip_welcome_page?: boolean;
  return_url?: string;
  refresh_url?: string;
  template_id?: string;
  merchant?: string;
  required_fields?: IndividualFields[];
  options?: {
    document_options: {
      require_live_capture: boolean | null;
      require_mobile_capture: boolean | null;
      document_types: DocumentTypes[] | null;
    };
  };
};

export type IdentitySettings = {
  networked_identity_enabled?: boolean;
  networked_identity_save_enabled?: boolean;
  networked_identity_reuse_available?: boolean;
};

export type StaticSupportSettings = {email?: string; url?: string};

export type StartCodeResponse = {
  session_api_key?: string;
  api_host?: string;
  graphql_url?: string;
  return_url?: string;
  refresh_url?: string;
  operating_mode?: SessionOperatingMode;
  platform_name?: string;
  session_expires_in_seconds?: number;
  idprod_merchant_publishable_key?: string;
  user_session_id?: string;
  experiment_assignments?: {name: ExperimentName; variant: string}[];
  settings?: IdentitySettings;
  _static?: StaticSettings;
};

const fetchApiKeyForLinkCode = async (
  linkCode: string,
): Promise<StartCodeResponse> => {
  // Create a Stripe.js object so we can read the Mirador GUID
  // from it. Note that we CANNOT depend on window.Stripe existing
  // as it may be blocked, or not yet loaded.
  // @ts-expect-error - TS2339 - Property 'Stripe' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property 'Stripe' does not exist on type 'Window & typeof globalThis'.
  const ids = window.Stripe && window.Stripe('test_sk_NONE')._mids();
  const formValues: Record<string, string> = {};
  if (ids && ids.guid) {
    formValues.guid = ids.guid;
  }
  let headers;
  if (global.Headers) {
    headers = new Headers();
    const commitHash = getConfigValue('COMMIT_HASH');
    headers.set('Content-Type', 'application/json');
    headers.set('X-Stripe-Identity-Client-Version', commitHash);
    headers.set('X-Requested-With', 'fetch');

    // TODO(IDPROD-4893)
    // Add server-side logic to detect client that requests for desktop site
    // headers.set('X-Stripe-Identity-Client-Is-Mobile-Device', '');
  }
  const resp = await fetch(`${getApiHost()}/gelato/start_code/${linkCode}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(formValues),
  }).catch((e) => {
    Sentry.addBreadcrumb({
      category: 'start_code',
      message: `Error fetching start code: ${e}`,
      level: Sentry.Severity.Warning,
    });
  });

  // 404 means one of
  // - no linkCode provided (very unlikely from this context)
  // - no verification link could be found corresponding to this linkCode
  if (resp?.status === 401 || resp?.status === 404) {
    return {};
  } else if (!resp?.ok) {
    throw Error(`Request rejected with status ${resp?.status || 0}`);
  }
  return resp.json();
};

export default fetchApiKeyForLinkCode;
