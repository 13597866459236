import qs from 'qs';

import {getLocale} from 'gelato/frontend/src/lib/locale';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {StartCodeResponse} from 'gelato/frontend/src/services/apis/start_code';
import type {SessionState} from 'gelato/frontend/src/services/types';

export default function storeSessionStateFromStartCodeResponse(
  resp: StartCodeResponse,
  clearState: boolean = true,
): SessionState {
  if (!resp.session_api_key) {
    // result fails to have a session_api_key in these cases:
    // - we received a 404 response due to invalid or missing link code
    // - link code points to a verification intent that is associated with a different link code
    // - link code is for a verification link that has expired or been consumed already
    // - link code points to a verification intent that has already been finalized
    // - the merchant for this VI was "rejected" at the account level (probably very surprising that this would
    //   actually occur today)
    const url = resp.refresh_url || resp.return_url;
    Storage.clearSession();
    return {noToken: true, refreshUrl: url ? encodeURI(url) : null};
  } else {
    const {
      session_api_key,
      operating_mode,
      graphql_url,
      session_expires_in_seconds,
      refresh_url,
      return_url,
      platform_name,
      idprod_merchant_publishable_key,
      user_session_id,
      experiment_assignments,
      settings,
      _static,
    } = resp;
    // Compute when session expires based on number of seconds in future, then subtract 2s to account
    // for network latency.
    const session_expires_at =
      // @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'string'.
      Date.now() + parseInt(session_expires_in_seconds, 10) * 1000 - 2000;

    const params = window.location.search
      ? qs.parse(window.location.search.split('?')[1])
      : {};

    let locale = null;
    if (params.locale) {
      // `params.locale` can be any string, check if it is a valid locale key.
      const localeParam = String(params.locale);
      const localeKey = getLocale(localeParam);
      if (localeKey && localeKey === localeParam) {
        // localeParam is valid.
        locale = localeParam;
      }
    }

    const overrideBranding = params.overrideBranding
      ? String(params.overrideBranding)
      : undefined;

    const overridePrimaryColor = params.overridePrimaryColor
      ? String(params.overridePrimaryColor)
      : undefined;
    const overrideSecondaryColor = params.overrideSecondaryColor
      ? String(params.overrideSecondaryColor)
      : undefined;

    const overrideUseConnectIframeDesign =
      String(params.overrideUseConnectIframeDesign) === 'true';

    const oldSessionAPIKey = Storage.getSessionAPIKey();
    if (oldSessionAPIKey && session_api_key !== oldSessionAPIKey) {
      // If the session API key has changed, we need to clear the stored
      // state for the previous session. This can happen if the user
      // paste a new link code into the browser to the same window.
      Storage.clearSession();
    } else {
      // Browsers clear session storage on window close, but iframes do not. So, by clearing the session
      // storage prior to starting the session we removed stored state from previous sessions about
      // things like the chosen upload_option.
      clearState && Storage.clearSession();
    }

    Storage.startSession({
      sessionApiKey: session_api_key,
      sessionExpiresAt: session_expires_at,
      graphqlUrl: graphql_url,
      refreshUrl: refresh_url,
      returnUrl: return_url,
      platformName: platform_name,
      merchantPublishableKey: idprod_merchant_publishable_key,
      userSessionId: user_session_id,
      experimentAssignments: experiment_assignments,
      settings,
      _static,
      overrideBranding,
      overridePrimaryColor,
      overrideSecondaryColor,
      overrideUseConnectIframeDesign,
      locale,
    });

    return {
      livemode: Storage.getLivemode() ?? false,
      noToken: false,
      operatingMode: operating_mode,
    };
  }
}
