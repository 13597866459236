/**
 * converts any value to an Error.
 * @param error The value to convert.
 * @returns The Error.
 */
export default function asError(error: any): Error {
  if (error instanceof Error) {
    return error;
  }

  if (typeof error === 'string') {
    return new Error(error);
  }

  return new Error(JSON.stringify(error));
}
