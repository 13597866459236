import {cssPluginsFromObject} from '@sail/engine/deprecated';

export const font = cssPluginsFromObject(
  (css) =>
    ({
      font: {
        title: {
          fontSize: css.value('28px'),
          lineHeight: css.value('36px'),
          fontWeight: 'bold',
        },
        subtitle: {
          fontSize: css.value('20px'),
          lineHeight: css.value('28px'),
          fontWeight: 'bold',
        },
        heading: {
          fontSize: css.value('16px'),
          lineHeight: css.value('24px'),
          fontWeight: 'bold',
        },
        subheading: {
          fontSize: css.value('11px'),
          lineHeight: css.value('20px'),
          textTransform: 'uppercase',
          fontWeight: 'semibold',
        },
        lead: {
          fontSize: css.value('16px'),
          lineHeight: css.value('24px'),
          fontWeight: 'regular',
        },
        kicker: {
          fontSize: css.value('11px'),
          lineHeight: css.value('20px'),
          textTransform: 'uppercase',
          fontWeight: 'semibold',
        },
        body: {
          fontSize: css.value('14px'),
          lineHeight: css.value('20px'),
          fontWeight: 'regular',
        },
        bodyEmphasized: {
          fontSize: css.value('14px'),
          lineHeight: css.value('20px'),
          fontWeight: 'semibold',
        },
        caption: {
          fontSize: css.value('12px'),
          lineHeight: css.value('16px'),
          fontWeight: 'regular',
        },
      },
    } as const),
);
