import {dispute} from '@sail/icons/react/Icon';
import * as React from 'react';

import InvalidLayout from 'gelato/frontend/src/components/Invalid/InvalidLayout';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';

export default function UnsupportedIpCountry() {
  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'validation_error',
      code: 'unsupported_ip_country',
    });
  });
  const title = <Message {...messages.unsupportedIpCountryTitle} />;
  const description = <Message {...messages.unsupportedIpCountryBody} />;
  return (
    <InvalidLayout title={title} icon={dispute} description={description} />
  );
}
