import {useObservabilityConfig} from 'src/internal/provider/ObservabilityConfigProvider';

import type {ProjectName} from 'src/internal/common/types';

/**
 * React hook to get the project to which the current scope belongs.
 *
 * It reads the value from the nearest `ObservabilityProvider` provider or
 * the init config if none is found.
 *
 * @see https://sail.stripe.me/apis/observability/useProject
 */
export default function useProject(): ProjectName | undefined {
  return useObservabilityConfig()?.project;
}
