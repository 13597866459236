import {getConfigValue} from 'gelato/frontend/src/lib/config';
import Storage from 'gelato/frontend/src/lib/Storage';

/**
 *  Converts urls to use an NGROK tunnel if the app is running on a tunnel domain.
 */

function maybeReplaceHostWithTunnel(url: string): string {
  // Note we need the window check since this gets evaluated
  // at build time as well where there is no browser
  if (process.env.NODE_ENV === 'production' || typeof window === 'undefined') {
    return url;
  } else {
    const hostname = window.location.hostname;
    if (hostname.includes('.tunnel.stripe')) {
      // Removes remote devbox ID and replaces suffix with .tunnel
      return url.replace('-mydev.dev', '.tunnel').replace(/-.*--/, '-');
    }
  }
  return url;
}

export function getApiHost(): string {
  return maybeReplaceHostWithTunnel(getConfigValue('API_HOST'));
}

export function getStripeApiHost(): string {
  return maybeReplaceHostWithTunnel(getConfigValue('STRIPE_API_HOST'));
}

export function getGraphqlURL(): string {
  const graphqlUrl = Storage.getGraphqlUrl();
  return maybeReplaceHostWithTunnel(graphqlUrl || '');
}

export function getUploadHost(): string {
  return maybeReplaceHostWithTunnel(getConfigValue('UPLOAD_HOST'));
}
