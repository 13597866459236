import {Intent, createViewConfig} from '@sail/engine';
import {
  // This component implements our wrapper of Button, so we should allow the import here!
  // eslint-disable-next-line no-restricted-imports
  Button as SailButton,
  ButtonProps as SailButtonProps,
  css,
} from '@sail/ui';
import * as React from 'react';

const Styles = {
  button: css({
    textAlign: 'center',
    userSelect: 'none',
    whiteSpace: 'normal',
    width: 'fill',
  }),
  buttonBorderless: css({
    boxShadow: 'none',
    borderWidth: 0,
    ':active:hover': {
      textDecoration: 'underline',
    },
    ':hover': {
      textDecoration: 'underline',
    },
  }),
};

type ButtonProps = SailButtonProps & {
  borderless?: boolean;
};

const ButtonConfig = createViewConfig({
  name: 'ButtonV2',
  props: {} as ButtonProps,
  flattens: true,
  defaults: {
    size: 'large',
    type: 'primary',
  },
});

const Button = ButtonConfig.createView(
  ({borderless, ...props}: ButtonProps) => {
    const uses: Intent[] = [Styles.button];
    if (borderless) {
      uses.push(Styles.buttonBorderless);
    }
    return <SailButton uses={uses} {...props} />;
  },
);

export default Button;
