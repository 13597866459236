import {stripe} from '@sail/icons/react/BrandIcon';
import {business} from '@sail/icons/react/Icon';
import {ButtonGroup, view, css} from '@sail/ui';
import * as React from 'react';
import {defineMessages, injectIntl} from 'react-intl';

import BiometricVerificationSheet from 'gelato/frontend/src/components/BiometricVerificationSheet';
import Button from 'gelato/frontend/src/components/ButtonV2';
import FCLink from 'gelato/frontend/src/components/FCLink';
import Heading from 'gelato/frontend/src/components/HeadingV2';
import LanguageSettingSheet from 'gelato/frontend/src/components/LanguageSettingSheet';
import NetworkedIdentityReuseSheet from 'gelato/frontend/src/components/Link/ReuseSheet';
import Link from 'gelato/frontend/src/components/LinkV3';
import ListItem from 'gelato/frontend/src/components/ListItemV2';
import List from 'gelato/frontend/src/components/ListV2';
import Logo from 'gelato/frontend/src/components/Logo';
import Message from 'gelato/frontend/src/components/Message';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import PhotoIDTypesSheet, {
  PhotoIDTypesSheetOpenerText,
} from 'gelato/frontend/src/components/PhotoIDTypesSheet';
import {
  mT50,
  responsiveCss,
  tCenter,
  tGrey,
  tSmall,
} from 'gelato/frontend/src/components/stylesV2';
import VarificationDataSheet from 'gelato/frontend/src/components/VarificationDataSheet';
import {isConsentRequired} from 'gelato/frontend/src/controllers/states/SessionState';
import analytics from 'gelato/frontend/src/lib/analytics';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {STRIPE_PRIVACY_POLICY_URL} from 'gelato/frontend/src/lib/constants';
import experiments from 'gelato/frontend/src/lib/experiments';
import flags from 'gelato/frontend/src/lib/flags';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import useAppRedirectIfWelcomePageSkipped from 'gelato/frontend/src/lib/hooks/useAppRedirectIfWelcomePageSkipped';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';

import type {PageCardProps} from 'gelato/frontend/src/components/PageCardV2';

const {useCallback, useEffect, useRef} = React;

// TODO(IDPROD-7352): Cleanup once we have a better way to handle internal integration branding concerns
const isLinkPlatformName = (platformName: string) => {
  return /Link/.test(platformName);
};

// Messages for the welcome page.
// Sort alphabetically by id.
export const Messages = defineMessages({
  accessToVerificationData: {
    defaultMessage:
      '{platformName} will only have access to this {verificationDataLink}.',
    description:
      'Information about who will have access to the verification data',
    id: 'pages.welcomeV2.accessToVerificationData',
  },
  accessToVerificationDataShopify: {
    defaultMessage:
      '{platformName} will have access to {verificationDataLink}.',
    description:
      'Information about who will have access to the verification data',
    id: 'pages.welcomeV2.shopify.accessToVerificationData',
  },
  accessToVerificationDataInternal: {
    defaultMessage:
      '{platformName} will have access to this {verificationDataLink}.',
    description:
      'Information about who will have access to the verification data',
    id: 'pages.welcomeV2.accessToVerificationDataInternal',
  },
  connectAccessToVerificationData: {
    defaultMessage:
      'Stripe and {platformName} will have access to this {verificationDataLink}.',
    description:
      'Information about who will have access to the verification data',
    id: 'pages.welcomeV2.connectAccessToVerificationData',
  },
  addressVerification: {
    defaultMessage: "You'll provide your address.",
    description: 'Text for address verification section',
    id: 'pages.welcomeV2.addressVerification',
  },
  agreeAndContinue: {
    defaultMessage: 'Agree and continue',
    description: 'Button label for agreeing to the terms and continuing',
    id: 'pages.welcomeV2.agreeAndContinue',
  },
  biometricVerification: {
    defaultMessage:
      'Stripe uses biometric technology on your images to {biometricVerificationLink}. You can delete your data at any time.',
    description:
      'Information about how biometric technology is used for verification and data deletion option',
    id: 'pages.welcomeV2.biometricVerification',
  },
  biometricVerificationShopify: {
    defaultMessage:
      'Stripe uses biometric technology to {biometricVerificationLink}. You can delete your data at any time.',
    description:
      'Information about how biometric technology is used for verification and data deletion option',
    id: 'pages.welcomeV2.shopify.biometricVerification',
  },
  biometricVerificationInternal: {
    defaultMessage:
      '{platformName} is built by Stripe, which uses biometric technology on your images to {biometricVerificationLink}. You can delete your data at any time.',
    description:
      'Information about how biometric technology is used for verification and data deletion option with Link branding',
    id: 'pages.welcomeV2.biometricVerificationForInternal',
  },
  biometricVerificationLink: {
    defaultMessage: "make sure it's you",
    description: 'Link text for biometric verification section',
    id: 'pages.welcomeV2.biometricVerificationLink',
  },
  confirmYourIdentity: {
    defaultMessage:
      'The information you provide Stripe will help us {confirmYourIdentityLink}. ',
    description: 'Text for identity verification section',
    id: 'pages.welcomeV2.confirmYourIdentity',
  },
  confirmYourIdentityInternal: {
    defaultMessage:
      'The information you provide {platformName} will help us {confirmYourIdentityLink}. ',
    description: 'Text for identity verification section',
    id: 'pages.welcomeV2.confirmYourIdentityInternal',
  },
  confirmYourIdentityLink: {
    defaultMessage: 'confirm your identity',
    description: 'Link text for identity  verification section',
    id: 'pages.welcomeV2.confirmYourIdentityLink',
  },
  continue: {
    defaultMessage: 'Continue',
    description: 'Button label for continuing the process',
    id: 'pages.welcomeV2.continue',
  },
  decline: {
    defaultMessage: 'Decline',
    description: 'Button label for declining the process',
    id: 'pages.welcomeV2.decline',
  },
  errorStart: {
    defaultMessage: 'Something went wrong, please try again',
    description: 'Error message when starting the verification process',
    id: 'pages.welcomeV2.errorStart',
  },
  merchantPrivacyPolicy: {
    defaultMessage: '{platformName} Privacy Policy',
    description: "Reference to merchant's privacy policy",
    id: 'pages.welcomeV2.merchantPrivacyPolicy',
  },
  viewShopify: {
    defaultMessage: 'View {privacyPolicy}',
    description: "Full sentence will be 'View Shopify's Privacy Policy'",
    id: 'pages.welcomeV2.shopify.view',
  },
  privacyPolicyShopify: {
    defaultMessage: "{platformName}'s Privacy Policy",
    description: "Reference to merchant's privacy policy",
    id: 'pages.welcomeV2.shopify.merchantPrivacyPolicy',
  },
  scanAndVerifyID: {
    defaultMessage: "You'll scan a valid {photoIDLink}.",
    description:
      'Instruction text for scanning a photo ID and taking a selfie for verification',
    id: 'pages.welcomeV2.scanAndVerifyID',
  },
  scanAndVerifyIDAndNumber: {
    defaultMessage:
      "You'll scan a valid {photoIDLink}, and provide personal information and ID number.",
    description:
      'Instruction text for scanning a photo ID and provide personal information for verification',
    id: 'pages.welcomeV2.scanAndVerifyIDAndNumber',
  },
  scanAndVerifyIDAndSelfie: {
    defaultMessage:
      "You'll scan a valid {photoIDLink}, then take a selfie to make sure it's yours.",
    description:
      'Instruction text for scanning a photo ID and taking a selfie for verification',
    id: 'pages.welcomeV2.scanAndVerifyIDAndSelfie',
  },
  scanAndVerifyIDAndSelfieAndNumber: {
    defaultMessage:
      "You'll scan a valid {photoIDLink}, take a selfie, and provide personal information and ID number.",
    description:
      'Instruction text for scanning a photo ID and taking a selfie and provide personal information for verification',
    id: 'pages.welcomeV2.scanAndVerifyIDAndSelfieAndNumber',
  },
  scanAndVerifyIDAndSelfieShopify: {
    defaultMessage: 'Scan a valid,  {photoIDLink}, then take a selfie.',
    description:
      'Instruction text for scanning a photo ID and taking a selfie for verification for Shopify',
    id: 'pages.welcomeV2.shopify.scanAndVerifyIDAndSelfieAndNumber',
  },
  smsVerification: {
    defaultMessage:
      'Stripe will send an SMS and work with trusted partners to {confirmYourIdentityLink}',
    description: 'Text for SMS verification section',
    id: 'pages.welcomeV2.smsVerification',
  },
  title: {
    defaultMessage: '{platformName} works with Stripe to verify your identity',
    description: 'Title on welcome page for external verifications.',
    id: 'pages.welcomeV2.title',
  },
  titleNoStripe: {
    defaultMessage: 'Verify your identity',
    description:
      'Title on welcome page for internal verifications that does not mention Stripe.',
    id: 'pages.welcomeV2.titleNoStripe',
  },
  titleStripe: {
    defaultMessage: 'Verify your identity with Stripe',
    description:
      'Title on welcome page for internal verifications that does mention Stripe.',
    id: 'pages.welcomeV2.titleStripe',
  },
  titleStripeInternal: {
    defaultMessage: 'Verify your identity with {platformName}',
    description:
      'Title on welcome page for internal verifications that have special branding.',
    id: 'pages.welcomeV2.titleStripeInternal',
  },
  verificationDataLink: {
    defaultMessage: 'verification data',
    description: 'Link text for verification data section',
    id: 'pages.welcomeV2verificationDataLink',
  },
  verificationDataLinkShopify: {
    defaultMessage: 'this verification data',
    description: 'Link text for verification data section for Shopify',
    id: 'pages.shopify.welcomeV2verificationDataLink',
  },
  verifyYourPhotoId: {
    defaultMessage: 'Verify your photo ID',
    description: 'Title for verifying your photo ID.',
    id: 'pages.welcomeV2.verifyYourIdentity',
  },
  verifyYourPhotoIdFallback: {
    defaultMessage: 'Verify your photo ID',
    description: 'Title for verifying your photo ID as a fallback option.',
    id: 'pages.welcomeV2.verifyYourPhotoIdFallback',
  },
  yourNameAndPhoneNumber: {
    defaultMessage: `You’ll provide your name and phone number.`,
    description:
      'Instruction text for providing personal information for verification',
    id: 'pages.welcomeV2.yourNameAndPhoneNumber',
  },
  yourPersonalInformation: {
    defaultMessage: `You'll provide personal information including name, date of birth, and ID number.`,
    description:
      'Instruction text for providing personal information for verification',
    id: 'pages.welcomeV2.yourPersonalInformation',
  },
});

export const Styles = {
  delimiter: css({
    paddingX: 'xsmall',
  }),
  logoWithBorder: css({
    borderColor: '#ffffff',
  }),
  header: responsiveCss({
    compact: {
      paddingRight: '12px',
      paddingLeft: '12px',
      paddingTop: '12px',
    },
    regular: {
      paddingRight: '24px',
      paddingLeft: '24px',
      paddingTop: '42px',
    },
  }),
  secondaryLink: css({
    textDecorationColor: '#99a5b8',
    font: 'body.small',
    fontWeight: 'normal',
  }),
  footer: css({
    paddingX: 'large',
    paddingBottom: 'large',
  }),
  footerButtons: responsiveCss({
    compact: {
      marginTop: '12px',
      gap: '8px',
    },
    regular: {
      marginTop: '16px',
      gap: '12px',
    },
  }),
  footerResponsive: responsiveCss({
    compact: {
      paddingBottom: '8px',
      paddingTop: '8px',
      paddingRight: '8px',
      paddingLeft: '8px',
    },
  }),
  logoStack: css({
    alignX: 'center',
    marginBottom: 'large',
    stack: 'x',
  }),
  logoStackResponsive: responsiveCss({
    compact: {
      margin: '-8px 0 -8px 0',
      transform: 'scale(0.5)',
    },
    regular: {
      transform: 'scale(0.875)',
    },
  }),
  h1: responsiveCss({
    compact: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: '700',
      marginBottom: '20px',
    },
    regular: {
      fontSize: '28px',
      lineHeight: '36px',
      fontWeight: '700',
      marginBottom: '24px',
    },
  }),
  iconWrapperStyle: css({
    top: 'xxsmall',
  }),
  body: css({
    paddingBottom: 'space.250',
    paddingTop: 'space.0',
    paddingX: 'large',
  }),
  error: css({
    color: 'feedback.critical',
    margin: 'medium',
  }),
  acceptTermsHeading: css({
    marginTop: 'large',
  }),
};

// The header section of the welcome page.
// TODOs:
// - If the `skipWelcome` flag is set, we should not show the header.
export function Header(): JSX.Element {
  const {appController} = useAppController();
  // Do not show the logo if this is inside a connect iframe
  const showLogo = !appController.state.session?.useConnectIframeDesign;

  return (
    <view.div uses={[Styles.header]}>
      {showLogo && <LogoStack />}
      <Heading level={1} uses={[Styles.h1]}>
        <TitleMessage />
      </Heading>
    </view.div>
  );
}

/**
 * The title message for the welcome page.
 */
function TitleMessage(): JSX.Element {
  const {appController, appState} = useAppController();
  const branding = appController.runtime?.branding;
  const isStripe = branding?.isStripe;
  const platformName = getBrandingPlatformName(branding);
  const currentPath = appController.runtime?.router.currentPath || '';
  const styles = [];
  let message;

  if (currentPath === '/continue') {
    // Show as the terms of service page.
    styles.push(Styles.acceptTermsHeading);
    message = appState.session?.submitted
      ? Messages.verifyYourPhotoIdFallback
      : Messages.verifyYourPhotoId;
  } else if (branding?.additionalPlatformName) {
    // Whitelabel branding experience. Do not show Stripe's name.
    message = Messages.titleNoStripe;
  } else if (isStripe) {
    // TODO(IDPROD-7352): Cleanup once we have a better way to handle internal integration branding concerns
    if (isLinkPlatformName(platformName)) {
      // For some internal verifications like Link, we want to replace the platform name
      message = Messages.titleStripeInternal;
    } else {
      // The Stripe only experience. Show Stripe's name.
      message = Messages.titleStripe;
    }
  } else {
    message = Messages.title;
  }

  // The partner experience. Show both Stripe's name and partner's name.
  return (
    <view.div uses={styles}>
      <Message {...message} values={{platformName}} />
    </view.div>
  );
}

/**
 * The stack of logos for the welcome page.
 * TODOs:
 * - Add add animation to animate the logos.
 */
function LogoStack(): JSX.Element | null {
  const {appController} = useAppController();

  const currentPath = appController.runtime?.router.currentPath || '';
  if (currentPath === '/continue') {
    // Show as the terms of service page that does not need to show the logos.
    return null;
  }

  const branding = appController.runtime?.branding;
  const src = branding?.platformIcon;
  const color = branding?.platformColor;
  const isStripe = branding?.isStripe;
  const logos = [];

  if (branding?.additionalPlatformName) {
    // The Whitelabel branding experience. Do not show Stripe's logo.
    logos.push(<Logo color="#fff" key="k1" src={src} />);
  } else if (isStripe) {
    // Just Stripe identity verification app. Show Stripe's logo and the business icon.
    logos.push(
      <Logo animation="ltr" color={color} key="k1" padIcon src={business} />,
      <Logo
        animation="rtl"
        additionalStyles={[Styles.logoWithBorder]}
        color={color}
        key="k2"
        src={src || stripe}
      />,
    );
  } else {
    // External merchant that is partnered with Stripe. Show both Stripe's logo
    // and merchant's logo.
    // To address the issue where the Stripe logo is currently covering the
    // right side of the merchant's logo, we are implementing an animation that
    // will reveal the full image of the merchant's logo right from the
    // beginning.
    logos.push(
      <Logo animation="ltr" color="#fff" key="k1" src={src} />,
      <Logo
        animation="rtl"
        additionalStyles={[Styles.logoWithBorder]}
        color="hue.purple.500"
        key="k2"
        src={stripe}
      />,
    );
  }

  return (
    <view.div uses={[Styles.logoStack, Styles.logoStackResponsive]}>
      {logos}
    </view.div>
  );
}

// The body section of the welcome page.
export function Body(): JSX.Element {
  const {appController, appState} = useAppController();
  const branding = appController.runtime?.branding;
  const isStripe = branding?.isStripe;
  const platformName = getBrandingPlatformName(branding);
  const requiredFields = appState.session?.requiredFields;
  let accessToVerificationDataMessage = Messages.accessToVerificationData;
  const startPageRedirectDone = useRef(false);

  useEffect(() => {
    if (startPageRedirectDone.current) {
      return;
    }
    analytics.track('startPageRedirectDone', {
      timeStartRedirectDone: Date.now(),
      startPageRedirectedTo: window.location.pathname,
    });
    startPageRedirectDone.current = true;
  }, [startPageRedirectDone]);

  if (appController.state.session?.useConnectIframeDesign && !isStripe) {
    // Use the correct message if inside a connect iframe
    accessToVerificationDataMessage = Messages.connectAccessToVerificationData;
  } else if (isStripe && !!platformName) {
    // TODO(IDPROD-7352): Cleanup once we have a better way to handle internal integration branding concerns
    // Use the platform name if given and is an internal verification
    accessToVerificationDataMessage = Messages.accessToVerificationDataInternal;
  }

  const photoIDLink = (
    <Link
      uses={[Styles.secondaryLink]}
      type="secondary"
      onPress={() => {
        appController.openLayer(PhotoIDTypesSheet);
        analytics.action('openedLink', {to: 'PhotoIDTypesSheet'});
      }}
    >
      <PhotoIDTypesSheetOpenerText />
    </Link>
  );

  const biometricVerificationMsg =
    // TODO(IDPROD-7352): Cleanup once we have a better way to handle internal integration branding concerns
    isStripe && isLinkPlatformName(platformName)
      ? Messages.biometricVerificationInternal
      : Messages.biometricVerification;

  const verificationDataLink = (
    <Link
      uses={[Styles.secondaryLink]}
      type="secondary"
      onPress={() => {
        appController.openLayer(VarificationDataSheet);
        analytics.action('openedLink', {to: 'VarificationDataSheet'});
      }}
    >
      <Message {...Messages.verificationDataLink} />
    </Link>
  );

  const biometricVerificationLink = (
    <Link
      uses={[Styles.secondaryLink]}
      type="secondary"
      onPress={() => {
        appController.openLayer(BiometricVerificationSheet);
        analytics.action('openedLink', {to: 'BiometricVerificationSheet'});
      }}
    >
      <Message {...Messages.biometricVerificationLink} />
    </Link>
  );

  const confirmYourIdentityMsg =
    // TODO(IDPROD-7352): Cleanup once we have a better way to handle internal integration branding concerns
    isStripe && isLinkPlatformName(platformName)
      ? Messages.confirmYourIdentityInternal
      : Messages.confirmYourIdentity;

  const confirmYourIdentityLink = (
    <Link
      uses={[Styles.secondaryLink]}
      type="secondary"
      onPress={() => {
        appController.openLayer(BiometricVerificationSheet);
        analytics.action('openedLink', {to: 'BiometricVerificationSheet'});
      }}
    >
      <Message {...Messages.confirmYourIdentityLink} />
    </Link>
  );

  const needsIDNumber = requiredFields?.includes('id_number');
  const needsPhone = requiredFields?.includes('phone_number');
  const needsSMS = requiredFields?.includes('phone_otp');
  const needsAddress = requiredFields?.includes('address');
  const needsSelfie = requiredFields?.includes('face');
  const needsIDImage = requiredFields?.includes('id_document_images');

  // List the information about the verification process based on the required
  // fields.
  let items;
  if (needsIDImage && needsSelfie && needsIDNumber) {
    // Document image, selfie, and ID number verification.
    items = (
      <>
        <ListItem icon="camera" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message
            {...Messages.scanAndVerifyIDAndSelfieAndNumber}
            values={{photoIDLink}}
          />
        </ListItem>
        <ListItem icon="lock" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message
            {...accessToVerificationDataMessage}
            values={{platformName, verificationDataLink}}
          />
        </ListItem>
        <ListItem
          icon="createIdentityVerification"
          iconWrapperStyle={[Styles.iconWrapperStyle]}
        >
          <Message
            {...biometricVerificationMsg}
            values={{biometricVerificationLink, platformName}}
          />
        </ListItem>
      </>
    );
  } else if (needsIDImage && needsSelfie) {
    // Document image and selfie verification.
    items = (
      <>
        <ListItem icon="camera" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message
            {...Messages.scanAndVerifyIDAndSelfie}
            values={{photoIDLink}}
          />
        </ListItem>
        <ListItem icon="lock" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message
            {...accessToVerificationDataMessage}
            values={{platformName, verificationDataLink}}
          />
        </ListItem>
        <ListItem
          icon="createIdentityVerification"
          iconWrapperStyle={[Styles.iconWrapperStyle]}
        >
          <Message
            {...biometricVerificationMsg}
            values={{biometricVerificationLink, platformName}}
          />
        </ListItem>
      </>
    );
  } else if (needsIDImage && needsIDNumber) {
    // Document image + ID Number verification.
    items = (
      <>
        <ListItem icon="document" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message
            {...Messages.scanAndVerifyIDAndNumber}
            values={{photoIDLink}}
          />
        </ListItem>
        <ListItem icon="lock" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message
            {...accessToVerificationDataMessage}
            values={{platformName, verificationDataLink}}
          />
        </ListItem>
        <ListItem
          icon="disputeProtection"
          iconWrapperStyle={[Styles.iconWrapperStyle]}
        >
          <Message
            {...confirmYourIdentityMsg}
            values={{confirmYourIdentityLink, platformName}}
          />
        </ListItem>
      </>
    );
  } else if (needsIDImage) {
    // Document image verification.
    items = (
      <>
        <ListItem icon="camera" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message {...Messages.scanAndVerifyID} values={{photoIDLink}} />
        </ListItem>
        <ListItem
          icon="disputeProtection"
          iconWrapperStyle={[Styles.iconWrapperStyle]}
        >
          <Message
            {...confirmYourIdentityMsg}
            values={{confirmYourIdentityLink, platformName}}
          />
        </ListItem>
        <ListItem icon="lock" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message
            {...accessToVerificationDataMessage}
            values={{platformName, verificationDataLink}}
          />
        </ListItem>
      </>
    );
  } else if (needsIDNumber) {
    // ID number verification.
    items = (
      <>
        <ListItem icon="document" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message {...Messages.yourPersonalInformation} />
        </ListItem>
        <ListItem
          icon="disputeProtection"
          iconWrapperStyle={[Styles.iconWrapperStyle]}
        >
          <Message
            {...Messages.confirmYourIdentity}
            values={{confirmYourIdentityLink}}
          />
        </ListItem>
        <ListItem icon="lock" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message
            {...accessToVerificationDataMessage}
            values={{platformName, verificationDataLink}}
          />
        </ListItem>
      </>
    );
  } else if (needsPhone) {
    // Phone number verification.
    items = (
      <>
        <ListItem icon="document" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message {...Messages.yourNameAndPhoneNumber} />
        </ListItem>
        <ListItem icon="phone" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          {needsSMS ? (
            <Message
              {...Messages.smsVerification}
              values={{confirmYourIdentityLink}}
            />
          ) : (
            <Message
              {...Messages.confirmYourIdentity}
              values={{confirmYourIdentityLink}}
            />
          )}
        </ListItem>
        <ListItem icon="lock" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message
            {...accessToVerificationDataMessage}
            values={{platformName, verificationDataLink}}
          />
        </ListItem>
      </>
    );
  } else if (needsAddress) {
    // Address verification.
    items = (
      <>
        <ListItem icon="document" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message {...Messages.addressVerification} />
        </ListItem>
        <ListItem
          icon="disputeProtection"
          iconWrapperStyle={[Styles.iconWrapperStyle]}
        >
          <Message
            {...Messages.confirmYourIdentity}
            values={{confirmYourIdentityLink}}
          />
        </ListItem>
        <ListItem icon="lock" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message
            {...accessToVerificationDataMessage}
            values={{platformName, verificationDataLink}}
          />
        </ListItem>
      </>
    );
  } else {
    // Unexpecred use case. Fallback to the default items.
    items = (
      <>
        <ListItem
          icon="disputeProtection"
          iconWrapperStyle={[Styles.iconWrapperStyle]}
        >
          <Message
            {...Messages.confirmYourIdentity}
            values={{confirmYourIdentityLink}}
          />
        </ListItem>
        <ListItem icon="lock" iconWrapperStyle={[Styles.iconWrapperStyle]}>
          <Message
            {...accessToVerificationDataMessage}
            values={{platformName, verificationDataLink}}
          />
        </ListItem>
      </>
    );
  }

  return <List uses={[tSmall]}>{items}</List>;
}

// The footer section of the welcome page.
function Footer(): JSX.Element {
  const {appController, appState} = useAppController();
  const {session, mutation} = appState;
  const {stripeInternal, useCase, imageMode} = session?.consentConfig || {};

  const shouldHideDeclineButton =
    appController.state.session?.useConnectIframeDesign &&
    // This is stripe hosted onboarding collecting a document for KYC
    ((stripeInternal &&
      useCase === 'kyc' &&
      imageMode === 'identity_document') ||
      // This is a connect onboarding scenario where users have a button to skip or cancel
      // outside the context of gelato that stands in for decline.
      useCase === 'additional_verification');

  const sessionStartedAt = mutation.start.createdAt;

  // The retryable error that should be displayed in the footer
  // (instead of redirecting to `/invalid` page).
  const error =
    appState.sessionError ||
    mutation.consent.error ||
    mutation.documentType.error ||
    mutation.start.error;

  // Whether any of the "call-to-action" buttons should be disabled.
  const ctaDisabled =
    !session ||
    appState.sessionPending ||
    mutation.consent.pending ||
    mutation.documentType.pending ||
    mutation.start.pending;

  const needsConsent = isConsentRequired(appState);
  const consentAccepted =
    needsConsent && !session?.missingFields.includes('consent');

  const handleAccept = useCallback(() => {
    const payload = {consentAccepted: true};
    if (!sessionStartedAt) {
      // Only track the first attempt to start the verification.
      analytics.action('startVerification', payload);
    }

    // If reuse is enabled, open a bottom sheet to ask the user to opt-in
    if (appController.state.networkedIdentity.reuseEnabled) {
      if (flags.isActive('idprod_ni_implementation_review')) {
        return appController.startLinkLoginFlow();
      }
      appController.openLayer(NetworkedIdentityReuseSheet);
    } else if (
      appController.state.networkedIdentity.enabled &&
      flags.isActive('idprod_ni_implementation_review')
    ) {
      appController.startLinkLoginFlow();
    } else if (
      appController.state.networkedIdentity.enabled &&
      experiments.isActive('ni_email_login')
    ) {
      appController.start({
        ...payload,
        networkedIdentityNetworkingOptIn: true,
      });
    } else {
      appController.start({consentAccepted: true});
    }
  }, [appController, sessionStartedAt]);

  const handleDecline = useCallback(() => {
    const payload = {consentAccepted: false};
    if (!sessionStartedAt) {
      // Only track the first attempt to stop the verification.
      analytics.action('stopVerification', payload);
    }
    appController.start(payload);
  }, [appController, sessionStartedAt]);

  const handleContinue = useCallback(() => {
    const payload = {consentAccepted: null};
    if (!sessionStartedAt) {
      // Only track the first attempt to start the verification.
      analytics.action('startVerification', payload);
    }
    // If reuse is enabled, open a bottom sheet to ask the user to opt-in
    if (appController.state.networkedIdentity.reuseEnabled) {
      if (flags.isActive('idprod_ni_implementation_review')) {
        // todo: write test for this
        return appController.startLinkLoginFlow();
      }
      appController.openLayer(NetworkedIdentityReuseSheet);
    } else if (
      appController.state.networkedIdentity.enabled &&
      flags.isActive('idprod_ni_implementation_review')
    ) {
      // todo: write test for this
      appController.startLinkLoginFlow();
    } else if (
      appController.state.networkedIdentity.enabled &&
      experiments.isActive('ni_email_login')
    ) {
      appController.start({
        ...payload,
        networkedIdentityNetworkingOptIn: true,
      });
    } else {
      appController.start(payload);
    }
  }, [appController, sessionStartedAt]);

  const errorMessage = error ? (
    <view.div uses={[Styles.error, tCenter]}>
      <Message {...Messages.errorStart} />
    </view.div>
  ) : null;

  const buttons = needsConsent ? (
    <>
      <Button
        data-testid="verify-start"
        disabled={ctaDisabled}
        onPress={consentAccepted ? handleContinue : handleAccept}
        type="primary"
      >
        <Message {...Messages.agreeAndContinue} />
      </Button>
      {!shouldHideDeclineButton && (
        <Button disabled={ctaDisabled} onPress={handleDecline} type="secondary">
          <Message {...Messages.decline} />
        </Button>
      )}
    </>
  ) : (
    <Button
      data-testid="verify-start"
      disabled={ctaDisabled}
      onPress={handleContinue}
      type="primary"
    >
      <Message {...Messages.continue} />
    </Button>
  );

  return (
    <view.div uses={[Styles.footer, Styles.footerResponsive]}>
      {errorMessage}
      <FooterLinksSection />
      <ButtonGroup direction="column" uses={[Styles.footerButtons]}>
        {buttons}
      </ButtonGroup>
    </view.div>
  );
}

export function FooterLinksSection(): JSX.Element {
  return (
    <view.div uses={[mT50, tSmall, tCenter]}>
      <PrivacyAndTerms />
      <Delimiter />
      <LanguageSelector />
    </view.div>
  );
}

function Delimiter(): JSX.Element {
  return <view.span uses={[Styles.delimiter, tGrey]}>{'\u2022'}</view.span>;
}

export function LanguageSelector(): JSX.Element {
  const {appState, appController} = useAppController();
  const label = appState.locale.currentValue[1];

  const handleClick = useCallback(() => {
    appController.openLayer(LanguageSettingSheet);
  }, [appController]);

  return <FCLink onClick={handleClick}>{label}</FCLink>;
}

// The privacy and terms section of the welcome page.
export function PrivacyAndTerms(): JSX.Element {
  const {appState, appController} = useAppController();
  const branding = appController.runtime?.branding;
  const consentConfig = appState.session?.consentConfig;
  const additionalPrivacyPolicyUrl = consentConfig?.additionalPrivacyPolicyUrl;
  const privacyPolicyUrl = consentConfig?.privacyPolicyUrl;
  const isStripe = !!branding?.isStripe;
  const platformName = getBrandingPlatformName(branding);

  if (branding?.additionalPlatformName) {
    // The whitelabeled experience.

    // TODOs:
    // - `additionalPrivacyPolicyUrl` should not be empty for whitelabel
    //   experience. We should add a check here to make sure it's not empty.
    const href =
      additionalPrivacyPolicyUrl ||
      privacyPolicyUrl ||
      STRIPE_PRIVACY_POLICY_URL;
    return (
      <FCLink href={href} target="_blank">
        <Message {...Messages.merchantPrivacyPolicy} values={{platformName}} />
      </FCLink>
    );
  }

  if (isStripe) {
    // Just the Stripe merchant.
    const href = privacyPolicyUrl || STRIPE_PRIVACY_POLICY_URL;
    return (
      <FCLink href={href} target="_blank">
        <Message {...Messages.merchantPrivacyPolicy} values={{platformName}} />
      </FCLink>
    );
  }

  // We'd show both Stripe's privacy policy and merchant's privacy policy,
  // if the merchant has one.
  let merchantPrivacyPolicyLink;

  if (
    !isStripe &&
    privacyPolicyUrl &&
    privacyPolicyUrl !== STRIPE_PRIVACY_POLICY_URL
  ) {
    // Merchant has their own privacy policy.
    merchantPrivacyPolicyLink = (
      <>
        <FCLink href={privacyPolicyUrl} target="_blank">
          <Message
            {...Messages.merchantPrivacyPolicy}
            values={{platformName}}
          />
        </FCLink>
        <br />
      </>
    );
  }

  // Stripe's privacy policy + Merchant's privacy policy (optional).
  return (
    <>
      {merchantPrivacyPolicyLink}
      <FCLink href={STRIPE_PRIVACY_POLICY_URL} target="_blank">
        <Message
          {...Messages.merchantPrivacyPolicy}
          values={{platformName: 'Stripe'}}
        />
      </FCLink>
    </>
  );
}

// The welcome page.
function WelcomePage(): JSX.Element {
  const {appState, appController} = useAppController();

  const merchantProvidedEmail =
    appState.session?.collectedData?.individual?.email?.merchantProvidedAddress;

  const shouldLookupConsumerAccount =
    merchantProvidedEmail &&
    appState.networkedIdentity.reuseEnabled &&
    !appState.networkedIdentity.accountSearchLoading &&
    !appState.networkedIdentity.consumerSession;

  // Proactively look up the consumer account if reuse is enabled. This
  // should help the networked identity reuse sheet load faster.
  useEffect(() => {
    if (shouldLookupConsumerAccount) {
      appController.lookupConsumerAccount({email: merchantProvidedEmail});
    }
  }, [appController, merchantProvidedEmail, shouldLookupConsumerAccount]);

  const isRedirecting = useAppRedirectIfWelcomePageSkipped();

  const props: PageCardProps =
    appState.session && !isRedirecting
      ? {
          body: <Body />,
          bodySectionStyles: [Styles.body],
          header: <Header />,
          footer: <Footer />,
        }
      : {loading: true};

  postIframeEvent('load');

  return <PageCard {...props} />;
}

setComponentConfig(WelcomePage, {componentName: 'welcome'});

export default injectIntl(WelcomePage);
