import {
  createViewConfig,
  assignProps,
  view,
  useIsomorphicLayoutEffect,
} from '@sail/engine';
import * as React from 'react';
import {useEffect, useRef} from 'react';
import {focusWithoutScrolling, useFocusRing} from '@sail/react-aria';
import type {PressEvent, FocusableElement} from '@react-types/shared';
import type {View} from '../view';
import {usePress} from './hooks/usePress';
import type {
  PressEvents,
  // Need to maintain existing context usage for backwards compat
  OverrideProps,
} from './types';
import {css} from '../css';

type HrefType = 'internal' | 'external' | 'mailto' | 'empty';

export type NavigateHandler<E> = (href: string, e: E) => void | false;

type ActionNavigateContext = () => NavigateHandler<PressEvent>;

type HrefAdapterHandler = (href: ActionProps['href']) => string | undefined;

type ActionHrefAdapterContext = () => HrefAdapterHandler;

export const PREFETCH_EVENT = `sail:prefetch`;
export const NAVIGATE_EVENT = `sail:navigate`;

// default useNavigate context hook
function useDefaultNavigate(): NavigateHandler<PressEvent> {
  const navigateHandler = (_href: string, e: PressEvent): void | false => {
    const navigateEvent = new CustomEvent(NAVIGATE_EVENT, {
      bubbles: true,
      cancelable: true,
    });

    if (!e.target.dispatchEvent(navigateEvent)) {
      return false;
    }
  };

  return navigateHandler;
}

// default useHrefAdapter context hook
function useDefaultHrefAdapter() {
  const defaultHrefAdapter = (
    href: ActionProps['href'],
  ): string | undefined => {
    if (typeof href === 'undefined') {
      return undefined;
    }

    if (typeof href !== 'string') {
      throw new Error(
        'Invalid href value, please provide a correct hrefAdapter through the Unstable_ActionHrefAdapterContext',
      );
    }

    return href;
  };

  return defaultHrefAdapter;
}

export const Unstable_ActionHrefAdapterContext =
  React.createContext<ActionHrefAdapterContext>(useDefaultHrefAdapter);

export const Unstable_ActionNavigateContext =
  React.createContext<ActionNavigateContext>(useDefaultNavigate);

function getHrefType(href: string | void): HrefType {
  if (!href) {
    return 'empty';
  }
  if (href.startsWith('mailto:')) {
    return 'mailto';
  }
  const hasScheme = !!href.match(/^\w+:\/\//);
  return !hasScheme ||
    (typeof window !== 'undefined' &&
      href.indexOf(window.location.origin) === 0)
    ? 'internal'
    : 'external';
}

interface EventKeys {
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
}

// Unfortunately, `usePress()` doesn't pass along the original event
// for us to cancel when we handle navigation client-side.
// Instead, our strategy is to keep track of the active event target
// if navigation is handled client-side and match it up with the event target
// in our own click handler to signal we should cancel native browser navigation.
let activeEventTarget: EventTarget | null = null;
let activeKeyboardPress: boolean | null = null;

function shouldUseClientRouter({
  download,
  href,
  target,
}: {
  download?: boolean;
  href?: string;
  target?: string;
}): boolean {
  return getHrefType(href) === 'internal' && target !== '_blank' && !download;
}

export type SailNavigationEventType =
  | typeof PREFETCH_EVENT
  | typeof NAVIGATE_EVENT;

export function useClientRouting(
  handlers: {
    /**
     * Fires on press start of internal links.
     * This is where you should handle prefetching any data for the request.
     */
    onPrefetch?: (href: string, event: Event) => void;

    /**
     * Fires on press of internal links.
     * This is where you should call your router's navigation method.
     *
     * Returning `false` signals your router will not handle navigation
     * and the browser should proceed handling navigation natively.
     */
    onNavigate?: NavigateHandler<Event>;
  },
  options: {
    /**
     * A ref containing the node to which listeners for navigation events should be attached.
     * If not provided, event listeners will be attached to `window` by default.
     */
    target?: React.RefObject<EventTarget>;
  } = {},
): void {
  const onPrefetch = useRef<(typeof handlers)['onPrefetch']>(
    handlers.onPrefetch,
  );
  const onNavigate = useRef<(typeof handlers)['onNavigate']>(
    handlers.onNavigate,
  );

  useEffect(() => {
    onPrefetch.current = handlers.onPrefetch;
    onNavigate.current = handlers.onNavigate;
  });

  useEffect(() => {
    // user passed a target ref, but it hasn't been initialized yet so return early
    if (options.target && options.target.current === undefined) {
      return;
    }

    const target = options.target?.current ?? window;

    const prefetchHandler = (e: Event) => {
      const href = (e.target as HTMLAnchorElement)?.href;
      if (href) {
        onPrefetch.current?.(href, e);
      }
    };

    const navigateHandler = (e: Event) => {
      const href = (e.target as HTMLAnchorElement)?.href;
      if (href) {
        const didNavigate = onNavigate.current?.(href, e);
        if (didNavigate !== false) {
          e.preventDefault();
        }
      }
    };

    target.addEventListener(PREFETCH_EVENT, prefetchHandler);
    target.addEventListener(NAVIGATE_EVENT, navigateHandler);
    return () => {
      target.removeEventListener(PREFETCH_EVENT, prefetchHandler);
      target.removeEventListener(NAVIGATE_EVENT, navigateHandler);
    };
  }, [options.target, onPrefetch, onNavigate]);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ActionOverrideProps {}

// It enables the href type to be overridden by the consumer
export type Href = OverrideProps<{href?: string}, ActionOverrideProps>['href'];

export type ActionProps = View.IntrinsicElement<
  'a',
  PressEvents & {
    /**
     * Indicates if the element should be focused on mount.
     * @external
     */
    autoFocus?: boolean;
    /**
     * Whether the action is disabled.
     * @external
     */
    disabled?: boolean;
    /**
     * Native `href` attribute that can be augmented by the `@sail/navigation` integration.
     * @type string or augmented
     * @external
     *
     */
    href?: Href;
  }
>;

function isModifiedEvent(event: EventKeys): boolean {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export const ActionConfig = createViewConfig({
  name: 'Action',
  props: {} as ActionProps,
  defaults: {
    disabled: false,
    tabIndex: 0,
  },
  flattens: true,
});

// We always use an `a` tag for actions instead of a `button` or `input` tag
// because some browsers cannot render `button` or `input` inline.
export const Action = ActionConfig.createView(
  ({
    disabled,
    onClick: onClickProp,
    onPress: onPressProp,
    onPressStart: onPressStartProp,
    onPressEnd,
    onPressChange,
    onPressUp,
    autoFocus,
    ...props
  }) => {
    let onPress = onPressProp;
    let onPressStart = onPressStartProp;
    let onClick: React.MouseEventHandler<HTMLAnchorElement> | undefined = onClickProp;

    const useHrefAdapter = React.useContext(Unstable_ActionHrefAdapterContext);
    const useNavigate = React.useContext(Unstable_ActionNavigateContext);

    const hrefAdapter = useHrefAdapter();
    const navigate = useNavigate();

    props.href = hrefAdapter(props.href);

    // https://www.scottohara.me/note/2019/07/17/placeholder-link.html
    if (disabled) {
      delete props.href;
    }

    // To avoid doing a casting a TS control flow analysis won't be modifying optional props keys
    const href = props.href;

    // Configure HTML attributes for a link with an href
    if (href) {
      // Prevent usage of unsafe target='_blank' by setting rel="noopener noreferrer".
      // This could be more nuanced (e.g. only external links) in the future.
      // https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-target-blank.md
      if (props.target === '_blank' && props.rel === undefined) {
        props.rel = 'noopener noreferrer';
      }

      // If this href supports client-side navigation, we add custom events.
      if (shouldUseClientRouter(props)) {
        const performNavigation = (e: PressEvent) => {
          const cancelable = !isModifiedEvent(e)
            ? navigate(href, e)
            : undefined;

          if (cancelable === false) {
            activeEventTarget = e.target;
          }
        };

        onPressStart = (e) => {
          const prefetchEvent = new CustomEvent(PREFETCH_EVENT, {
            bubbles: true,
            cancelable: true,
          });
          e.target.dispatchEvent(prefetchEvent);
          onPressStartProp && onPressStartProp(e);

          activeKeyboardPress = e.pointerType === 'keyboard';
          activeKeyboardPress && performNavigation(e);
        };
        onPress = (e) => {
          !activeKeyboardPress && performNavigation(e);

          onPressProp && onPressProp(e);
        };
        onClick = (e) => {
          if (e.button === 0 && activeEventTarget === e.currentTarget) {
            e.preventDefault();
          }

          activeEventTarget = null;

          onClickProp && onClickProp(e);
        };
      }
    } else {
      // When an href isn’t provided, we set `role="button"` to comply with
      // ARIA best practices.
      //
      // For more information, see:
      // - https://www.w3.org/TR/2016/WD-wai-aria-practices-1.1-20160317/examples/button/button.html
      // - https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role
      props.role = props.role || 'button';
      delete props.href;

      // If this is a button, we need to add keyboard events:
      // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role#Keyboard_interactions
      // https://www.w3.org/TR/2016/WD-wai-aria-practices-1.1-20160317/examples/button/button.html
      props.onKeyPress = function buttonKeyboardBehavior(
        e: React.KeyboardEvent<HTMLAnchorElement>,
      ) {
        if (e.key === 'Enter' || e.key === ' ') {
          // action(e, null);
          e.preventDefault();
        }
      };

      // If this is a button, we also need to ensure focus is placed onto the button when clicked (unlike with an anchor)
      // https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-3
      onPressStart = (e) => {
        focusWithoutScrolling(e.target as FocusableElement);
        onPressStartProp && onPressStartProp(e);
      };
    }

    const ref = useRef<HTMLAnchorElement>(null);

    // React handles autoFocus for some elemenst like buttons and inputs, but not for anchors, so we do it manually.
    useIsomorphicLayoutEffect(() => {
      autoFocus && ref.current?.focus();
    }, []);

    const {
      pressProps: {children, ...pressProps},
    } = usePress({
      isDisabled: disabled,
      onPress,
      onPressStart,
      onPressEnd,
      onPressUp,
      onPressChange,
      preventFocusOnPress: true,
      ref,
    });

    const {isFocusVisible, focusProps} = useFocusRing();

    const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (disabled) {
        e.stopPropagation();
        return;
      }

      onClick?.(e);
    };

    return (
      <view.a
        {...props}
        {...(disabled ? {'aria-disabled': true, tabIndex: -1} : {})}
        ref={ref}
        uses={[
          assignProps(pressProps),
          assignProps(focusProps as View.IntrinsicElement<'a'>),
          assignProps({
            onClick: handleOnClick,
          }),
          css({focusRing: isFocusVisible ? 'focus' : 'none'}),
        ]}
      />
    );
  },
  {
    css: {
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
    forward: {disabled: true},

    variants: {
      disabled: {
        true: [],
        false: [],
      },
    },
  },
);
