import * as React from 'react';
import type {ReactNode} from 'react';
import {createViewConfig, view, media} from '@sail/engine';
import type {View} from '@sail/engine';
import {cancel} from '@sail/icons/react/Icon';
import {tokens} from '../tokens';
import {Action} from './Action';
import {Icon} from './Icon';
import {actionButton} from '../utilStyles/ActionButton';

export type PopoverHeaderProps = View.IntrinsicElement<
  'div',
  {
    /** title for the header */
    title?: ReactNode;
    onClose?: () => void;
  }
>;

export const PopoverHeaderConfig = createViewConfig({
  props: {} as PopoverHeaderProps,
  name: 'Popover',
});

export const PopoverHeader = PopoverHeaderConfig.createView(
  ({title, onClose, ...props}) => {
    return (
      <view.div
        css={{
          stack: 'x',
          distribute: title ? 'space-between' : 'packed',
          alignX: title ? 'start' : 'end',
          paddingX: 'space.250',
          paddingTop: 'medium',
          paddingBottom: 'space.0',
        }}
        {...props}
      >
        {title ? (
          <view.h2
            css={{
              margin: 'space.0',
              color: 'emphasized',
              [media.up(tokens.viewport.mobile)]: {
                font: 'heading.small',
              },
              [media.down(tokens.viewport.mobile)]: {
                font: 'heading.medium',
              },
            }}
          >
            {title}
          </view.h2>
        ) : null}
        <Action
          // TODO: SAIL-3215 no hardcoded strings
          aria-label="Close"
          onPress={onClose}
          uses={actionButton}
          css={{bleedRight: 'small'}}
        >
          <Icon icon={cancel} />
        </Action>
      </view.div>
    );
  },
);
