import {COMMENT, DECLARATION, RULESET} from 'stylis';
import {conditionLayer, getActiveLayer} from './layer';
import {serialize} from './serialize';
import {declarationVariables} from './variables';
import {conditionWhen} from './variant';
import type {StylisElement, StylisMiddleware} from './types';
import type {
  Conditions,
  Declarations,
  Properties,
  PropertyPlugin,
  StylePlugin,
} from './utils';

export const defaultConditions: Conditions = {
  '@layer': conditionLayer,
  '@when': conditionWhen,
};

export const defaultDeclarations: Declarations = [declarationVariables];

export const defaultProperties: Properties = {};

export const defaultStylePlugin: StylePlugin = {
  conditions: defaultConditions,
  declarations: defaultDeclarations,
  properties: defaultProperties,
};

let activePropertyTransform: PropertyPlugin | null = null;

export function createMiddleware(plugin: StylePlugin = defaultStylePlugin): {
  preprocess: StylisMiddleware;
  postprocess: StylisMiddleware;
} {
  return {
    preprocess(element, index, elements, callback) {
      switch (element.type) {
        case DECLARATION:
          const declarationTransforms =
            plugin.declarations || defaultDeclarations;
          for (let i = 0; i < declarationTransforms.length; i++) {
            declarationTransforms[i](element);
          }
          if (!element.return) {
            const propertyTransform = (plugin.properties || defaultProperties)[
              element.props as string
            ];
            if (
              propertyTransform &&
              propertyTransform !== activePropertyTransform
            ) {
              const transformed = propertyTransform(element);
              if (transformed) {
                const previousPropertyTransform = activePropertyTransform;
                activePropertyTransform = propertyTransform;
                try {
                  element.return = element.return || element.value;
                  element.value = serialize(transformed, callback);
                  element.return = '';
                } finally {
                  activePropertyTransform = previousPropertyTransform;
                }
                break;
              }
            }
          }
          break;
        case COMMENT:
        case RULESET:
          break;
        default:
          const conditionTransforms = plugin.conditions || defaultConditions;
          const conditionTransform = conditionTransforms[element.type];
          if (conditionTransform) {
            conditionTransform(element, index, elements, callback);
          }
          break;
      }
    },
    postprocess(element, index, elements, callback) {
      if (!element.root && element.return) {
        const layer = getActiveLayer();
        layer.rulesets.push(element.return);
      }

      const after = element.data && (element.data.after as StylisElement[]);
      if (after) {
        return serialize(Object.values(after), callback);
      }

      if (
        index === elements.length - 1 &&
        element.root &&
        elements === element.root.children
      ) {
        const root = element.root;
        const declarations =
          root.data && (root.data.declarations as StylisElement[]);
        if (declarations) {
          return serialize(Object.values(declarations), callback);
        }
      }
    },
  };
}
