import {createTokens} from '@sail/engine';

const viewportScale = createTokens(
  {
    path: 'viewport',
    mode: 'static',
  },
  {
    xsmall: '0',
    small: '490px',
    medium: '768px',
    large: '1040px',
    xlarge: '1440px',
  },
);

export const viewport = createTokens(
  {
    path: 'viewport',
    mode: 'static',
  },
  {
    $default: viewportScale,
    mobile: viewportScale.small,
    tablet: viewportScale.medium,
    desktop: viewportScale.large,
    widescreen: viewportScale.xlarge,
  },
);
