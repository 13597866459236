import {compile, middleware, prefixer} from 'stylis';
import {assign} from '../../../util';
import {getClassName} from './selector';
import {RuleCache} from './cache';
import {createLayer} from './layer';
import {createMiddleware, defaultStylePlugin} from './middleware';
import type {StyleMetadata} from './metadata';
import {serialize, stringify} from './serialize';
import {mergeStylePlugins} from './utils';
import type {StylePlugin} from './utils';
import type {Layer} from './layer';
import {SPECIFICITY_TOKEN} from '../../../stylesheets';

const CASCADING_PREFIX = 'global-';
const SCOPED_PREFIX = 'rule-';

enum RuleStatus {
  Unparsed,
  Parsed,
}

/**
 * A rule represents the styles to apply to a single element.
 * Rules must adhere to the following constraints:
 *
 * 1. Mergeable
 * To apply multiple rules to an element, we merge them into a single rule.
 * We do this to ensure deterministic property ordering (otherwise, multiple
 * rules might be injected in a different order than they were requested).
 *
 * 2. Serializable
 * The rule data structure can be created at build time and serialized. Logic
 * used to parse and create rules should be decoupled from logic used to merge
 * rules whenever possible. If all rules are serialized at build time, an app
 * should be able to remove the CSS parser and rule creation logic using dead
 * code elimination.
 */
export interface Rule {
  className: string;
  classes: string;
  dependencies: Record<string, string> | null;
  inserted: boolean;
  key: string;
  layer: Layer;
  merged: string[] | null;
  metadata: StyleMetadata | null;
  snapshot?: string;
  source: string;
  status: RuleStatus;
}

export const ruleCache = new RuleCache();

export type RuleInterpolation = string | number | {toString(): string};

export interface CssTemplateLiteral<R = Rule> {
  (input: string | TemplateStringsArray, ...args: RuleInterpolation[]): R;
}

export interface ConfigurableCssTemplateLiteral<R = Rule>
  extends CssTemplateLiteral<R> {
  options(options?: Partial<RuleOptions>): ConfigurableCssTemplateLiteral<R>;
}

interface ActiveRuleState {
  deprecatedCss: ConfigurableCssTemplateLiteral;
  rule: Rule;
}

let activeState: ActiveRuleState | null = null;
function getActiveState(): ActiveRuleState {
  if (!activeState) {
    throw new Error('Expected rule state to exist.');
  }
  return activeState;
}

export function getActiveRule(): Rule {
  if (!activeState) {
    throw new Error('Expected rule state to exist.');
  }
  return activeState.rule;
}

function defineSnapshot(rule: Rule) {
  if (process.env.NODE_ENV === 'test') {
    let snapshot = '';
    Object.defineProperty(rule, 'snapshot', {
      get() {
        if (snapshot) {
          return snapshot;
        }
        ruleCache.forEachDependency(rule, (dep) => {
          snapshot += `${dep.snapshot}\n`;
        });

        function serializeLayer(layer: Layer) {
          snapshot += layer.rulesets.join('\n');
          for (let i = 0; i < layer.order.length; i++) {
            const segment = layer.order[i];
            snapshot += `@layer ${segment} {\n`;
            serializeLayer(layer.layers[segment]);
            snapshot += '\n}\n';
          }
        }

        serializeLayer(rule.layer);
        return snapshot;
      },
    });
  }
}

function updateClasses(rule: Rule) {
  let classes = rule.className;
  ruleCache.forEachDependency(rule, (dep) => {
    if (dep.className) {
      classes += ` ${dep.className}`;
    }
  });
  rule.classes = classes.trimStart();
}

function getSource(
  input: string | TemplateStringsArray,
  args: RuleInterpolation[],
): string {
  if (typeof input === 'string') {
    return input;
  }
  let source = '';
  for (let i = 0; i < input.length; i++) {
    source += input[i] + (args[i] || '');
  }
  return source;
}

export interface RuleOptions {
  global: boolean;
  layer: string;
}

function getKey(source: string, options: RuleOptions): string {
  return getClassName(
    source,
    options.global ? CASCADING_PREFIX : SCOPED_PREFIX,
  );
}

export function configureRules<R extends Rule = Rule>(
  plugins: StylePlugin[] = [defaultStylePlugin],
  options?: Partial<RuleOptions>,
  callback?: (rule: Rule) => R,
): ConfigurableCssTemplateLiteral<R> {
  const plugin = mergeStylePlugins(plugins);
  const {preprocess, postprocess} = createMiddleware(plugin);
  function initializeRule(
    rule: Rule,
    options: RuleOptions,
    deprecatedCss: ConfigurableCssTemplateLiteral,
  ) {
    const previousState = activeState;
    activeState = {
      deprecatedCss,
      rule,
    };

    let ruleset = rule.source;
    if (!options.global) {
      ruleset = `${SPECIFICITY_TOKEN} .${rule.className} { ${ruleset} }`;
    }
    if (options.layer) {
      ruleset = `@layer ${options.layer} { ${ruleset} }`;
    }

    try {
      serialize(
        compile(ruleset),
        middleware([preprocess, prefixer, stringify, postprocess]),
      );
      updateClasses(rule);
      rule.status = RuleStatus.Parsed;
    } finally {
      activeState = previousState;
    }
  }

  function createRule(
    source: string,
    options: RuleOptions,
    deprecatedCss: ConfigurableCssTemplateLiteral,
  ) {
    const key = getKey(source, options);
    const cachedRule = ruleCache.get(key);
    if (cachedRule) {
      return cachedRule as R;
    }

    const rule = new Proxy(
      {
        css: '',
        className: options.global ? '' : key,
        classes: '',
        dependencies: null,
        inserted: false,
        key,
        layer: createLayer(),
        merged: null,
        metadata: null,
        source,
        status: RuleStatus.Unparsed,
      } as Rule,
      {
        get(target, key: keyof Rule) {
          switch (key) {
            case 'className':
            case 'key':
            case 'inserted':
            case 'source':
            case 'status':
              return target[key];
            default:
              if (target.status === RuleStatus.Unparsed) {
                initializeRule(target, options, deprecatedCss);
              }
              return target[key];
          }
        },
      },
    );

    defineSnapshot(rule);
    ruleCache.add(rule);

    return (callback ? callback(rule) : rule) as R;
  }

  function createCssTemplateLiteral({
    global = false,
    layer = '',
  }: Partial<RuleOptions> = {}): ConfigurableCssTemplateLiteral<R> {
    const options: RuleOptions = {global, layer};
    function deprecatedCss(
      input: string | TemplateStringsArray,
      ...args: RuleInterpolation[]
    ) {
      const source = getSource(input, args);
      return createRule(source, options, deprecatedCss);
    }

    deprecatedCss.options = createCssTemplateLiteral;
    return deprecatedCss;
  }

  return createCssTemplateLiteral(options);
}

export function insertRule(rule: Rule): void {
  ruleCache.insert(rule);
}

export function mergeRules(input: (Rule | null)[]): Rule | null {
  const rules = input.filter(Boolean) as Rule[];
  if (rules.length === 0) {
    return null;
  } else if (rules.length === 1) {
    return rules[0];
  }

  const keys = rules.map(({key}) => key);
  const key = keys.join(' ');

  const cachedRule = ruleCache.get(key);
  if (cachedRule) {
    return cachedRule;
  }

  const rule = {
    ...rules[0],
    className: '',
    classes: '',
    inserted: false,
    key,
    layer: createLayer(),
    merged: keys,
  };
  let mergedDependencies = false;
  let mergedMetadata = false;

  for (let i = 1; i < rules.length; i++) {
    const currentRule = rules[i];

    // Merge dependencies (last wins)
    if (currentRule.dependencies) {
      if (!rule.dependencies) {
        rule.dependencies = currentRule.dependencies;
      } else {
        if (!mergedDependencies) {
          rule.dependencies = {...rule.dependencies};
          mergedDependencies = true;
        }
        assign(rule.dependencies, currentRule.dependencies);
      }
    }

    // Merge metadata (last wins)
    if (currentRule.metadata) {
      if (!rule.metadata) {
        rule.metadata = currentRule.metadata;
      } else {
        if (!mergedMetadata) {
          rule.metadata = {...rule.metadata};
          mergedMetadata = true;
        }
        assign(rule.metadata, currentRule.metadata);
      }
    }
  }

  defineSnapshot(rule);
  updateClasses(rule);
  ruleCache.add(rule);
  return rule;
}

export function createDependency(
  key: string,
  value: string | ((deprecatedCss: ConfigurableCssTemplateLiteral) => Rule),
): () => void {
  return function addDependency() {
    const {deprecatedCss, rule} = getActiveState();
    if (!rule.dependencies) {
      rule.dependencies = {};
    }
    if (!rule.dependencies[key]) {
      const reset = deprecatedCss.options({layer: 'reset'});
      const dependency =
        typeof value === 'string' ? reset(value) : value(reset);
      if (dependency.key !== rule.key) {
        rule.dependencies[key] = dependency.key;
      }
    }
  };
}
