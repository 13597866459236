import snakeCase from 'lodash/snakeCase';
import mapKeysDeep from 'map-keys-deep-lodash';

import type {AnalyticsParams} from 'gelato/frontend/src/lib/analytics/types';

/**
 * Helper function that makes the keys of params as snake-cased.
 */
export default function toSnakeCasesParams(
  params: AnalyticsParams,
): AnalyticsParams {
  return mapKeysDeep(params, (value, key) => snakeCase(key));
}
