import analytics from 'gelato/frontend/src/lib/analytics';
import {logInDev} from 'gelato/frontend/src/lib/assert';
import {DEFAULT_EXPERIMENT_VARIANT} from 'gelato/frontend/src/lib/constants';
import flags from 'gelato/frontend/src/lib/flags';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {ExperimentName} from '@stripe-internal/data-gelato/schema/types';
import type {Experiments} from 'gelato/frontend/src/lib/contexts';

type ExperimentNameStringValue = ExperimentName & string;

type ExperimentOptions = {
  // Wehether we log the check as an exposure event in the frontend.
  // Defaults to true.
  logExposure: true | false;
};

// Returns the active variant (if any) for the given experiment name
export const getExperimentVariant = (
  name: ExperimentName,
  experiments?: Experiments | null,
  options?: ExperimentOptions,
): string | null | undefined => {
  if (experiments) {
    const experiment = experiments.find((e) => e.name === name);
    const logExposure = options ? options.logExposure : true;

    if (experiment?.variant && logExposure) {
      logExperimentExposure(name, experiment.variant);
    }

    return experiment && experiment.variant;
  }
};

export const logExperimentExposure = (
  name: ExperimentName,
  variant: string,
) => {
  if (!Storage.hasExperimentExposure(name)) {
    // If the experiment name does not start with 'idprod_', we prepend it.
    // This is needed for the server to be able to join the experiment names
    // properly.
    const retrieved = name.indexOf('idprod_') !== 0 ? `idprod_${name}` : name;

    if (flags.isActive('idprod_frontend_analytics_update')) {
      analytics.track(
        'experimentExposure',
        {
          experiment_retrieved: retrieved,
          variant,
          arb_id: Storage.getUserSessionId(),
        },
        {
          // Set the current session as exposed to the specified experiment.
          onSuccess: () => Storage.setExperimentExposure(name),
        },
      );
    } else {
      Storage.setExperimentExposure(name);
      analytics.track('experimentExposure', {
        experiment_retrieved: retrieved,
        variant,
        arb_id: Storage.getUserSessionId(),
      });
    }
  }
};

// Checks if experiment is we are in treatment for given experiment.
// returns undefined if experiments is undefined.
export const isExperimentActive = (
  name: ExperimentName,
  experiments?: Experiments | null,
  options?: ExperimentOptions,
): boolean | null | undefined => {
  const variant = getExperimentVariant(name, experiments, options);
  const variantValue = variant || DEFAULT_EXPERIMENT_VARIANT;

  return variantValue !== DEFAULT_EXPERIMENT_VARIANT;
};

export const logExperimentSourceComparison = (
  sessionResponseExperiments: Experiments,
) => {
  const authResponseExperiments = Storage.getExperimentAssignments();

  if (!sessionResponseExperiments) {
    return;
  }

  const experimentsDiff: ExperimentName[] = sessionResponseExperiments
    .filter(({name: sessionExpName, variant: sessionExpVariant}) => {
      return !authResponseExperiments.some(
        ({name: authExpName, variant: authExpVariant}) => {
          return (
            sessionExpName === authExpName &&
            sessionExpVariant === authExpVariant
          );
        },
      );
    })
    .map(({name}) => name)
    .concat(
      authResponseExperiments
        .filter(({name: authExpName, variant: authExpVariant}) => {
          return !sessionResponseExperiments.some(
            ({name: sessionExpName, variant: sessionExpVariant}) => {
              return (
                sessionExpName === authExpName &&
                sessionExpVariant === authExpVariant
              );
            },
          );
        })
        .map(({name}) => name),
    );

  analytics.track('experimentsSourceComparison', {
    experiments_match: experimentsDiff.length === 0,
    experiments_diff: experimentsDiff.join(','),
  });
};

export class ExperimentsStore {
  _experiments: Experiments | null;

  constructor() {
    this._experiments = null;
  }

  getValues(): Experiments {
    return this._experiments || [];
  }

  setExperiments(experiments: Experiments): void {
    this._experiments = experiments;
    logInDev(`ExperimentsStore: setExperiments`, experiments);
  }

  /**
   * Checks if experiment is we are in treatment for given experiment.
   * returns undefined if experiments is undefined.
   * @param name The name of the experiment.
   * @param options The options for the experiment.
   * @returns Whether the experiment is active or undefined if experiments
   *   aren't set.
   */
  isActive(
    name: ExperimentName | ExperimentNameStringValue,
    options?: ExperimentOptions,
  ): boolean | null | undefined {
    if (!this._experiments) {
      logInDev(
        `ExperimentsStore: attempted to check "${name}" isActive before set`,
      );
    }

    return this._experiments
      ? !!isExperimentActive(name as ExperimentName, this._experiments, options)
      : undefined;
  }
}

export default new ExperimentsStore();
