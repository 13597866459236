// function multiplyArrays(a: string[], b: string[]): string[] {
//   const ab = [];
//   for (let ai = 0; ai < a.length; ai++) {
//     for (let bi = 0; bi < b.length; bi++) {
//       ab[ai * b.length + bi] = a[ai] + b[bi];
//     }
//   }
//   return ab;
// }

const AT_NEST_PREFIX = '@nest ';
const GLOBAL_PREFIX = ':global ';
const COMMA_REGEXP = /\s*,\s/g;

export function nestSelectors(inherited: string[], nested: string): string[] {
  if (nested.indexOf(GLOBAL_PREFIX) === 0) {
    return nested.slice(GLOBAL_PREFIX.length).split(COMMA_REGEXP);
  }

  let selector = nested;
  if (nested.indexOf(AT_NEST_PREFIX) === 0) {
    selector = nested.slice(AT_NEST_PREFIX.length);
  }

  const raw = selector.split(COMMA_REGEXP);
  const processed = [];
  for (let i = 0; i < raw.length; i++) {
    for (let j = 0; j < inherited.length; j++) {
      if (process.env.NODE_ENV !== 'production') {
        if (raw[i].indexOf('&') === -1) {
          throw new Error(
            'Invalid selector: each nested selector must contain an “&” character.',
          );
        }
      }
      processed.push(raw[i].replace(/&/g, inherited[j]));
    }
  }
  return processed;
}
