const typeface = 'typeface' as const;
const weight = 'weight' as const;

export const aliasTypographyBuiltIns = {
  fontFamily: typeface,
  fontWeight: weight,
} as const;

export const aliasTypography = {
  ...aliasTypographyBuiltIns,
} as const;
