import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import updateDocumentMetadataMutation from 'gelato/frontend/src/controllers/utils/updateDocumentMetadataMutation';

import type {GraphQlField} from '@sail/data';
import type {DocumentTypes} from '@stripe-internal/data-gelato/schema/types';
import type {
  ApplicationRuntime,
  ApplicationState,
} from 'gelato/frontend/src/controllers/types';
import type {UpdateDocumentMetadataMutationData} from 'gelato/frontend/src/graphql/mutations/useUpdateDocumentMetadataMutation';

type Session = GraphQlField<
  UpdateDocumentMetadataMutationData,
  'updateDocumentMetadata',
  'session'
>;

/**
 * Updates the document metadata
 * @param state The application state.
 * @param runtime The application runtime.
 * @param documentType The document type to update.
 * @returns The updated session changes.
 */

export default async function updateDocumentMetadata(
  state: Readonly<ApplicationState>,
  runtime: ApplicationRuntime,
  documentType: DocumentTypes,
): Promise<Session> {
  const {session} = state;
  const {requiredFields} = session!;

  if (!requiredFields?.includes('id_document_metadata')) {
    // document metadata must be required so that the controller can update it.
    // Otherwise, the controller should not invoke this action at first place.
    throw new Error(ErrorCode.documentMetadataIsNotRequired);
  }

  const resp = await updateDocumentMetadataMutation(runtime.apolloClient, {
    documentMetadata: {type: documentType},
  });
  const sessionChanges = resp.data?.updateDocumentMetadata?.session;
  if (!sessionChanges) {
    throw new Error(ErrorCode.documentMetadataFailedToUpdate);
  }
  return sessionChanges;
}
