import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import type {ApolloError, GraphQlDocument} from '@sail/data';
import type {
  Exact,
  InputMaybe,
  Scalars,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';

const doc = graphql`
  mutation GenerateOneTimePasswordMutation($email: Boolean, $phone: Boolean) {
    generateOneTimePassword(email: $email, phone: $phone) {
      success
    }
  }
`;

export const generateOneTimePasswordMutation: GenerateOneTimePasswordMutationDocument =
  doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const generateOneTimePasswordMutationOptions: GelatoMutationOptionsWithoutVariables<GenerateOneTimePasswordMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useGenerateOneTimePasswordMutation(
  options: GelatoMutationOptions<GenerateOneTimePasswordMutationDocument> = {},
): GelatoMutationReturn<GenerateOneTimePasswordMutationDocument> {
  return useGelatoMutation(generateOneTimePasswordMutation, {
    ...generateOneTimePasswordMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type GenerateOneTimePasswordMutationVariables = Exact<{
  email?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['Boolean']>;
}>;

export type GenerateOneTimePasswordMutationData = {
  readonly __typename: 'Mutation';
  readonly generateOneTimePassword: {
    readonly __typename: 'SuccessWithSessionResponse';
    readonly success: boolean;
  };
};

const generateOneTimePasswordMutationPermissions = {} as const;

doc.permissions = generateOneTimePasswordMutationPermissions;

export type GenerateOneTimePasswordMutationPermissions = GelatoPermissionToken<
  typeof generateOneTimePasswordMutationPermissions
>;

export type GenerateOneTimePasswordMutationDocument = GraphQlDocument<
  GenerateOneTimePasswordMutationData,
  GenerateOneTimePasswordMutationVariables,
  GenerateOneTimePasswordMutationPermissions
>;
