/**
 * @fileoverview This file contains the mutation actions for the application
 *   controller. Each action is a function that takes the application controller
 *   and update the application state accordingly.
 */
import {IntlShape} from 'react-intl';

import {
  handleResponseAction,
  setOTPCodeAction,
} from 'gelato/frontend/src/controllers/actions/individualActions';
import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {
  IndividualDataVariables,
  IndividualStateFields,
  OTPMode,
  convertStateToDataVariables,
} from 'gelato/frontend/src/controllers/states/IndividualState';
import {
  setStart,
  setSMSHandoff,
  setHandoffUrl,
  setEmailHandoff,
  setConsent,
  setSubmit,
  setUpdateIndividual,
  setGenerateOTP,
  setValidateOTP,
} from 'gelato/frontend/src/controllers/states/MutationState';
import {
  setOtpRequired as setNetworkingOtpRequired,
  setNetworkingOptIn,
} from 'gelato/frontend/src/controllers/states/NetworkedIdentityState';
import {
  hasAnyDocumentType,
  hasConsentValue,
  isConsentRequired,
  isDocumentTypeRequired,
} from 'gelato/frontend/src/controllers/states/SessionState';
import generateOTP from 'gelato/frontend/src/controllers/utils/generateOTP';
import generateTestModeData from 'gelato/frontend/src/controllers/utils/generateTestModeData';
import makeHandoffUrl from 'gelato/frontend/src/controllers/utils/makeHandoffUrl';
import refreshSession from 'gelato/frontend/src/controllers/utils/refreshSession';
import resetHandoff from 'gelato/frontend/src/controllers/utils/resetHandoff';
import routeToNextPage from 'gelato/frontend/src/controllers/utils/routeToNextPage';
import sendHandoffEmail from 'gelato/frontend/src/controllers/utils/sendHandoffEmail';
import sendHandoffSMS from 'gelato/frontend/src/controllers/utils/sendHandoffSMS';
import startSession from 'gelato/frontend/src/controllers/utils/startSession';
import submitVerification from 'gelato/frontend/src/controllers/utils/submitVerification';
import updateConsent from 'gelato/frontend/src/controllers/utils/updateConsent';
import updateDocumentMetadata from 'gelato/frontend/src/controllers/utils/updateDocumentMetadata';
import updateIndividual from 'gelato/frontend/src/controllers/utils/updateIndividual';
import validateOTP from 'gelato/frontend/src/controllers/utils/validateOTP';
import asError from 'gelato/frontend/src/lib/asError';
import {handleException} from 'gelato/frontend/src/lib/sentry';
import shallowMergeInto from 'gelato/frontend/src/lib/shallowMergeInto';

import type {
  ConsentDataInput,
  DocumentTypes,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  ApplicationAction,
  ApplicationActionWithPayload,
  ApplicationController,
} from 'gelato/frontend/src/controllers/types';
import type {RouteChangeReason} from 'gelato/frontend/src/controllers/utils/routeToNextPage';
import type {LocaleKey} from 'gelato/frontend/src/lib/locale';

/**
 * Start the verification flow.
 * @param controller The application controller.
 * @param data The data passed to the action.
 *   - consentAccepted: Whether the user has accepted the consent. If null, it
 *     means the user does not need to update the consent.
 *   - reason: A more specific reason for the route change that allows the caller
 *     to specify more nuanced behavior.
 * @returns
 */
export const startAction: ApplicationActionWithPayload<{
  consentAccepted: boolean | null;
  networkedIdentityOtpRequired?: boolean;
  networkedIdentityNetworkingOptIn?: boolean;
  reason?: RouteChangeReason;
}> = async (controller, data) => {
  const success = await updateConsentAndDocumentTypeAction(controller, data);

  if (!success) {
    return success;
  }

  const reason = data.reason ?? 'leave_welcome';
  await routeToNextPage(
    controller.state,
    controller.runtime!,
    reason,
    undefined,
    'mutationActions.startAction',
  );

  return true;
};

/**
 * Updates consent and document type. Additionally refetches the session.
 * @param controller The application controller.
 * @param data The data passed to the action.
 *   - consentAccepted: Whether the user has accepted the consent. If null, it
 *     means the user does not need to update the consent.
 *   - reason: A more specific reason for the route change that allows the caller
 *     to specify more nuanced behavior.
 * @returns
 */
export const updateConsentAndDocumentTypeAction: ApplicationActionWithPayload<{
  consentAccepted: boolean | null;
  networkedIdentityOtpRequired?: boolean;
  networkedIdentityNetworkingOptIn?: boolean;
  reason?: RouteChangeReason;
}> = async (controller, data) => {
  if (!(await setStartTimeAction(controller))) {
    // The action failed.
    return false;
  }

  const {
    consentAccepted,
    networkedIdentityOtpRequired,
    networkedIdentityNetworkingOptIn,
  } = data;

  controller.update((draft) => {
    setNetworkingOtpRequired(draft, networkedIdentityOtpRequired || false);
    setNetworkingOptIn(draft, networkedIdentityNetworkingOptIn || false);
  });

  if (
    consentAccepted !== null &&
    isConsentRequired(controller.state) &&
    !hasConsentValue(controller.state, consentAccepted) &&
    !(await updateConsentAction(controller, {accepted: consentAccepted}))
  ) {
    // The action failed.
    return false;
  }

  // Preemptively select the document type if it is required.
  // This is to avoid the user to manually select the document type later.
  // The value defaults to "id_card" for now since the backend does not care
  // about the document type as long as it's not null.
  const documentType = 'id_card';
  if (
    isDocumentTypeRequired(controller.state) &&
    !hasAnyDocumentType(controller.state) &&
    !(await updateDocumentTypeAction(controller, {documentType}))
  ) {
    // The action failed.
    return false;
  }

  // Before navigating to the next page, we need to make sure the session is
  // refreshed. Otherwise, the navigation might go to the wrong page.
  if (!(await refreshSessionAction(controller))) {
    // The action failed.
    return false;
  }

  return true;
};

/**
 * The action that sets the "started_at" timestamp of the session.
 * @returns false if the action failed.
 */
export const setStartTimeAction: ApplicationAction = async (controller) => {
  try {
    const {session, sessionCanceledByUserAt, mutation} = controller.state;
    if (!session) {
      throw new Error(ErrorCode.sessionIsEmpty);
    }

    if (sessionCanceledByUserAt) {
      throw new Error(ErrorCode.sessionIsClosed);
    }

    if (!mutation.start.pending && !mutation.start.createdAt) {
      // start the session if it has not been started yet.
      controller.update((draft) => {
        setStart(draft, {
          createdAt: null,
          error: null,
          pending: true,
          value: null,
        });
      });
      await startSession(controller.state, controller.runtime!);
      controller.update((draft) => {
        setStart(draft, {
          createdAt: Date.now(),
          error: null,
          pending: false,
          value: true,
        });
      });
    }
    return true;
  } catch (ex) {
    const error = asError(ex);
    handleException(error, `setStartTimeAction:${error.message}`);
    controller.update((draft) => {
      setStart(draft, {
        createdAt: null,
        error,
        pending: false,
        value: false,
      });
    });
    return false;
  }
};

/**
 * The action that automatically generates verification data for test mode
 */
export const generateTestModeDataAction: ApplicationActionWithPayload<{
  verified: boolean;
}> = async (controller, {verified}) => {
  try {
    const runtime = controller.runtime!;
    const nextSession = await generateTestModeData(
      controller.state,
      runtime,
      verified,
    );

    controller.update((draft) => {
      if (nextSession) {
        // Merge the session changes into the session.
        shallowMergeInto(draft.session!, nextSession);
      }
    });

    return true;
  } catch (ex) {
    const error = asError(ex);
    handleException(error, `generateTestModeDataAction:${error.message}`);
    return false;
  }
};

/**
 * The action that accept or decline the consent.
 */
export const updateConsentAction: ApplicationActionWithPayload<
  ConsentDataInput
> = async (controller, data) => {
  controller.update((draft) => {
    setConsent(draft, {
      createdAt: null,
      error: null,
      pending: true,
      value: null,
    });
  });
  try {
    const runtime = controller.runtime!;
    await updateConsent(controller.state, runtime, data);

    controller.update((draft) => {
      setConsent(draft, {
        createdAt: Date.now(),
        error: null,
        pending: false,
        value: data,
      });
    });

    return true;
  } catch (ex) {
    const error = asError(ex);
    handleException(error, `updateConsentAction:${error.message}`);
    controller.update((draft) => {
      setConsent(draft, {
        createdAt: null,
        error,
        pending: false,
        value: null,
      });
    });
    return false;
  }
};

/**
 * The action that updates the document type.
 */
export const updateDocumentTypeAction: ApplicationActionWithPayload<{
  documentType: DocumentTypes;
}> = async (controller, data) => {
  try {
    const {documentType} = data;
    controller.update((draft) => {
      shallowMergeInto(draft.mutation.documentType, {
        createdAt: null,
        error: null,
        pending: true,
        value: null,
      });
    });

    await updateDocumentMetadata(
      controller.state,
      controller.runtime!,
      documentType,
    );

    controller.update((draft) => {
      shallowMergeInto(draft.mutation.documentType, {
        createdAt: Date.now(),
        error: null,
        pending: false,
        value: documentType,
      });
    });

    return true;
  } catch (ex) {
    const error = asError(ex);
    handleException(error, `updateDocumentTypeAction:${error.message}`);
    controller.update((draft) => {
      shallowMergeInto(draft.mutation.documentType, {
        createdAt: null,
        error,
        pending: false,
        value: null,
      });
    });
    return false;
  }
};

/**
 * The action that creates a new handoff url.
 */
export const makeHandoffUrlAction: ApplicationAction = async (
  controller: ApplicationController,
) => {
  // First reset the handoff url.
  controller.update((draft) => {
    setHandoffUrl(draft, {
      createdAt: null,
      error: null,
      pending: true,
      value: null,
    });
  });
  try {
    // Then make the handoff url.
    const url = await makeHandoffUrl(controller.state, controller.runtime!);
    controller.update((draft) => {
      setHandoffUrl(draft, {
        createdAt: Date.now(),
        error: null,
        pending: false,
        value: url,
      });
    });
  } catch (ex) {
    // If there is an error, reset the handoff state.
    const error = asError(ex);
    handleException(error, `makeHandoffUrlAction:${error.message}`);
    controller.update((draft) => {
      setHandoffUrl(draft, {
        createdAt: null,
        error,
        pending: false,
        value: null,
      });
    });
  }
};

export const resetHandoffAction: ApplicationAction = async (
  controller: ApplicationController,
) => {
  // First reset the handoff url.
  controller.update((draft) => {
    setHandoffUrl(draft, {
      createdAt: null,
      error: null,
      pending: true,
      value: null,
    });
  });
  try {
    // Then make the handoff url.
    await resetHandoff(controller.state, controller.runtime!);
    const url = await makeHandoffUrl(controller.state, controller.runtime!);
    // Need to refresh the session to get the updated operatingMode at session.
    const session = await refreshSession(controller.state, controller.runtime!);
    controller.update((draft) => {
      draft.session = session;
      setHandoffUrl(draft, {
        createdAt: Date.now(),
        error: null,
        pending: false,
        value: url,
      });
    });
    // TODO: Do we need to re-fetch the session?
  } catch (error) {
    controller.update((draft) => {
      setHandoffUrl(draft, {
        ...draft.mutation.handoffUrl,
        error: asError(error),
        pending: false,
      });
    });
  }
};

export const resetEmailHandoffAction: ApplicationAction = async (
  controller: ApplicationController,
) => {
  controller.update((draft) => {
    setEmailHandoff(draft, {
      createdAt: null,
      error: null,
      pending: false,
      value: null,
    });
  });
};

export const resetSMSHandoffAction: ApplicationAction = async (
  controller: ApplicationController,
) => {
  controller.update((draft) => {
    setSMSHandoff(draft, {
      createdAt: null,
      error: null,
      pending: false,
      value: null,
    });
  });
};

export const sendHandoffEmailAction: ApplicationActionWithPayload<{
  email: string;
  locale: LocaleKey | null;
}> = async (controller, payload) => {
  // First reset the handoff email.
  controller.update((draft) => {
    // Keep the current value for re-send flow.
    const {value} = draft.mutation.emailHandoff;
    setEmailHandoff(draft, {
      createdAt: null,
      error: null,
      pending: true,
      value,
    });
  });
  try {
    // Then send the handoff email.
    await sendHandoffEmail(controller.state, controller.runtime!, payload);
    controller.update((draft) => {
      setEmailHandoff(draft, {
        createdAt: Date.now(),
        error: null,
        pending: false,
        value: payload.email,
      });
    });
  } catch (error) {
    // If there is an error, reset the handoff state.
    controller.update((draft) => {
      setEmailHandoff(draft, {
        createdAt: null,
        error: asError(error),
        pending: false,
        value: null,
      });
    });
  }
};

export const sendHandoffSMSAction: ApplicationActionWithPayload<{
  locale: LocaleKey | null;
  phoneNumber: string;
}> = async (controller, payload) => {
  // First reset the handoff email.
  controller.update((draft) => {
    // Keep the current value for re-send flow.
    const {value} = draft.mutation.smsHandoff;
    setSMSHandoff(draft, {
      createdAt: null,
      error: null,
      pending: true,
      value,
    });
  });

  try {
    const sessionChanges = await sendHandoffSMS(
      controller.state,
      controller.runtime!,
      payload,
    );
    controller.update((draft) => {
      // Merge the session changes into the session.
      shallowMergeInto(draft.session!, sessionChanges);
      setSMSHandoff(draft, {
        createdAt: Date.now(),
        error: null,
        pending: false,
        value: payload.phoneNumber,
      });
    });
  } catch (error) {
    // If there is an error, reset the handoff state.
    controller.update((draft) => {
      setSMSHandoff(draft, {
        createdAt: null,
        error: asError(error),
        pending: false,
        value: null,
      });
    });
  }
};

/**
 * Marks the verification as cancelled. Note that this only does the "soft
 * close" at the client-side of the verification session.
 * The actual session will be remain for 30 days until it is automatically
 * cleared.
 * @param controller The controller instance.
 * @returns True if the verification was cancelled, false otherwise.
 */
export const cancelVerificationAction: ApplicationAction = async (
  controller,
) => {
  controller.update((draft) => {
    draft.sessionCanceledByUserAt = Date.now();
  });
  // Navigate to the "/end" page.
  await routeToNextPage(
    controller.state,
    controller.runtime!,
    'end_session',
    undefined,
    'mutationActions.cancelVerificationAction',
  );
  return true;
};

/**
 * This refreshes the session and at both the GraphQL store and the controller.
 * @param controller The controller instance.
 */
export const refreshSessionAction: ApplicationAction = async (controller) => {
  try {
    controller.update((draft) => {
      shallowMergeInto(draft, {
        sessionPending: true,
        sessionError: null,
      });
    });
    const session = await refreshSession(controller.state, controller.runtime!);
    if (!session) {
      throw new Error(ErrorCode.sessionDidNotRefresh);
    }
    controller.update((draft) => {
      shallowMergeInto(draft, {
        sessionPending: false,
        session,
      });
    });
    return true;
  } catch (error) {
    controller.update((draft) => {
      shallowMergeInto(draft, {
        sessionPending: false,
        sessionError: asError(error),
      });
    });
    return false;
  }
};

/**
 * @param controller The controller instance.
 * @returns True if the verification was submitted, false otherwise.
 */
export const submitVerificationAction: ApplicationActionWithPayload<{
  forceDelay?: boolean;
}> = async (controller, {forceDelay = false}) => {
  if (controller.state.mutation.submit.pending) {
    return;
  }

  controller.update((draft) => {
    setSubmit(draft, {
      createdAt: null,
      error: null,
      pending: true,
      value: true,
    });
  });
  try {
    const response = await submitVerification(
      controller.state,
      controller.runtime!,
      {forceDelay},
    );
    const submitData = response?.data?.submit;

    if (submitData) {
      if (!submitData.success) {
        throw response?.error;
      } else {
        // This should cover both routing to /success or to
        // the next page needed if there are any missing required fields
        controller.update((draft) => {
          if (submitData.session) {
            // Merge the session changes into the session.
            shallowMergeInto(draft.session!, submitData.session);
          }

          setSubmit(draft, {
            createdAt: Date.now(),
            error: null,
            pending: false,
            value: true,
          });
        });

        routeToNextPage(
          controller.state,
          controller.runtime!,
          'submitted',
          undefined,
          'mutationActions.submitVerificationAction',
        );
        return true;
      }
    }
  } catch (err: any) {
    const cause = asError(err);
    const error = new Error(ErrorCode.failedToSubmit, {cause});
    handleException(error, `${error} ${cause}`);

    controller.update((draft) => {
      setSubmit(draft, {
        createdAt: null,
        error,
        pending: false,
        value: false,
      });
    });
    return false;
  }
};

/**
 * This function is meant for progressively updating the individual collected data
 * and not fully submitting the verification.
 * @param controller The controller instance.
 * @returns True if the verification was submitted, false otherwise.
 */
export const updateIndividualAction: ApplicationActionWithPayload<{
  intl: IntlShape;
  partialData?: Partial<IndividualStateFields>;
}> = async (controller, {intl, partialData}) => {
  if (controller.state.mutation.updateIndividual.pending) {
    return;
  }

  controller.update((draft) => {
    setUpdateIndividual(draft, {
      createdAt: null,
      error: null,
      pending: true,
      value: null,
    });
  });
  try {
    const individualDataVariables: Partial<IndividualDataVariables> =
      convertStateToDataVariables(partialData || controller.state.individual);

    const response = await updateIndividual(
      controller.state,
      controller.runtime!,
      individualDataVariables,
    );

    // To handle the individual specific state, we delegate this to
    // the handleResponseAction in individualActions
    const isSuccess = handleResponseAction(controller, response, intl);
    controller.update((draft) => {
      if (response.session) {
        // Merge the session changes into the session.
        shallowMergeInto(draft.session!, response.session);
      }
      setUpdateIndividual(draft, {
        createdAt: Date.now(),
        error: null,
        pending: false,
        value: isSuccess,
      });
    });
    return isSuccess;
  } catch (err: any) {
    const cause = asError(err);
    const error = new Error(ErrorCode.failedToUpdateIndividualMutation, {
      cause,
    });
    handleException(error, `${error} ${cause}`);

    controller.update((draft) => {
      setUpdateIndividual(draft, {
        createdAt: null,
        error,
        pending: false,
        value: false,
      });
    });
    return false;
  }
};

/**
 * This function is meant for to add individual collected data info
 * and submit the verification.
 * @param controller The controller instance.
 * @returns True if the verification was submitted, false otherwise.
 */
export const submitIndividualAction: ApplicationActionWithPayload<{
  intl: IntlShape;
}> = async (controller, {intl}) => {
  const isSuccess = await updateIndividualAction(controller, {intl});
  // Only route to success if there are no errors
  if (isSuccess) {
    routeToNextPage(
      controller.state,
      controller.runtime!,
      'leave_individual',
      undefined,
      'mutationActions.submitIndividualAction',
    );
    return true;
  }
};

export const initializeIndividualMutationAction: ApplicationAction = async (
  controller,
) => {
  controller.update((draft) => {
    setGenerateOTP(draft, {
      createdAt: null,
      error: null,
      pending: false,
      value: null,
    });

    setValidateOTP(draft, {
      createdAt: null,
      error: null,
      pending: false,
      value: null,
    });

    setUpdateIndividual(draft, {
      createdAt: null,
      error: null,
      pending: false,
      value: null,
    });
  });
};

/**
 * @param controller The controller instance.
 * @returns True if the generate OTP request was successful, false otherwise.
 */
export const generateOTPAction: ApplicationActionWithPayload<{
  mode: OTPMode;
}> = async (controller, {mode}) => {
  if (controller.state.mutation.generateOTP.pending) {
    return;
  }

  controller.update((draft) => {
    setGenerateOTP(draft, {
      createdAt: null,
      error: null,
      pending: true,
      value: {[mode]: false},
    });
  });
  try {
    const isSuccess = await generateOTP(controller.state, controller.runtime!);
    controller.update((draft) => {
      setGenerateOTP(draft, {
        createdAt: Date.now(),
        error: isSuccess
          ? null
          : new Error(ErrorCode.failedToGenerateOtp, {
              // Note that the BE only gives us a success boolean field to indicate if
              // generate OTP request was successful so we are showing a generic error here.
              cause: asError('Request unsuccessful'),
            }),
        pending: false,
        value: {[mode]: isSuccess},
      });
    });
    return isSuccess;
  } catch (err: any) {
    const cause = asError(err);
    const error = new Error(ErrorCode.failedToGenerateOtp, {
      cause,
    });
    handleException(error, `${error} ${cause}`);

    controller.update((draft) => {
      setGenerateOTP(draft, {
        createdAt: null,
        error,
        pending: false,
        value: null,
      });
    });
    return false;
  }
};

/**
 * @param controller The controller instance.
 * @returns True if the validate OTP request was successful, false otherwise.
 */
export const validateOTPAction: ApplicationActionWithPayload<{
  mode: OTPMode;
}> = async (controller, {mode}) => {
  if (controller.state.mutation.validateOTP.pending) {
    return;
  }

  controller.update((draft) => {
    setValidateOTP(draft, {
      createdAt: null,
      error: null,
      pending: true,
      value: {[mode]: false},
    });
  });
  try {
    const {session} = await validateOTP(controller.state, controller.runtime!);
    if (session) {
      controller.update((draft) => {
        // Merge the session changes into the session.
        setValidateOTP(draft, {
          createdAt: Date.now(),
          error: null,
          pending: false,
          value: {[mode]: true},
        });
        shallowMergeInto(draft.session!, session);
      });
      const reason =
        mode === OTPMode.email ? 'email_otp_submitted' : 'phone_otp_submitted';

      await routeToNextPage(
        controller.state,
        controller.runtime!,
        reason,
        undefined,
        'mutationActions.validateOTPAction',
      );
      return true;
    }
  } catch (err: any) {
    const cause = asError(err);
    const error = new Error(ErrorCode.failedToValidateOTP, {
      cause,
    });
    handleException(error, `${error} ${cause}`);

    controller.update((draft) => {
      setValidateOTP(draft, {
        createdAt: null,
        error,
        pending: false,
        value: null,
      });
    });
    setOTPCodeAction(controller, {mode, code: ''});
    return false;
  }
};
