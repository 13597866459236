/**
 * @fileoverview Actions that manage the layers state for the controller.
 */

/**
 * The layer state.
 * - layer.items: The items in the layer.
 * - layer.maxZIndex: The maximum z-index of the layer.
 * - layer.showBackdrop: Whether to show the backdrop.
 */
export type LayerState = {
  layer: {
    items: LayerItem[];
    maxZIndex: number;
    showBackdrop: boolean;
  };
};

/**
 * The component that is rendered in the layer.
 */
export type LayerContentRenderer = () => JSX.Element | string | null;

/**
 * The item in the layer.
 * - active: Whether the layer is active. If true, user can interact with the
 *     layer.
 * - contentRenderer: The component that is rendered in the content.
 * - key: The unique key of the layer.
 * - opened: Whether the layer is opened. If true, the layer is visible.
 */
export type LayerItem = {
  active: boolean;
  contentRenderer: LayerContentRenderer;
  key: string;
  opened: boolean;
  zIndex: number;
};

type State = LayerState;

/**
 * Creates the initial layer state for the controller.
 * @returns The initial state.
 */
export function createLayerState(): State {
  return {
    layer: {
      items: [],
      maxZIndex: 1000,
      showBackdrop: false,
    },
  };
}

/**
 * Whether the layer for the "contentRenderer" is active.
 * @param state The current state.
 * @param contentRenderer The content renderer for the layer.
 * @returns Whether the layer for the "contentRenderer" is active.
 */
export function isLayerActive(
  state: State,
  contentRenderer: LayerContentRenderer,
): boolean {
  return state.layer.items.some((item) => {
    return item.contentRenderer === contentRenderer && item.active;
  });
}

/**
 * Whether the layer for the "contentRenderer" is opened.
 * @param state The current state.
 * @param contentRenderer The content renderer for the layer.
 * @returns Whether the layer for the "contentRenderer" is opened.
 */
export function isLayerOpened(
  state: State,
  contentRenderer: LayerContentRenderer,
): boolean {
  return state.layer.items.some((item) => {
    return item.contentRenderer === contentRenderer && item.opened;
  });
}

/**
 * Find the layer that is active.
 * @param state The current state.
 * @returns The active layer or null if no layer is active.
 */
export function findActiveLayer(state: State): LayerItem | null {
  return (
    state.layer.items.find((item) => {
      return item.active;
    }) || null
  );
}

export function hasActiveLayer(state: State): boolean {
  return !!state.layer.items.some((item) => {
    return item.active;
  });
}

/**
 * Find the index of the layer in the state. The layer could be opened or
 * closed. Returns -1 if the layer is not found.
 * @param state The current state.
 * @param contentRenderer The content renderer for the layer.
 * @returns The index of the layer in the state. -1 if the layer is not found.
 */
export function findLayerIndex(
  state: State,
  contentRenderer: Readonly<LayerContentRenderer>,
): number {
  return state.layer.items.findIndex((item) => {
    return item.contentRenderer === contentRenderer;
  });
}

/**
 * Opens a layer.
 * @param state The current state.
 * @param contentRenderer The content renderer for the layer.
 */
export function openLayer(state: State, contentRenderer: LayerContentRenderer) {
  const {layer} = state;
  const {items, maxZIndex} = layer;
  const index = findLayerIndex(state, contentRenderer);

  // Deactivate all existing layers.
  items.forEach((item) => {
    item.active = false;
  });

  // Make the opened layer stay at the top.
  const zIndex = maxZIndex + 1;
  const item = state.layer.items[index];

  if (item) {
    // Bring the layer to the top.
    // The layer should animate in.
    item.active = true;
    item.zIndex = zIndex;
    item.opened = true;
  } else {
    // Add the layer to the list of layers.
    // The layer should animate in.
    items.push({
      active: true,
      contentRenderer,
      key: String(`layer_${zIndex}`),
      opened: true,
      zIndex,
    });
  }

  layer.maxZIndex = zIndex;
  layer.showBackdrop = true;
}

/**
 * Close a layer in the application state.
 * @param state The current state.
 * @param contentRenderer  The content renderer for the layer.
 */
export function closeLayer(
  state: State,
  contentRenderer: Readonly<LayerContentRenderer>,
) {
  const index = findLayerIndex(state, contentRenderer);

  if (index === -1) {
    // Layer not found.
    return;
  }

  const {layer} = state;
  const {items} = layer;

  layer.showBackdrop = false;

  // Deactivate the layer.
  const currentItem = items[index]!;
  currentItem.active = false;

  // The layer should animate out.
  currentItem.opened = false;

  // Activate the next opened layer at top.
  const openedItems = items
    .filter((item) => item.opened)
    .sort((a, b) => b.zIndex - a.zIndex);

  const nextItem = openedItems[0];

  if (nextItem) {
    nextItem.active = true;
    layer.showBackdrop = true;
  }
}
