import {PHONE_COUNTRY_WHITELIST} from 'gelato/frontend/src/lib/constants';

import type {LocaleKey} from 'gelato/frontend/src/lib/locale';
import type {Country} from 'sail/PhoneInput';

const US = 'US' as Country;

const COUNTRY_CODES: Set<string> = new Set<Country>(
  PHONE_COUNTRY_WHITELIST as Country[],
);

type SessionPartial = {
  defaultCountry?: Country | string | undefined | null;
  ipCountry?: Country | string | undefined | null;
};

/**
 * Get the default country code for SMS handoff.
 * @returns The default country code for SMS handoff.
 */
export default function getDefaultCountryForSMS(
  session?: SessionPartial | null,
  locale?: LocaleKey | null,
): Country {
  const {ipCountry, defaultCountry} = session || {};

  if (ipCountry && COUNTRY_CODES.has(ipCountry)) {
    // Use the local phone country if the user is in a country that supports SMS.
    return ipCountry as Country;
  }

  const localeCountry = (locale && locale.split(/[-_]/).pop()) || '';
  if (COUNTRY_CODES.has(localeCountry)) {
    return localeCountry as Country;
  }

  const merchantCountry = defaultCountry || '';
  if (merchantCountry && COUNTRY_CODES.has(merchantCountry)) {
    return merchantCountry as Country;
  }

  return US;
}
