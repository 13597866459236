import {graphql} from '@sail/data';
import {useGelatoMutation} from '@stripe-internal/data-gelato';

import type {ApolloError, GraphQlDocument} from '@sail/data';
import type {
  Exact,
  SessionOperatingMode,
} from '@stripe-internal/data-gelato/schema/types';
import type {
  GelatoMutationOptions,
  GelatoMutationOptionsWithoutVariables,
  GelatoMutationReturn,
  GelatoPermissionToken,
} from '@stripe-internal/data-gelato/types';

const doc = graphql`
  mutation ReclaimPrimaryMutation {
    reclaimPrimary {
      id
      operatingMode
    }
  }
`;

export const reclaimPrimaryMutation: ReclaimPrimaryMutationDocument = doc;

// Fill these options as needed. Options are documented at
// http://go/sail/apis/data/graphql-usage/the-basics-runtime#operation-options
const reclaimPrimaryMutationOptions: GelatoMutationOptionsWithoutVariables<ReclaimPrimaryMutationDocument> =
  {
    // expectedErrors: [],
    // mutedErrors: [],
    // fetchPolicy: 'cache-first',
    // ...

    // TODO: This is a legacy behavior that was present in the original Apollo
    // wrappers and is not standard. If you are not using this error for anything
    // feel free to remove it (More info: go/throw-errors-on-mutations)
    onError(error: ApolloError) {
      throw error;
    },
  };

export default function useReclaimPrimaryMutation(
  options: GelatoMutationOptions<ReclaimPrimaryMutationDocument> = {},
): GelatoMutationReturn<ReclaimPrimaryMutationDocument> {
  return useGelatoMutation(reclaimPrimaryMutation, {
    ...reclaimPrimaryMutationOptions,
    ...options,
  });
}

// THIS IS AUTOGENERATED CODE, DO NOT EDIT AS YOUR CHANGES WILL BE BLOWN AWAY
// Regenerate it using eslint in fix mode.

export type ReclaimPrimaryMutationVariables = Exact<{[key: string]: never}>;

export type ReclaimPrimaryMutationData = {
  readonly __typename: 'Mutation';
  readonly reclaimPrimary: {
    readonly __typename: 'Session';
    readonly id: string;
    readonly operatingMode: SessionOperatingMode;
  };
};

const reclaimPrimaryMutationPermissions = {} as const;

doc.permissions = reclaimPrimaryMutationPermissions;

export type ReclaimPrimaryMutationPermissions = GelatoPermissionToken<
  typeof reclaimPrimaryMutationPermissions
>;

export type ReclaimPrimaryMutationDocument = GraphQlDocument<
  ReclaimPrimaryMutationData,
  ReclaimPrimaryMutationVariables,
  ReclaimPrimaryMutationPermissions
>;
