import {GraphQlField} from '@sail/data';
import * as React from 'react';
import {defineMessages} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import getBrandingPlatformName from 'gelato/frontend/src/lib/getBrandingPlatformName';

import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';

const Messages = defineMessages({
  returnToInternal: {
    defaultMessage: 'Go back',
    description:
      'Text of link to return back to return URL without platform name',
    id: 'verification.button.returnToInternal',
  },
  returnToPlatform: {
    defaultMessage: 'Go back to {platformName}',
    description:
      'Text of link to return back to return URL with Merchant platform name',
    id: 'verification.button.returnToPlatform',
  },
});

export default function getGoBackButtonText(
  session: GraphQlField<GetSessionQueryData, 'session'>,
) {
  const {returnUrl, consentConfig} = session;
  const platformName = getBrandingPlatformName(session.branding);

  if (!returnUrl) {
    throw new Error(
      'Sesssion.returnUrl is undefined. There should not be a "Go back" button.',
    );
  }

  return consentConfig.stripeInternal ? (
    <Message {...Messages.returnToInternal} />
  ) : (
    <Message {...Messages.returnToPlatform} values={{platformName}} />
  );
}
