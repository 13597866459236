import * as React from 'react';
import {view, themeGlobals, useTokenContext} from '@sail/engine/deprecated';

export type LayerType = 'root' | 'overlay' | 'partial';
export type LayerDescription = {name: string; isolated?: boolean};

export interface LayerContextType {
  type: LayerType;
  container?: HTMLElement;
  parent: LayerContextType | null;
  name?: string;
  sublayers?: Array<LayerDescription>;
  layerCount: number;
  overlayCount: number;
  mountedOverlayCount: number;
  hasOverlay: () => boolean;
  addLayer: (type: LayerType) => void;
  removeLayer: (type: LayerType) => void;
  addMountedOverlay: () => void;
  removeMountedOverlay: () => void;
}

export const LayerContext = React.createContext<LayerContextType | null>(null);

export const ClassicLayerContext = React.createContext(false);

export const ClassicLayerCompat = view.div.create(function ClassicLayerCompat(
  props,
  View,
) {
  const tokens = useTokenContext();
  return (
    // eslint-disable-next-line react/jsx-boolean-value
    <ClassicLayerContext.Provider value={true}>
      <View uses={[props, themeGlobals, tokens.styles]} />
    </ClassicLayerContext.Provider>
  );
});
