import {isEqual, mapValues, keyBy} from 'lodash';
import * as React from 'react';

import {convertUserAgentDataToAnalyticsEvents} from 'gelato/frontend/src/components/AnalyticsProvider/utils';
import {UserAgentData} from 'gelato/frontend/src/lib/analytics/calculateUserAgentData';
import setupAnalyticsClient from 'gelato/frontend/src/lib/analytics/setupAnalyticsClient';
import {getConfigValue} from 'gelato/frontend/src/lib/config';
import {
  useBreakpoint,
  useExperiments,
  useFeatureFlags,
  useSession,
  useBranding,
} from 'gelato/frontend/src/lib/hooks';
import {isInIframe} from 'gelato/frontend/src/lib/iframe';
import Storage from 'gelato/frontend/src/lib/Storage';

type Props = {
  children: React.ReactNode;
  userAgentData?: UserAgentData;
};

// Sets up the analytics client.
const AnalyticsClientProvider = ({children, userAgentData}: Props) => {
  const breakpoint = useBreakpoint();
  const experiments = useExperiments();
  const featureFlags = useFeatureFlags();
  const session = useSession();
  const branding = useBranding() || Storage.getBranding();
  const [config, setConfig] = React.useState<any>();
  const [globalParams, setGlobalParams] = React.useState<any>();

  const templateId = Storage.getTemplateId();
  const merchant = Storage.getMerchant();
  const eak = Storage.getSessionAPIKey();
  const iframe = isInIframe();

  const gitHash = getConfigValue('COMMIT_HASH');
  // Hack to only update the config when flags change
  React.useEffect(() => {
    if (featureFlags) {
      const newConfig = {
        flags: featureFlags,
      };

      if (!isEqual(newConfig, config)) {
        setConfig(newConfig);
      }
    }
  }, [config, featureFlags]);

  // Hack to only update the params when relevant session variable change
  React.useEffect(() => {
    const newGlobalParams = {
      breakpoint,
      creationMethod: session?.creationMethod,
      eak4: eak ? eak.slice(-4) : null,
      experiments: mapValues(keyBy(experiments || {}, 'name'), 'variant'),
      gitHash,
      iframe,
      livemode: session?.livemode,
      merchantId: merchant,
      operatingMode: session?.operatingMode,
      platformName: branding?.platformName,
      templateId,
      verificationIntent: session?.id,
    };

    if (!isEqual(newGlobalParams, globalParams)) {
      setGlobalParams(newGlobalParams);
    }
  }, [
    breakpoint,
    session?.creationMethod,
    eak,
    experiments,
    gitHash,
    globalParams,
    iframe,
    session?.livemode,
    merchant,
    session?.operatingMode,
    branding?.platformName,
    templateId,
    session?.id,
  ]);

  const analyticsUserAgentData = React.useMemo(
    () =>
      userAgentData ? convertUserAgentDataToAnalyticsEvents(userAgentData) : {},
    [userAgentData],
  );

  React.useEffect(() => {
    if (config && globalParams) {
      setupAnalyticsClient({
        config,
        globalParams: {
          ...globalParams,
          ...analyticsUserAgentData,
        },
      });
    }
  }, [config, globalParams, analyticsUserAgentData]);

  return <>{children}</>;
};

export default AnalyticsClientProvider;
