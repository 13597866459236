import {SELF, VERIFIED} from 'src/internal/apollo/permissions';

import type {
  InternalPermissionToken,
  PermissionsPrototype,
} from 'src/internal/apollo/types';

type IsEmpty<T> = Record<string, never> extends T ? true : false;

type EmptyIfBlank<T> = IsEmpty<T> extends true ? {} : T;

/**
 * Adds additional properties to the token that are not present in the original token.
 *
 * This function performs permission checks. At runtime, this actually
 * checks for the presence of a permission in the token. At build time,
 * it leverages TypeScript to keep track of which permission checks it
 * is performing on the token.
 *
 * @see https://sail.stripe.me/apis/data/checkPermissions
 */
export default function checkPermissions<
  const Token extends InternalPermissionToken<any, any>,
  const Permissions extends Token extends {[SELF]: infer P}
    ? EmptyIfBlank<P>
    : never,
  AdditionalPermissions extends Omit<PermissionsPrototype, keyof Permissions>,
  Result =
    | Omit<Token, typeof SELF> & {
        [SELF]: AdditionalPermissions & Permissions;
      },
>(
  /**
   * This is the token in which you want to check for one or more permissions.
   * This type of this parameter **cannot** be a union.
   */
  token: Token | null | undefined,

  /**
   * Specifies the extra permissions you want to check for. It will refine the token
   * to add those too.
   */
  additionalPermissions: AdditionalPermissions,
): Result | undefined {
  if (!token) {
    return undefined;
  }

  return !additionalPermissions ||
    !token[VERIFIED] ||
    (token &&
      Object.keys(additionalPermissions).every((permission) => {
        const permissionValue = token[SELF][permission];

        const value = (additionalPermissions as AdditionalPermissions)[
          permission as keyof AdditionalPermissions
        ];

        return Array.isArray(permissionValue)
          ? permissionValue.includes(value)
          : permissionValue === value;
      }))
    ? (token as unknown as Result)
    : undefined;
}
