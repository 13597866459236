import {dispute} from '@sail/icons/react/Icon';
import {view} from '@sail/ui';
import * as React from 'react';

import InvalidLayout from 'gelato/frontend/src/components/Invalid/InvalidLayout';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {useRouter, useReturnUrl} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {ButtonLink} from 'sail/Button';
import Group from 'sail/Group';
import {InlineLink} from 'sail/InlineLink';
import {Text} from 'sail/Text';

import type {Session} from 'gelato/frontend/src/lib/contexts';

const SanctionedDocumentCountry = (props: {session: Session}) => {
  const {session} = props;
  const router = useRouter();
  const proceedCallback = React.useCallback(async () => {
    if (session) {
      const nextPage = await nextDataPageForSession(session);
      router.push(nextPage);
    }
  }, [router, session]);
  const {branding} = session;
  const returnUrl = useReturnUrl();
  const {platformName} = branding;
  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'validation_error',
      code: 'sanctioned_document_country',
    });
  });

  const title = (
    <Text color="dark">
      <Message {...messages.sanctionedDocumentCountryHeading} />
    </Text>
  );
  const description = (
    <Text color="default">
      <Message {...messages.sanctionedDocumentCountryBody} />
    </Text>
  );

  const action = (
    <Group spacing={12} direction="vertical" alignItems="center">
      {returnUrl && (
        <Text align="center">
          <ButtonLink
            id="sanctioned-document-country-return-url-link"
            icon="next"
            iconPosition="right"
            size="large"
            display="inline-flex"
            href={returnUrl}
            label={
              <Message {...messages.failureLink} values={{platformName}} />
            }
          />
        </Text>
      )}
      <Text align="center">
        <InlineLink
          id="sanctioned-document-country-retry-link"
          onClick={proceedCallback}
          style={{cursor: 'pointer'}}
        >
          <Message {...messages.sanctionedDocumentCountryBodyLink} />
        </InlineLink>
      </Text>
    </Group>
  );

  return (
    <InvalidLayout title={title} icon={dispute} description={description}>
      <view.div>{action}</view.div>
    </InvalidLayout>
  );
};

export default SanctionedDocumentCountry;
