import {dispute} from '@sail/icons/react/Icon';
import {view} from '@sail/ui';
import {
  SessionOperatingMode,
  VerificationIntentErrorDetail,
} from '@stripe-internal/data-gelato/schema/types';
import * as React from 'react';

import InvalidLayout from 'gelato/frontend/src/components/Invalid/InvalidLayout';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message, {
  FormattedMarkdownMessage,
} from 'gelato/frontend/src/components/Message';
import {isMobileDevice} from 'gelato/frontend/src/lib/device';
import experiments from 'gelato/frontend/src/lib/experiments';
import {useRouter} from 'gelato/frontend/src/lib/hooks';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import {
  isChromeUA,
  isFirefoxUA,
  isSafariUA,
} from 'gelato/frontend/src/lib/userAgent';
import Box from 'sail/Box';
import {Button, ButtonLink} from 'sail/Button';
import Group from 'sail/Group';
import {InlineLink} from 'sail/InlineLink';

type Props = {
  unsupportedReason: VerificationIntentErrorDetail | null;
  operatingMode: SessionOperatingMode;
};

const DeviceUnsupported = (props: Props) => {
  const {unsupportedReason, operatingMode} = props;
  const router = useRouter();

  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR', {
      type: 'device_error',
      code: 'device_unsupported',
    });
  });

  const renderPermissionsHelp = () => {
    const mobile = isMobileDevice();
    const permissionHelpLink = (() => {
      if (mobile && isChromeUA()) {
        return 'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DAndroid';
      } else if (!mobile && isChromeUA()) {
        return 'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop';
      } else if (isFirefoxUA()) {
        return 'https://support.mozilla.org/en-US/kb/site-information-panel';
      } else if (!mobile && isSafariUA()) {
        return 'https://support.apple.com/guide/safari/customize-settings-per-website-ibrw7f78f7fe/mac';
      } else {
        return undefined;
      }
    })();

    if (permissionHelpLink) {
      const PermissionHelpLink = (text: any) => (
        <InlineLink
          href={permissionHelpLink}
          target={mobile ? undefined : '_blank'}
        >
          {text}
        </InlineLink>
      );
      return (
        <FormattedMarkdownMessage
          {...messages.permissionDeniedHelp}
          values={{PermissionHelpLink}}
        />
      );
    } else {
      return null;
    }
  };

  const renderTitle = () => {
    const msg = (() => {
      if (unsupportedReason === 'no_webcam') {
        return messages.noWebcamTitle;
      } else if (unsupportedReason === 'permission_denied') {
        return messages.permissionDeniedTitle;
      } else if (unsupportedReason === 'no_web_rtc') {
        return messages.browserUnsupportedTitle;
      } else {
        return messages.deviceUnsupportedTitle;
      }
    })();
    return <Message {...msg} />;
  };

  const renderContent = () => {
    const msg = (() => {
      if (unsupportedReason === 'no_webcam') {
        return operatingMode === 'primary'
          ? messages.noWebcamBody
          : messages.noWebcamBodySecondary;
      } else if (unsupportedReason === 'permission_denied') {
        return null;
      } else if (unsupportedReason === 'no_web_rtc') {
        return operatingMode === 'primary'
          ? messages.browserUnsupportedBody
          : messages.browserUnsupportedBodySecondary;
      } else {
        return operatingMode === 'primary'
          ? messages.deviceUnsupportedBody
          : messages.deviceUnsupportedBodySecondary;
      }
    })();

    const permissionHelpSection =
      unsupportedReason === 'permission_denied' && renderPermissionsHelp();
    return (
      <Box>
        {msg && (
          <div>
            <Message {...msg} />
          </div>
        )}
        {permissionHelpSection && <div>{permissionHelpSection}</div>}
      </Box>
    );
  };

  const handleHandoff = () => router.push('/handoff');

  const onReloadPage = React.useCallback(async () => {
    // Reload the web page to ensure that the permissions are applied properly.
    // To communicate with the owner element of the containing iframe while
    // reloading the page, we need a safe and efficient solution that
    // minimizes wait times. Our approach is to create a new, lightweight
    // page that can quickly reload and effectively communicate with the
    // owner element of the containing iframe.
    router.push('/reload');
  }, [router]);

  const action = (
    <Group alignItems="center" spacing={12}>
      {operatingMode === 'primary' && !experiments.isActive('no_handoff') && (
        <Button
          // @ts-expect-error - TS2769 - No overload matches this call.
          id="webcam-handoff-button"
          onClick={handleHandoff}
          label={<Message {...messages.useDifferentBrowser} />}
          color="blue"
        />
      )}
      {unsupportedReason !== 'no_web_rtc' && (
        <ButtonLink
          id="webcam-try-again-button"
          label={<Message {...messages.enabledCamera} />}
          onClick={onReloadPage}
        />
      )}
    </Group>
  );

  return (
    <InvalidLayout
      title={renderTitle()}
      icon={dispute}
      description={renderContent()}
    >
      <view.div>{action}</view.div>
    </InvalidLayout>
  );
};

export default DeviceUnsupported;
