import hash from '@emotion/hash';

// Something about Emotion’s build process causes the CJS option to load
// sometimes, so we handle both cases.
const murmur2 = (hash as unknown as {default: typeof hash}).default ?? hash;

const PREFIX = 'engine-';

export function getClassName(content: string, prefix: string = PREFIX): string {
  return `${prefix}${murmur2(content)}`;
}

export function getSelector(content: string, prefix?: string): string {
  return `.${getClassName(content, prefix)}`;
}
