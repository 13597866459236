// eslint-disable-next-line @sail/data-no-imperative-methods
import {useApolloClient} from '@apollo/client';
import {useCallback, useContext, useEffect, useState} from 'react';

import AppController from 'gelato/frontend/src/controllers/AppController';
import analytics from 'gelato/frontend/src/lib/analytics';
import {errorInDev} from 'gelato/frontend/src/lib/assert';
import {
  createPreviewSession,
  createVerificationFlowSessionFromStorage,
  createVerificationFlowStoragePayload,
} from 'gelato/frontend/src/lib/bootstrap';
import {
  PreviewBrandingContext,
  LocaleContext,
} from 'gelato/frontend/src/lib/contexts';
import useBranding from 'gelato/frontend/src/lib/hooks/useBranding';
import useDataHooks from 'gelato/frontend/src/lib/hooks/useDataHooks';
import useSession from 'gelato/frontend/src/lib/hooks/useSession';
import getRouter from 'gelato/frontend/src/lib/localRouter';
import Storage from 'gelato/frontend/src/lib/Storage';

import type {ApplicationState} from 'gelato/frontend/src/controllers/types';

type AppControllerContextValue = {
  appState: ApplicationState;
  appController: AppController;
};

type StorageSession = ReturnType<
  typeof createVerificationFlowSessionFromStorage
>;

/**
 * Create the session for Flows (i.e verification link).
 * The hook returns the synthetic session from the session storage. Whenever
 * the session storage is updated, the hook will return a new derived session.
 * The hook will return null if the GraphQL session exists or the Flows template
 * slug is not available.
 * @param graphQlSession The session from the GraphQL API.
 * @returns The synthetic session from the session storage. Returns null if the
 *   GraphQL session exists.
 */
function useStorageSession(
  graphQlSession: ReturnType<typeof useSession>,
): StorageSession | null {
  const templateSlug = Storage.getTemplateSlug();
  const [data, setData] = useState(() => {
    if (graphQlSession) {
      // The GraphQL session exists. No need to create a synthetic session
      // from the session storage.
      return null;
    } else if (!templateSlug) {
      // Do not create a synthetic session if the template slug is not
      // available.
      return null;
    } else {
      // Create a synthetic session from the session storage.
      const payload = createVerificationFlowStoragePayload();
      const session = createVerificationFlowSessionFromStorage(payload);
      return {
        key: JSON.stringify(payload),
        session,
      };
    }
  });

  // Compare the session storage payload against the current rendering cycle.
  // If there's a change in the payload, create a new synthetic session from
  // session storage.
  // This approach is necessary as direct subscription to Storage for observing
  // changes isn't feasible.
  // Hence, manual comparison of the payload is required.
  let nextData: typeof data | null = null;
  if (!graphQlSession && templateSlug) {
    const payload = createVerificationFlowStoragePayload();
    const key = JSON.stringify(payload);
    if (data?.key !== key) {
      nextData = {
        key,
        session: createVerificationFlowSessionFromStorage(payload),
      };
    }
  }

  // The side-effect that updates the storage session.
  useEffect(() => {
    nextData && setData(nextData);
  }, [nextData, setData]);

  return data ? data.session : null;
}

/**
 * Create a session branding settings preview.
 * The hook returns the synthetic session based on the PreviewBrandingContext.
 * Whenever the PreviewBrandingContext is updated, the hook will return a new
 * derived session. The hook will return null if the PreviewBrandingContext does
 * not indicate that preview branding settings have been set.
 *
 * @returns The synthetic session based on the PreviewBrandingContext.
 */
function usePreviewSession() {
  const previewBranding = useContext(PreviewBrandingContext);
  const [data, setData] = useState(() => {
    return {
      key: JSON.stringify(previewBranding),
      session: createPreviewSession(previewBranding),
    };
  });

  // Compare the preview branding settings against the current rendering
  // cycle. If there's a change in the preview branding settings, create
  // a new synthetic sesion. This iss necessary to prevent extra renders
  // because React useEffect dependency objects are compared by reference
  // (as opposed to by value).
  let nextData: typeof data | null = null;
  if (previewBranding) {
    const key = JSON.stringify(previewBranding);
    if (data?.key !== key) {
      nextData = {key, session: createPreviewSession(previewBranding)};
    }
  }

  // Updates the preview session.
  useEffect(() => {
    nextData && setData(nextData);
  }, [nextData, setData]);

  return data.session;
}

/**
 * The React hook that creates and sets up the context value for
 * <AppControllerContextProvider />.
 *
 * In Butter 2.0, we follow the standard MVC pattern:
 * - Model: Represents the application state.
 * - View: Comprises the React components.
 * - Controller: Owns the state and defines actions that can be performed on
 *   the state.
 *
 * Use this hook to react to the application state and controller from the
 * component.
 *
 * @returns The application state and controller.
 */
export default function useAppControllerContextProviderValue(): AppControllerContextValue {
  const appController = AppController.getSingleton();
  const [appState, setAppState] = useState(appController.state);
  const {locale, dispatchLocale} = useContext(LocaleContext);

  const api = useDataHooks();
  const apolloClient = useApolloClient();
  const graphQlSession = useSession();
  const storageSession = useStorageSession(graphQlSession);
  const previewSession = usePreviewSession();
  const router = getRouter();
  const branding = useBranding() || null;
  const livemode = Storage.getLivemode();
  const appError = appState.error;

  useEffect(() => {
    if (appError) {
      errorInDev(appError);
      // Track the error whenever a new error is captured.
      analytics.track('appError', {
        cause: appError.cause,
        message: appError.message,
      });
    }
  }, [appError]);

  // The method that updates the global locale.
  const updateLocale = useCallback(
    (lan) => dispatchLocale(lan),
    [dispatchLocale],
  );

  // The side effect that subscribes to the application controller.
  useEffect(() => {
    return appController.subscribe(setAppState);
  }, [appController]);

  // Sync runtime environment with AppController.
  useEffect(() => {
    appController.setRuntime({
      /* eslint sort-keys: ["error", "asc"] */
      api,
      apolloClient,
      branding,
      livemode,
      router,
      updateLocale,
    });
  }, [
    /* eslint sort-keys: ["error", "asc"] */
    api,
    appController,
    apolloClient,
    branding,
    livemode,
    router,
    updateLocale,
  ]);

  // The side-effect that create or update session state with AppController.
  // The session state is fetched from GraphQL or
  // bootstrapped from session storage.

  useEffect(() => {
    appController.batchUpdates(() => {
      const prevSession = appController.state.session;
      const path = appController.runtime?.router?.currentPath;
      if (path === '/preview' && previewSession) {
        appController.setSession(previewSession);
      } else if (graphQlSession) {
        let coalescedSession;
        if (prevSession) {
          // Somehow the new session might not have all the fields from the
          // previous session. We need preserve the fields from the previous
          // session.
          coalescedSession = {
            ...prevSession,
            ...graphQlSession,
          };
        } else {
          coalescedSession = graphQlSession;
        }
        appController.setSession(coalescedSession);
      } else if (storageSession) {
        const templateSlug = Storage.getTemplateSlug();
        // Create session from the template slug.
        // The session will be bootstrapped from session storage
        appController.setSession(storageSession);

        const email = Storage.getPrefilledEmail();
        if (email) {
          appController.updateFlowsEmail({email});
        }

        const clientReferenceId = Storage.getClientReferenceId();
        if (clientReferenceId) {
          appController.setFlowsClientReferenceId({clientReferenceId});
        }

        appController.setFlowsSlug(templateSlug);
      }
    });
  }, [appController, graphQlSession, storageSession, previewSession]);

  // Sync the current locale to controller's state. The current locale is
  // determined by browser's language or the locale query string parameter.
  useEffect(() => {
    appController.localeDidChange(locale);
  }, [appController, locale]);

  // Set up Networked Identity
  useEffect(() => {
    const identitySettings = Storage.getIdentitySettings();
    appController.setNetworkedIdentityEnabled(
      identitySettings?.networked_identity_enabled || false,
      identitySettings?.networked_identity_reuse_available || false,
    );
  }, [appController]);

  return {appController, appState};
}
