export default class NetworkError extends Error {
  constructor(requestUrl: string) {
    super(requestUrl);
    this.name = 'NetworkError';
    this.type = 'network_error';
    this.requestUrl = requestUrl;
  }

  type: string;

  requestUrl: string;
}
