import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {confirmDocumentImagesMutation} from 'gelato/frontend/src/graphql/mutations/useConfirmDocumentImagesMutation';

// eslint-disable-next-line @sail/data-no-imperative-methods
import type {ApolloClient, FetchResult} from '@apollo/client';
import type {
  ConfirmDocumentImagesMutationVariables,
  ConfirmDocumentImagesMutationData,
} from 'gelato/frontend/src/graphql/mutations/useConfirmDocumentImagesMutation';

/**
 * Performs the mutation that confirms the document images.
 * @param apolloClient The apollo client.
 * @param variables The mutation variables.
 */
export default async function confirmDocumentImagesMutationMethod(
  apolloClient: ApolloClient<object>,
  variables: ConfirmDocumentImagesMutationVariables,
): Promise<FetchResult<ConfirmDocumentImagesMutationData>> {
  // eslint-disable-next-line @sail/data-no-imperative-methods
  const resp = await apolloClient.mutate({
    mutation: confirmDocumentImagesMutation,
    variables,
  });
  if (resp.errors) {
    const message = resp.errors.map((e) => e.message).join('\n');
    throw new Error(message || ErrorCode.failedToConfirmDocumentImages);
  }
  return resp;
}
