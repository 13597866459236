import {dispute} from '@sail/icons/react/Icon';
import {view} from '@sail/ui';
import * as React from 'react';

import InvalidLayout from 'gelato/frontend/src/components/Invalid/InvalidLayout';
import messages from 'gelato/frontend/src/components/Invalid/messages';
import Message from 'gelato/frontend/src/components/Message';
import {postIframeEvent} from 'gelato/frontend/src/lib/iframe';
import Storage from 'gelato/frontend/src/lib/Storage';
import {InlineLink} from 'sail/InlineLink';

type Props = {
  platformName?: string;
};

const Generic = (props: Props) => {
  React.useEffect(() => {
    postIframeEvent('STRIPE_IDENTITY_ERROR');
  });

  const returnUrl = Storage.getReturnUrl();

  const {platformName} = props;

  return (
    <InvalidLayout
      title={<Message {...messages.heading} />}
      icon={dispute}
      description={
        <p>
          <Message {...messages.body} />
        </p>
      }
    >
      <view.div>
        {returnUrl && platformName && (
          <InlineLink id="generic-invalid-return-url-link" href={returnUrl}>
            <Message {...messages.failureLink} values={{platformName}} />
          </InlineLink>
        )}
      </view.div>
    </InvalidLayout>
  );
};

export default Generic;
