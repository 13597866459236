import {useContext, useMemo, useReducer} from 'react';
import type {LayerContextType, LayerType, LayerDescription} from './layer';
import {LayerContext} from './layer';

type UseLayerState = {
  layerCount: number;
  overlayCount: number;
  mountedOverlayCount: number;
};
type UseLayerAction =
  | {type: 'add-layer'; layerType: LayerType}
  | {type: 'remove-layer'; layerType: LayerType}
  | {type: 'add-mounted-overlay'}
  | {type: 'remove-mounted-overlay'};
type UseLayerProps = {
  type: LayerType;
  name?: string;
  container?: HTMLElement | null;
  layers?: Array<string | LayerDescription>;
};

/**
 * This hook creates a new layer context. Each layer tracks how many layers are
 * open in its subtree. On mount, the layers trigger `addLayer` to increment the
 * count, and trigger `removelayer` on unmount to decrement it. This is done
 * recursively so that all parent providers are incremented and decremented.
 */
export function useLayer({
  type,
  container,
  name,
  layers: sublayers,
}: UseLayerProps): LayerContextType {
  const parent = useContext(LayerContext);

  const [state, dispatch] = useReducer(
    (state: UseLayerState, action: UseLayerAction): UseLayerState => {
      switch (action.type) {
        case 'add-layer':
          return {
            ...state,
            layerCount: state.layerCount + 1,
            overlayCount:
              state.overlayCount + (action.layerType === 'overlay' ? 1 : 0),
          };
        case 'remove-layer':
          return {
            ...state,
            layerCount: state.layerCount - 1,
            overlayCount:
              state.overlayCount - (action.layerType === 'overlay' ? 1 : 0),
          };
        case 'add-mounted-overlay':
          return {
            ...state,
            mountedOverlayCount: state.mountedOverlayCount + 1,
          };
        case 'remove-mounted-overlay':
          return {
            ...state,
            mountedOverlayCount: state.mountedOverlayCount - 1,
          };
        default:
          return state;
      }
    },
    {layerCount: 0, overlayCount: 0, mountedOverlayCount: 0},
  );

  const parsedSublayers = useMemo(() => {
    if (sublayers) {
      let foundIsolated = false;
      const names = new Set();
      return sublayers.map((sublayer) => {
        const parsedSublayer =
          typeof sublayer === 'string' ? {name: sublayer} : sublayer;

        if (!parsedSublayer.isolated) {
          if (foundIsolated) {
            throw new Error(
              'A layer config with `isolation: true` must come after all non-isolated layer configs. ' +
                'Check the `layers` configuration of this layer and put isolated layers last.',
            );
          }
        }

        if (
          parsedSublayer.name === 'modal' ||
          parsedSublayer.name === 'nonmodal'
        ) {
          throw new Error(
            `Cannot name a layer "${parsedSublayer.name}" because it is a reserved name`,
          );
        }

        if (names.has(parsedSublayer.name)) {
          throw new Error(
            `Duplicate name found in layer config: ${parsedSublayer.name}. These must be unique`,
          );
        }

        names.add(parsedSublayer.name);
        foundIsolated = foundIsolated || !!parsedSublayer.isolated;
        return parsedSublayer;
      });
    }

    return null;
  }, [sublayers]);

  return useMemo(
    () => ({
      parent,
      type,
      name,
      sublayers: parsedSublayers ?? parent?.sublayers,
      container: container ?? parent?.container,
      layerCount: state.layerCount,
      overlayCount: state.overlayCount,
      mountedOverlayCount: state.mountedOverlayCount,
      hasOverlay() {
        return state.overlayCount > 0;
      },
      addLayer(type: LayerType) {
        dispatch({type: 'add-layer', layerType: type});
        if (parent) {
          parent.addLayer(type);
        }
      },
      removeLayer(type: LayerType) {
        dispatch({type: 'remove-layer', layerType: type});
        if (parent) {
          parent.removeLayer(type);
        }
      },
      addMountedOverlay() {
        dispatch({type: 'add-mounted-overlay'});
        if (parent) {
          parent.addMountedOverlay();
        }
      },
      removeMountedOverlay() {
        dispatch({type: 'remove-mounted-overlay'});
        if (parent) {
          parent.removeMountedOverlay();
        }
      },
    }),
    [
      container,
      parent,
      parsedSublayers,
      name,
      state.layerCount,
      state.overlayCount,
      state.mountedOverlayCount,
      type,
    ],
  );
}
