import {Intent} from '@sail/engine';
import {css, view} from '@sail/ui';
import * as React from 'react';

import ListItem from 'gelato/frontend/src/components/ListItemV2';
import {mB400, mB50} from 'gelato/frontend/src/components/stylesV2';

type Props = {
  children?:
    | React.ReactElement<typeof ListItem>
    | React.ReactElement<typeof ListItem>[];
  gap?: 'xsmall' | 'small' | 'medium' | 'none';
  uses?: Intent[] | null | undefined;
};

const Styles = {
  ul: css({
    gap: 'medium',
    listStyle: 'none',
    margin: 'space.0',
    padding: 'space.0',
    stack: 'y',
  }),
  gapNone: css({
    gap: 'space.0',
  }),
  gapXS: css({
    gap: 'xsmall',
  }),
  gapS: css({
    gap: 'small',
  }),
  gapM: css({
    gap: 'medium',
  }),
};

export default function List({
  children,
  gap = 'medium',
  uses = [],
}: Props): JSX.Element {
  const styles: Intent[] = [Styles.ul];
  if (gap === 'xsmall') {
    styles.push(Styles.gapXS, mB50);
  } else if (gap === 'medium') {
    styles.push(Styles.gapM, mB400);
  } else if (gap === 'none') {
    styles.push(Styles.gapNone);
  }
  if (uses && uses.length > 0) {
    styles.push(...uses);
  }
  return (
    <view.ul style={{marginBlockStart: '0', marginBlockEnd: '0'}} uses={styles}>
      {children}
    </view.ul>
  );
}
