import * as React from 'react';
import {defineMessages} from 'react-intl';

import Message from 'gelato/frontend/src/components/Message';
import Spinner from 'sail/Spinner';

import type {SizeScale} from 'sail/types';

const messages = defineMessages({
  loadingSpinner: {
    id: 'loading.spinner',
    description: 'Text next to spinner that shows when page loads',
    defaultMessage: 'Loading...',
  },
});

type Props = {
  size?: SizeScale;
  color?: 'regular' | 'white';
  text?: React.ReactNode;
  className?: string;
};

export default function LoadingSpinner({
  size = 'large',
  color,
  text,
  className,
}: Props) {
  return (
    <Spinner
      size={size}
      color={color}
      text={text || <Message {...messages.loadingSpinner} />}
      className={className}
    />
  );
}
