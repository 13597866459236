export type ErrorName =
  | 'DETECTOR_BUILD_ERROR'
  | 'DETECTOR_WARMUP_ERROR'
  | 'DETECTOR_DETECT_ERROR';

type Props = {
  name: ErrorName;
  message: string;
  cause?: any;
};

export class DetectorError extends Error {
  name: ErrorName;

  message: string;

  cause: any;

  constructor(props: Props) {
    super();
    const {name, message, cause} = props;
    this.name = name;
    this.message = message;
    this.cause = cause;
  }
}
