import * as React from 'react';
import {defineMessages} from 'react-intl';

import {FormattedMarkdownMessage} from 'gelato/frontend/src/components/Message';

import styles from './TestmodeBanner.module.css';

import type {MessageDescriptor} from 'react-intl';

const messages = defineMessages({
  testDataTitle: {
    id: 'testmode.banner.title',
    description: 'text that appears on the testmode banner',
    defaultMessage: 'TEST DATA',
  },
});

type Props = {
  explicitOffset?: number;
  message?: MessageDescriptor;
};

export default function TestmodeBanner(props: Props) {
  let {message} = props;
  const {explicitOffset} = props;
  message = message || messages.testDataTitle;
  const bannerStyle =
    explicitOffset !== undefined ? {position: 'absolute'} : {};

  return (
    <div
      data-testid="test-mode-banner"
      className={styles.testModeBanner}
      // @ts-expect-error - TS2322 - Type '{ position: string; } | { position?: undefined; }' is not assignable to type 'CSSProperties | undefined'.
      style={bannerStyle}
    >
      <div className={styles.testModeBannerContent}>
        <FormattedMarkdownMessage {...message} />
      </div>
    </div>
  );
}
