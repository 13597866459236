import {DataProvider} from '@sail/data';
import {ObservabilityProvider} from '@sail/observability';
import {getGelatoSchemaConfig} from '@stripe-internal/data-gelato';
import * as React from 'react';

import {ObtainClientOnlyForMetrics} from 'gelato/frontend/src/components/getApolloClientOnlyForMetrics';
import {
  createApolloLinks,
  graphQLFetcher,
} from 'gelato/frontend/src/graphql/initApollo';
import {getGraphqlURL} from 'gelato/frontend/src/lib/urlConfig';

type Props = {
  children: React.ReactNode;
};

const sharedCacheId = Symbol('sharedCacheId');

export default function GelatoSdkProvider({children}: Props) {
  const graphqlUrl = getGraphqlURL();

  const internalObservability = React.useMemo(
    () => ({
      // For now we don't report anything using the SDK-provided
      // telemetry logic, since telemetry is reported by
      // reportErrorOrSuccessLink.

      metrics: {
        increment: () => Promise.resolve(),
        gauge: () => Promise.resolve(),
        set: () => Promise.resolve(),
      },
      analytics: {
        // Don't report anything using the SDK-provided analytics logic,
        // until we have a way to report analytics through the bridge.
        action: () => {},
        modal: () => {},
        link: () => {},
        viewed: () => {},
        track: () => {},
      },
      errors: {
        error: () => {},
        warning: () => {},
        info: () => {},

        excludeErrorFromSentry: () => {},
      },
    }),
    [],
  );

  const dataConfig = React.useMemo(
    () => ({
      graphql: {
        useNetworkOnlyForPrefetchQueries: true,
        disableDeadwood: true,
        sharedCacheId,
        schemas: {
          '*': getGelatoSchemaConfig(
            graphQLFetcher,
            graphqlUrl,
            createApolloLinks(),
          ).graphql,
        },
      },
      fetch: graphQLFetcher,
      observability: {
        expectedApiErrors: [],
        mutedApiErrors: [],
      },
      _internal_observability: internalObservability,
    }),
    [graphqlUrl, internalObservability],
  );

  const observabilityConfig = React.useMemo(
    () => ({
      errors: {
        _internal_injected_methods_only: true,
      },
      project: 'gelato_experience' as const,
      _internal_observability: internalObservability,
    }),
    [internalObservability],
  );

  return (
    <ObservabilityProvider {...observabilityConfig}>
      <DataProvider config={dataConfig}>
        <ObtainClientOnlyForMetrics>{children}</ObtainClientOnlyForMetrics>
      </DataProvider>
    </ObservabilityProvider>
  );
}
