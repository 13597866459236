import * as React from 'react';

import {DEFAULT_LOCALE} from 'gelato/frontend/src/lib/locale';
import {localeReducer} from 'gelato/frontend/src/lib/reducers';

import {DEFAULT_BREAKPOINT, DEFAULT_IFRAME} from './constants';

import type {GraphQlField} from '@sail/data';
import type {Props as PageCardProps} from 'gelato/frontend/src/components/PageCard';
import type {Breakpoint as ViewportSizeListenerBreakpoint} from 'gelato/frontend/src/components/ViewportSizeListener';
import type {SubmitMutationData} from 'gelato/frontend/src/graphql/mutations/useSubmitMutation';
import type {GetSessionQueryData} from 'gelato/frontend/src/graphql/queries/useGetSessionQuery';
import type {LocaleKey} from 'gelato/frontend/src/lib/locale';

export type Breakpoint = ViewportSizeListenerBreakpoint;
export type Experiments = ReadonlyArray<
  GraphQlField<GetSessionQueryData, 'experiments'>
>;
export type Flags = GraphQlField<GetSessionQueryData, 'flags'>;
export type LocaleState = {
  locale: LocaleKey;
  dispatchLocale: any;
};
export type Session = GraphQlField<GetSessionQueryData, 'session'>;
export type Branding = Session['branding'];
export type SubmitSession = GraphQlField<
  SubmitMutationData,
  'submit',
  'session'
>;
export type SetPageCardProps = React.Dispatch<
  React.SetStateAction<PageCardProps>
>;

export const BreakpointContext =
  React.createContext<Breakpoint>(DEFAULT_BREAKPOINT);
export const CameraStreamStateContext = React.createContext<
  [
    {
      stream: MediaStream | null | undefined;
      error: Error | null | undefined;
    },
    (arg1: {
      stream: MediaStream | null | undefined;
      error: Error | null | undefined;
    }) => void | null | undefined,
  ]
  // @ts-expect-error - TS2322 - Type 'undefined' is not assignable to type '(arg1: { stream: MediaStream | null | undefined; error: Error | null | undefined; }) => void | null | undefined'.
>([{stream: undefined, error: undefined}, undefined]);
export const ConnectIframeContext =
  React.createContext<boolean>(DEFAULT_IFRAME);
export const ExperimentsContext = React.createContext<
  Experiments | null | undefined
>([]);
export const FeatureFlagsContext = React.createContext<
  Flags | null | undefined
>([]);
export const LocaleContext = React.createContext<LocaleState>({
  locale: DEFAULT_LOCALE,
  dispatchLocale: localeReducer,
});
export const BrandingOverrideContext = React.createContext<boolean>(false);
export const PageCardAnimatingContext = React.createContext<boolean>(false);
// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
export const SessionContext = React.createContext<Session | null | undefined>();
// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
export const PreviewBrandingContext = React.createContext<
  Branding | null | undefined
>();
export const SetPageCardPropsContext = React.createContext<SetPageCardProps>(
  () => {},
);
