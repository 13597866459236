import {isBrowserSupported} from '@microblink/blinkid-imagecapture-in-browser-sdk';

import {logInDev} from 'gelato/frontend/src/lib/assert';
import {getPixelSourceDimensions} from 'gelato/frontend/src/lib/canvas';
import ImageFrame from 'gelato/frontend/src/lib/ImageFrame';
import releaseCanvas from 'gelato/frontend/src/lib/releaseCanvas';
import BlurDetector from 'gelato/frontend/src/ML/detectors/BlurDetector';
import Detector from 'gelato/frontend/src/ML/detectors/Detector';
import {DetectorError} from 'gelato/frontend/src/ML/detectors/DetectorError';
import IDProbabilityDetector from 'gelato/frontend/src/ML/detectors/IDProbabilityDetector';
import LightDetector from 'gelato/frontend/src/ML/detectors/LightDetector';
import MicroBlinkPlausibilityDetector from 'gelato/frontend/src/ML/detectors/MicroBlinkPlausibilityDetector';
import MicroBlinkSupportDetector from 'gelato/frontend/src/ML/detectors/MicroBlinkSupportDetector';
import MicroblinkWasm from 'gelato/frontend/src/ML/detectors/MicroblinkWasm';
import * as constants from 'gelato/frontend/src/ML/lib/constants';
import {cropImageAroundIDInternal} from 'gelato/frontend/src/ML/lib/cropImageAroundID';
import findIdInImage from 'gelato/frontend/src/ML/lib/findIdInImage';
import findPatchForBlurDetector from 'gelato/frontend/src/ML/lib/findPatchForBlurDetector';
import findSharpestPatch from 'gelato/frontend/src/ML/lib/findSharpestPatch';
import getMidPatch from 'gelato/frontend/src/ML/lib/getMidPatch';
import {
  adjustForScaleToSquare,
  initializeTensorflow,
  scaleCoords,
} from 'gelato/frontend/src/ML/utils';

import type {
  DetectionResult,
  Point,
  Rectangle,
} from 'gelato/frontend/src/ML/IDDetectorAPI';

export default class IDDetector extends Detector<
  [HTMLCanvasElement | ImageFrame],
  DetectionResult
> {
  static instance: IDDetector | undefined;

  public readonly detectorName = 'IDDetector';

  private idProbabilityDetector: IDProbabilityDetector;

  private blurDetector: BlurDetector;

  private lightDetector: LightDetector;

  private mbPlausibilityFrontDetector: MicroBlinkPlausibilityDetector;

  private mbPlausibilityBackDetector: MicroBlinkPlausibilityDetector;

  private mbSupportDetector: MicroBlinkSupportDetector;

  // this needs to be public so we can use it directly in IDCapture
  public mbWasmDetector: MicroblinkWasm | undefined;

  constructor() {
    super();

    this.idProbabilityDetector = new IDProbabilityDetector(
      constants.IMAGE_SIZE,
    );
    this.blurDetector = new BlurDetector(constants.BLUR_THRESHOLD, true);
    this.lightDetector = new LightDetector(
      constants.LIGHT_DETECTOR_LUMINANCE_UPPER_BOUND,
      constants.LIGHT_DETECTOR_MAX_DIMENSION,
    );
    this.mbPlausibilityFrontDetector = new MicroBlinkPlausibilityDetector(
      'front',
      constants.MB_IMAGE_SIZE,
    );
    this.mbPlausibilityBackDetector = new MicroBlinkPlausibilityDetector(
      'back',
      constants.MB_IMAGE_SIZE,
    );
    this.mbSupportDetector = new MicroBlinkSupportDetector(
      constants.SUPPORT_IMAGE_SIZE,
    );
    if (!process.env.PUPPETEER && isBrowserSupported()) {
      this.mbWasmDetector = new MicroblinkWasm('front');
    }
  }

  static getInstance() {
    if (!IDDetector.instance) {
      IDDetector.instance = new IDDetector();
    }
    return IDDetector.instance;
  }

  private getDetectors() {
    const detectors = [
      this.idProbabilityDetector,
      this.blurDetector,
      this.lightDetector,
      this.mbPlausibilityFrontDetector,
      this.mbPlausibilityBackDetector,
      this.mbSupportDetector,
      this.mbWasmDetector,
    ].filter((d) => d !== undefined);
    return detectors as unknown as IDDetector[];
  }

  protected async _build() {
    const usingWasm = await initializeTensorflow();

    const buildErrors: DetectorError[] = [];

    await Promise.all(
      this.getDetectors().map(async (d) => {
        try {
          await d.build();
        } catch (e) {
          buildErrors.push(
            new DetectorError({
              name: 'DETECTOR_BUILD_ERROR',
              message: `Error building ${d.detectorName}`,
              cause: e,
            }),
          );
        }
      }),
    );

    if (buildErrors.length > 0) {
      throw new DetectorError({
        name: 'DETECTOR_BUILD_ERROR',
        message: `Error building ${this.detectorName}`,
        cause: buildErrors,
      });
    }

    if (usingWasm) {
      this.warmup();
    }
  }

  protected async _warmup() {
    const warmupErrors: DetectorError[] = [];

    await Promise.all(
      this.getDetectors().map(async (d) => {
        try {
          if (d.warmup) {
            await d.warmup();
          }
          return undefined;
        } catch (e) {
          warmupErrors.push(
            new DetectorError({
              name: 'DETECTOR_WARMUP_ERROR',
              message: `Error warming up ${d.detectorName}`,
              cause: e,
            }),
          );
        }
      }),
    );

    if (warmupErrors.length > 0) {
      throw new DetectorError({
        name: 'DETECTOR_WARMUP_ERROR',
        message: `Error warming up ${this.detectorName}`,
        cause: warmupErrors,
      });
    }
  }

  private async _detectHelper(pixelSource: HTMLCanvasElement | ImageFrame) {
    const startTime = Date.now();
    let mbScoreTimeMillis = 0;
    let mbSupportTimeMillis = 0;
    let blurTimeMillis = 0;

    const {sourceWidth, sourceHeight} = getPixelSourceDimensions(pixelSource);
    const findImageResults = await findIdInImage(
      pixelSource,
      this.idProbabilityDetector,
      constants.IMAGE_SIZE,
    );
    const detectorModelTimeMillis = Date.now() - startTime;
    let probability = findImageResults.probability;

    // Multiply out by the largest dimension since we implicitly scale down
    // the smaller dimension by putting black space in the image.
    const targetDims = [
      Math.max(sourceWidth, sourceHeight),
      Math.max(sourceWidth, sourceHeight),
    ];

    const topLeftID = adjustForScaleToSquare(
      sourceWidth,
      sourceHeight,
      // @ts-expect-error - TS2345 - Argument of type 'number[]' is not assignable to parameter of type 'Point'.
      scaleCoords(findImageResults.location.topLeft, [1, 1], targetDims),
    );
    const dimensionsID = scaleCoords(
      findImageResults.location.dimensions,
      [1, 1],
      // @ts-expect-error - TS2345 - Argument of type 'number[]' is not assignable to parameter of type 'Point'.
      targetDims,
    );

    let blurScore = 0;
    let mbScore = 0;
    let darknessScore = 0;
    let mbSupportScore = 0;
    let mbPatchTopLeft: Point = [0, 0];
    const mbPatchDimensions: Point = [
      constants.MB_IMAGE_SIZE,
      constants.MB_IMAGE_SIZE,
    ];
    const blurPatchDimensions: Point = [
      constants.BLUR_IMAGE_SIZE,
      constants.BLUR_IMAGE_SIZE,
    ];

    if (probability.noDocument < 0.3) {
      const croppedCanvas = await cropImageAroundIDInternal(pixelSource, 2, {
        topLeft: topLeftID,
        dimensions: dimensionsID,
      });
      // If we think the doc is invalid, then crop to invalid part and re-run model to
      // get new probabilities.
      if (probability.invalid > 0.5) {
        const croppedResults = await findIdInImage(
          croppedCanvas,
          this.idProbabilityDetector,
          constants.IMAGE_SIZE,
        );
        logInDev(
          `Invalid recrop ${probability.invalid} -> ${croppedResults.probability.invalid}`,
        );
        probability = croppedResults.probability;
      }

      const blurStartTime = Date.now();
      // Only compute the blur score when a document is present and large enough to be
      // meaningful.
      // We allow 'invalid' on backs since we there are a lot of weird backs.
      // Select input for blur detector
      const blurDetectorPatchLocation = findPatchForBlurDetector(
        pixelSource,
        topLeftID,
        dimensionsID,
      );
      // Compute blur score
      blurScore = await this.blurDetector.detect(
        pixelSource,
        blurDetectorPatchLocation,
        blurPatchDimensions,
      );
      blurTimeMillis = Date.now() - blurStartTime;

      // Compute darkness score
      darknessScore = await this.lightDetector.detect(croppedCanvas);

      // Call MB back plausibility model only when it is a back of the doc
      if (probability.back > 0.5) {
        // Select input for MB plausibility model
        const isVertical =
          dimensionsID[1] / dimensionsID[0] >
          constants.VERTICAL_ASPECT_THRESHOLD;

        mbPatchTopLeft = await findSharpestPatch(
          pixelSource,
          topLeftID,
          dimensionsID,
          this.blurDetector,
          constants.MB_IMAGE_SIZE,
        );
        // Compute MB plausibility score
        mbScore = await this.mbPlausibilityBackDetector.detect(
          pixelSource,
          mbPatchTopLeft,
          mbPatchDimensions,
          isVertical,
        );
      }
      const mbSupportStartTime = Date.now();
      // Call MB front plausibility model only for a front of the doc
      if (
        probability.frontCard >
          constants.MINIMUM_ID_CARD_FRONT_PROBABILTY_THRESHOLD ||
        probability.frontPassport >
          constants.MINIMUM_PASSPORT_FRONT_PROBABILTY_THRESHOLD
      ) {
        // Compute MB Support score
        mbSupportScore = await this.mbSupportDetector.detect(croppedCanvas);

        // Select input for MB plausibility model
        mbPatchTopLeft = getMidPatch(
          topLeftID,
          dimensionsID,
          constants.MB_IMAGE_SIZE,
        );
        const mbScoreStartTime = Date.now();
        mbSupportTimeMillis = mbScoreStartTime - mbSupportStartTime;

        // Compute MB plausibility score
        mbScore = await this.mbPlausibilityFrontDetector.detect(
          pixelSource,
          mbPatchTopLeft,
          mbPatchDimensions,
          false,
        );
        mbScoreTimeMillis = Date.now() - mbScoreStartTime;
      }

      // `croppedCanvas` is no longer referenced, clear it.
      releaseCanvas(croppedCanvas);
    }

    const area = dimensionsID[0] * dimensionsID[1];
    const canvasArea = sourceWidth * sourceHeight;

    const results: DetectionResult = {
      inputSize: [sourceWidth, sourceHeight] as Point,
      aspectRatio: dimensionsID[1] / dimensionsID[0],
      location: {
        topLeft: topLeftID,
        dimensions: dimensionsID,
      } as Rectangle,
      coverage: area / canvasArea,
      probability,
      blurScore,
      mbScore,
      mbSupportScore,
      mbPatchLocation: {
        topLeft: mbPatchTopLeft,
        dimensions: mbPatchDimensions,
      } as Rectangle,
      detectionTimeMillis: Date.now() - startTime,
      mbScoreTimeMillis,
      mbSupportTimeMillis,
      detectorModelTimeMillis,
      blurTimeMillis,
      darknessScore,
    };

    return results;
  }

  protected async _detect(pixelSource: HTMLCanvasElement | ImageFrame) {
    try {
      return await this._detectHelper(pixelSource);
    } catch (e) {
      throw new DetectorError({
        name: 'DETECTOR_DETECT_ERROR',
        message: `Error detecting ${this.detectorName}`,
        cause: e,
      });
    }
  }
}
