import {refresh} from '@sail/icons/react/Icon';
import {view, css, Link, Icon, Banner, Spinner} from '@sail/ui';
import * as React from 'react';
import {injectIntl, useIntl} from 'react-intl';

import {LINK_GREEN} from 'gelato/frontend/src/components/Link/constants';
import {messages} from 'gelato/frontend/src/components/Link/messages';
import Message from 'gelato/frontend/src/components/Message';
import PageCard from 'gelato/frontend/src/components/PageCardV2';
import TopNavigationBar from 'gelato/frontend/src/components/TopNavigationBar';
import {setComponentConfig} from 'gelato/frontend/src/lib/ComponentConfig';
import {DEFAULT_BRANDING_BUTTON_COLOR} from 'gelato/frontend/src/lib/constants';
import {LocaleContext} from 'gelato/frontend/src/lib/contexts';
import useAppController from 'gelato/frontend/src/lib/hooks/useAppController';
import CodePuncher from 'sail/CodePuncher';

import type {PageCardProps} from 'gelato/frontend/src/components/PageCardV2';

const {useContext, useRef, useEffect} = React;

const Styles = {
  title: css({
    font: 'heading.large',
  }),
  subtitle: css({
    font: 'body.medium',
  }),
  body: css({
    gap: 'medium',
    stack: 'y',
  }),
  otpActions: css({
    alignX: 'center',
    gap: 'medium',
    stack: 'y',
  }),
  resendAction: css({
    alignY: 'center',
    gap: 'xsmall',
    stack: 'x',
  }),
  link: css({
    font: 'label.medium.emphasized',
  }),
};
function OTPPage() {
  const intl = useIntl();
  const {appState, appController} = useAppController();
  const {locale} = useContext(LocaleContext);
  const codePuncherRef = useRef<CodePuncher>(null);

  const {otpLoading, consumerSession, otpValue, canResendOtp, otpError} =
    appState.networkedIdentity;

  useEffect(() => {
    // Focus on the code puncher when user lands on this page
    codePuncherRef.current?.handleControlInputFocus();
  }, [codePuncherRef]);

  const body = appState.session && (
    <view.div uses={[Styles.body]}>
      <view.div uses={[Styles.title]}>
        <Message {...messages.phoneOTPTitle} />
      </view.div>
      <view.div uses={[Styles.subtitle]}>
        <Message
          {...messages.phoneOTPBody}
          values={{
            phone: consumerSession!.redacted_formatted_phone_number?.replaceAll(
              '*',
              '•',
            ),
          }}
        />
      </view.div>
      <CodePuncher
        data-testid="link-otp-input"
        value={otpValue}
        onChange={(otpInput) =>
          appController.updateConsumerOtpInputV2({
            otpInput,
            locale,
            codePuncherRef,
          })
        }
        disabled={otpLoading}
        error={otpError && intl.formatMessage(otpError)}
        autoFocus
        ref={codePuncherRef}
      />
      <view.div uses={[Styles.otpActions]}>
        {!otpLoading && canResendOtp && (
          <Link
            type="secondary"
            disabled={otpLoading}
            onClick={() =>
              appController.resendConsumerOtpV2({locale, codePuncherRef})
            }
            uses={[Styles.resendAction, Styles.link]}
          >
            <Message {...messages.resendOTP} />
          </Link>
        )}
        {!appController.runtime?.livemode && (
          <Banner
            type="caution"
            title={<Message {...messages.testmodeOtpBannerTitle} />}
            description={<Message {...messages.testmodeOtpBannerDescription} />}
          />
        )}
        {otpLoading && <Spinner size="large" css={{fill: LINK_GREEN}} />}
      </view.div>
    </view.div>
  );

  const props: PageCardProps = appState.session
    ? {
        header: <TopNavigationBar />,
        body,
      }
    : {loading: true};

  return <PageCard {...props} />;
}

setComponentConfig(OTPPage, {componentName: 'link', v2: true});

export default injectIntl(OTPPage);
