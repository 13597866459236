import {TextField, Label, PhoneNumberField, CountryCode} from '@sail/ui';
import {ButtonLink} from '@stripe-internal/sail/Button';
import {parsePhoneNumber} from 'libphonenumber-js';
import * as React from 'react';
import {IntlShape, defineMessages, injectIntl} from 'react-intl';
import {isMobilePhone} from 'validator';

import LoadingBox from 'gelato/frontend/src/components/LoadingBox';
import Message from 'gelato/frontend/src/components/Message';
import {SingleInputPageSkeleton} from 'gelato/frontend/src/components/SingleInputPageSkeleton';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import useUpdateIndividualMutation from 'gelato/frontend/src/graphql/mutations/useUpdateIndividualMutation';
import useValidateOneTimePasswordMutation from 'gelato/frontend/src/graphql/mutations/useValidateOneTimePasswordMutation';
import {PAGE_TITLE_MESSAGE} from 'gelato/frontend/src/lib/constants';
import {nextDataPageForSession} from 'gelato/frontend/src/lib/dataRouting';
import {useRouter, useSession} from 'gelato/frontend/src/lib/hooks';
import getRouter from 'gelato/frontend/src/lib/localRouter';
import {Body, Title} from 'sail/Text';

import OTPPageContent, {OTPMode} from '../components/OTPVerification';

const messages = defineMessages({
  phoneHeader: {
    id: 'otp.phone.header',
    description: 'Header for SMS verification page',
    defaultMessage: 'Verify your phone number',
  },
  phoneInputLabel: {
    id: 'otp.phone.input.label',
    description: 'Label for input box for user provided phone number',
    defaultMessage: 'Phone number',
  },
  sendOTPButton: {
    id: 'otp.phone.send.otp',
    description: 'Text on button to send OTP code via SMS',
    defaultMessage: 'Send code',
  },
  pageDescriptionEnterNoName: {
    id: 'otp.phone.description.enter.no_name',
    description:
      'Text explaining that we will send an OTP code to your phone number',
    defaultMessage:
      'Please enter your phone number. You will receive an SMS with a verification code.',
  },
  pageDescriptionEnterWithName: {
    id: 'otp.phone.description.enter.with_name',
    description:
      'Text explaining that you will enter a name and phone number and we will send an OTP code to your phone number',
    defaultMessage:
      'Please enter your phone number and name. You will receive an SMS with a verification code.',
  },

  pageDescriptionConfirm: {
    id: 'otp.phone.description.confirm',
    description:
      'Text explaining that we will send an OTP code to your phone number',
    defaultMessage:
      'Confirm your phone number ending in {phoneLastFour}. You will receive an SMS with a verification code.',
  },
  pageDescriptionConfirmWithName: {
    id: 'otp.phone.description.confirm.with_name',
    description:
      'Text explaining that you will enter a name and we will send an OTP code to your phone number',
    defaultMessage:
      'Please enter your name and confirm your phone number ending in {phoneLastFour}. You will receive an SMS with a verification code.',
  },

  decline: {
    id: 'otp.phone.decline',
    description:
      'Text for link allowing user to say that they cannot verify the given phone number',
    defaultMessage: 'I cannot verify this phone number',
  },
});

const PhoneVerificationPage = (props: {intl: IntlShape}) => {
  const {
    intl: {formatMessage},
  } = props;
  const session = useSession();
  const router = useRouter();
  const [updateIndividual] = useUpdateIndividualMutation();
  const [validateOTPMutation] = useValidateOneTimePasswordMutation();
  const [confirmed, setConfirmed] = React.useState(false);
  const [confirming, setConfirming] = React.useState(false);
  const [declining, setDeclining] = React.useState(false);

  const needsOTP = React.useMemo(
    () => session?.missingFields?.includes('phone_otp'),
    [session],
  );

  const needsName = React.useMemo(
    () => session?.missingFields?.includes('name'),
    [session],
  );

  const merchantPhone = React.useMemo(
    () =>
      session?.collectedData?.individual?.phoneNumber
        ?.merchantProvidedPhoneNumber,
    [session],
  );
  const userPhone = React.useMemo(
    () =>
      session?.collectedData?.individual?.phoneNumber?.userProvidedPhoneNumber,
    [session],
  );
  const sessionPhone = React.useMemo(
    () => merchantPhone || userPhone,
    [merchantPhone, userPhone],
  );
  const canUpdatePhone = !merchantPhone;
  const [phone, setPhone] = React.useState<string>(sessionPhone || '');
  const [phoneValid, setPhoneValid] = React.useState(isMobilePhone(phone));

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');

  let defaultCountry: CountryCode = 'US';
  if (sessionPhone) {
    // get default from session to better guess in case a number is unclear
    const parsed = parsePhoneNumber(sessionPhone, 'US');
    defaultCountry = parsed.country || defaultCountry;
  }

  const doRoute = React.useCallback(async () => {
    if (session) {
      const nextPage = await nextDataPageForSession(session);
      getRouter().push(nextPage);
    }
  }, [session]);

  React.useEffect(() => {
    if (!needsOTP && session) {
      // Automatically route to the next page if we don't need to verify phone.
      // when session is ready.
      doRoute();
    }
  }, [doRoute, needsOTP, session]);

  const onChange = React.useCallback((value: string) => {
    setPhoneValid(isMobilePhone(value));
    setPhone(value);
  }, []);

  const confirm = React.useCallback(async () => {
    setConfirming(true);
    let variables: any;
    const phoneNumberData = {phoneNumber: phone};
    const nameData = {
      firstName,
      lastName,
    };
    if (needsName) {
      variables = {
        phoneNumberData,
        nameData,
      };
    } else {
      variables = {phoneNumberData};
    }

    await updateIndividual({variables});
    setConfirmed(true);
  }, [phone, updateIndividual, needsName, firstName, lastName]);

  const handleKeydown = React.useCallback(
    // this onKeyDown handler is just to support pressing enter to submit email
    (event) => {
      if (confirming || declining) {
        return;
      }
      // Pressed the Enter key
      if (event.keyCode === 13) {
        confirm();
      }
    },
    [confirming, declining, confirm],
  );

  const decline = React.useCallback(async () => {
    setDeclining(true);
    const declineResponse = await validateOTPMutation({
      variables: {phoneDecline: true},
    });
    // in these cases, we know that the user just declined to otp verify and yet
    // they still have the requirement so we can send them to the invalid page
    const missingFields =
      declineResponse.data?.validateOneTimePassword?.session?.missingFields ||
      [];
    if (missingFields.includes('phone_otp')) {
      router.push('/invalid');
    }
    setDeclining(false);
  }, [router, validateOTPMutation]);

  const generateDescription = React.useCallback(() => {
    if (canUpdatePhone) {
      if (needsName) {
        return (
          <Body>
            <Message {...messages.pageDescriptionEnterWithName} />
          </Body>
        );
      } else {
        return (
          <Body>
            <Message {...messages.pageDescriptionEnterNoName} />
          </Body>
        );
      }
    } else if (needsName) {
      return (
        <Body>
          <Message
            {...messages.pageDescriptionConfirmWithName}
            values={{
              phoneLastFour: merchantPhone.slice(merchantPhone.length - 4),
            }}
          />
        </Body>
      );
    } else {
      return (
        <Body>
          <Message
            {...messages.pageDescriptionConfirm}
            values={{
              phoneLastFour: merchantPhone.slice(merchantPhone.length - 4),
            }}
          />
        </Body>
      );
    }
  }, [canUpdatePhone, merchantPhone, needsName]);

  const generateDeclineLink = React.useCallback(() => {
    if (!canUpdatePhone) {
      return (
        <ButtonLink
          id="decline-otp"
          label={<Message {...messages.decline} />}
          size="large"
          width="maximized"
          color="blue"
          onClick={decline}
          pending={declining}
          disabled={confirming || declining}
        />
      );
    }
  }, [declining, confirming, canUpdatePhone, decline]);

  const generateSecondaryInputs = React.useCallback(() => {
    if (needsName) {
      return (
        <>
          <TextField
            aria-label="First name"
            id="name.firstName"
            onChange={(e) => setFirstName(e.target.value)}
            size="large"
            label={<Label>First Name</Label>}
          />
          <TextField
            aria-label="Last name"
            id="name.lastName"
            onChange={(e) => setLastName(e.target.value)}
            size="large"
            label={<Label>Last Name</Label>}
          />
        </>
      );
    }
  }, [needsName]);

  const generateInputLabel = React.useCallback(() => {
    if (canUpdatePhone) {
      return (
        <Label>
          <Message {...messages.phoneInputLabel} />
        </Label>
      );
    }
  }, [canUpdatePhone]);

  const generateInputField = React.useCallback(() => {
    if (canUpdatePhone) {
      return (
        <PhoneNumberField
          aria-label="Phone number"
          autoFocus
          onChange={onChange}
          value={phone}
          size="large"
          defaultCountry={defaultCountry}
          onKeyDown={handleKeydown}
        />
      );
    }
  }, [canUpdatePhone, defaultCountry, handleKeydown, phone, onChange]);

  if (!confirmed && !confirming) {
    return (
      <SingleInputPageSkeleton
        helmet={<title>{formatMessage(PAGE_TITLE_MESSAGE)}</title>}
        title={
          <Title>
            <Message {...messages.phoneHeader} />
          </Title>
        }
        inputDescription={generateDescription()}
        inputLabel={generateInputLabel()}
        secondaryInputs={generateSecondaryInputs()}
        inputElement={generateInputField()}
        primaryButton={
          <ThemableButton
            color="blue"
            label={<Message {...messages.sendOTPButton} />}
            disabled={
              (canUpdatePhone && !phoneValid) ||
              (needsName && (!firstName || !lastName))
            }
            onClick={confirm}
            width="maximized"
            size="large"
          />
        }
        secondaryLink={generateDeclineLink()}
      />
    );
  } else if (!sessionPhone) {
    // put this here to have stronger guarantee that the session already contains the updated phone number before we show the otp page
    return <LoadingBox />;
  } else {
    return (
      <OTPPageContent mode={OTPMode.phone} skipping={!needsOTP} phone={phone} />
    );
  }
};

export default injectIntl(PhoneVerificationPage);
