/**
 * Get the client position of a DOM event.
 * @param event The DOM event.
 * @returns The client position (i.e [x, y]) of the DOM event if applicable,
 *   otherwise null.
 */
export default function getDOMEventClientPosition(
  event: Event,
): [number, number] | null {
  if (typeof PointerEvent !== 'undefined' && event instanceof PointerEvent) {
    return [event.clientX, event.clientY];
  }

  // `TouchEvent` is undefined in Safari desktop.
  if (typeof TouchEvent !== 'undefined' && event instanceof TouchEvent) {
    return [event.touches[0]?.clientX || 0, event.touches[0]?.clientY || 0];
  }

  if (event instanceof MouseEvent) {
    return [event.clientX, event.clientY];
  }

  const eventUnknown: any = event;
  if (
    eventUnknown &&
    typeof eventUnknown === 'object' &&
    typeof eventUnknown.clientX === 'number' &&
    typeof eventUnknown.clientY === 'number'
  ) {
    return [eventUnknown.clientX, eventUnknown.clientY];
  }

  return null;
}
