import {createCustomProperty} from '@sail/engine';
import type {Style} from '@sail/engine';

const fallback = '0 0 0 0 transparent';

const focusRing = createCustomProperty('--focus-ring', {
  initialValue: fallback,
});
const boxShadow = createCustomProperty('--box-shadow', {
  initialValue: fallback,
});
const topShadow = createCustomProperty('--top-shadow', {
  initialValue: fallback,
});
const keyline = createCustomProperty('--keyline', {
  syntax: '<color>',
  initialValue: 'transparent',
});
const keylineWidth = createCustomProperty('--keyline-width', {
  initialValue: '1px',
});

function createShadowPlugin(
  customProperty: Style.CustomProperty,
): Style.Plugin {
  return function shadowPlugin(value, set) {
    const isNone = value === 'none';
    // Special case: if the user sets `box-shadow: none`, clear all shadows
    if (customProperty === boxShadow && isNone) {
      set.property('boxShadow', 'none', {skipPlugin: true});
      set.property(boxShadow, undefined);
      set.property(focusRing, undefined);
      set.property(keyline, undefined);
      set.property(topShadow, undefined);
      return;
    }

    set.property(customProperty, () => {
      set.property(customProperty, isNone ? fallback : value);
      set.reset({
        // Earlier shadows appear atop later shadows
        boxShadow: `${set.var(topShadow)},
          ${set.var(keyline)} 0 0 0 ${set.var(keylineWidth)},
          ${set.var(focusRing)},
          ${set.var(boxShadow)}`,
      });

      // Render focus rings using box-shadow by default. This will be removed in
      // Forced Color Modes, see:
      //  https://developer.mozilla.org/en-US/docs/Web/CSS/@media/forced-colors
      //
      // Use `outline` as a fallback and reset. Instead of using `none` to
      // disable the outline we use a transparent outline which will remain
      // visible with custom system colors in Forced Color Modes.
      if (customProperty === focusRing) {
        set.property('outline', isNone ? 'none' : '1px solid transparent');
      }
    });
  };
}

export const pluginsBoxShadow = {
  boxShadow: createShadowPlugin(boxShadow),
  topShadow: createShadowPlugin(topShadow),
  focusRing: createShadowPlugin(focusRing),
  keyline: createShadowPlugin(keyline),
  keylineWidth(value: string, set: Style.PluginAPI): void {
    set.property(keylineWidth, value);
  },
};
