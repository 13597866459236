/* eslint-disable @typescript-eslint/ban-types */
import type * as CSS from 'csstype';
import {useEffect, useMemo, useState} from 'react';
import {onRender} from '../../intentTypes';
import type {
  AnyCssSerializer,
  CssAliasShape,
  CssComputeMediaFeatures,
  CssPluginsShape,
  CssSerializer,
  CssTokensShape,
  CssValue,
  CssVariantsShape,
  SerializedStyles,
} from './types';
import type {Intent} from '../../../intent';
import {toHyphenCase} from '../../../util';
import {css as engineCss} from './css';
import type {AssignedTokens} from './token';
import {resolveTokens, useTokenContext} from './token';
import {isCssValue} from './value';

// derived from https://developer.mozilla.org/en-US/docs/Web/CSS/@media#media_features
export type MediaFeatures = {
  /**
   * Does any available input mechanism allow the user to hover over elements?
   */
  anyHover?: 'none' | 'hover';
  /**
   * Is any available input mechanism a pointing device, and if so, how accurate is it?
   */
  anyPointer?: 'none' | 'coarse' | 'fine';
  /**
   * Width-to-height aspect ratio of the viewport.
   */
  aspectRatio?: CSS.Properties['aspectRatio'];
  /**
   * Number of bits per color component of the output device, or zero if the device isn't color.
   */
  color?: number;
  /**
   * Approximate range of colors that are supported by the user agent and output device.
   */
  colorGamut?: 'srgb' | 'p3' | 'rec2020';
  /**
   * Number of entries in the output device's color lookup table, or zero if the device does not use such a table.
   */
  colorIndex?: number;
  /**
   * The display mode of the application, as specified in the web app manifest's display member.
   */
  displayMode?: 'fullscreen' | 'standalone' | 'minimal-ui' | 'browser';
  /**
   * Combination of brightness, contrast ratio, and color depth that are supported by the user agent and the output device.
   */
  dynamicRange?: 'standard' | 'high';
  /**
   * Detect whether user agent restricts color palette.
   */
  forcedColors?: 'none' | 'active';
  /**
   * Does the device use a grid or bitmap screen?
   */
  grid?: 0 | 1;
  /**
   * Height of the viewport.
   */
  height?: CSS.AtRule.Viewport['height'];
  /**
   * Minimum height of the viewport.
   */
  minHeight?: CSS.AtRule.Viewport['minHeight'];
  /**
   * Maximum height of the viewport.
   */
  maxHeight?: CSS.AtRule.Viewport['maxHeight'];
  /**
   * Does the primary input mechanism allow the user to hover over elements? Added in Media Queries Level 4.
   */
  hover?: 'none' | 'hover';
  /**
   * Is the user agent or underlying OS inverting colors? Added in Media Queries Level 5.
   */
  invertedColors?: 'none' | 'inverted';
  /**
   * Bits per pixel in the output device's monochrome frame buffer, or zero if the device isn't monochrome.
   */
  monochrome?: number;
  /**
   * Minimum bits per pixel in the output device's monochrome frame buffer, or zero if the device isn't monochrome.
   */
  minMonochrome?: number;
  /**
   * Maximum bits per pixel in the output device's monochrome frame buffer, or zero if the device isn't monochrome.
   */
  maxMonochrome?: number;
  /**
   * Orientation of the viewport.
   */
  orientation?: CSS.AtRule.Viewport['orientation'];
  /**
   * How does the output device handle content that overflows the viewport along the block axis? Added in Media Queries Level 4.
   */
  overflowBlock?: 'none' | 'scroll' | 'optional-paged' | 'paged';
  /**
   * Can content that overflows the viewport along the inline axis be scrolled? Added in Media Queries Level 4.
   */
  overflowInline?: 'none' | 'scroll';
  /**
   * Is the primary input mechanism a pointing device, and if so, how accurate is it? Added in Media Queries Level 4.
   */
  pointer?: 'none' | 'coarse' | 'fine';
  /**
   * Detect if the user prefers a light or dark color scheme. Added in Media Queries Level 5.
   */
  prefersColorScheme?: 'light' | 'dark';
  /**
   * Detects if the user has requested the system increase or decrease the amount of contrast between adjacent colors. Added in Media Queries Level 5.
   */
  prefersContrast?: 'no-preference' | 'more' | 'less' | 'custom';
  /**
   * The user prefers less motion on the page. Added in Media Queries Level 5.
   */
  prefersReducedMotion?: 'no-preference' | 'reduce';
  /**
   * Pixel density of the output device.
   */
  resolution?: string & {};
  /**
   * Minimum pixel density of the output device.
   */
  minResolution?: string & {};
  /**
   * Maximum pixel density of the output device.
   */
  maxResolution?: string & {};
  /**
   * Detects whether scripting (i.e. JavaScript) is available. Added in Media Queries Level 5.
   */
  scripting?: 'none' | 'initial-only' | 'enabled';
  /**
   * How frequently the output device can modify the appearance of content. Added in Media Queries Level 4.
   */
  update?: 'none' | 'slow' | 'fast';
  /**
   * Combination of brightness, contrast ratio, and color depth that are supported by the video plane of user agent and the output device. Added in Media Queries Level 5.
   */
  videoDynamicRange?: 'standard' | 'high';
  /**
   * Width of the viewport including width of scrollbar.
   */
  width?: CSS.AtRule.Viewport['width'];
  /**
   * Minimum width of the viewport including width of scrollbar.
   */
  minWidth?: CSS.AtRule.Viewport['minWidth'];
  /**
   * Maximum width of the viewport including width of scrollbar.
   */
  maxWidth?: CSS.AtRule.Viewport['maxWidth'];
};

export type CssMediaFeatures = {
  [K in keyof MediaFeatures]: CssValue<Exclude<MediaFeatures[K], undefined>>;
};

const AtRule = 'atRule.';

/**
 * Turns a media query object into a string that `matchMedia` can accept.
 *
 * @example
 * You can combine multiple media queries into a single rule by separating them with commas:
 * ```ts
 * input: {minQuery: css.value('600px'), maxWidth: css.value('1024px'))}
 * output: '(min-query: 600px) and (max-width: 1024px)'
 * ```
 *
 * @example
 * You can use tokens configured within the provided serializer:
 * ```ts
 * input: {minQuery: 'desktop'}
 * output: '(min-query: 1024px)'
 * ```
 */
function serializeMediaQueryList(
  serializer: AnyCssSerializer,
  assignedTokens: AssignedTokens,
  query: CssMediaFeatures,
): string {
  const {
    token: tokenMap,
    options: {alias: aliases},
  } = serializer;

  return Object.entries(query).reduce((media, [key, valueOrToken]) => {
    let value: string | number;

    if (isCssValue(valueOrToken)) {
      value = valueOrToken.value;
    } else {
      const token = valueOrToken;
      const alias = (aliases as Record<string, string>)[`${AtRule}${key}`];
      const tokens = alias && (tokenMap as unknown as CssTokensShape)[alias];
      const cssVar =
        tokens && (tokens as unknown as Record<string, string>)[token];
      value = cssVar && resolveTokens(assignedTokens, cssVar);
    }

    const feature = value ? `(${toHyphenCase(key)}: ${value})` : '';
    return !media ? feature : [media, feature].join(' and ');
  }, '');
}

type Matches = {matches: boolean};

/**
 * Adds the provided styles as an isolated dependency.
 */
function mediaDependency(...deps: SerializedStyles[]): {
  isolatedDependencies: string[];
} {
  return {
    isolatedDependencies: deps.map(({key}) => key),
  };
}

function mediaIntent(
  css: AnyCssSerializer,
  query: CssMediaFeatures,
  styles: SerializedStyles[],
): Intent {
  return onRender(() => {
    const {tokens} = useTokenContext();

    const features = useMemo(
      () => serializeMediaQueryList(css, tokens, query),
      [tokens],
    );

    const [matches, setMatches] = useState<boolean>(false);

    useEffect(() => {
      const mq = window.matchMedia(features);

      const setMedia = (media: Matches) => setMatches(media.matches);

      // Triggered at the first client-side load and if query changes
      setMedia(mq);

      // listen for the status of media query support changing.
      if (typeof mq.addEventListener === 'function') {
        mq.addEventListener('change', setMedia);
      } else {
        // Before Safari 14, MediaQueryList is based on EventTarget we must use addListener()
        mq.addListener(setMedia);
      }

      return () => {
        if (typeof mq.removeEventListener === 'function') {
          mq.removeEventListener('change', setMedia);
        } else {
          // Before Safari 14, MediaQueryList is based on EventTarget we must use removeListener()
          mq.removeListener(setMedia);
        }
      };
    }, [setMatches, features]);

    // media-query styles should be isolated dependencies to avoid
    // being "merged" with the component base styles
    return matches ? [css(mediaDependency(...styles))] : [];
  });
}

type MediaAlias<T> = {
  [K in keyof T as `${K extends `${typeof AtRule}${infer Feature}`
    ? Feature
    : never}`]: T[K];
};

export function unstable_createMediaIntent<
  Properties,
  Variants extends CssVariantsShape,
  Plugins extends CssPluginsShape,
  Tokens extends CssTokensShape,
  Alias extends CssAliasShape<Tokens>,
  ComputedProperties = CssComputeMediaFeatures<
    CssMediaFeatures,
    {},
    Tokens,
    MediaAlias<Alias>
  >,
>(
  css: CssSerializer<Properties, Variants, Plugins, Tokens, Alias>,
): (query: ComputedProperties, styles: SerializedStyles[]) => Intent {
  return (query, styles) =>
    mediaIntent(css as AnyCssSerializer, query as CssMediaFeatures, styles);
}

export const unstable_media = unstable_createMediaIntent(engineCss);
