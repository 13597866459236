import {$cssValue} from './constants';
import {replaceTokens} from './replaceTokens';
import type {CssValue, CssTokensShape} from './types';

export function createValue<T>(
  tokenMap: CssTokensShape,
  value: T,
): CssValue<T> {
  const result = replaceTokens(
    tokenMap,
    value as unknown as string,
  ) as unknown as T;
  return {
    [$cssValue]: true,
    value: result,
    toString() {
      return result;
    },
  };
}

export function isCssValue<T>(
  value: CssValue<T> | unknown,
): value is CssValue<T> {
  return typeof value === 'object' && value != null && $cssValue in value;
}
