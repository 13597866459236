export default {
  'ar-AE': {
    longPressMessage: 'اضغط مطولاً أو اضغط على Alt + السهم لأسفل لفتح القائمة',
  },
  'bg-BG': {
    longPressMessage:
      'Натиснете продължително или натиснете Alt+ стрелка надолу, за да отворите менюто',
  },
  'cs-CZ': {
    longPressMessage:
      'Dlouhým stiskem nebo stisknutím kláves Alt + šipka dolů otevřete nabídku',
  },
  'da-DK': {
    longPressMessage:
      'Langt tryk eller tryk på Alt + pil ned for at åbne menuen',
  },
  'de-DE': {
    longPressMessage:
      'Drücken Sie lange oder drücken Sie Alt + Nach-unten, um das Menü zu öffnen',
  },
  'el-GR': {
    longPressMessage:
      'Πιέστε παρατεταμένα ή πατήστε Alt + κάτω βέλος για να ανοίξετε το μενού',
  },
  'en-US': {
    longPressMessage: 'Long press or press Alt + ArrowDown to open menu',
  },
  'es-ES': {
    longPressMessage:
      'Mantenga pulsado o pulse Alt + flecha abajo para abrir el menú',
  },
  'et-EE': {
    longPressMessage:
      'Menüü avamiseks vajutage pikalt või vajutage klahve Alt + allanool',
  },
  'fi-FI': {
    longPressMessage:
      'Avaa valikko painamalla pohjassa tai näppäinyhdistelmällä Alt + Alanuoli',
  },
  'fr-FR': {
    longPressMessage:
      'Appuyez de manière prolongée ou appuyez sur Alt + Flèche vers le bas pour ouvrir le menu.',
  },
  'he-IL': {
    longPressMessage:
      'לחץ לחיצה ארוכה או הקש Alt + ArrowDown כדי לפתוח את התפריט',
  },
  'hr-HR': {
    longPressMessage:
      'Dugo pritisnite ili pritisnite Alt + strelicu prema dolje za otvaranje izbornika',
  },
  'hu-HU': {
    longPressMessage:
      'Nyomja meg hosszan, vagy nyomja meg az Alt + lefele nyíl gombot a menü megnyitásához',
  },
  'it-IT': {
    longPressMessage:
      'Premere a lungo o premere Alt + Freccia giù per aprire il menu',
  },
  'ja-JP': {
    longPressMessage: '長押しまたは Alt+下矢印キーでメニューを開く',
  },
  'ko-KR': {
    longPressMessage: '길게 누르거나 Alt + 아래쪽 화살표를 눌러 메뉴 열기',
  },
  'lt-LT': {
    longPressMessage:
      'Norėdami atidaryti meniu, nuspaudę palaikykite arba paspauskite „Alt + ArrowDown“.',
  },
  'lv-LV': {
    longPressMessage:
      'Lai atvērtu izvēlni, turiet nospiestu vai nospiediet taustiņu kombināciju Alt + lejupvērstā bultiņa',
  },
  'nb-NO': {
    longPressMessage: 'Langt trykk eller trykk Alt + PilNed for å åpne menyen',
  },
  'nl-NL': {
    longPressMessage:
      'Druk lang op Alt + pijl-omlaag of druk op Alt om het menu te openen',
  },
  'pl-PL': {
    longPressMessage:
      'Naciśnij i przytrzymaj lub naciśnij klawisze Alt + Strzałka w dół, aby otworzyć menu',
  },
  'pt-BR': {
    longPressMessage:
      'Pressione e segure ou pressione Alt + Seta para baixo para abrir o menu',
  },
  'pt-PT': {
    longPressMessage:
      'Prima continuamente ou prima Alt + Seta Para Baixo para abrir o menu',
  },
  'ro-RO': {
    longPressMessage:
      'Apăsați lung sau apăsați pe Alt + săgeată în jos pentru a deschide meniul',
  },
  'ru-RU': {
    longPressMessage:
      'Нажмите и удерживайте или нажмите Alt + Стрелка вниз, чтобы открыть меню',
  },
  'sk-SK': {
    longPressMessage:
      'Ponuku otvoríte dlhým stlačením alebo stlačením klávesu Alt + klávesu so šípkou nadol',
  },
  'sl-SI': {
    longPressMessage:
      'Za odprtje menija pritisnite in držite gumb ali pritisnite Alt+puščica navzdol',
  },
  'sr-SP': {
    longPressMessage:
      'Dugo pritisnite ili pritisnite Alt + strelicu prema dole da otvorite meni',
  },
  'sv-SE': {
    longPressMessage:
      'Håll nedtryckt eller tryck på Alt + pil nedåt för att öppna menyn',
  },
  'tr-TR': {
    longPressMessage:
      'Menüyü açmak için uzun basın veya Alt + Aşağı Ok tuşuna basın',
  },
  'uk-UA': {
    longPressMessage:
      'Довго або звичайно натисніть комбінацію клавіш Alt і стрілка вниз, щоб відкрити меню',
  },
  'zh-CN': {
    longPressMessage: '长按或按 Alt + 向下方向键以打开菜单',
  },
  'zh-TW': {
    longPressMessage: '長按或按 Alt+向下鍵以開啟功能表',
  },
};
