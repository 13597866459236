import type {
  Collection,
  Direction,
  KeyboardDelegate,
  Node,
  Orientation,
} from '@react-types/shared';
import type {Key, RefObject} from 'react';
import {isScrollable} from '../utils';

interface ListKeyboardDelegateOptions<T> {
  collection: Collection<Node<T>>;
  ref: RefObject<HTMLElement>;
  collator?: Intl.Collator;
  layout?: 'stack' | 'grid';
  orientation?: Orientation;
  direction?: Direction;
  disabledKeys?: Set<Key>;
}

export class ListKeyboardDelegate<T> implements KeyboardDelegate {
  private collection: Collection<Node<T>>;

  private disabledKeys: Set<Key>;

  private ref: RefObject<HTMLElement>;

  private collator: Intl.Collator | undefined;

  private layout: 'stack' | 'grid';

  private orientation?: Orientation;

  private direction?: Direction;

  constructor(
    collection: Collection<Node<T>>,
    disabledKeys: Set<Key>,
    ref: RefObject<HTMLElement>,
    collator?: Intl.Collator,
  );

  constructor(options: ListKeyboardDelegateOptions<T>);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(...args: any[]) {
    if (args.length === 1) {
      const opts = args[0] as ListKeyboardDelegateOptions<T>;
      this.collection = opts.collection;
      this.ref = opts.ref;
      this.collator = opts.collator;
      this.disabledKeys = opts.disabledKeys || new Set();
      this.orientation = opts.orientation;
      this.direction = opts.direction;
      this.layout = opts.layout || 'stack';
    } else {
      this.collection = args[0];
      this.disabledKeys = args[1];
      this.ref = args[2];
      this.collator = args[3];
      this.layout = 'stack';
      this.orientation = 'vertical';
    }

    // If this is a vertical stack, remove the left/right methods completely
    // so they aren't called by useDroppableCollection.
    if (this.layout === 'stack' && this.orientation === 'vertical') {
      // @ts-expect-error - Type 'undefined' is not assignable to type '(key: Key) => Key | null'
      this.getKeyLeftOf = undefined;
      // @ts-expect-error - Type 'undefined' is not assignable to type '(key: Key) => Key | null'
      this.getKeyRightOf = undefined;
    }
  }

  getNextKey(key: Key) {
    let currentKey = this.collection.getKeyAfter(key);
    while (currentKey != null) {
      const item = this.collection.getItem(currentKey);
      if (item?.type === 'item' && !this.disabledKeys.has(currentKey)) {
        return currentKey;
      }

      currentKey = this.collection.getKeyAfter(currentKey);
    }

    return null;
  }

  getPreviousKey(key: Key) {
    let currentKey = this.collection.getKeyBefore(key);
    while (currentKey != null) {
      const item = this.collection.getItem(currentKey);
      if (item?.type === 'item' && !this.disabledKeys.has(currentKey)) {
        return currentKey;
      }

      currentKey = this.collection.getKeyBefore(currentKey);
    }

    return null;
  }

  private findKey(
    key: Key,
    nextKey: (key: Key) => Key,
    shouldSkip: (prevRect: DOMRect, itemRect: DOMRect) => boolean,
  ) {
    let item = this.getItem(key);
    if (!item) {
      return null;
    }

    // Find the item above or below in the same column.
    const prevRect = item.getBoundingClientRect();
    let currentKey = key;
    do {
      currentKey = nextKey(currentKey);
      item = this.getItem(currentKey);
    } while (item && shouldSkip(prevRect, item.getBoundingClientRect()));

    return currentKey;
  }

  private isSameRow(prevRect: DOMRect, itemRect: DOMRect) {
    return prevRect.top === itemRect.top || prevRect.left !== itemRect.left;
  }

  private isSameColumn(prevRect: DOMRect, itemRect: DOMRect) {
    return prevRect.left === itemRect.left || prevRect.top !== itemRect.top;
  }

  getKeyBelow(key: Key) {
    if (this.layout === 'grid' && this.orientation === 'vertical') {
      return this.findKey(key, (key) => this.getNextKey(key)!, this.isSameRow);
    } else {
      return this.getNextKey(key);
    }
  }

  getKeyAbove(key: Key) {
    if (this.layout === 'grid' && this.orientation === 'vertical') {
      return this.findKey(
        key,
        (key) => this.getPreviousKey(key)!,
        this.isSameRow,
      );
    } else {
      return this.getPreviousKey(key);
    }
  }

  private getNextColumn(key: Key, right: boolean) {
    return right ? this.getPreviousKey(key) : this.getNextKey(key);
  }

  getKeyRightOf(key: Key) {
    if (this.layout === 'grid') {
      if (this.orientation === 'vertical') {
        return this.getNextColumn(key, this.direction === 'rtl');
      } else {
        return this.findKey(
          key,
          (key) => this.getNextColumn(key, this.direction === 'rtl')!,
          this.isSameColumn,
        );
      }
    } else if (this.orientation === 'horizontal') {
      return this.getNextColumn(key, this.direction === 'rtl');
    }

    return null;
  }

  getKeyLeftOf(key: Key) {
    if (this.layout === 'grid') {
      if (this.orientation === 'vertical') {
        return this.getNextColumn(key, this.direction === 'ltr');
      } else {
        return this.findKey(
          key,
          (key) => this.getNextColumn(key, this.direction === 'ltr')!,
          this.isSameColumn,
        );
      }
    } else if (this.orientation === 'horizontal') {
      return this.getNextColumn(key, this.direction === 'ltr');
    }

    return null;
  }

  getFirstKey() {
    let key = this.collection.getFirstKey();
    while (key != null) {
      const item = this.collection.getItem(key);
      if (item?.type === 'item' && !this.disabledKeys.has(key)) {
        return key;
      }

      key = this.collection.getKeyAfter(key);
    }

    return null;
  }

  getLastKey() {
    let key = this.collection.getLastKey();
    while (key != null) {
      const item = this.collection.getItem(key);
      if (item?.type === 'item' && !this.disabledKeys.has(key)) {
        return key;
      }

      key = this.collection.getKeyBefore(key);
    }

    return null;
  }

  private getItem(key: Key): HTMLElement | null {
    return this.ref.current!.querySelector(`[data-key="${key}"]`);
  }

  getKeyPageAbove(key: Key) {
    const menu = this.ref.current!;
    let item = this.getItem(key);
    if (!item) {
      return null;
    }

    if (!isScrollable(menu)) {
      return this.getFirstKey();
    }

    const containerRect = menu.getBoundingClientRect();
    let itemRect: DOMRect | undefined = item.getBoundingClientRect();
    let currentKey: Key | null = key;
    if (this.orientation === 'horizontal') {
      const containerX = containerRect.x - menu.scrollLeft;
      const pageX = Math.max(
        0,
        itemRect.x - containerX + itemRect.width - containerRect.width,
      );

      while (item && itemRect!.x - containerX > pageX) {
        currentKey = this.getKeyAbove(currentKey!);
        item = currentKey == null ? null : this.getItem(currentKey);
        itemRect = item?.getBoundingClientRect();
      }
    } else {
      const containerY = containerRect.y - menu.scrollTop;
      const pageY = Math.max(
        0,
        itemRect.y - containerY + itemRect.height - containerRect.height,
      );

      while (item && itemRect!.y - containerY > pageY) {
        currentKey = this.getKeyAbove(currentKey!);
        item = currentKey == null ? null : this.getItem(currentKey);
        itemRect = item?.getBoundingClientRect();
      }
    }

    return currentKey ?? this.getFirstKey();
  }

  getKeyPageBelow(key: Key) {
    const menu = this.ref.current!;
    let item = this.getItem(key);
    if (!item) {
      return null;
    }

    if (!isScrollable(menu)) {
      return this.getLastKey();
    }

    const containerRect = menu.getBoundingClientRect();
    let itemRect: DOMRect | undefined = item.getBoundingClientRect();
    let currentKey: Key | null = key;
    if (this.orientation === 'horizontal') {
      const containerX = containerRect.x - menu.scrollLeft;
      const pageX = Math.min(
        menu.scrollWidth,
        itemRect.x - containerX - itemRect.width + containerRect.width,
      );

      while (item && itemRect!.x - containerX < pageX) {
        currentKey = this.getKeyBelow(currentKey!);
        item = currentKey == null ? null : this.getItem(currentKey);
        itemRect = item?.getBoundingClientRect();
      }
    } else {
      const containerY = containerRect.y - menu.scrollTop;
      const pageY = Math.min(
        menu.scrollHeight,
        itemRect.y - containerY - itemRect.height + containerRect.height,
      );

      while (item && itemRect!.y - containerY < pageY) {
        currentKey = this.getKeyBelow(currentKey!);
        item = currentKey == null ? null : this.getItem(currentKey);
        itemRect = item?.getBoundingClientRect();
      }
    }

    return currentKey ?? this.getLastKey();
  }

  getKeyForSearch(search: string, fromKey?: Key) {
    if (!this.collator) {
      return null;
    }

    const collection = this.collection;
    let key = fromKey || this.getFirstKey();
    while (key != null) {
      const item = collection.getItem(key);
      const substring = item?.textValue.slice(0, search.length);
      if (item?.textValue && this.collator.compare(substring!, search) === 0) {
        return key;
      }

      key = this.getKeyBelow(key);
    }

    return null;
  }
}
