import {cloneConsumerIdentityDocumentMutation} from 'gelato/frontend/src/graphql/mutations/useCloneConsumerIdentityDocumentMutation';

// eslint-disable-next-line @sail/data-no-imperative-methods
import type {ApolloClient, FetchResult} from '@apollo/client';
import type {
  CloneConsumerIdentityDocumentMutationVariables,
  CloneConsumerIdentityDocumentMutationData,
} from 'gelato/frontend/src/graphql/mutations/useCloneConsumerIdentityDocumentMutation';

/**
 * Performs the mutation that clones the consumer identity document.
 * @param apolloClient The apollo client.
 * @param variables The mutation variables.
 */
export default async function cloneConsumerIdentityDocumentMutationMethod(
  apolloClient: ApolloClient<object>,
  variables: CloneConsumerIdentityDocumentMutationVariables,
): Promise<FetchResult<CloneConsumerIdentityDocumentMutationData>> {
  // eslint-disable-next-line @sail/data-no-imperative-methods
  return apolloClient.mutate({
    mutation: cloneConsumerIdentityDocumentMutation,
    variables,
  });
}
