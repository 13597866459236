import * as React from 'react';

import Message from 'gelato/frontend/src/components/Message';
import ThemableButton from 'gelato/frontend/src/components/ThemableButton';
import {
  STRIPE_PRIVACY_CENTER_URL,
  STRIPE_PRIVACY_POLICY_URL,
} from 'gelato/frontend/src/lib/constants';
import {SetPageCardPropsContext} from 'gelato/frontend/src/lib/contexts';
import {ResponsiveBox} from 'gelato/frontend/src/lib/responsive';
import messages from 'gelato/frontend/src/local_pages/messages/welcome';
import Box from 'sail/Box';
import {ButtonLink} from 'sail/Button';
import Group from 'sail/Group';
import {InlineLink} from 'sail/InlineLink';
import {Body, Title} from 'sail/Text';

import styles from './WelcomeLayout.module.css';

import type {MessageDescriptor} from 'react-intl';

const learnMoreLink = (
  <InlineLink href={STRIPE_PRIVACY_CENTER_URL} target="_blank">
    <Message {...messages.learnMore} />
  </InlineLink>
);

const stripePrivacyPolicyLink = (
  <InlineLink href={STRIPE_PRIVACY_POLICY_URL} target="_blank">
    <Message {...messages.privacyPolicy} />
  </InlineLink>
);

export type WelcomeLayoutProps = {
  primaryMessage?: MessageDescriptor;
  onPrimaryClick: () => void;
  secondaryMessage?: MessageDescriptor | null | undefined;
  onSecondaryClick?: () => void;
  platformName?: string | null | undefined;
  additionalPlatformName?: string | null | undefined;
  primaryPending: boolean;
  secondaryPending?: boolean;
  titleMessage?: MessageDescriptor;
  privacyMessage?: MessageDescriptor;
  connectPrivacyMessage?: MessageDescriptor;
  needMessage?: MessageDescriptor;
  privacyPolicyUrl?: string | null | undefined;
  additionalPrivacyPolicyUrl?: string | null | undefined;
  buttonColor?: string;
};

export const WelcomeLayout = ({
  primaryMessage = messages.getStarted,
  onPrimaryClick,
  secondaryMessage,
  onSecondaryClick,
  platformName,
  additionalPlatformName,
  primaryPending = false,
  secondaryPending = false,
  titleMessage = messages.title,
  privacyMessage = messages.privacy,
  connectPrivacyMessage = messages.privacyConnect,
  needMessage = messages.needIdAndSelfie,
  privacyPolicyUrl,
  additionalPrivacyPolicyUrl,
  buttonColor,
}: WelcomeLayoutProps) => {
  const setPageCardProps = React.useContext(SetPageCardPropsContext);
  React.useEffect(() => {
    setPageCardProps((currentProps) => ({
      ...currentProps,
      showBackLink: false,
      showPageHeader: true,
      showProgressBar: false,
      size: 'single',
      title: null,
    }));
  }, [setPageCardProps]);

  const merchantPrivacyPolicyLink = privacyPolicyUrl ? (
    <InlineLink href={privacyPolicyUrl} target="_blank">
      <Message {...messages.privacyPolicy} />
    </InlineLink>
  ) : (
    <Message {...messages.privacyPolicy} />
  );

  const platformPrivacyPolicyLink = additionalPrivacyPolicyUrl ? (
    <InlineLink href={additionalPrivacyPolicyUrl} target="_blank">
      <Message {...messages.privacyPolicy} />
    </InlineLink>
  ) : (
    <Message {...messages.privacyPolicy} />
  );

  const testIdForCTAs =
    secondaryMessage === messages.continueOnThisDevice
      ? ['verify-handoff', 'verify-start']
      : ['verify-start', 'verify-handoff'];

  const showSecondary = onSecondaryClick && secondaryMessage;
  return (
    <>
      <ResponsiveBox padding={20} className={styles.WelcomeLayout}>
        <Group spacing={8} margin={{bottom: 16}}>
          <Title>
            <Message
              {...titleMessage}
              values={{
                platformName,
              }}
            />
          </Title>
        </Group>
        <Group spacing={8}>
          <Body>
            {additionalPlatformName ? (
              <Message
                {...connectPrivacyMessage}
                values={{
                  platformName,
                  additionalPlatformName,
                  platformPrivacyPolicyLink,
                  stripePrivacyPolicyLink,
                  merchantPrivacyPolicyLink,
                  learnMoreLink,
                }}
              />
            ) : (
              <Message
                {...privacyMessage}
                values={{
                  platformName,
                  stripePrivacyPolicyLink,
                  merchantPrivacyPolicyLink,
                  learnMoreLink,
                }}
              />
            )}
          </Body>
          <Body>
            <Message {...needMessage} />
          </Body>
        </Group>
      </ResponsiveBox>
      <Box
        padding={20}
        flex={{
          justifyContent: 'center',
          direction: 'column',
        }}
      >
        <ThemableButton
          data-testid={testIdForCTAs[0]}
          size="large"
          width="maximized"
          color="blue"
          label={<Message {...primaryMessage} />}
          onClick={onPrimaryClick}
          pending={primaryPending}
          disabled={primaryPending || secondaryPending}
          buttonColor={buttonColor}
        />
        {showSecondary && (
          <Box
            margin={{top: 12}}
            flex={{
              justifyContent: 'center',
            }}
          >
            <ButtonLink
              data-testid={testIdForCTAs[1]}
              size="large"
              width="maximized"
              color="blue"
              label={<Message {...secondaryMessage} />}
              onClick={onSecondaryClick}
              pending={secondaryPending}
              disabled={primaryPending || secondaryPending}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
