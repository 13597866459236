import analytics from 'gelato/frontend/src/lib/analytics';
import LegacyAnalyticsClient from 'gelato/frontend/src/lib/analytics/LegacyAnalyticsClient';
import ProductionAnalyticsClient from 'gelato/frontend/src/lib/analytics/ProductionAnalyticsClient';
import {getConfigValue} from 'gelato/frontend/src/lib/config';
import {getLocale} from 'gelato/frontend/src/lib/locale';
import {handleException} from 'gelato/frontend/src/lib/sentry';

import type {AnalyticsGlobalParams} from 'gelato/frontend/src/lib/analytics/types';
import type {Flags} from 'gelato/frontend/src/lib/contexts';

export type SetupOptions = {
  config: {flags: Flags};
  globalParams: AnalyticsGlobalParams;
};

let isTrackingInitialized = false;

// Sets up the global params that the Gelato frontend analytics will use.
export default function setupAnalyticsClient({
  config,
  globalParams,
}: SetupOptions) {
  try {
    const flags = config.flags.reduce<Record<string, boolean>>((obj, flag) => {
      obj[flag] = true;
      return obj;
    }, {});

    const options = {
      flags,
      locale: getLocale(),
      version: getConfigValue('COMMIT_HASH'),
    };

    analytics.reset();
    analytics.addGlobalParams({
      ...globalParams,
      flags,
    });

    // use legacy analytics that sends data to q.stripe.com
    const legacyAnalytics = new LegacyAnalyticsClient(options);
    analytics.addChildClient(legacyAnalytics);

    if (flags['idprod_analytics_double_logging']) {
      // Also use new the new analytics that sends  data to r.stripe.com
      const productionAnaltics = new ProductionAnalyticsClient(options);
      analytics.addChildClient(productionAnaltics);
    }

    initTracking();
  } catch (error: any) {
    handleException(error, 'Error setting up Analytics client');
  }
}

/**
 * This tracks the global interaction at the document/window level.
 */
function initTracking() {
  if (isTrackingInitialized) {
    // We had added the global event listeners. Exit now.
    return;
  }

  isTrackingInitialized = true;
  try {
    // Analytics.js requires that `viewed` be called before any other events are tracked
    analytics.viewed('init');

    // We track when the user focuses and hides the tab.
    // If we start visible, we record a tab_focused event immediately.
    if (!document.hidden) {
      analytics.track('tabFocused');
    }

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        analytics.track('tabHidden');
      } else {
        analytics.track('tabFocused');
      }
    });

    // We also track the first interaction on the page. We define interaction to be
    // mousedowns, keydowns, and scrolls. We immediately remove the event listener
    // after it fires for the first time.
    const interactionEvents = ['mousedown', 'keydown', 'scroll'];

    const firstInteractionHandler = () => {
      analytics.track('firstInteraction');
      interactionEvents.forEach((event) =>
        document.removeEventListener(event, firstInteractionHandler),
      );
    };

    interactionEvents.forEach((event) =>
      document.addEventListener(event, firstInteractionHandler),
    );

    // Attempt to track unload
    window.addEventListener('unload', () => {
      analytics.track('unload');
    });
  } catch (error: any) {
    handleException(error, 'Error initializing Analytics client');
  }
}
