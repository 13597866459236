import cloneConsumerIdentityDocumentMutation from 'gelato/frontend/src/controllers/utils/cloneConsumerIdentityDocumentMutation';

import type {ApolloError, MutationPromiseReturn} from '@sail/data';
import type {ApplicationRuntime} from 'gelato/frontend/src/controllers/types';
import type {CloneConsumerIdentityDocumentMutationDocument} from 'gelato/frontend/src/graphql/mutations/useCloneConsumerIdentityDocumentMutation';

export default async function cloneConsumerIdentityDocument(
  runtime: ApplicationRuntime,
  associationToken: string,
): Promise<
  MutationPromiseReturn<CloneConsumerIdentityDocumentMutationDocument>
> {
  const resp = await cloneConsumerIdentityDocumentMutation(
    runtime.apolloClient,
    {
      identityDocumentAssociationToken: associationToken,
    },
  );
  const {errors, data} = resp;

  return {
    // Fix for the mistyping of errors in Apollo
    // https://github.com/apollographql/apollo-client/blob/eb2cfee1846b6271e438d1a268e187151e691db4/src/react/hooks/useMutation.ts#L165-L166
    errors: errors as unknown as ApolloError | undefined, // Deprecated
    error: errors as unknown as ApolloError | undefined,
    data,
  };
}
