import * as React from 'react';

import analytics from 'gelato/frontend/src/lib/analytics';
import {LocaleContext} from 'gelato/frontend/src/lib/contexts';
import {AVAILABLE_LOCALES} from 'gelato/frontend/src/lib/locale';
import {ButtonLink} from 'sail/Button';
import Card from 'sail/Card';
import ContextualPopover from 'sail/Layer/ContextualPopover';
import ScrollableMenu from 'sail/ScrollableMenu';

import styles from './LocaleSelector.module.css';

const LOCALE_MENU_ENTRIES: Array<{
  locale: string;
  language: any;
}> = Object.entries(AVAILABLE_LOCALES).map(
  ([locale, language]: [any, any]) => ({
    locale,
    language,
  }),
);

// @ts-expect-error - TS7031 - Binding element 'entry' implicitly has an 'any' type.
const renderMenuItem = ({entry}) => <ButtonLink label={entry.language} />;

const LocaleSelector = () => {
  const {locale, dispatchLocale} = React.useContext(LocaleContext);
  const localeLinkRef = React.useRef<any>(<></>);
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = React.useState<number>(0);

  const handleLocaleSelected = (index: number) => {
    const {locale} = LOCALE_MENU_ENTRIES[index];
    if (document.documentElement) {
      document.documentElement.lang = locale;
    }
    dispatchLocale(locale);
    setShowMenu(false);
    analytics.track('setLocale', {locale});
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleHighlight = (index: number) => setHighlightedIndex(index);

  const renderScrollableMenu = () => (
    <ContextualPopover
      shown={showMenu}
      autoflip
      context={{element: localeLinkRef.current}}
      contextMeetingPoint="top"
      layerMeetingPoint="bottom"
    >
      {/* @ts-expect-error - TS2769 - No overload matches this call. */}
      <Card tabIndex="0">
        <ScrollableMenu
          id="LocaleMenu"
          className={styles.scrollableMenu}
          highlightedIndex={highlightedIndex}
          onHighlight={handleHighlight}
          onSelect={handleLocaleSelected}
          entries={LOCALE_MENU_ENTRIES}
          renderItem={renderMenuItem}
        />
      </Card>
    </ContextualPopover>
  );

  return (
    <div>
      <div ref={localeLinkRef}>
        <ButtonLink
          icon="globe"
          label={AVAILABLE_LOCALES[locale]}
          onClick={toggleMenu}
        />
      </div>
      {renderScrollableMenu()}
    </div>
  );
};

export default LocaleSelector;
