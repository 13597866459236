import {ErrorCode} from 'gelato/frontend/src/controllers/states/ErrorState';
import {sendDocumentImageMutation} from 'gelato/frontend/src/graphql/mutations/useSendDocumentImageMutation';

// eslint-disable-next-line @sail/data-no-imperative-methods
import type {ApolloClient, FetchResult} from '@apollo/client';
import type {
  SendDocumentImageMutationVariables,
  SendDocumentImageMutationData,
} from 'gelato/frontend/src/graphql/mutations/useSendDocumentImageMutation';

/**
 * Performs the send document image mutation.
 * @param apolloClient The apollo client.
 * @param variables The mutation variables.
 */
export default async function sendDocumentImageMutationMethod(
  apolloClient: ApolloClient<object>,
  variables: SendDocumentImageMutationVariables,
): Promise<FetchResult<SendDocumentImageMutationData>> {
  // eslint-disable-next-line @sail/data-no-imperative-methods
  const resp = await apolloClient.mutate({
    mutation: sendDocumentImageMutation,
    variables,
  });

  if (resp.errors) {
    const message = resp.errors.map((e) => e.message).join('\n');
    throw new Error(message || ErrorCode.failedToSendDocumentImage);
  }
  return resp;
}
