import type {RefObject} from 'react';
import {useRef} from 'react';
import {useIsomorphicLayoutEffect} from '@sail/engine';

type Config = {
  node: HTMLElement | Window | RefObject<HTMLElement> | null | undefined;
  handler: (entry: ResizeObserverEntry) => void;
  avoidInitialEvent?: boolean;
};

const callbacks = new WeakMap<Element, Set<Config['handler']>>();

const getResizeObserver = (() => {
  let resizeObserver: ResizeObserver;
  return () => {
    if (resizeObserver === undefined) {
      resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const elementCallbacks = callbacks.get(entry.target);
          elementCallbacks?.forEach((callback) => callback(entry));
        });
      });
    }

    return resizeObserver;
  };
})();

export function useResizeObserver({
  node: nodeOrRef,
  handler,
  avoidInitialEvent = false,
}: Config): void {
  const hasCalled = useRef(false);

  useIsomorphicLayoutEffect(() => {
    let node: HTMLElement | Window | null = null;
    if (nodeOrRef) {
      node = 'current' in nodeOrRef ? nodeOrRef.current : nodeOrRef;
    }

    // We can't use `ResizeObserver` on window, but for
    // consistency we polyfill it with a similar API
    if (node === window) {
      const func = () => {
        // TODO: We need to construct a similar thing to
        // ResizeObserverEntry
        handler({
          borderBoxSize: [],
          contentBoxSize: [],
          contentRect: {
            width: window.innerWidth,
            height: window.innerHeight,
          } as DOMRectReadOnly,
          devicePixelContentBoxSize: [],
          target: document.body,
        });
      };

      if (!avoidInitialEvent) {
        func();
      }
      window.addEventListener('resize', func);
      return () => window.removeEventListener('resize', func);
    } else if (node instanceof Element) {
      const resizeObserver = getResizeObserver();

      if (!callbacks.has(node)) {
        callbacks.set(node, new Set());
      }

      const elementCallbacks = callbacks.get(node);

      const callback = (entry: ResizeObserverEntry) => {
        if (!avoidInitialEvent || hasCalled.current) {
          handler(entry);
        }
        hasCalled.current = true;
      };

      if (elementCallbacks?.size === 0) {
        resizeObserver.observe(node, {box: 'border-box'});
      }

      elementCallbacks?.add(callback);

      return () => {
        hasCalled.current = false;
        elementCallbacks?.delete(callback);
        if (node instanceof Element && elementCallbacks?.size === 0) {
          resizeObserver.unobserve(node);
        }
      };
    }
  }, [nodeOrRef, handler, avoidInitialEvent]);
}
